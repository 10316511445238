// Fetch list

export const FETCH = 'costs.FETCH'
export const FETCH_STARTED = 'costs.FETCH_STARTED'
export const FETCH_FULFILLED = 'costs.FETCH_FULFILLED'
export const FETCH_REJECTED = 'costs.FETCH_REJECTED'
export const FETCH_CANCELLED = 'costs.FETCH_CANCELLED'

export const SORTING_CHANGED = 'costs.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'costs.FETCH_ONE'
export const FETCH_ONE_STARTED = 'costs.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'costs.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'costs.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'costs.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'costs.SAVE'
export const SAVE_STARTED = 'costs.SAVE_STARTED'
export const SAVE_FULFILLED = 'costs.SAVE_FULFILLED'
export const SAVE_REJECTED = 'costs.SAVE_REJECTED'
export const SAVE_CANCELLED = 'costs.SAVE_CANCELLED'

// Delete

export const DELETE = 'costs.DELETE'
export const DELETE_STARTED = 'costs.DELETE_STARTED'
export const DELETE_FULFILLED = 'costs.DELETE_FULFILLED'
export const DELETE_REJECTED = 'costs.DELETE_REJECTED'
export const DELETE_CANCELLED = 'costs.DELETE_CANCELLED'

// Clear

export const CLEAR = 'costs.CLEAR'

// Fetch import headers

export const FETCH_IMPORT_HEADERS = 'costs.FETCH_IMPORT_HEADERS'
export const FETCH_IMPORT_HEADERS_STARTED = 'costs.FETCH_IMPORT_HEADERS_STARTED'
export const FETCH_IMPORT_HEADERS_FULFILLED = 'costs.FETCH_IMPORT_HEADERS_FULFILLED'
export const FETCH_IMPORT_HEADERS_REJECTED = 'costs.FETCH_IMPORT_HEADERS_REJECTED'
export const FETCH_IMPORT_HEADERS_CANCELLED = 'costs.FETCH_IMPORT_HEADERS_CANCELLED'

// Fetch import values

export const FETCH_IMPORT_ITEMS = 'costs.FETCH_IMPORT_ITEMS'
export const FETCH_IMPORT_ITEMS_STARTED = 'costs.FETCH_IMPORT_ITEMS_STARTED'
export const FETCH_IMPORT_ITEMS_FULFILLED = 'costs.FETCH_IMPORT_ITEMS_FULFILLED'
export const FETCH_IMPORT_ITEMS_REJECTED = 'costs.FETCH_IMPORT_ITEMS_REJECTED'
export const FETCH_IMPORT_ITEMS_CANCELLED = 'costs.FETCH_IMPORT_ITEMS_CANCELLED'

// Import transports

export const IMPORT_COSTS = 'costs.IMPORT_COSTS'
export const IMPORT_COSTS_STARTED = 'costs.IMPORT_COSTS_STARTED'
export const IMPORT_COSTS_FULFILLED = 'costs.IMPORT_COSTS_FULFILLED'
export const IMPORT_COSTS_REJECTED = 'costs.IMPORT_COSTS_REJECTED'
export const IMPORT_COSTS_CANCELLED = 'costs.IMPORT_COSTS_CANCELLED'
