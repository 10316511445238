import React from 'react'
import { Route, Switch } from 'react-router'
import DriverSurchargesList from './DriverSurchargesList'
import DriverSurchargesEdit from './DriverSurchargesEdit'

const DriverSurcharges = props => (
    <Switch>
        <Route exact path={props.match.url} component={DriverSurchargesList} />
        <Route exact path={`${props.match.url}/new`} component={DriverSurchargesEdit} />
        <Route exact path={`${props.match.url}/:driverSurchargeID`} component={DriverSurchargesEdit} />
    </Switch>
)

export default DriverSurcharges
