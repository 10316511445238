import React from 'react'
import { Route, Switch } from 'react-router'
import TrailerEquipmentsList from './TrailerEquipmentsList'
import TrailerEquipmentsEdit from './TrailerEquipmentsEdit'

const TrailerEquipments = props => (
    <Switch>
        <Route exact path={props.match.url} component={TrailerEquipmentsList} />
        <Route exact path={`${props.match.url}/new`} component={TrailerEquipmentsEdit} />
        <Route exact path={`${props.match.url}/:trailerEquipmentID`} component={TrailerEquipmentsEdit} />
    </Switch>
)

export default TrailerEquipments
