import React from 'react'
import { Route, Switch } from 'react-router'
import ReportTransportOrders from './ReportTransportOrders'

const Components = props => (
    <Switch>
        <Route exact path={props.match.url} component={ReportTransportOrders} />
    </Switch>
)

export default Components
