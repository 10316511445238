import { createSelector } from 'reselect'

export const getVehicleFuelings = state => state.vehicleFuelings.getIn(['all', 'objects'])

export const createGetVehicleFuelingByIDSelector = id =>
    createSelector(
        [getVehicleFuelings],
        vehicleFuelings => vehicleFuelings.get(String(id))
    )

export const getImportFiles = state => state.vehicleFuelings.get('importFiles')

export const createGetImportFileByIDSelector = id =>
    createSelector(
        [getImportFiles],
        importFiles => importFiles.get(String(id))
    )

const getCurrentVehicleFuelingStatus = state => state.vehicleFuelings.get('current')

export const createGetCurrentVehicleFuelingStatusSelector = () =>
    createSelector(
        [getCurrentVehicleFuelingStatus],
        status => status
    )

export const getVehicleFuelingsFetching = state => state.vehicleFuelings.getIn(['all', 'fetching'])

export const getVehicleFuelingFetching = state => state.vehicleFuelings.getIn(['current', 'fetching'])

export const getVehicleFuelingDeleting = state => state.vehicleFuelings.getIn(['deletion', 'inProgress'])

export const getVehicleFuelingDeleteError = state => state.vehicleFuelings.getIn(['deletion', 'error'])

// Import

export const getImportInProgress = state => state.vehicleFuelings.getIn(['import', 'inProgress'])
export const getImportError = state => state.vehicleFuelings.getIn(['import', 'error'])
export const getImportFileId = state => state.vehicleFuelings.getIn(['import', 'file_id'])
export const getImportHeaders = state => state.vehicleFuelings.getIn(['import', 'headers'])
export const getImportDefaultHeaders = state => state.vehicleFuelings.getIn(['import', 'headersDefault'])
export const getImportValues = state => state.vehicleFuelings.getIn(['import', 'values'])
export const getImportDefaultValues = state => state.vehicleFuelings.getIn(['import', 'valuesDefault'])
export const getImportFuelingCompanies = state => state.vehicleFuelings.getIn(['import', 'fuelingCompanies'])
export const getImportShowFuelingCompanies = state => state.vehicleFuelings.getIn(['import', 'showFuelingCompanies'])
export const getImportSelectedFuelingCompany = state => state.vehicleFuelings.getIn(['import', 'selectedFuelingCompany'])
export const getImportFormats = state => state.vehicleFuelings.getIn(['import', 'formats'])
export const getImportExamples = state => state.vehicleFuelings.getIn(['import', 'examples'])
export const getImportPreview = state => state.vehicleFuelings.getIn(['import', 'preview'])
export const getImportDescription = state => state.vehicleFuelings.getIn(['import', 'description'])

// Import products

export const getImportProductsInProgress = state => state.vehicleFuelings.getIn(['importProducts', 'inProgress'])
export const getImportProductsError = state => state.vehicleFuelings.getIn(['importProducts', 'error'])
export const getImportProductsFileId = state => state.vehicleFuelings.getIn(['importProducts', 'file_id'])
export const getImportProductsHeaders = state => state.vehicleFuelings.getIn(['importProducts', 'headers'])
export const getImportProductsPreview = state => state.vehicleFuelings.getIn(['importProducts', 'preview'])

// Products

export const getProductsInProgress = state => state.vehicleFuelings.getIn(['products', 'inProgress'])
export const getProductsError = state => state.vehicleFuelings.getIn(['products', 'error'])
export const getProducts = state => state.vehicleFuelings.getIn(['products', 'objects'])

// Imports

export const getImportsInProgress = state => state.vehicleFuelings.getIn(['imports', 'inProgress'])
export const getImportsError = state => state.vehicleFuelings.getIn(['imports', 'error'])
export const getImports = state => state.vehicleFuelings.getIn(['imports', 'objects'])

export const getImportDeleting = state => state.vehicleFuelings.getIn(['deletionImport', 'inProgress'])
export const getImportDeleteError = state => state.vehicleFuelings.getIn(['deletionImport', 'error'])

// Country

export const getCountryInProgress = state => state.vehicleFuelings.getIn(['country', 'inProgress'])
export const getCountryError = state => state.vehicleFuelings.getIn(['country', 'error'])
export const getCountryValue = state => state.vehicleFuelings.getIn(['country', 'value'])

// Driver

export const getDriverInProgress = state => state.vehicleFuelings.getIn(['driver', 'inProgress'])
export const getDriverError = state => state.vehicleFuelings.getIn(['driver', 'error'])
export const getDriverValue = state => state.vehicleFuelings.getIn(['driver', 'value'])
