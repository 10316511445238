import * as actionTypes from './actionTypes'

// Fetch

export const fetchCompanies = () => ({
    type: actionTypes.FETCH,
})

export const fetchCompaniesFulfilled = companies => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: companies,
})

export const fetchCompaniesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCompaniesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCompany = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCompanyFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCompanyRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCompanyCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCompany = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveCompanyFulfilled = company => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: company,
})

export const saveCompanyRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteCompany = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCompanyFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCompanyRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportCompanies = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Fetch company cost centers

export const fetchCompanyCostCenters = (id, page) => ({
    type: actionTypes.FETCH_COST_CENTERS,
    payload: {
        id,
        page,
    },
})

export const fetchCompanyCostCentersFulfilled = data => ({
    type: actionTypes.FETCH_COST_CENTERS_FULFILLED,
    payload: data,
})

export const fetchCompanyCostCentersRejected = message => ({
    type: actionTypes.FETCH_COST_CENTERS_REJECTED,
    payload: message,
})

export const fetchCompanyCostCentersCancelled = () => ({
    type: actionTypes.FETCH_COST_CENTERS_CANCELLED,
})

// Export company cost centers

export const exportCompanyCostCenters = id => ({
    type: actionTypes.EXPORT_COST_CENTERS,
    payload: id,
})

export const exportCompanyCostCentersFulfilled = data => ({
    type: actionTypes.EXPORT_COST_CENTERS_FULFILLED,
    payload: data,
})

export const exportCompanyCostCentersRejected = message => ({
    type: actionTypes.EXPORT_COST_CENTERS_REJECTED,
    payload: message,
})

export const exportCompanyCostCentersCancelled = () => ({
    type: actionTypes.EXPORT_COST_CENTERS_CANCELLED,
})


//fetch settings

export const fetchSettings = (id, page) => ({
    type: actionTypes.FETCH_SETTINGS,
    payload: {
        id,
        page,
    },
})

export const fetchSettingsFulfilled = data => ({
    type: actionTypes.FETCH_SETTINGS_FULFILLED,
    payload: data,
})

export const fetchSettingsRejected = message => ({
    type: actionTypes.FETCH_SETTINGS_REJECTED,
    payload: message,
})

export const fetchSettingsCancelled = () => ({
    type: actionTypes.FETCH_SETTINGS_CANCELLED,
})

// Save settings

export const saveSetting = (companyId, localeId, id, value) => ({
    type: actionTypes.SAVE_SETTING,
    payload: {
        companyId: companyId,
        localeId: localeId,
        id: id,
        value: value
    },
})

export const saveSettingFulfilled = data => ({
    type: actionTypes.SAVE_SETTING_FULFILLED,
    payload: data,
})

export const saveSettingRejected = message => ({
    type: actionTypes.SAVE_SETTING_REJECTED,
    payload: message,
})

export const saveSettingCancelled = () => ({
    type: actionTypes.SAVE_SETTING_CANCELLED,
})

// Fetch company bank accounts

export const fetchCompanyBankAccounts = (id, page) => ({
    type: actionTypes.FETCH_BANK_ACCOUNTS,
    payload: {
        id,
        page,
    },
})

export const fetchCompanyBankAccountsFulfilled = data => ({
    type: actionTypes.FETCH_BANK_ACCOUNTS_FULFILLED,
    payload: data,
})

export const fetchCompanyBankAccountsRejected = message => ({
    type: actionTypes.FETCH_BANK_ACCOUNTS_REJECTED,
    payload: message,
})

export const fetchCompanyBankAccountsCancelled = () => ({
    type: actionTypes.FETCH_BANK_ACCOUNTS_CANCELLED,
})

// Export company bank accounts

export const exportCompanyBankAccounts = id => ({
    type: actionTypes.EXPORT_BANK_ACCOUNTS,
    payload: id,
})

export const exportCompanyBankAccountsFulfilled = data => ({
    type: actionTypes.EXPORT_BANK_ACCOUNTS_FULFILLED,
    payload: data,
})

export const exportCompanyBankAccountsRejected = message => ({
    type: actionTypes.EXPORT_BANK_ACCOUNTS_REJECTED,
    payload: message,
})

export const exportCompanyBankAccountsCancelled = () => ({
    type: actionTypes.EXPORT_BANK_ACCOUNTS_CANCELLED,
})

// Save default bank account

export const saveDefaultBankAccount = (company_id, values) => ({
    type: actionTypes.SAVE_DEFAULT_BANK_ACCOUNT,
    payload: {
        company_id,
        values,
    },
})

export const saveDefaultBankAccountFulfilled = data => ({
    type: actionTypes.SAVE_DEFAULT_BANK_ACCOUNT_FULFILLED,
    payload: data,
})

export const saveDefaultBankAccountRejected = message => ({
    type: actionTypes.SAVE_DEFAULT_BANK_ACCOUNT_REJECTED,
    payload: message,
})

export const saveDefaultBankAccountCancelled = () => ({
    type: actionTypes.SAVE_DEFAULT_BANK_ACCOUNT_CANCELLED,
})

// Fetch company bank accounts

export const fetchCompanyNumberings = (id, page) => ({
    type: actionTypes.FETCH_NUMBERINGS,
    payload: {
        id,
        page,
    },
})

export const fetchCompanyNumberingsFulfilled = data => ({
    type: actionTypes.FETCH_NUMBERINGS_FULFILLED,
    payload: data,
})

export const fetchCompanyNumberingsRejected = message => ({
    type: actionTypes.FETCH_NUMBERINGS_REJECTED,
    payload: message,
})

export const fetchCompanyNumberingsCancelled = () => ({
    type: actionTypes.FETCH_NUMBERINGS_CANCELLED,
})

// Export company bank accounts

export const exportCompanyNumberings = id => ({
    type: actionTypes.EXPORT_NUMBERINGS,
    payload: id,
})

export const exportCompanyNumberingsFulfilled = data => ({
    type: actionTypes.EXPORT_NUMBERINGS_FULFILLED,
    payload: data,
})

export const exportCompanyNumberingsRejected = message => ({
    type: actionTypes.EXPORT_NUMBERINGS_REJECTED,
    payload: message,
})

export const exportCompanyNumberingsCancelled = () => ({
    type: actionTypes.EXPORT_NUMBERINGS_CANCELLED,
})
