import { Observable } from 'rxjs'

import { request, parseAPIError } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchLegendEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(action => {
        const requestParams = {
            method: 'GET',
            path: `legend/${action.payload.key}?language=${action.payload.language}`,
        }

        return Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request(requestParams)
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchLegendFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchLegendRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED))
        )
    })

export default [fetchLegendEpic]
