import * as actionTypes from './actionTypes'

// Fetch

export const fetchUserLogins = () => ({
    type: actionTypes.FETCH,
})

export const fetchUserLoginsFulfilled = userLogins => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: userLogins,
})

export const fetchUserLoginsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchUserLoginsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Export

export const exportUserLogins = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
