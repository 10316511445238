import React from 'react'
import { Route, Switch } from 'react-router'
import warehousingWarehousesList from './WarehousingWarehousesList'
import warehousingWarehousesEdit from './WarehousingWarehousesEdit'

const warehousingWarehouses = props => (
    <Switch>
        <Route exact path={props.match.url} component={warehousingWarehousesList} />
        <Route exact path={`${props.match.url}/new`} component={warehousingWarehousesEdit} />
        <Route exact path={`${props.match.url}/:warehousingWarehouseID`} component={warehousingWarehousesEdit} />
    </Switch>
)

export default warehousingWarehouses
