import * as actionTypes from './actionTypes'

// Fetch

export const fetchCarriers = () => ({
    type: actionTypes.FETCH,
})

export const fetchCarriersFulfilled = carriers => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: carriers,
})

export const fetchCarriersRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCarriersCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCarrier = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCarrierFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCarrierRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCarrierCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCarrier = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveCarrierFulfilled = carrier => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: carrier,
})

export const saveCarrierRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveCarrierDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteCarrier = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCarrierFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCarrierRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})



// Restore

export const restoreCarrier = id => ({
    type: actionTypes.RESTORE,
    payload: id,
})

export const restoreCarrierFulfilled = item => ({
    type: actionTypes.RESTORE_FULFILLED,
    payload: item,
})

export const restoreCarrierRejected = message => ({
    type: actionTypes.RESTORE_REJECTED,
    payload: message,
})

// Load info by VAT number

export const loadInfoByVATNumber = vatNumber => ({
    type: actionTypes.LOAD_INFO,
    payload: vatNumber,
})

export const loadInfoByVATNumberFulfilled = vatNumber => ({
    type: actionTypes.LOAD_INFO_FULFILLED,
    payload: vatNumber,
})

export const loadInfoByVATNumberRejected = message => ({
    type: actionTypes.LOAD_INFO_REJECTED,
    payload: message,
})

export const clearInfoByVATNumber = () => ({
    type: actionTypes.CLEAR_INFO,
})

// Export

export const exportCarriers = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Fetch carrier contacts

export const fetchCarrierContacts = (id, page) => ({
    type: actionTypes.FETCH_CONTACTS,
    payload: {
        id,
        page,
    },
})

export const fetchCarrierContactsFulfilled = data => ({
    type: actionTypes.FETCH_CONTACTS_FULFILLED,
    payload: data,
})

export const fetchCarrierContactsRejected = message => ({
    type: actionTypes.FETCH_CONTACTS_REJECTED,
    payload: message,
})

export const fetchCarrierContactsCancelled = () => ({
    type: actionTypes.FETCH_CONTACTS_CANCELLED,
})

// Export carrier contacts

export const exportCarrierContacts = id => ({
    type: actionTypes.EXPORT_CONTACTS,
    payload: id,
})

export const exportCarrierContactsFulfilled = data => ({
    type: actionTypes.EXPORT_CONTACTS_FULFILLED,
    payload: data,
})

export const exportCarrierContactsRejected = message => ({
    type: actionTypes.EXPORT_CONTACTS_REJECTED,
    payload: message,
})

export const exportCarrierContactsCancelled = () => ({
    type: actionTypes.EXPORT_CONTACTS_CANCELLED,
})

// Check duplicity

export const checkCarrierDuplicity = values => ({
    type: actionTypes.CHECK_DUPLICITY,
    payload: values,
})

export const checkCarrierDuplicityFulfilled = data => ({
    type: actionTypes.CHECK_DUPLICITY_FULFILLED,
    payload: data,
})

export const checkCarrierDuplicityRejected = message => ({
    type: actionTypes.CHECK_DUPLICITY_REJECTED,
    payload: message,
})

export const checkCarrierDuplicityCancelled = () => ({
    type: actionTypes.CHECK_DUPLICITY_CANCELLED,
})
