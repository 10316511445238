import { Observable } from 'rxjs'
import { request, parseAPIError } from '../api'

import * as actionCreators from './actionCreators'
import * as actionTypes from './actionTypes'

// Fetch

const fetchEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH)
        .filter(() => !store.getState().hereMap.getIn(['all', 'fetching']))
        .switchMap(action => {
            const requestParams = {
                method: 'POST',
                path: `here-map`,
                body: {
                    settings: action.payload,
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED))
            )
        })
}

const updatePreferencesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.PREFERENCES_UPDATE)
        .filter(() => !store.getState().hereMap.getIn(['preferences', 'saving']))
        .switchMap(action => {
            const requestParams = {
                method: 'POST',
                path: `here-map/update-preferences`,
                body: {
                    preferences: action.payload.preferences,
                    settings: action.payload.settings,
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.PREFERENCES_UPDATE_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => Observable.of(actionCreators.updatePreferencesFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.updatePreferencesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.PREFERENCES_UPDATE_CANCELLED))
            )
        })

export default [fetchEpic, updatePreferencesEpic]
