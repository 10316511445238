import React, { Component } from 'react'
import TextareaAutosize from 'react-autosize-textarea'

import Field from './Field'

// eslint-disable-next-line react/prefer-stateless-function
class InputField extends Component {
    render() {
        const value = this.props.hasOwnProperty('getValue') ? this.props.getValue() : this.props.value

        return (
            <Field {...this.props}>
                {this.props.autosize ? (
                    <TextareaAutosize
                        id={this.props.id}
                        placeholder={this.props.placeholder}
                        value={value}
                        onChange={this.props.onChange}
                        className={`${this.props.className} form-control resizable`}
                        disabled={this.props.readonly ? this.props.readonly() : false}
                        readOnly={this.props.readonly ? this.props.readonly() : false}
                        rows={this.props.rows}
                    />
                ) : (
                    <input
                        id={this.props.id}
                        placeholder={this.props.placeholder}
                        type={this.props.type}
                        value={value}
                        step={this.props.step}
                        min={this.props.min}
                        max={this.props.max}
                        onChange={this.props.onChange}
                        className={`${this.props.className} form-control`}
                        disabled={this.props.readonly ? this.props.readonly() : false}
                        readOnly={this.props.readonly ? this.props.readonly() : false}
                    />
                )}
            </Field>
        )
    }
}

export default InputField
