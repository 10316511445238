import React, { Component } from 'react'
import { is } from 'immutable'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import ColoredBox from '../../../common/colored_box/components/ColoredBox'
import {
    MapPageVehiclesListRowTrailerIconState,
    MapPageVehiclesListRowTrailerIconDamaged,
    MapPageVehiclesListRowTrailerIconDamagedSecurity,
    MapPageVehiclesListRowTrailerIconDamagedCooling,
} from '../../map/components/MapPageVehiclesListRow'

import PermissionsProps from '../../auth/components/PermissionsProps'
import { PERMS } from '../permissions'

import DayTrailer from './DayTrailer'
import WeekendAtHomeTrailer from './WeekendAtHomeTrailer'

class VehicleRowTrailer extends Component {
    handleTrailerNoteChange = () => {
        this.props.handleTrailerNoteChange(this.props.item)
    }

    handleDayNoteChange = (date, currentNote) => {
        this.props.handleDayNoteChange(this.props.item, date, currentNote)
    }

    shouldComponentUpdate(nextProps) {
        return !is(this.props.item, nextProps.item) || !is(this.props.days, nextProps.days)
    }

    render() {
        const item = this.props.item
        const event = item.event
        const trailer = event && event.trailer
        const documents = item.documents
        const equipments = item.equipments

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <tr>
                <td className="b-r w-350 board-vehicle">
                    <div className="w-90 pull-left">
                        <div className="m-t-xxs">
                            <div className="m-t-xxs">
                                <ColoredBox>-</ColoredBox>
                            </div>
                            <div className="m-t-xxs">
                                <ColoredBox bgRGB="none" border="1px solid #CCCCCC">
                                    {item.name}
                                </ColoredBox>
                            </div>
                        </div>
                    </div>
                    <div className="w-240 pull-right">
                        <small>
                            -<br />
                            <span className="text-gray-light">-</span>
                        </small>
                        <span className="trailer-state trailer-state-top">
                            <MapPageVehiclesListRowTrailerIconState vehicleEvent={event} intl={this.props.intl} className="m-r-xxs" />
                            <MapPageVehiclesListRowTrailerIconDamaged vehicleEvent={event} intl={this.props.intl} className="m-r-xxs" />
                            <MapPageVehiclesListRowTrailerIconDamagedSecurity vehicleEvent={event} intl={this.props.intl} className="m-r-xxs" />
                            <MapPageVehiclesListRowTrailerIconDamagedCooling vehicleEvent={event} intl={this.props.intl} />
                        </span>
                    </div>
                </td>
                <PermissionsProps has={[PERMS.UPDATE]} props={{ onClick: this.handleTrailerNoteChange }}>
                    <td className="w-140 board-notes wrapped">
                        {trailer.board_note}
                        {documents.map(document => {
                            const tooltip = <Tooltip id="tooltip">{document.text}</Tooltip>

                            return (
                                <span key={`document-${document.id}`} className="display-inline wp-100 text-danger">
                                    <OverlayTrigger placement="top" overlay={tooltip}>
                                        <span>
                                            <i className="fas fa-exclamation-triangle" /> {document.name}
                                        </span>
                                    </OverlayTrigger>
                                </span>
                            )
                        })}
                        {equipments.map(equipment => {
                            const tooltip = <Tooltip id="tooltip">{equipment.text}</Tooltip>

                            return (
                                <span key={`equipment-${equipment.id}`} className="display-inline wp-100 text-primary">
                                    <OverlayTrigger placement="top" overlay={tooltip}>
                                        <span>
                                            <i className="fas fa-exclamation-triangle" /> {equipment.name}
                                        </span>
                                    </OverlayTrigger>
                                </span>
                            )
                        })}
                    </td>
                </PermissionsProps>
                {this.props.days.map((date, dayKey) => {
                    const dayDate = date.format('YYYY-MM-DD')

                    return (
                        <React.Fragment key={`trailer-header-${dayDate}`}>
                            {date.weekday() === 5 && <WeekendAtHomeTrailer />}
                            <DayTrailer
                                key={`trailer-${item.trailerID}-${dayDate}`}
                                date={date}
                                dayKey={dayKey}
                                trailerID={item.trailerID}
                                notes={item.daily_notes && item.daily_notes[dayDate]}
                                transports={item.transports}
                                transportPoints={item.transport_points && item.transport_points[dayDate]}
                                lastEvent={item.last_event}
                                lastEventDate={item.last_event_datetime && moment(item.last_event_datetime)}
                                handleNoteChange={this.handleDayNoteChange}
                                preferences={this.props.preferences}
                                handleTransportClick={this.props.handleTransportClick}
                            />
                        </React.Fragment>
                    )
                })}
            </tr>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

export default injectIntl(VehicleRowTrailer)
