import React, { Component } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { is } from 'immutable'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import PermissionsProps from '../../auth/components/PermissionsProps'
import { PERMS } from '../permissions'

import * as constants from '../../transports/constants'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'

class Day extends Component {
    handleNoteChange = e => {
        e.preventDefault()

        const currentNote =
            this.props.notes &&
            this.props.notes.filter(note => note.dispatcher && parseInt(note.dispatcher.id) === parseInt(this.props.currentUserId)).shift()

        this.props.handleNoteChange(this.props.date, currentNote ? currentNote.text : '')
    }

    handlePointNoteChange = (e, transportPoint) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.handlePointNoteChange(transportPoint)
    }

    shouldComponentUpdate(nextProps) {
        return (
            !is(this.props.date, nextProps.date) ||
            !is(this.props.transportPoints, nextProps.transportPoints) ||
            !is(this.props.notes, nextProps.notes) ||
            !is(this.props.lastEvent, nextProps.lastEvent)
        )
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const dateFormatted = this.props.date.format('YYYY-MM-DD')

        const cellClassNames = classNames('board-day b-r w-max wm-250', {
            'is-weekend': this.props.date.day() === 6 || this.props.date.day() === 0,
            'is-today': moment().format('YYYY-MM-DD') === dateFormatted,
            'is-last': this.props.lastEventDate && dateFormatted === this.props.lastEventDate.format('YYYY-MM-DD'),
        })

        const transportPointStatesDefinition = constants.TRANSPORT_POINT_STATES_DEFINITION
        const transportPointTypesDefinition = constants.TRANSPORT_POINT_TYPES_DEFINITION

        const lastEvent =
            !this.props.dayKey && this.props.lastEventDate && this.props.lastEventDate.isBefore(dateFormatted, 'day') && this.props.lastEvent
        const lastEventTransportPoint = lastEvent
        const lastEventTransport = lastEvent && this.props.transports && this.props.transports[lastEventTransportPoint.transport_id]
        const lastEventTransportState = lastEvent && lastEventTransport && lastEventTransport.state
        const lastEventTransportPointType =
            lastEvent && lastEventTransportPoint.type_id && transportPointTypesDefinition[lastEventTransportPoint.type_id]

        const preferences = this.props.preferences

        const trailer = this.props.trailer

        const lastEventPlannedDatetimeFrom = helpers.getTransportPointPlannedDatetimeFrom(lastEventTransportPoint, true)
        const lastEventPlannedDatetimeTo = helpers.getTransportPointPlannedDatetimeTo(lastEventTransportPoint, true)
        const lastEventDatetimeArrival = lastEvent && lastEventTransportPoint.datetime_arrival && moment(lastEventTransportPoint.datetime_arrival)
        const lastEventDatetimeFinished = lastEvent && lastEventTransportPoint.datetime_finished && moment(lastEventTransportPoint.datetime_finished)
        const lastEventDatetimeTo = helpers.getFirstExistValue([lastEventDatetimeFinished, lastEventDatetimeArrival, lastEventPlannedDatetimeTo])

        const lastEventTransportPointTooltip = lastEvent && (
            <Tooltip id="lastEventTransportPointTooltip">
                <strong>
                    <FormattedMessage id="fields.transportNo" /> {lastEventTransportPoint.transport_id}
                </strong>
                <br />
                {lastEventTransportState ? this.props.intl.formatMessage({ id: `transportStates.${lastEventTransportState.name}` }) : '-'}
                <br />
                <strong>{lastEventTransportPoint.name}</strong>
                <br />
                {lastEventTransportPointType ? this.props.intl.formatMessage({ id: lastEventTransportPointType.name }) : ''}
                <br />
                {formatters.datetimePeriodFormatter(lastEventPlannedDatetimeFrom, lastEventPlannedDatetimeTo)}
                <br />
                {lastEventDatetimeArrival && (
                    <span>
                        <FormattedMessage id="fields.arrival" />: {formatters.datetimeFormatter(lastEventDatetimeArrival)}
                    </span>
                )}
                <br />
                {lastEventDatetimeFinished && (
                    <span>
                        <FormattedMessage id="fields.finish" />: {formatters.datetimeFormatter(lastEventDatetimeFinished)}
                    </span>
                )}
                <br />
                {lastEventTransportPoint.state
                    ? this.props.intl.formatMessage({ id: `transportPointStates.${lastEventTransportPoint.state.name}` })
                    : '-'}
            </Tooltip>
        )

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsProps has={[PERMS.UPDATE]} props={{ onClick: this.handleNoteChange, className: cellClassNames }}>
                <td key={`day-${this.props.vehicleID}`} className={`${cellClassNames} cursor-default`}>
                    {lastEvent && (
                        <div className="last-event">
                            <OverlayTrigger placement="top" overlay={lastEventTransportPointTooltip}>
                                <a
                                    href="#"
                                    onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        this.props.handleTransportClick(lastEventTransportPoint.transport_id, lastEventTransportPoint.id)
                                    }}
                                    className="text-gray-dark no-text-decoration"
                                >
                                    <small>
                                        {lastEventTransportPoint.name} (
                                        {lastEventDatetimeTo.isValid() ? lastEventDatetimeTo.format('DD.MM.YYYY HH:mm') : ''})
                                    </small>
                                </a>
                            </OverlayTrigger>
                        </div>
                    )}

                    {lastEvent && trailer && trailer.current_position && (
                        <div className="trailer-current-position">
                            <small>
                                <FormattedMessage id="fields.trailer" />: {trailer.current_position}
                            </small>
                        </div>
                    )}

                    {this.props.transportPoints &&
                        preferences &&
                        Object.keys(this.props.transportPoints).map(key => {
                            const transportPoint = this.props.transportPoints[key]

                            if (!parseInt(preferences[`show_transport_point_type_${transportPoint.type_id}`])) {
                                return null
                            }

                            const datetimeEta = transportPoint.datetime_eta && moment(transportPoint.datetime_eta)
                            const transport = this.props.transports && this.props.transports[transportPoint.transport_id]
                            const transportState = transport && transport.state
                            const transportPointState = transportPoint.state && transportPointStatesDefinition[transportPoint.state.id]
                            const transportPointType = transportPoint.type_id && transportPointTypesDefinition[transportPoint.type_id]

                            const plannedDatetimeFrom = helpers.getTransportPointPlannedDatetimeFrom(transportPoint, true)
                            const plannedDatetimeTo = helpers.getTransportPointPlannedDatetimeTo(transportPoint, true)
                            const datetimeArrival = transportPoint.datetime_arrival && moment(transportPoint.datetime_arrival)
                            const datetimeFinished = transportPoint.datetime_finished && moment(transportPoint.datetime_finished)
                            const datetimeFrom = helpers.getFirstExistValue([datetimeFinished, datetimeArrival, plannedDatetimeFrom])

                            const transportPointTooltip = (
                                <Tooltip id="transportPointTooltip">
                                    <strong>
                                        <FormattedMessage id="fields.transportNo" /> {transportPoint.transport_id}
                                    </strong>
                                    <br />
                                    {transportState ? this.props.intl.formatMessage({ id: `transportStates.${transportState.name}` }) : '-'}
                                    <br />
                                    <strong>{transportPoint.name}</strong>
                                    <br />
                                    {transportPointType ? this.props.intl.formatMessage({ id: transportPointType.name }) : ''}
                                    <br />
                                    {formatters.datetimePeriodFormatter(plannedDatetimeFrom, plannedDatetimeTo)}
                                    <br />
                                    {datetimeArrival ? (
                                        <span>
                                            <FormattedMessage id="fields.arrival" />: {formatters.datetimeFormatter(datetimeArrival)}
                                        </span>
                                    ) : (
                                        <span>
                                            <FormattedMessage id="fields.eta" />:{' '}
                                            {datetimeEta ? (
                                                <span>
                                                    {formatters.datetimeFormatter(datetimeEta)}
                                                    {transportPoint.distance && ` (${formatters.distanceFormatter(transportPoint.distance / 1000)})`}
                                                </span>
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    )}
                                    <br />
                                    {datetimeFinished && (
                                        <span>
                                            <FormattedMessage id="fields.finish" />: {formatters.datetimeFormatter(datetimeFinished)}
                                        </span>
                                    )}
                                    <br />
                                    {transportPoint.state
                                        ? this.props.intl.formatMessage({ id: `transportPointStates.${transportPoint.state.name}` })
                                        : '-'}
                                </Tooltip>
                            )

                            const transportPointStateClassName = transportPointState ? transportPointState.className : 'far fa-circle'
                            const transportPointColor = transportPointType ? transportPointType.rgb_bg : '#cccccc'

                            return (
                                <div key={`transport-point-${transportPoint.id}`}>
                                    <OverlayTrigger placement="top" overlay={transportPointTooltip}>
                                        <a
                                            href="#"
                                            onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                this.props.handleTransportClick(transportPoint.transport_id, transportPoint.id)
                                            }}
                                            className="text-gray-dark no-text-decoration"
                                        >
                                            <small className={`transport-state transport-state-${transportState.id}`}>
                                                <i
                                                    className={`${transportPointStateClassName} transport-point-type-icon`}
                                                    style={{ color: `${transportPointColor}` }}
                                                />
                                                {transportPoint.name} ({datetimeFrom.isValid() ? datetimeFrom.format('HH:mm') : ''})
                                                <PermissionsCheck has={[PERMS.UPDATE]}>
                                                    <span
                                                        className="m-l-xxs pull-right far-fas-hover"
                                                        onClick={e => {
                                                            this.handlePointNoteChange(e, transportPoint)
                                                        }}
                                                    >
                                                        <i className="far fa-comment" />
                                                        <i className="fas fa-comment" />
                                                    </span>
                                                </PermissionsCheck>
                                                {transportPoint.board_note && <div className="note wrapped">{transportPoint.board_note}</div>}
                                            </small>
                                        </a>
                                    </OverlayTrigger>
                                </div>
                            )
                        })}

                    {this.props.notes &&
                        this.props.notes.map(note => {
                            const dispatcher = note.dispatcher

                            return (
                                <div
                                    className="note wrapped"
                                    title={dispatcher && dispatcher.name}
                                    key={`dispatcher-note-${note.id}`}
                                    style={{
                                        color: dispatcher && dispatcher.rgb_bg,
                                    }}
                                >
                                    {note.text}
                                </div>
                            )
                        })}
                </td>
            </PermissionsProps>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/no-static-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

export default injectIntl(Day)
