import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { DispatchersBox } from '../../../common/colored_box'

import * as ReportCostCenterCostActionCreators from '../actionCreators'
import { getYear, getData, getFetching, getDetailsData, getDetailsFetching } from '../selectors'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField } from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'report_cost_center_cost'
const prerequisites = ['currencies', 'cost_types']

const tableIdentifier = 'report_cost_center_cost'

class ReportCostCenterCost extends Component {
    state = {
        modalDetails: {
            show: false,
            name: null,
            subjectName: null,
            subjectId: null,
            month: null,
        },
    }

    // List
    getYear = () => {
        if (this.props.year) {
            return parseInt(this.props.year)
        }

        return parseInt(this.props.match.params.year ? this.props.match.params.year : moment().format('YYYY'))
    }

    refresh = () => {
        this.props.actions.fetchReportCostCenterCost(this.getYear())
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleChangeYearMonth = format => {
        const year = format.year.toString()
        const path = `/report-cost-center-cost/${year}`
        this.props.history.push(path)
    }

    // Export
    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportCostCenterCost(this.getYear(), this.props.tableModel.get('filters').toJS())
    }

    handleExportDetails = e => {
        e && e.preventDefault()

        this.props.actions.exportReportCostCenterCostDetails(this.getYear(), this.state.modalDetails.subjectName, this.state.modalDetails.subjectId)
    }

    // Detail
    handleShowDetails = (e, name, subjectName, subjectId) => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: true,
                    name,
                    subjectName,
                    subjectId,
                },
            },
            () => {
                this.props.actions.fetchReportCostCenterCostDetails(this.getYear(), subjectName, subjectId)
            }
        )
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: false,
                    name: null,
                    subjectName: null,
                    subjectId: null,
                    month: null,
                },
            },
            () => {
                this.refresh()
                this.props.actions.clearReportCostCenterCostDetails()
            }
        )
    }

    detialDataFormater = (type, data) => {
        if (type === 'currency') {
            return Object.keys(data).map(currencyCode => {
                const price = data[currencyCode]
                return <div key={`currency-${currencyCode}`}>{formatters.priceFormatter(price, '0,0', currencyCode)}</div>
            })
        } else if (type === 'currencyFloat') {
            return Object.keys(data).map(currencyCode => {
                const price = data[currencyCode]
                return <div key={`currency-${currencyCode}`}>{formatters.priceFormatter(price, '0,0.00', currencyCode)}</div>
            })
        } else if (type === 'km') {
            return formatters.distanceFormatter(data)
        } else if (type === 'consumption') {
            return formatters.volumeFormatter(data, '0,0')
        } else if (type === '100km_consumption') {
            return formatters.consumptionFormatter(data, '0,0.00')
        } else if (type === 'efficiency') {
            return formatters.percentFormatter(data, '0,0')
        }
        return formatters.numberFormatter(data, '0,0')
    }

    componentDidMount() {
        this.props.actions.fetchReportCostCenterCost(this.getYear())
        this.props.actions.fetchPrerequisites()
    }

    componentWillUnmount() {
        this.props.actions.clearReportCostCenterCost()
    }

    componentWillReceiveProps(nextProps) {
        const parsedYear = parseInt(nextProps.match.params.year ? nextProps.match.params.year : moment().format('YYYY'))

        if (parsedYear !== this.getYear()) {
            this.props.actions.clearReportCostCenterCost()
            this.props.actions.fetchReportCostCenterCost(parsedYear)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportCostCenterCost.heading' }))

        const data = this.props.data

        const momentFrom = moment(`${this.getYear()}-01-01`, 'YYYY-MM-DD')
        const momentTo = moment(momentFrom).endOf('year')

        const monthKeys = [...Array(12).keys()]

        const classNames = {
            company_cost_center: 'f-bold',
            vehicle: '',
            trailer: '',
            driver: '',
            user: '',
        }

        const filters = this.props.tableModel.get('filters')

        const detailsData = this.props.detailsData

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportCostCenterCost.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className={'m-r-sm'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            onChange={(momentFrom, momentTo, format) => this.handleChangeYearMonth(format)}
                                            allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_YEAR]}
                                            forbidPickType
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-max wm-300" />
                                                {monthKeys.map(key => {
                                                    const month = moment().set('month', key)

                                                    return (
                                                        <th key={`month-${key}`} className="w-100 text-right">
                                                            {this.props.intl.formatMessage({ id: `monthNames.${month.format('MM')}` })}
                                                        </th>
                                                    )
                                                })}
                                                <th className="w-140 text-right">
                                                    <FormattedMessage id="fields.total" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-max wm-300">
                                                    <TableFilterInputField
                                                        identifier="subject_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                {monthKeys.map(key => (
                                                    <th key={`month-${key}`} className="w-100" />
                                                ))}
                                                <th className="w-140 text-right" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.map(row => {
                                                    if (
                                                        filters.getIn(['subject_name', 'value']) &&
                                                        row.name.toLowerCase().indexOf(filters.getIn(['subject_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    const className = classNames[row.type]

                                                    return (
                                                        <tr key={`row-${row.ccc}-${row.type}-${row.id}`}>
                                                            <td
                                                                className={`w-max wm-300 td-clickable ${className}`}
                                                                onClick={e => {
                                                                    this.handleShowDetails(e, row.name, row.type, row.id)
                                                                }}
                                                            >
                                                                {row.type === 'vehicle' ? (
                                                                    <DispatchersBox dispatcher={row.dispatcher}>{row.name}</DispatchersBox>
                                                                ) : (
                                                                    row.name
                                                                )}
                                                            </td>
                                                            {monthKeys.map(monthKey => {
                                                                const rowMonth = row.data[monthKey + 1]

                                                                return (
                                                                    <td key={`row-${row.ccc}-${row.type}-${row.id}-month-${monthKey}`} className={`w-100 text-right ${className}`}>
                                                                        {rowMonth &&
                                                                            Object.keys(rowMonth).map(currencyCode => {
                                                                                const price = rowMonth[currencyCode]

                                                                                return (
                                                                                    <div key={`currency-${currencyCode}`}>
                                                                                        {formatters.priceFormatter(price, '0,0', currencyCode)}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                    </td>
                                                                )
                                                            })}
                                                            <td className={`w-140 text-right ${className}`}>
                                                                <strong>
                                                                    {Object.keys(row.data.total).map(currencyCode => {
                                                                        const price = row.data.total[currencyCode]

                                                                        return (
                                                                            <div key={`row-${row.ccc}-${row.type}-${row.id}-currency-${currencyCode}`}>
                                                                                {formatters.priceFormatter(price, '0,0', currencyCode)}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-auto">
                        <Modal.Header closeButton>
                            <Modal.Title>{`${this.state.modalDetails.name} / ${momentFrom.format('DD.MM.YYYY')} - ${momentTo.format('DD.MM.YYYY')}`}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="clearfix">
                            <LoadingOverlay active={this.props.detailsFetching}>
                                <div className="btns-list">
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleExportDetails}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                </div>

                                <div className="pull-left wp-100 mh-500">
                                    <div className="pull-left wp-100 m-b-sm">
                                        {detailsData && (
                                            <div className="pull-left wp-100">
                                                <div className="table-container">
                                                    <table className="table table-striped table-hover table-fixed-header">
                                                        <thead>
                                                            <tr>
                                                                <th className="w-max wm-300" />
                                                                {monthKeys.map(key => {
                                                                    const month = moment().set('month', key)

                                                                    return (
                                                                        <th key={`month-${key}`} className="w-120 text-right">
                                                                            {this.props.intl.formatMessage({
                                                                                id: `monthNames.${month.format('MM')}`,
                                                                            })}
                                                                        </th>
                                                                    )
                                                                })}
                                                                <th className="w-140 text-right">
                                                                    <FormattedMessage id="fields.total" />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {detailsData &&
                                                                detailsData.map(row => {
                                                                    const bold = row.bold

                                                                    return (
                                                                        <tr key={`row-${row.type}-${row.id}`}>
                                                                            <td className={`w-max wm-300`}>
                                                                                {bold ? (<strong>{row.name}</strong>) : row.name}
                                                                            </td>
                                                                            {monthKeys.map(monthKey => {
                                                                                const data = row.data[monthKey + 1]
                                                                                const type = row.type

                                                                                return (
                                                                                    <td key={`month-${data}`} className={`w-120 text-right`}>
                                                                                        {bold ? (<strong>{data && this.detialDataFormater(type, data)}</strong>) : data && this.detialDataFormater(type, data)}
                                                                                    </td>
                                                                                )
                                                                            })}
                                                                            <td className={`w-140 text-right`}>
                                                                                <strong>{this.detialDataFormater(row.type, row.data.total)}</strong>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default" onClick={this.handleHideDetails}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        year: getYear(state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...ReportCostCenterCostActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(withRouter(ReportCostCenterCost))
)
