import { Map } from 'immutable'

import * as actionTypes from './actionTypes'
import { TableModelSortDirection } from './model'

const createInitialTableModel = (sortColumn, sortDirection = TableModelSortDirection.ascending) =>
    Map({
        pagination: Map({
            total: 1,
            current: 0,
        }),
        sorting: Map({
            column: sortColumn,
            direction: sortDirection,
        }),
        filters: Map(),
    })

const initialState = Map({
    users_list: createInitialTableModel('full_name'),
    user_roles_list: createInitialTableModel('name'),
    user_logins_list: createInitialTableModel('datetime', TableModelSortDirection.descending),
    blocked_ips_list: createInitialTableModel('name'),
    countries_list: createInitialTableModel('name'),
    currencies_list: createInitialTableModel('name'),
    transport_types_list: createInitialTableModel('name'),
    cargo_types_list: createInitialTableModel('name'),
    vehicle_types_list: createInitialTableModel('name'),
    trailer_types_list: createInitialTableModel('name'),
    companies_list: createInitialTableModel('name'),
    carriers_list: createInitialTableModel('name'),
    carrier_contacts_list: createInitialTableModel('name'),
    customers_list: createInitialTableModel('name'),
    drivers_list: createInitialTableModel('last_name'),
    drivers_types_list: createInitialTableModel('name'),
    trailers_list: createInitialTableModel('registration'),
    vehicles_list: createInitialTableModel('registration'),
    customer_addresses_list: createInitialTableModel('name'),
    customer_contacts_list: createInitialTableModel('name'),
    transports_list: createInitialTableModel('loading_time', TableModelSortDirection.descending),
    board: createInitialTableModel('vehicle_name'),
    surcharge_types_list: createInitialTableModel('name'),
    customer_address_types_list: createInitialTableModel('name'),
    vehicle_events_list: createInitialTableModel(false),
    vehicle_fuelings_list: createInitialTableModel(false),
    vehicle_positions_list: createInitialTableModel('datetime', TableModelSortDirection.descending),
    transporeon_offers_list: createInitialTableModel('number'),
    report_vehicle_checks_list: createInitialTableModel(false),
    report_performance_list: createInitialTableModel(false),
    report_customer_turnovers_list: createInitialTableModel(false),
    report_empty_kilometers_list: createInitialTableModel(false),
    report_pallets_list: createInitialTableModel(false),
    report_invoicing_summary_list: createInitialTableModel(false),
    goods_types_list: createInitialTableModel('name'),
    document_types_list: createInitialTableModel('name'),
    driver_documents_list: createInitialTableModel('date_validity_to', TableModelSortDirection.descending),
    vehicle_documents_list: createInitialTableModel('date_validity_to', TableModelSortDirection.descending),
    trailer_documents_list: createInitialTableModel('date_validity_to', TableModelSortDirection.descending),
    company_cost_centers_list: createInitialTableModel('code'),
    company_bank_accounts_list: createInitialTableModel('name'),
    company_numberings_list: createInitialTableModel('name'),
    equipments_list: createInitialTableModel('name'),
    driver_equipments_list: createInitialTableModel('date_validity_to', TableModelSortDirection.descending),
    driver_timesheets_list: createInitialTableModel('start_datetime', TableModelSortDirection.descending),
    vehicle_equipments_list: createInitialTableModel('date_validity_to', TableModelSortDirection.descending),
    trailer_equipments_list: createInitialTableModel('date_validity_to', TableModelSortDirection.descending),
    report_customer_results: createInitialTableModel(false),
    report_cost_center_results: createInitialTableModel(false),
    report_cost_center_profits: createInitialTableModel(false),
    report_transport_invoicing: createInitialTableModel(false),
    report_transport_changes: createInitialTableModel(false),
    report_transports: createInitialTableModel(false),
    report_spedition_transports: createInitialTableModel(false),
    report_vehicle_kilometers_total: createInitialTableModel(false),
    report_cost_center_traffic: createInitialTableModel(false),
    report_cost_center_performance: createInitialTableModel(false),
    report_vehicle_traffic: createInitialTableModel(false),
    report_vehicle_performance: createInitialTableModel(false),
    report_vehicle_fuelings: createInitialTableModel(false),
    report_cost_center_cost: createInitialTableModel(false),
    tax_rates_list: createInitialTableModel('name'),
    payment_methods_list: createInitialTableModel('name'),
    invoicing_orders_list: createInitialTableModel('loading', TableModelSortDirection.descending),
    invoicing_invoices_list: createInitialTableModel('date_issued', TableModelSortDirection.descending),
    packaging_types_list: createInitialTableModel('name'),
    transport_conditions_list: createInitialTableModel('code'),
    invoice_item_types_list: createInitialTableModel('code'),
    invoicing_received_invoices_list: createInitialTableModel('date_issued', TableModelSortDirection.descending),
    report_driver_allowances: createInitialTableModel(false),
    report_driver_performance_analysis: createInitialTableModel(false),
    report_driver_hourly_overview: createInitialTableModel(false),
    report_driver_timesheets: createInitialTableModel(false),
    report_driver_costs: createInitialTableModel(false),
    report_trailer_costs: createInitialTableModel(false),
    report_trailer_fuelings: createInitialTableModel(false),
    report_vehicle_costs: createInitialTableModel(false),
    report_cost_center_costs: createInitialTableModel(false),
    report_carrier_scoring: createInitialTableModel(false),
    report_dispatcher_kpi: createInitialTableModel(false),
    report_app_usage: createInitialTableModel(false),
    report_carrier_usage: createInitialTableModel(false),
    report_customer_plans: createInitialTableModel(false),
    report_customer_monthly_plans: createInitialTableModel(false),
    report_customer_weekly_plans: createInitialTableModel(false),
    report_carrier_results: createInitialTableModel(false),
    border_crossings_list: createInitialTableModel('name'),
    diet_rates_list: createInitialTableModel(false),
    cost_types_list: createInitialTableModel('name'),
    costs_list: createInitialTableModel('date', TableModelSortDirection.descending),
    cost_generators_list: createInitialTableModel('date_end', TableModelSortDirection.descending),
    report_vehicle_results: createInitialTableModel(false),
    report_driver_results: createInitialTableModel(false),
    report_trailer_results: createInitialTableModel(false),
    report_vehicle_profits: createInitialTableModel(false),
    report_cost_type_costs: createInitialTableModel(false),
    sales_types_list: createInitialTableModel('name'),
    sales_list: createInitialTableModel('date', TableModelSortDirection.descending),
    driver_surcharge_types_list: createInitialTableModel('name'),
    driver_surcharges_list: createInitialTableModel('date', TableModelSortDirection.descending),
    report_driver_surcharges: createInitialTableModel(false),
    report_customer_speditions: createInitialTableModel(false),
    units_list: createInitialTableModel('code'),
    warehousing_warehouses_list: createInitialTableModel('code'),
    warehousing_items_list: createInitialTableModel('name'),
    warehousing_receipts_list: createInitialTableModel('date', TableModelSortDirection.descending),
    warehousing_issues_list: createInitialTableModel('date', TableModelSortDirection.descending),
    service_jobs_list: createInitialTableModel('code'),
    service_workers_list: createInitialTableModel('last_name'),
    service_orders_list: createInitialTableModel('date_planned_from'),
})

function tableModel(state = initialState, action) {
    switch (action.type) {
        case actionTypes.PAGINATION_CHANGED:
            return state.withMutations(map =>
                map
                    .setIn([action.payload.tableIdentifier, 'pagination', 'total'], action.payload.pagination.total)
                    .setIn([action.payload.tableIdentifier, 'pagination', 'current'], action.payload.pagination.current)
                    .setIn([action.payload.tableIdentifier, 'pagination', 'totalCount'], action.payload.pagination.totalCount)
            )
        case actionTypes.CONFIGURATION_CHANGED:
            return state.merge({
                [action.payload.tableIdentifier]: action.payload.configuration,
            })
        default:
            return state
    }
}

export default tableModel
