import React, { Component } from 'react'
import classNames from 'classnames'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { injectIntl } from 'react-intl'

import PermissionsProps from '../../auth/components/PermissionsProps'
import { PERMS } from '../permissions'

class WeekendAtHome extends Component {
    state = {
        atHome: false,
        note: null,
    }

    handleWeekendAtHomeClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.handleWeekendAtHomeClick(this.props.date, this.state.atHome, this.state.note)
    }

    handleWeekendAtHomeChange = () => {
        this.props.handleWeekendAtHomeChange(this.props.date, !this.state.atHome)
    }

    updateStateFromProps = props => {
        const atHome = props.weeklyInfo && props.weeklyInfo.weekend_home
        const note = props.weeklyInfo && props.weeklyInfo.weekend_home_note

        this.setState({ atHome, note })
    }

    componentDidMount() {
        this.updateStateFromProps(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.updateStateFromProps(nextProps)
    }

    render() {
        const cellClassNames = classNames('board-day text-center b-r w-100')
        const iconClass = classNames('fas fa-home', {
            'text-default': !this.state.atHome,
            'text-danger': this.state.atHome,
        })

        const tooltip = (
            <Tooltip id="tooltip">
                {this.state.atHome
                    ? this.props.intl.formatMessage({ id: 'modules.board.weekendAtHome' })
                    : this.props.intl.formatMessage({ id: 'modules.board.weekendAtWork' })}
            </Tooltip>
        )

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsProps has={[PERMS.UPDATE]} props={{ onClick: this.handleWeekendAtHomeClick, className: cellClassNames }}>
                <td className={`${cellClassNames} cursor-default`}>
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        <i className={iconClass} />
                    </OverlayTrigger>
                    <div className="note wrapped">{this.state.note}</div>
                </td>
            </PermissionsProps>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/no-static-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

export default injectIntl(WeekendAtHome)
