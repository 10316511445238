import React from 'react'
import { Route, Switch } from 'react-router'
import GoodsTypesList from './GoodsTypesList'
import GoodsTypesEdit from './GoodsTypesEdit'

const GoodsTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={GoodsTypesList} />
        <Route exact path={`${props.match.url}/new`} component={GoodsTypesEdit} />
        <Route exact path={`${props.match.url}/:goodsTypeID`} component={GoodsTypesEdit} />
    </Switch>
)

export default GoodsTypes
