import * as actionTypes from './actionTypes'

// Fetch

export const fetchTransportConditions = () => ({
    type: actionTypes.FETCH,
})

export const fetchTransportConditionsFulfilled = transportConditions => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: transportConditions,
})

export const fetchTransportConditionsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTransportConditionsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchTransportCondition = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchTransportConditionFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchTransportConditionRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchTransportConditionCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveTransportCondition = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveTransportConditionFulfilled = transportCondition => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: transportCondition,
})

export const saveTransportConditionRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteTransportCondition = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteTransportConditionFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteTransportConditionRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportTransportConditions = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
