import { createSelector } from 'reselect'

export const getUnits = state => state.units.getIn(['all', 'objects'])

export const createGetUnitByIDSelector = id => createSelector([getUnits], units => units.get(String(id)))

const getCurrentUnitStatus = state => state.units.get('current')

export const createGetCurrentUnitStatusSelector = () => createSelector([getCurrentUnitStatus], status => status)

export const getUnitDeleting = state => state.units.getIn(['deletion', 'inProgress'])

export const getUnitDeleteError = state => state.units.getIn(['deletion', 'error'])
