import React, { Component } from 'react'

import { Provider } from 'react-redux'
import { Router, Route, Switch } from 'react-router'

import AppUnauthenticated from './AppUnauthenticated'
import App from './App'
import ConnectedIntlProvider from '../../pages/locale/components/ConnectedIntlProvider'

import store from '../../store'
import history from '../../history'

class Container extends Component {
    render() {
        return (
            <Provider store={store}>
                <ConnectedIntlProvider>
                    <Router history={history}>
                        <Switch>
                            <Route path="/auth" component={AppUnauthenticated} />

                            <Route path="/" component={App} />
                        </Switch>
                    </Router>
                </ConnectedIntlProvider>
            </Provider>
        )
    }
}

export default Container
