// Fetch list

export const FETCH = 'invoicingReceivedInvoices.FETCH'
export const FETCH_STARTED = 'invoicingReceivedInvoices.FETCH_STARTED'
export const FETCH_FULFILLED = 'invoicingReceivedInvoices.FETCH_FULFILLED'
export const FETCH_REJECTED = 'invoicingReceivedInvoices.FETCH_REJECTED'
export const FETCH_CANCELLED = 'invoicingReceivedInvoices.FETCH_CANCELLED'

export const SORTING_CHANGED = 'invoicingReceivedInvoices.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'invoicingReceivedInvoices.FETCH_ONE'
export const FETCH_ONE_STARTED = 'invoicingReceivedInvoices.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'invoicingReceivedInvoices.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'invoicingReceivedInvoices.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'invoicingReceivedInvoices.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'invoicingReceivedInvoices.SAVE'
export const SAVE_STARTED = 'invoicingReceivedInvoices.SAVE_STARTED'
export const SAVE_FULFILLED = 'invoicingReceivedInvoices.SAVE_FULFILLED'
export const SAVE_REJECTED = 'invoicingReceivedInvoices.SAVE_REJECTED'
export const SAVE_CANCELLED = 'invoicingReceivedInvoices.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'invoicingReceivedInvoices.SAVE_DUPLICITY_FOUND'

// Clear

export const CLEAR = 'invoicingReceivedInvoices.CLEAR'
export const CLEAR_CURRENT = 'invoicingReceivedInvoices.CLEAR_CURRENT'

// Delete

export const DELETE = 'invoicingReceivedInvoices.DELETE'
export const DELETE_STARTED = 'invoicingReceivedInvoices.DELETE_STARTED'
export const DELETE_FULFILLED = 'invoicingReceivedInvoices.DELETE_FULFILLED'
export const DELETE_REJECTED = 'invoicingReceivedInvoices.DELETE_REJECTED'
export const DELETE_CANCELLED = 'invoicingReceivedInvoices.DELETE_CANCELLED'

// Restore

export const RESTORE = 'invoicingReceivedInvoices.RESTORE'
export const RESTORE_STARTED = 'invoicingReceivedInvoices.RESTORE_STARTED'
export const RESTORE_FULFILLED = 'invoicingReceivedInvoices.RESTORE_FULFILLED'
export const RESTORE_REJECTED = 'invoicingReceivedInvoices.RESTORE_REJECTED'
export const RESTORE_CANCELLED = 'invoicingReceivedInvoices.RESTORE_CANCELLED'


// Export

export const EXPORT = 'invoicingReceivedInvoices.EXPORT'
export const EXPORT_FULFILLED = 'invoicingReceivedInvoices.EXPORT_FULFILLED'

// Fetch detail

export const FETCH_DETAIL = 'invoicingReceivedInvoices.FETCH_DETAIL'
export const FETCH_DETAIL_STARTED = 'invoicingReceivedInvoices.FETCH_DETAIL_STARTED'
export const FETCH_DETAIL_FULFILLED = 'invoicingReceivedInvoices.FETCH_DETAIL_FULFILLED'
export const FETCH_DETAIL_REJECTED = 'invoicingReceivedInvoices.FETCH_DETAIL_REJECTED'
export const FETCH_DETAIL_CANCELLED = 'invoicingReceivedInvoices.FETCH_DETAIL_CANCELLED'

// Load supplier info

export const LOAD_SUPPLIER_INFO = 'invoicingReceivedInvoices.LOAD_SUPPLIER_INFO'
export const LOAD_SUPPLIER_INFO_STARTED = 'invoicingReceivedInvoices.LOAD_SUPPLIER_INFO_STARTED'
export const LOAD_SUPPLIER_INFO_FULFILLED = 'invoicingReceivedInvoices.LOAD_SUPPLIER_INFO_FULFILLED'
export const LOAD_SUPPLIER_INFO_REJECTED = 'invoicingReceivedInvoices.LOAD_SUPPLIER_INFO_REJECTED'
export const LOAD_SUPPLIER_INFO_CANCELLED = 'invoicingReceivedInvoices.LOAD_SUPPLIER_INFO_CANCELLED'
export const CLEAR_SUPPLIER_INFO = 'invoicingReceivedInvoices.CLEAR_SUPPLIER_INFO'

// Approve

export const APPROVE = 'invoicingReceivedInvoices.APPROVE'
export const APPROVE_STARTED = 'invoicingReceivedInvoices.APPROVE_STARTED'
export const APPROVE_FULFILLED = 'invoicingReceivedInvoices.APPROVE_FULFILLED'
export const APPROVE_REJECTED = 'invoicingReceivedInvoices.APPROVE_REJECTED'
export const APPROVE_CANCELLED = 'invoicingReceivedInvoices.APPROVE_CANCELLED'

// Set as paid

export const SET_AS_PAID = 'invoicingReceivedInvoices.SET_AS_PAID'
export const SET_AS_PAID_STARTED = 'invoicingReceivedInvoices.SET_AS_PAID_STARTED'
export const SET_AS_PAID_FULFILLED = 'invoicingReceivedInvoices.SET_AS_PAID_FULFILLED'
export const SET_AS_PAID_REJECTED = 'invoicingReceivedInvoices.SET_AS_PAID_REJECTED'
export const SET_AS_PAID_CANCELLED = 'invoicingReceivedInvoices.SET_AS_PAID_CANCELLED'

// Set as unpaid

export const SET_AS_UNPAID = 'invoicingReceivedInvoices.SET_AS_UNPAID'
export const SET_AS_UNPAID_STARTED = 'invoicingReceivedInvoices.SET_AS_UNPAID_STARTED'
export const SET_AS_UNPAID_FULFILLED = 'invoicingReceivedInvoices.SET_AS_UNPAID_FULFILLED'
export const SET_AS_UNPAID_REJECTED = 'invoicingReceivedInvoices.SET_AS_UNPAID_REJECTED'
export const SET_AS_UNPAID_CANCELLED = 'invoicingReceivedInvoices.SET_AS_UNPAID_CANCELLED'

// Load transport info

export const LOAD_TRANSPORT_INFO = 'invoicingReceivedInvoices.LOAD_TRANSPORT_INFO'
export const LOAD_TRANSPORT_INFO_STARTED = 'invoicingReceivedInvoices.LOAD_TRANSPORT_INFO_STARTED'
export const LOAD_TRANSPORT_INFO_FULFILLED = 'invoicingReceivedInvoices.LOAD_TRANSPORT_INFO_FULFILLED'
export const LOAD_TRANSPORT_INFO_REJECTED = 'invoicingReceivedInvoices.LOAD_TRANSPORT_INFO_REJECTED'
export const LOAD_TRANSPORT_INFO_CANCELLED = 'invoicingReceivedInvoices.LOAD_TRANSPORT_INFO_CANCELLED'
export const CLEAR_TRANSPORT_INFO = 'invoicingReceivedInvoices.CLEAR_TRANSPORT_INFO'

// Create credit note

export const CREATE_CREDIT_NOTE = 'invoicingReceivedInvoices.CREATE_CREDIT_NOTE'
export const CREATE_CREDIT_NOTE_STARTED = 'invoicingReceivedInvoices.CREATE_CREDIT_NOTE_STARTED'
export const CREATE_CREDIT_NOTE_FULFILLED = 'invoicingReceivedInvoices.CREATE_CREDIT_NOTE_FULFILLED'
export const CREATE_CREDIT_NOTE_REJECTED = 'invoicingReceivedInvoices.CREATE_CREDIT_NOTE_REJECTED'
export const CREATE_CREDIT_NOTE_CANCELLED = 'invoicingReceivedInvoices.CREATE_CREDIT_NOTE_CANCELLED'

// Load files

export const FETCH_FILES = 'invoicingReceivedInvoices.FETCH_FILES'
export const FETCH_FILES_STARTED = 'invoicingReceivedInvoices.FETCH_FILES_STARTED'
export const FETCH_FILES_FULFILLED = 'invoicingReceivedInvoices.FETCH_FILES_FULFILLED'
export const FETCH_FILES_REJECTED = 'invoicingReceivedInvoices.FETCH_FILES_REJECTED'
export const FETCH_FILES_CANCELLED = 'invoicingReceivedInvoices.FETCH_FILES_CANCELLED'

// Upload files

export const UPLOAD_FILES = 'invoicingReceivedInvoices.UPLOAD_FILES'
export const UPLOAD_FILES_STARTED = 'invoicingReceivedInvoices.UPLOAD_FILES_STARTED'
export const UPLOAD_FILES_FULFILLED = 'invoicingReceivedInvoices.UPLOAD_FILES_FULFILLED'
export const UPLOAD_FILES_REJECTED = 'invoicingReceivedInvoices.UPLOAD_FILES_REJECTED'
export const UPLOAD_FILES_CANCELLED = 'invoicingReceivedInvoices.UPLOAD_FILES_CANCELLED'

// Delete file

export const DELETE_FILE = 'invoicingReceivedInvoices.DELETE_FILE'
export const DELETE_FILE_STARTED = 'invoicingReceivedInvoices.DELETE_FILE_STARTED'
export const DELETE_FILE_FULFILLED = 'invoicingReceivedInvoices.DELETE_FILE_FULFILLED'
export const DELETE_FILE_REJECTED = 'invoicingReceivedInvoices.DELETE_FILE_REJECTED'
export const DELETE_FILE_CANCELLED = 'invoicingReceivedInvoices.DELETE_FILE_CANCELLED'

// Export to accounting

export const EXPORT_TO_ACCOUNTING = 'invoicingReceivedInvoices.EXPORT_TO_ACCOUNTING'
export const EXPORT_TO_ACCOUNTING_STARTED = 'invoicingReceivedInvoices.EXPORT_TO_ACCOUNTING_STARTED'
export const EXPORT_TO_ACCOUNTING_FULFILLED = 'invoicingReceivedInvoices.EXPORT_TO_ACCOUNTING_FULFILLED'
export const EXPORT_TO_ACCOUNTING_REJECTED = 'invoicingReceivedInvoices.EXPORT_TO_ACCOUNTING_REJECTED'
export const EXPORT_TO_ACCOUNTING_CANCELLED = 'invoicingReceivedInvoices.EXPORT_TO_ACCOUNTING_CANCELLED'

// Check duplicity

export const CHECK_DUPLICITY = 'invoicingReceivedInvoices.CHECK_DUPLICITY'
export const CHECK_DUPLICITY_STARTED = 'invoicingReceivedInvoices.CHECK_DUPLICITY_STARTED'
export const CHECK_DUPLICITY_FULFILLED = 'invoicingReceivedInvoices.CHECK_DUPLICITY_FULFILLED'
export const CHECK_DUPLICITY_REJECTED = 'invoicingReceivedInvoices.CHECK_DUPLICITY_REJECTED'
export const CHECK_DUPLICITY_CANCELLED = 'invoicingReceivedInvoices.CHECK_DUPLICITY_CANCELLED'
