import * as actionTypes from './actionTypes'

// Fetch

export const fetchUserRoles = () => ({
    type: actionTypes.FETCH,
})

export const fetchUserRolesFulfilled = users => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: users,
})

export const fetchUserRolesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchUserRolesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchUserRole = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchUserRoleFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchUserRoleRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchUserRoleCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveUserRole = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveUserRoleFulfilled = user => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: user,
})

export const saveUserRoleRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteUserRole = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteUserRoleFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteUserRoleRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Duplicate

export const duplicateUserRole = id => ({
    type: actionTypes.DUPLICATE,
    payload: id,
})

export const duplicateUserRoleFulfilled = userRole => ({
    type: actionTypes.DUPLICATE_FULFILLED,
    payload: userRole,
})

export const duplicateUserRoleRejected = message => ({
    type: actionTypes.DUPLICATE_REJECTED,
    payload: message,
})