import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCustomerWeeklyPlans = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportCustomerWeeklyPlansFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCustomerWeeklyPlansRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCustomerWeeklyPlansCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCustomerWeeklyPlans = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportCustomerWeeklyPlans = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})
