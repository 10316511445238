// Fetch list

export const FETCH = 'reportTransportChanges.FETCH'
export const FETCH_STARTED = 'reportTransportChanges.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportTransportChanges.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportTransportChanges.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportTransportChanges.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportTransportChanges.CLEAR'

// Export

export const EXPORT = 'reportTransportChanges.EXPORT'
export const EXPORT_FULFILLED = 'reportTransportChanges.EXPORT_FULFILLED'
