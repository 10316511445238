import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import { Modal } from 'react-bootstrap'

import * as config from '../../../common/config'

import { Form, InputField, SelectField, CheckboxField, SubmitButton } from '../../../common/form'
import { url, handleCommonEditActions, validationMessage, getPrerequisitesArray } from '../../../common/helpers'
import { HEREMapAddressPicker } from '../../../common/here_maps'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as customerAddressesActionCreators from '../actionCreators'
import { createGetCustomerAddressByIDSelector, createGetCurrentCustomerAddressStatusSelector, createGetDuplicitySelector } from '../selectors'
import { createGetCountryByIDSelector } from '../../countries/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {ConfirmDialog} from "../../../common/confirm_dialog";

const componentIdentifier = 'customer_addresses_edit'
const prerequisites = ['countries', 'customers', 'customer_address_types']

class CustomerAddressesEditForm extends Component {
    defaultValues = {}

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        country_code: value => {
            if (validator.isEmpty(String(value)) || value === undefined) {
                return validationMessage('isEmpty')
            } else if (!validator.isLength(value, { max: 3, min: 3 })) {
                return validationMessage('isLength', { max: 3, min: 3 })
            }
            return false
        }
    }

    state = {
        values: this.defaultValues,
        gps: {},
        modalDuplicity: {
            show: false,
            duplicity: null,
        },
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave(false)
        })
    }

    handleSave = ignoreDuplicity => {
        this.props.actions.saveCustomerAddress(Object.assign(this.state.values, this.state.gps), ignoreDuplicity)
    }

    duplicityFoundCallback = duplicity => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()
        this.handleSave(true)
    }

    setGPS = coord => {
        this.setState({
            gps: coord,
        })
    }

    handleMarkerPositionChanged = coord => {
        this.setGPS({
            gps_lat: coord.lat,
            gps_lng: coord.lng,
        })
    }

    handleAddressChanged = address => {
        this.handleChangeValues({
            ...this.state.values,
            street: address.street,
            city: address.city,
            zipcode: address.zipcode,
            country_code: address.country_code,
        })
    }

    successCallback = () => {
        this.props.successCallback ? this.props.successCallback() : this.props.redirect(url(this.props.match, 'customer-addresses'))
    }

    backCallback = () => {
        this.props.backCallback ? this.props.backCallback() : this.props.redirect(url(this.props.match, 'customer-addresses'))
    }

    // restore
    handleRestore = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.actions.restoreCustomerAddress(this.props.customerAddress.id)
                this.hideConfirmDialog()
            },
        })
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    componentDidMount() {
        this.props.customerAddressID && this.props.actions.fetchCustomerAddress(this.props.customerAddressID)
        this.props.actions.fetchPrerequisites()

        if (this.props.defaultValues) {
            this.setValues(this.props.defaultValues)
        }
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback, this.duplicityFoundCallback)

        if (
            nextProps.customerAddress &&
            (JSON.stringify(nextProps.customerAddress) !== JSON.stringify(this.props.customerAddress) || !this.state.values.id)
        ) {
            this.setValues({
                ...nextProps.customerAddress.toJS(),
                country_id: null,
                country_code: nextProps.customerAddress.country_code,
            })

            if (nextProps.customerAddress.gps_lat && nextProps.customerAddress.gps_lng) {
                this.setGPS({
                    gps_lat: nextProps.customerAddress.gps_lat,
                    gps_lng: nextProps.customerAddress.gps_lng,
                })
            }
        }
    }

    render() {
        const duplicity = this.props.duplicity
        const perms = this.props.PERMS ? this.props.PERMS : PERMS

        const customerAddress = this.props.customerAddress

        const poisSettings = customerAddress ? { exceptCustomerAddressIds: [customerAddress.id] } : {}

        const gps = this.state.gps &&
            this.state.gps.gps_lat &&
            this.state.gps.gps_lat && {
                lat: this.state.gps.gps_lat,
                lng: this.state.gps.gps_lng,
            }

        return (
            <div>
                <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                    <PermissionsCheck hasAny={Object.values(perms)}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                            isEdit={Boolean(this.props.customerAddress)}
                        >

                            {customerAddress && customerAddress.deleted_at && (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="alert alert-danger m-b-lg">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <p className="no-m">
                                                        <FormattedMessage id="alerts.messages.warningDeleted" />
                                                    </p>
                                                </div>
                                                <PermissionsCheck has={[PERMS.RESTORE]}>
                                                    <div className="col-sm-4 text-right">
                                                        <button onClick={this.handleRestore} type="button" className="btn btn-danger">
                                                            <FormattedMessage id="buttons.restore" />
                                                        </button>
                                                    </div>
                                                </PermissionsCheck>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="col-md-6 b-r">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputField id="code" label={this.props.intl.formatMessage({ id: 'fields.code' })} />
                                        </div>
                                        <div className="col-md-6">
                                            <SelectField
                                                id="customer_address_type_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.addressType' })}
                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('customer_address_types'))}
                                                nullable
                                            />
                                        </div>
                                    </div>
                                    <InputField
                                        id="name"
                                        label={this.props.intl.formatMessage({ id: 'fields.name' })}
                                        formGroupClassName="form-group-bigger"
                                    />
                                    <div className={this.props.hiddenFields && this.props.hiddenFields.indexOf('customer_id') !== -1 ? 'hidden' : ''}>
                                        <SelectField
                                            id="customer_id"
                                            label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('customers'))}
                                            nullable
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputField id="email" label={this.props.intl.formatMessage({ id: 'fields.email' })} />
                                        </div>
                                        <div className="col-md-6">
                                            <InputField id="phone" label={this.props.intl.formatMessage({ id: 'fields.phone' })} />
                                        </div>
                                    </div>
                                    <InputField id="note" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />
                                    <div className="form-part">
                                        <CheckboxField
                                            id="is_booking_required"
                                            label={this.props.intl.formatMessage({ id: 'fields.bookingRequired' })}
                                        />
                                        {Boolean(this.state.values.is_booking_required) && (
                                            <div>
                                                <InputField id="booking_url" label={this.props.intl.formatMessage({ id: 'fields.bookingURL' })} />
                                                <InputField
                                                    id="booking_note"
                                                    label={this.props.intl.formatMessage({ id: 'fields.bookingNote' })}
                                                    autosize
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <InputField id="identifier" label={this.props.intl.formatMessage({ id: 'fields.importIdentifier' })} />
                                </div>

                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <InputField id="street" label={this.props.intl.formatMessage({ id: 'fields.street' })} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <InputField id="zipcode" label={this.props.intl.formatMessage({ id: 'fields.zipcode' })} />
                                        </div>
                                        <div className="col-md-8">
                                            <InputField id="city" label={this.props.intl.formatMessage({ id: 'fields.city' })} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <InputField id="country_code" label={this.props.intl.formatMessage({ id: 'fields.country' })} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="value_gps">
                                                    <FormattedMessage id="fields.gps" />:
                                                </label>
                                                <span className="form-control input-readonly">{gps ? `${gps.lat}, ${gps.lng}` : ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-v-sm b-t">
                                <HEREMapAddressPicker
                                    appId={config.CFG_HERE_MAPS_APP_ID}
                                    appCode={config.CFG_HERE_MAPS_APP_CODE}
                                    markerCenter={gps}
                                    handleMarkerPositionChanged={this.handleMarkerPositionChanged}
                                    handleAddressChanged={this.handleAddressChanged}
                                    className="h-400"
                                    showPOIs={poisSettings}
                                />
                            </div>

                            <div className="btns-form">
                                <button onClick={this.backCallback} className="btn btn-addon btn-default">
                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                </button>
                                <SubmitButton
                                    perms={Object.values(perms)}
                                    isEdit={Boolean(this.props.customerAddress)}
                                    className="btn-addon pull-right"
                                />
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>

                <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} bsSize="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="confirmDialog.duplicityFound.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormattedMessage id="confirmDialog.duplicityFound.message" />
                        {duplicity && (
                            <div>
                                <div className="row m-t-lg">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.customer" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.customer}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.addressType" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.type}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.name" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.name}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.address" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.address}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.email" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.email}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.phone" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.phone}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.note" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.note}</strong>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                            <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                        </button>
                        <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                            <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                        </button>
                    </Modal.Footer>
                </Modal>
                <ConfirmDialog options={this.state.confirmDialog} />
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        customerAddress: createGetCustomerAddressByIDSelector(ownProps.customerAddressID)(state),
        status: createGetCurrentCustomerAddressStatusSelector()(state),
        duplicity: createGetDuplicitySelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...customerAddressesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CustomerAddressesEditForm)
)
