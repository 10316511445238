import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { Modal } from 'react-bootstrap'

import { ConfirmDialog } from '../../../common/confirm_dialog'

import { getListItemsCountMessage } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { getVehicleFuelingsData, getVehicleFuelingsFetching } from '../selectors'
import * as vehiclesActionCreators from '../actionCreators'

import { deleteVehicleFueling } from '../../vehicle_fuelings/actionCreators'
import { getVehicleFuelingDeleting } from '../../vehicle_fuelings/selectors'

import VehiclesFuelingsListRow from './VehiclesFuelingsListRow'
import VehiclesFuelingsEditForm from '../../vehicle_fuelings/components/VehicleFuelingsEditForm'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_FUELINGS } from '../permissions'

class VehiclesFuelingsList extends Component {
    vehicleEventTypesPairs = {}

    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalFueling: {
            show: false,
            item: null,
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // handles
    onClick = item => {
        this.setState({
            modalFueling: {
                show: true,
                item,
            },
        })
    }

    onDelete = item => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteVehicleFueling(item.id)
                this.hideConfirmDialog()
            },
        })
    }

    onPageChange = page => {
        this.props.actions.fetchVehicleFuelings(this.props.vehicle.id, page.selected)
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportVehicleFuelings(this.props.vehicle.id)
    }

    handleCreateItem = e => {
        e && e.preventDefault()
        this.setState({
            modalFueling: {
                show: true,
                item: null,
            },
        })
    }

    // create modal
    modalFuelingClose = () => {
        this.setState({
            modalFueling: {
                show: false,
                item: null,
            },
        })
    }

    // callbacks
    editSuccessCallback = () => {
        this.modalFuelingClose()
        this.refresh()
    }

    editBackCallback = () => {
        this.modalFuelingClose()
    }

    refresh = () => {
        this.props.actions.fetchVehicleFuelings(this.props.vehicle.id)
    }

    componentDidMount() {
        if (this.props.vehicle) {
            this.props.actions.fetchVehicleFuelings(this.props.vehicle.id)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.vehicle && JSON.stringify(nextProps.vehicle) !== JSON.stringify(this.props.vehicle)) {
            this.props.actions.fetchVehicleFuelings(nextProps.vehicle.id)
        }

        if (this.props.deleting && !nextProps.deleting) {
            this.refresh()
        }
    }

    render() {
        const data = this.props.data

        const rows = data && data.data
        const page = data && data.page ? data.page : 0
        const total = data && data.total ? data.total : 0

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_FUELINGS)} noPermissionsTab>
                <LoadingOverlay active={this.props.fetching}>
                    <div className="btns-list">
                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                        </button>
                        {rows && rows.length > 0 && (
                            <PermissionsCheck has={[PERMS_FUELINGS.EXPORT]}>
                                <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExport}>
                                    <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                </button>
                            </PermissionsCheck>
                        )}
                        <PermissionsCheck has={[PERMS_FUELINGS.CREATE]}>
                            <button className="btn btn-addon btn-success" onClick={this.handleCreateItem}>
                                <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                            </button>
                        </PermissionsCheck>
                    </div>

                    {rows && rows.length > 0 ? (
                        <React.Fragment>
                            <div className="table-container">
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                        <tr>
                                            <PermissionsCheck hasAny={[PERMS_FUELINGS.UPDATE, PERMS_FUELINGS.DELETE]}>
                                                <th className="w-60" />
                                            </PermissionsCheck>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.dateTime" />
                                            </th>
                                            <th className="w-140">
                                                <FormattedMessage id="fields.driver" />
                                            </th>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.country" />
                                            </th>
                                            <th className="w-80">
                                                <FormattedMessage id="fields.fuelLevel" />
                                            </th>
                                            <th className="w-80">
                                                <FormattedMessage id="fields.adblueLevel" />
                                            </th>
                                            <th className="w-max wm-200">
                                                <FormattedMessage id="fields.documentNumber" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows &&
                                            rows.map((item, index) => (
                                                <VehiclesFuelingsListRow
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={`row-${index}`}
                                                    item={item}
                                                    onClick={this.onClick}
                                                    onDelete={this.onDelete}
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="pull-left m-l-xxs m-t-md">
                                <FormattedMessage id="pagination.totalRecords" />: {getListItemsCountMessage(0, 0, total)}
                            </div>

                            <ReactPaginate
                                containerClassName="pagination"
                                breakLabel={<span className="disabled">...</span>}
                                activeClassName="active"
                                pageCount={Math.ceil(total / 50)}
                                pageRangeDisplayed={10}
                                marginPagesDisplayed={2}
                                forcePage={page}
                                onPageChange={this.onPageChange}
                                previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                            />
                        </React.Fragment>
                    ) : (
                        <div className="alert alert-danger pull-left wp-100">
                            <FormattedMessage id="fields.noItemsFound" />
                        </div>
                    )}
                </LoadingOverlay>

                <Modal show={Boolean(this.state.modalFueling.show)} onHide={this.modalFuelingClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.modalFueling.item ? <FormattedMessage id="fields.itemEdit" /> : <FormattedMessage id="fields.itemCreate" />}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <VehiclesFuelingsEditForm
                            vehicleFuelingID={this.state.modalFueling.item && this.state.modalFueling.item.id}
                            successCallback={this.editSuccessCallback}
                            backCallback={this.editBackCallback}
                            defaultValues={{
                                vehicle_id: this.props.vehicle.id,
                            }}
                            hiddenFields={['vehicle_id']}
                            PERMS={PERMS_FUELINGS}
                        />
                    </Modal.Body>
                </Modal>

                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: getVehicleFuelingsData(state),
        fetching: getVehicleFuelingsFetching(state),
        deleting: getVehicleFuelingDeleting(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...vehiclesActionCreators,
                    deleteVehicleFueling,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(VehiclesFuelingsList)
)
