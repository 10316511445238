// Fetch list

export const FETCH = 'reportAppUsage.FETCH'
export const FETCH_STARTED = 'reportAppUsage.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportAppUsage.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportAppUsage.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportAppUsage.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportAppUsage.CLEAR'

