import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {FormattedMessage, injectIntl} from 'react-intl'
import {connect} from 'react-redux'

import {LoadingOverlay} from '../../../common/loading_overlay'

import {
    getSettingsData,
    getSettingsFetching,
    getSettingsSaving,
    getSettingsError
} from '../selectors'

import {hasAnyPermission} from '../../../common/helpers'

import * as companiesActionCreators from '../actionCreators'

import {getPermissions} from '../../auth/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import {PERMS_SETTINGS} from "../permissions";
import {InputField} from "../../../common/form";
import * as config from '../../../common/config'


const componentIdentifier = 'companies_settings_list'


//settings
export const settings = {
    invoice_footer: {
        multilang: true,
        autosize: true,
        label: 'fields.invoice_footer',
    },
    transport_footer: {
        multilang: true,
        autosize: true,
        label: 'fields.transport_footer',
    }
}

class CompaniesSettingsList extends Component {
    state = {
        values: {}
    }

    saveSetting = (localeId, settingId, event) => {
        if(!this.state.values.hasOwnProperty(localeId)) {
            this.state.values[localeId] = []
        }
        if(!this.state.values[localeId].hasOwnProperty(settingId)) {
            this.state.values[localeId][settingId] = ''
        }

        var value = event.currentTarget.value;
        this.state.values[localeId][settingId] = value
        this.setState({values: this.state.values})

        if(this.timeout) {
            clearTimeout(this.timeout);
        }
        var value = event.target.value;
        var idParts = event.target.id.split('__');
        var id = idParts[0]
        this.timeout = setTimeout(() => {
            this.props.actions.saveSetting(this.props.company.id, localeId, id, value)
        }, 2000);

    }

    componentDidMount() {
        if (this.props.company) {
            this.props.actions.fetchSettings(this.props.company.id)
        }
    }
    componentWillReceiveProps(newProps){
        if(typeof newProps.data !== 'undefined' && newProps.data !== null){
            this.setState({values: newProps.data.data })
        }
    }

    render() {

        //permissions
        const isDisabledField = !hasAnyPermission([PERMS_SETTINGS.UPDATE])

        //prepare array
        const inputs = []
        {
            Object.entries(settings).map((setting, i, settings) => {
                var settingId = setting[0]
                var settingOptions = setting[1]
                var isLastSetting = settings.length - 1 === i
                if(settingOptions.multilang) {
                    Object.entries(config.locales).map((locale, i, locales) => {
                        var locale = locale[1]
                        inputs.push({
                            'id': settingId + '__' + locale.id,
                            'autosize': settingOptions.autosize,
                            'localeId': locale.id,
                            'value': this.state.values.hasOwnProperty(locale.id) && this.state.values[locale.id].hasOwnProperty(settingId) ? this.state.values[locale.id][settingId] : '',
                            'label': '['+locale.name+'] '+this.props.intl.formatMessage({id: settingOptions.label}),
                            'onChange': event => this.saveSetting(locale.id, settingId, event),
                            'isLastInGroup': locales.length - 1 === i && !isLastSetting
                        })
                    })
                }
                else {
                    inputs.push({
                        'id': settingId,
                        'autosize': settingOptions.autosize,
                        'localeId': null,
                        'value': this.state.values.hasOwnProperty('common') && this.state.values['common'].hasOwnProperty(settingId) ? this.state.values['common'][settingId] : '',
                        'label': this.props.intl.formatMessage({id: settingOptions.label}),
                        'onChange': event => this.saveSetting(null, settingId, event),
                        'isLastInGroup': true
                    })
                }
            })
        }


        //return
        return (<PermissionsCheck hasAny={Object.values(PERMS_SETTINGS)} noPermissionsTab>
            <LoadingOverlay active={this.props.saving || this.props.fetching}>
                {Object.entries(inputs).map((input, i) => {
                    var input = input[1];
                    return (<div key={i} className="row clearfix">
                        <div className="col-sm-12">
                            <InputField
                                id={`${input.id}`}
                                autosize={input.autosize}
                                value={input.value}
                                onChange={input.onChange}
                                readonly={() => isDisabledField}
                                label={`${input.label}`}
                            />
                            {input.isLastInGroup &&
                                <hr/>
                            }
                        </div>
                    </div>)
                })}
            </LoadingOverlay>
        </PermissionsCheck>)

    }
}

function mapStateToProps(state) {
    return {
        permissions: getPermissions(state),
        data: getSettingsData(state),
        fetching: getSettingsFetching(state),
        saving: getSettingsSaving(state),
        error: getSettingsError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators({
                ...companiesActionCreators

            }, dispatch),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps, ...dispatchProps, ...ownProps,
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(CompaniesSettingsList))
