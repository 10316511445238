import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import { LoadingOverlay } from '../../../common/loading_overlay'

import { getTrailerHistoryData, getTrailerHistoryFetching } from '../selectors'
import * as trailersActionCreators from '../actionCreators'

import TrailersHistoryListRow from './TrailersHistoryListRow'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_HISTORY } from '../permissions'

class TrailersHistoryList extends Component {
    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportTrailerHistory(this.props.trailer.id)
    }

    componentDidMount() {
        if (this.props.trailer) {
            this.props.actions.fetchTrailerHistory(this.props.trailer.id)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.trailer && JSON.stringify(nextProps.trailer) !== JSON.stringify(this.props.trailer)) {
            this.props.actions.fetchTrailerHistory(nextProps.trailer.id)
        }
    }

    render() {
        const trailer = this.props.trailer
        const data = this.props.data

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_HISTORY)} noPermissionsTab>
                <LoadingOverlay active={this.props.fetching}>
                    {data && data.length > 0 ? (
                        <React.Fragment>
                            {this.props.showTrailerRegistration && <h4>{trailer && trailer.registration}</h4>}

                            <div className="btns-list">
                                <PermissionsCheck has={[PERMS_HISTORY.EXPORT]}>
                                    <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                        <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                    </button>
                                </PermissionsCheck>
                            </div>

                            <div className="table-container table-container-no-pagination">
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                        <tr>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.from" />
                                            </th>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.to" />
                                            </th>
                                            <th className="w-140">
                                                <FormattedMessage id="fields.vehicle" />
                                            </th>
                                            <th className="w-max wm-200">
                                                <FormattedMessage id="fields.driver" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data &&
                                            data.map((item, index) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <TrailersHistoryListRow key={`row-${index}`} item={item} nextItem={data[index + 1]} />
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {this.props.showTrailerRegistration && (
                                <div className="pull-left wp-100">
                                    <h4>{trailer && trailer.registration}</h4>
                                </div>
                            )}
                            <div className="alert alert-danger pull-left wp-100">
                                <FormattedMessage id="fields.noItemsFound" />
                            </div>
                        </React.Fragment>
                    )}
                </LoadingOverlay>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: getTrailerHistoryData(state),
        fetching: getTrailerHistoryFetching(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...trailersActionCreators,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(TrailersHistoryList)
)
