import React, { Component } from 'react'
import moment from 'moment'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_COST } from '../permissions'

// eslint-disable-next-line react/prefer-stateless-function
class TransportTemplatesRoutesSummaryDetail extends Component {
    render() {
        const route = this.props.route
        const transportType = this.props.transportType

        const datetimeStart = route && route.datetime_start ? moment(route.datetime_start) : null
        const datetimeEnd = route && route.datetime_end ? moment(route.datetime_end) : null
        const time = datetimeStart && datetimeEnd && datetimeEnd.diff(datetimeStart, 'minutes')

        const priceClassName = route && transportType && route.getProfitPerc() < transportType.min_profit_percent ? 'text-danger' : 'text-success'
        const timeClassName = time && time < route.time_total ? 'text-danger' : 'text-success'

        return (
            <div>
                {route && (
                    <div>
                        <div className="row p-v-xs">
                            <div className="col-md-12 m-b-xxs text-gray">
                                <FormattedMessage id="fields.routeDetails" />
                            </div>
                            <div className="col-md-6 text-left text-list">
                                <b>
                                    <FormattedMessage id="fields.distance" />:
                                </b>
                                <br />
                                <b>
                                    <FormattedMessage id="fields.transportDuration" />:
                                </b>
                                <br />
                                <b>
                                    <FormattedMessage id="fields.requiredTime" />
                                </b>
                                <br />
                                <FormattedMessage id="fields.drive" />:<br />
                                <FormattedMessage id="fields.rests" /> ({route.rests_count}x):
                                <br />
                                <FormattedMessage id="fields.breaks" /> ({route.breaks_count}x):
                            </div>
                            <div className="col-md-6 text-right text-list">
                                <b>{formatters.integerFormatter(route.distance)} km</b>
                                <br />
                                <b className={timeClassName}>{time && formatters.hoursFromMinutesFormatter(time)}</b>
                                <br />
                                <b>{formatters.hoursFromMinutesFormatter(route.time_total)}</b>
                                <br />
                                {formatters.hoursFromMinutesFormatter(route.time_driving)}
                                <br />
                                {formatters.hoursFromMinutesFormatter(route.time_rest)}
                                <br />
                                {formatters.hoursFromMinutesFormatter(route.time_break)}
                            </div>
                        </div>
                        <PermissionsCheck hasAny={Object.values(PERMS_COST)}>
                            <div className="row p-v-xs">
                                <div className="col-md-12 m-b-xxs text-gray">
                                    <FormattedMessage id="fields.priceAndCosts" />
                                </div>
                                <div className="col-md-6 text-left text-list">
                                    <b>
                                        <FormattedMessage id="fields.totalPrice" />:
                                    </b>
                                    <br />
                                    <b>
                                        <FormattedMessage id="fields.totalCosts" />:
                                    </b>
                                    <br />
                                    <FormattedMessage id="fields.fuel" />:
                                    <br />
                                    <FormattedMessage id="fields.adblue" />:
                                    <br />
                                    <FormattedMessage id="fields.toll" />:
                                    <br />
                                    <FormattedMessage id="fields.tollPasses" />:
                                    <br />
                                    <FormattedMessage id="fields.fixCosts" />:
                                </div>
                                <div className="col-md-6 text-right text-list">
                                    <b className={priceClassName}>{formatters.priceFormatter(route.price)}</b>
                                    <br />
                                    <b>{formatters.priceFormatter(route.costs_total)}</b>
                                    <br />
                                    {formatters.priceFormatter(route.costs_fuel)}
                                    <br />
                                    {formatters.priceFormatter(route.costs_adblue)}
                                    <br />
                                    {formatters.priceFormatter(route.costs_toll_per_km + route.costs_toll_per_day)}
                                    <br />
                                    {formatters.priceFormatter(route.costs_toll_passes)}
                                    <br />
                                    {formatters.priceFormatter(route.costs_fixed)}
                                </div>
                            </div>
                        </PermissionsCheck>
                    </div>
                )}
            </div>
        )
    }
}

export default injectIntl(TransportTemplatesRoutesSummaryDetail)
