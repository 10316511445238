import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'

import * as actionCreators from '../actionCreators'
import * as selectors from '../selectors'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { SelectField } from '../../../common/form'
import Form from '../../../common/form/components/Form'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_SCORING } from '../permissions'

class TransportsScoring extends Component {
    state = {
        values: {},
        valuesSet: false,
    }

    getQuestions = () =>
        this.props.data && this.props.data.settings && Array.isArray(this.props.data.settings.data) ? this.props.data.settings.data : []

    getQuestionAnswers = question => (question && question.answers && Array.isArray(question.answers) ? question.answers : [])

    getScoringRules = () =>
        this.props.data && this.props.data.settings && Array.isArray(this.props.data.settings.rules) ? this.props.data.settings.rules : []

    getScoringAnswers = () => (this.props.data && this.props.data.data && Array.isArray(this.props.data.data) ? this.props.data.data : [])

    existsScoringAnswer = () => this.getScoringAnswers().length > 0

    getTotalPoints = () => {
        let points = 0
        this.getScoringAnswers().forEach(answer => {
            points += parseInt(answer.answer.points)
        })

        return points
    }

    getScoringResultRule = () => {
        const totalPoints = this.getTotalPoints()
        let resultRule = null
        this.getScoringRules().forEach(rule => {
            if (rule.points_from <= totalPoints && rule.points_to >= totalPoints) {
                resultRule = rule
            }
        })

        return resultRule
    }

    changeValues = values => {
        this.setState({
            values,
        })
    }

    setValuesFromData = data => {
        const values = {}
        data.forEach(score => {
            values[`answer_${score.answer.question_id}`] = score.answer.id
        })

        this.setState({
            values,
            valuesSet: true,
        })
    }

    submitForm = () => {
        if (this.props.transport && this.props.actions.saveScoring) {
            this.props.actions.saveScoring(this.props.transport.id, this.state.values)
        }
    }

    componentDidMount() {
        if (this.props.transport && this.props.actions.fetchScoring) {
            this.props.actions.fetchScoring(this.props.transport.id)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.transport && JSON.stringify(nextProps.transport) !== JSON.stringify(this.props.transport) && this.props.actions.fetchScoring) {
            this.props.actions.fetchScoring(nextProps.transport.id)
        }

        if (
            nextProps.data &&
            nextProps.data.data &&
            (JSON.stringify(nextProps.data.data) !== JSON.stringify(this.props.data.data) || !this.state.valuesSet)
        ) {
            this.setValuesFromData(nextProps.data.data)
        }
    }

    render() {
        const transport = this.props.transport

        if (!transport) {
            return null
        }

        const showLoader = this.props.isFetching || this.props.isSaving
        const scoringResultRule = this.getScoringResultRule()

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_SCORING)} noPermissionsTab>
                <LoadingOverlay active={showLoader}>
                    <div className="panel-head">
                        <h2 className="pull-left wp-100">
                            <div className="pull-left">
                                <FormattedMessage id="modules.transports.scoring" />
                            </div>
                        </h2>
                    </div>

                    <div className="pull-left wp-100 m-b-md">
                        <PermissionsCheck hasAny={Object.values(PERMS_SCORING)}>
                            <Form validationRules={{}} values={this.state.values} onChange={this.changeValues} onSubmit={this.submitForm}>
                                <div className="row">
                                    <div className="col-md-6">
                                        {this.existsScoringAnswer() ? (
                                            <div className="alert alert-success">
                                                {scoringResultRule && <strong>{scoringResultRule.mark}</strong>}
                                                {scoringResultRule && ` - ${scoringResultRule.text}`} (<FormattedMessage id="fields.pointsCount" />:{' '}
                                                {this.getTotalPoints()})
                                            </div>
                                        ) : (
                                            <div className="alert alert-danger">
                                                <FormattedMessage id="fields.scoringNotSetYet" />
                                            </div>
                                        )}

                                        {this.getQuestions().map(question => {
                                            const options = this.getQuestionAnswers(question).map(answer => ({
                                                id: answer.id,
                                                name: `${answer.points} - ${answer.text}`,
                                            }))

                                            return (
                                                <div key={`question-${question.id}`} className="form-group">
                                                    <label htmlFor="nothing">{question.text}</label>
                                                    <div>
                                                        <SelectField id={`answer_${question.id}`} values={options} nullable />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <PermissionsCheck has={[PERMS_SCORING.UPDATE]}>
                                            <div className="btns-form clearfix">
                                                <button type="submit" className="btn btn-addon btn-success">
                                                    <i className="far fa-check" /> <FormattedMessage id="buttons.save" />
                                                </button>
                                            </div>
                                        </PermissionsCheck>
                                    </div>
                                </div>
                            </Form>
                        </PermissionsCheck>
                    </div>
                </LoadingOverlay>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: selectors.getTransportScoringData(state),
        isFetching: selectors.isTransportScoringFetching(state),
        isSaving: selectors.isTransportScoringSaving(state),
        error: selectors.getTransportScoringError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TransportsScoring)
)
