import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import { DispatchersBox } from '../../../common/colored_box'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class VehicleEventsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render() {
        const event = this.props.data
        const transportPoint = this.props.data.transport_point

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-120">{event.datetime_start ? formatters.datetimeFormatter(event.datetime_start) : null}</td>
                <td className="w-120">{event.datetime_end ? formatters.datetimeFormatter(event.datetime_end) : null}</td>
                <td className="w-140">
                    {this.props.vehicle ? (
                        <DispatchersBox dispatcher={this.props.dispatcher}>{this.props.vehicle.registration}</DispatchersBox>
                    ) : null}
                </td>
                <td className="w-140">
                    {this.props.trailer ? <DispatchersBox dispatcher={null}>{this.props.trailer.registration}</DispatchersBox> : null}
                </td>
                <td className="w-120">{this.props.driver ? this.props.driver.getShortFullName() : null}</td>
                <td className="w-120">{this.props.dispatcher ? this.props.dispatcher.getShortFullName() : null}</td>
                <td className="w-80">{this.props.transport ? this.props.transport.id : null}</td>
                <td className="w-200">{this.props.vehicleEventType}</td>
                <td className="w-max wm-300">
                    {this.props.country ? `${this.props.country.iso_code} ` : null}
                    {transportPoint ? `${transportPoint.zipcode} ${transportPoint.city}` : null}
                </td>
                <td className="w-70 text-center">
                    {this.props.trailer ? formatters.boolFormatter(this.props.data.is_trailer_loaded, 'text-danger') : null}
                </td>
                <td className="w-70 text-center">
                    {this.props.trailer ? formatters.boolFormatter(this.props.trailer.is_damage, 'text-danger') : null}
                </td>
                <td className="w-70 text-center">
                    {this.props.trailerType && this.props.trailerType.is_secured
                        ? formatters.boolFormatter(this.props.trailer.is_security_damage, 'text-danger')
                        : null}
                </td>
                <td className="w-70 text-center">
                    {this.props.trailerType && this.props.trailerType.is_cooled
                        ? formatters.boolFormatter(this.props.trailer.is_cooling_damage, 'text-danger')
                        : null}
                </td>
            </tr>
        )
    }
}

export default VehicleEventsListRow
