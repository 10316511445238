import React from 'react'
import { Route, Switch } from 'react-router'
import DriversTypesList from './DriversTypesList'
import DriversTypesEdit from './DriversTypesEdit'

const DriversTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={DriversTypesList} />
        <Route exact path={`${props.match.url}/new`} component={DriversTypesEdit} />
        <Route exact path={`${props.match.url}/:driversTypeID`} component={DriversTypesEdit} />
    </Switch>
)

export default DriversTypes
