import { createSelector } from 'reselect'

export const getWarehousingReceipts = state => state.warehousingReceipts.getIn(['all', 'objects'])
export const createGetWarehousingReceiptByIDSelector = id => createSelector([getWarehousingReceipts], receipts => receipts.get(String(id)))

const getCurrentWarehousingReceiptStatus = state => state.warehousingReceipts.get('current')
export const createGetCurrentWarehousingReceiptStatusSelector = () => createSelector([getCurrentWarehousingReceiptStatus], status => status)

export const getWarehousingReceiptDeleting = state => state.warehousingReceipts.getIn(['deletion', 'inProgress'])
export const getWarehousingReceiptDeleteError = state => state.warehousingReceipts.getIn(['deletion', 'error'])

export const getWarehousingItemData = state => state.warehousingReceipts.getIn(['warehousingItem', 'data'])
export const getWarehousingItemFetching = state => state.warehousingReceipts.getIn(['warehousingItem', 'fetching'])
export const getWarehousingItemError = state => state.warehousingReceipts.getIn(['warehousingItem', 'error'])