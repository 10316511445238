import React from 'react'
import { Route, Switch } from 'react-router'
import CustomerAddressTypesList from './CustomerAddressTypesList'
import CustomerAddressTypesEdit from './CustomerAddressTypesEdit'

const CustomerAddressTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={CustomerAddressTypesList} />
        <Route exact path={`${props.match.url}/new`} component={CustomerAddressTypesEdit} />
        <Route exact path={`${props.match.url}/:customerAddressTypeID`} component={CustomerAddressTypesEdit} />
    </Switch>
)

export default CustomerAddressTypes
