import { createSelector } from 'reselect'

export const getTransportTypes = state => state.transportTypes.getIn(['all', 'objects'])

export const createGetTransportTypeByIDSelector = id => createSelector([getTransportTypes], transportTypes => transportTypes.get(String(id)))

const getCurrentTransportTypeStatus = state => state.transportTypes.get('current')

export const createGetCurrentTransportTypeStatusSelector = () => createSelector([getCurrentTransportTypeStatus], status => status)

export const getTransportTypeDeleting = state => state.transportTypes.getIn(['deletion', 'inProgress'])

export const getTransportTypeDeleteError = state => state.transportTypes.getIn(['deletion', 'error'])
