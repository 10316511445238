import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import { Modal } from 'react-bootstrap'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import moment from 'moment'

import * as constants from '../constants'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import { Form, InputField, SelectField } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as transportsActionCreators from '../actionCreators'
import {
    getTransportParts,
    getTransportPartsFetching,
    getTransportPartsSaving,
    getTransportPartsDeleting,
    getTransportPartsError,
} from '../selectors'

import TransportsPartsItem from './TransportsPartsItem'
import CustomerAddressesPicker from '../../customer_address/components/CustomerAddressesPicker'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_PARTS } from '../permissions'
import {CustomDatePicker} from "../../../common/custom_date_picker";

const componentIdentifier = 'transports_parts'
const prerequisites = ['transport_states', 'carriers', 'currencies', 'countries', 'transport_conditions']

class TransportsParts extends Component {
    state = {
        transportParts: [],
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalCreateItem: {
            show: false,
            values: {},
        },
        addressesPicker: {
            show: false,
            target: null,
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // actions
    fetchTransportParts = transportId => {
        this.props.actions.fetchTransportParts(transportId)
    }

    saveTransportPart = data => {
        this.props.actions.saveTransportPart(this.props.transport.id, data)
    }

    saveTransportPartsOrder = transportPartsIds => {
        this.props.actions.saveTransportPartsOrder(this.props.transport.id, transportPartsIds)
    }

    deleteTransportPart = transportPartsId => {
        this.props.actions.deleteTransportPart(this.props.transport.id, transportPartsId)
    }

    setTransportParts = transportParts => {
        this.setState({
            transportParts,
        })
    }

    refresh = () => {
        this.fetchTransportParts(this.props.transport.id)
    }

    // handles
    onSortEnd = ({ oldIndex, newIndex }) => {
        const transportParts = arrayMove(this.state.transportParts, oldIndex, newIndex)
        this.saveTransportPartsOrder(transportParts.map(transportPart => transportPart.id))
    }

    onClick = transportPart => {
        this.modalCreateItemOpen({
            ...transportPart,
            transport_conditions: transportPart.transport_conditions.map(item => item.id),
        })
    }

    onDelete = transportPart => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.deleteTransportPart(transportPart.id)
                this.hideConfirmDialog()
            },
        })
    }

    onDownloadOrder = transportPart => {
        this.props.onDownloadOrder && this.props.onDownloadOrder(transportPart)
    }

    onCreateItemButtonClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.modalCreateItemOpen({})
    }

    onDivideTransportButtonClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.saveTransportPart({
            take_from_transport: true,
        })
    }

    // create item
    modalCreateItemOpen = values => {
        this.setState({
            modalCreateItem: {
                show: true,
                values,
            },
        })
    }

    modalCreateItemClose = () => {
        this.setState({
            modalCreateItem: {
                show: false,
                values: {},
            },
        })
    }

    handleCreateItemChange = values => {
        this.setState({
            modalCreateItem: {
                ...this.state.modalCreateItem,
                values: {
                    ...this.state.modalCreateItem.values,
                    ...values,
                },
            },
        })
    }

    handleCreateItemDatetimeChange = (key, value) => {
        this.setState({
            modalCreateItem: {
                ...this.state.modalCreateItem,
                values: {
                    ...this.state.modalCreateItem.values,
                    [key]: helpers.convertCSDatetimeToISO(value),
                },
            },
        })
    }

    handleCreateItemSubmit = () => {
        this.saveTransportPart(this.state.modalCreateItem.values)
    }

    // customer picker
    closeAddressesPicker = e => {
        e && e.preventDefault()
        this.setState({
            addressesPicker: {
                show: false,
                target: null,
            },
        })
    }

    openAddressesPickerLoading = e => {
        e && e.preventDefault()
        this.setState({
            addressesPicker: {
                show: true,
                target: 1,
            },
        })
    }

    openAddressesPickerUnloading = e => {
        e && e.preventDefault()
        this.setState({
            addressesPicker: {
                show: true,
                target: 2,
            },
        })
    }

    handleSelectAddress = address => {
        const target = this.state.addressesPicker.target === 1 ? 'loading' : 'unloading'

        this.closeAddressesPicker()

        this.setState({
            modalCreateItem: {
                ...this.state.modalCreateItem,
                values: {
                    ...this.state.values,
                    [`${target}_name`]: address.name,
                    [`${target}_street`]: address.street,
                    [`${target}_zipcode`]: address.zipcode,
                    [`${target}_city`]: address.city,
                    [`${target}_country_id`]: address.country_id,
                },
            },
        })
    }

    componentDidMount() {
        if (this.props.transport) {
            this.fetchTransportParts(this.props.transport.id)
        }

        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        // Fetching after transport change
        if (nextProps.transport && JSON.stringify(nextProps.transport) !== JSON.stringify(this.props.transport)) {
            this.fetchTransportParts(nextProps.transport.id)
        }

        // Set transport parts
        if (JSON.stringify(nextProps.transportParts) !== JSON.stringify(this.state.transportParts)) {
            this.setTransportParts(nextProps.transportParts)
        }

        // Saving
        if (this.props.transportPartsSaving && !nextProps.transportPartsSaving) {
            if (nextProps.transportPartsError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.saveSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )

                this.modalCreateItemClose()
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.transportPartsError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Deleting
        if (this.props.transportPartsDeleting && !nextProps.transportPartsDeleting) {
            if (nextProps.transportPartsError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.deleteSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.transportPartsError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    render() {
        const transport = this.props.transport
        const transportParts = this.state.transportParts || []
        const values = this.state.modalCreateItem.values || {}

        if (!transport) {
            return null
        }

        const momentLoadingPlannedFrom = values.loading_datetime_planned_from && moment(values.loading_datetime_planned_from)
        const momentLoadingPlannedTo = values.loading_datetime_planned_to && moment(values.loading_datetime_planned_to)
        const momentLoadingSpecifiedFrom = values.loading_datetime_specified_from && moment(values.loading_datetime_specified_from)
        const momentLoadingSpecifiedTo = values.loading_datetime_specified_to && moment(values.loading_datetime_specified_to)
        const momentLoadingArrival = values.loading_datetime_arrival && moment(values.loading_datetime_arrival)
        const momentLoadingFinished = values.loading_datetime_finished && moment(values.loading_datetime_finished)

        const loadingPlannedFrom = momentLoadingPlannedFrom && momentLoadingPlannedFrom.format('DD.MM.YYYY HH:mm')
        const loadingPlannedTo = momentLoadingPlannedTo && momentLoadingPlannedTo.format('DD.MM.YYYY HH:mm')
        const loadingSpecifiedFrom = momentLoadingSpecifiedFrom && momentLoadingSpecifiedFrom.format('DD.MM.YYYY HH:mm')
        const loadingSpecifiedTo = momentLoadingSpecifiedTo && momentLoadingSpecifiedTo.format('DD.MM.YYYY HH:mm')
        const loadingArrival = momentLoadingArrival && momentLoadingArrival.format('DD.MM.YYYY HH:mm')
        const loadingFinished = momentLoadingFinished && momentLoadingFinished.format('DD.MM.YYYY HH:mm')

        const momentUnloadingPlannedFrom = values.unloading_datetime_planned_from && moment(values.unloading_datetime_planned_from)
        const momentUnloadingPlannedTo = values.unloading_datetime_planned_to && moment(values.unloading_datetime_planned_to)
        const momentUnloadingSpecifiedFrom = values.unloading_datetime_specified_from && moment(values.unloading_datetime_specified_from)
        const momentUnloadingSpecifiedTo = values.unloading_datetime_specified_to && moment(values.unloading_datetime_specified_to)
        const momentUnloadingArrival = values.unloading_datetime_arrival && moment(values.unloading_datetime_arrival)
        const momentUnloadingFinished = values.unloading_datetime_finished && moment(values.unloading_datetime_finished)

        const unloadingPlannedFrom = momentUnloadingPlannedFrom && momentUnloadingPlannedFrom.format('DD.MM.YYYY HH:mm')
        const unloadingPlannedTo = momentUnloadingPlannedTo && momentUnloadingPlannedTo.format('DD.MM.YYYY HH:mm')
        const unloadingSpecifiedFrom = momentUnloadingSpecifiedFrom && momentUnloadingSpecifiedFrom.format('DD.MM.YYYY HH:mm')
        const unloadingSpecifiedTo = momentUnloadingSpecifiedTo && momentUnloadingSpecifiedTo.format('DD.MM.YYYY HH:mm')
        const unloadingArrival = momentUnloadingArrival && momentUnloadingArrival.format('DD.MM.YYYY HH:mm')
        const unloadingFinished = momentUnloadingFinished && momentUnloadingFinished.format('DD.MM.YYYY HH:mm')

        const SortableItem = SortableElement(({ item, onClick, onDelete, onDownloadOrder }) => (
            <div>
                <TransportsPartsItem transportPart={item} onClick={onClick} onDelete={onDelete} onDownloadOrder={onDownloadOrder} />
            </div>
        ))

        const SortableList = SortableContainer(({ items, onClick, onDelete, onDownloadOrder }) => (
            <div className="display-inline wp-100">
                {items.map((item, index) => (
                    <SortableItem
                        key={`transportPart-${item.id}`}
                        index={index}
                        item={item}
                        onClick={onClick}
                        onDelete={onDelete}
                        onDownloadOrder={onDownloadOrder}
                    />
                ))}
            </div>
        ))

        const transportVehicleTypeOptions = Object.keys(constants.TRANSPORT_VEHICLE_TYPES).map(typeId => {
            const type = constants.TRANSPORT_VEHICLE_TYPES[typeId]

            return {
                id: typeId,
                name: this.props.intl.formatMessage({ id: type.name }),
            }
        })

        const transportVehicleType = constants.TRANSPORT_VEHICLE_TYPES[values.transport_vehicle_type_id]
        const transportVehicleNumberLabel = transportVehicleType && transportVehicleType.numberLabel

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_PARTS)} noPermissionsTab>
                <LoadingOverlay active={this.props.transportPartsFetching || this.props.transportPartsDeleting}>
                    <div className="panel-head">
                        <h2 className="pull-left wp-100">
                            <div className="pull-left">
                                <FormattedMessage id="modules.transports.parts" />
                            </div>
                        </h2>
                    </div>

                    {transportParts.length > 0 ? (
                        <div className="m-b-xs">
                            <SortableList
                                items={transportParts}
                                onClick={this.onClick}
                                onDelete={this.onDelete}
                                onDownloadOrder={this.onDownloadOrder}
                                onSortEnd={this.onSortEnd}
                                lockAxis="y"
                                useDragHandle
                                lockToContainerEdges
                                helperClass="transport-waypoints"
                            />
                        </div>
                    ) : (
                        <div className="alert alert-warning pull-left wp-100">
                            <p>
                                <FormattedMessage id="fields.transportIsNotDivided" />
                            </p>
                        </div>
                    )}

                    <div className="pull-left wp-100">
                        {transportParts.length > 0 ? (
                            <PermissionsCheck has={[PERMS_PARTS.CREATE]}>
                                <button className="btn btn-default btn-addon m-r-xs" onClick={this.onCreateItemButtonClick}>
                                    <i className="far fa-plus" /> <FormattedMessage id="buttons.addTransportPart" />
                                </button>
                            </PermissionsCheck>
                        ) : (
                            <PermissionsCheck has={[PERMS_PARTS.CREATE]}>
                                <button className="btn btn-default btn-addon m-r-xs" onClick={this.onDivideTransportButtonClick}>
                                    <i className="far fa-plus" /> <FormattedMessage id="buttons.divideTransportToParts" />
                                </button>
                            </PermissionsCheck>
                        )}
                    </div>
                </LoadingOverlay>

                <ConfirmDialog options={this.state.confirmDialog} />

                <Modal show={this.state.modalCreateItem.show} onHide={this.modalCreateItemClose} className="modal-size-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="buttons.createItem" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-12" style={{ display: this.state.addressesPicker.show ? 'none' : 'block' }}>
                                <LoadingOverlay active={this.props.transportPartsSaving}>
                                    <PermissionsCheck hasAny={Object.values(PERMS_PARTS)}>
                                        <Form
                                            values={values}
                                            validationRules={{}}
                                            onChange={this.handleCreateItemChange}
                                            onSubmit={this.handleCreateItemSubmit}
                                            className="pull-left wp-100"
                                        >
                                            <div className="row m-b-md">
                                                <div className="col-md-4">
                                                    <p>
                                                        <strong>
                                                            <FormattedMessage id="fields.general" />
                                                        </strong>
                                                    </p>
                                                    <InputField id="name" label={this.props.intl.formatMessage({ id: 'fields.name' })} />
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <SelectField
                                                                id="transport_vehicle_type_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.transportVehicleType' })}
                                                                values={transportVehicleTypeOptions}
                                                                prompt={false}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            {transportVehicleNumberLabel && (
                                                                <InputField
                                                                    id="transport_vehicle_number"
                                                                    label={this.props.intl.formatMessage({ id: transportVehicleNumberLabel })}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <SelectField
                                                        id="carrier_id"
                                                        label={this.props.intl.formatMessage({ id: 'fields.supplier' })}
                                                        values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('carriers'))}
                                                        prompt="-"
                                                        nullable
                                                    />
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="carrier_order_number"
                                                                label={this.props.intl.formatMessage({ id: 'fields.orderNumber' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="pull-left wp-60">
                                                                <InputField
                                                                    id="carrier_price"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.price' })}
                                                                    className="text-right"
                                                                />
                                                            </div>
                                                            <div className="pull-left wp-40">
                                                                <SelectField
                                                                    id="carrier_currency_id"
                                                                    label="&nbsp;"
                                                                    values={helpers.getPrerequisitesArray(
                                                                        this.props.prerequisites.values.get('currencies'),
                                                                        'id',
                                                                        'iso_code'
                                                                    )}
                                                                    prompt={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <SelectField
                                                        id="transport_conditions"
                                                        label={this.props.intl.formatMessage({ id: 'fields.transportConditions' })}
                                                        values={helpers.getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('transport_conditions'),
                                                            'id',
                                                            'code'
                                                        )}
                                                        multi
                                                    />
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <InputField
                                                                id="carrier_note"
                                                                label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                                autosize
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <SelectField
                                                                id="transport_state_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.state' })}
                                                                values={helpers.getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('transport_states'),
                                                                    'id',
                                                                    'name',
                                                                    this.props.intl,
                                                                    'transportStates.'
                                                                )}
                                                                disableSorting
                                                                prompt={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 b-r b-l">
                                                    <div className="row m-b-xs">
                                                        <div className="col-md-4">
                                                            <strong>
                                                                <FormattedMessage id="fields.loading" />
                                                            </strong>
                                                        </div>
                                                        <div className="col-md-8 text-right">
                                                            <button
                                                                onClick={this.openAddressesPickerLoading}
                                                                className="btn btn-addon btn-xs btn-default"
                                                                style={{ marginTop: '-7px' }}
                                                            >
                                                                <i className="far fa-search" />
                                                                <FormattedMessage id="buttons.findAddress" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <InputField id="loading_name" label={this.props.intl.formatMessage({ id: 'fields.company' })} />
                                                    <InputField id="loading_street" label={this.props.intl.formatMessage({ id: 'fields.street' })} />
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <InputField
                                                                id="loading_zipcode"
                                                                label={this.props.intl.formatMessage({ id: 'fields.zipcode' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <InputField
                                                                id="loading_city"
                                                                label={this.props.intl.formatMessage({ id: 'fields.city' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <SelectField
                                                                id="loading_country_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.country' })}
                                                                values={helpers.getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('countries'),
                                                                    'id',
                                                                    'iso_code'
                                                                )}
                                                                nullable
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_planned_time_from">
                                                                    <FormattedMessage id="fields.plannedTimeFrom" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_planned_time_from"
                                                                    selected={momentLoadingPlannedFrom}
                                                                    value={loadingPlannedFrom}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_planned_from',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_planned_from',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_planned_time_to">
                                                                    <FormattedMessage id="fields.to" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_planned_time_to"
                                                                    selected={momentLoadingPlannedTo}
                                                                    value={loadingPlannedTo}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_planned_to',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_planned_to',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_specified_time_from">
                                                                    <FormattedMessage id="fields.specifiedTimeFrom" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_specified_time_from"
                                                                    selected={momentLoadingSpecifiedFrom}
                                                                    value={loadingSpecifiedFrom}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_specified_from',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_specified_from',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_specified_time_to">
                                                                    <FormattedMessage id="fields.to" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_specified_time_to"
                                                                    selected={momentLoadingSpecifiedTo}
                                                                    value={loadingSpecifiedTo}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_specified_to',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_specified_to',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_arrival">
                                                                    <FormattedMessage id="fields.arrival" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_arrival"
                                                                    selected={momentLoadingArrival}
                                                                    value={loadingArrival}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_arrival',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_arrival',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_finished">
                                                                    <FormattedMessage id="fields.finish" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_finished"
                                                                    selected={momentLoadingFinished}
                                                                    value={loadingFinished}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_finished',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'loading_datetime_finished',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <InputField
                                                        id="loading_note"
                                                        label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                        autosize
                                                    />
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="row m-b-xs">
                                                        <div className="col-md-4">
                                                            <strong>
                                                                <FormattedMessage id="fields.unloading" />
                                                            </strong>
                                                        </div>
                                                        <div className="col-md-8 text-right">
                                                            <button
                                                                onClick={this.openAddressesPickerUnloading}
                                                                className="btn btn-addon btn-xs btn-default"
                                                                style={{ marginTop: '-7px' }}
                                                            >
                                                                <i className="far fa-search" />
                                                                <FormattedMessage id="buttons.findAddress" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <InputField id="unloading_name" label={this.props.intl.formatMessage({ id: 'fields.company' })} />
                                                    <InputField
                                                        id="unloading_street"
                                                        label={this.props.intl.formatMessage({ id: 'fields.street' })}
                                                    />
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <InputField
                                                                id="unloading_zipcode"
                                                                label={this.props.intl.formatMessage({ id: 'fields.zipcode' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <InputField
                                                                id="unloading_city"
                                                                label={this.props.intl.formatMessage({ id: 'fields.city' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <SelectField
                                                                id="unloading_country_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.country' })}
                                                                values={helpers.getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('countries'),
                                                                    'id',
                                                                    'iso_code'
                                                                )}
                                                                nullable
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_planned_time_from">
                                                                    <FormattedMessage id="fields.plannedTimeFrom" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_planned_time_from"
                                                                    selected={momentUnloadingPlannedFrom}
                                                                    value={unloadingPlannedFrom}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_planned_from',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_planned_from',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_planned_time_to">
                                                                    <FormattedMessage id="fields.to" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_planned_time_to"
                                                                    selected={momentUnloadingPlannedTo}
                                                                    value={unloadingPlannedTo}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_planned_to',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_planned_to',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_specified_time_from">
                                                                    <FormattedMessage id="fields.specifiedTimeFrom" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_specified_time_from"
                                                                    selected={momentUnloadingSpecifiedFrom}
                                                                    value={unloadingSpecifiedFrom}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_specified_from',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_specified_from',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_specified_time_to">
                                                                    <FormattedMessage id="fields.to" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_specified_time_to"
                                                                    selected={momentUnloadingSpecifiedTo}
                                                                    value={unloadingSpecifiedTo}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_specified_to',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_specified_to',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_arrival">
                                                                    <FormattedMessage id="fields.arrival" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_arrival"
                                                                    selected={momentUnloadingArrival}
                                                                    value={unloadingArrival}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_arrival',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_arrival',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="value_finished">
                                                                    <FormattedMessage id="fields.finish" />:
                                                                </label>
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id="value_finished"
                                                                    selected={momentUnloadingFinished}
                                                                    value={unloadingFinished}
                                                                    onChange={value => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_finished',
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleCreateItemDatetimeChange(
                                                                            'unloading_datetime_finished',
                                                                            e.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <InputField
                                                        id="unloading_note"
                                                        label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                        autosize
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <InputField
                                                    id="carrier_transport_description"
                                                    label={this.props.intl.formatMessage({ id: 'fields.transportDescription' })}
                                                    autosize
                                                    rows={12}
                                                />
                                            </div>

                                            <PermissionsCheck hasAny={[PERMS_PARTS.CREATE, PERMS_PARTS.UPDATE]}>
                                                <div className="btns-form text-right">
                                                    <button type="submit" className="btn btn-success">
                                                        <FormattedMessage id="buttons.save" />
                                                    </button>
                                                </div>
                                            </PermissionsCheck>
                                        </Form>
                                    </PermissionsCheck>
                                </LoadingOverlay>
                            </div>

                            {this.state.addressesPicker.show && (
                                <div className="col-md-12" style={{ minHeight: '70vh' }}>
                                    <CustomerAddressesPicker onSelectAddress={this.handleSelectAddress} />
                                    <div className="wp-100 pull-left text-right m-t-sm">
                                        <button onClick={this.closeAddressesPicker} className="btn btn-default">
                                            <FormattedMessage id="buttons.back" />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        transportParts: getTransportParts(state),
        transportPartsFetching: getTransportPartsFetching(state),
        transportPartsSaving: getTransportPartsSaving(state),
        transportPartsDeleting: getTransportPartsDeleting(state),
        transportPartsError: getTransportPartsError(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TransportsParts)
)
