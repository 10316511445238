import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'

import { url, validationMessage, getPrerequisitesArray, handleCommonEditActions } from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { Form, InputField, CheckboxField, SelectField } from '../../../common/form'
import { createGetUserIdSelector, createGetCurrentUserStatusSelector, createGetUserProfileSelector } from '../selectors'
import * as usersActionCreators from '../actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'

const componentIdentifier = 'users_edit'
const prerequisites = ['user_roles']

class UserProfileEdit extends Component {
    defaultValues = {}

    validationRules = {
        first_name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        last_name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        email: [
            value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
            value => !validator.isEmail(String(value)) && validationMessage('isEmail'),
        ],
        password: (value, isEditing) =>
            (!validator.isEmpty(String(value)) || isEditing) &&
            !validator.isLength(String(value), { min: 6 }) &&
            validationMessage('isLength', { min: 6 }),
        rgb_text: value =>
            !validator.isEmpty(String(value)) &&
            !validator.isLength(String(value), { min: 7, max: 7 }) &&
            validationMessage('isLength', { min: 7, max: 7 }),
        rgb_bg: value =>
            !validator.isEmpty(String(value)) &&
            !validator.isLength(String(value), { min: 7, max: 7 }) &&
            validationMessage('isLength', { min: 7, max: 7 }),
    }

    state = {
        values: this.defaultValues,
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave()
        })
    }

    handleSave = () => {
        this.props.actions.saveUser(this.state.values)
    }

    componentDidMount() {
        this.props.userId && this.props.actions.fetchUser(this.props.userId)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {})

        if (Boolean(nextProps.profile) && (JSON.stringify(nextProps.profile) !== JSON.stringify(this.props.profile) || !this.state.values.id)) {
            const newValues = nextProps.profile.toJS()[nextProps.userId]

            if (Boolean(newValues) && Boolean(newValues.notifications)) {
                JSON.parse(newValues.notifications).forEach(notification => (newValues[`notifications_${notification}`] = true))
            }

            this.setValues(newValues)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.users.heading' }))
        const notificationTypes = [
            'use_system',
            'general_notification',
            'transports_load_arrived',
            'transports_load_wait',
            'transports_load_finished',
            'transports_unload_arrival',
            'transports_unload_wait',
            'transports_unload_finished',
            'transports_state_changed_processing',
            'transports_state_changed_delivered',
            'transporeon_offers',
        ]

        return (
            <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                <div id="main-wrapper">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4">
                            <div className="panel panel-white">
                                <div className="panel-body panel-padding">
                                    <div className="panel-head">
                                        <h4>
                                            <FormattedMessage id="fields.itemEdit" /> -{' '}
                                            {this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching') ? (
                                                <FormattedMessage id="fields.itemFetching" />
                                            ) : (
                                                this.state.values.first_name + ' ' + this.state.values.last_name
                                            )}
                                        </h4>
                                    </div>
                                    <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                                        <Form
                                            values={this.state.values}
                                            validationRules={this.validationRules}
                                            onChange={this.handleChangeValues}
                                            onSubmit={this.handleSubmit}
                                        >
                                            <InputField
                                                id="first_name"
                                                label={this.props.intl.formatMessage({ id: 'fields.firstName' })}
                                                className="wp-50"
                                            />

                                            <InputField
                                                id="last_name"
                                                label={this.props.intl.formatMessage({ id: 'fields.lastName' })}
                                                className="wp-50"
                                            />

                                            <InputField id="email" label={this.props.intl.formatMessage({ id: 'fields.email' })} className="wp-50" />

                                            <InputField
                                                id="password"
                                                type="password"
                                                label={this.props.intl.formatMessage({ id: 'fields.password' })}
                                                className="wp-50"
                                            />

                                            <InputField id="phone" label={this.props.intl.formatMessage({ id: 'fields.phone' })} className="wp-50" />

                                            <InputField
                                                id="rgb_text"
                                                label={this.props.intl.formatMessage({ id: 'fields.textColor' })}
                                                className="wp-50"
                                            />

                                            <InputField
                                                id="rgb_bg"
                                                label={this.props.intl.formatMessage({ id: 'fields.bgColor' })}
                                                className="wp-50"
                                            />

                                            <CheckboxField id="is_admin" label={this.props.intl.formatMessage({ id: 'fields.isAdmin' })} />

                                            <SelectField
                                                id="user_role_id"
                                                label={this.props.intl.formatMessage({ id: 'fields.userRole' })}
                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('user_roles'), 'id', 'name')}
                                                className="wp-50"
                                                nullable
                                            />

                                            <hr />

                                            <div className="col-xs-12">
                                                <h4>
                                                    <FormattedMessage id="fields.notificationSettings" />
                                                </h4>
                                            </div>

                                            {notificationTypes.map(notification => (
                                                <CheckboxField
                                                    id={`notifications_${notification}`}
                                                    key={`notifications_${notification}`}
                                                    label={this.props.intl.formatMessage({ id: `fields.notifications_${notification}` })}
                                                    className="wp-50"
                                                />
                                            ))}

                                            <div className="btns-form">
                                                <Link to={url(this.props.match, 'users')} className="btn btn-addon btn-default">
                                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                </Link>
                                                <button type="submit" className="btn btn-addon btn-success pull-right">
                                                    <i className="far fa-check" /> <FormattedMessage id="buttons.save" />
                                                </button>
                                            </div>
                                        </Form>
                                    </LoadingOverlay>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userId: createGetUserIdSelector()(state),
        profile: createGetUserProfileSelector()(state),
        status: createGetCurrentUserStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...usersActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserProfileEdit))
