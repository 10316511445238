import * as actionTypes from './actionTypes'

// Fetch

export const fetchVehicleDocuments = () => ({
    type: actionTypes.FETCH,
})

export const fetchVehicleDocumentsForMap = () => ({
    type: actionTypes.FETCH_FOR_MAP,
})

export const fetchVehicleDocumentsFulfilled = vehicleDocuments => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: vehicleDocuments,
})

export const fetchVehicleDocumentsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchVehicleDocumentsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchVehicleDocument = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchVehicleDocumentFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchVehicleDocumentRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchVehicleDocumentCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveVehicleDocument = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveVehicleDocumentFulfilled = vehicleDocument => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: vehicleDocument,
})

export const saveVehicleDocumentRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveVehicleDocumentDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteVehicleDocument = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteVehicleDocumentFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteVehicleDocumentRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Clear

export const clearVehicleDocuments = () => ({
    type: actionTypes.CLEAR,
})
// Fetch files

export const fetchVehicleDocumentFiles = id => ({
    type: actionTypes.FETCH_FILES,
    payload: id,
})

export const fetchVehicleDocumentFilesFulfilled = files => ({
    type: actionTypes.FETCH_FILES_FULFILLED,
    payload: files,
})

export const fetchVehicleDocumentFilesRejected = message => ({
    type: actionTypes.FETCH_FILES_REJECTED,
    payload: message,
})

export const fetchVehicleDocumentFilesCancelled = () => ({
    type: actionTypes.FETCH_FILES_CANCELLED,
})

// Upload files

export const uploadVehicleDocumentFiles = (id, files) => ({
    type: actionTypes.UPLOAD_FILES,
    payload: {
        id,
        files,
    },
})

export const uploadVehicleDocumentFilesFulfilled = files => ({
    type: actionTypes.UPLOAD_FILES_FULFILLED,
    payload: files,
})

export const uploadVehicleDocumentFilesRejected = message => ({
    type: actionTypes.UPLOAD_FILES_REJECTED,
    payload: message,
})

export const uploadVehicleDocumentFilesCancelled = () => ({
    type: actionTypes.UPLOAD_FILES_CANCELLED,
})

// Delete file

export const deleteVehicleDocumentFile = (id, file_id) => ({
    type: actionTypes.DELETE_FILE,
    payload: {
        id,
        file_id,
    },
})

export const deleteVehicleDocumentFileFulfilled = files => ({
    type: actionTypes.DELETE_FILE_FULFILLED,
    payload: files,
})

export const deleteVehicleDocumentFileRejected = message => ({
    type: actionTypes.DELETE_FILE_REJECTED,
    payload: message,
})

export const deleteVehicleDocumentFileCancelled = () => ({
    type: actionTypes.DELETE_FILE_CANCELLED,
})

// Export

export const exportVehicleDocuments = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
