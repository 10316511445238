// Fetch list

export const FETCH = 'customerAddresses.FETCH'
export const FETCH_STARTED = 'customerAddresses.FETCH_STARTED'
export const FETCH_FULFILLED = 'customerAddresses.FETCH_FULFILLED'
export const FETCH_REJECTED = 'customerAddresses.FETCH_REJECTED'
export const FETCH_CANCELLED = 'customerAddresses.FETCH_CANCELLED'

export const SORTING_CHANGED = 'customerAddresses.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'customerAddresses.FETCH_ONE'
export const FETCH_ONE_STARTED = 'customerAddresses.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'customerAddresses.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'customerAddresses.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'customerAddresses.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'customerAddresses.SAVE'
export const SAVE_STARTED = 'customerAddresses.SAVE_STARTED'
export const SAVE_FULFILLED = 'customerAddresses.SAVE_FULFILLED'
export const SAVE_REJECTED = 'customerAddresses.SAVE_REJECTED'
export const SAVE_CANCELLED = 'customerAddresses.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'customerAddresses.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'customerAddresses.DELETE'
export const DELETE_STARTED = 'customerAddresses.DELETE_STARTED'
export const DELETE_FULFILLED = 'customerAddresses.DELETE_FULFILLED'
export const DELETE_REJECTED = 'customerAddresses.DELETE_REJECTED'
export const DELETE_CANCELLED = 'customerAddresses.DELETE_CANCELLED'

// Restore

export const RESTORE = 'customerAddresses.RESTORE'
export const RESTORE_STARTED = 'customerAddresses.RESTORE_STARTED'
export const RESTORE_FULFILLED = 'customerAddresses.RESTORE_FULFILLED'
export const RESTORE_REJECTED = 'customerAddresses.RESTORE_REJECTED'
export const RESTORE_CANCELLED = 'customerAddresses.RESTORE_CANCELLED'

// Export

export const EXPORT = 'customerAddresses.EXPORT'
export const EXPORT_FULFILLED = 'customerAddresses.EXPORT_FULFILLED'

// Find

export const FIND = 'customerAddresses.FIND'
export const FIND_STARTED = 'customerAddresses.FIND_STARTED'
export const FIND_FULFILLED = 'customerAddresses.FIND_FULFILLED'
export const FIND_REJECTED = 'customerAddresses.FIND_REJECTED'
export const FIND_CANCELLED = 'customerAddresses.FIND_CANCELLED'

// Fetch import headers

export const FETCH_IMPORT_HEADERS = 'customerAddresses.FETCH_IMPORT_HEADERS'
export const FETCH_IMPORT_HEADERS_STARTED = 'customerAddresses.FETCH_IMPORT_HEADERS_STARTED'
export const FETCH_IMPORT_HEADERS_FULFILLED = 'customerAddresses.FETCH_IMPORT_HEADERS_FULFILLED'
export const FETCH_IMPORT_HEADERS_REJECTED = 'customerAddresses.FETCH_IMPORT_HEADERS_REJECTED'
export const FETCH_IMPORT_HEADERS_CANCELLED = 'customerAddresses.FETCH_IMPORT_HEADERS_CANCELLED'

// Fetch import values

export const FETCH_IMPORT_ITEMS = 'customerAddresses.FETCH_IMPORT_ITEMS'
export const FETCH_IMPORT_ITEMS_STARTED = 'customerAddresses.FETCH_IMPORT_ITEMS_STARTED'
export const FETCH_IMPORT_ITEMS_FULFILLED = 'customerAddresses.FETCH_IMPORT_ITEMS_FULFILLED'
export const FETCH_IMPORT_ITEMS_REJECTED = 'customerAddresses.FETCH_IMPORT_ITEMS_REJECTED'
export const FETCH_IMPORT_ITEMS_CANCELLED = 'customerAddresses.FETCH_IMPORT_ITEMS_CANCELLED'

// Import

export const IMPORT_CUSTOMER_ADDRESSES = 'customerAddresses.IMPORT_CUSTOMER_ADDRESSES'
export const IMPORT_CUSTOMER_ADDRESSES_STARTED = 'customerAddresses.IMPORT_CUSTOMER_ADDRESSES_STARTED'
export const IMPORT_CUSTOMER_ADDRESSES_FULFILLED = 'customerAddresses.IMPORT_CUSTOMER_ADDRESSES_FULFILLED'
export const IMPORT_CUSTOMER_ADDRESSES_REJECTED = 'customerAddresses.IMPORT_CUSTOMER_ADDRESSES_REJECTED'
export const IMPORT_CUSTOMER_ADDRESSES_CANCELLED = 'customerAddresses.IMPORT_CUSTOMER_ADDRESSES_CANCELLED'
