import { Record } from 'immutable'

export class CarrierContact extends Record({
    id: undefined,
    name: undefined,
    position: undefined,
    phone: undefined,
    email: undefined,
    note: undefined,
}) {}
