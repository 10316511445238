import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import classnames from 'classnames'

import { DispatchersBox } from '../../../common/colored_box'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'

import * as reportCostCenterResultsActionCreators from '../actionCreators'
import { getData, getFetching } from '../selectors'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import {TableFilterCheckboxField, TableFilterInputField, TableFilterSelectField} from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'report_vehicle_kilometers_total'

const componentIdentifier = 'report_vehicle_kilometers_total'
const prerequisites = ['dispatchers', 'company_cost_centers']

class ReportVehicleKilometersTotal extends Component {
    refresh = () => {
        this.props.actions.fetchReportVehicleKilometersTotal(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportVehicleKilometersTotal(this.props.tableModel.get('filters').toJS())
    }

    handleFilterChanged = (momentFrom, momentTo) => {
        this.props.table.changeFilters([
            {
                column: 'date_from',
                type: TableModelColumnFilteringType.equal,
                value: momentFrom.format('DD.MM.YYYY'),
            },
            {
                column: 'date_to',
                type: TableModelColumnFilteringType.equal,
                value: momentTo.format('DD.MM.YYYY'),
            },
        ])
    }

    componentDidMount() {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.setConfiguration(
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ],
            false
        )

        this.props.actions.fetchReportVehicleKilometersTotal(this.props.tableModel.get('filters'))
        this.props.actions.fetchPrerequisites()
    }

    componentWillUnmount() {
        this.props.actions.clearReportVehicleKilometersTotal()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportVehicleKilometersTotal.heading' }))

        const data = this.props.data
        const filters = this.props.tableModel.get('filters')

        const stringFrom = filters.getIn(['date_from', 'value'])
        const stringTo = filters.getIn(['date_to', 'value'])

        const momentToday = moment()
        const momentFrom = stringFrom ? helpers.convertCSDateToMoment(stringFrom) : moment().startOf('month')
        const momentTo = stringTo ? helpers.convertCSDateToMoment(stringTo) : moment().endOf('month')

        const momentDays = []
        const totalDays = {}
        for (let i = 0; i <= momentTo.diff(momentFrom, 'days'); i++) {
            const momentDay = moment(momentFrom).add(i, 'days')
            momentDays.push(momentDay)
            totalDays[momentDay.format('YYYY-MM-DD')] = 0
        }

        let total = 0

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportVehicleKilometersTotal.heading" />
                                        </span>
                                    </h4>
                                    <div className="pull-left" style={{ marginLeft: '100px' }}>
                                    <span className="pull-left text-gray f-bold" style={{ paddingTop: '10px' }}>
                                        <FormattedMessage id="fields.showIncl" />:
                                    </span>
                                        <div className="pull-left m-l-sm">
                                            <TableFilterCheckboxField
                                                identifier="with_empty"
                                                type={TableModelColumnFilteringType.bool}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                label={this.props.intl.formatMessage({ id: 'fields.showInclEmpty' })}
                                            />
                                        </div>
                                        <div className="pull-left m-l-sm">
                                            <TableFilterCheckboxField
                                                identifier="with_inactive"
                                                type={TableModelColumnFilteringType.bool}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                label={this.props.intl.formatMessage({ id: 'fields.showInclInactive' })}
                                            />
                                        </div>
                                    </div>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className="m-r-lg"
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_MONTH}
                                            onChange={(momentFrom, momentTo) => this.handleFilterChanged(momentFrom, momentTo)}
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.vehicle" />
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-80">
                                                    <FormattedMessage id="fields.costCenter" />
                                                </th>
                                                {momentDays.map(momentDay => (
                                                    <th key={`day-th-${momentDay.format('YYYY-MM-DD')}`} className="w-50 text-center">
                                                        {momentDay.format('DD/MM')}
                                                    </th>
                                                ))}
                                                <th className="w-max wm-100 text-right">
                                                    <FormattedMessage id="fields.total" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-120">
                                                    <TableFilterInputField
                                                        identifier="vehicle_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterSelectField
                                                        identifier="dispatcher_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                    />
                                                </th>
                                                <th className="w-80">
                                                    <TableFilterSelectField
                                                        identifier="company_cost_center_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={helpers.getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('company_cost_centers')
                                                        )}
                                                    />
                                                </th>
                                                {momentDays.map(momentDay => (
                                                    <th key={`day-th-filter-${momentDay.format('YYYY-MM-DD')}`} className="w-50" />
                                                ))}
                                                <th className="w-max wm-100" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.vehicles &&
                                                data.vehicles.map(vehicle => {
                                                    let totalRow = 0

                                                    return (
                                                        <tr key={`vehicle-${vehicle.id}`}>
                                                            <td className="w-120">
                                                                <DispatchersBox dispatcher={vehicle.dispatcher}>{vehicle.registration}</DispatchersBox>
                                                            </td>
                                                            <td className="w-100">
                                                                {vehicle.dispatcher &&
                                                                    formatters.shortFullName(vehicle.dispatcher.first_name, vehicle.dispatcher.last_name)}
                                                            </td>
                                                            <td className="w-80">{vehicle.company_cost_center && vehicle.company_cost_center.code}</td>
                                                            {momentDays.map(momentDay => {
                                                                const date = momentDay.format('YYYY-MM-DD')
                                                                const km = vehicle.days[date] || 0

                                                                totalDays[date] += km
                                                                totalRow += km
                                                                total += km

                                                                const classes = classnames('w-50 text-center', {
                                                                    'is-weekend': momentDay.isoWeekday() >= 6,
                                                                    'is-today': momentDay.isSame(momentToday, 'day'),
                                                                })

                                                                return (
                                                                    <td key={`day-td-${date}`} className={classes}>
                                                                        {Math.round(km) || '-'}
                                                                    </td>
                                                                )
                                                            })}
                                                            <td className="w-max wm-100 text-right">
                                                                <strong>{formatters.distanceFormatter(totalRow)}</strong>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-300" colSpan={3}>
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                {momentDays.map(momentDay => {
                                                    const date = momentDay.format('YYYY-MM-DD')

                                                    return (
                                                        <td key={`day-td-total-${date}`} className="w-50 text-center">
                                                            <strong>{Math.round(totalDays[date]) || 0}</strong>
                                                        </td>
                                                    )
                                                })}
                                                <td className="w-max wm-100 text-right">
                                                    <strong>{formatters.distanceFormatter(total)}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        data: getData(state),
        fetching: getFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportCostCenterResultsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReportVehicleKilometersTotal))
