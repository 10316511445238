import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { dateFormatter, numberFormatter, priceFormatter } from '../../../common/formatters'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_PLANS } from '../permissions'

// eslint-disable-next-line react/prefer-stateless-function
class CustomersPlansListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick && this.props.onClick(this.props.item)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete && this.props.onDelete(this.props.item)
    }

    render() {
        const plan = this.props.item

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS_PLANS.UPDATE, PERMS_PLANS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS_PLANS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_PLANS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-180">
                    <strong>
                        {dateFormatter(plan.date_from)} - {dateFormatter(plan.date_to)}
                    </strong>
                </td>
                <td className="w-200">{plan.company_cost_center_name}</td>
                <td className="w-120 text-right">{numberFormatter(plan.count)}</td>
                <td className="w-120 text-right">{priceFormatter(plan.sales)}</td>
                <td className="w-120 text-right">{priceFormatter(plan.profit)}</td>
                <td className="w-max wm-200 wrapped">{plan.note}</td>
            </tr>
        )
    }
}

export default injectIntl(CustomersPlansListRow)
