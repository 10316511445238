import React, { Component } from 'react'
import { convertISODatetimeToCS } from '../../../common/helpers'

class UserLoginsListRow extends Component {
    render = () => (
        <tr className="row">
            <td className="w-30 text-center">
                <i className={`fas ${this.props.data.login_success === 1 ? 'fa-check text-success' : 'fa-times text-danger'}`} />
            </td>
            <td className="w-120">{convertISODatetimeToCS(this.props.data.datetime)}</td>
            <td className="w-240">{this.props.data.login}</td>
            <td className="w-240">{this.props.user && this.props.user.getFullName()}</td>
            <td className="w-140">{this.props.data.ip_address}</td>
            <td className="w-max wm-300">{this.props.data.user_agent}</td>
        </tr>
    )
}

export default UserLoginsListRow
