import React from 'react'
import { Route, Switch } from 'react-router'
import VehiclesList from './VehiclesList'
import VehiclesEdit from './VehiclesEdit'
import VehiclesHistory from './VehiclesHistory'

const Vehicles = props => (
    <Switch>
        <Route exact path={props.match.url} component={VehiclesList} />
        <Route exact path={`${props.match.url}/new`} component={VehiclesEdit} />
        <Route exact path={`${props.match.url}/history`} component={VehiclesHistory} />
        <Route exact path={`${props.match.url}/:vehicleID`} component={VehiclesEdit} />
    </Switch>
)

export default Vehicles
