const translations = {
    // Login
    'login.title': 'Fleetingo',
    'login.login': 'Přihlaste se ke svému účtu',
    'login.forgottenPassword': 'Zapomenuté heslo',
    'login.setPassword': 'Nastavit heslo',
    'login.copyright': 'Fleetingo s.r.o.',

    // Auth
    'auth.notLoggedIn': 'Přihlaste se prosím.',
    'auth.pleaseWait': 'Čekejte prosím...',

    // Top line
    'topLine.title': 'Fleetingo',
    'topLine.messages': 'Zprávy',
    'topLine.signOut': 'Odhlásit',
    'topLine.help': 'Nápověda',

    // Messages
    'messages.headline': 'Zprávy',
    'messages.find': 'Najít...',
    'messages.noMessage': 'Žádná zpráva',
    'messages.writeMessage': 'Napište zprávu...',
    'messages.send': 'Odeslat',
    'messages.fromDriver': 'Od řidiče',
    'messages.state.sending': 'Odesílá se',
    'messages.state.sent': 'Odesláno',
    'messages.state.received': 'Doručeno',
    'messages.state.read': 'Přečteno',
    'messages.state.markAsRead': 'Označit jako přečtené',
    'messages.subscribed': 'Notifikace zapnuté',
    'messages.unsubscribed': 'Notifikace vypnuté',

    // Menu
    'menuItems.transports': 'Přepravy',
    'menuItems.transportTemplates': 'Šablony',
    'menuItems.transporeonOffers': 'Transporeon nabídky',
    'menuItems.tracing': 'Trasování',
    'menuItems.transportsImport': 'Import přeprav',
    'menuItems.transportsImportPrices': 'Import cen',
    'menuItems.dispatcherBoard': 'Dispečerská plachta',
    'menuItems.map': 'Mapa',
    'menuItems.reports': 'Reporty',
    'menuItems.reportTransports': 'Přepravy',
    'menuItems.reportTransportDetails': 'Hospodářské výsledky',
    'menuItems.reportSpeditionTransportDetails': 'HV spedičních přeprav',
    'menuItems.reportTransportChanges': 'Sledování změn',
    'menuItems.reportTransportInvoicing': 'Fakturace',
    'menuItems.reportTransportOrders': 'Objednávky',
    'menuItems.reportVehicles': 'Vozidla',
    'menuItems.reportVehicleKilometersTotal': 'Celkové kilometry',
    'menuItems.reportVehiclePerformance': 'Výkonové ukazatele',
    'menuItems.reportVehicleTraffic': 'Provozní ukazatele',
    'menuItems.reportVehicleChecks': 'Kontroly a závady',
    'menuItems.reportVehicleFuelings': 'Tankování a spotřeba',
    'menuItems.reportVehicleResults': 'Obraty',
    'menuItems.reportVehicleProfits': 'Hospodářské výsledky',
    'menuItems.reportCustomers': 'Zákazníci',
    'menuItems.reportCustomerResults': 'Obraty',
    'menuItems.reportCustomerPlans': 'Plán přeprav',
    'menuItems.reportCustomerMonthlyPlans': 'Měsíční plán',
    'menuItems.reportCustomerWeeklyPlans': 'Týdenní plán',
    'menuItems.reportCustomerSpeditions': 'Spediční přepravy',
    'menuItems.reportCostCenters': 'Nákladová střediska',
    'menuItems.reportCostCenterResults': 'Obraty',
    'menuItems.reportCostCenterTraffic': 'Provozní ukazatele',
    'menuItems.reportCostCenterPerformance': 'Výkonové ukazatele',
    'menuItems.reportCostCenterProfits': 'Hospodářské výsledky',
    'menuItems.reportCostCenterCost': 'Náklady',
    'menuItems.reportOther': 'Ostatní',
    'menuItems.reportCustomerTurnovers': 'Zákazníci',
    'menuItems.reportEmptyKilometers': 'Nájezdové kilometry',
    'menuItems.reportPallets': 'Paletová evidence',
    'menuItems.reportInvoicingSummary': 'Sumář',
    'menuItems.reportDrivers': 'Řidiči',
    'menuItems.reportDriverAllowances': 'Stravné a odměny',
    'menuItems.reportDriverPerformanceAnalysis': 'Analýza výkonu',
    'menuItems.reportDriverHourlyOverview': 'Hodinový přehled',
    'menuItems.reportDriverTimesheet': 'Pracovní výkaz',
    'menuItems.reportDriverResults': 'Obraty',
    'menuItems.reportTrailers': 'Návěsy',
    'menuItems.reportTrailerResults': 'Obraty',
    'menuItems.reportTrailerFuelings': 'Tankování',
    'menuItems.reportDriverCosts': 'Náklady',
    'menuItems.reportTrailerCosts': 'Náklady',
    'menuItems.reportVehicleCosts': 'Náklady',
    'menuItems.reportCostCenterCosts': 'Náklady',
    'menuItems.reportCarriers': 'Spediční dopravci',
    'menuItems.reportCarrierResults': 'Náklady',
    'menuItems.reportCarrierScoring': 'Hodnocení',
    'menuItems.reportCarrierUsage': 'Využití',
    'menuItems.reportDispatchers': 'Dispečeři',
    'menuItems.reportDispatcherKPI': 'KPI',
    'menuItems.reportAppUsage': 'Využití aplikace',
    'menuItems.reportResults': 'Obraty',
    'menuItems.reportResultsCostCenters': 'Nákladová střediska',
    'menuItems.reportResultsVehicles': 'Vozidla',
    'menuItems.reportResultsDrivers': 'Řidiči',
    'menuItems.reportResultsTrailers': 'Návěsy',
    'menuItems.reportCosts': 'Náklady',
    'menuItems.reportCostsCostCenters': 'Nákladová střediska',
    'menuItems.reportCostsVehicles': 'Vozidla',
    'menuItems.reportCostsTrailers': 'Návěsy',
    'menuItems.reportCostsDrivers': 'Řidiči',
    'menuItems.reportCostsCostTypes': 'Dle typu',
    'menuItems.fleet': 'Vozový park',
    'menuItems.vehicles': 'Vozidla',
    'menuItems.trailers': 'Návěsy',
    'menuItems.vehicleEvents': 'Události',
    'menuItems.vehicleFuelings': 'Tankování',
    'menuItems.vehiclePositions': 'Pozice',
    'menuItems.drivers': 'Řidiči',
    'menuItems.addressBook': 'Adresář',
    'menuItems.customers': 'Zákazníci',
    'menuItems.customerAddresses': 'Adresy',
    'menuItems.carriers': 'Spediční dopravci',
    'menuItems.borderCrossings': 'Hraniční přechody',
    'menuItems.settings': 'Nastavení',
    'menuItems.users': 'Uživatelé',
    'menuItems.userRoles': 'Uživatelské role',
    'menuItems.userLogins': 'Log přihlášení',
    'menuItems.blockedIps': 'Blokované IP adresy',
    'menuItems.countries': 'Země',
    'menuItems.currencies': 'Měny',
    'menuItems.companies': 'Společnosti',
    'menuItems.transportTypes': 'Typy přeprav',
    'menuItems.vehicleTypes': 'Typy vozidel',
    'menuItems.trailerTypes': 'Typy návěsů',
    'menuItems.cargoTypes': 'Typy nákladů',
    'menuItems.surchargeTypes': 'Typy příplatků',
    'menuItems.customerAddressTypes': 'Typy adres',
    'menuItems.goodsTypes': 'Typy zboží',
    'menuItems.documentTypes': 'Typy dokumentů',
    'menuItems.driverDocuments': 'Dokumenty',
    'menuItems.vehicleDocuments': 'Dokumenty',
    'menuItems.trailerDocuments': 'Dokumenty',
    'menuItems.driverHistory': 'Historie',
    'menuItems.vehicleHistory': 'Historie',
    'menuItems.trailerHistory': 'Historie',
    'menuItems.equipments': 'Vybavení',
    'menuItems.driverEquipments': 'Vybavení',
    'menuItems.vehicleEquipments': 'Vybavení',
    'menuItems.trailerEquipments': 'Vybavení',
    'menuItems.driverTimesheets': 'Pracovní výkazy',
    'menuItems.taxRates': 'Daňové sazby',
    'menuItems.paymentMethods': 'Platební metody',
    'menuItems.invoicing': 'Fakturace',
    'menuItems.invoicingOrders': 'Objednávky',
    'menuItems.invoicingInvoices': 'Faktury vydané',
    'menuItems.invoicingReceivedInvoices': 'Faktury přijaté',
    'menuItems.invoicingPayments': 'Platby',
    'menuItems.packagingTypes': 'Typy obalů',
    'menuItems.transportConditions': 'Přepravní podmínky',
    'menuItems.invoiceItemTypes': 'Typy pohybů',
    'menuItems.general': 'Obecné',
    'menuItems.transportScoring': 'Vyhodnocení přeprav',
    'menuItems.carrierScoring': 'Vyhodnocení dopravců',
    'menuItems.generalSettings': 'Obecné nastavení',
    'menuItems.dietRates': 'Sazby stravného',
    'menuItems.costTypes': 'Typy nákladů',
    'menuItems.cost': 'Náklady',
    'menuItems.costs': 'Náklady',
    'menuItems.costGenerators': 'Opakované náklady',
    'menuItems.salesTypes': 'Typy tržeb',
    'menuItems.driversTypes': 'Typy řidičů',
    'menuItems.sales': 'Tržby',
    'menuItems.driverSurchargeTypes': 'Typy příplatků',
    'menuItems.driverSurcharges': 'Příplatky',
    'menuItems.reportDriverSurcharges': 'Příplatky',
    'menuItems.warehousing': 'Sklad',
    'menuItems.units': 'Jednotky',
    'menuItems.warehousingWarehouses': 'Sklady',
    'menuItems.warehousingItems': 'Skladové karty',
    'menuItems.warehousingReceipts': 'Příjemky',
    'menuItems.warehousingIssues': 'Výdejky',
    'menuItems.service': 'Servis',
    'menuItems.serviceJobs': 'Servisní úkony',
    'menuItems.serviceWorkers': 'Servisní technici',
    'menuItems.serviceOrders': 'Servisní objednávky',

    // Fields
    'fields.showWithTrashed': 'Zobrazit včetně smazaných',
    'fields.showOnlyTrashed': 'Zobrazit pouze smazané',
    'fields.hoursCount': 'Počet hodin',
    'fields.ignition': 'Zapalování',
    'fields.motor': 'Motor',
    'fields.showInclEmpty': 'nulových',
    'fields.showInclInactive': 'neaktivních',
    'fields.showIncl': 'Zobrazit včetně',
    'fields.settings': 'Nastavení',
    'fields.invoice_footer': 'Patička faktury',
    'fields.transport_footer': 'Patička nabídky/objednávky přepravy',
    'fields.email': 'E-mail',
    'fields.password': 'Heslo',
    'fields.findByAddress': 'Najít adresu',
    'fields.findByGPS': 'Najít GPS',
    'fields.yes': 'Ano',
    'fields.no': 'Ne',
    'fields.number': 'Číslo',
    'fields.documentNumber': 'Číslo dokladu',
    'fields.createdAt': 'Čas vytvoření',
    'fields.state': 'Stav',
    'fields.disponent': 'Disponent',
    'fields.customer': 'Zákazník',
    'fields.transportType': 'Typ přepravy',
    'fields.cargoType': 'Typ nákladu',
    'fields.goodsType': 'Typ zboží',
    'fields.loading': 'Nakládka',
    'fields.loadingDate': 'Datum nakládky',
    'fields.loadingTime': 'Čas nakládky',
    'fields.loadingDateTime': 'Datum a čas nakládky',
    'fields.loadingAddress': 'Adresa nakládky',
    'fields.loadingCity': 'Město nakládky',
    'fields.loadingCountry': 'Země nakládky',
    'fields.unloading': 'Vykládka',
    'fields.unloadingDate': 'Datum vykládky',
    'fields.unloadingTime': 'Čas vykládky',
    'fields.unloadingDateTime': 'Datum a čas vykládky',
    'fields.unloadingAddress': 'Adresa vykládky',
    'fields.unloadingCity': 'Město vykládky',
    'fields.unloadingCountry': 'Země vykládky',
    'fields.address': 'Adresa',
    'fields.ipAddress': 'IP adresa',
    'fields.cidr': 'CIDR',
    'fields.userAgent': 'User agent',
    'fields.time': 'Čas',
    'fields.from': 'Od',
    'fields.fromPlace': 'Odkud',
    'fields.to': 'Do',
    'fields.toPlace': 'Kam',
    'fields.code': 'Kód',
    'fields.dateBoarding': 'Datum nástupu',
    'fields.vehicle': 'Vozidlo',
    'fields.registration': 'SPZ',
    'fields.dispatcher': 'Dispečer',
    'fields.trailer': 'Návěs',
    'fields.driver': 'Řidič',
    'fields.price': 'Cena',
    'fields.pricePerKm': 'Cena / KM',
    'fields.priceCZK': 'Cena CZK',
    'fields.profitKM': 'Výnosy / KM',
    'fields.profitKMCZK': 'CZK / KM',
    'fields.costsKM': 'Náklady / KM',
    'fields.costsCZK': 'Náklady CZK',
    'fields.sentAt': 'Čas odeslání',
    'fields.approach': 'Nájezd',
    'fields.approachDateTime': 'Datum a čas nájezdu',
    'fields.plannedApproach': 'Plánovaný nájezd',
    'fields.approachFormTransportNumber': 'Nájezd z přepravy č.',
    'fields.difference': 'Rozdíl',
    'fields.arrival': 'Příjezd',
    'fields.eta': 'ETA',
    'fields.note': 'Poznámka',
    'fields.orderNumber': 'Číslo obj.',
    'fields.spedition': 'Spedice',
    'fields.autoCabotage': 'Automaticky',
    'fields.cabotage': 'Kabotáž',
    'fields.trailerRegistration': 'SPZ návěsu',
    'fields.vehicleRegistration': 'SPZ vozidla',
    'fields.driverName': 'Jméno řidiče',
    'fields.driverPhone': 'Telefon na řidiče',
    'fields.unknownTrailer': 'Neznámý návěs',
    'fields.archived': 'Archivováno',
    'fields.carrier': 'Dopravce',
    'fields.basePrice': 'Základní cena',
    'fields.totalPrice': 'Celková cena',
    'fields.totalDistance': 'Celková vzdálenost',
    'fields.odometerState': 'Stav km (poč./kon.)',
    'fields.odometerDistance': 'Rozdíl (poč./kon.)',
    'fields.totalConsumption': 'Celková spotřeba',
    'fields.avgConsumption': 'Průměrná spotřeba',
    'fields.fueling': 'Tankováno',
    'fields.log': 'Log změn',
    'fields.date': 'Datum',
    'fields.dateTime': 'Datum a čas',
    'fields.user': 'Uživatel',
    'fields.changes': 'Změny',
    'fields.pcs': 'Počet ks',
    'fields.pcsToLoad': 'Množství k naložení',
    'fields.pcsToUnload': 'Množství k vyložení',
    'fields.weight': 'Hmotnost',
    'fields.temperature': 'Teplota',
    'fields.temperatureFrom': 'Teplota od',
    'fields.temperatureTo': 'Teplota do',
    'fields.palletExchange': 'Paletová výměna',
    'fields.palletExchangeIssued': 'Vydaných palet',
    'fields.palletExchangeReceived': 'Přijatých palet',
    'fields.palletExchangeDamaged': 'Poškozených palet',
    'fields.count': 'Počet',
    'fields.userCount': 'Počet uživatelů',
    'fields.plannedCount': 'Plánovaný počet',
    'fields.fuelingType': 'Množství',
    'fields.fuelingTypeFull': 'Do plna',
    'fields.fuelingTypeMinimal': 'Na dojezd',
    'fields.phone': 'Telefon',
    'fields.reason': 'Důvod',
    'fields.defect': 'Závada',
    'fields.defectDescription': 'Popis závady',
    'fields.plannedEndTime': 'Plánovaný konec',
    'fields.vehicleDefect': 'Závada na vozidle',
    'fields.vehicleDefectDescription': 'Popis závady na vozidle',
    'fields.trailerDefect': 'Závada na návěsu',
    'fields.trailerDefectDescription': 'Popis závady na návěsu',
    'fields.documentsDefect': 'Závada v dokumentech',
    'fields.documentsDefectDescription': 'Popis závady v dokumentech',
    'fields.withObjection': 'S výhradou',
    'fields.sourceTransport': 'Zdrojová přeprava',
    'fields.template': 'Šablona',
    'fields.transporeonOffersNumber': 'SCI',
    'fields.pointType': 'Typ bodu',
    'fields.pointName': 'Název bodu',
    'fields.eventType': 'Typ události',
    'fields.transportState': 'Stav přepravy',
    'fields.customersOrderNumber': 'Číslo obj. (zákazník)',
    'fields.customersPrice': 'Cena (zákazník)',
    'fields.customersCurrency': 'Měna (zákazník)',
    'fields.customersNote': 'Poznámka (zákazník)',
    'fields.vehicleNote': 'Poznámka (vozidlo)',
    'fields.carriersOrderNumber': 'Číslo obj. (dopravce)',
    'fields.carriersPrice': 'Cena (dopravce)',
    'fields.carriersCurrency': 'Měna (dopravce)',
    'fields.carriersNote': 'Poznámka (dopravce)',
    'fields.predepartureCheck': 'Kontrola před odjezdem',
    'fields.ordering': 'Pořadí',
    'fields.plannedTime': 'Plánovaný čas',
    'fields.plannedTimeFrom': 'Plánovaný čas od',
    'fields.plannedTimeTo': 'Plánovaný čas do',
    'fields.specifiedTime': 'Upřesněný čas',
    'fields.specifiedTimeFrom': 'Upřesněný čas od',
    'fields.specifiedTimeTo': 'Upřesněný čas do',
    'fields.bookingTime': 'Čas rezervace',
    'fields.bookingTimeFrom': 'Čas rezervace od',
    'fields.bookingTimeTo': 'Čas rezervace do',
    'fields.arrivalTime': 'Čas příjezdu',
    'fields.finishedTime': 'Čas dokončení',
    'fields.companyName': 'Název firmy',
    'fields.customerName': 'Název firmy / jméno zákazníka',
    'fields.carrierName': 'Název firmy / jméno dopravce',
    'fields.street': 'Ulice',
    'fields.city': 'Město',
    'fields.zipcode': 'PSČ',
    'fields.country': 'Země',
    'fields.gpsLat': 'GPS lat',
    'fields.gpsLng': 'GPS lng',
    'fields.driver2': 'Řidič 2',
    'fields.mileage': 'Stav km',
    'fields.gps': 'GPS',
    'fields.attachments': 'Přílohy',
    'fields.transport': 'Přeprava',
    'fields.transportNo': 'Přeprava č.',
    'fields.plannedKilometers': 'Plánované kilometry',
    'fields.realKilometers': 'Skutečné kilometry',
    'fields.times': 'Časy',
    'fields.totalTime': 'Čas celkem',
    'fields.planned': 'Plánovaný',
    'fields.specified': 'Upřesněný',
    'fields.booking': 'Rezervace',
    'fields.finish': 'Dokončení',
    'fields.details': 'Podrobnosti',
    'fields.event': 'Událost',
    'fields.incorrectTime': 'Chybný čas',
    'fields.duration': 'Trvání',
    'fields.hours': 'h',
    'fields.hoursVerbally': 'Hodin',
    'fields.daysVerbally': 'Dnů',
    'fields.minutes': 'm',
    'fields.minutesVerbally': 'Minut',
    'fields.eg': 'např.',
    'fields.addressType': 'Typ adresy',
    'fields.name': 'Název',
    'fields.plannedRoute': 'Plánovaná trasa',
    'fields.specifiedRoute': 'Upřesněná trasa',
    'fields.realRoute': 'Skutečná trasa',
    'fields.routeDetails': 'Podrobnosti o trase',
    'fields.distance': 'Vzdálenost',
    'fields.transportDuration': 'Čas přepravy',
    'fields.requiredTime': 'Požadovaný čas',
    'fields.drive': 'Jízda',
    'fields.pause': 'Stání',
    'fields.rests': 'Odpočinky',
    'fields.breaks': 'Přestávky',
    'fields.priceAndCosts': 'Cena a náklady',
    'fields.totalCosts': 'Celkové náklady',
    'fields.fuel': 'Palivo',
    'fields.adblue': 'AdBlue',
    'fields.fuel_level': 'Palivo',
    'fields.adblue_level': 'AdBlue',
    'fields.fuelingCompany': 'Dodavatelská čerpací stanice',
    'fields.fuelingCompanyName': 'Název dodavatelské čerpací stanice',
    'fields.toll': 'Mýtné',
    'fields.tollPasses': 'Placené průjezdy',
    'fields.fixCosts': 'Fixní náklady',
    'fields.templateName': 'Název šablony',
    'fields.route': 'Trasa',
    'fields.lenght': 'Délka',
    'fields.volume': 'Objem',
    'fields.trailerNote': 'Poznámka (návěs)',
    'fields.informationUnavailable': 'Informace nedostupná',
    'fields.vehicleIsMoving': 'Vozidlo jede',
    'fields.vehicleIsMovingAt': 'Vozidlo jede rychlostí',
    'fields.vehicleIsStanding': 'Vozidlo stojí',
    'fields.updatedAt': 'Čas aktualizace',
    'fields.etaUpdatedAt': 'Čas aktualizace ETA',
    'fields.trailerLoaded': 'Návěs naložen',
    'fields.trailerEmpty': 'Návěs prázdný',
    'fields.trailerDamage': 'Poškození návěsu',
    'fields.trailerOk': 'Návěs v pořádku',
    'fields.trailerDamaged': 'Návěs poškozen',
    'fields.security': 'Zabezpečení návěsu',
    'fields.securityOk': 'Zabezpečení v pořádku',
    'fields.securityDamaged': 'Zabezpečení poškozeno',
    'fields.securedTrailers': 'Zabezpečené návěsy',
    'fields.unsecuredTrailers': 'Nezabezpečené návěsy',
    'fields.trailerCooling': 'Chlazení návěsu',
    'fields.coolingOk': 'Chlazení v pořádku',
    'fields.coolingDamaged': 'Chlazení poškozeno',
    'fields.cooledTrailers': 'Chlazené návěsy',
    'fields.notCooledTrailers': 'Nechlazené návěsy',
    'fields.week': 'Týden',
    'fields.previousWeek': 'Předchozí týden',
    'fields.nextWeek': 'Následující týden',
    'fields.previousDay': 'Předchozí den',
    'fields.day': 'Den',
    'fields.day4': 'dní',
    'fields.nextDay': 'Následující den',
    'fields.year': 'Rok',
    'fields.previousYear': 'Předchozí rok',
    'fields.nextYear': 'Následující rok',
    'fields.month': 'Měsíc',
    'fields.previousMonth': 'Předchozí měsíc',
    'fields.nextMonth': 'Následující měsíc',
    'fields.company': 'Společnost',
    'fields.costCenter': 'Nákladové středisko',
    'fields.costCenters': 'Nákladová střediska',
    'fields.total': 'Celkem',
    'fields.mean': 'Průměr',
    'fields.km': 'KM',
    'fields.kmFull': 'Plné',
    'fields.kmApproach': 'Nájezdové',
    'fields.kmService': 'Servisní',
    'fields.kmEmpty': 'Prázdné',
    'fields.approachFrom': 'Nájezd z',
    'fields.approachTo': 'Nájezd do',
    'fields.loaded': 'Naloženo',
    'fields.unloaded': 'Vyloženo',
    'fields.balance': 'Saldo',
    'fields.checkType': 'Typ kontroly',
    'fields.defects': 'Závady',
    'fields.defectType': 'Typ závady',
    'fields.notes': 'Poznámky',
    'fields.fbId': 'FB ID',
    'fields.vin': 'VIN',
    'fields.vehicleType': 'Typ vozidla',
    'fields.vehicleState': 'Stav vozidla',
    'fields.trailerState': 'Stav návěsu',
    'fields.fbConfiguration': 'FB konfigurace',
    'fields.active': 'Aktivní',
    'fields.itemEdit': 'Upravit položku',
    'fields.itemCreate': 'Vytvořit novou položku',
    'fields.trailerType': 'Typ návěsu',
    'fields.damaged': 'Poškozeno',
    'fields.firstName': 'Jméno',
    'fields.lastName': 'Příjmení',
    'fields.fullName': 'Celé jméno',
    'fields.language': 'Jazyk',
    'fields.regNumber': 'IČO',
    'fields.vatNumber': 'DIČ',
    'fields.blocked': 'Blokováno',
    'fields.allowed': 'Povoleno',
    'fields.icon': 'Ikona',
    'fields.color': 'Barva',
    'fields.textColor': 'Barva textu',
    'fields.bgColor': 'Barva pozadí',
    'fields.isAdmin': 'Administrátor',
    'fields.userRole': 'Role',
    'fields.isoCode': 'ISO kód',
    'fields.isoCode2': 'ISO kód (2)',
    'fields.isoCode3': 'ISO kód (3)',
    'fields.currency': 'Měna',
    'fields.tollPerKm': 'Mýtné/KM',
    'fields.tollPerDay': 'Mýtné/den',
    'fields.minimumHourWageInSelectedCurrency': 'Minimální hodinová mzda (ve vybrané měně)',
    'fields.minimumHourWage': 'Minimální hodinová mzda',
    'fields.default': 'Výchozí',
    'fields.exchangeRate': 'Kurz',
    'fields.minProfit': 'Minimální zisk',
    'fields.dimensions': 'Rozměry',
    'fields.width': 'Šířka',
    'fields.height': 'Výška',
    'fields.tankVolume': 'Objem nádrže',
    'fields.axlesCount': 'Počet náprav',
    'fields.avgFuelConsumption': 'Prům. spotřeba paliva',
    'fields.avgAdBlueConsumption': 'Prům. spotřeba AdBlue',
    'fields.fuelLevel': 'Palivo [l]',
    'fields.adblueLevel': 'AdBlue [l]',
    'fields.secured': 'Zabezpečeno',
    'fields.cooled': 'Chlazeno',
    'fields.transportTypes': 'Typy přeprav',
    'fields.vehicleTypes': 'Typy vozidel',
    'fields.cargoTypes': 'Typy nákladů',
    'fields.goodsTypes': 'Typy zboží',
    'fields.securityRequired': 'Vyžadováno zabezpečení',
    'fields.coolingRequired': 'Vyžadováno chlazení',
    'fields.trailerIsNotSecured': 'Návěs není zabezpečen',
    'fields.trailerIsNotCooled': 'Návěs není chlazen',
    'fields.currentPosition': 'Aktuální poloha',
    'fields.eventCreatedByDriver': 'Vytvořeno řidičem (v DP)',
    'fields.eventCreatedByDispatcher': 'Vytvořeno dispečerem (manuálně)',
    'fields.capacityPallets': 'Počet pal. míst',
    'fields.noVehicle': 'Bez vozidla',
    'fields.noTrailer': 'Bez návěsu',
    'fields.noDriver': 'Bez řidiče',
    'fields.noTime': 'Bez času',
    'fields.trailerEquipments': 'Vybavení návěsu',
    'fields.nameInTransporeon': 'Název v Transporeonu',
    'fields.navigationStart': 'Zapnutí navigace',
    'fields.navigationStop': 'Vypnutí navigace',
    'fields.speed': 'Rychlost',
    'fields.isSpedition': 'Spediční',
    'fields.legend': 'Legenda',
    'fields.all': 'Vše',
    'fields.transportPointState': 'Stav bodu přepravy',
    'fields.transportPointType': 'Typ bodu přepravy',
    'fields.preferences': 'Nastavení',
    'fields.relevantForDrivers': 'K řidičům',
    'fields.relevantForVehicles': 'K vozidlům',
    'fields.relevantForTrailers': 'K návěsům',
    'fields.notify': 'Upozorňovat',
    'fields.notifyInList': 'Upozorňovat v přehledu',
    'fields.notifyInBoard': 'Upozorňovat v DP',
    'fields.daysInAdvance': 'Dní předem',
    'fields.documentType': 'Typ dokumentu',
    'fields.validity': 'Platnost',
    'fields.validityFrom': 'Platnost od',
    'fields.validityTo': 'Platnost do',
    'fields.validityExpiresIn': 'Vyprší za',
    'fields.abbrDays': 'd',
    'fields.invoicingAddress': 'Fakturační adresa',
    'fields.defaultInvoicingAddress': 'Výchozí fakturační adresa',
    'fields.deliveryAddress': 'Korespondenční adresa',
    'fields.contacts': 'Kontakty',
    'fields.invoicingSettings': 'Nastavení fakturace',
    'fields.defaultDeadline': 'Výchozí splatnost [dny]',
    'fields.invoicingEmail': 'Fakturační e-mail',
    'fields.invoicingNote': 'Poznámka pro fakturaci',
    'fields.limitsAndBlocking': 'Limity a blokace',
    'fields.limitUnpaidInvoices': 'Limit pro nezaplacené pohledávky [CZK]',
    'fields.limitOverdueInvoices': 'Limit pro pohledávky po splatnosti [CZK]',
    'fields.bookingRequired': 'Vyžadovaná rezervace pro nakládku/vykládku',
    'fields.bookingURL': 'URL adresa pro rezervaci',
    'fields.bookingNote': 'Poznámka k rezervaci',
    'fields.history': 'Historie',
    'fields.loginLog': 'Log přihlášení',
    'fields.documents': 'Dokumenty',
    'fields.events': 'Události',
    'fields.timesheets': 'Pracovní výkazy',
    'fields.importHistory': 'Historie importů',
    'fields.fuelings': 'Tankování',
    'fields.noItemsFound': 'Žádné nalezené záznamy',
    'fields.addresses': 'Adresy',
    'fields.shippingOrder': 'Přepravní příkaz',
    'fields.client': 'Objednavatel',
    'fields.vehicleRequirements': 'Podmínky pro přepravu / požadavky na vozidlo',
    'fields.temperatureRecorder': 'Zapisovač teplot',
    'fields.temperatureControl': 'Návěs s řízenou teplotou',
    'fields.frcCooling': 'ATP/chlazení FRC -20°C',
    'fields.dividingWall': 'Dělící stěna',
    'fields.doubleDeckerTrailer': 'Dvoupatrový návěs',
    'fields.curtain': 'Plachta',
    'fields.vehicleAndDriver': 'Vozidlo / řidič',
    'fields.vehiclePhone': 'Telefon do vozidla',
    'fields.loadingPlaces': 'Místa nakládek',
    'fields.customHouse': 'Clení',
    'fields.allowedParking': 'Povolené parkování',
    'fields.unloadingPlaces': 'Místa vykládek',
    'fields.shippingPrice': 'Přepravné',
    'fields.shippingPriceInclToll': 'Přepravné vč. mýta',
    'fields.boatBooking': 'Booking lodí',
    'fields.hotline': 'Hotline',
    'fields.attachedDocuments': 'Připojené doklady',
    'fields.otherArrangements': 'Další ujednání',
    'fields.bankAccounts': 'Bankovní účty',
    'fields.bankAccount': 'Bankovní účet',
    'fields.bankAccountNumber': 'Číslo účtu',
    'fields.bankAccountIBAN': 'IBAN',
    'fields.bankAccountSWIFT': 'SWIFT',
    'fields.bankCode': 'Kód banky',
    'fields.foundAddressesTitle': 'Nalezené adresy',
    'fields.foundAddressesText':
        'V blízkosti této lokality jsme našli níže uvedené adresy. Kliknutím vyberte tu, kterou chcete použít. Pokud si přejete uložit novou adresu, klikněte na tlačítko níže.',
    'fields.foundAddressesTextNoSave': 'V blízkosti této lokality jsme našli níže uvedené adresy. Kliknutím vyberte tu, kterou chcete použít.',
    'fields.dropzoneReady': 'Přetáhněte soubory sem, nebo klikněte pro výběr souborů k nahrání',
    'fields.dropzoneAccept': 'Přetáhněte soubory sem',
    'fields.fileType': 'Typ souboru',
    'fields.fileName': 'Název souboru',
    'fields.ImportFileName': 'Název souboru importu',
    'fields.ImportDateTime': 'Datum a čas importu',
    'fields.fileSize': 'Velikost',
    'fields.noFilesUploaded': 'Zatím nebyly nahrány žádné soubory.',
    'fields.show': 'Zobrazit',
    'fields.monthsAbbr': 'měs.',
    'fields.defaultValidity': 'Výchozí platnost',
    'fields.defaultValidityInMonths': 'Výchozí platnost [měs.]',
    'fields.defaultLifetime': 'Výchozí životnost',
    'fields.defaultLifetimeInMonths': 'Výchozí životnost [měs.]',
    'fields.equipment': 'Vybavení',
    'fields.passedAt': 'Předáno',
    'fields.lifetimeTo': 'Životnost do',
    'fields.showChangesFrom': 'Zobrazit změny od',
    'fields.showOnlyChangedTransports': 'Zobrazit jen přepravy se změnou',
    'fields.cost': 'Náklad',
    'fields.costType': 'Typ nákladu',
    'fields.salesType': 'Typ tržeb',
    'fields.costs': 'Náklady',
    'fields.profit': 'Zisk',
    'fields.profitPerc': 'Zisk %',
    'fields.plannedProfit': 'Plánovaný zisk',
    'fields.period': 'Období',
    'fields.numberings': 'Číselné řady',
    'fields.format': 'Formát',
    'fields.prefix': 'Předpona',
    'fields.separator': 'Oddělovač',
    'fields.timeFormat': 'Formát času',
    'fields.dateFormat': 'Formát datumu',
    'fields.yearFormat': 'Formát roku',
    'fields.showMonthNumber': 'Použít číslo měsíce',
    'fields.maxRange': 'Maximální rozsah',
    'fields.defaultCurrency': 'Výchozí měna',
    'fields.value': 'Hodnota',
    'fields.invoicingState': 'Stav fakturace',
    'fields.changeTo': 'Změnit na',
    'fields.supplier': 'Dodavatel',
    'fields.numbering': 'Číselná řada',
    'fields.paymentMethod': 'Platební metoda',
    'fields.dateIssue': 'Datum vystavení',
    'fields.imported': 'Importováno',
    'fields.importedAlert': 'Pozor, tato faktura již byla naimportována. Případné změny se nepřenesou.',
    'fields.dateDue': 'Datum splatnosti',
    'fields.dateTax': 'Datum UZP',
    'fields.taxTransactionType': 'Typ plnění',
    'fields.taxRateType': 'Typ daňové sazby',
    'fields.varSymbol': 'Variabilní symbol',
    'fields.unit': 'MJ',
    'fields.item': 'Položka',
    'fields.unitPrice': 'Cena MJ',
    'fields.totalExclVat': 'Celkem bez DPH',
    'fields.totalInclVat': 'Celkem vč. DPH',
    'fields.vat': 'DPH',
    'fields.invoiceNo': 'Faktura č.',
    'fields.invoicingItems': 'Fakturujeme Vám následující položky',
    'fields.dietRate': 'Sazba',
    'fields.taxRate': 'Daňová sazba',
    'fields.noTaxRate': 'Bez daňové sazby',
    'fields.base': 'Základ',
    'fields.totalToPay': 'Celkem k úhradě',
    'fields.rate': 'Kurz',
    'fields.forDay': 'ke dni',
    'fields.createdBy': 'Vytvořil',
    'fields.paid': 'Zaplaceno',
    'fields.noGoodsCreated': 'Zatím nebyla vytvořena žádná položka nákladu.',
    'fields.totalWeight': 'Celková hmotnost',
    'fields.totalVolume': 'Celkový objem',
    'fields.packagingType': 'Typ obalu',
    'fields.transportIsNotDivided': 'Přeprava zatím není rozdělena na více částí',
    'fields.ownTransport': 'Vlastní přeprava',
    'fields.transportGoods': 'Přepravované zboží',
    'fields.paymentConditions': 'Splatnost',
    'fields.transportVehicleType': 'Typ přepravního prostředku',
    'fields.boatNumber': 'Číslo lodě',
    'fields.trainNumber': 'Číslo vlaku',
    'fields.flightNumber': 'Číslo letu',
    'fields.description': 'Popis',
    'fields.type': 'Typ',
    'fields.general': 'Obecné',
    'fields.conditions': 'Podmínky',
    'fields.transportConditions': 'Přepravní podmínky',
    'fields.invoiceItemType': 'Typ pohybu',
    'fields.suppliersNumber': 'Dodavatelské číslo',
    'fields.dateReceived': 'Datum přijetí',
    'fields.datePaid': 'Datum zaplacení',
    'fields.approved': 'Schváleno',
    'fields.notApproved': 'Neschváleno',
    'fields.approve': 'Schválit',
    'fields.unpaid': 'Nezaplaceno',
    'fields.overdue': 'Po splatnosti',
    'fields.setAsPaid': 'Označit jako zaplaceno',
    'fields.setAsUnpaid': 'Zrušit zaplacení',
    'fields.creditNote': 'Dobropis',
    'fields.creditNoteNo': 'Dobropis č.',
    'fields.forInvoiceNo': 'K faktuře č.',
    'fields.inEU': 'V EU',
    'fields.files': 'Soubory',
    'fields.preloading': 'Přednakládka',
    'fields.vehicleCount': 'Počet vozidel',
    'fields.transportCount': 'Počet přeprav',
    'fields.businessManager': 'Obchodní manažer',
    'fields.costUser': 'Náklad na pracovníka',
    'fields.position': 'Pozice',
    'fields.transportPlan': 'Plán přeprav',
    'fields.speditionPlan': 'Plán spedic',
    'fields.margin': 'Marže',
    'fields.offer': 'Nabídka',
    'fields.order': 'Objednávka',
    'fields.orderConfirm': 'Potvrzení objednávky',
    'fields.sendToEmail': 'Odeslat emailem',
    'fields.correctEventTimes': 'Automatická korekce časů',
    'fields.finishQuickly': 'Dokončit zrychleně',
    'fields.allowances': 'Stravné a odměny',
    'fields.createAllowance': 'Přidat odměnu',
    'fields.allowanceAmount': 'Výše odměny',
    'fields.noAllowancesFound': 'Žádné odměny nenalezeny',
    'fields.allowanceType': 'Typ odměny',
    'fields.allowanceCalculationMethod': 'Způsob výpočtu',
    'fields.actions': 'Akce',
    'fields.differentInMinutes': 'Rozdíl [min.]',
    'fields.saveThisEvent': 'Zaznamenat',
    'fields.transportDescription': 'Popis přepravy',
    'fields.noTransportsFound': 'Žádné nalezené přepravy',
    'fields.vatPayer': 'Plátce DPH',
    'fields.factoring': 'Factoring',
    'fields.factoringCompanyName': 'Factoring: Název společnosti',
    'fields.factoringCompanyRegNumber': 'Factoring: IČO společnosti',
    'fields.email_invoicing': 'E-mailová fakturace',
    'fields.doNotInvoice': 'Nefakturovat',
    'fields.defaultBankAccountsSettings': 'Nastavení výchozích účtů',
    'fields.defaultCompanyBankAccount': 'Výchozí bankovní účet společnosti',
    'fields.defaultInvoicingBankAccounts': 'Výchozí bankovní účty pro fakturaci',
    'fields.defaultSupplier': 'Výchozí dodavatel',
    'fields.domesticCustomers': 'Tuzemští zákazníci',
    'fields.domesticDietRate': 'Tuzemská sazba',
    'fields.foreignCountryCustomers': 'Zahraniční zákazníci',
    'fields.paidAt': 'Datum zaplacení',
    'fields.paidAmount': 'Zaplacená částka',
    'fields.amount': 'Částka',
    'fields.amountCZK': 'Částka CZK',
    'fields.proportion': 'Poměr',
    'fields.proportionalAmount': 'Poměrná částka',
    'fields.scoringNoQuestions': 'Žádné otázky k vyhodnocení nenalezeny.',
    'fields.scoringNoAnswers': 'Tato otázka nemá žádné odpovědi.',
    'fields.scoringNoRules': 'Nejsou nastavena žádná pravidla pro hodnocení',
    'fields.question': 'Otázka',
    'fields.answer': 'Odpověď',
    'fields.rule': 'Pravidlo',
    'fields.pointsCount': 'Počet bodů',
    'fields.pointsCountFrom': 'Počet bodů od',
    'fields.pointsCountTo': 'Počet bodů do',
    'fields.mark': 'Známka',
    'fields.text': 'Text',
    'fields.createQuestion': 'Přidat otázku',
    'fields.createAnswer': 'Přidat odpověď',
    'fields.createRule': 'Přidat pravidlo',
    'fields.scoringNotSetYet': 'Vyhodnocení zatím nebylo provedeno',
    'fields.scoring': 'Vyhodnocení',
    'fields.calculatedFromTransportScoring': 'Počítáno z vyhodnocení přeprav',
    'fields.outgoingInvoices': 'Vydané faktury',
    'fields.receivedInvoices': 'Přijaté faktury',
    'fields.invoiced': 'Fakturováno',
    'fields.uninvoiced': 'Nefakturováno',
    'fields.showSpeditions': 'Zobrazit spedice',
    'fields.showTrailersOut': 'Zobrazit nezapřažené návěsy',
    'fields.sales': 'Tržby',
    'fields.plannedSales': 'Plánované tržby',
    'fields.minimum': 'Minimální',
    'fields.optimum': 'Optimální',
    'fields.transport_plans': 'Plány přeprav',
    'fields.transports': 'Přepravy',
    'fields.speditions': 'Spedice',
    'fields.dateFrom': 'Od',
    'fields.dateTo': 'Do',
    'fields.borderCrossing': 'Hraniční přejezd',
    'fields.activity': 'Činnost',
    'fields.dailyCost': 'Denní náklady',
    'fields.paidRoutes': 'Placené úseky',
    'fields.telematicsDataSource': 'Zdroj telematickych dat',
    'fields.telematicsDataId': 'ID telematickych dat',
    'fields.logisticDataSource': 'Zdroj logistických dat',
    'fields.logisticDataId': 'ID logistických dat',
    'fields.notificationSettings': 'Nastavení notifikací',
    'fields.itemFetching': 'nahrávám',
    'fields.notifications_use_system': 'Pro zobrazování používat systémové notifikace',
    'fields.notifications_general': 'Obecné notifikace',
    'fields.notifications_transports_load_arrived': 'Přepravy - Příjezd na nakládku (čas, č. přepravy, vozidlo, řidič, město)',
    'fields.notifications_transports_load_wait': 'Přepravy - Čekání na nakládku (+ důvod čekání)',
    'fields.notifications_transports_load_finished': 'Přepravy - Konec nakládky',
    'fields.notifications_transports_unload_arrival': 'Přepravy - Příjezd na vykládku',
    'fields.notifications_transports_unload_wait': 'Přepravy - Čekání na vykládku',
    'fields.notifications_transports_unload_finished': 'Přepravy - Konec vykládky',
    'fields.notifications_transports_state_changed_processing': 'Přepravy - Změna stavu na: Zpracovává se (č. přepravy, čas)',
    'fields.notifications_transports_state_changed_delivered': 'Přepravy - Změna stavu na: Dokončena',
    'fields.notifications_transports_state_changed_canceled': 'Přepravy - Změna stavu na: Stornována',
    'fields.notifications_transports_approval': 'Přepravy - Žádost o schválení',
    'fields.notifications_transporeon_offers': 'Transporeon nabídky - Vložení nové nabídky',
    'fields.showMarkers': 'Zobrazeni trasy - Zobrazit ostatní vozidla',
    'fields.notifications_vehicle_message': 'Chat',
    'fields.cardNumber': 'Číslo karty',
    'fields.importIdentifier': 'Identifikátor (pro importy)',
    'fields.invoicingDistance': 'Fakturované km',
    'fields.transportPricesSetup': 'Nastavení cen přeprav',
    'fields.priceForKm': 'Cena za kilometry',
    'fields.priceFix': 'Fixní cena',
    'fields.erasePriceToll': 'Vymazat mýto',
    'fields.totalTransportCount': 'Celkový počet přeprav',
    'fields.repeat': 'Perioda',
    'fields.person': 'Osoba',
    'fields.showFuturePayments': 'Zobrazit i budoucí platby',
    'fields.repeatCount': 'Počet opakování',
    'fields.billNumber': 'Číslo dokladu / smlouvy',
    'fields.show_costs': 'Zahrnout náklady na',
    'fields.incl_cost_centers': 'Nákladová střediska',
    'fields.incl_vehicles': 'Vozidla',
    'fields.incl_drivers': 'Řidiče',
    'fields.incl_trailers': 'Návěsy',
    'fields.incl_feature_costs': 'Zahrnout budoucí náklady',
    'fields.yearOfManufacture': 'Rok výroby',
    'fields.soldAt': 'Datum prodeje',
    'fields.driverSurchargeType': 'Typ příplatku',
    'fields.sum': 'Součet',
    'fields.stockInfo': 'Zásoba',
    'fields.itemsCount': 'Počet položek',
    'fields.warehouse': 'Sklad',
    'fields.receiptCount': 'Přijato',
    'fields.issuedCount': 'Vydáno',
    'fields.receipts': 'Příjemky',
    'fields.issues': 'Výdejky',
    'fields.unitsCount': 'Počet MJ',
    'fields.durationMin': 'Časová náročnost',
    'fields.unitDuration': 'Časová náročnost MJ',

    // Buttons
    'buttons.signIn': 'Přihlásit',
    'buttons.find': 'Najít',
    'buttons.createItem': 'Vytvořit záznam',
    'buttons.details': 'Podrobnosti',
    'buttons.showDetail': 'Zobrazit detail',
    'buttons.edit': 'Upravit',
    'buttons.openEdit': 'Otevřít / upravit',
    'buttons.copy': 'Kopírovat',
    'buttons.smsCopy': 'Zkopírovat do schránky',
    'buttons.createTemplate': 'Vytvořit šablonu',
    'buttons.delete': 'Smazat',
    'buttons.restore': 'Obnovit',
    'buttons.approval': 'Schválit',
    'buttons.log': 'Zobrazit log změn',
    'buttons.findVehicle': 'Najít vozidlo',
    'buttons.back': 'Zpět',
    'buttons.next': 'Další',
    'buttons.cancel': 'Zrušit',
    'buttons.save': 'Uložit',
    'buttons.saveAndClose': 'Uložit a zavřít',
    'buttons.done': 'Hotovo',
    'buttons.createTransportPoint': 'Vytvořit bod trasy',
    'buttons.sendToVehicle': 'Odeslat do vozidla',
    'buttons.deleteFromVehicle': 'Odebrat z vozidla',
    'buttons.createEvent': 'Vytvořit událost',
    'buttons.editEvent': 'Upravit událost',
    'buttons.changeState': 'Změnit stav',
    'buttons.showEvents': 'Zobrazit události',
    'buttons.hideEvents': 'Skrýt události',
    'buttons.showNaviEvents': 'Zobrazit události z navigace',
    'buttons.hideNaviEvents': 'Skrýt události z navigace',
    'buttons.findAddress': 'Najít adresu',
    'buttons.saveAddress': 'Uložit adresu',
    'buttons.addRow': 'Přidat řádek',
    'buttons.createTransports': 'Vytvořit přepravy',
    'buttons.createTransport': 'Vytvořit přepravu',
    'buttons.accept': 'Přijmout',
    'buttons.reject': 'Odmítnout',
    'buttons.exportToXLS': 'Exportovat do XLS',
    'buttons.exportDetailsToXLS': 'Exportovat podrobnosti do XLS',
    'buttons.refresh': 'Obnovit',
    'buttons.filter': 'Filtrovat',
    'buttons.resetFilters': 'Zrušit filtry',
    'buttons.acceptSelected': 'Přijmout vybrané',
    'buttons.rejectSelected': 'Odmítnout vybrané',
    'buttons.recountRoute': 'Přepočítat trasu',
    'buttons.addRoute': 'Přidat trasu',
    'buttons.clearRoute': 'Vymazat trasu',
    'buttons.showGpsPositions': 'Zobrazit GPS pozice',
    'buttons.hideGpsPositions': 'Skrýt GPS pozice',
    'buttons.addCustomer': 'Přidat zákazníka',
    'buttons.downloadOrder': 'Stáhnout objednávku',
    'buttons.saveAndDownload': 'Uložit a stáhnout',
    'buttons.saveAsNewAddress': 'Uložit jako novou adresu',
    'buttons.createInvoice': 'Vytvořit fakturu',
    'buttons.setAsInvoiced': 'Označit jako vyfakturované',
    'buttons.addItem': 'Přidat položku',
    'buttons.divideTransportToParts': 'Rozdělit přepravu na části',
    'buttons.addTransportPart': 'Přidat další část',
    'buttons.createCreditNote': 'Vytvořit dobropis',
    'buttons.exportTo': 'Exportovat do',
    'buttons.sendToEmail': 'Odeslat emailem',
    'buttons.download': 'Stáhnout',
    'buttons.send': 'Odeslat',
    'buttons.import': 'Importovat',
    'buttons.importPrices': 'Importovat ceny',
    'buttons.moreInformation': 'Více informací',
    'buttons.reassignAllowancesFromDriverConfig': 'Načíst odměny z nastavení řidiče',
    'buttons.createNote': 'Vytvořit poznámku',
    'buttons.clear': 'Vymazat',
    'buttons.focusOnMap': 'Zaměřit na mapě',
    'buttons.select': 'Vybrat',
    'buttons.selectAll': 'Vybrat vše',
    'buttons.unselectAll': 'Odznačit vše',
    'buttons.continue': 'Pokračovat',
    'buttons.setTransportPrices': 'Zadat ceny přeprav',

    // Units
    'units.pcs': 'ks',
    'units.kg': 'kg',

    // Periods
    'period.1': 'Neopakovat',
    'period.2': 'Týden',
    'period.3': 'Měsíc',
    'period.4': 'Kvartál',
    'period.5': 'Půlrok',
    'period.6': 'Rok',

    // Sorting
    'sorting.title': 'Seřadit dle',
    'sorting.option.vehicle': 'Vozidla',
    'sorting.option.waterfall': 'Vodopád',

    // Validators
    'validator.email.required': 'E-mail je vyžadován',
    'validator.password.required': 'Heslo je vyžadováno',

    // Alerts
    'alerts.titles.warning': 'Pozor!',
    'alerts.titles.error': 'Ajaj...',
    'alerts.titles.success': 'OK.',

    'alerts.messages.loginFailed': 'Přihlášení se nezdařilo, prosím zkontrolujte e-mail a heslo.',
    'alerts.messages.logoutFailed': 'Odhlášení se nezdařilo, zkuste to prosím znovu.',
    'alerts.messages.mailSend': 'E-mail odeslán.',
    'alerts.messages.mailSendFiled': 'E-mail se nepodařilo odeslat.',
    'alerts.messages.invalidToken': 'Neplatný token.',
    'alerts.messages.passwordDontMatch': 'Hesla se neschodují.',
    'alerts.messages.passwordChange': 'Heslo úspěšně změněno.',
    'alerts.messages.createSuccess': 'Položka byla úspěšně vytvořena.',
    'alerts.messages.saveSuccess': 'Položka byla úspěšně uložena.',
    'alerts.messages.copySuccess': 'Položka byla úspěšně zkopírována.',
    'alerts.messages.deleteSuccess': 'Položka byla úspěšně smazána.',
    'alerts.messages.restoreSuccess': 'Položka byla úspěšně obnovena.',
    'alerts.messages.approveSuccess': 'Položka byla úspěšně schválena.',
    'alerts.messages.transportSendToVehicleSuccess': 'Přeprava byla úspěšně odeslána do vozidla.',
    'alerts.messages.transportDeleteFromVehicleSuccess': 'Přeprava byla úspěšně odebrána z vozidla.',
    'alerts.messages.createFromTemplateSuccess': 'Přepravy byly úspěšně vytvořeny ze šablony.',
    'alerts.messages.transporeonOfferAcceptSuccess': 'Úspěšně přijato.',
    'alerts.messages.transporeonOfferRejectSuccess': 'Úspěšně zamítnuto.',
    'alerts.messages.noteSaveSuccess': 'Poznámka byla úspěšně uložena.',
    'alerts.messages.changesSaveSuccess': 'Změny byly úspěšně uloženy.',
    'alerts.messages.addressSaveSuccess': 'Adresa byla úspěšně uložena.',
    'alerts.messages.routeCalculateSuccess': 'Trasa byla úspěšně přepočítána.',
    'alerts.messages.filesUploadSuccess': 'Soubory byly úspěšně nahrány.',
    'alerts.messages.filesDeleteSuccess': 'Soubor byl úspěšně smazán.',
    'alerts.messages.invoiceCreateSuccess': 'Faktura byla úspěšně vytvořena.',
    'alerts.messages.exportSuccess': 'Export proběhl úspěšně.',
    'alerts.messages.importSuccess': 'Import proběhl úspěšně.',
    'alerts.messages.transportOrderSendingSuccess': 'Úspěšně odesláno na zadanou adresu.',
    'alerts.messages.invoiceSendingSuccess': 'Úspěšně odesláno na zadanou adresu.',
    'alerts.messages.eventTimesCorrectionSuccess': 'Úspěšně přepočítáno.',
    'alerts.messages.transportEventsCreatingSuccess': 'Události byly úspěšně vytvořeny.',
    'alerts.messages.duplicityFound': 'Nalezena duplicita.',
    'alerts.messages.legendNotFound': 'Legenda nenalezena.',
    'alerts.messages.code.9000010': 'Váše IP adresa je zablokovaná, kontaktujte svého správce...',
    'alerts.messages.code.9000020': 'Váš účet je zablokovaný, kontaktujte svého správce...',
    'alerts.messages.fileImportSuccess': 'Soubor byl úspěšně importován.',
    'alerts.messages.mandatoryItems': 'Následující položky jsou povinné',
    'alerts.messages.calculateRouteNoRouteFound': 'Trasa nenalezena.',
    'alerts.messages.customerBlockedNeedApproval': 'Zákazník je blokovaný a je potřeba schválení.',
    'alerts.messages.warningDeleted': 'Pozor! Upravujete smazaný záznam. Pokud si jej přejete obnovit, klikněte na tlačítko obnovit.',


    // Loader
    'loader.pleaseWait': 'Čekejte prosím...',

    // Confirm dialog
    'confirmDialog.default.title': 'Potvrzení',
    'confirmDialog.default.message': 'Opravdu si přejete vykonat tuto akci?',
    'confirmDialog.default.accept': 'Ano, potvrdit',
    'confirmDialog.default.cancel': 'Zrušit',

    'confirmDialog.delete.title': 'Smazat záznam?',
    'confirmDialog.delete.message': 'Toto je nevratná akce, opravdu si přejete smazat záznam?',
    'confirmDialog.delete.accept': 'Ano, smazat',
    'confirmDialog.delete.cancel': 'Zrušit',

    'confirmDialog.transportDelete.title': 'Smazat přepravu?',
    'confirmDialog.transportDelete.message': 'Opravdu si přejete smazat přepravu? Pokud byla přeprava odeslána do vozidla, bude z vozidla odebrána.',
    'confirmDialog.transportDelete.accept': 'Ano, smazat',
    'confirmDialog.transportDelete.cancel': 'Zrušit',

    'confirmDialog.transportRestore.title': 'Obnovit přepravu?',
    'confirmDialog.transportRestore.message': 'Opravdu si přejete obnovit přepravu?',
    'confirmDialog.transportRestore.accept': 'Ano, obnovit',
    'confirmDialog.transportRestore.cancel': 'Zrušit',

    'confirmDialog.itemRestore.title': 'Obnovit položku?',
    'confirmDialog.itemRestore.message': 'Opravdu si přejete obnovit položku?',
    'confirmDialog.itemRestore.accept': 'Ano, obnovit',
    'confirmDialog.itemRestore.cancel': 'Zrušit',

    'confirmDialog.transportApproval.title': 'Obnovit schválit?',
    'confirmDialog.transportApproval.message': 'Opravdu si přejete schválit přepravu?',
    'confirmDialog.transportApproval.accept': 'Ano, schválit',
    'confirmDialog.transportApproval.cancel': 'Zrušit',

    'confirmDialog.transportSendToVehicle.title': 'Odeslat přepravu do vozidla?',
    'confirmDialog.transportSendToVehicle.message': 'Opravdu si přejete odeslat přepravu do vozidla?',
    'confirmDialog.transportSendToVehicle.accept': 'Ano, odeslat',
    'confirmDialog.transportSendToVehicle.cancel': 'Zrušit',

    'confirmDialog.transportDeleteFromVehicle.title': 'Odebrat z vozidla?',
    'confirmDialog.transportDeleteFromVehicle.message': 'Opravdu si přejete odebrat přepravu z vozidla?',
    'confirmDialog.transportDeleteFromVehicle.accept': 'Ano, odebrat',
    'confirmDialog.transportDeleteFromVehicle.cancel': 'Zrušit',

    'confirmDialog.transportTemplateDelete.title': 'Smazat šablonu?',
    'confirmDialog.transportTemplateDelete.message': 'Opravdu si přejete smazat šablonu?',
    'confirmDialog.transportTemplateDelete.accept': 'Ano, smazat',
    'confirmDialog.transportTemplateDelete.cancel': 'Zrušit',

    'confirmDialog.duplicityFound.title': 'Duplicita',
    'confirmDialog.duplicityFound.message': 'Byl nalezen stejný nebo velmi podobný záznam.',
    'confirmDialog.duplicityFound.accept': 'Přesto uložit',
    'confirmDialog.duplicityFound.cancel': 'Zrušit',

    'confirmDialog.emptyMandatoryFields.title': 'Pozor',
    'confirmDialog.emptyMandatoryFields.message': 'Některé vyžadované údaje nejsou vyplněné.',
    'confirmDialog.emptyMandatoryFields.accept': 'Přesto uložit',
    'confirmDialog.emptyMandatoryFields.cancel': 'Zrušit',

    'confirmDialog.removeCustomer.title': 'Odebrat zákazníka?',
    'confirmDialog.removeCustomer.message': 'Opravdu si přejete odebrat zákazníka?',
    'confirmDialog.removeCustomer.accept': 'Ano, odebrat',
    'confirmDialog.removeCustomer.cancel': 'Zrušit',

    'confirmDialog.deletePoint.title': 'Smazat bod trasy?',
    'confirmDialog.deletePoint.message': 'Opravdu si přejete smazat bod trasy?',
    'confirmDialog.deletePoint.accept': 'Ano, smazat',
    'confirmDialog.deletePoint.cancel': 'Zrušit',

    'confirmDialog.deleteEvent.title': 'Smazat událost?',
    'confirmDialog.deleteEvent.message': 'Opravdu si přejete smazat událost?',
    'confirmDialog.deleteEvent.accept': 'Ano, smazat',
    'confirmDialog.deleteEvent.cancel': 'Zrušit',

    'confirmDialog.addressNotFoundCreateNew.title': 'Adresa nenalezena',
    'confirmDialog.addressNotFoundCreateNew.message': 'V blízkosti této lokality jsme nenašli žádnou uloženou adresu. Přejete si ji vytvořit?',
    'confirmDialog.addressNotFoundCreateNew.accept': 'Ano, vytvořit novou adresu',
    'confirmDialog.addressNotFoundCreateNew.cancel': 'Zrušit',

    'confirmDialog.approve.title': 'Schválit položku?',
    'confirmDialog.approve.message': 'Opravdu si přejete položku schválit?',
    'confirmDialog.approve.accept': 'Ano, schválit',
    'confirmDialog.approve.cancel': 'Zrušit',

    'confirmDialog.setAsUnpaid.title': 'Zrušit zaplacení?',
    'confirmDialog.setAsUnpaid.message': 'Opravdu si přejete zrušit zaplacení?',
    'confirmDialog.setAsUnpaid.accept': 'Ano, zrušit zaplacení',
    'confirmDialog.setAsUnpaid.cancel': 'Zrušit',

    'confirmDialog.correctEventTimes.title': 'Automaticky upravit nebo vytvořit události?',
    'confirmDialog.correctEventTimes.message':
        'Tato funkce automaticky upraví časy událostí jako je příjezd nebo odjezd z nakládky podle GPS pozic z vozidla a následně přepočítá skutečnou trasu. Původní hodnoty budou nahrazeny. Opravdu si přejete pokračovat?', // eslint-disable-line
    'confirmDialog.correctEventTimes.accept': 'Ano, pokračovat',
    'confirmDialog.correctEventTimes.cancel': 'Zrušit',

    'confirmDialog.reAssignAllowances.title': 'Znovu načíst z nastavení?',
    'confirmDialog.reAssignAllowances.message': 'Opravdu si přejete znovu načíst odměny z nastavení? Existující záznamy budou nahrazeny.',
    'confirmDialog.reAssignDietAllowances.message': 'Opravdu si přejete znovu časové stravné z nastavení? Existující záznamy budou nahrazeny.',
    'confirmDialog.reAssignAllowances.accept': 'Ano, načíst',
    'confirmDialog.reAssignAllowances.cancel': 'Zrušit',

    'confirmDialog.updateCostGonerator.title': 'Upravit pouze tento náklad?',
    'confirmDialog.updateCostGonerator.message': 'Přejete si upravit pouze tento náklad nebo i následující náklady.',
    'confirmDialog.updateCostGonerator.onlyThis': 'Pouze tento náklad',
    'confirmDialog.updateCostGonerator.thisAndFuture': 'Tento náklad a budoucí',

    'confirmDialog.deleteCostGonerator.title': 'Odebrat pouze tento náklad?',
    'confirmDialog.deleteCostGonerator.message': 'Přejete si odebrat pouze tento náklad nebo i následující náklady.',
    'confirmDialog.deleteCostGonerator.onlyThis': 'Pouze tento náklad',
    'confirmDialog.deleteCostGonerator.thisAndFuture': 'Tento náklad a budoucí',

    'confirmDialog.updateSalesGonerator.title': 'Upravit pouze tento záznam?',
    'confirmDialog.updateSalesGonerator.message': 'Přejete si upravit pouze tento záznam nebo i následující záznamy.',
    'confirmDialog.updateSalesGonerator.onlyThis': 'Pouze tento záznam',
    'confirmDialog.updateSalesGonerator.thisAndFuture': 'Tento záznam a budoucí',

    'confirmDialog.deleteCSalesGonerator.title': 'Odebrat pouze tento záznam?',
    'confirmDialog.deleteSalesGonerator.message': 'Přejete si odebrat pouze tento záznam nebo i následující záznamy.',
    'confirmDialog.deleteSalesGonerator.onlyThis': 'Pouze tento záznam',
    'confirmDialog.deleteSalesGonerator.thisAndFuture': 'Tento záznam a budoucí',

    // Pagination
    'pagination.totalRecords': 'Celkem položek',
    'pagination.previous': 'Předchozí',
    'pagination.next': 'Další',

    // Transport states
    'transportStates.notApproved': 'Neschváleno',
    'transportStates.preOrder': 'Předobjednávka',
    'transportStates.order': 'Objednávka',
    'transportStates.planned': 'Naplánována',
    'transportStates.sent': 'Odeslána',
    'transportStates.processing': 'Zpracovává se',
    'transportStates.finished': 'Dokončena',
    'transportStates.canceled': 'Stornována',

    // Days
    'dayNames.0': 'Neděle',
    'dayNames.1': 'Pondělí',
    'dayNames.2': 'Úterý',
    'dayNames.3': 'Středa',
    'dayNames.4': 'Čtvrtek',
    'dayNames.5': 'Pátek',
    'dayNames.6': 'Sobota',

    // Months
    'monthNames.01': 'Leden',
    'monthNames.02': 'Únor',
    'monthNames.03': 'Březen',
    'monthNames.04': 'Duben',
    'monthNames.05': 'Květen',
    'monthNames.06': 'Červen',
    'monthNames.07': 'Červenec',
    'monthNames.08': 'Srpen',
    'monthNames.09': 'Září',
    'monthNames.10': 'Říjen',
    'monthNames.11': 'Listopad',
    'monthNames.12': 'Prosinec',

    // Vehicle data source
    'vehicleDataSource.1': 'FB',

    // Transport point types
    'pointTypes.loading': 'Nakládka',
    'pointTypes.loadingCreate': 'Vytvořit nakládku',
    'pointTypes.loadingEdit': 'Upravit nakládku',
    'pointTypes.unloading': 'Vykládka',
    'pointTypes.unloadingCreate': 'Vytvořit vykládku',
    'pointTypes.unloadingEdit': 'Upravit vykládku',
    'pointTypes.waypoint': 'Průjezdný bod',
    'pointTypes.waypointCreate': 'Vytvořit průjezdný bod',
    'pointTypes.waypointEdit': 'Upravit průjezdný bod',
    'pointTypes.service': 'Servis',
    'pointTypes.serviceCreate': 'Vytvořit servis',
    'pointTypes.serviceEdit': 'Upravit servis',
    'pointTypes.fueling': 'Tankování',
    'pointTypes.fuelingCreate': 'Vytvořit tankování',
    'pointTypes.fuelingEdit': 'Upravit tankování',
    'pointTypes.parking': 'Parkování',
    'pointTypes.parkingCreate': 'Vytvořit parkování',
    'pointTypes.parkingEdit': 'Upravit parkování',
    'pointTypes.parkingWithChange': 'Parkování s výměnou',
    'pointTypes.parkingWithChangeCreate': 'Vytvořit parkování s výměnou',
    'pointTypes.parkingWithChangeEdit': 'Upravit parkování s výměnou',
    'pointTypes.trailerChange': 'Přepřah',
    'pointTypes.trailerChangeCreate': 'Vytvořit přepřah',
    'pointTypes.trailerChangeEdit': 'Upravit přepřah',
    'pointTypes.trailerCatch': 'Zápřah',
    'pointTypes.trailerCatchCreate': 'Vytvořit zápřah',
    'pointTypes.trailerCatchEdit': 'Upravit zápřah',
    'pointTypes.trailerOut': 'Odpřah',
    'pointTypes.trailerOutCreate': 'Vytvořit odpřah',
    'pointTypes.trailerOutEdit': 'Upravit odpřah',
    'pointTypes.move': 'Přesun',
    'pointTypes.moveCreate': 'Vytvořit přesun',
    'pointTypes.moveEdit': 'Upravit přesun',
    'pointTypes.customHouse': 'Celnice',
    'pointTypes.customHouseCreate': 'Vytvořit celnici',
    'pointTypes.customHouseEdit': 'Upravit celnici',
    'pointTypes.vehicleQuit': 'Odstavení vozidla',
    'pointTypes.vehicleQuitCreate': 'Vytvořit odstavení vozidla',
    'pointTypes.vehicleQuitEdit': 'Upravit odstavení vozidla',

    // Transport point states
    'transportPointStates.onWay': 'Na cestě',
    'transportPointStates.arrived': 'Na místě',
    'transportPointStates.waiting': 'Na místě / čeká',
    'transportPointStates.processing': 'Na místě / zpracovává se',
    'transportPointStates.done': 'Na místě / hotovo',
    'transportPointStates.finished': 'Dokončeno',

    // Transport event types
    'transportEvents.predepartureCheck': 'Kontrola před odjezdem',
    'transportEvents.loadingArrival': 'Příjezd na nakládku',
    'transportEvents.systemCheck': 'Kontrola systémů',
    'transportEvents.loadingWaiting': 'Čekání na nakládku',
    'transportEvents.loadingStart': 'Začátek nakládky',
    'transportEvents.loadingEnd': 'Konec nakládky',
    'transportEvents.loadingLoaded': 'Vyplnění množství / odjezd',
    'transportEvents.unloadingArrival': 'Příjezd na vykládku',
    'transportEvents.unloadingWaiting': 'Čekání na vykládku',
    'transportEvents.unloadingStart': 'Začátek vykládky',
    'transportEvents.unloadingEnd': 'Konec vykládky',
    'transportEvents.unloadingUnloaded': 'Vyplnění množství / odjezd',
    'transportEvents.waypointEnd': 'Dokončení průjezdu',
    'transportEvents.fuelingArrival': 'Příjezd na tankování',
    'transportEvents.fuelingDeparture': 'Odjezd z tankování',
    'transportEvents.serviceArrival': 'Příjezd na servis',
    'transportEvents.serviceDeparture': 'Odjezd ze servisu',
    'transportEvents.parkingArrival': 'Příjezd na parkování',
    'transportEvents.parkingCheckBefore': 'Kontrola před parkováním',
    'transportEvents.parkingTime': 'Vyplnění plánovaného času',
    'transportEvents.parkingCheckAfter': 'Kontrola po parkování',
    'transportEvents.parkingDeparture': 'Odjezd z parkování',
    'transportEvents.trailerChangeArrival': 'Příjezd na přepřah',
    'transportEvents.trailerChangeCheck': 'Kontrola návěsu po přepřahu',
    'transportEvents.trailerChangeDeparture': 'Odjezd z přepřahu',
    'transportEvents.trailerCatchArrival': 'Příjezd na zápřah',
    'transportEvents.trailerCatchCheck': 'Kontrola návěsu po zápřahu',
    'transportEvents.trailerCatchDeparture': 'Odjezd ze zápřahu',
    'transportEvents.trailerOutArrival': 'Příjezd na odpřah',
    'transportEvents.trailerOutCheck': 'Kontrola návěsu po odpřahu',
    'transportEvents.trailerOutDeparture': 'Odjezd z odpřahu',
    'transportEvents.parkingWithChangeArrival': 'Příjezd na parkování s výměnou',
    'transportEvents.parkingWithChangeQuitCheck': 'Kontrola před parkováním s výměnou',
    'transportEvents.parkingWithChangeCheck': 'Kontrola po parkování s výměnou',
    'transportEvents.moveEnd': 'Dokončení přesunu',
    'transportEvents.customHouseArrival': 'Příjezd na celnici',
    'transportEvents.customHouseDeparture': 'Odjezd z celnice',
    'transportEvents.vehicleQuitCheck': 'Kontrola před odstavením vozidla',
    'transportEvents.vehicleQuitFinish': 'Odstavení vozidla',

    // Transport log changes
    'transportLogChanges.transportCreated': 'Vytvoření přepravy',
    'transportLogChanges.transportCreatedByCopying': 'Vytvoření přepravy kopírováním',
    'transportLogChanges.transportCreatedFromTemplate': 'Vytvoření přepravy ze šablony',
    'transportLogChanges.transportCreatedFromTransporeon': 'Vytvoření přepravy z Transporeon nabídky',
    'transportLogChanges.transportUpdated': 'Editace přepravy',
    'transportLogChanges.transportDeleted': 'Smazání přepravy',
    'transportLogChanges.transportRestored': 'Obnovení přepravy',
    'transportLogChanges.transportSentToVehicle': 'Odeslání přepravy do vozidla',
    'transportLogChanges.transportUpdatedInVehicle': 'Editace přepravy ve vozidle',
    'transportLogChanges.transportDeletedFromVehicle': 'Odebrání přepravy z vozidla',
    'transportLogChanges.transportPointCreated': 'Vytvoření bodu trasy',
    'transportLogChanges.transportPointUpdated': 'Editace bodu trasy',
    'transportLogChanges.transportPointDeleted': 'Smazání bodu trasy',
    'transportLogChanges.transportPointRestored': 'Obnovení bodu trasy',
    'transportLogChanges.transportEventCreated': 'Vytvoření události',
    'transportLogChanges.transportEventUpdated': 'Editace události',
    'transportLogChanges.transportEventDeleted': 'Smazání události',

    // Vehicle events
    'vehicleEvents.predepartureCheck': 'Kontrola před odjezdem',
    'vehicleEvents.fueling': 'Tankování',
    'vehicleEvents.service': 'Servis',
    'vehicleEvents.parking': 'Parkování',
    'vehicleEvents.parkingWithChange': 'Parkování s výměnou',
    'vehicleEvents.accident': 'Nehoda',
    'vehicleEvents.trailerCatch': 'Zápřah',
    'vehicleEvents.trailerOut': 'Odpřah',
    'vehicleEvents.loading': 'Nakládka',
    'vehicleEvents.unloading': 'Vykládka',
    'vehicleEvents.move': 'Přesun',
    'vehicleEvents.customHouse': 'Celnice',
    'vehicleEvents.vehicleQuit': 'Odstavení',

    // Vehicle trip record type
    'vehicleTripRecordType.dataCompletion': 'Doplnění dat',
    'vehicleTripRecordType.fueling': 'Tankování',
    'vehicleTripRecordType.0': 'Stání',
    'vehicleTripRecordType.1': 'Jízda',
    'vehicleTripRecordType.2': 'Hraniční přechod',

    // Invoicing states
    'invoicingStates.0': 'Nefakturováno',
    'invoicingStates.1': 'Připraveno',
    'invoicingStates.2': 'Fakturováno',

    // Tax transaction types
    'taxTransactionTypes.1': 'Tuzemské',
    'taxTransactionTypes.2': 'EU (reverse charge)',
    'taxTransactionTypes.3': 'Mimo EU',
    'taxTransactionTypes.4': 'Nepodléhá DPH',

    // Text before items types
    'textBeforeItemsTypes.1': 'Fakturujeme Vám za následující položky:',
    'textBeforeItemsTypes.2': 'Fakturujeme Vám za následující přepravy:',

    // Tax rate types
    'taxRateTypes.1': 'Základní',
    'taxRateTypes.2': 'První snížená',
    'taxRateTypes.3': 'Druhá snížená',
    'taxRateTypes.4': 'Nulová',

    // Transport vehicle types
    'transportVehicleTypes.vehicle': 'Vozidlo',
    'transportVehicleTypes.boat': 'Loď',
    'transportVehicleTypes.train': 'Vlak',
    'transportVehicleTypes.plane': 'Letadlo',
    'transportVehicleTypes.other': 'Jiné',

    // Company numbering types
    'companyNumberingTypes.1': 'Vydané faktury',
    'companyNumberingTypes.2': 'Přijaté faktury',
    'companyNumberingTypes.3': 'Vydané dobropisy',
    'companyNumberingTypes.4': 'Přijaté dobropisy',

    // Driver timesheet interval types
    'driverTimesheetIntervalTypes.interval': 'Časový období',
    'driverTimesheetIntervalTypes.time': 'Počet hodin a minut',

    // Driver timesheet types
    'driverTimesheetTypes.pause': 'Stání',
    'driverTimesheetTypes.drive': 'Jízda',
    'driverTimesheetTypes.other_job': 'Jiná práce',
    'driverTimesheetTypes.holiday': 'Dovolená',
    'driverTimesheetTypes.vacation': 'Placené volno',
    'driverTimesheetTypes.unpaid_vacation': 'Neplacené volno',
    'driverTimesheetTypes.turnus_vacation': 'Turnusové volno',
    'driverTimesheetTypes.disease': 'Nemoc',
    'driverTimesheetTypes.doctor': 'Lékař',
    'driverTimesheetTypes.nursing': 'Ošetřovné',
    'driverTimesheetTypes.absence': 'Absence',
    'driverTimesheetTypes.use_overtime': 'Čerpání přesčasu',

    // Driver surcharge types
    'driverSurchargeTypes.1': 'Denní',
    'driverSurchargeTypes.2': 'Měsíční',

    // Transports
    'modules.transports.heading': 'Přepravy',
    'modules.transports.headingArchive': 'Přepravy - archiv',
    'modules.transports.headingTrashed': 'Přepravy - smazané',
    'modules.transports.headingNew': 'Nová přeprava',
    'modules.transports.headingEdit': 'Přeprava č.',
    'modules.transports.showOnlyCombined': 'Zobrazit pouze kombinované',
    'modules.transports.showWithSpeditions': 'Zobrazit včetně spedičních přeprav',
    'modules.transports.showOnlySpeditions': 'Zobrazit pouze spediční přepravy',
    'modules.transports.showOnlyNotApproved': 'Zobrazit pouze neschválené',
    'modules.transports.showOnlyCabotage': 'Zobrazit pouze kabotáže',
    'modules.transports.notSentYet': 'Přeprava zatím nebyla odeslána do vozidla',
    'modules.transports.suspectlyLargeApproach': 'Podezřele velký nájezd',
    'modules.transports.warningDeletedTransport': 'Pozor! Upravujete smazanou přepravu. Pokud si přejete ji obnovit, klikněte na tlačítko obnovit.',
    'modules.transports.warningNotApprovalTransport':
        'Pozor! Jedná se o neschválenou přepravu. Pokud si přejete ji schválit, klikněte na tlačítko schválit.',
    'modules.transports.pricing': 'Skladba ceny',
    'modules.transports.routeDefinition': 'Definice trasy',
    'modules.transports.requirePredepartureCheck': 'Vyžadovat kontrolu před odjezdem',
    'modules.transports.showRoutesOnMap': 'Zobrazit trasy na mapě',
    'modules.transports.noVehiclePositionsFound': 'V rámci této přepravy nebyly nalezeny žádné GPS pozice',
    'modules.transports.warningBlockedCustomer': 'Pozor! Tento zákazník je blokovaný.',
    'modules.transports.warningBlockedCarrier': 'Pozor! Tento dopravce je blokovaný.',
    'modules.transports.files': 'Soubory',
    'modules.transports.goods': 'Definice nákladu',
    'modules.transports.parts': 'Kombinovaná přeprava (části)',
    'modules.transports.preloaded': 'Přednaloženo',
    'modules.transports.allowances': 'Stravné a odměny',
    'modules.transports.reInitAllowances': 'Znovu načíst ze šablony',
    'modules.transports.addAllowances': 'Přidat stravné/kapesné',
    'modules.transports.addAllowancesSelectType': 'Výše stravného/kapesného, měna a typ',
    'modules.transports.noDriversFound': 'Přeprava nemá přiřazeného řidiče, stravné není možné zadávat',
    'modules.transports.allowancesSetDefaultValue': 'Nastavit výchozí',
    'modules.transports.driver': 'Řidič',
    'modules.transports.noDrivers': 'Nebyli nelezeni žádní řidiči',
    'modules.transports.scoring': 'Vyhodnocení',
    'modules.transports.realDistanceWarning': 'Podezřele velký rozdíl',
    'modules.transports.minPricePerKmWarningTooltip': 'Podezřele nízká cena',

    // Transport templates
    'modules.transportTemplates.heading': 'Šablony',
    'modules.transportTemplates.headingNew': 'Nová šablona',
    'modules.transportTemplates.headingEdit': 'Editace šablony',
    'modules.transportTemplates.createTransportFromTemplate': 'Vytvořit přepravy ze šablony',

    // Transports import
    'modules.transportsImport.heading': 'Import přeprav',
    'modules.transportsImport.step': 'Krok',
    'modules.transportsImport.stepNames.1': 'Nahrání souboru',
    'modules.transportsImport.stepNames.2': 'Definice sloupců',
    'modules.transportsImport.stepNames.3': 'Souhrn',

    'modules.transportsImport.columnTypes.vehicle': 'SPZ vozidla',
    'modules.transportsImport.columnTypes.circle': 'Kolečko',
    'modules.transportsImport.columnTypes.template': 'Název šablony',
    'modules.transportsImport.columnTypes.templateIdentifier': 'Identifikátor šablony',
    'modules.transportsImport.columnTypes.customer': 'Zákazník',
    'modules.transportsImport.columnTypes.orderNumber': 'Číslo objednávky',
    'modules.transportsImport.columnTypes.transportType': 'Typ přepravy',
    'modules.transportsImport.columnTypes.cargoType': 'Typ nákladu',
    'modules.transportsImport.columnTypes.goodsType': 'Typ zboží',
    'modules.transportsImport.columnTypes.price': 'Cena',
    'modules.transportsImport.columnTypes.priceToll': 'Dálniční poplatky',
    'modules.transportsImport.columnTypes.currency': 'Měna',
    'modules.transportsImport.columnTypes.loadingPlaceCode': 'Nakládka - kód místa',
    'modules.transportsImport.columnTypes.loadingPlace': 'Nakládka - název místa',
    'modules.transportsImport.columnTypes.loadingDate': 'Nakládka - datum',
    'modules.transportsImport.columnTypes.loadingTime': 'Nakládka - čas',
    'modules.transportsImport.columnTypes.loadingDateTime': 'Nakládka - datum a čas',
    'modules.transportsImport.columnTypes.loadingCode': 'Nakládka - kód',
    'modules.transportsImport.columnTypes.loadingNote': 'Nakládka - poznámka',
    'modules.transportsImport.columnTypes.loadingWeight': 'Nakládka - hmotnost nákladu',
    'modules.transportsImport.columnTypes.loadingPcsCount': 'Nakládka - množství (počet kusů)',
    'modules.transportsImport.columnTypes.unloadingPlaceCode': 'Vykládka - kód místa',
    'modules.transportsImport.columnTypes.unloadingPlace': 'Vykládka - název místa',
    'modules.transportsImport.columnTypes.unloadingDate': 'Vykládka - datum',
    'modules.transportsImport.columnTypes.unloadingTime': 'Vykládka - čas',
    'modules.transportsImport.columnTypes.unloadingDateTime': 'Vykládka - datum a čas',
    'modules.transportsImport.columnTypes.unloadingCode': 'Vykládka - kód',
    'modules.transportsImport.columnTypes.unloadingNote': 'Vykládka - poznámka',
    'modules.transportsImport.columnTypes.unloadingWeight': 'Vykládka - hmotnost nákladu',
    'modules.transportsImport.columnTypes.unloadingPcsCount': 'Vykládka - množství (počet kusů)',

    // Transports import prices
    'modules.transportsImportPrices.heading': 'Import cen',
    'modules.transportsImportPrices.step': 'Krok',
    'modules.transportsImportPrices.stepNames.1': 'Nahrání souboru',
    'modules.transportsImportPrices.stepNames.2': 'Definice sloupců',
    'modules.transportsImportPrices.stepNames.3': 'Souhrn',

    'modules.transportsImportPrices.columnTypes.orderNumber': 'Číslo objednávky',
    'modules.transportsImportPrices.columnTypes.price': 'Celková cena',
    'modules.transportsImportPrices.columnTypes.priceToll': 'Dálniční poplatky',

    // Transporeon offers
    'modules.transporeonOffers.heading': 'Transporeon nabídky',
    'modules.transporeonOffers.reason.transitTimeTooShort': 'Příliš krátká doba přepravy',
    'modules.transporeonOffers.reason.noDriverAvailable': 'Není volný řidič',
    'modules.transporeonOffers.reason.requestedEquipmentNotAvailable': 'Požadovaná výbava není k dispozici',
    'modules.transporeonOffers.reason.other': 'Jiný důvod',
    'modules.transporeonOffers.accept.title': 'Přjmout nabídku?',
    'modules.transporeonOffers.accept.message': 'Opravdu si přejete přijmout nabídku?',
    'modules.transporeonOffers.reject.title': 'Odmítnout nabídku?',
    'modules.transporeonOffers.reject.message': 'Opravdu si přejete odmítnout nabídku?',

    // Board
    'modules.board.heading': 'Dispečerská plachta',
    'modules.board.vehicleNote': 'Poznámka k vozidlu',
    'modules.board.trailerNote': 'Poznámka k návěsu',
    'modules.board.trailerDamage': 'Poškození návěsu',
    'modules.board.showFree': 'Zobrazit volné',
    'modules.board.weekendAtHome': 'Víkend doma',
    'modules.board.weekendAtWork': 'Víkend jezdí',
    'modules.board.isDone': 'Naplánováno (pro dnešek)',
    'modules.board.isNotDone': 'Není naplánováno (pro dnešek)',
    'modules.board.updatedInLast2Hours': 'Aktualizováno v posledních 2 hodinách',
    'modules.board.updated2HoursAgo': 'Aktualizováno před více než 2 hodinami',
    'modules.board.planningState': 'Stav plánování',

    // Map
    'modules.map.heading': 'Mapa',

    // Reports
    'modules.reportTransports.heading': 'Reporty - Přepravy - Hospodářské výsledky',
    'modules.reportSpeditionTransports.heading': 'Reporty - Přepravy - Hospodářské výsledky spedičních přaprav',
    'modules.reportTransportChanges.heading': 'Reporty - Přepravy - Sledování změn',
    'modules.reportTransportInvoicing.heading': 'Reporty - Přepravy - Fakturace',
    'modules.reportTransportOrders.heading': 'Reporty - Přepravy - Objednávky',
    'modules.reportVehicleKilometersTotal.heading': 'Reporty - Vozidla - Celkové kilometry',
    'modules.reportVehiclePerformance.heading': 'Reporty - Vozidla - Výkonové ukazatele',
    'modules.reportVehicleTraffic.heading': 'Reporty - Vozidla - Provozní ukazatele',
    'modules.reportVehicleChecks.heading': 'Reporty - Vozidla - Kontroly a závady',
    'modules.reportVehicleFuelings.heading': 'Reporty - Vozidla - Tankování a spotřeba',
    'modules.reportVehicleProfits.heading': 'Reporty - Vozidla - Hospodářské výsledky',
    'modules.reportVehicleResults.heading': 'Reporty - Vozidla - Obraty',
    'modules.reportDriverPerformanceAnalysis.heading': 'Reporty - Řidiči - Analýza výkonu',
    'modules.reportDriverHourlyOverview.heading': 'Reporty - Řidiči - Hodinový přehled',
    'modules.reportDriverTimesheet.heading': 'Reporty - Řidiči - Pracovní výkaz',
    'modules.reportDriverResults.heading': 'Reporty - Řidiči - Obraty',
    'modules.reportDriverCosts.heading': 'Reporty - Řidiči - Náklady',
    'modules.reportTrailerResults.heading': 'Reporty - Návěsy - Obraty',
    'modules.reportTrailerCosts.heading': 'Reporty - Návěsy - Náklady',
    'modules.reportTrailerFuelings.heading': 'Reporty - Návěsy - Tankování',
    'modules.reportVehicleCosts.heading': 'Reporty - Vozidla - Náklady',
    'modules.reportCostCenterCosts.heading': 'Reporty - Nákladová střediska - Náklady',
    'modules.reportCustomerResults.heading': 'Reporty - Zákazníci - Obraty',
    'modules.reportCustomerPlans.heading': 'Reporty - Zákazníci - Plán přeprav',
    'modules.reportCustomerMonthlyPlans.heading': 'Reporty - Zákazníci - Měsíční plán',
    'modules.reportCustomerSpeditions.heading': 'Reporty - Zákazníci - Spediční přepravy',
    'modules.reportCustomerWeeklyPlans.heading': 'Reporty - Zákazníci - Týdenní plán',
    'modules.reportCostCenterResults.heading': 'Reporty - Nákladová střediska - Obraty',
    'modules.reportCostCenterTraffic.heading': 'Reporty - Nákladová střediska - Provozní ukazatele',
    'modules.reportCostCenterPerformance.heading': 'Reporty - Nákladová střediska - Výkonové ukazatele',
    'modules.reportCostCenterCost.heading': 'Reporty - Nákladová střediska - Náklady',
    'modules.reportCarrierResults.heading': 'Reporty - Spediční dopravci - Náklady',
    'modules.reportCarrierScoring.heading': 'Reporty - Spediční dopravci - Hodnocení',
    'modules.reportCarrierUsage.heading': 'Reporty - Spediční dopravci - Využití',
    'modules.reportDispatcherKPI.heading': 'Reporty - Dispečeři - KPI',
    'modules.reportAppUsage.heading': 'Reporty - Ostatní - Využití aplikace',
    'modules.reportCostTypeCosts.heading': 'Reporty - Náklady - Dle typu',
    'modules.reportCostCenterProfits.heading': 'Reporty - Nákladová střediska - Hospodářské výsledky',
    'modules.reportDriverSurcharge.heading': 'Reporty - Řidiči - Příplatky',

    'modules.reportCustomerTurnovers.heading': 'Reporty - Zákazníci',
    'modules.reportEmptyKilometers.heading': 'Reporty - Nájezdové kilometry',
    'modules.reportPallets.heading': 'Reporty - Paletová evidence',

    // Vehicles
    'modules.vehicles.heading': 'Vozidla',
    'modules.vehicleHistory.heading': 'Vozidla - Historie',
    'modules.vehicleDocuments.heading': 'Vozidla - Dokumenty',
    'modules.vehicleEquipments.heading': 'Vozidla - Vybavení',
    'modules.vehicleEvents.heading': 'Události',

    // Vehicle fueling
    'modules.reportVehicleFuelings.detail.sumeryHeading': 'Celkový přehled',
    'modules.reportVehicleFuelings.detail.tripRecordsHeading': 'Detailní přehled',
    'modules.reportVehicleFuelings.detail.odometerHeading': 'Přehled tachometru',

    // Vehicle fueling
    'modules.reportTrailerFuelings.detail.sumeryHeading': 'Celkový přehled',
    'modules.reportTrailerFuelings.detail.tripRecordsHeading': 'Detailní přehled',


    // Trailers
    'modules.trailers.heading': 'Návěsy',
    'modules.trailerHistory.heading': 'Návěsy - Historie',
    'modules.trailerDocuments.heading': 'Návěsy - Dokumenty',
    'modules.trailerEquipments.heading': 'Návěsy - Vybavení',

    // Drivers
    'modules.drivers.heading': 'Řidiči',
    'modules.driverHistory.heading': 'Řidiči - Historie',
    'modules.driverDocuments.heading': 'Řidiči - Dokumenty',
    'modules.driverEquipments.heading': 'Řidiči - Vybavení',
    'modules.driverTimesheets.heading': 'Řidiči - Pracovní výkazy',
    'modules.driverSurcharges.heading': 'Řidiči - Příplatky',

    // Drivers hourly overview
    'modules.reportDriverHourlyOverview.detail.sumeryHeading': 'Celkový přehled',
    'modules.reportDriverHourlyOverview.detail.tripRecordsHeading': 'Detailní přehled',

    // Driver surcharges
    'modules.reportDriverSurcharges.detail.title': 'Detailní přehled',

    // Customers
    'modules.customers.heading': 'Zákazníci',
    'modules.customerAddresses.heading': 'Adresy',
    'modules.carriers.heading': 'Spediční dopravci',
    'modules.carriers.scoring': 'Vyhodnocení',

    // Settings
    'modules.users.heading': 'Nastavení - Uživatelé',
    'modules.userRoles.heading': 'Nastavení - Uživatelské role',
    'modules.userLogins.heading': 'Nastavení - Uživatelé - Log přihlášení',
    'modules.blockedIps.heading': 'Nastavení - Uživatelé - Blokované IP adresy',
    'modules.countries.heading': 'Nastavení - Adresář - Země',
    'modules.currencies.heading': 'Nastavení - Adresář - Měny',
    'modules.companies.heading': 'Nastavení - Adresář - Společnosti',
    'modules.companyCostCenters.heading': 'Nastavení - Adresář - Nákladová střediska',
    'modules.transportTypes.heading': 'Nastavení - Přepravy - Typy přeprav',
    'modules.vehicleTypes.heading': 'Nastavení - Vozový park - Typy vozidel',
    'modules.trailerTypes.heading': 'Nastavení - Vozový park - Typy návěsů',
    'modules.cargoTypes.heading': 'Nastavení - Přepravy - Typy nákladů',
    'modules.surchargeTypes.heading': 'Nastavení - Přepravy - Typy příplatků',
    'modules.customerAddressTypes.heading': 'Nastavení - Adresář - Typy adres',
    'modules.goodsTypes.heading': 'Nastavení - Přepravy - Typy zboží',
    'modules.documentTypes.heading': 'Nastavení - Vozový park - Typy dokumentů',
    'modules.packagingTypes.heading': 'Nastavení - Přepravy - Typy obalů',
    'modules.equipments.heading': 'Nastavení - Vozový park - Vybavení',
    'modules.taxRates.heading': 'Nastavení - Fakturace - Daňové sazby',
    'modules.paymentMethods.heading': 'Nastavení - Fakturace - Platební metody',
    'modules.transportConditions.heading': 'Nastavení - Přepravy - Přepravní podmínky',
    'modules.invoiceItemTypes.heading': 'Nastavení - Fakturace - Typy pohybů',
    'modules.costTypes.heading': 'Nastavení - Typy nákladů',
    'modules.costs.heading': 'Náklady',
    'modules.costGenerators.heading': 'Opakované náklady',
    'modules.salesTypes.heading': 'Nastavení - Typy tržeb',
    'modules.driversTypes.heading': 'Nastavení - Typy řidičů',
    'modules.sales.heading': 'Tržby',
    'modules.driverSurchargeTypes.heading': 'Nastavení - Typy příplatků',

    'modules.transportScoring.heading': 'Nastavení - Přepravy - Vyhodnocení přeprav',
    'modules.transportScoring.rules': 'Pravidla pro hodnocení',

    'modules.carrierScoring.heading': 'Nastavení - Adresář - Vyhodnocení dopravců',
    'modules.carrierScoring.rules': 'Pravidla pro hodnocení',

    // Invoicing
    'modules.invoicingOrders.heading': 'Fakturace - Objednávky',
    'modules.invoicingInvoices.heading': 'Fakturace - Faktury vydané',
    'modules.invoicingReceivedInvoices.heading': 'Fakturace - Faktury přijaté',
    'modules.reportInvoicingSummary.heading': 'Fakturace - Sumář',

    // Diets
    'modules.dietRates.heading': 'Sazby stravného',

    // General settings
    'modules.generalSettings.heading': 'Nastavení - Obecné nastavení',
    'modules.generalSettings.transports': 'Přepravy / trasování',
    'modules.generalSettings.email': 'Mailing',
    'modules.generalSettings.invoices': 'Fakturace',
    'modules.generalSettings.security': 'Bezpečnost',
    // General settings - transports
    'modules.generalSettings.price_fuel': 'Průměrná cena PHM [CZK / 1 litr]',
    'modules.generalSettings.price_adblue': 'Průměrná cena AdBlue [CZK / 1 litr]',
    'modules.generalSettings.rest_minutes': 'Délka odpočinku [minuty]',
    'modules.generalSettings.break_minutes': 'Délka bezpečnostní přestávky [minuty]',
    'modules.generalSettings.rest_interval_minutes': 'Interval odpočinku [minuty]',
    'modules.generalSettings.break_interval_minutes': 'Interval bezpečnostní přestávky [minuty]',
    'modules.generalSettings.costs_fixed': 'Fixní hodinové náklady [CZK]',
    'modules.generalSettings.avg_speed': 'Průměrná rychlost [km / h]',
    'modules.generalSettings.avg_fuel': 'Průměrná spotřeba PHM [l / km]',
    'modules.generalSettings.avg_adblue': 'Průměrná spotřeba AdBlue [l / km]',
    'modules.generalSettings.drive_at_night_start': 'Noční jízda začíná v',
    'modules.generalSettings.drive_at_night_end': 'Noční jízda končí v',
    'modules.generalSettings.sms_template': 'Šablona přepravy',
    'modules.generalSettings.sms_route_point_template': 'Šablona průjezdného bodu',
    'modules.generalSettings.sms_customer_template': 'Šablona zákazníka',
    'modules.generalSettings.automatic_event_creation_radius': 'Rádius automatického vytvoření události [°]',
    'modules.generalSettings.automatic_event_creation_time': 'Časová tolerance automatického vytvoření události [h]',
    'modules.generalSettings.approach_diff_tolerance': 'Tolerance pro navýšení nájezdu [%]',
    'modules.generalSettings.real_route_diff_tolerance': 'Tolerance pro navýšení skutešné trasy [%]',
    'modules.generalSettings.min_price_per_km': 'Minimální cena za km [CZK]',
    // General settings - email
    'modules.generalSettings.mail_from_address': 'Výchozí odesílatel (email)',
    'modules.generalSettings.mail_from_name': 'Výchozí odesílatel (jméno)',
    // General settings - invoices
    'modules.generalSettings.customer_invoice_deadline_days': 'Výchozí splatnost pro vystavené faktury [dny]',
    'modules.generalSettings.supplier_invoice_deadline_days': 'Výchozí splatnost pro přijaté faktury [dny]',
    // General settings - security
    'modules.generalSettings.reset_password_token_valid_hours': 'Platnost odkazu pro reset hesla [hodiny]',
    'modules.generalSettings.ip_login_attempts': 'Blokovat IP adresu pro X neúspěšných pokusech o přihlášení',
    'modules.generalSettings.user_login_attempts': 'Blokovat účet pro X neúspěšných pokusech o přihlášení',

    //Import messages
    'import.step1': 'Níže prosím nahrajte soubor, ze kterého se mají položky naimportovat. Podporované formáty jsou XLS, XLSX a CSV.',
    'import.step2': 'Nastavte prosím význam jednotlivých sloupců tak, aby bylo možné položky správně naimportovat.',
    'import.step3': 'Níže je přehled položek, které jsme v souboru našli. Vyberte prosím ty, které mají být zpracovány.',
    'import.noItemsFound': 'Nebyla nalezena žádná položka.',

    // Costs import
    'modules.costsImport.heading': 'Import nákladů',
    'modules.costsImport.step': 'Krok',
    'modules.costsImport.stepNames.1': 'Nahrání souboru',
    'modules.costsImport.stepNames.2': 'Definice sloupců',
    'modules.costsImport.stepNames.3': 'Souhrn',

    'modules.costsImport.columnTypes.costCenter': 'Nákladové středisko (kód)',
    'modules.costsImport.columnTypes.vehicle': 'Vozidlo (SPZ)',
    'modules.costsImport.columnTypes.driver': 'Řidič (příjmení a jméno / osobní číslo)',
    'modules.costsImport.columnTypes.trailer': 'Návěs (SPZ)',
    'modules.costsImport.columnTypes.registration': 'SPZ (Automatická detekce)',
    'modules.costsImport.columnTypes.supplier': 'Dodavatel',
    'modules.costsImport.columnTypes.billNumber': 'Číslo dokladu',
    'modules.costsImport.columnTypes.price': 'Částka',
    'modules.costsImport.columnTypes.currency': 'Měna',
    'modules.costsImport.columnTypes.date': 'Datum',

    // Drivers import
    'modules.driversImport.heading': 'Import řidičů',
    'modules.driversImport.step': 'Krok',
    'modules.driversImport.stepNames.1': 'Nahrání souboru',
    'modules.driversImport.stepNames.2': 'Definice sloupců',
    'modules.driversImport.stepNames.3': 'Souhrn',

    'modules.driversImport.columnTypes.firstName': 'Jméno',
    'modules.driversImport.columnTypes.lastName': 'Příjmení',
    'modules.driversImport.columnTypes.locale': 'Jazyk',
    'modules.driversImport.columnTypes.cardNumber': 'Číslo karty',
    'modules.driversImport.columnTypes.code': 'Kód',
    'modules.driversImport.columnTypes.active': 'Aktivní',
    'modules.driversImport.columnTypes.phone': 'Telefon',
    'modules.driversImport.columnTypes.driverType': 'Typ',
    'modules.driversImport.columnTypes.dispatcher': 'Dispečer',
    'modules.driversImport.columnTypes.costCenter': 'Nákladové středisko (kód)',

    // Trailers import
    'modules.trailersImport.heading': 'Import návěsů',
    'modules.trailersImport.step': 'Krok',
    'modules.trailersImport.stepNames.1': 'Nahrání souboru',
    'modules.trailersImport.stepNames.2': 'Definice sloupců',
    'modules.trailersImport.stepNames.3': 'Souhrn',

    'modules.trailersImport.columnTypes.vin': 'VIN',
    'modules.trailersImport.columnTypes.registration': 'SPZ',
    'modules.trailersImport.columnTypes.trailerType': 'Typ',
    'modules.trailersImport.columnTypes.active': 'Aktivní',
    'modules.trailersImport.columnTypes.damaged': 'Poškozeno',
    'modules.trailersImport.columnTypes.securityDamage': 'Zabezpečení poškozeno',
    'modules.trailersImport.columnTypes.coolingDamage': 'Chlazení poškozeno',
    'modules.trailersImport.columnTypes.spedition': 'Spediční',
    'modules.trailersImport.columnTypes.carrier': 'Dopravce',
    'modules.trailersImport.columnTypes.costCenter': 'Nákladové středisko (kód)',

    // Sales import
    'modules.salesImport.heading': 'Import tržeb',
    'modules.salesImport.step': 'Krok',
    'modules.salesImport.stepNames.1': 'Nahrání souboru',
    'modules.salesImport.stepNames.2': 'Definice sloupců',
    'modules.salesImport.stepNames.3': 'Souhrn',

    'modules.salesImport.columnTypes.costCenter': 'Nákladové středisko (kód)',
    'modules.salesImport.columnTypes.vehicle': 'Vozidlo (SPZ)',
    'modules.salesImport.columnTypes.driver': 'Řidič (příjmení a jméno / osobní číslo)',
    'modules.salesImport.columnTypes.trailer': 'Návěs (SPZ)',
    'modules.salesImport.columnTypes.price': 'Částka',
    'modules.salesImport.columnTypes.currency': 'Měna',
    'modules.salesImport.columnTypes.date': 'Datum',

    // Customers import
    'modules.customersImport.heading': 'Import zákazníků',
    'modules.customersImport.step': 'Krok',
    'modules.customersImport.stepNames.1': 'Nahrání souboru',
    'modules.customersImport.stepNames.2': 'Definice sloupců',
    'modules.customersImport.stepNames.3': 'Souhrn',

    'modules.customersImport.columnTypes.regNumber': 'IČO',
    'modules.customersImport.columnTypes.vatNumber': 'DIČ',
    'modules.customersImport.columnTypes.name': 'Název firmy / jméno zákazníka',
    'modules.customersImport.columnTypes.nameTransporeon': 'Název v Transporeonu',
    'modules.customersImport.columnTypes.defaultCurrency': 'Výchozí měna',
    'modules.customersImport.columnTypes.invoiceDeadlineDays': 'Výchozí splatnost',
    'modules.customersImport.columnTypes.emailForInvoicing': 'Fakturační e-mail',
    'modules.customersImport.columnTypes.invoicingNote': 'Poznámka k fakturaci',
    'modules.customersImport.columnTypes.limitUnpaidInvoices': 'Limit pro nezaplacené pohledávky',
    'modules.customersImport.columnTypes.limitOverdueInvoices': 'Limit pro pohledávky po splatnosti',
    'modules.customersImport.columnTypes.blocked': 'Blokováno',
    'modules.customersImport.columnTypes.businessManager': 'Obchodní manažer',
    'modules.customersImport.columnTypes.dispatcher': 'Dispečer',
    'modules.customersImport.columnTypes.transportPlanCount': 'Plán doprava (počet)',
    'modules.customersImport.columnTypes.transportPlanValue': 'Plán doprava (hodnota)',
    'modules.customersImport.columnTypes.transportPlanMargin': 'Plán doprava (marže)',
    'modules.customersImport.columnTypes.speditionPlanCount': 'Plán spedice (počet)',
    'modules.customersImport.columnTypes.speditionPlanValue': 'Plán spedice (hodnota)',
    'modules.customersImport.columnTypes.speditionPlanMargin': 'Plán spedice (marže)',


    // Customer addresss import
    'modules.customerAddressesImport.heading': 'Import adres',
    'modules.customerAddressesImport.step': 'Krok',
    'modules.customerAddressesImport.stepNames.1': 'Nahrání souboru',
    'modules.customerAddressesImport.stepNames.2': 'Definice sloupců',
    'modules.customerAddressesImport.stepNames.3': 'Souhrn',

    'modules.customerAddressesImport.columnTypes.name': 'Název',
    'modules.customerAddressesImport.columnTypes.code': 'Kód',
    'modules.customerAddressesImport.columnTypes.customer': 'Zákazník',
    'modules.customerAddressesImport.columnTypes.addressType': 'Typ adresy',
    'modules.customerAddressesImport.columnTypes.street': 'Ulice',
    'modules.customerAddressesImport.columnTypes.zipcode': 'PSČ',
    'modules.customerAddressesImport.columnTypes.city': 'Město',
    'modules.customerAddressesImport.columnTypes.country': 'Země',
    'modules.customerAddressesImport.columnTypes.gps': 'GPS',
    'modules.customerAddressesImport.columnTypes.email': 'E-mail',
    'modules.customerAddressesImport.columnTypes.phone': 'Telefon',
    'modules.customerAddressesImport.columnTypes.note': 'Poznámka',
    'modules.customerAddressesImport.columnTypes.booking': 'Rezervace',
    'modules.customerAddressesImport.columnTypes.bookingUrl': 'URL adresa pro rezervaci',
    'modules.customerAddressesImport.columnTypes.bookingNote': 'Poznámka k rezervaci',


    // Notifications
    'modules.notifications.heading': 'Notifikace',

    // Permissions
    'permissionGroups.transports': 'Přepravy',
    'permissionGroups.transports_cost': 'Přepravy - Cena',
    'permissionGroups.transport_to_vehicle': 'Přepravy - Odeslání do vozidla',
    'permissionGroups.transports_customer': 'Přepravy - Zákazník',
    'permissionGroups.transports_customer_cost': 'Přepravy - Zákazník - Cena',
    'permissionGroups.transports_spedition': 'Přepravy - Dopravce',
    'permissionGroups.transports_spedition_cost': 'Přepravy - Dopravce - Cena',
    'permissionGroups.transports_events': 'Přepravy - Události',
    'permissionGroups.transports_log': 'Přepravy - Log',
    'permissionGroups.transports_routes': 'Přepravy - Definice trasy',
    'permissionGroups.transports_goods': 'Přepravy - Definice nákladu',
    'permissionGroups.transports_parts': 'Přepravy - Kombinovaná přeprava (části)',
    'permissionGroups.transports_files': 'Přepravy - Soubory',
    'permissionGroups.transports_allowances': 'Přepravy - Stravné a odměny',
    'permissionGroups.transports_scoring': 'Přepravy - Vyhodnocení',
    'permissionGroups.transports_template': 'Přepravy - Šablony',
    'permissionGroups.transports_template_cost': 'Přepravy - Šablony - Cena',
    'permissionGroups.transports_template_customer': 'Přepravy - Šablony - Zákazník',
    'permissionGroups.transports_template_customer_cost': 'Přepravy - Šablony - Zákazník - Cena',
    'permissionGroups.transports_template_routes': 'Přepravy - Šablony - Definice trasy',
    'permissionGroups.transports_template_allowances': 'Přepravy - Šablony - Stravné a odměny',
    'permissionGroups.transporeon_offers': 'Přepravy - Transporeon nabídky',
    'permissionGroups.tracing': 'Přepravy - Trasování',
    'permissionGroups.board': 'Dispečerská plachta',
    'permissionGroups.here_map': 'Mapa',
    'permissionGroups.report_transports': 'Reporty - Přepravy - Hospodářské výsledky',
    'permissionGroups.report_spedition_transports': 'Reporty - Přepravy - Hospodářské výsledky spedičních přeprav',
    'permissionGroups.report_transport_changes': 'Reporty - Přepravy - Sledování změn',
    'permissionGroups.report_transport_invoicing': 'Reporty - Přepravy - Fakturace',
    'permissionGroups.report_transport_orders': 'Reporty - Přepravy - Objednávky',
    'permissionGroups.report_vehicle_kilometers_total': 'Reporty - Vozidla - Celkové kilometry',
    'permissionGroups.report_vehicle_performance': 'Reporty - Vozidla - Výkonové ukazatele',
    'permissionGroups.report_vehicle_traffic': 'Reporty - Vozidla - Provozní ukazatele',
    'permissionGroups.report_vehicle_checks': 'Reporty - Vozidla - Kontroly a závady',
    'permissionGroups.report_vehicle_fuelings': 'Reporty - Vozidla - Tankování a spotřeba',
    'permissionGroups.report_vehicle_results': 'Reporty - Vozidla - Obraty',
    'permissionGroups.report_driver_allowances': 'Reporty - Řidiči - Stravné a odměny',
    'permissionGroups.report_driver_performance_analysis': 'Reporty - Řidiči - Analýza výkonu',
    'permissionGroups.report_driver_hourly_overview': 'Reporty - Řidiči - Hodinový přehled',
    'permissionGroups.report_driver_timesheets': 'Reporty - Řidiči - Pracovní výkaz',
    'permissionGroups.report_driver_results': 'Reporty - Řidiči - Obraty',
    'permissionGroups.report_driver_costs': 'Reporty - Řidiči - Náklady',
    'permissionGroups.report_trailer_results': 'Reporty - Návěsy - Obraty',
    'permissionGroups.report_trailer_costs': 'Reporty - Návěsy - Náklady',
    'permissionGroups.report_trailer_fuelings': 'Reporty - Návěsy - Tankování',
    'permissionGroups.report_vehicle_costs': 'Reporty - Vozidla - Náklady',
    'permissionGroups.report_vehicle_profits': 'Reporty - Vozidla - Hospodářské výsledky',
    'permissionGroups.report_cost_center_profits': 'Reporty - Nákladová střediska - Hospodářské výsledky',
    'permissionGroups.report_cost_center_costs': 'Reporty - Nákladová střediska - Náklady',
    'permissionGroups.report_customer_results': 'Reporty - Zákazníci - Obraty',
    'permissionGroups.report_customer_plans': 'Reporty - Zákazníci - Plán přeprav',
    'permissionGroups.report_customer_weekly_plans': 'Reporty - Zákazníci - Týdenní plán',
    'permissionGroups.report_customer_monthly_plans': 'Reporty - Zákazníci - Měsíční plán',
    'permissionGroups.report_carrier_results': 'Reporty - Spediční dopravci - Náklady',
    'permissionGroups.report_carrier_scoring': 'Reporty - Spediční dopravci - Hodnocení',
    'permissionGroups.report_carrier_usage': 'Reporty - Spediční dopravci - Využití',
    'permissionGroups.report_dispatcher_kpi': 'Reporty - Dispečeři - KPI',
    'permissionGroups.report_app_usage': 'Reporty - Ostatní - Využití aplikace',
    'permissionGroups.report_cost_center_results': 'Reporty - Nákladová střediska - Obraty',
    'permissionGroups.report_cost_center_traffic': 'Reporty - Nákladová střediska - Provozní ukazatele',
    'permissionGroups.report_cost_center_performance': 'Reporty - Nákladová střediska - Výkonové ukazatele',
    'permissionGroups.report_customer_turnovers': 'Reporty - Ostatní - Zákazníci',
    'permissionGroups.report_empty_kilometers': 'Reporty - Ostatní - Nájezdové kilometry',
    'permissionGroups.report_pallets': 'Reporty - Ostatní - Paletová evidence',
    'permissionGroups.report_invoicing_summary': 'Fakturace - Sumář',
    'permissionGroups.invoicing_orders': 'Fakturace - Objednávky',
    'permissionGroups.invoicing_invoices': 'Fakturace - Faktury vydané',
    'permissionGroups.invoicing_received_invoices': 'Fakturace - Faktury přijaté',
    'permissionGroups.vehicles': 'Vozidla',
    'permissionGroups.vehicle_history': 'Vozidla - Historie',
    'permissionGroups.vehicle_documents': 'Vozidla - Dokumenty',
    'permissionGroups.vehicle_equipments': 'Vozidla - Vybavení',
    'permissionGroups.vehicle_events': 'Vozidla - Události',
    'permissionGroups.vehicle_fuelings': 'Vozidla - Tankování',
    'permissionGroups.vehicle_positions': 'Vozidla - Pozice',
    'permissionGroups.vehicle_messages': 'Chat',
    'permissionGroups.trailers': 'Návěsy',
    'permissionGroups.trailer_history': 'Návěsy - Historie',
    'permissionGroups.trailer_documents': 'Návěsy - Dokumenty',
    'permissionGroups.trailer_equipments': 'Návěsy - Vybavení',
    'permissionGroups.trailer_events': 'Návěsy - Události',
    'permissionGroups.drivers': 'Řidiči',
    'permissionGroups.driver_history': 'Řidiči - Historie',
    'permissionGroups.driver_documents': 'Řidiči - Dokumenty',
    'permissionGroups.driver_equipments': 'Řidiči - Vybavení',
    'permissionGroups.driver_events': 'Řidiči - Události',
    'permissionGroups.driver_allowances': 'Řidiči - Stravné a odměny',
    'permissionGroups.driver_timesheets': 'Řidiči - Pracovní výkazy',
    'permissionGroups.customers': 'Adresář - Zákazníci',
    'permissionGroups.customer_addresses': 'Adresář - Zákazníci - Adresy',
    'permissionGroups.customer_contacts': 'Adresář - Zákazníci - Kontakty',
    'permissionGroups.customer_plans': 'Adresář - Zákazníci - Plány přeprav',
    'permissionGroups.addresses': 'Adresář - Adresy',
    'permissionGroups.carriers': 'Adresář - Spediční dopravci',
    'permissionGroups.carrier_contacts': 'Adresář - Spediční dopravci - Kontakty',
    'permissionGroups.border_crossings': 'Adresář - Hraniční přechody',
    'permissionGroups.transport_conditions': 'Nastavení - Přepravy - Přepravní podmínky',
    'permissionGroups.transport_types': 'Nastavení - Přepravy - Typy přeprav',
    'permissionGroups.cargo_types': 'Nastavení - Přepravy - Typy nákladů',
    'permissionGroups.goods_types': 'Nastavení - Přepravy - Typy zboží',
    'permissionGroups.packaging_types': 'Nastavení - Přepravy - Typy obalů',
    'permissionGroups.surcharge_types': 'Nastavení - Přepravy - Typy příplatků',
    'permissionGroups.transport_scoring': 'Nastavení - Přepravy - Vyhodnocení přeprav',
    'permissionGroups.tax_rates': 'Nastavení - Fakturace - Daňové sazby',
    'permissionGroups.payment_methods': 'Nastavení - Fakturace - Platební metody',
    'permissionGroups.invoice_item_types': 'Nastavení - Fakturace - Typy pohybů',
    'permissionGroups.vehicle_types': 'Nastavení - Vozový park - Typy vozidel',
    'permissionGroups.trailer_types': 'Nastavení - Vozový park - Typy návěsů',
    'permissionGroups.document_types': 'Nastavení - Vozový park - Typy dokumentů',
    'permissionGroups.equipments': 'Nastavení - Vozový park - Vybavení',
    'permissionGroups.companies': 'Nastavení - Adresář - Společnosti',
    'permissionGroups.company_cost_centers': 'Nastavení - Adresář - Společnosti - Nákladová střediska',
    'permissionGroups.company_bank_accounts': 'Nastavení - Adresář - Společnosti - Bankovní účty',
    'permissionGroups.company_default_bank_accounts': 'Nastavení - Adresář - Společnosti - Bankovní účty - Nastavení výchozích účtů',
    'permissionGroups.company_numberings': 'Nastavení - Adresář - Společnosti - Číselné řady',
    'permissionGroups.company_settings': 'Nastavení - Adresář - Společnosti - Nastavení',
    'permissionGroups.countries': 'Nastavení - Adresář - Země',
    'permissionGroups.currencies': 'Nastavení - Adresář - Měny',
    'permissionGroups.customer_address_types': 'Nastavení - Adresář - Typy adres',
    'permissionGroups.carrier_scoring': 'Nastavení - Adresář - Vyhodnocení dopravců',
    'permissionGroups.users': 'Nastavení - Uživatelé',
    'permissionGroups.users_login_log': 'Nastavení - Uživatelé - Log přihlášení',
    'permissionGroups.user_roles': 'Nastavení - Uživatelé - Uživatelské role',
    'permissionGroups.user_logins': 'Nastavení - Uživatelé - Log přihlášení',
    'permissionGroups.blocked_ips': 'Nastavení - Uživatelé - Blokované IP adresy',
    'permissionGroups.general_settings_transports': 'Nastavení - Obecné nastavení - Přepravy / trasování',
    'permissionGroups.general_settings_email': 'Nastavení - Obecné nastavení - Mailing',
    'permissionGroups.general_settings_invoices': 'Nastavení - Obecné nastavení - Fakturace',
    'permissionGroups.general_settings_security': 'Nastavení - Obecné nastavení - Bezpečnost',
    'permissionGroups.mails_user_blocked': 'Email administrátorovi o zablokování uživatele',
    'permissionGroups.mails_ip_address_blocked': 'Email administrátorovi o zablokování ip addressy',
    'permissionGroups.notification': 'Notifikace',
    'permissionGroups.diet_rates': 'Nastavení - Sazby stravného',
    'permissionGroups.cost_types': 'Nastavení - Typy nákladů',
    'permissionGroups.costs': 'Náklady',
    'permissionGroups.report_cost_center_cost': 'Reporty - Nákladová střediska - Náklady',
    'permissionGroups.report_cost_type_costs': 'Reporty - Náklady - Dle typu',
    'permissionGroups.sales_types': 'Nastavení - Typy tržeb',
    'permissionGroups.drivers_types': 'Nastavení - Typy řidičů',
    'permissionGroups.sales': 'Tržby',
    'permissionGroups.driver_surcharge_types': 'Typy příplatků',
    'permissionGroups.report_driver_surcharges': 'Report - Řidiči - Příplatky',
    'permissionGroups.driver_surcharges': 'Řidiči - Příplatky',
    'permissionGroups.report_customer_speditions': 'Reporty - Zákazníci - Spediční přepravy',
    'permissionGroups.units': 'Sklad - Jednotky',
    'permissionGroups.warehousing_warehouses': 'Sklad - Sklady',
    'permissionGroups.warehousing_items': 'Sklad - Skladové karty',
    'permissionGroups.warehousing_receipts': 'Sklad - Příjemky',
    'permissionGroups.warehousing_issues': 'Sklad - Výdejky',
    'permissionGroups.service_jobs': 'Servis - Servisní úkony',
    'permissionGroups.service_workers': 'Servis - Servisní technici',
    'permissionGroups.service_orders': 'Servis - Servisní objednávky',

    'permissions.read': 'Čtení',
    'permissions.create': 'Vytváření',
    'permissions.update': 'Editace',
    'permissions.delete': 'Mazání',
    'permissions.write': 'Psát',
    'permissions.copy_to_clipboard': 'Zkopírovat do schránky',
    'permissions.export': 'Export',
    'permissions.import': 'Import',
    'permissions.restore': 'Obnovení',
    'permissions.download': 'Stáhnout',
    'permissions.send': 'Poslat',
    'permissions.receive': 'Příjmout',
    'permissions.export_to_accounting': 'Exportovat do účetnictví',
    'permissions.approval': 'Schválení',

    'permissions.no_permission': 'Nemáte oprávnění k prohlížení této stránky.',

    // Travel allowances
    'modules.reportAllowances.heading': 'Reporty - Stravné a odměny',
    'modules.reportAllowances.drivers': 'Jméno řidiče',
    'modules.reportAllowances.months': 'Měsíc',
    'modules.reportAllowances.spendingMoney': 'Kapesné',
    'modules.reportAllowances.allowances': 'Stravné a odměny',
    'modules.reportAllowances.additionalAllowances': 'Odměny',
    'modules.reportAllowances.noAdditionalAllowances': 'Źádné odměny',
    'modules.reportAllowances.addAdditionalAllowance': 'Přidat odměnu',
    'modules.reportAllowances.additionalAllowanceAmount': 'Výše odměny',
    'modules.reportAllowances.additionalAllowanceDate': 'Datum přiznané odměny',
    'modules.reportAllowances.calculationMethods.0': 'Časový',
    'modules.reportAllowances.calculationMethods.1': 'Paušální',
    'modules.reportAllowances.calculationMethods.2': 'Denní (pracovní dny)',
    'modules.reportAllowances.calculationMethods.3': 'Denní (víkend, svátky)',
    'modules.reportAllowances.calculationMethods.4': 'Měsíční',
    'modules.reportAllowances.calculationMethods.5': 'Kilometrový',

    // Driver performance analysis
    'modules.driverPerformanceAnalysis.period': 'Období',
    'modules.driverPerformanceAnalysis.driver': 'Jméno řidiče',
    'modules.driverPerformanceAnalysis.drivingStyle': 'Styl jízdy',
    'modules.driverPerformanceAnalysis.degreeOfDifficulty': 'Obtížnost',
    'modules.driverPerformanceAnalysis.totalDistance': 'Ujeté km',
    'modules.driverPerformanceAnalysis.speedOver85DistancePerc': '> 85 km/h',
    'modules.driverPerformanceAnalysis.overallConsumption': 'Spotřeba celková',
    'modules.driverPerformanceAnalysis.driveConsumption': 'Spotřeba při jizdě',
    'modules.driverPerformanceAnalysis.serviceBrakeDistance': 'Provozní brzda',
    'modules.driverPerformanceAnalysis.handBrakeUsageCount': 'Parkovací brzda',

    // Period picker
    'modules.periodPicke.heading': 'Období',
    'modules.periodPicke.year': 'Rok',
    'modules.periodPicke.half': 'Půlrok',
    'modules.periodPicke.quarter': 'Kvartál',
    'modules.periodPicke.month': 'Měsíc',
    'modules.periodPicke.week': 'Týden',
    'modules.periodPicke.day': 'Den',
    'modules.periodPicke.period': 'Vlastní období',
    'modules.periodPicke.weekShort': 'T',

    // Border crossings
    'modules.borderCrossings.heading': 'Hraniční přechody',

    // Tooltips
    'tooltips.shortAscending': 'Seřadit vzestupně',
    'tooltips.shortDescending': 'Seřadit sestupně',

    // Map
    'modules.map.poisLoading': 'Načítání bodů zájmů',

    // Vehicle fueling
    'modules.vehicleFuelings.heading': 'Tankování',
    'modules.vehicleFuelings.importHistory': 'ImpoHistorie importů',
    'modules.vehicleFuelings.drive': 'JIZ',
    'modules.vehicleFuelings.night': 'NOC',
    'modules.vehicleFuelings.weekend': 'VIK',
    'modules.vehicleFuelings.holiday': 'SVA',
    'modules.vehicleFuelings.otherJob': 'JP',
    'modules.vehicleFuelings.vacation': 'D',
    'modules.vehicleFuelings.paidVacation': 'PV',
    'modules.vehicleFuelings.unpaidVacation': 'NV',
    'modules.vehicleFuelings.useOvertime': 'CP',
    'modules.vehicleFuelings.disease': 'N',
    'modules.vehicleFuelings.absence': 'A',
    'modules.vehicleFuelings.holidayNotWorking': 'SN',
    'modules.vehicleFuelings.driveOvertimesHolidayNotWorking': 'JIZ+JP +CP+SN',
    'modules.vehicleFuelings.overtime': 'PRES',
    // Vehicle fueling - import
    'modules.vehicleFuelings.firstImportLine': 'První záznam importu',
    'modules.vehicleFuelings.numberedValues':
        'Zdá se, že produkty jsou označeny kódy. Zvolte nebo importujte seznam produktů čerpací stanice.',
    // Vehicle fueling - import - steps
    'modules.vehicleFuelings.importStep1': 'Importovat záznamy tankování - Volba souboru',
    'modules.vehicleFuelings.importStep2': 'Importovat záznamy tankování - Přiřazení položek',
    'modules.vehicleFuelings.importStep3': 'Importovat záznamy tankování - Přiřazení produktů',
    'modules.vehicleFuelings.importStep4': 'Importovat záznamy tankování - Náhled importu',
    'modules.vehicleFuelings.importProductsStep1': 'Importovat produkty tankování - Volba souboru',
    'modules.vehicleFuelings.importProductsStep2': 'Importovat produkty tankování - Přiřazení položek',
    'modules.vehicleFuelings.importProductsStep3': 'Importovat produkty tankování - Náhled importu',
    // Vehicle fueling - import - infolog
    'modules.vehicleFuelings.infolog.notFound': 'V importovaném souboru hodnota nenalezena',
    'modules.vehicleFuelings.infolog.notFoundObject': 'K záznamu nenalezena odpovídajcí položka',
    'modules.vehicleFuelings.infolog.fromFile': 'Importováno ze souboru',
    'modules.vehicleFuelings.infolog.fromPoint': 'Nalezeno dle pozice',
    'modules.vehicleFuelings.infolog.fromLastPoint': 'Nalezeno dle poslední známé pozice',
    'modules.vehicleFuelings.infolog.empty': 'Prázdné',
    'modules.vehicleFuelings.infolog.format': 'Špatný formát',
    'modules.vehicleFuelings.infolog.timeNotFound': 'Časový údaj nenalezen',
    'modules.vehicleFuelings.infolog.cantFind': 'Nelze nalézt',
    // Vehicle positions
    'modules.vehiclePositions.heading': 'Pozice',
    // Tracing
    'modules.tracing.heading': 'Trasování',
    'modules.tracing.transportDetailHeading': 'Údaje o přepravě',
    'modules.tracing.routeDefinitionHeading': 'Definice trasy',
    'modules.tracing.detailHeading': 'Detail trasy',
    'modules.tracing.totalDistance': 'Celková vzdálenost',
    'modules.tracing.totalTime': 'Čas přepravy',
    'modules.tracing.shippingTime': 'Čas jízdy + přestávky',
    'modules.tracing.contractPrice': 'Cena zakázky',
    'modules.tracing.totalCost': 'Celkové náklady',
    'modules.tracing.economicResults': 'Hosp. výsledky',
    'modules.tracing.additionalCosts': 'Další náklady',
    'modules.tracing.fixedRate': 'Fixní sazba',
    'modules.tracing.extraCosts': 'Extra náklady',
    'modules.tracing.waypoints': 'Průjezdné body',
    'modules.tracing.timePlane': 'Podrobný časový plán',
    'modules.tracing.map.vehiclesLoading': 'Načítání vozidel',
    'modules.tracing.map.vehicleSearch': 'Hledání vozidla',
    'modules.tracing.warningApproachTime': 'Nájezd není v časovém rozmezí',
    'modules.tracing.warningShippingTime': 'Přeprava není v časovém rozmezí',
    'modules.tracing.warningTime': 'Přepravu nelze vytvořit! Nastavený časový rozsah nestačí pro celkový čas (jízda + přestávky)',
    'modules.tracing.warningCustomer': 'Než budete pokračovat, zvolte prosím zákazníka',
    'modules.tracing.warningProfit': 'Zisk musí být minimálně {profit}.',

    'modules.units.heading': 'Sklad - Jednotky',
    'modules.warehousingWarehouses.heading': 'Sklad - Sklady',
    'modules.warehousingItems.heading': 'Sklad - Skladové karty',
    'modules.warehousingReceipts.heading': 'Sklad - Příjemky',
    'modules.warehousingIssues.heading': 'Sklad - Výdejky',
    'modules.serviceJobs.heading': 'Servis - Servisní úkony',
    'modules.serviceWorkers.heading': 'Servis - Servisní technici',
    'modules.serviceOrders.heading': 'Servis - Servisní objednávky',

    // Notifications
    'notifications.allItems': 'Všechny notifikace',
    'notifications.unreadItems': 'Máte {count} nepřečtených zpráv',
    'notifications.noItemsFound': 'Nebyly nalezeny žádné notifikace',
    'notifications.markAsRead': 'Označit jako přečtené',

    'permissions.general': 'Obecné',
    'permissions.transports_load_arrived': 'Příjezd na nakládku',
    'permissions.transports_load_wait': 'Čekání na nakládku',
    'permissions.transports_load_finished': 'Konec nakládky',
    'permissions.transports_unload_arrival': 'Příjezd na vykládku',
    'permissions.transports_unload_wait': 'Čekání na vykládku',
    'permissions.transports_unload_finished': 'Konec vykládky',
    'permissions.transports_state_changed_processing': 'Stavu přepravy: Zpracovává se',
    'permissions.transports_state_changed_delivered': 'Stavu přepravy: Dokončena',
    'permissions.transports_state_changed_canceled': 'Stavu přepravy: Stornována',
    'permissions.transporeon_offers': 'Nová Transporeon nabídka',
    'permissions.vehicle_message': 'Chat',
}

export default translations
