import * as actionTypes from './actionTypes'

// Fetch

export const fetchTrailerCurrentPositions = () => ({
    type: actionTypes.FETCH,
})

export const fetchTrailerCurrentPositionsFulfilled = trailerCurrents => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: trailerCurrents,
})

export const fetchTrailerCurrentPositionsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTrailerCurrentPositionsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})
