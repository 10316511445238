import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import * as actionCreators from './actionCreators'
import * as actionTypes from './actionTypes'

// Fetch

const fetchInvoicingReceivedInvoicesEpic = (action$, store) => {
    const tableIdentifier = 'invoicing_received_invoices_list'

    return action$
        .ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'POST',
                path: `invoicing-received-invoices/list?page=${modelState.getIn(['pagination', 'current']) + 1}`,
                body: {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const observables = [
                            Observable.of(actionCreators.fetchInvoicingReceivedInvoicesFulfilled(ajaxResponse.response.data)),
                            Observable.of(
                                tableModelActionCreators.updatePagination(
                                    tableIdentifier,
                                    ajaxResponse.response.last_page,
                                    ajaxResponse.response.current_page - 1,
                                    ajaxResponse.response.total
                                )
                            ),
                        ]

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchInvoicingReceivedInvoicesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchInvoicingReceivedInvoiceEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().taxRates.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `invoicing-received-invoices/${action.payload ? action.payload : 0}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.fetchInvoicingReceivedInvoiceFulfilled(ajaxResponse.response.item, ajaxResponse.response.support_data)
                        )
                    )
                    .catch(error => Observable.of(actionCreators.fetchInvoicingReceivedInvoiceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveInvoicingReceivedInvoiceEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().taxRates.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = { ...action.payload.values }
            if (action.payload.ignoreDuplicity) {
                values.ignore_duplicity = 1
            }

            let path = `invoicing-received-invoices`
            let method = 'POST'

            if (values.id) {
                path = `invoicing-received-invoices/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        if (ajaxResponse.response.duplicity_found) {
                            return Observable.concat(
                                Observable.of(actionCreators.saveInvoicingReceivedInvoiceDuplicityFound(ajaxResponse.response.duplicities))
                            )
                        }

                        return Observable.of(
                            actionCreators.saveInvoicingReceivedInvoiceFulfilled(ajaxResponse.response.item, ajaxResponse.response.support_data)
                        )
                    })
                    .catch(error => Observable.of(actionCreators.saveInvoicingReceivedInvoiceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteReceivedInvoiceEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().vehicles.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `invoicing-received-invoices/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteReceivedInvoiceFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteReceivedInvoiceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Restore

const restoreReceivedInvoiceEpic = (action$, store) =>
    action$
        .ofType(actionTypes.RESTORE)
        .filter(() => !store.getState().invoicingReceivedInvoices.getIn(['restoring', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.RESTORE_STARTED,
                }),
                request({
                    path: `invoicing-received-invoices/${action.payload}/restore`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        return Observable.concat(Observable.of(actionCreators.restoreReceivedInvoiceFulfilled(ajaxResponse.response.item)))
                    })
                    .catch(error => Observable.of(actionCreators.restoreReceivedInvoiceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.RESTORE_CANCELLED))
            )
        )

// Export

const exportInvoicingReceivedInvoicesEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `invoicing-received-invoices/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Fetch detail

const fetchDataForInvoicingEpic = action$ =>
    action$.ofType(actionTypes.FETCH_DETAIL).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DETAIL_STARTED,
            }),
            request({
                method: 'GET',
                path: `invoicing-received-invoices/detail?id=${action.payload.receivedInvoiceId}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReceivedInvoiceDetailFulfilled(ajaxResponse.response.data)))
                .catch(error => Observable.of(actionCreators.fetchReceivedInvoiceDetailRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_DETAIL_CANCELLED))
        )
    )

// Load customer info

const loadSupplierInfoEpic = action$ =>
    action$.ofType(actionTypes.LOAD_SUPPLIER_INFO).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.LOAD_SUPPLIER_INFO_STARTED,
            }),
            request({
                method: 'GET',
                path: `invoicing-received-invoices/supplier-info?supplier_id=${action.payload.supplierId}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.loadSupplierInfoFulfilled(ajaxResponse.response.data)))
                .catch(error => Observable.of(actionCreators.loadSupplierInfoRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.LOAD_SUPPLIER_INFO_CANCELLED))
        )
    )

// Approve

const approveReceivedInvoiceEpic = (action$, store) => {
    const tableIdentifier = 'invoicing_received_invoices_list'

    return action$
        .ofType(actionTypes.APPROVE)
        .filter(() => !store.getState().vehicles.getIn(['approve', 'inProgress']))
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            return Observable.concat(
                Observable.of({
                    type: actionTypes.APPROVE_STARTED,
                }),
                request({
                    method: 'POST',
                    path: `invoicing-received-invoices/${action.payload}/approve`,
                    body: {
                        filters: modelState.get('filters').toJS(),
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.approveReceivedInvoiceFulfilled(ajaxResponse.response.data)))
                    .catch(error => Observable.of(actionCreators.approveReceivedInvoiceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.APPROVE_CANCELLED))
            )
        })
}

// Set as paid

const setReceivedInvoiceAsPaidEpic = (action$, store) => {
    const tableIdentifier = 'invoicing_received_invoices_list'

    return action$
        .ofType(actionTypes.SET_AS_PAID)
        .filter(() => !store.getState().vehicles.getIn(['setAsPaid', 'inProgress']))
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SET_AS_PAID_STARTED,
                }),
                request({
                    method: 'POST',
                    path: `invoicing-received-invoices/${action.payload.id}/set-as-paid`,
                    body: {
                        filters: modelState.get('filters').toJS(),
                        date: action.payload.date,
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.setReceivedInvoiceAsPaidFulfilled(ajaxResponse.response.data)))
                    .catch(error => Observable.of(actionCreators.setReceivedInvoiceAsPaidRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SET_AS_PAID_CANCELLED))
            )
        })
}

// Set as unpaid

const setReceivedInvoiceAsUnpaidEpic = (action$, store) => {
    const tableIdentifier = 'invoicing_received_invoices_list'

    return action$
        .ofType(actionTypes.SET_AS_UNPAID)
        .filter(() => !store.getState().vehicles.getIn(['setAsUnpaid', 'inProgress']))
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SET_AS_UNPAID_STARTED,
                }),
                request({
                    method: 'POST',
                    path: `invoicing-received-invoices/${action.payload.id}/set-as-unpaid`,
                    body: {
                        filters: modelState.get('filters').toJS(),
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.setReceivedInvoiceAsUnpaidFulfilled(ajaxResponse.response.data)))
                    .catch(error => Observable.of(actionCreators.setReceivedInvoiceAsUnpaidRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SET_AS_UNPAID_CANCELLED))
            )
        })
}

// Load customer info

const loadTransportInfoEpic = action$ =>
    action$.ofType(actionTypes.LOAD_TRANSPORT_INFO).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.LOAD_TRANSPORT_INFO_STARTED,
            }),
            request({
                method: 'GET',
                path: `invoicing-received-invoices/transport-info?transport_id=${action.payload.transportId}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.loadTransportInfoFulfilled(ajaxResponse.response.data)))
                .catch(error => Observable.of(actionCreators.loadTransportInfoRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.LOAD_TRANSPORT_INFO_CANCELLED))
        )
    )

// Create credit note

const createCreditNoteEpic = action$ =>
    action$.ofType(actionTypes.CREATE_CREDIT_NOTE).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.CREATE_CREDIT_NOTE_STARTED,
            }),
            request({
                method: 'POST',
                path: `invoicing-received-invoices/${action.payload.invoiceId}/credit-note`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.createCreditNoteFulfilled(ajaxResponse.response.item)))
                .catch(error => Observable.of(actionCreators.createCreditNoteRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.CREATE_CREDIT_NOTE_CANCELLED))
        )
    )

// Fetch files

const fetchReceivedInvoiceFilesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_FILES)
        .filter(() => !store.getState().invoicingReceivedInvoices.getIn(['files', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_FILES_STARTED,
                }),
                request({
                    path: `invoicing-received-invoices/${action.payload}/files`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReceivedInvoiceFilesFulfilled(ajaxResponse.response.files)))
                    .catch(error => Observable.of(actionCreators.fetchReceivedInvoiceFilesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_FILES_CANCELLED))
            )
        )

// Upload files

const uploadReceivedInvoiceFilesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.UPLOAD_FILES)
        .filter(() => !store.getState().invoicingReceivedInvoices.getIn(['files', 'saving']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.UPLOAD_FILES_STARTED,
                }),
                request({
                    path: `invoicing-received-invoices/${action.payload.id}/files`,
                    method: 'POST',
                    body: { ...action.payload },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.uploadReceivedInvoiceFilesFulfilled(ajaxResponse.response.files)))
                    .catch(error => Observable.of(actionCreators.uploadReceivedInvoiceFilesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.UPLOAD_FILES_CANCELLED))
            )
        )

// Delete file

const deleteReceivedInvoiceFileEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE_FILE)
        .filter(() => !store.getState().invoicingReceivedInvoices.getIn(['files', 'deleting']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_FILE_STARTED,
                }),
                request({
                    path: `invoicing-received-invoices/${action.payload.id}/files/${action.payload.file_id}`,
                    method: 'DELETE',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.deleteReceivedInvoiceFileFulfilled(ajaxResponse.response.files)))
                    .catch(error => Observable.of(actionCreators.deleteReceivedInvoiceFileRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_FILE_CANCELLED))
            )
        )

// Export to accounting

const exportInvoicingReceivedInvoicesToAccountingEpic = (action$, store) =>
    action$
        .ofType(actionTypes.EXPORT_TO_ACCOUNTING)
        .filter(() => !store.getState().vehicles.getIn(['exportToAccounting', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.EXPORT_TO_ACCOUNTING_STARTED,
                }),
                request({
                    path: `invoicing-received-invoices/export-to-accounting`,
                    method: 'POST',
                })
                    .switchMap(() => Observable.of(actionCreators.exportInvoicingReceivedInvoicesToAccountingFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.exportInvoicingReceivedInvoicesToAccountingRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.EXPORT_TO_ACCOUNTING_CANCELLED))
            )
        )

// Check duplicity

const checkReceivedInvoiceDuplicityEpic = (action$, store) =>
    action$
        .ofType(actionTypes.CHECK_DUPLICITY)
        .filter(() => !store.getState().customers.getIn(['checkingDuplicity', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.CHECK_DUPLICITY_STARTED,
                }),
                request({
                    path: `invoicing-received-invoices/check-duplicity`,
                    method: 'POST',
                    body: action.payload,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.checkReceivedInvoiceDuplicityFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.checkReceivedInvoiceDuplicityRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.CHECK_DUPLICITY_CANCELLED))
            )
        )

export default [
    fetchInvoicingReceivedInvoicesEpic,
    fetchInvoicingReceivedInvoiceEpic,
    saveInvoicingReceivedInvoiceEpic,
    deleteReceivedInvoiceEpic,
    restoreReceivedInvoiceEpic,
    exportInvoicingReceivedInvoicesEpic,
    fetchDataForInvoicingEpic,
    loadSupplierInfoEpic,
    approveReceivedInvoiceEpic,
    setReceivedInvoiceAsPaidEpic,
    setReceivedInvoiceAsUnpaidEpic,
    loadTransportInfoEpic,
    createCreditNoteEpic,
    fetchReceivedInvoiceFilesEpic,
    uploadReceivedInvoiceFilesEpic,
    deleteReceivedInvoiceFileEpic,
    exportInvoicingReceivedInvoicesToAccountingEpic,
    checkReceivedInvoiceDuplicityEpic,
]
