import React from 'react'
import { Route, Switch } from 'react-router'
import SalesList from './SalesList'
import SalesEdit from './SalesEdit'
import SalesImport from './SalesImport'

const Sales = props => (
    <Switch>
        <Route exact path={props.match.url} component={SalesList} />
        <Route exact path={`${props.match.url}/new`} component={SalesEdit} />
        <Route exact path={`${props.match.url}/import`} component={SalesImport} />
        <Route exact path={`${props.match.url}/:salesID`} component={SalesEdit} />
    </Switch>
)

export default Sales
