import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

import { DispatchersBox } from '../../../common/colored_box'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as reportCustomerSpeditionsActionCreators from '../actionCreators'
import { getYear, getData, getFetching, getDetailsData, getDetailsFetching } from '../selectors'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import {TableFilterCheckboxField, TableFilterInputField, TableFilterSelectField} from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {Link} from "react-router-dom";

const tableIdentifier = 'report_customer_speditions'

const componentIdentifier = 'report_customer_kilometers_total'
const prerequisites = ['dispatchers', 'company_cost_centers']

class ReportCustomerSpeditions extends Component {
    state = {
        modalDetails: {
            show: false,
            customerId: null,
        },
    }

    refresh = () => {
        this.props.actions.fetchReportCustomerSpeditions(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    // Export
    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportCustomerSpeditions(this.props.tableModel.get('filters').toJS())
    }

    handleFilterChanged = (momentFrom, momentTo) => {
        this.props.table.changeFilters([
            {
                column: 'date_from',
                type: TableModelColumnFilteringType.equal,
                value: momentFrom.format('DD.MM.YYYY'),
            },
            {
                column: 'date_to',
                type: TableModelColumnFilteringType.equal,
                value: momentTo.format('DD.MM.YYYY'),
            },
        ])
    }

    // Detail
    handleShowDetails = (e, customerId) => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: true,
                    customerId,
                },
            },
            () => {
                const filters = this.props.tableModel.get('filters').toJS()
                filters.customerId = { value: customerId }

                this.props.actions.fetchReportCustomerSpeditionsDetails(filters)
            }
        )
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: false,
                    customerId: null,
                },
            },
            () => {
                this.props.actions.clearReportCustomerSpeditionsDetails()
            }
        )
    }

    handleExportDetails = e => {
        e && e.preventDefault()

        const filters = this.props.tableModel.get('filters').toJS()
        filters.customerId = { value: this.state.modalDetails.customerId }

        this.props.actions.exportReportCustomerSpeditionsDetails(filters)
    }

    componentDidMount() {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.setConfiguration(
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ],
            false
        )

        this.props.actions.fetchReportCustomerSpeditions(this.props.tableModel.get('filters'))
        this.props.actions.fetchPrerequisites()
    }

    componentWillUnmount() {
        this.props.actions.clearReportCustomerSpeditions()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportCustomerSpeditions.heading' }))

        const data = this.props.data
        const filters = this.props.tableModel.get('filters')

        const stringFrom = filters.getIn(['date_from', 'value'])
        const stringTo = filters.getIn(['date_to', 'value'])

        const momentFrom = stringFrom ? helpers.convertCSDateToMoment(stringFrom) : moment().startOf('month')
        const momentTo = stringTo ? helpers.convertCSDateToMoment(stringTo) : moment().endOf('month')

        let totalTransportCount = 0
        let totalKM = 0
        let totalSales = 0
        let totalCosts = 0

        const detailsData = this.props.detailsData

        let detailsDataTotalKm = 0
        let detailsDataTotalSales = 0
        let detailsDataTotalCosts = 0

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportCustomerSpeditions.heading" />
                                        </span>
                                    </h4>
                                    <div className="pull-left" style={{ marginLeft: '100px' }}>
                                        <span className="pull-left text-gray f-bold" style={{ paddingTop: '10px' }}>
                                            <FormattedMessage id="fields.showIncl" />:
                                        </span>
                                        <div className="pull-left m-l-sm">
                                            <TableFilterCheckboxField
                                                identifier="with_empty"
                                                type={TableModelColumnFilteringType.bool}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                label={this.props.intl.formatMessage({ id: 'fields.showInclEmpty' })}
                                            />
                                        </div>
                                    </div>
                                    <div className="btns-list">

                                        <PeriodPicker
                                            className={'m-r-lg'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_MONTH}
                                            onChange={(momentFrom, momentTo) => this.handleFilterChanged(momentFrom, momentTo)}
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-max wm-300">
                                                    <FormattedMessage id="fields.customer" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.transportCount" />
                                                </th>
                                                <th className="w-160 text-right">
                                                    <FormattedMessage id="fields.km" />
                                                </th>
                                                <th className="w-160 text-right">
                                                    <FormattedMessage id="fields.sales" />
                                                </th>
                                                <th className="w-160 text-right">
                                                    <FormattedMessage id="fields.costs" />
                                                </th>
                                                <th className="w-160 text-right">
                                                    <FormattedMessage id="fields.profit" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-max wm-120">
                                                    <TableFilterInputField
                                                        identifier="customer_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-120" />
                                                <th className="w-120" />
                                                <th className="w-160" />
                                                <th className="w-160" />
                                                <th className="w-160" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.customers &&
                                                data.customers.map(customer => {
                                                    const transportCount = customer.transport_count
                                                    const km = customer.km
                                                    const sales = customer.sales
                                                    const costs = customer.costs
                                                    const result = sales - costs

                                                    totalTransportCount += transportCount
                                                    totalKM += km
                                                    totalSales += sales
                                                    totalCosts += costs

                                                    return (
                                                        <tr key={`customer-${customer.id}`}>
                                                            <td
                                                                className="w-max wm-300 td-clickable"
                                                                onClick={e => {
                                                                    this.handleShowDetails(e, customer.id)
                                                                }}
                                                            >
                                                                {customer.name}
                                                            </td>
                                                            <td className="w-120 text-right">
                                                                {formatters.integerFormatter(transportCount)}
                                                            </td>
                                                            <td className="w-160 text-right">
                                                                {formatters.distanceFormatter(km)}
                                                            </td>
                                                            <td className="w-160 text-right">
                                                                {formatters.priceFormatter(sales)}
                                                            </td>
                                                            <td className="w-160 text-right">
                                                                {formatters.priceFormatter(costs)}
                                                            </td>
                                                            <td className="w-160 text-right">
                                                                <strong>{formatters.priceFormatter(result)}</strong>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-max wm-300">
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                <td className="w-120 text-right">
                                                    <strong>{formatters.integerFormatter(totalTransportCount)}</strong>
                                                </td>
                                                <td className="w-160 text-right">
                                                    <strong>{formatters.distanceFormatter(totalKM)}</strong>
                                                </td>
                                                <td className="w-160 text-right">
                                                    <strong>{formatters.priceFormatter(totalSales)}</strong>
                                                </td>
                                                <td className="w-160 text-right">
                                                    <strong>{formatters.priceFormatter(totalCosts)}</strong>
                                                </td>
                                                <td className="w-160 text-right">
                                                    <strong>{formatters.priceFormatter(totalSales - totalCosts)}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-xl">
                        <Modal.Header closeButton>
                            <Modal.Title>{detailsData ? detailsData.title : null}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LoadingOverlay active={this.props.detailsFetching}>
                                <div className="btns-list">
                                    {detailsData &&
                                        detailsData.transports.length > 0 && (
                                            <PermissionsCheck has={[PERMS.EXPORT]}>
                                                <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExportDetails}>
                                                    <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                                </button>
                                            </PermissionsCheck>
                                        )}
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-80 text-center">
                                                    <FormattedMessage id="fields.date" />
                                                </th>
                                                <th className="w-80 text-center">
                                                    <FormattedMessage id="fields.number" />
                                                </th>
                                                <th className="w-max wm-140">
                                                    <FormattedMessage id="fields.orderNumber" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.loadingCity" />
                                                </th>
                                                <th className="w-60 text-center">
                                                    <FormattedMessage id="fields.loadingCountry" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.unloadingCity" />
                                                </th>
                                                <th className="w-60 text-center">
                                                    <FormattedMessage id="fields.unloadingCountry" />
                                                </th>
                                                <th className="w-240">
                                                    <FormattedMessage id="fields.carrier" />
                                                </th>
                                                <th className="w-80">
                                                    <FormattedMessage id="fields.registration" />
                                                </th>
                                                <th className="w-80 text-right">
                                                    <FormattedMessage id="fields.km" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.price" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.priceCZK" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.costs" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.costsCZK" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.profit" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detailsData &&
                                                detailsData.transports.map(transport => {
                                                    detailsDataTotalKm += transport.km
                                                    detailsDataTotalSales += transport.price_czk
                                                    detailsDataTotalCosts += transport.costs_czk

                                                    const datetime = moment(transport.datetime)

                                                    return (
                                                        <tr key={`transport-${transport.number}`}>
                                                            <td className="w-80 text-center">
                                                                {formatters.dateFormatter(datetime)}
                                                            </td>
                                                            <td className="w-80 text-center">
                                                                <Link to={`/transports/${transport.number}`}>
                                                                    {transport.number}
                                                                </Link>
                                                            </td>
                                                            <td className="w-max wm-140">
                                                                {transport.order_number}
                                                            </td>
                                                            <td className="w-160">
                                                                {transport.loading_city}
                                                            </td>
                                                            <td className="w-60 text-center">
                                                                {transport.loading_country}
                                                            </td>
                                                            <td className="w-160">
                                                                {transport.unloading_city}
                                                            </td>
                                                            <td className="w-60 text-center">
                                                                {transport.unloading_country}
                                                            </td>
                                                            <td className="w-240">
                                                                {transport.carrier}
                                                            </td>
                                                            <td className="w-80">
                                                                {transport.registration}
                                                            </td>
                                                            <td className="w-80 text-right">
                                                                {formatters.distanceFormatter(transport.km)}
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {formatters.priceFormatter(transport.price, '0,0', transport.currency_code)}
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {formatters.priceFormatter(transport.price_czk)}
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {formatters.priceFormatter(transport.costs, '0,0', transport.carrier_currency_code)}
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {formatters.priceFormatter(transport.costs_czk)}
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {formatters.priceFormatter(transport.price_czk - transport.costs_czk)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-max" colSpan={9}>
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                <td className="w-80 text-right">
                                                    <strong>{formatters.distanceFormatter(detailsDataTotalKm)}</strong>
                                                </td>
                                                <td className="w-100 text-right" />
                                                <td className="w-100 text-right">
                                                    <strong>{formatters.priceFormatter(detailsDataTotalSales)}</strong>
                                                </td>
                                                <td className="w-100 text-right" />
                                                <td className="w-100 text-right">
                                                    <strong>{formatters.priceFormatter(detailsDataTotalCosts)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>{formatters.priceFormatter(detailsDataTotalSales - detailsDataTotalCosts)}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default" onClick={this.handleHideDetails}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        year: getYear(state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportCustomerSpeditionsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ReportCustomerSpeditions)
)
