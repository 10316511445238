// Fetch list

export const FETCH = 'units.FETCH'
export const FETCH_STARTED = 'units.FETCH_STARTED'
export const FETCH_FULFILLED = 'units.FETCH_FULFILLED'
export const FETCH_REJECTED = 'units.FETCH_REJECTED'
export const FETCH_CANCELLED = 'units.FETCH_CANCELLED'

export const SORTING_CHANGED = 'units.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'units.FETCH_ONE'
export const FETCH_ONE_STARTED = 'units.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'units.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'units.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'units.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'units.SAVE'
export const SAVE_STARTED = 'units.SAVE_STARTED'
export const SAVE_FULFILLED = 'units.SAVE_FULFILLED'
export const SAVE_REJECTED = 'units.SAVE_REJECTED'
export const SAVE_CANCELLED = 'units.SAVE_CANCELLED'

// Delete

export const DELETE = 'units.DELETE'
export const DELETE_STARTED = 'units.DELETE_STARTED'
export const DELETE_FULFILLED = 'units.DELETE_FULFILLED'
export const DELETE_REJECTED = 'units.DELETE_REJECTED'
export const DELETE_CANCELLED = 'units.DELETE_CANCELLED'

// Export

export const EXPORT = 'units.EXPORT'
export const EXPORT_FULFILLED = 'units.EXPORT_FULFILLED'
