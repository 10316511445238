import { Record } from 'immutable'

export class DriverDocument extends Record({
    id: undefined,
    driver_id: undefined,
    driver: undefined,
    document_type_id: undefined,
    document_type: undefined,
    driver_document_files_count: undefined,
    number: undefined,
    note: undefined,
    date_validity_from: undefined,
    date_validity_to: undefined,
}) {}
