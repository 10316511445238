import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import { Tabs, Tab } from 'react-bootstrap'

import * as formatters from '../../../common/formatters'
import { Form, InputField, SelectField, SubmitButton, SuggestField } from '../../../common/form'
import {
    url, handleCommonEditActions, getPrerequisitesArray, convertCSDateToISO, convertISODateToCS, convertCSDateToMoment, getPrerequisitesPairs
} from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as serviceOrdersActionCreators from '../actionCreators'
import {
    createGetServiceOrderByIDSelector,
    createGetCurrentServiceOrderStatusSelector,
    getServiceJobData,
    getServiceJobFetching,
    getServiceJobError,
    getServiceMaterialData,
    getServiceMaterialFetching,
    getServiceMaterialError,
    getServiceWorkerData,
    getServiceWorkerFetching,
    getServiceWorkerError
} from '../selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import moment from "moment";
import { CustomDatePicker } from "../../../common/custom_date_picker";
import { dateFormatter, floatFormatter, numberFormatter } from "../../../common/formatters";

const componentIdentifier = 'service_orders_edit'
const prerequisites = ['allowed_customers', 'vehicles', 'service_order_states', 'service_jobs_codes', 'service_jobs_names', 'warehousing_item_codes', 'warehousing_item_names', 'service_workers_names']

class ServiceOrdersEdit extends Component {
    defaultValues = {
        state_id: 1
    }

    validationRules = {}

    state = {
        values: this.defaultValues,
        datetimes: {
            date_planned_from: moment().format('DD.MM.YYYY'),
            date_planned_to: moment().format('DD.MM.YYYY')
        },
        datetimesSet: false,
        jobs: [],
        material: [],
        workers: [],
    }

    serviceJobKey = null
    serviceMaterialKey = null
    serviceWorkerKey = null

    setValues = (values, callback) => {
        this.setState({
            values,
        }, callback)
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleDatetimeChange = (key, value) => {
        const newState = {
            datetimes: {
                ...this.state.datetimes,
                [key]: value,
            },
        }

        this.setState(newState)
    }

    handleChangeJobValues = (itemKey, name, value, processAutocomplete) => {
        const jobs = [...this.state.jobs]
        jobs[itemKey] = {
            ...this.state.jobs[itemKey],
            [name]: value,
        }

        this.setState({
            jobs,
        })

        if (processAutocomplete) {
            if (name === 'code') {
                this.autocompleteJob(itemKey, { code: value })
            }
            else if (name === 'name') {
                this.autocompleteJob(itemKey, { name: value })
            }
        }
    }

    autocompleteJob = (jobKey, data) => {
        this.serviceJobKey = jobKey
        this.props.actions.fetchJobAutocomplete(data)
    }

    handleAddJob = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const jobs = [...this.state.jobs]
        jobs.push({
            id: null,
            code: '',
            name: '',
            units_count: 1,
            unit_price: 0,
            unit_duration: 0,
        })

        this.setState({
            jobs,
        })
    }

    handleRemoveJob = (e, jobKey) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const jobs = [...this.state.jobs]
        jobs.splice(jobKey, 1)

        this.setState({
            jobs,
        })
    }

    setJobsValues = (jobKey, values, callback) => {
        const jobs = [...this.state.jobs]

        jobs[jobKey] = {
            ...this.state.jobs[jobKey],
            code: (values && values.code) || '',
            name: (values && values.name) || ''
        }

        if (values.unit_price) {
            jobs[jobKey].unit_price = values.unit_price
        }

        if (values.unit_duration) {
            jobs[jobKey].unit_duration = values.unit_duration
        }

        this.setState({
            jobs,
        }, () => {
            callback && callback()
        })
    }

    handleChangeMaterialValues = (itemKey, name, value, processAutocomplete) => {
        const material = [...this.state.material]
        material[itemKey] = {
            ...this.state.material[itemKey],
            [name]: value,
        }

        this.setState({
            material,
        })

        if (processAutocomplete) {
            if (name === 'code') {
                this.autocompleteMaterial(itemKey, { code: value })
            }
            else if (name === 'name') {
                this.autocompleteMaterial(itemKey, { name: value })
            }
        }
    }

    autocompleteMaterial = (materialKey, data) => {
        this.serviceMaterialKey = materialKey
        this.props.actions.fetchMaterialAutocomplete(data)
    }

    handleAddMaterial = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const material = [...this.state.material]
        material.push({
            id: null,
            code: '',
            name: '',
            units_count: 1,
            unit_price: 0,
            unit: 'ks',
        })

        this.setState({
            material,
        })
    }

    handleRemoveMaterial = (e, materialKey) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const material = [...this.state.material]
        material.splice(materialKey, 1)

        this.setState({
            material,
        })
    }

    setMaterialsValues = (materialKey, values, callback) => {
        const material = [...this.state.material]

        material[materialKey] = {
            ...this.state.material[materialKey],
            code: (values && values.code) || '',
            name: (values && values.name) || '',
            unit: (values && values.unit) || ''
        }

        if (values.unit_price) {
            material[materialKey].unit_price = values.unit_price
        }

        if (values.units_count) {
            material[materialKey].units_count = values.units_count
        }

        this.setState({
            material,
        }, () => {
            callback && callback()
        })
    }

    handleChangeWorkerValues = (itemKey, name, value, processAutocomplete) => {
        const workers = [...this.state.workers]
        workers[itemKey] = {
            ...this.state.workers[itemKey],
            [name]: value,
        }

        this.setState({
            workers,
        })

        if (processAutocomplete) {
            if (name === 'first_name') {
                this.autocompleteWorker(itemKey, { name: value })
            }
            else if (name === 'last_name') {
                this.autocompleteWorker(itemKey, { name: value })
            }
        }
    }

    autocompleteWorker = (workerKey, data) => {
        this.serviceWorkerKey = workerKey
        this.props.actions.fetchWorkerAutocomplete(data)
    }

    handleAddWorker = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const workers = [...this.state.workers]
        workers.push({
            id: null,
            first_name: '',
            last_name: '',
            duration: 0,
            costs: 0
        })

        this.setState({
            workers,
        })
    }

    handleRemoveWorker = (e, workerKey) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const workers = [...this.state.workers]
        workers.splice(workerKey, 1)

        this.setState({
            workers,
        })
    }

    setWorkersValues = (workerKey, values, callback) => {
        const workers = [...this.state.workers]

        workers[workerKey] = {
            ...this.state.workers[workerKey],
            first_name: (values && values.first_name) || '',
            last_name: (values && values.last_name) || ''
        }

        if (values.duration) {
            workers[workerKey].duration = values.duration
        }

        if (values.costs) {
            workers[workerKey].costs = values.costs
        }

        this.setState({
            workers,
        }, () => {
            callback && callback()
        })
    }

    handleCreateIssue = () => {
        this.props.actions.createIssue(this.props.match.params.serviceOrderID)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.save()
        })
    }

    save = () => {
        this.props.actions.saveServiceOrder({
            ...this.state.values,
            date_planned_from: convertCSDateToISO(this.state.datetimes.date_planned_from),
            date_planned_to: convertCSDateToISO(this.state.datetimes.date_planned_to),
            jobs: this.state.jobs,
            material: this.state.material,
            workers: this.state.workers,
        })
    }

    componentDidMount() {
        this.props.match.params.serviceOrderID && this.props.actions.fetchServiceOrder(this.props.match.params.serviceOrderID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'service-orders'))
        })

        if (nextProps.serviceOrder && !this.state.datetimesSet) {
            this.setState({
                values: nextProps.serviceOrder.toJS(),
                datetimes: {
                    date_planned_from: convertISODateToCS(nextProps.serviceOrder.date_planned_from),
                    date_planned_to: convertISODateToCS(nextProps.serviceOrder.date_planned_to),
                },
                datetimesSet: true,
            })
        }

        if (nextProps.serviceOrder && (JSON.stringify(nextProps.serviceOrder) !== JSON.stringify(this.props.serviceOrder) || !this.state.values.id)) {
            this.setValues(nextProps.serviceOrder.toJS())

            this.setState({
                values: nextProps.serviceOrder.toJS(),
                jobs: nextProps.serviceOrder && nextProps.serviceOrder.jobs ? nextProps.serviceOrder.jobs.toJS().map(item => ({
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    units_count: item.units_count || 1,
                    unit_price: item.unit_price,
                    unit_duration: item.unit_duration
                })) : [],
                material: nextProps.serviceOrder && nextProps.serviceOrder.material ? nextProps.serviceOrder.material.toJS().map(item => ({
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    units_count: item.units_count || 1,
                    unit_price: item.unit_price,
                    unit: item.unit
                })) : [],
                workers: nextProps.serviceOrder && nextProps.serviceOrder.workers ? nextProps.serviceOrder.workers.toJS().map(item => ({
                    id: item.id,
                    first_name: item.first_name,
                    last_name: item.last_name,
                    duration: item.duration,
                    costs: item.costs
                })) : [],
            })
        }

        // Fetch job autocomplete
        if (this.props.getServiceJobFetching && !nextProps.getServiceJobFetching && !nextProps.getServiceJobError && nextProps.getServiceJobData) {
            this.setJobsValues(this.serviceJobKey, {
                code: nextProps.getServiceJobData.code,
                name: nextProps.getServiceJobData.name,
                unit_price: nextProps.getServiceJobData.price,
                unit_duration: nextProps.getServiceJobData.duration,
            })
        }

        // Fetch material autocomplete
        if (this.props.getServiceMaterialFetching && !nextProps.getServiceMaterialFetching && !nextProps.getServiceMaterialError && nextProps.getServiceMaterialData) {
            this.setMaterialsValues(this.serviceMaterialKey, {
                code: nextProps.getServiceMaterialData.code,
                name: nextProps.getServiceMaterialData.name,
                unit_price: nextProps.getServiceMaterialData.price,
                unit_duration: nextProps.getServiceMaterialData.duration,
                unit: nextProps.getServiceMaterialData.unit,
            })
        }

        // Fetch worker autocomplete
        if (this.props.getServiceWorkerFetching && !nextProps.getServiceWorkerFetching && !nextProps.getServiceWorkerError && nextProps.getServiceWorkerData) {
            this.setWorkersValues(this.serviceWorkerKey, {
                first_name: nextProps.getServiceWorkerData.first_name,
                last_name: nextProps.getServiceWorkerData.last_name,
                costs: nextProps.getServiceWorkerData.costs
            })
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.serviceOrders.heading' }))

        const serviceOrder = this.props.serviceOrder

        const jobs = this.state.jobs
        const materials = this.state.material
        const workers = this.state.workers

        const suggestJobsCodes = []
        this.props.prerequisites.values.get('service_jobs_codes').map(item => {
            suggestJobsCodes.push({
                value: item.toJS().code
            })
        })

        const suggestJobsNames = []
        this.props.prerequisites.values.get('service_jobs_names').map(item => {
            suggestJobsNames.push({
                value: item.toJS().name
            })
        })

        const suggestMaterialsCodes = []
        this.props.prerequisites.values.get('warehousing_item_codes').map(item => {
            suggestMaterialsCodes.push({
                value: item.toJS().code
            })
        })

        const suggestMaterialsNames = []
        this.props.prerequisites.values.get('warehousing_item_names').map(item => {
            suggestMaterialsNames.push({
                value: item.toJS().name
            })
        })

        const suggestWorkersNames = []
        this.props.prerequisites.values.get('service_workers_names').map(item => {
            suggestWorkersNames.push({
                value: item.toJS().name
            })
        })

        return (<PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
            <div className={'page-inner ' + (document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                <div id="main-wrapper">
                    <div className="row hp-100">
                        <div className="col-lg-4" style={{ paddingRight: '0' }}>
                            <div className="panel panel-white">
                                <div className="panel-body panel-padding">
                                    <div className="panel-head">
                                        <h4>
                                            <FormattedMessage id="modules.serviceOrders.heading" /> -{' '}
                                            {serviceOrder ? <FormattedMessage id="fields.itemEdit" /> : <FormattedMessage id="fields.itemCreate" />}
                                        </h4>
                                    </div>
                                    <LoadingOverlay
                                        active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching') || this.props.status.get('saving')}
                                    >
                                        <PermissionsCheck hasAny={Object.values(PERMS)}>
                                            <Form
                                                values={this.state.values}
                                                validationRules={this.validationRules}
                                                onChange={this.handleChangeValues}
                                                onSubmit={this.handleSubmit}
                                                isEdit={Boolean(serviceOrder)}
                                            >
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="value_date">
                                                                <FormattedMessage id="fields.dateFrom" />
                                                            </label>
                                                            <CustomDatePicker
                                                                id="value_date_planned_from"
                                                                selected={convertCSDateToMoment(this.state.datetimes.date_planned_from)}
                                                                value={this.state.datetimes.date_planned_from}
                                                                onChange={value => {
                                                                    this.handleDatetimeChange('date_planned_from', value ? formatters.dateFormatter(value) : '')
                                                                }}
                                                                onChangeRaw={e => {
                                                                    this.handleDatetimeChange('date_planned_from', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="value_date">
                                                                <FormattedMessage id="fields.dateTo" />
                                                            </label>
                                                            <CustomDatePicker
                                                                id="value_date_planned_to"
                                                                selected={convertCSDateToMoment(this.state.datetimes.date_planned_to)}
                                                                value={this.state.datetimes.date_planned_to}
                                                                onChange={value => {
                                                                    this.handleDatetimeChange('date_planned_to', value ? formatters.dateFormatter(value) : '')
                                                                }}
                                                                onChangeRaw={e => {
                                                                    this.handleDatetimeChange('date_planned_to', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <SelectField
                                                            id="state_id"
                                                            label={this.props.intl.formatMessage({ id: 'fields.state' })}
                                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('service_order_states'))}
                                                        />
                                                    </div>
                                                </div>

                                                <SelectField
                                                    id="customer_id"
                                                    label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('allowed_customers'))}
                                                    nullable={true}
                                                />

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <SelectField
                                                            id="vehicle_id"
                                                            label={this.props.intl.formatMessage({ id: 'fields.vehicle' })}
                                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('vehicles'), 'id', 'name')}
                                                            nullable={true}
                                                        />
                                                    </div>
                                                </div>

                                                <InputField
                                                    id="text"
                                                    label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                    autosize={true}
                                                />

                                                <div className="btns-form">
                                                    <Link to={url(this.props.match, 'service-orders')} className="btn btn-addon btn-default">
                                                        <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                    </Link>

                                                    <SubmitButton
                                                        perms={Object.values(PERMS)}
                                                        isEdit={Boolean(serviceOrder)}
                                                        className="btn-addon pull-right"
                                                    />
                                                </div>
                                            </Form>
                                        </PermissionsCheck>
                                    </LoadingOverlay>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 hp-100" style={{ paddingLeft: '5px' }}>
                            <div className="panel panel-white hp-100 m-b-no">
                                <div className="panel-body panel-padding hp-100">
                                    <Tabs
                                        defaultActiveKey={1}
                                        animation={false}
                                        id="detail-tabs"
                                        className="detail-tabs"
                                        onSelect={this.handleSelectTab}
                                    >
                                        <Tab
                                            eventKey={1}
                                            title={<span>Servisní úkony ({jobs.length})</span>}
                                        >
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                <tr>
                                                    <th className="w-70">
                                                        <FormattedMessage id="fields.count" />
                                                    </th>
                                                    <th className="w-140">
                                                        <FormattedMessage id="fields.code" />
                                                    </th>
                                                    <th className="w-max wm-400">
                                                        <FormattedMessage id="fields.name" />
                                                    </th>
                                                    <th className="w-200 text-right">
                                                        <FormattedMessage id="fields.unitDuration" /> [min.]
                                                    </th>
                                                    <th className="w-100 text-right">
                                                        <FormattedMessage id="fields.unitPrice" />
                                                    </th>
                                                    <th className="w-40" />
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {jobs.map((job, key) => (// eslint-disable-next-line react/no-array-index-key
                                                    <tr key={`jobs-${key}`}>
                                                        <td className="text-right">
                                                            <input
                                                                type="text"
                                                                value={job.units_count}
                                                                className="form-control"
                                                                onChange={e => {
                                                                    this.handleChangeJobValues(key, 'units_count', e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <SuggestField
                                                                suggestions={suggestJobsCodes}
                                                                noFormGroup={true}
                                                                onChange={(e, {
                                                                    newValue,
                                                                    method
                                                                }) => {
                                                                    if (method === 'enter' || method === 'click' || method === 'type') {
                                                                        this.handleChangeJobValues(key, 'code', newValue, method === 'enter' || method === 'click')
                                                                    }
                                                                }}
                                                                defaultValue={job.code}
                                                            />
                                                        </td>
                                                        <td>
                                                            <SuggestField
                                                                suggestions={suggestJobsNames}
                                                                noFormGroup={true}
                                                                onChange={(e, {
                                                                    newValue,
                                                                    method
                                                                }) => {
                                                                    if (method === 'enter' || method === 'click' || method === 'type') {
                                                                        this.handleChangeJobValues(key, 'name', newValue, method === 'enter' || method === 'click')
                                                                    }
                                                                }}
                                                                defaultValue={job.name}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <input
                                                                type="text"
                                                                value={job.unit_duration}
                                                                className="form-control text-right"
                                                                onChange={e => {
                                                                    this.handleChangeJobValues(key, 'unit_duration', e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <input
                                                                type="text"
                                                                value={job.unit_price}
                                                                className="form-control text-right"
                                                                onChange={e => {
                                                                    this.handleChangeJobValues(key, 'unit_price', e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <a
                                                                href="#"
                                                                className="display-inline m-t-xxs text-gray-light text-md"
                                                                onClick={e => {
                                                                    this.handleRemoveJob(e, key)
                                                                }}
                                                            >
                                                                <i className="far fa-trash" />
                                                            </a>
                                                        </td>
                                                    </tr>))}
                                                </tbody>
                                            </table>
                                            <div className="row m-b-md">
                                                <div className="col-md-6">
                                                    <button
                                                        className="btn btn-default btn-addon"
                                                        onClick={e => {
                                                            this.handleAddJob(e)
                                                        }}
                                                    >
                                                        <i className="far fa-plus" /> <FormattedMessage id="buttons.addItem" />
                                                    </button>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab
                                            eventKey={3}
                                            title={<span>Práce techniků ({workers.length})</span>}
                                        >
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                <tr>
                                                    <th className="w-200">
                                                        <FormattedMessage id="fields.lastName" />
                                                    </th>
                                                    <th className="w-max wm-200">
                                                        <FormattedMessage id="fields.firstName" />
                                                    </th>
                                                    <th className="w-200 text-right">
                                                        <FormattedMessage id="fields.duration" /> [min.]
                                                    </th>
                                                    <th className="w-100 text-right">
                                                        <FormattedMessage id="fields.costs" />
                                                    </th>
                                                    <th className="w-40" />
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {workers.map((worker, key) => (// eslint-disable-next-line react/no-array-index-key
                                                    <tr key={`workers-${key}`}>
                                                        <td>
                                                            <SuggestField
                                                                suggestions={suggestWorkersNames}
                                                                noFormGroup={true}
                                                                onChange={(e, {
                                                                    newValue,
                                                                    method
                                                                }) => {
                                                                    if (method === 'enter' || method === 'click' || method === 'type') {
                                                                        this.handleChangeWorkerValues(key, 'last_name', newValue, method === 'enter' || method === 'click')
                                                                    }
                                                                }}
                                                                defaultValue={worker.last_name}
                                                            />
                                                        </td>
                                                        <td>
                                                            <SuggestField
                                                                suggestions={suggestWorkersNames}
                                                                noFormGroup={true}
                                                                onChange={(e, {
                                                                    newValue,
                                                                    method
                                                                }) => {
                                                                    if (method === 'enter' || method === 'click' || method === 'type') {
                                                                        this.handleChangeWorkerValues(key, 'first_name', newValue, method === 'enter' || method === 'click')
                                                                    }
                                                                }}
                                                                defaultValue={worker.first_name}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <input
                                                                type="text"
                                                                value={worker.duration}
                                                                className="form-control text-right"
                                                                onChange={e => {
                                                                    this.handleChangeWorkerValues(key, 'duration', e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <input
                                                                type="text"
                                                                value={worker.costs}
                                                                className="form-control text-right"
                                                                onChange={e => {
                                                                    this.handleChangeWorkerValues(key, 'costs', e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <a
                                                                href="#"
                                                                className="display-inline m-t-xxs text-gray-light text-md"
                                                                onClick={e => {
                                                                    this.handleRemoveWorker(e, key)
                                                                }}
                                                            >
                                                                <i className="far fa-trash" />
                                                            </a>
                                                        </td>
                                                    </tr>))}
                                                </tbody>
                                            </table>
                                            <div className="row m-b-md">
                                                <div className="col-md-6">
                                                    <button
                                                        className="btn btn-default btn-addon"
                                                        onClick={e => {
                                                            this.handleAddWorker(e)
                                                        }}
                                                    >
                                                        <i className="far fa-plus" /> <FormattedMessage id="buttons.addItem" />
                                                    </button>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab
                                            eventKey={2}
                                            title={<span>Materiál ({materials.length})</span>}
                                        >
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                <tr>
                                                    <th className="w-70">
                                                        <FormattedMessage id="fields.count" />
                                                    </th>
                                                    <th className="w-70">
                                                        <FormattedMessage id="fields.unit" />
                                                    </th>
                                                    <th className="w-140">
                                                        <FormattedMessage id="fields.code" />
                                                    </th>
                                                    <th className="w-max wm-400">
                                                        <FormattedMessage id="fields.name" />
                                                    </th>
                                                    <th className="w-100 text-right">
                                                        <FormattedMessage id="fields.unitPrice" />
                                                    </th>
                                                    <th className="w-40" />
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {materials.map((material, key) => (// eslint-disable-next-line react/no-array-index-key
                                                    <tr key={`materials-${key}`}>
                                                        <td className="text-right">
                                                            <input
                                                                type="text"
                                                                value={material.units_count}
                                                                className="form-control"
                                                                onChange={e => {
                                                                    this.handleChangeMaterialValues(key, 'units_count', e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <input
                                                                type="text"
                                                                value={material.unit}
                                                                className="form-control"
                                                                onChange={e => {
                                                                    this.handleChangeMaterialValues(key, 'unit', e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <SuggestField
                                                                suggestions={suggestMaterialsCodes}
                                                                noFormGroup={true}
                                                                onChange={(e, {
                                                                    newValue,
                                                                    method
                                                                }) => {
                                                                    if (method === 'enter' || method === 'click' || method === 'type') {
                                                                        this.handleChangeMaterialValues(key, 'code', newValue, method === 'enter' || method === 'click')
                                                                    }
                                                                }}
                                                                defaultValue={material.code}
                                                            />
                                                        </td>
                                                        <td>
                                                            <SuggestField
                                                                suggestions={suggestMaterialsNames}
                                                                noFormGroup={true}
                                                                onChange={(e, {
                                                                    newValue,
                                                                    method
                                                                }) => {
                                                                    if (method === 'enter' || method === 'click' || method === 'type') {
                                                                        this.handleChangeMaterialValues(key, 'name', newValue, method === 'enter' || method === 'click')
                                                                    }
                                                                }}
                                                                defaultValue={material.name}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <input
                                                                type="text"
                                                                value={material.unit_price}
                                                                className="form-control text-right"
                                                                onChange={e => {
                                                                    this.handleChangeMaterialValues(key, 'unit_price', e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <a
                                                                href="#"
                                                                className="display-inline m-t-xxs text-gray-light text-md"
                                                                onClick={e => {
                                                                    this.handleRemoveMaterial(e, key)
                                                                }}
                                                            >
                                                                <i className="far fa-trash" />
                                                            </a>
                                                        </td>
                                                    </tr>))}
                                                </tbody>
                                            </table>
                                            <div className="row m-b-md">
                                                <div className="col-md-6">
                                                    <button
                                                        className="btn btn-default btn-addon"
                                                        onClick={e => {
                                                            this.handleAddMaterial(e)
                                                        }}
                                                    >
                                                        <i className="far fa-plus" /> <FormattedMessage id="buttons.addItem" />
                                                    </button>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab
                                            eventKey={4}
                                            title={<span>Výdejky ({serviceOrder ? serviceOrder.issues.toJS().length : '0'})</span>}
                                        >
                                            <div className="table-container">
                                                <table className="table table-striped table-hover">
                                                    <thead>
                                                    <tr>
                                                        <th className="w-100">
                                                            <FormattedMessage id="fields.date" />
                                                        </th>
                                                        <th className="w-100">
                                                            <FormattedMessage id="fields.warehouse" />
                                                        </th>
                                                        <th className="w-300">
                                                            <FormattedMessage id="fields.customer" />
                                                        </th>
                                                        <th className="w-140 text-right">
                                                            <FormattedMessage id="fields.itemsCount" />
                                                        </th>
                                                        <th className="w-140 text-right">
                                                            <FormattedMessage id="fields.totalPrice" />
                                                        </th>
                                                        <th className="w-max wm-300">
                                                            <FormattedMessage id="fields.note" />
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {serviceOrder && serviceOrder.issues &&
                                                        serviceOrder.issues.toJS().map((item, index) => (
                                                            <tr>
                                                                <td>{dateFormatter(item.date)}</td>
                                                                <td>{item.warehouse}</td>
                                                                <td>{item.customer}</td>
                                                                <td className="text-right">{floatFormatter(item.items_count, '0,0')}</td>
                                                                <td className="text-right">{numberFormatter(item.total_price, '0,0.00', item.currency)}</td>
                                                                <td>{item.note}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                {materials.length ? (<div className="row m-b-md">
                                                    <div className="col-md-6">
                                                        <button
                                                            className="btn btn-default btn-addon"
                                                            onClick={e => {
                                                                this.handleCreateIssue(e)
                                                            }}
                                                        >
                                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.addItem" />
                                                        </button>
                                                    </div>
                                                </div>) : null}
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PermissionsCheck>)
    }
}

function mapStateToProps(state, ownProps) {
    return {
        serviceOrder: createGetServiceOrderByIDSelector(ownProps.match.params.serviceOrderID)(state),
        status: createGetCurrentServiceOrderStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        getServiceJobData: getServiceJobData(state),
        getServiceJobFetching: getServiceJobFetching(state),
        getServiceJobError: getServiceJobError(state),
        getServiceMaterialData: getServiceMaterialData(state),
        getServiceMaterialFetching: getServiceMaterialFetching(state),
        getServiceMaterialError: getServiceMaterialError(state),
        getServiceWorkerData: getServiceWorkerData(state),
        getServiceWorkerFetching: getServiceWorkerFetching(state),
        getServiceWorkerError: getServiceWorkerError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators({
                ...serviceOrdersActionCreators, ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
            }, dispatch),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ServiceOrdersEdit))
