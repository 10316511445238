import { createSelector } from 'reselect'

export const getPackagingTypes = state => state.packagingTypes.getIn(['all', 'objects'])
export const createGetPackagingTypeByIDSelector = id => createSelector([getPackagingTypes], packagingTypes => packagingTypes.get(String(id)))

const getCurrentPackagingTypeStatus = state => state.packagingTypes.get('current')
export const createGetCurrentPackagingTypeStatusSelector = () => createSelector([getCurrentPackagingTypeStatus], status => status)

export const getPackagingTypeDeleting = state => state.packagingTypes.getIn(['deletion', 'inProgress'])
export const getPackagingTypeDeleteError = state => state.packagingTypes.getIn(['deletion', 'error'])
