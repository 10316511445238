import React, { Component } from 'react'

class CustomerAddressesPickerRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onSelectAddress(this.props.data)
    }

    render = () => (
        <tr onClick={this.handleClick} className="row-selectable">
            <td className="w-40 table-buttons text-center">
                <button className="far fa-check-circle text-gray" />
            </td>
            <td className="w-300">{this.props.customer && this.props.customer.name}</td>
            <td className="w-200">{this.props.type && this.props.type.name}</td>
            <td className="w-300">{this.props.data.name}</td>
            <td className="w-200">{this.props.data.note}</td>
            <td className="w-80">{this.props.country && this.props.country.iso_code}</td>
            <td className="w-max wm-300">{this.props.data.getAddress()}</td>
        </tr>
    )
}

export default CustomerAddressesPickerRow
