import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'

import Field from './Field'
import CustomerPickerList from './CustomerPickerList'

class CustomerPicker extends Component {
    state = {
        showModal: false,
    }

    showModal = e => {
        e && e.preventDefault()

        const showModal = !this.state.showModal
        this.setState({ showModal })
    }

    clear = e => {
        e && e.preventDefault()

        this.props.onDelete ? this.props.onDelete() : this.props.onChange(null)
    }

    onClick = value => {
        this.props.onChange(value)
        this.showModal()
    }

    render() {
        let value

        if (this.props.hasOwnProperty('getValue')) {
            value = String(this.props.getValue() ? this.props.getValue() : this.props.defaultValue)
        } else {
            value = String(this.props.value ? this.props.value : this.props.defaultValue)
        }

        const customer_name = value && this.props.values && this.props.values[value] ? this.props.values[value] : '-'

        return (
            <Field {...this.props}>
                <div className="input-group m-b-sm">
                    <input value={customer_name} placeholder={this.props.placeholder} className={`${this.props.className} form-control`} disabled />
                    <input id={this.props.id} type="hidden" value={value} />
                    <span className="input-group-btn">
                        <button className="btn btn-default" onClick={this.showModal} disabled={this.props.readonly()}>
                            <i className="far fa-search" />
                        </button>
                        <button className="btn btn-default" onClick={this.clear} disabled={this.props.readonly()}>
                            <i className="far fa-trash text-danger mr-0" />
                        </button>
                    </span>
                </div>
                <Modal show={Boolean(this.state.showModal)} onHide={this.showModal} className="modal-size-auto">
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <CustomerPickerList onClick={this.onClick} />
                    </Modal.Body>
                </Modal>
            </Field>
        )
    }
}

export default CustomerPicker
