import { createSelector } from 'reselect'

export const getTransportConditions = state => state.transportConditions.getIn(['all', 'objects'])

export const createGetTransportConditionByIDSelector = id =>
    createSelector([getTransportConditions], transportConditions => transportConditions.get(String(id)))

const getCurrentTransportConditionStatus = state => state.transportConditions.get('current')
export const createGetCurrentTransportConditionStatusSelector = () => createSelector([getCurrentTransportConditionStatus], status => status)

export const getTransportConditionDeleting = state => state.transportConditions.getIn(['deletion', 'inProgress'])
export const getTransportConditionDeleteError = state => state.transportConditions.getIn(['deletion', 'error'])
