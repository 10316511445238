import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'
import moment from 'moment'

class HalfSelector extends Component {
    onChange = half => {
        const year = this.props.momentFrom.format('YYYY')

        const startHalf = half.value * 6 + 1
        const endHalf = (half.value + 1) * 6

        const momentFrom = moment(`${startHalf}-01-${year}`, 'MM-DD-YYYY')
        const momentTo = moment(`${endHalf}-01-${year}`, 'MM-DD-YYYY').endOf('month')

        this.props.onChange(momentFrom, momentTo)
    }

    render() {
        const options = [
            {
                value: 0,
                label: '1/2',
            },
            {
                value: 1,
                label: '2/2',
            },
        ]

        const half = Math.floor(this.props.momentFrom.format('Q') / 3)

        return (
            <div className={`datetime-selection ${this.props.className}`}>
                <label htmlFor={this.props.id} className="wp-100 display-inline">
                    <FormattedMessage id="modules.periodPicke.half" />
                </label>
                <Select value={half} options={options} onChange={this.onChange} clearable={false} />
            </div>
        )
    }
}

export default HalfSelector
