import { createSelector } from 'reselect'

export const getTrailerDocuments = state => state.trailerDocuments.getIn(['all', 'objects'])

export const createGetTrailerDocumentByIDSelector = id => createSelector([getTrailerDocuments], trailerDocuments => trailerDocuments.get(String(id)))

const getCurrentTrailerDocumentStatus = state => state.trailerDocuments.get('current')

export const createGetCurrentTrailerDocumentStatusSelector = () => createSelector([getCurrentTrailerDocumentStatus], status => status)

export const getTrailerDocumentsFetching = state => state.trailerDocuments.getIn(['all', 'fetching'])

export const getTrailerDocumentDeleting = state => state.trailerDocuments.getIn(['deletion', 'inProgress'])

export const getTrailerDocumentDeleteError = state => state.trailerDocuments.getIn(['deletion', 'error'])

const getDuplicity = state => state.trailerDocuments.getIn(['all', 'duplicity'])

export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)

export const getTrailerDocumentFiles = state => state.trailerDocuments.getIn(['files', 'objects'])
export const getTrailerDocumentFilesFetching = state => state.trailerDocuments.getIn(['files', 'fetching'])
export const getTrailerDocumentFilesSaving = state => state.trailerDocuments.getIn(['files', 'saving'])
export const getTrailerDocumentFilesDeleting = state => state.trailerDocuments.getIn(['files', 'deleting'])
export const getTrailerDocumentFilesError = state => state.trailerDocuments.getIn(['files', 'error'])