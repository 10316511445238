// Fetch list

export const FETCH = 'driverSurchargeTypes.FETCH'
export const FETCH_STARTED = 'driverSurchargeTypes.FETCH_STARTED'
export const FETCH_FULFILLED = 'driverSurchargeTypes.FETCH_FULFILLED'
export const FETCH_REJECTED = 'driverSurchargeTypes.FETCH_REJECTED'
export const FETCH_CANCELLED = 'driverSurchargeTypes.FETCH_CANCELLED'

export const SORTING_CHANGED = 'driverSurchargeTypes.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'driverSurchargeTypes.FETCH_ONE'
export const FETCH_ONE_STARTED = 'driverSurchargeTypes.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'driverSurchargeTypes.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'driverSurchargeTypes.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'driverSurchargeTypes.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'driverSurchargeTypes.SAVE'
export const SAVE_STARTED = 'driverSurchargeTypes.SAVE_STARTED'
export const SAVE_FULFILLED = 'driverSurchargeTypes.SAVE_FULFILLED'
export const SAVE_REJECTED = 'driverSurchargeTypes.SAVE_REJECTED'
export const SAVE_CANCELLED = 'driverSurchargeTypes.SAVE_CANCELLED'

// Delete

export const DELETE = 'driverSurchargeTypes.DELETE'
export const DELETE_STARTED = 'driverSurchargeTypes.DELETE_STARTED'
export const DELETE_FULFILLED = 'driverSurchargeTypes.DELETE_FULFILLED'
export const DELETE_REJECTED = 'driverSurchargeTypes.DELETE_REJECTED'
export const DELETE_CANCELLED = 'driverSurchargeTypes.DELETE_CANCELLED'
