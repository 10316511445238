import React from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'

const LoadingOverlay = props => {
    const active = Boolean(props.active)
    const message = props.message ? props.message : props.intl.formatMessage({ id: 'loader.pleaseWait' })

    const containerClass = classNames('loading-overlay', props.className, {
        'loading-overlay-dark': props.dark,
        'loading-overlay-transparent': props.transparent,
    })

    if (active) {
        return (
            <div className={containerClass}>
                <div className="loading-overlay-inner">
                    <div className="loading-overlay-contents">
                        <div className="spinner" />
                        {message}
                    </div>
                </div>
                {props.children}
            </div>
        )
    }

    return <React.Fragment>{props.children}</React.Fragment>
}

export default injectIntl(LoadingOverlay)
