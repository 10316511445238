import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Modal } from 'react-bootstrap'

import 'react-datepicker/dist/react-datepicker.css'

import { Form, SelectField, InputField, SubmitButton } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import {
    url, handleCommonEditActions, getPrerequisitesArray, convertCSDateToMoment, convertCSDatetimeToMoment, convertCSDateToISO, convertISODateToCS,
} from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as driverSurchargesActionCreators from '../actionCreators'
import { createGetDriverSurchargeByIDSelector, createGetCurrentDriverSurchargeStatusSelector, createGetDuplicitySelector } from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'driver_timesheets_edit'
const prerequisites = ['drivers', 'driver_surcharge_types', 'currencies']

class DriverSurchargesEditForm extends Component {
    defaultValues = {}

    validationRules = {}

    defaultValidity = {}

    state = {
        values: this.defaultValues,
        datetimes: {
            date: moment().format('DD.MM.YYYY'),
        },
        defaultValuesSet: false,
        datetimesSet: false,
        modalDuplicity: {
            show: false,
            duplicity: null,
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave(false)
        })
    }

    handleSave = ignoreDuplicity => {
        this.props.actions.saveDriverSurcharge(
            {
                ...this.state.values,
                date: convertCSDateToISO(this.state.datetimes.date),
            },
            ignoreDuplicity
        )
    }

    duplicityFoundCallback = duplicity => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()
        this.handleSave(true)
    }

    successCallback = () => {
        this.props.successCallback ? this.props.successCallback() : this.props.redirect(url(this.props.match, 'driver-surcharges'))
    }

    backCallback = () => {
        this.props.backCallback ? this.props.backCallback() : this.props.redirect(url(this.props.match, 'driver-surcharges'))
    }

    handleDatetimeChange = (key, value) => {
        const newState = {
            datetimes: {
                ...this.state.datetimes,
                [key]: value,
            },
        }

        this.setState(newState)
    }

    componentDidMount() {
        this.props.driverSurchargeID && this.props.actions.fetchDriverSurcharge(this.props.driverSurchargeID)
        this.props.actions.fetchPrerequisites()

        if (!this.props.driverSurchargeID && this.props.defaultValues && !this.state.defaultValuesSet) {
            const defaultValues = { ...this.props.defaultValues }

            const datetimes = { ...this.state.datetimes }

            if (defaultValues.date) {
                datetimes.date = defaultValues.date
                delete defaultValues.date
            }

            this.setValues(defaultValues, () => this.setState({
                datetimes,
                defaultValuesSet: true
            }))
        }
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback, this.duplicityFoundCallback)

        if (nextProps.driverSurcharge && !this.state.datetimesSet) {
            this.setState({
                values: nextProps.driverSurcharge.toJS(),
                datetimes: {
                    date: convertISODateToCS(nextProps.driverSurcharge.date),
                },
                datetimesSet: true,
            })
        }

        if (!nextProps.driverSurchargeID && nextProps.defaultValues && !this.state.defaultValuesSet) {
            const defaultValues = { ...nextProps.defaultValues }

            const datetimes = { ...this.state.datetimes }

            if (defaultValues.date) {
                datetimes.date = defaultValues.date
                delete defaultValues.date
            }

            this.setValues(defaultValues, () => this.setState({
                datetimes,
                defaultValuesSet: true
            }))
        }
    }

    render() {
        const duplicity = this.props.duplicity
        const perms = this.props.PERMS ? this.props.PERMS : PERMS

        return (
            <div>
                <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                    <PermissionsCheck hasAny={Object.values(perms)}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                            isEdit={Boolean(this.props.driverSurcharge)}
                        >
                            <div className={this.props.hiddenFields && this.props.hiddenFields.indexOf('driver_id') !== -1 ? 'hidden' : ''}>
                                <SelectField
                                    id="driver_id"
                                    label={this.props.intl.formatMessage({ id: 'fields.driver' })}
                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('drivers'))}
                                />
                            </div>
                            <SelectField
                                id="driver_surcharge_type_id"
                                label={this.props.intl.formatMessage({ id: 'fields.type' })}
                                values={getPrerequisitesArray(this.props.prerequisites.values.get('driver_surcharge_types'))}
                                prompt={false}
                            />
                            <div className="row m-b-xs">
                                <div className="col-sm-4">
                                    <label htmlFor="date">
                                        <FormattedMessage id="fields.date" />
                                    </label>
                                    <DatePicker
                                        id="date"
                                        dateFormat="DD.MM.YYYY"
                                        autoComplete="off"
                                        selected={convertCSDateToMoment(this.state.datetimes.date)}
                                        value={this.state.datetimes.date}
                                        onChange={value => {
                                            this.handleDatetimeChange('date', value ? formatters.dateFormatter(value) : '')
                                        }}
                                        onChangeRaw={e => {
                                            this.handleDatetimeChange('date', e.target.value)
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <InputField
                                            id="value"
                                            label={this.props.intl.formatMessage({ id: 'fields.value' })}
                                            type="number"
                                            value={4}
                                            step={1}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <SelectField
                                            id={`currency_id`}
                                            label={this.props.intl.formatMessage({ id: 'fields.currency' })}
                                            values={getPrerequisitesArray(
                                                this.props.prerequisites.values.get('currencies'),
                                                'id',
                                                'iso_code'
                                            )}
                                            prompt={false}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <InputField
                                    id="note"
                                    label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                />
                            </div>

                            <div className="btns-form">
                                <button onClick={this.backCallback} className="btn btn-addon btn-default">
                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                </button>
                                <SubmitButton
                                    perms={Object.values(perms)}
                                    isEdit={Boolean(this.props.driverSurcharge)}
                                    className="btn-addon pull-right"
                                />
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>

                <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} bsSize="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="confirmDialog.duplicityFound.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormattedMessage id="confirmDialog.duplicityFound.message" />
                        {duplicity && (
                            <div>
                                <div className="row m-t-lg">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.driver" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.driver}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row m-t-lg">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.type" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.driver_surcharge_type}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row m-t-lg">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.value" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.value}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.currency" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.currency}</strong>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                            <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                        </button>
                        <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                            <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        driverSurcharge: createGetDriverSurchargeByIDSelector(ownProps.driverSurchargeID)(state),
        status: createGetCurrentDriverSurchargeStatusSelector()(state),
        duplicity: createGetDuplicitySelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...driverSurchargesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DriverSurchargesEditForm)
)
