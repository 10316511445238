import React from 'react'
import { Route, Switch } from 'react-router'
import TransportConditionsList from './TransportConditionsList'
import TransportConditionsEdit from './TransportConditionsEdit'

const TransportConditions = props => (
    <Switch>
        <Route exact path={props.match.url} component={TransportConditionsList} />
        <Route exact path={`${props.match.url}/new`} component={TransportConditionsEdit} />
        <Route exact path={`${props.match.url}/:transportConditionID`} component={TransportConditionsEdit} />
    </Switch>
)

export default TransportConditions
