import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportEmptyKilometers = month => ({
    type: actionTypes.FETCH,
    payload: month,
})

export const fetchReportEmptyKilometersFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportEmptyKilometersRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportEmptyKilometersCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportEmptyKilometers = () => ({
    type: actionTypes.CLEAR,
})
