import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { isDisableField } from '../../helpers'

/* eslint-disable prettier/prettier */
// eslint-disable-next-line react/prefer-stateless-function
class SubmitButton extends Component {
    render() {
        let type = this.props.type ? this.props.type : 'submit'
        let text = 'buttons.save'
        const onClick = this.props.onClick ? this.props.onClick : null
        if (this.props.saveAndClose) {
            type = this.props.type ? this.props.type : 'button'
            text = 'buttons.saveAndClose'
        }

        return (
            <React.Fragment>
                {!isDisableField(this.props.isEdit, this.props.perms) && (
                    <button type={type} className={`btn btn-success ${this.props.className}`} onClick={onClick} disabled={this.props.disabled}>
                        <i className="far fa-check" /> <FormattedMessage id={text} />
                    </button>
                )}
            </React.Fragment>
        )
    }
}

export default SubmitButton
