// Fetch list

export const FETCH = 'trailers.FETCH'
export const FETCH_STARTED = 'trailers.FETCH_STARTED'
export const FETCH_FULFILLED = 'trailers.FETCH_FULFILLED'
export const FETCH_REJECTED = 'trailers.FETCH_REJECTED'
export const FETCH_CANCELLED = 'trailers.FETCH_CANCELLED'

export const SORTING_CHANGED = 'trailers.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'trailers.FETCH_ONE'
export const FETCH_ONE_STARTED = 'trailers.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'trailers.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'trailers.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'trailers.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'trailers.SAVE'
export const SAVE_STARTED = 'trailers.SAVE_STARTED'
export const SAVE_FULFILLED = 'trailers.SAVE_FULFILLED'
export const SAVE_REJECTED = 'trailers.SAVE_REJECTED'
export const SAVE_CANCELLED = 'trailers.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'trailers.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'trailers.DELETE'
export const DELETE_STARTED = 'trailers.DELETE_STARTED'
export const DELETE_FULFILLED = 'trailers.DELETE_FULFILLED'
export const DELETE_REJECTED = 'trailers.DELETE_REJECTED'
export const DELETE_CANCELLED = 'trailers.DELETE_CANCELLED'

// Restore

export const RESTORE = 'trailers.RESTORE'
export const RESTORE_STARTED = 'trailers.RESTORE_STARTED'
export const RESTORE_FULFILLED = 'trailers.RESTORE_FULFILLED'
export const RESTORE_REJECTED = 'trailers.RESTORE_REJECTED'
export const RESTORE_CANCELLED = 'trailers.RESTORE_CANCELLED'


// Export

export const EXPORT = 'trailers.EXPORT'
export const EXPORT_FULFILLED = 'trailers.EXPORT_FULFILLED'

// Fetch trailer history

export const FETCH_HISTORY = 'trailers.FETCH_HISTORY'
export const FETCH_HISTORY_STARTED = 'trailers.FETCH_HISTORY_STARTED'
export const FETCH_HISTORY_FULFILLED = 'trailers.FETCH_HISTORY_FULFILLED'
export const FETCH_HISTORY_REJECTED = 'trailers.FETCH_HISTORY_REJECTED'
export const FETCH_HISTORY_CANCELLED = 'trailers.FETCH_HISTORY_CANCELLED'

// Export trailer history

export const EXPORT_HISTORY = 'trailers.EXPORT_HISTORY'
export const EXPORT_HISTORY_STARTED = 'trailers.EXPORT_HISTORY_STARTED'
export const EXPORT_HISTORY_FULFILLED = 'trailers.EXPORT_HISTORY_FULFILLED'
export const EXPORT_HISTORY_REJECTED = 'trailers.EXPORT_HISTORY_REJECTED'
export const EXPORT_HISTORY_CANCELLED = 'trailers.EXPORT_HISTORY_CANCELLED'

// Fetch trailer documents

export const FETCH_DOCUMENTS = 'trailers.FETCH_DOCUMENTS'
export const FETCH_DOCUMENTS_STARTED = 'trailers.FETCH_DOCUMENTS_STARTED'
export const FETCH_DOCUMENTS_FULFILLED = 'trailers.FETCH_DOCUMENTS_FULFILLED'
export const FETCH_DOCUMENTS_REJECTED = 'trailers.FETCH_DOCUMENTS_REJECTED'
export const FETCH_DOCUMENTS_CANCELLED = 'trailers.FETCH_DOCUMENTS_CANCELLED'

// Export trailer documents

export const EXPORT_DOCUMENTS = 'trailers.EXPORT_DOCUMENTS'
export const EXPORT_DOCUMENTS_STARTED = 'trailers.EXPORT_DOCUMENTS_STARTED'
export const EXPORT_DOCUMENTS_FULFILLED = 'trailers.EXPORT_DOCUMENTS_FULFILLED'
export const EXPORT_DOCUMENTS_REJECTED = 'trailers.EXPORT_DOCUMENTS_REJECTED'
export const EXPORT_DOCUMENTS_CANCELLED = 'trailers.EXPORT_DOCUMENTS_CANCELLED'

// Fetch trailer equipments

export const FETCH_EQUIPMENTS = 'trailers.FETCH_EQUIPMENTS'
export const FETCH_EQUIPMENTS_STARTED = 'trailers.FETCH_EQUIPMENTS_STARTED'
export const FETCH_EQUIPMENTS_FULFILLED = 'trailers.FETCH_EQUIPMENTS_FULFILLED'
export const FETCH_EQUIPMENTS_REJECTED = 'trailers.FETCH_EQUIPMENTS_REJECTED'
export const FETCH_EQUIPMENTS_CANCELLED = 'trailers.FETCH_EQUIPMENTS_CANCELLED'

// Export trailer equipments

export const EXPORT_EQUIPMENTS = 'trailers.EXPORT_EQUIPMENTS'
export const EXPORT_EQUIPMENTS_STARTED = 'trailers.EXPORT_EQUIPMENTS_STARTED'
export const EXPORT_EQUIPMENTS_FULFILLED = 'trailers.EXPORT_EQUIPMENTS_FULFILLED'
export const EXPORT_EQUIPMENTS_REJECTED = 'trailers.EXPORT_EQUIPMENTS_REJECTED'
export const EXPORT_EQUIPMENTS_CANCELLED = 'trailers.EXPORT_EQUIPMENTS_CANCELLED'

// Fetch trailer events

export const FETCH_EVENTS = 'trailers.FETCH_EVENTS'
export const FETCH_EVENTS_STARTED = 'trailers.FETCH_EVENTS_STARTED'
export const FETCH_EVENTS_FULFILLED = 'trailers.FETCH_EVENTS_FULFILLED'
export const FETCH_EVENTS_REJECTED = 'trailers.FETCH_EVENTS_REJECTED'
export const FETCH_EVENTS_CANCELLED = 'trailers.FETCH_EVENTS_CANCELLED'

// Export trailer events

export const EXPORT_EVENTS = 'trailers.EXPORT_EVENTS'
export const EXPORT_EVENTS_STARTED = 'trailers.EXPORT_EVENTS_STARTED'
export const EXPORT_EVENTS_FULFILLED = 'trailers.EXPORT_EVENTS_FULFILLED'
export const EXPORT_EVENTS_REJECTED = 'trailers.EXPORT_EVENTS_REJECTED'
export const EXPORT_EVENTS_CANCELLED = 'trailers.EXPORT_EVENTS_CANCELLED'



// Fetch import headers

export const FETCH_IMPORT_HEADERS = 'trailers.FETCH_IMPORT_HEADERS'
export const FETCH_IMPORT_HEADERS_STARTED = 'trailers.FETCH_IMPORT_HEADERS_STARTED'
export const FETCH_IMPORT_HEADERS_FULFILLED = 'trailers.FETCH_IMPORT_HEADERS_FULFILLED'
export const FETCH_IMPORT_HEADERS_REJECTED = 'trailers.FETCH_IMPORT_HEADERS_REJECTED'
export const FETCH_IMPORT_HEADERS_CANCELLED = 'trailers.FETCH_IMPORT_HEADERS_CANCELLED'

// Fetch import values

export const FETCH_IMPORT_ITEMS = 'trailers.FETCH_IMPORT_ITEMS'
export const FETCH_IMPORT_ITEMS_STARTED = 'trailers.FETCH_IMPORT_ITEMS_STARTED'
export const FETCH_IMPORT_ITEMS_FULFILLED = 'trailers.FETCH_IMPORT_ITEMS_FULFILLED'
export const FETCH_IMPORT_ITEMS_REJECTED = 'trailers.FETCH_IMPORT_ITEMS_REJECTED'
export const FETCH_IMPORT_ITEMS_CANCELLED = 'trailers.FETCH_IMPORT_ITEMS_CANCELLED'

// Import

export const IMPORT_TRAILERS = 'trailers.IMPORT_TRAILERS'
export const IMPORT_TRAILERS_STARTED = 'trailers.IMPORT_TRAILERS_STARTED'
export const IMPORT_TRAILERS_FULFILLED = 'trailers.IMPORT_TRAILERS_FULFILLED'
export const IMPORT_TRAILERS_REJECTED = 'trailers.IMPORT_TRAILERS_REJECTED'
export const IMPORT_TRAILERS_CANCELLED = 'trailers.IMPORT_TRAILERS_CANCELLED'
