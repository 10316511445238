import React from 'react'
import { render } from 'react-dom'

import { addLocaleData } from 'react-intl'

import Container from './app/components/Container'

import cs from 'react-intl/locale-data/cs'
import en from 'react-intl/locale-data/en'
import pl from 'react-intl/locale-data/pl'
import numeral from 'numeral'
import moment from 'moment'
import 'moment/locale/cs'

addLocaleData([...cs, ...en, ...pl])

numeral.register('locale', 'cs', {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
})
numeral.locale('cs')
moment.locale('cs')

const renderApp = () => render(<Container />, document.getElementById('root'))

if (module.hot) {
    module.hot.accept('./app/components/Container', renderApp)
}

renderApp()
