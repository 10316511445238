import { Record } from 'immutable'

export class Currency extends Record({
    id: undefined,
    iso_code: undefined,
    name: undefined,
    rate: undefined,
    is_default: undefined,
}) {
    getFullName = () => `${this.iso_code} - ${this.name}`
}
