import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal, Col, Row, Tab, Nav, NavItem } from 'react-bootstrap'
import moment from 'moment'

import * as constants from '../constants'

import DayPicker from './pickers/DayPicker'
import DaysPicker from './pickers/DaysPicker'

import YearSelector from './selectors/YearSelector'
import HalfSelector from './selectors/HalfSelector'
import QuarterSelector from './selectors/QuarterSelector'
import MonthSelector from './selectors/MonthSelector'
import WeekSelector from './selectors/WeekSelector'

class ModalPicker extends Component {
    state = {
        pickerType: this.props.pickerType,
        momentFrom: this.props.momentFrom,
        momentTo: this.props.momentTo,
    }

    onAccept = () => {
        this.props.onAccept(this.state.momentFrom, this.state.momentTo, this.state.pickerType)
    }

    pickerChange = pickerType => {
        let momentFrom = this.state.momentFrom
        let momentTo = this.state.momentTo

        switch (pickerType) {
            case constants.PERIOD_PICKER_TYPE_YEAR:
                momentFrom = moment(momentFrom).startOf('year')
                momentTo = moment(momentFrom).endOf('year')
                break
            case constants.PERIOD_PICKER_TYPE_HALF:
                // eslint-disable-next-line no-case-declarations
                const year = this.props.momentTo.format('YYYY')
                // eslint-disable-next-line no-case-declarations
                const half = Math.floor(momentFrom.format('Q') / 3)
                // eslint-disable-next-line no-case-declarations
                const startHalf = half * 6 + 1
                // eslint-disable-next-line no-case-declarations
                const endHalf = (half + 1) * 6

                momentFrom = moment(`${startHalf}-01-${year}`, 'MM-DD-YYYY')
                momentTo = moment(`${endHalf}-01-${year}`, 'MM-DD-YYYY').endOf('month')
                break
            case constants.PERIOD_PICKER_TYPE_QUARTER:
                momentFrom = moment(momentFrom).startOf('quarter')
                momentTo = moment(momentFrom).endOf('quarter')
                break
            case constants.PERIOD_PICKER_TYPE_MONTH:
                momentFrom = moment(momentFrom).startOf('month')
                momentTo = moment(momentFrom).endOf('month')
                break
            case constants.PERIOD_PICKER_TYPE_WEEK:
                momentFrom = moment(momentFrom).startOf('week')
                momentTo = moment(momentFrom).endOf('week')
                break
            case constants.PERIOD_PICKER_TYPE_DAY:
                momentTo = momentFrom
                break
            default:
                break
        }

        this.setState({
            pickerType,
            momentFrom,
            momentTo,
        })
    }

    dateChange = (momentFrom, momentTo) => {
        this.setState({
            momentFrom,
            momentTo,
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.momentFrom && moment.isMoment(nextProps.momentFrom)) {
            this.setState({
                momentFrom: nextProps.momentFrom,
            })
        }
        if (nextProps.momentTo && moment.isMoment(nextProps.momentTo)) {
            this.setState({
                momentTo: nextProps.momentTo,
            })
        }
    }

    render() {
        const pickerType = this.props.pickerType
        const pickers = this.props.pickers

        return (
            <Modal show={Boolean(this.props.show)} onHide={this.props.onCancel} bsSize="sm" className="period-picker">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="modules.periodPicke.heading" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="period-picker" defaultActiveKey={pickerType} onSelect={this.pickerChange}>
                        <Row className="clearfix">
                            <Col sm={4} className="period-picker-menu">
                                <Nav bsStyle="tabs" stacked>
                                    {pickers &&
                                        pickers.map(picker => (
                                            <NavItem key={picker} eventKey={picker} className="period-picker-item">
                                                <FormattedMessage id={`modules.periodPicke.${picker}`} />
                                            </NavItem>
                                        ))}
                                </Nav>
                            </Col>
                            <Col sm={8} className="period-picker-body">
                                <Tab.Content animation>
                                    {pickers.includes('year') && (
                                        <Tab.Pane eventKey="year">
                                            <YearSelector
                                                momentFrom={this.state.momentFrom}
                                                momentTo={this.state.momentTo}
                                                onChange={this.dateChange}
                                                minYear={this.props.minYear}
                                                maxYear={this.props.maxYear}
                                            />
                                        </Tab.Pane>
                                    )}
                                    {pickers.includes('half') && (
                                        <Tab.Pane eventKey="half">
                                            <YearSelector
                                                momentFrom={this.state.momentFrom}
                                                momentTo={this.state.momentTo}
                                                onChange={this.dateChange}
                                                minYear={this.props.minYear}
                                                maxYear={this.props.maxYear}
                                            />
                                            <HalfSelector
                                                className="m-t-xs"
                                                momentFrom={this.state.momentFrom}
                                                momentTo={this.state.momentTo}
                                                onChange={this.dateChange}
                                            />
                                        </Tab.Pane>
                                    )}
                                    {pickers.includes('quarter') && (
                                        <Tab.Pane eventKey="quarter">
                                            <YearSelector
                                                momentFrom={this.state.momentFrom}
                                                momentTo={this.state.momentTo}
                                                onChange={this.dateChange}
                                                minYear={this.props.minYear}
                                                maxYear={this.props.maxYear}
                                            />
                                            <QuarterSelector
                                                className="m-t-xs"
                                                momentFrom={this.state.momentFrom}
                                                momentTo={this.state.momentTo}
                                                onChange={this.dateChange}
                                            />
                                        </Tab.Pane>
                                    )}
                                    {pickers.includes('month') && (
                                        <Tab.Pane eventKey="month">
                                            <YearSelector
                                                momentFrom={this.state.momentFrom}
                                                momentTo={this.state.momentTo}
                                                onChange={this.dateChange}
                                                minYear={this.props.minYear}
                                                maxYear={this.props.maxYear}
                                            />
                                            <MonthSelector
                                                className="m-t-xs"
                                                momentFrom={this.state.momentFrom}
                                                momentTo={this.state.momentTo}
                                                onChange={this.dateChange}
                                            />
                                        </Tab.Pane>
                                    )}
                                    {pickers.includes('week') && (
                                        <Tab.Pane eventKey="week">
                                            <YearSelector
                                                momentFrom={this.state.momentFrom}
                                                momentTo={this.state.momentTo}
                                                onChange={this.dateChange}
                                                minYear={this.props.minYear}
                                                maxYear={this.props.maxYear}
                                            />
                                            <WeekSelector
                                                className="m-t-xs"
                                                momentFrom={this.state.momentFrom}
                                                momentTo={this.state.momentTo}
                                                onChange={this.dateChange}
                                            />
                                        </Tab.Pane>
                                    )}
                                    {pickers.includes('day') && (
                                        <Tab.Pane eventKey="day">
                                            <label htmlFor="day" className="wp-100 display-inline">
                                                <FormattedMessage id="modules.periodPicke.day" />
                                            </label>
                                            <DayPicker
                                                id="day"
                                                momentFrom={this.state.momentFrom}
                                                momentTo={this.state.momentTo}
                                                onChange={this.dateChange}
                                                minYear={this.props.minYear}
                                                maxYear={this.props.maxYear}
                                            />
                                        </Tab.Pane>
                                    )}
                                    {pickers.includes('period') && (
                                        <Tab.Pane eventKey="period">
                                            <label htmlFor="period" className="wp-100 display-inline">
                                                <FormattedMessage id="modules.periodPicke.period" />
                                            </label>
                                            <DaysPicker
                                                id="period"
                                                momentFrom={this.state.momentFrom}
                                                momentTo={this.state.momentTo}
                                                onChange={this.dateChange}
                                                minYear={this.props.minYear}
                                                maxYear={this.props.maxYear}
                                            />
                                        </Tab.Pane>
                                    )}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.onCancel} className="btn btn-addon btn-default pull-right m-l-sm">
                        <i className="far fa-times" /> <FormattedMessage id="buttons.cancel" />
                    </button>
                    <button onClick={this.onAccept} className="btn btn-addon btn-success pull-right m-l-sm">
                        <i className="far fa-check" /> <FormattedMessage id="buttons.filter" />
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ModalPicker
