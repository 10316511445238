import { Observable } from 'rxjs'

import { request, parseAPIError } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchGeneralSettingsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH)
        .filter(() => !store.getState().generalSettings.getIn(['all', 'fetching']))
        .switchMap(() => {
            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request({
                    method: 'GET',
                    path: 'general-settings/list',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchGeneralSettingsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchGeneralSettingsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED))
            )
        })

// Save

const saveGeneralSettingsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().goodsTypes.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload)

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    method: 'PUT',
                    path: `general-settings`,
                    body: values,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.saveGeneralSettingsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.saveGeneralSettingsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

export default [fetchGeneralSettingsEpic, saveGeneralSettingsEpic]
