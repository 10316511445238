import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'
import moment from 'moment'

class YearSelector extends Component {
    onChange = year => {
        const momentFrom = moment(`01-01-${year.value}`, 'MM-DD-YYYY')
        const momentTo = moment(`12-31-${year.value}`, 'MM-DD-YYYY')

        this.props.onChange(momentFrom, momentTo)
    }

    render() {
        const year = this.props.momentTo.format('YYYY')

        const yearFrom = this.props.minYear
        const yearTo = this.props.maxYear

        const years = [...Array(yearTo - yearFrom + 1).keys()].map(i => yearFrom + i)

        const options = years.map(value => ({
            value,
            label: String(value),
        }))

        return (
            <div className={`datetime-selection ${this.props.className}`}>
                <label htmlFor={this.props.id} className="wp-100 display-inline">
                    <FormattedMessage id="modules.periodPicke.year" />
                </label>
                <Select id={this.props.id} value={year} options={options} onChange={this.onChange} clearable={false} />
            </div>
        )
    }
}

export default YearSelector
