import { Record } from 'immutable'

export class Vehicle extends Record({
    id: undefined,
    telematics_data_source_id: undefined,
    telematics_data_id: undefined,
    logistic_data_source_id: undefined,
    logistic_data_id: undefined,
    name: undefined,
    registration: undefined,
    vin: undefined,
    phone: undefined,
    is_active: undefined,
    snow_chains: undefined,
    board_note: undefined,
    board_daily_info: undefined,
    board_daily_notes: undefined,
    board_weekly_info: undefined,
    last_transport_point: undefined,
    last_driver: undefined,
    vehicle_type_id: undefined,
    fleetboard_configuration_id: undefined,
    dispatcher_id: undefined,
    dispatcher: undefined,
    company_cost_center_id: undefined,
    last_position: undefined,
    messages: undefined,
    is_spedition: undefined,
    carrier_id: undefined,
    year_of_manufacture: undefined,
    sold_at: undefined,
    deleted_at: undefined,
}) {
    getLastMessage = () => this.messages.last()
}
