import * as actionTypes from './actionTypes'

// Fetch

export const fetchDocumentTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchDocumentTypesFulfilled = documentTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: documentTypes,
})

export const fetchDocumentTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchDocumentTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchDocumentType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchDocumentTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchDocumentTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchDocumentTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveDocumentType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveDocumentTypeFulfilled = documentType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: documentType,
})

export const saveDocumentTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteDocumentType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteDocumentTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteDocumentTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportDocumentTypes = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
