import * as actionTypes from './actionTypes'

// Fetch

export const fetchCustomerPlans = () => ({
    type: actionTypes.FETCH,
})

export const fetchCustomerPlansFulfilled = customerPlans => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: customerPlans,
})

export const fetchCustomerPlansRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCustomerPlansCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCustomerPlan = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCustomerPlanFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCustomerPlanRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCustomerPlanCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCustomerPlan = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveCustomerPlanFulfilled = customer => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: customer,
})

export const saveCustomerPlanRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveCustomerPlanDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteCustomerPlan = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCustomerPlanFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCustomerPlanRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportCustomerPlans = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
