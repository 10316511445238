// Fetch

export const FETCH = 'carrierScoring.FETCH'
export const FETCH_STARTED = 'carrierScoring.FETCH_STARTED'
export const FETCH_FULFILLED = 'carrierScoring.FETCH_FULFILLED'
export const FETCH_REJECTED = 'carrierScoring.FETCH_REJECTED'
export const FETCH_CANCELLED = 'carrierScoring.FETCH_CANCELLED'

// Save question

export const SAVE_QUESTION = 'carrierScoring.SAVE_QUESTION'
export const SAVE_QUESTION_STARTED = 'carrierScoring.SAVE_QUESTION_STARTED'
export const SAVE_QUESTION_FULFILLED = 'carrierScoring.SAVE_QUESTION_FULFILLED'
export const SAVE_QUESTION_REJECTED = 'carrierScoring.SAVE_QUESTION_REJECTED'
export const SAVE_QUESTION_CANCELLED = 'carrierScoring.SAVE_QUESTION_CANCELLED'

// Delete question

export const DELETE_QUESTION = 'carrierScoring.DELETE_QUESTION'
export const DELETE_QUESTION_STARTED = 'carrierScoring.DELETE_QUESTION_STARTED'
export const DELETE_QUESTION_FULFILLED = 'carrierScoring.DELETE_QUESTION_FULFILLED'
export const DELETE_QUESTION_REJECTED = 'carrierScoring.DELETE_QUESTION_REJECTED'
export const DELETE_QUESTION_CANCELLED = 'carrierScoring.DELETE_QUESTION_CANCELLED'

// Move question UP

export const MOVE_QUESTION_UP = 'carrierScoring.MOVE_QUESTION_UP'
export const MOVE_QUESTION_UP_STARTED = 'carrierScoring.MOVE_QUESTION_UP_STARTED'
export const MOVE_QUESTION_UP_FULFILLED = 'carrierScoring.MOVE_QUESTION_UP_FULFILLED'
export const MOVE_QUESTION_UP_REJECTED = 'carrierScoring.MOVE_QUESTION_UP_REJECTED'
export const MOVE_QUESTION_UP_CANCELLED = 'carrierScoring.MOVE_QUESTION_UP_CANCELLED'

// Move question DOWN

export const MOVE_QUESTION_DOWN = 'carrierScoring.MOVE_QUESTION_DOWN'
export const MOVE_QUESTION_DOWN_STARTED = 'carrierScoring.MOVE_QUESTION_DOWN_STARTED'
export const MOVE_QUESTION_DOWN_FULFILLED = 'carrierScoring.MOVE_QUESTION_DOWN_FULFILLED'
export const MOVE_QUESTION_DOWN_REJECTED = 'carrierScoring.MOVE_QUESTION_DOWN_REJECTED'
export const MOVE_QUESTION_DOWN_CANCELLED = 'carrierScoring.MOVE_QUESTION_DOWN_CANCELLED'

// Save answer

export const SAVE_ANSWER = 'carrierScoring.SAVE_ANSWER'
export const SAVE_ANSWER_STARTED = 'carrierScoring.SAVE_ANSWER_STARTED'
export const SAVE_ANSWER_FULFILLED = 'carrierScoring.SAVE_ANSWER_FULFILLED'
export const SAVE_ANSWER_REJECTED = 'carrierScoring.SAVE_ANSWER_REJECTED'
export const SAVE_ANSWER_CANCELLED = 'carrierScoring.SAVE_ANSWER_CANCELLED'

// Delete answer

export const DELETE_ANSWER = 'carrierScoring.DELETE_ANSWER'
export const DELETE_ANSWER_STARTED = 'carrierScoring.DELETE_ANSWER_STARTED'
export const DELETE_ANSWER_FULFILLED = 'carrierScoring.DELETE_ANSWER_FULFILLED'
export const DELETE_ANSWER_REJECTED = 'carrierScoring.DELETE_ANSWER_REJECTED'
export const DELETE_ANSWER_CANCELLED = 'carrierScoring.DELETE_ANSWER_CANCELLED'

// Move answer UP

export const MOVE_ANSWER_UP = 'carrierScoring.MOVE_ANSWER_UP'
export const MOVE_ANSWER_UP_STARTED = 'carrierScoring.MOVE_ANSWER_UP_STARTED'
export const MOVE_ANSWER_UP_FULFILLED = 'carrierScoring.MOVE_ANSWER_UP_FULFILLED'
export const MOVE_ANSWER_UP_REJECTED = 'carrierScoring.MOVE_ANSWER_UP_REJECTED'
export const MOVE_ANSWER_UP_CANCELLED = 'carrierScoring.MOVE_ANSWER_UP_CANCELLED'

// Move answer DOWN

export const MOVE_ANSWER_DOWN = 'carrierScoring.MOVE_ANSWER_DOWN'
export const MOVE_ANSWER_DOWN_STARTED = 'carrierScoring.MOVE_ANSWER_DOWN_STARTED'
export const MOVE_ANSWER_DOWN_FULFILLED = 'carrierScoring.MOVE_ANSWER_DOWN_FULFILLED'
export const MOVE_ANSWER_DOWN_REJECTED = 'carrierScoring.MOVE_ANSWER_DOWN_REJECTED'
export const MOVE_ANSWER_DOWN_CANCELLED = 'carrierScoring.MOVE_ANSWER_DOWN_CANCELLED'

// Save rule

export const SAVE_RULE = 'carrierScoring.SAVE_RULE'
export const SAVE_RULE_STARTED = 'carrierScoring.SAVE_RULE_STARTED'
export const SAVE_RULE_FULFILLED = 'carrierScoring.SAVE_RULE_FULFILLED'
export const SAVE_RULE_REJECTED = 'carrierScoring.SAVE_RULE_REJECTED'
export const SAVE_RULE_CANCELLED = 'carrierScoring.SAVE_RULE_CANCELLED'

// Delete rule

export const DELETE_RULE = 'carrierScoring.DELETE_RULE'
export const DELETE_RULE_STARTED = 'carrierScoring.DELETE_RULE_STARTED'
export const DELETE_RULE_FULFILLED = 'carrierScoring.DELETE_RULE_FULFILLED'
export const DELETE_RULE_REJECTED = 'carrierScoring.DELETE_RULE_REJECTED'
export const DELETE_RULE_CANCELLED = 'carrierScoring.DELETE_RULE_CANCELLED'
