// Fetch list

export const FETCH = 'driverSurcharges.FETCH'
export const FETCH_FOR_MAP = 'driverSurcharges.FETCH_FOR_MAP'
export const FETCH_STARTED = 'driverSurcharges.FETCH_STARTED'
export const FETCH_FULFILLED = 'driverSurcharges.FETCH_FULFILLED'
export const FETCH_REJECTED = 'driverSurcharges.FETCH_REJECTED'
export const FETCH_CANCELLED = 'driverSurcharges.FETCH_CANCELLED'

export const SORTING_CHANGED = 'driverSurcharges.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'driverSurcharges.FETCH_ONE'
export const FETCH_ONE_STARTED = 'driverSurcharges.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'driverSurcharges.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'driverSurcharges.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'driverSurcharges.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'driverSurcharges.SAVE'
export const SAVE_STARTED = 'driverSurcharges.SAVE_STARTED'
export const SAVE_FULFILLED = 'driverSurcharges.SAVE_FULFILLED'
export const SAVE_REJECTED = 'driverSurcharges.SAVE_REJECTED'
export const SAVE_CANCELLED = 'driverSurcharges.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'driverSurcharges.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'driverSurcharges.DELETE'
export const DELETE_STARTED = 'driverSurcharges.DELETE_STARTED'
export const DELETE_FULFILLED = 'driverSurcharges.DELETE_FULFILLED'
export const DELETE_REJECTED = 'driverSurcharges.DELETE_REJECTED'
export const DELETE_CANCELLED = 'driverSurcharges.DELETE_CANCELLED'

// Clear

export const CLEAR = 'driverSurcharges.CLEAR'

// Export

export const EXPORT = 'driverSurcharges.EXPORT'
export const EXPORT_FULFILLED = 'driverSurcharges.EXPORT_FULFILLED'
