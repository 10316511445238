import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import {handleCommonListActions, getListItemsCountMessage, getPrerequisitesArray} from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import {
    TableFilterBooleanField,
    TableFilterInputField,
    TableFilterSelectField,
    TableSortButtons
} from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as vehiclePositionsActionCreators from '../actionCreators'
import { getVehiclePositions, getVehiclePositionDeleting, getVehiclePositionDeleteError } from '../selectors'

import { createGetDriverByIDSelector } from '../../drivers/selectors'
import { createGetVehicleByIDSelector } from '../../vehicles/selectors'
import { createGetCountryByIDSelector } from '../../countries/selectors'

import VehiclePositionsListRow from './VehiclePositionsListRow'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'vehicle_positions_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'vehicle_positions_list'
const prerequisites = ['countries', 'vehicles', 'drivers']

const columns = {
    vehicle: new TableModelColumn({}),
    date_start: new TableModelColumn({}),
    date_end: new TableModelColumn({}),
}

class VehiclePositionsList extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // handlers

    onDelete = itemId => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteVehiclePosition(itemId)
                this.hideConfirmDialog()
            },
        })
    }

    refresh = () => {
        this.props.actions.fetchVehiclePositions()
    }

    resetFilters = () => {
        this.props.table.resetFilters(
            []
        )
    }


    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        this.props.actions.fetchVehiclePositions()
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)

        if (this.props.deleting && !nextProps.deleting) {
            this.refresh()
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.vehiclePositions.heading' }))

        const sorting = this.props.tableModel.get('sorting')
        const filters = this.props.tableModel.get('filters')

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.vehiclePositions.heading" />
                                </h4>

                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <PermissionsCheck hasAny={[PERMS.DELETE]}>
                                                    <th className="w-40" />
                                                </PermissionsCheck>
                                                <th className="w-80">
                                                    ID
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.gps" />
                                                </th>
                                                <th className="w-200 text-center">
                                                    <FormattedMessage id="fields.date" />
                                                    <TableSortButtons identifier="datetime" sorting={sorting} onChange={this.props.table.changeSorting} />
                                                </th>
                                                <th className="w-300">
                                                    <FormattedMessage id="fields.position" />
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.country" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.vehicle" />
                                                </th>
                                                <th className="w-80 text-center">
                                                    <FormattedMessage id="fields.ignition" />
                                                </th>
                                                <th className="w-80 text-center">
                                                    <FormattedMessage id="fields.motor" />
                                                </th>
                                                <th className="w-80 text-center">
                                                    <FormattedMessage id="fields.vehicleIsMoving" />
                                                </th>
                                                <th className="w-80 text-right">
                                                    <FormattedMessage id="fields.speed" />
                                                </th>
                                                <th className="w-80 text-right">
                                                    <FormattedMessage id="fields.fuel" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.totalConsumption" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.totalWeight" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.mileage" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <PermissionsCheck hasAny={[PERMS.DELETE]}>
                                                    <th className="w-40" />
                                                </PermissionsCheck>
                                                <th className="w-80">
                                                    <TableFilterInputField
                                                        identifier="id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder="ID"
                                                    />
                                                </th>
                                                <th className="w-160">

                                                </th>
                                                <th className="w-200">
                                                    <div className="w-90 pull-left m-r-xxs">
                                                        <TableFilterInputField
                                                            identifier="date_from"
                                                            type={TableModelColumnFilteringType.custom}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="date_to"
                                                            type={TableModelColumnFilteringType.custom}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-300">
                                                    <TableFilterInputField
                                                        identifier="position_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.position' })}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterSelectField
                                                        identifier="country_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('countries'), 'id', 'iso_code')}
                                                    />
                                                </th>
                                                <th className="w-140">
                                                    <TableFilterSelectField
                                                        identifier="driver_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('drivers'))}
                                                    />
                                                </th>
                                                <th className="w-120">
                                                    <TableFilterInputField
                                                        identifier="vehicle"
                                                        type={TableModelColumnFilteringType.custom}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                    />
                                                </th>
                                                <th className="w-80">
                                                    <TableFilterBooleanField
                                                        identifier="is_ignition_on"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-80">
                                                    <TableFilterBooleanField
                                                        identifier="is_motor_on"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-80">
                                                    <TableFilterBooleanField
                                                        identifier="is_moving"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-80">
                                                </th>
                                                <th className="w-80">
                                                </th>
                                                <th className="w-100">
                                                </th>
                                                <th className="w-100">
                                                </th>
                                                <th className="w-100">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.vehiclePositions.data.valueSeq().map(row => {
                                                return (
                                                    <VehiclePositionsListRow
                                                        key={`row-${row.id}`}
                                                        data={row}
                                                        onDelete={this.onDelete}
                                                        driver={this.props.createGetDriverByIDSelector(row.driver_id)}
                                                        vehicle={this.props.createGetVehicleByIDSelector(row.vehicle_id)}
                                                        country={this.props.createGetCountryByIDSelector(row.country_id)}
                                                    />
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.vehiclePositions.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.vehiclePositions.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>

                    <ConfirmDialog options={this.state.confirmDialog} />
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        vehiclePositions: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getVehiclePositions,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        deleting: getVehiclePositionDeleting(state),
        deleteError: getVehiclePositionDeleteError(state),
        createGetDriverByIDSelector: id => createGetDriverByIDSelector(id)(state),
        createGetVehicleByIDSelector: id => createGetVehicleByIDSelector(id)(state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...vehiclePositionsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(VehiclePositionsList)
)
