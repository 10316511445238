import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportDriverHourlyOverview = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportDriverHourlyOverviewFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportDriverHourlyOverviewRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportDriverHourlyOverviewCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportDriverHourlyOverview = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportDriverHourlyOverview = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportDriverHourlyOverviewDetails = (driverId, dateFrom, dateTo) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        driverId,
        dateFrom,
        dateTo,
    },
})

export const fetchReportDriverHourlyOverviewDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportDriverHourlyOverviewDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportDriverHourlyOverviewDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear details

export const clearReportDriverHourlyOverviewDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export details

export const exportReportDriverHourlyOverviewDetails = (driverId, dateFrom, dateTo, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        driverId,
        dateFrom,
        dateTo,
        filters,
    }
})