import { createSelector } from 'reselect'

export const getGoodsTypes = state => state.goodsTypes.getIn(['all', 'objects'])

export const createGetGoodsTypeByIDSelector = id => createSelector([getGoodsTypes], goodsTypes => goodsTypes.get(String(id)))

const getCurrentGoodsTypeStatus = state => state.goodsTypes.get('current')

export const createGetCurrentGoodsTypeStatusSelector = () => createSelector([getCurrentGoodsTypeStatus], status => status)

export const getGoodsTypeDeleting = state => state.goodsTypes.getIn(['deletion', 'inProgress'])

export const getGoodsTypeDeleteError = state => state.goodsTypes.getIn(['deletion', 'error'])
