import * as actionTypes from './actionTypes'

// Fetch

export const fetchDriverSurcharges = () => ({
    type: actionTypes.FETCH,
})

export const fetchDriverSurchargesFulfilled = driverSurcharges => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: driverSurcharges,
})

export const fetchDriverSurchargesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchDriverSurchargesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchDriverSurcharge = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchDriverSurchargeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchDriverSurchargeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchDriverSurchargeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveDriverSurcharge = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveDriverSurchargeFulfilled = driverSurcharge => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: driverSurcharge,
})

export const saveDriverSurchargeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveDriverSurchargeDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteDriverSurcharge = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteDriverSurchargeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteDriverSurchargeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Clear

export const clearDriverSurcharges = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportDriverSurcharges = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
