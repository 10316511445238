import * as actionTypes from './actionTypes'

// Fetch

export const fetchTaxRates = () => ({
    type: actionTypes.FETCH,
})

export const fetchTaxRatesFulfilled = taxRates => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: taxRates,
})

export const fetchTaxRatesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTaxRatesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchTaxRate = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchTaxRateFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchTaxRateRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchTaxRateCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveTaxRate = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveTaxRateFulfilled = taxRate => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: taxRate,
})

export const saveTaxRateRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteTaxRate = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteTaxRateFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteTaxRateRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportTaxRates = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
