import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCustomerTurnovers = (month, filters) => ({
    type: actionTypes.FETCH,
    payload: {
        month,
        filters
    },
})

export const fetchReportCustomerTurnoversFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCustomerTurnoversRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCustomerTurnoversCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCustomerTurnovers = () => ({
    type: actionTypes.CLEAR,
})
