import { createSelector } from 'reselect'

export const getVehicleTypes = state => state.vehicleTypes.getIn(['all', 'objects'])

export const createGetVehicleTypeByIDSelector = id => createSelector([getVehicleTypes], vehicleTypes => vehicleTypes.get(String(id)))

const getCurrentVehicleTypeStatus = state => state.vehicleTypes.get('current')

export const createGetCurrentVehicleTypeStatusSelector = () => createSelector([getCurrentVehicleTypeStatus], status => status)

export const getVehicleTypeDeleting = state => state.vehicleTypes.getIn(['deletion', 'inProgress'])

export const getVehicleTypeDeleteError = state => state.vehicleTypes.getIn(['deletion', 'error'])
