import React, { Component } from 'react'
import deepEqual from 'deep-equal'

import DatePicker from 'react-datepicker'
import InputField from './InputField'
import SelectField from './SelectField'
import CheckboxField from './CheckboxField'
import BooleanField from './BooleanField'
import SuggestField from './SuggestField'
import CustomerPicker from './CustomerPicker'
import FontawesomePicker from './FontawesomePicker'
import ColorPicker from './ColorPicker'

class Form extends Component {
    state = {
        values: { ...this.props.values },
        errors: {},
        touched: [],
        formSubmissionAttempted: false,
    }

    inputRefs = {}
    children = {}
    defaultValues = null

    constructor(props) {
        super(props)

        this.updateChildren(props)
    }

    handleInputChange = event => {
        this.setValue(event.target.id, event.target.value)
    }

    handleSelectChange = (id, option) => {
        let value = null

        if (option !== null) {
            if (option instanceof Array) {
                value = option.map(item => item.value).filter(item => Boolean(item))
            } else if (typeof option === 'object' && option.value) {
                value = option.value
            }
        }

        this.setValue(id, value)
    }

    handleSuggestChange = (event, { newValue, method }) => {
        const isNotChangingMethod = ['down', 'up']

        if (method === 'enter') event.preventDefault()

        if (!isNotChangingMethod.includes(method)) {
            let id = event.target.id

            if (id.includes('react-autowhatever-')) {
                id = id.replace('react-autowhatever-', '').split('--')[0]
            }

            this.setValue(id, newValue)
        }
    }

    handleCheckboxChange = event => {
        this.setValue(event.target.id, event.target.checked)
    }

    getValue = id => {
        // tests if this "id" is present in state.values
        // or, in case the id represents an array of values,
        // if the embedded key is present in values
        //
        // =====================================================================
        // Example:
        // ---------------------------------------------------------------------
        // id = 0_value
        // which means:
        // id = {arrayKey}_{valueName}
        // ---------------------------------------------------------------------
        // state.values:
        // 0 => {id: 1, value: 150}
        // 1 => {id: 2, value: 250}
        // ---------------------------------------------------------------------
        // results in returning "value" from array key 0
        // =====================================================================
        if (id) {
            // eslint-disable-next-line require-unicode-regexp
            if (id.indexOf('_') !== -1 && this.state.values.hasOwnProperty(id.split('_')[0]) && /\d+/.test(id.split('_')[0])) {
                return this.state.values[id.split('_')[0]][id.substr(id.indexOf('_') + 1, id.lenght)] || ''
            } else if (this.state.values.hasOwnProperty(id)) {
                return this.state.values[id]
            }
        }

        return ''
    }

    isTouched = id => this.state.touched.includes(id)

    setValue = (id, value) => {
        const newState = {
            values: {
                ...this.state.values,
                [id]: value,
            },
        }
        if (!this.isTouched(id)) {
            newState.touched = [...this.state.touched, id]
        }

        this.setState(newState, () => {
            this.inputRefs[id].forceUpdate()
            this.validate()
        })

        this.props.onChange && this.props.onChange(newState.values)
    }

    setValues = values => {
        const newState = {
            values: { ...this.state.values, ...values },
        }

        this.setState(newState)
        this.props.onChange && this.props.onChange(newState.values)
    }

    getError = id => {
        if (!this.state.formSubmissionAttempted && !this.isTouched(id)) {
            return null
        }

        const error = this.state.errors[id]

        if (error === undefined) {
            return null
        }

        return error
    }

    validate = () => {
        const errors = {}

        Object.keys(this.props.validationRules).forEach(id => {
            const validationRules = this.props.validationRules[id]
            let error = null

            if (validationRules instanceof Array && validationRules.length > 0) {
                for (const rule of validationRules) {
                    error = rule(this.getValue(id), this.props.isEditing)

                    if (error) {
                        break
                    }
                }
            } else {
                error = validationRules(this.getValue(id), this.props.isEditing)
            }

            if (typeof error === 'string') {
                errors[id] = error
            }
        })

        const formIsValid = Object.keys(errors).length === 0

        this.setState(
            {
                errors,
            },
            () => {
                Object.entries(this.inputRefs).forEach(ref => {
                    ref[1].forceUpdate()
                })
            }
        )

        return formIsValid
    }

    readonly = () => {
        // there are no permissions defined (or no props at all)
        // therefore we have to allow this child
        if (typeof this.props === 'undefined' || typeof this.props.permissions === 'undefined') {
            return false
        }
        const permissionsIntersect = this.props.permissions.filter(x => this.props.requiredpermissions.includes(x))
        // test if user has more than 1 permission on this component
        // and if this permission is not read-only
        if (permissionsIntersect.length <= 1 && permissionsIntersect[0].split('.')[1] === 'read') {
            return true
        }
        const permissions = permissionsIntersect.map(x => x.split('.')[1])
        // test if user has no update and create permission
        if (!permissions.includes('update') && !permissions.includes('create')) {
            return true
        }
        // test if is defined type of form (creat/edit)
        // and if there is permission to edit fields
        if (typeof this.props.isEdit !== 'undefined') {
            if (this.props.isEdit && !permissions.includes('update')) {
                return true
            } else if (!this.props.isEdit && !permissions.includes('create')) {
                return true
            }
        }

        return false
    }

    handleSubmit = event => {
        event.preventDefault()

        this.setState({ formSubmissionAttempted: true }, () => {
            if (!this.validate()) {
                return
            }

            this.props.onSubmit(this.state.values)
        })
    }

    handleButton = (event, child) => {
        event.preventDefault()

        this.setState({ formSubmissionAttempted: true }, () => {
            if (!this.validate()) {
                return
            }

            child.props.onClick(event)
        })
    }

    updateChildren = props => {
        this.inputRefs = {}

        const mapChildrenRecursively = (children, handler) =>
            React.Children.map(children, child => {
                const updatedChild = handler(child)

                if (updatedChild && updatedChild.props && updatedChild.props.children) {
                    const updatedChildChildren = mapChildrenRecursively(updatedChild.props.children, handler)

                    return React.cloneElement(updatedChild, {
                        children: updatedChildChildren,
                    })
                }

                return updatedChild
            })

        this.defaultValues = {}

        this.children = mapChildrenRecursively(props.children, child => {
            if (
                child &&
                [InputField, SelectField, CheckboxField, BooleanField, SuggestField, CustomerPicker, FontawesomePicker, ColorPicker].includes(
                    child.type
                )
            ) {
                let changeHandler = child.type === CheckboxField ? this.handleCheckboxChange : this.handleInputChange
                if (child.type === SelectField) {
                    changeHandler = option => this.handleSelectChange(child.props.id, option)
                } else if (child.type === SuggestField) {
                    changeHandler = this.handleSuggestChange
                } else if (child.type === CustomerPicker || child.type === FontawesomePicker || child.type === ColorPicker) {
                    changeHandler = option => this.setValue(child.props.id, option)
                }

                if (child.props.onChange !== undefined) {
                    changeHandler = child.props.onChange

                    if (child.type === SelectField) {
                        changeHandler = option => child.props.onChange(child.props.id, option)
                    } else if (child.type === SuggestField) {
                        changeHandler = this.handleSuggestChange
                    }
                }

                if (
                    !this.getValue(child.props.id) &&
                    child.type === SelectField &&
                    child.props.prompt === false &&
                    child.props.values &&
                    child.props.values.length
                ) {
                    this.defaultValues[child.props.id] = String(child.props.values[0].id)
                }

                return React.cloneElement(child, {
                    key: `field-${child.props.id}`,
                    readonly: child.props.readonly ? child.props.readonly : this.readonly,
                    onChange: changeHandler,
                    getValue: () => this.getValue(child.props.id),
                    getError: () => this.getError(child.props.id),
                    defaultValue: this.defaultValues[child.props.id],
                    ref: input => {
                        if (input !== null) {
                            this.inputRefs[input.props.id] = input
                        }
                    },
                })
            } else if (child && child.type === 'button' && child.props['data-validate'] && child.props.onClick) {
                return React.cloneElement(child, {
                    onClick: event => this.handleButton(event, child),
                })
            } else if (child && child.type === DatePicker) {
                return React.cloneElement(child, {
                    disabled: child.props.readonly ? child.props.readonly() : this.readonly(),
                })
            }

            return child
        })
    }

    componentWillMount() {
        this.validate()
    }

    componentDidMount() {
        this.defaultValues && this.setValues(this.defaultValues)
    }

    componentWillReceiveProps(nextProps) {
        const valuesEqual = deepEqual(this.state.values, nextProps.values)

        if (valuesEqual === false) {
            this.setValues(nextProps.values)
        }
    }

    shouldComponentUpdate(nextProps) {
        const childrenEqual = deepEqual(this.props.children, nextProps.children)

        if (childrenEqual === false) {
            this.updateChildren(nextProps)
        }

        return childrenEqual === false
    }

    render() {
        return <form onSubmit={this.handleSubmit}>{this.children}</form>
    }
}

export default Form
