import cs from './translations/cs'
import en from './translations/en'
import pl from './translations/pl'

const localization = {
    cs,
    en,
    pl,
}

export default localization
