import * as actionTypes from './actionTypes'

// Fetch

export const fetch = settings => ({
    type: actionTypes.FETCH,
    payload: settings,
})

export const fetchFulfilled = pois => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: pois,
})

export const fetchRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Preferences

export const updatePreferences = (preferences, settings) => ({
    type: actionTypes.PREFERENCES_UPDATE,
    payload: {
        preferences,
        settings,
    },
})

export const updatePreferencesFulfilled = data => ({
    type: actionTypes.PREFERENCES_UPDATE_FULFILLED,
    payload: data,
})

export const updatePreferencesRejected = message => ({
    type: actionTypes.PREFERENCES_UPDATE_REJECTED,
    payload: message,
})

export const updatePreferencesCancelled = () => ({
    type: actionTypes.PREFERENCES_UPDATE_CANCELLED,
})
