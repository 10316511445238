import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportTransportOrders = settings => ({
    type: actionTypes.FETCH,
    payload: settings,
})

export const fetchReportTransportOrdersFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportTransportOrdersRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportTransportOrdersCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportTransportOrders = () => ({
    type: actionTypes.CLEAR,
})
