import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportVehicleCosts = (year, inclCostCenters, inclDrivers, inclTrailers, withEmpty, withInactive) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        inclCostCenters,
        inclDrivers,
        inclTrailers,
        withEmpty,
        withInactive
    },
})

export const fetchReportVehicleCostsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportVehicleCostsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportVehicleCostsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Export

export const exportReportVehicleCosts = (year, filters, inclCostCenters, inclDrivers, inclTrailers, withEmpty, withInactive) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
        inclCostCenters,
        inclDrivers,
        inclTrailers,
        withEmpty,
        withInactive
    },
})

// Clear

export const clearReportVehicleCosts = () => ({
    type: actionTypes.CLEAR,
})

// Fetch details

export const fetchReportVehicleCostsDetails = (id, year, month, inclCostCenters, inclDrivers, inclTrailers) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        id,
        year,
        month,
        inclCostCenters,
        inclDrivers,
        inclTrailers
    },
})

export const fetchReportVehicleCostsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportVehicleCostsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportVehicleCostsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Export

export const exportReportVehicleCostsDetails = (id, year, month, inclCostCenters, inclDrivers, inclTrailers) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        id,
        year,
        month,
        inclCostCenters,
        inclDrivers,
        inclTrailers
    },
})

// Clear

export const clearReportVehicleCostsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})
