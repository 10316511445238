import { Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
        duplicity: null,
    }),
    current: Map({
        fetching: false,
        saving: false,
        error: null,
        id: null,
    }),
    deletion: Map({
        inProgress: false,
        error: null,
    }),
    restoring: Map({
        inProgress: false,
        error: null,
    }),
    transportStart: Map({
        object: false,
        fetching: false,
        error: null,
    }),
    history: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    documents: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    equipments: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    events: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    fuelings: Map({
        data: [],
        fetching: false,
        error: null,
    }),
})

function vehicles(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // One
        case actionTypes.FETCH_ONE_STARTED:
            return state.setIn(['current', 'fetching'], true)
        case actionTypes.FETCH_ONE_FULFILLED:
        case actionTypes.FETCH_ONE_REJECTED:
        case actionTypes.FETCH_ONE_CANCELLED:
            return state.setIn(['current', 'fetching'], false)

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], true)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicity'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['current', 'id'], action.payload.get('id'))
                    .setIn(['all', 'duplicity'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], action.payload)
                    .setIn(['all', 'duplicity'], null)
            })
        case actionTypes.SAVE_DUPLICITY_FOUND:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicity'], action.payload)
            })

        // Delete
        case actionTypes.DELETE_STARTED:
            return state.setIn(['deletion', 'inProgress'], true)
        case actionTypes.DELETE_FULFILLED:
            return state.deleteIn(['all', 'objects', action.payload]).withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], null)
            })
        case actionTypes.DELETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], action.payload)
            })

        // Restore
        case actionTypes.RESTORE_STARTED:
            return state.setIn(['restoring', 'inProgress'], true)
        case actionTypes.RESTORE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['restoring', 'inProgress'], false)
                    .setIn(['restoring', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.RESTORE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['restoring', 'inProgress'], false).setIn(['restoring', 'error'], action.payload)
            })

        // Transport start
        case actionTypes.FETCH_TRANSPORT_START_STARTED:
            return state.setIn(['transportStart', 'fetching'], true)
        case actionTypes.FETCH_TRANSPORT_START_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['transportStart', 'object'], action.payload)
                    .setIn(['transportStart', 'fetching'], false)
                    .setIn(['transportStart', 'error'], null)
            })
        case actionTypes.FETCH_TRANSPORT_START_REJECTED:
            return state.withMutations(map => {
                map.setIn(['transportStart', 'fetching'], false).setIn(['transportStart', 'error'], action.payload)
            })
        case actionTypes.FETCH_TRANSPORT_START_CANCELLED:
            return state.setIn(['transportStart', 'fetching'], false)
        case actionTypes.CLEAR_TRANSPORT_START:
            return state.setIn(['transportStart', 'object'], null)

        // Fetch vehicle history
        case actionTypes.FETCH_HISTORY_STARTED:
            return state.withMutations(map => {
                map.setIn(['history', 'fetching'], true).setIn(['history', 'error'], null)
            })
        case actionTypes.FETCH_HISTORY_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['history', 'fetching'], false)
                    .setIn(['history', 'error'], null)
                    .setIn(['history', 'data'], action.payload)
            })
        case actionTypes.FETCH_HISTORY_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['history', 'fetching'], false)
                    .setIn(['history', 'error'], action.payload)
                    .setIn(['history', 'data'], null)
            })

        // Fetch vehicle documents
        case actionTypes.FETCH_DOCUMENTS_STARTED:
            return state.withMutations(map => {
                map.setIn(['documents', 'fetching'], true).setIn(['documents', 'error'], null)
            })
        case actionTypes.FETCH_DOCUMENTS_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['documents', 'fetching'], false)
                    .setIn(['documents', 'error'], null)
                    .setIn(['documents', 'data'], action.payload)
            })
        case actionTypes.FETCH_DOCUMENTS_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['documents', 'fetching'], false)
                    .setIn(['documents', 'error'], action.payload)
                    .setIn(['documents', 'data'], null)
            })

        // Fetch vehicle equipments
        case actionTypes.FETCH_EQUIPMENTS_STARTED:
            return state.withMutations(map => {
                map.setIn(['equipments', 'fetching'], true).setIn(['equipments', 'error'], null)
            })
        case actionTypes.FETCH_EQUIPMENTS_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['equipments', 'fetching'], false)
                    .setIn(['equipments', 'error'], null)
                    .setIn(['equipments', 'data'], action.payload)
            })
        case actionTypes.FETCH_EQUIPMENTS_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['equipments', 'fetching'], false)
                    .setIn(['equipments', 'error'], action.payload)
                    .setIn(['equipments', 'data'], null)
            })

        // Fetch vehicle events
        case actionTypes.FETCH_EVENTS_STARTED:
            return state.withMutations(map => {
                map.setIn(['events', 'fetching'], true).setIn(['events', 'error'], null)
            })
        case actionTypes.FETCH_EVENTS_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['events', 'fetching'], false)
                    .setIn(['events', 'error'], null)
                    .setIn(['events', 'data'], action.payload)
            })
        case actionTypes.FETCH_EVENTS_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['events', 'fetching'], false)
                    .setIn(['events', 'error'], action.payload)
                    .setIn(['events', 'data'], null)
            })

        // Fetch vehicle fuelings
        case actionTypes.FETCH_FUELINGS_STARTED:
            return state.withMutations(map => {
                map.setIn(['fuelings', 'fetching'], true).setIn(['fuelings', 'error'], null)
            })
        case actionTypes.FETCH_FUELINGS_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['fuelings', 'fetching'], false)
                    .setIn(['fuelings', 'error'], null)
                    .setIn(['fuelings', 'data'], action.payload)
            })
        case actionTypes.FETCH_FUELINGS_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['fuelings', 'fetching'], false)
                    .setIn(['fuelings', 'error'], action.payload)
                    .setIn(['fuelings', 'data'], null)
            })

        default:
            return state
    }
}

export default vehicles
