// Fetch offers list

export const FETCH = 'transporeon_offers.FETCH'
export const FETCH_STARTED = 'transporeon_offers.FETCH_STARTED'
export const FETCH_FULFILLED = 'transporeon_offers.FETCH_FULFILLED'
export const FETCH_REJECTED = 'transporeon_offers.FETCH_REJECTED'
export const FETCH_CANCELLED = 'transporeon_offers.FETCH_CANCELLED'

export const SORTING_CHANGED = 'transporeon_offers.SORTING_CHANGED'

// Accept

export const ACCEPT = 'transporeon_offers.ACCEPT'
export const ACCEPT_STARTED = 'transporeon_offers.ACCEPT_STARTED'
export const ACCEPT_FULFILLED = 'transporeon_offers.ACCEPT_FULFILLED'
export const ACCEPT_REJECTED = 'transporeon_offers.ACCEPT_REJECTED'
export const ACCEPT_CANCELLED = 'transporeon_offers.ACCEPT_CANCELLED'

// Decline

export const DECLINE = 'transporeon_offers.DECLINE'
export const DECLINE_STARTED = 'transporeon_offers.DECLINE_STARTED'
export const DECLINE_FULFILLED = 'transporeon_offers.DECLINE_FULFILLED'
export const DECLINE_REJECTED = 'transporeon_offers.DECLINE_REJECTED'
export const DECLINE_CANCELLED = 'transporeon_offers.DECLINE_CANCELLED'

// Export

export const EXPORT = 'transporeon_offers.EXPORT'
export const EXPORT_FULFILLED = 'transporeon_offers.EXPORT_FULFILLED'
