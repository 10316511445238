import { Record } from 'immutable'

export class UserLogins extends Record({
    id: undefined,
    login_success: undefined,
    datetime: undefined,
    login: undefined,
    user_id: undefined,
    ip_address: undefined,
    user_agent: undefined,
}) {}
