import React from 'react'
import { useDrop } from 'react-dnd'

export const Constant = ({ constantKey, children }) => {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'Variable',
        drop: () => ({ constantKey }),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })
    const isActive = canDrop && isOver
    let backgroundColor = '#FFFFFF'
    if (isActive) {
        backgroundColor = '#DDDDDD'
    } else if (canDrop) {
        backgroundColor = '#EEEEEE'
    }
    return (
        <div ref={drop} className="constant" style={{ backgroundColor }}>
            {children}
        </div>
    )
}
