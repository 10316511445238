import { Record } from 'immutable'

export class VehicleEquipment extends Record({
    id: undefined,
    vehicle_id: undefined,
    vehicle: undefined,
    equipment_id: undefined,
    equipment: undefined,
    note: undefined,
    date_validity_from: undefined,
    date_validity_to: undefined,
}) {}
