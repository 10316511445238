import React from 'react'
import { Route, Switch } from 'react-router'
import BorderCrossingsList from './BorderCrossingsList'
import BorderCrossingsEdit from './BorderCrossingsEdit'

const BorderCrossings = props => (
    <Switch>
        <Route exact path={props.match.url} component={BorderCrossingsList} />
        <Route exact path={`${props.match.url}/new`} component={BorderCrossingsEdit} />
        <Route exact path={`${props.match.url}/:borderCrossingID`} component={BorderCrossingsEdit} />
    </Switch>
)

export default BorderCrossings
