import React, { Component } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { injectIntl } from 'react-intl'

import { datetimeFormatter } from '../../../common/formatters'

// eslint-disable-next-line react/prefer-stateless-function
class ChatMessage extends Component {
    render() {
        const message = this.props.message

        let stateIconClass = 'far fa-clock text-gray-lighter'
        let stateIconText = this.props.intl.formatMessage({ id: 'messages.state.sending' })
        if (message && message.type_id === 1) {
            if (message.read_at) {
                stateIconClass = 'fas fa-check-circle text-success'
                stateIconText = `${this.props.intl.formatMessage({ id: 'messages.state.read' })} ${datetimeFormatter(message.read_at)}`
            } else if (message.received_at) {
                stateIconClass = 'fas fa-check-circle text-gray-lighter'
                stateIconText = `${this.props.intl.formatMessage({ id: 'messages.state.received' })} ${datetimeFormatter(message.received_at)}`
            } else if (message.sent_at) {
                stateIconClass = 'far fa-check-circle text-gray-lighter'
                stateIconText = `${this.props.intl.formatMessage({ id: 'messages.state.sent' })} ${datetimeFormatter(message.sent_at)}`
            }
        }

        const stateIconTooltip = <Tooltip id="tooltip">{stateIconText}</Tooltip>

        return (
            <div>
                {message.type_id === 1 ? (
                    <div className="chat-item chat-item-right">
                        <div className="chat-message">{message.text}</div>
                        <small className="wp-100 pull-left text-gray text-right">
                            {message.user && `${message.user.name} | `}
                            {datetimeFormatter(message.created_at)}
                            <OverlayTrigger placement="left" overlay={stateIconTooltip}>
                                <i className={`m-l-xxs ${stateIconClass}`} />
                            </OverlayTrigger>
                        </small>
                    </div>
                ) : (
                    <div className="chat-item chat-item-left">
                        <div className="chat-message">{message.text}</div>
                        <small className="wp-100 pull-left text-gray">
                            {datetimeFormatter(message.created_at)}
                            {message.driver && ` | ${message.driver.name}`}
                        </small>
                    </div>
                )}
            </div>
        )
    }
}

export default injectIntl(ChatMessage)
