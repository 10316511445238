import { createSelector } from 'reselect'

export const getDriverTimesheets = state => state.driverTimesheets.getIn(['all', 'objects'])

export const createGetDriverTimesheetByIDSelector = id =>
    createSelector(
        [getDriverTimesheets],
        driverTimesheets => driverTimesheets.get(String(id))
    )

const getCurrentDriverTimesheetStatus = state => state.driverTimesheets.get('current')

export const createGetCurrentDriverTimesheetStatusSelector = () =>
    createSelector(
        [getCurrentDriverTimesheetStatus],
        status => status
    )

export const getDriverTimesheetsFetching = state => state.driverTimesheets.getIn(['all', 'fetching'])

export const getDriverTimesheetDeleting = state => state.driverTimesheets.getIn(['deletion', 'inProgress'])

export const getDriverTimesheetDeleteError = state => state.driverTimesheets.getIn(['deletion', 'error'])

const getDuplicity = state => state.driverTimesheets.getIn(['all', 'duplicity'])

export const createGetDuplicitySelector = () =>
    createSelector(
        [getDuplicity],
        duplicity => duplicity
    )
