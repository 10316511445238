import { createSelector } from 'reselect'

export const getServiceWorkers = state => state.serviceWorkers.getIn(['all', 'objects'])

export const createGetServiceWorkerByIDSelector = id => createSelector([getServiceWorkers], serviceWorkers => serviceWorkers.get(String(id)))

const getCurrentServiceWorkerStatus = state => state.serviceWorkers.get('current')

export const createGetCurrentServiceWorkerStatusSelector = () => createSelector([getCurrentServiceWorkerStatus], status => status)

export const getServiceWorkerDeleting = state => state.serviceWorkers.getIn(['deletion', 'inProgress'])

export const getServiceWorkerDeleteError = state => state.serviceWorkers.getIn(['deletion', 'error'])
