// eslint-disable-next-line max-classes-per-file
import { Record } from 'immutable'

export class VehicleFueling extends Record({
    id: undefined,
    import_file_id: undefined,
    datetime: undefined,
    vehicle_id: undefined,
    registration: undefined,
    trailer_id: undefined,
    registration_trailer: undefined,
    driver_id: undefined,
    country_id: undefined,
    fuel_level: undefined,
    adblue_level: undefined,
    number: undefined,
    gps_lat: undefined,
    gps_lng: undefined,
}) {}

export class VehicleFuelingPreview extends Record({
    id: undefined,
    datetime: undefined,
    vehicle: undefined,
    registration: undefined,
    driver: undefined,
    country: undefined,
    fuel_level: undefined,
    adblue_level: undefined,
    number: undefined,
    gps_lat: undefined,
    gps_lng: undefined,
    infolog_datetime: undefined,
    infolog_vehicle: undefined,
    infolog_registration: undefined,
    infolog_driver: undefined,
    infolog_country: undefined,
    infolog_fuel_level: undefined,
    infolog_adblue_level: undefined,
    infolog_number: undefined,
    infolog_gps_lat: undefined,
    infolog_gps_lng: undefined,
}) {}
