import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class DriverSurchargesListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render() {
        const driverSurcharge = this.props.data
        const driver = this.props.driver
        const driverSurchargeType = this.props.driverSurchargeType
        const currency = this.props.currency

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-160">{driver ? driver.getShortFullName() : null}</td>
                <td className="w-240">{driverSurchargeType ? driverSurchargeType.name : null}</td>
                <td className="w-200 text-center">{driverSurcharge.date ? formatters.dateFormatter(driverSurcharge.date) : null}</td>
                <td className="w-160 text-right">{formatters.priceFormatter(driverSurcharge.value, '0.00', currency ? currency.iso_code : null)}</td>
                <td className="w-max wm-200">{driverSurcharge.note}</td>
            </tr>
        )
    }
}

export default injectIntl(DriverSurchargesListRow)
