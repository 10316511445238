/* eslint-disable prefer-object-spread */
import * as actionTypes from './actionTypes'

const initialState = {
    unreadNotificationsLoading: true,
    unreadNotifications: [],
    notifications: [],
    settings: [],
    subscribedEvents: [],
    userId: null,
    loading: true,
    loadingSettings: false,
    savingSettings: false,
}

function wsnotifications(state = initialState, action) {
    switch (action.type) {
        case actionTypes.RECEIVED:
            // test if this user should receive this notification
            // otherwise return original state
            if (action.payload.users.includes(parseInt(action.userId))) {
                return Object.assign({}, state, {
                    unreadNotifications: [action.payload, ...state.unreadNotifications],
                })
            }
            return state
        case actionTypes.MARK_AS_READ_STARTED:
            return Object.assign({}, state, {
                loading: true,
            })
        case actionTypes.MARK_AS_READ_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                unreadNotifications: state.unreadNotifications.filter(notification => parseInt(notification.timestamp) !== parseInt(action.payload)),
                notifications: {
                    ...state.notifications,
                    data:
                        state.notifications.data !== undefined
                            ? state.notifications.data.map(notification => {
                                  if (parseInt(notification.timestamp) === parseInt(action.payload)) {
                                      notification.is_read = true
                                  }
                                  return notification
                              })
                            : [],
                },
            })
        case actionTypes.UNREAD_NOTIFICATIONS_FETCH_STARTED:
            return Object.assign({}, state, {
                unreadNotificationsLoading: true,
            })
        case actionTypes.UNREAD_NOTIFICATIONS_FETCH_FULFILLED:
            return Object.assign({}, state, {
                unreadNotifications: action.payload,
                unreadNotificationsLoading: false,
            })
        case actionTypes.UNREAD_NOTIFICATIONS_FETCH_REJECTED:
            return Object.assign({}, state, {
                unreadNotificationsLoading: false,
            })
        case actionTypes.NOTIFICATIONS_FETCH_STARTED:
            return Object.assign({}, state, {
                loading: true,
            })
        case actionTypes.NOTIFICATIONS_FETCH_FULFILLED:
            return Object.assign({}, state, {
                notifications: action.payload,
                loading: false,
            })
        case actionTypes.NOTIFICATIONS_SETTINGS_FETCH_STARTED:
            return Object.assign({}, state, {
                loadingSettings: true,
            })
        case actionTypes.NOTIFICATIONS_SETTINGS_FETCH_FULFILLED:
            return Object.assign({}, state, {
                settings: action.payload,
                loadingSettings: false,
            })
        case actionTypes.NOTIFICATIONS_SETTINGS_FETCH_REJECTED:
            return Object.assign({}, state, {
                loadingSettings: false,
            })
        case actionTypes.NOTIFICATIONS_SETTINGS_SAVE_STARTED:
            return Object.assign({}, state, {
                savingSettings: true,
            })
        case actionTypes.NOTIFICATIONS_SETTINGS_SAVE_FULFILLED:
            return Object.assign({}, state, {
                settings: action.payload,
                savingSettings: false,
            })
        default:
            return state
    }
}

export default wsnotifications
