import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import { Modal, Tabs, Tab } from 'react-bootstrap'

import * as formatters from '../../../common/formatters'
import { Form, InputField, CheckboxField, SelectField, SubmitButton } from '../../../common/form'
import { url, handleCommonEditActions, validationMessage, getNormalizedString, getPrerequisitesArray } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as customersActionCreators from '../actionCreators'
import {
    createGetCustomerByIDSelector,
    createGetCurrentCustomerStatusSelector,
    getInfoByVATLoading,
    createGetInfoByVATSelector,
    createGetDuplicitySelector,
    getCustomerDuplicityChecking,
    getCustomerDuplicityCheckingData,
    getCustomerDuplicityCheckingError,
} from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import { getCustomerAddresses } from '../../customer_address/selectors'

import CustomersAddressesList from './CustomersAddressesList'
import CustomersContactsList from './CustomersContactsList'
import CustomersPlansList from './CustomersPlansList'
import {ConfirmDialog} from "../../../common/confirm_dialog";

const componentIdentifier = 'customers_edit'
const prerequisites = ['countries', 'currencies', 'users', 'company_bank_accounts']

class CustomersEdit extends Component {
    defaultValues = {
        is_spedition: false,
    }

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
        modalDuplicity: {
            show: false,
            duplicity: null,
        },
        customerAddresses: {
            show: true,
        },
        customerContacts: {
            show: false,
        },
        customerPlans: {
            show: false,
        },
        duplicityFound: false,
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        if (values.vat_number !== this.state.values.vat_number) {
            this.checkDuplicity(values.vat_number)
        }

        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.save(false)
        })
    }

    save = ignoreDuplicity => {
        this.props.actions.saveCustomer(this.state.values, ignoreDuplicity)
    }

    duplicityFoundCallback = duplicity => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()
        this.save(true)
    }

    handleVatNumberSearch = () => {
        if (this.state.values && this.state.values.vat_number) {
            this.props.actions.loadInfoByVATNumber(this.state.values.vat_number)
        }
    }

    handleSelectTab = tabKey => {
        if (tabKey === 1) {
            this.loadCustomerAddresses()
        } else if (tabKey === 2) {
            this.loadCustomerContacts()
        } else if (tabKey === 3) {
            this.loadCustomerPlans()
        }
    }

    loadCustomerAddresses = () => {
        this.setState({
            customerAddresses: {
                show: true,
            },
        })
    }

    loadCustomerContacts = () => {
        this.setState({
            customerContacts: {
                show: true,
            },
        })
    }

    loadCustomerPlans = () => {
        this.setState({
            customerPlans: {
                show: true,
            },
        })
    }

    successCallback = null

    handleSave = () => {
        this.successCallback = (props, nextProps) => {
            if (nextProps.status.get('id')) {
                props.redirect(url(props.match, `customers/${nextProps.status.get('id')}`))
            } else {
                props.redirect(url(props.match, 'customers'))
            }
        }

        this.save()
    }

    handleSaveAndClose = () => {
        this.successCallback = props => {
            props.redirect(url(props.match, 'customers'))
        }

        this.save()
    }

    checkDuplicity = vatNumber => {
        this.props.actions.checkCustomerDuplicity({
            vat_number: vatNumber,
            id: this.props.match.params.customerID,
        })
    }

    checkDuplicityCallback = data => {
        if (data.duplicity_found) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.warning' }),
                    message: this.props.intl.formatMessage({ id: 'alerts.messages.duplicityFound' }),
                    position: 'tc',
                },
                'error'
            )
        }
        this.setState({
            duplicityFound: data.duplicity_found,
        })
    }

    // restore
    handleRestore = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.actions.restoreCustomer(this.props.customer.id)
                this.hideConfirmDialog()
            },
        })
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    componentDidMount() {
        this.props.match.params.customerID && this.props.actions.fetchCustomer(this.props.match.params.customerID)
        this.props.actions.clearInfoByVATNumber()
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback, this.duplicityFoundCallback)

        if (JSON.stringify(nextProps.customer) !== JSON.stringify(this.props.customer) || (nextProps.customer && !this.state.values.id)) {
            var newValues = nextProps.customer.toJS()

            if(newValues.customer_default_bank_accounts) {
                const keys = Object.keys(newValues.customer_default_bank_accounts);
                keys.forEach((key, index) => {
                    newValues['company_bank_account_for_currency__' + newValues.customer_default_bank_accounts[key].currency_id] = newValues.customer_default_bank_accounts[key].company_bank_account_id
                });
            }

            this.setValues(newValues)
        }

        if (nextProps.getInfoByVAT && JSON.stringify(nextProps.getInfoByVAT) !== JSON.stringify(this.props.getInfoByVAT)) {
            const newValues = {
                ...this.state.values,
            }

            if (nextProps.getInfoByVAT.name) {
                newValues.name = nextProps.getInfoByVAT.name
            }

            if (nextProps.getInfoByVAT.street) {
                newValues.street = nextProps.getInfoByVAT.street
            }

            if (nextProps.getInfoByVAT.city) {
                newValues.city = nextProps.getInfoByVAT.city
            }

            if (nextProps.getInfoByVAT.zipcode) {
                newValues.zipcode = nextProps.getInfoByVAT.zipcode
            }

            if (nextProps.getInfoByVAT.country_id) {
                newValues.country_id = nextProps.getInfoByVAT.country_id
            }

            if (nextProps.getInfoByVAT.reg_number) {
                newValues.reg_number = nextProps.getInfoByVAT.reg_number
            }

            this.setValues(newValues)
        }

        if (this.props.getDuplicityChecking && !nextProps.getDuplicityChecking && !nextProps.getDuplicityCheckingError) {
            this.checkDuplicityCallback(nextProps.getDuplicityCheckingData)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.customers.heading' }))

        const customer = this.props.customer
        const duplicity = this.props.duplicity

        const mainColClassName = customer ? 'col-lg-4' : 'col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4'

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className={mainColClassName} style={{ paddingRight: '0' }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.customers.heading" /> -{' '}
                                                {this.props.customer ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay
                                            active={
                                                this.props.prerequisites.status.get('fetching') ||
                                                this.props.status.get('fetching') ||
                                                this.props.status.get('saving') ||
                                                this.props.loadingInfoByVAT
                                            }
                                        >
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.customer)}
                                                >

                                                    {customer && customer.deleted_at && (
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-danger m-b-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-8">
                                                                            <p className="no-m">
                                                                                <FormattedMessage id="alerts.messages.warningDeleted" />
                                                                            </p>
                                                                        </div>
                                                                        <PermissionsCheck has={[PERMS.RESTORE]}>
                                                                            <div className="col-sm-4 text-right">
                                                                                <button onClick={this.handleRestore} type="button" className="btn btn-danger">
                                                                                    <FormattedMessage id="buttons.restore" />
                                                                                </button>
                                                                            </div>
                                                                        </PermissionsCheck>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className={`form-group ${this.state.duplicityFound ? 'has-error' : ''}`}>
                                                                <label htmlFor="vat_number">
                                                                    <FormattedMessage id="fields.vatNumber" />
                                                                </label>
                                                                <div className="input-group">
                                                                    <InputField id="vat_number" />
                                                                    <span className="input-group-btn">
                                                                        <button
                                                                            onClick={this.handleVatNumberSearch}
                                                                            className="btn btn-default"
                                                                            type="button"
                                                                        >
                                                                            <span className="far fa-search" />
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="reg_number"
                                                                label={this.props.intl.formatMessage({ id: 'fields.regNumber' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <InputField
                                                        id="name"
                                                        label={this.props.intl.formatMessage({ id: 'fields.customerName' })}
                                                        formGroupClassName="form-group-bigger"
                                                    />
                                                    <InputField
                                                        id="name_transporeon"
                                                        label={this.props.intl.formatMessage({ id: 'fields.nameInTransporeon' })}
                                                    />
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <SelectField
                                                                id="business_manager_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.businessManager' })}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('users'))}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <SelectField
                                                                id="dispatcher_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.dispatcher' })}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('users'))}
                                                            />
                                                        </div>
                                                    </div>

                                                    <h4 className="m-t-lg wp-100">
                                                        <FormattedMessage id="fields.invoicingSettings" />
                                                    </h4>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <CheckboxField
                                                                id="vat_payer"
                                                                label={this.props.intl.formatMessage({ id: 'fields.vatPayer' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <CheckboxField
                                                                id="factoring"
                                                                label={this.props.intl.formatMessage({ id: 'fields.factoring' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <CheckboxField
                                                                id="email_invoicing"
                                                                label={this.props.intl.formatMessage({ id: 'fields.email_invoicing' })}
                                                            />
                                                        </div>
                                                    </div>
		                                            <SelectField
		                                                id="default_invoicing_address_id"
		                                                label={this.props.intl.formatMessage({ id: 'fields.defaultInvoicingAddress' })}
		                                                values={getPrerequisitesArray(this.props.customerInvoicingAddresses)}
		                                            />
                                                    <InputField
                                                        id="email_for_invoicing"
                                                        label={this.props.intl.formatMessage({ id: 'fields.invoicingEmail' })}
                                                    />
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="invoice_deadline_days"
                                                                label={this.props.intl.formatMessage({ id: 'fields.defaultDeadline' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <SelectField
                                                                id="default_currency_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.defaultCurrency' })}
                                                                values={getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('currencies'),
                                                                    'id',
                                                                    'iso_code'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="bank_account"
                                                                label={this.props.intl.formatMessage({ id: 'fields.bankAccountNumber' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <InputField
                                                                id="bank_code"
                                                                label={this.props.intl.formatMessage({ id: 'fields.bankCode' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="bank_account_iban"
                                                                label={this.props.intl.formatMessage({ id: 'fields.bankAccountIBAN' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="bank_account_swift"
                                                                label={this.props.intl.formatMessage({ id: 'fields.bankAccountSWIFT' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <InputField
                                                        id="invoicing_note"
                                                        label={this.props.intl.formatMessage({ id: 'fields.invoicingNote' })}
                                                        autosize
                                                    />

                                                    <h4 className="m-t-lg wp-100">
                                                        <FormattedMessage id="fields.defaultInvoicingBankAccounts" />
                                                    </h4>

                                                    <div className="row">
                                                        {getPrerequisitesArray(this.props.prerequisites.values.get('currencies'), 'id', 'iso_code').map(currency => (
                                                            <div key={`currency-${currency.id}`} className="col-sm-6">
                                                                <SelectField
                                                                    id={`company_bank_account_for_currency__${currency.id}`}
                                                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('company_bank_accounts'))}
                                                                    label={currency.name}
                                                                    nullable
                                                                />
                                                            </div>))}
                                                    </div>

                                                    <h4 className="m-t-lg wp-100">
                                                        <FormattedMessage id="fields.limitsAndBlocking" />
                                                    </h4>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="limit_unpaid_invoices"
                                                                label={this.props.intl.formatMessage({ id: 'fields.limitUnpaidInvoices' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="limit_overdue_invoices"
                                                                label={this.props.intl.formatMessage({ id: 'fields.limitOverdueInvoices' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <CheckboxField id="is_blocked" label={this.props.intl.formatMessage({ id: 'fields.blocked' })} />

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'customers')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        {customer && (
                                                            <SubmitButton
                                                                saveAndClose
                                                                onClick={this.handleSaveAndClose}
                                                                isEdit={Boolean(this.props.customer)}
                                                                perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                                className="btn-addon pull-right"
                                                                disabled={this.state.duplicityFound || this.props.getDuplicityChecking}
                                                            />
                                                        )}
                                                        <SubmitButton
                                                            type="button"
                                                            onClick={this.handleSave}
                                                            isEdit={Boolean(this.props.customer)}
                                                            perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                            className="btn-addon pull-right m-r-xs"
                                                            disabled={this.state.duplicityFound || this.props.getDuplicityChecking}
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>

                            {customer && (
                                <div className="col-lg-8 hp-100" style={{ paddingLeft: '5px' }}>
                                    <div className="panel panel-white hp-100 m-b-no">
                                        <div className="panel-body panel-padding hp-100">
                                            <Tabs
                                                defaultActiveKey={1}
                                                animation={false}
                                                id="detail-tabs"
                                                className="detail-tabs"
                                                onSelect={this.handleSelectTab}
                                            >
                                                <Tab
                                                    eventKey={1}
                                                    title={
                                                        <span>
                                                            <i className="far fa-map-marker-alt m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.addresses' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.customerAddresses.show && <CustomersAddressesList customer={customer} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={2}
                                                    title={
                                                        <span>
                                                            <i className="far fa-user m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.contacts' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.customerContacts.show && <CustomersContactsList customer={customer} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={3}
                                                    title={
                                                        <span>
                                                            <i className="far fa-list-alt m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.transport_plans' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.customerPlans.show && <CustomersPlansList customer={customer} />}
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="confirmDialog.duplicityFound.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="confirmDialog.duplicityFound.message" />
                            {duplicity && (
                                <div>
                                    <div className="row m-t-lg">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.vatNumber" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.vat_number}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.regNumber" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.reg_number}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.name" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.name}</strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            {duplicity && getNormalizedString(duplicity.vat_number) !== getNormalizedString(this.state.values.vat_number) && (
                                <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                                    <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                                </button>
                            )}
                            <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        customer: createGetCustomerByIDSelector(ownProps.match.params.customerID)(state),
        customerInvoicingAddresses: getCustomerAddresses(state),
        status: createGetCurrentCustomerStatusSelector()(state),
        duplicity: createGetDuplicitySelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        loadingInfoByVAT: getInfoByVATLoading(state),
        getInfoByVAT: createGetInfoByVATSelector()(state),
        getDuplicityChecking: getCustomerDuplicityChecking(state),
        getDuplicityCheckingError: getCustomerDuplicityCheckingError(state),
        getDuplicityCheckingData: getCustomerDuplicityCheckingData(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...customersActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CustomersEdit)
)
