import * as actionTypes from './actionTypes'

// Fetch

export const fetchTrailers = () => ({
    type: actionTypes.FETCH,
})

export const fetchTrailersFulfilled = trailers => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: trailers,
})

export const fetchTrailersRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTrailersCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchTrailer = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchTrailerFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchTrailerRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchTrailerCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveTrailer = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveTrailerFulfilled = trailer => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: trailer,
})

export const saveTrailerRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveTrailerDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteTrailer = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteTrailerFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteTrailerRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Restore

export const restoreTrailer = id => ({
    type: actionTypes.RESTORE,
    payload: id,
})

export const restoreTrailerFulfilled = item => ({
    type: actionTypes.RESTORE_FULFILLED,
    payload: item,
})

export const restoreTrailerRejected = message => ({
    type: actionTypes.RESTORE_REJECTED,
    payload: message,
})

// Export

export const exportTrailers = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Fetch trailer history

export const fetchTrailerHistory = id => ({
    type: actionTypes.FETCH_HISTORY,
    payload: id,
})

export const fetchTrailerHistoryFulfilled = data => ({
    type: actionTypes.FETCH_HISTORY_FULFILLED,
    payload: data,
})

export const fetchTrailerHistoryRejected = message => ({
    type: actionTypes.FETCH_HISTORY_REJECTED,
    payload: message,
})

export const fetchTrailerHistoryCancelled = () => ({
    type: actionTypes.FETCH_HISTORY_CANCELLED,
})

// Export trailer history

export const exportTrailerHistory = id => ({
    type: actionTypes.EXPORT_HISTORY,
    payload: id,
})

export const exportTrailerHistoryFulfilled = data => ({
    type: actionTypes.EXPORT_HISTORY_FULFILLED,
    payload: data,
})

export const exportTrailerHistoryRejected = message => ({
    type: actionTypes.EXPORT_HISTORY_REJECTED,
    payload: message,
})

export const exportTrailerHistoryCancelled = () => ({
    type: actionTypes.EXPORT_HISTORY_CANCELLED,
})

// Fetch trailer documents

export const fetchTrailerDocuments = (id, page) => ({
    type: actionTypes.FETCH_DOCUMENTS,
    payload: {
        id,
        page,
    },
})

export const fetchTrailerDocumentsFulfilled = data => ({
    type: actionTypes.FETCH_DOCUMENTS_FULFILLED,
    payload: data,
})

export const fetchTrailerDocumentsRejected = message => ({
    type: actionTypes.FETCH_DOCUMENTS_REJECTED,
    payload: message,
})

export const fetchTrailerDocumentsCancelled = () => ({
    type: actionTypes.FETCH_DOCUMENTS_CANCELLED,
})

// Export trailer documents

export const exportTrailerDocuments = id => ({
    type: actionTypes.EXPORT_DOCUMENTS,
    payload: id,
})

export const exportTrailerDocumentsFulfilled = data => ({
    type: actionTypes.EXPORT_DOCUMENTS_FULFILLED,
    payload: data,
})

export const exportTrailerDocumentsRejected = message => ({
    type: actionTypes.EXPORT_DOCUMENTS_REJECTED,
    payload: message,
})

export const exportTrailerDocumentsCancelled = () => ({
    type: actionTypes.EXPORT_DOCUMENTS_CANCELLED,
})

// Fetch trailer equipments

export const fetchTrailerEquipments = (id, page) => ({
    type: actionTypes.FETCH_EQUIPMENTS,
    payload: {
        id,
        page,
    },
})

export const fetchTrailerEquipmentsFulfilled = data => ({
    type: actionTypes.FETCH_EQUIPMENTS_FULFILLED,
    payload: data,
})

export const fetchTrailerEquipmentsRejected = message => ({
    type: actionTypes.FETCH_EQUIPMENTS_REJECTED,
    payload: message,
})

export const fetchTrailerEquipmentsCancelled = () => ({
    type: actionTypes.FETCH_EQUIPMENTS_CANCELLED,
})

// Export trailer equipments

export const exportTrailerEquipments = id => ({
    type: actionTypes.EXPORT_EQUIPMENTS,
    payload: id,
})

export const exportTrailerEquipmentsFulfilled = data => ({
    type: actionTypes.EXPORT_EQUIPMENTS_FULFILLED,
    payload: data,
})

export const exportTrailerEquipmentsRejected = message => ({
    type: actionTypes.EXPORT_EQUIPMENTS_REJECTED,
    payload: message,
})

export const exportTrailerEquipmentsCancelled = () => ({
    type: actionTypes.EXPORT_EQUIPMENTS_CANCELLED,
})

// Fetch trailer events

export const fetchTrailerEvents = (id, page) => ({
    type: actionTypes.FETCH_EVENTS,
    payload: {
        id,
        page,
    },
})

export const fetchTrailerEventsFulfilled = data => ({
    type: actionTypes.FETCH_EVENTS_FULFILLED,
    payload: data,
})

export const fetchTrailerEventsRejected = message => ({
    type: actionTypes.FETCH_EVENTS_REJECTED,
    payload: message,
})

export const fetchTrailerEventsCancelled = () => ({
    type: actionTypes.FETCH_EVENTS_CANCELLED,
})

// Export trailer events

export const exportTrailerEvents = (id, page) => ({
    type: actionTypes.EXPORT_EVENTS,
    payload: {
        id,
        page,
    },
})

export const exportTrailerEventsFulfilled = data => ({
    type: actionTypes.EXPORT_EVENTS_FULFILLED,
    payload: data,
})

export const exportTrailerEventsRejected = message => ({
    type: actionTypes.EXPORT_EVENTS_REJECTED,
    payload: message,
})

export const exportTrailerEventsCancelled = () => ({
    type: actionTypes.EXPORT_EVENTS_CANCELLED,
})



// Fetch import headers

export const fetchTrailersImportHeaders = file => ({
    type: actionTypes.FETCH_IMPORT_HEADERS,
    payload: file,
})

export const fetchTrailersImportHeadersFulfilled = headers => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_FULFILLED,
    payload: headers,
})

export const fetchTrailersImportHeadersRejected = message => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_REJECTED,
    payload: message,
})

export const fetchTrailersImportHeadersCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_CANCELLED,
})

// Fetch import items

export const fetchTrailersImportItems = (fileId, columnTypes) => ({
    type: actionTypes.FETCH_IMPORT_ITEMS,
    payload: {
        fileId,
        columnTypes,
    },
})

export const fetchTrailersImportItemsFulfilled = (items, defaultLocale) => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_FULFILLED,
    payload: {
        items,
        defaultLocale,
    },
})

export const fetchTrailersImportItemsRejected = message => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_REJECTED,
    payload: message,
})

export const fetchTrailersImportItemsCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_CANCELLED,
})

// Import

export const importTrailers = (fileId, columnTypes, keys) => ({
    type: actionTypes.IMPORT_TRAILERS,
    payload: {
        fileId,
        columnTypes,
        keys
    },
})

export const importTrailersFulfilled = () => ({
    type: actionTypes.IMPORT_TRAILERS_FULFILLED,
})

export const importTrailersRejected = message => ({
    type: actionTypes.IMPORT_TRAILERS_REJECTED,
    payload: message,
})

export const importTrailersCancelled = () => ({
    type: actionTypes.IMPORT_TRAILERS_CANCELLED,
})
