import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import { DispatchersBox } from '../../../common/colored_box'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {speedFormatter, volumeFormatter} from "../../../common/formatters";

class VehicleEventsListRow extends Component {
    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render() {
        const vehiclePosition = this.props.data

        return (
            <tr>
                <PermissionsCheck hasAny={[PERMS.DELETE]}>
                    <td className="w-40 table-buttons text-center">
                        <React.Fragment>
                            <PermissionsCheck has={[PERMS.DELETE]}>
                                <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                            </PermissionsCheck>
                        </React.Fragment>
                    </td>
                </PermissionsCheck>
                <td className="w-80">
                    {vehiclePosition.id}
                </td>
                <td className="w-160">
                    {vehiclePosition.getGPS() && (
                        <a href={'https://www.google.com/maps/place/'+vehiclePosition.getGPS()} target={'_blank'}>{vehiclePosition.getGPS()}</a>
                    )}
                </td>
                <td className="w-200 text-center">
                    {vehiclePosition.datetime ? formatters.datetimeFormatter(vehiclePosition.datetime) : null}
                </td>
                <td className="w-300">{vehiclePosition.position_name}</td>
                <td className="w-100">{this.props.country && this.props.country.iso_code}</td>
                <td className="w-140">
                    {this.props.driver ? this.props.driver.getShortFullName() : null} {this.props.user ? this.props.user.getShortFullName() : null}
                </td>
                <td className="w-120">
                    {this.props.vehicle ? (
                        <DispatchersBox dispatcher={this.props.dispatcher}>{this.props.vehicle.registration}</DispatchersBox>
                    ) : null}
                </td>

                <td className="w-80 text-center">
                    {formatters.boolFormatter(vehiclePosition.is_ignition_on)}
                </td>
                <td className="w-80 text-center">
                    {formatters.boolFormatter(vehiclePosition.is_motor_on)}
                </td>
                <td className="w-80 text-center">
                    {formatters.boolFormatter(vehiclePosition.is_moving)}
                </td>
                <td className="w-80 text-right">
                    {vehiclePosition.speed ? formatters.speedFormatter(vehiclePosition.speed) : ''}
                </td>
                <td className="w-80 text-right">
                    {vehiclePosition.fuel_level}{vehiclePosition.fuel_level ? '%' : ''}
                </td>
                <td className="w-100 text-right">
                    {vehiclePosition.consumption ? formatters.volumeFormatter(vehiclePosition.consumption) : ''}
                </td>
                <td className="w-100 text-right">
                    {vehiclePosition.truck_weight ? formatters.numberFormatter(vehiclePosition.truck_weight, '0', 't') : ''}
                </td>
                <td className="w-100 text-right">
                    {vehiclePosition.km ? formatters.distanceFormatter(vehiclePosition.km) : ''}
                </td>
            </tr>
        )
    }
}

export default VehicleEventsListRow
