import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError } from '../../common/api'
import { vehicleEventSchema } from '../../common/normalizrSchemas'
import { mapNestedRecordsToRecordMap, normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { VehicleEvent } from './model'
import { Vehicle } from '../vehicles/model'
import * as vehiclesActionCreators from '../vehicles/actionCreators'
import { Trailer } from '../trailers/model'
import * as trailersActionCreators from '../trailers/actionCreators'
import { TrailerType } from '../trailer_types/model'
import * as trailerTypesActionCreators from '../trailer_types/actionCreators'
import { User } from '../users/model'
import * as usersActionCreators from '../users/actionCreators'
import { Driver } from '../drivers/model'
import * as driversActionCreators from '../drivers/actionCreators'
import { Transport, TransportPoint } from '../transports/model'
import * as transportsActionCreators from '../transports/actionCreators'
import { Country } from '../countries/model'
import * as countriesActionCreators from '../countries/actionCreators'
import { VehiclePosition } from '../vehicle_positions/model'
import * as vehiclePositionsActionCreators from '../vehicle_positions/actionCreators'
import { DriverTimes } from '../driver_times/model'
import * as driverTimesActionCreators from '../driver_times/actionCreators'

// Fetch

const fetchVehicleEventsEpic = (action$, store) => {
    const tableIdentifier = 'vehicle_events_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `vehicle-events/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(vehicleEventSchema)
                        )

                        let vehicleEvents = normalizedEntitiesToRecordMap(normalizedData.entities.vehicle_events, VehicleEvent, normalizedData.result)
                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                        const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)
                        const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                        const trailerTypes = normalizedEntitiesToRecordMap(normalizedData.entities.trailer_types, TrailerType)
                        const drivers = normalizedEntitiesToRecordMap(normalizedData.entities.drivers, Driver)
                        const transports = normalizedEntitiesToRecordMap(normalizedData.entities.transports, Transport)
                        const transportPoints = normalizedEntitiesToRecordMap(normalizedData.entities.transport_points, TransportPoint)
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)

                        vehicleEvents = mapNestedRecordsToRecordMap(
                            vehicleEvents,
                            {
                                transport_point: { data: transportPoints, recordClass: TransportPoint },
                            },
                            VehicleEvent
                        )

                        const observables = [
                            Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                            Observable.of(usersActionCreators.fetchUsersFulfilled(users)),
                            Observable.of(trailersActionCreators.fetchTrailersFulfilled(trailers)),
                            Observable.of(trailerTypesActionCreators.fetchTrailerTypesFulfilled(trailerTypes)),
                            Observable.of(driversActionCreators.fetchDriversFulfilled(drivers)),
                            Observable.of(transportsActionCreators.fetchTransportsFulfilled(transports)),
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(actionCreators.fetchVehicleEventsFulfilled(vehicleEvents)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchVehicleEventsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchVehicleEventEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().vehicleEvents.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `vehicle-events/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, vehicleEventSchema)

                        const vehicleEvents = normalizedEntitiesToRecordMap(normalizedData.entities.vehicle_events, VehicleEvent)
                        const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)

                        return Observable.concat(
                            Observable.of(trailersActionCreators.fetchTrailersFulfilled(trailers)),
                            Observable.of(actionCreators.fetchVehicleEventsFulfilled(vehicleEvents)),
                            Observable.of(actionCreators.fetchVehicleEventFulfilled())
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchVehicleEventsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveVehicleEventEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().vehicleEvents.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload)

            let path = `vehicle-events`
            let method = 'POST'

            if (values.id) {
                path = `vehicle-events/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, vehicleEventSchema)

                        const vehicleEvents = normalizedEntitiesToRecordMap(normalizedData.entities.vehicle_events, VehicleEvent)
                        const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                        const vehicleEvent = vehicleEvents.valueSeq().first()

                        return Observable.concat(
                            Observable.of(trailersActionCreators.fetchTrailersFulfilled(trailers)),
                            Observable.of(actionCreators.saveVehicleEventFulfilled(vehicleEvent))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.saveVehicleEventRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteVehicleEventEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().vehicleEvents.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `vehicle-events/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteVehicleEventFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteVehicleEventRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Fetch for map

const fetchVehicleEventsForMapEpic = action$ =>
    action$.ofType(actionTypes.FETCH_FOR_MAP).switchMap(() => {
        const requestParams = {
            method: 'POST',
            path: `vehicle-events/for-map`,
        }

        return Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request(requestParams)
                .switchMap(ajaxResponse => {
                    const normalizedData = normalize(ajaxResponse.response, new schema.Array(vehicleEventSchema))

                    let vehicleEvents = normalizedEntitiesToRecordMap(normalizedData.entities.vehicle_events, VehicleEvent, normalizedData.result)
                    const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                    const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)
                    const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                    const trailerTypes = normalizedEntitiesToRecordMap(normalizedData.entities.trailer_types, TrailerType)
                    const drivers = normalizedEntitiesToRecordMap(normalizedData.entities.drivers, Driver)
                    const driverTimes = normalizedEntitiesToRecordMap(normalizedData.entities.times, DriverTimes)
                    const transports = normalizedEntitiesToRecordMap(normalizedData.entities.transports, Transport)
                    const transportPoints = normalizedEntitiesToRecordMap(normalizedData.entities.transport_points, TransportPoint)
                    const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                    const vehiclePositions = normalizedEntitiesToRecordMap(normalizedData.entities.vehicle_positions, VehiclePosition)

                    vehicleEvents = mapNestedRecordsToRecordMap(
                        vehicleEvents,
                        {
                            transport_point: { data: transportPoints, recordClass: TransportPoint },
                        },
                        VehicleEvent
                    )

                    const observables = [
                        Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                        Observable.of(usersActionCreators.fetchUsersFulfilled(users)),
                        Observable.of(trailersActionCreators.fetchTrailersFulfilled(trailers)),
                        Observable.of(trailerTypesActionCreators.fetchTrailerTypesFulfilled(trailerTypes)),
                        Observable.of(driversActionCreators.fetchDriversFulfilled(drivers)),
                        Observable.of(driverTimesActionCreators.fetchDriverTimesFulfilled(driverTimes)),
                        Observable.of(transportsActionCreators.fetchTransportsFulfilled(transports)),
                        Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                        Observable.of(actionCreators.fetchVehicleEventsFulfilled(vehicleEvents)),
                        Observable.of(vehiclePositionsActionCreators.fetchVehiclePositionsFulfilled(vehiclePositions)),
                    ]

                    return Observable.concat(...observables)
                })
                .catch(error => Observable.of(actionCreators.fetchVehicleEventsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
        )
    })

// Fetch one for map

const fetchVehicleEventForMapEpic = action$ =>
    action$
        .ofType(actionTypes.FETCH_ONE_FOR_MAP)
        .switchMap(action => 
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `vehicle-events/${action.payload}/for-map`,
                    method: 'POST',
                })        
                .switchMap(ajaxResponse => {
                    const observables = [
                        Observable.of(actionCreators.fetchVehicleEventFulfilled()),
                        Observable.of(transportsActionCreators.fetchTransportMapPreviewFulfilled(ajaxResponse.response.transport)),
                    ]
                    return Observable.concat(...observables)
                })
                .catch(error => Observable.of(actionCreators.fetchVehicleEventsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
        )
    )
export default [fetchVehicleEventsEpic, fetchVehicleEventEpic, saveVehicleEventEpic, deleteVehicleEventEpic, fetchVehicleEventsForMapEpic, fetchVehicleEventForMapEpic]
