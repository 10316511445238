import { createSelector } from 'reselect'

export const getCarriers = state => state.carriers.getIn(['all', 'objects'])

export const createGetCarrierByIDSelector = id => createSelector([getCarriers], carriers => carriers.get(String(id)))

const getCurrentCarrierStatus = state => state.carriers.get('current')
export const createGetCurrentCarrierStatusSelector = () => createSelector([getCurrentCarrierStatus], status => status)

export const getCarrierDeleting = state => state.carriers.getIn(['deletion', 'inProgress'])
export const getCarrierDeleteError = state => state.carriers.getIn(['deletion', 'error'])

const getInfoByVAT = state => state.carriers.get('infoByVAT')
export const createGetInfoByVATSelector = () => createSelector([getInfoByVAT], data => data.get('data'))
export const getInfoByVATLoading = state => state.carriers.getIn(['infoByVAT', 'inProgress'])
export const getInfoByVATError = state => state.carriers.getIn(['infoByVAT', 'error'])

const getDuplicity = state => state.carriers.getIn(['all', 'duplicity'])
export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)

export const getCarrierContactsData = state => state.carriers.getIn(['contacts', 'data'])
export const getCarrierContactsFetching = state => state.carriers.getIn(['contacts', 'fetching'])
export const getCarrierContactsError = state => state.carriers.getIn(['contacts', 'error'])

export const getCarrierDuplicityChecking = state => state.carriers.getIn(['checkingDuplicity', 'inProgress'])
export const getCarrierDuplicityCheckingError = state => state.carriers.getIn(['checkingDuplicity', 'error'])
export const getCarrierDuplicityCheckingData = state => state.carriers.getIn(['checkingDuplicity', 'data'])
