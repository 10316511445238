import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as transportsActionCreators from '../../transports/actionCreators'

import { Modal } from 'react-bootstrap'
import Form from '../../../common/form/components/Form'
import { InputField, SelectField, SubmitButton } from '../../../common/form'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { getAllowancesData, getAllowancesDeleting, getAllowancesFetching, getAllowancesSaving } from '../../transports/selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_ALLOWANCES } from '../permissions'

const componentIdentifier = 'transport_template_allowances'
const prerequisites = ['currencies', 'allowance_types']

class TransportTemplatesAllowances extends Component {
    state = {
        addAllowancesDialog: {
            show: false,
        },
        values: {},
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    handleChangeValue = values => {
        this.setState({ values })
    }

    deleteItem = (e, allowance) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteAllowance({ allowanceId: allowance.id, transportId: this.props.transport.id })
                this.hideConfirmDialog()
            },
        })
    }

    editItem = (e, allowance) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.setState({
            values: {
                ...allowance,
                allowance_type: allowance.allowance_type && allowance.allowance_type.id,
                currency_id: allowance.currency && allowance.currency.id,
            },
            addAllowancesDialog: {
                show: true,
            },
        })
    }

    submitAddAllowanceForm = () => {
        this.setState({ addAllowancesDialog: { show: false } })
        if (this.props.transport) {
            this.props.actions.saveAllowances([Object.assign({ transport_id: this.props.transport.id }, this.state.values)])
            this.setState({ values: {} })
        }
    }

    refresh = () => {
        this.props.actions.fetchAllowances(this.props.transport.id)
    }

    componentDidMount() {
        if (this.props.transport) {
            this.props.actions.fetchAllowances(this.props.transport.id)
            this.props.actions.fetchPrerequisites()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.transport && JSON.stringify(nextProps.transport) !== JSON.stringify(this.props.transport)) {
            this.props.actions.fetchAllowances(nextProps.transport.id)
        }
    }

    render() {
        const rows = this.props.data
        const total = rows ? Object.keys(rows).length : 0

        const calculationMethods = [
            {
                id: String(1),
                name: this.props.intl.formatMessage({ id: `modules.reportAllowances.calculationMethods.1` }),
            },
        ]

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_ALLOWANCES)} noPermissionsTab>
                <LoadingOverlay active={this.props.isFetching || this.props.isSaving || this.props.isDeleting}>
                    <div className="btns-list">
                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                        </button>
                        <PermissionsCheck has={[PERMS_ALLOWANCES.CREATE]}>
                            <button
                                className="btn btn-success btn-addon"
                                onClick={() => this.setState({ addAllowancesDialog: { show: true }, values: {} })}
                            >
                                <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                            </button>
                        </PermissionsCheck>
                    </div>
                    {rows && rows.length > 0 ? (
                        <React.Fragment>
                            <div className="table-container">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <PermissionsCheck hasAny={[PERMS_ALLOWANCES.UPDATE, PERMS_ALLOWANCES.DELETE]}>
                                                <th className="w-60" />
                                            </PermissionsCheck>
                                            <th className="w-140">
                                                <FormattedMessage id="fields.allowanceType" />
                                            </th>
                                            <th className="w-140">
                                                <FormattedMessage id="fields.allowanceCalculationMethod" />
                                            </th>
                                            <th className="w-140 text-right">
                                                <FormattedMessage id="fields.allowanceAmount" />
                                            </th>
                                            <th className="w-max wm-200">
                                                <FormattedMessage id="fields.note" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows &&
                                            rows.map((allowance, index) => (
                                                <tr onClick={e => this.editItem(e, allowance)} className="row-selectable" key={`row-${index}`}>
                                                    <PermissionsCheck hasAny={[PERMS_ALLOWANCES.UPDATE, PERMS_ALLOWANCES.DELETE]}>
                                                        <td className="w-60 table-buttons">
                                                            <PermissionsCheck has={[PERMS_ALLOWANCES.UPDATE]}>
                                                                <button className="far fa-pencil text-gray m-r-xxs" />
                                                            </PermissionsCheck>
                                                            <PermissionsCheck has={[PERMS_ALLOWANCES.DELETE]}>
                                                                <button
                                                                    onClick={e => this.deleteItem(e, allowance)}
                                                                    className="far fa-trash text-gray"
                                                                />
                                                            </PermissionsCheck>
                                                        </td>
                                                    </PermissionsCheck>
                                                    <td className="w-140">{allowance.allowance_type && allowance.allowance_type.name}</td>
                                                    <td className="w-140">
                                                        <FormattedMessage
                                                            id={`modules.reportAllowances.calculationMethods.${allowance.calculation_method}`}
                                                        />
                                                    </td>
                                                    <td className="w-140 text-right">
                                                        <strong>
                                                            {formatters.priceFormatter(
                                                                allowance.allowance,
                                                                '0,0',
                                                                allowance.currency ? allowance.currency.iso_code : ''
                                                            )}
                                                        </strong>
                                                    </td>
                                                    <td className="w-max wm-200">{allowance.notice}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="pull-left m-l-xxs m-t-md">
                                <FormattedMessage id="pagination.totalRecords" />: {getListItemsCountMessage(0, 0, total)}
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="alert alert-danger pull-left wp-100">
                            <FormattedMessage id="fields.noItemsFound" />
                        </div>
                    )}
                </LoadingOverlay>

                <Modal
                    show={Boolean(this.state.addAllowancesDialog.show)}
                    className="modal-size-sm"
                    onHide={() => this.setState({ addAllowancesDialog: { show: false } })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.values.id ? <FormattedMessage id="fields.itemEdit" /> : <FormattedMessage id="fields.itemCreate" />}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PermissionsCheck hasAny={Object.values(PERMS_ALLOWANCES)}>
                            <Form
                                validationRules={{}}
                                values={this.state.values}
                                onChange={this.handleChangeValue}
                                onSubmit={this.submitAddAllowanceForm}
                                isEdit={Boolean(this.state.values.id)}
                            >
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="nothing">
                                                <FormattedMessage id={'fields.allowanceType'} />:
                                            </label>
                                            <div>
                                                <SelectField
                                                    id="allowance_type"
                                                    values={getPrerequisitesArray(
                                                        this.props.prerequisites.values.get('allowance_types'),
                                                        'id',
                                                        'name'
                                                    )}
                                                    prompt={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="nothing">
                                                <FormattedMessage id={'fields.allowanceCalculationMethod'} />:
                                            </label>
                                            <SelectField id="calculation_method" values={calculationMethods} prompt={false} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="nothing">
                                                <FormattedMessage id={'fields.allowanceAmount'} />:
                                            </label>
                                            <div>
                                                <InputField id="allowance" type="numeric" className="text-right" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="nothing">
                                                <FormattedMessage id={'fields.currency'} />:
                                            </label>
                                            <div>
                                                <SelectField
                                                    id="currency_id"
                                                    values={getPrerequisitesArray(
                                                        this.props.prerequisites.values.get('currencies'),
                                                        'id',
                                                        'iso_code'
                                                    )}
                                                    prompt={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <InputField id="notice" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />
                                </div>

                                <div className="btns-form clearfix">
                                    <button
                                        type="button"
                                        className="btn btn-addon btn-default pull-left"
                                        onClick={() => this.setState({ addAllowancesDialog: { show: false } })}
                                    >
                                        <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                    </button>
                                    <SubmitButton
                                        isEdit={Boolean(this.state.values.id)}
                                        perms={[PERMS_ALLOWANCES.CREATE, PERMS_ALLOWANCES.UPDATE]}
                                        className="btn-addon pull-right"
                                    />
                                </div>
                            </Form>
                        </PermissionsCheck>
                    </Modal.Body>
                </Modal>

                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: getAllowancesData(state),
        isFetching: getAllowancesFetching(state),
        isDeleting: getAllowancesDeleting(state),
        isSaving: getAllowancesSaving(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(TransportTemplatesAllowances)
)
