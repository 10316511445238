import { createSelector } from 'reselect'
import { Map } from 'immutable'

const getPrerequisitesFetchStatus = identifier => state => state.prerequisites.get(identifier)

const createPrerequisitesFetchStatusSelector = identifier => createSelector([getPrerequisitesFetchStatus(identifier)], status => status)

const getPrerequisites = keys => state =>
    Map()
        .withMutations(map => keys.map(key => map.set(key, Map())))
        .merge(state.prerequisites.get('values'))

const createPrerequisitesSelector = keys => createSelector([getPrerequisites(keys)], prerequisites => prerequisites)

export const preparePrerequisitesFetchStatusSelectors = (identifier, keys, state) => ({
    status: createPrerequisitesFetchStatusSelector(identifier)(state),
    values: createPrerequisitesSelector(keys)(state),
})
