import {List, Map, OrderedMap} from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
        duplicities: null,
    }),
    current: Map({
        fetching: false,
        saving: false,
        error: null,
        id: null,
    }),
    deletion: Map({
        inProgress: false,
        error: null,
    }),
    restoring: Map({
        inProgress: false,
        error: null,
    }),
    history: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    documents: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    equipments: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    events: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    timesheets: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    allowances: Map({
        data: [],
        fetching: false,
        deleting: false,
        saving: false,
        error: null,
    }),
    import: Map({
        inProgress: false,
        error: null,
        file_id: null,
        headers: List(),
        headersDefault: Map(),
        items: List(),
        defaultLocale: null,
    }),
})

function drivers(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // One
        case actionTypes.FETCH_ONE_STARTED:
            return state.setIn(['current', 'fetching'], true)
        case actionTypes.FETCH_ONE_FULFILLED:
        case actionTypes.FETCH_ONE_REJECTED:
        case actionTypes.FETCH_ONE_CANCELLED:
            return state.setIn(['current', 'fetching'], false)

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], true)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicities'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['current', 'id'], action.payload.get('id'))
                    .setIn(['all', 'duplicities'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], action.payload)
                    .setIn(['all', 'duplicities'], null)
            })
        case actionTypes.SAVE_DUPLICITY_FOUND:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicities'], action.payload)
            })

        // Delete
        case actionTypes.DELETE_STARTED:
            return state.setIn(['deletion', 'inProgress'], true)
        case actionTypes.DELETE_FULFILLED:
            return state.deleteIn(['all', 'objects', action.payload]).withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], null)
            })
        case actionTypes.DELETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], action.payload)
            })

        // Restore
        case actionTypes.RESTORE_STARTED:
            return state.setIn(['restoring', 'inProgress'], true)
        case actionTypes.RESTORE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['restoring', 'inProgress'], false)
                    .setIn(['restoring', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.RESTORE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['restoring', 'inProgress'], false).setIn(['restoring', 'error'], action.payload)
            })

        // Fetch driver history
        case actionTypes.FETCH_HISTORY_STARTED:
            return state.withMutations(map => {
                map.setIn(['history', 'fetching'], true).setIn(['history', 'error'], null)
            })
        case actionTypes.FETCH_HISTORY_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['history', 'fetching'], false)
                    .setIn(['history', 'error'], null)
                    .setIn(['history', 'data'], action.payload)
            })
        case actionTypes.FETCH_HISTORY_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['history', 'fetching'], false)
                    .setIn(['history', 'error'], action.payload)
                    .setIn(['history', 'data'], null)
            })

        // Fetch driver documents
        case actionTypes.FETCH_DOCUMENTS_STARTED:
            return state.withMutations(map => {
                map.setIn(['documents', 'fetching'], true).setIn(['documents', 'error'], null)
            })
        case actionTypes.FETCH_DOCUMENTS_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['documents', 'fetching'], false)
                    .setIn(['documents', 'error'], null)
                    .setIn(['documents', 'data'], action.payload)
            })
        case actionTypes.FETCH_DOCUMENTS_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['documents', 'fetching'], false)
                    .setIn(['documents', 'error'], action.payload)
                    .setIn(['documents', 'data'], null)
            })

        // Fetch driver equipments
        case actionTypes.FETCH_EQUIPMENTS_STARTED:
            return state.withMutations(map => {
                map.setIn(['equipments', 'fetching'], true).setIn(['equipments', 'error'], null)
            })
        case actionTypes.FETCH_EQUIPMENTS_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['equipments', 'fetching'], false)
                    .setIn(['equipments', 'error'], null)
                    .setIn(['equipments', 'data'], action.payload)
            })
        case actionTypes.FETCH_EQUIPMENTS_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['equipments', 'fetching'], false)
                    .setIn(['equipments', 'error'], action.payload)
                    .setIn(['equipments', 'data'], null)
            })

        // Fetch driver events
        case actionTypes.FETCH_EVENTS_STARTED:
            return state.withMutations(map => {
                map.setIn(['events', 'fetching'], true).setIn(['events', 'error'], null)
            })
        case actionTypes.FETCH_EVENTS_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['events', 'fetching'], false)
                    .setIn(['events', 'error'], null)
                    .setIn(['events', 'data'], action.payload)
            })
        case actionTypes.FETCH_EVENTS_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['events', 'fetching'], false)
                    .setIn(['events', 'error'], action.payload)
                    .setIn(['events', 'data'], null)
            })

        // Fetch driver timesheets
        case actionTypes.FETCH_TIMESHEETS_STARTED:
            return state.withMutations(map => {
                map.setIn(['timesheets', 'fetching'], true).setIn(['timesheets', 'error'], null)
            })
        case actionTypes.FETCH_TIMESHEETS_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['timesheets', 'fetching'], false)
                    .setIn(['timesheets', 'error'], null)
                    .setIn(['timesheets', 'data'], action.payload)
            })
        case actionTypes.FETCH_TIMESHEETS_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['timesheets', 'fetching'], false)
                    .setIn(['timesheets', 'error'], action.payload)
                    .setIn(['timesheets', 'data'], null)
            })

        // Fetch driver allowances
        case actionTypes.FETCH_ALLOWANCES_STARTED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'fetching'], true).setIn(['allowances', 'error'], null)
            })
        case actionTypes.FETCH_ALLOWANCES_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['allowances', 'fetching'], false)
                    .setIn(['allowances', 'error'], null)
                    .setIn(['allowances', 'data'], action.payload)
            })
        case actionTypes.FETCH_ALLOWANCES_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['allowances', 'fetching'], false)
                    .setIn(['allowances', 'error'], action.payload)
                    .setIn(['allowances', 'data'], null)
            })

        // Save driver allowances
        case actionTypes.SAVE_ALLOWANCES_STARTED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'saving'], true).setIn(['allowances', 'error'], null)
            })
        case actionTypes.SAVE_ALLOWANCES_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['allowances', 'saving'], false)
                    .setIn(['allowances', 'error'], null)
                    .setIn(['allowances', 'data'], action.payload)
            })
        case actionTypes.SAVE_ALLOWANCES_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['allowances', 'saving'], false)
                    .setIn(['allowances', 'error'], action.payload)
                    .setIn(['allowances', 'data'], null)
            })

        // Delete driver allowances
        case actionTypes.DELETE_ALLOWANCES_STARTED:
            return state.withMutations(map => {
                map.setIn(['allowances', 'deleting'], true).setIn(['allowances', 'error'], null)
            })
        case actionTypes.DELETE_ALLOWANCES_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['allowances', 'deleting'], false)
                    .setIn(['allowances', 'error'], null)
                    .setIn(['allowances', 'data'], action.payload)
            })
        case actionTypes.DELETE_ALLOWANCES_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['allowances', 'deleting'], false)
                    .setIn(['allowances', 'error'], action.payload)
                    .setIn(['allowances', 'data'], null)
            })

        // Import headers

        case actionTypes.FETCH_IMPORT_HEADERS_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_HEADERS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
                map.setIn(['import', 'file_id'], action.payload.file_id)
                map.setIn(['import', 'headers'], action.payload.headers)
                map.setIn(['import', 'headersDefault'], action.payload.headers_default)
            })
        case actionTypes.FETCH_IMPORT_HEADERS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_HEADERS_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import values

        case actionTypes.FETCH_IMPORT_ITEMS_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_ITEMS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
                map.setIn(['import', 'items'], action.payload.items)
            })
        case actionTypes.FETCH_IMPORT_ITEMS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_ITEMS_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import

        case actionTypes.IMPORT_DRIVERS_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.IMPORT_DRIVERS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
            })
        case actionTypes.IMPORT_DRIVERS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.IMPORT_DRIVERS_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        default:
            return state
    }
}

export default drivers
