import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import { resolveModelState } from '../../common/table/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchReportAllowancesEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request({
                method: 'GET',
                path: `report-allowances?year=${action.payload.year}&filters=${JSON.stringify(action.payload.filters)}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportAllowancesFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportAllowancesRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    )

// Save

const saveReportAllowancesEpic = action$ =>
    action$.ofType(actionTypes.SAVE).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.SAVE_STARTED,
            }),
            request({
                method: 'POST',
                path: `report-allowances?filters=${JSON.stringify({ ...action.payload })}`,
                body: {
                    data: action.payload.values,
                },
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.saveAllowanceFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.saveAllowanceRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED, actionTypes.SAVE))
        )
    )

// Export

const exportReportAllowancesEpic = (action$, store) => {
    const tableIdentifier = 'report_driver_allowances'

    return action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const modelState = resolveModelState(tableIdentifier, store.getState(), action)

        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-allowances/export?year=${action.payload.year}&filters=${JSON.stringify(action.payload.filters)}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })
}

// Fetch details

const fetchReportAllowancesDetailsEpic = action$ =>
    action$.ofType(actionTypes.FETCH_DETAILS).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DETAILS_STARTED,
            }),
            request({
                method: 'GET',
                path: `report-allowances/details?filters=${JSON.stringify({ ...action.payload })}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportAllowancesDetailsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportAllowancesDetailsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_DETAILS_CANCELLED))
        )
    )

// Export details

const exportReportAllowancesDetailsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_DETAILS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-allowances/details/export?filters=${JSON.stringify({ ...action.payload })}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_DETAILS_FULFILLED,
            })
        )
    })

// Delete allowance

const deleteAllowanceEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE_ALLOWANCE)
        .filter(() => !store.getState().drivers.getIn(['list', 'deleting']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_ALLOWANCE_STARTED,
                }),
                request({
                    method: 'DELETE',
                    path: `report-allowances/${action.payload.allowanceId}?filters=${JSON.stringify({ ...action.payload })}`,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.deleteAllowanceFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.deleteAllowanceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_ALLOWANCE_CANCELLED))
            )
        )

// Reassign allowances

const reassignReportAllowancesEpic = action$ =>
    action$.ofType(actionTypes.REASSIGN_ALLOWANCE).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.REASSIGN_ALLOWANCE_STARTED,
            }),
            request({
                method: 'POST',
                path: `report-allowances/reassign-allowances?filters=${JSON.stringify({ ...action.payload })}`,
                body: {
                    year: action.payload.year,
                    month: action.payload.month,
                    driverId: action.payload.driverId,
                    transportIds: action.payload.transportIds,
                },
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.reassignAllowancesFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.reassignAllowancesRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.REASSIGN_ALLOWANCE_CANCELLED, actionTypes.SAVE))
        )
    )

// Reassign diet allowances

const reassignReportDietAllowancesEpic = action$ =>
    action$.ofType(actionTypes.REASSIGN_DIET_ALLOWANCE).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.REASSIGN_DIET_ALLOWANCE_STARTED,
            }),
            request({
                method: 'POST',
                path: `report-allowances/reassign-diet-allowances?filters=${JSON.stringify({ ...action.payload })}`,
                body: {
                    year: action.payload.year,
                    month: action.payload.month,
                    driverId: action.payload.driverId,
                },
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.reassignDietAllowancesFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.reassignDietAllowancesRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.REASSIGN_DIET_ALLOWANCE_CANCELLED, actionTypes.SAVE))
        )
    )

export default [
    fetchReportAllowancesEpic,
    saveReportAllowancesEpic,
    exportReportAllowancesEpic,
    fetchReportAllowancesDetailsEpic,
    exportReportAllowancesDetailsEpic,
    deleteAllowanceEpic,
    reassignReportAllowancesEpic,
    reassignReportDietAllowancesEpic,
]
