import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { SortableHandle } from 'react-sortable-hoc'

import 'react-datepicker/dist/react-datepicker.css'

import * as constants from '../constants'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_PARTS } from '../permissions'

import { TransportStateBox } from '../../../common/colored_box'
import * as formatters from '../../../common/formatters'

class TransportsParts extends Component {
    onClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick && this.props.onClick(this.props.transportPart)
    }

    onDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete && this.props.onDelete(this.props.transportPart)
    }

    onDownloadOrder = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDownloadOrder && this.props.onDownloadOrder(this.props.transportPart)
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const transportPart = this.props.transportPart

        if (!transportPart) {
            return null
        }

        const DragHandle = SortableHandle(() => <i className="far fa-bars m-r-sm sort-handle" />)
        const transportVehicleType = constants.TRANSPORT_VEHICLE_TYPES[transportPart.transport_vehicle_type_id]

        return (
            <div key={`transportPart-${transportPart.id}`} className={`panel panel-${transportPart.take_from_transport ? 'parking' : 'trailer'}`}>
                <div className="panel-heading">
                    <span className="pull-left">
                        <PermissionsCheck has={[PERMS_PARTS.UPDATE]}>
                            <DragHandle />
                        </PermissionsCheck>
                        {transportPart.name}
                    </span>
                    <span className="pull-right">
                        <PermissionsCheck has={[PERMS_PARTS.UPDATE]}>
                            <a href="#" onClick={this.onClick} className="m-l-lg">
                                <i className="far fa-pencil" /> <FormattedMessage id="buttons.edit" />
                            </a>
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_PARTS.DELETE]}>
                            <a href="#" onClick={this.onDelete} className="m-l-lg">
                                <i className="far fa-trash" /> <FormattedMessage id="buttons.delete" />
                            </a>
                        </PermissionsCheck>
                    </span>
                </div>

                <div className="panel-body">
                    <div className="row line-p">
                        <div className="col-md-4">
                            <span className="text-gray-light">
                                <FormattedMessage id="fields.general" />:
                            </span>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.supplier" />:
                                </div>
                                <div className="col-md-9">
                                    <strong>{transportPart.carrier ? transportPart.carrier.name : '-'}</strong>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.type" />:
                                </div>
                                <div className="col-md-9">{transportVehicleType ? <FormattedMessage id={transportVehicleType.name} /> : '-'}</div>
                            </div>
                            {transportVehicleType ? (
                                <div className="row">
                                    <div className="col-md-3">
                                        <FormattedMessage id={transportVehicleType.numberLabel} />:
                                    </div>
                                    <div className="col-md-9">{transportPart.transport_vehicle_number || '-'}</div>
                                </div>
                            ) : null}
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.orderNumber" />:
                                </div>
                                <div className="col-md-9">{transportPart.carrier_order_number || '-'}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.price" />:
                                </div>
                                <div className="col-md-9">
                                    {transportPart.carrier_price
                                        ? formatters.priceFormatter(
                                              transportPart.carrier_price,
                                              '0,0.00',
                                              transportPart.carrier_currency && transportPart.carrier_currency.iso_code
                                          )
                                        : '-'}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.conditions" />:
                                </div>
                                <div className="col-md-9">{transportPart.transport_conditions.map(item => item.code).join(', ')}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.state" />:
                                </div>
                                <div className="col-md-9">
                                    {transportPart.transport_state && (
                                        <TransportStateBox transportState={transportPart.transport_state}>
                                            {this.props.intl.formatMessage({
                                                id: `transportStates.${transportPart.transport_state.name}`,
                                            })}
                                        </TransportStateBox>
                                    )}
                                </div>
                            </div>
                            {transportPart.carrier_note && <p className="m-t-sm">{transportPart.carrier_note}</p>}
                            <div className="m-t-xxs">
                                <a href="#" className="no-text-decoration text-gray" onClick={this.onDownloadOrder}>
                                    <i className="far fa-file-pdf" /> <FormattedMessage id="fields.order" />
                                </a>
                            </div>
                        </div>

                        <div className="col-md-4 b-r b-l">
                            <span className="text-gray-light">
                                <FormattedMessage id="fields.loading" />:
                            </span>
                            <p>
                                <strong>{transportPart.loading_name || '-'}</strong>
                                <br />
                                {formatters.address(
                                    transportPart.loading_street,
                                    transportPart.loading_city,
                                    transportPart.loading_zipcode,
                                    transportPart.loading_country && transportPart.loading_country.iso_code
                                ) || '-'}
                            </p>
                            <span className="text-gray-light">
                                <FormattedMessage id="fields.times" />:
                            </span>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.planned" />:
                                </div>
                                <div className="col-md-9">
                                    {formatters.datetimePeriodFormatter(
                                        transportPart.loading_datetime_planned_from,
                                        transportPart.loading_datetime_planned_to
                                    ) || '-'}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.specified" />:
                                </div>
                                <div className="col-md-9">
                                    {formatters.datetimePeriodFormatter(
                                        transportPart.loading_datetime_specified_from,
                                        transportPart.loading_datetime_specified_to
                                    ) || '-'}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.arrival" />:
                                </div>
                                <div className="col-md-9">{formatters.datetimeFormatter(transportPart.loading_datetime_arrival) || '-'}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.finish" />:
                                </div>
                                <div className="col-md-9">{formatters.datetimeFormatter(transportPart.loading_datetime_finished) || '-'}</div>
                            </div>
                            {transportPart.loading_note && <p className="m-t-sm">{transportPart.loading_note}</p>}
                        </div>

                        <div className="col-md-4 b-r b-l">
                            <span className="text-gray-light">
                                <FormattedMessage id="fields.unloading" />:
                            </span>
                            <p>
                                <strong>{transportPart.unloading_name || '-'}</strong>
                                <br />
                                {formatters.address(
                                    transportPart.unloading_street,
                                    transportPart.unloading_city,
                                    transportPart.unloading_zipcode,
                                    transportPart.unloading_country && transportPart.unloading_country.iso_code
                                ) || '-'}
                            </p>
                            <span className="text-gray-light">
                                <FormattedMessage id="fields.times" />:
                            </span>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.planned" />:
                                </div>
                                <div className="col-md-9">
                                    {formatters.datetimePeriodFormatter(
                                        transportPart.unloading_datetime_planned_from,
                                        transportPart.unloading_datetime_planned_to
                                    ) || '-'}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.specified" />:
                                </div>
                                <div className="col-md-9">
                                    {formatters.datetimePeriodFormatter(
                                        transportPart.unloading_datetime_specified_from,
                                        transportPart.unloading_datetime_specified_to
                                    ) || '-'}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.arrival" />:
                                </div>
                                <div className="col-md-9">{formatters.datetimeFormatter(transportPart.unloading_datetime_arrival) || '-'}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <FormattedMessage id="fields.finish" />:
                                </div>
                                <div className="col-md-9">{formatters.datetimeFormatter(transportPart.unloading_datetime_finished) || '-'}</div>
                            </div>
                            {transportPart.unloading_note && <p className="m-t-sm">{transportPart.unloading_note}</p>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(TransportsParts)
