import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportVehicleFuelings = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportVehicleFuelingsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportVehicleFuelingsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportVehicleFuelingsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportVehicleFuelings = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportVehicleFuelings = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportVehicleFuelingDetails = (vehicleId, dateFrom, dateTo) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        vehicleId,
        dateFrom,
        dateTo,
    },
})

export const fetchReportVehicleFuelingDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportVehicleFuelingDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportVehicleFuelingDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear details

export const clearReportVehicleFuelingDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export details

export const exportReportVehicleFuelingDetails = (vehicleId, dateFrom, dateTo, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        vehicleId,
        dateFrom,
        dateTo,
        filters,
    },
})
