import { createSelector } from 'reselect'

export const getCosts = state => state.costs.getIn(['all', 'objects'])

// eslint-disable-next-line prettier/prettier
export const createGetCostByIDSelector = id => createSelector([getCosts], costs => costs.get(String(id)))

const getCurrentCostStatus = state => state.costs.get('current')

// eslint-disable-next-line prettier/prettier
export const createGetCurrentCostStatusSelector = () => createSelector([getCurrentCostStatus], status => status)

export const getCostsFetching = state => state.costs.getIn(['all', 'fetching'])

export const getCostFetching = state => state.costs.getIn(['current', 'fetching'])

export const getCostDeleting = state => state.costs.getIn(['deletion', 'inProgress'])

export const getCostDeleteError = state => state.costs.getIn(['deletion', 'error'])

// Import

export const getImportInProgress = state => state.costs.getIn(['import', 'inProgress'])
export const getImportError = state => state.costs.getIn(['import', 'error'])
export const getImportFileId = state => state.costs.getIn(['import', 'file_id'])
export const getImportHeaders = state => state.costs.getIn(['import', 'headers'])
export const getImportDefaultHeaders = state => state.costs.getIn(['import', 'headersDefault'])
export const getImportItems = state => state.costs.getIn(['import', 'items'])
export const getDefaultDate = state => state.costs.getIn(['import', 'defaultDate'])
export const getDefaultCostTypeId = state => state.costs.getIn(['import', 'defaultCostTypeId'])
