import { createSelector } from 'reselect'

export const getCustomerContacts = state => state.customerContacts.getIn(['all', 'objects'])

export const createGetCustomerContactByIDSelector = id => createSelector([getCustomerContacts], customerContacts => customerContacts.get(String(id)))

const getCurrentCustomerContactStatus = state => state.customerContacts.get('current')
export const createGetCurrentCustomerContactStatusSelector = () => createSelector([getCurrentCustomerContactStatus], status => status)

export const getCustomerContactDeleting = state => state.customerContacts.getIn(['deletion', 'inProgress'])
export const getCustomerContactDeleteError = state => state.customerContacts.getIn(['deletion', 'error'])

const getDuplicity = state => state.customerContacts.getIn(['all', 'duplicity'])
export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)
