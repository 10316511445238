import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { transporeonTransportSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap, mapNestedRecordsToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import * as countriesActionCreators from '../countries/actionCreators'
import * as currenciesActionCreators from '../currencies/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { TransporeonTransport, TransporeonShipment, TransporeonStation } from './model'
import { Currency } from '../currencies/model'
import { Country } from '../countries/model'

// Fetch

const fetchTransporeonOffersEpic = (action$, store) => {
    const tableIdentifier = 'transporeon_offers_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `transporeon-offers/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(transporeonTransportSchema)
                        )

                        let transporeonTransports = normalizedEntitiesToRecordMap(
                            normalizedData.entities.transporeon_transports,
                            TransporeonTransport,
                            normalizedData.result
                        )
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                        const currencies = normalizedEntitiesToRecordMap(normalizedData.entities.currencies, Currency)
                        let transporeonShipments = normalizedEntitiesToRecordMap(normalizedData.entities.transporeon_shipments, TransporeonShipment)
                        const transporeonStations = normalizedEntitiesToRecordMap(normalizedData.entities.transporeon_stations, TransporeonStation)

                        transporeonShipments = mapNestedRecordsToRecordMap(
                            transporeonShipments,
                            {
                                transporeon_stations: { data: transporeonStations, recordClass: TransporeonStation },
                            },
                            TransporeonShipment
                        )

                        transporeonTransports = mapNestedRecordsToRecordMap(
                            transporeonTransports,
                            {
                                transporeon_shipments: { data: transporeonShipments, recordClass: TransporeonShipment },
                            },
                            TransporeonTransport
                        )

                        const observables = [
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(currenciesActionCreators.fetchCurrenciesFulfilled(currencies)),
                            Observable.of(actionCreators.fetchOffersFulfilled(transporeonTransports)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchOffersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Accept

const acceptOfferEpic = (action$, store) =>
    action$
        .ofType(actionTypes.ACCEPT)
        .filter(() => !store.getState().companies.getIn(['acceptance', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.ACCEPT_STARTED,
                }),
                request({
                    path: `transporeon-offers/accept`,
                    method: 'POST',
                    body: {
                        ids: action.payload.ids,
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.acceptFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.acceptRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.ACCEPT_REJECTED))
            )
        )

// Accept

const declineOfferEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DECLINE)
        .filter(() => !store.getState().companies.getIn(['declination', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DECLINE_STARTED,
                }),
                request({
                    path: `transporeon-offers/decline`,
                    method: 'POST',
                    body: {
                        ids: action.payload.ids,
                        reason_id: action.payload.reasonID,
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.declineFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.declineRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DECLINE_REJECTED))
            )
        )

// Export

const exportOffersEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `transporeon-offers/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

export default [fetchTransporeonOffersEpic, acceptOfferEpic, declineOfferEpic, exportOffersEpic]
