import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import { DispatchersBox } from '../../../common/colored_box'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import moment from "moment";

class VehiclesListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    handleOpenConfigurationModal = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClickConfiguration(this.props.vehicleFleetboardConfiguration)
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const classNames = ['row-selectable']
        if (this.props.data.deleted_at) {
            classNames.push('is-deleted')
        }

        return (
            <tr onClick={this.handleClick} className={classNames.join(' ')}>
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        {!this.props.data.deleted_at && (
                            <PermissionsCheck has={[PERMS.DELETE]}>
                                <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                            </PermissionsCheck>
                        )}
                    </td>
                </PermissionsCheck>
                <td className="w-140">
                    {this.props.data.registration && (
                        <DispatchersBox dispatcher={this.props.dispatcher}>{this.props.data.registration}</DispatchersBox>
                    )}
                </td>
                <td className="w-160">{this.props.data.vin}</td>
                <td className="w-160">{this.props.vehicleType && this.props.vehicleType.name}</td>
                <td className="w-60">{this.props.data.year_of_manufacture}</td>
                <td className="w-90">{this.props.data.sold_at && moment(this.props.data.sold_at, 'YYYY-MM-DD').format('DD.MM.YYYY') }</td>
                <td className="w-140 text-right">
                    {this.props.vehicleType &&
                        formatters.dimensionsFormatter(this.props.vehicleType.width, this.props.vehicleType.height, this.props.vehicleType.length)}
                </td>
                <td className="w-80 text-right">{this.props.vehicleType && formatters.weightFormatter(this.props.vehicleType.weight)}</td>
                <td className="w-60 text-right">{this.props.vehicleType && formatters.volumeFormatter(this.props.vehicleType.tank_volume)}</td>
                <td className="w-120">
                    {this.props.vehicleFleetboardConfiguration && this.props.vehicleFleetboardConfiguration.name}
                    {this.props.vehicleFleetboardConfiguration && this.props.vehicleFleetboardConfiguration.description ? (
                        <a
                            href="#"
                            className="m-l-xs pull-right"
                            onClick={e => {
                                this.handleOpenConfigurationModal(e)
                            }}
                        >
                            <i className="far fa-info-circle text-gray" />
                        </a>
                    ) : null}
                </td>
                <td className="w-120">{this.props.dispatcher && this.props.dispatcher.getShortFullName()}</td>
                <td className="w-140">{this.props.data.phone}</td>
                <td className="w-220">
                    {this.props.companyCostCenter && `${this.props.companyCostCenter.code} - ${this.props.companyCostCenter.name}`}
                </td>
                <td className="w-70 text-center">{formatters.boolFormatter(this.props.data.is_spedition)}</td>
                <td className="w-max wm-200">{this.props.carrier && this.props.carrier.name}</td>
                <td className="w-70 text-center">{formatters.boolFormatter(this.props.data.is_active)}</td>
            </tr>
        )
    }
}

export default VehiclesListRow
