import { createSelector } from 'reselect'

export const getTaxRates = state => state.taxRates.getIn(['all', 'objects'])

export const createGetTaxRateByIDSelector = id => createSelector([getTaxRates], taxRates => taxRates.get(String(id)))

const getCurrentTaxRateStatus = state => state.taxRates.get('current')
export const createGetCurrentTaxRateStatusSelector = () => createSelector([getCurrentTaxRateStatus], status => status)

export const getTaxRateDeleting = state => state.taxRates.getIn(['deletion', 'inProgress'])
export const getTaxRateDeleteError = state => state.taxRates.getIn(['deletion', 'error'])
