import { createSelector } from 'reselect'

export const getCountries = state => state.countries.getIn(['all', 'objects'])

export const createGetCountryByIDSelector = id => createSelector([getCountries], countries => countries.get(String(id)))

const getCurrentCountryStatus = state => state.countries.get('current')

export const createGetCurrentCountryStatusSelector = () => createSelector([getCurrentCountryStatus], status => status)

export const getCountryDeleting = state => state.countries.getIn(['deletion', 'inProgress'])

export const getCountryDeleteError = state => state.countries.getIn(['deletion', 'error'])
