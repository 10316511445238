// Fetch list

export const FETCH = 'vehicles.FETCH'
export const FETCH_STARTED = 'vehicles.FETCH_STARTED'
export const FETCH_FULFILLED = 'vehicles.FETCH_FULFILLED'
export const FETCH_REJECTED = 'vehicles.FETCH_REJECTED'
export const FETCH_CANCELLED = 'vehicles.FETCH_CANCELLED'

export const SORTING_CHANGED = 'vehicles.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'vehicles.FETCH_ONE'
export const FETCH_ONE_STARTED = 'vehicles.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'vehicles.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'vehicles.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'vehicles.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'vehicles.SAVE'
export const SAVE_STARTED = 'vehicles.SAVE_STARTED'
export const SAVE_FULFILLED = 'vehicles.SAVE_FULFILLED'
export const SAVE_REJECTED = 'vehicles.SAVE_REJECTED'
export const SAVE_CANCELLED = 'vehicles.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'vehicles.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'vehicles.DELETE'
export const DELETE_STARTED = 'vehicles.DELETE_STARTED'
export const DELETE_FULFILLED = 'vehicles.DELETE_FULFILLED'
export const DELETE_REJECTED = 'vehicles.DELETE_REJECTED'
export const DELETE_CANCELLED = 'vehicles.DELETE_CANCELLED'

// Restore

export const RESTORE = 'vehicles.RESTORE'
export const RESTORE_STARTED = 'vehicles.RESTORE_STARTED'
export const RESTORE_FULFILLED = 'vehicles.RESTORE_FULFILLED'
export const RESTORE_REJECTED = 'vehicles.RESTORE_REJECTED'
export const RESTORE_CANCELLED = 'vehicles.RESTORE_CANCELLED'


// Transport start

export const FETCH_TRANSPORT_START = 'vehicles.FETCH_TRANSPORT_START'
export const FETCH_TRANSPORT_START_STARTED = 'vehicles.FETCH_TRANSPORT_START_STARTED'
export const FETCH_TRANSPORT_START_FULFILLED = 'vehicles.FETCH_TRANSPORT_START_FULFILLED'
export const FETCH_TRANSPORT_START_REJECTED = 'vehicles.FETCH_TRANSPORT_START_REJECTED'
export const FETCH_TRANSPORT_START_CANCELLED = 'vehicles.FETCH_TRANSPORT_START_CANCELLED'
export const CLEAR_TRANSPORT_START = 'vehicles.CLEAR_TRANSPORT_START'

// Export

export const EXPORT = 'vehicles.EXPORT'
export const EXPORT_FULFILLED = 'vehicles.EXPORT_FULFILLED'

// Fetch driver history

export const FETCH_HISTORY = 'vehicles.FETCH_HISTORY'
export const FETCH_HISTORY_STARTED = 'vehicles.FETCH_HISTORY_STARTED'
export const FETCH_HISTORY_FULFILLED = 'vehicles.FETCH_HISTORY_FULFILLED'
export const FETCH_HISTORY_REJECTED = 'vehicles.FETCH_HISTORY_REJECTED'
export const FETCH_HISTORY_CANCELLED = 'vehicles.FETCH_HISTORY_CANCELLED'

// Export vehicle history

export const EXPORT_HISTORY = 'vehicles.EXPORT_HISTORY'
export const EXPORT_HISTORY_STARTED = 'vehicles.EXPORT_HISTORY_STARTED'
export const EXPORT_HISTORY_FULFILLED = 'vehicles.EXPORT_HISTORY_FULFILLED'
export const EXPORT_HISTORY_REJECTED = 'vehicles.EXPORT_HISTORY_REJECTED'
export const EXPORT_HISTORY_CANCELLED = 'vehicles.EXPORT_HISTORY_CANCELLED'

// Fetch vehicle documents

export const FETCH_DOCUMENTS = 'vehicles.FETCH_DOCUMENTS'
export const FETCH_DOCUMENTS_STARTED = 'vehicles.FETCH_DOCUMENTS_STARTED'
export const FETCH_DOCUMENTS_FULFILLED = 'vehicles.FETCH_DOCUMENTS_FULFILLED'
export const FETCH_DOCUMENTS_REJECTED = 'vehicles.FETCH_DOCUMENTS_REJECTED'
export const FETCH_DOCUMENTS_CANCELLED = 'vehicles.FETCH_DOCUMENTS_CANCELLED'

// Export vehicle documents

export const EXPORT_DOCUMENTS = 'vehicles.EXPORT_DOCUMENTS'
export const EXPORT_DOCUMENTS_STARTED = 'vehicles.EXPORT_DOCUMENTS_STARTED'
export const EXPORT_DOCUMENTS_FULFILLED = 'vehicles.EXPORT_DOCUMENTS_FULFILLED'
export const EXPORT_DOCUMENTS_REJECTED = 'vehicles.EXPORT_DOCUMENTS_REJECTED'
export const EXPORT_DOCUMENTS_CANCELLED = 'vehicles.EXPORT_DOCUMENTS_CANCELLED'

// Fetch vehicle equipments

export const FETCH_EQUIPMENTS = 'vehicles.FETCH_EQUIPMENTS'
export const FETCH_EQUIPMENTS_STARTED = 'vehicles.FETCH_EQUIPMENTS_STARTED'
export const FETCH_EQUIPMENTS_FULFILLED = 'vehicles.FETCH_EQUIPMENTS_FULFILLED'
export const FETCH_EQUIPMENTS_REJECTED = 'vehicles.FETCH_EQUIPMENTS_REJECTED'
export const FETCH_EQUIPMENTS_CANCELLED = 'vehicles.FETCH_EQUIPMENTS_CANCELLED'

// Export vehicle equipments

export const EXPORT_EQUIPMENTS = 'vehicles.EXPORT_EQUIPMENTS'
export const EXPORT_EQUIPMENTS_STARTED = 'vehicles.EXPORT_EQUIPMENTS_STARTED'
export const EXPORT_EQUIPMENTS_FULFILLED = 'vehicles.EXPORT_EQUIPMENTS_FULFILLED'
export const EXPORT_EQUIPMENTS_REJECTED = 'vehicles.EXPORT_EQUIPMENTS_REJECTED'
export const EXPORT_EQUIPMENTS_CANCELLED = 'vehicles.EXPORT_EQUIPMENTS_CANCELLED'

// Fetch vehicle events

export const FETCH_EVENTS = 'vehicles.FETCH_EVENTS'
export const FETCH_EVENTS_STARTED = 'vehicles.FETCH_EVENTS_STARTED'
export const FETCH_EVENTS_FULFILLED = 'vehicles.FETCH_EVENTS_FULFILLED'
export const FETCH_EVENTS_REJECTED = 'vehicles.FETCH_EVENTS_REJECTED'
export const FETCH_EVENTS_CANCELLED = 'vehicles.FETCH_EVENTS_CANCELLED'

// Export vehicle events

export const EXPORT_EVENTS = 'vehicles.EXPORT_EVENTS'
export const EXPORT_EVENTS_STARTED = 'vehicles.EXPORT_EVENTS_STARTED'
export const EXPORT_EVENTS_FULFILLED = 'vehicles.EXPORT_EVENTS_FULFILLED'
export const EXPORT_EVENTS_REJECTED = 'vehicles.EXPORT_EVENTS_REJECTED'
export const EXPORT_EVENTS_CANCELLED = 'vehicles.EXPORT_EVENTS_CANCELLED'

// Fetch vehicle fuelings

export const FETCH_FUELINGS = 'vehicles.FETCH_FUELINGS'
export const FETCH_FUELINGS_STARTED = 'vehicles.FETCH_FUELINGS_STARTED'
export const FETCH_FUELINGS_FULFILLED = 'vehicles.FETCH_FUELINGS_FULFILLED'
export const FETCH_FUELINGS_REJECTED = 'vehicles.FETCH_FUELINGS_REJECTED'
export const FETCH_FUELINGS_CANCELLED = 'vehicles.FETCH_FUELINGS_CANCELLED'

// Export vehicle fuelings

export const EXPORT_FUELINGS = 'vehicles.EXPORT_FUELINGS'
export const EXPORT_FUELINGS_STARTED = 'vehicles.EXPORT_FUELINGS_STARTED'
export const EXPORT_FUELINGS_FULFILLED = 'vehicles.EXPORT_FUELINGS_FULFILLED'
export const EXPORT_FUELINGS_REJECTED = 'vehicles.EXPORT_FUELINGS_REJECTED'
export const EXPORT_FUELINGS_CANCELLED = 'vehicles.EXPORT_FUELINGS_CANCELLED'
