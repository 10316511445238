import { createSelector } from 'reselect'

export const getCompanyNumberings = state => state.companyNumberings.getIn(['all', 'objects'])

export const createGetCompanyNumberingByIDSelector = id =>
    createSelector([getCompanyNumberings], companyNumberings => companyNumberings.get(String(id)))

const getCurrentCompanyNumberingStatus = state => state.companyNumberings.get('current')
export const createGetCurrentCompanyNumberingStatusSelector = () => createSelector([getCurrentCompanyNumberingStatus], status => status)

export const getCompanyNumberingDeleting = state => state.companyNumberings.getIn(['deletion', 'inProgress'])
export const getCompanyNumberingDeleteError = state => state.companyNumberings.getIn(['deletion', 'error'])
