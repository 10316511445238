// Fetch list

export const FETCH = 'driverTimesheets.FETCH'
export const FETCH_FOR_MAP = 'driverTimesheets.FETCH_FOR_MAP'
export const FETCH_STARTED = 'driverTimesheets.FETCH_STARTED'
export const FETCH_FULFILLED = 'driverTimesheets.FETCH_FULFILLED'
export const FETCH_REJECTED = 'driverTimesheets.FETCH_REJECTED'
export const FETCH_CANCELLED = 'driverTimesheets.FETCH_CANCELLED'

export const SORTING_CHANGED = 'driverTimesheets.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'driverTimesheets.FETCH_ONE'
export const FETCH_ONE_STARTED = 'driverTimesheets.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'driverTimesheets.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'driverTimesheets.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'driverTimesheets.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'driverTimesheets.SAVE'
export const SAVE_STARTED = 'driverTimesheets.SAVE_STARTED'
export const SAVE_FULFILLED = 'driverTimesheets.SAVE_FULFILLED'
export const SAVE_REJECTED = 'driverTimesheets.SAVE_REJECTED'
export const SAVE_CANCELLED = 'driverTimesheets.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'driverTimesheets.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'driverTimesheets.DELETE'
export const DELETE_STARTED = 'driverTimesheets.DELETE_STARTED'
export const DELETE_FULFILLED = 'driverTimesheets.DELETE_FULFILLED'
export const DELETE_REJECTED = 'driverTimesheets.DELETE_REJECTED'
export const DELETE_CANCELLED = 'driverTimesheets.DELETE_CANCELLED'

// Clear

export const CLEAR = 'driverTimesheets.CLEAR'

// Export

export const EXPORT = 'driverTimesheets.EXPORT'
export const EXPORT_FULFILLED = 'driverTimesheets.EXPORT_FULFILLED'
