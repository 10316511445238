import * as actionTypes from './actionTypes'

// Fetch

export const fetchEquipments = () => ({
    type: actionTypes.FETCH,
})

export const fetchEquipmentsFulfilled = equipments => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: equipments,
})

export const fetchEquipmentsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchEquipmentsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchEquipment = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchEquipmentFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchEquipmentRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchEquipmentCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveEquipment = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveEquipmentFulfilled = equipment => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: equipment,
})

export const saveEquipmentRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteEquipment = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteEquipmentFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteEquipmentRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportEquipments = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
