import * as actionTypes from './actionTypes'

// Fetch

export const fetchGeneralSettings = () => ({
    type: actionTypes.FETCH,
})

export const fetchGeneralSettingsFulfilled = generalSettings => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: generalSettings,
})

export const fetchGeneralSettingsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchGeneralSettingsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Save

export const saveGeneralSettings = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveGeneralSettingsFulfilled = generalSettings => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: generalSettings,
})

export const saveGeneralSettingsRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})
