import React, { Component } from 'react'

import { ColoredBox } from '../../../common/colored_box'
import * as formatters from '../../../common/formatters'

// eslint-disable-next-line react/prefer-stateless-function
class TrailersHistoryListRow extends Component {
    render() {
        const item = this.props.item
        const nextItem = this.props.nextItem

        return (
            <tr>
                <td className="w-120">{formatters.datetimeFormatter(item.datetime_from)}</td>
                <td className="w-120">{formatters.datetimeFormatter(item.datetime_to)}</td>
                <td className={`w-140 ${nextItem && JSON.stringify(nextItem.vehicle) === JSON.stringify(item.vehicle) ? 'opacity-25' : ''}`}>
                    {item.vehicle && (
                        <ColoredBox bgRGB={item.dispatcher && item.dispatcher.rgb_bg} textRGB={item.dispatcher && item.dispatcher.rgb_text}>
                            {item.vehicle.registration}
                        </ColoredBox>
                    )}
                </td>
                <td className={`w-max wm-200 ${nextItem && JSON.stringify(nextItem.driver) === JSON.stringify(item.driver) ? 'opacity-50' : ''}`}>
                    {item.driver && item.driver.name}
                </td>
            </tr>
        )
    }
}

export default TrailersHistoryListRow
