// Fetch list

export const FETCH = 'cargoTypes.FETCH'
export const FETCH_STARTED = 'cargoTypes.FETCH_STARTED'
export const FETCH_FULFILLED = 'cargoTypes.FETCH_FULFILLED'
export const FETCH_REJECTED = 'cargoTypes.FETCH_REJECTED'
export const FETCH_CANCELLED = 'cargoTypes.FETCH_CANCELLED'

export const SORTING_CHANGED = 'cargoTypes.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'cargoTypes.FETCH_ONE'
export const FETCH_ONE_STARTED = 'cargoTypes.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'cargoTypes.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'cargoTypes.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'cargoTypes.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'cargoTypes.SAVE'
export const SAVE_STARTED = 'cargoTypes.SAVE_STARTED'
export const SAVE_FULFILLED = 'cargoTypes.SAVE_FULFILLED'
export const SAVE_REJECTED = 'cargoTypes.SAVE_REJECTED'
export const SAVE_CANCELLED = 'cargoTypes.SAVE_CANCELLED'

// Delete

export const DELETE = 'cargoTypes.DELETE'
export const DELETE_STARTED = 'cargoTypes.DELETE_STARTED'
export const DELETE_FULFILLED = 'cargoTypes.DELETE_FULFILLED'
export const DELETE_REJECTED = 'cargoTypes.DELETE_REJECTED'
export const DELETE_CANCELLED = 'cargoTypes.DELETE_CANCELLED'

// Export

export const EXPORT = 'cargoTypes.EXPORT'
export const EXPORT_FULFILLED = 'cargoTypes.EXPORT_FULFILLED'
