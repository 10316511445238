import { createSelector } from 'reselect'

export const getDriverSurcharges = state => state.driverSurcharges.getIn(['all', 'objects'])

export const createGetDriverSurchargeByIDSelector = id =>
    createSelector(
        [getDriverSurcharges],
        driverSurcharges => driverSurcharges.get(String(id))
    )

const getCurrentDriverSurchargeStatus = state => state.driverSurcharges.get('current')

export const createGetCurrentDriverSurchargeStatusSelector = () =>
    createSelector(
        [getCurrentDriverSurchargeStatus],
        status => status
    )

export const getDriverSurchargesFetching = state => state.driverSurcharges.getIn(['all', 'fetching'])

export const getDriverSurchargeDeleting = state => state.driverSurcharges.getIn(['deletion', 'inProgress'])

export const getDriverSurchargeDeleteError = state => state.driverSurcharges.getIn(['deletion', 'error'])

const getDuplicity = state => state.driverSurcharges.getIn(['all', 'duplicity'])

export const createGetDuplicitySelector = () =>
    createSelector(
        [getDuplicity],
        duplicity => duplicity
    )
