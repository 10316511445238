import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import ReactHtmlParser from 'react-html-parser'
import Notifications from 'react-notification-system-redux'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as actionCreators from '../actionCreators'
import { getRequesedInfo, getDate, getFetching, getError } from '../selectors'

class Legend extends Component {
    componentWillReceiveProps(nextProps) {
        if (this.props.fetching && !nextProps.fetching) {
            if (nextProps.error !== null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.legendNotFound' }),
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        const requesedInfo = this.props.requesedInfo
        if (nextProps.show && (requesedInfo.key !== nextProps.component || requesedInfo.language !== nextProps.locale)) {
            this.props.actions.fetchLegend(nextProps.component, nextProps.locale)
        }
    }

    render() {
        return (
            <Modal show={Boolean(this.props.show)} onHide={this.props.onHide}>
                <React.Fragment>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="fields.legend" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoadingOverlay className="mh-100" active={this.props.fetching}>
                            {!this.props.fetching && this.props.error === null && ReactHtmlParser(this.props.data)}
                        </LoadingOverlay>
                    </Modal.Body>
                </React.Fragment>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        requesedInfo: getRequesedInfo(state),
        data: getDate(state),
        fetching: getFetching(state),
        error: getError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Legend)
)
