import * as actionTypes from './actionTypes'

// Fetch list

export const fetchVehicleFuelings = () => ({
    type: actionTypes.FETCH,
})

export const fetchVehicleFuelingsFulfilled = vehicleFuelings => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: vehicleFuelings,
})

export const fetchVehicleFuelingsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchVehicleFuelingsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

export const fetchImportFilesFulfilled = importFiles => ({
    type: actionTypes.FETCH_IMPORT_FILES_FULFILLED,
    payload: importFiles,
})

// Fetch one

export const fetchVehicleFueling = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchVehicleFuelingFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchVehicleFuelingRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchVehicleFuelingCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveVehicleFueling = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveVehicleFuelingFulfilled = vehicleFueling => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: vehicleFueling,
})

export const saveVehicleFuelingRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteVehicleFueling = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteVehicleFuelingFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteVehicleFuelingRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Fetch import headers

export const fetchVehicleFuelingsImportHeaders = file => ({
    type: actionTypes.FETCH_IMPORT_HEADERS,
    payload: file,
})

export const fetchVehicleFuelingsImportHeadersFulfilled = headers => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_FULFILLED,
    payload: headers,
})

export const fetchVehicleFuelingsImportHeadersRejected = message => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_REJECTED,
    payload: message,
})

export const fetchVehicleFuelingsImportHeadersCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_CANCELLED,
})

// Fetch import values

export const fetchVehicleFuelingsImportValues = (file_id, pairs) => ({
    type: actionTypes.FETCH_IMPORT_VALUES,
    payload: {
        file_id,
        pairs,
    },
})

export const fetchVehicleFuelingsImportValuesFulfilled = values => ({
    type: actionTypes.FETCH_IMPORT_VALUES_FULFILLED,
    payload: values,
})

export const fetchVehicleFuelingsImportValuesRejected = message => ({
    type: actionTypes.FETCH_IMPORT_VALUES_REJECTED,
    payload: message,
})

export const fetchVehicleFuelingsImportValuesCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_VALUES_CANCELLED,
})

// Fetch import preview

export const fetchVehicleFuelingsImportPreview = (file_id, pairs, items, formats) => ({
    type: actionTypes.FETCH_IMPORT_PREVIEW,
    payload: {
        file_id,
        items,
        pairs,
        formats,
    },
})

export const fetchVehicleFuelingsImportPreviewFulfilled = (data, description) => ({
    type: actionTypes.FETCH_IMPORT_PREVIEW_FULFILLED,
    payload: {
        data,
        description,
    },
})

export const fetchVehicleFuelingsImportPreviewRejected = message => ({
    type: actionTypes.FETCH_IMPORT_PREVIEW_REJECTED,
    payload: message,
})

export const fetchVehicleFuelingsImportPreviewCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_PREVIEW_CANCELLED,
})

// Import

export const importVehicleFuelings = (file_id, pairs, vehicle_fueling_company_id, items, formats, description) => ({
    type: actionTypes.IMPORT,
    payload: {
        file_id,
        pairs,
        vehicle_fueling_company_id,
        items,
        formats,
        description,
    },
})

export const importVehicleFuelingsFulfilled = () => ({
    type: actionTypes.IMPORT_FULFILLED,
})

export const importVehicleFuelingsRejected = message => ({
    type: actionTypes.IMPORT_REJECTED,
    payload: message,
})

export const importVehicleFuelingsCancelled = () => ({
    type: actionTypes.IMPORT_CANCELLED,
})

// Fetch products headers

export const fetchProductsImportHeaders = file => ({
    type: actionTypes.FETCH_IMPORT_PRODUCTS_HEADERS,
    payload: file,
})

export const fetchProductsImportHeadersFulfilled = headers => ({
    type: actionTypes.FETCH_IMPORT_PRODUCTS_HEADERS_FULFILLED,
    payload: headers,
})

export const fetchProductsImportHeadersRejected = message => ({
    type: actionTypes.FETCH_IMPORT_PRODUCTS_HEADERS_REJECTED,
    payload: message,
})

export const fetchProductsImportHeadersCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_PRODUCTS_HEADERS_CANCELLED,
})

// Fetch products preview

export const fetchProductsImportPreview = (file_id, pairs, company_name) => ({
    type: actionTypes.FETCH_IMPORT_PRODUCTS_PREVIEW,
    payload: {
        file_id,
        pairs,
        company_name,
    },
})

export const fetchProductsImportPreviewFulfilled = data => ({
    type: actionTypes.FETCH_IMPORT_PRODUCTS_PREVIEW_FULFILLED,
    payload: data,
})

export const fetchProductsImportPreviewRejected = message => ({
    type: actionTypes.FETCH_IMPORT_PRODUCTS_PREVIEW_REJECTED,
    payload: message,
})

export const fetchProductsImportPreviewCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_PRODUCTS_PREVIEW_CANCELLED,
})

// Import products

export const importProducts = (file_id, pairs, company_name) => ({
    type: actionTypes.IMPORT_PRODUCTS,
    payload: {
        file_id,
        pairs,
        company_name,
    },
})

export const importProductsFulfilled = company => ({
    type: actionTypes.IMPORT_PRODUCTS_FULFILLED,
    payload: company,
})

export const importProductsRejected = message => ({
    type: actionTypes.IMPORT_PRODUCTS_REJECTED,
    payload: message,
})

export const importProductsCancelled = () => ({
    type: actionTypes.IMPORT_PRODUCTS_CANCELLED,
})

// Fetch product list

export const fetchProducts = vehicle_fueling_company_id => ({
    type: actionTypes.FETCH_PRODUCTS,
    payload: {
        vehicle_fueling_company_id,
    },
})

export const fetchProductFulfilled = products => ({
    type: actionTypes.FETCH_PRODUCTS_FULFILLED,
    payload: products,
})

export const fetchProductRejected = message => ({
    type: actionTypes.FETCH_PRODUCTS_REJECTED,
    payload: message,
})

export const fetchProductCancelled = () => ({
    type: actionTypes.FETCH_PRODUCTS_CANCELLED,
})

// Fetch imports list

export const fetchImports = () => ({
    type: actionTypes.FETCH_IMPORTS,
})

export const fetchImportsFulfilled = imports => ({
    type: actionTypes.FETCH_IMPORTS_FULFILLED,
    payload: imports,
})

export const fetchImportsRejected = message => ({
    type: actionTypes.FETCH_IMPORTS_REJECTED,
    payload: message,
})

export const fetchImportsCancelled = () => ({
    type: actionTypes.FETCH_IMPORTS_CANCELLED,
})

// Delete import

export const deleteImport = id => ({
    type: actionTypes.DELETE_IMPORT,
    payload: id,
})

export const deleteImportFulfilled = id => ({
    type: actionTypes.DELETE_IMPORT_FULFILLED,
    payload: id,
})

export const deleteImportRejected = message => ({
    type: actionTypes.DELETE_IMPORT_REJECTED,
    payload: message,
})

// Fetch country

export const fetchCountry = (gps_lat, gps_lng) => ({
    type: actionTypes.FETCH_COUNTRY,
    payload: {
        gps_lat,
        gps_lng,
    },
})

export const fetchCountryFulfilled = country => ({
    type: actionTypes.FETCH_COUNTRY_FULFILLED,
    payload: country,
})

export const fetchCountryRejected = message => ({
    type: actionTypes.FETCH_COUNTRY_REJECTED,
    payload: message,
})

export const fetchCountryCancelled = () => ({
    type: actionTypes.FETCH_COUNTRY_CANCELLED,
})

// Fetch driver

export const fetchDriver = (vehicle_id, datetime) => ({
    type: actionTypes.FETCH_DRIVER,
    payload: {
        vehicle_id,
        datetime,
    },
})

export const fetchDriverFulfilled = position => ({
    type: actionTypes.FETCH_DRIVER_FULFILLED,
    payload: position,
})

export const fetchDriverRejected = message => ({
    type: actionTypes.FETCH_DRIVER_REJECTED,
    payload: message,
})

export const fetchDriverCancelled = () => ({
    type: actionTypes.FETCH_DRIVER_CANCELLED,
})

// Clear

export const clearVehicleFuelings = () => ({
    type: actionTypes.CLEAR,
})

export const clearProducts = () => ({
    type: actionTypes.CLEAR_PRODUCTS,
})

export const clearImport = () => ({
    type: actionTypes.CLEAR_IMPORT,
})

export const clearImportProducts = () => ({
    type: actionTypes.CLEAR_IMPORT_PRODUCTS,
})
