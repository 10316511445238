import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCostCenterResults = (year, filters) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        filters
    },
})

export const fetchReportCostCenterResultsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterResultsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCostCenterResultsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCostCenterResults = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportCostCenterResults = (year, filters) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
    },
})

// Fetch details

export const fetchReportCostCenterResultsDetails = (year, costCenterId, month, filters) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        year,
        costCenterId,
        month,
        filters,
    },
})

export const fetchReportCostCenterResultsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterResultsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportCostCenterResultsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportCostCenterResultsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportCostCenterResultsDetails = (year, costCenterId, month, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        year,
        costCenterId,
        month,
        filters,
    },
})
