import React from 'react'
import { Route, Switch } from 'react-router'
import VehicleDocumentsList from './VehicleDocumentsList'
import VehicleDocumentsEdit from './VehicleDocumentsEdit'

const VehicleDocuments = props => (
    <Switch>
        <Route exact path={props.match.url} component={VehicleDocumentsList} />
        <Route exact path={`${props.match.url}/new`} component={VehicleDocumentsEdit} />
        <Route exact path={`${props.match.url}/:vehicleDocumentID`} component={VehicleDocumentsEdit} />
    </Switch>
)

export default VehicleDocuments
