import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import { url, hasAnyPermission } from '../../common/helpers'

import { PERMS as PERMS_TRANSPORTS } from '../../pages/transports/permissions'
import { PERMS as PERMS_TRANSPORT_TEMPLATES } from '../../pages/transport_templates/permissions'
import { PERMS as PERMS_TRANSPOREON_OFFERS } from '../../pages/transporeon_offers/permissions'
import { PERMS as PERMS_TRACING } from '../../pages/tracing/permissions'
import { PERMS as PERMS_BOARD } from '../../pages/board/permissions'
import { PERMS as PERMS_MAP } from '../../pages/map/permissions'

import { PERMS as PERMS_REPORT_TRANSPORTS } from '../../pages/report_transports/permissions'
import { PERMS as PERMS_REPORT_SPEDITION_TRANSPORTS } from '../../pages/report_spedition_transports/permissions'
import { PERMS as PERMS_REPORT_TRANSPORT_CHANGES } from '../../pages/report_transport_changes/permissions'
import { PERMS as PERMS_REPORT_TRANSPORT_INVOICING } from '../../pages/report_transport_invoicing/permissions'
import { PERMS as PERMS_REPORT_TRANSPORT_ORDERS } from '../../pages/report_transport_orders/permissions'
import { PERMS as PERMS_REPORT_VEHICLE_KILOMETERS_TOTAL } from '../../pages/report_vehicle_kilometers_total/permissions'
import { PERMS as PERMS_REPORT_VEHICLE_TRAFFIC } from '../../pages/report_vehicle_traffic/permissions'
import { PERMS as PERMS_REPORT_VEHICLE_PERFORMANCE } from '../../pages/report_vehicle_performance/permissions'
import { PERMS as PERMS_REPORT_VEHICLE_CHECKS } from '../../pages/report_vehicle_checks/permissions'
import { PERMS as PERMS_REPORT_VEHICLE_FUELINGS } from '../../pages/report_vehicle_fuelings/permissions'
import { PERMS as PERMS_REPORT_VEHICLE_RESULTS } from '../../pages/report_vehicle_results/permissions'
import { PERMS as PERMS_REPORT_VEHICLE_PROFITS } from '../../pages/report_vehicle_profits/permissions'
import { PERMS as PERMS_REPORT_DRIVER_ALLOWANCES } from '../../pages/report_driver_allowances/permissions'
import { PERMS as PERMS_REPORT_DRIVER_PERFORMANCE_ANALYSIS } from '../../pages/report_driver_performance_analysis/permissions'
import { PERMS as PERMS_REPORT_DRIVER_HOURLY_OVERVIEW } from '../../pages/report_driver_hourly_overview/permissions'
import { PERMS as PERMS_REPORT_TIMESHEETS } from '../../pages/report_driver_timesheets/permissions'
import { PERMS as PERMS_REPORT_DRIVER_SURCHARGES } from '../../pages/report_driver_surcharges/permissions'
import { PERMS as PERMS_REPORT_DRIVER_RESULTS } from '../../pages/report_driver_results/permissions'
import { PERMS as PERMS_REPORT_DRIVER_COSTS } from '../../pages/report_driver_costs/permissions'
import { PERMS as PERMS_REPORT_TRAILER_RESULTS } from '../../pages/report_trailer_results/permissions'
import { PERMS as PERMS_REPORT_TRAILER_COSTS } from '../../pages/report_trailer_costs/permissions'
import { PERMS as PERMS_REPORT_TRAILER_FUELINGS } from '../../pages/report_trailer_fuelings/permissions'
import { PERMS as PERMS_REPORT_VEHICLE_COSTS } from '../../pages/report_vehicle_costs/permissions'
import { PERMS as PERMS_REPORT_COST_CENTER_COSTS } from '../../pages/report_cost_center_costs/permissions'
import { PERMS as PERMS_REPORT_COST_CENTER_PROFITS } from '../../pages/report_cost_center_profits/permissions'
import { PERMS as PERMS_REPORT_CUSTOMER_RESULTS } from '../../pages/report_customer_results/permissions'
import { PERMS as PERMS_REPORT_CUSTOMER_PLANS } from '../../pages/report_customer_plans/permissions'
import { PERMS as PERMS_REPORT_CUSTOMER_WEEKLY_PLANS } from '../../pages/report_customer_weekly_plans/permissions'
import { PERMS as PERMS_REPORT_CUSTOMER_MONTHLY_PLANS } from '../../pages/report_customer_monthly_plans/permissions'
import { PERMS as PERMS_REPORT_CARRIER_RESULTS } from '../../pages/report_carrier_results/permissions'
import { PERMS as PERMS_REPORT_CARRIER_SCORING } from '../../pages/report_carrier_scoring/permissions'
import { PERMS as PERMS_REPORT_CARRIER_USAGE } from '../../pages/report_carrier_usage/permissions'
import { PERMS as PERMS_REPORT_DISPATCHER_KPI } from '../../pages/report_dispatcher_kpi/permissions'
import { PERMS as PERMS_REPORT_APP_USAGE } from '../../pages/report_app_usage/permissions'
import { PERMS as PERMS_REPORT_COST_CENTER_RESULTS } from '../../pages/report_cost_center_results/permissions'
import { PERMS as PERMS_REPORT_COST_CENTER_TRAFFIC } from '../../pages/report_cost_center_traffic/permissions'
import { PERMS as PERMS_REPORT_COST_CENTER_PERFORMANCE } from '../../pages/report_cost_center_performance/permissions'
import { PERMS as PERMS_REPORT_COST_CENTER_COST } from '../../pages/report_cost_center_cost/permissions'
import { PERMS as PERMS_REPORT_CUSTOMER_TURNOVERS } from '../../pages/report_customer_turnovers/permissions'
import { PERMS as PERMS_REPORT_EMPTY_KILOMETERS } from '../../pages/report_empty_kilometers/permissions'
import { PERMS as PERMS_REPORT_PALLETS } from '../../pages/report_pallets/permissions'
import { PERMS as PERMS_REPORT_INVOICING_SUMMARY } from '../../pages/report_invoicing_summary/permissions'
import { PERMS as PERMS_INVOICING_ORDERS } from '../../pages/invoicing_orders/permissions'
import { PERMS as PERMS_INVOICING_INVOICES } from '../../pages/invoicing_invoices/permissions'
import { PERMS as PERMS_INVOICING_RECEIVED_INVOICES } from '../../pages/invoicing_received_invoices/permissions'
import { PERMS as PERMS_VEHICLES, PERMS_HISTORY as PERMS_VEHICLE_HISTORY } from '../../pages/vehicles/permissions'
import { PERMS as PERMS_VEHICLE_DOCUMENTS } from '../../pages/vehicle_documents/permissions'
import { PERMS as PERMS_VEHICLE_EQUIPMENTS } from '../../pages/vehicle_equipments/permissions'
import { PERMS as PERMS_VEHICLE_EVENTS } from '../../pages/vehicle_events/permissions'
import { PERMS as PERMS_VEHICLE_FUELINGS } from '../../pages/vehicle_fuelings/permissions'
import { PERMS as PERMS_VEHICLE_POSITIONS } from '../../pages/vehicle_positions/permissions'
import { PERMS as PERMS_TRAILERS, PERMS_HISTORY as PERMS_TRAILER_HISTORY } from '../../pages/trailers/permissions'
import { PERMS as PERMS_TRAILER_DOCUMENTS } from '../../pages/trailer_documents/permissions'
import { PERMS as PERMS_TRAILER_EQUIPMENTS } from '../../pages/trailer_equipments/permissions'
import { PERMS as PERMS_DRIVERS, PERMS_HISTORY as PERMS_DRIVER_HISTORY } from '../../pages/drivers/permissions'
import { PERMS as PERMS_DRIVERS_TYPES } from '../../pages/drivers_types/permissions'
import { PERMS as PERMS_DRIVER_DOCUMENTS } from '../../pages/driver_documents/permissions'
import { PERMS as PERMS_DRIVER_EQUIPMENTS } from '../../pages/driver_equipments/permissions'
import { PERMS as PERMS_DRIVER_TIMESHEETS } from '../../pages/driver_timesheets/permissions'
import { PERMS as PERMS_DRIVER_SURCHARGES } from '../../pages/driver_surcharges/permissions'
import { PERMS as PERMS_CUSTOMERS } from '../../pages/customers/permissions'
import { PERMS as PERMS_ADDRESSES } from '../../pages/customer_address/permissions'
import { PERMS as PERMS_CARRIERS } from '../../pages/carriers/permissions'
import { PERMS as PERMS_BORDER_CROSSINGS } from '../../pages/border_crossings/permissions'
import { PERMS as PERMS_REPORT_COST_TYPE_COSTS } from '../../pages/report_cost_types_costs/permissions'

import { PERMS as PERMS_TRANSPORT_CONDITIONS } from '../../pages/transport_conditions/permissions'
import { PERMS as PERMS_TRANSPORT_TYPES } from '../../pages/transport_types/permissions'
import { PERMS as PERMS_CARGO_TYPES } from '../../pages/cargo_types/permissions'
import { PERMS as PERMS_GOODS_TYPES } from '../../pages/goods_types/permissions'
import { PERMS as PERMS_PACKAGING_TYPES } from '../../pages/packaging_types/permissions'
import { PERMS as PERMS_SURCHARGE_TYPES } from '../../pages/surcharge_types/permissions'
import { PERMS as PERMS_TRANSPORT_SCORING } from '../../pages/transport_scoring/permissions'
import { PERMS as PERMS_TAX_RATES } from '../../pages/tax_rates/permissions'
import { PERMS as PERMS_PAYMENT_METHODS } from '../../pages/payment_methods/permissions'
import { PERMS as PERMS_INVOICE_ITEM_TYPES } from '../../pages/invoice_item_types/permissions'
import { PERMS as PERMS_COSTS } from '../../pages/costs/permissions'
import { PERMS as PERMS_SALES } from '../../pages/sales/permissions'
import { PERMS as PERMS_VEHICLE_TYPES } from '../../pages/vehicle_types/permissions'
import { PERMS as PERMS_TRAILER_TYPES } from '../../pages/trailer_types/permissions'
import { PERMS as PERMS_DOCUMENT_TYPES } from '../../pages/document_types/permissions'
import { PERMS as PERMS_EQUIPMENTS } from '../../pages/equipments/permissions'
import { PERMS as PERMS_COMPANIES } from '../../pages/companies/permissions'
import { PERMS as PERMS_COUNTRIES } from '../../pages/countries/permissions'
import { PERMS as PERMS_CURRENCIES } from '../../pages/currencies/permissions'
import { PERMS as PERMS_CUSTOMER_ADDRESS_TYPES } from '../../pages/customer_address_types/permissions'
import { PERMS as PERMS_CARRIER_SCORING } from '../../pages/carrier_scoring/permissions'
import { PERMS as PERMS_USERS } from '../../pages/users/permissions'
import { PERMS as PERMS_USER_ROLES } from '../../pages/user_roles/permissions'
import { PERMS as PERMS_USER_LOGINS } from '../../pages/user_logins/permissions'
import { PERMS as PERMS_BLOCKED_IPS } from '../../pages/blocked_ips/permissions'
import {
    PERMS_TRANSPORTS as PERMS_GENERAL_SETTINGS_TRANSPORTS,
    PERMS_EMAIL as PERMS_GENERAL_SETTINGS_EMAIL,
    PERMS_SECURITY as PERMS_GENERAL_SETTINGS_SECURITY,
} from '../../pages/general_settings/permissions'
import { PERMS as PERSM_COST_TYPES } from '../../pages/cost_types/permissions'
import { PERMS as PERSM_DIET_RATES } from '../../pages/diet_rates/permissions'
import { PERMS as PERSM_SALES_TYPES } from '../../pages/sales_types/permissions'
import { PERMS as PERMS_DRIVER_SURCHARGE_TYPES } from '../../pages/driver_surcharge_types/permissions'
import { PERMS as PERMS_REPORT_CUSTOMER_SPEDITIONS } from '../../pages/report_customer_speditions/permissions'
import { PERMS as PERMS_UNITS } from '../../pages/units/permissions'
import { PERMS as PERMS_WAREHOUSING_WAREHOUSES } from '../../pages/warehousing_warehouses/permissions'
import { PERMS as PERMS_WAREHOUSING_ITEMS } from '../../pages/warehousing_items/permissions'
import { PERMS as PERMS_WAREHOUSING_RECEIPTS } from '../../pages/warehousing_receipts/permissions'
import { PERMS as PERMS_WAREHOUSING_ISSUES } from '../../pages/warehousing_issues/permissions'
import { PERMS as PERMS_SERVICE_JOBS } from '../../pages/service_jobs/permissions'
import { PERMS as PERMS_SERVICE_WORKERS } from '../../pages/service_workers/permissions'
import { PERMS as PERMS_SERVICE_ORDERS } from '../../pages/service_orders/permissions'

const TopMenu = props => {
    // Links
    let transportsLink = null
    const permTransports = hasAnyPermission(Object.values(PERMS_TRANSPORTS))
    const permTransportTemplates = hasAnyPermission(Object.values(PERMS_TRANSPORT_TEMPLATES))
    const permTransporeonOffers = hasAnyPermission(Object.values(PERMS_TRANSPOREON_OFFERS))
    const permTracing = hasAnyPermission(Object.values(PERMS_TRACING))
    const permTransportImport = hasAnyPermission([PERMS_TRANSPORTS.CREATE])
    const permTransportImportPrices = hasAnyPermission([PERMS_TRANSPORTS.CREATE])

    if (permTransports) {
        transportsLink = 'transports'
    } else if (permTransportTemplates) {
        transportsLink = 'transport-templates'
    } else if (permTransporeonOffers) {
        transportsLink = 'transporeon/offers'
    } else if (permTracing) {
        transportsLink = 'tracing'
    }

    const permBoard = hasAnyPermission(Object.values(PERMS_BOARD))

    const permMap = hasAnyPermission(Object.values(PERMS_MAP))

    let reportsLink = null

    let reportTransportsLink = null
    const permReportTransports = hasAnyPermission(Object.values(PERMS_REPORT_TRANSPORTS))
    const permReportSpeditionTransports = hasAnyPermission(Object.values(PERMS_REPORT_SPEDITION_TRANSPORTS))
    const permReportTransportChanges = hasAnyPermission(Object.values(PERMS_REPORT_TRANSPORT_CHANGES))
    const permReportTransportInvoicing = hasAnyPermission(Object.values(PERMS_REPORT_TRANSPORT_INVOICING))
    const permReportTransportOrders = hasAnyPermission(Object.values(PERMS_REPORT_TRANSPORT_ORDERS))

    if (permReportTransports) {
        reportsLink = 'report-transports'
        reportTransportsLink = 'report-transports'
    } else if (permReportSpeditionTransports) {
        reportsLink = 'report-spedition-transports'
        reportTransportsLink = 'report-spedition-transports'
    } else if (permReportTransportChanges) {
        reportsLink = 'report-transport-changes'
        reportTransportsLink = 'report-transport-changes'
    } else if (permReportTransportInvoicing) {
        reportsLink = 'report-transport-invoicing'
        reportTransportsLink = 'report-transport-invoicing'
    } else if (permReportTransportOrders) {
        reportsLink = 'report-transport-orders'
        reportTransportsLink = 'report-transport-orders'
    }

    let reportVehiclesLink = null
    const permReportVehicleKilometersTotal = hasAnyPermission(Object.values(PERMS_REPORT_VEHICLE_KILOMETERS_TOTAL))
    const permReportVehicleTraffic = hasAnyPermission(Object.values(PERMS_REPORT_VEHICLE_TRAFFIC))
    const permReportVehiclePerformance = hasAnyPermission(Object.values(PERMS_REPORT_VEHICLE_PERFORMANCE))
    const permReportVehicleChecks = hasAnyPermission(Object.values(PERMS_REPORT_VEHICLE_CHECKS))
    const permsReportVehicleFuelings = hasAnyPermission(Object.values(PERMS_REPORT_VEHICLE_FUELINGS))
    const permsReportVehicleProfits = hasAnyPermission(Object.values(PERMS_REPORT_VEHICLE_PROFITS))
    const permsReportVehicleResults = hasAnyPermission(Object.values(PERMS_REPORT_VEHICLE_RESULTS))
    const permsReportVehicleCosts = hasAnyPermission(Object.values(PERMS_REPORT_VEHICLE_COSTS))

    if (permReportVehicleKilometersTotal) {
        if (!reportsLink) {
            reportsLink = 'report-vehicle-kilometers-total'
        }
        reportVehiclesLink = 'report-vehicle-kilometers-total'
    } else if (permReportVehicleTraffic) {
        if (!reportsLink) {
            reportsLink = 'report-vehicle-traffic'
        }
        reportVehiclesLink = 'report-vehicle-traffic'
    } else if (permReportVehiclePerformance) {
        if (!reportsLink) {
            reportsLink = 'report-vehicle-performance'
        }
        reportVehiclesLink = 'report-vehicle-performance'
    } else if (permReportVehicleChecks) {
        if (!reportsLink) {
            reportsLink = 'report-vehicle-checks'
        }
        reportVehiclesLink = 'report-vehicle-checks'
    } else if (permsReportVehicleFuelings) {
        if (!reportsLink) {
            reportsLink = 'report-vehicle-fuelings'
        }
        reportVehiclesLink = 'report-vehicle-fuelings'
    } else if (permsReportVehicleProfits) {
        if (!reportsLink) {
            reportsLink = 'report-vehicle-profits'
        }
        reportVehiclesLink = 'report-vehicle-profits'
    } else if (permsReportVehicleResults) {
        if (!reportsLink) {
            reportsLink = 'report-vehicle-results'
        }
        reportVehiclesLink = 'report-vehicle-results'
    } else if (permsReportVehicleCosts) {
        if (!reportsLink) {
            reportsLink = 'report-vehicle-costs'
        }
        reportVehiclesLink = 'report-vehicle-costs'
    }

    let reportDriversLink = null
    const permReportDriverAllowances = hasAnyPermission(Object.values(PERMS_REPORT_DRIVER_ALLOWANCES))
    const permReportDriverPerformanceAnalysis = hasAnyPermission(Object.values(PERMS_REPORT_DRIVER_PERFORMANCE_ANALYSIS))
    const permReportDriverHourlyOverview = hasAnyPermission(Object.values(PERMS_REPORT_DRIVER_HOURLY_OVERVIEW))
    const permReportDriverTimesheets = hasAnyPermission(Object.values(PERMS_REPORT_TIMESHEETS))
    const permReportDriverSurcharges = hasAnyPermission(Object.values(PERMS_REPORT_DRIVER_SURCHARGES))
    const permReportDriverResults = hasAnyPermission(Object.values(PERMS_REPORT_DRIVER_RESULTS))
    const permReportDriverCosts = hasAnyPermission(Object.values(PERMS_REPORT_DRIVER_COSTS))

    if (permReportDriverAllowances) {
        if (!reportsLink) {
            reportsLink = 'report-driver-allowances'
        }
        reportDriversLink = 'report-driver-allowances'
    } else if (permReportDriverPerformanceAnalysis) {
        if (!reportsLink) {
            reportsLink = 'report-driver-performance-analysis'
        }
        reportDriversLink = 'report-driver-performance-analysis'
    } else if (permReportDriverHourlyOverview) {
        if (!reportsLink) {
            reportsLink = 'report-driver-hourly-overview'
        }
        reportDriversLink = 'report-driver-hourly-overview'
    } else if (permReportDriverTimesheets) {
        if (!reportsLink) {
            reportsLink = 'report-driver-timesheets'
        }
        reportDriversLink = 'report-driver-timesheets'
    }  else if (permReportDriverSurcharges) {
        if (!reportsLink) {
            reportsLink = 'report-driver-surcharges'
        }
        reportDriversLink = 'report-driver-surcharges'
    } else if (permReportDriverResults) {
        if (!reportsLink) {
            reportsLink = 'report-driver-results'
        }
        reportDriversLink = 'report-driver-results'
    } else if (permReportDriverCosts) {
        if (!reportsLink) {
            reportsLink = 'report-driver-costs'
        }
        reportDriversLink = 'report-driver-costs'
    }

    let reportTrailersLink = null
    const permReportTrailerResults = hasAnyPermission(Object.values(PERMS_REPORT_TRAILER_RESULTS))
    const permReportTrailerCosts = hasAnyPermission(Object.values(PERMS_REPORT_TRAILER_COSTS))
    const permsReportTrailerFuelings = hasAnyPermission(Object.values(PERMS_REPORT_TRAILER_FUELINGS))

    if (permReportTrailerResults) {
        if (!reportsLink) {
            reportsLink = 'report-trailer-results'
        }
        reportTrailersLink = 'report-trailer-results'
    }
    else if (permReportTrailerCosts) {
        if (!reportsLink) {
            reportsLink = 'report-trailer-costs'
        }
        reportTrailersLink = 'report-trailer-costs'
    }
    else if (permsReportTrailerFuelings) {
        if (!reportsLink) {
            reportsLink = 'report-trailer-fuelings'
        }
        reportTrailersLink = 'report-trailer-fuelings'
    }

    let reportCustomersLink = null
    const permReportCustomerResults = hasAnyPermission(Object.values(PERMS_REPORT_CUSTOMER_RESULTS))
    const permReportCustomerPlans = hasAnyPermission(Object.values(PERMS_REPORT_CUSTOMER_PLANS))
    const permReportCustomerWeeklyPlans = hasAnyPermission(Object.values(PERMS_REPORT_CUSTOMER_WEEKLY_PLANS))
    const permReportCustomerMonthlyPlans = hasAnyPermission(Object.values(PERMS_REPORT_CUSTOMER_MONTHLY_PLANS))
    const permReportCustomerSpeditions = hasAnyPermission(Object.values(PERMS_REPORT_CUSTOMER_SPEDITIONS))

    if (permReportCustomerResults) {
        if (!reportsLink) {
            reportsLink = 'report-customer-results'
        }
        reportCustomersLink = 'report-customer-results'
    } else if (permReportCustomerPlans) {
        if (!reportsLink) {
            reportsLink = 'report-customer-plans'
        }
        reportCustomersLink = 'report-customer-plans'
    } else if (permReportCustomerWeeklyPlans) {
        if (!reportsLink) {
            reportsLink = 'report-customer-weekly-plans'
        }
        reportCustomersLink = 'report-customer-weekly-plans'
    } else if (permReportCustomerMonthlyPlans) {
        if (!reportsLink) {
            reportsLink = 'report-customer-monthly-plans'
        }
        reportCustomersLink = 'report-customer-monthly-plans'
    } else if (permReportCustomerSpeditions) {
        if (!reportsLink) {
            reportsLink = 'report-customer-speditions'
        }
        reportCustomersLink = 'report-customer-speditions'
    }

    let reportCarriersLink = null
    const permReportCarrierResults = hasAnyPermission(Object.values(PERMS_REPORT_CARRIER_RESULTS))
    const permReportCarrierScoring = hasAnyPermission(Object.values(PERMS_REPORT_CARRIER_SCORING))
    const permReportCarrierUsage = hasAnyPermission(Object.values(PERMS_REPORT_CARRIER_USAGE))

    if (permReportCarrierResults) {
        if (!reportsLink) {
            reportsLink = 'report-carrier-results'
        }
        reportCarriersLink = 'report-carrier-results'
    } else if (permReportCarrierScoring) {
        if (!reportsLink) {
            reportsLink = 'report-carrier-scoring'
        }
        reportCarriersLink = 'report-carrier-scoring'
    } else if (permReportCarrierUsage) {
        if (!reportsLink) {
            reportsLink = 'report-carrier-usage'
        }
        reportCarriersLink = 'report-carrier-usage'
    }

    let reportDispatchersLink = null
    const permReportDispatcherKpi = hasAnyPermission(Object.values(PERMS_REPORT_DISPATCHER_KPI))

    if (permReportDispatcherKpi) {
        if (!reportsLink) {
            reportsLink = 'report-dispatcher-kpi'
        }
        reportDispatchersLink = 'report-dispatcher-kpi'
    }

    let reportCostCentersLink = null
    const permReportCostCenterTraffic = hasAnyPermission(Object.values(PERMS_REPORT_COST_CENTER_TRAFFIC))
    const permReportCostCenterPerformance = hasAnyPermission(Object.values(PERMS_REPORT_COST_CENTER_PERFORMANCE))
    const permReportCostCenterCost = hasAnyPermission(Object.values(PERMS_REPORT_COST_CENTER_COST))
    const permReportCostCenterResults = hasAnyPermission(Object.values(PERMS_REPORT_COST_CENTER_RESULTS))
    const permReportCostCenterCosts = hasAnyPermission(Object.values(PERMS_REPORT_COST_CENTER_COSTS))
    const permReportCostCenterProfits = hasAnyPermission(Object.values(PERMS_REPORT_COST_CENTER_PROFITS))

    if (permReportCostCenterTraffic) {
        if (!reportsLink) {
            reportsLink = 'report-cost-center-traffic'
        }
        reportCostCentersLink = 'report-cost-center-traffic'
    } else if (permReportCostCenterPerformance) {
        if (!reportsLink) {
            reportsLink = 'report-cost-center-performance'
        }
        reportCostCentersLink = 'report-cost-center-performance'
    } else if (permReportCostCenterCost) {
        if (!reportsLink) {
            reportsLink = 'report-cost-center-cost'
        }
        reportCostCentersLink = 'report-cost-center-cost'
    } else if (permReportCostCenterResults) {
        if (!reportsLink) {
            reportsLink = 'report-cost-center-results'
        }
        reportCostCentersLink = 'report-cost-center-results'
    } else if (permReportCostCenterCosts) {
        if (!reportsLink) {
            reportsLink = 'report-cost-center-costs'
        }
        reportCostCentersLink = 'report-cost-center-costs'
    } else if (permReportCostCenterProfits) {
        if (!reportsLink) {
            reportsLink = 'report-cost-center-profits'
        }
        reportCostCentersLink = 'report-cost-center-profits'
    }

    let reportResultsLink = null
    const permReportResultsCostCenters = hasAnyPermission(Object.values(PERMS_REPORT_COST_CENTER_RESULTS))
    const permReportResultsVehicles = hasAnyPermission(Object.values(PERMS_REPORT_VEHICLE_RESULTS))
    const permReportResultsDrivers = hasAnyPermission(Object.values(PERMS_REPORT_DRIVER_RESULTS))
    const permReportResultsTrailers = hasAnyPermission(Object.values(PERMS_REPORT_TRAILER_RESULTS))

    if (permReportResultsCostCenters) {
        if (!reportsLink) {
            reportsLink = 'report-cost-center-results'
        }
        reportResultsLink = 'report-cost-center-results'
    } else if (permReportResultsVehicles) {
        if (!reportsLink) {
            reportsLink = 'report-vehicle-results'
        }
        reportResultsLink = 'report-vehicle-results'
    } else if (permReportResultsDrivers) {
        if (!reportsLink) {
            reportsLink = 'report-driver-results'
        }
        reportResultsLink = 'report-driver-results'
    } else if (permReportResultsTrailers) {
        if (!reportsLink) {
            reportsLink = 'report-trailer-results'
        }
        reportResultsLink = 'report-trailer-results'
    }

    let reportCostsLink = null
    const permReportCostsCostCenters = hasAnyPermission(Object.values(PERMS_REPORT_COST_CENTER_COSTS))
    const permReportCostsVehicles = hasAnyPermission(Object.values(PERMS_REPORT_VEHICLE_COSTS))
    const permReportCostsDrivers = hasAnyPermission(Object.values(PERMS_REPORT_DRIVER_COSTS))
    const permReportCostsTrailers = hasAnyPermission(Object.values(PERMS_REPORT_TRAILER_COSTS))
    const permReportCostsCostTypes = hasAnyPermission(Object.values(PERMS_REPORT_COST_TYPE_COSTS))

    if (permReportCostsCostCenters) {
        if (!reportsLink) {
            reportsLink = 'report-cost-center-costs'
        }
        reportCostsLink = 'report-cost-center-costs'
    } else if (permReportCostsVehicles) {
        if (!reportsLink) {
            reportsLink = 'report-vehicle-costs'
        }
        reportCostsLink = 'report-vehicle-costs'
    } else if (permReportCostsTrailers) {
        if (!reportsLink) {
            reportsLink = 'report-trailer-costs'
        }
        reportCostsLink = 'report-trailer-costs'
    } else if (permReportCostsDrivers) {
        if (!reportsLink) {
            reportsLink = 'report-driver-costs'
        }
        reportCostsLink = 'report-driver-costs'
    } else if (permReportCostsCostTypes) {
        if (!reportsLink) {
            reportsLink = 'report-cost-type-costs'
        }
        reportCostsLink = 'report-cost-type-costs'
    }

    let reportOtherLink = null
    const permReportCustomerTurnovers = hasAnyPermission(Object.values(PERMS_REPORT_CUSTOMER_TURNOVERS))
    const permReportEmptyKilometers = hasAnyPermission(Object.values(PERMS_REPORT_EMPTY_KILOMETERS))
    const permReportPallets = hasAnyPermission(Object.values(PERMS_REPORT_PALLETS))
    const permReportAppUsage = hasAnyPermission(Object.values(PERMS_REPORT_APP_USAGE))

    if (permReportCustomerTurnovers) {
        if (!reportsLink) {
            reportsLink = 'report-customer-turnovers'
        }
        reportOtherLink = 'report-customer-turnovers'
    } else if (permReportEmptyKilometers) {
        if (!reportsLink) {
            reportsLink = 'report-empty-kilometers'
        }
        reportOtherLink = 'report-empty-kilometers'
    } else if (permReportPallets) {
        if (!reportsLink) {
            reportsLink = 'report-pallets'
        }
        reportOtherLink = 'report-pallets'
    } else if (permReportAppUsage) {
        if (!reportsLink) {
            reportsLink = 'report-app-usage'
        }
        reportOtherLink = 'report-app-usage'
    }

    let invoicingLink = null

    const permInvoicingOrders = hasAnyPermission(Object.values(PERMS_INVOICING_ORDERS))
    const permInvoicingInvoices = hasAnyPermission(Object.values(PERMS_INVOICING_INVOICES))
    const permInvoicingReceivedInvoices = hasAnyPermission(Object.values(PERMS_INVOICING_RECEIVED_INVOICES))
    const permReportInvoicingSummary = hasAnyPermission(Object.values(PERMS_REPORT_INVOICING_SUMMARY))

    if (permInvoicingOrders) {
        invoicingLink = 'invoicing-orders'
    } else if (permInvoicingInvoices) {
        invoicingLink = 'invoicing-invoices'
    } else if (permInvoicingReceivedInvoices) {
        invoicingLink = 'invoicing-received-invoices'
    }else if (permReportInvoicingSummary) {
        invoicingLink = 'report-invoicing-summary'
    }

    const permCosts = hasAnyPermission(Object.values(PERMS_COSTS))
    const permSales = hasAnyPermission(Object.values(PERMS_SALES))

    let vehiclesLink = null

    const permVehicles = hasAnyPermission(Object.values(PERMS_VEHICLES))
    const permVehicleHistory = hasAnyPermission(Object.values(PERMS_VEHICLE_HISTORY))
    const permVehicleDocuments = hasAnyPermission(Object.values(PERMS_VEHICLE_DOCUMENTS))
    const permVehicleEquipments = hasAnyPermission(Object.values(PERMS_VEHICLE_EQUIPMENTS))
    const permVehicleEvents = hasAnyPermission(Object.values(PERMS_VEHICLE_EVENTS))
    const permVehicleFuelings = hasAnyPermission(Object.values(PERMS_VEHICLE_FUELINGS))
    const permVehiclePositions = hasAnyPermission(Object.values(PERMS_VEHICLE_POSITIONS))

    if (permVehicles) {
        vehiclesLink = 'vehicles'
    } else if (permVehicleHistory) {
        vehiclesLink = 'vehicles/history'
    } else if (permVehicleDocuments) {
        vehiclesLink = 'vehicle-documents'
    } else if (permVehicleEquipments) {
        vehiclesLink = 'vehicle-equipments'
    } else if (permVehicleEvents) {
        vehiclesLink = 'vehicle-events'
    } else if (permVehicleFuelings) {
        vehiclesLink = 'vehicle-fuelings'
    }else if (permVehiclePositions) {
        vehiclesLink = 'vehicle-positions'
    }

    let trailersLink = null

    const permTrailers = hasAnyPermission(Object.values(PERMS_TRAILERS))
    const permTrailerHistory = hasAnyPermission(Object.values(PERMS_TRAILER_HISTORY))
    const permTrailerDocuments = hasAnyPermission(Object.values(PERMS_TRAILER_DOCUMENTS))
    const permTrailerEquipments = hasAnyPermission(Object.values(PERMS_TRAILER_EQUIPMENTS))

    if (permTrailers) {
        trailersLink = 'trailers'
    } else if (permTrailerHistory) {
        trailersLink = 'trailers/history'
    } else if (permTrailerDocuments) {
        trailersLink = 'trailer-documents'
    } else if (permTrailerEquipments) {
        trailersLink = 'trailer-equipments'
    }

    let driversLink = null

    const permDrivers = hasAnyPermission(Object.values(PERMS_DRIVERS))
    const permDriverHistory = hasAnyPermission(Object.values(PERMS_DRIVER_HISTORY))
    const permDriverDocuments = hasAnyPermission(Object.values(PERMS_DRIVER_DOCUMENTS))
    const permDriverEquipments = hasAnyPermission(Object.values(PERMS_DRIVER_EQUIPMENTS))
    const permDriverTimesheets = hasAnyPermission(Object.values(PERMS_DRIVER_TIMESHEETS))
    const permDriverSurcharges = hasAnyPermission(Object.values(PERMS_DRIVER_SURCHARGES))

    if (permDrivers) {
        driversLink = 'drivers'
    } else if (permDriverHistory) {
        driversLink = 'drivers/history'
    } else if (permDriverDocuments) {
        driversLink = 'driver-documents'
    } else if (permDriverEquipments) {
        driversLink = 'driver-equipments'
    } else if (permDriverTimesheets) {
        driversLink = 'driver-timesheets'
    } else if (permDriverSurcharges) {
        driversLink = 'driver-surcharges'
    }

    let addressBookLink = null

    const permCustomers = hasAnyPermission(Object.values(PERMS_CUSTOMERS))
    const permAddresses = hasAnyPermission(Object.values(PERMS_ADDRESSES))
    const permCarriers = hasAnyPermission(Object.values(PERMS_CARRIERS))
    const permBorderCrossings = hasAnyPermission(Object.values(PERMS_BORDER_CROSSINGS))

    if (permCustomers) {
        addressBookLink = 'customers'
    } else if (permAddresses) {
        addressBookLink = 'customer-addresses'
    } else if (permCarriers) {
        addressBookLink = 'carriers'
    } else if (permBorderCrossings) {
        addressBookLink = 'border-crossings'
    }

    let settingsLink = null

    let settingTransportsLink = null
    const permTransportConditions = hasAnyPermission(Object.values(PERMS_TRANSPORT_CONDITIONS))
    const permTransportTypes = hasAnyPermission(Object.values(PERMS_TRANSPORT_TYPES))
    const permCargoTypes = hasAnyPermission(Object.values(PERMS_CARGO_TYPES))
    const permGoodsTypes = hasAnyPermission(Object.values(PERMS_GOODS_TYPES))
    const permPackagingTypes = hasAnyPermission(Object.values(PERMS_PACKAGING_TYPES))
    const permSurchargeTypes = hasAnyPermission(Object.values(PERMS_SURCHARGE_TYPES))
    const permTransportScoring = hasAnyPermission(Object.values(PERMS_TRANSPORT_SCORING))

    if (permTransportConditions) {
        settingsLink = 'transport-conditions'
        settingTransportsLink = 'transport-conditions'
    } else if (permTransportTypes) {
        settingsLink = 'transport-types'
        settingTransportsLink = 'transport-types'
    } else if (permCargoTypes) {
        settingsLink = 'cargo-types'
        settingTransportsLink = 'cargo-types'
    } else if (permGoodsTypes) {
        settingsLink = 'goods-types'
        settingTransportsLink = 'goods-types'
    } else if (permPackagingTypes) {
        settingsLink = 'packaging-types'
        settingTransportsLink = 'packaging-types'
    } else if (permSurchargeTypes) {
        settingsLink = 'surcharge-types'
        settingTransportsLink = 'surcharge-types'
    } else if (permTransportScoring) {
        settingsLink = 'transport-scoring'
        settingTransportsLink = 'transport-scoring'
    }

    let settingsInvoicingLink = null

    const permTaxRates = hasAnyPermission(Object.values(PERMS_TAX_RATES))
    const permPaymentMethods = hasAnyPermission(Object.values(PERMS_PAYMENT_METHODS))
    const permInvoiceItemTypes = hasAnyPermission(Object.values(PERMS_INVOICE_ITEM_TYPES))

    if (permTaxRates) {
        if (!settingsLink) {
            settingsLink = 'tax-rates'
        }
        settingsInvoicingLink = 'tax-rates'
    } else if (permPaymentMethods) {
        if (!settingsLink) {
            settingsLink = 'payment-methods'
        }
        settingsInvoicingLink = 'payment-methods'
    } else if (permInvoiceItemTypes) {
        if (!settingsLink) {
            settingsLink = 'invoice-item-types'
        }
        settingsInvoicingLink = 'invoice-item-types'
    }

    let settingsFleetLink = null

    const permVehicleTypes = hasAnyPermission(Object.values(PERMS_VEHICLE_TYPES))
    const permTrailerTypes = hasAnyPermission(Object.values(PERMS_TRAILER_TYPES))
    const permDocumentTypes = hasAnyPermission(Object.values(PERMS_DOCUMENT_TYPES))
    const permEquipments = hasAnyPermission(Object.values(PERMS_EQUIPMENTS))

    if (permVehicleTypes) {
        if (!settingsLink) {
            settingsLink = 'vehicle-types'
        }
        settingsFleetLink = 'vehicle-types'
    } else if (permTrailerTypes) {
        if (!settingsLink) {
            settingsLink = 'trailer-types'
        }
        settingsFleetLink = 'trailer-types'
    } else if (permDocumentTypes) {
        if (!settingsLink) {
            settingsLink = 'document-types'
        }
        settingsFleetLink = 'document-types'
    } else if (permEquipments) {
        if (!settingsLink) {
            settingsLink = 'equipments'
        }
        settingsFleetLink = 'equipments'
    }

    let settingsAddressBookLink = null

    const permCompanies = hasAnyPermission(Object.values(PERMS_COMPANIES))
    const permCountries = hasAnyPermission(Object.values(PERMS_COUNTRIES))
    const permCurrencies = hasAnyPermission(Object.values(PERMS_CURRENCIES))
    const permCustomerAddressTypes = hasAnyPermission(Object.values(PERMS_CUSTOMER_ADDRESS_TYPES))
    const permCarrierScoring = hasAnyPermission(Object.values(PERMS_CARRIER_SCORING))

    if (permCompanies) {
        if (!settingsLink) {
            settingsLink = 'companies'
        }
        settingsAddressBookLink = 'companies'
    } else if (permCountries) {
        if (!settingsLink) {
            settingsLink = 'countries'
        }
        settingsAddressBookLink = 'countries'
    } else if (permCurrencies) {
        if (!settingsLink) {
            settingsLink = 'currencies'
        }
        settingsAddressBookLink = 'currencies'
    } else if (permCustomerAddressTypes) {
        if (!settingsLink) {
            settingsLink = 'customer-address-types'
        }
        settingsAddressBookLink = 'customer-address-types'
    } else if (permCarrierScoring) {
        if (!settingsLink) {
            settingsLink = 'carrier-scoring'
        }
        settingsAddressBookLink = 'carrier-scoring'
    }

    let settingsUsersLink = null

    const permUsers = hasAnyPermission(Object.values(PERMS_USERS))
    const permUserRoles = hasAnyPermission(Object.values(PERMS_USER_ROLES))
    const permUserLogins = hasAnyPermission(Object.values(PERMS_USER_LOGINS))
    const permBlockedIps = hasAnyPermission(Object.values(PERMS_BLOCKED_IPS))

    if (permUsers) {
        if (!settingsLink) {
            settingsLink = 'users'
        }
        settingsUsersLink = 'users'
    } else if (permUserRoles) {
        if (!settingsLink) {
            settingsLink = 'user-roles'
        }
        settingsUsersLink = 'user-roles'
    } else if (permUserLogins) {
        if (!settingsLink) {
            settingsLink = 'user-logins'
        }
        settingsUsersLink = 'user-logins'
    } else if (permBlockedIps) {
        if (!settingsLink) {
            settingsLink = 'blocked-ips'
        }
        settingsUsersLink = 'blocked-ips'
    }

    const permGeneralSettings = hasAnyPermission([
        ...Object.values(PERMS_GENERAL_SETTINGS_TRANSPORTS),
        ...Object.values(PERMS_GENERAL_SETTINGS_EMAIL),
        ...Object.values(PERMS_GENERAL_SETTINGS_SECURITY),
    ])

    if (permGeneralSettings) {
        if (!settingsLink) {
            settingsLink = 'general-settings'
        }
    }

    const permCostTypes = hasAnyPermission(Object.values(PERSM_COST_TYPES))
    const permSalesTypes = hasAnyPermission(Object.values(PERSM_SALES_TYPES))
    const permDriversTypes = hasAnyPermission(Object.values(PERMS_DRIVERS_TYPES))
    const permDietRates = hasAnyPermission(Object.values(PERSM_DIET_RATES))
    const permDriverSurchargeTypes = hasAnyPermission(Object.values(PERMS_DRIVER_SURCHARGE_TYPES))

    if (permCostTypes) {
        if (!settingsLink) {
            settingsLink = 'cost-types'
        }
    } else if (permDietRates) {
        if (!settingsLink) {
            settingsLink = 'diet-rates'
        }
    } else if (permSalesTypes) {
        if (!settingsLink) {
            settingsLink = 'sales-types'
        }
    } else if (permDriversTypes) {
        if (!settingsLink) {
            settingsLink = 'drivers-types'
        }
    } else if (permDriverSurchargeTypes) {
        if (!settingsLink) {
            settingsLink = 'driver-surcharge-types'
        }
    }

    let warehousingLink = null
    const permWarehousingWarehouses = hasAnyPermission(Object.values(PERMS_WAREHOUSING_WAREHOUSES))
    const permWarehousingItems = hasAnyPermission(Object.values(PERMS_WAREHOUSING_ITEMS))
    const permWarehousingReceipts = hasAnyPermission(Object.values(PERMS_WAREHOUSING_RECEIPTS))
    const permWarehousingIssues = hasAnyPermission(Object.values(PERMS_WAREHOUSING_ISSUES))
    const permUnits = hasAnyPermission(Object.values(PERMS_UNITS))

    if (permWarehousingWarehouses) {
        warehousingLink = 'warehousing-warehouses'
    }
    else if (permWarehousingItems) {
        warehousingLink = 'warehousing-items'
    }
    else if (permWarehousingReceipts) {
        warehousingLink = 'warehousing-receipts'
    }
    else if (permWarehousingIssues) {
        warehousingLink = 'warehousing-issues'
    }
    else if (permUnits) {
        warehousingLink = 'units'
    }

    let serviceLink = null
    const permServiceJobs = hasAnyPermission(Object.values(PERMS_SERVICE_JOBS))
    const permServiceWorkers = hasAnyPermission(Object.values(PERMS_SERVICE_WORKERS))
    const permServiceOrders = hasAnyPermission(Object.values(PERMS_SERVICE_ORDERS))

    if (permServiceOrders) {
        serviceLink = 'service-orders'
    }
    else if (permServiceJobs) {
        serviceLink = 'service-jobs'
    }
    else if (permServiceWorkers) {
        serviceLink = 'service-workers'
    }

    let handleMobileMenuClose = () => {
        document.body.classList.remove('mobile-menu-open')
    }

    return (

        <div className={'horizontal-bar sidebar '+(document.body.classList.contains('mobile-menu-open') ? 'visible' : '')}>
            <div className="page-sidebar-inner slimscroll">
                <ul className="menu accordion-menu">
                    {transportsLink && (
                        <li className="droplink">
                            <Link to={url(props.match, transportsLink)}>
                                <span className="menu-icon far fa-road" />
                                <p>
                                    <FormattedMessage id="menuItems.transports" />
                                </p>
                            </Link>
                            <ul className="sub-menu hidden">
                                {permTransports && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'transports')}>
                                            <FormattedMessage id="menuItems.transports" />
                                        </Link>
                                    </li>
                                )}
                                {permTransportTemplates && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'transport-templates')}>
                                            <FormattedMessage id="menuItems.transportTemplates" />
                                        </Link>
                                    </li>
                                )}
                                {permTransporeonOffers && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'transporeon/offers')}>
                                            <FormattedMessage id="menuItems.transporeonOffers" />
                                        </Link>
                                    </li>
                                )}
                                {permTracing && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'tracing')}>
                                            <FormattedMessage id="menuItems.tracing" />
                                        </Link>
                                    </li>
                                )}
                                {permTransportImport && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'transports/import')}>
                                            <FormattedMessage id="menuItems.transportsImport" />
                                        </Link>
                                    </li>
                                )}
                                {permTransportImportPrices && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'transports/import-prices')}>
                                            <FormattedMessage id="menuItems.transportsImportPrices" />
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}

                    {permBoard && (
                        <li className="droplink">
                            <Link to={url(props.match, `board/${moment().format('Y-MM-DD')}`)}>
                                <span className="menu-icon far fa-calendar-alt" />
                                <p>
                                    <FormattedMessage id="menuItems.dispatcherBoard" />
                                </p>
                            </Link>
                        </li>
                    )}

                    {permMap && (
                        <li className="droplink">
                            <Link to={url(props.match, 'map')}>
                                <span className="menu-icon far fa-map-marker-alt" />
                                <p>
                                    <FormattedMessage id="menuItems.map" />
                                </p>
                            </Link>
                        </li>
                    )}

                    {reportsLink && (
                        <li className="droplink">
                            <Link to={url(props.match, reportsLink)}>
                                <span className="menu-icon far fa-chart-line" />
                                <p>
                                    <FormattedMessage id="menuItems.reports" />
                                </p>
                            </Link>
                            <ul className="sub-menu hidden">
                                {reportTransportsLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, 'report-transports')}>
                                            <FormattedMessage id="menuItems.reportTransports" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permReportTransports && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-transports')}>
                                                        <FormattedMessage id="menuItems.reportTransportDetails" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportSpeditionTransports && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-spedition-transports')}>
                                                        <FormattedMessage id="menuItems.reportSpeditionTransportDetails" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportTransportChanges && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-transport-changes')}>
                                                        <FormattedMessage id="menuItems.reportTransportChanges" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportTransportInvoicing && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-transport-invoicing')}>
                                                        <FormattedMessage id="menuItems.reportTransportInvoicing" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportTransportOrders && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-transport-orders')}>
                                                        <FormattedMessage id="menuItems.reportTransportOrders" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {reportCostCentersLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, reportCostCentersLink)}>
                                            <FormattedMessage id="menuItems.reportCostCenters" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permReportCostCenterTraffic && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-cost-center-traffic')}>
                                                        <FormattedMessage id="menuItems.reportCostCenterTraffic" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCostCenterPerformance && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-cost-center-performance')}>
                                                        <FormattedMessage id="menuItems.reportCostCenterPerformance" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCostCenterResults && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-cost-center-results')}>
                                                        <FormattedMessage id="menuItems.reportCostCenterResults" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCostCenterCosts && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-cost-center-costs')}>
                                                        <FormattedMessage id="menuItems.reportCostCenterCosts" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCostCenterCost && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-cost-center-cost')}>
                                                        <FormattedMessage id="menuItems.reportCostCenterCost" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCostCenterProfits && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-cost-center-profits')}>
                                                        <FormattedMessage id="menuItems.reportCostCenterProfits" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {reportVehiclesLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, reportVehiclesLink)}>
                                            <FormattedMessage id="menuItems.reportVehicles" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permReportVehicleKilometersTotal && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-vehicle-kilometers-total')}>
                                                        <FormattedMessage id="menuItems.reportVehicleKilometersTotal" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportVehicleTraffic && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-vehicle-traffic')}>
                                                        <FormattedMessage id="menuItems.reportVehicleTraffic" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportVehiclePerformance && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-vehicle-performance')}>
                                                        <FormattedMessage id="menuItems.reportVehiclePerformance" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportVehicleChecks && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-vehicle-checks')}>
                                                        <FormattedMessage id="menuItems.reportVehicleChecks" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permsReportVehicleFuelings && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-vehicle-fuelings')}>
                                                        <FormattedMessage id="menuItems.reportVehicleFuelings" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permsReportVehicleProfits && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-vehicle-profits')}>
                                                        <FormattedMessage id="menuItems.reportVehicleProfits" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permsReportVehicleResults && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-vehicle-results')}>
                                                        <FormattedMessage id="menuItems.reportVehicleResults" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permsReportVehicleCosts && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-vehicle-costs')}>
                                                        <FormattedMessage id="menuItems.reportVehicleCosts" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {reportDriversLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, reportDriversLink)}>
                                            <FormattedMessage id="menuItems.reportDrivers" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permReportDriverAllowances && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-driver-allowances')}>
                                                        <FormattedMessage id="menuItems.reportDriverAllowances" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportDriverPerformanceAnalysis && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-driver-performance-analysis')}>
                                                        <FormattedMessage id="menuItems.reportDriverPerformanceAnalysis" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportDriverHourlyOverview && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-driver-hourly-overview')}>
                                                        <FormattedMessage id="menuItems.reportDriverHourlyOverview" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportDriverTimesheets && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-driver-timesheets')}>
                                                        <FormattedMessage id="menuItems.reportDriverTimesheet" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportDriverSurcharges && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-driver-surcharges')}>
                                                        <FormattedMessage id="menuItems.reportDriverSurcharges" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportDriverResults && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-driver-results')}>
                                                        <FormattedMessage id="menuItems.reportDriverResults" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportDriverCosts && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-driver-costs')}>
                                                        <FormattedMessage id="menuItems.reportDriverCosts" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {reportTrailersLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, reportTrailersLink)}>
                                            <FormattedMessage id="menuItems.reportTrailers" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permReportTrailerResults && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-trailer-results')}>
                                                        <FormattedMessage id="menuItems.reportTrailerResults" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportTrailerCosts && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-trailer-costs')}>
                                                        <FormattedMessage id="menuItems.reportTrailerCosts" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permsReportTrailerFuelings && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-trailer-fuelings')}>
                                                        <FormattedMessage id="menuItems.reportTrailerFuelings" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {reportCustomersLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, reportCustomersLink)}>
                                            <FormattedMessage id="menuItems.reportCustomers" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permReportCustomerResults && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-customer-results')}>
                                                        <FormattedMessage id="menuItems.reportCustomerResults" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCustomerPlans && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-customer-plans')}>
                                                        <FormattedMessage id="menuItems.reportCustomerPlans" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCustomerWeeklyPlans && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-customer-weekly-plans')}>
                                                        <FormattedMessage id="menuItems.reportCustomerWeeklyPlans" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCustomerMonthlyPlans && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-customer-monthly-plans')}>
                                                        <FormattedMessage id="menuItems.reportCustomerMonthlyPlans" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCustomerSpeditions && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-customer-speditions')}>
                                                        <FormattedMessage id="menuItems.reportCustomerSpeditions" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {reportCarriersLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, reportCarriersLink)}>
                                            <FormattedMessage id="menuItems.reportCarriers" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permReportCarrierResults && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-carrier-results')}>
                                                        <FormattedMessage id="menuItems.reportCarrierResults" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCarrierScoring && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-carrier-scoring')}>
                                                        <FormattedMessage id="menuItems.reportCarrierScoring" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCarrierUsage && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-carrier-usage')}>
                                                        <FormattedMessage id="menuItems.reportCarrierUsage" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {reportDispatchersLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, reportDispatchersLink)}>
                                            <FormattedMessage id="menuItems.reportDispatchers" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permReportDispatcherKpi && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-dispatcher-kpi')}>
                                                        <FormattedMessage id="menuItems.reportDispatcherKPI" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {reportResultsLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, reportResultsLink)}>
                                            <FormattedMessage id="menuItems.reportResults" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permReportCostCenterResults && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-cost-center-results')}>
                                                        <FormattedMessage id="menuItems.reportResultsCostCenters" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permsReportVehicleResults && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-vehicle-results')}>
                                                        <FormattedMessage id="menuItems.reportResultsVehicles" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportDriverResults && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-driver-results')}>
                                                        <FormattedMessage id="menuItems.reportResultsDrivers" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportTrailerResults && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-trailer-results')}>
                                                        <FormattedMessage id="menuItems.reportResultsTrailers" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {reportCostsLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, reportCostsLink)}>
                                            <FormattedMessage id="menuItems.reportCosts" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permReportCostCenterCosts && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-cost-center-costs')}>
                                                        <FormattedMessage id="menuItems.reportCostsCostCenters" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permsReportVehicleCosts && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-vehicle-costs')}>
                                                        <FormattedMessage id="menuItems.reportCostsVehicles" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportDriverCosts && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-driver-costs')}>
                                                        <FormattedMessage id="menuItems.reportCostsDrivers" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportTrailerCosts && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-trailer-costs')}>
                                                        <FormattedMessage id="menuItems.reportCostsTrailers" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportCostsCostTypes && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-cost-type-costs')}>
                                                        <FormattedMessage id="menuItems.reportCostsCostTypes" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {reportOtherLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, reportOtherLink)}>
                                            <FormattedMessage id="menuItems.reportOther" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permReportCustomerTurnovers && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-customer-turnovers')}>
                                                        <FormattedMessage id="menuItems.reportCustomerTurnovers" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportEmptyKilometers && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-empty-kilometers')}>
                                                        <FormattedMessage id="menuItems.reportEmptyKilometers" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportPallets && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-pallets')}>
                                                        <FormattedMessage id="menuItems.reportPallets" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permReportAppUsage && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-app-usage')}>
                                                        <FormattedMessage id="menuItems.reportAppUsage" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}

                    {warehousingLink && (
                        <li className="droplink">
                            <Link to={url(props.match, warehousingLink)}>
                                <span className="menu-icon far fa-warehouse" />
                                <p>
                                    <FormattedMessage id="menuItems.warehousing" />
                                </p>
                            </Link>
                            <ul className="sub-menu hidden">
                                {permWarehousingWarehouses && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'warehousing-warehouses')}>
                                            <FormattedMessage id="menuItems.warehousingWarehouses" />
                                        </Link>
                                    </li>
                                )}
                                {permWarehousingItems && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'warehousing-items')}>
                                            <FormattedMessage id="menuItems.warehousingItems" />
                                        </Link>
                                    </li>
                                )}
                                {permWarehousingReceipts && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'warehousing-receipts')}>
                                            <FormattedMessage id="menuItems.warehousingReceipts" />
                                        </Link>
                                    </li>
                                )}
                                {permWarehousingIssues && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'warehousing-issues')}>
                                            <FormattedMessage id="menuItems.warehousingIssues" />
                                        </Link>
                                    </li>
                                )}
                                {permUnits && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'units')}>
                                            <FormattedMessage id="menuItems.units" />
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}

                    {serviceLink && (
                        <li className="droplink">
                            <Link to={url(props.match, serviceLink)}>
                                <span className="menu-icon far fa-wrench" />
                                <p>
                                    <FormattedMessage id="menuItems.service" />
                                </p>
                            </Link>
                            <ul className="sub-menu hidden">
                                {permServiceOrders && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'service-orders')}>
                                            <FormattedMessage id="menuItems.serviceOrders" />
                                        </Link>
                                    </li>
                                )}
                                {permServiceJobs && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'service-jobs')}>
                                            <FormattedMessage id="menuItems.serviceJobs" />
                                        </Link>
                                    </li>
                                )}
                                {permServiceWorkers && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'service-workers')}>
                                            <FormattedMessage id="menuItems.serviceWorkers" />
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}

                    {invoicingLink && (
                        <li className="droplink">
                            <Link to={url(props.match, invoicingLink)}>
                                <span className="menu-icon far fa-inbox" />
                                <p>
                                    <FormattedMessage id="menuItems.invoicing" />
                                </p>
                            </Link>
                            <ul className="sub-menu hidden">
                                {permInvoicingOrders && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'invoicing-orders')}>
                                            <FormattedMessage id="menuItems.invoicingOrders" />
                                        </Link>
                                    </li>
                                )}
                                {permInvoicingInvoices && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'invoicing-invoices')}>
                                            <FormattedMessage id="menuItems.invoicingInvoices" />
                                        </Link>
                                    </li>
                                )}
                                {permInvoicingReceivedInvoices && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'invoicing-received-invoices')}>
                                            <FormattedMessage id="menuItems.invoicingReceivedInvoices" />
                                        </Link>
                                    </li>
                                )}
                                {permReportInvoicingSummary && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'report-invoicing-summary')}>
                                            <FormattedMessage id="menuItems.reportInvoicingSummary" />
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}

                    {permSales && (
                        <li className="droplink">
                            <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'sales')}>
                                <span className="menu-icon fas fa-coins" />
                                <p>
                                    <FormattedMessage id="menuItems.sales" />
                                </p>
                            </Link>
                        </li>
                    )}

                    {permCosts && (
                        <li className="droplink">
                            <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'costs')}>
                                <span className="menu-icon far fa-coins" />
                                <p>
                                    <FormattedMessage id="menuItems.cost" />
                                </p>
                            </Link>
                        </li>
                    )}

                    {vehiclesLink && (
                        <li className="droplink">
                            <Link to={url(props.match, vehiclesLink)}>
                                <span className="menu-icon far fa-car" />
                                <p>
                                    <FormattedMessage id="menuItems.vehicles" />
                                </p>
                            </Link>
                            <ul className="sub-menu hidden">
                                {permVehicles && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'vehicles')}>
                                            <FormattedMessage id="menuItems.vehicles" />
                                        </Link>
                                    </li>
                                )}
                                {permVehicleHistory && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'vehicles/history')}>
                                            <FormattedMessage id="menuItems.vehicleHistory" />
                                        </Link>
                                    </li>
                                )}
                                {permVehicleDocuments && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'vehicle-documents')}>
                                            <FormattedMessage id="menuItems.vehicleDocuments" />
                                        </Link>
                                    </li>
                                )}
                                {permVehicleEquipments && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'vehicle-equipments')}>
                                            <FormattedMessage id="menuItems.vehicleEquipments" />
                                        </Link>
                                    </li>
                                )}
                                {permVehicleEvents && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'vehicle-events')}>
                                            <FormattedMessage id="menuItems.vehicleEvents" />
                                        </Link>
                                    </li>
                                )}
                                {permVehicleFuelings && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'vehicle-fuelings')}>
                                            <FormattedMessage id="menuItems.vehicleFuelings" />
                                        </Link>
                                    </li>
                                )}
                                {permVehiclePositions && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'vehicle-positions')}>
                                            <FormattedMessage id="menuItems.vehiclePositions" />
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}

                    {trailersLink && (
                        <li className="droplink">
                            <Link to={url(props.match, trailersLink)}>
                                <span className="menu-icon far fa-truck" />
                                <p>
                                    <FormattedMessage id="menuItems.trailers" />
                                </p>
                            </Link>
                            <ul className="sub-menu hidden">
                                {permTrailers && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'trailers')}>
                                            <FormattedMessage id="menuItems.trailers" />
                                        </Link>
                                    </li>
                                )}
                                {permTrailerHistory && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'trailers/history')}>
                                            <FormattedMessage id="menuItems.trailerHistory" />
                                        </Link>
                                    </li>
                                )}
                                {permTrailerDocuments && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'trailer-documents')}>
                                            <FormattedMessage id="menuItems.trailerDocuments" />
                                        </Link>
                                    </li>
                                )}
                                {permTrailerEquipments && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'trailer-equipments')}>
                                            <FormattedMessage id="menuItems.trailerEquipments" />
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}

                    {driversLink && (
                        <li className="droplink">
                            <Link to={url(props.match, driversLink)}>
                                <span className="menu-icon far fa-user" />
                                <p>
                                    <FormattedMessage id="menuItems.drivers" />
                                </p>
                            </Link>
                            <ul className="sub-menu hidden">
                                {permDrivers && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'drivers')}>
                                            <FormattedMessage id="menuItems.drivers" />
                                        </Link>
                                    </li>
                                )}
                                {permDriverHistory && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'drivers/history')}>
                                            <FormattedMessage id="menuItems.driverHistory" />
                                        </Link>
                                    </li>
                                )}
                                {permDriverDocuments && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'driver-documents')}>
                                            <FormattedMessage id="menuItems.driverDocuments" />
                                        </Link>
                                    </li>
                                )}
                                {permDriverEquipments && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'driver-equipments')}>
                                            <FormattedMessage id="menuItems.driverEquipments" />
                                        </Link>
                                    </li>
                                )}
                                {permDriverTimesheets && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'driver-timesheets')}>
                                            <FormattedMessage id="menuItems.driverTimesheets" />
                                        </Link>
                                    </li>
                                )}
                                {permDriverSurcharges && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'driver-surcharges')}>
                                            <FormattedMessage id="menuItems.driverSurcharges" />
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}

                    {addressBookLink && (
                        <li className="droplink">
                            <Link to={url(props.match, addressBookLink)}>
                                <span className="menu-icon far fa-address-book" />
                                <p>
                                    <FormattedMessage id="menuItems.addressBook" />
                                </p>
                            </Link>
                            <ul className="sub-menu hidden">
                                {permCustomers && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'customers')}>
                                            <FormattedMessage id="menuItems.customers" />
                                        </Link>
                                    </li>
                                )}
                                {permAddresses && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'customer-addresses')}>
                                            <FormattedMessage id="menuItems.customerAddresses" />
                                        </Link>
                                    </li>
                                )}
                                {permCarriers && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'carriers')}>
                                            <FormattedMessage id="menuItems.carriers" />
                                        </Link>
                                    </li>
                                )}
                                {permBorderCrossings && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'border-crossings')}>
                                            <FormattedMessage id="menuItems.borderCrossings" />
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}

                    {settingsLink && (
                        <li className="droplink">
                            <Link to={url(props.match, settingsLink)}>
                                <span className="menu-icon far fa-cog" />
                                <p>
                                    <FormattedMessage id="menuItems.settings" />
                                </p>
                            </Link>
                            <ul className="sub-menu hidden">
                                {settingTransportsLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, settingTransportsLink)}>
                                            <FormattedMessage id="menuItems.transports" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permTransportConditions && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'transport-conditions')}>
                                                        <FormattedMessage id="menuItems.transportConditions" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permTransportTypes && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'transport-types')}>
                                                        <FormattedMessage id="menuItems.transportTypes" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permCargoTypes && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'cargo-types')}>
                                                        <FormattedMessage id="menuItems.cargoTypes" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permGoodsTypes && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'goods-types')}>
                                                        <FormattedMessage id="menuItems.goodsTypes" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permPackagingTypes && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'packaging-types')}>
                                                        <FormattedMessage id="menuItems.packagingTypes" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permSurchargeTypes && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'surcharge-types')}>
                                                        <FormattedMessage id="menuItems.surchargeTypes" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permTransportScoring && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'transport-scoring')}>
                                                        <FormattedMessage id="menuItems.transportScoring" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {settingsInvoicingLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, settingsInvoicingLink)}>
                                            <FormattedMessage id="menuItems.invoicing" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permTaxRates && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'tax-rates')}>
                                                        <FormattedMessage id="menuItems.taxRates" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permPaymentMethods && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'payment-methods')}>
                                                        <FormattedMessage id="menuItems.paymentMethods" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permInvoiceItemTypes && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'invoice-item-types')}>
                                                        <FormattedMessage id="menuItems.invoiceItemTypes" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {settingsFleetLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, settingsFleetLink)}>
                                            <FormattedMessage id="menuItems.fleet" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permVehicleTypes && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'vehicle-types')}>
                                                        <FormattedMessage id="menuItems.vehicleTypes" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permTrailerTypes && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'trailer-types')}>
                                                        <FormattedMessage id="menuItems.trailerTypes" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permDocumentTypes && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'document-types')}>
                                                        <FormattedMessage id="menuItems.documentTypes" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permEquipments && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'equipments')}>
                                                        <FormattedMessage id="menuItems.equipments" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {settingsAddressBookLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, settingsAddressBookLink)}>
                                            <FormattedMessage id="menuItems.addressBook" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permCompanies && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'companies')}>
                                                        <FormattedMessage id="menuItems.companies" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permCountries && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'countries')}>
                                                        <FormattedMessage id="menuItems.countries" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permCurrencies && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'currencies')}>
                                                        <FormattedMessage id="menuItems.currencies" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permCustomerAddressTypes && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'customer-address-types')}>
                                                        <FormattedMessage id="menuItems.customerAddressTypes" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permCarrierScoring && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'carrier-scoring')}>
                                                        <FormattedMessage id="menuItems.carrierScoring" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {settingsUsersLink && (
                                    <li className="droplink">
                                        <Link to={url(props.match, settingsUsersLink)}>
                                            <FormattedMessage id="menuItems.users" />
                                            <span className="arrow" />
                                        </Link>
                                        <ul className="sub-menu hidden">
                                            {permUsers && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'users')}>
                                                        <FormattedMessage id="menuItems.users" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permUserRoles && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'user-roles')}>
                                                        <FormattedMessage id="menuItems.userRoles" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permUserLogins && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'user-logins')}>
                                                        <FormattedMessage id="menuItems.userLogins" />
                                                    </Link>
                                                </li>
                                            )}
                                            {permBlockedIps && (
                                                <li>
                                                    <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'blocked-ips')}>
                                                        <FormattedMessage id="menuItems.blockedIps" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}

                                {permGeneralSettings && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'general-settings')}>
                                            <FormattedMessage id="menuItems.generalSettings" />
                                        </Link>
                                    </li>
                                )}

                                {permCostTypes && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'cost-types')}>
                                            <FormattedMessage id="menuItems.costTypes" />
                                        </Link>
                                    </li>
                                )}

                                {permSalesTypes && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'sales-types')}>
                                            <FormattedMessage id="menuItems.salesTypes" />
                                        </Link>
                                    </li>
                                )}

                                {permDriversTypes && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'drivers-types')}>
                                            <FormattedMessage id="menuItems.driversTypes" />
                                        </Link>
                                    </li>
                                )}

                                {permDietRates && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'diet-rates')}>
                                            <FormattedMessage id="menuItems.dietRates" />
                                        </Link>
                                    </li>
                                )}

                                {permDriverSurchargeTypes && (
                                    <li>
                                        <Link onClick={() => handleMobileMenuClose()} to={url(props.match, 'driver-surcharge-types')}>
                                            <FormattedMessage id="menuItems.driverSurchargeTypes" />
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default TopMenu
