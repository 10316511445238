import React, { Component } from 'react'
import { convertISODatetimeToCS } from '../../helpers'

/* eslint-disable jsx-a11y/anchor-is-valid */
class WSNotificationLine extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
        }

        this.getCheckIcon = this.getCheckIcon.bind(this)
        this.parseLinks = this.parseLinks.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ loading: nextProps.loading })
    }

    /**
     * Returns check icon and handles click on it (marks notification as read)
     *
     * @param {string|int} timestamp Notification timestamp
     * @returns {*}
     */
    getCheckIcon(timestamp) {
        return (
            <span
                onClick={e => {
                    this.setState({ loading: true })
                    this.props.markAsRead(e, timestamp)
                }}
            >
                {this.state.loading ? <i className="fa fa-spin fa-spinner" /> : <i className="fa fa-check" />}
            </span>
        )
    }

    /**
     * Parses links (if any) into message body
     *
     * @param {object} notification Notification object
     * @returns {*}
     */
    parseLinks(notification) {
        let parsedMessage = notification.message
        if (Boolean(notification.links) && notification.links.length > 0) {
            const links = notification.links.map(link => (
                <a
                    title={link.title || link.text}
                    onClick={e => {
                        e.preventDefault()
                        this.props.redirect(link.href)
                    }}
                >
                    {link.text}
                </a>
            ))

            parsedMessage = notification.message.split(new RegExp(/%l\d/gm))
            for (let i = 0; i < parsedMessage.length; i++) {
                parsedMessage[i] = (
                    <span key={`${notification.timestamp}_${i}`}>
                        {parsedMessage[i]} {links[i]}
                    </span>
                )
            }
        }

        return parsedMessage
    }

    render() {
        return (
            <li key={this.props.notification.timestamp}>
                <div>
                    <div className="pull-right">{this.getCheckIcon(this.props.notification.timestamp)}</div>
                    <p className="task-details bold">{this.parseLinks(this.props.notification)}</p>
                    <p>
                        <span className="badge badge-roundless badge-default pull-right">
                            {convertISODatetimeToCS(this.props.notification.timestamp * 1000)}
                        </span>
                    </p>
                </div>
            </li>
        )
    }
}

export default WSNotificationLine
