import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCostCenterCosts = (year, inclVehicles, inclDrivers, inclTrailers, withEmpty) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        inclVehicles,
        inclDrivers,
        inclTrailers,
        withEmpty
    },
})

export const fetchReportCostCenterCostsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterCostsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCostCenterCostsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Export

export const exportReportCostCenterCosts = (year, filters, inclVehicles, inclDrivers, inclTrailers, withEmpty) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
        inclVehicles,
        inclDrivers,
        inclTrailers,
        withEmpty
    },
})

// Clear

export const clearReportCostCenterCosts = () => ({
    type: actionTypes.CLEAR,
})

// Fetch details

export const fetchReportCostCenterCostsDetails = (id, year, month, inclVehicles, inclDrivers, inclTrailers) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        id,
        year,
        month,
        inclVehicles,
        inclDrivers,
        inclTrailers
    },
})

export const fetchReportCostCenterCostsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterCostsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportCostCenterCostsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Export

export const exportReportCostCenterCostsDetails = (id, year, month, inclVehicles, inclDrivers, inclTrailers) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        id,
        year,
        month,
        inclVehicles,
        inclDrivers,
        inclTrailers
    },
})

// Clear

export const clearReportCostCenterCostsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})
