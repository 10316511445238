export const INVOICING_STATE_NOT_INVOICED = 0
export const INVOICING_STATE_READY = 1
export const INVOICING_STATE_INVOICED = 2

export const TAX_TRANSACTION_TYPE_DOMESTIC = 1
export const TAX_TRANSACTION_TYPE_EU = 2
export const TAX_TRANSACTION_TYPE_OTHER = 3
export const TAX_TRANSACTION_TYPE_NOT_SUBJECT_TO_VAT = 4

export const TEXT_BEFORE_ITEMS_TYPE_ITEMS = 1
export const TEXT_BEFORE_ITEMS_TYPE_TRANSPORTS = 2
