import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'

import { handleCommonListActions, getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableSortButtons, TableFilterInputField, TableFilterSelectField } from '../../../common/table/components'
import { getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as customerAddressesActionCreators from '../actionCreators'
import { getCustomerAddresses } from '../selectors'
import { createGetCountryByIDSelector } from '../../countries/selectors'
import { createGetCustomerByIDSelector } from '../../customers/selectors'
import { createGetCustomerAddressTypeByIDSelector } from '../../customer_address_types/selectors'

import CustomerAddressesPickerRow from './CustomerAddressesPickerRow'

const tableIdentifier = 'customer_addresses_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'customer_addresses_list'
const prerequisites = ['countries', 'customers', 'customer_address_types']

const columns = {
    name: new TableModelColumn({}),
    street: new TableModelColumn({}),
    city: new TableModelColumn({}),
    zipcode: new TableModelColumn({}),
    country: new TableModelColumn({}),
    company: new TableModelColumn({}),
    customer_address_type: new TableModelColumn({}),
}

class CustomerAddressesPicker extends Component {
    onSelectAddress = address => {
        address.customer = this.props.createGetCustomerByIDSelector(address.customer_id)
        address.country = this.props.createGetCountryByIDSelector(address.country_id)
        this.props.onSelectAddress && this.props.onSelectAddress(address)
    }

    componentDidMount() {
        this.props.actions.fetchCustomerAddresses()
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)
    }

    render() {
        const sorting = this.props.tableModel.get('sorting')
        const filters = this.props.tableModel.get('filters')

        return (
            <React.Fragment>
                <table className="table table-striped table-hover table-fixed-header">
                    <thead>
                        <tr>
                            <th className="w-40" />
                            <th className="w-300">
                                <FormattedMessage id="fields.customer" />
                            </th>
                            <th className="w-200">
                                <FormattedMessage id="fields.addressType" />
                            </th>
                            <th className="w-300">
                                <FormattedMessage id="fields.name" />
                                <TableSortButtons identifier="name" sorting={sorting} onChange={this.props.table.changeSorting} />
                            </th>
                            <th className="w-200">
                                <FormattedMessage id="fields.note" />
                            </th>
                            <th className="w-80">
                                <FormattedMessage id="fields.country" />
                            </th>
                            <th className="w-max wm-300">
                                <FormattedMessage id="fields.address" />
                            </th>
                        </tr>
                        <tr className="filters">
                            <th className="w-40" />
                            <th className="w-300">
                                <TableFilterSelectField
                                    identifier="customer_id"
                                    type={TableModelColumnFilteringType.equal}
                                    filters={filters}
                                    onChange={this.props.table.changeFilter}
                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('customers'))}
                                />
                            </th>
                            <th className="w-200">
                                <TableFilterSelectField
                                    identifier="customer_address_type_id"
                                    type={TableModelColumnFilteringType.equal}
                                    filters={filters}
                                    onChange={this.props.table.changeFilter}
                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('customer_address_types'))}
                                />
                            </th>
                            <th className="w-300">
                                <TableFilterInputField
                                    identifier="name"
                                    type={TableModelColumnFilteringType.string}
                                    filters={filters}
                                    onChange={this.props.table.changeFilter}
                                />
                            </th>
                            <th className="w-200">
                                <TableFilterInputField
                                    identifier="note"
                                    type={TableModelColumnFilteringType.string}
                                    filters={filters}
                                    onChange={this.props.table.changeFilter}
                                />
                            </th>
                            <th className="w-80">
                                <TableFilterSelectField
                                    identifier="country_id"
                                    type={TableModelColumnFilteringType.equal}
                                    filters={filters}
                                    onChange={this.props.table.changeFilter}
                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('countries'), 'id', 'iso_code')}
                                />
                            </th>
                            <th className="w-max wm-300">
                                <TableFilterInputField
                                    identifier="address_full"
                                    type={TableModelColumnFilteringType.string}
                                    filters={filters}
                                    onChange={this.props.table.changeFilter}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.customerAddresses.data
                            .valueSeq()
                            .map(row => (
                                <CustomerAddressesPickerRow
                                    key={`row-${row.id}`}
                                    data={row}
                                    onSelectAddress={this.onSelectAddress}
                                    country={this.props.createGetCountryByIDSelector(row.country_id)}
                                    customer={this.props.createGetCustomerByIDSelector(row.customer_id)}
                                    type={this.props.createGetCustomerAddressTypeByIDSelector(row.customer_address_type_id)}
                                />
                            ))}
                    </tbody>
                </table>

                <div className="wp-100 pull-left">
                    <div className="pull-left m-l-xxs m-t-md">
                        <FormattedMessage id="pagination.totalRecords" />:{' '}
                        {getListItemsCountMessage(
                            clientSideItemsPerPage,
                            this.props.customerAddresses.count,
                            this.props.tableModel.getIn(['pagination', 'totalCount'])
                        )}
                    </div>

                    <ReactPaginate
                        containerClassName="pagination"
                        breakLabel={<span className="disabled">...</span>}
                        activeClassName="active"
                        pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.customerAddresses.count, clientSideItemsPerPage)}
                        pageRangeDisplayed={10}
                        marginPagesDisplayed={2}
                        forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                        onPageChange={this.props.table.changePage}
                        previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                        nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                    />
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        customerAddresses: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getCustomerAddresses,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
        createGetCustomerByIDSelector: id => createGetCustomerByIDSelector(id)(state),
        createGetCustomerAddressTypeByIDSelector: id => createGetCustomerAddressTypeByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...customerAddressesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(CustomerAddressesPicker))
