import { Record } from 'immutable'

export class VehicleEvent extends Record({
    id: undefined,
    vehicle_id: undefined,
    trailer_id: undefined,
    type_id: undefined,
    driver_id: undefined,
    dispatcher_id: undefined,
    transport_id: undefined,
    transport_point_id: undefined,
    datetime_start: undefined,
    datetime_end: undefined,
    gps_lat: undefined,
    gps_lng: undefined,
    km: undefined,
    is_trailer_loaded: undefined,
    transport_point: undefined,
}) {}
