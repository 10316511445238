import { createSelector } from 'reselect'

export const getDriverEquipments = state => state.driverEquipments.getIn(['all', 'objects'])

export const createGetDriverEquipmentByIDSelector = id => createSelector([getDriverEquipments], driverEquipments => driverEquipments.get(String(id)))

const getCurrentDriverEquipmentStatus = state => state.driverEquipments.get('current')

export const createGetCurrentDriverEquipmentStatusSelector = () => createSelector([getCurrentDriverEquipmentStatus], status => status)

export const getDriverEquipmentsFetching = state => state.driverEquipments.getIn(['all', 'fetching'])

export const getDriverEquipmentDeleting = state => state.driverEquipments.getIn(['deletion', 'inProgress'])

export const getDriverEquipmentDeleteError = state => state.driverEquipments.getIn(['deletion', 'error'])

const getDuplicity = state => state.driverEquipments.getIn(['all', 'duplicity'])

export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)
