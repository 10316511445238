import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import { Modal } from 'react-bootstrap'

import { Form, InputField, SelectField, SubmitButton } from '../../../common/form'
import { url, handleCommonEditActions, validationMessage, getPrerequisitesArray } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as customerContactsActionCreators from '../actionCreators'
import { createGetCustomerContactByIDSelector, createGetCurrentCustomerContactStatusSelector, createGetDuplicitySelector } from '../selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'customer_contacts_edit'
const prerequisites = ['customers']

class CustomerContactsEditForm extends Component {
    defaultValues = {}

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
        contact: {},
        gps: {},
        modalDuplicity: {
            show: false,
            duplicity: null,
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave(false)
        })
    }

    handleSave = ignoreDuplicity => {
        this.props.actions.saveCustomerContact(Object.assign(this.state.values, this.state.contact, this.state.gps), ignoreDuplicity)
    }

    duplicityFoundCallback = duplicity => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()
        this.handleSave(true)
    }

    successCallback = () => {
        this.props.successCallback ? this.props.successCallback() : this.props.redirect(url(this.props.match, 'customer-contacts'))
    }

    backCallback = () => {
        this.props.backCallback ? this.props.backCallback() : this.props.redirect(url(this.props.match, 'customer-contacts'))
    }

    componentDidMount() {
        this.props.customerContactID && this.props.actions.fetchCustomerContact(this.props.customerContactID)
        this.props.actions.fetchPrerequisites()

        if (this.props.defaultValues) {
            this.setValues(this.props.defaultValues)
        }
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback, this.duplicityFoundCallback)

        if (
            nextProps.customerContact &&
            (JSON.stringify(nextProps.customerContact) !== JSON.stringify(this.props.customerContact) || !this.state.values.id)
        ) {
            this.setValues(nextProps.customerContact.toJS())
        }
    }

    render() {
        const duplicity = this.props.duplicity
        const perms = this.props.PERMS ? this.props.PERMS : PERMS

        return (
            <div>
                <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                    <PermissionsCheck hasAny={Object.values(perms)}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                            isEdit={Boolean(this.props.customerContact)}
                        >
                            <InputField
                                id="name"
                                label={this.props.intl.formatMessage({ id: 'fields.name' })}
                                formGroupClassName="form-group-bigger"
                            />
                            <div className={this.props.hiddenFields && this.props.hiddenFields.indexOf('customer_id') !== -1 ? 'hidden' : ''}>
                                <SelectField
                                    id="customer_id"
                                    label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('customers'))}
                                    nullable
                                />
                            </div>
                            <InputField id="position" label={this.props.intl.formatMessage({ id: 'fields.position' })} />
                            <div className="row">
                                <div className="col-md-6">
                                    <InputField id="email" label={this.props.intl.formatMessage({ id: 'fields.email' })} />
                                </div>
                                <div className="col-md-6">
                                    <InputField id="phone" label={this.props.intl.formatMessage({ id: 'fields.phone' })} />
                                </div>
                            </div>
                            <InputField id="note" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />

                            <div className="btns-form">
                                <button onClick={this.backCallback} className="btn btn-addon btn-default">
                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                </button>
                                <SubmitButton
                                    perms={Object.values(perms)}
                                    isEdit={Boolean(this.props.customerContact)}
                                    className="btn-addon pull-right"
                                />
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>

                <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} bsSize="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="confirmDialog.duplicityFound.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormattedMessage id="confirmDialog.duplicityFound.message" />
                        {duplicity && (
                            <div>
                                <div className="row m-t-lg">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.customer" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.customer}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.name" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.name}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.position" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.position}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.email" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.email}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.phone" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.phone}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.note" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.note}</strong>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                            <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                        </button>
                        <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                            <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        customerContact: createGetCustomerContactByIDSelector(ownProps.customerContactID)(state),
        status: createGetCurrentCustomerContactStatusSelector()(state),
        duplicity: createGetDuplicitySelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...customerContactsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CustomerContactsEditForm)
)
