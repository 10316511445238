import { createSelector } from 'reselect'

export const getTrailerTypes = state => state.trailerTypes.getIn(['all', 'objects'])

export const createGetTrailerTypeByIDSelector = id => createSelector([getTrailerTypes], trailerTypes => trailerTypes.get(String(id)))

const getCurrentTrailerTypeStatus = state => state.trailerTypes.get('current')

export const createGetCurrentTrailerTypeStatusSelector = () => createSelector([getCurrentTrailerTypeStatus], status => status)

export const getTrailerTypeDeleting = state => state.trailerTypes.getIn(['deletion', 'inProgress'])

export const getTrailerTypeDeleteError = state => state.trailerTypes.getIn(['deletion', 'error'])
