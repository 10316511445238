import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import moment from 'moment'
import { FormattedMessage, injectIntl } from 'react-intl'

import { DispatchersBox } from '../../../common/colored_box'
import * as formatters from '../../../common/formatters'

/* eslint-disable jsx-a11y/anchor-is-valid */

export const MapPageVehiclesListRowVehiclePosition = props => {
    let title = props.intl ? props.intl.formatMessage({ id: 'fields.informationUnavailable' }) : 'Information is unavailable'
    let className = 'text-default'

    if (props.vehiclePosition) {
        title =
            props.vehiclePosition.speed > 0
                ? `${props.intl ? props.intl.formatMessage({ id: 'fields.vehicleIsMovingAt' }) : 'Vehicle is moving at'} ${formatters.speedFormatter(
                      props.vehiclePosition.speed
                  )}`
                : `${props.intl ? props.intl.formatMessage({ id: 'fields.vehicleIsStanding' }) : 'Vehicle is standing'}`
        className = props.vehiclePosition.speed > 0 ? 'text-success' : 'text-danger'
    }

    const tooltip = (
        <Tooltip id="tooltip">
            <strong>{title}</strong>
        </Tooltip>
    )

    return (
        <span className={props.className}>
            <OverlayTrigger placement="top" overlay={tooltip} className="m-r-xxs">
                <i className={`fas fa-circle ${className}`} />
            </OverlayTrigger>
        </span>
    )
}

export const MapPageVehiclesListRowTime = props => {
    let className = 'text-default'

    if (props.time) {
        const minutesDiff = moment().diff(moment(props.time), 'minutes')
        className = minutesDiff > 120 ? 'text-danger' : 'text-success'
    }

    const titleUnavailable = props.intl ? props.intl.formatMessage({ id: 'fields.informationUnavailable' }) : 'Information is unavailable'

    const tooltip = (
        <Tooltip id="tooltip">
            {props.time ? (
                <span>
                    <FormattedMessage id="fields.updatedAt" />:<br />
                    <strong>{formatters.datetimeFormatter(props.time)}</strong>
                </span>
            ) : (
                titleUnavailable
            )}
        </Tooltip>
    )

    return (
        <span className={props.className}>
            <OverlayTrigger placement="top" overlay={tooltip}>
                <i className={`fas fa-clock ${className}`} />
            </OverlayTrigger>
        </span>
    )
}

export const MapPageVehiclesListRowNavigation = props => {
    let className = 'text-default'

    if (props.time) {
        const minutesDiff = moment().diff(moment(props.time), 'minutes')
        className = minutesDiff > 120 ? 'text-danger' : 'text-success'
    }

    const titleUnavailable = props.intl ? props.intl.formatMessage({ id: 'fields.informationUnavailable' }) : 'Information is unavailable'

    const tooltip = (
        <Tooltip id="tooltip">
            {props.time ? (
                <span>
                    <FormattedMessage id="fields.etaUpdatedAt" />:<br />
                    <strong>{formatters.datetimeFormatter(props.time)}</strong>
                </span>
            ) : (
                titleUnavailable
            )}
        </Tooltip>
    )

    return (
        <span className={props.className}>
            <OverlayTrigger placement="top" overlay={tooltip}>
                <i className={`fas fa-location-arrow ${className}`} />
            </OverlayTrigger>
        </span>
    )
}

export const MapPageVehiclesListRowTrailerIconState = props => {
    let title = props.intl ? props.intl.formatMessage({ id: 'fields.informationUnavailable' }) : 'Information is unavailable'
    let className = 'text-default'

    if (props.vehicleEvent && props.vehicleEvent.trailer) {
        title =
            props.vehicleEvent.is_trailer_loaded > 0
                ? `${props.intl ? props.intl.formatMessage({ id: 'fields.trailerLoaded' }) : 'Trailer is loaded'}`
                : `${props.intl ? props.intl.formatMessage({ id: 'fields.trailerEmpty' }) : 'Trailer is empty'}`
        className = props.vehicleEvent.is_trailer_loaded > 0 ? 'text-danger' : 'text-success'
    }
    const tooltip = (
        <Tooltip id="tooltip">
            <strong>{title}</strong>
        </Tooltip>
    )

    return (
        <span className={props.className}>
            <OverlayTrigger placement="top" overlay={tooltip} className="m-r-xxs">
                <i className={`fas fa-truck ${className}`} />
            </OverlayTrigger>
        </span>
    )
}

export const MapPageVehiclesListRowTrailerIconDamaged = props => {
    let title = props.intl ? props.intl.formatMessage({ id: 'fields.informationUnavailable' }) : 'Information is unavailable'
    let className = 'text-default'

    if (props.vehicleEvent && props.vehicleEvent.trailer) {
        title =
            props.vehicleEvent.trailer.is_damage > 0
                ? `${props.intl ? props.intl.formatMessage({ id: 'fields.trailerDamaged' }) : 'Trailer is damaged'}`
                : `${props.intl ? props.intl.formatMessage({ id: 'fields.trailerOk' }) : 'Trailer is OK'}`
        className = props.vehicleEvent.trailer.is_damage > 0 ? 'text-danger' : 'text-success'
    }

    const tooltip = (
        <Tooltip id="tooltip">
            <strong>{title}</strong>
        </Tooltip>
    )

    return (
        <span className={props.className}>
            <OverlayTrigger placement="top" overlay={tooltip} className="m-r-xxs">
                <i className={`fas fa-exclamation-circle ${className}`} />
            </OverlayTrigger>
        </span>
    )
}

export const MapPageVehiclesListRowTrailerIconDamagedSecurity = props => {
    let title = props.intl ? props.intl.formatMessage({ id: 'fields.informationUnavailable' }) : 'Information is unavailable'
    let className = 'text-default'

    const event = props.vehicleEvent
    const trailerType = event && event.trailer && event.trailer.trailer_type
    if (event && event.trailer && trailerType && trailerType.is_secured) {
        title =
            event.trailer.is_security_damage > 0
                ? `${props.intl ? props.intl.formatMessage({ id: 'fields.securityDamaged' }) : 'Security is damaged'}`
                : `${props.intl ? props.intl.formatMessage({ id: 'fields.securityOk' }) : 'Security is OK'}`
        className = event.trailer.is_security_damage > 0 ? 'text-danger' : 'text-success'
    }

    if (trailerType && !trailerType.is_secured) {
        title = props.intl ? props.intl.formatMessage({ id: 'fields.trailerIsNotSecured' }) : 'Trailer is not cooled'
    }

    const tooltip = (
        <Tooltip id="tooltip">
            <strong>{title}</strong>
        </Tooltip>
    )

    return (
        <span className={props.className}>
            <OverlayTrigger placement="top" overlay={tooltip} className="m-r-xxs">
                <i className={`fas fa-lock ${className}`} />
            </OverlayTrigger>
        </span>
    )
}

export const MapPageVehiclesListRowTrailerIconDamagedCooling = props => {
    let title = props.intl ? props.intl.formatMessage({ id: 'fields.informationUnavailable' }) : 'Information is unavailable'
    let className = 'text-default'

    const event = props.vehicleEvent
    const trailerType = event && event.trailer && event.trailer.trailer_type
    if (event && event.trailer && trailerType && trailerType.is_cooled) {
        title =
            event.trailer.is_cooling_damage > 0
                ? `${props.intl ? props.intl.formatMessage({ id: 'fields.coolingDamaged' }) : 'Cooling is damaged'}`
                : `${props.intl ? props.intl.formatMessage({ id: 'fields.coolingOk' }) : 'Cooling is OK'}`
        className = event.trailer.is_cooling_damage > 0 ? 'text-danger' : 'text-success'
    }

    if (trailerType && !trailerType.is_cooled) {
        title = props.intl ? props.intl.formatMessage({ id: 'fields.trailerIsNotCooled' }) : 'Trailer is not cooled'
    }

    const tooltip = (
        <Tooltip id="tooltip">
            <strong>{title}</strong>
        </Tooltip>
    )

    return (
        <span className={props.className}>
            <OverlayTrigger placement="top" overlay={tooltip} className="m-r-xxs">
                <i className={`far fa-snowflake ${className}`} />
            </OverlayTrigger>
        </span>
    )
}

const MapPageVehiclesListRow = props => (
    <tr>
        <td className={`w-2000 ${props.selected ? 'selected' : ''}`}>
            <a href="#" onClick={() => props.handleVehicleEventsRowClick(props.vehicleEvent)}>
                <div className="vehicle">
                    <div>
                        <DispatchersBox dispatcher={props.vehicleEvent.vehicle && props.vehicleEvent.dispatcher}>
                            {props.vehicleEvent.vehicle ? props.vehicleEvent.vehicle.name : '-'}
                        </DispatchersBox>
                    </div>
                    <div className="m-t-xxs">
                        {props.vehicleEvent.trailer && <DispatchersBox dispatcher={null}>{props.vehicleEvent.trailer.name}</DispatchersBox>}
                    </div>
                </div>
                <div className="driver">
                    {props.vehicleEvent.driver ? (
                        <React.Fragment>
                            <span className="m-r-xs">{props.vehicleEvent.driver.getShortFullName()}</span>
                            {props.vehicleEvent.driverTimes && (
                                <span>
                                    <span className="text-gray-light">
                                        {`${formatters.timeFromSecondsFormatter(props.vehicleEvent.driverTimes.day_drive_time)} / `}
                                    </span>
                                    {formatters.timeFromSecondsFormatter(props.vehicleEvent.driverTimes.day_drive_time_remaining)}
                                </span>
                            )}
                        </React.Fragment>
                    ) : (
                        '-'
                    )}
                    <br />
                    <span className="text-gray-light">{props.vehicleEvent.dispatcher ? props.vehicleEvent.dispatcher.getShortFullName() : '-'}</span>
                </div>
                <span className="vehicle-state">
                    <MapPageVehiclesListRowVehiclePosition
                        vehiclePosition={props.vehicleEvent.vehiclePosition}
                        intl={props.intl}
                        className="m-r-xxs"
                    />
                    <MapPageVehiclesListRowTime
                        time={props.vehicleEvent.vehiclePosition ? props.vehicleEvent.vehiclePosition.datetime : null}
                        intl={props.intl}
                    />
                </span>
                <span className="trailer-state">
                    <MapPageVehiclesListRowTrailerIconState vehicleEvent={props.vehicleEvent} intl={props.intl} className="m-r-xxs" />
                    <MapPageVehiclesListRowTrailerIconDamaged vehicleEvent={props.vehicleEvent} intl={props.intl} className="m-r-xxs" />
                    <MapPageVehiclesListRowTrailerIconDamagedSecurity vehicleEvent={props.vehicleEvent} intl={props.intl} className="m-r-xxs" />
                    <MapPageVehiclesListRowTrailerIconDamagedCooling vehicleEvent={props.vehicleEvent} intl={props.intl} />
                </span>
            </a>
        </td>
    </tr>
)

export default injectIntl(MapPageVehiclesListRow)
