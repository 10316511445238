import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError } from '../../common/api'
import { userRoleSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { UserRole } from './model'

// Fetch

const fetchUserRolesEpic = (action$, store) => {
    const tableIdentifier = 'user_roles_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `user-roles/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(userRoleSchema)
                        )

                        const userRoles = normalizedEntitiesToRecordMap(normalizedData.entities.user_roles, UserRole, normalizedData.result)

                        const observables = [Observable.of(actionCreators.fetchUserRolesFulfilled(userRoles))]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchUserRolesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchUserRoleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().userRoles.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `user-roles/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, userRoleSchema)

                        const userRoles = normalizedEntitiesToRecordMap(normalizedData.entities.user_roles, UserRole)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchUserRolesFulfilled(userRoles)),
                            Observable.of(actionCreators.fetchUserRoleFulfilled())
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchUserRolesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveUserRoleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().userRoles.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload)

            let path = `user-roles`
            let method = 'POST'

            if (values.id) {
                path = `user-roles/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, userRoleSchema)

                        const userRoles = normalizedEntitiesToRecordMap(normalizedData.entities.user_roles, UserRole)
                        const userRole = userRoles.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveUserRoleFulfilled(userRole)))
                    })
                    .catch(error => Observable.of(actionCreators.saveUserRoleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteUserRoleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().userRoles.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `user-roles/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteUserRoleFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteUserRoleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Duplicate

const duplicateUserRoleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DUPLICATE)
        .filter(() => !store.getState().userRoles.getIn(['duplication', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DUPLICATE_STARTED,
                }),
                request({
                    path: `user-roles/${action.payload}/duplicate`,
                    method: 'POST',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, userRoleSchema)

                        const userRoles = normalizedEntitiesToRecordMap(normalizedData.entities.user_roles, UserRole)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchUserRolesFulfilled(userRoles)),
                            Observable.of(actionCreators.duplicateUserRoleFulfilled(userRoles.valueSeq().first()))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.duplicateUserRoleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DUPLICATE_CANCELED))
            )
        )

export default [fetchUserRolesEpic, fetchUserRoleEpic, saveUserRoleEpic, deleteUserRoleEpic, duplicateUserRoleEpic]
