import { TableModelColumnType, TableModelSortDirection, TableModelColumnFilteringType } from './model'

const filterData = (data, filteringConfiguration) => {
    if (!filteringConfiguration) {
        return data
    }

    for (const columnIdentifier of filteringConfiguration.keys()) {
        const columnFilter = filteringConfiguration.get(columnIdentifier)
        const columnFilterType = columnFilter.get('type')
        const columnFilterValue = String(columnFilter.get('value')).toLowerCase()
        const columnCustomFilter = columnFilter.get('custom_filter')

        if (!columnFilterValue) {
            continue
        }

        data = data.filter(row => {
            if (columnFilterType === TableModelColumnFilteringType.custom) {
                return columnCustomFilter(row, columnFilterValue)
            }

            let value = row.get(columnIdentifier)

            if (!value) {
                return false
            }

            value = String(value).toLowerCase()

            switch (columnFilterType) {
                case TableModelColumnFilteringType.stringFromStart:
                    return value.startsWith(columnFilterValue)
                case TableModelColumnFilteringType.string:
                    return value.indexOf(columnFilterValue) >= 0
                case TableModelColumnFilteringType.equal:
                    return value === columnFilterValue
                default:
                    return true
            }
        })
    }

    return data
}

const sortData = (data, columns, sortingConfiguration) => {
    if (!sortingConfiguration) {
        return data
    }

    const column = sortingConfiguration.get('column')
    const columnType = columns[column].type
    const ascending = sortingConfiguration.get('direction') === TableModelSortDirection.ascending

    switch (columnType) {
        case TableModelColumnType.string:
            data = data.sort((first, second) => first.get(column).localeCompare(second.get(column)))
            break
        case TableModelColumnType.float:
            data = data.sort((first, second) => first.get(column) - second.get(column))
            break
        case TableModelColumnType.date:
            data = data.sort((first, second) => first.get(column).localeCompare(second.get(column)))
            break
        default:
            break
    }

    if (!ascending) {
        data = data.reverse()
    }

    return data
}

const paginateData = (data, page, itemsPerPage) => data.slice(page * itemsPerPage, (page + 1) * itemsPerPage)

export function prepareTableData({ data, tableModelState, columns, itemsPerPage }) {
    let totalCount

    if (tableModelState !== undefined) {
        const filteringConfiguration = tableModelState.get('filters')
        const sortingConfiguration = tableModelState.get('sorting')
        const page = tableModelState.getIn(['pagination', 'current'])

        data = filterData(data, filteringConfiguration)
        data = sortData(data, columns, sortingConfiguration)

        totalCount = data.size

        if (itemsPerPage !== undefined) {
            data = paginateData(data, page, itemsPerPage)
        }
    }

    return {
        data,
        count: totalCount,
    }
}
