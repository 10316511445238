import { Map } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    userID: localStorage.getItem('userID'),
    inProgress: false,
    findTokenInProgress: false,
    error: null,
    permissions: null,
    settings: null,
    legends: null,
})

function auth(state = initialState, action) {
    switch (action.type) {
        // Login
        case actionTypes.LOGIN_STARTED:
            return state.mergeDeep({
                inProgress: true,
                error: null,
            })
        case actionTypes.LOGIN_FULFILLED:
            localStorage.setItem('accessToken', action.payload.accessToken)
            localStorage.setItem('refreshToken', action.payload.refreshToken)
            localStorage.setItem('userID', action.payload.userID)

            return state.mergeDeep({
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                userID: action.payload.userID,
                permissions: action.payload.permissions,
                settings: action.payload.settings,
                legends: action.payload.legends,
                inProgress: false,
            })
        case actionTypes.LOGIN_REJECTED:
            return state.mergeDeep({
                inProgress: false,
                error: action.payload,
            })

        // Logout
        case actionTypes.LOGOUT_STARTED:
            return state.mergeDeep({
                inProgress: true,
                error: null,
            })
        case actionTypes.LOGOUT_FULFILLED:
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')

            return state.mergeDeep({
                permissions: null,
                settings: null,
                accessToken: null,
                refreshToken: null,
                inProgress: false,
            })
        case actionTypes.LOGOUT_REJECTED:
            return state.mergeDeep({
                inProgress: false,
                error: action.payload,
            })
        default:
            return state

        // Permissions
        case actionTypes.PERMISSIONS_FETCH_STARTED:
            return state.mergeDeep({
                inProgress: true,
                error: null,
            })
        case actionTypes.PERMISSIONS_FETCH_FULFILLED:
            return state.mergeDeep({
                permissions: action.payload.permissions,
                settings: action.payload.settings,
                legends: action.payload.legends,
                inProgress: false,
            })
        case actionTypes.PERMISSIONS_FETCH_REJECTED:
            return state.mergeDeep({
                inProgress: false,
                error: action.payload,
            })

        // Forgotten password
        case actionTypes.FORGOTTEN_PASSWORD_STARTED:
            return state.mergeDeep({
                inProgress: true,
                error: null,
            })
        case actionTypes.FORGOTTEN_PASSWORD_FULFILLED:
            return state.mergeDeep({
                inProgress: false,
            })
        case actionTypes.FORGOTTEN_PASSWORD_REJECTED:
            return state.mergeDeep({
                inProgress: false,
                error: action.payload,
            })

        // Find token
        case actionTypes.FIND_TOKEN_STARTED:
            return state.mergeDeep({
                findTokenInProgress: true,
                error: null,
            })
        case actionTypes.FIND_TOKEN_FULFILLED:
            return state.mergeDeep({
                findTokenInProgress: false,
            })
        case actionTypes.FIND_TOKEN_REJECTED:
            return state.mergeDeep({
                findTokenInProgress: false,
                error: action.payload,
            })

        // Reset password
        case actionTypes.RESET_PASSWORD_STARTED:
            return state.mergeDeep({
                inProgress: true,
                error: null,
            })
        case actionTypes.RESET_PASSWORD_FULFILLED:
            return state.mergeDeep({
                inProgress: false,
            })
        case actionTypes.RESET_PASSWORD_REJECTED:
            return state.mergeDeep({
                inProgress: false,
                error: action.payload,
            })
    }
}

export default auth
