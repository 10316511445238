import { createSelector } from 'reselect'

export const getCompanyBankAccounts = state => state.companyBankAccounts.getIn(['all', 'objects'])

export const createGetCompanyBankAccountByIDSelector = id =>
    createSelector([getCompanyBankAccounts], companyBankAccounts => companyBankAccounts.get(String(id)))

const getCurrentCompanyBankAccountStatus = state => state.companyBankAccounts.get('current')

export const createGetCurrentCompanyBankAccountStatusSelector = () => createSelector([getCurrentCompanyBankAccountStatus], status => status)

export const getCompanyBankAccountDeleting = state => state.companyBankAccounts.getIn(['deletion', 'inProgress'])

export const getCompanyBankAccountDeleteError = state => state.companyBankAccounts.getIn(['deletion', 'error'])
