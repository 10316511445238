import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportDriverResults = (year, filters) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        filters
    },
})

export const fetchReportDriverResultsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportDriverResultsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportDriverResultsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportDriverResults = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportDriverResults = (year, filters) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
    },
})

// Fetch details

export const fetchReportDriverResultsDetails = (year, driverId, month, filters) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        year,
        driverId,
        month,
        filters,
    },
})

export const fetchReportDriverResultsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportDriverResultsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportDriverResultsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportDriverResultsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportDriverResultsDetails = (year, driverId, month, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        year,
        driverId,
        month,
        filters,
    },
})
