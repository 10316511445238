import { Record } from 'immutable'

export class Equipment extends Record({
    id: undefined,
    code: undefined,
    name: undefined,
    notify: undefined,
    notify_days: undefined,
    notify_in_board: undefined,
    notify_in_board_days: undefined,
    default_validity: undefined,
}) {}
