import { Observable } from 'rxjs'
import { Seq, List } from 'immutable'

import { request, parseAPIError } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Login

const login = (action$, store) =>
    action$
        .ofType(actionTypes.LOGIN)
        .filter(() => !store.getState().auth.get('inProgress'))
        .switchMap(action => {
            const values = {
                grant_type: 'password',
                client_id: 1,
                username: action.payload.email,
                password: action.payload.password,
                language: action.payload.language,
                scope: '',
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.LOGIN_STARTED,
                }),
                request(
                    {
                        path: 'oauth/token',
                        method: 'POST',
                        body: values,
                    },
                    false
                )
                    .flatMap(loginResponse => {
                        const accessToken = loginResponse.xhr.response.access_token
                        const refreshToken = loginResponse.xhr.response.refresh_token

                        return request(
                            {
                                path: 'auth/permissions',
                                method: 'GET',
                            },
                            true,
                            accessToken
                        ).switchMap(permissionsResponse => {
                            const response = permissionsResponse.xhr.response
                            const userID = response.userID
                            const permissions = new List(response.permissions)
                            const settings = Seq(response.settings)
                            const legends = Seq(response.legends)

                            return Observable.of(actionCreators.loginFulfilled(accessToken, refreshToken, userID, permissions, settings, legends))
                        })
                    })
                    .catch(error => Observable.of(actionCreators.loginRejected(error.response)))
                    .takeUntil(action$.ofType(actionTypes.LOGIN_CANCELLED))
            )
        })

// Logout

const logout = (action$, store) =>
    action$
        .ofType(actionTypes.LOGOUT)
        .filter(() => !store.getState().auth.get('inProgress'))
        .switchMap(() =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.LOGOUT_STARTED,
                }),
                request({
                    path: 'auth/logout',
                    method: 'POST',
                })
                    .switchMap(() => Observable.of(actionCreators.logoutFulfilled()))
                    .catch(error => Observable.of(actionCreators.logoutRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.LOGOUT_CANCELLED))
            )
        )

const permissions = (action$, store) =>
    action$
        .ofType(actionTypes.PERMISSIONS_FETCH)
        .filter(() => !store.getState().auth.get('inProgress'))
        .switchMap(() =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.PERMISSIONS_FETCH_STARTED,
                }),
                request({
                    path: 'auth/permissions',
                    method: 'GET',
                })
                    .switchMap(response => {
                        const fetchedPermissions = new List(response.xhr.response.permissions)
                        const fetchedSettings = Seq(response.xhr.response.settings)
                        const fetchedLegends = Seq(response.xhr.response.legends)

                        return Observable.of(actionCreators.permissionsFetchFulfilled(fetchedPermissions, fetchedSettings, fetchedLegends))
                    })
                    .catch(error => {
                        return Observable.concat(
                            Observable.of(actionCreators.permissionsFetchRejected(parseAPIError(error))),
                            Observable.of(actionCreators.logoutFulfilled())
                        )
                    })
                    .takeUntil(action$.ofType(actionTypes.PERMISSIONS_FETCH_CANCELLED))
            )
        )

// Forgotten password

const forgottenPassword = (action$, store) =>
    action$
        .ofType(actionTypes.FORGOTTEN_PASSWORD)
        .filter(() => !store.getState().auth.get('inProgress'))
        .switchMap(action => {
            const values = {
                username: action.payload.email,
                language: action.payload.language,
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FORGOTTEN_PASSWORD_STARTED,
                }),
                request(
                    {
                        path: 'auth/reset-password',
                        method: 'POST',
                        body: values,
                    },
                    false
                )
                    .switchMap(() => Observable.of(actionCreators.forgottenPasswordFulfilled()))
                    .catch(error => Observable.of(actionCreators.forgottenPasswordRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FORGOTTEN_PASSWORD_CANCELLED))
            )
        })

// Find token

const findToken = (action$, store) =>
    action$
        .ofType(actionTypes.FIND_TOKEN)
        .filter(() => !store.getState().auth.get('findTokenInProgress'))
        .switchMap(action => {
            const token = action.payload

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FIND_TOKEN_STARTED,
                }),
                request(
                    {
                        path: `auth/reset-password/${token}`,
                        method: 'GET',
                        body: {},
                    },
                    false
                )
                    .switchMap(() => Observable.of(actionCreators.findTokenFulfilled()))
                    .catch(error => Observable.of(actionCreators.findTokenRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FIND_TOKEN_CANCELLED))
            )
        })

// Reset password

const resetPassword = (action$, store) =>
    action$
        .ofType(actionTypes.RESET_PASSWORD)
        .filter(() => !store.getState().auth.get('inProgress'))
        .switchMap(action => {
            const values = {
                password: action.payload.password,
                password_confirmation: action.payload.password_confirmation,
            }
            const token = action.payload.token

            return Observable.concat(
                Observable.of({
                    type: actionTypes.RESET_PASSWORD_STARTED,
                }),
                request(
                    {
                        path: `auth/reset-password/${token}`,
                        method: 'POST',
                        body: values,
                    },
                    false
                )
                    .switchMap(() => Observable.of(actionCreators.resetPasswordFulfilled()))
                    .catch(error => Observable.of(actionCreators.resetPasswordRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.RESET_PASSWORD_CANCELLED))
            )
        })

export default [login, logout, permissions, forgottenPassword, findToken, resetPassword]
