import * as actionTypes from './actionTypes'

// Fetch

export const fetchCustomerContacts = () => ({
    type: actionTypes.FETCH,
})

export const fetchCustomerContactsFulfilled = customerContacts => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: customerContacts,
})

export const fetchCustomerContactsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCustomerContactsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCustomerContact = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCustomerContactFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCustomerContactRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCustomerContactCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCustomerContact = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveCustomerContactFulfilled = customer => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: customer,
})

export const saveCustomerContactRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveCustomerContactDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteCustomerContact = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCustomerContactFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCustomerContactRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportCustomerContacts = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
