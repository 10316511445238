import { Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
        duplicity: null,
    }),
    current: Map({
        fetching: false,
        saving: false,
        error: null,
        id: null,
    }),
    deletion: Map({
        inProgress: false,
        error: null,
    }),
    restoring: Map({
        inProgress: false,
        error: null,
    }),
    infoByVAT: Map({
        data: null,
        inProgress: false,
        error: null,
    }),
    contacts: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    checkingDuplicity: Map({
        data: {},
        inProgress: false,
        error: null,
    }),
})

function carriers(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], action.payload)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // One
        case actionTypes.FETCH_ONE_STARTED:
            return state.setIn(['current', 'fetching'], true)
        case actionTypes.FETCH_ONE_FULFILLED:
        case actionTypes.FETCH_ONE_REJECTED:
        case actionTypes.FETCH_ONE_CANCELLED:
            return state.setIn(['current', 'fetching'], false)

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], true)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicity'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                .setIn(['current', 'id'], action.payload.get('id'))
                    .setIn(['all', 'duplicity'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], action.payload)
                    .setIn(['all', 'duplicity'], null)
            })
        case actionTypes.SAVE_DUPLICITY_FOUND:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicity'], action.payload)
            })

        // Delete
        case actionTypes.DELETE_STARTED:
            return state.setIn(['deletion', 'inProgress'], true)
        case actionTypes.DELETE_FULFILLED:
            return state.deleteIn(['all', 'objects', action.payload]).withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], null)
            })
        case actionTypes.DELETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], action.payload)
            })

        // Restore
        case actionTypes.RESTORE_STARTED:
            return state.setIn(['restoring', 'inProgress'], true)
        case actionTypes.RESTORE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['restoring', 'inProgress'], false)
                    .setIn(['restoring', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.RESTORE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['restoring', 'inProgress'], false).setIn(['restoring', 'error'], action.payload)
            })


        // Load info by VAT
        case actionTypes.LOAD_INFO_STARTED:
            return state.setIn(['infoByVAT', 'inProgress'], true)
        case actionTypes.LOAD_INFO_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['infoByVAT', 'inProgress'], false)
                    .setIn(['infoByVAT', 'error'], null)
                    .setIn(['infoByVAT', 'data'], action.payload)
            })
        case actionTypes.LOAD_INFO_REJECTED:
            return state.withMutations(map => {
                map.setIn(['infoByVAT', 'inProgress'], false).setIn(['infoByVAT', 'error'], action.payload)
            })
        case actionTypes.CLEAR_INFO:
            return state.withMutations(map => {
                map.setIn(['infoByVAT', 'inProgress'], false)
                    .setIn(['infoByVAT', 'error'], null)
                    .setIn(['infoByVAT', 'data'], null)
            })

        // Fetch carrier contacts
        case actionTypes.FETCH_CONTACTS_STARTED:
            return state.withMutations(map => {
                map.setIn(['contacts', 'fetching'], true).setIn(['contacts', 'error'], null)
            })
        case actionTypes.FETCH_CONTACTS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['contacts', 'fetching'], false)
                    .setIn(['contacts', 'error'], null)
                    .setIn(['contacts', 'data'], action.payload)
            })
        case actionTypes.FETCH_CONTACTS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['contacts', 'fetching'], false)
                    .setIn(['contacts', 'error'], action.payload)
                    .setIn(['contacts', 'data'], null)
            })

        // Check duplicity
        case actionTypes.CHECK_DUPLICITY_STARTED:
            return state.setIn(['checkingDuplicity', 'inProgress'], true)
        case actionTypes.CHECK_DUPLICITY_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['checkingDuplicity', 'inProgress'], false)
                    .setIn(['checkingDuplicity', 'error'], null)
                    .setIn(['checkingDuplicity', 'data'], action.payload)
            })
        case actionTypes.CHECK_DUPLICITY_REJECTED:
            return state.withMutations(map => {
                map.setIn(['checkingDuplicity', 'inProgress'], false).setIn(['checkingDuplicity', 'error'], action.payload)
            })

        default:
            return state
    }
}

export default carriers
