import React, { Component } from 'react'
import Select from 'react-select'
import 'react-select/dist/react-select.css'

import Field from './Field'

// eslint-disable-next-line react/prefer-stateless-function
class SelectField extends Component {
    render() {
        const options =
            this.props.values ?
            this.props.values.map(value => ({
                value: String(value.id),
                label: value.name,
            })) : []

        if (this.props.nullable) {
            options.unshift({
                value: null,
                label: '-',
            })

        }

        let value

        if (this.props.hasOwnProperty('getValue')) {
            value = String(this.props.getValue() ? this.props.getValue() : this.props.defaultValue)
        } else {
            value = String(this.props.value ? this.props.value : this.props.defaultValue)
        }

        return (
            <Field {...this.props}>
                <Select
                    name={this.props.id}
                    value={value}
                    options={options}
                    onChange={this.props.onChange}
                    clearable={false}
                    placeholder="-"
                    className={this.props.className}
                    multi={this.props.multi}
                    disabled={this.props.readonly ? this.props.readonly() : false}
                />
            </Field>
        )
    }
}

export default SelectField
