import React from 'react'
import { Route, Switch } from 'react-router'
import TransportTemplatesList from './TransportTemplatesList'
import TransportTemplatesEdit from './TransportTemplatesEdit'

const TransportTemplates = props => (
    <Switch>
        <Route exact path={props.match.url} component={TransportTemplatesList} />
        <Route exact path={`${props.match.url}/new`} component={TransportTemplatesEdit} />
        <Route exact path={`${props.match.url}/:transportID`} component={TransportTemplatesEdit} />
    </Switch>
)

export default TransportTemplates
