import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportDriverSurcharge = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportDriverSurchargeFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportDriverSurchargeRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportDriverSurchargeCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Driver note

export const updateDriverNote = values => ({
    type: actionTypes.DRIVER_NOTE_UPDATE,
    payload: values,
})

export const updateDriverNoteFulfilled = () => ({
    type: actionTypes.DRIVER_NOTE_UPDATE_FULFILLED,
})

export const updateDriverNoteRejected = message => ({
    type: actionTypes.DRIVER_NOTE_UPDATE_REJECTED,
    payload: message,
})

export const updateDriverNoteCancelled = () => ({
    type: actionTypes.DRIVER_NOTE_UPDATE_CANCELLED,
})

// Clear

export const clearReportDriverSurcharge = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportDriverSurcharge = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportDriverSurchargeDetails = (driverId, dateFrom, dateTo, driverSurchargeTypeId) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        driverId,
        dateFrom,
        dateTo,
        driverSurchargeTypeId,
    },
})

export const fetchReportDriverSurchargeDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportDriverSurchargeDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportDriverSurchargeDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear details

export const clearReportDriverSurchargeDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export details

export const exportReportDriverSurchargeDetails = (driverId, dateFrom, dateTo, driverSurchargeTypeId, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        driverId,
        dateFrom,
        dateTo,
        driverSurchargeTypeId,
        filters,
    }
})