import React from 'react'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'

const AppNotifications = props => <Notifications notifications={props.notifications} />

function mapStateToProps(state) {
    return {
        notifications: state.notifications,
    }
}

function mapDispatchToProps() {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppNotifications)
