import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError } from '../../common/api'
import { salesSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { Sales } from './model'

import { SalesType } from '../sales_types/model'
import * as salesTypesActionCreators from '../sales_types/actionCreators'
import { Currency } from '../currencies/model'
import * as currenciesActionCreators from '../currencies/actionCreators'
import { CompanyCostCenter } from '../company_cost_centers/model'
import * as companyCostCentersActionCreators from '../company_cost_centers/actionCreators'
import { User } from '../users/model'
import * as usersActionCreators from '../users/actionCreators'
import { Driver } from '../drivers/model'
import * as driversActionCreators from '../drivers/actionCreators'
import { Vehicle } from '../vehicles/model'
import * as vehiclesActionCreators from '../vehicles/actionCreators'
import { Trailer } from '../trailers/model'
import * as trailersActionCreators from '../trailers/actionCreators'
import { Customer } from '../customers/model'
import * as customersActionCreators from '../customers/actionCreators'
import { fromJS } from "immutable";

// Fetch

const fetchSalesEpic = (action$, store) => {
    const tableIdentifier = 'sales_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `sales/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(salesSchema)
                        )

                        const sales = normalizedEntitiesToRecordMap(normalizedData.entities.sales, Sales, normalizedData.result)
                        const salesTypes = normalizedEntitiesToRecordMap(normalizedData.entities.sales_types, SalesType)
                        const currencies = normalizedEntitiesToRecordMap(normalizedData.entities.currencies, Currency)
                        const company_cost_center = normalizedEntitiesToRecordMap(normalizedData.entities.company_cost_centers, CompanyCostCenter)
                        const drivers = normalizedEntitiesToRecordMap(normalizedData.entities.drivers, Driver)
                        const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)
                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                        const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                        const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)

                        const observables = [
                            Observable.of(actionCreators.fetchSalesFulfilled(sales)),
                            Observable.of(salesTypesActionCreators.fetchSalesTypesFulfilled(salesTypes)),
                            Observable.of(currenciesActionCreators.fetchCurrenciesFulfilled(currencies)),
                            Observable.of(companyCostCentersActionCreators.fetchCompanyCostCentersFulfilled(company_cost_center)),
                            Observable.of(driversActionCreators.fetchDriversFulfilled(drivers)),
                            Observable.of(usersActionCreators.fetchUsersFulfilled(users)),
                            Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                            Observable.of(trailersActionCreators.fetchTrailersFulfilled(trailers)),
                            Observable.of(customersActionCreators.fetchCustomersFulfilled(customers)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchSalesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchSaleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().sales.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `sales/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, salesSchema)

                        const sales = normalizedEntitiesToRecordMap(normalizedData.entities.sales, Sales)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchSalesFulfilled(sales)),
                            Observable.of(actionCreators.fetchSaleFulfilled())
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchSaleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveSaleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().sales.getIn(['current', 'saving']))
        .switchMap(action => {
            // eslint-disable-next-line prefer-object-spread
            const values = Object.assign({}, action.payload)

            let path = `sales`
            let method = 'POST'

            if (values.id) {
                path = `sales/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, salesSchema)

                        const sales = normalizedEntitiesToRecordMap(normalizedData.entities.sales, Sales)
                        const sale = sales.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveSalesFulfilled(sale)))
                    })
                    .catch(error => Observable.of(actionCreators.saveSalesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteSaleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().sales.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `sales/${action.payload.id}${action.payload.withFuture ? '/with-future' : ''}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteSalesFulfilled(action.payload.id)))
                    .catch(error => Observable.of(actionCreators.deleteSalesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Fetch import headers

const fetchImportHeadersEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH_IMPORT_HEADERS)
        .filter(() => !store.getState().sales.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORT_HEADERS_STARTED,
                }),
                request({
                    path: `sales/import/get-headers`,
                    method: 'POST',
                    body: {
                        file: action.payload,
                    },
                })
                .switchMap(ajaxResponse => {
                    const importHeaders = {
                        file_id: ajaxResponse.response.file_id,
                        headers: fromJS(ajaxResponse.response.headers),
                        headers_default: fromJS(ajaxResponse.response.headers_default),
                    }
                    return Observable.of(actionCreators.fetchSalesImportHeadersFulfilled(importHeaders))
                })
                .catch(error => Observable.of(actionCreators.fetchSalesImportHeadersRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_HEADERS_CANCELLED))
            )
        )
}

// Fetch import items

const fetchImportItemsEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH_IMPORT_ITEMS)
        .filter(() => !store.getState().sales.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORT_ITEMS_STARTED,
                }),
                request({
                    path: `sales/import/get-items`,
                    method: 'POST',
                    body: {
                        fileId: action.payload.fileId,
                        columnTypes: action.payload.columnTypes,
                        defaultDate: action.payload.defaultDate,
                        defaultSalesTypeId: action.payload.defaultSalesTypeId,
                    },
                })
                .switchMap(ajaxResponse => {
                    return Observable.of(actionCreators.fetchSalesImportItemsFulfilled(ajaxResponse.response.items, ajaxResponse.response.defaultDate, ajaxResponse.response.defaultSalesTypeId))
                })
                .catch(error => Observable.of(actionCreators.fetchSalesImportItemsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_ITEMS_CANCELLED))
            )
        )
}

// Import sales

const importSalesEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.IMPORT_SALES)
        .filter(() => !store.getState().sales.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.IMPORT_SALES_STARTED,
                }),
                request({
                    path: `sales/import/import`,
                    method: 'POST',
                    body: {
                        fileId: action.payload.fileId,
                        columnTypes: action.payload.columnTypes,
                        defaultDate: action.payload.defaultDate,
                        defaultSalesTypeId: action.payload.defaultSalesTypeId,
                        keys: action.payload.keys,
                    },
                })
                .switchMap(ajaxResponse => {
                    return Observable.of(actionCreators.importSalesFulfilled())
                })
                .catch(error => Observable.of(actionCreators.importSalesRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.IMPORT_SALES_CANCELLED))
            )
        )
}


// Fetch data for invoicing

const fetchDataForInvoicingEpic = action$ =>
    action$.ofType(actionTypes.FETCH_DATA_FOR_INVOICING).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DATA_FOR_INVOICING_STARTED,
            }),
            request({
                method: 'GET',
                path: `sales/data-for-invoicing?ids=${action.payload.salesIds.join(',')}`,
            })
            .switchMap(ajaxResponse => {
                return Observable.of(actionCreators.fetchDataForInvoicingFulfilled(ajaxResponse.response.data))
            })
            .catch(error => Observable.of(actionCreators.fetchDataForInvoicingRejected(parseAPIError(error))))
            .takeUntil(action$.ofType(actionTypes.FETCH_DATA_FOR_INVOICING_CANCELLED))
        )
    )

// Create invoice

const createInvoiceEpic = (action$, store) => {
    const tableIdentifier = 'sales_list'

    return action$.ofType(actionTypes.CREATE_INVOICE).switchMap(action => {
        const modelState = resolveModelState(tableIdentifier, store.getState(), action)

        const requestParams = {
            method: 'POST',
            path: `sales/create-invoice`,
            body: {
                filters: modelState.get('filters').toJS(),
                sorting: modelState.get('sorting').toJS(),
                data: action.payload.data,
            },
        }

        return Observable.concat(
            Observable.of({
                type: actionTypes.CREATE_INVOICE_STARTED,
            }),
            request(requestParams)
            .switchMap(ajaxResponse => {

                const normalizedData = normalize(
                    ajaxResponse.response.data.data,
                    new schema.Array(salesSchema)
                )
                const sales = normalizedEntitiesToRecordMap(normalizedData.entities.sales, Sales, normalizedData.result)

                const observables = [
                    Observable.of(actionCreators.createInvoiceFulfilled(sales, ajaxResponse.response.data.createdInvoiceId)),
                ]

                observables.push(
                    Observable.of(
                        tableModelActionCreators.updatePagination(
                            tableIdentifier,
                            ajaxResponse.response.last_page,
                            ajaxResponse.response.current_page - 1,
                            ajaxResponse.response.total
                        )
                    )
                )

                return Observable.concat(...observables)
            })
            .catch(error => Observable.of(actionCreators.createInvoiceRejected(parseAPIError(error))))
            .takeUntil(action$.ofType(actionTypes.CREATE_INVOICE_CANCELLED))
        )
    })
}


const setInvoicingStateEpic = (action$, store) => {
    const tableIdentifier = 'sales_list'

    return action$.ofType(actionTypes.SET_INVOICING_STATE).switchMap(action => {
        const modelState = resolveModelState(tableIdentifier, store.getState(), action)

        const requestParams = {
            method: 'POST',
            path: `sales/${action.payload.salesId}/state`,
            body: {
                sorting: modelState.get('sorting').toJS(),
                filters: modelState.get('filters').toJS(),
                invoicing_state_id: action.payload.invoicingStateId,
            },
        }

        return Observable.concat(
            Observable.of({
                type: actionTypes.SET_INVOICING_STATE_STARTED,
            }),
            request(requestParams)
            .switchMap(ajaxResponse => {
                const normalizedData = normalize(
                    ajaxResponse.response.data,
                    new schema.Array(salesSchema)
                )
                const sales = normalizedEntitiesToRecordMap(normalizedData.entities.sales, Sales, normalizedData.result)

                const observables = [
                    Observable.of(actionCreators.setInvoicingStateFulfilled(sales)),
                ]

                observables.push(
                    Observable.of(
                        tableModelActionCreators.updatePagination(
                            tableIdentifier,
                            ajaxResponse.response.last_page,
                            ajaxResponse.response.current_page - 1,
                            ajaxResponse.response.total
                        )
                    )
                )

                return Observable.concat(...observables)
            })
            .catch(error => Observable.of(actionCreators.setInvoicingStateRejected(parseAPIError(error))))
            .takeUntil(action$.ofType(actionTypes.SET_INVOICING_STATE_CANCELLED))
        )
    })
}


export default [fetchSalesEpic, fetchSaleEpic, saveSaleEpic, deleteSaleEpic, fetchImportHeadersEpic, fetchImportItemsEpic, importSalesEpic, fetchDataForInvoicingEpic, createInvoiceEpic, setInvoicingStateEpic]
