import { Record } from 'immutable'

export class Country extends Record({
    id: undefined,
    iso_code: undefined,
    iso_code_2: undefined,
    name: undefined,
    currency_id: undefined,
    toll_per_km: undefined,
    toll_per_day: undefined,
    minimum_hour_wage: undefined,
    is_in_eu: undefined,
}) {}
