import { Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
    }),
    current: Map({
        fetching: false,
        saving: false,
        error: null,
        id: null,
    }),
    deletion: Map({
        inProgress: false,
        error: null,
    }),
    serviceJob: Map({
        data: null,
        fetching: false,
        error: null,
    }),
    serviceMaterial: Map({
        data: null,
        fetching: false,
        error: null,
    }),
    serviceWorker: Map({
        data: null,
        fetching: false,
        error: null,
    }),
    serviceOrderStates: Map({
        objects: OrderedMap({})
    }),
})

function serviceOrders(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], action.payload).setIn(['all', 'fetching'], false).setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // One
        case actionTypes.FETCH_ONE_STARTED:
            return state.setIn(['current', 'fetching'], true)
        case actionTypes.FETCH_ONE_FULFILLED:
        case actionTypes.FETCH_ONE_REJECTED:
        case actionTypes.FETCH_ONE_CANCELLED:
            return state.setIn(['current', 'fetching'], false)

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], true).setIn(['current', 'error'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false).setIn(['current', 'error'], null).setIn(['current', 'id'], action.payload.get('id')).setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false).setIn(['current', 'error'], action.payload)
            })

        // Delete
        case actionTypes.DELETE_STARTED:
            return state.setIn(['deletion', 'inProgress'], true)
        case actionTypes.DELETE_FULFILLED:
            return state.deleteIn(['all', 'objects', action.payload]).withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], null)
            })
        case actionTypes.DELETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], action.payload)
            })

        // Fetch job autocomplete
        case actionTypes.FETCH_JOB_AUTOCOMPLETE_STARTED:
            return state.setIn(['serviceJob', 'fetching'], true)
        case actionTypes.FETCH_JOB_AUTOCOMPLETE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['serviceJob', 'data'], action.payload).setIn(['serviceJob', 'fetching'], false).setIn(['serviceJob', 'error'], null)
            })
        case actionTypes.FETCH_JOB_AUTOCOMPLETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['serviceJob', 'fetching'], false).setIn(['serviceJob', 'error'], action.payload)
            })
        case actionTypes.FETCH_JOB_AUTOCOMPLETE_CANCELLED:
            return state.setIn(['serviceJob', 'fetching'], false)

        // Fetch material autocomplete
        case actionTypes.FETCH_MATERIAL_AUTOCOMPLETE_STARTED:
            return state.setIn(['serviceMaterial', 'fetching'], true)
        case actionTypes.FETCH_MATERIAL_AUTOCOMPLETE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['serviceMaterial', 'data'], action.payload).setIn(['serviceMaterial', 'fetching'], false).setIn(['serviceMaterial', 'error'], null)
            })
        case actionTypes.FETCH_MATERIAL_AUTOCOMPLETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['serviceMaterial', 'fetching'], false).setIn(['serviceMaterial', 'error'], action.payload)
            })
        case actionTypes.FETCH_MATERIAL_AUTOCOMPLETE_CANCELLED:
            return state.setIn(['serviceMaterial', 'fetching'], false)

        // Fetch worker autocomplete
        case actionTypes.FETCH_WORKER_AUTOCOMPLETE_STARTED:
            return state.setIn(['serviceWorker', 'fetching'], true)
        case actionTypes.FETCH_WORKER_AUTOCOMPLETE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['serviceWorker', 'data'], action.payload).setIn(['serviceWorker', 'fetching'], false).setIn(['serviceWorker', 'error'], null)
            })
        case actionTypes.FETCH_WORKER_AUTOCOMPLETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['serviceWorker', 'fetching'], false).setIn(['serviceWorker', 'error'], action.payload)
            })
        case actionTypes.FETCH_WORKER_AUTOCOMPLETE_CANCELLED:
            return state.setIn(['serviceWorker', 'fetching'], false)

        // Order states
        case actionTypes.FETCH_SERVICE_ORDER_STATES_FULFILLED:
            return state.setIn(['serviceOrderStates', 'objects'], action.payload)

        default:
            return state
    }
}

export default serviceOrders
