import { createSelector } from 'reselect'

export const getBlockedIps = state => state.blockedIps.getIn(['all', 'objects'])

export const createGetBlockedIpByIDSelector = id => createSelector([getBlockedIps], blockedIps => blockedIps.get(String(id)))

const getCurrentBlockedIpStatus = state => state.blockedIps.get('current')

export const createGetCurrentBlockedIpStatusSelector = () => createSelector([getCurrentBlockedIpStatus], status => status)

export const getBlockedIpDeleting = state => state.blockedIps.getIn(['deletion', 'inProgress'])

export const getBlockedIpDeleteError = state => state.blockedIps.getIn(['deletion', 'error'])
