import React, { Component } from 'react'
import moment from 'moment'
import { injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class VehicleEquipmentsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render() {
        const vehicleEquipment = this.props.data
        const vehicle = this.props.vehicle
        const equipment = this.props.equipment
        const notifyDays = equipment && equipment.notify_days
        const validityDaysRemained = vehicleEquipment.date_validity_to && moment(vehicleEquipment.date_validity_to).diff(moment(), 'days')

        const validityDaysClassName = notifyDays && vehicleEquipment.date_validity_to && validityDaysRemained <= notifyDays ? 'text-danger' : ''

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-160">{vehicle ? vehicle.registration : null}</td>
                <td className="w-200">{equipment ? equipment.name : null}</td>
                <td className="w-200 text-center">
                    {vehicleEquipment.date_validity_from ? formatters.dateFormatter(vehicleEquipment.date_validity_from) : null}
                </td>
                <td className="w-200 text-center">
                    {vehicleEquipment.date_validity_to ? formatters.dateFormatter(vehicleEquipment.date_validity_to) : null}
                </td>
                <td className={`w-80 text-right ${validityDaysClassName}`}>
                    {vehicleEquipment.date_validity_to
                        ? `${formatters.integerFormatter(validityDaysRemained)} ${this.props.intl.formatMessage({ id: 'fields.abbrDays' })}`
                        : null}
                </td>
                <td className="w-max wm-200 wrapped">{vehicleEquipment.note}</td>
            </tr>
        )
    }
}

export default injectIntl(VehicleEquipmentsListRow)
