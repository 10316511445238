import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class OffersListRow extends Component {
    handleAccept = e => {
        e.preventDefault()

        this.props.onAccept([this.props.data.id])
    }

    handleDecline = e => {
        e.preventDefault()

        this.props.onDecline([this.props.data.id])
    }

    handleSelect = () => {
        this.props.onSelect(this.props.data.id)
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const transport = this.props.data
        const currency = this.props.currencySelector(transport.price_currency_id)

        return (
            <tr>
                <PermissionsCheck has={[PERMS.UPDATE]}>
                    <td className="w-30 text-center">
                        <input type="checkbox" checked={this.props.isSelected} onChange={this.handleSelect} />
                    </td>
                </PermissionsCheck>
                <PermissionsCheck has={[PERMS.UPDATE]}>
                    <td className="w-60 table-buttons">
                        <a href="#" onClick={this.handleAccept} className="m-r-xxs">
                            <i className="far fa-check-circle text-success" />
                        </a>
                        <a href="#" onClick={this.handleDecline}>
                            <i className="far fa-times-circle text-danger" />
                        </a>
                    </td>
                </PermissionsCheck>
                <td className="w-100">
                    <strong>{transport.get('number')}</strong>
                </td>
                <td className="w-2000">{transport.get('company_name')}</td>
                <td className="w-200">{formatters.datetimeFormatter(this.props.data.start_date)}</td>
                <td className="w-300">
                    <strong>{transport.getStartAddress(this.props.countrySelector)}</strong>
                </td>
                <td className="w-200">{formatters.datetimeFormatter(this.props.data.end_date)}</td>
                <td className="w-300">
                    <strong>{transport.getEndAddress(this.props.countrySelector)}</strong>
                </td>
                <td className="w-100 text-right">{`${formatters.integerFormatter(transport.get('weight'))} ${transport.get('weight_unit')}`}</td>
                <td className="w-100 text-right">
                    <strong>{`${formatters.priceFormatter(transport.getTotalPrice(), '0,0', currency && currency.get('iso_code'))}`}</strong>
                </td>
            </tr>
        )
    }
}

export default injectIntl(OffersListRow)
