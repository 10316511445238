import * as actionTypes from './actionTypes'

// Fetch

export const fetchUsers = () => ({
    type: actionTypes.FETCH,
})

export const fetchUsersFulfilled = users => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: users,
})

export const fetchUsersRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchUsersCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchUser = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchUserFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchUserRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchUserCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveUser = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveUserFulfilled = user => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: user,
})

export const saveUserRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteUser = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteUserFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteUserRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})


// Restore

export const restoreUser = id => ({
    type: actionTypes.RESTORE,
    payload: id,
})

export const restoreUserFulfilled = item => ({
    type: actionTypes.RESTORE_FULFILLED,
    payload: item,
})

export const restoreUserRejected = message => ({
    type: actionTypes.RESTORE_REJECTED,
    payload: message,
})

// Export

export const exportUsers = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Fetch user login log

export const fetchUserLoginLog = id => ({
    type: actionTypes.FETCH_LOGIN_LOG,
    payload: id,
})

export const fetchUserLoginLogFulfilled = data => ({
    type: actionTypes.FETCH_LOGIN_LOG_FULFILLED,
    payload: data,
})

export const fetchUserLoginLogRejected = message => ({
    type: actionTypes.FETCH_LOGIN_LOG_REJECTED,
    payload: message,
})

export const fetchUserLoginLogCancelled = () => ({
    type: actionTypes.FETCH_LOGIN_LOG_CANCELLED,
})

// Export user login log

export const exportUserLoginLog = id => ({
    type: actionTypes.EXPORT_LOGIN_LOG,
    payload: id,
})

export const exportUserLoginLogFulfilled = data => ({
    type: actionTypes.EXPORT_LOGIN_LOG_FULFILLED,
    payload: data,
})

export const exportUserLoginLogRejected = message => ({
    type: actionTypes.EXPORT_LOGIN_LOG_REJECTED,
    payload: message,
})

export const exportUserLoginLogCancelled = () => ({
    type: actionTypes.EXPORT_LOGIN_LOG_CANCELLED,
})
