import { Map } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    requesed: '',
    data: null,
    fetching: false,
    error: null,
})

function legend(state = initialState, action) {
    switch (action.type) {
        // Fetch
        case actionTypes.FETCH:
            return state.set('requesed', action.payload).set('fetching', true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.set('fetching', false)
                    .set('data', action.payload)
                    .set('error', null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.set('fetching', false).set('error', action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.set('fetching', false)

        default:
            return state
    }
}

export default legend
