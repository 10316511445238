import { createStore, applyMiddleware, compose } from 'redux'

import { routerMiddleware } from 'react-router-redux'
import { createEpicMiddleware } from 'redux-observable'
import reducers from './reducers'
import epics from './epics'
import history from './history'

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composeEnhancers(applyMiddleware(routerMiddleware(history), createEpicMiddleware(epics))))

if (module.hot) {
    module.hot.accept('./reducers', () => {
        store.replaceReducer(reducers)
    })
}

export default store
