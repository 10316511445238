import * as actionTypes from './actionTypes'

export const fetchPrerequisites = (identifier, keys) => () => ({
    type: actionTypes.FETCH,
    payload: {
        identifier,
        keys,
    },
})

export const fetchPrerequisitesStarted = (identifier, keys) => () => ({
    type: actionTypes.FETCH_STARTED,
    payload: {
        identifier,
        keys,
    },
})

export const fetchPrerequisitesFulfilled = identifier => prerequisites => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: {
        identifier,
        prerequisites,
    },
})

export const fetchPrerequisitesRejected = identifier => message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: {
        identifier,
        message,
    },
})

export const fetchPrerequisitesCancelled = identifier => () => ({
    type: actionTypes.FETCH_CANCELLED,
    payload: {
        identifier,
    },
})

export const preparePrerequisitesActionCreators = (identifier, entities) => ({
    fetchPrerequisites: fetchPrerequisites(identifier, entities),
    fetchPrerequisitesFulfilled: fetchPrerequisitesFulfilled(identifier),
    fetchPrerequisitesRejected: fetchPrerequisitesRejected(identifier),
    fetchPrerequisitesCancelled: fetchPrerequisitesCancelled(identifier),
})
