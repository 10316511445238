import { createSelector } from 'reselect'

import { prepareTableData } from './data'

// Table Model

const getTableModel = tableIdentifier => state => state.tableModel.get(tableIdentifier)

export const createTableModelSelector = tableIdentifier => createSelector([getTableModel(tableIdentifier)], model => model)

// Data

const getTableModelState = ({ tableIdentifier }) => state => state.tableModel.getIn([tableIdentifier])

export const createTableDataSelector = ({ tableIdentifier, columns, dataSelector, clientSideItemsPerPage }) => {
    if (!clientSideItemsPerPage) {
        return createSelector([dataSelector], data =>
            prepareTableData({
                data,
                columns,
            })
        )
    }

    return createSelector([dataSelector, getTableModelState({ tableIdentifier })], (data, tableModelState) =>
        prepareTableData({
            data,
            tableModelState,
            columns,
            itemsPerPage: clientSideItemsPerPage,
        })
    )
}
