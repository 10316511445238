import React from 'react'
import { Route, Switch } from 'react-router'
import VehicleFuelingsList from './VehicleFuelingsList'
import VehicleFuelingsEdit from './VehicleFuelingsEdit'

const VehicleFuelings = props => (
    <Switch>
        <Route exact path={props.match.url} component={VehicleFuelingsList} />
        <Route exact path={`${props.match.url}/new`} component={VehicleFuelingsEdit} />
        <Route exact path={`${props.match.url}/:vehicleFuelingID`} component={VehicleFuelingsEdit} />
    </Switch>
)

export default VehicleFuelings
