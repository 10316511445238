import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchReportCustomerWeeklyPlansEpic = (action$, store) => {
    return action$.ofType(actionTypes.FETCH).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request({
                method: 'POST',
                path: `report-customer-weekly-plans`,
                body: {
                    settings: store.getState().reportCustomerWeeklyPlans.getIn(['list', 'settings']),
                },
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportCustomerWeeklyPlansFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportCustomerWeeklyPlansRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    )
}

// Export

const exportReportCustomerWeeklyPlansEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const settings = JSON.stringify(store.getState().reportCustomerWeeklyPlans.getIn(['list', 'settings']))
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-customer-weekly-plans/export?settings=${settings}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

export default [fetchReportCustomerWeeklyPlansEpic, exportReportCustomerWeeklyPlansEpic]
