import { Map } from 'immutable'

export const resolveModelState = (tableIdentifier, state, action) => {
    let configuration = state.tableModel.get(tableIdentifier)

    if (action.payload !== undefined && action.payload.configuration !== undefined) {
        configuration = action.payload.configuration
    }

    return configuration
}

export const getTotalNumberOfPages = (tableModel, totalCount, itemsPerPage) => {
    if (!itemsPerPage) {
        return tableModel.getIn(['pagination', 'total'])
    }

    return Math.ceil(totalCount / itemsPerPage)
}

export const getActiveFilters = (tableModel, ignoreFilters) => {
    const filters = tableModel.get('filters').toJS()

    return Map({}).withMutations(map => {
        Object.keys(filters).forEach(filterKey => {
            if (
                filters[filterKey].value !== '' &&
                filters[filterKey].value !== false &&
                (!ignoreFilters || ignoreFilters.indexOf(filterKey) === -1)
            ) {
                map.set(filterKey, filters[filterKey])
            }
            return false
        })
    })
}
