import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportTransportChanges = (filters, datetime) => ({
    type: actionTypes.FETCH,
    payload: {
        filters,
        datetime,
    },
})

export const fetchReportTransportChangesFulfilled = (data, datetime) => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: {
        data,
        datetime,
    },
})

export const fetchReportTransportChangesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportTransportChangesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportTransportChanges = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportTransportChanges = (filters, datetime) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        datetime,
    },
})
