// Fetch list

export const FETCH = 'currencies.FETCH'
export const FETCH_STARTED = 'currencies.FETCH_STARTED'
export const FETCH_FULFILLED = 'currencies.FETCH_FULFILLED'
export const FETCH_REJECTED = 'currencies.FETCH_REJECTED'
export const FETCH_CANCELLED = 'currencies.FETCH_CANCELLED'

export const SORTING_CHANGED = 'currencies.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'currencies.FETCH_ONE'
export const FETCH_ONE_STARTED = 'currencies.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'currencies.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'currencies.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'currencies.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'currencies.SAVE'
export const SAVE_STARTED = 'currencies.SAVE_STARTED'
export const SAVE_FULFILLED = 'currencies.SAVE_FULFILLED'
export const SAVE_REJECTED = 'currencies.SAVE_REJECTED'
export const SAVE_CANCELLED = 'currencies.SAVE_CANCELLED'

// Delete

export const DELETE = 'currencies.DELETE'
export const DELETE_STARTED = 'currencies.DELETE_STARTED'
export const DELETE_FULFILLED = 'currencies.DELETE_FULFILLED'
export const DELETE_REJECTED = 'currencies.DELETE_REJECTED'
export const DELETE_CANCELLED = 'currencies.DELETE_CANCELLED'

// Export

export const EXPORT = 'currencies.EXPORT'
export const EXPORT_FULFILLED = 'currencies.EXPORT_FULFILLED'
