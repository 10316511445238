import { Observable } from 'rxjs'
import { normalize } from 'normalizr'

import { request, parseAPIError } from '../../common/api'
import { userSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import { User } from './model'

// Fetch one

const fetchUserEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH)
        .filter(() => !store.getState().users.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request({
                    path: `users/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, userSchema)

                        const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchUserFulfilled(users)),
                            Observable.of(actionCreators.fetchUserFulfilled())
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchUserRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED))
            )
        )

// Save

const saveUserEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().users.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload)

            let path = `users`
            let method = 'POST'

            if (values.id) {
                path = `users/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, userSchema)

                        const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)
                        const user = users.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveUserFulfilled(user)))
                    })
                    .catch(error => Observable.of(actionCreators.saveUserRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

export default [fetchUserEpic, saveUserEpic]
