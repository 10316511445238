import * as actionTypes from './actionTypes'

// Fetch

export const fetchTransportStates = () => ({
    type: actionTypes.FETCH,
})

export const fetchTransportStatesFulfilled = transportStates => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: transportStates,
})

export const fetchTransportStatesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTransportStatesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchTransportState = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchTransportStateFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchTransportStateRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchTransportStateCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveTransportState = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveTransportStateFulfilled = transportState => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: transportState,
})

export const saveTransportStateRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteTransportState = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteTransportStateFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteTransportStateRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})
