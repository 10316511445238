import { Record } from 'immutable'

export class BorderCrossings extends Record({
    id: undefined,
    name: undefined,
    gps_lat: undefined,
    gps_lng: undefined,
    country_id_1: undefined,
    country_id_2: undefined,
}) {
    getGPS = () => (this.gps_lat && this.gps_lng ? `${this.gps_lat}, ${this.gps_lng}` : '')
}
