import * as actionTypes from './actionTypes'

// Fetch

export const fetchCompanyNumberings = () => ({
    type: actionTypes.FETCH,
})

export const fetchCompanyNumberingsFulfilled = companyNumberings => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: companyNumberings,
})

export const fetchCompanyNumberingsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCompanyNumberingsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCompanyNumbering = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCompanyNumberingFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCompanyNumberingRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCompanyNumberingCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCompanyNumbering = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveCompanyNumberingFulfilled = companyNumbering => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: companyNumbering,
})

export const saveCompanyNumberingRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteCompanyNumbering = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCompanyNumberingFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCompanyNumberingRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportCompanyNumberings = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
