import React, { Component } from 'react'
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'

import { ConfirmDialog } from '../../../common/confirm_dialog'

import TransportTemplatesEditRoutePoint from './TransportTemplatesEditRoutePoint'
import TransportTemplatesEditRoutePointEdit from './TransportTemplatesEditRoutePointEdit'
import TransportTemplatesRoutesSummary from './TransportTemplatesRoutesSummary'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_ROUTES } from '../permissions'

const SortableItem = SortableElement(({ point, pointType, eventDataTypes, handleEditButtonClicked, handleDeleteButtonClicked }) => (
    <div>
        <TransportTemplatesEditRoutePoint
            key={point.id}
            point={point}
            pointType={pointType}
            eventDataTypes={eventDataTypes}
            handleEditButtonClicked={handleEditButtonClicked}
            handleDeleteButtonClicked={handleDeleteButtonClicked}
        />
    </div>
))

const SortableList = SortableContainer(({ points, pointTypes, eventDataTypes, handleEditPoint, handleDeletePoint }) => (
    <div className="display-inline wp-100">
        {points.map((point, pointIndex) => (
            <SortableItem
                // eslint-disable-next-line react/no-array-index-key
                key={`sortable-item-${pointIndex}`}
                index={pointIndex}
                point={point}
                pointType={pointTypes[point.type_id]}
                eventDataTypes={eventDataTypes}
                handleEditButtonClicked={() => handleEditPoint(pointIndex)}
                handleDeleteButtonClicked={() => handleDeletePoint(pointIndex)}
            />
        ))}
    </div>
))

class TransportTemplatesEditRoute extends Component {
    pointTypes = {}

    state = {
        showPointModal: false,
        showRoutesModal: false,
        pointType: null,
        pointIndexToEdit: null,
        points: [],
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    setPoints = (points, callback) => {
        this.setState(
            {
                points,
            },
            () => {
                this.props.handleRouteChanged && this.props.handleRouteChanged(this.state.points)
                callback && callback()
            }
        )
    }

    handleCreatePoint = pointType => {
        this.setState({
            pointType,
            pointIndexToEdit: null,
        })
        this.openPointModal()
    }

    handleEditPoint = pointIndex => {
        this.setState({ pointIndexToEdit: pointIndex })
        this.openPointModal()
    }

    handleDeletePoint = pointIndex => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.deletePoint.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.deletePoint.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.deletePoint.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.deletePoint.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.deletePoint(pointIndex)
                this.hideConfirmDialog()
            },
        })
    }

    deletePoint = pointIndex => {
        this.setPoints(this.state.points.filter((item, key) => key !== pointIndex))
    }

    closePointModal = () => {
        this.setState({ showPointModal: false })
    }

    openPointModal = () => {
        this.setState({ showPointModal: true })
    }

    handlePointChanged = values => {
        const points = [...this.state.points]

        if (this.state.pointIndexToEdit !== null) {
            points[this.state.pointIndexToEdit] = values
        } else {
            points.push({
                ...values,
                type_id: this.state.pointType,
            })
        }

        this.setPoints(points, this.closePointModal)
    }

    handleSortEnd = ({ oldIndex, newIndex }) => {
        this.setPoints(arrayMove(this.state.points, oldIndex, newIndex))
    }

    closeRoutesModal = () => {
        this.setState({ showRoutesModal: false })
    }

    openRoutesModal = () => {
        this.setState({ showRoutesModal: true })
    }

    handleSetPredepartureCheck = value => {
        this.props.handlePredepartureCheckChanged && this.props.handlePredepartureCheckChanged(value)
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.points) !== JSON.stringify(this.state.points)) {
            this.setState({
                points: [...nextProps.points],
            })
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const transport = this.props.transport

        const pointToEdit = this.state.points[this.state.pointIndexToEdit]
        const pointTypeId = pointToEdit ? pointToEdit.type_id : this.state.pointType
        const pointType = pointTypeId && this.props.pointTypes[pointTypeId]

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_ROUTES)} noPermissionsTab>
                <div>
                    <div className="panel-head">
                        <h2 className="pull-left wp-100">
                            <div className="pull-left">
                                <FormattedMessage id="modules.transports.routeDefinition" />
                            </div>
                            <div className="pull-left m-l-lg">
                                {this.props.predepartureCheck ? (
                                    <PermissionsCheck hasAny={[PERMS_ROUTES.CREATE, PERMS_ROUTES.UPDATE]}>
                                        <a
                                            href="#"
                                            onClick={() => {
                                                this.handleSetPredepartureCheck(false)
                                            }}
                                            className="text-md text-gray no-text-decoration"
                                        >
                                            <i className="far fa-toggle-on text-success" />{' '}
                                            <FormattedMessage id="modules.transports.requirePredepartureCheck" />
                                        </a>
                                    </PermissionsCheck>
                                ) : (
                                    <PermissionsCheck hasAny={[PERMS_ROUTES.CREATE, PERMS_ROUTES.UPDATE]}>
                                        <a
                                            href="#"
                                            onClick={() => {
                                                this.handleSetPredepartureCheck(true)
                                            }}
                                            className="text-md text-gray no-text-decoration"
                                        >
                                            <i className="far fa-toggle-off" /> <FormattedMessage id="modules.transports.requirePredepartureCheck" />
                                        </a>
                                    </PermissionsCheck>
                                )}
                            </div>
                            <div className="btns-right" style={{ marginTop: '-5px' }}>
                                {transport && transport.transport_routes && transport.transport_routes.size > 0 && (
                                    <a href="#" onClick={this.openRoutesModal} className="btn btn-default btn-addon">
                                        <i className="far fa-map-marker-alt" /> <FormattedMessage id="modules.transports.showRoutesOnMap" />
                                    </a>
                                )}
                            </div>
                        </h2>
                    </div>

                    <div className="m-b-xs">
                        {this.props.predepartureCheck ? (
                            <div className="panel panel-predeparture-check">
                                <div className="panel-heading">
                                    <span className="pull-left">
                                        <PermissionsCheck hasAny={[PERMS_ROUTES.UPDATE, PERMS_ROUTES.CREATE]}>
                                            <i className="far fa-bars m-r-sm" style={{ visibility: 'hidden' }} />
                                        </PermissionsCheck>
                                        <span>
                                            <FormattedMessage id="fields.predepartureCheck" />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        ) : null}

                        {this.state.points && (
                            <SortableList
                                points={this.state.points}
                                onSortEnd={this.handleSortEnd}
                                lockAxis="y"
                                useDragHandle
                                lockToContainerEdges
                                helperClass="transport-waypoints"
                                pointTypes={this.props.pointTypes}
                                eventDataTypes={this.props.eventDataTypes}
                                handleEditPoint={this.handleEditPoint}
                                handleDeletePoint={this.handleDeletePoint}
                            />
                        )}
                    </div>

                    <PermissionsCheck hasAny={[PERMS_ROUTES.CREATE]}>
                        <div>
                            <span className="dropdown">
                                <DropdownButton
                                    title={this.props.intl.formatMessage({ id: 'buttons.createTransportPoint' })}
                                    id="dropdown-add-transport-point"
                                    dropup={this.state.points && this.state.points.length > 2}
                                >
                                    {Object.keys(this.props.pointTypes).map(key => (
                                        <MenuItem
                                            key={`point-menu-item-${key}`}
                                            eventKey={key}
                                            onClick={() => {
                                                this.handleCreatePoint(key)
                                            }}
                                        >
                                            {this.props.intl.formatMessage({ id: this.props.pointTypes[key].name })}
                                        </MenuItem>
                                    ))}
                                </DropdownButton>
                            </span>
                        </div>
                    </PermissionsCheck>

                    <Modal show={this.state.showPointModal} onHide={this.closePointModal} bsSize="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {pointType && this.props.intl.formatMessage({ id: pointToEdit ? pointType.editTitle : pointType.createTitle })}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TransportTemplatesEditRoutePointEdit
                                point={pointToEdit}
                                points={this.state.points}
                                pointType={pointToEdit ? pointToEdit.type_id : this.state.pointType}
                                dataFields={pointType && pointType.dataFields}
                                noDatetimes={pointType && pointType.noDatetimes}
                                showBookingTime={pointType && pointType.showBookingTime}
                                cargoType={this.props.cargoType}
                                handlePointChanged={this.handlePointChanged}
                                handleSaveAddress={this.props.handleSaveAddress}
                            />
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.showRoutesModal} onHide={this.closeRoutesModal} bsSize="lg">
                        <Modal.Header closeButton>
                            <Modal.Title />
                        </Modal.Header>
                        <Modal.Body>
                            <TransportTemplatesRoutesSummary
                                transportType={this.props.transportType}
                                transportStart={transport && transport.transport_point_start}
                                points={transport && transport.transport_points}
                                routes={transport && transport.transport_routes}
                                pointTypes={this.props.pointTypes}
                            />
                        </Modal.Body>
                    </Modal>

                    <ConfirmDialog options={this.state.confirmDialog} />
                </div>
            </PermissionsCheck>
        )
    }
}

export default injectIntl(TransportTemplatesEditRoute)
