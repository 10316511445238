// Fetch list

export const FETCH = 'reportCostCenterTraffic.FETCH'
export const FETCH_STARTED = 'reportCostCenterTraffic.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportCostCenterTraffic.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportCostCenterTraffic.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportCostCenterTraffic.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportCostCenterTraffic.CLEAR'

// Export

export const EXPORT = 'reportCostCenterTraffic.EXPORT'
export const EXPORT_FULFILLED = 'reportCostCenterTraffic.EXPORT_FULFILLED'

// Fetch details

export const FETCH_DETAILS = 'reportCostCenterTraffic.FETCH_DETAILS'
export const FETCH_DETAILS_STARTED = 'reportCostCenterTraffic.FETCH_DETAILS_STARTED'
export const FETCH_DETAILS_FULFILLED = 'reportCostCenterTraffic.FETCH_DETAILS_FULFILLED'
export const FETCH_DETAILS_REJECTED = 'reportCostCenterTraffic.FETCH_DETAILS_REJECTED'
export const FETCH_DETAILS_CANCELLED = 'reportCostCenterTraffic.FETCH_DETAILS_CANCELLED'

// Clear details

export const CLEAR_DETAILS = 'reportCostCenterTraffic.CLEAR_DETAILS'

// Export details

export const EXPORT_DETAILS = 'reportCostCenterTraffic.EXPORT_DETAILS'
export const EXPORT_DETAILS_FULFILLED = 'reportCostCenterTraffic.EXPORT_DETAILS_FULFILLED'

// Fetch details vehicles

export const FETCH_DETAILS_VEHICLES = 'reportCostCenterTraffic.FETCH_DETAILS_VEHICLES'
export const FETCH_DETAILS_VEHICLES_STARTED = 'reportCostCenterTraffic.FETCH_DETAILS_VEHICLES_STARTED'
export const FETCH_DETAILS_VEHICLES_FULFILLED = 'reportCostCenterTraffic.FETCH_DETAILS_VEHICLES_FULFILLED'
export const FETCH_DETAILS_VEHICLES_REJECTED = 'reportCostCenterTraffic.FETCH_DETAILS_VEHICLES_REJECTED'
export const FETCH_DETAILS_VEHICLES_CANCELLED = 'reportCostCenterTraffic.FETCH_DETAILS_VEHICLES_CANCELLED'

// Clear details vehicles

export const CLEAR_DETAILS_VEHICLES = 'reportCostCenterTraffic.CLEAR_DETAILS_VEHICLES'

// Export details vehicles

export const EXPORT_DETAILS_VEHICLES = 'reportCostCenterTraffic.EXPORT_DETAILS_VEHICLES'
export const EXPORT_DETAILS_VEHICLES_FULFILLED = 'reportCostCenterTraffic.EXPORT_DETAILS_VEHICLES_FULFILLED'
