import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_CONTACTS } from '../permissions'

// eslint-disable-next-line react/prefer-stateless-function
class CustomersContactsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick && this.props.onClick(this.props.item)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete && this.props.onDelete(this.props.item)
    }

    render() {
        const contact = this.props.item

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS_CONTACTS.UPDATE, PERMS_CONTACTS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS_CONTACTS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_CONTACTS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-200">
                    <strong>{contact.name}</strong>
                </td>
                <td className="w-200">{contact.position}</td>
                <td className="w-160">{contact.phone}</td>
                <td className="w-240">{contact.email}</td>
                <td className="w-max wm-200 wrapped">{contact.note}</td>
            </tr>
        )
    }
}

export default injectIntl(CustomersContactsListRow)
