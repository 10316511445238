import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'

import * as formatters from '../../../common/formatters'
import { getListItemsCountMessage } from '../../../common/helpers'

import * as notificationsActionCreators from '../actionCreators'
import { createGetLoadingSelector, createGetNotificationsSelector, createGetSettingsSelector } from '../selectors'
import WSNotificationsListLine from './WSNotificationsListLine'
import { icons } from '../config'

class WSNotificationsList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filters: {},
        }

        this.setCategory = this.setCategory.bind(this)
        this.markNotificationAsRead = this.markNotificationAsRead.bind(this)
    }

    componentWillMount() {
        this.props.actions.fetchNotifications(this.state.filters)
        this.props.actions.fetchNotificationsSettings()
    }

    onPageChange = page => {
        this.props.actions.fetchNotifications(this.state.filters, page.selected)
    }

    /**
     * Sets the category to filter
     *
     * @param {Event|MouseEvent} e Event which has fired this method (typically button click)
     * @param {string|null} category Category name to filter
     * @returns {void}
     */
    setCategory(e, category) {
        e.preventDefault()
        this.setState({ filters: category ? { category: { type: 'equal', value: category } } : {} }, () =>
            this.props.actions.fetchNotifications(this.state.filters)
        )
    }

    /**
     * Marks given notification as read
     *
     * @param {Event|MouseEvent} e Event
     * @param {number} timestamp Timestamp identifier
     * @returns {void}
     */
    markNotificationAsRead(e, timestamp) {
        this.props.actions.markNotificationAsReadStart(timestamp)
        e.stopPropagation()
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.notifications.heading' }))

        const data = this.props.data
        const settings = this.props.settings

        const rows = data && data.data
        const page = data && data.current_page ? data.current_page - 1 : 0
        const total = data && data.total ? data.total : 0

        return (
            <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                <div id="main-wrapper h-1500">
                    <div className="panel panel-white">
                        <div className="panel-body">
                            <div className="row m-t-md">
                                <div className="col-md-2">
                                    <ul className="list-unstyled mailbox-nav">
                                        <li key="all" className={this.state.category === null ? 'active' : null}>
                                            <a href="#" onClick={e => this.setCategory(e, null)}>
                                                <i className="fa fa-comments" />
                                                {this.props.intl.formatMessage({ id: 'notifications.allItems' })}{' '}
                                            </a>
                                        </li>
                                        {Object.keys(settings).map(settingKey =>
                                            settings[settingKey] ? (
                                                <li
                                                    key={settingKey}
                                                    className={this.state.category && this.state.category === settingKey ? 'active' : null}
                                                >
                                                    <a href="#" onClick={e => this.setCategory(e, settingKey)}>
                                                        <i className={icons[settingKey]} />
                                                        {this.props.intl.formatMessage({ id: `fields.notifications_${settingKey}` })}{' '}
                                                    </a>
                                                </li>
                                            ) : null
                                        )}
                                    </ul>
                                </div>
                                <div className="col-md-10">
                                    <div className="mailbox-content">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-right" colSpan="2" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows && rows.length ? (
                                                    rows.map(notification => (
                                                        <WSNotificationsListLine
                                                            key={notification.timestamp}
                                                            notification={notification}
                                                            markAsRead={this.markNotificationAsRead}
                                                            redirect={this.props.redirect}
                                                            loading={this.props.loading}
                                                        />
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5">
                                                            <FormattedMessage id="notifications.noItemsFound" />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pull-left m-l-xxs m-t-md">
                                        <FormattedMessage id="pagination.totalRecords" />: {getListItemsCountMessage(0, 0, total)}
                                    </div>

                                    <ReactPaginate
                                        containerClassName="pagination"
                                        breakLabel={<span className="disabled">...</span>}
                                        activeClassName="active"
                                        pageCount={Math.ceil(total / 50)}
                                        pageRangeDisplayed={10}
                                        marginPagesDisplayed={2}
                                        forcePage={page}
                                        onPageChange={this.onPageChange}
                                        previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                        nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: createGetNotificationsSelector()(state),
        fetching: createGetLoadingSelector(),
        settings: createGetSettingsSelector()(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...notificationsActionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(WSNotificationsList)
)
