import React from 'react'
import { Route, Switch } from 'react-router'
import WarehousingIssuesList from './WarehousingIssuesList'
import WarehousingIssuesEdit from './WarehousingIssuesEdit'

const WarehousingIssues = props => (
    <Switch>
        <Route exact path={props.match.url} component={WarehousingIssuesList} />
        <Route exact path={`${props.match.url}/new`} component={WarehousingIssuesEdit} />
        <Route exact path={`${props.match.url}/:warehousingIssueID`} component={WarehousingIssuesEdit} />
    </Switch>
)

export default WarehousingIssues
