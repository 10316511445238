import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import { DispatchersBox } from '../../../common/colored_box'

class VehiclesHistoryVehiclesRow extends Component {
    onClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onVehicleSelect && this.props.onVehicleSelect(this.props.vehicle)
    }

    render() {
        const vehicle = this.props.vehicle
        const selectedVehicle = this.props.selectedVehicle
        const dispatcher = this.props.dispatcher

        return (
            <tr onClick={this.onClick} className={`row-selectable ${selectedVehicle && selectedVehicle.id === vehicle.id ? 'row-selected' : ''}`}>
                <td className="w-140">{vehicle.registration && <DispatchersBox dispatcher={dispatcher}>{vehicle.registration}</DispatchersBox>}</td>
                <td className="w-max wm-200">{vehicle.vin}</td>
                <td className="w-120">{dispatcher && dispatcher.getShortFullName()}</td>
                <td className="w-80 text-center">{formatters.boolFormatter(vehicle.is_active)}</td>
                <td className="w-40 table-buttons text-center">
                    <button className="far fa-arrow-circle-right text-gray" />
                </td>
            </tr>
        )
    }
}

export default VehiclesHistoryVehiclesRow
