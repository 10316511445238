import { Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
    }),
    current: Map({
        fetching: false,
        saving: false,
        error: null,
        id: null,
    }),
    deletion: Map({
        inProgress: false,
        error: null,
    }),
    costCenters: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    bankAccounts: Map({
        data: [],
        fetching: false,
        error: null,
    }),
    defaultBankAccounts: Map({
        saving: false,
        error: null,
    }),
    numberings: Map({
        data: [],
        fetching: false,
        error: null,
    }),
})

function companies(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], action.payload)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // One
        case actionTypes.FETCH_ONE_STARTED:
            return state.setIn(['current', 'fetching'], true)
        case actionTypes.FETCH_ONE_FULFILLED:
        case actionTypes.FETCH_ONE_REJECTED:
        case actionTypes.FETCH_ONE_CANCELLED:
            return state.setIn(['current', 'fetching'], false)

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], true).setIn(['current', 'error'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['current', 'id'], action.payload.get('id'))
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false).setIn(['current', 'error'], action.payload)
            })

        // Delete
        case actionTypes.DELETE_STARTED:
            return state.setIn(['deletion', 'inProgress'], true)
        case actionTypes.DELETE_FULFILLED:
            return state.deleteIn(['all', 'objects', action.payload]).withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], null)
            })
        case actionTypes.DELETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], action.payload)
            })

        // Fetch company cost centers
        case actionTypes.FETCH_COST_CENTERS_STARTED:
            return state.withMutations(map => {
                map.setIn(['costCenters', 'fetching'], true).setIn(['costCenters', 'error'], null)
            })
        case actionTypes.FETCH_COST_CENTERS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['costCenters', 'fetching'], false)
                    .setIn(['costCenters', 'error'], null)
                    .setIn(['costCenters', 'data'], action.payload)
            })
        case actionTypes.FETCH_COST_CENTERS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['costCenters', 'fetching'], false)
                    .setIn(['costCenters', 'error'], action.payload)
                    .setIn(['costCenters', 'data'], null)
            })


        // Fetch settings
        case actionTypes.FETCH_SETTINGS_STARTED:
            return state.withMutations(map => {
                map.setIn(['settings', 'fetching'], true).setIn(['settings', 'error'], null)
            })
        case actionTypes.FETCH_SETTINGS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['settings', 'fetching'], false)
                    .setIn(['settings', 'error'], null)
                    .setIn(['settings', 'data'], action.payload)
            })
        case actionTypes.FETCH_SETTINGS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['settings', 'fetching'], false)
                    .setIn(['settings', 'error'], action.payload)
                    .setIn(['settings', 'data'], null)
            })

        // Save setting
        case actionTypes.SAVE_SETTING_STARTED:
            return state.withMutations(map => {
                map.setIn(['settings', 'saving'], true).setIn(['settings', 'error'], null)
            })
        case actionTypes.SAVE_SETTING_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['settings', 'saving'], false)
                    .setIn(['settings', 'error'], null)
                    .setIn(['settings', 'data'], action.payload)
            })
        case actionTypes.SAVE_SETTING_REJECTED:
            return state.withMutations(map => {
                map.setIn(['settings', 'saving'], false)
                    .setIn(['settings', 'error'], action.payload)
            })

        // Fetch company bank accounts
        case actionTypes.FETCH_BANK_ACCOUNTS_STARTED:
            return state.withMutations(map => {
                map.setIn(['bankAccounts', 'fetching'], true).setIn(['bankAccounts', 'error'], null)
            })
        case actionTypes.FETCH_BANK_ACCOUNTS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['bankAccounts', 'fetching'], false)
                    .setIn(['bankAccounts', 'error'], null)
                    .setIn(['bankAccounts', 'data'], action.payload)
            })
        case actionTypes.FETCH_BANK_ACCOUNTS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['bankAccounts', 'fetching'], false)
                    .setIn(['bankAccounts', 'error'], action.payload)
                    .setIn(['bankAccounts', 'data'], null)
            })

        // Save default bank account
        case actionTypes.SAVE_DEFAULT_BANK_ACCOUNT_STARTED:
            return state.withMutations(map => {
                map.setIn(['defaultBankAccounts', 'saving'], true).setIn(['defaultBankAccounts', 'error'], null)
            })
        case actionTypes.SAVE_DEFAULT_BANK_ACCOUNT_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['defaultBankAccounts', 'saving'], false)
                    .setIn(['defaultBankAccounts', 'error'], null)
                    .setIn(['bankAccounts', 'data'], action.payload)
            })
        case actionTypes.SAVE_DEFAULT_BANK_ACCOUNT_REJECTED:
            return state.withMutations(map => {
                map.setIn(['defaultBankAccounts', 'saving'], false)
                    .setIn(['defaultBankAccounts', 'error'], action.payload)
            })

        // Fetch company bank accounts
        case actionTypes.FETCH_NUMBERINGS_STARTED:
            return state.withMutations(map => {
                map.setIn(['numberings', 'fetching'], true).setIn(['numberings', 'error'], null)
            })
        case actionTypes.FETCH_NUMBERINGS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['numberings', 'fetching'], false)
                    .setIn(['numberings', 'error'], null)
                    .setIn(['numberings', 'data'], action.payload)
            })
        case actionTypes.FETCH_NUMBERINGS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['numberings', 'fetching'], false)
                    .setIn(['numberings', 'error'], action.payload)
                    .setIn(['numberings', 'data'], null)
            })

        default:
            return state
    }
}

export default companies
