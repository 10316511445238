import { createSelector } from 'reselect'

export const getVehicleDocuments = state => state.vehicleDocuments.getIn(['all', 'objects'])

export const createGetVehicleDocumentByIDSelector = id => createSelector([getVehicleDocuments], vehicleDocuments => vehicleDocuments.get(String(id)))

const getCurrentVehicleDocumentStatus = state => state.vehicleDocuments.get('current')

export const createGetCurrentVehicleDocumentStatusSelector = () => createSelector([getCurrentVehicleDocumentStatus], status => status)

export const getVehicleDocumentsFetching = state => state.vehicleDocuments.getIn(['all', 'fetching'])

export const getVehicleDocumentDeleting = state => state.vehicleDocuments.getIn(['deletion', 'inProgress'])

export const getVehicleDocumentDeleteError = state => state.vehicleDocuments.getIn(['deletion', 'error'])

const getDuplicity = state => state.vehicleDocuments.getIn(['all', 'duplicity'])

export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)

export const getVehicleDocumentFiles = state => state.vehicleDocuments.getIn(['files', 'objects'])
export const getVehicleDocumentFilesFetching = state => state.vehicleDocuments.getIn(['files', 'fetching'])
export const getVehicleDocumentFilesSaving = state => state.vehicleDocuments.getIn(['files', 'saving'])
export const getVehicleDocumentFilesDeleting = state => state.vehicleDocuments.getIn(['files', 'deleting'])
export const getVehicleDocumentFilesError = state => state.vehicleDocuments.getIn(['files', 'error'])