import React from 'react'
import { Route, Switch } from 'react-router'
import CustomersList from './CustomersList'
import CustomersEdit from './CustomersEdit'
import CustomersImport from "./CustomersImport";

const Customers = props => (
    <Switch>
        <Route exact path={props.match.url} component={CustomersList} />
        <Route exact path={`${props.match.url}/new`} component={CustomersEdit} />
        <Route exact path={`${props.match.url}/import`} component={CustomersImport} />
        <Route exact path={`${props.match.url}/:customerID`} component={CustomersEdit} />
    </Switch>
)

export default Customers
