import { createSelector } from 'reselect'

export const getCarrierContacts = state => state.carrierContacts.getIn(['all', 'objects'])

export const createGetCarrierContactByIDSelector = id => createSelector([getCarrierContacts], carrierContacts => carrierContacts.get(String(id)))

const getCurrentCarrierContactStatus = state => state.carrierContacts.get('current')
export const createGetCurrentCarrierContactStatusSelector = () => createSelector([getCurrentCarrierContactStatus], status => status)

export const getCarrierContactDeleting = state => state.carrierContacts.getIn(['deletion', 'inProgress'])
export const getCarrierContactDeleteError = state => state.carrierContacts.getIn(['deletion', 'error'])

const getDuplicity = state => state.carrierContacts.getIn(['all', 'duplicity'])
export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)
