import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportVehiclePerformance = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportVehiclePerformanceFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportVehiclePerformanceRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportVehiclePerformanceCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportVehiclePerformance = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportVehiclePerformance = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportVehiclePerformanceDetails = filters => ({
    type: actionTypes.FETCH_DETAILS,
    payload: filters,
})

export const fetchReportVehiclePerformanceDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportVehiclePerformanceDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportVehiclePerformanceDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportVehiclePerformanceDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportVehiclePerformanceDetails = filters => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: filters,
})
