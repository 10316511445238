// Fetch list

export const FETCH = 'customerContacts.FETCH'
export const FETCH_STARTED = 'customerContacts.FETCH_STARTED'
export const FETCH_FULFILLED = 'customerContacts.FETCH_FULFILLED'
export const FETCH_REJECTED = 'customerContacts.FETCH_REJECTED'
export const FETCH_CANCELLED = 'customerContacts.FETCH_CANCELLED'

export const SORTING_CHANGED = 'customerContacts.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'customerContacts.FETCH_ONE'
export const FETCH_ONE_STARTED = 'customerContacts.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'customerContacts.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'customerContacts.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'customerContacts.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'customerContacts.SAVE'
export const SAVE_STARTED = 'customerContacts.SAVE_STARTED'
export const SAVE_FULFILLED = 'customerContacts.SAVE_FULFILLED'
export const SAVE_REJECTED = 'customerContacts.SAVE_REJECTED'
export const SAVE_CANCELLED = 'customerContacts.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'customerContacts.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'customerContacts.DELETE'
export const DELETE_STARTED = 'customerContacts.DELETE_STARTED'
export const DELETE_FULFILLED = 'customerContacts.DELETE_FULFILLED'
export const DELETE_REJECTED = 'customerContacts.DELETE_REJECTED'
export const DELETE_CANCELLED = 'customerContacts.DELETE_CANCELLED'

// Export

export const EXPORT = 'customerContacts.EXPORT'
export const EXPORT_FULFILLED = 'customerContacts.EXPORT_FULFILLED'
