import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import { Modal } from 'react-bootstrap'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import { Form, InputField, SelectField, SubmitButton } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as transportsActionCreators from '../actionCreators'
import {
    getTransportGoods,
    getTransportGoodsFetching,
    getTransportGoodsSaving,
    getTransportGoodsDeleting,
    getTransportGoodsError,
} from '../selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_GOODS } from '../permissions'

const componentIdentifier = 'transports_goods'
const prerequisites = ['goods_types', 'packaging_types']

class TransportsGoods extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalCreateItem: {
            show: false,
            values: {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // actions
    fetchTransportGoods = transportId => {
        this.props.actions.fetchTransportGoods(transportId)
    }

    saveTransportGoods = data => {
        this.props.actions.saveTransportGoods(this.props.transport.id, data)
    }

    deleteTransportGoods = transportGoodsId => {
        this.props.actions.deleteTransportGoods(this.props.transport.id, transportGoodsId)
    }

    refresh = () => {
        this.fetchTransportGoods(this.props.transport.id)
    }

    // handles
    onClick = (e, transportGoods) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.modalCreateItemOpen(transportGoods)
    }

    onDelete = (e, transportGoods) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.deleteTransportGoods(transportGoods.id)
                this.hideConfirmDialog()
            },
        })
    }

    onCreateItemButtonClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.modalCreateItemOpen({})
    }

    // create item
    modalCreateItemOpen = values => {
        this.setState({
            modalCreateItem: {
                show: true,
                values,
            },
        })
    }

    modalCreateItemClose = () => {
        this.setState({
            modalCreateItem: {
                show: false,
                values: {},
            },
        })
    }

    handleCreateItemChange = values => {
        this.setState({
            modalCreateItem: {
                ...this.state.modalCreateItem,
                values,
            },
        })
    }

    handleCreateItemSubmit = () => {
        this.saveTransportGoods(this.state.modalCreateItem.values)
    }

    componentDidMount() {
        if (this.props.transport) {
            this.fetchTransportGoods(this.props.transport.id)
        }

        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        // Fetching after transport change
        if (nextProps.transport && JSON.stringify(nextProps.transport) !== JSON.stringify(this.props.transport)) {
            this.fetchTransportGoods(nextProps.transport.id)
        }

        // Saving
        if (this.props.transportGoodsSaving && !nextProps.transportGoodsSaving) {
            if (nextProps.transportGoodsError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.saveSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )

                this.modalCreateItemClose()
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.transportGoodsError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Deleting
        if (this.props.transportGoodsDeleting && !nextProps.transportGoodsDeleting) {
            if (nextProps.transportGoodsError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.deleteSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.transportGoodsError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    render() {
        const transport = this.props.transport
        const transportGoods = this.props.transportGoods || []

        if (!transport) {
            return null
        }

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_GOODS)} noPermissionsTab>
                <LoadingOverlay active={this.props.transportGoodsFetching || this.props.transportGoodsDeleting}>
                    <div className="panel-head">
                        <h2 className="pull-left wp-100">
                            <div className="pull-left">
                                <FormattedMessage id="modules.transports.goods" />
                            </div>
                        </h2>
                    </div>

                    {transportGoods.length > 0 ? (
                        <div className="pull-left wp-100 m-b-md">
                            <div className="table-container">
                                <table className="table table-striped table-no-border no-m">
                                    <thead>
                                        <tr>
                                            <PermissionsCheck hasAny={[PERMS_GOODS.DELETE, PERMS_GOODS.UPDATE]}>
                                                <th className="w-60" />
                                            </PermissionsCheck>
                                            <th className="w-max wm-300">
                                                <FormattedMessage id="fields.name" />
                                            </th>
                                            <th className="w-160">
                                                <FormattedMessage id="fields.goodsType" />
                                            </th>
                                            <th className="w-160">
                                                <FormattedMessage id="fields.packagingType" />
                                            </th>
                                            <th className="w-60 text-right">
                                                <FormattedMessage id="fields.count" />
                                            </th>
                                            <th className="w-160 text-right">
                                                <FormattedMessage id="fields.dimensions" />
                                            </th>
                                            <th className="w-80 text-right">
                                                <FormattedMessage id="fields.weight" />
                                            </th>
                                            <th className="w-90 text-right">
                                                <FormattedMessage id="fields.totalWeight" />
                                            </th>
                                            <th className="w-90 text-right">
                                                <FormattedMessage id="fields.totalVolume" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transportGoods.map(transportGood => (
                                            <tr
                                                key={`transportGood-${transportGood.id}`}
                                                onClick={e => {
                                                    this.onClick(e, transportGood)
                                                }}
                                                className="row-selectable"
                                            >
                                                <PermissionsCheck hasAny={[PERMS_GOODS.DELETE, PERMS_GOODS.UPDATE]}>
                                                    <td className="w-60 table-buttons">
                                                        <PermissionsCheck has={[PERMS_GOODS.UPDATE]}>
                                                            <button className="far fa-pencil text-gray m-r-xxs" />
                                                        </PermissionsCheck>
                                                        <PermissionsCheck has={[PERMS_GOODS.DELETE]}>
                                                            <button
                                                                onClick={e => {
                                                                    this.onDelete(e, transportGood)
                                                                }}
                                                                className="far fa-trash text-gray"
                                                            />
                                                        </PermissionsCheck>
                                                    </td>
                                                </PermissionsCheck>
                                                <td>{transportGood.name}</td>
                                                <td>{transportGood.goods_type && transportGood.goods_type.name}</td>
                                                <td>{transportGood.packaging_type && transportGood.packaging_type.name}</td>
                                                <td className="text-right">{formatters.integerFormatter(transportGood.count)}</td>
                                                <td className="text-right">
                                                    {formatters.dimensionsFormatter(transportGood.width, transportGood.height, transportGood.length)}
                                                </td>
                                                <td className="text-right">{formatters.weightFormatter(transportGood.weight)}</td>
                                                <td className="text-right">
                                                    {formatters.weightFormatter(transportGood.weight * transportGood.count)}
                                                </td>
                                                <td className="text-right">
                                                    {formatters.floatFormatter(
                                                        transportGood.width * transportGood.height * transportGood.length * transportGood.count,
                                                        2
                                                    )}{' '}
                                                    m³
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className="alert alert-warning pull-left wp-100">
                            <p>
                                <FormattedMessage id="fields.noGoodsCreated" />
                            </p>
                        </div>
                    )}

                    <PermissionsCheck has={[PERMS_GOODS.CREATE]}>
                        <div className="pull-left wp-100">
                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.onCreateItemButtonClick}>
                                <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                            </button>
                        </div>
                    </PermissionsCheck>
                </LoadingOverlay>

                <ConfirmDialog options={this.state.confirmDialog} />

                <Modal show={this.state.modalCreateItem.show} onHide={this.modalCreateItemClose} className="modal-size-sm-2">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="buttons.createItem" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoadingOverlay active={this.props.transportGoodsSaving}>
                            <PermissionsCheck hasAny={[PERMS_GOODS.READ, PERMS_GOODS.CREATE, PERMS_GOODS.UPDATE]}>
                                <Form
                                    values={this.state.modalCreateItem.values}
                                    validationRules={{}}
                                    onChange={this.handleCreateItemChange}
                                    onSubmit={this.handleCreateItemSubmit}
                                    isEdit={Boolean(this.state.modalCreateItem.values.id)}
                                    className="pull-left wp-100"
                                >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <InputField id="name" label={this.props.intl.formatMessage({ id: 'fields.name' })} />
                                                </div>
                                                <div className="col-md-3">
                                                    <InputField id="count" label={this.props.intl.formatMessage({ id: 'fields.count' })} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <SelectField
                                                        id="goods_type_id"
                                                        label={this.props.intl.formatMessage({ id: 'fields.goodsType' })}
                                                        values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('goods_types'))}
                                                        nullable
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <SelectField
                                                        id="packaging_type_id"
                                                        label={this.props.intl.formatMessage({ id: 'fields.packagingType' })}
                                                        values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('packaging_types'))}
                                                        nullable
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <InputField id="length" label={`${this.props.intl.formatMessage({ id: 'fields.lenght' })} [m]`} />
                                                </div>
                                                <div className="col-md-3">
                                                    <InputField id="width" label={`${this.props.intl.formatMessage({ id: 'fields.width' })} [m]`} />
                                                </div>
                                                <div className="col-md-3">
                                                    <InputField id="height" label={`${this.props.intl.formatMessage({ id: 'fields.height' })} [m]`} />
                                                </div>
                                                <div className="col-md-3">
                                                    <InputField
                                                        id="weight"
                                                        label={`${this.props.intl.formatMessage({ id: 'fields.weight' })} [kg]`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btns-form text-right">
                                        <SubmitButton perms={Object.values(PERMS_GOODS)} isEdit={Boolean(this.state.modalCreateItem.values.id)} />
                                    </div>
                                </Form>
                            </PermissionsCheck>
                        </LoadingOverlay>
                    </Modal.Body>
                </Modal>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        transportGoods: getTransportGoods(state),
        transportGoodsFetching: getTransportGoodsFetching(state),
        transportGoodsSaving: getTransportGoodsSaving(state),
        transportGoodsDeleting: getTransportGoodsDeleting(state),
        transportGoodsError: getTransportGoodsError(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TransportsGoods)
)
