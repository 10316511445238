import React from 'react'
import { Route, Switch } from 'react-router'

import AppNotifications from '../../common/notifications/components/AppNotifications'
import Login from '../../pages/auth/components/Login'

const AppUnauthenticated = props => (
    <main className="page-content">
        <AppNotifications />

        <Switch>
            <Route path={`${props.match.url}/login`} component={Login} />
        </Switch>
    </main>
)

export default AppUnauthenticated
