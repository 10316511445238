import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {FormattedMessage} from "react-intl";
import {numberFormatter} from "../../../common/formatters";

class DietRatesListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render = () => (
        <tr onClick={this.handleClick} className="row-selectable">
            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                <td className="w-60 table-buttons">
                    <PermissionsCheck has={[PERMS.UPDATE]}>
                        <button className="far fa-pencil text-gray m-r-xxs" />
                    </PermissionsCheck>
                    <PermissionsCheck has={[PERMS.DELETE]}>
                        <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                    </PermissionsCheck>
                </td>
            </PermissionsCheck>
            <td className="w-max wm-300"><FormattedMessage id="fields.from" /> {formatters.dateFormatter(this.props.data.date_from)} <FormattedMessage id="fields.to" /> {formatters.dateFormatter(this.props.data.date_to)}</td>
            <td className="w-200 text-center"><FormattedMessage id="fields.from" /> {this.props.data.hours_from} <FormattedMessage id="fields.to" /> {this.props.data.hours_to} <FormattedMessage id="fields.hoursVerbally" /></td>
            <td className="w-80 text-center">{this.props.country && this.props.country.iso_code}</td>
            <td className="w-80 text-center">{formatters.boolFormatter(this.props.data.is_domestic)}</td>
            <td className="w-80 text-center">{formatters.priceFormatter(this.props.data.rate, '0,0.00', '')}</td>
            <td className="w-180 text-center">{this.props.currency && this.props.currency.getFullName()}</td>
        </tr>
    )
}

export default DietRatesListRow
