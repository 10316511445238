import { Observable } from 'rxjs'

import { request, parseAPIError } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchReportTransportOrdersEpic = (action$, store) => {
    return action$.ofType(actionTypes.FETCH).switchMap(() =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request({
                method: 'POST',
                path: `report-transport-orders`,
                body: {
                    settings: store.getState().reportTransportOrders.getIn(['list', 'settings']),
                },
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportTransportOrdersFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportTransportOrdersRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    )
}

export default [fetchReportTransportOrdersEpic]
