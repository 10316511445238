import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import TableFilterField from './TableFilterField'

class TableFilterBooleanField extends Component {
    handleChange = event => {
        this.props.onChange(this.props.identifier, this.props.type, event.target.value)
    }

    render() {
        let value = this.props.filters.getIn([this.props.identifier, 'value'])

        if (value === undefined) {
            value = ''
        }

        const values = [
            { id: 1, name: this.props.intl.formatMessage({ id: 'fields.yes' }) },
            { id: 0, name: this.props.intl.formatMessage({ id: 'fields.no' }) },
        ]

        return (
            <TableFilterField>
                <select id={`filter_${this.props.identifier}`} value={value} onChange={this.handleChange} className="form-control">
                    <option value="">-</option>
                    {values.map(item => (
                        <option key={`field-${this.props.id}-${item.id}`} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
            </TableFilterField>
        )
    }
}

export default injectIntl(TableFilterBooleanField)
