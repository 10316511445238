import React, { Component } from 'react'

import Field from './Field'

// eslint-disable-next-line react/prefer-stateless-function
class SelectField extends Component {
    render() {
        const options = this.props.values

        if (this.props.nullable) {
            options.unshift({
                id: null,
                name: '-',
            })
        }

        let value

        if (this.props.hasOwnProperty('getValue')) {
            value = this.props.getValue() ? String(this.props.getValue()) : String(this.props.defaultValue)
        } else {
            value = this.props.value ? this.props.value : this.props.defaultValue
        }

        return (
            <Field {...this.props}>
                <select value={value} onChange={this.props.onChange} className={this.props.className} disabled={this.props.readonly ? this.props.readonly() : false}>
                    {options.map(val => (
                        <option key={`${this.props.id}-option-${val.id}`} value={val.id}>
                            {val.name}
                        </option>
                    ))}
                </select>
            </Field>
        )
    }
}

export default SelectField
