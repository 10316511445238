import * as actionTypes from './actionTypes'

// Fetch

export const fetchVehicleTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchVehicleTypesFulfilled = vehicleTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: vehicleTypes,
})

export const fetchVehicleTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchVehicleTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchVehicleType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchVehicleTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchVehicleTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchVehicleTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveVehicleType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveVehicleTypeFulfilled = vehicleType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: vehicleType,
})

export const saveVehicleTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteVehicleType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteVehicleTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteVehicleTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportVehicleTypes = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
