import { Record } from 'immutable'

export class CompanyBankAccount extends Record({
    id: undefined,
    name: undefined,
    number: undefined,
    bank_code: undefined,
    iban: undefined,
    swift: undefined,
    note: undefined,
    company_id: undefined,
    currency_id: undefined,
    is_factoring: undefined,
    factoring_company_name: undefined,
    factoring_company_reg_number: undefined,
}) {}
