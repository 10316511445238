import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import moment from 'moment'

import * as constants from '../constants'
import {
    convertCSDatetimeToISO,
    convertCSDatetimeToMoment,
    convertISODatetimeToCS,
    convertISODatetimeToMoment,
    getFirstExistValue,
    getPrerequisitesArray,
    url,
    isReadOnlyPermission,
} from '../../../common/helpers'
import { ConfirmDialog } from '../../../common/confirm_dialog'
import { datetimeFormatter, datetimeISOFormatter, datetimePeriodFormatter, gpsFormatter } from '../../../common/formatters'
import { SelectField, InputField, CheckboxField } from '../../../common/form'

import TransportsEditRoutePoint from './TransportsEditRoutePoint'
import TransportsEditRoutePointEdit from './TransportsEditRoutePointEdit'
import TransportsRoutesSummary from './TransportsRoutesSummary'
import * as config from '../../../common/config'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_TO_VEHICLE, PERMS_EVENTS, PERMS_ROUTES } from '../permissions'
import {CustomDatePicker} from "../../../common/custom_date_picker";

const SortableItem = SortableElement(
    ({
        transport,
        point,
        pointType,
        totalPlannedDistance,
        totalRealDistance,
        eventDataTypes,
        prevPoint,
        createGetTransportPointStateByIDSelector,
        createGetDriverByIDSelector,
        createGetVehicleByIDSelector,
        handleEditButtonClicked,
        handleDeleteButtonClicked,
        handleCreateEventButtonClicked,
        handleEditEventButtonClicked,
        handleDeleteEventButtonClicked,
        handleChangeStateButtonClicked,
        handleCreateEvents,
        handleCorrectPointEventTimes
    }) => (
        <div>
            <TransportsEditRoutePoint
                key={point.id}
                transport={transport}
                point={point}
                pointType={pointType}
                totalPlannedDistance={totalPlannedDistance}
                totalRealDistance={totalRealDistance}
                eventDataTypes={eventDataTypes}
                prevPoint={prevPoint}
                createGetTransportPointStateByIDSelector={createGetTransportPointStateByIDSelector}
                createGetDriverByIDSelector={createGetDriverByIDSelector}
                createGetVehicleByIDSelector={createGetVehicleByIDSelector}
                handleEditButtonClicked={handleEditButtonClicked}
                handleDeleteButtonClicked={handleDeleteButtonClicked}
                handleCreateEventButtonClicked={handleCreateEventButtonClicked}
                handleEditEventButtonClicked={handleEditEventButtonClicked}
                handleDeleteEventButtonClicked={handleDeleteEventButtonClicked}
                handleChangeStateButtonClicked={handleChangeStateButtonClicked}
                handleCreateEvents={handleCreateEvents}
                handleCorrectPointEventTimes={handleCorrectPointEventTimes}
            />
        </div>
    )
)

const SortableList = SortableContainer(
    ({
        transport,
        points,
        pointTypes,
        eventDataTypes,
        createGetTransportPointStateByIDSelector,
        createGetDriverByIDSelector,
        createGetVehicleByIDSelector,
        handleEditPoint,
        handleDeletePoint,
        handleCreateEvent,
        handleEditEvent,
        handleDeleteEvent,
        handleChangeStateButtonClicked,
        handleCreateEvents,
        handleCorrectPointEventTimes,
    }) => {
        let totalPointPlannedDistance = 0
        let totalPointRealDistance = 0
        let prevPointTemp = null

        return (
            <div className="display-inline wp-100">
                {points.map((point, pointIndex) => {
                    const totalPlannedDistance = (totalPointPlannedDistance += point.planned_distance ? point.planned_distance : 0)
                    const totalRealDistance = (totalPointRealDistance += point.real_distance ? point.real_distance : 0)
                    const prevPoint = prevPointTemp

                    if (point.type_id !== constants.TRANSPORT_POINT_TYPE_WAYPOINT && point.type_id !== constants.TRANSPORT_POINT_TYPE_MOVE) {
                        prevPointTemp = point
                    }

                    return (
                        <SortableItem
                            // eslint-disable-next-line react/no-array-index-key
                            key={`sortable-item-${pointIndex}`}
                            index={pointIndex}
                            transport={transport}
                            point={point}
                            pointType={pointTypes[point.type_id]}
                            totalPlannedDistance={totalPlannedDistance}
                            totalRealDistance={totalRealDistance}
                            eventDataTypes={eventDataTypes}
                            prevPoint={prevPoint}
                            createGetTransportPointStateByIDSelector={createGetTransportPointStateByIDSelector}
                            createGetDriverByIDSelector={createGetDriverByIDSelector}
                            createGetVehicleByIDSelector={createGetVehicleByIDSelector}
                            handleEditButtonClicked={() => handleEditPoint(pointIndex)}
                            handleDeleteButtonClicked={() => handleDeletePoint(pointIndex)}
                            handleCreateEventButtonClicked={(eventTypeId, driverId) => handleCreateEvent(point, eventTypeId, driverId)}
                            handleEditEventButtonClicked={event => handleEditEvent(event)}
                            handleDeleteEventButtonClicked={event => handleDeleteEvent(event)}
                            handleChangeStateButtonClicked={stateId => handleChangeStateButtonClicked(point, stateId)}
                            handleCreateEvents={() => handleCreateEvents(point, pointTypes[point.type_id])}
                            handleCorrectPointEventTimes={() => handleCorrectPointEventTimes(point)}
                        />
                    )
                })}
            </div>
        )
    }
)

class TransportsEditRoute extends Component {
    pointTypes = {}
    eventTypes = constants.TRANSPORT_EVENT_TYPES_DEFINITION

    state = {
        showPointModal: false,
        showRoutesModal: false,
        showEventModal: false,
        pointType: null,
        pointIndexToEdit: null,
        points: [],
        event: {
            id: null,
            typeId: null,
            datetime: null,
            driverId: null,
            data: {},
            point: null,
        },
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalFinishQuickly: {
            show: false,
            driverId: null,
            point: null,
            pointType: null,
            events: [],
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    setPoints = (points, callback) => {
        this.setState(
            {
                points,
            },
            () => {
                this.props.handleRouteChanged && this.props.handleRouteChanged(this.state.points)
                callback && callback()
            }
        )
    }

    handleCreatePoint = pointType => {
        this.setState({
            pointType,
            pointIndexToEdit: null,
        })
        this.openPointModal()
    }

    handleEditPoint = pointIndex => {
        this.setState({ pointIndexToEdit: pointIndex })
        this.openPointModal()
    }

    handleDeletePoint = pointIndex => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.deletePoint.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.deletePoint.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.deletePoint.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.deletePoint.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.deletePoint(pointIndex)
                this.hideConfirmDialog()
            },
        })
    }

    deletePoint = pointIndex => {
        this.setPoints(this.state.points.filter((item, key) => key !== pointIndex))
    }

    closePointModal = () => {
        this.setState({ showPointModal: false })
    }

    openPointModal = () => {
        this.setState({ showPointModal: true })
    }

    handlePointChanged = values => {
        const points = [...this.state.points]

        if (this.state.pointIndexToEdit !== null) {
            points[this.state.pointIndexToEdit] = values
        } else {
            points.push({
                ...values,
                type_id: this.state.pointType,
            })
        }

        this.setPoints(points, this.closePointModal)
    }

    handleSortEnd = ({ oldIndex, newIndex }) => {
        this.setPoints(arrayMove(this.state.points, oldIndex, newIndex))
    }

    closeRoutesModal = () => {
        this.setState({ showRoutesModal: false })
    }

    openRoutesModal = () => {
        this.props.handleLoadRealVehiclePositions && this.props.handleLoadRealVehiclePositions()

        this.setState({ showRoutesModal: true })
    }

    transportCanBeSentToVehicle = () => {
        const transport = this.props.transport
        const vehicleDataSource = this.props.vehicleDataSource

        return (
            vehicleDataSource &&
            vehicleDataSource.logistic_data_source_id &&
            vehicleDataSource.logistic_data_id &&
            (transport.transport_state_id === constants.TRANSPORT_STATE_ORDER ||
                transport.transport_state_id === constants.TRANSPORT_STATE_PLANNED ||
                transport.transport_state_id === constants.TRANSPORT_STATE_SENT ||
                transport.transport_state_id === constants.TRANSPORT_STATE_IN_PROCESS)
        )
    }

    handleSendToVehicle = () => {
        this.props.handleSendToVehicle && this.props.handleSendToVehicle()
    }

    handleSetPredepartureCheck = value => {
        this.props.handlePredepartureCheckChanged && this.props.handlePredepartureCheckChanged(value)
    }

    handleSetPreloadingInfo = (key, value) => {
        this.props.handlePreloadingChanged && this.props.handlePreloadingChanged(key, value)
    }

    closeEventModal = () => {
        this.setState({ showEventModal: false })
    }

    openEventModal = () => {
        this.setState({ showEventModal: true })
    }

    handleCreateEvent = (point, eventTypeId, driverId) => {
        this.setState({
            event: {
                id: null,
                typeId: eventTypeId,
                datetime: moment().format('DD.MM.YYYY HH:mm'),
                driverId,
                data: {},
                point,
            },
        })
        this.openEventModal()
    }

    handleEditEvent = event => {
        const data = {}
        if (event.transport_event_data) {
            event.transport_event_data.forEach(item => {
                const field = constants.TRANSPORT_EVENT_DATA_TYPES_DEFINITION[item.type_id]

                if (field.type === 'time') {
                    data[item.type_id] = convertISODatetimeToCS(item.value)
                } else {
                    data[item.type_id] = item.value
                }
            })
        }

        this.setState({
            event: {
                id: event.id,
                typeId: event.type_id,
                datetime: convertISODatetimeToCS(event.datetime),
                driverId: event.driver_id,
                data,
                point: null,
            },
        })
        this.openEventModal()
    }

    handleDeleteEvent = event => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.deleteEvent.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.deleteEvent.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.deleteEvent.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.deleteEvent.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.deleteEvent(event)
                this.hideConfirmDialog()
            },
        })
    }

    deleteEvent = event => {
        this.props.handleDeleteEvent &&
            this.props.handleDeleteEvent({
                id: event.id,
                transport_id: this.props.transport && this.props.transport.id,
            })
    }

    handleEventSetDatetime = value => {
        this.setState({
            event: {
                ...this.state.event,
                datetime: value,
            },
        })
    }

    handleEventSetDriver = value => {
        this.setState({
            event: {
                ...this.state.event,
                driverId: value,
            },
        })
    }

    handleEventSetData = (eventDataTypeId, value) => {
        this.setState({
            event: {
                ...this.state.event,
                data: {
                    ...this.state.event.data,
                    [eventDataTypeId]: value,
                },
            },
        })
    }

    handleEventSubmit = () => {
        const data = {}
        if (this.state.event.data) {
            Object.keys(this.state.event.data).forEach(eventDataId => {
                const value = this.state.event.data[eventDataId]
                const field = constants.TRANSPORT_EVENT_DATA_TYPES_DEFINITION[eventDataId]

                if (field.type === 'time') {
                    data[eventDataId] = convertCSDatetimeToISO(value)
                } else {
                    data[eventDataId] = value
                }
            })
        }

        this.props.handleEventSubmit &&
            this.props.handleEventSubmit({
                id: this.state.event.id,
                transport_id: this.props.transport && this.props.transport.id,
                transport_point_id: this.state.event.point && this.state.event.point.id,
                type_id: this.state.event.typeId,
                datetime: convertCSDatetimeToISO(this.state.event.datetime),
                driver_id: this.state.event.driverId && parseInt(this.state.event.driverId),
                data,
            })
        this.closeEventModal()
    }

    handleChangePointState = (point, stateId) => {
        this.props.handleChangePointState && this.props.handleChangePointState(point, stateId)
    }

    handleCorrectEventTimes = e => {
        e.preventDefault()
        e.stopPropagation()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.correctEventTimes.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.correctEventTimes.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.correctEventTimes.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.correctEventTimes.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.handleCorrectEventTimes && this.props.handleCorrectEventTimes()
                this.hideConfirmDialog()
            },
        })
    }

    handleCorrectPointEventTimes = point => {
        this.props.handleCorrectPointEventTimes && this.props.handleCorrectPointEventTimes(point)
    }

    // finish events quickly
    handleCreateEvents = (point, pointType) => {
        const events = []
        pointType.eventTypes.forEach(eventTypeId => {
            const eventType = constants.TRANSPORT_EVENT_TYPES_DEFINITION[eventTypeId]
            const datetimeFrom = getFirstExistValue([point.bookingFrom, point.specifiedFrom, point.plannedFrom, moment()])

            events.push({
                type_id: eventTypeId,
                datetime: datetimeFormatter(datetimeFrom),
                name: this.props.intl.formatMessage({ id: eventType.name }),
                different: 0,
                save:
                    eventTypeId !== constants.TRANSPORT_EVENT_TYPE_LOADING_WAITING &&
                    eventTypeId !== constants.TRANSPORT_EVENT_TYPE_LOADING_START &&
                    eventTypeId !== constants.TRANSPORT_EVENT_TYPE_LOADING_END &&
                    eventTypeId !== constants.TRANSPORT_EVENT_TYPE_UNLOADING_WAITING &&
                    eventTypeId !== constants.TRANSPORT_EVENT_TYPE_UNLOADING_START &&
                    eventTypeId !== constants.TRANSPORT_EVENT_TYPE_UNLOADING_END,
            })
        })

        this.setState({
            modalFinishQuickly: {
                ...this.state.modalFinishQuickly,
                show: true,
                point,
                pointType,
                events,
            },
        })
    }

    modalFinishQuicklyClose = () => {
        this.setState({
            modalFinishQuickly: {
                ...this.state.modalFinishQuickly,
                show: false,
            },
        })
    }

    modalFinishQuicklySetDriver = driverId => {
        this.setState({
            modalFinishQuickly: {
                ...this.state.modalFinishQuickly,
                driverId,
            },
        })
    }

    modalFinishQuicklySetDatetime = (eventTypeId, value) => {
        const events = this.state.modalFinishQuickly.events.map(event => ({
            ...event,
            datetime: event.type_id === eventTypeId ? value : event.datetime,
        }))

        this.setState({
            modalFinishQuickly: {
                ...this.state.modalFinishQuickly,
                events: this.modalFinishQuicklyGetUpdatedDatetimes(events),
            },
        })
    }

    modalFinishQuicklySetDatetimeDifferent = (eventTypeId, value) => {
        const events = this.state.modalFinishQuickly.events.map(event => ({
            ...event,
            different: event.type_id === eventTypeId ? Math.max(value, 0) : event.different,
        }))

        this.setState({
            modalFinishQuickly: {
                ...this.state.modalFinishQuickly,
                events: this.modalFinishQuicklyGetUpdatedDatetimes(events),
            },
        })
    }

    modalFinishQuicklySetSaveThisEvent = (eventTypeId, value) => {
        const events = this.state.modalFinishQuickly.events.map(event => ({
            ...event,
            save: event.type_id === eventTypeId ? value : event.save,
        }))

        this.setState({
            modalFinishQuickly: {
                ...this.state.modalFinishQuickly,
                events: this.modalFinishQuicklyGetUpdatedDatetimes(events),
            },
        })
    }

    modalFinishQuicklyGetUpdatedDatetimes = events => {
        let prevEvent = null

        return events.map(event => {
            const newEvent = {
                ...event,
                datetime:
                    prevEvent && prevEvent.datetime
                        ? datetimeFormatter(convertCSDatetimeToMoment(prevEvent.datetime).add(event.different, 'minutes'))
                        : event.datetime,
            }

            if (event.save) {
                prevEvent = newEvent
            }

            return newEvent
        })
    }

    modalFinishQuicklySubmit = () => {
        const values = {
            transport_point_id: this.state.modalFinishQuickly.point.id,
            driver_id: this.state.modalFinishQuickly.driverId,
            events: this.state.modalFinishQuickly.events.map(event =>
                event.save
                    ? {
                          type_id: event.type_id,
                          datetime: event.datetime,
                      }
                    : null
            ),
        }

        this.props.handleCreateEvents && this.props.handleCreateEvents(values)
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.points) !== JSON.stringify(this.state.points)) {
            this.setState({
                points: [...nextProps.points],
            })
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const transport = this.props.transport

        const pointToEdit = this.state.points[this.state.pointIndexToEdit]
        const pointTypeId = pointToEdit ? pointToEdit.type_id : this.state.pointType
        const pointType = pointTypeId && this.props.pointTypes[pointTypeId]

        const transportStart = this.props.transportStart
        const transportStartDatetime =
            transportStart &&
            getFirstExistValue([
                transportStart.finishedAt,
                transportStart.arrivedAt,
                transportStart.bookingFrom,
                transportStart.specifiedFrom,
                transportStart.plannedFrom,
            ])

        const firstTransportPoint = this.props.firstTransportPoint
        const firstTransportPointDatetime =
            firstTransportPoint &&
            getFirstExistValue([
                firstTransportPoint.finishedAt,
                firstTransportPoint.arrivedAt,
                firstTransportPoint.bookingFrom,
                firstTransportPoint.specifiedFrom,
                firstTransportPoint.plannedFrom,
            ])

        const transportStartError =
            transportStartDatetime && firstTransportPointDatetime && firstTransportPointDatetime.isBefore(transportStartDatetime)

        const booleanOptions = [
            {
                id: '0',
                name: this.props.intl.formatMessage({ id: 'fields.no' }),
            },
            {
                id: '1',
                name: this.props.intl.formatMessage({ id: 'fields.yes' }),
            },
        ]

        const predepartureCheckEvents = transport && transport.predeparture_check
        const eventDataTypes = this.props.eventDataTypes

        const pointToFinish = this.state.modalFinishQuickly.point
        const isReadOnly = isReadOnlyPermission([PERMS_ROUTES.READ, PERMS_ROUTES.CREATE, PERMS_ROUTES.UPDATE])
        const isReadOnlyEvent = isReadOnlyPermission([PERMS_EVENTS.READ, PERMS_EVENTS.CREATE, PERMS_EVENTS.UPDATE])

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_ROUTES)} noPermissionsTab>
                <div>
                    <div className="panel-head">
                        <h2 className="pull-left wp-100">
                            <div className="pull-left">
                                <FormattedMessage id="modules.transports.routeDefinition" />
                            </div>
                            <PermissionsCheck hasAny={[PERMS_ROUTES.UPDATE, PERMS_ROUTES.CREATE]}>
                                <div className="pull-left m-l-lg">
                                    {this.props.predepartureCheck ? (
                                        <a
                                            href="#"
                                            onClick={() => {
                                                this.handleSetPredepartureCheck(false)
                                            }}
                                            className="text-md text-gray no-text-decoration"
                                        >
                                            <i className="far fa-toggle-on text-success" />{' '}
                                            <FormattedMessage id="modules.transports.requirePredepartureCheck" />
                                        </a>
                                    ) : (
                                        <a
                                            href="#"
                                            onClick={() => {
                                                this.handleSetPredepartureCheck(true)
                                            }}
                                            className="text-md text-gray no-text-decoration"
                                        >
                                            <i className="far fa-toggle-off" /> <FormattedMessage id="modules.transports.requirePredepartureCheck" />
                                        </a>
                                    )}
                                </div>
                            </PermissionsCheck>
                            <PermissionsCheck hasAny={[PERMS_ROUTES.UPDATE, PERMS_ROUTES.CREATE]}>
                                <div className="pull-left m-l-lg">
                                    {this.props.preloading.is_preloaded ? (
                                        <a
                                            href="#"
                                            onClick={() => {
                                                this.handleSetPreloadingInfo('is_preloaded', false)
                                            }}
                                            className="text-md text-gray no-text-decoration"
                                        >
                                            <i className="far fa-toggle-on text-success" /> <FormattedMessage id="modules.transports.preloaded" />
                                        </a>
                                    ) : (
                                        <a
                                            href="#"
                                            onClick={() => {
                                                this.handleSetPreloadingInfo('is_preloaded', true)
                                            }}
                                            className="text-md text-gray no-text-decoration"
                                        >
                                            <i className="far fa-toggle-off" /> <FormattedMessage id="modules.transports.preloaded" />
                                        </a>
                                    )}
                                </div>
                            </PermissionsCheck>
                            <div className="btns-right" style={{ marginTop: '-5px' }}>
                                <PermissionsCheck hasAny={[PERMS_ROUTES.UPDATE, PERMS_ROUTES.CREATE]}>
                                    {transport && transport.transport_state_id === constants.TRANSPORT_STATE_FINISHED && (
                                        <a href="#" onClick={this.handleCorrectEventTimes} className="btn btn-default btn-addon">
                                            <i className="far fa-clock" /> <FormattedMessage id="fields.correctEventTimes" />
                                        </a>
                                    )}
                                </PermissionsCheck>
                                {transport && transport.transport_routes && transport.transport_routes.size > 0 && (
                                    <a href="#" onClick={this.openRoutesModal} className="btn btn-default btn-addon m-l-sm">
                                        <i className="far fa-map-marker-alt" /> <FormattedMessage id="modules.transports.showRoutesOnMap" />
                                    </a>
                                )}
                            </div>
                        </h2>
                    </div>

                    <div className="m-b-xs">
                        {this.props.predepartureCheck ? (
                            <div className="panel panel-predeparture-check">
                                <div className="panel-heading">
                                    <span className="pull-left">
                                        <PermissionsCheck hasAny={[PERMS_ROUTES.CREATE, PERMS_ROUTES.UPDATE]}>
                                            <i className="far fa-bars m-r-sm" style={{ visibility: 'hidden' }} />
                                        </PermissionsCheck>
                                        <span>
                                            <FormattedMessage id="fields.predepartureCheck" />
                                        </span>
                                    </span>
                                </div>

                                {predepartureCheckEvents && predepartureCheckEvents.size > 0 && (
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-no-border no-m">
                                                    <thead>
                                                        <tr>
                                                            <th className="w-140">
                                                                <FormattedMessage id="fields.time" />
                                                            </th>
                                                            <th className="w-100">
                                                                <FormattedMessage id="fields.vehicle" />
                                                            </th>
                                                            <th className="w-140">
                                                                <FormattedMessage id="fields.driver" />
                                                            </th>
                                                            <th />
                                                            <th className="w-180 text-center">
                                                                <FormattedMessage id="fields.gps" />
                                                            </th>
                                                            <th className="w-80 text-right">
                                                                <FormattedMessage id="fields.attachments" />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {predepartureCheckEvents.map(event => {
                                                            const driver = event.driver_id && this.props.createGetDriverByIDSelector(event.driver_id)
                                                            const vehicle =
                                                                event.vehicle_id && this.props.createGetVehicleByIDSelector(event.vehicle_id)

                                                            return (
                                                                <tr key={event.id}>
                                                                    <td>{event.datetime && moment(event.datetime).format('DD.MM.YYYY HH:mm')}</td>
                                                                    <td>{vehicle && vehicle.name}</td>
                                                                    <td>{driver && driver.getShortFullName()}</td>
                                                                    <td>
                                                                        {event.transport_event_data &&
                                                                            event.transport_event_data.map((item, key) => {
                                                                                const type = eventDataTypes[item.type_id]
                                                                                return (
                                                                                    <span key={item.id}>
                                                                                        {key > 0 && ', '}
                                                                                        {type &&
                                                                                            `${this.props.intl.formatMessage({ id: type.label })}: `}
                                                                                        {type && type.formatter
                                                                                            ? type.formatter(item.value)
                                                                                            : item.value}
                                                                                        {type && type.unit ? ` ${type.unit}` : ''}
                                                                                    </span>
                                                                                )
                                                                            })}
                                                                    </td>
                                                                    <td className="text-center">{gpsFormatter(event.gps_lat, event.gps_lng)}</td>
                                                                    <td className="text-right">
                                                                        {event.transport_point_files &&
                                                                            event.transport_point_files.map(file =>
                                                                                file.filename ? (
                                                                                    <a
                                                                                        key={file.id}
                                                                                        href={`${config.CFG_API_BASE_URL}/files/transport-documents/${
                                                                                            file.filename
                                                                                        }`}
                                                                                        className="text-gray m-l-xxs"
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        <i className="far fa-file-image" />
                                                                                    </a>
                                                                                ) : (
                                                                                    <span key={file.id} className="text-default m-l-xxs">
                                                                                        <i className="far fa-file-image" />
                                                                                    </span>
                                                                                )
                                                                            )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}

                        {this.props.preloading.is_preloaded ? (
                            <div className="panel panel-predeparture-check">
                                <div className="panel-heading">
                                    <span className="pull-left">
                                        <PermissionsCheck hasAny={[PERMS_ROUTES.CREATE, PERMS_ROUTES.UPDATE]}>
                                            <i className="far fa-bars m-r-sm" style={{ visibility: 'hidden' }} />
                                        </PermissionsCheck>
                                        <span>
                                            <FormattedMessage id="fields.preloading" />
                                        </span>
                                    </span>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="preloading_datetime">
                                                    <FormattedMessage id="fields.dateTime" />
                                                </label>
                                                <CustomDatePicker
                                                    isTimePicker={true}
                                                    id="preloading_datetime"
                                                    timeIntervals={5}
                                                    selected={
                                                        this.props.preloading.datetime && convertISODatetimeToMoment(this.props.preloading.datetime)
                                                    }
                                                    value={this.props.preloading.datetime && convertISODatetimeToCS(this.props.preloading.datetime)}
                                                    onChange={value => {
                                                        this.handleSetPreloadingInfo('datetime', value ? datetimeISOFormatter(value) : '')
                                                    }}
                                                    onChangeRaw={e => {
                                                        this.handleSetPreloadingInfo('datetime', convertCSDatetimeToISO(e.target.value))
                                                    }}
                                                />
                                            </div>
                                            <InputField
                                                id="preloading_address"
                                                label={this.props.intl.formatMessage({ id: 'fields.address' })}
                                                value={this.props.preloading.address}
                                                onChange={value => {
                                                    this.handleSetPreloadingInfo('address', value.target.value)
                                                }}
                                                readonly={() => isReadOnly}
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            <InputField
                                                id="preloading_note"
                                                label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                value={this.props.preloading.note}
                                                onChange={value => {
                                                    this.handleSetPreloadingInfo('note', value.target.value)
                                                }}
                                                readonly={() => isReadOnly}
                                                autosize
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {transportStart ? (
                            <div className={`panel panel-start ${transportStartError && 'panel-start-error'}`}>
                                <div className="panel-heading">
                                    <span className="pull-left">
                                        {transportStartError ? (
                                            <i className="far fa-exclamation-triangle m-r-sm" />
                                        ) : (
                                            <i className="far fa-bars m-r-sm" style={{ visibility: 'hidden' }} />
                                        )}
                                        <span className="type">
                                            <FormattedMessage id="fields.approach" />
                                        </span>
                                        {transportStart.city && (
                                            <span>
                                                {transportStart.country_code} / {transportStart.zipcode} / {transportStart.city}(
                                                {this.props.intl.formatMessage({ id: 'fields.transportNo' })}
                                                <Link to={url(this.props.match, `transports/${transportStart.transport_id}`)}>
                                                    {transportStart.transport_id}
                                                </Link>
                                                )
                                            </span>
                                        )}
                                    </span>
                                    <span className="pull-right">
                                        <span className="m-l-lg w-120 display-inline">
                                            {transportStartDatetime && datetimeFormatter(transportStartDatetime)}
                                        </span>
                                        <PermissionsCheck hasAny={[PERMS_ROUTES.UPDATE, PERMS_ROUTES.CREATE]}>
                                            <a className="m-l-lg" style={{ visibility: 'hidden' }}>
                                                <i className="far fa-pencil" /> <FormattedMessage id="buttons.edit" />
                                            </a>
                                        </PermissionsCheck>
                                        <PermissionsCheck has={[PERMS_ROUTES.DELETE]}>
                                            <a className="m-l-lg" style={{ visibility: 'hidden' }}>
                                                <i className="far fa-trash" /> <FormattedMessage id="buttons.delete" />
                                            </a>
                                        </PermissionsCheck>
                                    </span>
                                </div>
                            </div>
                        ) : null}

                        {this.state.points && (
                            <SortableList
                                transport={transport}
                                points={this.state.points}
                                onSortEnd={this.handleSortEnd}
                                lockAxis="y"
                                useDragHandle
                                lockToContainerEdges
                                helperClass="transport-waypoints"
                                pointTypes={this.props.pointTypes}
                                eventDataTypes={this.props.eventDataTypes}
                                createGetTransportPointStateByIDSelector={this.props.createGetTransportPointStateByIDSelector}
                                createGetDriverByIDSelector={this.props.createGetDriverByIDSelector}
                                createGetVehicleByIDSelector={this.props.createGetVehicleByIDSelector}
                                handleEditPoint={this.handleEditPoint}
                                handleDeletePoint={this.handleDeletePoint}
                                handleCreateEvent={this.handleCreateEvent}
                                handleEditEvent={this.handleEditEvent}
                                handleDeleteEvent={this.handleDeleteEvent}
                                handleChangeStateButtonClicked={this.handleChangePointState}
                                handleCreateEvents={this.handleCreateEvents}
                                handleCorrectPointEventTimes={this.handleCorrectPointEventTimes}
                            />
                        )}
                    </div>

                    <div>
                        <PermissionsCheck hasAny={[PERMS_ROUTES.CREATE, PERMS_ROUTES.UPDATE]}>
                            <span className="dropdown">
                                <DropdownButton
                                    title={this.props.intl.formatMessage({ id: 'buttons.createTransportPoint' })}
                                    id="dropdown-add-transport-point"
                                    dropup={this.state.points && this.state.points.length > 2}
                                >
                                    {Object.keys(this.props.pointTypes).map(
                                        key =>
                                            !this.props.pointTypes[key].notAvailable && (
                                                <MenuItem
                                                    key={`point-menu-item-${key}`}
                                                    eventKey={key}
                                                    onClick={() => {
                                                        this.handleCreatePoint(key)
                                                    }}
                                                >
                                                    {this.props.intl.formatMessage({ id: this.props.pointTypes[key].name })}
                                                </MenuItem>
                                            )
                                    )}
                                </DropdownButton>
                            </span>
                        </PermissionsCheck>
                        {transport && (
                            <PermissionsCheck has={[PERMS_TO_VEHICLE.CREATE]}>
                                <div className="btns-right">
                                    {this.transportCanBeSentToVehicle() && (
                                        <a href="#" onClick={this.handleSendToVehicle} className="btn btn-addon btn-primary m-l-xs">
                                            <i className="far fa-paper-plane" /> <FormattedMessage id="buttons.sendToVehicle" />
                                        </a>
                                    )}
                                </div>
                            </PermissionsCheck>
                        )}
                    </div>

                    <Modal show={this.state.showPointModal} onHide={this.closePointModal} bsSize="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {pointType && this.props.intl.formatMessage({ id: pointToEdit ? pointType.editTitle : pointType.createTitle })}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TransportsEditRoutePointEdit
                                point={pointToEdit}
                                points={this.state.points}
                                pointType={pointToEdit ? pointToEdit.type_id : this.state.pointType}
                                dataFields={pointType && pointType.dataFields}
                                noDatetimes={pointType && pointType.noDatetimes}
                                showBookingTime={pointType && pointType.showBookingTime}
                                cargoType={this.props.cargoType}
                                handlePointChanged={this.handlePointChanged}
                                handleSaveAddress={this.props.handleSaveAddress}
                                onGPSChange={this.props.handleFindAddress}
                                onAddressChange={this.props.handleChangeAddress}
                                selectedAddress={this.props.selectedAddress}
                                onSelectedAddressSet={this.props.onSelectedAddressSet}
                            />
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.showRoutesModal} onHide={this.closeRoutesModal}>
                        <Modal.Header closeButton>
                            <Modal.Title />
                        </Modal.Header>
                        <Modal.Body>
                            <TransportsRoutesSummary
                                transport={transport}
                                transportType={this.props.transportType}
                                transportStart={transport && transport.transport_point_start}
                                points={transport && transport.transport_points}
                                routes={transport && transport.transport_routes}
                                pointTypes={this.props.pointTypes}
                                realVehiclePositions={this.props.realVehiclePositions}
                                handleRecountPlannedRoute={this.props.handleRecountPlannedRoute}
                                handleRecountSpecifiedRoute={this.props.handleRecountSpecifiedRoute}
                                handleRecountRealRoute={this.props.handleRecountRealRoute}
                                handleLoadRealVehiclePositions={this.props.handleLoadRealVehiclePositions}
                            />
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.showEventModal} onHide={this.closeEventModal} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {`${this.props.intl.formatMessage({ id: this.state.event.id ? 'buttons.editEvent' : 'buttons.createEvent' })}: `}
                                {this.state.event.typeId &&
                                    this.eventTypes[this.state.event.typeId] &&
                                    this.props.intl.formatMessage({ id: this.eventTypes[this.state.event.typeId].name })}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <label htmlFor="value_time">
                                    <FormattedMessage id="fields.time" />:
                                </label>
                                <CustomDatePicker
                                    isTimePicker={true}
                                    id="value_time"
                                    timeIntervals={5}
                                    selected={convertCSDatetimeToMoment(this.state.event.datetime)}
                                    value={this.state.event.datetime}
                                    onChange={value => {
                                        this.handleEventSetDatetime(value ? datetimeFormatter(value) : '')
                                    }}
                                    onChangeRaw={e => {
                                        this.handleEventSetDatetime(e.target.value)
                                    }}
                                />
                            </div>
                            <SelectField
                                label={this.props.intl.formatMessage({ id: 'fields.driver' })}
                                values={getPrerequisitesArray(this.props.prerequisites.values.get('drivers'))}
                                value={this.state.event.driverId}
                                onChange={value => {
                                    this.handleEventSetDriver(value.value)
                                }}
                                readonly={() => isReadOnlyEvent}
                            />
                            <div className="row">
                                {this.state.event.typeId &&
                                    this.eventTypes[this.state.event.typeId].dataFields.map(eventDataId => {
                                        const field = constants.TRANSPORT_EVENT_DATA_TYPES_DEFINITION[eventDataId]

                                        let input = (
                                            <InputField
                                                id={`data-${eventDataId}`}
                                                label={`${this.props.intl.formatMessage({ id: field.label })}:`}
                                                value={this.state.event.data[eventDataId]}
                                                onChange={value => {
                                                    this.handleEventSetData(eventDataId, value.target.value)
                                                }}
                                                readonly={() => isReadOnlyEvent}
                                            />
                                        )

                                        if (field.type && field.type === 'bool') {
                                            input = (
                                                <SelectField
                                                    id={`data-${eventDataId}`}
                                                    label={`${this.props.intl.formatMessage({ id: field.label })}:`}
                                                    values={booleanOptions}
                                                    value={this.state.event.data[eventDataId]}
                                                    onChange={value => {
                                                        this.handleEventSetData(eventDataId, value.value)
                                                    }}
                                                    prompt={false}
                                                    readonly={() => isReadOnlyEvent}
                                                />
                                            )
                                        } else if (field.type && field.type === 'select') {
                                            input = (
                                                <SelectField
                                                    id={`data-${eventDataId}`}
                                                    label={`${this.props.intl.formatMessage({ id: field.label })}:`}
                                                    values={field.values}
                                                    value={this.state.event.data[eventDataId]}
                                                    onChange={value => {
                                                        this.handleEventSetData(eventDataId, value.value)
                                                    }}
                                                    prompt={false}
                                                    readonly={() => isReadOnlyEvent}
                                                />
                                            )
                                        } else if (field.type && field.type === 'time') {
                                            input = (
                                                <div className="form-group">
                                                    <label htmlFor="value_datetime">
                                                        <FormattedMessage id={field.label} />:
                                                    </label>
                                                    <CustomDatePicker
                                                        isTimePicker={true}
                                                        id="value_datetime"
                                                        timeIntervals={5}
                                                        selected={convertCSDatetimeToMoment(this.state.event.data[eventDataId])}
                                                        value={this.state.event.data[eventDataId]}
                                                        onChange={value => {
                                                            this.handleEventSetData(eventDataId, value ? datetimeFormatter(value) : '')
                                                        }}
                                                        onChangeRaw={e => {
                                                            this.handleEventSetData(eventDataId, e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }

                                        if (field.unit) {
                                            return (
                                                <div key={eventDataId} className={field.className || 'col-md-12'}>
                                                    <div className="pull-left wp-60">{input}</div>
                                                    <div className="pull-left wp-40 no-label">
                                                        <span className="input-unit">{field.unit}</span>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        return (
                                            <div key={eventDataId} className={field.className || 'col-md-12'}>
                                                {input}
                                            </div>
                                        )
                                    })}
                            </div>
                            <div className="btns-form text-right">
                                <button onClick={this.handleEventSubmit} type="submit" className="btn btn-addon btn-success">
                                    <i className="far fa-check" /> <FormattedMessage id="buttons.save" />
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.modalFinishQuickly.show} onHide={this.modalFinishQuicklyClose} className="modal-size-sm-2">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.finishQuickly" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h4 className="wp-100">
                                {this.state.modalFinishQuickly.pointType &&
                                    this.props.intl.formatMessage({ id: this.state.modalFinishQuickly.pointType.name })}
                            </h4>
                            {pointToFinish && (
                                <div className="row m-b-lg">
                                    <div className="col-sm-6">
                                        <span className="text-gray-light">
                                            <FormattedMessage id="fields.address" />:
                                        </span>
                                        <p>
                                            {pointToFinish.name && (
                                                <span>
                                                    <strong>{pointToFinish.name}</strong>
                                                    <br />
                                                </span>
                                            )}
                                            {pointToFinish.street && `${pointToFinish.street},`}
                                            <br />
                                            {pointToFinish.zipcode} {pointToFinish.city && `${pointToFinish.city},`}
                                            <br />
                                            {pointToFinish.country_code}
                                            <br />
                                            {pointToFinish.gps_lat &&
                                                pointToFinish.gps_lng &&
                                                `${this.props.intl.formatMessage({ id: 'fields.gps' })}: ${gpsFormatter(
                                                    pointToFinish.gps_lat,
                                                    pointToFinish.gps_lng
                                                )}`}
                                        </p>
                                    </div>
                                    <div className="col-sm-6">
                                        <span className="text-gray-light">
                                            <FormattedMessage id="fields.times" />:
                                        </span>
                                        <p className="times">
                                            <span className="left">
                                                <FormattedMessage id="fields.planned" />:
                                            </span>{' '}
                                            {datetimePeriodFormatter(pointToFinish.plannedFrom, pointToFinish.plannedTo)}
                                            <br />
                                            <span className="left">
                                                <FormattedMessage id="fields.specified" />:
                                            </span>{' '}
                                            {datetimePeriodFormatter(pointToFinish.specifiedFrom, pointToFinish.specifiedTo)}
                                        </p>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="b-b xs" />
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-sm-7">
                                    <SelectField
                                        label={this.props.intl.formatMessage({ id: 'fields.driver' })}
                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('drivers'))}
                                        value={this.state.modalFinishQuickly.driverId}
                                        onChange={value => {
                                            this.modalFinishQuicklySetDriver(value.value)
                                        }}
                                        readonly={() => isReadOnlyEvent}
                                    />
                                </div>
                            </div>
                            {this.state.modalFinishQuickly.events.map((event, index) => (
                                <div key={`finishQuickly-event-${event.type_id}`} className="form-group">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <label htmlFor={`event_datetime_${event.type_id}`}>{event.name}:</label>
                                            <CustomDatePicker
                                                isTimePicker={true}
                                                id={`event_datetime_${event.type_id}`}
                                                timeIntervals={5}
                                                selected={convertCSDatetimeToMoment(event.datetime)}
                                                value={event.datetime}
                                                onChange={value => {
                                                    this.modalFinishQuicklySetDatetime(event.type_id, value ? datetimeFormatter(value) : '', index)
                                                }}
                                                onChangeRaw={e => {
                                                    this.modalFinishQuicklySetDatetime(event.type_id, e.target.value, index)
                                                }}
                                                disabled={index > 0}
                                                readonly={() => isReadOnlyEvent}
                                            />
                                        </div>
                                        {index > 0 && (
                                            <div className="col-sm-3">
                                                <InputField
                                                    id={`event_datetime_different_${event.type_id}`}
                                                    label={this.props.intl.formatMessage({ id: 'fields.differentInMinutes' })}
                                                    value={event.different}
                                                    onChange={e => {
                                                        this.modalFinishQuicklySetDatetimeDifferent(event.type_id, e.target.value)
                                                    }}
                                                    type="number"
                                                    step={5}
                                                    readonly={() => !event.save}
                                                />
                                            </div>
                                        )}
                                        {index > 0 &&
                                            (event.type_id === constants.TRANSPORT_EVENT_TYPE_LOADING_WAITING ||
                                                event.type_id === constants.TRANSPORT_EVENT_TYPE_LOADING_START ||
                                                event.type_id === constants.TRANSPORT_EVENT_TYPE_LOADING_END ||
                                                event.type_id === constants.TRANSPORT_EVENT_TYPE_UNLOADING_WAITING ||
                                                event.type_id === constants.TRANSPORT_EVENT_TYPE_UNLOADING_START ||
                                                event.type_id === constants.TRANSPORT_EVENT_TYPE_UNLOADING_END) && (
                                                <div className="col-sm-5" style={{ paddingTop: '15px' }}>
                                                    <CheckboxField
                                                        id={`event_datetime_save_${event.type_id}`}
                                                        label={this.props.intl.formatMessage({ id: 'fields.saveThisEvent' })}
                                                        value={event.save}
                                                        onChange={e => {
                                                            this.modalFinishQuicklySetSaveThisEvent(event.type_id, e.target.checked)
                                                        }}
                                                        readonly={() => isReadOnlyEvent}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                </div>
                            ))}
                            <div className="btns-form text-right">
                                <button onClick={this.modalFinishQuicklySubmit} type="submit" className="btn btn-addon btn-success m-r-xs">
                                    <i className="far fa-check" /> <FormattedMessage id="buttons.save" />
                                </button>
                                <button className="btn btn-default" onClick={this.modalFinishQuicklyClose}>
                                    <FormattedMessage id="buttons.cancel" />
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <ConfirmDialog options={this.state.confirmDialog} />
                </div>
            </PermissionsCheck>
        )
    }
}

export default injectIntl(TransportsEditRoute)
