import React from 'react'
import { Route, Switch } from 'react-router'
import CarriersList from './CarriersList'
import CarriersEdit from './CarriersEdit'

const Carriers = props => (
    <Switch>
        <Route exact path={props.match.url} component={CarriersList} />
        <Route exact path={`${props.match.url}/new`} component={CarriersEdit} />
        <Route exact path={`${props.match.url}/:carrierID`} component={CarriersEdit} />
    </Switch>
)

export default Carriers
