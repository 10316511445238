import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Form, InputField } from '../../../common/form'
import { getAuthInProgress, getAuthError } from '../selectors'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { url } from '../../../common/helpers'
import * as actionCreators from '../actionCreators'

class ForgottenPasswordForm extends Component {
    validationRules = {
        email: value => validator.isEmpty(value) && this.props.intl.formatMessage({ id: 'validator.email.required' }),
    }

    handleSubmit = values => {
        values.language = this.props.intl.locale ? this.props.intl.locale : 'cs'
        this.props.actions.forgottenPassword(values)
    }

    componentWillReceiveProps(nextProps) {
        // Login successful
        if (this.props.inProgress && !nextProps.inProgress && nextProps.loginError === null) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                    message: this.props.intl.formatMessage({
                        id: 'alerts.messages.mailSend',
                    }),
                    position: 'tc',
                },
                'success'
            )
            this.props.redirect('/auth/login')
        }

        // Login error
        if (this.props.inProgress && !nextProps.inProgress && nextProps.loginError !== null) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: this.props.intl.formatMessage({
                        id: 'alerts.messages.mailSendFiled',
                    }),
                    position: 'tc',
                },
                'error'
            )
        }
    }

    render() {
        return (
            <div>
                <p className="text-center m-t-md">
                    <FormattedMessage id="login.forgottenPassword" />
                </p>
                <Form values={{}} validationRules={this.validationRules} onSubmit={this.handleSubmit}>
                    <LoadingOverlay active={this.props.inProgress} dark>
                        <InputField
                            id="email"
                            placeholder={this.props.intl.formatMessage({
                                id: 'fields.email',
                            })}
                        />
                        <button type="submit" className="btn btn-success btn-block">
                            <FormattedMessage id="buttons.send" />
                        </button>
                    </LoadingOverlay>
                </Form>
                <p className="text-center">
                    <Link to={url(this.props.match, 'auth/login')}>
                        <FormattedMessage id="login.login" />
                    </Link>
                </p>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        inProgress: getAuthInProgress(state),
        loginError: getAuthError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ForgottenPasswordForm)
)
