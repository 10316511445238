import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'

import * as formatters from '../../../common/formatters'
import { Form, InputField, SelectField, SubmitButton, SuggestField } from '../../../common/form'
import {
    url, handleCommonEditActions, getPrerequisitesArray, convertCSDateToISO, convertISODateToCS, convertCSDateToMoment, getPrerequisitesPairs
} from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as warehousingReceiptsActionCreators from '../actionCreators'
import {
    createGetWarehousingReceiptByIDSelector,
    createGetCurrentWarehousingReceiptStatusSelector,
    getWarehousingItemData,
    getWarehousingItemFetching,
    getWarehousingItemError
} from '../selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import moment from "moment";
import { CustomDatePicker } from "../../../common/custom_date_picker";
import { fetchItemAutocomplete } from "../actionCreators";

const componentIdentifier = 'warehousing_receipts_edit'
const prerequisites = ['warehousing_warehouses', 'currencies', 'allowed_customers', 'warehousing_item_codes', 'warehousing_item_names']

class WarehousingReceiptsEdit extends Component {
    defaultValues = {}

    validationRules = {}

    state = {
        values: this.defaultValues,
        datetimes: {
            date: moment().format('DD.MM.YYYY')
        },
        datetimesSet: false,
        items: [],
    }

    warehousingItemKey = null

    setValues = (values, callback) => {
        this.setState({
            values,
        }, callback)
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleDatetimeChange = (key, value) => {
        const newState = {
            datetimes: {
                ...this.state.datetimes,
                [key]: value,
            },
        }

        this.setState(newState)
    }

    handleChangeItemValues = (itemKey, name, value, processAutocomplete) => {
        const items = [...this.state.items]
        items[itemKey] = {
            ...this.state.items[itemKey],
            [name]: value,
        }

        this.setState({
            items,
        })

        if (processAutocomplete) {
            if (name === 'code') {
                this.autocompleteItem(itemKey, { code: value })
            }
            else if (name === 'name') {
                this.autocompleteItem(itemKey, { name: value })
            }
        }
    }

    autocompleteItem = (itemKey, data) => {
        console.log(itemKey, data)

        this.warehousingItemKey = itemKey
        this.props.actions.fetchItemAutocomplete(data)
    }

    handleAddItem = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const items = [...this.state.items]
        items.push({
            id: null,
            code: '',
            name: '',
            units_count: 1,
            unit_price: 0,
        })

        this.setState({
            items,
        })
    }

    handleRemoveItem = (e, itemKey) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const items = [...this.state.items]
        items.splice(itemKey, 1)

        this.setState({
            items,
        })
    }

    setItemsValues = (itemKey, values, callback) => {
        const items = [...this.state.items]

        items[itemKey] = {
            ...this.state.items[itemKey],
            code: (values && values.code) || '',
            name: (values && values.name) || ''
        }

        if (values.unit_price) {
            items[itemKey].unit_price = values.unit_price
        }

        this.setState({
            items,
        }, () => {
            callback && callback()
        })
    }

    handleSubmit = values => {
        console.log(values)
        this.setValues(values, () => {
            this.save()
        })
    }

    save = () => {
        this.props.actions.saveWarehousingReceipt({
            ...this.state.values,
            date: convertCSDateToISO(this.state.datetimes.date),
            items: this.state.items,
        })
    }

    componentDidMount() {
        this.props.match.params.warehousingReceiptID && this.props.actions.fetchWarehousingReceipt(this.props.match.params.warehousingReceiptID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'warehousing-receipts'))
        })

        if (nextProps.warehousingReceipt && !this.state.datetimesSet) {
            this.setState({
                values: nextProps.warehousingReceipt.toJS(),
                datetimes: {
                    date: convertISODateToCS(nextProps.warehousingReceipt.date),
                },
                datetimesSet: true,
            })
        }

        if (nextProps.warehousingReceipt && (JSON.stringify(nextProps.warehousingReceipt) !== JSON.stringify(this.props.warehousingReceipt) || !this.state.values.id)) {
            this.setValues(nextProps.warehousingReceipt.toJS())

            this.setState({
                values: nextProps.warehousingReceipt.toJS(),
                items: nextProps.warehousingReceipt && nextProps.warehousingReceipt.items ? nextProps.warehousingReceipt.items.toJS().map(item => ({
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    units_count: item.units_count || 1,
                    unit_price: item.unit_price
                })) : [],
            })
        }

        // Fetch item autocomplete
        if (this.props.getWarehousingItemFetching && !nextProps.getWarehousingItemFetching && !nextProps.getWarehousingItemError && nextProps.getWarehousingItemData) {
            this.setItemsValues(this.warehousingItemKey, {
                code: nextProps.getWarehousingItemData.code,
                name: nextProps.getWarehousingItemData.name,
                unit_price: nextProps.getWarehousingItemData.price,
            })
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.warehousingReceipts.heading' }))

        const warehousingReceipt = this.props.warehousingReceipt

        const items = this.state.items

        const suggestCodes = []
        this.props.prerequisites.values.get('warehousing_item_codes').map(item => {
            suggestCodes.push({
                value: item.toJS().code
            })
        })

        const suggestNames = []
        this.props.prerequisites.values.get('warehousing_item_names').map(item => {
            suggestNames.push({
                value: item.toJS().name
            })
        })

        return (<PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
            <div className={'page-inner ' + (document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                <div id="main-wrapper">
                    <div className="row hp-100">
                        <div className="col-lg-4" style={{ paddingRight: '0' }}>
                            <div className="panel panel-white">
                                <div className="panel-body panel-padding">
                                    <div className="panel-head">
                                        <h4>
                                            <FormattedMessage id="modules.warehousingReceipts.heading" /> -{' '}
                                            {warehousingReceipt ? <FormattedMessage id="fields.itemEdit" /> :
                                                <FormattedMessage id="fields.itemCreate" />}
                                        </h4>
                                    </div>
                                    <LoadingOverlay
                                        active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching') || this.props.status.get('saving')}
                                    >
                                        <PermissionsCheck hasAny={Object.values(PERMS)}>
                                            <Form
                                                values={this.state.values}
                                                validationRules={this.validationRules}
                                                onChange={this.handleChangeValues}
                                                onSubmit={this.handleSubmit}
                                                isEdit={Boolean(warehousingReceipt)}
                                            >
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="value_date">
                                                                <FormattedMessage id="fields.date" />
                                                            </label>
                                                            <CustomDatePicker
                                                                id="value_date"
                                                                selected={convertCSDateToMoment(this.state.datetimes.validity_from)}
                                                                value={this.state.datetimes.date}
                                                                onChange={value => {
                                                                    this.handleDatetimeChange('date', value ? formatters.dateFormatter(value) : '')
                                                                }}
                                                                onChangeRaw={e => {
                                                                    this.handleDatetimeChange('date', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputField
                                                            id="number"
                                                            label={this.props.intl.formatMessage({ id: 'fields.number' })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <SelectField
                                                            id="warehouse_id"
                                                            label={this.props.intl.formatMessage({ id: 'fields.warehouse' })}
                                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('warehousing_warehouses'), 'id', 'code')}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <SelectField
                                                            id="currency_id"
                                                            label={this.props.intl.formatMessage({ id: 'fields.currency' })}
                                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('currencies'), 'id', 'iso_code')}
                                                        />
                                                    </div>
                                                </div>

                                                <SelectField
                                                    id="customer_id"
                                                    label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('allowed_customers'))}
                                                />

                                                <InputField
                                                    id="note"
                                                    label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                    autosize={true}
                                                />

                                                <div className="btns-form">
                                                    <Link to={url(this.props.match, 'warehousing-receipts')} className="btn btn-addon btn-default">
                                                        <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                    </Link>

                                                    <SubmitButton
                                                        perms={Object.values(PERMS)}
                                                        isEdit={Boolean(warehousingReceipt)}
                                                        className="btn-addon pull-right"
                                                    />
                                                </div>
                                            </Form>
                                        </PermissionsCheck>
                                    </LoadingOverlay>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 hp-100" style={{ paddingLeft: '5px' }}>
                            <div className="panel panel-white hp-100 m-b-no">
                                <div className="panel-body panel-padding hp-100">
                                    <div className="panel-head">
                                        <h4>
                                            Položky příjemky
                                        </h4>
                                    </div>
                                    <table className="table table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th className="w-70">
                                                <FormattedMessage id="fields.count" />
                                            </th>
                                            <th className="w-140">
                                                <FormattedMessage id="fields.code" />
                                            </th>
                                            <th className="w-max wm-400">
                                                <FormattedMessage id="fields.name" />
                                            </th>
                                            <th className="w-100 text-right">
                                                <FormattedMessage id="fields.unitPrice" />
                                            </th>
                                            <th className="w-40" />
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {items.map((item, key) => (// eslint-disable-next-line react/no-array-index-key
                                            <tr key={`items-${key}`}>
                                                <td className="text-right">
                                                    <input
                                                        type="text"
                                                        value={item.units_count}
                                                        className="form-control"
                                                        onChange={e => {
                                                            this.handleChangeItemValues(key, 'units_count', e.target.value)
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <SuggestField
                                                        suggestions={suggestCodes}
                                                        noFormGroup={true}
                                                        onChange={(e, {
                                                            newValue,
                                                            method
                                                        }) => {
                                                            if (method === 'enter' || method === 'click' || method === 'type') {
                                                                this.handleChangeItemValues(key, 'code', newValue, method === 'enter' || method === 'click')
                                                            }
                                                        }}
                                                        defaultValue={item.code}
                                                    />
                                                </td>
                                                <td>
                                                    <SuggestField
                                                        suggestions={suggestNames}
                                                        noFormGroup={true}
                                                        onChange={(e, {
                                                            newValue,
                                                            method
                                                        }) => {
                                                            if (method === 'enter' || method === 'click' || method === 'type') {
                                                                this.handleChangeItemValues(key, 'name', newValue, method === 'enter' || method === 'click')
                                                            }
                                                        }}
                                                        defaultValue={item.name}
                                                    />
                                                </td>
                                                <td className="text-right">
                                                    <input
                                                        type="text"
                                                        value={item.unit_price}
                                                        className="form-control text-right"
                                                        onChange={e => {
                                                            this.handleChangeItemValues(key, 'unit_price', e.target.value)
                                                        }}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <a
                                                        href="#"
                                                        className="display-inline m-t-xxs text-gray-light text-md"
                                                        onClick={e => {
                                                            this.handleRemoveItem(e, key)
                                                        }}
                                                    >
                                                        <i className="far fa-trash" />
                                                    </a>
                                                </td>
                                            </tr>))}
                                        </tbody>
                                    </table>
                                    <div className="row m-b-md">
                                        <div className="col-md-6">
                                            <button
                                                className="btn btn-default btn-addon"
                                                onClick={e => {
                                                    this.handleAddItem(e)
                                                }}
                                            >
                                                <i className="far fa-plus" /> <FormattedMessage id="buttons.addItem" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PermissionsCheck>)
    }
}

function mapStateToProps(state, ownProps) {
    return {
        warehousingReceipt: createGetWarehousingReceiptByIDSelector(ownProps.match.params.warehousingReceiptID)(state),
        status: createGetCurrentWarehousingReceiptStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        getWarehousingItemData: getWarehousingItemData(state),
        getWarehousingItemFetching: getWarehousingItemFetching(state),
        getWarehousingItemError: getWarehousingItemError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators({
                ...warehousingReceiptsActionCreators, ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
            }, dispatch),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WarehousingReceiptsEdit))
