import { Record } from 'immutable'

export class PackagingType extends Record({
    id: undefined,
    name: undefined,
    width: undefined,
    height: undefined,
    length: undefined,
    weight: undefined,
}) {}
