export const PERMS = {
    READ: 'carriers.read',
    UPDATE: 'carriers.update',
    DELETE: 'carriers.delete',
    RESTORE: 'carriers.restore',
    CREATE: 'carriers.create',
    EXPORT: 'carriers.export',
}

export const PERMS_CONTACTS = {
    READ: 'carrier_contacts.read',
    UPDATE: 'carrier_contacts.update',
    DELETE: 'carrier_contacts.delete',
    CREATE: 'carrier_contacts.create',
    EXPORT: 'carrier_contacts.export',
}
