import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportTrailerFuelings = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportTrailerFuelingsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportTrailerFuelingsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportTrailerFuelingsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportTrailerFuelings = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportTrailerFuelings = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportTrailerFuelingDetails = (trailerId, dateFrom, dateTo) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        trailerId,
        dateFrom,
        dateTo,
    },
})

export const fetchReportTrailerFuelingDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportTrailerFuelingDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportTrailerFuelingDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear details

export const clearReportTrailerFuelingDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export details

export const exportReportTrailerFuelingDetails = (trailerId, dateFrom, dateTo, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        trailerId,
        dateFrom,
        dateTo,
        filters,
    },
})
