import React, { Component } from 'react'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class BlockedIpsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render = () => (
        <tr onClick={this.handleClick} className="row-selectable">
            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                <td className="w-60 table-buttons">
                    <PermissionsCheck has={[PERMS.UPDATE]}>
                        <button className="far fa-pencil text-gray m-r-xxs" />
                    </PermissionsCheck>
                    <PermissionsCheck has={[PERMS.DELETE]}>
                        <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                    </PermissionsCheck>
                </td>
            </PermissionsCheck>
            <td className="w-30 text-center">
                <i className={`fas ${this.props.data.is_blocked === 1 ? 'fa-times text-danger' : 'fa-check text-success'}`} />
            </td>
            <td className="w-300">{this.props.data.name}</td>
            <td className="w-140">
                {this.props.data.ip_address}/{this.props.data.cidr}
            </td>
            <td className="w-max wm-300">{this.props.data.note}</td>
        </tr>
    )
}

export default BlockedIpsListRow
