export const PERMS = {
    READ: 'trailers.read',
    UPDATE: 'trailers.update',
    DELETE: 'trailers.delete',
    CREATE: 'trailers.create',
    RESTORE: 'trailers.restore',
    EXPORT: 'trailers.export',
}

export const PERMS_HISTORY = {
    READ: 'trailer_history.read',
    UPDATE: 'trailer_history.update',
    DELETE: 'trailer_history.delete',
    CREATE: 'trailer_history.create',
    EXPORT: 'trailer_history.export',
}

export const PERMS_DOCUMENTS = {
    READ: 'trailer_documents.read',
    UPDATE: 'trailer_documents.update',
    DELETE: 'trailer_documents.delete',
    CREATE: 'trailer_documents.create',
    EXPORT: 'trailer_documents.export',
}

export const PERMS_EQUIPMENTS = {
    READ: 'trailer_equipments.read',
    UPDATE: 'trailer_equipments.update',
    DELETE: 'trailer_equipments.delete',
    CREATE: 'trailer_equipments.create',
    EXPORT: 'trailer_equipments.export',
}

export const PERMS_EVENTS = {
    READ: 'trailer_events.read',
    UPDATE: 'trailer_events.update',
    DELETE: 'trailer_events.delete',
    CREATE: 'trailer_events.create',
    EXPORT: 'trailer_events.export',
}
