import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'

// Fetch

const fetchInvoicingOrdersEpic = (action$, store) => {
    const tableIdentifier = 'invoicing_orders_list'

    return action$
        .ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'POST',
                path: `invoicing-orders?page=${modelState.getIn(['pagination', 'current']) + 1}`,
                body: {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const observables = [
                            Observable.of(actionCreators.fetchInvoicingOrdersFulfilled(ajaxResponse.response.data)),
                            Observable.of(
                                tableModelActionCreators.updatePagination(
                                    tableIdentifier,
                                    ajaxResponse.response.last_page,
                                    ajaxResponse.response.current_page - 1,
                                    ajaxResponse.response.total
                                )
                            ),
                        ]

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchInvoicingOrdersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Export

const exportInvoicingOrdersEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `invoicing-orders/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Set invoicing state

const setInvoicingStateEpic = (action$, store) => {
    const tableIdentifier = 'invoicing_orders_list'

    return action$.ofType(actionTypes.SET_INVOICING_STATE).switchMap(action => {
        const modelState = resolveModelState(tableIdentifier, store.getState(), action)

        const requestParams = {
            method: 'PUT',
            path: `invoicing-orders/${action.payload.transportCustomerId}/state`,
            body: {
                sorting: modelState.get('sorting').toJS(),
                filters: modelState.get('filters').toJS(),
                invoicing_state_id: action.payload.invoicingStateId,
            },
        }

        return Observable.concat(
            Observable.of({
                type: actionTypes.SET_INVOICING_STATE_STARTED,
            }),
            request(requestParams)
                .switchMap(ajaxResponse => {
                    const observables = [
                        Observable.of(actionCreators.setInvoicingStateFulfilled(ajaxResponse.response.data)),
                        Observable.of(
                            tableModelActionCreators.updatePagination(
                                tableIdentifier,
                                ajaxResponse.response.last_page,
                                ajaxResponse.response.current_page - 1,
                                ajaxResponse.response.total
                            )
                        ),
                    ]

                    return Observable.concat(...observables)
                })
                .catch(error => Observable.of(actionCreators.setInvoicingStateRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.SET_INVOICING_STATE_CANCELLED))
        )
    })
}

// Set as invoiced

const setAsInvoicedEpic = (action$, store) => {
    const tableIdentifier = 'invoicing_orders_list'

    return action$.ofType(actionTypes.SET_AS_INVOICED).switchMap(action => {
        const modelState = resolveModelState(tableIdentifier, store.getState(), action)

        const requestParams = {
            method: 'PUT',
            path: `invoicing-orders/set-as-invoiced`,
            body: {
                sorting: modelState.get('sorting').toJS(),
                filters: modelState.get('filters').toJS(),
                selectedIds: action.payload.selectedIds,
            },
        }

        return Observable.concat(
            Observable.of({
                type: actionTypes.SET_INVOICING_STATE_STARTED,
            }),
            request(requestParams)
                .switchMap(ajaxResponse => {
                    const observables = [
                        Observable.of(actionCreators.setAsInvoicedFulfilled(ajaxResponse.response.data)),
                        Observable.of(
                            tableModelActionCreators.updatePagination(
                                tableIdentifier,
                                ajaxResponse.response.last_page,
                                ajaxResponse.response.current_page - 1,
                                ajaxResponse.response.total
                            )
                        ),
                    ]

                    return Observable.concat(...observables)
                })
                .catch(error => Observable.of(actionCreators.setAsInvoicedRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.SET_AS_INVOICED_CANCELLED))
        )
    })
}

// Fetch data for invoicing

const fetchDataForInvoicingEpic = action$ =>
    action$.ofType(actionTypes.FETCH_DATA_FOR_INVOICING).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DATA_FOR_INVOICING_STARTED,
            }),
            request({
                method: 'GET',
                path: `invoicing-orders/data-for-invoicing?ids=${action.payload.transportCustomersIds.join(',')}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchDataForInvoicingFulfilled(ajaxResponse.response.data)))
                .catch(error => Observable.of(actionCreators.fetchDataForInvoicingRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_DATA_FOR_INVOICING_CANCELLED))
        )
    )

// Create invoice

const createInvoiceEpic = (action$, store) => {
    const tableIdentifier = 'invoicing_orders_list'

    return action$.ofType(actionTypes.CREATE_INVOICE).switchMap(action => {
        const modelState = resolveModelState(tableIdentifier, store.getState(), action)

        const requestParams = {
            method: 'POST',
            path: `invoicing-orders/create-invoice`,
            body: {
                filters: modelState.get('filters').toJS(),
                sorting: modelState.get('sorting').toJS(),
                data: action.payload.data,
            },
        }

        return Observable.concat(
            Observable.of({
                type: actionTypes.CREATE_INVOICE_STARTED,
            }),
            request(requestParams)
                .switchMap(ajaxResponse => {
                    const observables = [
                        Observable.of(actionCreators.createInvoiceFulfilled(ajaxResponse.response.data, ajaxResponse.response.invoice_id)),
                        Observable.of(
                            tableModelActionCreators.updatePagination(
                                tableIdentifier,
                                ajaxResponse.response.last_page,
                                ajaxResponse.response.current_page - 1,
                                ajaxResponse.response.total
                            )
                        ),
                    ]

                    return Observable.concat(...observables)
                })
                .catch(error => Observable.of(actionCreators.createInvoiceRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.CREATE_INVOICE_CANCELLED))
        )
    })
}

export default [fetchInvoicingOrdersEpic, exportInvoicingOrdersEpic, setInvoicingStateEpic, setAsInvoicedEpic, fetchDataForInvoicingEpic, createInvoiceEpic]
