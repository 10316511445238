import { Observable } from 'rxjs'

import { request, parseAPIError } from '../../common/api'

import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import * as actionCreators from './actionCreators'
import * as actionTypes from './actionTypes'

// Fetch

const fetchReportTransportInvoicingEpic = (action$, store) => {
    const tableIdentifier = 'report_transport_invoicing'

    return action$
        .ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'POST',
                path: `report-transport-invoicing?page=${modelState.getIn(['pagination', 'current']) + 1}`,
                body: {
                    filters: modelState.get('filters').toJS(),
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const observables = [
                            Observable.of(actionCreators.fetchReportTransportInvoicingFulfilled(ajaxResponse.response.data)),
                            Observable.of(
                                tableModelActionCreators.updatePagination(
                                    tableIdentifier,
                                    ajaxResponse.response.last_page,
                                    ajaxResponse.response.current_page - 1,
                                    ajaxResponse.response.total
                                )
                            ),
                        ]

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchReportTransportInvoicingRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Set transport prices

const setTransportPricesEpic = (action$, store) => {
    const tableIdentifier = 'report_transport_invoicing'

    return action$
        .ofType(actionTypes.SET_TRANSPORT_PRICES)
        .filter(() => !store.getState().transports.getIn(['setTransportPrices', 'inProgress']))
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'POST',
                path: 'report-transport-invoicing/set-transport-prices',
                body: {
                    filters: modelState.get('filters').toJS(),
                    transportIds: action.payload.transportIds,
                    priceForKm: action.payload.priceForKm,
                    priceFix: action.payload.priceFix,
                    erasePriceToll: action.payload.erasePriceToll
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SET_TRANSPORT_PRICES_STARTED,
                }),
                request(requestParams)
                .switchMap(ajaxResponse => Observable.of(actionCreators.setTransportPricesFulfilled(ajaxResponse.response.data)))
                .catch(error => Observable.of(actionCreators.setTransportPricesRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.SET_TRANSPORT_PRICES_CANCELLED))
            )
        })
}

export default [fetchReportTransportInvoicingEpic, setTransportPricesEpic]
