import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCustomerPlans = settings => ({
    type: actionTypes.FETCH,
    payload: settings,
})

export const fetchReportCustomerPlansFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCustomerPlansRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCustomerPlansCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCustomerPlans = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportCustomerPlans = settings => ({
    type: actionTypes.EXPORT,
    payload: settings,
})
