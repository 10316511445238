import * as actionTypes from './actionTypes'

// Fetch

export const fetchSalesTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchSalesTypesFulfilled = salesTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: salesTypes,
})

export const fetchSalesTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchSalesTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchSalesType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchSalesTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchSalesTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchSalesTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveSalesType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveSalesTypeFulfilled = salesType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: salesType,
})

export const saveSalesTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteSalesType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteSalesTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteSalesTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})
