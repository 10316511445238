import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'

// Fetch

const fetchInvoicingInvoicesEpic = (action$, store) => {
    const tableIdentifier = 'invoicing_invoices_list'

    return action$
        .ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'POST',
                path: `invoicing-invoices/list?page=${modelState.getIn(['pagination', 'current']) + 1}`,
                body: {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const observables = [
                            Observable.of(actionCreators.fetchInvoicingInvoicesFulfilled(ajaxResponse.response.data)),
                            Observable.of(
                                tableModelActionCreators.updatePagination(
                                    tableIdentifier,
                                    ajaxResponse.response.last_page,
                                    ajaxResponse.response.current_page - 1,
                                    ajaxResponse.response.total
                                )
                            ),
                        ]

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchInvoicingInvoicesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchInvoicingInvoiceEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().taxRates.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `invoicing-invoices/${action.payload ? action.payload : 0}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.fetchInvoicingInvoiceFulfilled(ajaxResponse.response.item, ajaxResponse.response.support_data))
                    )
                    .catch(error => Observable.of(actionCreators.fetchInvoicingInvoiceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveInvoicingInvoiceEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().taxRates.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload)

            let path = `invoicing-invoices`
            let method = 'POST'

            if (values.id) {
                path = `invoicing-invoices/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.saveInvoicingInvoiceFulfilled(ajaxResponse.response.item, ajaxResponse.response.support_data))
                    )
                    .catch(error => Observable.of(actionCreators.saveInvoicingInvoiceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteInvoiceEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().vehicles.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `invoicing-invoices/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteInvoiceFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteInvoiceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )


// Restore

const restoreInvoiceEpic = (action$, store) =>
    action$
        .ofType(actionTypes.RESTORE)
        .filter(() => !store.getState().invoicingInvoices.getIn(['restoring', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.RESTORE_STARTED,
                }),
                request({
                    path: `invoicing-invoices/${action.payload}/restore`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        return Observable.concat(Observable.of(actionCreators.restoreInvoiceFulfilled(ajaxResponse.response.item)))
                    })
                    .catch(error => Observable.of(actionCreators.restoreInvoiceRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.RESTORE_CANCELLED))
            )
        )


// Export

const exportInvoicingInvoicesEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `invoicing-invoices/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Fetch detail

const fetchDataForInvoicingEpic = action$ =>
    action$.ofType(actionTypes.FETCH_DETAIL).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DETAIL_STARTED,
            }),
            request({
                method: 'GET',
                path: `invoicing-invoices/detail?id=${action.payload.invoiceId}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchInvoiceDetailFulfilled(ajaxResponse.response.data)))
                .catch(error => Observable.of(actionCreators.fetchInvoiceDetailRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_DETAIL_CANCELLED))
        )
    )

// Load customer info

const loadCustomerInfoEpic = action$ =>
    action$.ofType(actionTypes.LOAD_CUSTOMER_INFO).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.LOAD_CUSTOMER_INFO_STARTED,
            }),
            request({
                method: 'GET',
                path: `invoicing-invoices/customer-info?customer_id=${action.payload.customerId}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.loadCustomerInfoFulfilled(ajaxResponse.response.data)))
                .catch(error => Observable.of(actionCreators.loadCustomerInfoRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.LOAD_CUSTOMER_INFO_CANCELLED))
        )
    )

// Create credit note

const createCreditNoteEpic = action$ =>
    action$.ofType(actionTypes.CREATE_CREDIT_NOTE).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.CREATE_CREDIT_NOTE_STARTED,
            }),
            request({
                method: 'POST',
                path: `invoicing-invoices/${action.payload.invoiceId}/credit-note`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.createCreditNoteFulfilled(ajaxResponse.response.item)))
                .catch(error => Observable.of(actionCreators.createCreditNoteRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.CREATE_CREDIT_NOTE_CANCELLED))
        )
    )

// Export to accounting

const exportInvoicingInvoicesToAccountingEpic = (action$, store) =>
    action$
        .ofType(actionTypes.EXPORT_TO_ACCOUNTING)
        .filter(() => !store.getState().vehicles.getIn(['exportToAccounting', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.EXPORT_TO_ACCOUNTING_STARTED,
                }),
                request({
                    path: `invoicing-invoices/export-to-accounting`,
                    method: 'POST',
                })
                    .switchMap(() => Observable.of(actionCreators.exportInvoicingInvoicesToAccountingFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.exportInvoicingInvoicesToAccountingRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.EXPORT_TO_ACCOUNTING_CANCELLED))
            )
        )

// Load transport info

const loadTransportInfoEpic = action$ =>
    action$.ofType(actionTypes.LOAD_TRANSPORT_INFO).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.LOAD_TRANSPORT_INFO_STARTED,
            }),
            request({
                method: 'GET',
                path: `invoicing-invoices/transport-info?transport_id=${action.payload.transportId}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.loadTransportInfoFulfilled(ajaxResponse.response.data)))
                .catch(error => Observable.of(actionCreators.loadTransportInfoRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.LOAD_TRANSPORT_INFO_CANCELLED))
        )
    )

// Create invoice PDF

const createInvoicePdfEpic = (action$, store) =>
    action$
        .ofType(actionTypes.CREATE_INVOICE_PDF)
        .filter(() => !store.getState().invoicingInvoices.getIn(['pdfCreating', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.CREATE_INVOICE_PDF_STARTED,
                }),
                request({
                    path: `invoicing-invoices/${action.payload.id}/create-invoice`,
                    method: 'POST',
                    body: action.payload.values,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.createInvoicePdfFulfilled(ajaxResponse.response.data)))
                    .catch(error => Observable.of(actionCreators.createInvoicePdfRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.CREATE_INVOICE_PDF_CANCELLED))
            )
        )

// Send invoice to email

const sendInvoiceToEmailEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SEND_INVOICE_TO_EMAIL)
        .filter(() => !store.getState().invoicingInvoices.getIn(['invoiceSending', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.SEND_INVOICE_TO_EMAIL_STARTED,
                }),
                request({
                    path: `invoicing-invoices/${action.payload.id}/send-invoice-to-email`,
                    method: 'POST',
                    body: { ...action.payload.values },
                })
                    .switchMap(() => Observable.of(actionCreators.sendInvoiceToEmailFulfilled()))
                    .catch(error => Observable.of(actionCreators.sendInvoiceToEmailRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SEND_INVOICE_TO_EMAIL_CANCELLED))
            )
        )

export default [
    fetchInvoicingInvoicesEpic,
    fetchInvoicingInvoiceEpic,
    saveInvoicingInvoiceEpic,
    deleteInvoiceEpic,
    restoreInvoiceEpic,
    exportInvoicingInvoicesEpic,
    fetchDataForInvoicingEpic,
    loadCustomerInfoEpic,
    createCreditNoteEpic,
    exportInvoicingInvoicesToAccountingEpic,
    loadTransportInfoEpic,
    createInvoicePdfEpic,
    sendInvoiceToEmailEpic,
]
