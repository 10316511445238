import { Record } from 'immutable'

export class CustomerAddress extends Record({
    id: undefined,
    code: undefined,
    name: undefined,
    street: undefined,
    city: undefined,
    zipcode: undefined,
    country_id: undefined,
    country_code: undefined,
    phone: undefined,
    email: undefined,
    gps_lat: undefined,
    gps_lng: undefined,
    customer_id: undefined,
    customer_address_type_id: undefined,
    note: undefined,
    is_booking_required: undefined,
    booking_url: undefined,
    booking_note: undefined,
    identifier: undefined,
    deleted_at: undefined,
}) {
    getAddress = () => {
        const parts = []
        this.street && parts.push(this.street)
        this.city && parts.push(this.city)
        this.zipcode && parts.push(this.zipcode)

        return parts.join(', ')
    }

    getGPS = () => (this.gps_lat && this.gps_lng ? `${this.gps_lat}, ${this.gps_lng}` : '')
}
