import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { Modal } from 'react-bootstrap'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import { getListItemsCountMessage } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as carriersActionCreators from '../actionCreators'
import { getCarrierContactsData, getCarrierContactsFetching } from '../selectors'

import { deleteCarrierContact } from '../../carrier_contacts/actionCreators'
import { getCarrierContactDeleting } from '../../carrier_contacts/selectors'

import CarriersContactsListRow from './CarriersContactsListRow'
import CarrierContactsEditForm from '../../carrier_contacts/components/CarrierContactsEditForm'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_CONTACTS } from '../permissions'

class CarriersContactsList extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalContact: {
            show: false,
            item: null,
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // handles
    onClick = item => {
        this.setState({
            modalContact: {
                show: true,
                item,
            },
        })
    }

    onDelete = item => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteCarrierContact(item.id)
                this.hideConfirmDialog()
            },
        })
    }

    onPageChange = page => {
        this.props.actions.fetchCarrierContacts(this.props.carrier.id, page.selected)
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportCarrierContacts(this.props.carrier.id)
    }

    handleCreateItem = e => {
        e && e.preventDefault()
        this.setState({
            modalContact: {
                show: true,
                item: null,
            },
        })
    }

    // create modal
    modalContactClose = () => {
        this.setState({
            modalContact: {
                show: false,
                item: null,
            },
        })
    }

    // callbacks
    editSuccessCallback = () => {
        this.modalContactClose()
        this.refresh()
    }

    editBackCallback = () => {
        this.modalContactClose()
    }

    refresh = () => {
        this.props.actions.fetchCarrierContacts(this.props.carrier.id)
    }

    componentDidMount() {
        if (this.props.carrier) {
            this.props.actions.fetchCarrierContacts(this.props.carrier.id)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.carrier && JSON.stringify(nextProps.carrier) !== JSON.stringify(this.props.carrier)) {
            this.props.actions.fetchCarrierContacts(nextProps.carrier.id)
        }

        if (this.props.deleting && !nextProps.deleting) {
            this.refresh()
        }
    }

    render() {
        const data = this.props.data

        const rows = data && data.data
        const page = data && data.page ? data.page : 0
        const total = data && data.total ? data.total : 0

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_CONTACTS)} noPermissionsTab>
                <LoadingOverlay active={this.props.fetching || this.props.deleting}>
                    <div className="btns-list">
                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                        </button>
                        {rows && rows.length > 0 && (
                            <PermissionsCheck has={[PERMS_CONTACTS.EXPORT]}>
                                <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExport}>
                                    <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                </button>
                            </PermissionsCheck>
                        )}
                        <PermissionsCheck has={[PERMS_CONTACTS.CREATE]}>
                            <button className="btn btn-addon btn-success" onClick={this.handleCreateItem}>
                                <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                            </button>
                        </PermissionsCheck>
                    </div>

                    {rows && rows.length > 0 ? (
                        <React.Fragment>
                            <div className="table-container">
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                        <tr>
                                            <PermissionsCheck hasAny={[PERMS_CONTACTS.UPDATE, PERMS_CONTACTS.DELETE]}>
                                                <th className="w-60" />
                                            </PermissionsCheck>
                                            <th className="w-200">
                                                <FormattedMessage id="fields.name" />
                                            </th>
                                            <th className="w-200">
                                                <FormattedMessage id="fields.position" />
                                            </th>
                                            <th className="w-160">
                                                <FormattedMessage id="fields.phone" />
                                            </th>
                                            <th className="w-240">
                                                <FormattedMessage id="fields.email" />
                                            </th>
                                            <th className="w-max wm-200">
                                                <FormattedMessage id="fields.note" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows &&
                                            rows.map((item, index) => (
                                                <CarriersContactsListRow
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={`row-${index}`}
                                                    item={item}
                                                    onClick={this.onClick}
                                                    onDelete={this.onDelete}
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="pull-left m-l-xxs m-t-md">
                                <FormattedMessage id="pagination.totalRecords" />: {getListItemsCountMessage(0, 0, total)}
                            </div>

                            <ReactPaginate
                                containerClassName="pagination"
                                breakLabel={<span className="disabled">...</span>}
                                activeClassName="active"
                                pageCount={Math.ceil(total / 50)}
                                pageRangeDisplayed={10}
                                marginPagesDisplayed={2}
                                forcePage={page}
                                onPageChange={this.onPageChange}
                                previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                            />
                        </React.Fragment>
                    ) : (
                        <div className="alert alert-danger pull-left wp-100">
                            <FormattedMessage id="fields.noItemsFound" />
                        </div>
                    )}
                </LoadingOverlay>

                <Modal show={Boolean(this.state.modalContact.show)} onHide={this.modalContactClose} bsSize="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.modalContact.item ? <FormattedMessage id="fields.itemEdit" /> : <FormattedMessage id="fields.itemCreate" />}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CarrierContactsEditForm
                            carrierContactID={this.state.modalContact.item && this.state.modalContact.item.id}
                            successCallback={this.editSuccessCallback}
                            backCallback={this.editBackCallback}
                            defaultValues={{
                                carrier_id: this.props.carrier.id,
                                carrier_contact_type_id: 1,
                            }}
                            hiddenFields={['carrier_id']}
                            PERMS={PERMS_CONTACTS}
                        />
                    </Modal.Body>
                </Modal>

                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: getCarrierContactsData(state),
        fetching: getCarrierContactsFetching(state),
        deleting: getCarrierContactDeleting(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...carriersActionCreators,
                    deleteCarrierContact,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(CarriersContactsList)
)
