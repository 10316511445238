import { createSelector } from 'reselect'

const getUnreadNotifications = state => state.wsnotifications.unreadNotifications

export const createGetUnreadNotificationsSelector = () => createSelector([getUnreadNotifications], notifications => notifications)

const getNotifications = state => state.wsnotifications.notifications

export const createGetNotificationsSelector = () => createSelector([getNotifications], notifications => notifications)

const getUserId = state => state.auth.get('userID')

export const createGetUserIdSelector = () => createSelector([getUserId], userId => userId)

const getSubscribedEvents = state => state.wsnotifications.subscribedEvents

export const createGetSubscribedEventsSelector = () => createSelector(getSubscribedEvents, subscribedEvents => subscribedEvents)

const getLoading = state => state.wsnotifications.loading

export const createGetLoadingSelector = () => createSelector(getLoading, loading => loading)

const getUnreadNotificationsLoading = state => state.wsnotifications.unreadNotificationsLoading

export const createGetUnreadNotificationsLoading = () => createSelector(getUnreadNotificationsLoading, loading => loading)

const getSettings = state => state.wsnotifications.settings

export const createGetSettingsSelector = () => createSelector([getSettings], settings => settings)

const getLoadingSettings = state => state.wsnotifications.loadingSettings

export const createGetLoadingSettings = () => createSelector(getLoadingSettings, loadingSettings => loadingSettings)

const getSavingSettings = state => state.wsnotifications.savingSettings

export const createGetSavingSettings = () => createSelector(getSavingSettings, savingSettings => savingSettings)
