// Received
export const RECEIVED = 'wsnotification.RECEIVED'

// Fetch unread notifications
export const UNREAD_NOTIFICATIONS_FETCH_STARTED = 'wsnotification.UNREAD_NOTIFICATIONS_FETCH_STARTED'
export const UNREAD_NOTIFICATIONS_FETCH_FULFILLED = 'wsnotification.UNREAD_NOTIFICATIONS_FETCH_FULFILLED'
export const UNREAD_NOTIFICATIONS_FETCH_CANCELLED = 'wsnotification.UNREAD_NOTIFICATIONS_FETCH_CANCELLED'
export const UNREAD_NOTIFICATIONS_FETCH_REJECTED = 'wsnotification.UNREAD_NOTIFICATIONS_FETCH_REJECTED'

// Mark as read
export const MARK_AS_READ = 'wsnotification.MARK_AS_READ'
export const MARK_AS_READ_STARTED = 'wsnotification.MARK_AS_READ_STARTED'
export const MARK_AS_READ_FULFILLED = 'wsnotification.MARK_AS_READ_FULFILLED'
export const MARK_AS_READ_CANCELLED = 'wsnotification.MARK_AS_READ_CANCELLED'

// Fetch all notifications
export const NOTIFICATIONS_FETCH_STARTED = 'wsnotification.NOTIFICATIONS_FETCH_STARTED'
export const NOTIFICATIONS_FETCH_FULFILLED = 'wsnotification.NOTIFICATIONS_FETCH_FULFILLED'
export const NOTIFICATIONS_FETCH_CANCELLED = 'wsnotification.NOTIFICATIONS_FETCH_CANCELLED'

// Fetch settings
export const NOTIFICATIONS_SETTINGS_FETCH_STARTED = 'wsnotification.NOTIFICATIONS_SETTINGS_FETCH_STARTED'
export const NOTIFICATIONS_SETTINGS_FETCH_FULFILLED = 'wsnotification.NOTIFICATIONS_SETTINGS_FETCH_FULFILLED'
export const NOTIFICATIONS_SETTINGS_FETCH_CANCELLED = 'wsnotification.NOTIFICATIONS_SETTINGS_FETCH_CANCELLED'
export const NOTIFICATIONS_SETTINGS_FETCH_REJECTED = 'wsnotification.NOTIFICATIONS_SETTINGS_FETCH_REJECTED'

// Save settings
export const NOTIFICATIONS_SETTINGS_SAVE_STARTED = 'wsnotification.NOTIFICATIONS_SETTINGS_SAVE_STARTED'
export const NOTIFICATIONS_SETTINGS_SAVE_FULFILLED = 'wsnotification.NOTIFICATIONS_SETTINGS_SAVE_FULFILLED'
export const NOTIFICATIONS_SETTINGS_SAVE_CANCELLED = 'wsnotification.NOTIFICATIONS_SETTINGS_SAVE_CANCELLED'
