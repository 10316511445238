import React, { Component } from 'react'

import TableFilterField from './TableFilterField'

class TableFilterCheckboxField extends Component {
    handleChange = event => {
        this.props.onChange(this.props.identifier, this.props.type, event.target.checked)
    }

    render() {
        let value = this.props.filters.getIn([this.props.identifier, 'value'])

        if (value === undefined) {
            value = false
        }

        return (
            <TableFilterField className={this.props.className}>
                <div className="checkbox">
                    <label htmlFor={`filter_${this.props.identifier}`}>
                        <input id={`filter_${this.props.identifier}`} type="checkbox" checked={value} onChange={this.handleChange} />
                        {this.props.label}
                    </label>
                </div>
            </TableFilterField>
        )
    }
}

export default TableFilterCheckboxField
