import { Observable } from 'rxjs'
import { fromJS } from 'immutable'

import { request, parseAPIError } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchPrerequisitesEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(action => {
        const identifier = action.payload.identifier
        const keys = action.payload.keys

        const requestParams = {
            method: 'POST',
            path: 'prerequisites',
            body: {
                keys,
            },
        }

        return Observable.concat(
            Observable.of(actionCreators.fetchPrerequisitesStarted(identifier)()),
            request(requestParams)
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchPrerequisitesFulfilled(identifier)(fromJS(ajaxResponse.response))))
                .catch(error => Observable.of(actionCreators.fetchPrerequisitesRejected(identifier)(parseAPIError(error))))
        )
    })

export default [fetchPrerequisitesEpic]
