import * as actionTypes from './actionTypes'

// Fetch

export const fetch = (tracingPoints, datetimes, transport_type_id, vehicle_id) => ({
    type: actionTypes.FETCH,
    payload: { waypoints: tracingPoints, datetimes, transport_type_id, vehicle_id },
})

export const fetchFulfilled = route => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: route,
})

export const fetchRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch vehicle

export const fetchVehicle = values => ({
    type: actionTypes.FETCH_VEHICLE,
    payload: values,
})

export const fetchVehicleFulfilled = vehicle => ({
    type: actionTypes.FETCH_VEHICLE_FULFILLED,
    payload: vehicle,
})

export const fetchVehicleRejected = message => ({
    type: actionTypes.FETCH_VEHICLE_REJECTED,
    payload: message,
})

export const fetchVehicleCancelled = () => ({
    type: actionTypes.FETCH_VEHICLE_CANCELLED,
})

// Fetch vehicles

export const fetchVehicles = values => ({
    type: actionTypes.FETCH_VEHICLES,
    payload: values,
})

export const fetchVehiclesFulfilled = vehicles => ({
    type: actionTypes.FETCH_VEHICLES_FULFILLED,
    payload: vehicles,
})

export const fetchVehiclesRejected = message => ({
    type: actionTypes.FETCH_VEHICLES_REJECTED,
    payload: message,
})

export const fetchVehiclesCancelled = () => ({
    type: actionTypes.FETCH_VEHICLES_CANCELLED,
})

// Fetch gps

export const fetchGps = gps => ({
    type: actionTypes.FETCH_GPS,
    payload: gps,
})

export const fetchGpsFulfilled = gps => ({
    type: actionTypes.FETCH_GPS_FULFILLED,
    payload: gps,
})

export const fetchGpsRejected = message => ({
    type: actionTypes.FETCH_GPS_REJECTED,
    payload: message,
})

export const fetchGpsCancelled = () => ({
    type: actionTypes.FETCH_GPS_CANCELLED,
})

// Fetch address

export const fetchAddress = address => ({
    type: actionTypes.FETCH_ADDRESS,
    payload: address,
})

export const fetchAddressFulfilled = gps => ({
    type: actionTypes.FETCH_ADDRESS_FULFILLED,
    payload: gps,
})

export const fetchAddressRejected = message => ({
    type: actionTypes.FETCH_ADDRESS_REJECTED,
    payload: message,
})

export const fetchAddressCancelled = () => ({
    type: actionTypes.FETCH_ADDRESS_CANCELLED,
})

// Clear

export const clear = () => ({
    type: actionTypes.CLEAR,
})

export const clearRoute = () => ({
    type: actionTypes.CLEAR_ROUTE,
})
