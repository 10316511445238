import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportVehicleResults = (year, filters) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        filters
    },
})

export const fetchReportVehicleResultsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportVehicleResultsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportVehicleResultsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportVehicleResults = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportVehicleResults = (year, filters) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
    },
})

// Fetch details

export const fetchReportVehicleResultsDetails = (year, vehicleId, month, filters) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        year,
        vehicleId,
        month,
        filters,
    },
})

export const fetchReportVehicleResultsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportVehicleResultsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportVehicleResultsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportVehicleResultsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportVehicleResultsDetails = (year, vehicleId, month, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        year,
        vehicleId,
        month,
        filters,
    },
})
