import { createSelector } from 'reselect'

export const getSalesTypes = state => state.salesTypes.getIn(['all', 'objects'])

// eslint-disable-next-line prettier/prettier
export const createGetSalesTypeByIDSelector = id => createSelector([getSalesTypes], salesTypes => salesTypes.get(String(id)))

const getCurrentSalesTypeStatus = state => state.salesTypes.get('current')

// eslint-disable-next-line prettier/prettier
export const createGetCurrentSalesTypeStatusSelector = () => createSelector([getCurrentSalesTypeStatus], status => status)

export const getSalesTypeDeleting = state => state.salesTypes.getIn(['deletion', 'inProgress'])

export const getSalesTypeDeleteError = state => state.salesTypes.getIn(['deletion', 'error'])
