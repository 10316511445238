import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportPallets = month => ({
    type: actionTypes.FETCH,
    payload: month,
})

export const fetchReportPalletsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportPalletsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportPalletsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportPallets = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportPallets = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})
