import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import DriverSurchargesEditForm from './DriverSurchargesEditForm'

// eslint-disable-next-line react/prefer-stateless-function
class DriverSurchargesEdit extends Component {
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.driverSurcharges.heading' }))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.driverSurcharges.heading" /> -{' '}
                                                {this.props.match.params.driverSurchargeID ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>

                                        <DriverSurchargesEditForm driverSurchargeID={this.props.match.params.driverSurchargeID} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

export default injectIntl(DriverSurchargesEdit)
