import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { SelectField } from '../../../common/form'
import Form from '../../../common/form/components/Form'

import * as reportCarrierScoringActionCreators from '../actionCreators'
import {
    getYear,
    getData,
    getFetching,
    getCarrierScoringData,
    isCarrierScoringFetching,
    isCarrierScoringSaving,
    getCarrierScoringError,
} from '../selectors'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField } from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'report_carrier_scoring'

class ReportCarrierScoring extends Component {
    state = {
        modalForm: {
            show: false,
            carrierId: null,
            carrierName: null,
            month: null,
            values: {},
            valuesSet: false,
        },
    }

    getYear = () => {
        if (this.props.year) {
            return parseInt(this.props.year)
        }

        return parseInt(this.props.match.params.year ? this.props.match.params.year : moment().format('YYYY'))
    }

    modalFormShow = (carrier, month) => {
        this.props.actions.fetchScoring(carrier.id, this.getYear(), month)

        this.setState({
            modalForm: {
                show: true,
                carrierId: carrier.id,
                carrierName: carrier.name,
                month,
                values: {},
                valuesSet: false,
            },
        })
    }

    modalFormHide = () => {
        this.setState({
            modalForm: {
                ...this.state.modalForm,
                show: false,
            },
        })
    }

    modalFormSetValuesFromData = data => {
        const values = {}
        data.forEach(score => {
            values[`answer_${score.answer.question_id}`] = score.answer.id
        })

        this.setState({
            modalForm: {
                ...this.state.modalForm,
                values,
                valuesSet: true,
            },
        })
    }

    modalFormChangeValues = values => {
        this.setState({
            modalForm: {
                ...this.state.modalForm,
                values,
            },
        })
    }

    modalFormSubmitForm = () => {
        this.props.actions.saveScoring(this.state.modalForm.carrierId, this.getYear(), this.state.modalForm.month, this.state.modalForm.values)
        this.modalFormHide()
    }

    getQuestions = () =>
        this.props.carrierScoringData && this.props.carrierScoringData.settings && Array.isArray(this.props.carrierScoringData.settings.data)
            ? this.props.carrierScoringData.settings.data
            : []

    getQuestionAnswers = question => (question && question.answers && Array.isArray(question.answers) ? question.answers : [])

    getScoringRules = () =>
        this.props.carrierScoringData && this.props.carrierScoringData.settings && Array.isArray(this.props.carrierScoringData.settings.rules)
            ? this.props.carrierScoringData.settings.rules
            : []

    getScoringAnswers = () =>
        this.props.carrierScoringData && this.props.carrierScoringData.data && Array.isArray(this.props.carrierScoringData.data)
            ? this.props.carrierScoringData.data
            : []

    existsScoringAnswer = () => this.getScoringAnswers().length > 0

    getTotalPoints = () => {
        let points = 0
        this.getScoringAnswers().forEach(answer => {
            points += parseInt(answer.answer.points)
        })

        return points
    }

    getScoringResultRule = () => {
        const totalPoints = this.getTotalPoints()
        let resultRule = null
        this.getScoringRules().forEach(rule => {
            if (rule.points_from <= totalPoints && rule.points_to >= totalPoints) {
                resultRule = rule
            }
        })

        return resultRule
    }

    refresh = () => {
        this.props.actions.fetchReportCarrierScoring(this.getYear())
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleChangeYearMonth = format => {
        const year = format.year.toString()
        const path = `/report-carrier-scoring/${year}`
        this.props.history.push(path)
    }

    componentDidMount() {
        this.props.actions.fetchReportCarrierScoring(this.getYear())
    }

    componentWillUnmount() {
        this.props.actions.clearReportCarrierScoring()
    }

    componentWillReceiveProps(nextProps) {
        const parsedYear = parseInt(nextProps.match.params.year ? nextProps.match.params.year : moment().format('YYYY'))

        if (parsedYear !== this.getYear()) {
            this.props.actions.clearReportCarrierScoring()
            this.props.actions.fetchReportCarrierScoring(parsedYear)
        }

        if (
            nextProps.carrierScoringData &&
            nextProps.carrierScoringData.data &&
            (JSON.stringify(nextProps.carrierScoringData.data) !== JSON.stringify(this.props.carrierScoringData.data) ||
                !this.state.modalForm.valuesSet)
        ) {
            this.modalFormSetValuesFromData(nextProps.carrierScoringData.data)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportCarrierScoring.heading' }))

        const data = this.props.data

        const momentFrom = moment(`${this.getYear()}-01-01`, 'YYYY-MM-DD')
        const momentTo = moment(momentFrom).endOf('year')

        const monthKeys = [...Array(12).keys()]

        const filters = this.props.tableModel.get('filters')

        const ratingColors = {
            1: 'rgba(0, 128, 0, 0.1)',
            2: 'rgba(255, 165, 0, 0.1)',
            3: 'rgba(255, 0, 0, 0.1)',
        }

        const scoringResultRule = this.getScoringResultRule()

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportCarrierScoring.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className={'m-r-sm'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            onChange={(momentFrom, momentTo, format) => this.handleChangeYearMonth(format)}
                                            allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_YEAR]}
                                            forbidPickType
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-max wm-300">
                                                    <FormattedMessage id="fields.carrier" />
                                                </th>
                                                {monthKeys.map(key => {
                                                    const month = moment().set('month', key)

                                                    return (
                                                        <th key={`month-${key}`} className="w-90 text-center">
                                                            {this.props.intl.formatMessage({ id: `monthNames.${month.format('MM')}` })}
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-max wm-300">
                                                    <TableFilterInputField
                                                        identifier="carrier_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                {monthKeys.map(key => (
                                                    <th key={`month-${key}`} className="w-90 text-center">
                                                        <TableFilterInputField
                                                            identifier={`month-${key}`}
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            className="text-center"
                                                        />
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.carriers &&
                                                data.carriers.map(carrier => {
                                                    if (
                                                        filters.getIn(['carrier_name', 'value']) &&
                                                        carrier.name.toLowerCase().indexOf(filters.getIn(['carrier_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    let passed = true
                                                    monthKeys.forEach(key => {
                                                        if (filters.getIn([`month-${key}`, 'value'])) {
                                                            const score = carrier.months && carrier.months[key + 1]
                                                            const mark = score && score.mark

                                                            if (
                                                                !mark ||
                                                                mark.toLowerCase().indexOf(filters.getIn([`month-${key}`, 'value']).toLowerCase()) < 0
                                                            ) {
                                                                passed = false
                                                            }
                                                        }
                                                    })

                                                    if (!passed) {
                                                        return null
                                                    }

                                                    const rows = []

                                                    rows.push(
                                                        <tr key={`row-${carrier.id}`}>
                                                            <td className="w-max wm-300">{carrier.name}</td>
                                                            {monthKeys.map(monthKey => {
                                                                const score = carrier.months && carrier.months[monthKey + 1]
                                                                const mark = score && score.mark
                                                                const rating = score && score.rating
                                                                const color = rating && ratingColors[rating]

                                                                return (
                                                                    <td
                                                                        key={`month-${monthKey}`}
                                                                        className="w-90 text-center td-clickable"
                                                                        style={{ backgroundColor: color }}
                                                                        onClick={() => this.modalFormShow(carrier, monthKey + 1)}
                                                                    >
                                                                        {mark}
                                                                    </td>
                                                                )
                                                            })}
                                                        </tr>
                                                    )

                                                    return rows
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalForm.show)} onHide={this.modalFormHide} className="modal-size-sm-2">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.modalForm.carrierName} - {this.state.modalForm.month}/{this.getYear()}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LoadingOverlay active={this.props.isCarrierScoringFetching || this.props.isCarrierScoringSaving}>
                                <div className="hm-200">
                                    <PermissionsCheck hasAny={Object.values(PERMS)}>
                                        <Form
                                            validationRules={{}}
                                            values={this.state.modalForm.values}
                                            onChange={this.modalFormChangeValues}
                                            onSubmit={this.modalFormSubmitForm}
                                        >
                                            {this.existsScoringAnswer() ? (
                                                <div className="alert alert-success">
                                                    {scoringResultRule && <strong>{scoringResultRule.mark}</strong>}
                                                    {scoringResultRule && ` - ${scoringResultRule.text}`} (
                                                    <FormattedMessage id="fields.pointsCount" />: {this.getTotalPoints()})
                                                </div>
                                            ) : (
                                                <div className="alert alert-danger">
                                                    <FormattedMessage id="fields.scoringNotSetYet" />
                                                </div>
                                            )}

                                            {this.getQuestions().map(question => {
                                                const options = this.getQuestionAnswers(question).map(answer => ({
                                                    id: answer.id,
                                                    name: `${answer.points} - ${answer.text}`,
                                                }))

                                                return (
                                                    <div key={`question-${question.id}`} className="form-group">
                                                        <label htmlFor="nothing">{question.text}</label>
                                                        <div>
                                                            <SelectField id={`answer_${question.id}`} values={options} nullable />
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                            <div className="btns-form clearfix">
                                                <button type="button" className="btn btn-addon btn-default pull-left" onClick={this.modalFormHide}>
                                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                </button>
                                                <PermissionsCheck has={[PERMS.UPDATE]}>
                                                    <button type="submit" className="btn btn-addon btn-success pull-right">
                                                        <i className="far fa-check" /> <FormattedMessage id="buttons.save" />
                                                    </button>
                                                </PermissionsCheck>
                                            </div>
                                        </Form>
                                    </PermissionsCheck>
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        year: getYear(state),
        data: getData(state),
        fetching: getFetching(state),
        carrierScoringData: getCarrierScoringData(state),
        isCarrierScoringFetching: isCarrierScoringFetching(state),
        isCarrierScoringSaving: isCarrierScoringSaving(state),
        getCarrierScoringError: getCarrierScoringError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportCarrierScoringActionCreators,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(withRouter(ReportCarrierScoring))
)
