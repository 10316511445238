import { Map } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    messageVehicles: Map({
        data: null,
        fetching: false,
        error: null,
        isAutoFetchingActive: false,
    }),
    messages: Map({
        data: null,
        fetching: false,
        error: null,
        isAutoFetchingActive: false,
    }),
    isOpen: false,
    searchString: '',
    selectedVehicle: null,
    sendMessage: Map({
        inProgress: false,
        error: null,
    }),
    markAsRead: Map({
        inProgress: false,
        error: null,
    }),
    subscribe: Map({
        inProgress: false,
        error: null,
    }),
    unsubscribe: Map({
        inProgress: false,
        error: null,
    }),
})

function vehicleMessages(state = initialState, action) {
    switch (action.type) {
        // Toggle sidebar
        case actionTypes.SHOW_SIDEBAR:
            return state.set('isOpen', true).set('selectedVehicle', null)
        case actionTypes.HIDE_SIDEBAR:
            return state
                .set('isOpen', false)
                .set('selectedVehicle', null)
                .setIn(['messages', 'data'], null)

        // Toggle sidebar
        case actionTypes.SET_SEARCH:
            return state.set('searchString', action.payload)

        // Change vehicle
        case actionTypes.SELECT_VEHICLE:
            return state.set('selectedVehicle', action.payload)
        case actionTypes.DESELECT_VEHICLE:
            return state.set('selectedVehicle', null).setIn(['messages', 'data'], null)

        // Fetch conversations
        case actionTypes.FETCH:
            return state.setIn(['messageVehicles', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['messageVehicles', 'fetching'], false)
                    .setIn(['messageVehicles', 'data'], action.payload)
                    .setIn(['messageVehicles', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['messageVehicles', 'fetching'], false).setIn(['messageVehicles', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['messageVehicles', 'fetching'], false)

        // Fetch messages
        case actionTypes.FETCH_MESSAGES:
            return state.setIn(['messages', 'fetching'], true)
        case actionTypes.FETCH_MESSAGES_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['messages', 'fetching'], false)
                    .setIn(['messages', 'data'], action.payload)
                    .setIn(['messages', 'error'], null)
            })
        case actionTypes.FETCH_MESSAGES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['messages', 'fetching'], false).setIn(['messages', 'error'], action.payload)
            })
        case actionTypes.FETCH_MESSAGES_CANCELLED:
            return state.setIn(['messages', 'fetching'], false)

        // Send message
        case actionTypes.SEND_MESSAGE:
            return state.withMutations(map => {
                map.setIn(['sendMessage', 'inProgress'], true).setIn(['sendMessage', 'error'], null)
            })
        case actionTypes.SEND_MESSAGE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['sendMessage', 'inProgress'], false).setIn(['sendMessage', 'error'], null)
            })
        case actionTypes.SEND_MESSAGE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['sendMessage', 'inProgress'], false).setIn(['sendMessage', 'error'], action.payload)
            })

        // Mark as read
        case actionTypes.MARK_AS_READ:
            return state.withMutations(map => {
                map.setIn(['markAsRead', 'inProgress'], true).setIn(['markAsRead', 'error'], null)
            })
        case actionTypes.MARK_AS_READ_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['markAsRead', 'inProgress'], false).setIn(['markAsRead', 'error'], null)
            })
        case actionTypes.MARK_AS_READ_REJECTED:
            return state.withMutations(map => {
                map.setIn(['markAsRead', 'inProgress'], false).setIn(['markAsRead', 'error'], action.payload)
            })

        // Subscribe
        case actionTypes.SUBSCRIBE:
            return state.withMutations(map => {
                map.setIn(['subscribe', 'inProgress'], true).setIn(['subscribe', 'error'], null)
            })
        case actionTypes.SUBSCRIBE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['subscribe', 'inProgress'], false).setIn(['subscribe', 'error'], null)
            })
        case actionTypes.SUBSCRIBE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['subscribe', 'inProgress'], false).setIn(['subscribe', 'error'], action.payload)
            })

        // Unsubscribe
        case actionTypes.UNSUBSCRIBE:
            return state.withMutations(map => {
                map.setIn(['unsubscribe', 'inProgress'], true).setIn(['unsubscribe', 'error'], null)
            })
        case actionTypes.UNSUBSCRIBE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['unsubscribe', 'inProgress'], false).setIn(['unsubscribe', 'error'], null)
            })
        case actionTypes.UNSUBSCRIBE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['unsubscribe', 'inProgress'], false).setIn(['unsubscribe', 'error'], action.payload)
            })

        default:
            return state
    }
}

export default vehicleMessages
