import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportInvoicingSummary = month => ({
    type: actionTypes.FETCH,
    payload: month,
})

export const fetchReportInvoicingSummaryFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportInvoicingSummaryRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportInvoicingSummaryCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportInvoicingSummary = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportInvoicingSummary = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})
