import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as vehicleMessagesActionCreators from '../actionCreators'
import { createGetFilteredVehiclesSelector } from '../selectors'
import SearchRow from './SearchRow'

class Search extends Component {
    handleSearch = e => {
        this.props.handleSearch(e.target.value)
    }

    handleMarkAsRead = vehiclesId => {
        this.props.actions.markMessageAsRead(vehiclesId)
    }

    handleSubscribe = vehiclesId => {
        this.props.actions.subscribe(vehiclesId)
    }

    handleUnsubscribe = vehiclesId => {
        this.props.actions.unsubscribe(vehiclesId)
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        return (
            <React.Fragment>
                <div className="search-header">
                    <h3>
                        <span className="pull-left">
                            <FormattedMessage id="messages.headline" />
                        </span>
                        <div className="search-input-container pull-left">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={this.props.intl.formatMessage({ id: 'messages.find' })}
                                defaultValue={this.props.searchString}
                                value={this.props.searchString}
                                onChange={this.handleSearch}
                            />
                        </div>
                        <a href="#" onClick={this.props.handleCloseSidebar} className="pull-right">
                            <i className="far fa-times" />
                        </a>
                    </h3>
                </div>
                <div className="search-results pull-left">
                    {this.props.messageVehicles &&
                        this.props.messageVehicles.map(messageVehicle => (
                            <SearchRow
                                key={`messageVehicle-${messageVehicle.id}`}
                                messageVehicle={messageVehicle}
                                selectVehicle={this.props.actions.selectVehicle}
                                startMessageAutoFetching={this.props.actions.startMessageAutoFetching}
                                handleMarkAsRead={this.handleMarkAsRead}
                                handleSubscribe={this.handleSubscribe}
                                handleUnsubscribe={this.handleUnsubscribe}
                            />
                        ))}
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        messageVehicles: createGetFilteredVehiclesSelector()(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(vehicleMessagesActionCreators, dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Search))
