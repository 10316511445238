import * as actionTypes from './actionTypes'

// Fetch

export const fetchWarehousingWarehouses = () => ({
    type: actionTypes.FETCH,
})

export const fetchWarehousingWarehousesFulfilled = warehousingWarehouses => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: warehousingWarehouses,
})

export const fetchWarehousingWarehousesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchWarehousingWarehousesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchWarehousingWarehouse = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchWarehousingWarehouseFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchWarehousingWarehouseRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchWarehousingWarehouseCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveWarehousingWarehouse = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveWarehousingWarehouseFulfilled = warehousingWarehouse => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: warehousingWarehouse,
})

export const saveWarehousingWarehouseRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteWarehousingWarehouse = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteWarehousingWarehouseFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteWarehousingWarehouseRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportWarehousingWarehouses = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
