import React, { Component } from 'react'
import { Set, Map } from 'immutable'
import DragAndDrop from '../../DragAndDrop'
import VariableList from './VariableList'
import ConstantList from './ConstantList'

class VariableAssignment extends Component {
    handleDrag = (constant, variable) => {
        let values = null
        if (this.props.singleValue) {
            values = Set().add(variable)
        } else {
            values = this.props.values.get(constant, Set()).add(variable)
        }
        this.props.onChange(this.props.values.set(constant, values))
    }

    onDelete = (constant, variable) => {
        const constantValues = this.props.values.get(constant, Set()).delete(variable)
        if (constantValues.isEmpty()) {
            this.props.onChange(this.props.values.delete(constant))
        } else {
            this.props.onChange(this.props.values.set(constant, constantValues))
        }
    }

    render() {
        const values = this.props.values
        let constants = this.props.constants
        let variables = this.props.variables

        values.forEach((variables, constantName) => {
            constants = constants.setIn([constantName, 'variables'], variables)
        })

        if (!Map.isMap(variables)) {
            variables = Map()
            this.props.variables.forEach(variable => {
                variables = variables.set(variable, Map({ label: variable }))
            })
        }

        return (
            <DragAndDrop>
                <VariableList variables={variables} onDrag={this.handleDrag} />
                <ConstantList constants={constants} variables={variables} onDelete={this.onDelete} />
            </DragAndDrop>
        )
    }
}

export default VariableAssignment
