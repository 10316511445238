export const PERMS = {
    READ: 'warehousing_items.read',
    UPDATE: 'warehousing_items.update',
    DELETE: 'warehousing_items.delete',
    CREATE: 'warehousing_items.create',
    EXPORT: 'warehousing_items.export',
}

export const PERMS_RECEIPTS = {
    READ: 'warehousing_receipts.read',
    UPDATE: 'warehousing_receipts.update',
    DELETE: 'warehousing_receipts.delete',
    CREATE: 'warehousing_receipts.create',
    EXPORT: 'warehousing_receipts.export',
}