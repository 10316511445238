import React, { Component } from 'react'
import moment from 'moment'
import { injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_DOCUMENTS } from '../permissions'

// eslint-disable-next-line react/prefer-stateless-function
class TrailersDocumentsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick && this.props.onClick(this.props.item)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete && this.props.onDelete(this.props.item)
    }

    render() {
        const document = this.props.item
        const notifyDays = document.type && document.type.notify_days
        const validityDaysRemained = document.date_validity_to && moment(document.date_validity_to).diff(moment(), 'days')

        const validityDaysClassName = notifyDays && document.date_validity_to && validityDaysRemained <= notifyDays ? 'text-danger' : ''

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS_DOCUMENTS.UPDATE, PERMS_DOCUMENTS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS_DOCUMENTS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_DOCUMENTS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-30 text-center">
                    <span>{document.is_files_uploaded ? <i className="fas fa-file text-gray-dark" /> : null}</span>
                </td>
                <td className="w-200">{document.type ? document.type.name : null}</td>
                <td className="w-140">{document.number}</td>
                <td className="w-100 text-center">{document.date_validity_from ? formatters.dateFormatter(document.date_validity_from) : null}</td>
                <td className="w-100 text-center">{document.date_validity_to ? formatters.dateFormatter(document.date_validity_to) : null}</td>
                <td className={`w-100 text-right ${validityDaysClassName}`}>
                    {document.date_validity_to
                        ? `${formatters.integerFormatter(validityDaysRemained)} ${this.props.intl.formatMessage({ id: 'fields.abbrDays' })}`
                        : null}
                </td>
                <td className="w-max wm-200 wrapped">{document.note}</td>
            </tr>
        )
    }
}

export default injectIntl(TrailersDocumentsListRow)
