import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCustomerSpeditions = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportCustomerSpeditionsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCustomerSpeditionsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCustomerSpeditionsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCustomerSpeditions = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportCustomerSpeditions = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportCustomerSpeditionsDetails = filters => ({
    type: actionTypes.FETCH_DETAILS,
    payload: filters,
})

export const fetchReportCustomerSpeditionsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportCustomerSpeditionsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportCustomerSpeditionsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportCustomerSpeditionsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportCustomerSpeditionsDetails = filters => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: filters,
})
