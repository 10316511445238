import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'

import * as formatters from '../../../common/formatters'

import * as reportInvoicingSummaryActionCreators from '../actionCreators'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { getData } from '../selectors'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { getActiveFilters } from '../../../common/table/helpers'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import { PeriodPicker } from "../../../common/period_picker";
import * as periodPickerConstants from "../../../common/period_picker/constants";
import moment from "moment";
import * as helpers from "../../../common/helpers";

const tableIdentifier = 'report_invoicing_summary_list'


class ReportInvoicingSummary extends Component {
    refresh = () => {
        this.props.actions.fetchReportInvoicingSummary(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        const dateFrom = moment().startOf('year')
        const dateTo = moment().endOf('year')

        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportInvoicingSummary(this.props.tableModel.get('filters'))
    }

    handleFilterChanged = (momentFrom, momentTo) => {
        this.props.table.changeFilters([
            {
                column: 'date_from',
                type: TableModelColumnFilteringType.equal,
                value: momentFrom.format('DD.MM.YYYY'),
            },
            {
                column: 'date_to',
                type: TableModelColumnFilteringType.equal,
                value: momentTo.format('DD.MM.YYYY'),
            },
        ])
    }

    componentDidMount() {
        const dateFrom = moment().startOf('year')
        const dateTo = moment().endOf('yeat')

        this.props.table.setConfiguration(
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ],
            false
        )

        this.props.actions.fetchReportInvoicingSummary(this.props.tableModel.get('filters'))
    }

    componentWillUnmount() {
        this.props.actions.clearReportInvoicingSummary()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportInvoicingSummary.heading' }))

        const filters = this.props.tableModel.get('filters')

        const stringFrom = filters.getIn(['date_from', 'value'])
        const stringTo = filters.getIn(['date_to', 'value'])

        console.log(stringFrom);
        console.log(stringTo);

        const momentFrom = stringFrom ? helpers.convertCSDateToMoment(stringFrom) : moment().startOf('year')
        const momentTo = stringTo ? helpers.convertCSDateToMoment(stringTo) : moment().endOf('year')
        console.log(momentFrom);
        console.log(momentTo);

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportInvoicingSummary.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className="m-r-lg"
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_YEAR}
                                            onChange={(momentFrom, momentTo) => this.handleFilterChanged(momentFrom, momentTo)}
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-80">
                                                    <FormattedMessage id="fields.month" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.totalExclVat" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.vat" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.totalInclVat" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.invoicingSummary &&
                                                this.props.invoicingSummary.data.map(item => (
                                                    <tr>
                                                        <td className="w-80"><strong>{item.month}</strong></td>
                                                        <td className="w-120 text-right">{formatters.priceFormatter(item.totalExclVat, '0,0', item.currency)}</td>
                                                        <td className="w-120 text-right">{formatters.priceFormatter(item.vat, '0,0', item.currency)}</td>
                                                        <td className="w-120 text-right">{formatters.priceFormatter(item.totalInclVat, '0,0', item.currency)}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        invoicingSummary: createTableDataSelector({
            tableIdentifier,
            dataSelector: getData,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportInvoicingSummaryActionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReportInvoicingSummary))
