import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Field from './Field'

// eslint-disable-next-line react/prefer-stateless-function
class BooleanField extends Component {
    render() {
        const values = [
            { id: 1, name: this.props.intl.formatMessage({ id: 'fields.yes' }) },
            { id: 0, name: this.props.intl.formatMessage({ id: 'fields.no' }) },
        ]

        if (this.props.nullable) {
            values.unshift({
                id: null,
                name: '-',
            })
        }

        let value

        if (this.props.hasOwnProperty('getValue')) {
            value = parseInt(this.props.getValue() ? this.props.getValue() : this.props.defaultValue)
        } else {
            value = parseInt(this.props.value ? this.props.value : this.props.defaultValue)
        }

        if (isNaN(value)) {
            value = 0
        }

        return (
            <Field {...this.props}>
                <select id={this.props.id} value={value} onChange={this.props.onChange} className="form-control" disabled={this.props.readonly()}>
                    {values.map(item => (
                        <option key={`field-${this.props.id}-${item.id}`} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
            </Field>
        )
    }
}

export default injectIntl(BooleanField)
