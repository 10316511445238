import * as actionTypes from './actionTypes'

// Fetch

export const fetchDriverEquipments = () => ({
    type: actionTypes.FETCH,
})

export const fetchDriverEquipmentsForMap = () => ({
    type: actionTypes.FETCH_FOR_MAP,
})

export const fetchDriverEquipmentsFulfilled = driverEquipments => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: driverEquipments,
})

export const fetchDriverEquipmentsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchDriverEquipmentsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchDriverEquipment = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchDriverEquipmentFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchDriverEquipmentRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchDriverEquipmentCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveDriverEquipment = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveDriverEquipmentFulfilled = driverEquipment => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: driverEquipment,
})

export const saveDriverEquipmentRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveDriverEquipmentDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteDriverEquipment = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteDriverEquipmentFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteDriverEquipmentRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Clear

export const clearDriverEquipments = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportDriverEquipments = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
