import { Record } from 'immutable'

export class VehicleType extends Record({
    id: undefined,
    name: undefined,
    width: undefined,
    height: undefined,
    length: undefined,
    axles_count: undefined,
    tank_volume: undefined,
    weight: undefined,
    avg_fuel_consumption: undefined,
    avg_adblue_consumption: undefined,
}) {}
