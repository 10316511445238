import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCostCenterTraffic = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportCostCenterTrafficFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterTrafficRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCostCenterTrafficCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCostCenterTraffic = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportCostCenterTraffic = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportCostCenterTrafficDetails = filters => ({
    type: actionTypes.FETCH_DETAILS,
    payload: filters,
})

export const fetchReportCostCenterTrafficDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterTrafficDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportCostCenterTrafficDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportCostCenterTrafficDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportCostCenterTrafficDetails = filters => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: filters,
})

// Fetch details vehicles

export const fetchReportCostCenterTrafficDetailsVehicles = filters => ({
    type: actionTypes.FETCH_DETAILS_VEHICLES,
    payload: filters,
})

export const fetchReportCostCenterTrafficDetailsVehiclesFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_VEHICLES_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterTrafficDetailsVehiclesRejected = message => ({
    type: actionTypes.FETCH_DETAILS_VEHICLES_REJECTED,
    payload: message,
})

export const fetchReportCostCenterTrafficDetailsVehiclesCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_VEHICLES_CANCELLED,
})

// Clear details vehicles

export const clearReportCostCenterTrafficDetailsVehicles = () => ({
    type: actionTypes.CLEAR_DETAILS_VEHICLES,
})

// Export details vehicles

export const exportReportCostCenterTrafficDetailsVehicles = filters => ({
    type: actionTypes.EXPORT_DETAILS_VEHICLES,
    payload: filters,
})
