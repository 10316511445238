import React from 'react'
import { Route, Switch } from 'react-router'
import UserRolesList from './UserRolesList'
import UserRolesEdit from './UserRolesEdit'

const Users = props => (
    <Switch>
        <Route exact path={props.match.url} component={UserRolesList} />
        <Route exact path={`${props.match.url}/new`} component={UserRolesEdit} />
        <Route exact path={`${props.match.url}/:userRoleID`} component={UserRolesEdit} />
    </Switch>
)

export default Users
