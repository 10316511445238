import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'

import { getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField, TableFilterSelectField, TableFilterBooleanField } from '../../../common/table/components'
import { getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as trailersActionCreators from '../actionCreators'
import { getTrailers } from '../selectors'
import { createGetTrailerTypeByIDSelector } from '../../trailer_types/selectors'

import TrailersHistoryTrailersRow from './TrailersHistoryTrailersRow'

const tableIdentifier = 'trailers_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'trailers_list'
const prerequisites = ['trailer_types']

const columns = {
    fb_id: new TableModelColumn({}),
    registration: new TableModelColumn({}),
}

class TrailersHistoryTrailers extends Component {
    componentDidMount() {
        this.props.actions.fetchTrailers()
        this.props.actions.fetchPrerequisites()
    }

    render() {
        const filters = this.props.tableModel.get('filters')

        return (
            <React.Fragment>
                <div className="table-container">
                    <table className="table table-striped table-hover table-fixed-header">
                        <thead>
                            <tr>
                                <th className="w-140">
                                    <FormattedMessage id="fields.trailer" />
                                </th>
                                <th className="w-max wm-200">
                                    <FormattedMessage id="fields.vin" />
                                </th>
                                <th className="w-200">
                                    <FormattedMessage id="fields.trailerType" />
                                </th>
                                <th className="w-80 text-center">
                                    <FormattedMessage id="fields.active" />
                                </th>
                                <th className="w-40" />
                            </tr>
                            <tr className="filters">
                                <th className="w-140">
                                    <TableFilterInputField
                                        identifier="registration"
                                        type={TableModelColumnFilteringType.string}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                    />
                                </th>
                                <th className="w-max wm-200">
                                    <TableFilterInputField
                                        identifier="vin"
                                        type={TableModelColumnFilteringType.string}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                    />
                                </th>
                                <th className="w-200">
                                    <TableFilterSelectField
                                        identifier="trailer_type_id"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('trailer_types'))}
                                    />
                                </th>
                                <th className="w-80">
                                    <TableFilterBooleanField
                                        identifier="is_active"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                    />
                                </th>
                                <th className="w-40" />
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.trailers.data.valueSeq().map(trailer => (
                                <TrailersHistoryTrailersRow
                                    key={`row-${trailer.id}`}
                                    trailer={trailer}
                                    trailerType={this.props.createGetTrailerTypeByIDSelector(trailer.trailer_type_id)}
                                    selectedTrailer={this.props.selectedTrailer}
                                    onTrailerSelect={this.props.onTrailerSelect}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="pull-left m-l-xxs m-t-md">
                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                    {getListItemsCountMessage(
                        clientSideItemsPerPage,
                        this.props.trailers.count,
                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                    )}
                </div>

                <ReactPaginate
                    containerClassName="pagination"
                    breakLabel={<span className="disabled">...</span>}
                    activeClassName="active"
                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.trailers.count, clientSideItemsPerPage)}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                    onPageChange={this.props.table.changePage}
                    ppreviousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        trailers: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getTrailers,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        createGetTrailerTypeByIDSelector: id => createGetTrailerTypeByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...trailersActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(TrailersHistoryTrailers)
)
