import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCostCenterProfits = (year, inclVehicles, inclDrivers, inclTrailers, inclFeatureCosts, withEmpty) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        inclVehicles,
        inclDrivers,
        inclTrailers,
        inclFeatureCosts,
        withEmpty
    },
})

export const fetchReportCostCenterProfitsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterProfitsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCostCenterProfitsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Export

export const exportReportCostCenterProfits = (year, inclVehicles, inclDrivers, inclTrailers, inclFeatureCosts, withEmpty) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        inclVehicles,
        inclDrivers,
        inclTrailers,
        inclFeatureCosts,
        withEmpty
    },
})

// Clear

export const clearReportCostCenterProfits = () => ({
    type: actionTypes.CLEAR,
})

// Fetch details

export const fetchReportCostCenterProfitsDetails = (id, year, month, inclVehicles, inclDrivers, inclTrailers, inclFeatureCosts) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        id,
        year,
        month,
        inclVehicles,
        inclDrivers,
        inclTrailers,
        inclFeatureCosts
    },
})

export const fetchReportCostCenterProfitsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterProfitsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportCostCenterProfitsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Export

export const exportReportCostCenterProfitsDetails = (id, year, month, inclVehicles, inclDrivers, inclTrailers, inclFeatureCosts) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        id,
        year,
        month,
        inclVehicles,
        inclDrivers,
        inclTrailers,
        inclFeatureCosts
    },
})

// Clear

export const clearReportCostCenterProfitsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})
