// Fetch list

export const FETCH = 'driverDocuments.FETCH'
export const FETCH_FOR_MAP = 'driverDocuments.FETCH_FOR_MAP'
export const FETCH_STARTED = 'driverDocuments.FETCH_STARTED'
export const FETCH_FULFILLED = 'driverDocuments.FETCH_FULFILLED'
export const FETCH_REJECTED = 'driverDocuments.FETCH_REJECTED'
export const FETCH_CANCELLED = 'driverDocuments.FETCH_CANCELLED'

export const SORTING_CHANGED = 'driverDocuments.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'driverDocuments.FETCH_ONE'
export const FETCH_ONE_STARTED = 'driverDocuments.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'driverDocuments.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'driverDocuments.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'driverDocuments.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'driverDocuments.SAVE'
export const SAVE_STARTED = 'driverDocuments.SAVE_STARTED'
export const SAVE_FULFILLED = 'driverDocuments.SAVE_FULFILLED'
export const SAVE_REJECTED = 'driverDocuments.SAVE_REJECTED'
export const SAVE_CANCELLED = 'driverDocuments.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'driverDocuments.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'driverDocuments.DELETE'
export const DELETE_STARTED = 'driverDocuments.DELETE_STARTED'
export const DELETE_FULFILLED = 'driverDocuments.DELETE_FULFILLED'
export const DELETE_REJECTED = 'driverDocuments.DELETE_REJECTED'
export const DELETE_CANCELLED = 'driverDocuments.DELETE_CANCELLED'

// Clear

export const CLEAR = 'driverDocuments.CLEAR'

// Load files

export const FETCH_FILES = 'driverDocuments.FETCH_FILES'
export const FETCH_FILES_STARTED = 'driverDocuments.FETCH_FILES_STARTED'
export const FETCH_FILES_FULFILLED = 'driverDocuments.FETCH_FILES_FULFILLED'
export const FETCH_FILES_REJECTED = 'driverDocuments.FETCH_FILES_REJECTED'
export const FETCH_FILES_CANCELLED = 'driverDocuments.FETCH_FILES_CANCELLED'

// Upload files

export const UPLOAD_FILES = 'driverDocuments.UPLOAD_FILES'
export const UPLOAD_FILES_STARTED = 'driverDocuments.UPLOAD_FILES_STARTED'
export const UPLOAD_FILES_FULFILLED = 'driverDocuments.UPLOAD_FILES_FULFILLED'
export const UPLOAD_FILES_REJECTED = 'driverDocuments.UPLOAD_FILES_REJECTED'
export const UPLOAD_FILES_CANCELLED = 'driverDocuments.UPLOAD_FILES_CANCELLED'

// Delete file

export const DELETE_FILE = 'driverDocuments.DELETE_FILE'
export const DELETE_FILE_STARTED = 'driverDocuments.DELETE_FILE_STARTED'
export const DELETE_FILE_FULFILLED = 'driverDocuments.DELETE_FILE_FULFILLED'
export const DELETE_FILE_REJECTED = 'driverDocuments.DELETE_FILE_REJECTED'
export const DELETE_FILE_CANCELLED = 'driverDocuments.DELETE_FILE_CANCELLED'

// Export

export const EXPORT = 'driverDocuments.EXPORT'
export const EXPORT_FULFILLED = 'driverDocuments.EXPORT_FULFILLED'
