
export const getYear = state => state.reportCostCenterProfits.getIn(['list', 'year'])

export const getData = state => state.reportCostCenterProfits.getIn(['list', 'data'])
export const getFetching = state => state.reportCostCenterProfits.getIn(['list', 'fetching'])
export const getError = state => state.reportCostCenterProfits.getIn(['list', 'error'])

export const getDetailsData = state => state.reportCostCenterProfits.getIn(['details', 'data'])
export const getDetailsFetching = state => state.reportCostCenterProfits.getIn(['details', 'fetching'])
export const getDetailsError = state => state.reportCostCenterProfits.getIn(['details', 'error'])
