import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import { Tabs, Tab } from 'react-bootstrap'

import * as formatters from '../../../common/formatters'
import { Form, InputField, SelectField, CheckboxField, SubmitButton } from '../../../common/form'
import { url, handleCommonEditActions, validationMessage, getPrerequisitesArray } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as warehousingItemsActionCreators from '../actionCreators'
import { createGetWarehousingItemByIDSelector, createGetCurrentWarehousingItemStatusSelector } from '../selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import { dateFormatter, floatFormatter } from "../../../common/formatters";

const componentIdentifier = 'warehousing_items_edit'
const prerequisites = ['units', 'currencies']

class WarehousingItemsEdit extends Component {
    defaultValues = {}

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.save()
        })
    }

    save = () => {
        this.props.actions.saveWarehousingItem(this.state.values)
    }

    componentDidMount() {
        this.props.match.params.warehousingItemID && this.props.actions.fetchWarehousingItem(this.props.match.params.warehousingItemID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'warehousing-items'))
        })

        if (nextProps.warehousingItem && (JSON.stringify(nextProps.warehousingItem) !== JSON.stringify(this.props.warehousingItem) || !this.state.values.id)) {
            this.setValues(nextProps.warehousingItem.toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.warehousingItems.heading' }))

        const warehousingItem = this.props.warehousingItem

        const mainColClassName = warehousingItem ? 'col-lg-4' : 'col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4'

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className={mainColClassName} style={{ paddingRight: '0' }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.warehousingItems.heading" /> -{' '}
                                                {warehousingItem ? <FormattedMessage id="fields.itemEdit" /> : <FormattedMessage id="fields.itemCreate" />}
                                            </h4>
                                        </div>
                                        <LoadingOverlay
                                            active={
                                                this.props.prerequisites.status.get('fetching') ||
                                                this.props.status.get('fetching') ||
                                                this.props.status.get('saving')
                                            }
                                        >
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(warehousingItem)}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="code"
                                                                label={this.props.intl.formatMessage({ id: 'fields.code' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <InputField
                                                        id="name"
                                                        label={this.props.intl.formatMessage({ id: 'fields.name' })}
                                                        formGroupClassName="form-group-bigger"
                                                    />

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <SelectField
                                                                id="unit_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.unit' })}
                                                                values={getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('units'),
                                                                    'id',
                                                                    'code'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="price"
                                                                label={this.props.intl.formatMessage({ id: 'fields.price' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <SelectField
                                                                id="currency_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.currency' })}
                                                                values={getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('currencies'),
                                                                    'id',
                                                                    'iso_code'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'warehousing-items')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>

                                                        <SubmitButton
                                                            perms={Object.values(PERMS)}
                                                            isEdit={Boolean(warehousingItem)}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>

                            {warehousingItem && (
                                <div className="col-lg-8 hp-100" style={{ paddingLeft: '5px' }}>
                                    <div className="panel panel-white hp-100 m-b-no">
                                        <div className="panel-body panel-padding hp-100">
                                            <Tabs
                                                defaultActiveKey={1}
                                                animation={false}
                                                id="detail-tabs"
                                                className="detail-tabs"
                                                onSelect={this.handleSelectTab}
                                            >
                                                <Tab
                                                    eventKey={1}
                                                    title={
                                                        <span>
                                                            {this.props.intl.formatMessage({ id: 'fields.receipts' })}
                                                        </span>
                                                    }
                                                >
                                                    <div className="table-container">
                                                        <table className="table table-striped table-hover">
                                                            <thead>
                                                            <tr>
                                                                <th className="w-100">
                                                                    <FormattedMessage id="fields.date" />
                                                                </th>
                                                                <th className="w-100">
                                                                    <FormattedMessage id="fields.number" />
                                                                </th>
                                                                <th className="w-100">
                                                                    <FormattedMessage id="fields.warehouse" />
                                                                </th>
                                                                <th className="w-300">
                                                                    <FormattedMessage id="fields.supplier" />
                                                                </th>
                                                                <th className="w-100 text-right">
                                                                    <FormattedMessage id="fields.unitsCount" />
                                                                </th>
                                                                <th className="w-max wm-300">
                                                                    <FormattedMessage id="fields.note" />
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {warehousingItem.receipts &&
                                                                warehousingItem.receipts.toJS().map((item, index) => (
                                                                    <tr>
                                                                        <td>{dateFormatter(item.date)}</td>
                                                                        <td>{item.number}</td>
                                                                        <td>{item.warehouse}</td>
                                                                        <td>{item.customer}</td>
                                                                        <td className="text-right">{floatFormatter(item.total, '0,0.00')}</td>
                                                                        <td>{item.note}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Tab>
                                                <Tab
                                                    eventKey={2}
                                                    title={
                                                        <span>
                                                            {this.props.intl.formatMessage({ id: 'fields.issues' })}
                                                        </span>
                                                    }
                                                >
                                                    <div className="table-container">
                                                        <table className="table table-striped table-hover">
                                                            <thead>
                                                            <tr>
                                                                <th className="w-100">
                                                                    <FormattedMessage id="fields.date" />
                                                                </th>
                                                                <th className="w-100">
                                                                    <FormattedMessage id="fields.warehouse" />
                                                                </th>
                                                                <th className="w-300">
                                                                    <FormattedMessage id="fields.customer" />
                                                                </th>
                                                                <th className="w-100 text-right">
                                                                    <FormattedMessage id="fields.unitsCount" />
                                                                </th>
                                                                <th className="w-max wm-300">
                                                                    <FormattedMessage id="fields.note" />
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {warehousingItem.issues &&
                                                                warehousingItem.issues.toJS().map((item, index) => (
                                                                    <tr>
                                                                        <td>{dateFormatter(item.date)}</td>
                                                                        <td>{item.warehouse}</td>
                                                                        <td>{item.customer}</td>
                                                                        <td className="text-right">{floatFormatter(item.total, '0,0.00')}</td>
                                                                        <td>{item.note}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        warehousingItem: createGetWarehousingItemByIDSelector(ownProps.match.params.warehousingItemID)(state),
        status: createGetCurrentWarehousingItemStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...warehousingItemsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(WarehousingItemsEdit)
)
