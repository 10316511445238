import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'

import TrailersHistoryTrailers from './TrailersHistoryTrailers'
import TrailersHistoryList from './TrailersHistoryList'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_HISTORY } from '../permissions'

class TrailersHistory extends Component {
    state = {
        selectedTrailer: null,
    }

    selectTrailer = trailer => {
        this.setState({
            selectedTrailer: trailer,
        })
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.trailerHistory.heading' }))

        const selectedTrailer = this.state.selectedTrailer

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_HISTORY)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="row hp-100">
                                    <div className="col-md-6 hp-100 b-r">
                                        <div className="pull-left wp-100">
                                            <h4>
                                                <FormattedMessage id="modules.trailerHistory.heading" />
                                            </h4>
                                        </div>
                                        <TrailersHistoryTrailers selectedTrailer={selectedTrailer} onTrailerSelect={this.selectTrailer} />
                                    </div>
                                    <div className="col-md-6 hp-100">
                                        {selectedTrailer && <TrailersHistoryList trailer={selectedTrailer} showTrailerRegistration />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

export default injectIntl(TrailersHistory)
