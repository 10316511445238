export const getItems = state => state.transportScoring.getIn(['data', 'items'])
export const getRules = state => state.transportScoring.getIn(['data', 'rules'])
export const isTransportScoringFetching = state => state.transportScoring.getIn(['data', 'fetching'])
export const isTransportScoringError = state => state.transportScoring.getIn(['data', 'error'])

export const isTransportScoringQuestionSaving = state => state.transportScoring.getIn(['question', 'saving'])
export const isTransportScoringQuestionDeleting = state => state.transportScoring.getIn(['question', 'deleting'])
export const isTransportScoringQuestionMovingUp = state => state.transportScoring.getIn(['question', 'movingUp'])
export const isTransportScoringQuestionMovingDown = state => state.transportScoring.getIn(['question', 'movingDown'])
export const getTransportScoringQuestionError = state => state.transportScoring.getIn(['question', 'error'])

export const isTransportScoringAnswerSaving = state => state.transportScoring.getIn(['answer', 'saving'])
export const isTransportScoringAnswerDeleting = state => state.transportScoring.getIn(['answer', 'deleting'])
export const isTransportScoringAnswerMovingUp = state => state.transportScoring.getIn(['answer', 'movingUp'])
export const isTransportScoringAnswerMovingDown = state => state.transportScoring.getIn(['answer', 'movingDown'])
export const getTransportScoringAnswerError = state => state.transportScoring.getIn(['answer', 'error'])

export const isTransportScoringRuleSaving = state => state.transportScoring.getIn(['rule', 'saving'])
export const isTransportScoringRuleDeleting = state => state.transportScoring.getIn(['rule', 'deleting'])
export const getTransportScoringRuleError = state => state.transportScoring.getIn(['rule', 'error'])
