import * as actionTypes from './actionTypes'

// Fetch

export const fetchCostTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchCostTypesFulfilled = costTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: costTypes,
})

export const fetchCostTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCostTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCostType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCostTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCostTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCostTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCostType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveCostTypeFulfilled = costType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: costType,
})

export const saveCostTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteCostType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCostTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCostTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})
