import * as actionTypes from './actionTypes'

// Fetch

export const fetchServiceJobs = () => ({
    type: actionTypes.FETCH,
})

export const fetchServiceJobsFulfilled = serviceJobs => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: serviceJobs,
})

export const fetchServiceJobsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchServiceJobsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchServiceJob = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchServiceJobFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchServiceJobRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchServiceJobCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveServiceJob = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveServiceJobFulfilled = serviceJob => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: serviceJob,
})

export const saveServiceJobRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteServiceJob = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteServiceJobFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteServiceJobRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportServiceJobs = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
