import { createSelector } from 'reselect'

export const getServiceOrders = state => state.serviceOrders.getIn(['all', 'objects'])
export const createGetServiceOrderByIDSelector = id => createSelector([getServiceOrders], orders => orders.get(String(id)))

const getCurrentServiceOrderStatus = state => state.serviceOrders.get('current')
export const createGetCurrentServiceOrderStatusSelector = () => createSelector([getCurrentServiceOrderStatus], status => status)

export const getServiceOrderDeleting = state => state.serviceOrders.getIn(['deletion', 'inProgress'])
export const getServiceOrderDeleteError = state => state.serviceOrders.getIn(['deletion', 'error'])

export const getServiceJobData = state => state.serviceOrders.getIn(['serviceJob', 'data'])
export const getServiceJobFetching = state => state.serviceOrders.getIn(['serviceJob', 'fetching'])
export const getServiceJobError = state => state.serviceOrders.getIn(['serviceJob', 'error'])

export const getServiceMaterialData = state => state.serviceOrders.getIn(['serviceMaterial', 'data'])
export const getServiceMaterialFetching = state => state.serviceOrders.getIn(['serviceMaterial', 'fetching'])
export const getServiceMaterialError = state => state.serviceOrders.getIn(['serviceMaterial', 'error'])

export const getServiceWorkerData = state => state.serviceOrders.getIn(['serviceWorker', 'data'])
export const getServiceWorkerFetching = state => state.serviceOrders.getIn(['serviceWorker', 'fetching'])
export const getServiceWorkerError = state => state.serviceOrders.getIn(['serviceWorker', 'error'])

export const getServiceOrderStates = state => state.serviceOrders.getIn(['serviceOrderStates', 'objects'])
export const createGetServiceOrderStateByIDSelector = id => createSelector([getServiceOrderStates], states => states.get(String(id)))