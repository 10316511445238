import { Record } from 'immutable'

export class TrailerType extends Record({
    id: undefined,
    name: undefined,
    width: undefined,
    height: undefined,
    length: undefined,
    axles_count: undefined,
    weight: undefined,
    capacity_pallets: undefined,
    is_secured: undefined,
    is_cooled: undefined,
    transport_types: [],
    vehicle_types: [],
    cargo_types: [],
    goods_types: [],
}) {}
