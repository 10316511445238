import React from 'react'
import { Route, Switch } from 'react-router'
import SalesTypesList from './SalesTypesList'
import SalesTypesEdit from './SalesTypesEdit'

const SalesTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={SalesTypesList} />
        <Route exact path={`${props.match.url}/new`} component={SalesTypesEdit} />
        <Route exact path={`${props.match.url}/:salesTypeID`} component={SalesTypesEdit} />
    </Switch>
)

export default SalesTypes
