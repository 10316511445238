import * as actionTypes from './actionTypes'

// Fetch

export const fetchCountries = () => ({
    type: actionTypes.FETCH,
})

export const fetchCountriesFulfilled = countries => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: countries,
})

export const fetchCountriesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCountriesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCountry = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCountryFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCountryRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCountryCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCountry = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveCountryFulfilled = country => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: country,
})

export const saveCountryRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteCountry = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCountryFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCountryRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportCountries = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
