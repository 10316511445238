import { Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
    }),
    accept: Map({
        inProgress: false,
        error: null,
    }),
    decline: Map({
        inProgress: false,
        error: null,
    }),
})

function transporeonOffers(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // Accept
        case actionTypes.ACCEPT_STARTED:
            return state.setIn(['accept', 'inProgress'], true)
        case actionTypes.ACCEPT_FULFILLED:
            return state.withMutations(map => {
                action.payload.forEach(id => {
                    map.deleteIn(['all', 'objects', id])
                })

                map.setIn(['accept', 'inProgress'], false).setIn(['accept', 'error'], null)
            })
        case actionTypes.ACCEPT_REJECTED:
            return state.withMutations(map => {
                map.setIn(['accept', 'inProgress'], false).setIn(['accept', 'error'], action.payload)
            })

        // Decline
        case actionTypes.DECLINE_STARTED:
            return state.setIn(['decline', 'inProgress'], true)
        case actionTypes.DECLINE_FULFILLED:
            return state.withMutations(map => {
                action.payload.forEach(id => {
                    map.deleteIn(['all', 'objects', id])
                })

                map.setIn(['decline', 'inProgress'], false).setIn(['decline', 'error'], null)
            })
        case actionTypes.DECLINE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['decline', 'inProgress'], false).setIn(['decline', 'error'], action.payload)
            })
        default:
            return state
    }
}

export default transporeonOffers
