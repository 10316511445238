import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import moment from 'moment'

import ColoredBox from '../../../common/colored_box/components/ColoredBox'

import * as formatters from '../../../common/formatters'

import {
    convertISODatetimeToCS,
    getListItemsCountMessage,
    getPrerequisitesArray,
    getTransportPointPlannedDatetimeType,
} from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField, TableFilterSelectField, TableFilterCheckboxField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'

import * as actionCreators from '../actionCreators'
import { getData, getDatetime } from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {Link} from "react-router-dom";
import {CustomDatePicker} from "../../../common/custom_date_picker";

const tableIdentifier = 'report_transport_changes'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'report_transport_changes'
const prerequisites = ['cargo_types', 'dispatchers', 'active_drivers']

const columns = {
    datetime: new TableModelColumn({}),
    transport_id: new TableModelColumn({}),
}

class ReportTransportChanges extends Component {
    handleDatetimeChange = value => {
        this.props.actions.fetchReportTransportChanges(this.props.tableModel.get('filters'), value)
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportReportTransportChanges(this.props.tableModel.get('filters'), this.props.datetime)
    }

    refresh = () => {
        this.props.actions.fetchReportTransportChanges(this.props.tableModel.get('filters'), this.props.datetime)
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        this.props.actions.fetchReportTransportChanges(this.props.tableModel.get('filters'))
        this.props.actions.fetchPrerequisites()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportTransportChanges.heading' }))

        const filters = this.props.tableModel.get('filters')

        const driversOptions = getPrerequisitesArray(this.props.prerequisites.values.get('active_drivers'))
        driversOptions.unshift({
            id: 'no_driver',
            name: this.props.intl.formatMessage({ id: 'fields.noDriver' }),
        })

        const tooltipNoTime = <Tooltip id="tooltipNoTime">{this.props.intl.formatMessage({ id: 'fields.noTime' })}</Tooltip>
        const tooltipNoVehicle = <Tooltip id="tooltipNoVehicle">{this.props.intl.formatMessage({ id: 'fields.noVehicle' })}</Tooltip>
        const tooltipNoTrailer = <Tooltip id="tooltipNoTrailer">{this.props.intl.formatMessage({ id: 'fields.noTrailer' })}</Tooltip>

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.reportTransportChanges.heading" />
                                </h4>

                                <div className="pull-left" style={{ marginLeft: '100px' }}>
                                    <TableFilterCheckboxField
                                        identifier="only_changed"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        label={this.props.intl.formatMessage({ id: 'fields.showOnlyChangedTransports' })}
                                    />
                                </div>

                                <div className="btns-list">
                                    <div className="pull-left text-thin datetime-selection m-r-lg">
                                        <div className="pull-left m-r-xs p-t-5">
                                            <FormattedMessage id="fields.showChangesFrom" />:
                                        </div>
                                        <div className="pull-left">
                                            <CustomDatePicker
                                                isTimePicker={true}
                                                id="datetime"
                                                timeIntervals={5}
                                                selected={this.props.datetime && moment(this.props.datetime)}
                                                value={convertISODatetimeToCS(this.props.datetime)}
                                                onChange={value => {
                                                    this.handleDatetimeChange(value ? formatters.datetimeFormatter(value) : '')
                                                }}
                                                onChangeRaw={e => {
                                                    this.handleDatetimeChange(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header table-transports">
                                        <thead>
                                            <tr>
                                                <th className="w-80">
                                                    <FormattedMessage id="fields.transport" />
                                                </th>
                                                <th className="w-max wm-300">
                                                    <FormattedMessage id="fields.customer" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.orderNumber" />
                                                </th>
                                                <th className="w-180">
                                                    <FormattedMessage id="fields.cargoType" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.vehicle" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.trailer" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                <th className="w-400">
                                                    <FormattedMessage id="fields.loading" />
                                                </th>
                                                <th className="w-400">
                                                    <FormattedMessage id="fields.unloading" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-80">
                                                    <TableFilterInputField
                                                        identifier="transport_id"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-max">
                                                    <TableFilterInputField
                                                        identifier="customer"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-140">
                                                    <TableFilterInputField
                                                        identifier="order_number"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-180">
                                                    <TableFilterSelectField
                                                        identifier="cargo_type_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('cargo_types'))}
                                                        prompt={this.props.intl.formatMessage({ id: 'fields.cargoType' })}
                                                    />
                                                </th>
                                                <th className="w-120">
                                                    <div className="pull-left wp-100">
                                                        <div className="pull-left w-80">
                                                            <TableFilterInputField
                                                                identifier="vehicle"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                            />
                                                        </div>
                                                        <div className="pull-left w-20">
                                                            <OverlayTrigger placement="top" overlay={tooltipNoVehicle}>
                                                                <div>
                                                                    <TableFilterCheckboxField
                                                                        identifier="no_vehicle"
                                                                        type={TableModelColumnFilteringType.equal}
                                                                        filters={filters}
                                                                        onChange={this.props.table.changeFilter}
                                                                        label=""
                                                                    />
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                    <div className="pull-left wp-100 m-t-xxs">
                                                        <TableFilterSelectField
                                                            identifier="dispatcher_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                            prompt={this.props.intl.formatMessage({ id: 'fields.dispatcher' })}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-120">
                                                    <div className="pull-left wp-100">
                                                        <div className="pull-left w-80">
                                                            <TableFilterInputField
                                                                identifier="trailer"
                                                                type={TableModelColumnFilteringType.string}
                                                                filters={filters}
                                                                onChange={this.props.table.changeFilter}
                                                                placeholder={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                            />
                                                        </div>
                                                        <div className="pull-left w-20">
                                                            <OverlayTrigger placement="top" overlay={tooltipNoTrailer}>
                                                                <div>
                                                                    <TableFilterCheckboxField
                                                                        identifier="no_trailer"
                                                                        type={TableModelColumnFilteringType.equal}
                                                                        filters={filters}
                                                                        onChange={this.props.table.changeFilter}
                                                                        label=""
                                                                    />
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="w-120">
                                                    <TableFilterSelectField
                                                        identifier="driver_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={driversOptions}
                                                        prompt={this.props.intl.formatMessage({ id: 'fields.driver' })}
                                                    />
                                                </th>
                                                <th className="w-400">
                                                    <TableFilterInputField
                                                        identifier="loading_address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.address' })}
                                                    />
                                                    <div className="m-t-xxs w-90 pull-left m-r-xxs">
                                                        <TableFilterInputField
                                                            identifier="loading_date_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="loading_date_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-20 pull-left m-r-xs">
                                                        <OverlayTrigger placement="top" overlay={tooltipNoTime}>
                                                            <div>
                                                                <TableFilterCheckboxField
                                                                    identifier="loading_no_date"
                                                                    type={TableModelColumnFilteringType.equal}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    label=""
                                                                />
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="m-t-xxs pull-left" style={{ width: '99px' }}>
                                                        <TableFilterInputField
                                                            identifier="loading_code"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.code' })}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-400">
                                                    <TableFilterInputField
                                                        identifier="unloading_address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.address' })}
                                                    />
                                                    <div className="m-t-xxs w-90 pull-left m-r-xxs">
                                                        <TableFilterInputField
                                                            identifier="unloading_date_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="unloading_date_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-20 pull-left m-r-xs">
                                                        <OverlayTrigger placement="top" overlay={tooltipNoTime}>
                                                            <div>
                                                                <TableFilterCheckboxField
                                                                    identifier="unloading_no_date"
                                                                    type={TableModelColumnFilteringType.equal}
                                                                    filters={filters}
                                                                    onChange={this.props.table.changeFilter}
                                                                    label=""
                                                                />
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="m-t-xxs pull-left" style={{ width: '99px' }}>
                                                        <TableFilterInputField
                                                            identifier="unloading_code"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.code' })}
                                                        />
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.data &&
                                                this.props.data.data.map(item => {
                                                    const changes = item.changes

                                                    // customer
                                                    const tooltipChangeCustomer = changes.customer && (
                                                        <Tooltip id="tooltipChangeCustomer">
                                                            {changes.customer.map(change => (
                                                                <div key={`change-customer-${change.id}`}>
                                                                    {change.datetime} - {change.user}
                                                                </div>
                                                            ))}
                                                        </Tooltip>
                                                    )

                                                    const contentCustomer = (
                                                        <div className={`${changes.customer ? 'changed' : ''}`}>
                                                            {item.customers.map(customer => (
                                                                <div key={`customer-${item.id}-${customer.id}`}>
                                                                    <strong>{customer.name}</strong>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )

                                                    // order number
                                                    const tooltipChangeOrderNumber = changes.order_number && (
                                                        <Tooltip id="tooltipChangeOrderNumber">
                                                            {changes.order_number.map(change => (
                                                                <div key={`change-order_number-${change.id}`}>
                                                                    {change.datetime} - {change.user}
                                                                </div>
                                                            ))}
                                                        </Tooltip>
                                                    )

                                                    const contentOrderNumber = (
                                                        <div className={`${changes.order_number ? 'changed' : ''}`}>
                                                            {item.customers.map(customer => (
                                                                <div key={`order_number-${item.id}-${customer.id}`}>{customer.order_number}</div>
                                                            ))}
                                                        </div>
                                                    )

                                                    // cargo type
                                                    const tooltipChangeCargoType = changes.cargo_type && (
                                                        <Tooltip id="tooltipChangeCargoType">
                                                            {changes.cargo_type.map(change => (
                                                                <div key={`change-cargo_type-${change.id}`}>
                                                                    {change.datetime} - {change.user}
                                                                </div>
                                                            ))}
                                                        </Tooltip>
                                                    )

                                                    const contentCargoType = (
                                                        <div className={`${changes.cargo_type ? 'changed' : ''}`}>
                                                            {item.cargo_type && item.cargo_type.name}
                                                        </div>
                                                    )

                                                    // vehicles
                                                    const tooltipChangeVehicle = changes.vehicle && (
                                                        <Tooltip id="tooltipChangeVehicle">
                                                            {changes.vehicle.map(change => (
                                                                <div key={`change-vehicle-${change.id}`}>
                                                                    {change.datetime} - {change.user}
                                                                </div>
                                                            ))}
                                                        </Tooltip>
                                                    )

                                                    const contentVehicle = (
                                                        <div>
                                                            {item.vehicles.map(vehicle => (
                                                                <span key={`vehicle-${item.id}-${vehicle.id}`} className="display-inline wp-100">
                                                                    <ColoredBox
                                                                        bgRGB={vehicle.dispatcher && vehicle.dispatcher.rgb_bg}
                                                                        textRGB={vehicle.dispatcher && vehicle.dispatcher.rgb_text}
                                                                    >
                                                                        {vehicle.registration}
                                                                    </ColoredBox>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    )

                                                    // loading name
                                                    const tooltipChangeLoadingName = changes.loading_name && (
                                                        <Tooltip id="tooltipChangeLoadingName">
                                                            {changes.loading_name.map(change => (
                                                                <div key={`change-loading_name-${change.id}`}>
                                                                    {change.datetime} - {change.user}
                                                                </div>
                                                            ))}
                                                        </Tooltip>
                                                    )

                                                    const contentLoadingName = (
                                                        <span className={`${changes.loading_name ? 'changed' : ''}`}>
                                                            {item.loading && item.loading.name}
                                                        </span>
                                                    )

                                                    // loading address
                                                    const addressChanges = []
                                                    changes.loading_street &&
                                                        changes.loading_street.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (addressChanges.indexOf(changeName) === -1) {
                                                                addressChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.loading_city &&
                                                        changes.loading_city.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (addressChanges.indexOf(changeName) === -1) {
                                                                addressChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.loading_zipcode &&
                                                        changes.loading_zipcode.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (addressChanges.indexOf(changeName) === -1) {
                                                                addressChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.loading_country &&
                                                        changes.loading_country.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (addressChanges.indexOf(changeName) === -1) {
                                                                addressChanges.push(changeName)
                                                            }
                                                        })

                                                    const tooltipChangeLoadingAddress = addressChanges.length > 0 && (
                                                        <Tooltip id="tooltipChangeLoadingAddress">
                                                            {addressChanges.map(change => <div key={`change-loading_address-${change}`}>{change}</div>)}
                                                        </Tooltip>
                                                    )

                                                    const contentLoadingAddress = (
                                                        <span className={`${addressChanges.length > 0 ? 'changed' : ''}`}>
                                                            {item.loading && item.loading.address}
                                                        </span>
                                                    )

                                                    // loading datetime
                                                    const datetimeChanges = []
                                                    changes.loading_datetime_booking_from &&
                                                        changes.loading_datetime_booking_from.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (datetimeChanges.indexOf(changeName) === -1) {
                                                                datetimeChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.loading_datetime_booking_to &&
                                                        changes.loading_datetime_booking_to.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (datetimeChanges.indexOf(changeName) === -1) {
                                                                datetimeChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.loading_datetime_specified_from &&
                                                        changes.loading_datetime_specified_from.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (datetimeChanges.indexOf(changeName) === -1) {
                                                                datetimeChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.loading_datetime_specified_to &&
                                                        changes.loading_datetime_specified_to.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (datetimeChanges.indexOf(changeName) === -1) {
                                                                datetimeChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.loading_datetime_planned_from &&
                                                        changes.loading_datetime_planned_from.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (datetimeChanges.indexOf(changeName) === -1) {
                                                                datetimeChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.loading_datetime_planned_to &&
                                                        changes.loading_datetime_planned_to.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (datetimeChanges.indexOf(changeName) === -1) {
                                                                datetimeChanges.push(changeName)
                                                            }
                                                        })

                                                    const tooltipChangeLoadingDatetime = datetimeChanges.length > 0 && (
                                                        <Tooltip id="tooltipChangeLoadingDatetime">
                                                            {datetimeChanges.map(change => (
                                                                <div key={`change-loading_datetime_booking_from-${change}`}>{change}</div>
                                                            ))}
                                                        </Tooltip>
                                                    )

                                                    const loadingDatetimeType = item.loading && getTransportPointPlannedDatetimeType(item.loading)
                                                    const contentLoadingDatetime = (
                                                        <span className={`${datetimeChanges.length > 0 ? 'changed' : ''}`}>
                                                            {loadingDatetimeType && `${loadingDatetimeType}: `}
                                                            {item.loading &&
                                                                (item.loading.datetime_booking ||
                                                                    item.loading.datetime_specified ||
                                                                    item.loading.datetime_planned)}
                                                        </span>
                                                    )

                                                    // loading code
                                                    const tooltipChangeLoadingCode = changes.loading_code && (
                                                        <Tooltip id="tooltipChangeLoadingCode">
                                                            {changes.loading_code.map(change => (
                                                                <div key={`change-loading_code-${change.id}`}>
                                                                    {change.datetime} - {change.user}
                                                                </div>
                                                            ))}
                                                        </Tooltip>
                                                    )

                                                    const contentLoadingCode = (
                                                        <span className={`${changes.loading_code ? 'changed' : ''}`}>
                                                            {item.loading && item.loading.code}
                                                        </span>
                                                    )

                                                    // unloading name
                                                    const tooltipChangeUnloadingName = changes.unloading_name && (
                                                        <Tooltip id="tooltipChangeUnloadingName">
                                                            {changes.unloading_name.map(change => (
                                                                <div key={`change-unloading_name-${change.id}`}>
                                                                    {change.datetime} - {change.user}
                                                                </div>
                                                            ))}
                                                        </Tooltip>
                                                    )

                                                    const contentUnloadingName = (
                                                        <span className={`${changes.unloading_name ? 'changed' : ''}`}>
                                                            {item.unloading && item.unloading.name}
                                                        </span>
                                                    )

                                                    // unloading address
                                                    const unloadingAddressChanges = []
                                                    changes.unloading_street &&
                                                        changes.unloading_street.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (unloadingAddressChanges.indexOf(changeName) === -1) {
                                                                unloadingAddressChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.unloading_city &&
                                                        changes.unloading_city.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (unloadingAddressChanges.indexOf(changeName) === -1) {
                                                                unloadingAddressChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.unloading_zipcode &&
                                                        changes.unloading_zipcode.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (unloadingAddressChanges.indexOf(changeName) === -1) {
                                                                unloadingAddressChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.unloading_country &&
                                                        changes.unloading_country.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (unloadingAddressChanges.indexOf(changeName) === -1) {
                                                                unloadingAddressChanges.push(changeName)
                                                            }
                                                        })

                                                    const tooltipChangeUnloadingAddress = unloadingAddressChanges.length > 0 && (
                                                        <Tooltip id="tooltipChangeUnloadingAddress">
                                                            {unloadingAddressChanges.map(change => (
                                                                <div key={`change-unloading_address-${change}`}>{change}</div>
                                                            ))}
                                                        </Tooltip>
                                                    )

                                                    const contentUnloadingAddress = (
                                                        <span className={`${unloadingAddressChanges.length > 0 ? 'changed' : ''}`}>
                                                            {item.unloading && item.unloading.address}
                                                        </span>
                                                    )

                                                    // unloading datetime
                                                    const unloadingDatetimeChanges = []
                                                    changes.unloading_datetime_booking_from &&
                                                        changes.unloading_datetime_booking_from.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (unloadingDatetimeChanges.indexOf(changeName) === -1) {
                                                                unloadingDatetimeChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.unloading_datetime_booking_to &&
                                                        changes.unloading_datetime_booking_to.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (unloadingDatetimeChanges.indexOf(changeName) === -1) {
                                                                unloadingDatetimeChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.unloading_datetime_specified_from &&
                                                        changes.unloading_datetime_specified_from.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (unloadingDatetimeChanges.indexOf(changeName) === -1) {
                                                                unloadingDatetimeChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.unloading_datetime_specified_to &&
                                                        changes.unloading_datetime_specified_to.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (unloadingDatetimeChanges.indexOf(changeName) === -1) {
                                                                unloadingDatetimeChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.unloading_datetime_planned_from &&
                                                        changes.unloading_datetime_planned_from.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (unloadingDatetimeChanges.indexOf(changeName) === -1) {
                                                                unloadingDatetimeChanges.push(changeName)
                                                            }
                                                        })
                                                    changes.unloading_datetime_planned_to &&
                                                        changes.unloading_datetime_planned_to.forEach(change => {
                                                            const changeName = `${change.datetime} - ${change.user}`
                                                            if (unloadingDatetimeChanges.indexOf(changeName) === -1) {
                                                                unloadingDatetimeChanges.push(changeName)
                                                            }
                                                        })

                                                    const tooltipChangeUnloadingDatetime = unloadingDatetimeChanges.length > 0 && (
                                                        <Tooltip id="tooltipChangeUnloadingDatetime">
                                                            {unloadingDatetimeChanges.map(change => (
                                                                <div key={`change-unloading_datetime_booking_from-${change}`}>{change}</div>
                                                            ))}
                                                        </Tooltip>
                                                    )

                                                    const unloadingDatetimeType = item.unloading && getTransportPointPlannedDatetimeType(item.unloading)
                                                    const contentUnloadingDatetime = (
                                                        <span className={`${unloadingDatetimeChanges.length > 0 ? 'changed' : ''}`}>
                                                            {unloadingDatetimeType && `${unloadingDatetimeType}: `}
                                                            {item.unloading &&
                                                                (item.unloading.datetime_booking ||
                                                                    item.unloading.datetime_specified ||
                                                                    item.unloading.datetime_planned)}
                                                        </span>
                                                    )

                                                    // unloading code
                                                    const tooltipChangeUnloadingCode = changes.unloading_code && (
                                                        <Tooltip id="tooltipChangeUnloadingCode">
                                                            {changes.unloading_code.map(change => (
                                                                <div key={`change-unloading_code-${change.id}`}>
                                                                    {change.datetime} - {change.user}
                                                                </div>
                                                            ))}
                                                        </Tooltip>
                                                    )

                                                    const contentUnloadingCode = (
                                                        <span className={`${changes.unloading_code ? 'changed' : ''}`}>
                                                            {item.unloading && item.unloading.code}
                                                        </span>
                                                    )

                                                    return (
                                                        <tr key={item.id}>
                                                            <td className="w-80">
                                                                <strong>
                                                                        <Link to={`/transports/${item.id}`}>
                                                                            {item.id}
                                                                        </Link>
                                                                </strong>
                                                            </td>
                                                            <td className={`w-max wm-300 ${changes.customer ? 'td-changed' : ''}`}>
                                                                {changes.customer ? (
                                                                    <OverlayTrigger placement="top" overlay={tooltipChangeCustomer}>
                                                                        {contentCustomer}
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    contentCustomer
                                                                )}
                                                            </td>
                                                            <td className={`w-140 ${changes.order_number ? 'td-changed' : ''}`}>
                                                                {changes.order_number ? (
                                                                    <OverlayTrigger placement="top" overlay={tooltipChangeOrderNumber}>
                                                                        {contentOrderNumber}
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    contentOrderNumber
                                                                )}
                                                            </td>
                                                            <td className={`w-180 ${changes.cargo_type ? 'td-changed' : ''}`}>
                                                                {changes.cargo_type ? (
                                                                    <OverlayTrigger placement="top" overlay={tooltipChangeCargoType}>
                                                                        {contentCargoType}
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    contentCargoType
                                                                )}
                                                            </td>
                                                            <td className={`w-120 ${changes.vehicle ? 'td-changed' : ''}`}>
                                                                {changes.vehicle ? (
                                                                    <OverlayTrigger placement="top" overlay={tooltipChangeVehicle}>
                                                                        {contentVehicle}
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    contentVehicle
                                                                )}
                                                            </td>
                                                            <td className="w-120">
                                                                {item.trailers.map(trailer => (
                                                                    <span key={`trailer-${item.id}-${trailer.id}`} className="display-inline wp-100">
                                                                        <ColoredBox>{trailer.registration}</ColoredBox>
                                                                    </span>
                                                                ))}
                                                            </td>
                                                            <td className="w-120">
                                                                {item.drivers.map(driver => (
                                                                    <span key={`driver-${item.id}-${driver.id}`} className="display-inline wp-100">
                                                                        {driver.name}
                                                                    </span>
                                                                ))}
                                                            </td>
                                                            <td
                                                                className={`w-400 ${
                                                                    changes.loading_name ||
                                                                    changes.loading_code ||
                                                                    addressChanges.length > 0 ||
                                                                    datetimeChanges.length > 0
                                                                        ? 'td-changed'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {item.loading && (
                                                                    <div>
                                                                        <strong>
                                                                            {addressChanges.length > 0 ? (
                                                                                <OverlayTrigger placement="top" overlay={tooltipChangeLoadingAddress}>
                                                                                    {contentLoadingAddress}
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                contentLoadingAddress
                                                                            )}
                                                                            {item.loading.address && item.loading.name && ` - `}
                                                                            {changes.loading_name ? (
                                                                                <OverlayTrigger placement="top" overlay={tooltipChangeLoadingName}>
                                                                                    {contentLoadingName}
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                contentLoadingName
                                                                            )}
                                                                        </strong>
                                                                        <br />
                                                                        <small>
                                                                            {datetimeChanges.length > 0 ? (
                                                                                <OverlayTrigger placement="top" overlay={tooltipChangeLoadingDatetime}>
                                                                                    {contentLoadingDatetime}
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                contentLoadingDatetime
                                                                            )}
                                                                            {item.loading.code && ` - `}
                                                                            {changes.loading_code ? (
                                                                                <OverlayTrigger placement="top" overlay={tooltipChangeLoadingCode}>
                                                                                    {contentLoadingCode}
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                contentLoadingCode
                                                                            )}
                                                                        </small>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td
                                                                className={`w-400 ${
                                                                    changes.unloading_name ||
                                                                    changes.unloading_code ||
                                                                    unloadingAddressChanges.length > 0 ||
                                                                    unloadingDatetimeChanges.length > 0
                                                                        ? 'td-changed'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {item.unloading && (
                                                                    <div>
                                                                        <strong>
                                                                            {unloadingAddressChanges.length > 0 ? (
                                                                                <OverlayTrigger placement="top" overlay={tooltipChangeUnloadingAddress}>
                                                                                    {contentUnloadingAddress}
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                contentUnloadingAddress
                                                                            )}
                                                                            {item.unloading.address && item.unloading.name && ` - `}
                                                                            {changes.unloading_name ? (
                                                                                <OverlayTrigger placement="top" overlay={tooltipChangeUnloadingName}>
                                                                                    {contentUnloadingName}
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                contentUnloadingName
                                                                            )}
                                                                        </strong>
                                                                        <br />
                                                                        <small>
                                                                            {unloadingDatetimeChanges.length > 0 ? (
                                                                                <OverlayTrigger placement="top" overlay={tooltipChangeUnloadingDatetime}>
                                                                                    {contentUnloadingDatetime}
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                contentUnloadingDatetime
                                                                            )}
                                                                            {item.unloading.code && ` - `}
                                                                            {changes.unloading_code ? (
                                                                                <OverlayTrigger placement="top" overlay={tooltipChangeUnloadingCode}>
                                                                                    {contentUnloadingCode}
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                contentUnloadingCode
                                                                            )}
                                                                        </small>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.data.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.data.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getData,
            clientSideItemsPerPage,
        })(state),
        datetime: getDatetime(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReportTransportChanges))
