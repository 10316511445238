import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

import localizations from '../../../common/localizations'

function mapStateToProps(state) {
    const currentLocale = state.locale.get('current')
    const messages = localizations[currentLocale]

    return {
        locale: currentLocale,
        messages,
    }
}

export default connect(mapStateToProps)(IntlProvider)
