import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import * as formatters from '../../../common/formatters'

import PermissionsCheck from '../../auth/components/PermissionsCheck'

import { PERMS } from '../permissions'
import Dropzone from "react-dropzone"
import * as customerAddressesActionCreators from "../actionCreators"
import * as selectors from "../selectors"
import { LoadingOverlay } from "../../../common/loading_overlay"
import { SelectField } from "../../../common/form";

class CustomerAddressesImport extends Component {
    stepNames = {
        1: this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.stepNames.1' }),
        2: this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.stepNames.2' }),
        3: this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.stepNames.3' })
    }

    columnTypes = {
        'name': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.name' }),
        'code': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.code' }),
        'customer': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.customer' }),
        'addressType': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.addressType' }),
        'street': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.street' }),
        'zipcode': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.zipcode' }),
        'city': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.city' }),
        'country': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.country' }),
        'gps': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.gps' }),
        'email': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.email' }),
        'phone': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.phone' }),
        'note': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.note' }),
        'booking': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.booking' }),
        'bookingUrl': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.bookingUrl' }),
        'bookingNote': this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.columnTypes.bookingNote' }),
    }

    state = {
        step: 1,
        importHeaders: [],
        importFileId: null,
        importFileName: null,
        importItems: [],
        importItemValues: [],
        columnTypes: {},
        importAllItemsChecked: false
    }

    importProcessing = false

    onDropFiles = files => {
        files.forEach(file => {
            const reader = new FileReader()
            const fileName = file.name

            reader.onload = e => {
                const loadedFile = {
                    name: fileName,
                    base64: e.target.result,
                }
                this.props.actions.fetchCustomerAddressesImportHeaders(loadedFile)
            }

            reader.readAsDataURL(file)
        })
    }

    onBackButtonClick = () => {
        this.setState({
            ...this.state,
            step: this.state.step - 1
        })
    }

    onSummaryButtonClick = () => {
        this.props.actions.fetchCustomerAddressesImportItems(this.state.importFileId, this.state.columnTypes)
    }

    setColumnType = (id, value) => {
        this.setState({
            columnTypes: {
                ...this.state.columnTypes,
                [id]: value
            }
        })
    }


    onImportItemChange = (key, value) => {
        this.setState({
            importItemValues: {
                ...this.state.importItemValues,
                [key]: value
            }
        })
    }

    onImportAllItemsChange = (value) => {
        this.setState({
            importAllItemsChecked: value
        })

        var importItemValues = {};

        for (var key in this.state.importItemValues) {
            importItemValues[key] = value
        }

        this.setState({
            importItemValues: importItemValues
        })
    }

    onImportButtonClick = () => {
        this.importProcessing = true
        this.props.actions.importCustomerAddresses(this.state.importFileId, this.state.columnTypes, this.state.importItemValues)
    }

    componentWillMount() {

    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.importInProgress && !nextProps.importInProgress) {
            // Import error
            if (nextProps.importError) {
                this.props.notify({
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: nextProps.importError,
                    position: 'tc',
                }, 'error')

                this.importProcessing = false

                return false;
            }
            else if (this.state.step === 1) {
                const headers = nextProps.importHeaders.valueSeq().toArray()
                const defaultHeaders = nextProps.importDefaultHeaders.toJS()
                const columnTypes = {}

                nextProps.importHeaders.map((headerName, headerId) => {
                    if (defaultHeaders[headerName]) {
                        columnTypes[headerId] = defaultHeaders[headerName]
                    }

                    return false;
                })

                this.setState({
                    ...this.state,
                    step: 2,
                    importHeaders: headers,
                    importFileId: nextProps.importFileId,
                    importFileName: nextProps.importFileName,
                    columnTypes,
                })
            }
            else if (this.state.step >= 2) {
                if (this.importProcessing) {
                    this.props.notify({
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.importSuccess' }),
                        position: 'tc',
                    }, 'success')

                    this.props.redirect('/customer-addresses/')
                }

                const items = nextProps.importItems
                const itemValues = []

                items.map((item, key) => {
                    itemValues[key] = item.name ? true : false
                })

                this.setState({
                    ...this.state,
                    step: 3,
                    importItems: items,
                    importItemValues: itemValues,
                })
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.customerAddressesImport.heading' }))

        const columnTypesOptions = Object.keys(this.columnTypes).map(id => ({
            id: id,
            name: this.columnTypes[id],
        }))

        columnTypesOptions.unshift({
            id: null,
            name: '-'
        })

        return (<PermissionsCheck has={[PERMS.CREATE]} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className={this.state.step <= 2 ? 'col-md-12 col-xl-8 col-xl-offset-2' : 'col-md-12 col-xl-12'}>
                                <LoadingOverlay active={this.props.importInProgress}>
                                    <div className="panel panel-white">
                                        <div className="panel-body panel-padding">
                                            <h4>
                                                <FormattedMessage id="modules.customerAddressesImport.heading" /> - <FormattedMessage id="modules.customerAddressesImport.step" /> {this.state.step}/3 ({this.stepNames[this.state.step]})
                                            </h4>

                                            {this.state.step === 1 && (<div className="wp-100 pull-left clearfix">
                                                <p className="m-b-lg">
                                                    <FormattedMessage id="import.step1" />
                                                </p>
                                                <Dropzone onDrop={this.onDropFiles} multiple={false} style={{}} className="dropzone" acceptClassName="dropzone-accept">
                                                    <p className="ready">
                                                        <FormattedMessage id="fields.dropzoneReady" />
                                                    </p>
                                                    <p className="accept">
                                                        <FormattedMessage id="fields.dropzoneAccept" />
                                                    </p>
                                                </Dropzone>
                                            </div>)}

                                            {this.state.step === 2 && (<div className="wp-100 pull-left clearfix">
                                                <p className="m-b-lg">
                                                    <FormattedMessage id="import.step2" />
                                                </p>

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        {Object.keys(this.state.importHeaders).map(headerId => {
                                                            let headerName = this.state.importHeaders[headerId]

                                                            if(!headerName){
                                                                return
                                                            }
                                                            return <div key={headerId}>
                                                                <SelectField
                                                                    id={headerId}
                                                                    label={headerName}
                                                                    readonly={() => false}
                                                                    values={columnTypesOptions}
                                                                    value={this.state.columnTypes[headerId]}
                                                                    onChange={e => {
                                                                        this.setColumnType(headerId, e.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>)}

                                            {this.state.step === 3 && (<div className="wp-100 pull-left clearfix">
                                                <p className="m-b-lg">
                                                    <FormattedMessage id="import.step3" />
                                                </p>

                                                {!this.state.importItems.length && (<div className="alert alert-danger">
                                                    <p>
                                                        <FormattedMessage id="import.noItemsFound" />
                                                    </p>
                                                </div>)}

                                                {this.state.importItems.length && (<div>

                                                    <div className="table-container">
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th className="w-40 text-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={'item-all'}
                                                                        onChange={(e) => {
                                                                            this.onImportAllItemsChange(e.target.checked)
                                                                        }}
                                                                        checked={this.state.importAllItemsChecked}
                                                                    />
                                                                </th>
                                                                <th className="w-80">
                                                                    {this.columnTypes.code}
                                                                </th>
                                                                <th className="w-200">
                                                                    {this.columnTypes.name}
                                                                </th>
                                                                <th className="w-200">
                                                                    {this.columnTypes.customer}
                                                                </th>
                                                                <th className="w-120">
                                                                    {this.columnTypes.addressType}
                                                                </th>
                                                                <th className="w-120">
                                                                    {this.columnTypes.street}
                                                                </th>
                                                                <th className="w-60">
                                                                    {this.columnTypes.zipcode}
                                                                </th>
                                                                <th className="w-100">
                                                                    {this.columnTypes.city}
                                                                </th>
                                                                <th className="w-60">
                                                                    {this.columnTypes.country}
                                                                </th>
                                                                <th className="w-120">
                                                                    {this.columnTypes.gps}
                                                                </th>
                                                                <th className="w-160">
                                                                    {this.columnTypes.email}
                                                                </th>
                                                                <th className="w-100">
                                                                    {this.columnTypes.phone}
                                                                </th>
                                                                <th className="w-120">
                                                                    {this.columnTypes.note}
                                                                </th>
                                                                <th className="w-70 text-center">
                                                                    {this.columnTypes.booking}
                                                                </th>
                                                                <th className="w-120">
                                                                    {this.columnTypes.bookingUrl}
                                                                </th>
                                                                <th className="w-120">
                                                                    {this.columnTypes.bookingNote}
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.importItems.map((item, key) => {
                                                                return (<tr key={'importItem-' + key}>
                                                                    <td className="text-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={'item-' + key}
                                                                            onChange={(e) => {
                                                                                this.onImportItemChange(key, e.target.checked)
                                                                            }}
                                                                            disabled={!item.name}
                                                                            checked={this.state.importItemValues[key]}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        {item.code}
                                                                    </td>
                                                                    <td>
                                                                        {item.name}
                                                                    </td>
                                                                    <td>
                                                                        {item.customer && (<div>
                                                                            {item.customer} {formatters.boolFormatter(item.customerId ? 1 : 0)}
                                                                        </div>)}
                                                                    </td>
                                                                    <td>
                                                                        {item.addressType && (<div>
                                                                            {item.addressType} {formatters.boolFormatter(item.addressTypeId ? 1 : 0)}
                                                                        </div>)}
                                                                    </td>
                                                                    <td>
                                                                        {item.street}
                                                                    </td>
                                                                    <td>
                                                                        {item.zipcode}
                                                                    </td>
                                                                    <td>
                                                                        {item.city}
                                                                    </td>
                                                                    <td>
                                                                        {item.country && (<div>
                                                                            {item.country} {formatters.boolFormatter(item.countryId ? 1 : 0)}
                                                                        </div>)}
                                                                    </td>
                                                                    <td>
                                                                        {item.gps}
                                                                    </td>
                                                                    <td>
                                                                        {item.email}
                                                                    </td>
                                                                    <td>
                                                                        {item.phone}
                                                                    </td>
                                                                    <td>
                                                                        {item.note}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {formatters.boolFormatter(item.booking)}
                                                                    </td>
                                                                    <td>
                                                                        {item.bookingUrl}
                                                                    </td>
                                                                    <td>
                                                                        {item.bookingNote}
                                                                    </td>
                                                                </tr>)
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>)}
                                            </div>)}

                                            <div className="pull-left wp-100 btns-form">
                                                {this.state.step > 1 && (
                                                    <button onClick={this.onBackButtonClick} className="btn btn-addon btn-default">
                                                        <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                    </button>)}

                                                {this.state.step === 2 && (
                                                    <button onClick={this.onSummaryButtonClick} className="btn btn-addon btn-success pull-right">
                                                        <i className="far fa-chevron-right" /> <FormattedMessage id="buttons.continue" />
                                                    </button>)}

                                                {this.state.step === 3 && (
                                                    <button onClick={this.onImportButtonClick} className="btn btn-addon btn-success pull-right">
                                                        <i className="far fa-check" /> <FormattedMessage id="buttons.import" />
                                                    </button>)}
                                            </div>
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>)
    }
}

function mapStateToProps(state) {
    return {
        importInProgress: selectors.getImportInProgress(state),
        importError: selectors.getImportError(state),
        importFileId: selectors.getImportFileId(state),
        importHeaders: selectors.getImportHeaders(state),
        importDefaultHeaders: selectors.getImportDefaultHeaders(state),
        importItems: selectors.getImportItems(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators({
                ...customerAddressesActionCreators
            }, dispatch),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps, ...dispatchProps, ...ownProps
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(CustomerAddressesImport))
