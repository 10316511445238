// Fetch list

export const FETCH = 'hereMap.FETCH'
export const FETCH_STARTED = 'hereMap.FETCH_STARTED'
export const FETCH_FULFILLED = 'hereMap.FETCH_FULFILLED'
export const FETCH_REJECTED = 'hereMap.FETCH_REJECTED'
export const FETCH_CANCELLED = 'hereMap.FETCH_CANCELLED'

// Preferences

export const PREFERENCES_UPDATE = 'hereMap.PREFERENCES_UPDATE'
export const PREFERENCES_UPDATE_STARTED = 'hereMap.PREFERENCES_UPDATE_STARTED'
export const PREFERENCES_UPDATE_FULFILLED = 'hereMap.PREFERENCES_UPDATE_FULFILLED'
export const PREFERENCES_UPDATE_REJECTED = 'hereMap.PREFERENCES_UPDATE_REJECTED'
export const PREFERENCES_UPDATE_CANCELLED = 'hereMap.PREFERENCES_UPDATE_CANCELLED'
