export const PERMS = {
    READ: 'companies.read',
    UPDATE: 'companies.update',
    DELETE: 'companies.delete',
    CREATE: 'companies.create',
    EXPORT: 'companies.export',
}

export const PERMS_COST_CENTERS = {
    READ: 'company_cost_centers.read',
    UPDATE: 'company_cost_centers.update',
    DELETE: 'company_cost_centers.delete',
    CREATE: 'company_cost_centers.create',
    EXPORT: 'company_cost_centers.export',
}

export const PERMS_BANK_ACCOUNTS = {
    READ: 'company_bank_accounts.read',
    UPDATE: 'company_bank_accounts.update',
    DELETE: 'company_bank_accounts.delete',
    CREATE: 'company_bank_accounts.create',
    EXPORT: 'company_bank_accounts.export',
}

export const PERMS_DEFAULT_BANK_ACCOUNTS = {
    READ: 'company_default_bank_accounts.read',
    UPDATE: 'company_default_bank_accounts.update',
}

export const PERMS_NUMBERINGS = {
    READ: 'company_numberings.read',
    UPDATE: 'company_numberings.update',
    DELETE: 'company_numberings.delete',
    CREATE: 'company_numberings.create',
    EXPORT: 'company_numberings.export',
}

export const PERMS_SETTINGS = {
    READ: 'company_settings.read',
    UPDATE: 'company_settings.update',
}
