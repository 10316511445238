import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import scriptLoader from 'react-async-script-loader'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Notifications from 'react-notification-system-redux'

import { datetimeFormatter, distanceFormatter } from '../../formatters'
import { getGpsDistance } from '../../helpers'

import '../../../app/components/assets/css/mapsjs-ui.css'

class HEREMapTracing extends Component {
    platform = null
    defaultLayers = null
    map = null
    mapEvents = null
    behavior = null
    ui = null
    geocoder = null
    router = null
    mapInitialized = false

    mapRoutes = null
    mapRoutePoints = {}
    mapVehicle = null
    mapVehicles = {}

    // InfoBubble content
    printVehicle = (vehicle, selector = true) => {
        let content = `<p>${vehicle.name}: ${datetimeFormatter(vehicle.datetime)}`
        if (selector) {
            content += `<button type="button" data-id="${vehicle.id}">${this.props.intl.formatMessage({ id: 'buttons.select' })}</button>`
        }
        content += '</p>'
        return content
    }

    printDistance = (gps1, gps2) => {
        const distance = getGpsDistance(gps1.lat, gps1.lng, gps2.lat, gps2.lng)
        return `<p>${this.props.intl.formatMessage({ id: 'fields.distance' })}: ${distanceFormatter(distance)}</p>`
    }

    // Map init
    initMap = () => {
        const H = window.H

        this.platform = new H.service.Platform({
            app_id: this.props.appId,
            app_code: this.props.appCode,
            useHTTPS: true,
        })

        this.defaultLayers = this.platform.createDefaultLayers()

        this.map = new H.Map(document.getElementById('mapContainer'), this.defaultLayers.normal.map, {
            zoom: this.props.zoom,
            center: this.props.center,
        })

        this.mapEvents = new H.mapevents.MapEvents(this.map)
        this.behavior = new H.mapevents.Behavior(this.mapEvents)
        this.ui = H.ui.UI.createDefault(this.map, this.defaultLayers)
        this.geocoder = this.platform.getGeocodingService()
        this.router = this.platform.getRoutingService()

        const that = this

        const CUSTOM_THEME = {
            // Cluster
            getClusterPresentation(cluster) {
                let lat = null
                let lng = null
                const data = { cnt: 0, isCluster: false }
                const vehicleData = []

                cluster.forEachDataPoint(noisePoint => {
                    const position = noisePoint.getPosition()
                    vehicleData.push(noisePoint.getData())
                    if (lat === null && lng === null) {
                        lat = position.lat
                        lng = position.lng
                    } else if (lat !== position.lat || lng !== position.lng) {
                        // Markers are in diferent coords => is cluster
                        data.isCluster = true
                    }
                    data.cnt++
                })

                // Retrun cluster marker
                const clusterMarker = new H.map.Marker(cluster.getPosition(), {
                    icon: new H.map.Icon(
                        ReactDOMServer.renderToStaticMarkup(that.getClusterIconSVG(data.cnt, data.isCluster ? '#ffffff' : '#22baa0')),
                        {
                            size: { w: 30, h: 30 },
                            anchor: { x: 15, y: 15 },
                        }
                    ),
                    min: cluster.getMinZoom(),
                    max: cluster.getMaxZoom(),
                })

                if (!data.isCluster) {
                    data.vehicles = vehicleData
                }

                clusterMarker.setData(data)

                return clusterMarker
            },
            // Marker
            getNoisePresentation(noisePoint) {
                const data = noisePoint.getData()

                const clusterMarker = new H.map.Marker(noisePoint.getPosition(), {
                    icon: new H.map.Icon(ReactDOMServer.renderToStaticMarkup(that.getVehicleIconSVG(data.name, '#22baa0', '#ffffff'))),
                    min: noisePoint.getMinZoom(),
                })

                clusterMarker.setData(data)

                return clusterMarker
            },
        }

        this.mapVehicles = new window.H.clustering.Provider([], {
            clusteringOptions: {
                eps: 64,
                minWeight: 1,
            },
            theme: CUSTOM_THEME,
        })
        const vehiclesLayer = new window.H.map.layer.ObjectLayer(this.mapVehicles)
        this.map.addLayer(vehiclesLayer)

        this.mapRoutes = new H.map.Group()
        this.map.addObject(this.mapRoutes)

        this.mapInitialized = true

        // Add point - right click on map
        this.map.addEventListener('tap', e => {
            e.originalEvent.which === 3 && that.handlePointAdd(that.map.screenToGeo(e.currentPointer.viewportX, e.currentPointer.viewportY))
        })

        // Move marker
        this.map.addEventListener(
            'dragstart',
            ev => {
                const target = ev.target
                if (target instanceof window.H.map.Marker) {
                    that.behavior.disable()
                }
            },
            false
        )

        this.map.addEventListener(
            'dragend',
            ev => {
                const target = ev.target
                const pointer = ev.currentPointer

                if (target instanceof window.mapsjs.map.Marker) {
                    that.behavior.enable()
                    const id = target.getData().id
                    that.handlePointChange(that.map.screenToGeo(pointer.viewportX, pointer.viewportY), id)
                }
            },
            false
        )

        // Move marker on map
        this.map.addEventListener(
            'drag',
            ev => {
                const target = ev.target
                const pointer = ev.currentPointer

                if (target instanceof window.mapsjs.map.Marker) {
                    target.setPosition(that.map.screenToGeo(pointer.viewportX, pointer.viewportY))
                }
            },
            false
        )

        // Click on vehicle
        this.mapVehicles.addEventListener(
            'tap',
            evt => {
                const data = evt.target.getData()
                if (data.cnt) {
                    // Is cluster
                    if (data.isCluster) {
                        // Is cluster => zoom
                        this.map.setCenter(this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY), true)
                        this.map.setZoom(this.map.getZoom() + 2, true)
                    } else {
                        // Is many vehicles on same position => InfoBubble
                        let content = ''
                        data.vehicles.forEach(vehicle => {
                            content += this.printVehicle(vehicle)
                        })
                        // Can calculate distance
                        if (this.mapRoutePoints[0]) {
                            const startRoutePosition = this.mapRoutePoints[0].getPosition()
                            const vehiclePosition = { lat: data.vehicles[0].gps_lat, lng: data.vehicles[0].gps_lng }
                            content += this.printDistance(startRoutePosition, vehiclePosition)
                        }
                        this.addInfoBuble(evt.target, content)
                    }
                } else {
                    // Is vehicle
                    let content = ''
                    content += this.printVehicle(data)
                    // Can calculate distance
                    if (this.mapRoutePoints[0]) {
                        const startRoutePosition = this.mapRoutePoints[0].getPosition()
                        const vehiclePosition = { lat: data.gps_lat, lng: data.gps_lng }
                        content += this.printDistance(startRoutePosition, vehiclePosition)
                    }
                    this.addInfoBuble(evt.target, content)
                }
            },
            false
        )
    }

    addInfoBuble = (target, content) => {
        const bubble = new window.H.ui.InfoBubble(target.getGeometry(), {
            content,
        })
        bubble.addClass('vehicle-infobubble')
        this.ui.addBubble(bubble)

        // Add event on select vehicle button(s)
        const buttons = bubble.getContentElement().getElementsByTagName('button')
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', event => {
                this.handleVehicleSelect(event.target.dataset.id)
                bubble.close()
            })
        }
    }

    handlePointChange = (coord, id) => {
        this.geocoder.reverseGeocode(
            {
                prox: `${coord.lat},${coord.lng},150`,
                mode: 'retrieveAddresses',
                maxresults: '1',
                jsonattributes: 1,
                language: 'en',
            },
            result => {
                let address
                if (result && result.response && result.response.view) {
                    address = result.response.view[0].result[0]
                }
                this.props.handleRouterPointChange(id, coord, address)
            },
            () => {}
        )
    }

    handlePointAdd = coord => {
        this.geocoder.reverseGeocode(
            {
                prox: `${coord.lat},${coord.lng},150`,
                mode: 'retrieveAddresses',
                maxresults: '1',
                jsonattributes: 1,
                language: 'en',
            },
            result => {
                let address
                if (result && result.response && result.response.view) {
                    address = result.response.view[0].result[0]
                }
                this.props.handleAddRouterPoint(coord, address)
            },
            () => {}
        )
    }

    handleVehicleSelect = vehicleId => {
        this.props.handleVehicleSelect(vehicleId)
    }

    setMapCenter = center => {
        this.map.setCenter(center, true)
    }

    setMapZoom = zoom => {
        this.map.setZoom(zoom, true)
    }

    setMapVehicle = vehicle => {
        // Remove from map
        if (this.mapVehicle) {
            this.map.removeObject(this.mapVehicle)
            this.mapVehicle = null
        }

        if (vehicle && vehicle.gps_lat && vehicle.gps_lng) {
            // Remove vehicles from map
            this.mapVehicles.setDataPoints([])

            // Add marker
            const position = { lat: vehicle.gps_lat, lng: vehicle.gps_lng }
            const marker = new window.H.map.Marker(position, {
                icon: new window.H.map.Icon(ReactDOMServer.renderToStaticMarkup(this.getVehicleIconSVG(vehicle.name, '#22baa0', '#ffffff'))),
            })
            this.map.addObject(marker)
            this.mapVehicle = marker

            // Add InfoBubble
            this.mapVehicle.addEventListener('tap', e => {
                let content = ''
                content += this.printVehicle(vehicle, false)
                // Can calculate distance
                if (this.mapRoutePoints[0]) {
                    const startRoutePosition = this.mapRoutePoints[0].getPosition()
                    const vehiclePosition = { lat: vehicle.gps_lat, lng: vehicle.gps_lng }
                    content += this.printDistance(startRoutePosition, vehiclePosition)
                }
                this.addInfoBuble(e.target, content)
            })
        }
    }

    setMapVehicles = vehicles => {
        // Remove vehicle
        if (this.mapVehicle) {
            this.map.removeObject(this.mapVehicle)
            this.mapVehicle = null
        }
        const dataPoints = []

        // Add vehicles points to cluster
        Object.keys(vehicles).forEach(key => {
            const vehicle = vehicles[key]
            if (vehicle.gps_lat && vehicle.gps_lng) {
                const dataPoint = new window.H.clustering.DataPoint(vehicle.gps_lat, vehicle.gps_lng, 1, vehicle)
                dataPoints.push(dataPoint)
            }
        })

        this.mapVehicles.setDataPoints(dataPoints)
    }

    setMapTracingPoints = (routePoints, vehicle) => {
        // Remove routes from map
        this.mapRoutes.removeAll()

        // Remove route points from map
        Object.keys(this.mapRoutePoints).forEach(key => {
            this.map.removeObject(this.mapRoutePoints[key])
        })
        this.mapRoutePoints = {}

        const that = this

        // Add routes to map
        if (routePoints && Object.keys(routePoints).length) {
            const routeRequestParams = {
                mode: 'fastest;truck;traffic:disabled',
                instructionformat: 'text',
                routeattributes: 'shape',
                jsonattributes: 1,
                metricSystem: 'metric',
                language: 'cs-cz',
            }

            let isVehicle = 0 // !int for waypoint numbering
            if (vehicle && vehicle.gps_lat && vehicle.gps_lng) {
                // Is vehicle => add waypoint0
                routeRequestParams.waypoint0 = `geo!${vehicle.gps_lat},${vehicle.gps_lng}`
                isVehicle++
            }

            Object.keys(routePoints).forEach(key => {
                const routePoint = routePoints[key]
                if (routePoint.lat && routePoint.lng) {
                    // Add waypoint
                    routeRequestParams[`waypoint${parseInt(key) + isVehicle}`] = `geo!${routePoint.lat},${routePoint.lng}`

                    // Add route point
                    const options = {
                        data: { id: key },
                        zIndex: 100,
                        icon: new window.H.map.Icon(
                            ReactDOMServer.renderToStaticMarkup(this.getIconSVG(parseInt(key) + 1, routePoint.bgColor, routePoint.textColor))
                        ),
                    }
                    const coord = { lat: routePoint.lat, lng: routePoint.lng }
                    const marker = new window.H.map.Marker(coord, options)
                    marker.draggable = true

                    this.map.addObject(marker)
                    this.mapRoutePoints[key] = marker
                }
            })

            // Vehicle params
            if (vehicle && vehicle.vehicleType) {
                routeRequestParams.limitedWeight = Math.round(vehicle.vehicleType.get('weight')) / 1000
                routeRequestParams.height = vehicle.vehicleType.get('height')
                routeRequestParams.width = vehicle.vehicleType.get('width')
                routeRequestParams.length = vehicle.vehicleType.get('length') + 10
            }

            // Is valid request (route points >= 2)
            Object.keys(this.mapRoutePoints).length > 1 &&
                this.router.calculateRoute(
                    routeRequestParams,
                    result => {
                        if (result.response && result.response.route && result.response.route[0]) {
                            // Route points
                            const shape = result.response.route[0].shape
                            // Loading start at route point 0
                            let shapeIndex = 0
                            // Vehicle => approach
                            if (isVehicle) {
                                // Loading start at next route waypoint shape index
                                shapeIndex = result.response.route[0].waypoint[1].shapeIndex
                                const line = new window.H.geo.LineString()

                                for (const [index, point] of shape.entries()) {
                                    if (index > shapeIndex) {
                                        // Its loading
                                        break
                                    }
                                    const parts = point.split(',')
                                    line.pushLatLngAlt(parts[0], parts[1])
                                }

                                const polyline = new window.H.map.Polyline(line, {
                                    style: {
                                        lineWidth: 8,
                                        strokeColor: '#FF0000',
                                    },
                                    zIndex: 1,
                                    fillColor: '#FF0000',
                                    width: 3,
                                    length: 2,
                                    frequency: 5,
                                })

                                this.mapRoutes.addObject(polyline)
                            }

                            // Routes
                            const line = new window.H.geo.LineString()

                            for (const [index, point] of shape.entries()) {
                                if (index < shapeIndex) {
                                    // Its approach
                                    continue
                                }
                                const parts = point.split(',')
                                line.pushLatLngAlt(parts[0], parts[1])
                            }

                            const polyline = new window.H.map.Polyline(line, {
                                style: {
                                    lineWidth: 8,
                                    strokeColor: '#000000',
                                },
                                zIndex: 1,
                                fillColor: '#000000',
                                width: 3,
                                length: 2,
                                frequency: 5,
                            })

                            this.mapRoutes.addObject(polyline)

                            that.map.setViewBounds(that.mapRoutes.getBounds())
                        } else if (result.additionalData && result.additionalData[0] && result.additionalData[0].key === 'error_code') {
                            // Error
                            this.props.notify(
                                {
                                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                                    message: this.props.intl.formatMessage({ id: `alerts.messages.calculateRoute${result.subtype}` }),
                                    position: 'tc',
                                },
                                'error'
                            )
                        }
                    },
                    () => {}
                )
        }
    }

    // SVG

    getIconSVG = (text, bgColor = '#ffffff', textColor = '#000000', bdColor = '#ffffff', fontSize = '14px') => (
        <svg xmlns="http://www.w3.org/2000/svg" width="28px" height="30px">
            <path
                d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z"
                fill={bdColor}
            />
            <path
                d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z"
                fill={bgColor}
            />
            <text x="13" y="19" fontSize={fontSize} fontWeight="bold" textAnchor="middle" fill={textColor} style={{ fontFamily: 'sans-serif' }}>
                {text}
            </text>
        </svg>
    )

    getClusterIconSVG = (text, bgColor = '#ffffff', textColor = '#000000', bdColor = '#000000', fontSize = '14px') => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px">
            <circle fill={bdColor} cx="50%" cy="50%" r="15" />
            <circle fill={bgColor} cx="50%" cy="50%" r="14" />
            <text
                x="50%"
                y="50%"
                fontSize={fontSize}
                fontWeight="bold"
                textAnchor="middle"
                alignmentBaseline="middle"
                fill={textColor}
                style={{ fontFamily: 'sans-serif' }}
            >
                {text}
            </text>
        </svg>
    )

    getVehicleIconSVG = (vehicle, bgColor = '#ffffff', textColor = '#000000', size = 60) => (
        <svg
            version="1.2"
            baseProfile="tiny"
            width={size}
            height={size}
            viewBox="0 0 100.31094360351562 61.11110305786133"
            overflow="inherit"
            xmlns="http://www.w3.org/2000/svg"
        >
            ` +
            <path
                className="cls-1"
                d="M 98.414 0 L 1.404 0 C 0.631 0.004 0.002 0.955 0 2.127 L 0 46.381 C 0.002 47.555 0.631 48.504 1.404 48.507 L 37.867 48.594 L 49.912 59.991 L 61.128 48.505 L 98.419 48.507 C 99.194 48.504 99.819 47.555 99.824 46.381 L 99.824 2.135 C 99.824 0.955 99.194 0 98.414 0 Z"
                style={{
                    fill: bgColor,
                }}
                stroke="white"
            />
            ` +
            <text x="50" y="20" style={{ fontFamily: 'sans-serif', fontSize: '18px', textAnchor: 'middle', whiteSpace: 'pre', fill: textColor }}>
                {vehicle}
            </text>
        </svg>
    )

    // Component

    componentWillReceiveProps(nextProps) {
        // Loaded
        if (nextProps.isScriptLoaded && !this.props.isScriptLoaded) {
            if (nextProps.isScriptLoadSucceed) {
                this.initMap()
                this.setMapTracingPoints(nextProps.tracingPoints, nextProps.vehicle)
                this.setMapVehicle(nextProps.vehicle)
                this.setMapVehicles(nextProps.vehicles)
            }
        }
        // Center
        if (this.mapInitialized && nextProps.center && JSON.stringify(nextProps.center) !== JSON.stringify(this.props.center)) {
            this.setMapCenter(nextProps.center)
        }
        // Zoom
        if (this.mapInitialized && nextProps.zoom && nextProps.zoom !== this.props.zoom) {
            this.setMapZoom(nextProps.zoom)
        }
        // Tracing points change
        if (this.mapInitialized && nextProps.tracingPoints && JSON.stringify(nextProps.tracingPoints) !== JSON.stringify(this.props.tracingPoints)) {
            this.setMapTracingPoints(nextProps.tracingPoints, nextProps.vehicle)
        }
        // Vehicle change
        if (this.mapInitialized && JSON.stringify(nextProps.vehicle) !== JSON.stringify(this.props.vehicle)) {
            this.setMapTracingPoints(nextProps.tracingPoints, nextProps.vehicle)
            if (nextProps.vehicle) {
                this.setMapVehicle(nextProps.vehicle)
            } else {
                this.setMapVehicles(nextProps.vehicles)
            }
        }
        // Vehicles change
        if (this.mapInitialized && JSON.stringify(nextProps.vehicles) !== JSON.stringify(this.props.vehicles)) {
            this.setMapVehicles(nextProps.vehicles)
            this.setMapTracingPoints(nextProps.tracingPoints, nextProps.vehicle)
        }
    }

    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed, tracingPoints, vehicle, vehicles } = this.props

        if (isScriptLoaded && isScriptLoadSucceed) {
            this.initMap()
            this.setMapTracingPoints(tracingPoints, vehicle)
            this.setMapVehicle(vehicle)
            this.setMapVehicles(vehicles)
        }
    }

    render() {
        return <div id="mapContainer" className="wp-100 hp-100" />
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default scriptLoader(
    'https://js.api.here.com/v3/3.1/mapsjs-core.js',
    'https://js.api.here.com/v3/3.1/mapsjs-service.js',
    'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
    'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js',
    'https://js.api.here.com/v3/3.1/mapsjs-clustering.js'
)(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(HEREMapTracing)
    )
)
