import * as actionTypes from './actionTypes'

// Sidebar visibility

export const showSidebar = () => ({
    type: actionTypes.SHOW_SIDEBAR,
})

export const hideSidebar = () => ({
    type: actionTypes.HIDE_SIDEBAR,
})

// Search

export const setSearch = searchString => ({
    type: actionTypes.SET_SEARCH,
    payload: searchString,
})

// Vehicle selection

export const selectVehicle = vehicle => ({
    type: actionTypes.SELECT_VEHICLE,
    payload: vehicle,
})

export const deselectVehicle = () => ({
    type: actionTypes.DESELECT_VEHICLE,
})

// Fetch message vehicles

export const fetchMessageVehicles = () => ({
    type: actionTypes.FETCH,
})

export const fetchMessageVehiclesFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchMessageVehiclesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchMessageVehiclesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

export const startMessageVehiclesAutoFetching = () => ({
    type: actionTypes.FETCH_START_AUTO_FETCHING,
})

export const stopMessageVehiclesAutoFetching = () => ({
    type: actionTypes.FETCH_STOP_AUTO_FETCHING,
})

// Fetch messages

export const fetchMessages = vehiclesId => ({
    type: actionTypes.FETCH_MESSAGES,
    payload: vehiclesId,
})

export const fetchMessagesFulfilled = data => ({
    type: actionTypes.FETCH_MESSAGES_FULFILLED,
    payload: data,
})

export const fetchMessagesRejected = message => ({
    type: actionTypes.FETCH_MESSAGES_REJECTED,
    payload: message,
})

export const fetchMessagesCancelled = () => ({
    type: actionTypes.FETCH_MESSAGES_CANCELLED,
})

export const startMessageAutoFetching = () => ({
    type: actionTypes.FETCH_MESSAGES_START_AUTO_FETCHING,
})

export const stopMessageAutoFetching = () => ({
    type: actionTypes.FETCH_MESSAGES_STOP_AUTO_FETCHING,
})

// Send message

export const sendMessage = (vehiclesId, text) => ({
    type: actionTypes.SEND_MESSAGE,
    payload: {
        vehiclesId,
        text,
    },
})

export const sendMessageFulfilled = message => ({
    type: actionTypes.SEND_MESSAGE_FULFILLED,
    payload: message,
})

export const sendMessageRejected = error => ({
    type: actionTypes.SEND_MESSAGE_REJECTED,
    payload: error,
})

// Mark as read

export const markMessageAsRead = vehiclesId => ({
    type: actionTypes.MARK_AS_READ,
    payload: vehiclesId,
})

export const markMessageAsReadFulfilled = () => ({
    type: actionTypes.MARK_AS_READ_FULFILLED,
})

export const markMessageAsReadRejected = error => ({
    type: actionTypes.MARK_AS_READ_REJECTED,
    payload: error,
})

// Subscribe

export const subscribe = vehiclesId => ({
    type: actionTypes.SUBSCRIBE,
    payload: vehiclesId,
})

export const subscribeFulfilled = () => ({
    type: actionTypes.SUBSCRIBE_FULFILLED,
})

export const subscribeRejected = error => ({
    type: actionTypes.SUBSCRIBE_REJECTED,
    payload: error,
})

// Unsubscribe

export const unsubscribe = vehiclesId => ({
    type: actionTypes.UNSUBSCRIBE,
    payload: vehiclesId,
})

export const unsubscribeFulfilled = () => ({
    type: actionTypes.UNSUBSCRIBE_FULFILLED,
})

export const unsubscribeRejected = error => ({
    type: actionTypes.UNSUBSCRIBE_REJECTED,
    payload: error,
})
