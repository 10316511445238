import React from 'react'
import { Route, Switch } from 'react-router'
import DriverDocumentsList from './DriverDocumentsList'
import DriverDocumentsEdit from './DriverDocumentsEdit'

const DriverDocuments = props => (
    <Switch>
        <Route exact path={props.match.url} component={DriverDocumentsList} />
        <Route exact path={`${props.match.url}/new`} component={DriverDocumentsEdit} />
        <Route exact path={`${props.match.url}/:driverDocumentID`} component={DriverDocumentsEdit} />
    </Switch>
)

export default DriverDocuments
