export const SHOW_SIDEBAR = 'vehicle_messages.SHOW_SIDEBAR'
export const HIDE_SIDEBAR = 'vehicle_messages.HIDE_SIDEBAR'

export const SET_SEARCH = 'vehicle_messages.SET_SEARCH'
export const SELECT_VEHICLE = 'vehicle_messages.SELECT_VEHICLE'
export const DESELECT_VEHICLE = 'vehicle_messages.DESELECT_VEHICLE'

export const FETCH = 'vehicle_messages.FETCH'
export const FETCH_STARTED = 'vehicle_messages.FETCH_STARTED'
export const FETCH_FULFILLED = 'vehicle_messages.FETCH_FULFILLED'
export const FETCH_REJECTED = 'vehicle_messages.FETCH_REJECTED'
export const FETCH_CANCELLED = 'vehicle_messages.FETCH_CANCELLED'
export const FETCH_START_AUTO_FETCHING = 'vehicle_messages.FETCH_START_AUTO_FETCHING'
export const FETCH_STOP_AUTO_FETCHING = 'vehicle_messages.FETCH_STOP_AUTO_FETCHING'

export const FETCH_MESSAGES = 'vehicle_messages.FETCH_MESSAGES'
export const FETCH_MESSAGES_STARTED = 'vehicle_messages.FETCH_MESSAGES_STARTED'
export const FETCH_MESSAGES_FULFILLED = 'vehicle_messages.FETCH_MESSAGES_FULFILLED'
export const FETCH_MESSAGES_REJECTED = 'vehicle_messages.FETCH_MESSAGES_REJECTED'
export const FETCH_MESSAGES_CANCELLED = 'vehicle_messages.FETCH_MESSAGES_CANCELLED'
export const FETCH_MESSAGES_START_AUTO_FETCHING = 'vehicle_messages.FETCH_MESSAGES_START_AUTO_FETCHING'
export const FETCH_MESSAGES_STOP_AUTO_FETCHING = 'vehicle_messages.FETCH_MESSAGES_STOP_AUTO_FETCHING'

export const SEND_MESSAGE = 'vehicle_messages.SEND_MESSAGE'
export const SEND_MESSAGE_STARTED = 'vehicle_messages.SEND_MESSAGE_STARTED'
export const SEND_MESSAGE_FULFILLED = 'vehicle_messages.SEND_MESSAGE_FULFILLED'
export const SEND_MESSAGE_REJECTED = 'vehicle_messages.SEND_MESSAGE_REJECTED'
export const SEND_MESSAGE_CANCELLED = 'vehicle_messages.SEND_MESSAGE_CANCELLED'

export const MARK_AS_READ = 'vehicle_messages.MARK_AS_READ'
export const MARK_AS_READ_STARTED = 'vehicle_messages.MARK_AS_READ_STARTED'
export const MARK_AS_READ_FULFILLED = 'vehicle_messages.MARK_AS_READ_FULFILLED'
export const MARK_AS_READ_REJECTED = 'vehicle_messages.MARK_AS_READ_REJECTED'
export const MARK_AS_READ_CANCELLED = 'vehicle_messages.MARK_AS_READ_CANCELLED'

export const SUBSCRIBE = 'vehicle_messages.SUBSCRIBE'
export const SUBSCRIBE_STARTED = 'vehicle_messages.SUBSCRIBE_STARTED'
export const SUBSCRIBE_FULFILLED = 'vehicle_messages.SUBSCRIBE_FULFILLED'
export const SUBSCRIBE_REJECTED = 'vehicle_messages.SUBSCRIBE_REJECTED'
export const SUBSCRIBE_CANCELLED = 'vehicle_messages.SUBSCRIBE_CANCELLED'

export const UNSUBSCRIBE = 'vehicle_messages.UNSUBSCRIBE'
export const UNSUBSCRIBE_STARTED = 'vehicle_messages.UNSUBSCRIBE_STARTED'
export const UNSUBSCRIBE_FULFILLED = 'vehicle_messages.UNSUBSCRIBE_FULFILLED'
export const UNSUBSCRIBE_REJECTED = 'vehicle_messages.UNSUBSCRIBE_REJECTED'
export const UNSUBSCRIBE_CANCELLED = 'vehicle_messages.UNSUBSCRIBE_CANCELLED'
