import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import scriptLoader from 'react-async-script-loader'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import Notifications from 'react-notification-system-redux'
import { Modal } from 'react-bootstrap'

import { LoadingOverlay } from '../../loading_overlay'
import '../../../app/components/assets/css/mapsjs-ui.css'

import * as actions from '../actionCreators'
import { getData, getDataFetching, getPreferences, getPreferencesFetching, getCustomerAddressTypes } from '../selectors'
import * as formatters from "../../formatters";
import { url } from "../../helpers";
import { Link } from "react-router-dom";
import button from "react-bootstrap/lib/Button";

class HEREMap extends Component {
    platform = null
    defaultLayers = null
    map = null
    mapEvents = null
    behavior = null
    ui = null
    geocoder = null
    router = null
    mapInitialized = false
    mapMarkers = []
    mapPositions = []
    mapMarker = null
    mapRoutes = {}
    mapRoutePoints = {}
    mapPoints = null
    mapPOIs = null

    state = {
        markerPosition: null,
        modalPreferences: {
            show: false,
            values: null,
            isAllTypesSelected: 0,
        },
    }

    initMap = () => {
        const H = window.H

        this.platform = new H.service.Platform({
            apiKey: this.props.appCode
        })

        this.defaultLayers = this.platform.createDefaultLayers()

        this.map = new H.Map(document.getElementById(this.props.id ? this.props.id : 'mapContainer'), this.defaultLayers.vector.normal.map, {
            zoom: this.props.zoom,
            center: this.props.center,
            pixelRatio: window.devicePixelRatio || 1,
            padding: {
                top: 70,
                left: 70,
                bottom: 70,
                right: 70
            }
        })

        this.mapEvents = new H.mapevents.MapEvents(this.map)
        this.behavior = new H.mapevents.Behavior(this.mapEvents)
        this.ui = H.ui.UI.createDefault(this.map, this.defaultLayers)
        this.geocoder = this.platform.getSearchService()
        this.router = this.platform.getRoutingService(null, 8)

        const that = this

        const CUSTOM_THEME = {
            // Cluster
            getClusterPresentation(cluster) {
                let lat = null
                let lng = null
                const data = {
                    cnt: 0,
                    isCluster: false
                }
                const pois = []

                cluster.forEachDataPoint(noisePoint => {
                    const position = noisePoint.getPosition()
                    pois.push(noisePoint.getData())
                    if (lat === null && lng === null) {
                        lat = position.lat
                        lng = position.lng
                    }
                    else if (lat !== position.lat || lng !== position.lng) {
                        // Markers are in diferent coords => is cluster
                        data.isCluster = true
                    }
                    data.cnt++
                })

                // Retrun cluster marker
                const clusterMarker = new H.map.Marker(cluster.getPosition(), {
                    icon: new H.map.Icon(ReactDOMServer.renderToStaticMarkup(that.getIconSVGCluster(data.cnt, data.isCluster ? '#ffffff' : '#22baa0')), {
                        size: {
                            w: 30,
                            h: 30
                        },
                        anchor: {
                            x: 15,
                            y: 15
                        },
                    }),
                    min: cluster.getMinZoom(),
                    max: cluster.getMaxZoom(),
                })

                if (!data.isCluster) {
                    data.pois = pois
                }

                clusterMarker.setData(data)

                return clusterMarker
            }, // Marker
            getNoisePresentation(noisePoint) {
                const data = noisePoint.getData()

                if (that.props.customerAddressTypes) {
                    const classValues = that.props.customerAddressTypes.find(customerAddressTypes => {
                        return customerAddressTypes.id === data.customer_address_type_id
                    })

                    const H = window.H

                    const element = document.createElement('div')
                    const icon_element = document.createElement('i')

                    icon_element.className = classValues && classValues.icon_class

                    icon_element.style.color = classValues && classValues.color
                    if (classValues && classValues.bg_color && classValues.bg_color !== 'transparent') {
                        icon_element.style.backgroundColor = classValues.bg_color
                        icon_element.style.border = '1px solid #000000'
                    }
                    icon_element.style.fontSize = '28px'
                    icon_element.style.lineHeight = '28px'

                    icon_element.style.padding = '1px'
                    icon_element.style.marginTop = '-15px'
                    icon_element.style.marginLeft = '-15px'

                    element.appendChild(icon_element)

                    const icon = new H.map.DomIcon(element)

                    const clusterMarker = new H.map.DomMarker(noisePoint.getPosition(), {
                        icon,
                        min: noisePoint.getMinZoom()
                    })

                    clusterMarker.setData(data)

                    return clusterMarker
                }
            },
        }

        this.mapPOIs = new window.H.clustering.Provider([], {
            clusteringOptions: {
                eps: 64,
                minWeight: 1,
            },
            theme: CUSTOM_THEME,
        })

        const mapPOIs = new window.H.map.layer.ObjectLayer(this.mapPOIs)

        this.map.addLayer(mapPOIs)

        this.mapInitialized = true

        this.map.addEventListener('tap', e => {
            e.originalEvent.which === 3 && that.setMarkerPosition(that.map.screenToGeo(e.currentPointer.viewportX, e.currentPointer.viewportY))
        })

        this.map.addEventListener('dragstart', ev => {
            const target = ev.target
            const pointer = ev.currentPointer

            if (target instanceof window.H.map.Marker) {
                var targetPosition = that.map.geoToScreen(target.getGeometry());
                target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);

                that.behavior.disable()
            }
        }, false)

        this.map.addEventListener('dragend', ev => {
            const target = ev.target
            const pointer = ev.currentPointer

            if (target instanceof window.H.map.Marker) {
                that.behavior.enable()

                that.setMarkerPosition(that.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y))
            }
        }, false)

        this.map.addEventListener('drag', ev => {
            const target = ev.target
            const pointer = ev.currentPointer

            if (target instanceof window.H.map.Marker) {
                target.setGeometry(that.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
            }
        }, false)

        this.mapPOIs.addEventListener('tap', evt => {
            const data = evt.target.getData()
            if (data.cnt) {
                // Is cluster
                if (data.isCluster) {
                    // Is cluster => zoom
                    this.map.setCenter(this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY), true)
                    this.map.setZoom(this.map.getZoom() + 2, true)
                }
                else {
                    // Is many POIs on same position => InfoBubble
                    this.addInfoBublePOIs(evt.target, data)
                }
            }
            else {
                // Is POI
                this.addInfoBublePOI(evt.target, data)
            }
        }, false)

        this.mapPoints = new H.map.Group()
        this.map.addObject(this.mapPoints)
        this.mapPoints.addEventListener('tap', evt => {
            const data = evt.target.getData()
            if (data && data.infoBubbleContent) {
                this.addInfoBublePoint(evt.target, data.infoBubbleContent, data.infoBubbleButtonOnClick)
            }
        }, false)
    }

    setMapCenter = center => {
        this.map.setCenter(center)
    }

    setMapZoom = zoom => {
        this.map.setZoom(zoom)
    }

    // POIs
    getContentInfoBublePOI = data => {
        let text = `<p><b>${data.name}</b></br>`
        const customer = data.customer_id ? data.customer : null
        if (customer) {
            text += `${customer.name}</br>`
        }
        text += `${data.street}, ${data.city}, ${data.zipcode}</p>`
        return text
    }

    addInfoBublePOI = (target, data) => {
        const content = this.getContentInfoBublePOI(data)
        const bubble = new window.H.ui.InfoBubble(target.getGeometry(), {
            content,
        })
        bubble.addClass('vehicle-infobubble')
        this.ui.addBubble(bubble)
    }

    addInfoBublePOIs = (target, data) => {
        let content = ''
        data.pois.forEach(poi => {
            content += this.getContentInfoBublePOI(poi)
        })
        const bubble = new window.H.ui.InfoBubble(target.getGeometry(), {
            content,
        })
        bubble.addClass('vehicle-infobubble')
        this.ui.addBubble(bubble)
    }

    setMapPOIs = pois => {
        const dataPoints = []
        pois && pois.forEach(poi => {
            const dataPoint = new window.H.clustering.DataPoint(poi.gps_lat, poi.gps_lng, 1, poi)
            dataPoints.push(dataPoint)
        })
        this.mapPOIs.setDataPoints(dataPoints)
    }

    // Markers
    removeMarkers = () => {
        this.mapMarkers.forEach(marker => {
            this.map.removeObject(marker)
        })

        this.mapMarkers = []
    }

    addMarkers = markers => {
        markers && markers.forEach(marker => {
            this.mapMarkers.push(this.map.addObject(new window.H.map.Marker(marker.center, marker.iconSvg && {
                icon: new window.H.map.Icon(ReactDOMServer.renderToStaticMarkup(marker.iconSvg), {
                    size: marker.size,
                    anchor: marker.anchor,
                }),
                zIndex: marker.zIndex,
            })))
        })
    }

    // Marker
    removeMarker = () => {
        if (this.mapMarker) {
            this.map.removeObject(this.mapMarker)
            this.mapMarker = null
        }
    }

    setMarker = coord => {
        this.removeMarker()

        if (coord) {
            const marker = new window.H.map.Marker(coord, {
                volatility: true
            })
            marker.draggable = true
            this.mapMarker = this.map.addObject(marker)
        }
    }

    setMarkerPosition = coord => {
        if (JSON.stringify(coord) !== JSON.stringify(this.state.markerPosition)) {
            this.setState({
                markerPosition: coord,
            }, () => {
                this.props.handleMarkerPositionChanged && this.props.handleMarkerPositionChanged(coord)

                const that = this

                if (this.props.handleAddressChanged) {
                    this.geocoder.reverseGeocode({
                        at: `${coord.lat},${coord.lng},150`,
                        limit: 1
                    }, result => {
                        if (result && result.items && result.items[0]) {
                            that.props.handleAddressChanged(result, coord)
                        }
                    }, () => {
                    })
                }
            })
        }
    }

    // routes
    setMapRoutes = routes => {
        // remove objects from map
        Object.keys(this.mapRoutes).forEach(key => {
            this.map.removeObject(this.mapRoutes[key])
        })
        this.mapRoutes = {}

        Object.keys(this.mapRoutePoints).forEach(key => {
            this.map.removeObject(this.mapRoutePoints[key])
        })
        this.mapRoutePoints = {}

        const that = this

        // add routes to map
        routes && routes.forEach((route, routeKey) => {
            if (route.showWaypoints && route.waypoints && route.waypoints.length) {
                route.waypoints.forEach((gps, key) => {
                    const routePoint = new window.H.map.Marker({
                        lat: gps[0],
                        lng: gps[1],
                    }, {
                        icon: new window.H.map.Icon(ReactDOMServer.renderToStaticMarkup(this.getIconSVGCircle(route.color)), {
                            size: {
                                w: 10,
                                h: 10
                            },
                            anchor: {
                                x: 5,
                                y: 5
                            },
                        }),
                    })

                    this.map.addObject(routePoint)
                    this.mapRoutePoints[key] = routePoint
                })
            }

            if (route.showRoute && route.polyline) {
                const group = new window.H.map.Group();
                const polyline = JSON.parse(route.polyline)

                polyline.forEach(p => {
                    group.addObject(new window.H.map.Polyline(window.H.geo.LineString.fromFlexiblePolyline(p), {
                        style: {
                            lineWidth: route.width,
                            strokeColor: route.color
                        },
                        zIndex: routeKey + 1,
                    }))
                })

                that.mapRoutes[routeKey] = group;

                this.map.addObject(group);

                this.map.getViewModel().setLookAtData({
                    bounds: group.getBoundingBox()
                });
            }
            else if (route.showRoute && route.waypoints && route.waypoints.length && 0) {
                const group = new window.H.map.Group();

                const routeRequestParams = {
                    routingMode: 'fast',
                    transportMode: 'truck',
                    origin: route.waypoints.shift().join(','),
                    destination: route.waypoints.pop().join(','),
                    return: 'polyline',
                    units: 'metric'
                }

                const via = []
                route.waypoints.forEach(gps => {
                    via.push(gps.join(','))
                })

                routeRequestParams.via = new window.H.service.Url.MultiValueQueryParameter(via)

                routeRequestParams.limitedWeight = 25
                routeRequestParams.height = 4.0
                routeRequestParams.width = 2.55
                routeRequestParams.length = 16.5

                this.router.calculateRoute(routeRequestParams, result => {
                    if (result.routes[0]) {
                        result.routes[0].sections.forEach((section) => {
                            group.addObject(new window.H.map.Polyline(window.H.geo.LineString.fromFlexiblePolyline(section.polyline), {
                                style: {
                                    lineWidth: route.width,
                                    strokeColor: route.color
                                },
                                zIndex: routeKey + 1,
                            }))

                            that.mapRoutes[routeKey] = group;

                            this.map.addObject(group);
                        });

                        this.map.getViewModel().setLookAtData({
                            bounds: group.getBoundingBox()
                        });
                    }

                }, (error) => {

                })
            }
        })
    }

    // positions
    setPositions = positions => {
        // remove objects from map
        Object.keys(this.mapPositions).forEach(key => {
            this.map.removeObject(this.mapPositions[key])
        })
        this.mapPositions = {}

        // add positions to map
        positions && positions.forEach((position, positionKey) => {
            const point = new window.H.map.Marker({
                lat: position.gps_lat,
                lng: position.gps_lng,
            }, {
                icon: new window.H.map.Icon(ReactDOMServer.renderToStaticMarkup(this.getIconSVGCircle('#000000')), {
                    size: {
                        w: 10,
                        h: 10
                    },
                    anchor: {
                        x: 5,
                        y: 5
                    },
                }),
            })

            this.map.addObject(point)
            this.mapPositions[positionKey] = point

            const that = this
            point.addEventListener('tap', function (evt) {
                let content = '<p>' + 'Vozidlo: ' + position.vehicle_name + '<br/>' + 'Řidič: ' + position.driver_name + '<br/>' + 'Čas: ' + (position.datetime && formatters.datetimeFormatter(position.datetime)) + '<br/>' + 'Rychlost: ' + formatters.speedFormatter(position.speed) + '<br/>' + 'GPS: ' + formatters.gpsFormatter(position.gps_lat, position.gps_lng) + '<br/>' + 'ID pozice: ' + position.id + '</p>'

                var bubble = new window.H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: content
                });
                bubble.addClass('vehicle-infobubble')
                that.ui.addBubble(bubble)
            }, false);
        })
    }

    // points

    addInfoBublePoint = (target, infoBubbleContent, infoBubbleButtonOnClick) => {
        const bubble = new window.H.ui.InfoBubble(target.getGeometry(), {
            content: infoBubbleContent,
        })
        bubble.addClass('vehicle-infobubble')
        this.ui.addBubble(bubble)

        const buttons = bubble.getContentElement().getElementsByTagName('button')
        if (typeof infoBubbleButtonOnClick === 'function') {
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', event => {
                    infoBubbleButtonOnClick(event)
                    bubble.close()
                })
            }
        }
    }

    setMapPoints = points => {
        this.mapPoints.removeAll()
        const dataPoints = []
        points && points.forEach(point => {
            let dataPoint = null
            const settingsIcon = {
                size: point.size,
                anchor: point.anchor,
            }
            const settingsDataPoint = {
                data: {
                    infoBubbleContent: point.infoBubbleContent,
                    infoBubbleButtonOnClick: point.infoBubbleButtonOnClick,
                },
                zIndex: point.zIndex,
            }

            if (point.domMarker) {
                dataPoint = new window.H.map.DomMarker(point.center, {
                    ...settingsDataPoint,
                    icon: new window.H.map.DomIcon(point.getIconSvg(), settingsIcon),
                })
            }
            else {
                dataPoint = new window.H.map.Marker(point.center, {
                    ...settingsDataPoint,
                    icon: new window.H.map.Icon(ReactDOMServer.renderToStaticMarkup(point.getIconSvg()), settingsIcon),
                })
            }
            dataPoints.push(dataPoint)
        })
        this.mapPoints.addObjects(dataPoints)
    }

    // SVG
    getIconSVGCircle = (color = '#000000') => (<svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px">
            <circle cx="7" cy="7" r="4" stroke={color} strokeWidth="4" fill="#ffffff" />
        </svg>)

    getIconSVGCluster = (text, bgColor = '#ffffff', textColor = '#000000', bdColor = '#000000', fontSize = '14px') => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px">
            <circle fill={bdColor} cx="50%" cy="50%" r="15" />
            <circle fill={bgColor} cx="50%" cy="50%" r="14" />
            <text
                x="50%"
                y="50%"
                dy="4px"
                fontSize={fontSize}
                fontWeight="bold"
                textAnchor="middle"
                fill={textColor}
                style={{ fontFamily: 'sans-serif' }}
            >
                {text}
            </text>
        </svg>)

    // Preferences
    handleModalPreferencesOpen = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                show: true,
            },
        })
    }

    handleModalPreferencesClose = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                show: false,
            },
        })
    }

    handleModalPreferencesChange = (key, value) => {
        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                values: {
                    ...this.state.modalPreferences.values,
                    [key]: value,
                },
            },
        })
    }

    handleModalPreferencesSelectAllTypes = value => {
        const values = {}
        this.props.customerAddressTypes.forEach(customerAddressType => {
            values[`show_customer_address_type_${customerAddressType.id}`] = value
        })

        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                values,
                isAllTypesSelected: value,
            },
        })
    }

    handleModalPreferencesSubmit = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const settings = typeof this.props.showPOIs === 'object' ? this.props.showPOIs : {}
        this.props.actions.updatePreferences(this.state.modalPreferences.values, settings)
        this.handleModalPreferencesClose()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isScriptLoaded && !this.props.isScriptLoaded) {
            if (nextProps.isScriptLoadSucceed) {
                this.initMap()
                this.addMarkers(nextProps.markers)
                this.setMapRoutes(nextProps.routes)
                this.setPositions(nextProps.positions)
                if (this.props.showPOIs) {
                    this.setMapPOIs(nextProps.data)
                }
            }
        }

        if (this.mapInitialized && nextProps.center && JSON.stringify(nextProps.center) !== JSON.stringify(this.props.center)) {
            this.setMapCenter(nextProps.center)
        }

        if (this.mapInitialized && nextProps.zoom && nextProps.zoom !== this.props.zoom) {
            this.setMapZoom(nextProps.zoom)
        }

        if (this.mapInitialized && nextProps.markers && !nextProps.markers.equals(this.props.markers)) {
            this.removeMarkers()
            this.addMarkers(nextProps.markers)
        }

        if (this.mapInitialized && nextProps.markerCenter && (nextProps.markerCenter !== this.props.markerCenter || !this.mapMarker)) {
            this.setMarker(nextProps.markerCenter)
            this.setMarkerPosition(nextProps.markerCenter)
        }

        if (this.mapInitialized && nextProps.routes && JSON.stringify(nextProps.routes) !== JSON.stringify(this.props.routes)) {
            this.setMapRoutes(nextProps.routes)
        }

        if (this.mapInitialized && nextProps.positions && JSON.stringify(nextProps.positions) !== JSON.stringify(this.props.positions)) {
            this.setPositions(nextProps.positions)
        }

        if (this.mapInitialized && nextProps.points && JSON.stringify(nextProps.points) !== JSON.stringify(this.props.points)) {
            this.setMapPoints(nextProps.points)
        }

        if (this.mapInitialized && this.props.showPOIs && nextProps.data && JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
            this.setMapPOIs(nextProps.data)
        }

        if (this.props.fetching && !nextProps.fetching) {
            const modalPreferences = { ...this.state.modalPreferences }
            modalPreferences.values = nextProps.preferences
            this.setState({ modalPreferences })
        }
    }

    componentDidMount() {
        if (this.props.showPOIs) {
            const settings = typeof this.props.showPOIs === 'object' ? this.props.showPOIs : {}
            this.props.actions.fetch(settings)
        }
        const {
            isScriptLoaded,
            isScriptLoadSucceed,
            markers,
            positions,
            routes,
            data
        } = this.props
        if (isScriptLoaded && isScriptLoadSucceed) {
            this.initMap()
            this.addMarkers(markers)
            this.setMapRoutes(routes)
            this.setPositions(positions)
            if (this.props.showPOIs) {
                this.setMapPOIs(data)
            }
        }
    }

    render() {
        return (<React.Fragment>
                <div id={this.props.id ? this.props.id : 'mapContainer'} className="wp-100 hp-100 relative">
                    {(this.props.fetching || this.props.preferencesFetching) && (<div className="routing-map-info">
                            <div className="spinner" />
                            <span className="info"> {this.props.intl.formatMessage({ id: 'modules.map.poisLoading' })} </span>
                        </div>)}
                    {this.props.showPOIs && !this.props.fetching && (<button
                            className="btn btn-default far fa-cog"
                            type="button"
                            style={{
                                position: 'absolute',
                                top: '0px',
                                right: '1px',
                                zIndex: '1'
                            }}
                            onClick={this.handleModalPreferencesOpen}
                        />)}
                </div>

                {this.props.showPOIs && !this.props.fetching && (
                    <Modal show={Boolean(this.state.modalPreferences.show)} onHide={this.handleModalPreferencesClose} bsSize="sm">
                        <React.Fragment>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <FormattedMessage id="fields.preferences" />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <LoadingOverlay active={this.props.preferencesSaving}>
                                    <form onSubmit={this.handleModalPreferencesSubmit}>
                                        <div className="row board-legend">
                                            <div className="col-md-12">
                                                <table className="table table-striped table-hover">
                                                    <tbody>
                                                    <tr>
                                                        <td colSpan={2} />
                                                        <td className="text-center w-40">
                                                            <input
                                                                type="checkbox"
                                                                checked={this.state.modalPreferences.isAllTypesSelected === 1}
                                                                onChange={e => {
                                                                    this.handleModalPreferencesSelectAllTypes(e.target.checked ? 1 : 0)
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>

                                                    {this.props.customerAddressTypes && this.props.customerAddressTypes.map(customerAddressType => {
                                                        const className = customerAddressType.icon_class ? customerAddressType.icon_class : 'fas fa-map-marker'
                                                        const color = customerAddressType.color ? customerAddressType.color : '#000000'

                                                        return (<tr key={`preferences-transport-point-type-${customerAddressType.id}`}>
                                                                <td className="text-center w-40">
                                                                    <i className={className} style={{ color }} />
                                                                </td>
                                                                <td>
                                                                    <FormattedMessage id="fields.transportPointType" /> -{' '}
                                                                    {customerAddressType.name}
                                                                </td>
                                                                <td className="text-center w-40">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={this.state.modalPreferences.values && parseInt(this.state.modalPreferences.values[`show_customer_address_type_${customerAddressType.id}`]) === 1}
                                                                        onChange={e => {
                                                                            this.handleModalPreferencesChange(`show_customer_address_type_${customerAddressType.id}`, e.target.checked ? 1 : 0)
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>)
                                                    })}
                                                    </tbody>
                                                </table>
                                                {this.props.onShowMarkers && (<table className="table table-striped table-hover">
                                                        <tbody>
                                                        <tr>
                                                            <td>
                                                                <FormattedMessage id="fields.showMarkers" />
                                                            </td>
                                                            <td className="text-center w-40">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.props.showMarkers}
                                                                    onChange={() => {
                                                                        this.props.onShowMarkers(this.props.showMarkers ? 0 : 1)
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>)}
                                            </div>
                                        </div>
                                        <div className="btns-form text-right">
                                            <button type="submit" className="btn btn-success">
                                                <FormattedMessage id="buttons.save" />
                                            </button>
                                        </div>
                                    </form>
                                </LoadingOverlay>
                            </Modal.Body>
                        </React.Fragment>
                    </Modal>)}
            </React.Fragment>)
    }
}

function mapStateToProps(state) {
    return {
        data: getData(state),
        fetching: getDataFetching(state),
        preferences: getPreferences(state),
        preferencesFetching: getPreferencesFetching(state),
        customerAddressTypes: getCustomerAddressTypes(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators({
                ...actions,
            }, dispatch),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default scriptLoader('https://js.api.here.com/v3/3.1/mapsjs-core.js', 'https://js.api.here.com/v3/3.1/mapsjs-service.js', 'https://js.api.here.com/v3/3.1/mapsjs-ui.js', 'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js', 'https://js.api.here.com/v3/3.1/mapsjs-clustering.js')(injectIntl(connect(mapStateToProps, mapDispatchToProps)(HEREMap)))
