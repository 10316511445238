import React, { Component } from 'react'
import moment from 'moment'
import { injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_EQUIPMENTS } from '../permissions'

// eslint-disable-next-line react/prefer-stateless-function
class DriversEquipmentsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick && this.props.onClick(this.props.item)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete && this.props.onDelete(this.props.item)
    }

    render() {
        const equipment = this.props.item
        const notifyDays = equipment.equipment && equipment.equipment.notify_days
        const validityDaysRemained = equipment.date_validity_to && moment(equipment.date_validity_to).diff(moment(), 'days')

        const validityDaysClassName = notifyDays && equipment.date_validity_to && validityDaysRemained <= notifyDays ? 'text-danger' : ''

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS_EQUIPMENTS.UPDATE, PERMS_EQUIPMENTS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS_EQUIPMENTS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_EQUIPMENTS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-200">{equipment.equipment ? equipment.equipment.name : null}</td>
                <td className="w-100 text-center">{equipment.date_validity_from ? formatters.dateFormatter(equipment.date_validity_from) : null}</td>
                <td className="w-100 text-center">{equipment.date_validity_to ? formatters.dateFormatter(equipment.date_validity_to) : null}</td>
                <td className={`w-100 text-right ${validityDaysClassName}`}>
                    {equipment.date_validity_to
                        ? `${formatters.integerFormatter(validityDaysRemained)} ${this.props.intl.formatMessage({ id: 'fields.abbrDays' })}`
                        : null}
                </td>
                <td className="w-max wm-200 wrapped">{equipment.note}</td>
            </tr>
        )
    }
}

export default injectIntl(DriversEquipmentsListRow)
