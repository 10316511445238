import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import classnames from 'classnames'
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap'
import TextareaAutosize from 'react-autosize-textarea'
import Notifications from 'react-notification-system-redux'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'
import DriverTimesheetsEditForm from '../../driver_timesheets/components/DriverTimesheetsEditForm'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as reportDriverTimesheetActionCreators from '../actionCreators'
import { getData, getFetching, getDetailsData, getDetailsFetching, getDriverNote, getModalSaving } from '../selectors'

import * as driver_timesheets_constants from '../../driver_timesheets/constants'
import { deleteDriverTimesheet } from '../../driver_timesheets/actionCreators'
import { getDriverTimesheetDeleting, getDriverTimesheetDeleteError } from '../../driver_timesheets/selectors'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import {TableFilterCheckboxField, TableFilterInputField, TableFilterSelectField} from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import PermissionsProps from '../../auth/components/PermissionsProps'
import { PERMS } from '../permissions'

const tableIdentifier = 'report_driver_timesheets'

const componentIdentifier = 'report_driver_timesheets'
const prerequisites = ['dispatchers', 'driversTypes']

class ReportDriverTimesheet extends Component {
    state = {
        modal: {
            identifier: null,
        },
        modalCreateActivity: {
            show: false,
            driverTimesheetID: null,
        },
        modalDetails: {
            show: false,
            driverId: null,
            dateFrom: null,
            dateTo: null,
        },
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalPreferences: {
            show: false,
            values: Object.fromEntries(Object.keys(driver_timesheets_constants.DRIVER_TIMESHEET_TYPES).map(key => [key, 1])),
            isAllTypesSelected: 1,
        },
    }

    refresh = () => {
        this.props.actions.fetchReportDriverTimesheet(this.props.tableModel.get('filters'))
    }

    refreshDetail = () => {
        if (this.state.modalDetails.show) {
            this.props.actions.fetchReportDriverTimesheetDetails(
                this.state.modalDetails.driverId,
                this.state.modalDetails.dateFrom,
                this.state.modalDetails.dateTo
            )
        }
    }

    resetFilters = () => {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportDriverTimesheet(this.props.tableModel.get('filters').toJS())
    }

    handleFilterChanged = (key, value) => {
        const filters = this.props.tableModel.get('filters')

        let stringFrom = filters.getIn(['date_from', 'value'])
        let stringTo = filters.getIn(['date_to', 'value'])

        if (key === 'period') {
            stringFrom = value.momentFrom.format('DD.MM.YYYY')
            stringTo = value.momentTo.format('DD.MM.YYYY')
        }

        this.props.table.changeFilters([
            {
                column: 'date_from',
                type: TableModelColumnFilteringType.equal,
                value: stringFrom,
            },
            {
                column: 'date_to',
                type: TableModelColumnFilteringType.equal,
                value: stringTo,
            },
        ])
    }

    // Preferences
    handleModalPreferencesOpen = () => {
        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                show: true,
            },
        })
    }

    handleModalPreferencesClose = () => {
        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                show: false,
            },
        })
    }

    handleModalPreferencesChange = (key, value) => {
        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                values: {
                    ...this.state.modalPreferences.values,
                    [key]: value,
                },
            },
        })
    }

    handleModalPreferencesSelectAllTypes = value => {
        const values = {}
        Object.keys(driver_timesheets_constants.DRIVER_TIMESHEET_TYPES).forEach(typeId => {
            const type = driver_timesheets_constants.DRIVER_TIMESHEET_TYPES[typeId]

            if (!type.notAvailable) {
                values[typeId] = value
            }
        })

        this.setState({
            modalPreferences: {
                ...this.state.modalPreferences,
                values,
                isAllTypesSelected: value,
            },
        })
    }

    handleModalPreferencesSubmit = e => {
        e && e.preventDefault()

        const values = this.state.modalPreferences.values
        Object.keys(values).forEach(key => {
            if (values[key] === 0) {
                delete values[key]
            }
        })

        this.props.table.changeFilters([
            {
                column: 'type_id',
                type: TableModelColumnFilteringType.equal,
                value: Object.keys(values),
            },
        ])

        this.handleModalPreferencesClose()
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // Detail
    handleShowDetails = (e, driverId, dateFrom, dateTo) => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: true,
                    driverId,
                    dateFrom,
                    dateTo,
                },
            },
            () => {
                this.props.actions.fetchReportDriverTimesheetDetails(driverId, dateFrom, dateTo)
            }
        )
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: false,
                    driverId: null,
                    dateFrom: null,
                    dateTo: null,
                },
            },
            () => {
                this.props.actions.clearReportDriverTimesheetDetails()
            }
        )
    }

    handleExportDetails = e => {
        e && e.preventDefault()

        const stringFrom = this.state.modalDetails.dateFrom
        const strinTo = this.state.modalDetails.dateTo

        this.props.actions.exportReportDriverTimesheetDetails(
            this.state.modalDetails.driverId,
            stringFrom,
            strinTo,
            this.props.tableModel.get('filters').toJS()
        )
    }

    // Create activity
    handleDeleteActivity = (e, driverTimesheetID) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteDriverTimesheet(driverTimesheetID)
                this.hideConfirmDialog()
            },
        })
    }

    handleModalCreateActivityShow = (e, driverTimesheetID) => {
        e && e.preventDefault()

        this.setState({
            modalCreateActivity: {
                show: true,
                driverTimesheetID,
            },
        })
    }

    handleModalCreateActivityClose = e => {
        e && e.preventDefault()

        this.setState({
            modalCreateActivity: {
                show: false,
            },
        })
    }

    editCreateActivitySuccessCallback = () => {
        this.handleModalCreateActivityClose()
        this.refreshDetail()
        this.refresh()
    }

    editCreateActivityBackCallback = () => {
        this.handleModalCreateActivityClose()
    }

    // Note
    handleDriverMonthNoteChange = driver => {
        const filters = this.props.tableModel.get('filters')
        const stringFrom = filters.getIn(['date_from', 'value'])
        const momentFrom = stringFrom ? helpers.convertCSDateToMoment(stringFrom) : moment().startOf('month')
        const year = momentFrom.format('YYYY')
        const month = momentFrom.format('M')

        this.setState({
            modal: {
                identifier: 'vehicle_month_note',
                title: `${this.props.intl.formatMessage({ id: 'modules.board.vehicleNote' })}: ${driver.full_name}`,
                values: {
                    note: driver.note,
                    driver_id: driver.id,
                    year,
                    month,
                },
                onSave: this.handleModalDriverNoteSubmit,
            },
        })
    }

    handleModalInputChange = e => {
        this.setState({
            modal: { ...this.state.modal, values: { ...this.state.modal.values, note: e.target.value } },
        })
    }

    handleModalClose = () => {
        this.setState({
            modal: { identifier: null },
        })
    }

    handleModalDriverNoteSubmit = e => {
        e.preventDefault()
        this.props.actions.updateDriverNote(this.state.modal.values)
    }

    handleSaveResult = (error, successMessage) => {
        if (error) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: `alerts.titles.error` }),
                    message: error,
                    position: 'tc',
                },
                'error'
            )
        } else {
            this.setState({ modal: { identifier: null } })

            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: `alerts.titles.success` }),
                    message: successMessage,
                    position: 'tc',
                },
                'success'
            )

            this.refresh()
            this.refreshDetail()
        }
    }

    componentDidMount() {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.setConfiguration(
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
                {
                    column: 'type_id',
                    type: TableModelColumnFilteringType.equal,
                    value: Object.keys(driver_timesheets_constants.DRIVER_TIMESHEET_TYPES),
                },
            ],
            false
        )

        this.props.actions.fetchPrerequisites()
        this.props.actions.fetchReportDriverTimesheet(this.props.tableModel.get('filters'))
    }

    componentWillUnmount() {
        this.props.actions.clearReportDriverTimesheetDetails()
        this.props.actions.clearReportDriverTimesheet()
    }

    componentWillReceiveProps(nextProps) {
        // Driver note
        if (this.props.driverNote.get('saving') && !nextProps.driverNote.get('saving')) {
            this.handleSaveResult(nextProps.driverNote.get('error'), this.props.intl.formatMessage({ id: 'alerts.messages.noteSaveSuccess' }))
        }
        // Timesheet delete
        if (this.props.driverTimesheetDeleting && !nextProps.driverTimesheetDeleting) {
            if (nextProps.driverTimesheetDeleteError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.deleteSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
                this.refreshDetail()
                this.refresh()
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.createFromTemplateError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportDriverTimesheet.heading' }))

        const data = this.props.data
        const filters = this.props.tableModel.get('filters')

        const detailsData = this.props.detailsData

        const stringFrom = filters.getIn(['date_from', 'value'])
        const stringTo = filters.getIn(['date_to', 'value'])

        const momentToday = moment()
        const momentFrom = stringFrom ? helpers.convertCSDateToMoment(stringFrom) : moment().startOf('month')
        const momentTo = stringTo ? helpers.convertCSDateToMoment(stringTo) : moment().endOf('month')
        const stringISOFrom = momentFrom.format('YYYY-MM-DD')
        const stringISOTo = momentTo.format('YYYY-MM-DD')

        const detailMomentDay =
            this.state.modalDetails.dateFrom === this.state.modalDetails.dateTo
                ? helpers.convertISODatetimeToMoment(this.state.modalDetails.dateTo)
                : null
        const stringDetailMomentDay = detailMomentDay ? detailMomentDay.format('D.M.Y') : null
        const stringISODetailMomentNextDay = detailMomentDay ? detailMomentDay.add(1, 'd').format('YYYY-MM-DD') : null
        const stringISODetailMomentPrevDay = detailMomentDay ? detailMomentDay.add(-2, 'd').format('YYYY-MM-DD') : null

        const momentDays = []
        const totalDays = {}
        for (let i = 0; i <= momentTo.diff(momentFrom, 'days'); i++) {
            const momentDay = moment(momentFrom).add(i, 'days')
            momentDays.push(momentDay)
            totalDays[momentDay.format('YYYY-MM-DD')] = {
                total: 0,
                overtime: 0,
            }
        }

        let total = {
            drive: 0,
            night: 0,
            weekend: 0,
            holiday: 0,
            otherJob: 0,
            vacation: 0,
            paidVacation: 0,
            unpaidVacation: 0,
            useOvertime: 0,
            disease: 0,
            absence: 0,
            holidayNotWorking: 0,
            driveOvertimesHolidayNotWorking: 0,
            sumOvertime: 0,
            overtime: 0,
            total: 0,
        }

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportDriverTimesheet.heading" />
                                        </span>
                                    </h4>
                                    <div className="pull-left" style={{ marginLeft: '100px' }}>
                                        <span className="pull-left text-gray f-bold" style={{ paddingTop: '10px' }}>
                                            <FormattedMessage id="fields.showIncl" />:
                                        </span>
                                        <div className="pull-left m-l-sm">
                                            <TableFilterCheckboxField
                                                identifier="with_empty"
                                                type={TableModelColumnFilteringType.bool}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                label={this.props.intl.formatMessage({ id: 'fields.showInclEmpty' })}
                                            />
                                        </div>
                                        <div className="pull-left m-l-sm">
                                            <TableFilterCheckboxField
                                                identifier="with_inactive"
                                                type={TableModelColumnFilteringType.bool}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                label={this.props.intl.formatMessage({ id: 'fields.showInclInactive' })}
                                            />
                                        </div>
                                    </div>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className="m-r-lg"
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_MONTH}
                                            onChange={(momentFrom, momentTo) => this.handleFilterChanged('period', { momentFrom, momentTo })}
                                            allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_MONTH]}
                                            forbidPickType
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <button className="btn btn-default far fa-cog m-r-xs" onClick={this.handleModalPreferencesOpen} />
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-200">
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.type" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-max wm-140">
                                                    <FormattedMessage id="fields.note" />
                                                </th>
                                                {momentDays.map(momentDay => (
                                                    <th key={`day-th-${momentDay.format('YYYY-MM-DD')}`} className="w-50 text-center">
                                                        {momentDay.format('DD/MM')}
                                                    </th>
                                                ))}
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.drive" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.night" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.weekend" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.holiday" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.otherJob" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.vacation" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.paidVacation" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.unpaidVacation" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.useOvertime" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.disease" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.absence" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.holidayNotWorking" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.driveOvertimesHolidayNotWorking" />
                                                </th>
                                                <th className="w-50 text-center">
                                                    <FormattedMessage id="modules.vehicleFuelings.overtime" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.total" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-200">
                                                    <TableFilterInputField
                                                        identifier="driver_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterSelectField
                                                        identifier="drivers_type_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('driversTypes'))}
                                                    />
                                                </th>
                                                <th className="w-140">
                                                    <TableFilterSelectField
                                                        identifier="dispatcher_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                    />
                                                </th>
                                                <th className="w-max wm-140">
                                                    <TableFilterInputField
                                                        identifier="note"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                {momentDays.map(momentDay => (
                                                    <th key={`day-th-filter-${momentDay.format('YYYY-MM-DD')}`} className="w-50" />
                                                ))}
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-50" />
                                                <th className="w-100" />
                                            </tr>
                                        </thead>
                                        <tbody className="relative-td">
                                            {data &&
                                                data.drivers &&
                                                data.drivers.map(driverData => {
                                                    const driver = driverData.driver
                                                    const days = driverData.days
                                                    const sums = driverData.sums

                                                    total.drive += sums.drive
                                                    total.night += sums.night
                                                    total.weekend += sums.weekend
                                                    total.holiday += sums.holiday
                                                    total.otherJob += sums.other_job
                                                    total.vacation += sums.vacation
                                                    total.paidVacation += sums.paid_vacation
                                                    total.unpaidVacation += sums.unpaid_vacation
                                                    total.useOvertime += sums.use_overtime
                                                    total.disease += sums.disease
                                                    total.absence += sums.absence
                                                    total.holidayNotWorking += sums.holiday_not_working
                                                    total.driveOvertimesHolidayNotWorking += sums.drive_overtimes_holiday_not_working
                                                    total.sumOvertime += sums.overtime

                                                    if (
                                                        filters.getIn(['driver_name', 'value']) &&
                                                        driver.full_name
                                                            .toLowerCase()
                                                            .indexOf(filters.getIn(['driver_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    const totalRow = {
                                                        total: 0,
                                                        overtime: 0,
                                                    }

                                                    return (
                                                        <tr key={`vehicle-${driver.id}`}>
                                                            <td
                                                                className="w-200"
                                                                role="button"
                                                                onClick={e => {
                                                                    this.handleShowDetails(e, driver.id, stringISOFrom, stringISOTo)
                                                                }}
                                                            >
                                                                {driver.full_name}
                                                            </td>
                                                            <td className="w-100">
                                                                {driver.drivers_type
                                                                    ? driver.drivers_type.name
                                                                    : null}
                                                            </td>
                                                            <td className="w-140">
                                                                {driver.dispatcher
                                                                    ? helpers.getShortFullName(
                                                                          driver.dispatcher.first_name,
                                                                          driver.dispatcher.last_name
                                                                      )
                                                                    : null}
                                                            </td>
                                                            <PermissionsProps
                                                                has={[PERMS.UPDATE]}
                                                                props={{ onClick: () => this.handleDriverMonthNoteChange(driver) }}
                                                            >
                                                                <td className="w-max wm-140 board-notes wrapped">{driver.note}</td>
                                                            </PermissionsProps>
                                                            {momentDays.map(momentDay => {
                                                                const date = momentDay.format('YYYY-MM-DD')
                                                                const haveData = days[date]
                                                                    ? Object.keys(days[date].duration_seconds).length > 0
                                                                    : false
                                                                const dots = {}
                                                                let value = 0
                                                                const overtime =
                                                                    haveData &&
                                                                    days[date].duration_seconds[
                                                                        driver_timesheets_constants.DRIVER_TIMESHEET_TYPE_USE_OVERTIME
                                                                    ]
                                                                        ? days[date].duration_seconds[
                                                                              driver_timesheets_constants.DRIVER_TIMESHEET_TYPE_USE_OVERTIME
                                                                          ]
                                                                        : 0

                                                                if (haveData) {
                                                                    Object.keys(days[date].duration_seconds).forEach(key => {
                                                                        const driverTimesheetType =
                                                                            driver_timesheets_constants.DRIVER_TIMESHEET_TYPES[key]
                                                                        if (driverTimesheetType.group) {
                                                                            dots[driverTimesheetType.group] = dots[driverTimesheetType.group]
                                                                                ? dots[driverTimesheetType.group] + days[date].duration_seconds[key]
                                                                                : days[date].duration_seconds[key]
                                                                        }
                                                                        if (
                                                                            String(key) ===
                                                                            String(driver_timesheets_constants.DRIVER_TIMESHEET_TYPE_USE_OVERTIME)
                                                                        ) {
                                                                            totalDays[date].overtime += days[date].duration_seconds[key]
                                                                            totalRow.overtime += days[date].duration_seconds[key]
                                                                        } else if (
                                                                            String(key) !==
                                                                            String(driver_timesheets_constants.DRIVER_TIMESHEET_TYPE_PAUSE)
                                                                        ) {
                                                                            value += days[date].duration_seconds[key]
                                                                            totalDays[date].total += days[date].duration_seconds[key]
                                                                            totalRow.total += days[date].duration_seconds[key]
                                                                        }
                                                                    }, 0)
                                                                }

                                                                const isWeekend = momentDay.isoWeekday() >= 6
                                                                const isHoliday = data.holidays.includes(date)

                                                                const classes = classnames('w-50 text-center', {
                                                                    'is-weekend': isWeekend || isHoliday,
                                                                    'is-today': momentDay.isSame(momentToday, 'day'),
                                                                })

                                                                let tooltipContent = []
                                                                const content = (
                                                                    <div>
                                                                        {value !== 0 ? formatters.durationFromSecondsFormatter(value) : '-'}
                                                                        <br />
                                                                        {overtime !== 0 ? formatters.durationFromSecondsFormatter(overtime) : '-'}
                                                                        {Object.keys(dots).map((groupId, index) => {
                                                                            const group = driver_timesheets_constants.DRIVER_TIMESHEET_GROUPS[groupId]
                                                                            tooltipContent.push(
                                                                                `${this.props.intl.formatMessage({
                                                                                    id: group.name,
                                                                                })}: ${formatters.durationFromSecondsFormatter(dots[groupId])}`
                                                                            )
                                                                            return (
                                                                                <span
                                                                                    key={groupId}
                                                                                    className="dot"
                                                                                    style={{
                                                                                        backgroundColor: group.rgb_bg,
                                                                                        marginTop: 6 * index,
                                                                                    }}
                                                                                />
                                                                            )
                                                                        })}
                                                                    </div>
                                                                )

                                                                const tooltipNoVehicle = (
                                                                    <Tooltip id="tooltipNoVehicle">{tooltipContent.join(', ')}</Tooltip>
                                                                )

                                                                return (
                                                                    <td
                                                                        key={`day-td-${date}`}
                                                                        className={classes}
                                                                        role="button"
                                                                        onClick={e => {
                                                                            this.handleShowDetails(e, driver.id, date, date)
                                                                        }}
                                                                    >
                                                                        {Object.keys(dots).length ? (
                                                                            <OverlayTrigger placement="top" overlay={tooltipNoVehicle}>
                                                                                {content}
                                                                            </OverlayTrigger>
                                                                        ) : (
                                                                            content
                                                                        )}
                                                                    </td>
                                                                )
                                                            })}
                                                            <td className="w-50 text-center">
                                                                {sums.drive !== 0 ? formatters.durationFromSecondsFormatter(sums.drive) : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.night !== 0 ? formatters.durationFromSecondsFormatter(sums.night) : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.weekend !== 0 ? formatters.durationFromSecondsFormatter(sums.weekend) : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.holiday !== 0 ? formatters.durationFromSecondsFormatter(sums.holiday) : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.other_job !== 0 ? formatters.durationFromSecondsFormatter(sums.other_job) : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.vacation !== 0 ? formatters.durationFromSecondsFormatter(sums.vacation) : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.paid_vacation !== 0
                                                                    ? formatters.durationFromSecondsFormatter(sums.paid_vacation)
                                                                    : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.unpaid_vacation !== 0
                                                                    ? formatters.durationFromSecondsFormatter(sums.unpaid_vacation)
                                                                    : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.use_overtime !== 0
                                                                    ? formatters.durationFromSecondsFormatter(sums.use_overtime)
                                                                    : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.disease !== 0 ? formatters.durationFromSecondsFormatter(sums.disease) : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.absence !== 0 ? formatters.durationFromSecondsFormatter(sums.absence) : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.drive !== 0
                                                                    ? formatters.durationFromSecondsFormatter(sums.holiday_not_working)
                                                                    : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.drive !== 0
                                                                    ? formatters.durationFromSecondsFormatter(
                                                                          sums.drive_overtimes_holiday_not_working
                                                                      )
                                                                    : '-'}
                                                            </td>
                                                            <td className="w-50 text-center">
                                                                {sums.drive !== 0 ? formatters.durationFromSecondsFormatter(sums.overtime) : '-'}
                                                            </td>
                                                            <td
                                                                className="w-100 text-right"
                                                                role="button"
                                                                onClick={e => this.handleShowDetails(e, driver.id, stringISOFrom, stringISOTo)}
                                                            >
                                                                {totalRow.total !== 0 ? formatters.durationFromSecondsFormatter(totalRow.total) : '-'}
                                                                <br />
                                                                {totalRow.overtime !== 0
                                                                    ? formatters.durationFromSecondsFormatter(totalRow.overtime)
                                                                    : '-'}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-200">
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                <td className="w-100" />
                                                <td className="w-140" />
                                                <td className="w-max wm-140" />
                                                {momentDays.map(momentDay => {
                                                    const date = momentDay.format('YYYY-MM-DD')

                                                    total.total += totalDays[date].total
                                                    total.overtime += totalDays[date].overtime

                                                    return (
                                                        <td
                                                            key={`day-td-total-${date}`}
                                                            className="w-50 text-center"
                                                            role="button"
                                                            onClick={e => this.handleShowDetails(e, null, date, date)}
                                                        >
                                                            <strong>
                                                                {totalDays[date].total !== 0
                                                                    ? formatters.durationFromSecondsFormatter(totalDays[date].total)
                                                                    : '-'}
                                                                <br />
                                                                {totalDays[date].overtime !== 0
                                                                    ? formatters.durationFromSecondsFormatter(totalDays[date].overtime)
                                                                    : '-'}
                                                            </strong>
                                                        </td>
                                                    )
                                                })}
                                                <td className="w-50 text-center">
                                                    {total.drive !== 0 ? formatters.durationFromSecondsFormatter(total.drive) : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.night !== 0 ? formatters.durationFromSecondsFormatter(total.night) : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.weekend !== 0 ? formatters.durationFromSecondsFormatter(total.weekend) : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.holiday !== 0 ? formatters.durationFromSecondsFormatter(total.holiday) : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.otherJob !== 0 ? formatters.durationFromSecondsFormatter(total.otherJob) : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.vacation !== 0 ? formatters.durationFromSecondsFormatter(total.vacation) : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.paidVacation !== 0 ? formatters.durationFromSecondsFormatter(total.paidVacation) : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.unpaidVacation !== 0 ? formatters.durationFromSecondsFormatter(total.unpaidVacation) : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.useOvertime !== 0 ? formatters.durationFromSecondsFormatter(total.useOvertime) : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.disease !== 0 ? formatters.durationFromSecondsFormatter(total.disease) : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.absence !== 0 ? formatters.durationFromSecondsFormatter(total.absence) : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.holidayNotWorking !== 0
                                                        ? formatters.durationFromSecondsFormatter(total.holidayNotWorking)
                                                        : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.driveOvertimesHolidayNotWorking !== 0
                                                        ? formatters.durationFromSecondsFormatter(total.driveOvertimesHolidayNotWorking)
                                                        : '-'}
                                                </td>
                                                <td className="w-50 text-center">
                                                    {total.sumOvertime !== 0 ? formatters.durationFromSecondsFormatter(total.sumOvertime) : '-'}
                                                </td>
                                                <td
                                                    className="w-100 text-right"
                                                    role="button"
                                                    onClick={e => this.handleShowDetails(e, null, stringISOFrom, stringISOTo)}
                                                >
                                                    <strong>
                                                        {total.total !== 0 ? formatters.durationFromSecondsFormatter(total.total) : '-'}
                                                        <br />
                                                        {total.overtime !== 0 ? formatters.durationFromSecondsFormatter(total.overtime) : '-'}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {['vehicle_month_note'].includes(this.state.modal.identifier) && (
                        <Modal show onHide={this.handleModalClose} bsSize="sm">
                            <Modal.Header closeButton>
                                <Modal.Title>{this.state.modal.title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <LoadingOverlay active={this.props.modalSaving}>
                                    <form onSubmit={this.state.modal.onSave}>
                                        <div className="form-group">
                                            <label htmlFor="value_note">
                                                <FormattedMessage id="fields.note" />
                                            </label>
                                            <TextareaAutosize
                                                id="value_note"
                                                className="form-control"
                                                value={this.state.modal.values.note}
                                                onChange={this.handleModalInputChange}
                                            />
                                        </div>
                                        <div className="btns-form text-right">
                                            <button type="submit" className="btn btn-success">
                                                <FormattedMessage id="buttons.save" />
                                            </button>
                                        </div>
                                    </form>
                                </LoadingOverlay>
                            </Modal.Body>
                        </Modal>
                    )}

                    <Modal show={Boolean(this.state.modalCreateActivity.show)} onHide={this.handleModalCreateActivityClose} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.modalCreateActivity.driverTimesheetID ? (
                                    <FormattedMessage id="fields.itemEdit" />
                                ) : (
                                    <FormattedMessage id="fields.itemCreate" />
                                )}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="clearfix">
                            <LoadingOverlay active={false}>
                                <div className="pull-left wp-100">
                                    <DriverTimesheetsEditForm
                                        successCallback={this.editCreateActivitySuccessCallback}
                                        backCallback={this.editCreateActivityBackCallback}
                                        defaultValues={{
                                            driver_id: this.state.modalDetails.driverId,
                                            start_datetime: moment(this.state.modalDetails.dateFrom).format('DD.MM.YYYY HH:mm'),
                                            end_datetime: moment(this.state.modalDetails.dateTo).format('DD.MM.YYYY HH:mm'),
                                        }}
                                        driverTimesheetID={this.state.modalCreateActivity.driverTimesheetID}
                                        hiddenFields={this.state.modalDetails.driverId ? ['driver_id'] : []}
                                        PERMS={PERMS}
                                    />
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                    </Modal>

                    <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-md-2">
                        <Modal.Header closeButton>
                            <Modal.Title>{detailsData ? detailsData.title : null}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="clearfix">
                            <LoadingOverlay active={this.props.detailsFetching}>
                                {detailsData && detailsData.driver && typeof detailsData.driver.note !== 'undefined' && (
                                    <div className="pull-left wp-100">
                                        {detailsData.driver.note ? (
                                            <React.Fragment>
                                                <p>{detailsData.driver.note}</p>
                                                <PermissionsCheck has={[PERMS.UPDATE]}>
                                                    <button
                                                        className="btn btn-default"
                                                        onClick={() => this.handleDriverMonthNoteChange(detailsData.driver)}
                                                    >
                                                        <FormattedMessage id="buttons.edit" />
                                                    </button>
                                                </PermissionsCheck>
                                            </React.Fragment>
                                        ) : (
                                            <PermissionsCheck has={[PERMS.UPDATE]}>
                                                <button
                                                    className="btn btn-default"
                                                    onClick={() => this.handleDriverMonthNoteChange(detailsData.driver)}
                                                >
                                                    <FormattedMessage id="buttons.createNote" />
                                                </button>
                                            </PermissionsCheck>
                                        )}
                                    </div>
                                )}
                                <div className="btns-list">
                                    {stringDetailMomentDay && (
                                        <span className="pull-left text-thin period-selection">
                                            <button
                                                className="btn btn-default btn-rounded"
                                                onClick={e =>
                                                    this.handleShowDetails(
                                                        e,
                                                        this.state.modalDetails.driverId,
                                                        stringISODetailMomentPrevDay,
                                                        stringISODetailMomentPrevDay
                                                    )
                                                }
                                            >
                                                <i className="far fa-angle-left" />
                                            </button>
                                            <span>{stringDetailMomentDay}</span>
                                            <button
                                                className="btn btn-default btn-rounded"
                                                onClick={e =>
                                                    this.handleShowDetails(
                                                        e,
                                                        this.state.modalDetails.driverId,
                                                        stringISODetailMomentNextDay,
                                                        stringISODetailMomentNextDay
                                                    )
                                                }
                                            >
                                                <i className="far fa-angle-right" />
                                            </button>
                                        </span>
                                    )}
                                    {detailsData && detailsData.data.length > 0 && (
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExportDetails}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    )}
                                </div>

                                <div className="pull-left wp-100">
                                    <div className="panel-head">
                                        <h2 className="pull-left wp-100">
                                            <div className="pull-left">
                                                <FormattedMessage id="modules.reportDriverHourlyOverview.detail.sumeryHeading" />
                                            </div>
                                            <div className="pull-right text-md" />
                                        </h2>
                                    </div>

                                    <div className="pull-left wp-100 p-v-xs p-h-xs m-b-sm b alert-default">
                                        <div className="table-container">
                                            <table className="table table-striped table-no-border no-m">
                                                <thead>
                                                    <tr>
                                                        <th className="w-max wm-200">
                                                            <FormattedMessage id="fields.activity" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.totalTime" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {detailsData &&
                                                        detailsData.type_summary.map(summary => {
                                                            return (
                                                                <tr key={`transport-${summary.type_id}`}>
                                                                    <td>
                                                                        {this.props.intl.formatMessage({
                                                                            id: `driverTimesheetTypes.${summary.type}`,
                                                                        })}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {formatters.durationFromSecondsFormatter(summary.duration_seconds)}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="btns-list">
                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                        <button className="btn btn-addon btn-success m-r-xs" onClick={this.handleModalCreateActivityShow}>
                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                                        </button>
                                    </PermissionsCheck>
                                </div>

                                <div className="pull-left wp-100">
                                    <div className="panel-head">
                                        <h2 className="pull-left wp-100">
                                            <div className="pull-left">
                                                <FormattedMessage id="modules.reportDriverHourlyOverview.detail.tripRecordsHeading" />
                                            </div>
                                            <div className="pull-right text-md" />
                                        </h2>
                                    </div>

                                    <div className="pull-left wp-100 p-v-xs p-h-xs m-b-sm b alert-default">
                                        <div className="table-container">
                                            <table className="table table-striped table-no-border no-m">
                                                <thead>
                                                    <tr>
                                                        <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                            <th className="w-60" />
                                                        </PermissionsCheck>
                                                        {detailsData && !detailsData.driver && (
                                                            <th className="w-140 text-right">
                                                                <FormattedMessage id="fields.driver" />
                                                            </th>
                                                        )}
                                                        <th className="w-max wm-200">
                                                            <FormattedMessage id="fields.activity" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.note" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.vehicle" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.from" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.to" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.time" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {detailsData &&
                                                        detailsData.data.map(row => {
                                                            const vehicle = row.vehicle && row.vehicle.registration
                                                            const showDatetime =
                                                                String(row.interval_type_id) ===
                                                                String(driver_timesheets_constants.INTERVAL_TYPE_INTERVAL)
                                                            const onClickRow = row.vehicle_trip_records
                                                                ? null
                                                                : e => this.handleModalCreateActivityShow(e, row.id)
                                                            return (
                                                                <tr key={`transport-${row.id}`} onClick={onClickRow}>
                                                                    <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                                        <td className="w-60 table-buttons">
                                                                            {!row.vehicle_trip_records && (
                                                                                <React.Fragment>
                                                                                    <PermissionsCheck has={[PERMS.UPDATE]}>
                                                                                        <button className="far fa-pencil text-gray m-r-xxs" />
                                                                                    </PermissionsCheck>
                                                                                    <PermissionsCheck has={[PERMS.DELETE]}>
                                                                                        <button
                                                                                            onClick={e => this.handleDeleteActivity(e, row.id)}
                                                                                            className="far fa-trash text-gray"
                                                                                        />
                                                                                    </PermissionsCheck>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </td>
                                                                    </PermissionsCheck>
                                                                    {detailsData && !detailsData.driver && (
                                                                        <td className="w-140">
                                                                            {row.driver
                                                                                ? helpers.getShortFullName(
                                                                                      row.driver.first_name,
                                                                                      row.driver.last_name
                                                                                  )
                                                                                : null}
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        {this.props.intl.formatMessage({ id: `driverTimesheetTypes.${row.type}` })}
                                                                    </td>
                                                                    <td className="text-right">{row.note}</td>
                                                                    <td className="text-right">{vehicle}</td>
                                                                    <td className="text-right">
                                                                        {showDatetime
                                                                            ? formatters.datetimeFormatter(row.start_datetime)
                                                                            : formatters.dateFormatter(row.start_datetime)}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {showDatetime
                                                                            ? formatters.datetimeFormatter(row.end_datetime)
                                                                            : formatters.dateFormatter(row.end_datetime)}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {formatters.durationFromSecondsFormatter(row.duration_seconds)}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default" onClick={this.handleHideDetails}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={Boolean(this.state.modalPreferences.show)} onHide={this.handleModalPreferencesClose} bsSize="sm">
                        <React.Fragment>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <FormattedMessage id="fields.preferences" />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <LoadingOverlay active={this.props.preferencesSaving}>
                                    <form onSubmit={this.handleModalPreferencesSubmit}>
                                        <div className="row board-legend">
                                            <div className="col-md-12">
                                                <table className="table table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2} />
                                                            <td className="text-center w-40">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.modalPreferences.isAllTypesSelected === 1}
                                                                    onChange={e => {
                                                                        this.handleModalPreferencesSelectAllTypes(e.target.checked ? 1 : 0)
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        {Object.keys(driver_timesheets_constants.DRIVER_TIMESHEET_TYPES).map(typeId => {
                                                            const type = driver_timesheets_constants.DRIVER_TIMESHEET_TYPES[typeId]

                                                            if (type.notAvailable) {
                                                                return null
                                                            }

                                                            return (
                                                                <tr key={`preferences-transport-point-type-${typeId}`}>
                                                                    <td className="text-center w-40">
                                                                        <i className="fas fa-circle" style={{ color: type.rgb_bg }} />
                                                                    </td>
                                                                    <td>
                                                                        <FormattedMessage id="fields.activity" /> -{' '}
                                                                        {this.props.intl.formatMessage({ id: type.name })}
                                                                    </td>
                                                                    <td className="text-center w-40">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                this.state.modalPreferences.values &&
                                                                                parseInt(this.state.modalPreferences.values[typeId]) === 1
                                                                            }
                                                                            onChange={e => {
                                                                                this.handleModalPreferencesChange(typeId, e.target.checked ? 1 : 0)
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="btns-form text-right">
                                            <button type="submit" className="btn btn-success">
                                                <FormattedMessage id="buttons.save" />
                                            </button>
                                        </div>
                                    </form>
                                </LoadingOverlay>
                            </Modal.Body>
                        </React.Fragment>
                    </Modal>
                </div>

                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
        driverNote: getDriverNote(state),
        modalSaving: getModalSaving(state),
        driverTimesheetDeleting: getDriverTimesheetDeleting(state),
        driverTimesheetDeleteError: getDriverTimesheetDeleteError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportDriverTimesheetActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                    deleteDriverTimesheet,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ReportDriverTimesheet)
)
