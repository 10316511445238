export const notificationDropdownLimit = 10

// based on notification type (which is clearly and explicitly
// mentioned by each notification), customized icon will appear
export const icons = {
    general: 'fa fa-bell',
    transports_load_arrived: 'fas fa-truck-loading',
    transports_load_wait: 'fas fa-hourglass-half',
    transports_load_finished: 'fas fa-check',
    transports_unload_arrival: 'fas fa-truck-loading',
    transports_unload_wait: 'fas fa-hourglass-half',
    transports_unload_finished: 'fas fa-check',
    transports_state_changed_processing: 'fas fa-dolly',
    transports_state_changed_delivered: 'fa fa-flag-checkered',
    transports_state_changed_canceled: 'fas fa-ban',
    transports_approval: 'fas fa-check',
    transporeon_offers: 'fas fa-plus',
    vehicle_message: 'fas fa-comment',
}

// based on notification severity, its badge colour will
// change. Starts from 0 (low severity)
export const iconBadge = {
    0: 'success',
    1: 'success',
    2: 'success',
    3: 'success',
    4: 'warning',
    5: 'warning',
    6: 'warning',
    7: 'danger',
    8: 'danger',
    9: 'danger',
    10: 'danger',
}

// in case user has not any notifications subscribed (profile
// return NULL), below mentioned notification events will be
// subscribed as fallback
export const defaultNotificationChannels = [
    'general',
    'transports_load_arrived',
    'transports_load_wait',
    'transports_load_finished',
    'transports_unload_arrival',
    'transports_unload_wait',
    'transports_unload_finished',
    'transports_state_changed_processing',
    'transports_state_changed_delivered',
    'transports_state_changed_canceled',
    'transports_approval',
    'transporeon_offers',
    'vehicle_message',
]

// sets system notifications to active
// default is set to true, nevertheless this might be
// subject of profile settings
export const useSystemNotifications = false

// default notifications channel
export const defaultChannel = 'notifications'

// based on notification severity, below mentioned CSS class
// will be used
export const notificationClasses = {
    0: 'success',
    1: 'success',
    2: 'success',
    3: 'warning',
    4: 'warning',
    5: 'warning',
    6: 'warning',
    7: 'error',
    8: 'error',
    9: 'error',
    10: 'error',
}
