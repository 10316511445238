import * as actionTypes from './actionTypes'

// Fetch

export const fetchTransportTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchTransportTypesFulfilled = transportTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: transportTypes,
})

export const fetchTransportTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTransportTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchTransportType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchTransportTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchTransportTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchTransportTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveTransportType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveTransportTypeFulfilled = transportType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: transportType,
})

export const saveTransportTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteTransportType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteTransportTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteTransportTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportTransportTypes = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
