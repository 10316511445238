import * as actionTypes from './actionTypes'

// Fetch

export const fetchDriverDocuments = () => ({
    type: actionTypes.FETCH,
})

export const fetchDriverDocumentsForMap = () => ({
    type: actionTypes.FETCH_FOR_MAP,
})

export const fetchDriverDocumentsFulfilled = driverDocuments => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: driverDocuments,
})

export const fetchDriverDocumentsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchDriverDocumentsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchDriverDocument = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchDriverDocumentFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchDriverDocumentRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchDriverDocumentCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveDriverDocument = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveDriverDocumentFulfilled = driverDocument => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: driverDocument,
})

export const saveDriverDocumentRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveDriverDocumentDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteDriverDocument = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteDriverDocumentFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteDriverDocumentRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Clear

export const clearDriverDocuments = () => ({
    type: actionTypes.CLEAR,
})

// Fetch files

export const fetchDriverDocumentFiles = id => ({
    type: actionTypes.FETCH_FILES,
    payload: id,
})

export const fetchDriverDocumentFilesFulfilled = files => ({
    type: actionTypes.FETCH_FILES_FULFILLED,
    payload: files,
})

export const fetchDriverDocumentFilesRejected = message => ({
    type: actionTypes.FETCH_FILES_REJECTED,
    payload: message,
})

export const fetchDriverDocumentFilesCancelled = () => ({
    type: actionTypes.FETCH_FILES_CANCELLED,
})

// Upload files

export const uploadDriverDocumentFiles = (id, files) => ({
    type: actionTypes.UPLOAD_FILES,
    payload: {
        id,
        files,
    },
})

export const uploadDriverDocumentFilesFulfilled = files => ({
    type: actionTypes.UPLOAD_FILES_FULFILLED,
    payload: files,
})

export const uploadDriverDocumentFilesRejected = message => ({
    type: actionTypes.UPLOAD_FILES_REJECTED,
    payload: message,
})

export const uploadDriverDocumentFilesCancelled = () => ({
    type: actionTypes.UPLOAD_FILES_CANCELLED,
})

// Delete file

export const deleteDriverDocumentFile = (id, file_id) => ({
    type: actionTypes.DELETE_FILE,
    payload: {
        id,
        file_id,
    },
})

export const deleteDriverDocumentFileFulfilled = files => ({
    type: actionTypes.DELETE_FILE_FULFILLED,
    payload: files,
})

export const deleteDriverDocumentFileRejected = message => ({
    type: actionTypes.DELETE_FILE_REJECTED,
    payload: message,
})

export const deleteDriverDocumentFileCancelled = () => ({
    type: actionTypes.DELETE_FILE_CANCELLED,
})

// Export

export const exportDriverDocuments = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
