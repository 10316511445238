import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Modal from 'react-modal'

import Search from './Search'
import Chat from './Chat'
import * as vehicleMessagesActionCreators from '../actionCreators'
import { getIsOpen, getSearchString, getSelectedVehicle } from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class Sidebar extends Component {
    handleCloseSidebar = e => {
        e.preventDefault()

        this.props.actions.hideSidebar()
    }

    handleSearch = searchString => {
        this.props.actions.setSearch(searchString)
    }

    render() {
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)}>
                <Modal
                    className={`slide-pane slide-pane_from_right`}
                    overlayClassName={`slide-pane__overlay`}
                    closeTimeoutMS={500}
                    isOpen={this.props.isOpen}
                    contentLabel={`Chat`}
                >
                    <div className="slide-pane__contents vehicle-messages-sidebar">
                        {this.props.selectedVehicle ? (
                            <Chat />
                        ) : (
                            <Search
                                searchString={this.props.searchString}
                                handleSearch={this.handleSearch}
                                handleCloseSidebar={this.handleCloseSidebar}
                            />
                        )}
                    </div>
                </Modal>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        isOpen: getIsOpen(state),
        selectedVehicle: getSelectedVehicle(state),
        searchString: getSearchString(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(vehicleMessagesActionCreators, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
