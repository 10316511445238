import { combineEpics } from 'redux-observable'

import auth from './pages/auth/epics'
import prerequisites from './common/prerequisites/epics'
import users from './pages/users/epics'
import userRoles from './pages/user_roles/epics'
import userLogins from './pages/user_logins/epics'
import blockedIps from './pages/blocked_ips/epics'
import generalSettings from './pages/general_settings/epics'
import countries from './pages/countries/epics'
import currencies from './pages/currencies/epics'
import carriers from './pages/carriers/epics'
import carrierContacts from './pages/carrier_contacts/epics'
import transportTypes from './pages/transport_types/epics'
import cargoTypes from './pages/cargo_types/epics'
import vehicleTypes from './pages/vehicle_types/epics'
import trailerTypes from './pages/trailer_types/epics'
import companies from './pages/companies/epics'
import companyCostCenters from './pages/company_cost_centers/epics'
import companyBankAccounts from './pages/company_bank_accounts/epics'
import companyNumberings from './pages/company_numberings/epics'
import customers from './pages/customers/epics'
import drivers from './pages/drivers/epics'
import driversTypes from './pages/drivers_types/epics'
import trailers from './pages/trailers/epics'
import vehicles from './pages/vehicles/epics'
import driverTimesheets from './pages/driver_timesheets/epics'
import customerAddresses from './pages/customer_address/epics'
import customerContacts from './pages/customer_contacts/epics'
import customerPlans from './pages/customer_plans/epics'
import vehiclePositions from './pages/vehicle_positions/epics'
import transports from './pages/transports/epics'
import board from './pages/board/epics'
import surchargeTypes from './pages/surcharge_types/epics'
import customerAddressTypes from './pages/customer_address_types/epics'
import vehicleEvents from './pages/vehicle_events/epics'
import vehicleFuelings from './pages/vehicle_fuelings/epics'
import transporeonOffers from './pages/transporeon_offers/epics'
import vehicleMessages from './pages/vehicle_messages/epics'
import reportVehicleChecks from './pages/report_vehicle_checks/epics'
import reportCustomerTurnovers from './pages/report_customer_turnovers/epics'
import reportEmptyKilometers from './pages/report_empty_kilometers/epics'
import reportPallets from './pages/report_pallets/epics'
import reportInvoicingSummary from './pages/report_invoicing_summary/epics'
import goodsTypes from './pages/goods_types/epics'
import documentTypes from './pages/document_types/epics'
import driverDocuments from './pages/driver_documents/epics'
import vehicleDocuments from './pages/vehicle_documents/epics'
import trailerDocuments from './pages/trailer_documents/epics'
import equipments from './pages/equipments/epics'
import driverEquipments from './pages/driver_equipments/epics'
import vehicleEquipments from './pages/vehicle_equipments/epics'
import trailerEquipments from './pages/trailer_equipments/epics'
import reportCustomerResults from './pages/report_customer_results/epics'
import reportCostCenterResults from './pages/report_cost_center_results/epics'
import reportTransportInvoicing from './pages/report_transport_invoicing/epics'
import reportTransportChanges from './pages/report_transport_changes/epics'
import reportTransports from './pages/report_transports/epics'
import reportSpeditionTransports from './pages/report_spedition_transports/epics'
import reportVehicleKilometersTotal from './pages/report_vehicle_kilometers_total/epics'
import reportCostCenterTraffic from './pages/report_cost_center_traffic/epics'
import reportCostCenterPerformance from './pages/report_cost_center_performance/epics'
import reportVehicleTraffic from './pages/report_vehicle_traffic/epics'
import reportVehiclePerformance from './pages/report_vehicle_performance/epics'
import reportVehicleFuelings from './pages/report_vehicle_fuelings/epics'
import reportVehicleResults from './pages/report_vehicle_results/epics'
import reportDriverAllowances from './pages/report_driver_allowances/epics'
import reportDriverPerformanceAnalysis from './pages/report_driver_performance_analysis/epics'
import reportDriverHourlyOverview from './pages/report_driver_hourly_overview/epics'
import reportDriverTimesheet from './pages/report_driver_timesheets/epics'
import reportDriverResults from './pages/report_driver_results/epics'
import reportDriverCosts from './pages/report_driver_costs/epics'
import reportTrailerResults from './pages/report_trailer_results/epics'
import reportTrailerCosts from './pages/report_trailer_costs/epics'
import reportTrailerFuelings from './pages/report_trailer_fuelings/epics'
import reportVehicleCosts from './pages/report_vehicle_costs/epics'
import reportCostCenterCosts from './pages/report_cost_center_costs/epics'
import reportCostCenterCost from './pages/report_cost_center_cost/epics'
import reportCostCenterProfits from './pages/report_cost_center_profits/epics'
import reportVehicleProfits from './pages/report_vehicle_profits/epics'
import taxRates from './pages/tax_rates/epics'
import paymentMethods from './pages/payment_methods/epics'
import invoicingOrders from './pages/invoicing_orders/epics'
import invoicingInvoices from './pages/invoicing_invoices/epics'
import packagingTypes from './pages/packaging_types/epics'
import transportConditions from './pages/transport_conditions/epics'
import invoiceItemTypes from './pages/invoice_item_types/epics'
import invoicingReceivedInvoices from './pages/invoicing_received_invoices/epics'
import transportScoring from './pages/transport_scoring/epics'
import carrierScoring from './pages/carrier_scoring/epics'
import reportCarrierScoring from './pages/report_carrier_scoring/epics'
import reportDispatcherKPI from './pages/report_dispatcher_kpi/epics'
import reportAppUsage from './pages/report_app_usage/epics'
import reportCarrierUsage from './pages/report_carrier_usage/epics'
import reportTransportOrders from './pages/report_transport_orders/epics'
import reportCustomerPlans from './pages/report_customer_plans/epics'
import reportCustomerMonthlyPlans from './pages/report_customer_monthly_plans/epics'
import reportCustomerWeeklyPlans from './pages/report_customer_weekly_plans/epics'
import reportCarrierResults from './pages/report_carrier_results/epics'
import borderCrossings from './pages/border_crossings/epics'
import legend from './pages/legend/epics'
import hereMap from './common/here_maps/epics'
import tracing from './pages/tracing/epics'
import userProfile from './pages/user_profile/epics'
import notifications from './common/notifications/epics'
import dietRates from './pages/diet_rates/epics'
import costTypes from './pages/cost_types/epics'
import costs from './pages/costs/epics'
import reportCostTypeCosts from './pages/report_cost_types_costs/epics'
import salesTypes from './pages/sales_types/epics'
import sales from './pages/sales/epics'
import driverSurchargeTypes from './pages/driver_surcharge_types/epics'
import reportDriverSurcharges from './pages/report_driver_surcharges/epics'
import driverSurcharges from './pages/driver_surcharges/epics'
import reportCustomerSpeditions from './pages/report_customer_speditions/epics'
import units from './pages/units/epics'
import warehousingWarehouses from './pages/warehousing_warehouses/epics'
import warehousingItems from './pages/warehousing_items/epics'
import warehousingReceipts from './pages/warehousing_receipts/epics'
import warehousingIssues from './pages/warehousing_issues/epics'
import serviceJobs from './pages/service_jobs/epics'
import serviceWorkers from './pages/service_workers/epics'
import serviceOrders from './pages/service_orders/epics'

const epics = combineEpics(
    ...auth,
    ...prerequisites,
    ...users,
    ...userRoles,
    ...userLogins,
    ...blockedIps,
    ...generalSettings,
    ...countries,
    ...currencies,
    ...transportTypes,
    ...cargoTypes,
    ...vehicleTypes,
    ...trailerTypes,
    ...companies,
    ...carriers,
    ...carrierContacts,
    ...customers,
    ...drivers,
	...driversTypes,
    ...trailers,
    ...vehicles,
    ...driverTimesheets,
    ...customerAddresses,
    ...customerContacts,
    ...customerPlans,
    ...vehiclePositions,
    ...transports,
    ...board,
    ...surchargeTypes,
    ...customerAddressTypes,
    ...vehicleEvents,
    ...vehicleFuelings,
    ...transporeonOffers,
    ...vehicleMessages,
    ...reportVehicleChecks,
    ...reportCustomerTurnovers,
    ...reportEmptyKilometers,
    ...reportPallets,
    ...reportInvoicingSummary,
    ...goodsTypes,
    ...documentTypes,
    ...driverDocuments,
    ...vehicleDocuments,
    ...trailerDocuments,
    ...companyCostCenters,
    ...companyBankAccounts,
    ...companyNumberings,
    ...equipments,
    ...driverEquipments,
    ...vehicleEquipments,
    ...trailerEquipments,
    ...reportCustomerResults,
    ...reportCostCenterResults,
    ...reportTransportChanges,
    ...reportTransports,
    ...reportSpeditionTransports,
    ...reportVehicleKilometersTotal,
    ...reportCostCenterTraffic,
    ...reportCostCenterPerformance,
    ...reportCostCenterProfits,
    ...reportVehicleTraffic,
    ...reportVehiclePerformance,
    ...reportVehicleFuelings,
    ...reportVehicleResults,
    ...reportDriverAllowances,
    ...reportDriverPerformanceAnalysis,
    ...reportDriverHourlyOverview,
    ...reportDriverTimesheet,
    ...reportDriverResults,
    ...reportDriverCosts,
    ...reportTrailerResults,
    ...reportTrailerCosts,
	...reportTrailerFuelings,
    ...reportVehicleCosts,
    ...reportCostCenterCosts,
    ...reportCostCenterCost,
    ...reportVehicleProfits,
    ...taxRates,
    ...paymentMethods,
    ...invoicingOrders,
    ...invoicingInvoices,
    ...packagingTypes,
    ...transportConditions,
    ...invoiceItemTypes,
    ...invoicingReceivedInvoices,
    ...transportScoring,
    ...carrierScoring,
    ...reportCarrierScoring,
    ...reportDispatcherKPI,
    ...reportAppUsage,
    ...reportCarrierUsage,
    ...reportTransportInvoicing,
    ...reportTransportOrders,
    ...reportCustomerPlans,
    ...reportCustomerMonthlyPlans,
    ...reportCustomerWeeklyPlans,
    ...reportCarrierResults,
    ...borderCrossings,
    ...legend,
    ...hereMap,
    ...tracing,
    ...userProfile,
    ...notifications,
    ...dietRates,
    ...costTypes,
    ...costs,
    ...reportCostTypeCosts,
    ...salesTypes,
    ...sales,
	...driverSurchargeTypes,
	...reportDriverSurcharges,
	...driverSurcharges,
	...reportCustomerSpeditions,
	...units,
	...warehousingWarehouses,
	...warehousingItems,
	...warehousingReceipts,
	...warehousingIssues,
	...serviceJobs,
	...serviceWorkers,
	...serviceOrders
)

export default epics
