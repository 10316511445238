/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import Echo from 'laravel-echo'
import io from 'socket.io-client'
import { createGetUnreadNotificationsSelector, createGetUnreadNotificationsLoading, createGetUserIdSelector } from '../selectors'
import { bindActionCreators } from 'redux'
import * as wsNotificationActionCreators from '../actionCreators'
import { push } from 'react-router-redux'
import Notifier from 'react-desktop-notification'
import WSNotificationLine from './WSNotificationLine'
import WSNotificationSettingModal from './WSNotificationSettingModal'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as notificationsConfig from '../config'
import * as config from '../../config'

window.io = io

const notif = config.NOTIFICATIONS_URL ? new Echo({
    broadcaster: 'socket.io',
    host: config.NOTIFICATIONS_URL,
}) : null

class WSNotifications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            openModal: false,
        }

        this.open = this.open.bind(this)
        this.openModal = this.openModal.bind(this)
        this.markNotificationAsRead = this.markNotificationAsRead.bind(this)
    }

    componentWillMount() {
        this.props.actions.fetchUnreadNotifications()
    }

    componentDidMount() {
        const userId = this.props.userId

        // subscribe all channels based on settings
        notif && notificationsConfig.defaultNotificationChannels.forEach(eventName => {
            // subscribes converted channel name
            // conversion   from:   transports_load_arrived
            //              to:     TransportsLoadArrivedEvent
            let transformedEventName = `${eventName.replace(/_([a-z])/g, function(g) {
                return g[1].toUpperCase()
            })}Event`
            transformedEventName = transformedEventName[0].toUpperCase() + transformedEventName.slice(1)

            notif.channel(notificationsConfig.defaultChannel).listen(transformedEventName, e => {
                this.props.actions.notificationReceived(e, userId)

                if (e.users.includes(parseInt(userId))) {
                    if (notificationsConfig.useSystemNotifications) {
                        Notifier.focus(this.props.intl.formatMessage({ id: `fields.notifications_${e.type}` }), this.parseLinks(e))
                    } else {
                        this.props.notify(
                            { /* title: this.parseLinks(e), */ message: this.parseLinks(e), position: 'bl' },
                            notificationsConfig.notificationClasses[e.severity]
                        )
                    }
                }
            })
        })
    }

    componentWillUnmount() {
        notif && notif.leave(notificationsConfig.defaultChannel)
    }

    open(e) {
        e.preventDefault()
        this.setState({ open: !this.state.open })
    }

    openModal(e) {
        e && e.preventDefault()
        this.setState({ openModal: !this.state.openModal })
    }

    markNotificationAsRead(e, timestamp) {
        this.props.actions.markNotificationAsReadStart(timestamp)
        e.stopPropagation()
    }

    /**
     * Parses links (if any) into message body
     *
     * @param {object} notification Notification object
     * @returns {*}
     */
    parseLinks(notification) {
        let parsedMessage = notification.message
        if (Boolean(notification.links) && notification.links.length > 0) {
            const links = notification.links.map(link => link.text)

            parsedMessage = notification.message.split(new RegExp(/%l\d/gm))
            for (let i = 0; i < parsedMessage.length; i++) {
                parsedMessage[i] = (
                    <React.Fragment>
                        {parsedMessage[i]} {links[i]}
                    </React.Fragment>
                )
            }
        }
        return parsedMessage
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const unreadNotifications = this.props.notifications
        const notifCfg = notificationsConfig
        let unreadLoopCounter = 0

        if (!notif) {
            return null;
        }

        return (
            <React.Fragment>
                <li onClick={e => this.open(e)}>
                    <div className={`dropdown ${this.state.open ? 'open' : ''} btn-group`}>
                        <button className="dropdown-toggle btn btn-default">
                            <span>
                                <i className="far fa-bell m-r-xxs" />
                                {Boolean(this.props.notifications) && unreadNotifications.length > 0 && (
                                    <span className="badge badge-success pull-right">{unreadNotifications.length}</span>
                                )}
                            </span>
                        </button>
                        <ul className="dropdown-menu title-caret dropdown-lg" role="menu">
                            <li>
                                <p className="drop-title text-center">
                                    <FormattedMessage id="notifications.unreadItems" values={{ count: unreadNotifications.length || 0 }} />
                                    <i onClick={e => this.openModal(e)} className="pull-right fa fa-cogs" />
                                </p>
                            </li>
                            <li className="dropdown-menu-list messages">
                                <ul className="list-unstyled">
                                    {this.props.notifications.map(notification => {
                                        if (unreadLoopCounter <= notifCfg.notificationDropdownLimit) {
                                            unreadLoopCounter++
                                            return (
                                                <WSNotificationLine
                                                    notification={notification}
                                                    key={`notification_${notification.timestamp}`}
                                                    markAsRead={this.markNotificationAsRead}
                                                    redirect={this.props.redirect}
                                                    loading={this.props.loading}
                                                />
                                            )
                                        }
                                        return null
                                    })}
                                </ul>
                            </li>
                            <li className="drop-all">
                                <a href="#" onClick={() => this.props.redirect('/notifications')} className="text-center">
                                    <FormattedMessage id="notifications.allItems" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <WSNotificationSettingModal show={this.state.openModal} onHide={this.openModal} />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        loading: createGetUnreadNotificationsLoading()(state),
        notifications: createGetUnreadNotificationsSelector()(state),
        userId: createGetUserIdSelector()(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...wsNotificationActionCreators,
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(WSNotifications)
)
