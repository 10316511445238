import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class CurrenciesListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render = () => (
        <tr onClick={this.handleClick} className="row-selectable">
            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                <td className="w-60 table-buttons">
                    <PermissionsCheck has={[PERMS.UPDATE]}>
                        <button className="far fa-pencil text-gray m-r-xxs" />
                    </PermissionsCheck>
                    <PermissionsCheck has={[PERMS.DELETE]}>
                        <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                    </PermissionsCheck>
                </td>
            </PermissionsCheck>
            <td className="w-120">{this.props.data.iso_code}</td>
            <td className="w-max wm-300">{this.props.data.name}</td>
            <td className="w-100 text-center">{formatters.boolFormatter(this.props.data.is_default)}</td>
            <td className="w-100 text-right">{formatters.floatFormatter(this.props.data.rate, 3)}</td>
        </tr>
    )
}

export default CurrenciesListRow
