import { Map } from 'immutable'

import * as actionTypes from './actionTypes'

const updateConfiguration = (tableIdentifier, configuration) => ({
    type: actionTypes.CONFIGURATION_CHANGED,
    payload: {
        tableIdentifier,
        configuration,
    },
})

const changePage = (tableIdentifier, configuration) => page => {
    const newConfiguration = configuration.setIn(['pagination', 'current'], page.selected)

    return updateConfiguration(tableIdentifier, newConfiguration)
}

const changeSorting = (tableIdentifier, configuration) => (column, direction) => {
    const newConfiguration = configuration.withMutations(map => {
        map
            .setIn(['sorting', 'column'], column)
            .setIn(['sorting', 'direction'], direction)
            .setIn(['pagination', 'current'], 0)
    })

    return updateConfiguration(tableIdentifier, newConfiguration)
}

const changeFilter = (tableIdentifier, configuration) => (column, type, value, customFilter) => {
    const newConfiguration = configuration.withMutations(map => {
        map
            .setIn(['filters', column, 'type'], type)
            .setIn(['filters', column, 'value'], value)
            .setIn(['filters', column, 'custom_filter'], customFilter)
            .setIn(['pagination', 'current'], 0)
    })

    return updateConfiguration(tableIdentifier, newConfiguration)
}

const changeFilters = (tableIdentifier, configuration) => filters => {
    const newConfiguration = configuration.withMutations(map => {
        if (filters !== undefined && filters.length > 0) {
            filters.map(filter =>
                map
                    .setIn(['filters', filter.column, 'type'], filter.type)
                    .setIn(['filters', filter.column, 'value'], filter.value)
                    .setIn(['filters', filter.column, 'custom_filter'], filter.customFilter)
            )

            map.setIn(['pagination', 'current'], 0)
        }
    })

    return updateConfiguration(tableIdentifier, newConfiguration)
}

const resetFilters = (tableIdentifier, configuration) => (columns, filters) => {
    let newConfiguration

    if (columns === undefined || columns.length === 0) {
        newConfiguration = configuration.withMutations(map => {
            map.set('filters', Map()).setIn(['pagination', 'current'], 0)
            if (filters !== undefined && filters.length > 0) {
                filters.map(filter =>
                    map
                        .setIn(['filters', filter.column, 'type'], filter.type)
                        .setIn(['filters', filter.column, 'value'], filter.value)
                        .setIn(['filters', filter.column, 'custom_filter'], filter.customFilter)
                )
            }
        })
    } else {
        newConfiguration = configuration.withMutations(map => {
            columns.map(column => map.deleteIn(['filters', column]))
            map.setIn(['pagination', 'current'], 0)
        })
    }

    return updateConfiguration(tableIdentifier, newConfiguration)
}

const setConfiguration = (tableIdentifier, configuration) => (filters, sortingColumn, sortingDirection) => {
    let newConfiguration

    if (filters === undefined || filters.length === 0) {
        newConfiguration = configuration.set('filters', Map())
    } else {
        newConfiguration = configuration.withMutations(map => {
            filters.map(filter =>
                map
                    .setIn(['filters', filter.column, 'type'], filter.type)
                    .setIn(['filters', filter.column, 'value'], filter.value)
                    .setIn(['filters', filter.column, 'custom_filter'], filter.customFilter)
                    .setIn(['sorting', 'column'], sortingColumn)
                    .setIn(['sorting', 'direction'], sortingDirection)
            )
        })
    }

    return updateConfiguration(tableIdentifier, newConfiguration)
}

export const prepareTableActionCreators = (tableIdentifier, configuration) => ({
    changePage: changePage(tableIdentifier, configuration),
    changeSorting: changeSorting(tableIdentifier, configuration),
    changeFilter: changeFilter(tableIdentifier, configuration),
    changeFilters: changeFilters(tableIdentifier, configuration),
    resetFilters: resetFilters(tableIdentifier, configuration),
    setConfiguration: setConfiguration(tableIdentifier, configuration),
})

// Only update page without triggering CONFIGURATION_CHANGED
// This is useful when parsing paginated server responses
export const updatePagination = (tableIdentifier, totalPages, currentPage, totalCount) => ({
    type: actionTypes.PAGINATION_CHANGED,
    payload: {
        tableIdentifier,
        pagination: {
            total: totalPages,
            current: currentPage,
            totalCount,
        },
    },
})
