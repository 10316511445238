import React from 'react'
import moment from 'moment'
import numeral from 'numeral'

// Date & time
export const dateFormatter = (value, format = 'DD.MM.YYYY') => value && moment(value).format(format)

export const timeFormatter = (value, format = 'HH:mm') => value && moment(value).format(format)

export const datetimeFormatter = (value, format = 'DD.MM.YYYY HH:mm') => value && moment(value).format(format)

export const datetimeISOFormatter = (value, format = 'YYYY-MM-DD HH:mm') => value && moment(value).format(format)

export const timeFromSecondsFormatter = (value, format = 'HH:mm') =>
    moment('00:00', 'HH:mm')
        .add(value, 'seconds')
        .format(format)

export const durationFromSecondsFormatter = (value) => {
    const duration = moment.duration(value, 'seconds')
    return Math.floor(duration.asHours()) + ':' + ('0' + duration.minutes()).slice(-2)
}

export const datePeriodFormatter = (from, to) => {
    const parts = []
    if (from) {
        parts.push(dateFormatter(from))
    }

    if (to && to !== from) {
        parts.push(dateFormatter(to))
    }

    return parts.join(' - ')
}

export const datetimePeriodFormatter = (from, to) => {
    const parts = []
    if (from) {
        parts.push(datetimeFormatter(from))
    }

    if (to && to !== from) {
        const dateFrom = from && dateFormatter(from)
        const dateTo = to && dateFormatter(to)

        parts.push(dateFrom !== dateTo ? datetimeFormatter(to) : timeFormatter(to))
    }

    return (from || to) && parts.join(' - ')
}

export const hoursFromMinutesFormatter = minutes =>
    `${Math.floor(minutes / 60) < 10 ? '0' : ''}${Math.floor(minutes / 60)} h ${minutes % 60 < 10 ? '0' : ''}${minutes % 60} min`
// Numbers
export const numberFormatter = (value, format = '0,0', unit = '') => numeral(parseFloat(value)).format(format) + (unit ? ` ${unit}` : '')

export const integerFormatter = value => numberFormatter(value)

export const floatFormatter = (value, decimals) => numberFormatter(value, `0,0.${String('0').repeat(decimals || 2)}`)

export const priceFormatter = (value, format = '0,0', currencyCode = 'CZK') => numberFormatter(value, format, currencyCode)

export const percentFormatter = (value, format = '0,0', unit = '%') => numberFormatter(value, format, unit)

export const weightFormatter = (value, format = '0,0', unit = 'kg') => numberFormatter(value, format, unit)

export const volumeFormatter = (value, format = '0,0', unit = 'l') => numberFormatter(value, format, unit)

export const distanceFormatter = (value, format = '0,0', unit = 'km') => numberFormatter(value, format, unit)

export const consumptionFormatter = (value, format = '0,0.00', unit = 'l/100 km') => numberFormatter(value, format, unit)

export const dimensionsFormatter = (width, height, length, format = '0,0.00', unit = 'm') =>
    `${numberFormatter(length, format)} x ${numberFormatter(width, format)} x ${numberFormatter(height, format, unit)}`

export const speedFormatter = (value, format = '0,0', unit = 'km/h') => numberFormatter(value, format, unit)

// Boolean
export const boolFormatter = (value, className = 'text-success') =>
    String(value) === '1' ? <i className={`far fa-check ${className}`} /> : <i className={'far fa-times text-gray-lighter'} />

export const boolFormatterDanger = value => boolFormatter(value, 'text-danger')

// GPS
export const gpsFormatter = (lat, lng) =>
    lat && lng ? `${numberFormatter(lat, '0.00000000').replace(',', '.')}, ${numberFormatter(lng, '0.0000000').replace(',', '.')}` : ''

export const firstLetterCapitalizationFormatter = string => string.charAt(0).toUpperCase() + string.slice(1)

// Title
export const titleFormatter = string => (string ? `${string} | Fleetingo` : 'Fleetingo')

// Name
export const fullName = (firstName, lastName) => {
    const parts = []
    lastName && parts.push(lastName)
    firstName && parts.push(firstName)

    return parts.join(' ')
}

export const shortFullName = (firstName, lastName) => {
    const parts = []
    lastName && parts.push(lastName)
    firstName && parts.push(`${firstName.substring(0, 1)}.`)

    return parts.join(' ')
}

// Address
export const address = (street, city, zipcode, countryCode) => {
    const parts = []
    street && parts.push(street)
    city && parts.push(city)
    zipcode && parts.push(zipcode)
    countryCode && parts.push(countryCode)

    return parts.join(', ')
}
