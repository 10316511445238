import React, { Component } from 'react'

import 'react-datepicker/dist/react-datepicker.css'

import TableFilterField from './TableFilterField'

import * as helpers from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import {CustomDatePicker} from "../../custom_date_picker";

class TableFilterInputField extends Component {
    handleChange = event => {
        this.props.onChange(this.props.identifier, this.props.type, event.target.value, this.props.customFilter)
    }

    handleDatetimeChange = value => {
        this.props.onChange(this.props.identifier, this.props.type, value)
    }

    render() {
        let value = this.props.filters.getIn([this.props.identifier, 'value'])

        if (value === undefined) {
            value = ''
        }

        return (
            <TableFilterField>
                {this.props.datepicker ? (
                    <div style={{ position: 'relative' }}>
                        <CustomDatePicker
                            selected={helpers.convertCSDatetimeToMoment(value)}
                            value={value}
                            onChange={val => {
                                this.handleDatetimeChange(formatters.dateFormatter(val))
                            }}
                            onChangeRaw={e => {
                                this.handleDatetimeChange(e.target.value)
                            }}
                            placeholderText={this.props.placeholder}
                        />
                    </div>
                ) : (
                    <input
                        id={`filter_${this.props.identifier}`}
                        placeholder={this.props.placeholder}
                        type="text"
                        value={value}
                        onChange={this.handleChange}
                        className={`form-control ${this.props.className ? this.props.className : ''}`}
                        disabled={this.props.disabled}
                    />
                )}
            </TableFilterField>
        )
    }
}

export default TableFilterInputField
