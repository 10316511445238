import React from 'react'
import { Route, Switch } from 'react-router'
import VehicleTypesList from './VehicleTypesList'
import VehicleTypesEdit from './VehicleTypesEdit'

const VehicleTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={VehicleTypesList} />
        <Route exact path={`${props.match.url}/new`} component={VehicleTypesEdit} />
        <Route exact path={`${props.match.url}/:vehicleTypeID`} component={VehicleTypesEdit} />
    </Switch>
)

export default VehicleTypes
