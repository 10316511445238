import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import { ColoredBox } from '../../../common/colored_box'

class TrailersHistoryTrailersRow extends Component {
    onClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onTrailerSelect && this.props.onTrailerSelect(this.props.trailer)
    }

    render() {
        const trailer = this.props.trailer
        const selectedTrailer = this.props.selectedTrailer
        const trailerType = this.props.trailerType

        return (
            <tr onClick={this.onClick} className={`row-selectable ${selectedTrailer && selectedTrailer.id === trailer.id ? 'row-selected' : ''}`}>
                <td className="w-140">{trailer.registration && <ColoredBox>{trailer.registration}</ColoredBox>}</td>
                <td className="w-max wm-200">{trailer.vin}</td>
                <td className="w-200">{trailerType && trailerType.name}</td>
                <td className="w-80 text-center">{formatters.boolFormatter(trailer.is_active)}</td>
                <td className="w-40 table-buttons text-center">
                    <button className="far fa-arrow-circle-right text-gray" />
                </td>
            </tr>
        )
    }
}

export default TrailersHistoryTrailersRow
