import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { url, convertISODatetimeToCS } from '../../helpers'

/* eslint-disable jsx-a11y/anchor-is-valid */
class WSNotificationsListLine extends Component {
    constructor(props) {
        super(props)

        this.state = { loading: false }

        this.parseLinks = this.parseLinks.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ loading: nextProps.loading })
    }

    /**
     * Parses links (if any) into message body
     *
     * @param {object} notification Notification object
     * @returns {*}
     */
    parseLinks(notification) {
        let parsedMessage = notification.message
        if (Boolean(notification.links) && notification.links.length > 0) {
            const links = notification.links.map(link => (
                // eslint-disable-next-line react/jsx-key
                <Link title={link.title || link.text} to={url(this.props.match, link.href)}>
                    {link.text}
                </Link>
            ))

            // eslint-disable-next-line require-unicode-regexp
            parsedMessage = notification.message.split(new RegExp(/%l\d/gm))
            for (let i = 0; i < parsedMessage.length; i++) {
                parsedMessage[i] = (
                    <span key={`${notification.timestamp}_${i}`}>
                        {parsedMessage[i]} {links[i]}
                    </span>
                )
            }
        }

        return parsedMessage
    }

    render() {
        return (
            <tr className={!this.props.notification.is_read ? 'unread' : null}>
                <td className="w-120">{convertISODatetimeToCS(this.props.notification.timestamp * 1000)}</td>
                <td>
                    {!this.props.notification.is_read ? (
                        <a
                            onClick={e => {
                                this.setState({ loading: true })
                                this.props.markAsRead(e, this.props.notification.timestamp)
                            }}
                        >
                            {this.state.loading ? <i className="fa fa-spin fa-spinner" /> : <i className="fas fa-check-circle text-success" />}{' '}
                        </a>
                    ) : null}
                    {this.parseLinks(this.props.notification)}
                </td>
            </tr>
        )
    }
}

export default injectIntl(WSNotificationsListLine)
