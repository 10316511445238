import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import { DispatchersBox } from '../../../common/colored_box'

// eslint-disable-next-line react/prefer-stateless-function
class DriversEventsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.item.id)
    }

    render() {
        const event = this.props.item
        const transportPoint = event.transport_point
        const typeName = event.type_id && this.props.eventTypes && this.props.eventTypes[event.type_id]

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <td className="w-120">{event.datetime_start ? formatters.datetimeFormatter(event.datetime_start) : null}</td>
                <td className="w-120">{event.datetime_end ? formatters.datetimeFormatter(event.datetime_end) : null}</td>
                <td className="w-140">
                    {event.vehicle ? <DispatchersBox dispatcher={event.dispatcher}>{event.vehicle.registration}</DispatchersBox> : null}
                </td>
                <td className="w-120">{event.trailer ? <DispatchersBox dispatcher={null}>{event.trailer.registration}</DispatchersBox> : null}</td>
                <td className="w-80">{transportPoint ? transportPoint.transport_id : null}</td>
                <td className="w-200">{typeName}</td>
                <td className="w-max wm-300">{transportPoint ? transportPoint.address : null}</td>
            </tr>
        )
    }
}

export default injectIntl(DriversEventsListRow)
