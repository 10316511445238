import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'

import { url, handleCommonEditActions, validationMessage } from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { Form, InputField, SubmitButton } from '../../../common/form'
import { createGetDriversTypeByIDSelector, createGetCurrentDriversTypeStatusSelector } from '../selectors'
import * as driversTypesActionCreators from '../actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class DriversTypesEdit extends Component {
    defaultValues = {}

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave()
        })
    }

    handleSave = () => {
        this.props.actions.saveDriversType(this.state.values)
    }

    componentDidMount() {
        this.props.match.params.driversTypeID && this.props.actions.fetchDriversType(this.props.match.params.driversTypeID)
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'drivers-types'))
        })

        if (JSON.stringify(nextProps.driversType) !== JSON.stringify(this.props.driversType) || (nextProps.driversType && !this.state.values.id)) {
            this.setValues(nextProps.driversType.toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.driversTypes.heading' }))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.driversTypes.heading" /> -{' '}
                                                {this.props.driversType ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay active={this.props.status.get('fetching')}>
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.driversType)}
                                                >
                                                    <InputField id="name" label={this.props.intl.formatMessage({ id: 'fields.name' })} />

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'drivers-types')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        <SubmitButton
                                                            perms={Object.values(PERMS)}
                                                            isEdit={Boolean(this.props.driversType)}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        driversType: createGetDriversTypeByIDSelector(ownProps.match.params.driversTypeID)(state),
        status: createGetCurrentDriversTypeStatusSelector()(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...driversTypesActionCreators,
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DriversTypesEdit)
)
