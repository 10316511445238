import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_ADDRESSES } from '../permissions'

import * as formatters from '../../../common/formatters'

// eslint-disable-next-line react/prefer-stateless-function
class CustomersAddressesListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick && this.props.onClick(this.props.item)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete && this.props.onDelete(this.props.item)
    }

    render() {
        const address = this.props.item

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS_ADDRESSES.UPDATE, PERMS_ADDRESSES.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS_ADDRESSES.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_ADDRESSES.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-100">
                    <strong>{address.code}</strong>
                </td>
                <td className="w-240">
                    <strong>{address.name}</strong>
                </td>
                <td className="w-180">{address.type && address.type.name}</td>
                <td className="w-60 text-center">{address.country && address.country.iso_code}</td>
                <td className="w-max wm-250">{address.address}</td>
                <td className="w-200 wrapped">{address.note}</td>
                <td className="w-80 text-center">{formatters.boolFormatter(address.is_booking_required)}</td>
            </tr>
        )
    }
}

export default injectIntl(CustomersAddressesListRow)
