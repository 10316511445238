import React from 'react'
import { Route, Switch } from 'react-router'
import ReportDriverHourlyOverview from './ReportDriverHourlyOverview'

const Components = props => (
    <Switch>
        <Route exact path={props.match.url} component={ReportDriverHourlyOverview} />
    </Switch>
)

export default Components