import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'
import Notifications from 'react-notification-system-redux'
import moment from 'moment/moment'
import { Tooltip, OverlayTrigger, Collapse } from 'react-bootstrap'

import * as config from '../../../common/config'
import * as constants from '../constants'
import * as formatters from '../../../common/formatters'
import { getFirstExistValue, getTransportPointPlannedDatetimeFrom, getTransportPointPlannedDatetimeTo } from '../../../common/helpers'
import { ColoredBox } from '../../../common/colored_box'

import * as transportsActionCreators from '../actionCreators'
import { getTransportPreview } from '../selectors'

import TransportListRowStateIcon from './TransportsListRowStateIcon'
import { HEREMapRoutes } from '../../../common/here_maps'
import Immutable from 'immutable'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS, PERMS_CUSTOMER, PERMS_COST } from '../permissions'

class TransportsPreview extends Component {
    state = {
        transportPreview: null,
        panels: {},
    }

    setTransportPreview = transportPreview => {
        this.setState({
            transportPreview,
        })
    }

    handleTogglePanel = (e, index, show) => {
        e && e.preventDefault()

        const panel = this.state.panels[index] ? { ...this.state.panels[index] } : {}
        panel.show = show || !(this.state.panels[index] && this.state.panels[index].show)

        this.setState({
            panels: {
                ...this.state.panels,
                [index]: panel,
            },
        })
    }

    handleTogglePanelEvents = (e, index, show) => {
        e && e.preventDefault()

        const panel = this.state.panels[index] ? { ...this.state.panels[index] } : {}
        panel.show = true
        panel.showEvents = show || !(this.state.panels[index] && this.state.panels[index].showEvents)

        this.setState({
            panels: {
                ...this.state.panels,
                [index]: panel,
            },
        })
    }

    handleTogglePanelNaviEvents = (e, index, show) => {
        e && e.preventDefault()

        const panel = this.state.panels[index] ? { ...this.state.panels[index] } : {}
        panel.show = true
        panel.showNaviEvents = show || !(this.state.panels[index] && this.state.panels[index].showNaviEvents)

        this.setState({
            panels: {
                ...this.state.panels,
                [index]: panel,
            },
        })
    }

    /* eslint-disable max-len */

    getIconSVG = (text, bgColor = '#ffffff', textColor = '#000000', fontSize = '14px') => (
        <svg xmlns="http://www.w3.org/2000/svg" width="28px" height="30px">
            <path
                d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z"
                fill="#ffffff"
            />
            <path
                d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z"
                fill={bgColor}
            />
            <text x="13" y="19" fontSize={fontSize} fontWeight="bold" textAnchor="middle" fill={textColor} style={{ fontFamily: 'sans-serif' }}>
                {text}
            </text>
        </svg>
    )

    getVehicleIconSVG = (vehicle, bgColor = '#ffffff', textColor = '#000000', size = 60) => (
        <svg
            version="1.2"
            baseProfile="tiny"
            width={size}
            height={size}
            viewBox="0 0 100.31094360351562 61.11110305786133"
            overflow="inherit"
            xmlns="http://www.w3.org/2000/svg"
        >
            ` +
            <path
                className="cls-1"
                d="M 98.414 0 L 1.404 0 C 0.631 0.004 0.002 0.955 0 2.127 L 0 46.381 C 0.002 47.555 0.631 48.504 1.404 48.507 L 37.867 48.594 L 49.912 59.991 L 61.128 48.505 L 98.419 48.507 C 99.194 48.504 99.819 47.555 99.824 46.381 L 99.824 2.135 C 99.824 0.955 99.194 0 98.414 0 Z"
                style={{
                    fill: bgColor,
                }}
                stroke="black"
            />` +
            <text x="50" y="30" style={{ fontFamily: 'sans-serif', fontSize: '18px', textAnchor: 'middle', whiteSpace: 'pre', fill: textColor }}>
                {vehicle}
            </text>
        </svg>
    )

    /* eslint-enable max-len */

    componentDidMount() {
        if (this.props.transportId) {
            this.props.actions.fetchTransportPreview(this.props.transportId)
        }

        if (this.props.transportPointId) {
            this.handleTogglePanelEvents(null, this.props.transportPointId, true)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.transportId && nextProps.transportId !== this.props.transportId) {
            this.props.actions.fetchTransportPreview(nextProps.transportId)
        }

        if (nextProps.transportPointId && nextProps.transportPointId !== this.props.transportPointId) {
            this.handleTogglePanelEvents(null, nextProps.transportPointId, true)
        }

        if (
            nextProps.transportPreview &&
            (!this.state.transportPreview || JSON.stringify(nextProps.transportPreview) !== JSON.stringify(this.props.transportPreview))
        ) {
            this.setTransportPreview(nextProps.transportPreview)
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const transport = this.state.transportPreview

        if (!transport) {
            return null
        }

        const pointTypes = constants.TRANSPORT_POINT_TYPES_DEFINITION
        const eventTypes = constants.TRANSPORT_EVENT_TYPES_DEFINITION
        const eventDataTypes = constants.TRANSPORT_EVENT_DATA_TYPES_DEFINITION

        const transportPoints = []
        if (transport.transport_start) {
            transportPoints.push(transport.transport_start)
        }
        transport.transport_points.forEach(point => {
            transportPoints.push(point)
        })

        const driverNames = []
        transport.drivers.forEach(driver => {
            driverNames.push(driver.name)
        })

        const panels = this.state.panels
        const showPredepartureCheck = panels[0] && panels[0].show

        let markers = {}
        transportPoints.forEach((point, key) => {
            if (point.gps_lat && point.gps_lng) {
                const isTransportStart = transport.transport_start && transport.transport_start.id === point.id
                const pointType = point.type_id && pointTypes[point.type_id]
                const iconSvg = isTransportStart
                    ? this.getIconSVG('N', '#999999', '#ffffff')
                    : this.getIconSVG(key + 1, pointType && pointType.rgb_bg, pointType && pointType.rgb_text)

                markers[point.id] = {
                    center: {
                        lat: point.gps_lat,
                        lng: point.gps_lng,
                    },
                    iconSvg,
                    zIndex: 1,
                    size: { w: 30, h: 30 },
                    anchor: { x: 15, y: 30 },
                }
            }
        })
        transport.vehicles.forEach(vehicle => {
            if (vehicle.position) {
                markers[vehicle.id] = {
                    center: {
                        lat: vehicle.position.gps_lat,
                        lng: vehicle.position.gps_lng,
                    },
                    iconSvg: this.getVehicleIconSVG(
                        vehicle.registration,
                        vehicle.dispatcher && vehicle.dispatcher.rgb_bg ? vehicle.dispatcher.rgb_bg : '#999999',
                        vehicle.dispatcher && vehicle.dispatcher.rgb_text ? vehicle.dispatcher.rgb_text : '#ffffff'
                    ),
                    zIndex: 1,
                    size: { w: 60, h: 60 },
                    anchor: { x: 30, y: 48 },
                }
            }
        })
        markers = Immutable.Map(markers)

        const routes = []
        if (transport.routes.planned) {
            routes.push({
                waypoints: transport.routes.planned.waypoints,
                color: '#7a6fbe',
                showRoute: true,
                showWaypoints: false,
                showArrows: false,
            })
        }
        if (transport.routes.specified) {
            routes.push({
                waypoints: transport.routes.specified.waypoints,
                color: '#22BAA0',
                showRoute: true,
                showWaypoints: false,
                showArrows: false,
            })
        }
        if (transport.routes.real) {
            routes.push({
                waypoints: transport.routes.real.waypoints,
                color: '#000000',
                arrowsColor: '#000000',
                showRoute: true,
                showWaypoints: true,
                showArrows: false,
            })
        }

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)}>
                <div>
                    <h2>
                        <FormattedMessage id="fields.transportNo" /> <strong>{transport.id}</strong>
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <Link to={`/transports/${transport.id}`} className="btn btn-addon btn-default pull-right">
                                <i className="far fa-pencil" /> <FormattedMessage id="buttons.openEdit" />
                            </Link>
                        </PermissionsCheck>
                    </h2>

                    <div className="b-t m-b-md" />

                    <div className="row">
                        <div className="col-md-6">
                            <div className="row m-b-xs">
                                <div className="col-md-3 text-gray">
                                    <FormattedMessage id="fields.transportState" />:
                                </div>
                                <div className="col-md-8">{transport.state && <FormattedMessage id={`transportStates.${transport.state.name}`} />}</div>
                            </div>
                            <div className="row m-b-xs">
                                <div className="col-md-3 text-gray">
                                    <FormattedMessage id="fields.disponent" />:
                                </div>
                                <div className="col-md-8">{transport.disponent && transport.disponent.name}</div>
                            </div>
                            <div className="row m-b-xs">
                                <div className="col-md-3 text-gray">
                                    <FormattedMessage id="fields.transportType" />:
                                </div>
                                <div className="col-md-8">{transport.type && transport.type.name}</div>
                            </div>
                            <div className="row m-b-xs">
                                <div className="col-md-3 text-gray">
                                    <FormattedMessage id="fields.cargoType" />:
                                </div>
                                <div className="col-md-8">{transport.cargo_type && transport.cargo_type.name}</div>
                            </div>
                            <div className="row m-b-xs">
                                <div className="col-md-3 text-gray">
                                    <FormattedMessage id="fields.goodsType" />:
                                </div>
                                <div className="col-md-8">{transport.goods_type && transport.goods_type.name}</div>
                            </div>
                            <div className="row m-b-xs">
                                <div className="col-md-3 text-gray">
                                    <FormattedMessage id="fields.note" />:
                                </div>
                                <div className="col-md-8">{transport.note}</div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            {transport.transport_customers.map(transportCustomer => (
                                <div key={`transportCustomer-${transportCustomer.id}`}>
                                    <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER)}>
                                        <div className="row m-b-xs">
                                            <div className="col-md-3 text-gray">
                                                <FormattedMessage id="fields.customer" />:
                                            </div>
                                            <div className="col-md-9">
                                                <strong>{transportCustomer.customer && transportCustomer.customer.name}</strong>
                                            </div>
                                        </div>
                                    </PermissionsCheck>
                                    <div className="row m-b-xs">
                                        <div className="col-md-3 text-gray">
                                            <FormattedMessage id="fields.orderNumber" />:
                                        </div>
                                        <div className="col-md-9">{transportCustomer.order_number}</div>
                                    </div>
                                    <PermissionsCheck hasAny={Object.values(PERMS_COST)}>
                                        <div className="row m-b-xs">
                                            <div className="col-md-3 text-gray">
                                                <FormattedMessage id="fields.price" />:
                                            </div>
                                            <div className="col-md-9">
                                                {formatters.priceFormatter(
                                                    transportCustomer.price,
                                                    '0,0',
                                                    transportCustomer.currency ? transportCustomer.currency.iso_code : ''
                                                )}
                                            </div>
                                        </div>
                                    </PermissionsCheck>
                                </div>
                            ))}

                            <div className="row m-b-xs">
                                <div className="col-md-3 text-gray">
                                    <FormattedMessage id="fields.vehicle" />:
                                </div>
                                <div className="col-md-8">
                                    {transport.vehicles.map(vehicle => (
                                        <div key={`vehicle-${vehicle.id}`} className="pull-left m-r-xs">
                                            <ColoredBox
                                                bgRGB={vehicle.dispatcher && vehicle.dispatcher.rgb_bg}
                                                textRGB={vehicle.dispatcher && vehicle.dispatcher.rgb_text}
                                            >
                                                {vehicle.registration}
                                            </ColoredBox>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="row m-b-xs">
                                <div className="col-md-3 text-gray">
                                    <FormattedMessage id="fields.trailer" />:
                                </div>
                                <div className="col-md-8">
                                    {transport.trailers.map(trailer => (
                                        <div key={`trailer-${trailer.id}`} className="pull-left m-r-xs">
                                            <ColoredBox>{trailer.registration}</ColoredBox>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="row m-b-xs">
                                <div className="col-md-3 text-gray">
                                    <FormattedMessage id="fields.driver" />:
                                </div>
                                <div className="col-md-8">{driverNames.join(', ')}</div>
                            </div>
                        </div>
                    </div>

                    <div className="transport-waypoints m-t-md">
                        {transport.is_predeparture_check && (
                            <div className="panel panel-predeparture-check">
                                <div className="panel-heading">
                                    <span className="pull-left">
                                        <a
                                            href="#"
                                            className="m-r-md"
                                            onClick={e => {
                                                this.handleTogglePanel(e, 0)
                                            }}
                                        >
                                            <i className={`far ${showPredepartureCheck ? 'fa-minus' : 'fa-plus'}`} />
                                        </a>
                                        <span>
                                            <FormattedMessage id="fields.predepartureCheck" />
                                        </span>
                                    </span>
                                </div>

                                {transport.predeparture_checks &&
                                    transport.predeparture_checks.length > 0 && (
                                        <Collapse in={showPredepartureCheck}>
                                            <div className="panel-body">
                                                <table className="table table-no-border no-m">
                                                    <thead>
                                                        <tr>
                                                            <th className="w-140">
                                                                <FormattedMessage id="fields.time" />
                                                            </th>
                                                            <th className="w-100">
                                                                <FormattedMessage id="fields.vehicle" />
                                                            </th>
                                                            <th className="w-140">
                                                                <FormattedMessage id="fields.driver" />
                                                            </th>
                                                            <th />
                                                            <th className="w-180 text-center">
                                                                <FormattedMessage id="fields.gps" />
                                                            </th>
                                                            <th className="w-80 text-right">
                                                                <FormattedMessage id="fields.attachments" />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transport.predeparture_checks.map(event => (
                                                            <tr key={`event-${event.id}`}>
                                                                <td>{event.datetime && formatters.datetimeFormatter(event.datetime)}</td>
                                                                <td>{event.vehicle && event.vehicle.registration}</td>
                                                                <td>{event.driver && event.driver.name}</td>
                                                                <td>
                                                                    {event.data.map((item, key) => {
                                                                        const type = eventDataTypes[item.type_id]
                                                                        return (
                                                                            <span key={`event-data-${item.id}`}>
                                                                                {key > 0 && ', '}
                                                                                {type &&
                                                                                    `${this.props.intl.formatMessage({
                                                                                        id: type.label,
                                                                                    })}: `}
                                                                                {type && type.formatter ? type.formatter(item.value) : item.value}
                                                                                {type && type.unit ? ` ${type.unit}` : ''}
                                                                            </span>
                                                                        )
                                                                    })}
                                                                </td>
                                                                <td className="text-center">{formatters.gpsFormatter(event.gps_lat, event.gps_lng)}</td>
                                                                <td className="text-right">
                                                                    {event.files.map(
                                                                        file =>
                                                                            file.filename ? (
                                                                                <a
                                                                                    key={`event-file-${file.id}`}
                                                                                    href={`${config.CFG_API_BASE_URL}/files/transport-documents/${
                                                                                        file.filename
                                                                                    }`}
                                                                                    className="text-gray m-l-xxs"
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    <i className="far fa-file-image" />
                                                                                </a>
                                                                            ) : (
                                                                                <span key={file.id} className="text-default m-l-xxs">
                                                                                    <i className="far fa-file-image" />
                                                                                </span>
                                                                            )
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Collapse>
                                    )}
                            </div>
                        )}

                        {transportPoints.map(point => {
                            const isTransportStart = transport.transport_start && transport.transport_start.id === point.id

                            const show = panels[point.id] && panels[point.id].show
                            const showEvents = panels[point.id] && panels[point.id].showEvents
                            const showNaviEvents = panels[point.id] && panels[point.id].showNaviEvents

                            const pointType = pointTypes[point.type_id]

                            const plannedDatetimeFrom = getTransportPointPlannedDatetimeFrom(point)
                            const plannedDatetimeTo = getTransportPointPlannedDatetimeTo(point)
                            const datetime = getFirstExistValue([point.datetime_finished, point.datetime_arrival, plannedDatetimeFrom])

                            const arrivalClassName =
                                point.arrivedAt && plannedDatetimeTo && (point.arrivedAt.isAfter(plannedDatetimeTo) ? 'text-danger' : 'text-success')

                            const eta =
                                (!point.state || point.state.id === constants.TRANSPORT_POINT_STATE_ON_WAY) &&
                                point.last_eta_update &&
                                point.last_eta_update.datetime_real_eta &&
                                moment(point.last_eta_update.datetime_real_eta)
                            const etaClassName = eta && plannedDatetimeTo && (eta.isAfter(plannedDatetimeTo) ? 'text-danger' : 'text-success')

                            const pointData = point.data.map(dataItem => {
                                let value = dataItem.value !== undefined ? dataItem.value : ''
                                if (
                                    pointTypes[point.type_id].dataFields[dataItem.type_id] &&
                                    pointTypes[point.type_id].dataFields[dataItem.type_id].type === 'bool'
                                ) {
                                    value = parseInt(dataItem.value)
                                }
                                return {
                                    ...dataItem,
                                    ...pointTypes[point.type_id].dataFields[dataItem.type_id],
                                    value,
                                }
                            })

                            const events = point.events
                            const naviEvents = point.navi_events

                            return (
                                <div
                                    key={`point-${point.id}`}
                                    className={`panel ${isTransportStart ? 'panel-start' : `panel-${pointType && pointType.className}`}`}
                                >
                                    <div className="panel-heading">
                                        <span className="pull-left">
                                            <a
                                                href="#"
                                                className="m-r-md"
                                                style={{ visibility: `${pointType.noDetails ? 'hidden' : 'visible'}` }}
                                                onClick={e => {
                                                    this.handleTogglePanel(e, point.id)
                                                }}
                                            >
                                                <i className={`far ${show ? 'fa-minus' : 'fa-plus'}`} />
                                            </a>
                                            <span className="state">
                                                {point.state ? <TransportListRowStateIcon state={point.state} /> : <i className="far fa-circle" />}
                                            </span>
                                            <span className="type">
                                                {isTransportStart ? (
                                                    <FormattedMessage id="fields.approach" />
                                                ) : (
                                                    <span>{pointType && this.props.intl.formatMessage({ id: pointType.name })}</span>
                                                )}
                                            </span>
                                            {point.city && `${point.country && point.country.iso_code} / ${point.zipcode} / ${point.city}`}
                                            {isTransportStart && (
                                                <span className="m-l-xs">
                                                    (<FormattedMessage id="fields.transportNo" /> {point.transport_id})
                                                </span>
                                            )}
                                        </span>
                                        <span className="pull-right">
                                            <span className="m-l-lg w-120 display-inline">{datetime && formatters.datetimeFormatter(datetime)}</span>
                                        </span>
                                    </div>

                                    {!pointType.noDetails && (
                                        <Collapse in={show}>
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <span className="text-gray-light">
                                                            <FormattedMessage id="fields.address" />:
                                                        </span>
                                                        <p>
                                                            {point.name && (
                                                                <span>
                                                                    <strong>{point.name}</strong>
                                                                    <br />
                                                                </span>
                                                            )}
                                                            {point.street && `${point.street},`}
                                                            <br />
                                                            {point.zipcode} {point.city && `${point.city},`}
                                                            <br />
                                                            {point.country && point.country.iso_code}
                                                            <br />
                                                            {point.gps_lat &&
                                                                point.gps_lng &&
                                                                `${this.props.intl.formatMessage({ id: 'fields.gps' })}: ${formatters.gpsFormatter(
                                                                    point.gps_lat,
                                                                    point.gps_lng
                                                                )}`}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <span className="text-gray-light">
                                                            <FormattedMessage id="fields.times" />:
                                                        </span>
                                                        <p className="times">
                                                            <span className="left">
                                                                <FormattedMessage id="fields.planned" />:
                                                            </span>{' '}
                                                            {formatters.datetimePeriodFormatter(point.datetime_planned_from, point.datetime_planned_to)}
                                                            <br />
                                                            <span className="left">
                                                                <FormattedMessage id="fields.specified" />:
                                                            </span>{' '}
                                                            {formatters.datetimePeriodFormatter(
                                                                point.datetime_specified_from,
                                                                point.datetime_specified_to
                                                            )}
                                                            <br />
                                                            {pointType.showBookingTime && (
                                                                <span>
                                                                    <span className="left">
                                                                        <FormattedMessage id="fields.booking" />:
                                                                    </span>{' '}
                                                                    <span>
                                                                        <strong>
                                                                            {formatters.datetimePeriodFormatter(
                                                                                point.datetime_booking_from,
                                                                                point.datetime_booking_to
                                                                            )}
                                                                        </strong>
                                                                    </span>
                                                                    <br />
                                                                </span>
                                                            )}
                                                            {eta && (
                                                                <span>
                                                                    <span className="left">
                                                                        <FormattedMessage id="fields.eta" />:
                                                                    </span>
                                                                    <span>
                                                                        <span className={etaClassName}> {formatters.datetimeFormatter(eta) || '-'}</span>
                                                                        {point.last_eta_update.distance &&
                                                                            ` (${formatters.distanceFormatter(point.last_eta_update.distance / 1000)})`}
                                                                    </span>
                                                                    <br />
                                                                </span>
                                                            )}
                                                            <span className="left">
                                                                <FormattedMessage id="fields.arrival" />:
                                                            </span>
                                                            <span className={arrivalClassName}>
                                                                {' '}
                                                                {formatters.datetimeFormatter(point.datetime_arrival) || '-'}
                                                            </span>
                                                            <br />
                                                            <span className="left">
                                                                <FormattedMessage id="fields.finish" />:
                                                            </span>{' '}
                                                            {formatters.datetimeFormatter(point.datetime_finished) || '-'}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <span className="text-gray-light">
                                                            <FormattedMessage id="fields.details" />:
                                                        </span>
                                                        <p className="cargo">
                                                            {pointData &&
                                                                pointData.map(item => (
                                                                    <span key={`point-data-${item.id}`}>
                                                                        <span className="left">{this.props.intl.formatMessage({ id: item.label })}:</span>
                                                                        {item.value !== '' && (
                                                                            <span className="right">
                                                                                {item.formatter ? item.formatter(item.value) : item.value}
                                                                                {item.unit ? ` ${item.unit}` : ''}
                                                                            </span>
                                                                        )}
                                                                        <br />
                                                                    </span>
                                                                ))}
                                                        </p>
                                                    </div>
                                                </div>

                                                {point.note && (
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p className="m-b-xxs">
                                                                <span className="text-gray-light m-r-xxs">
                                                                    <FormattedMessage id="fields.note" />:
                                                                </span>
                                                                {point.note}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p className="m-b-xxs">
                                                            {point.state && (
                                                                <span className="m-r-lg">
                                                                    <span className="text-gray-light m-r-xxs m-r-xxs">
                                                                        <FormattedMessage id="fields.state" />:
                                                                    </span>
                                                                    {this.props.intl.formatMessage({ id: `transportPointStates.${point.state.name}` })}
                                                                    <span className="m-l-xxs m-r-xxs">
                                                                        <TransportListRowStateIcon state={point.state} />
                                                                    </span>
                                                                </span>
                                                            )}

                                                            {events.length > 0 && (
                                                                <span className="m-r-lg">
                                                                    {showEvents ? (
                                                                        <a
                                                                            href="#"
                                                                            onClick={e => {
                                                                                this.handleTogglePanelEvents(e, point.id)
                                                                            }}
                                                                            className="no-text-decoration"
                                                                        >
                                                                            <FormattedMessage id="buttons.hideEvents" /> ({events.length}){' '}
                                                                            <i className="fal fa-angle-up" />
                                                                        </a>
                                                                    ) : (
                                                                        <a
                                                                            href="#"
                                                                            onClick={e => {
                                                                                this.handleTogglePanelEvents(e, point.id)
                                                                            }}
                                                                            className="no-text-decoration"
                                                                        >
                                                                            <FormattedMessage id="buttons.showEvents" /> ({events.length}){' '}
                                                                            <i className="fal fa-angle-down" />
                                                                        </a>
                                                                    )}
                                                                </span>
                                                            )}

                                                            {naviEvents.length > 0 && (
                                                                <span>
                                                                    {showNaviEvents ? (
                                                                        <a
                                                                            href="#"
                                                                            onClick={e => {
                                                                                this.handleTogglePanelNaviEvents(e, point.id)
                                                                            }}
                                                                            className="no-text-decoration"
                                                                        >
                                                                            <FormattedMessage id="buttons.hideNaviEvents" /> ({naviEvents.length}){' '}
                                                                            <i className="fal fa-angle-up" />
                                                                        </a>
                                                                    ) : (
                                                                        <a
                                                                            href="#"
                                                                            onClick={e => {
                                                                                this.handleTogglePanelNaviEvents(e, point.id)
                                                                            }}
                                                                            className="no-text-decoration"
                                                                        >
                                                                            <FormattedMessage id="buttons.showNaviEvents" /> ({naviEvents.length}){' '}
                                                                            <i className="fal fa-angle-down" />
                                                                        </a>
                                                                    )}
                                                                </span>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>

                                                {events.length ? (
                                                    <div className="row">
                                                        <Collapse in={showEvents}>
                                                            <div className="col-md-12 m-t-sm">
                                                                <table className="table table-no-border no-m">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="w-140">
                                                                                <FormattedMessage id="fields.time" />
                                                                            </th>
                                                                            <th className="w-100">
                                                                                <FormattedMessage id="fields.vehicle" />
                                                                            </th>
                                                                            <th className="w-140">
                                                                                <FormattedMessage id="fields.driver" />
                                                                            </th>
                                                                            <th className="w-200">
                                                                                <FormattedMessage id="fields.event" />
                                                                            </th>
                                                                            <th />
                                                                            <th className="w-180 text-center">
                                                                                <FormattedMessage id="fields.gps" />
                                                                            </th>
                                                                            <th className="w-80 text-right">
                                                                                <FormattedMessage id="fields.attachments" />
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {events.map(event => {
                                                                            if (!eventTypes[parseInt(event.type_id)]) {
                                                                                return null
                                                                            }

                                                                            const tooltipEventSource = (
                                                                                <Tooltip id="tooltipEventSource">
                                                                                    {this.props.intl.formatMessage({
                                                                                        id: event.fb_tmo_id
                                                                                            ? 'fields.eventCreatedByDriver'
                                                                                            : 'fields.eventCreatedByDispatcher',
                                                                                    })}
                                                                                </Tooltip>
                                                                            )

                                                                            return (
                                                                                <tr key={`point-event-${event.id}`}>
                                                                                    <td>
                                                                                        {formatters.datetimeFormatter(event.datetime)}
                                                                                        <span className="pull-right w-20 text-center">
                                                                                            <OverlayTrigger placement="top" overlay={tooltipEventSource}>
                                                                                                {event.fb_tmo_id ? (
                                                                                                    <i className="far fa-car text-gray-lighter" />
                                                                                                ) : (
                                                                                                    <i className="far fa-user text-gray-lighter" />
                                                                                                )}
                                                                                            </OverlayTrigger>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>{event.vehicle && event.vehicle.registration}</td>
                                                                                    <td>{event.driver && event.driver.name}</td>
                                                                                    <td>
                                                                                        {this.props.intl.formatMessage({
                                                                                            id: eventTypes[parseInt(event.type_id)].name,
                                                                                        })}
                                                                                    </td>
                                                                                    <td>
                                                                                        {event.data.map((item, key) => {
                                                                                            const type = eventDataTypes[item.type_id]
                                                                                            return (
                                                                                                <span key={item.id}>
                                                                                                    {key > 0 && ', '}
                                                                                                    {type &&
                                                                                                        `${this.props.intl.formatMessage({
                                                                                                            id: type.label,
                                                                                                        })}: `}
                                                                                                    {type && type.formatter
                                                                                                        ? type.formatter(item.value)
                                                                                                        : item.value}
                                                                                                    {type && type.unit ? ` ${type.unit}` : ''}
                                                                                                </span>
                                                                                            )
                                                                                        })}
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        {formatters.gpsFormatter(event.gps_lat, event.gps_lng)}
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {event.files.map(
                                                                                            file =>
                                                                                                file.filename ? (
                                                                                                    <a
                                                                                                        key={file.id}
                                                                                                        href={`${
                                                                                                            config.CFG_API_BASE_URL
                                                                                                        }/files/transport-documents/${file.filename}`}
                                                                                                        className="text-gray m-l-xxs"
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                    >
                                                                                                        <i className="far fa-file-image" />
                                                                                                    </a>
                                                                                                ) : (
                                                                                                    <span key={file.id} className="text-default m-l-xxs">
                                                                                                        <i className="far fa-file-image" />
                                                                                                    </span>
                                                                                                )
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Collapse>
                                                    </div>
                                                ) : null}

                                                {naviEvents.length ? (
                                                    <div className="row">
                                                        <Collapse in={showNaviEvents}>
                                                            <div className="col-md-12 m-t-sm">
                                                                <table className="table table-no-border no-m">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="w-140">
                                                                                <FormattedMessage id="fields.time" />
                                                                            </th>
                                                                            <th className="w-100">
                                                                                <FormattedMessage id="fields.vehicle" />
                                                                            </th>
                                                                            <th className="w-max">
                                                                                <FormattedMessage id="fields.event" />
                                                                            </th>
                                                                            <th className="w-180 text-center">
                                                                                <FormattedMessage id="fields.gps" />
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {naviEvents.map(event => (
                                                                            <tr key={event.id}>
                                                                                <td>{formatters.datetimeFormatter(event.datetime)}</td>
                                                                                <td>{event.vehicle && event.vehicle.registration}</td>
                                                                                <td>
                                                                                    {event.type_id === 1 ? (
                                                                                        <i className="far fa-toggle-on text-success m-r-xs" />
                                                                                    ) : (
                                                                                        <i className="far fa-toggle-off text-danger m-r-xs" />
                                                                                    )}
                                                                                    {this.props.intl.formatMessage({
                                                                                        id:
                                                                                            event.type_id === 1
                                                                                                ? 'fields.navigationStart'
                                                                                                : 'fields.navigationStop',
                                                                                    })}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {formatters.gpsFormatter(event.gps_lat, event.gps_lng)}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Collapse>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Collapse>
                                    )}
                                </div>
                            )
                        })}
                    </div>

                    <div className="p-v-sm">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <HEREMapRoutes
                                    appId={config.CFG_HERE_MAPS_APP_ID}
                                    appCode={config.CFG_HERE_MAPS_APP_CODE}
                                    routes={routes}
                                    markers={markers}
                                    className="h-700"
                                    showPOIs
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        transportPreview: getTransportPreview(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TransportsPreview))
