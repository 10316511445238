import React from 'react'
import { Route, Switch } from 'react-router'
import PackagingTypesList from './PackagingTypesList'
import PackagingTypesEdit from './PackagingTypesEdit'

const PackagingTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={PackagingTypesList} />
        <Route exact path={`${props.match.url}/new`} component={PackagingTypesEdit} />
        <Route exact path={`${props.match.url}/:packagingTypeID`} component={PackagingTypesEdit} />
    </Switch>
)

export default PackagingTypes
