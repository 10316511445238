import React from 'react'
import { Route, Switch } from 'react-router'
import MapPage from './MapPage'

const Map = props => (
    <Switch>
        <Route exact path={props.match.url} component={MapPage} />
    </Switch>
)

export default Map
