import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { warehousingIssueSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import * as customersActionCreators from '../customers/actionCreators'
import * as warehousesActionCreators from '../warehousing_warehouses/actionCreators'
import * as currenciesActionCreators from '../currencies/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import * as actionCreators from './actionCreators'
import * as actionTypes from './actionTypes'
import { WarehousingIssue } from './model'
import { Customer } from "../customers/model";
import { Currency } from "../currencies/model";
import { WarehousingWarehouse } from "../warehousing_warehouses/model";

// Fetch

const fetchWarehousingIssuesEpic = (action$, store) => {
    const tableIdentifier = 'warehousing_issues_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `warehousing-issues/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(warehousingIssueSchema)
                        )

                        const issues = normalizedEntitiesToRecordMap(normalizedData.entities.warehousing_issues, WarehousingIssue, normalizedData.result)
                        const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)
                        const currencies = normalizedEntitiesToRecordMap(normalizedData.entities.currencies, Currency)
                        const warehouses = normalizedEntitiesToRecordMap(normalizedData.entities.warehousing_warehouses, WarehousingWarehouse)

                        const observables = [
                            Observable.of(actionCreators.fetchWarehousingIssuesFulfilled(issues)),
                            Observable.of(customersActionCreators.fetchCustomersFulfilled(customers)),
                            Observable.of(currenciesActionCreators.fetchCurrenciesFulfilled(currencies)),
                            Observable.of(warehousesActionCreators.fetchWarehousingWarehousesFulfilled(warehouses)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchWarehousingIssuesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchWarehousingIssueEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().warehousingIssues.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `warehousing-issues/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, warehousingIssueSchema)

                        const warehousingIssues = normalizedEntitiesToRecordMap(normalizedData.entities.warehousing_issues, WarehousingIssue)
                        const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)
                        const currencies = normalizedEntitiesToRecordMap(normalizedData.entities.currencies, Currency)
                        const warehouses = normalizedEntitiesToRecordMap(normalizedData.entities.warehousing_warehouses, WarehousingWarehouse)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchWarehousingIssuesFulfilled(warehousingIssues)),
                            Observable.of(actionCreators.fetchWarehousingIssueFulfilled()),
                            Observable.of(customersActionCreators.fetchCustomersFulfilled(customers)),
                            Observable.of(currenciesActionCreators.fetchCurrenciesFulfilled(currencies)),
                            Observable.of(warehousesActionCreators.fetchWarehousingWarehousesFulfilled(warehouses)),
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchWarehousingIssueRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveWarehousingIssueEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().warehousingIssues.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = { ...action.payload }

            let path = `warehousing-issues`
            let method = 'POST'

            if (values.id) {
                path = `warehousing-issues/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, warehousingIssueSchema)

                        const warehousingIssues = normalizedEntitiesToRecordMap(normalizedData.entities.warehousing_issues, WarehousingIssue)
                        const warehousingIssue = warehousingIssues.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveWarehousingIssueFulfilled(warehousingIssue)))
                    })
                    .catch(error => Observable.of(actionCreators.saveWarehousingIssueRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteWarehousingIssueEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().warehousingIssues.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `warehousing-issues/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteWarehousingIssueFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteWarehousingIssueRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Export

const exportWarehousingIssuesEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `warehousing-issues/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Fetch item autocomplete

const fetchItemAutocompleteEpic = (action$, store) =>
    action$
    .ofType(actionTypes.FETCH_ITEM_AUTOCOMPLETE)
    .filter(() => !store.getState().warehousingIssues.getIn(['warehousingItem', 'fetching']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_ITEM_AUTOCOMPLETE_STARTED,
            }),
            request({
                path: `warehousing-issues/item-autocomplete`,
                method: 'POST',
                body: action.payload
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchItemAutocompleteFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchItemAutocompleteRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_ITEM_AUTOCOMPLETE_CANCELLED))
        )
    )

export default [
    fetchWarehousingIssuesEpic,
    fetchWarehousingIssueEpic,
    saveWarehousingIssueEpic,
    deleteWarehousingIssueEpic,
    exportWarehousingIssuesEpic,
    fetchItemAutocompleteEpic
]
