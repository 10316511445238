import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Modal } from 'react-bootstrap'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'

import * as config from '../../../common/config'
import * as formatters from '../../../common/formatters'
import { HEREMapTracing } from '../../../common/here_maps'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { getPrerequisitesArray, getPrerequisitesPairs, convertCSDatetimeToMoment, validationMessage } from '../../../common/helpers'
import { Form, InputField, SelectField, CustomerPicker } from '../../../common/form'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as tracingActionCreators from '../actionCreators'
import * as selectors from '../selectors'
import { loadCustomerInfo } from '../../invoicing_invoices/actionCreators'
import { getCustomerInfo, getCustomerInfoFetching } from '../../invoicing_invoices/selectors'
import { findCustomerAddresses, saveCustomerAddress } from '../../customer_address/actionCreators'
import {
    getFoundAddresses,
    getFoundAddressesFetching,
    getFoundAddressesError,
    createGetCurrentCustomerAddressStatusSelector,
    createGetDuplicitySelector,
} from '../../customer_address/selectors'
import { checkCustomers } from '../../transports/actionCreators'
import { getCustomersCheckingData } from '../../transports/selectors'

import CustomerAddressesPicker from '../../customer_address/components/CustomerAddressesPicker'
import TracingPoint from './TracingPoint'
import TracingDetail from './TracingDetail'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {CustomDatePicker} from "../../../common/custom_date_picker";

const componentIdentifier = 'tracing'
const prerequisites = ['transport_types', 'customers', 'carrier_vehicle_registration', 'vehicles', 'currencies_with_rate', 'detailed_vehicle_types']

const SortableItem = SortableElement(
    ({ id, isActive, tracingPoint, onChange, onSubmit, onSelect, onDelete, onSelectMarker, openAddressesPicker }) => (
        <TracingPoint
            id={id}
            isActive={isActive}
            tracingPoint={tracingPoint}
            onChange={onChange}
            onSubmit={onSubmit}
            onSelect={onSelect}
            onDelete={onDelete}
            onSelectMarker={onSelectMarker}
            openAddressesPicker={openAddressesPicker}
        />
    )
)
/* eslint-disable jsx-a11y/anchor-is-valid */
const SortableList = SortableContainer(
    ({
        tracingPoints,
        activeTracingPointId,
        onAddressChange,
        onAddressSubmit,
        onAddressSelected,
        onAddressDelete,
        onAddresMarkerSelect,
        addTracingPoint,
        openAddressesPicker,
    }) => (
        <div className="display-inline tracing-list wp-100">
            {tracingPoints.map((tracingPoint, id) => (
                <div key={`sortable-item-${id}`} className="tracing-list-item">
                    <SortableItem
                        // eslint-disable-next-line react/no-array-index-key
                        index={id}
                        id={id + 1}
                        isActive={activeTracingPointId === id}
                        tracingPoint={tracingPoint}
                        onChange={address => onAddressChange(address, id)}
                        onSubmit={address => onAddressSubmit(address, id)}
                        onSelect={(latLng, address) => onAddressSelected(latLng, address, id)}
                        onDelete={() => onAddressDelete(id)}
                        onSelectMarker={() => onAddresMarkerSelect(id)}
                        openAddressesPicker={() => openAddressesPicker(id)}
                    />
                    <div className="tracing-list-add">
                        <a href="#" onClick={() => addTracingPoint(id + 1)} className="text-gray-light">
                            <i className="far fa-plus-circle" />
                        </a>
                    </div>
                </div>
            ))}
        </div>
    )
)

class Tracing extends Component {
    defaultValues = {
        customer_id: null,
        transport_type_id: null,
        vehicle_id: null,
        price: 0,
        currency_id: null,
        currency_rate: 1,
        currency_iso_code: '',
    }

    defaultTracingPoint = {
        address: '',
        lat: null,
        lng: null,
        street: '',
        postalCode: '',
        city: '',
        country: '',
        isSavedAddress: true,
    }

    defaultDatetimes = {
        approach: '',
        loading: '',
        unloading: '',
    }

    state = {
        values: this.defaultValues,
        tracingPoints: [this.defaultTracingPoint],
        datetimes: this.defaultDatetimes,

        activeTracingPointId: 0,
        editingAddressId: null,
        shouldRecalculate: false,

        mapZoom: 7,
        mapCenter: {
            lat: 49.758924,
            lng: 15.546382,
        },

        showDetail: false,
        showAddressesPicker: false,
        showCustomerAddressesFound: false,

        modalSaveAddress: {
            show: false,
            values: {},
        },
        modalSaveAddressDuplicity: {
            show: false,
            duplicity: null,
        },
    }

    validationRules = {}
    saveAddressValidationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    setValues = (values, callback) => {
        let shouldRecalculate = this.state.shouldRecalculate
        const datetimes = { ...this.state.datetimes }

        // Customer
        if (this.state.values.customer_id !== values.customer_id) {
            this.props.actions.checkCustomers([values.customer_id])
            this.props.actions.loadCustomerInfo(values.customer_id)
        }

        // Transport type
        if (this.state.values.transport_type_id !== values.transport_type_id) {
            shouldRecalculate = true
        }

        // Vehicle
        if (this.state.values.vehicle_id !== values.vehicle_id) {
            let selectedVehicle = null
            // Have vehicle id and vehicles list
            if (values.vehicle_id && this.props.vehicles) {
                // eslint-disable-next-line no-unused-vars
                for (const [index, vehicle] of this.props.vehicles.entries()) {
                    if (String(vehicle.id) === values.vehicle_id) {
                        // Selected vehicle found in vehicles
                        selectedVehicle = vehicle
                        break
                    }
                }
            }

            if (selectedVehicle) {
                // Have vehicle
                datetimes.approach = formatters.datetimeFormatter(selectedVehicle.datetime)
                this.props.actions.fetchVehicleFulfilled(selectedVehicle)
            } else if (values.vehicle_id) {
                // Must featch by id
                this.props.actions.fetchVehicle({ vehicleId: values.vehicle_id })
            } else {
                // No vehicle selected
                this.props.actions.fetchVehicleFulfilled(null)
                datetimes.approach = ''
                // Load vehicles
                if (
                    this.state.datetimes.loading !== '' &&
                    this.state.tracingPoints[0] &&
                    this.state.tracingPoints[0].lat &&
                    this.state.tracingPoints[0].lng
                ) {
                    const gps = { lat: this.state.tracingPoints[0].lat, lng: this.state.tracingPoints[0].lng }
                    this.props.actions.fetchVehicles({ gps, loading: this.state.datetimes.loading })
                }
            }

            shouldRecalculate = true
        }

        // Currency
        if (this.state.values.currency_id !== values.currency_id) {
            const currency = this.props.prerequisites.values
                .get('currencies_with_rate')
                .find(val => String(val.get('id')) === String(values.currency_id))
            if (currency) {
                values.currency_rate = currency.get('rate')
                values.currency_name = currency.get('iso_code')
            }
        }

        this.setState(
            {
                values,
                shouldRecalculate,
                datetimes,
            },
            callback
        )
    }

    setTracingPoints = (tracingPoints, activeTracingPointId, callback) => {
        // Load vehicles
        if (
            this.state.datetimes.loading !== '' &&
            this.state.values.vehicle_id === null &&
            tracingPoints[0] &&
            tracingPoints[0].lat &&
            tracingPoints[0].lng &&
            (!this.state.tracingPoints[0] ||
                !this.state.tracingPoints[0].lat ||
                !this.state.tracingPoints[0].lng ||
                tracingPoints[0].lat !== this.state.tracingPoints[0].lat ||
                tracingPoints[0].lng !== this.state.tracingPoints[0].lng)
        ) {
            const gps = { lat: tracingPoints[0].lat, lng: tracingPoints[0].lng }
            this.props.actions.fetchVehicles({ gps, loading: this.state.datetimes.loading })
        }

        this.setState(
            {
                tracingPoints,
                shouldRecalculate: true,
                activeTracingPointId,
            },
            callback
        )
    }

    changeTracingPoint = (tracingPoint, id, activeTracingPointId = null, callback) => {
        const tracingPoints = [...this.state.tracingPoints]
        if (tracingPoints[id]) {
            tracingPoints[id] = {
                ...this.defaultTracingPoint,
                ...tracingPoint,
            }
            this.setTracingPoints(tracingPoints, activeTracingPointId, callback)
        }
    }

    // Form

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleClearForm = () => {
        this.setState({
            values: this.defaultValues,
            datetimes: this.defaultDatetimes,
            tracingPoints: [this.defaultTracingPoint],
            activeTracingPointId: 0,
        })
        this.props.actions.clearRoute()
    }

    handleClearRoute = () => {
        this.setState({ tracingPoints: [this.defaultTracingPoint], activeTracingPointId: 0 })
        this.props.actions.clearRoute()
    }

    handleChangeModalValues = (key, value) => {
        const values = { ...this.state.values, [key]: value }
        this.setValues(values)
    }

    handleSelectFocus = id => {
        this.setState({ activeTracingPointId: this.state.activeTracingPointId === id ? null : id })
    }

    handleSortEnd = ({ oldIndex, newIndex }) => {
        const tracingPoints = arrayMove([...this.state.tracingPoints], oldIndex, newIndex)
        let activeTracingPointId = this.state.activeTracingPointId

        if (oldIndex === activeTracingPointId) {
            activeTracingPointId = newIndex
        } else if (newIndex === activeTracingPointId) {
            activeTracingPointId = newIndex
        }

        this.setTracingPoints(tracingPoints, activeTracingPointId)
    }

    handleShowDetail = showDetail => {
        this.setState({ showDetail })
    }

    // Address picker

    closeAddressesPicker = () => {
        this.setState({ showAddressesPicker: false })
    }

    openAddressesPicker = id => {
        this.setState({ showAddressesPicker: true, editingAddressId: id })
    }

    handleAddressPickerSelect = address => {
        this.closeAddressesPicker()

        const values = {
            address: address.name,
            lat: address.gps_lat,
            lng: address.gps_lng,
            street: address.street,
            postalCode: address.zipcode,
            city: address.city,
            country: address.country ? address.country.iso_code : null,
        }

        const activeTracingPointId = this.state.activeTracingPointId
        const id = this.state.editingAddressId

        this.changeTracingPoint(values, id, activeTracingPointId)
    }

    // Tracing

    addTracingPoint = index => {
        const tracingPoints = [...this.state.tracingPoints]
        const activeTracingPointId =
            this.state.activeTracingPointId === null || this.state.activeTracingPointId < index
                ? this.state.activeTracingPointId
                : this.state.activeTracingPointId + 1
        if (index === undefined) {
            tracingPoints.push(this.defaultTracingPoint)
        } else {
            tracingPoints.splice(index, 0, this.defaultTracingPoint)
        }
        this.setTracingPoints(tracingPoints, activeTracingPointId)
    }

    removeTracingPoint = id => {
        const tracingPoints = [...this.state.tracingPoints]
        let activeTracingPointId = this.state.activeTracingPointId === id ? null : this.state.activeTracingPointId
        activeTracingPointId = activeTracingPointId === null || activeTracingPointId < id ? activeTracingPointId : activeTracingPointId - 1

        tracingPoints.splice(id, 1)

        this.setTracingPoints(tracingPoints, activeTracingPointId)
    }

    calculateRoute = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        const tracingPoints = this.state.tracingPoints.map(({ address, ...tracingPoint }) => tracingPoint)
        this.props.actions.fetch(tracingPoints, this.state.datetimes, this.state.values.transport_type_id, this.state.values.vehicle_id)
        this.setState({
            shouldRecalculate: false,
        })
    }

    // Address

    findAddress = (coord, editingAddressId) => {
        this.setState({ editingAddressId }, () => {
            this.props.actions.findCustomerAddresses({ gps: coord })
        })
    }

    fillAddress = id => {
        if (this.state.tracingPoints[id]) {
            const address = this.state.tracingPoints[id]
            if (address.lat && address.lng && address.city === '') {
                this.setState({ editingAddressId: id }, () => {
                    this.props.actions.fetchAddress({ lat: address.lat, lng: address.lng })
                })
            }
        }
    }

    // Address input

    handleAddressChange = (addressValue, id) => {
        const activeTracingPointId = this.state.activeTracingPointId
        const tracingPoint = { address: addressValue }
        this.changeTracingPoint(tracingPoint, id, activeTracingPointId)
    }

    handleAddressSubmit = (addressValue, id) => {
        const activeTracingPointId = this.state.activeTracingPointId
        const parts = addressValue.split(',')
        if (parts.length === 2) {
            const lat = parseFloat(parts[0])
            const lng = parseFloat(parts[1])
            if (!isNaN(lat) && !isNaN(lng)) {
                // Gps to address
                const coord = { lat, lng }
                const tracingPoint = { ...coord, address: addressValue }
                this.changeTracingPoint(tracingPoint, id, activeTracingPointId, () => {
                    this.findAddress(coord, id)
                })
                return
            }
        }
        // Address to gps
        const tracingPoint = { address: addressValue }
        this.changeTracingPoint(tracingPoint, id, activeTracingPointId, () => {
            this.setState({ editingAddressId: id }, () => {
                this.props.actions.fetchGps(addressValue)
            })
        })
    }

    handleAddressSelected = (coord, addressValue, id) => {
        const activeTracingPointId = this.state.activeTracingPointId
        const tracingPoint = { ...coord, address: addressValue }
        this.changeTracingPoint(tracingPoint, id, activeTracingPointId, () => {
            // Google gps
            this.findAddress(coord, id)
        })
    }

    // Map address

    parseAddressFromMap = address => {
        const resultAddress = address && address.location && address.location.address
        return {
            address: resultAddress.label ? resultAddress.label : '',
            street: resultAddress.street ? resultAddress.street : '',
            postalCode: resultAddress.postalCode ? resultAddress.postalCode : '',
            city: resultAddress.city ? resultAddress.city : '',
            country: resultAddress.country ? resultAddress.country : '',
        }
    }

    handleMapAddTracingPoint = (coord, address) => {
        const tracingPoints = [...this.state.tracingPoints]
        let id = this.state.activeTracingPointId
        const tracingPoint = { ...coord, ...this.parseAddressFromMap(address) }

        if (id !== null) {
            tracingPoints[id] = tracingPoint
        } else {
            id = tracingPoints.length
            tracingPoints.push(tracingPoint)
        }

        this.setTracingPoints(tracingPoints, null, () => {
            this.findAddress(coord, id)
        })
    }

    handleMapChangeTracingPoint = (id, coord, address) => {
        const activeTracingPointId = this.state.activeTracingPointId
        const tracingPoint = { ...coord, ...this.parseAddressFromMap(address) }

        this.changeTracingPoint(tracingPoint, id, activeTracingPointId, () => {
            this.findAddress(coord, id)
        })
    }

    // Map vehicle

    handleMapVehicleSelect = vehicle_id => {
        const values = { ...this.state.values, vehicle_id }
        this.setValues(values)
    }

    // datetimes

    handleDatetimeChange = (key, value) => {
        if (
            key === 'loading' &&
            value !== '' &&
            this.state.values.vehicle_id === null &&
            this.state.tracingPoints[0] &&
            this.state.tracingPoints[0].lat &&
            this.state.tracingPoints[0].lng
        ) {
            const gps = { lat: this.state.tracingPoints[0].lat, lng: this.state.tracingPoints[0].lng }
            this.props.actions.fetchVehicles({ gps, loading: this.state.datetimes.loading })
        }

        const newState = {
            datetimes: {
                ...this.state.datetimes,
                [key]: value,
            },
        }

        this.setState(newState)
    }

    // Address found

    modalCustomerAddressesFoundOpen = () => {
        this.setState({ showCustomerAddressesFound: true })
    }

    modalCustomerAddressesFoundSelectAddress = address => {
        const id = this.state.editingAddressId
        const activeTracingPointId = this.state.activeTracingPointId

        const TracingPoint = {
            address: address.address,
            lat: address.gps_lat,
            lng: address.gps_lng,
            street: address.street,
            postalCode: address.zipcode,
            city: address.city,
            country: address.country_code,
        }

        this.setState({ showCustomerAddressesFound: false }, () => {
            this.changeTracingPoint(TracingPoint, id, activeTracingPointId)
        })
    }

    modalCustomerAddressesFoundClose = () => {
        this.setState({ showCustomerAddressesFound: false }, () => {
            this.fillAddress(this.state.editingAddressId)
        })
    }

    // Save address

    modalCustomerAddressesFoundSaveAsNewAddress = () => {
        this.handleSaveAddress()
        this.modalCustomerAddressesFoundClose()
    }

    closeSaveAddressModal = () => {
        this.setState(
            {
                modalSaveAddress: {
                    show: false,
                    values: {},
                },
            },
            () => {
                this.fillAddress(this.state.editingAddressId)
            }
        )
    }

    handleSaveAddress = () => {
        const id = this.state.editingAddressId
        const values = this.state.tracingPoints[id]
        values.customer_id = this.state.values.customer_id
        this.setState(
            {
                modalSaveAddress: {
                    show: true,
                    values,
                },
            },
            () => {
                this.fillAddress(id)
            }
        )
    }

    handleSaveAddressChange = values => {
        values.is_booking_required = values.is_booking_required_address

        this.setState({
            modalSaveAddress: {
                ...this.state.modalSaveAddress,
                values,
            },
        })
    }

    handleSaveAddressSubmit = () => {
        this.handleSaveAddressSave(false)
    }

    handleSaveAddressSave = ignoreDuplicity => {
        const { country, postalCode, lat, lng, ...others } = this.state.modalSaveAddress.values
        const values = { country_code: country, zipcode: postalCode, gps_lat: lat, gps_lng: lng, ...others }
        this.props.actions.saveCustomerAddress && this.props.actions.saveCustomerAddress(values, ignoreDuplicity)
    }

    // Address duplicity

    handleSaveAddressDuplicity = duplicity => {
        this.setState({
            modalSaveAddressDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleSaveAddressDuplicityCancel = () => {
        this.setState({
            modalSaveAddressDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleSaveAddressDuplicityConfirm = () => {
        this.handleSaveAddressDuplicityCancel()
        this.handleSaveAddressSave(true)
    }

    // Component

    componentDidMount() {
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        // Find gps by address
        if (this.props.gpsFetching && !nextProps.gpsFetching && this.state.editingAddressId !== null && nextProps.gps) {
            const id = this.state.editingAddressId
            const activeTracingPointId = this.state.activeTracingPointId
            const tracingPoints = this.state.tracingPoints

            if (tracingPoints[id]) {
                const tracingPoint = { ...tracingPoints[id], ...nextProps.gps }
                this.changeTracingPoint(tracingPoint, id, activeTracingPointId, () => {
                    this.findAddress(nextProps.gps, id)
                })
            }
        }

        // Find address by gps
        if (this.props.addressFetching && !nextProps.addressFetching && this.state.editingAddressId !== null && nextProps.address) {
            const id = this.state.editingAddressId
            const activeTracingPointId = this.state.activeTracingPointId
            const tracingPoints = this.state.tracingPoints

            if (tracingPoints[id]) {
                const tracingPoint = { ...tracingPoints[id], ...nextProps.address }
                this.changeTracingPoint(tracingPoint, id, activeTracingPointId, () => {
                    if (this.state.modalSaveAddress.show) {
                        this.handleSaveAddressChange(tracingPoint)
                    }
                })
            }
        }

        // Fetch selected vehicle
        if (this.props.vehicleFetching && !nextProps.vehicleFetching && nextProps.vehicle) {
            const datetimes = { ...this.state.datetimes }
            datetimes.approach = formatters.datetimeFormatter(nextProps.vehicle.datetime)
            this.setState({ datetimes })
        }

        // Find customer addresses
        if (this.props.foundCustomerAddressesFetching && !nextProps.foundCustomerAddressesFetching && !nextProps.foundCustomerAddressesError) {
            if (nextProps.foundCustomerAddresses.length) {
                this.modalCustomerAddressesFoundOpen()
            } else {
                // console.log('AddressNotFound')
            }
        }

        // Save address
        if (this.props.customerAddressStatus.get('saving') && !nextProps.customerAddressStatus.get('saving')) {
            if (nextProps.customerAddressStatus.get('error') === null && !nextProps.customerAddressDuplicity) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.addressSaveSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
                this.closeSaveAddressModal()
            } else if (nextProps.customerAddressStatus.get('error') === null && nextProps.customerAddressDuplicity) {
                this.handleSaveAddressDuplicity(nextProps.customerAddressDuplicity)
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.customerAddressStatus.get('error'),
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        if (this.props.routeFetching && !nextProps.routeFetching && nextProps.routeError !== null) {
            this.props.actions.clearRoute()
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: `alerts.titles.error` }),
                    message: nextProps.routeError,
                    position: 'tc',
                },
                'error'
            )
        }
    }

    componentWillUnmount() {
        this.props.actions.clear()
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.tracing.heading' }))

        const customers = getPrerequisitesPairs(this.props.prerequisites.values.get('customers'))
        const transportTypes = getPrerequisitesArray(this.props.prerequisites.values.get('transport_types'))
        const currencies = getPrerequisitesArray(this.props.prerequisites.values.get('currencies_with_rate'), 'id', 'iso_code')

        const route = this.props.route
        const values = this.state.values
        const datetimes = this.state.datetimes
        const tracingPoints = this.state.tracingPoints
        const mapVehicle =
            values.vehicle_id && this.props.vehicle
                ? {
                      ...this.props.vehicle,
                      vehicleType: this.props.prerequisites.values
                          .get('detailed_vehicle_types')
                          .find(val => String(val.get('id')) === String(this.props.vehicle.vehicle_type_id)),
                  }
                : null
        const mapVehicles =
            this.props.vehicles && datetimes.loading !== '' && tracingPoints[0] && tracingPoints[0].lat && tracingPoints[0].lng
                ? [...this.props.vehicles]
                : []
        const activePointId = this.state.activeTracingPointId
        const foundCustomerAddresses = this.props.foundCustomerAddresses
        const customerAddressDuplicity = this.props.customerAddressDuplicity

        const isCustomersBlocked =
            this.props.customersCheckingData &&
            this.props.customersCheckingData[this.state.values.customer_id] &&
            this.props.customersCheckingData[this.state.values.customer_id].is_blocked

        const mapTracingPoints = {}
        tracingPoints.forEach((routePoint, key) => {
            mapTracingPoints[key] = {
                lat: routePoint.lat,
                lng: routePoint.lng,
                bgColor: key === activePointId ? '#ff0000' : '#000000',
                textColor: '#ffffff',
            }
        })

        const canRecalculate = Object.keys(mapTracingPoints).length > 1
        const showCalculatedRoute = route || this.props.routeFetching

        const approach = convertCSDatetimeToMoment(datetimes.approach)
        const loading = convertCSDatetimeToMoment(datetimes.loading)
        const unloading = convertCSDatetimeToMoment(datetimes.unloading)

        const diffApproach = approach && loading ? loading.diff(approach, 'minutes') : null
        const diffShipping = loading && unloading ? unloading.diff(loading, 'minutes') : null
        const timeTotal = diffApproach + diffShipping > 0 ? diffApproach + diffShipping : 0

        const timeApproach = route && route.approach ? route.approach.time_driving + route.approach.time_rest + route.approach.time_break : 0
        const timeApproachAndShiping = route ? route.time_driving + route.time_rest + route.time_break : 0
        const timeShiping = timeApproachAndShiping - timeApproach > 0 ? timeApproachAndShiping - timeApproach : 0

        const isApproachInTime = diffApproach !== null ? diffApproach >= timeApproach : true
        const isShippingInTime = diffShipping !== null ? diffShipping >= timeShiping : true

        const fixedCostTotal = route ? (route.costs_fixed * timeTotal) / 60 / values.currency_rate : 0
        const costTotal = route
            ? (route.costs_adblue + route.costs_fuel + route.costs_toll_per_day + route.costs_toll_per_km) / values.currency_rate + fixedCostTotal
            : 0
        const economicResults = values.price - costTotal
        const distance = route ? route.distance : 0

        /* eslint-disable jsx-a11y/anchor-is-valid */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className="col-md-3 hp-100">
                                <LoadingOverlay active={this.props.prerequisites.status.get('fetching')}>
                                    <div className="panel panel-white">
                                        <div className="panel-body panel-padding">
                                            <Form values={values} validationRules={this.validationRules} onChange={this.handleChangeValues}>
                                                <div className="p-v-xs">
                                                    <FormattedMessage id="modules.tracing.transportDetailHeading" />
                                                    <a href="#" onClick={() => this.handleClearForm()} className={'text-gray pull-right text-danger'}>
                                                        <FormattedMessage id="buttons.clear" /> <i className="far fa-trash" />
                                                    </a>
                                                </div>
                                                <CustomerPicker
                                                    id="customer_id"
                                                    label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                                    values={customers}
                                                />
                                                {isCustomersBlocked === 1 && (
                                                    <div className="alert alert-danger m-b-sm">
                                                        <FormattedMessage id="modules.transports.warningBlockedCustomer" />
                                                    </div>
                                                )}
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <SelectField
                                                            id="transport_type_id"
                                                            label={this.props.intl.formatMessage({ id: 'fields.transportType' })}
                                                            values={transportTypes}
                                                            prompt="-"
                                                            nullable
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="pull-left wp-60">
                                                            <InputField
                                                                id="price"
                                                                label={this.props.intl.formatMessage({ id: 'fields.price' })}
                                                                className="text-right"
                                                            />
                                                        </div>
                                                        <div className="pull-left wp-40">
                                                            <SelectField id="currency_id" label="&nbsp;" values={currencies} prompt={false} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <SelectField
                                                            id="vehicle_id"
                                                            label={this.props.intl.formatMessage({ id: 'fields.vehicle' })}
                                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('vehicles'))}
                                                            prompt="-"
                                                            nullable
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="approach">
                                                            <FormattedMessage id="fields.approachDateTime" />
                                                        </label>
                                                        <CustomDatePicker
                                                            isTimePicker={true}
                                                            id="approach"
                                                            disabled
                                                            value={datetimes.approach}
                                                            onChange={value => {
                                                                this.handleDatetimeChange(
                                                                    'approach',
                                                                    value ? formatters.datetimeFormatter(value) : ''
                                                                )
                                                            }}
                                                            onChangeRaw={e => {
                                                                this.handleDatetimeChange('approach', e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="loading">
                                                            <FormattedMessage id="fields.loadingDateTime" />
                                                        </label>
                                                        <CustomDatePicker
                                                            isTimePicker={true}
                                                            id="loading"
                                                            selected={loading}
                                                            value={datetimes.loading}
                                                            onChange={value => {
                                                                this.handleDatetimeChange('loading', value ? formatters.datetimeFormatter(value) : '')
                                                            }}
                                                            onChangeRaw={e => {
                                                                this.handleDatetimeChange('loading', e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="unloading">
                                                            <FormattedMessage id="fields.unloadingDateTime" />
                                                        </label>
                                                        <CustomDatePicker
                                                            isTimePicker={true}
                                                            id="unloading"
                                                            selected={unloading}
                                                            value={datetimes.unloading}
                                                            onChange={value => {
                                                                this.handleDatetimeChange(
                                                                    'unloading',
                                                                    value ? formatters.datetimeFormatter(value) : ''
                                                                )
                                                            }}
                                                            onChangeRaw={e => {
                                                                this.handleDatetimeChange('unloading', e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-v-xs m-t-xs">
                                                    <FormattedMessage id="modules.tracing.routeDefinitionHeading" />
                                                    <a
                                                        href="#"
                                                        onClick={() => this.handleClearRoute()}
                                                        className={'text-gray pull-right text-danger'}
                                                    >
                                                        <FormattedMessage id="buttons.clearRoute" /> <i className="far fa-trash" />
                                                    </a>
                                                </div>
                                                <SortableList
                                                    tracingPoints={tracingPoints}
                                                    activeTracingPointId={activePointId}
                                                    onAddressChange={this.handleAddressChange}
                                                    onAddressSubmit={this.handleAddressSubmit}
                                                    onAddressSelected={this.handleAddressSelected}
                                                    onAddressDelete={this.removeTracingPoint}
                                                    onAddresMarkerSelect={this.handleSelectFocus}
                                                    addTracingPoint={this.addTracingPoint}
                                                    openAddressesPicker={this.openAddressesPicker}
                                                    onSortEnd={this.handleSortEnd}
                                                    distance={1}
                                                    lockAxis="y"
                                                    useDragHandle
                                                    lockToContainerEdges
                                                />
                                                <div>
                                                    {canRecalculate ? (
                                                        <button
                                                            className={`btn btn-default btn-addon pull-right ${
                                                                this.state.shouldRecalculate ? 'text-danger' : ''
                                                            }`}
                                                            onClick={e => this.calculateRoute(e)}
                                                        >
                                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.recountRoute" />
                                                        </button>
                                                    ) : (
                                                        <button className="btn btn-default btn-addon pull-right" disabled>
                                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.recountRoute" />
                                                        </button>
                                                    )}
                                                </div>
                                                {showCalculatedRoute && (
                                                    <div className="p-v-xs m-t-xs f-bold">
                                                        <LoadingOverlay active={this.props.routeFetching}>
                                                            <div className="p-v-xxs">
                                                                <span>
                                                                    <FormattedMessage id="modules.tracing.totalDistance" />:
                                                                </span>{' '}
                                                                <span className="pull-right">{formatters.distanceFormatter(distance, '0,0.00')}</span>
                                                            </div>
                                                            <div className="p-v-xxs">
                                                                <span>
                                                                    <FormattedMessage id="modules.tracing.totalTime" />:
                                                                </span>{' '}
                                                                <span
                                                                    className={`pull-right ${
                                                                        isApproachInTime && isShippingInTime ? '' : 'text-danger'
                                                                    }`}
                                                                >
                                                                    {formatters.hoursFromMinutesFormatter(timeTotal)}
                                                                </span>
                                                                {!isApproachInTime && (
                                                                    <div className="alert alert-danger m-b-sm">
                                                                        <FormattedMessage id="modules.tracing.warningApproachTime" />
                                                                    </div>
                                                                )}
                                                                {!isShippingInTime && (
                                                                    <div className="alert alert-danger m-b-sm">
                                                                        <FormattedMessage id="modules.tracing.warningShippingTime" />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="p-v-xxs">
                                                                <span>
                                                                    <FormattedMessage id="modules.tracing.shippingTime" />:
                                                                </span>{' '}
                                                                <span className="pull-right">
                                                                    {formatters.hoursFromMinutesFormatter(timeApproachAndShiping)}
                                                                </span>
                                                            </div>
                                                            <div className="p-v-xxs">
                                                                <span>
                                                                    <FormattedMessage id="modules.tracing.economicResults" />:
                                                                </span>{' '}
                                                                <span className="pull-right">
                                                                    {formatters.priceFormatter(economicResults, '0,0', values.currency_name)}
                                                                </span>
                                                            </div>
                                                            <button
                                                                onClick={() => this.handleShowDetail(true)}
                                                                className="btn btn-default"
                                                                type="button"
                                                            >
                                                                <FormattedMessage id="buttons.showDetail" />
                                                            </button>
                                                        </LoadingOverlay>
                                                    </div>
                                                )}
                                            </Form>
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                            <div className="col-md-9 hp-100" style={{ paddingLeft: '0px' }}>
                                <LoadingOverlay active={this.props.prerequisites.status.get('fetching')}>
                                    <div className="panel panel-white hp-100 m-b-no">
                                        <div className="panel-body hp-100">
                                            {this.props.vehiclesFetching && (
                                                <div className="routing-map-info">
                                                    <div className="spinner" />
                                                    <span className="info">
                                                        {this.props.intl.formatMessage({ id: 'modules.tracing.map.vehiclesLoading' })}
                                                    </span>
                                                </div>
                                            )}
                                            {this.props.vehicleFetching && (
                                                <div className="routing-map-info">
                                                    <div className="spinner" />
                                                    <span className="info">
                                                        {this.props.intl.formatMessage({ id: 'modules.tracing.map.vehicleSearch' })}
                                                    </span>
                                                </div>
                                            )}
                                            <HEREMapTracing
                                                appId={config.CFG_HERE_MAPS_APP_ID}
                                                appCode={config.CFG_HERE_MAPS_APP_CODE}
                                                center={this.state.mapCenter}
                                                zoom={this.state.mapZoom}
                                                tracingPoints={mapTracingPoints}
                                                vehicles={mapVehicles}
                                                vehicle={mapVehicle}
                                                handleAddRouterPoint={this.handleMapAddTracingPoint}
                                                handleRouterPointChange={this.handleMapChangeTracingPoint}
                                                handleVehicleSelect={this.handleMapVehicleSelect}
                                            />
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                    <TracingDetail
                        showDetail={Boolean(this.state.showDetail)}
                        handleHideDetail={() => this.handleShowDetail(false)}
                        datetimes={datetimes}
                        values={values}
                        transport_types={transportTypes}
                        customers={customers}
                        tracingRoute={this.props.route ? this.props.route : {}}
                        tracingPoints={tracingPoints}
                        fixedCostTotal={fixedCostTotal}
                        costTotal={costTotal}
                        timeTotal={timeTotal}
                        customerInfo={this.props.customerInfo}
                        isCustomersBlocked={isCustomersBlocked}
                        isApproachInTime={isApproachInTime}
                        isShippingInTime={isShippingInTime}
                        onChange={this.handleChangeModalValues}
                    />

                    <Modal show={Boolean(this.state.showCustomerAddressesFound)} onHide={this.modalCustomerAddressesFoundClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.foundAddressesTitle" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                <FormattedMessage id="fields.foundAddressesTextNoSave" />
                            </p>
                            <table className="table table-striped table-hover m-t-lg">
                                <thead>
                                    <tr>
                                        <th className="w-40" />
                                        <th className="w-300">
                                            <FormattedMessage id="fields.customer" />
                                        </th>
                                        <th className="w-max wm-300">
                                            <FormattedMessage id="fields.address" />
                                        </th>
                                        <th className="w-200">
                                            <FormattedMessage id="fields.addressType" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {foundCustomerAddresses &&
                                        foundCustomerAddresses.length &&
                                        foundCustomerAddresses.map(address => (
                                            <tr
                                                key={`address-${address.id}`}
                                                onClick={() => {
                                                    this.modalCustomerAddressesFoundSelectAddress(address)
                                                }}
                                                className="row-selectable"
                                            >
                                                <td className="w-40 table-buttons text-center">
                                                    <button className="far fa-check-circle text-gray" />
                                                </td>
                                                <td>{address.customer}</td>
                                                <td>
                                                    <strong>
                                                        {address.code && `${address.code} - `}
                                                        {address.name}
                                                    </strong>
                                                    <br />
                                                    <small>{address.address}</small>
                                                </td>
                                                <td>{address.type}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default" onClick={this.modalCustomerAddressesFoundClose}>
                                <FormattedMessage id="buttons.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.showAddressesPicker} onHide={this.closeAddressesPicker} bsSize="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="modules.customerAddresses.heading" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="page-inner bg-n" style={{ overflowY: 'unset', height: 'calc(85vh - 150px)' }}>
                                <div className="main-wrapper">
                                    <CustomerAddressesPicker onSelectAddress={this.handleAddressPickerSelect} />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={Boolean(this.state.modalSaveAddressDuplicity.show)} onHide={this.handleSaveAddressDuplicityCancel} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="confirmDialog.duplicityFound.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="confirmDialog.duplicityFound.message" />
                            {customerAddressDuplicity && (
                                <div>
                                    <div className="row m-t-lg">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.customer" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.customer}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.addressType" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.type}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.name" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.name}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.address" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.address}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.email" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.email}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.phone" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.phone}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.note" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{customerAddressDuplicity.note}</strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-success m-r-xs" onClick={this.handleSaveAddressDuplicityConfirm}>
                                <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                            </button>
                            <button className="btn btn-default" onClick={this.handleSaveAddressDuplicityCancel}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        gps: selectors.getGps(state),
        gpsFetching: selectors.getGpsFetching(state),
        gpsError: selectors.getGpsError(state),

        address: selectors.getAddress(state),
        addressFetching: selectors.getAddressFetching(state),
        addressError: selectors.getAddressError(state),

        vehicles: selectors.getVehicles(state),
        vehiclesFetching: selectors.getVehiclesFetching(state),
        vehiclesError: selectors.getVehiclesError(state),

        vehicle: selectors.getVehicle(state),
        vehicleFetching: selectors.getVehicleFetching(state),
        vehicleError: selectors.getVehicleError(state),

        route: selectors.getRoute(state),
        routeFetching: selectors.getRouteFetching(state),
        routeError: selectors.getRouteError(state),

        customersCheckingData: getCustomersCheckingData(state),

        customerInfo: getCustomerInfo(state),
        customerInfoFetching: getCustomerInfoFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),

        foundCustomerAddresses: getFoundAddresses(state),
        foundCustomerAddressesFetching: getFoundAddressesFetching(state),
        foundCustomerAddressesError: getFoundAddressesError(state),

        customerAddressStatus: createGetCurrentCustomerAddressStatusSelector()(state),
        customerAddressDuplicity: createGetDuplicitySelector()(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    checkCustomers,
                    loadCustomerInfo,
                    findCustomerAddresses,
                    saveCustomerAddress,
                    ...tracingActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Tracing)
)
