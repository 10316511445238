// Fetch list

export const FETCH = 'reportCustomerTurnovers.FETCH'
export const FETCH_STARTED = 'reportCustomerTurnovers.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportCustomerTurnovers.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportCustomerTurnovers.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportCustomerTurnovers.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportCustomerTurnovers.CLEAR'
