import { createSelector } from 'reselect'

export const getWarehousingWarehouses = state => state.warehousingWarehouses.getIn(['all', 'objects'])

export const createGetWarehousingWarehouseByIDSelector = id => createSelector([getWarehousingWarehouses], warehousingWarehouses => warehousingWarehouses.get(String(id)))

const getCurrentWarehousingWarehouseStatus = state => state.warehousingWarehouses.get('current')

export const createGetCurrentWarehousingWarehouseStatusSelector = () => createSelector([getCurrentWarehousingWarehouseStatus], status => status)

export const getWarehousingWarehouseDeleting = state => state.warehousingWarehouses.getIn(['deletion', 'inProgress'])

export const getWarehousingWarehouseDeleteError = state => state.warehousingWarehouses.getIn(['deletion', 'error'])
