import React from 'react'
import { Route, Switch } from 'react-router'
import DriverSurchargeTypesList from './DriverSurchargeTypesList'
import DriverSurchargeTypesEdit from './DriverSurchargeTypesEdit'

const DriverSurchargeTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={DriverSurchargeTypesList} />
        <Route exact path={`${props.match.url}/new`} component={DriverSurchargeTypesEdit} />
        <Route exact path={`${props.match.url}/:driverSurchargeTypeID`} component={DriverSurchargeTypesEdit} />
    </Switch>
)

export default DriverSurchargeTypes
