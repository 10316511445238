import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import { DispatchersBox } from '../../../common/colored_box'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class VehicleFuelingsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render() {
        const fueling = this.props.data
        let dispatchersBox = null
        if (this.props.vehicle) {
            dispatchersBox = <DispatchersBox dispatcher={this.props.dispatcher}>{this.props.vehicle.registration}</DispatchersBox>
        } else if (fueling.registration) {
            dispatchersBox = <DispatchersBox>{fueling.registration}</DispatchersBox>
        }

        let dispatchersBoxTrailer = null
        if (this.props.trailer) {
            dispatchersBoxTrailer = <DispatchersBox dispatcher={this.props.dispatcher}>{this.props.trailer.registration}</DispatchersBox>
        } else if (fueling.registration_trailer) {
            dispatchersBoxTrailer = <DispatchersBox>{fueling.registration_trailer}</DispatchersBox>
        }

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-120">{fueling.datetime ? formatters.datetimeFormatter(fueling.datetime) : null}</td>
                <td className="w-max wm-140">{dispatchersBox}</td>
                <td className="w-max wm-140">{dispatchersBoxTrailer}</td>
                <td className="w-140">{this.props.driver ? this.props.driver.getShortFullName() : null}</td>
                <td className="w-100">{this.props.country ? this.props.country.iso_code : null}</td>
                <td className="w-100 text-right">{fueling.fuel_level ? formatters.floatFormatter(fueling.fuel_level) : null}</td>
                <td className="w-100 text-right">{fueling.adblue_level ? formatters.floatFormatter(fueling.adblue_level) : null}</td>
                <td className="w-200">{fueling.number ? fueling.number : null}</td>
                <td className="w-200">{this.props.importFile ? this.props.importFile.name : null}</td>
                <td className="w-120">{this.props.importFile ? formatters.datetimeFormatter(this.props.importFile.imported_at) : null}</td>
            </tr>
        )
    }
}

export default VehicleFuelingsListRow
