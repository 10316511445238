import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest'

import Field from './Field'

class SuggestField extends Component {
    constructor() {
        super()

        this.state = {
            suggestions: [],
        }
    }

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase()
        const inputLength = inputValue.length
        const searchValues = inputValue.split(' ')

        if (inputLength === 0) {
            return []
        }

        return this.props.suggestions.filter(suggestion => {
            const suggestionValues = suggestion.value.toLowerCase().split(' ')
            return suggestionValues.some(suggestionValue => {
                return searchValues.some(searchValue => {
                    return suggestionValue.slice(0, searchValue.length) === searchValue
                })
            })
        })
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        })
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        })
    }

    getSuggestionValue = suggestion => {
        return suggestion.value
    }

    renderSuggestion = suggestion => {
        return suggestion.value
    }

    render() {
        const theme = {
            container: 'autosuggest__container',
            containerOpen: 'autosuggest__container-open',

            input: 'form-control',
            inputOpen: 'form-control',
            inputFocused: 'form-control',

            suggestionsContainer: 'autosuggest__suggestions-container',
            suggestionsContainerOpen: 'autosuggest__suggestions-container--open',

            suggestionsList: 'autosuggest__suggestions-list',
            suggestion: 'autosuggest__suggestion',
            suggestionFirst: 'autosuggest__suggestion--first',
            suggestionHighlighted: 'autosuggest__suggestion--highlighted',

            sectionContainer: 'autosuggest__section-container',
            sectionContainerFirst: 'autosuggest__section-container--first',
            sectionTitle: 'autosuggest__section-title',
        }

        let value
        let defaultValue = this.props.defaultValue ? this.props.defaultValue : ''

        if (this.props.hasOwnProperty('getValue')) {
            value = String(this.props.getValue() ? this.props.getValue() : defaultValue)
        } else {
            value = String(this.props.value ? this.props.value : defaultValue)
        }

        const suggestions = this.getSuggestions(value)

        const inputProps = {
            placeholder: this.props.placeholder ? this.props.placeholder : '',
            value: value,
            onChange: this.props.onChange,
            id: this.props.id,
            disabled: this.props.readonly ? this.props.readonly() : false,
        }

        if (this.props.noFormGroup) {
            return (
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    theme={theme}
                    id={this.props.id}
                />
            )
        }

        return (
            <Field {...this.props}>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    theme={theme}
                    id={this.props.id}
                />
            </Field>
        )
    }
}

export default SuggestField
