import { createSelector } from 'reselect'

export const getDriverSurchargeTypes = state => state.driverSurchargeTypes.getIn(['all', 'objects'])

// eslint-disable-next-line prettier/prettier
export const createGetDriverSurchargeTypeByIDSelector = id => createSelector([getDriverSurchargeTypes], driverSurchargeTypes => driverSurchargeTypes.get(String(id)))

const getCurrentDriverSurchargeTypeStatus = state => state.driverSurchargeTypes.get('current')

// eslint-disable-next-line prettier/prettier
export const createGetCurrentDriverSurchargeTypeStatusSelector = () => createSelector([getCurrentDriverSurchargeTypeStatus], status => status)

export const getDriverSurchargeTypeDeleting = state => state.driverSurchargeTypes.getIn(['deletion', 'inProgress'])

export const getDriverSurchargeTypeDeleteError = state => state.driverSurchargeTypes.getIn(['deletion', 'error'])
