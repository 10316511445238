import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'
import { Modal } from 'react-bootstrap'


import { Form, SelectField, InputField, SubmitButton } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import {
    url,
    handleCommonEditActions,
    getPrerequisitesArray,
    convertCSDatetimeToISO,
    convertISODatetimeToCS,
    convertCSDateToMoment,
    convertCSDatetimeToMoment,
    convertISODatetimeToMoment,
} from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as constants from '../constants'
import * as driverTimesheetsActionCreators from '../actionCreators'
import { createGetDriverTimesheetByIDSelector, createGetCurrentDriverTimesheetStatusSelector, createGetDuplicitySelector } from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {CustomDatePicker} from "../../../common/custom_date_picker";

const componentIdentifier = 'driver_timesheets_edit'
const prerequisites = ['drivers']

class DriverTimesheetsEditForm extends Component {
    defaultValues = {}

    validationRules = {}

    defaultValidity = {}

    state = {
        values: this.defaultValues,
        datetimes: {
            start_datetime: moment().format('DD.MM.YYYY HH:mm'),
            end_datetime: moment().format('DD.MM.YYYY HH:mm'),
        },
        modalDuplicity: {
            show: false,
            duplicity: null,
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleDatetimeChange = (key, value) => {
        const newState = { datetimes: { ...this.state.datetimes } }
        if (key === 'day') {
            newState.datetimes.start_datetime = `${value} 00:00`
            newState.datetimes.end_datetime = `${value} ${convertCSDatetimeToMoment(newState.datetimes.end_datetime).format('HH:mm')}`
        } else {
            newState.datetimes[key] = value
        }

        this.setState(newState)
    }

    handleTimeChange = (key, value) => {
        const end_datetime = convertCSDatetimeToMoment(this.state.datetimes.end_datetime)
        end_datetime.set({ [key]: value })
        const newState = {
            datetimes: {
                ...this.state.datetimes,
                end_datetime: end_datetime.format('DD.MM.YYYY HH:mm'),
            },
            values: {
                ...this.state.values,
                [key]: value,
            },
        }
        this.setState(newState)
    }

    handleIntervalTypeChange = (key, value) => {
        const intervalTypeId = value.value
        const newState = {
            values: { ...this.state.values, interval_type_id: intervalTypeId },
            datetimes: { ...this.state.datetimes },
        }

        if (String(intervalTypeId) === String(constants.INTERVAL_TYPE_TIME)) {
            const start_datetime = convertCSDatetimeToMoment(newState.datetimes.start_datetime)
            const end_datetime = convertCSDatetimeToMoment(newState.datetimes.end_datetime)

            const date = start_datetime.format('DD.MM.YYYY')
            const hour = `0${end_datetime.hour() - start_datetime.hour()}`.slice(-2)
            const minut = `0${end_datetime.minute() - start_datetime.minute()}`.slice(-2)

            newState.datetimes.start_datetime = `${date} 00:00`
            newState.datetimes.end_datetime = `${date} ${hour}:${minut}`
        }

        this.setState(newState)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave(false)
        })
    }

    handleSave = ignoreDuplicity => {
        this.props.actions.saveDriverTimesheet(
            {
                ...this.state.values,
                start_datetime: convertCSDatetimeToISO(this.state.datetimes.start_datetime),
                end_datetime: convertCSDatetimeToISO(this.state.datetimes.end_datetime),
            },
            ignoreDuplicity
        )
    }

    duplicityFoundCallback = duplicity => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()
        this.handleSave(true)
    }

    successCallback = () => {
        this.props.successCallback ? this.props.successCallback() : this.props.redirect(url(this.props.match, 'driver-timesheets'))
    }

    backCallback = () => {
        this.props.backCallback ? this.props.backCallback() : this.props.redirect(url(this.props.match, 'driver-timesheets'))
    }

    handleTimesheetChange = (fieldId, option) => {
        this.setValues(
            {
                ...this.state.values,
                [fieldId]: option.value,
            },
            this.updateValidity
        )
    }

    updateValidity = () => {
        if (this.state.datetimes.validity_from && this.state.values.timesheet_id) {
            const timesheetId = parseInt(this.state.values.timesheet_id)
            const validity = this.defaultValidity[timesheetId] ? parseInt(this.defaultValidity[timesheetId]) : 0
            const validityToMoment = convertCSDatetimeToMoment(this.state.datetimes.validity_from)
            validityToMoment.add(validity, 'months')

            this.setState({
                datetimes: {
                    ...this.state.datetimes,
                    validity_to: validityToMoment.format('DD.MM.YYYY'),
                },
            })
        }
    }

    componentDidMount() {
        this.props.driverTimesheetID && this.props.actions.fetchDriverTimesheet(this.props.driverTimesheetID)
        this.props.actions.fetchPrerequisites()

        if (!this.props.driverTimesheetID && this.props.defaultValues) {
            const defaultValues = { ...this.props.defaultValues }

            const datetimes = { ...this.state.datetimes }
            if (defaultValues.start_datetime) {
                datetimes.start_datetime = defaultValues.start_datetime
                defaultValues.h = convertCSDatetimeToMoment(defaultValues.end_datetime).hour()
                delete defaultValues.start_datetime
            }
            if (defaultValues.end_datetime) {
                datetimes.end_datetime = defaultValues.end_datetime
                defaultValues.m = convertCSDatetimeToMoment(defaultValues.end_datetime).minute()
                delete defaultValues.end_datetime
            }

            this.setValues(defaultValues, () => this.setState({ datetimes }))
        }
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback, this.duplicityFoundCallback)

        if (!this.props.driverTimesheet && nextProps.driverTimesheet && !this.state.datetimesSet) {
            this.setState({
                values: {
                    ...nextProps.driverTimesheet.toJS(),
                    h: convertISODatetimeToMoment(nextProps.driverTimesheet.end_datetime).hour(),
                    m: convertISODatetimeToMoment(nextProps.driverTimesheet.end_datetime).minute(),
                },
                datetimes: {
                    start_datetime: convertISODatetimeToCS(nextProps.driverTimesheet.start_datetime),
                    end_datetime: convertISODatetimeToCS(nextProps.driverTimesheet.end_datetime),
                },
                datetimesSet: true,
            })
        }
    }

    render() {
        const duplicity = this.props.duplicity
        const perms = this.props.PERMS ? this.props.PERMS : PERMS

        const driverTimesheetTypeOptions = Object.keys(constants.DRIVER_TIMESHEET_TYPES).map(typeId => {
            const type = constants.DRIVER_TIMESHEET_TYPES[typeId]

            return {
                id: typeId,
                name: this.props.intl.formatMessage({ id: type.name }),
            }
        })

        const driverTimesheetIntervalTypeOptions = Object.keys(constants.DRIVER_TIMESHEET_INTERVAL_TYPES).map(typeId => {
            const type = constants.DRIVER_TIMESHEET_INTERVAL_TYPES[typeId]

            return {
                id: typeId,
                name: this.props.intl.formatMessage({ id: type.name }),
            }
        })

        return (
            <div>
                <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                    <PermissionsCheck hasAny={Object.values(perms)}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                            isEdit={Boolean(this.props.driverTimesheet)}
                        >
                            <div className={this.props.hiddenFields && this.props.hiddenFields.indexOf('driver_id') !== -1 ? 'hidden' : ''}>
                                <SelectField
                                    id="driver_id"
                                    label={this.props.intl.formatMessage({ id: 'fields.driver' })}
                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('drivers'))}
                                />
                            </div>
                            <SelectField
                                id="type_id"
                                label={this.props.intl.formatMessage({ id: 'fields.activity' })}
                                values={driverTimesheetTypeOptions}
                                prompt={false}
                            />
                            <InputField id="note" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />
                            <SelectField
                                id="interval_type_id"
                                label={this.props.intl.formatMessage({ id: 'fields.transportVehicleType' })}
                                values={driverTimesheetIntervalTypeOptions}
                                onChange={this.handleIntervalTypeChange}
                                prompt={false}
                            />
                            {String(this.state.values.interval_type_id) === String(constants.INTERVAL_TYPE_INTERVAL) && (
                                <div className="row">
                                    <div className="col-sm-6 col-lg-12 col-xl-6">
                                        <div className="form-group w-200">
                                            <label htmlFor="start_datetime">
                                                <FormattedMessage id="fields.from" />
                                            </label>
                                            <CustomDatePicker
                                                isTimePicker={true}
                                                id="start_datetime"
                                                selected={convertCSDatetimeToMoment(this.state.datetimes.start_datetime)}
                                                value={this.state.datetimes.start_datetime}
                                                onChange={value => {
                                                    this.handleDatetimeChange('start_datetime', value ? formatters.datetimeFormatter(value) : '')
                                                }}
                                                onChangeRaw={e => {
                                                    this.handleDatetimeChange('start_datetime', e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-12 col-xl-6">
                                        <div className="form-group">
                                            <label htmlFor="end_datetime">
                                                <FormattedMessage id="fields.to" />
                                            </label>
                                            <CustomDatePicker
                                                isTimePicker={true}
                                                id="end_datetime"
                                                selected={convertCSDatetimeToMoment(this.state.datetimes.end_datetime)}
                                                value={this.state.datetimes.end_datetime}
                                                onChange={value => {
                                                    this.handleDatetimeChange('end_datetime', value ? formatters.datetimeFormatter(value) : '')
                                                }}
                                                onChangeRaw={e => {
                                                    this.handleDatetimeChange('end_datetime', e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {String(this.state.values.interval_type_id) === String(constants.INTERVAL_TYPE_TIME) && (
                                <div className="form-group">
                                    <label htmlFor="day">
                                        <FormattedMessage id="fields.date" />
                                    </label>
                                    <CustomDatePicker
                                        id="day"
                                        selected={convertCSDateToMoment(this.state.datetimes.end_datetime)}
                                        value={convertCSDatetimeToMoment(this.state.datetimes.end_datetime).format('DD.MM.YYYY')}
                                        onChange={value => {
                                            this.handleDatetimeChange('day', value ? formatters.dateFormatter(value) : '')
                                        }}
                                        onChangeRaw={e => {
                                            this.handleDatetimeChange('day', e.target.value)
                                        }}
                                    />
                                    <div className="row">
                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                            <InputField
                                                id="h"
                                                label={this.props.intl.formatMessage({ id: 'fields.hoursVerbally' })}
                                                type="number"
                                                min="0"
                                                max="23"
                                                onChange={e => this.handleTimeChange('h', e.target.value)}
                                                value={convertCSDatetimeToMoment(this.state.datetimes.end_datetime).hour()}
                                            />
                                        </div>
                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                            <InputField
                                                id="m"
                                                label={this.props.intl.formatMessage({ id: 'fields.minutesVerbally' })}
                                                type="number"
                                                min="0"
                                                max="59"
                                                onChange={e => this.handleTimeChange('m', e.target.value)}
                                                value={convertCSDatetimeToMoment(this.state.datetimes.end_datetime).minute()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="btns-form">
                                <button onClick={this.backCallback} className="btn btn-addon btn-default">
                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                </button>
                                <SubmitButton
                                    perms={Object.values(perms)}
                                    isEdit={Boolean(this.props.driverTimesheet)}
                                    className="btn-addon pull-right"
                                />
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>

                <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} bsSize="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="confirmDialog.duplicityFound.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormattedMessage id="confirmDialog.duplicityFound.message" />
                        {duplicity && (
                            <div>
                                <div className="row m-t-lg">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.driver" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.driver}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.from" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.start_datetime}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.to" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.end_datetime}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.time" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{formatters.durationFromSecondsFormatter(duplicity.time)}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.note" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.note}</strong>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                            <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                        </button>
                        <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                            <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        driverTimesheet: createGetDriverTimesheetByIDSelector(ownProps.driverTimesheetID)(state),
        status: createGetCurrentDriverTimesheetStatusSelector()(state),
        duplicity: createGetDuplicitySelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...driverTimesheetsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DriverTimesheetsEditForm)
)
