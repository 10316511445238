import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import { convertCSDateToMoment } from '../../../helpers'

class DaysPicker extends Component {
    onChange = (dateFrom, dateTo) => {
        let momentFrom = convertCSDateToMoment(dateFrom)
        let momentTo = convertCSDateToMoment(dateTo)

        if (momentFrom === null) {
            momentFrom = this.props.momentFrom
        }

        if (momentTo === null) {
            momentTo = this.props.momentTo
        }

        this.props.onChange(momentFrom, momentTo)
    }

    render() {
        const momentFrom = this.props.momentFrom
        const momentTo = this.props.momentTo

        const dateFrom = momentFrom.format('DD.MM.YYYY')
        const dateTo = momentTo.format('DD.MM.YYYY')

        const minDate = moment(`01-01-${this.props.minYear}`, 'MM-DD-YYYY')
        const maxDate = moment(`12-31-${this.props.maxYear}`, 'MM-DD-YYYY')

        return (
            <div className="pull-left">
                <div className="pull-left w-120">
                    <DatePicker
                        dateFormat="DD.MM.YYYY"
                        autoComplete="off"
                        selected={momentFrom}
                        value={dateFrom}
                        onChange={value => {
                            this.onChange(value, dateTo)
                        }}
                        onChangeRaw={e => {
                            this.onChange(e.target.value, dateTo)
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </div>
                <div className="pull-left m-r-xxs m-l-xxs p-t-5">-</div>
                <div className="pull-left w-120">
                    <DatePicker
                        dateFormat="DD.MM.YYYY"
                        autoComplete="off"
                        selected={momentTo}
                        value={dateTo}
                        onChange={value => {
                            this.onChange(dateFrom, value)
                        }}
                        onChangeRaw={e => {
                            this.onChange(dateFrom, e.target.value)
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </div>
            </div>
        )
    }
}

export default DaysPicker
