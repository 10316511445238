import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import validator from 'validator'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

import * as constants from '../constants'

import { Form, InputField, BooleanField, SelectField } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as actionCreators from './../actionCreators'
import { getTransportOrdersInfo, getTransportOrdersInfoFetching, getTransportOrderCreating, getTransportOrderSending } from './../selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import * as config from '../../../common/config'
import {CustomDatePicker} from "../../../common/custom_date_picker";

const componentIdentifier = 'transports_shipping_order'
const prerequisites = ['transport_conditions']

class TransportsShippingOrderForm extends Component {
    defaultValues = {
        date: moment().format('DD.MM.YYYY'),
        locale: this.props.intl.locale,
    }

    state = {
        values: this.defaultValues,
        transport: null,
        transportPoints: {
            loadings: [],
            unloadings: [],
            parkings: [],
            customHouses: [],
        },
        languages: [],
        forWhom: constants.TRANSPORT_ORDER_FOR_SUPPLIER,
        documentType: constants.TRANSPORT_ORDER_TYPE_ORDER,
        transportCustomer: null,
        modalSendToEmail: {
            show: false,
            emailAddress: '',
        },
    }

    validationRules = {
        payment_conditions: value => (validator.isEmpty(String(value)) || value === undefined) && helpers.validationMessage('isEmpty'),
    }

    setValues = values => {
        this.setState({
            values,
        })
    }

    setLanguages = languages => {
        this.setState({
            languages,
        })
    }

    setValuesFromTransport = (transport, transportPoints, transportFormValues) => {
        let order_number = null
        let customer_id = null
        let currency_id = null
        let price = null
        let note = null
        let transport_description = null

        const isForCustomer = this.state.forWhom === constants.TRANSPORT_ORDER_FOR_CUSTOMER
        const isSpedition = transportFormValues.is_spedition

        if (isForCustomer && this.state.transportCustomer) {
            order_number = this.state.transportCustomer.order_number
            customer_id = this.state.transportCustomer.customer_id
            currency_id = this.state.transportCustomer.currency_id
            note = this.state.transportCustomer.note
            transport_description = this.state.transportCustomer.transport_description

            Object.keys(this.state.transportCustomer.priceParts).forEach(key => {
                price += helpers.getFloatVal(this.state.transportCustomer.priceParts[key])
            })
        }

        const values = {
            ...this.state.values,
            number: transport.id,
            price: isForCustomer ? price : transportFormValues.carrier_price,
            vehicle_registration: isSpedition ? transportFormValues.carrier_vehicle_registration : '',
            trailer_registration: isSpedition ? transportFormValues.carrier_trailer_registration : '',
            driver_phone: isSpedition ? transportFormValues.carrier_driver_phone : '',
            driver_name: isSpedition ? transportFormValues.carrier_driver_name : '',
            client_note: isForCustomer ? note : transportFormValues.note,
            supplier_note: isForCustomer ? transportFormValues.note : transportFormValues.carrier_note,
            order_number: isForCustomer ? order_number : transportFormValues.carrier_order_number,
            transport_description: isForCustomer ? transport_description : transportFormValues.carrier_transport_description,
            transport_conditions: transportFormValues.transport_conditions ? transportFormValues.transport_conditions : [],
            document_type: this.state.documentType,
            file_type: 'pdf',
        }

        const points = {
            loadings: [],
            unloadings: [],
            parkings: [],
            customHouses: [],
        }

        transportPoints.forEach(point => {
            if (parseInt(point.type_id) === constants.TRANSPORT_POINT_TYPE_LOADING) {
                points.loadings.push(this.getTransportPointInfo(point))
            } else if (parseInt(point.type_id) === constants.TRANSPORT_POINT_TYPE_UNLOADING) {
                points.unloadings.push(this.getTransportPointInfo(point))
            } else if (
                parseInt(point.type_id) === constants.TRANSPORT_POINT_TYPE_PARKING ||
                parseInt(point.type_id) === constants.TRANSPORT_POINT_TYPE_PARKING_AND_CHANGE
            ) {
                points.parkings.push(this.getTransportPointInfo(point))
            } else if (parseInt(point.type_id) === constants.TRANSPORT_POINT_TYPE_CUSTOM_HOUSE) {
                points.customHouses.push(this.getTransportPointInfo(point))
            }
        })

        this.setState({
            transport,
            transportPoints: points,
            values: {
                ...values,
                ...this.getValuesFromTransportPoints(points),
            },
        })

        if (
            (transportFormValues.disponent_id && (!this.state.disponent || this.state.disponent.id !== transportFormValues.disponent_id)) ||
            (transportFormValues.carrier_id && (!this.state.carrier || this.state.carrier.id !== transportFormValues.carrier_id)) ||
            (transportFormValues.carrier_currency_id && (!this.state.currency || this.state.currency.id !== transportFormValues.carrier_currency_id))
        ) {
            this.props.actions.fetchTransportOrdersInfo(transport.id, {
                disponent_id: transportFormValues.disponent_id,
                carrier_id: transportFormValues.carrier_id,
                carrier_currency_id: transportFormValues.carrier_currency_id,
                carrier_price: transportFormValues.carrier_price,
                is_spedition: isSpedition,
                customer_id,
                currency_id,
                price,
                vehicle_id: transportFormValues.vehicle_id,
                datetime: points.loadings[0] && points.loadings[0].datetimeFrom,
                is_for_customer: isForCustomer,
            })
        }
    }

    setValuesFromTransportPart = (transport, transportFormValues, transportPart) => {
        const values = {
            ...this.state.values,
            number: transport.id,
            price: transportPart.carrier_price,
            vehicle_registration:
                transportPart.transport_vehicle_type_id === constants.TRANSPORT_VEHICLE_TYPE_VEHICLE ? transportPart.transport_vehicle_number : '',
            trailer_registration: '',
            driver_phone: '',
            driver_name: '',
            client_note: transportFormValues.note,
            supplier_note: transportPart.carrier_note,
            order_number: transportPart.carrier_order_number,
            transport_description: transportPart.carrier_transport_description,
            transport_conditions: transportPart.transport_conditions.map(item => item.id),
            document_type: this.state.documentType,
            file_type: 'pdf',
        }

        const loadingFrom = helpers.getFirstExistValue([transportPart.loading_datetime_specified_from, transportPart.loading_datetime_planned_from])
        const loadingTo = helpers.getFirstExistValue([
            transportPart.loading_datetime_specified_to || transportPart.loading_datetime_specified_from,
            transportPart.loading_datetime_planned_to || transportPart.loading_datetime_planned_from,
        ])

        const unloadingFrom = helpers.getFirstExistValue([
            transportPart.unloading_datetime_specified_from,
            transportPart.unloading_datetime_planned_from,
        ])
        const unloadingTo = helpers.getFirstExistValue([
            transportPart.unloading_datetime_specified_to || transportPart.unloading_datetime_specified_from,
            transportPart.unloading_datetime_planned_to || transportPart.unloading_datetime_planned_from,
        ])

        const points = {
            loadings: [
                {
                    name: transportPart.loading_name,
                    address: formatters.address(
                        transportPart.loading_street,
                        transportPart.loading_city,
                        transportPart.loading_zipcode,
                        transportPart.loading_country && transportPart.loading_country.iso_code
                    ),
                    datetime: formatters.datetimePeriodFormatter(loadingFrom, loadingTo),
                    note: transportPart.loading_note,
                },
            ],
            unloadings: [
                {
                    name: transportPart.unloading_name,
                    address: formatters.address(
                        transportPart.unloading_street,
                        transportPart.unloading_city,
                        transportPart.unloading_zipcode,
                        transportPart.unloading_country && transportPart.unloading_country.iso_code
                    ),
                    datetime: formatters.datetimePeriodFormatter(unloadingFrom, unloadingTo),
                    note: transportPart.unloading_note,
                },
            ],
            parkings: [],
            customHouses: [],
        }

        this.setState({
            transport,
            transportPoints: points,
            values: {
                ...values,
                ...this.getValuesFromTransportPoints(points),
            },
        })

        if (
            (transportFormValues.disponent_id && (!this.state.disponent || this.state.disponent.id !== transportFormValues.disponent_id)) ||
            (transportPart.carrier_id && (!this.state.carrier || this.state.carrier.id !== transportPart.carrier_id)) ||
            (transportPart.carrier_currency_id && (!this.state.currency || this.state.currency.id !== transportPart.carrier_currency_id))
        ) {
            this.props.actions.fetchTransportOrdersInfo(transport.id, {
                disponent_id: transportFormValues.disponent_id,
                carrier_id: transportPart.carrier_id,
                currency_id: transportPart.carrier_currency_id,
                price: transportPart.carrier_price,
                is_spedition: !transportPart.take_from_transport || transportFormValues.is_spedition,
                is_for_customer: false,
            })
        }
    }

    getTransportPointInfo = transportPoint => {
        const addressParts = []
        if (transportPoint.street) {
            addressParts.push(transportPoint.street)
        }
        if (transportPoint.city) {
            addressParts.push(transportPoint.city)
        }
        if (transportPoint.zipcode) {
            addressParts.push(transportPoint.zipcode)
        }
        if (transportPoint.country_code) {
            addressParts.push(transportPoint.country_code)
        }

        const datetimeFrom = helpers.getFirstExistValue([transportPoint.bookingFrom, transportPoint.specifiedFrom, transportPoint.plannedFrom])
        const datetimeTo = helpers.getFirstExistValue([transportPoint.bookingTo, transportPoint.specifiedTo, transportPoint.plannedTo])

        const pointInfo = {
            name: transportPoint.name,
            address: addressParts.join(', '),
            datetimeFrom: datetimeFrom && formatters.datetimeFormatter(datetimeFrom),
            datetime: formatters.datetimePeriodFormatter(datetimeFrom, datetimeTo),
            note: transportPoint.note,
        }

        transportPoint.data &&
            transportPoint.data.forEach(item => {
                if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_CODE) {
                    pointInfo.code = item.value
                } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_PCS_COUNT) {
                    pointInfo.pcs = item.value
                } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_WEIGHT) {
                    pointInfo.weight = item.value
                } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_TEMPERATURE_FROM) {
                    pointInfo.temperatureFrom = item.value
                } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_TEMPERATURE_TO) {
                    pointInfo.temperatureTo = item.value
                } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE) {
                    pointInfo.palletExchange = parseInt(item.value) === 1
                } else if (parseInt(item.type_id) === constants.TRANSPORT_POINT_DATA_TYPE_PALLET_EXCHANGE_COUNT) {
                    pointInfo.palletExchangeCount = item.value
                }
            })

        return pointInfo
    }

    getValuesFromTransportPoints = points => {
        const values = {}
        let totalPcsForLoading = 0

        // loadings
        points.loadings.forEach((point, key) => {
            if (point.code) {
                values[`loading-${key}-code`] = point.code
            }
            if (point.pcs) {
                values[`loading-${key}-pcs`] = `${point.pcs} ${this.props.intl.formatMessage({ id: 'units.pcs' })}`
                if (!isNaN(parseInt(point.pcs))) {
                    totalPcsForLoading += parseInt(point.pcs)
                }
            }
            if (point.weight) {
                values[`loading-${key}-weight`] = `${point.weight} ${this.props.intl.formatMessage({ id: 'units.kg' })}`
            }

            const temperatureParts = []
            if (point.temperatureFrom) {
                temperatureParts.push(`${String(this.props.intl.formatMessage({ id: 'fields.from' })).toLowerCase()} ${point.temperatureFrom}°C`)
            }
            if (point.temperatureTo) {
                temperatureParts.push(`${String(this.props.intl.formatMessage({ id: 'fields.to' })).toLowerCase()} ${point.temperatureTo}°C`)
            }
            values[`loading-${key}-temperature`] = temperatureParts.join(' ')

            if (temperatureParts.length) {
                values.req_temperature_recorder = '1'
                values.req_temperature_control = '1'
                values.req_frc_cooling = '1'
            }

            let palletExchange = this.props.intl.formatMessage({ id: 'fields.no' })
            if (point.palletExchange) {
                palletExchange = this.props.intl.formatMessage({ id: 'fields.yes' })

                if (point.palletExchangeCount) {
                    palletExchange += `, ${point.palletExchangeCount} ${this.props.intl.formatMessage({ id: 'units.pcs' })}`
                }
            }
            values[`loading-${key}-palletExchange`] = palletExchange
        })

        if (totalPcsForLoading) {
            values.req_pallets_capacity = `FTL - ${totalPcsForLoading} PLT`
        }

        // unloadings
        points.unloadings.forEach((point, key) => {
            if (point.code) {
                values[`unloading-${key}-code`] = point.code
            }
            if (point.pcs) {
                values[`unloading-${key}-pcs`] = `${point.pcs} ${this.props.intl.formatMessage({ id: 'units.pcs' })}`
            }
            if (point.weight) {
                values[`unloading-${key}-weight`] = `${point.weight} ${this.props.intl.formatMessage({ id: 'units.kg' })}`
            }

            let palletExchange = this.props.intl.formatMessage({ id: 'fields.no' })
            if (point.palletExchange) {
                palletExchange = this.props.intl.formatMessage({ id: 'fields.yes' })

                if (point.palletExchangeCount) {
                    palletExchange += `, ${point.palletExchangeCount} ${this.props.intl.formatMessage({ id: 'units.pcs' })}`
                }
            }
            values[`unloading-${key}-palletExchange`] = palletExchange
        })

        // custom house
        values.custom_house = points.customHouses.length > 0 ? '1' : '0'

        const customHouses = []
        points.customHouses.forEach(point => {
            customHouses.push(point.address)
        })
        values.custom_house_address = customHouses.join('\n')

        // parking
        values.parking = points.parkings.length > 0 ? '1' : '0'

        const parkings = []
        points.parkings.forEach(point => {
            parkings.push(point.address)
        })
        values.parking_address = parkings.join('\n')

        return values
    }

    onChange = values => {
        this.setValues(values)
    }

    onChangeDate = (key, value) => {
        this.setValues({
            ...this.state.values,
            [key]: value,
        })
    }

    onSubmit = () => {
        this.props.onSubmit && this.props.onSubmit(this.getValuesForSubmit())
    }

    getValuesForSubmit = () => ({
        ...this.state.values,
        loadings: this.state.transportPoints.loadings.map((point, key) => ({
            name: point.name,
            address: point.address,
            note: point.note,
            datetime: point.datetime,
            code: this.state.values[`loading-${key}-code`],
            pcs: this.state.values[`loading-${key}-pcs`],
            weight: this.state.values[`loading-${key}-weight`],
            temperature: this.state.values[`loading-${key}-temperature`],
            palletExchange: this.state.values[`loading-${key}-palletExchange`],
        })),
        unloadings: this.state.transportPoints.unloadings.map((point, key) => ({
            name: point.name,
            address: point.address,
            note: point.note,
            datetime: point.datetime,
            code: this.state.values[`unloading-${key}-code`],
            pcs: this.state.values[`unloading-${key}-pcs`],
            weight: this.state.values[`unloading-${key}-weight`],
            palletExchange: this.state.values[`unloading-${key}-palletExchange`],
        })),
        is_for_customer: this.state.forWhom === constants.TRANSPORT_ORDER_FOR_CUSTOMER,
    })

    onCancel = () => {
        this.props.onCancel && this.props.onCancel()
    }

    // send to email
    handleSendToEmail = e => {
        e && e.preventDefault()

        const isForCustomer = this.state.forWhom === constants.TRANSPORT_ORDER_FOR_CUSTOMER

        this.modalSendToEmailOpen(isForCustomer ? this.state.values.client_email : this.state.values.supplier_email)
    }

    modalSendToEmailOpen = emailAddress => {
        this.setState({
            modalSendToEmail: {
                ...this.state.modalSendToEmail,
                show: true,
                emailAddress,
            },
        })
    }

    modalSendToEmailClose = () => {
        this.setState({
            modalSendToEmail: {
                ...this.state.modalSendToEmail,
                show: false,
            },
        })
    }

    modalSendToEmailSetEmailAddress = e => {
        this.setState({
            modalSendToEmail: {
                ...this.state.modalSendToEmail,
                emailAddress: e.target.value,
            },
        })
    }

    modalSendToEmailSubmit = () => {
        this.props.onSendToEmail &&
            this.props.onSendToEmail({
                ...this.getValuesForSubmit(),
                email_address: this.state.modalSendToEmail.emailAddress,
            })

        this.modalSendToEmailClose()
    }

    componentDidMount() {
        if (this.props.transport) {
            this.setState(
                {
                    forWhom: this.props.forWhom,
                    transportCustomer: this.props.transportCustomer,
                    documentType: this.props.documentType,
                },
                () => {
                    if (this.props.transportPart) {
                        this.setValuesFromTransportPart(this.props.transport, this.props.transportFormValues, this.props.transportPart)
                    } else {
                        this.setValuesFromTransport(this.props.transport, this.props.transportPoints, this.props.transportFormValues)
                    }
                }
            )
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.ordersInfoFetching && !nextProps.ordersInfoFetching && nextProps.ordersInfo) {
            let requirements = nextProps.ordersInfo.requirements.map(obj => ({ [`req_${obj.id}`]: '0' }))
            requirements = Object.assign({}, ...requirements)

            this.setValues({
                ...this.state.values,
                ...nextProps.ordersInfo,
                ...requirements,
            })

            this.setLanguages(nextProps.ordersInfo.languages)
        }
    }

    handleChangeLocale = e => {
        this.setState({
            ...this.state,
            locale: e.value
        })
    }

    render() {
        const transport = this.state.transport
        const values = this.state.values

        if (!transport) {
            return null
        }

        const languagesOptions = this.state.languages

        const documentTypesOptions = [
            { id: constants.TRANSPORT_ORDER_TYPE_OFFER, name: 'Nabídka' },
            { id: constants.TRANSPORT_ORDER_TYPE_ORDER, name: 'Objednávka' },
            { id: constants.TRANSPORT_ORDER_TYPE_ORDER_CONFIRM, name: 'Potvrzení objednávky' },
        ]

        const fileTypesOptions = [{ id: 'pdf', name: 'PDF' }, { id: 'xls', name: 'XLS' }]

        return (
            <LoadingOverlay active={this.props.ordersInfoFetching || this.props.orderCreating || this.props.orderSending}>
                <PermissionsCheck hasAny={Object.values(PERMS)}>
                    <Form
                        values={values}
                        validationRules={this.validationRules}
                        onChange={this.onChange}
                        onSubmit={this.onSubmit}
                        className="pull-left wp-100"
                    >
                        <div className="row">
                            <div className="col-md-2 form-label">
                                <FormattedMessage id="fields.number" />:
                            </div>
                            <div className="col-md-2">
                                <InputField id="number" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 form-label">
                                <FormattedMessage id="fields.orderNumber" />:
                            </div>
                            <div className="col-md-4">
                                <InputField id="order_number" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 form-label">
                                <FormattedMessage id="fields.date" />:
                            </div>
                            <div className="col-md-2">
                                <CustomDatePicker
                                    id="date"
                                    selected={helpers.convertCSDateToMoment(values.date)}
                                    value={values.date}
                                    onChange={value => {
                                        this.onChangeDate('date', value ? formatters.dateFormatter(value) : '')
                                    }}
                                    onChangeRaw={e => {
                                        this.onChangeDate('date', e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        {this.state.forWhom === constants.TRANSPORT_ORDER_FOR_CUSTOMER && (
                            <div className="row">
                                <div className="col-md-2 form-label">
                                    <FormattedMessage id="fields.documentType" />:
                                </div>
                                <div className="col-md-2">
                                    <SelectField id="document_type" values={documentTypesOptions} />
                                </div>
                            </div>
                        )}

                        <hr />

                        <div className="row">
                            <div className="col-md-6 b-r">
                                <h4 className="wp-100 m-b-md">
                                    <FormattedMessage id="fields.client" />
                                </h4>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.companyName" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="client_name" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.address" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="client_address" autosize />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.regNumber" />:
                                    </div>
                                    <div className="col-md-4">
                                        <InputField id="client_reg_number" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.vatNumber" />:
                                    </div>
                                    <div className="col-md-4">
                                        <InputField id="client_vat_number" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.disponent" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="client_disponent" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.phone" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="client_phone" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.email" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="client_email" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.note" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="client_note" autosize />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <h4 className="wp-100 m-b-md">
                                    <FormattedMessage id="fields.carrier" />
                                </h4>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.companyName" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="supplier_name" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.address" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="supplier_address" autosize />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.regNumber" />:
                                    </div>
                                    <div className="col-md-4">
                                        <InputField id="supplier_reg_number" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.vatNumber" />:
                                    </div>
                                    <div className="col-md-4">
                                        <InputField id="supplier_vat_number" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.disponent" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="supplier_disponent" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.phone" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="supplier_phone" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.email" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="supplier_email" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.note" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="supplier_note" autosize />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="row">
                            <div className="col-md-6">
                                <h4 className="wp-100 m-b-md">
                                    <FormattedMessage id="fields.vehicleRequirements" />
                                </h4>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.capacityPallets" />:
                                    </div>
                                    <div className="col-md-4">
                                        <InputField id="req_pallets_capacity" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.transportConditions" />:
                                    </div>
                                    <div className="col-md-8">
                                        <SelectField
                                            id="transport_conditions"
                                            label={null}
                                            values={helpers.getPrerequisitesArray(
                                                this.props.prerequisites.values.get('transport_conditions'),
                                                'id',
                                                'code'
                                            )}
                                            multi
                                        />
                                    </div>
                                </div>
                                {values.requirements &&
                                    values.requirements.map(requirement => (
                                        <div key={`requirement-${requirement.id}`} className="row">
                                            <div className="col-md-4 form-label">{`${requirement.name}:`}</div>
                                            <div className="col-md-4">
                                                <BooleanField id={`req_${requirement.id}`} />
                                            </div>
                                        </div>
                                    ))}
                            </div>

                            <div className="col-md-6 b-l">
                                <h4 className="wp-100 m-b-md">
                                    <FormattedMessage id="fields.vehicleAndDriver" />
                                </h4>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.vehicleRegistration" />:
                                    </div>
                                    <div className="col-md-4">
                                        <InputField id="vehicle_registration" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.trailerRegistration" />:
                                    </div>
                                    <div className="col-md-4">
                                        <InputField id="trailer_registration" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.driverPhone" />:
                                    </div>
                                    <div className="col-md-4">
                                        <InputField id="driver_phone" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.driverName" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="driver_name" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="wp-100 m-b-md">
                                    <FormattedMessage id="fields.transportGoods" />
                                </h4>
                                <table className="table table-no-border no-m">
                                    <thead>
                                        <tr>
                                            <th className="w-max wm-200">
                                                <FormattedMessage id="fields.name" />
                                            </th>
                                            <th className="w-200">
                                                <FormattedMessage id="fields.packagingType" />
                                            </th>
                                            <th className="w-80 text-right">
                                                <FormattedMessage id="fields.count" />
                                            </th>
                                            <th className="w-180 text-right">
                                                <FormattedMessage id="fields.dimensions" />
                                            </th>
                                            <th className="w-100 text-right">
                                                <FormattedMessage id="fields.weight" />
                                            </th>
                                            <th className="w-100 text-right">
                                                <FormattedMessage id="fields.volume" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {values &&
                                            values.goods &&
                                            values.goods.map(transportGood => (
                                                <tr key={`goods-${transportGood.id}`}>
                                                    <td>{transportGood.name}</td>
                                                    <td>{transportGood.packaging}</td>
                                                    <td className="text-right">{transportGood.count}</td>
                                                    <td className="text-right">{transportGood.dimensions}</td>
                                                    <td className="text-right">{transportGood.weight}</td>
                                                    <td className="text-right">{transportGood.volume}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row m-t-lg">
                            <div className="col-md-12">
                                <h4 className="wp-100 m-b-md">
                                    <FormattedMessage id="fields.loadingPlaces" />
                                </h4>
                                <table className="table table-no-border no-m">
                                    <thead>
                                        <tr>
                                            <th className="w-max">
                                                <FormattedMessage id="fields.address" /> / <FormattedMessage id="fields.dateTime" />
                                            </th>
                                            <th className="w-160 text-center">
                                                <FormattedMessage id="fields.dateTime" />
                                            </th>
                                            <th className="w-160">
                                                <FormattedMessage id="fields.code" />
                                            </th>
                                            <th className="w-80">
                                                <FormattedMessage id="fields.pcs" />
                                            </th>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.palletExchange" />
                                            </th>
                                            <th className="w-100">
                                                <FormattedMessage id="fields.weight" />
                                            </th>
                                            <th className="w-160">
                                                <FormattedMessage id="fields.temperature" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.transportPoints.loadings.map((point, key) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <tr key={`point-${key}`}>
                                                <td>
                                                    {point.name && (
                                                        <span>
                                                            <strong>{point.name}</strong>
                                                            <br />
                                                        </span>
                                                    )}
                                                    {point.address}
                                                    <br />
                                                    <i>{point.note}</i>
                                                </td>
                                                <td className="text-center">{point.datetime}</td>
                                                <td>
                                                    <InputField id={`loading-${key}-code`} formGroupClassName="m-b-no" />
                                                </td>
                                                <td>
                                                    <InputField id={`loading-${key}-pcs`} formGroupClassName="m-b-no" />
                                                </td>
                                                <td>
                                                    <InputField id={`loading-${key}-palletExchange`} formGroupClassName="m-b-no" />
                                                </td>
                                                <td>
                                                    <InputField id={`loading-${key}-weight`} formGroupClassName="m-b-no" />
                                                </td>
                                                <td>
                                                    <InputField id={`loading-${key}-temperature`} formGroupClassName="m-b-no" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row m-t-lg">
                            <div className="col-md-6 b-r">
                                <h4 className="wp-100 m-b-md">
                                    <FormattedMessage id="fields.customHouse" />
                                </h4>
                                <div className="row">
                                    <div className="col-md-4">
                                        <BooleanField id="custom_house" />
                                    </div>
                                    {values.custom_house === '1' && (
                                        <div className="col-md-8">
                                            <InputField id="custom_house_address" autosize />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h4 className="wp-100 m-b-md">
                                    <FormattedMessage id="fields.allowedParking" />
                                </h4>
                                <div className="row">
                                    <div className="col-md-4">
                                        <BooleanField id="parking" />
                                    </div>
                                    {values.parking === '1' && (
                                        <div className="col-md-8">
                                            <InputField id="parking_address" autosize />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row m-t-lg">
                            <div className="col-md-12">
                                <h4 className="wp-100 m-b-md">
                                    <FormattedMessage id="fields.unloadingPlaces" />
                                </h4>
                                <table className="table table-no-border no-m">
                                    <thead>
                                        <tr>
                                            <th className="w-max">
                                                <FormattedMessage id="fields.address" /> / <FormattedMessage id="fields.dateTime" />
                                            </th>
                                            <th className="w-160 text-center">
                                                <FormattedMessage id="fields.dateTime" />
                                            </th>
                                            <th className="w-160">
                                                <FormattedMessage id="fields.code" />
                                            </th>
                                            <th className="w-80">
                                                <FormattedMessage id="fields.pcs" />
                                            </th>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.palletExchange" />
                                            </th>
                                            <th className="w-100">
                                                <FormattedMessage id="fields.weight" />
                                            </th>
                                            <th className="w-160" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.transportPoints.unloadings.map((point, key) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <tr key={`point-${key}`}>
                                                <td>
                                                    {point.name && (
                                                        <span>
                                                            <strong>{point.name}</strong>
                                                            <br />
                                                        </span>
                                                    )}
                                                    {point.address}
                                                    <br />
                                                    <i>{point.note}</i>
                                                </td>
                                                <td className="text-center">{point.datetime}</td>
                                                <td>
                                                    <InputField id={`unloading-${key}-code`} formGroupClassName="m-b-no" />
                                                </td>
                                                <td>
                                                    <InputField id={`unloading-${key}-pcs`} formGroupClassName="m-b-no" />
                                                </td>
                                                <td>
                                                    <InputField id={`unloading-${key}-palletExchange`} formGroupClassName="m-b-no" />
                                                </td>
                                                <td>
                                                    <InputField id={`unloading-${key}-weight`} formGroupClassName="m-b-no" />
                                                </td>
                                                <td />
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row m-t-lg">
                            <div className="col-md-12">
                                <h4 className="wp-100 m-b-md">
                                    <FormattedMessage id="fields.transportDescription" />
                                </h4>
                                <InputField id="transport_description" autosize rows={6} />
                            </div>
                        </div>

                        <div className="row m-t-lg">
                            <div className="col-md-6">
                                <h4 className="wp-100 m-b-md">
                                    <FormattedMessage id="fields.shippingPrice" />
                                </h4>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.shippingPriceInclToll" />:
                                    </div>
                                    <div className="col-md-4">
                                        <InputField id="price" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.paymentConditions" />:
                                    </div>
                                    <div className="col-md-4">
                                        <InputField id="payment_conditions" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.boatBooking" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="boat_booking" autosize />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.hotline" />:
                                    </div>
                                    <div className="col-md-4">
                                        <InputField id="hotline" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.attachedDocuments" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="attachments" autosize />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-label">
                                        <FormattedMessage id="fields.otherArrangements" />:
                                    </div>
                                    <div className="col-md-8">
                                        <InputField id="other_arrangements" autosize />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btns-form m-t-lg clearfix">
                            <div className="pull-right">
                                <PermissionsCheck hasAny={[PERMS.CREATE, PERMS.UPDATE]}>
                                    <div className="w-100 pull-left m-r-xs">
                                        <SelectField id="language" values={languagesOptions} />
                                    </div>
                                    <div className="pull-left w-100 m-r-xs">
                                        <SelectField id="file_type" values={fileTypesOptions} />
                                    </div>
                                    <button type="submit" className="btn btn-addon btn-success m-r-xs">
                                        <i className="far fa-download" /> <FormattedMessage id="buttons.download" />
                                    </button>
                                    <button onClick={this.handleSendToEmail} data-validate="true" type="button" className="btn btn-addon btn-success m-r-xs">
                                        <i className="far fa-envelope" /> <FormattedMessage id="buttons.sendToEmail" />
                                    </button>
                                </PermissionsCheck>
                                <button type="button" className="btn btn-addon btn-default" onClick={this.onCancel}>
                                    <i className="far fa-times" /> <FormattedMessage id="buttons.cancel" />
                                </button>
                            </div>
                        </div>
                    </Form>

                    <Modal show={Boolean(this.state.modalSendToEmail.show)} onHide={this.modalSendToEmailClose} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.sendToEmail" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputField
                                id="email"
                                label={this.props.intl.formatMessage({ id: 'fields.email' })}
                                value={this.state.modalSendToEmail.emailAddress}
                                onChange={this.modalSendToEmailSetEmailAddress}
                                readonly={() => false}
                                disabled={() => false}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-success m-r-xs" onClick={this.modalSendToEmailSubmit}>
                                <FormattedMessage id="buttons.send" />
                            </button>
                            <button className="btn btn-default" onClick={this.modalSendToEmailClose}>
                                <FormattedMessage id="buttons.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </PermissionsCheck>
            </LoadingOverlay>
        )
    }
}

function mapStateToProps(state) {
    return {
        ordersInfo: getTransportOrdersInfo(state),
        ordersInfoFetching: getTransportOrdersInfoFetching(state),
        orderCreating: getTransportOrderCreating(state),
        orderSending: getTransportOrderSending(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TransportsShippingOrderForm))
