import { createSelector } from 'reselect'

export const getDriversTypes = state => state.driversTypes.getIn(['all', 'objects'])

// eslint-disable-next-line prettier/prettier
export const createGetDriversTypeByIDSelector = id => createSelector([getDriversTypes], driversTypes => driversTypes.get(String(id)))

const getCurrentDriversTypeStatus = state => state.driversTypes.get('current')

// eslint-disable-next-line prettier/prettier
export const createGetCurrentDriversTypeStatusSelector = () => createSelector([getCurrentDriversTypeStatus], status => status)

export const getDriversTypeDeleting = state => state.driversTypes.getIn(['deletion', 'inProgress'])

export const getDriversTypeDeleteError = state => state.driversTypes.getIn(['deletion', 'error'])
