export const getYear = state => state.reportCostCenterTraffic.getIn(['list', 'year'])
export const getData = state => state.reportCostCenterTraffic.getIn(['list', 'data'])
export const getFetching = state => state.reportCostCenterTraffic.getIn(['list', 'fetching'])
export const getError = state => state.reportCostCenterTraffic.getIn(['list', 'error'])

export const getDetailsData = state => state.reportCostCenterTraffic.getIn(['details', 'data'])
export const getDetailsFetching = state => state.reportCostCenterTraffic.getIn(['details', 'fetching'])
export const getDetailsError = state => state.reportCostCenterTraffic.getIn(['details', 'error'])

export const getDetailsVehiclesData = state => state.reportCostCenterTraffic.getIn(['detailsVehicles', 'data'])
export const getDetailsVehiclesFetching = state => state.reportCostCenterTraffic.getIn(['detailsVehicles', 'fetching'])
export const getDetailsVehiclesError = state => state.reportCostCenterTraffic.getIn(['detailsVehicles', 'error'])
