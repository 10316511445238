import * as actionTypes from './actionTypes'

// Fetch

export const fetchInvoicingInvoices = filters => ({
    type: actionTypes.FETCH,
    payload: {
        filters,
    },
})

export const fetchInvoicingInvoicesFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: {
        data,
    },
})

export const fetchInvoicingInvoicesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchInvoicingInvoicesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearInvoicingInvoices = () => ({
    type: actionTypes.CLEAR,
})

// Fetch one

export const fetchInvoicingInvoice = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchInvoicingInvoiceFulfilled = (invoice, supportData) => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
    payload: {
        invoice,
        supportData,
    },
})

export const fetchInvoicingInvoiceRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchInvoicingInvoiceCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveInvoicingInvoice = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveInvoicingInvoiceFulfilled = (invoice, supportData) => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: {
        invoice,
        supportData,
    },
})

export const saveInvoicingInvoiceRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteInvoice = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteInvoiceFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteInvoiceRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Restore

export const restoreInvoice = id => ({
    type: actionTypes.RESTORE,
    payload: id,
})

export const restoreInvoiceFulfilled = item => ({
    type: actionTypes.RESTORE_FULFILLED,
    payload: item,
})

export const restoreInvoiceRejected = message => ({
    type: actionTypes.RESTORE_REJECTED,
    payload: message,
})

// Export

export const exportInvoicingInvoices = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Fetch detail

export const fetchInvoiceDetail = invoiceId => ({
    type: actionTypes.FETCH_DETAIL,
    payload: {
        invoiceId,
    },
})

export const fetchInvoiceDetailFulfilled = data => ({
    type: actionTypes.FETCH_DETAIL_FULFILLED,
    payload: {
        data,
    },
})

export const fetchInvoiceDetailRejected = message => ({
    type: actionTypes.FETCH_DETAIL_REJECTED,
    payload: message,
})

export const fetchInvoiceDetailCancelled = () => ({
    type: actionTypes.FETCH_DETAIL_CANCELLED,
})

// Load customer info

export const loadCustomerInfo = customerId => ({
    type: actionTypes.LOAD_CUSTOMER_INFO,
    payload: {
        customerId,
    },
})

export const loadCustomerInfoFulfilled = data => ({
    type: actionTypes.LOAD_CUSTOMER_INFO_FULFILLED,
    payload: {
        data,
    },
})

export const loadCustomerInfoRejected = message => ({
    type: actionTypes.LOAD_CUSTOMER_INFO_REJECTED,
    payload: message,
})

export const loadCustomerInfoCancelled = () => ({
    type: actionTypes.LOAD_CUSTOMER_INFO_CANCELLED,
})

export const clearCustomerInfo = () => ({
    type: actionTypes.CLEAR_CUSTOMER_INFO,
})

// Create credit note

export const createCreditNote = invoiceId => ({
    type: actionTypes.CREATE_CREDIT_NOTE,
    payload: {
        invoiceId,
    },
})

export const createCreditNoteFulfilled = invoice => ({
    type: actionTypes.CREATE_CREDIT_NOTE_FULFILLED,
    payload: {
        invoice,
    },
})

export const createCreditNoteRejected = message => ({
    type: actionTypes.CREATE_CREDIT_NOTE_REJECTED,
    payload: message,
})

export const createCreditNoteCancelled = () => ({
    type: actionTypes.CREATE_CREDIT_NOTE_CANCELLED,
})

// Export to accounting

export const exportInvoicingInvoicesToAccounting = () => ({
    type: actionTypes.EXPORT_TO_ACCOUNTING,
})

export const exportInvoicingInvoicesToAccountingFulfilled = invoice => ({
    type: actionTypes.EXPORT_TO_ACCOUNTING_FULFILLED,
    payload: {
        invoice,
    },
})

export const exportInvoicingInvoicesToAccountingRejected = message => ({
    type: actionTypes.EXPORT_TO_ACCOUNTING_REJECTED,
    payload: message,
})

export const exportInvoicingInvoicesToAccountingCancelled = () => ({
    type: actionTypes.EXPORT_TO_ACCOUNTING_CANCELLED,
})

// Load transport info

export const loadTransportInfo = transportId => ({
    type: actionTypes.LOAD_TRANSPORT_INFO,
    payload: {
        transportId,
    },
})

export const loadTransportInfoFulfilled = data => ({
    type: actionTypes.LOAD_TRANSPORT_INFO_FULFILLED,
    payload: {
        data,
    },
})

export const loadTransportInfoRejected = message => ({
    type: actionTypes.LOAD_TRANSPORT_INFO_REJECTED,
    payload: message,
})

export const loadTransportInfoCancelled = () => ({
    type: actionTypes.LOAD_TRANSPORT_INFO_CANCELLED,
})

// Create invoice PDF

export const createInvoicePdf = (id, values) => ({
    type: actionTypes.CREATE_INVOICE_PDF,
    payload: {
        id,
        values,
    },
})

export const createInvoicePdfFulfilled = data => ({
    type: actionTypes.CREATE_INVOICE_PDF_FULFILLED,
    payload: data,
})

export const createInvoicePdfRejected = message => ({
    type: actionTypes.CREATE_INVOICE_PDF_REJECTED,
    payload: message,
})

export const createInvoicePdfCancelled = () => ({
    type: actionTypes.CREATE_INVOICE_PDF_CANCELLED,
})

// Send invoice to email

export const sendInvoiceToEmail = (id, values) => ({
    type: actionTypes.SEND_INVOICE_TO_EMAIL,
    payload: {
        id,
        values,
    },
})

export const sendInvoiceToEmailFulfilled = () => ({
    type: actionTypes.SEND_INVOICE_TO_EMAIL_FULFILLED,
})

export const sendInvoiceToEmailRejected = message => ({
    type: actionTypes.SEND_INVOICE_TO_EMAIL_REJECTED,
    payload: message,
})

export const sendInvoiceToEmailCancelled = () => ({
    type: actionTypes.SEND_INVOICE_TO_EMAIL_CANCELLED,
})
