import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import { DispatchersBox } from '../../../common/colored_box'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class VehicleEventsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id, Boolean(this.props.data.cost_generator_id))
    }

    render() {
        const cost = this.props.data

        return (
            <tr onClick={this.handleClick} className={'row-selectable'}>
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <React.Fragment>
                            <PermissionsCheck has={[PERMS.UPDATE]}>
                                <button className="far fa-pencil text-gray m-r-xxs" />
                            </PermissionsCheck>
                            <PermissionsCheck has={[PERMS.DELETE]}>
                                <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                            </PermissionsCheck>
                        </React.Fragment>
                    </td>
                </PermissionsCheck>
                <td className="w-120">
                    {cost.date ? formatters.dateFormatter(cost.date) : null}
                    {cost.cost_generator_id && <span className="far fa-repeat m-l-xs" />}
                </td>
                <td className="w-180">{this.props.costType && this.props.costType.name}</td>
                <td className="w-200">{cost.supplier}</td>
                <td className="w-180">{cost.bill_number}</td>
                <td className="w-max wm-300">{cost.note}</td>
                <td className="w-300">
                    {this.props.companyCostCenter && `${this.props.companyCostCenter.code} - ${this.props.companyCostCenter.name}`}
                </td>
                <td className="w-120">
                    {this.props.vehicle ? (
                        <DispatchersBox dispatcher={this.props.dispatcher}>{this.props.vehicle.registration}</DispatchersBox>
                    ) : null}
                </td>
                <td className="w-120">
                    {this.props.trailer ? (
                        <DispatchersBox dispatcher={this.props.trailer}>{this.props.trailer.registration}</DispatchersBox>
                    ) : null}
                </td>
                <td className="w-120">
                    {this.props.driver ? this.props.driver.getShortFullName() : null} {this.props.user ? this.props.user.getShortFullName() : null}
                </td>
                <td className="w-110 text-right">
                    <strong>
                        {formatters.priceFormatter(cost.price, '0,0.00', this.props.currency ? this.props.currency.iso_code : '')}
                    </strong>
                </td>
                <td className="w-110 text-right">
                    {formatters.priceFormatter(cost.price * cost.currency_rate, '0,0.00')}
                </td>
            </tr>
        )
    }
}

export default VehicleEventsListRow
