import * as actionTypes from './actionTypes'

// Fetch

export const fetchBlockedIps = () => ({
    type: actionTypes.FETCH,
})

export const fetchBlockedIpsFulfilled = blockedIps => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: blockedIps,
})

export const fetchBlockedIpsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchBlockedIpsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchBlockedIp = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchBlockedIpFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchBlockedIpRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchBlockedIpCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveBlockedIp = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveBlockedIpFulfilled = blockedIp => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: blockedIp,
})

export const saveBlockedIpRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteBlockedIp = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteBlockedIpFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteBlockedIpRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportBlockedIps = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
