import * as actionTypes from './actionTypes'

// Fetch

export const fetchPaymentMethods = () => ({
    type: actionTypes.FETCH,
})

export const fetchPaymentMethodsFulfilled = paymentMethods => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: paymentMethods,
})

export const fetchPaymentMethodsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchPaymentMethodsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchPaymentMethod = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchPaymentMethodFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchPaymentMethodRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchPaymentMethodCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const savePaymentMethod = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const savePaymentMethodFulfilled = paymentMethod => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: paymentMethod,
})

export const savePaymentMethodRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deletePaymentMethod = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deletePaymentMethodFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deletePaymentMethodRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportPaymentMethods = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
