// Fetch list

export const FETCH = 'vehicleEquipments.FETCH'
export const FETCH_FOR_MAP = 'vehicleEquipments.FETCH_FOR_MAP'
export const FETCH_STARTED = 'vehicleEquipments.FETCH_STARTED'
export const FETCH_FULFILLED = 'vehicleEquipments.FETCH_FULFILLED'
export const FETCH_REJECTED = 'vehicleEquipments.FETCH_REJECTED'
export const FETCH_CANCELLED = 'vehicleEquipments.FETCH_CANCELLED'

export const SORTING_CHANGED = 'vehicleEquipments.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'vehicleEquipments.FETCH_ONE'
export const FETCH_ONE_STARTED = 'vehicleEquipments.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'vehicleEquipments.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'vehicleEquipments.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'vehicleEquipments.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'vehicleEquipments.SAVE'
export const SAVE_STARTED = 'vehicleEquipments.SAVE_STARTED'
export const SAVE_FULFILLED = 'vehicleEquipments.SAVE_FULFILLED'
export const SAVE_REJECTED = 'vehicleEquipments.SAVE_REJECTED'
export const SAVE_CANCELLED = 'vehicleEquipments.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'vehicleEquipments.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'vehicleEquipments.DELETE'
export const DELETE_STARTED = 'vehicleEquipments.DELETE_STARTED'
export const DELETE_FULFILLED = 'vehicleEquipments.DELETE_FULFILLED'
export const DELETE_REJECTED = 'vehicleEquipments.DELETE_REJECTED'
export const DELETE_CANCELLED = 'vehicleEquipments.DELETE_CANCELLED'

// Clear

export const CLEAR = 'vehicleEquipments.CLEAR'

// Export

export const EXPORT = 'vehicleEquipments.EXPORT'
export const EXPORT_FULFILLED = 'vehicleEquipments.EXPORT_FULFILLED'
