import { Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    data: Map({
        items: OrderedMap({}),
        rules: OrderedMap({}),
        transportScoringRules: OrderedMap({}),
        fetching: false,
        error: null,
    }),
    question: Map({
        saving: false,
        deleting: false,
        movingUp: false,
        movingDown: false,
        error: null,
    }),
    answer: Map({
        saving: false,
        deleting: false,
        movingUp: false,
        movingDown: false,
        error: null,
    }),
    rule: Map({
        saving: false,
        deleting: false,
        error: null,
    }),
})

function carrierScoring(state = initialState, action) {
    switch (action.type) {
        // Fetch

        case actionTypes.FETCH_STARTED:
            return state.setIn(['data', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['data', 'items'], action.payload.items)
                    .setIn(['data', 'rules'], action.payload.rules)
                    .setIn(['data', 'transportScoringRules'], action.payload.transportScoringRules)
                    .setIn(['data', 'fetching'], false)
                    .setIn(['data', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['data', 'fetching'], false).setIn(['data', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['data', 'fetching'], false)

        // Save question

        case actionTypes.SAVE_QUESTION_STARTED:
            return state.withMutations(map => {
                map.setIn(['question', 'saving'], true).setIn(['question', 'error'], null)
            })
        case actionTypes.SAVE_QUESTION_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['question', 'saving'], false)
                    .setIn(['question', 'error'], null)
                    .setIn(['data', 'items'], action.payload.items)
                    .setIn(['data', 'rules'], action.payload.rules)
                    .setIn(['data', 'transportScoringRules'], action.payload.transportScoringRules)
            })
        case actionTypes.SAVE_QUESTION_REJECTED:
            return state.withMutations(map => {
                map.setIn(['question', 'saving'], false).setIn(['question', 'error'], action.payload)
            })
        case actionTypes.SAVE_QUESTION_CANCELLED:
            return state.setIn(['question', 'saving'], false)

        // Delete question

        case actionTypes.DELETE_QUESTION_STARTED:
            return state.withMutations(map => {
                map.setIn(['question', 'deleting'], true).setIn(['question', 'error'], null)
            })
        case actionTypes.DELETE_QUESTION_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['question', 'deleting'], false)
                    .setIn(['question', 'error'], null)
                    .setIn(['data', 'items'], action.payload.items)
                    .setIn(['data', 'rules'], action.payload.rules)
                    .setIn(['data', 'transportScoringRules'], action.payload.transportScoringRules)
            })
        case actionTypes.DELETE_QUESTION_REJECTED:
            return state.withMutations(map => {
                map.setIn(['question', 'deleting'], false).setIn(['question', 'error'], action.payload)
            })
        case actionTypes.DELETE_QUESTION_CANCELLED:
            return state.setIn(['question', 'deleting'], false)

        // Move question UP

        case actionTypes.MOVE_QUESTION_UP_STARTED:
            return state.withMutations(map => {
                map.setIn(['question', 'movingUp'], true).setIn(['movingUp', 'error'], null)
            })
        case actionTypes.MOVE_QUESTION_UP_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['question', 'movingUp'], false)
                    .setIn(['question', 'error'], null)
                    .setIn(['data', 'items'], action.payload.items)
                    .setIn(['data', 'rules'], action.payload.rules)
                    .setIn(['data', 'transportScoringRules'], action.payload.transportScoringRules)
            })
        case actionTypes.MOVE_QUESTION_UP_REJECTED:
            return state.withMutations(map => {
                map.setIn(['question', 'movingUp'], false).setIn(['question', 'error'], action.payload)
            })
        case actionTypes.MOVE_QUESTION_UP_CANCELLED:
            return state.setIn(['question', 'movingUp'], false)

        // Move question DOWN

        case actionTypes.MOVE_QUESTION_DOWN_STARTED:
            return state.withMutations(map => {
                map.setIn(['question', 'movingDown'], true).setIn(['movingDown', 'error'], null)
            })
        case actionTypes.MOVE_QUESTION_DOWN_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['question', 'movingDown'], false)
                    .setIn(['question', 'error'], null)
                    .setIn(['data', 'items'], action.payload.items)
                    .setIn(['data', 'rules'], action.payload.rules)
                    .setIn(['data', 'transportScoringRules'], action.payload.transportScoringRules)
            })
        case actionTypes.MOVE_QUESTION_DOWN_REJECTED:
            return state.withMutations(map => {
                map.setIn(['question', 'movingDown'], false).setIn(['question', 'error'], action.payload)
            })
        case actionTypes.MOVE_QUESTION_DOWN_CANCELLED:
            return state.setIn(['question', 'movingDown'], false)

        // Save answer

        case actionTypes.SAVE_ANSWER_STARTED:
            return state.withMutations(map => {
                map.setIn(['answer', 'saving'], true).setIn(['answer', 'error'], null)
            })
        case actionTypes.SAVE_ANSWER_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['answer', 'saving'], false)
                    .setIn(['answer', 'error'], null)
                    .setIn(['data', 'items'], action.payload.items)
                    .setIn(['data', 'rules'], action.payload.rules)
                    .setIn(['data', 'transportScoringRules'], action.payload.transportScoringRules)
            })
        case actionTypes.SAVE_ANSWER_REJECTED:
            return state.withMutations(map => {
                map.setIn(['answer', 'saving'], false).setIn(['answer', 'error'], action.payload)
            })
        case actionTypes.SAVE_ANSWER_CANCELLED:
            return state.setIn(['answer', 'saving'], false)

        // Delete answer

        case actionTypes.DELETE_ANSWER_STARTED:
            return state.withMutations(map => {
                map.setIn(['answer', 'deleting'], true).setIn(['answer', 'error'], null)
            })
        case actionTypes.DELETE_ANSWER_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['answer', 'deleting'], false)
                    .setIn(['answer', 'error'], null)
                    .setIn(['data', 'items'], action.payload.items)
                    .setIn(['data', 'rules'], action.payload.rules)
                    .setIn(['data', 'transportScoringRules'], action.payload.transportScoringRules)
            })
        case actionTypes.DELETE_ANSWER_REJECTED:
            return state.withMutations(map => {
                map.setIn(['answer', 'deleting'], false).setIn(['answer', 'error'], action.payload)
            })
        case actionTypes.DELETE_ANSWER_CANCELLED:
            return state.setIn(['answer', 'deleting'], false)

        // Move answer UP

        case actionTypes.MOVE_ANSWER_UP_STARTED:
            return state.withMutations(map => {
                map.setIn(['answer', 'movingUp'], true).setIn(['movingUp', 'error'], null)
            })
        case actionTypes.MOVE_ANSWER_UP_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['answer', 'movingUp'], false)
                    .setIn(['answer', 'error'], null)
                    .setIn(['data', 'items'], action.payload.items)
                    .setIn(['data', 'rules'], action.payload.rules)
                    .setIn(['data', 'transportScoringRules'], action.payload.transportScoringRules)
            })
        case actionTypes.MOVE_ANSWER_UP_REJECTED:
            return state.withMutations(map => {
                map.setIn(['answer', 'movingUp'], false).setIn(['answer', 'error'], action.payload)
            })
        case actionTypes.MOVE_ANSWER_UP_CANCELLED:
            return state.setIn(['answer', 'movingUp'], false)

        // Move answer DOWN

        case actionTypes.MOVE_ANSWER_DOWN_STARTED:
            return state.withMutations(map => {
                map.setIn(['answer', 'movingDown'], true).setIn(['movingDown', 'error'], null)
            })
        case actionTypes.MOVE_ANSWER_DOWN_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['answer', 'movingDown'], false)
                    .setIn(['answer', 'error'], null)
                    .setIn(['data', 'items'], action.payload.items)
                    .setIn(['data', 'rules'], action.payload.rules)
                    .setIn(['data', 'transportScoringRules'], action.payload.transportScoringRules)
            })
        case actionTypes.MOVE_ANSWER_DOWN_REJECTED:
            return state.withMutations(map => {
                map.setIn(['answer', 'movingDown'], false).setIn(['answer', 'error'], action.payload)
            })
        case actionTypes.MOVE_ANSWER_DOWN_CANCELLED:
            return state.setIn(['answer', 'movingDown'], false)

        // Save rule

        case actionTypes.SAVE_RULE_STARTED:
            return state.withMutations(map => {
                map.setIn(['rule', 'saving'], true).setIn(['rule', 'error'], null)
            })
        case actionTypes.SAVE_RULE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['rule', 'saving'], false)
                    .setIn(['rule', 'error'], null)
                    .setIn(['data', 'items'], action.payload.items)
                    .setIn(['data', 'rules'], action.payload.rules)
                    .setIn(['data', 'transportScoringRules'], action.payload.transportScoringRules)
            })
        case actionTypes.SAVE_RULE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['rule', 'saving'], false).setIn(['rule', 'error'], action.payload)
            })
        case actionTypes.SAVE_RULE_CANCELLED:
            return state.setIn(['rule', 'saving'], false)

        // Delete rule

        case actionTypes.DELETE_RULE_STARTED:
            return state.withMutations(map => {
                map.setIn(['rule', 'deleting'], true).setIn(['rule', 'error'], null)
            })
        case actionTypes.DELETE_RULE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['rule', 'deleting'], false)
                    .setIn(['rule', 'error'], null)
                    .setIn(['data', 'items'], action.payload.items)
                    .setIn(['data', 'rules'], action.payload.rules)
                    .setIn(['data', 'transportScoringRules'], action.payload.transportScoringRules)
            })
        case actionTypes.DELETE_RULE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['rule', 'deleting'], false).setIn(['rule', 'error'], action.payload)
            })
        case actionTypes.DELETE_RULE_CANCELLED:
            return state.setIn(['rule', 'deleting'], false)

        // Default

        default:
            return state
    }
}

export default carrierScoring
