import React, { Component } from 'react'
import ArrowField from './ArrowField'

class YearPicker extends Component {
    onClick = years => {
        const momentFrom = this.props.momentFrom.add(years, 'years')
        const momentTo = this.props.momentTo.add(years, 'years').endOf('month')

        this.props.onClick(momentFrom, momentTo)
    }

    render() {
        const year = this.props.momentFrom.year()
        const isMin = this.props.minYear === year
        const isMax = this.props.maxYear === year

        return <ArrowField onClick={this.onClick} value={year} isMin={isMin} isMax={isMax} />
    }
}

export default YearPicker
