import { Map, ReceivedInvoiceedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: [],
        fetching: false,
        error: null,
        duplicities: null,
    }),
    current: Map({
        object: null,
        supportData: null,
        fetching: false,
        saving: false,
        error: null,
    }),
    deleting: Map({
        inProgress: false,
        error: null,
    }),
    restoring: Map({
        inProgress: false,
        error: null,
    }),
    detail: Map({
        data: {},
        fetching: false,
        error: null,
    }),
    supplierInfo: Map({
        data: {},
        fetching: false,
        error: null,
    }),
    approve: Map({
        inProgress: false,
        error: null,
    }),
    setAsPaid: Map({
        inProgress: false,
        error: null,
    }),
    setAsUnpaid: Map({
        inProgress: false,
        error: null,
    }),
    transportInfo: Map({
        data: {},
        fetching: false,
        error: null,
    }),
    createCreditNote: Map({
        data: null,
        fetching: false,
        error: null,
    }),
    files: Map({
        objects: [],
        fetching: false,
        saving: false,
        deleting: false,
        error: null,
    }),
    exportToAccounting: Map({
        inProgress: false,
        error: null,
    }),
    checkingDuplicity: Map({
        data: [],
        inProgress: false,
        error: null,
    }),
})

function invoicingReceivedInvoices(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], action.payload.data)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // Clear
        case actionTypes.CLEAR:
            return state.setIn(['all', 'objects'], ReceivedInvoiceedMap({}))
        case actionTypes.CLEAR_CURRENT:
            return state.setIn(['current', 'object'], null)

        // One
        case actionTypes.FETCH_ONE_STARTED:
            return state.setIn(['current', 'fetching'], true)
        case actionTypes.FETCH_ONE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['current', 'object'], action.payload.receivedInvoice)
                    .setIn(['current', 'supportData'], action.payload.supportData)
                    .setIn(['current', 'fetching'], false)
                    .setIn(['current', 'error'], null)
            })
        case actionTypes.FETCH_ONE_REJECTED:
        case actionTypes.FETCH_ONE_CANCELLED:
            return state.setIn(['current', 'fetching'], false)

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], true)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicities'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['current', 'object'], action.payload.receivedInvoice)
                    .setIn(['current', 'supportData'], action.payload.supportData)
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicities'], null)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], action.payload)
                    .setIn(['all', 'duplicities'], null)
            })
        case actionTypes.SAVE_DUPLICITY_FOUND:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicities'], action.payload)
            })

        // Delete
        case actionTypes.DELETE_STARTED:
            return state.setIn(['deleting', 'inProgress'], true)
        case actionTypes.DELETE_FULFILLED:
            return state.withMutations(map => {
                const objects = state.getIn(['all', 'objects']).filter(item => item.id !== action.payload)
                map.setIn(['all', 'objects'], objects)
                    .setIn(['deleting', 'inProgress'], false)
                    .setIn(['deleting', 'error'], null)
            })
        case actionTypes.DELETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deleting', 'inProgress'], false).setIn(['deleting', 'error'], action.payload)
            })

        // Restore
        case actionTypes.RESTORE_STARTED:
            return state.setIn(['restoring', 'inProgress'], true)
        case actionTypes.RESTORE_FULFILLED:
            return state.withMutations(map => {
                console.log(action.payload)
                map.setIn(['restoring', 'inProgress'], false)
                    .setIn(['restoring', 'error'], null)
                    .setIn(['current', 'object'], action.payload)
            })
        case actionTypes.RESTORE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['restoring', 'inProgress'], false).setIn(['restoring', 'error'], action.payload)
            })


        // Fetch detail
        case actionTypes.FETCH_DETAIL_STARTED:
            return state.setIn(['detail', 'fetching'], true)
        case actionTypes.FETCH_DETAIL_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['detail', 'data'], action.payload.data)
                    .setIn(['detail', 'fetching'], false)
                    .setIn(['detail', 'error'], null)
            })
        case actionTypes.FETCH_DETAIL_REJECTED:
            return state.withMutations(map => {
                map.setIn(['detail', 'fetching'], false).setIn(['detail', 'error'], action.payload)
            })
        case actionTypes.FETCH_DETAIL_CANCELLED:
            return state.setIn(['detail', 'fetching'], false)

        // Load supplier info
        case actionTypes.LOAD_SUPPLIER_INFO_STARTED:
            return state.setIn(['supplierInfo', 'fetching'], true)
        case actionTypes.LOAD_SUPPLIER_INFO_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['supplierInfo', 'data'], action.payload.data)
                    .setIn(['supplierInfo', 'fetching'], false)
                    .setIn(['supplierInfo', 'error'], null)
            })
        case actionTypes.LOAD_SUPPLIER_INFO_REJECTED:
            return state.withMutations(map => {
                map.setIn(['supplierInfo', 'fetching'], false).setIn(['supplierInfo', 'error'], action.payload)
            })
        case actionTypes.LOAD_SUPPLIER_INFO_CANCELLED:
            return state.setIn(['supplierInfo', 'fetching'], false)
        case actionTypes.CLEAR_SUPPLIER_INFO:
            return state.setIn(['supplierInfo', 'data'], {})

        // Approve
        case actionTypes.APPROVE_STARTED:
            return state.setIn(['approve', 'inProgress'], true)
        case actionTypes.APPROVE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], action.payload.data)
                    .setIn(['approve', 'inProgress'], false)
                    .setIn(['approve', 'error'], null)
            })
        case actionTypes.APPROVE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['approve', 'inProgress'], false).setIn(['approve', 'error'], action.payload)
            })

        // Set as paid
        case actionTypes.SET_AS_PAID_STARTED:
            return state.setIn(['setAsPaid', 'inProgress'], true)
        case actionTypes.SET_AS_PAID_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], action.payload.data)
                    .setIn(['setAsPaid', 'inProgress'], false)
                    .setIn(['setAsPaid', 'error'], null)
            })
        case actionTypes.SET_AS_PAID_REJECTED:
            return state.withMutations(map => {
                map.setIn(['setAsPaid', 'inProgress'], false).setIn(['setAsPaid', 'error'], action.payload)
            })

        // Set as unpaid
        case actionTypes.SET_AS_UNPAID_STARTED:
            return state.setIn(['setAsUnpaid', 'inProgress'], true)
        case actionTypes.SET_AS_UNPAID_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], action.payload.data)
                    .setIn(['setAsUnpaid', 'inProgress'], false)
                    .setIn(['setAsUnpaid', 'error'], null)
            })
        case actionTypes.SET_AS_UNPAID_REJECTED:
            return state.withMutations(map => {
                map.setIn(['setAsUnpaid', 'inProgress'], false).setIn(['setAsUnpaid', 'error'], action.payload)
            })

        // Load transport info
        case actionTypes.LOAD_TRANSPORT_INFO_STARTED:
            return state.setIn(['transportInfo', 'fetching'], true)
        case actionTypes.LOAD_TRANSPORT_INFO_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['transportInfo', 'data'], action.payload.data)
                    .setIn(['transportInfo', 'fetching'], false)
                    .setIn(['transportInfo', 'error'], null)
            })
        case actionTypes.LOAD_TRANSPORT_INFO_REJECTED:
            return state.withMutations(map => {
                map.setIn(['transportInfo', 'fetching'], false).setIn(['transportInfo', 'error'], action.payload)
            })
        case actionTypes.LOAD_TRANSPORT_INFO_CANCELLED:
            return state.setIn(['transportInfo', 'fetching'], false)
        case actionTypes.CLEAR_TRANSPORT_INFO:
            return state.setIn(['transportInfo', 'data'], {})

        // Create credit note
        case actionTypes.CREATE_CREDIT_NOTE_STARTED:
            return state.setIn(['createCreditNote', 'fetching'], true)
        case actionTypes.CREATE_CREDIT_NOTE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['createCreditNote', 'data'], action.payload.invoice)
                    .setIn(['createCreditNote', 'fetching'], false)
                    .setIn(['createCreditNote', 'error'], null)
            })
        case actionTypes.CREATE_CREDIT_NOTE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['createCreditNote', 'fetching'], false).setIn(['createCreditNote', 'error'], action.payload)
            })
        case actionTypes.CREATE_CREDIT_NOTE_CANCELLED:
            return state.setIn(['createCreditNote', 'fetching'], false)

        // Fetch files
        case actionTypes.FETCH_FILES_STARTED:
            return state.setIn(['files', 'fetching'], true)
        case actionTypes.FETCH_FILES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['files', 'fetching'], false)
                    .setIn(['files', 'error'], null)
                    .setIn(['files', 'objects'], action.payload)
            })
        case actionTypes.FETCH_FILES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['files', 'fetching'], false).setIn(['files', 'error'], action.payload)
            })
        case actionTypes.FETCH_FILES_CANCELLED:
            return state.setIn(['files', 'fetching'], false)

        // Upload files
        case actionTypes.UPLOAD_FILES_STARTED:
            return state.setIn(['files', 'saving'], true)
        case actionTypes.UPLOAD_FILES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['files', 'saving'], false)
                    .setIn(['files', 'error'], null)
                    .setIn(['files', 'objects'], action.payload)
            })
        case actionTypes.UPLOAD_FILES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['files', 'saving'], false).setIn(['files', 'error'], action.payload)
            })
        case actionTypes.UPLOAD_FILES_CANCELLED:
            return state.setIn(['files', 'saving'], false)

        // Delete file
        case actionTypes.DELETE_FILE_STARTED:
            return state.setIn(['files', 'deleting'], true)
        case actionTypes.DELETE_FILE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['files', 'deleting'], false)
                    .setIn(['files', 'error'], null)
                    .setIn(['files', 'objects'], action.payload)
            })
        case actionTypes.DELETE_FILE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['files', 'deleting'], false).setIn(['files', 'error'], action.payload)
            })
        case actionTypes.DELETE_FILE_CANCELLED:
            return state.setIn(['files', 'deleting'], false)

        // Export to accounting
        case actionTypes.EXPORT_TO_ACCOUNTING_STARTED:
            return state.setIn(['exportToAccounting', 'inProgress'], true)
        case actionTypes.EXPORT_TO_ACCOUNTING_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['exportToAccounting', 'inProgress'], false).setIn(['exportToAccounting', 'error'], null)
            })
        case actionTypes.EXPORT_TO_ACCOUNTING_REJECTED:
            return state.withMutations(map => {
                map.setIn(['exportToAccounting', 'inProgress'], false).setIn(['exportToAccounting', 'error'], action.payload)
            })

        // Check duplicity
        case actionTypes.CHECK_DUPLICITY_STARTED:
            return state.setIn(['checkingDuplicity', 'inProgress'], true)
        case actionTypes.CHECK_DUPLICITY_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['checkingDuplicity', 'inProgress'], false)
                    .setIn(['checkingDuplicity', 'error'], null)
                    .setIn(['checkingDuplicity', 'data'], action.payload)
            })
        case actionTypes.CHECK_DUPLICITY_REJECTED:
            return state.withMutations(map => {
                map.setIn(['checkingDuplicity', 'inProgress'], false).setIn(['checkingDuplicity', 'error'], action.payload)
            })

        default:
            return state
    }
}

export default invoicingReceivedInvoices
