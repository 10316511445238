// Fetch list

export const FETCH = 'reportCarrierScoring.FETCH'
export const FETCH_STARTED = 'reportCarrierScoring.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportCarrierScoring.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportCarrierScoring.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportCarrierScoring.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportCarrierScoring.CLEAR'

// Fetch

export const FETCH_SCORING = 'reportCarrierScoring.FETCH_SCORING'
export const FETCH_SCORING_STARTED = 'reportCarrierScoring.FETCH_SCORING_STARTED'
export const FETCH_SCORING_FULFILLED = 'reportCarrierScoring.FETCH_SCORING_FULFILLED'
export const FETCH_SCORING_REJECTED = 'reportCarrierScoring.FETCH_SCORING_REJECTED'
export const FETCH_SCORING_CANCELLED = 'reportCarrierScoring.FETCH_SCORING_CANCELLED'

// Save scoring

export const SAVE_SCORING = 'reportCarrierScoring.SAVE_SCORING'
export const SAVE_SCORING_STARTED = 'reportCarrierScoring.SAVE_SCORING_STARTED'
export const SAVE_SCORING_FULFILLED = 'reportCarrierScoring.SAVE_SCORING_FULFILLED'
export const SAVE_SCORING_REJECTED = 'reportCarrierScoring.SAVE_SCORING_REJECTED'
export const SAVE_SCORING_CANCELLED = 'reportCarrierScoring.SAVE_SCORING_CANCELLED'
