import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { customerSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import * as countriesActionCreators from '../countries/actionCreators'
import * as customerAddressesActionCreators from '../customer_address/actionCreators'
import * as currenciesActionCreators from '../currencies/actionCreators'
import * as usersActionCreators from '../users/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { Country } from '../countries/model'
import { CustomerAddress } from '../customer_address/model'
import { Currency } from '../currencies/model'
import { User } from '../users/model'
import { Customer } from './model'
import * as actionCreators from './actionCreators'
import * as actionTypes from './actionTypes'
import {fromJS} from "immutable";

// Fetch

const fetchCustomersEpic = (action$, store) => {
    const tableIdentifier = 'customers_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `customers/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(customerSchema)
                        )

                        const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer, normalizedData.result)
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                        const customerAddresses = normalizedEntitiesToRecordMap(normalizedData.entities.customer_addresses, CustomerAddress)
                        const currencies = normalizedEntitiesToRecordMap(normalizedData.entities.currencies, Currency)
                        const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)

                        const observables = [
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(customerAddressesActionCreators.fetchCustomerAddressesFulfilled(customerAddresses)),
                            Observable.of(currenciesActionCreators.fetchCurrenciesFulfilled(currencies)),
                            Observable.of(usersActionCreators.fetchUsersFulfilled(users)),
                            Observable.of(actionCreators.fetchCustomersFulfilled(customers)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchCustomersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchCustomerEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().customers.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `customers/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, customerSchema)

                        const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                        const customerAddresses = normalizedEntitiesToRecordMap(normalizedData.entities.customer_addresses, CustomerAddress)
                        const currencies = normalizedEntitiesToRecordMap(normalizedData.entities.currencies, Currency)
                        const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchCustomersFulfilled(customers)),
                            Observable.of(actionCreators.fetchCustomerFulfilled()),
                            Observable.of(customerAddressesActionCreators.fetchCustomerAddressesFulfilled(customerAddresses)),
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(usersActionCreators.fetchUsersFulfilled(users)),
                            Observable.of(currenciesActionCreators.fetchCurrenciesFulfilled(currencies))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchCustomersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveCustomerEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().customers.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = { ...action.payload.values }
            if (action.payload.ignoreDuplicity) {
                values.ignore_duplicity = 1
            }

            let path = `customers`
            let method = 'POST'

            if (values.id) {
                path = `customers/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        if (ajaxResponse.response.duplicity_found) {
                            return Observable.concat(Observable.of(actionCreators.saveCustomerDuplicityFound(ajaxResponse.response.duplicity)))
                        }

                        const normalizedData = normalize(ajaxResponse.response, customerSchema)

                        const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)
                        const customer = customers.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveCustomerFulfilled(customer)))
                    })
                    .catch(error => Observable.of(actionCreators.saveCustomerRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteCustomerEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().customers.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `customers/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteCustomerFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteCustomerRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )


// Restore

const restoreCustomerEpic = (action$, store) =>
    action$
        .ofType(actionTypes.RESTORE)
        .filter(() => !store.getState().customers.getIn(['restoring', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.RESTORE_STARTED,
                }),
                request({
                    path: `customers/${action.payload}/restore`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, customerSchema)
                        const items = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)
                        const item = items.valueSeq().first()
                        return Observable.concat(Observable.of(actionCreators.restoreCustomerFulfilled(item)))
                    })
                    .catch(error => Observable.of(actionCreators.restoreCustomerRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.RESTORE_CANCELLED))
            )
        )

// Load info by VAT

const loadInfoByVATEpic = (action$, store) =>
    action$
        .ofType(actionTypes.LOAD_INFO)
        .filter(() => !store.getState().customers.getIn(['infoByVAT', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.LOAD_INFO_STARTED,
                }),
                request({
                    path: `customers/find-info?vat_number=${action.payload}`,
                    method: 'POST',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.loadInfoByVATNumberFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.loadInfoByVATNumberRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.LOAD_INFO_CANCELLED))
            )
        )

// Export

const exportCustomersEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `customers/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Addresses

const fetchCustomerAddressesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ADDRESSES)
        .filter(() => !store.getState().customers.getIn(['addresses', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ADDRESSES_STARTED,
                }),
                request({
                    path: `customers/${action.payload.id}/addresses${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchCustomerAddressesFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchCustomerAddressesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ADDRESSES_CANCELLED))
            )
        )

const exportCustomerAddressesEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_ADDRESSES).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `customers/${action.payload}/addresses/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_ADDRESSES_FULFILLED,
            })
        )
    })

// Contacts

const fetchCustomerContactsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_CONTACTS)
        .filter(() => !store.getState().customers.getIn(['contacts', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_CONTACTS_STARTED,
                }),
                request({
                    path: `customers/${action.payload.id}/contacts${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchCustomerContactsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchCustomerContactsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CONTACTS_CANCELLED))
            )
        )

const exportCustomerContactsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_CONTACTS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `customers/${action.payload}/contacts/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_CONTACTS_FULFILLED,
            })
        )
    })

// Plans

const fetchCustomerPlansEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_PLANS)
        .filter(() => !store.getState().customers.getIn(['plans', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_PLANS_STARTED,
                }),
                request({
                    path: `customers/${action.payload.id}/plans${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchCustomerPlansFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchCustomerPlansRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_PLANS_CANCELLED))
            )
        )

const exportCustomerPlansEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_PLANS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `customers/${action.payload}/plans/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_PLANS_FULFILLED,
            })
        )
    })

// Check duplicity

const checkCustomerDuplicityEpic = (action$, store) =>
    action$
        .ofType(actionTypes.CHECK_DUPLICITY)
        .filter(() => !store.getState().customers.getIn(['checkingDuplicity', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.CHECK_DUPLICITY_STARTED,
                }),
                request({
                    path: `customers/check-duplicity`,
                    method: 'POST',
                    body: action.payload,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.checkCustomerDuplicityFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.checkCustomerDuplicityRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.CHECK_DUPLICITY_CANCELLED))
            )
        )


// Fetch import headers

const fetchImportHeadersEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH_IMPORT_HEADERS)
        .filter(() => !store.getState().customers.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORT_HEADERS_STARTED,
                }),
                request({
                    path: `customers/import/get-headers`,
                    method: 'POST',
                    body: {
                        file: action.payload,
                    },
                })
                    .switchMap(ajaxResponse => {
                        const importHeaders = {
                            file_id: ajaxResponse.response.file_id,
                            headers: fromJS(ajaxResponse.response.headers),
                            headers_default: fromJS(ajaxResponse.response.headers_default),
                        }
                        return Observable.of(actionCreators.fetchCustomersImportHeadersFulfilled(importHeaders))
                    })
                    .catch(error => Observable.of(actionCreators.fetchCustomersImportHeadersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_HEADERS_CANCELLED))
            )
        )
}

// Fetch import items

const fetchImportItemsEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH_IMPORT_ITEMS)
        .filter(() => !store.getState().customers.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORT_ITEMS_STARTED,
                }),
                request({
                    path: `customers/import/get-items`,
                    method: 'POST',
                    body: {
                        fileId: action.payload.fileId,
                        columnTypes: action.payload.columnTypes,
                    },
                })
                    .switchMap(ajaxResponse => {
                        return Observable.of(actionCreators.fetchCustomersImportItemsFulfilled(ajaxResponse.response.items))
                    })
                    .catch(error => Observable.of(actionCreators.fetchCustomersImportItemsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_ITEMS_CANCELLED))
            )
        )
}

// Import

const importCustomersEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.IMPORT_CUSTOMERS)
        .filter(() => !store.getState().customers.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.IMPORT_CUSTOMERS_STARTED,
                }),
                request({
                    path: `customers/import/import`,
                    method: 'POST',
                    body: {
                        fileId: action.payload.fileId,
                        columnTypes: action.payload.columnTypes,
                        keys: action.payload.keys,
                    },
                })
                    .switchMap(ajaxResponse => {
                        return Observable.of(actionCreators.importCustomersFulfilled())
                    })
                    .catch(error => Observable.of(actionCreators.importCustomersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.IMPORT_CUSTOMERS_CANCELLED))
            )
        )
}

export default [
    fetchCustomersEpic,
    fetchCustomerEpic,
    saveCustomerEpic,
    deleteCustomerEpic,
    restoreCustomerEpic,
    loadInfoByVATEpic,
    exportCustomersEpic,
    fetchCustomerAddressesEpic,
    exportCustomerAddressesEpic,
    fetchCustomerContactsEpic,
    exportCustomerContactsEpic,
    fetchCustomerPlansEpic,
    exportCustomerPlansEpic,
    checkCustomerDuplicityEpic,
    fetchImportHeadersEpic,
    fetchImportItemsEpic,
    importCustomersEpic
]
