// Fetch one

export const FETCH = 'user_profile.FETCH'
export const FETCH_STARTED = 'user_profile.FETCH_STARTED'
export const FETCH_FULFILLED = 'user_profile.FETCH_FULFILLED'
export const FETCH_REJECTED = 'user_profile.FETCH_REJECTED'
export const FETCH_CANCELLED = 'user_profile.FETCH_CANCELLED'

// Save

export const SAVE = 'user_profile.SAVE'
export const SAVE_STARTED = 'user_profile.SAVE_STARTED'
export const SAVE_FULFILLED = 'user_profile.SAVE_FULFILLED'
export const SAVE_REJECTED = 'user_profile.SAVE_REJECTED'
export const SAVE_CANCELLED = 'user_profile.SAVE_CANCELLED'
