import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportTransportInvoicing = filters => ({
    type: actionTypes.FETCH,
    payload: {
        filters,
    },
})

export const fetchReportTransportInvoicingFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: {
        data,
    },
})

export const fetchReportTransportInvoicingRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportTransportInvoicingCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportTransportInvoicing = () => ({
    type: actionTypes.CLEAR,
})

// Set transport prices

export const setTransportPrices = (transportIds, priceForKm, priceFix, erasePriceToll) => ({
    type: actionTypes.SET_TRANSPORT_PRICES,
    payload: {
        transportIds,
        priceForKm,
        priceFix,
        erasePriceToll
    },
})

export const setTransportPricesFulfilled = data => ({
    type: actionTypes.SET_TRANSPORT_PRICES_FULFILLED,
    payload: {
        data,
    },
})

export const setTransportPricesRejected = message => ({
    type: actionTypes.SET_TRANSPORT_PRICES_REJECTED,
    payload: message,
})