import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import { getIsAuthenticated, getAuthInProgress, getPermissionsLoaded, getAuthError } from '../selectors'
import * as actionCreators from '../actionCreators'
import * as config from '../../../common/config'
import { LoadingOverlay } from '../../../common/loading_overlay'

class AuthenticationCheck extends Component {
    performChecks = props => {
        if (props.isAuthenticated === false) {
            this.redirectToLogin()

            return
        }

        if (props.authInProgress === false && props.permissionsLoaded === false) {
            this.props.actions.fetchPermissions()
        }
    }

    redirectToLogin = () => {
        this.props.redirect('/auth/login')
    }

    componentWillMount() {
        this.performChecks(this.props)
    }

    componentWillReceiveProps(nextProps) {
        // If this if passes something is really weird
        if (this.props.authInProgress && !nextProps.authInProgress && nextProps.authError !== null) {
            this.props.actions.logout()
            this.redirectToLogin()
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: this.props.intl.formatMessage({ id: 'alerts.messages.loginFailed' }),
                    position: 'tc',
                },
                'error'
            )
        }

        this.performChecks(nextProps)
    }

    render() {
        if (this.props.authInProgress === true) {
            return (
                <div className="login-container" style={{ backgroundImage: `url(/images/${config.CFG_APP_BACKGROUND_IMAGE})` }}>
                    <div className="login-overlay" />
                    <div className="row" style={{ marginLeft: '0', marginRight: '0' }}>
                        <div className="col-md-3 center">
                            <div className="login-box">
                                <div className="login-box-overlay" />
                                <div className="login-box-inner">
                                    <h1>{config.CFG_APP_NAME}</h1>
                                    <h2>
                                        <FormattedMessage id="login.title" /> {config.CFG_VERSION}
                                    </h2>
                                    <LoadingOverlay active={1} transparent>
                                        <div style={{ minHeight: '150px' }} />
                                    </LoadingOverlay>
                                    <p className="text-center m-t-xs text-sm">
                                        {new Date().getFullYear()} &copy; <FormattedMessage id="login.copyright" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return this.props.children
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: getIsAuthenticated(state),
        authInProgress: getAuthInProgress(state),
        permissionsLoaded: getPermissionsLoaded(state),
        authError: getAuthError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AuthenticationCheck)
)
