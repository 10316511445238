import { Record } from 'immutable'

export class WarehousingItem extends Record({
    id: undefined,
    code: undefined,
    name: undefined,
    unit_id: undefined,
    price: undefined,
    currency_id: undefined,
    currency_rate: undefined,
    stock_info: undefined,
    issued_count: undefined,
    receipt_count: undefined,
    receipts: undefined,
    issues: undefined
}) {}
