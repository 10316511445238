import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import Notifications from 'react-notification-system-redux'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { Form, CheckboxField } from '../../../common/form'

import * as actionCreators from '../actionCreators'
import { createGetSettingsSelector, createGetLoadingSettings, createGetSavingSettings } from '../selectors'

class WSNotificationSettingModal extends Component {
    defaultValues = []

    validationRules = {}

    state = {
        values: this.defaultValues,
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave()
        })
    }

    handleSave = () => {
        this.props.actions.saveNotificationsSettings(this.state.values)
        this.props.onHide()
    }

    componentDidMount() {
        this.props.actions.fetchNotificationsSettings()
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.loading && this.props.loading) {
            this.setValues(nextProps.settings)
        }
    }

    render() {
        return (
            <Modal show={Boolean(this.props.show)} onHide={this.props.onHide}>
                <React.Fragment>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="fields.legend" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoadingOverlay className="mh-100" active={this.props.loading}>
                            <Form
                                values={this.state.values}
                                validationRules={this.validationRules}
                                onChange={this.handleChangeValues}
                                onSubmit={this.handleSubmit}
                            >
                                {Object.keys(this.state.values).map(notification => (
                                    <CheckboxField
                                        id={notification}
                                        key={notification}
                                        label={this.props.intl.formatMessage({ id: `fields.notifications_${notification}` })}
                                        className="wp-50"
                                        readonly={() => false}
                                        disabled={() => false}
                                    />
                                ))}
                                <button type="submit" className="btn btn-success btn-block">
                                    <FormattedMessage id="buttons.saveAndClose" />
                                </button>
                            </Form>
                        </LoadingOverlay>
                    </Modal.Body>
                </React.Fragment>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        settings: createGetSettingsSelector()(state),
        loading: createGetLoadingSettings()(state),
        saving: createGetSavingSettings()(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(WSNotificationSettingModal)
)
