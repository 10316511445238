import * as actionTypes from './actionTypes'

// Fetch

export const fetchLegend = (key, language) => ({
    type: actionTypes.FETCH,
    payload: { key, language },
})

export const fetchLegendFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchLegendRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchLegendCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})
