import { Record } from 'immutable'

export class DietRate extends Record({
    id: undefined,
    country_id: undefined,
    currency_id: undefined,
    rate: undefined,
    hours_from: undefined,
    hours_to: undefined,
    date_from: undefined,
    date_to: undefined,
    is_domestic: undefined,
}) {}
