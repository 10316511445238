import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { vehicleSchema, transportPointSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap, mapNestedRecordsToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { Vehicle } from './model'
import { VehicleType } from '../vehicle_types/model'
import * as vehicleTypesActionCreators from '../vehicle_types/actionCreators'
import { VehicleFleetboardConfiguration } from '../vehicle_fleetboard_configurations/model'
import * as vehicleFleetboardConfiguratiosActionCreators from '../vehicle_fleetboard_configurations/actionCreators'
import { User } from '../users/model'
import * as usersActionCreators from '../users/actionCreators'
import { Company } from '../companies/model'
import * as companiesActionCreators from '../companies/actionCreators'
import { CompanyCostCenter } from '../company_cost_centers/model'
import * as companyCostCenterActionCreators from '../company_cost_centers/actionCreators'
import { Country } from '../countries/model'
import { TransportPoint } from '../transports/model'
import { Trailer } from '../trailers/model'
import { Carrier } from '../carriers/model'
import * as carriersActionCreators from '../carriers/actionCreators'

// Fetch

const fetchVehiclesEpic = (action$, store) => {
    const tableIdentifier = 'vehicles_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `vehicles/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(vehicleSchema)
                        )

                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle, normalizedData.result)
                        const vehicleTypes = normalizedEntitiesToRecordMap(normalizedData.entities.vehicle_types, VehicleType)
                        const vehicleFleetboardConfigurations = normalizedEntitiesToRecordMap(
                            normalizedData.entities.vehicle_fleetboard_configurations,
                            VehicleFleetboardConfiguration
                        )
                        const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)
                        const companyCostCenters = normalizedEntitiesToRecordMap(normalizedData.entities.company_cost_centers, CompanyCostCenter)
                        const companies = normalizedEntitiesToRecordMap(normalizedData.entities.companies, Company)
                        const carriers = normalizedEntitiesToRecordMap(normalizedData.entities.carriers, Carrier)

                        const observables = [
                            Observable.of(vehicleTypesActionCreators.fetchVehicleTypesFulfilled(vehicleTypes)),
                            Observable.of(
                                vehicleFleetboardConfiguratiosActionCreators.fetchVehicleFleetboardConfigurationsFulfilled(
                                    vehicleFleetboardConfigurations
                                )
                            ),
                            Observable.of(usersActionCreators.fetchUsersFulfilled(users)),
                            Observable.of(companiesActionCreators.fetchCompaniesFulfilled(companies)),
                            Observable.of(companyCostCenterActionCreators.fetchCompanyCostCentersFulfilled(companyCostCenters)),
                            Observable.of(carriersActionCreators.fetchCarriersFulfilled(carriers)),
                            Observable.of(actionCreators.fetchVehiclesFulfilled(vehicles)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchVehiclesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchVehicleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().vehicles.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `vehicles/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, vehicleSchema)

                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                        const vehicleTypes = normalizedEntitiesToRecordMap(normalizedData.entities.vehicle_types, VehicleType)
                        const vehicleFleetboardConfigurations = normalizedEntitiesToRecordMap(
                            normalizedData.entities.vehicle_fleetboard_configurations,
                            VehicleFleetboardConfiguration
                        )
                        const users = normalizedEntitiesToRecordMap(normalizedData.entities.users, User)
                        const companyCostCenters = normalizedEntitiesToRecordMap(normalizedData.entities.company_cost_centers, CompanyCostCenter)
                        const companies = normalizedEntitiesToRecordMap(normalizedData.entities.companies, Company)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchVehiclesFulfilled(vehicles)),
                            Observable.of(actionCreators.fetchVehicleFulfilled()),
                            Observable.of(vehicleTypesActionCreators.fetchVehicleTypesFulfilled(vehicleTypes)),
                            Observable.of(
                                vehicleFleetboardConfiguratiosActionCreators.fetchVehicleFleetboardConfigurationsFulfilled(
                                    vehicleFleetboardConfigurations
                                )
                            ),
                            Observable.of(usersActionCreators.fetchUsersFulfilled(users)),
                            Observable.of(companiesActionCreators.fetchCompaniesFulfilled(companies)),
                            Observable.of(companyCostCenterActionCreators.fetchCompanyCostCentersFulfilled(companyCostCenters))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchVehiclesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveVehicleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().vehicles.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload.values)
            if (action.payload.ignoreDuplicity) {
                values.ignore_duplicity = 1
            }

            let path = `vehicles`
            let method = 'POST'

            if (values.id) {
                path = `vehicles/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        if (ajaxResponse.response.duplicity_found) {
                            return Observable.concat(Observable.of(actionCreators.saveVehicleDuplicityFound(ajaxResponse.response.duplicity)))
                        }

                        const normalizedData = normalize(ajaxResponse.response, vehicleSchema)

                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                        const vehicle = vehicles.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveVehicleFulfilled(vehicle)))
                    })
                    .catch(error => Observable.of(actionCreators.saveVehicleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteVehicleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().vehicles.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `vehicles/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteVehicleFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteVehicleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )


// Restore

const restoreVehicleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.RESTORE)
        .filter(() => !store.getState().vehicles.getIn(['restoring', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.RESTORE_STARTED,
                }),
                request({
                    path: `vehicles/${action.payload}/restore`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, vehicleSchema)
                        const items = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                        const item = items.valueSeq().first()
                        return Observable.concat(Observable.of(actionCreators.restoreVehicleFulfilled(item)))
                    })
                    .catch(error => Observable.of(actionCreators.restoreVehicleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.RESTORE_CANCELLED))
            )
        )

// Fetch transport start

const fetchTransportStartEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_TRANSPORT_START)
        .filter(() => !store.getState().vehicles.getIn(['transportStart', 'fetching']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload)

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_TRANSPORT_START_STARTED,
                }),
                request({
                    path: `vehicles/${values.vehicle_id}/load-transport-start`,
                    method: 'POST',
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, transportPointSchema)

                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                        const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                        let transportPoints = normalizedEntitiesToRecordMap(normalizedData.entities.transport_points, TransportPoint)

                        transportPoints = mapNestedRecordsToRecordMap(
                            transportPoints,
                            {
                                country: {
                                    data: countries,
                                    recordClass: Country,
                                },
                                trailer: {
                                    data: trailers,
                                    recordClass: Trailer,
                                },
                            },
                            TransportPoint
                        )

                        const transportStart = transportPoints.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.fetchTransportStartFulfilled(transportStart.id && transportStart)))
                    })
                    .catch(error => Observable.of(actionCreators.fetchTransportStartRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_TRANSPORT_START_CANCELLED))
            )
        })

// Export

const exportVehiclesEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `vehicles/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// History

const fetchVehicleHistoryEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_HISTORY)
        .filter(() => !store.getState().vehicles.getIn(['history', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_HISTORY_STARTED,
                }),
                request({
                    path: `vehicles/${action.payload}/history`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchVehicleHistoryFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchVehicleHistoryRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_HISTORY_CANCELLED))
            )
        )

const exportVehicleHistoryEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_HISTORY).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `vehicles/${action.payload}/history/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_HISTORY_FULFILLED,
            })
        )
    })

// Documents

const fetchVehicleDocumentsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_DOCUMENTS)
        .filter(() => !store.getState().vehicles.getIn(['documents', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_DOCUMENTS_STARTED,
                }),
                request({
                    path: `vehicles/${action.payload.id}/documents${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchVehicleDocumentsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchVehicleDocumentsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_DOCUMENTS_CANCELLED))
            )
        )

const exportVehicleDocumentsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_DOCUMENTS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `vehicles/${action.payload}/documents/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_DOCUMENTS_FULFILLED,
            })
        )
    })

// Equipments

const fetchVehicleEquipmentsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_EQUIPMENTS)
        .filter(() => !store.getState().vehicles.getIn(['equipments', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_EQUIPMENTS_STARTED,
                }),
                request({
                    path: `vehicles/${action.payload.id}/equipment${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchVehicleEquipmentsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchVehicleEquipmentsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_EQUIPMENTS_CANCELLED))
            )
        )

const exportVehicleEquipmentsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_EQUIPMENTS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `vehicles/${action.payload}/equipment/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_EQUIPMENTS_FULFILLED,
            })
        )
    })

// Events

const fetchVehicleEventsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_EVENTS)
        .filter(() => !store.getState().vehicles.getIn(['events', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_EVENTS_STARTED,
                }),
                request({
                    path: `vehicles/${action.payload.id}/events${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchVehicleEventsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchVehicleEventsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_EVENTS_CANCELLED))
            )
        )

const exportVehicleEventsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_EVENTS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `vehicles/${action.payload.id}/events/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_EVENTS_FULFILLED,
            })
        )
    })

// Fuelings

const fetchVehicleFuelingsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_FUELINGS)
        .filter(() => !store.getState().vehicles.getIn(['fuelings', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_FUELINGS_STARTED,
                }),
                request({
                    path: `vehicles/${action.payload.id}/fuelings${action.payload.page ? `?page=${action.payload.page}` : ''}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchVehicleFuelingsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchVehicleFuelingsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_FUELINGS_CANCELLED))
            )
        )

const exportVehicleFuelingsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_FUELINGS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `vehicles/${action.payload.id}/fuelings/export?token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FUELINGS_FULFILLED,
            })
        )
    })

export default [
    fetchVehiclesEpic,
    fetchVehicleEpic,
    saveVehicleEpic,
    deleteVehicleEpic,
    restoreVehicleEpic,
    fetchTransportStartEpic,
    exportVehiclesEpic,
    fetchVehicleHistoryEpic,
    exportVehicleHistoryEpic,
    fetchVehicleDocumentsEpic,
    exportVehicleDocumentsEpic,
    fetchVehicleEventsEpic,
    exportVehicleEventsEpic,
    fetchVehicleEquipmentsEpic,
    exportVehicleEquipmentsEpic,
    fetchVehicleFuelingsEpic,
    exportVehicleFuelingsEpic,
]
