import { createSelector } from 'reselect'

export const getData = state => state.invoicingOrders.getIn(['all', 'objects'])
export const createGetDataSelector = () => createSelector([getData], data => data)

export const getInvoicingStateSettingInProgress = state => state.invoicingOrders.getIn(['invoicingStateSetting', 'inProgress'])
export const getInvoicingStateSettingError = state => state.invoicingOrders.getIn(['invoicingStateSetting', 'error'])

export const getDataForInvoicing = state => state.invoicingOrders.getIn(['dataForInvoicing', 'data'])
export const getDataForInvoicingFetching = state => state.invoicingOrders.getIn(['dataForInvoicing', 'fetching'])
export const getDataForInvoicingError = state => state.invoicingOrders.getIn(['dataForInvoicing', 'error'])

export const getCreatingInvoiceInProgress = state => state.invoicingOrders.getIn(['creatingInvoice', 'inProgress'])
export const getCreatingInvoiceError = state => state.invoicingOrders.getIn(['creatingInvoice', 'error'])

export const getCreatedInvoiceId = state => state.invoicingOrders.getIn(['creatingInvoice', 'createdInvoiceId'])
