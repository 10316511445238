import { createSelector } from 'reselect'

export const getCustomerPlans = state => state.customerPlans.getIn(['all', 'objects'])

export const createGetCustomerPlanByIDSelector = id => createSelector([getCustomerPlans], customerPlans => customerPlans.get(String(id)))

const getCurrentCustomerPlanStatus = state => state.customerPlans.get('current')
export const createGetCurrentCustomerPlanStatusSelector = () => createSelector([getCurrentCustomerPlanStatus], status => status)

export const getCustomerPlanDeleting = state => state.customerPlans.getIn(['deletion', 'inProgress'])
export const getCustomerPlanDeleteError = state => state.customerPlans.getIn(['deletion', 'error'])

const getDuplicity = state => state.customerPlans.getIn(['all', 'duplicity'])
export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)
