import { createSelector } from 'reselect'

export const getSales = state => state.sales.getIn(['all', 'objects'])

// eslint-disable-next-line prettier/prettier
export const createGetSalesByIDSelector = id => createSelector([getSales], sales => sales.get(String(id)))

const getCurrentSaleStatus = state => state.sales.get('current')

// eslint-disable-next-line prettier/prettier
export const createGetCurrentSaleStatusSelector = () => createSelector([getCurrentSaleStatus], status => status)

export const getSalesFetching = state => state.sales.getIn(['all', 'fetching'])

export const getSaleFetching = state => state.sales.getIn(['current', 'fetching'])

export const getSalesDeleting = state => state.sales.getIn(['deletion', 'inProgress'])

export const getSalesDeleteError = state => state.sales.getIn(['deletion', 'error'])

// Import

export const getImportInProgress = state => state.sales.getIn(['import', 'inProgress'])
export const getImportError = state => state.sales.getIn(['import', 'error'])
export const getImportFileId = state => state.sales.getIn(['import', 'file_id'])
export const getImportHeaders = state => state.sales.getIn(['import', 'headers'])
export const getImportDefaultHeaders = state => state.sales.getIn(['import', 'headersDefault'])
export const getImportItems = state => state.sales.getIn(['import', 'items'])
export const getDefaultDate = state => state.sales.getIn(['import', 'defaultDate'])
export const getDefaultSalesTypeId = state => state.sales.getIn(['import', 'defaultSalesTypeId'])



export const getInvoicingStateSettingInProgress = state => state.sales.getIn(['invoicingStateSetting', 'inProgress'])
export const getInvoicingStateSettingError = state => state.sales.getIn(['invoicingStateSetting', 'error'])

export const getDataForInvoicing = state => state.sales.getIn(['dataForInvoicing', 'data'])
export const getDataForInvoicingFetching = state => state.sales.getIn(['dataForInvoicing', 'fetching'])
export const getDataForInvoicingError = state => state.sales.getIn(['dataForInvoicing', 'error'])

export const getCreatingInvoiceInProgress = state => state.sales.getIn(['creatingInvoice', 'inProgress'])
export const getCreatingInvoiceError = state => state.sales.getIn(['creatingInvoice', 'error'])

export const getCreatedInvoiceId = state => state.sales.getIn(['creatingInvoice', 'createdInvoiceId'])
