import React, { Component } from 'react'
import ArrowField from './ArrowField'

class HalfPicker extends Component {
    onClick = half => {
        const momentFrom = this.props.momentFrom.add(half * 2, 'quarter')
        const momentTo = this.props.momentTo.add(half * 2, 'quarter').endOf('month')

        this.props.onClick(momentFrom, momentTo)
    }

    render() {
        const year = this.props.momentFrom.format('YYYY')
        const quarter = this.props.momentFrom.format('Q')

        let half = `${year} - `

        switch (quarter) {
            case '1':
            case '2':
                half += '1/2'
                break
            case '3':
            case '4':
                half += '2/2'
                break
            default:
                half += null
                break
        }
        const isSecondHalf = quarter === '3' || quarter === '4'
        const isMin = this.props.minYear === year && !isSecondHalf
        const isMax = this.props.maxYear === year && isSecondHalf

        return <ArrowField onClick={this.onClick} value={half} isMin={isMin} isMax={isMax} />
    }
}

export default HalfPicker
