import { createSelector } from 'reselect'

export const getUsers = state => state.users.getIn(['all', 'objects'])

const getUserId = state => state.auth.get('userID')

const getUserProfile = state => state.userProfileSettings.getIn(['user', 'profile'])

export const createGetUserProfileSelector = () => createSelector([getUserProfile], profile => profile)

export const createGetUserIdSelector = () => createSelector([getUserId], status => status)

export const createGetUserByIDSelector = () => createSelector([getUsers, getUserId], (users, id) => users.get(String(id)))

const getCurrentUserStatus = state => state.userProfileSettings.get('user')

export const createGetCurrentUserStatusSelector = () => createSelector([getCurrentUserStatus], status => status)
