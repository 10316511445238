import { Record } from 'immutable'

export class Company extends Record({
    id: undefined,
    name: undefined,
    code: undefined,
    street: undefined,
    city: undefined,
    zipcode: undefined,
    country_id: undefined,
    phone: undefined,
    email: undefined,
    reg_number: undefined,
    vat_number: undefined,
    vat_payer: undefined,
}) {
    getAddress = () => {
        const parts = []
        this.street && parts.push(this.street)
        this.city && parts.push(this.city)
        this.zipcode && parts.push(this.zipcode)

        return parts.join(', ')
    }
}
