import React from 'react'
import { Route, Switch } from 'react-router'
import OffersList from './OffersList'

const Companies = props => (
    <Switch>
        <Route exact path={props.match.url} component={OffersList} />
    </Switch>
)

export default Companies
