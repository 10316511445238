import * as actionTypes from './actionTypes'

// Fetch

export const fetchTransports = () => ({
    type: actionTypes.FETCH,
})

export const fetchTransportsFulfilled = transports => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: transports,
})

export const fetchTransportsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTransportsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchTransport = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchTransportFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchTransportRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchTransportCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Load customer info

export const loadCustomerInfo = (index, customerId) => ({
    type: actionTypes.LOAD_CUSTOMER_INFO,
    payload: {
        index,
        customerId,
    },
})

export const loadCustomerInfoFulfilled = (index, data) => ({
    type: actionTypes.LOAD_CUSTOMER_INFO_FULFILLED,
    payload: {
        index,
        data,
    },
})

export const loadCustomerInfoRejected = message => ({
    type: actionTypes.LOAD_CUSTOMER_INFO_REJECTED,
    payload: message,
})

export const loadCustomerInfoCancelled = () => ({
    type: actionTypes.LOAD_CUSTOMER_INFO_CANCELLED,
})

export const clearCustomerInfo = () => ({
    type: actionTypes.CLEAR_CUSTOMER_INFO,
})

// Save

export const saveTransport = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveTransportFulfilled = transport => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: transport,
})

export const saveTransportRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveTransportDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteTransport = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteTransportFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteTransportRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Restore

export const restoreTransport = id => ({
    type: actionTypes.RESTORE,
    payload: id,
})

export const restoreTransportFulfilled = transport => ({
    type: actionTypes.RESTORE_FULFILLED,
    payload: transport,
})

export const restoreTransportRejected = message => ({
    type: actionTypes.RESTORE_REJECTED,
    payload: message,
})

// Approve

export const approveTransport = id => ({
    type: actionTypes.APPROVE,
    payload: id,
})

export const approveTransportFulfilled = transport => ({
    type: actionTypes.APPROVE_FULFILLED,
    payload: transport,
})

export const approveTransportRejected = message => ({
    type: actionTypes.APPROVE_REJECTED,
    payload: message,
})

// Copy sms

export const fetchTransportSms = id => ({
    type: actionTypes.COPY_SMS,
    payload: id,
})

export const fetchTransportSmsFulfilled = text => ({
    type: actionTypes.COPY_SMS_FULFILLED,
    payload: text,
})

export const fetchTransportSmsRejected = message => ({
    type: actionTypes.COPY_SMS_REJECTED,
    payload: message,
})

// Send to vehicle

export const sendTransportToVehicle = id => ({
    type: actionTypes.SEND_TO_VEHICLE,
    payload: id,
})

export const sendTransportToVehicleFulfilled = transport => ({
    type: actionTypes.SEND_TO_VEHICLE_FULFILLED,
    payload: transport,
})

export const sendTransportToVehicleRejected = message => ({
    type: actionTypes.SEND_TO_VEHICLE_REJECTED,
    payload: message,
})

// Delete from vehicle

export const deleteTransportFromVehicle = id => ({
    type: actionTypes.DELETE_FROM_VEHICLE,
    payload: id,
})

export const deleteTransportFromVehicleFulfilled = transport => ({
    type: actionTypes.DELETE_FROM_VEHICLE_FULFILLED,
    payload: transport,
})

export const deleteTransportFromVehicleRejected = message => ({
    type: actionTypes.DELETE_FROM_VEHICLE_REJECTED,
    payload: message,
})

// Clear

export const clearTransports = () => ({
    type: actionTypes.CLEAR,
})

// Create from template

export const createFromTemplate = (id, params) => ({
    type: actionTypes.CREATE_FROM_TEMPLATE,
    payload: {
        id,
        params,
    },
})

export const createFromTemplateFulfilled = () => ({
    type: actionTypes.CREATE_FROM_TEMPLATE_FULFILLED,
})

export const createFromTemplateRejected = message => ({
    type: actionTypes.CREATE_FROM_TEMPLATE_REJECTED,
    payload: message,
})

// Create template

export const createTemplate = (id, ignoreDuplicity) => ({
    type: actionTypes.CREATE_TEMPLATE,
    payload: {
        id,
        ignoreDuplicity,
    },
})

export const createTemplateFulfilled = transport => ({
    type: actionTypes.CREATE_TEMPLATE_FULFILLED,
    payload: transport,
})

export const createTemplateRejected = message => ({
    type: actionTypes.CREATE_TEMPLATE_REJECTED,
    payload: message,
})

export const createTemplateDuplicityFound = duplicity => ({
    type: actionTypes.CREATE_TEMPLATE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Duplicate

export const duplicateTransport = id => ({
    type: actionTypes.DUPLICATE,
    payload: id,
})

export const duplicateTransportFulfilled = transport => ({
    type: actionTypes.DUPLICATE_FULFILLED,
    payload: transport,
})

export const duplicateTransportRejected = message => ({
    type: actionTypes.DUPLICATE_REJECTED,
    payload: message,
})

// Save event

export const saveTransportEvent = values => ({
    type: actionTypes.SAVE_EVENT,
    payload: values,
})

export const saveTransportEventFulfilled = transport => ({
    type: actionTypes.SAVE_EVENT_FULFILLED,
    payload: transport,
})

export const saveTransportEventRejected = message => ({
    type: actionTypes.SAVE_EVENT_REJECTED,
    payload: message,
})

// Delete event

export const deleteTransportEvent = values => ({
    type: actionTypes.DELETE_EVENT,
    payload: values,
})

export const deleteTransportEventFulfilled = transport => ({
    type: actionTypes.DELETE_EVENT_FULFILLED,
    payload: transport,
})

export const deleteTransportEventRejected = message => ({
    type: actionTypes.DELETE_EVENT_REJECTED,
    payload: message,
})

// Create events

export const createTransportEvents = values => ({
    type: actionTypes.CREATE_EVENTS,
    payload: values,
})

export const createTransportEventsFulfilled = transport => ({
    type: actionTypes.CREATE_EVENTS_FULFILLED,
    payload: transport,
})

export const createTransportEventsRejected = message => ({
    type: actionTypes.CREATE_EVENTS_REJECTED,
    payload: message,
})

// Change point state

export const changeTransportPointState = values => ({
    type: actionTypes.CHANGE_POINT_STATE,
    payload: values,
})

export const changeTransportPointStateFulfilled = transport => ({
    type: actionTypes.CHANGE_POINT_STATE_FULFILLED,
    payload: transport,
})

export const changeTransportPointStateRejected = message => ({
    type: actionTypes.CHANGE_POINT_STATE_REJECTED,
    payload: message,
})

// Fetch log

export const fetchTransportLog = id => ({
    type: actionTypes.FETCH_LOG,
    payload: id,
})

export const fetchTransportLogFulfilled = logItems => ({
    type: actionTypes.FETCH_LOG_FULFILLED,
    payload: logItems,
})

export const fetchTransportLogRejected = message => ({
    type: actionTypes.FETCH_LOG_REJECTED,
    payload: message,
})

export const fetchTransportLogCancelled = () => ({
    type: actionTypes.FETCH_LOG_CANCELLED,
})

// Export

export const exportTransports = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Recount planned route

export const recountPlannedRoute = id => ({
    type: actionTypes.RECOUNT_PLANNED_ROUTE,
    payload: id,
})

export const recountPlannedRouteFulfilled = transport => ({
    type: actionTypes.RECOUNT_PLANNED_ROUTE_FULFILLED,
    payload: transport,
})

export const recountPlannedRouteRejected = message => ({
    type: actionTypes.RECOUNT_PLANNED_ROUTE_REJECTED,
    payload: message,
})

// Recount specified route

export const recountSpecifiedRoute = id => ({
    type: actionTypes.RECOUNT_SPECIFIED_ROUTE,
    payload: id,
})

export const recountSpecifiedRouteFulfilled = transport => ({
    type: actionTypes.RECOUNT_SPECIFIED_ROUTE_FULFILLED,
    payload: transport,
})

export const recountSpecifiedRouteRejected = message => ({
    type: actionTypes.RECOUNT_SPECIFIED_ROUTE_REJECTED,
    payload: message,
})

// Recount real route

export const recountRealRoute = id => ({
    type: actionTypes.RECOUNT_REAL_ROUTE,
    payload: id,
})

export const recountRealRouteFulfilled = transport => ({
    type: actionTypes.RECOUNT_REAL_ROUTE_FULFILLED,
    payload: transport,
})

export const recountRealRouteRejected = message => ({
    type: actionTypes.RECOUNT_REAL_ROUTE_REJECTED,
    payload: message,
})

// Fetch real vehicle positions

export const fetchRealVehiclePositions = id => ({
    type: actionTypes.FETCH_REAL_VEHICLE_POSITIONS,
    payload: id,
})

export const fetchRealVehiclePositionsFulfilled = positions => ({
    type: actionTypes.FETCH_REAL_VEHICLE_POSITIONS_FULFILLED,
    payload: positions,
})

export const fetchRealVehiclePositionsRejected = message => ({
    type: actionTypes.FETCH_REAL_VEHICLE_POSITIONS_REJECTED,
    payload: message,
})

export const fetchRealVehiclePositionsCancelled = () => ({
    type: actionTypes.FETCH_REAL_VEHICLE_POSITIONS_CANCELLED,
})

// Fetch vehicle data source

export const fetchVehicleDataSource = vehicle_id => ({
    type: actionTypes.FETCH_VEHICLE_DATA_SOURCE,
    payload: vehicle_id,
})

export const fetchVehicleDataSourceFulfilled = dataSource => ({
    type: actionTypes.FETCH_VEHICLE_DATA_SOURCE_FULFILLED,
    payload: dataSource,
})

export const fetchVehicleDataSourceRejected = message => ({
    type: actionTypes.FETCH_VEHICLE_DATA_SOURCE_REJECTED,
    payload: message,
})

export const fetchVehicleDataSourceCancelled = () => ({
    type: actionTypes.FETCH_VEHICLE_DATA_SOURCE_CANCELLED,
})

export const clearVehicleDataSource = () => ({
    type: actionTypes.CLEAR_VEHICLE_DATA_SOURCE
})

// Fetch home country

export const fetchVehicleHomeCountry = vehicle_id => ({
    type: actionTypes.FETCH_VEHICLE_HOME_COUNTRY,
    payload: vehicle_id,
})

export const fetchVehicleHomeCountryFulfilled = homeCountry => ({
    type: actionTypes.FETCH_VEHICLE_HOME_COUNTRY_FULFILLED,
    payload: homeCountry,
})

export const fetchVehicleHomeCountryRejected = message => ({
    type: actionTypes.FETCH_VEHICLE_HOME_COUNTRY_REJECTED,
    payload: message,
})

export const fetchVehicleHomeCountryCancelled = () => ({
    type: actionTypes.FETCH_VEHICLE_HOME_COUNTRY_CANCELLED,
})

// Fetch preview

export const fetchTransportPreview = id => ({
    type: actionTypes.FETCH_PREVIEW,
    payload: id,
})

export const fetchTransportPreviewFulfilled = transport => ({
    type: actionTypes.FETCH_PREVIEW_FULFILLED,
    payload: transport,
})

export const fetchTransportPreviewRejected = message => ({
    type: actionTypes.FETCH_PREVIEW_REJECTED,
    payload: message,
})

export const fetchTransportPreviewCancelled = () => ({
    type: actionTypes.FETCH_PREVIEW_CANCELLED,
})

// Fetch map preview

export const fetchTransportMapPreview = id => ({
    type: actionTypes.FETCH_MAP_PREVIEW,
    payload: id,
})

export const fetchTransportMapPreviewFulfilled = transport => ({
    type: actionTypes.FETCH_MAP_PREVIEW_FULFILLED,
    payload: transport,
})

export const fetchTransportMapPreviewRejected = message => ({
    type: actionTypes.FETCH_MAP_PREVIEW_REJECTED,
    payload: message,
})

export const fetchTransportMapPreviewCancelled = () => ({
    type: actionTypes.FETCH_MAP_PREVIEW_CANCELLED,
})

// Check customers

export const checkCustomers = (customersIds, transportId) => ({
    type: actionTypes.CHECK_CUSTOMERS,
    payload: {
        customersIds,
        transportId,
    },
})

export const checkCustomersFulfilled = data => ({
    type: actionTypes.CHECK_CUSTOMERS_FULFILLED,
    payload: data,
})

export const checkCustomersRejected = message => ({
    type: actionTypes.CHECK_CUSTOMERS_REJECTED,
    payload: message,
})

// Check carriers

export const checkCarriers = (carriersIds, transportId) => ({
    type: actionTypes.CHECK_CARRIERS,
    payload: {
        carriersIds,
        transportId,
    },
})

export const checkCarriersFulfilled = data => ({
    type: actionTypes.CHECK_CARRIERS_FULFILLED,
    payload: data,
})

export const checkCarriersRejected = message => ({
    type: actionTypes.CHECK_CARRIERS_REJECTED,
    payload: message,
})

// Fetch orders info

export const fetchTransportOrdersInfo = (id, values) => ({
    type: actionTypes.FETCH_ORDERS_INFO,
    payload: {
        id,
        values,
    },
})

export const fetchTransportOrdersInfoFulfilled = data => ({
    type: actionTypes.FETCH_ORDERS_INFO_FULFILLED,
    payload: data,
})

export const fetchTransportOrdersInfoRejected = message => ({
    type: actionTypes.FETCH_ORDERS_INFO_REJECTED,
    payload: message,
})

export const fetchTransportOrdersInfoCancelled = () => ({
    type: actionTypes.FETCH_ORDERS_INFO_CANCELLED,
})

// Create order

export const createTransportOrder = (id, values) => ({
    type: actionTypes.CREATE_ORDER,
    payload: {
        id,
        values,
    },
})

export const createTransportOrderFulfilled = data => ({
    type: actionTypes.CREATE_ORDER_FULFILLED,
    payload: data,
})

export const createTransportOrderRejected = message => ({
    type: actionTypes.CREATE_ORDER_REJECTED,
    payload: message,
})

export const createTransportOrderCancelled = () => ({
    type: actionTypes.CREATE_ORDER_CANCELLED,
})

// Send order to email

export const sendTransportOrderToEmail = (id, values) => ({
    type: actionTypes.SEND_ORDER_TO_EMAIL,
    payload: {
        id,
        values,
    },
})

export const sendTransportOrderToEmailFulfilled = () => ({
    type: actionTypes.SEND_ORDER_TO_EMAIL_FULFILLED,
})

export const sendTransportOrderToEmailRejected = message => ({
    type: actionTypes.SEND_ORDER_TO_EMAIL_REJECTED,
    payload: message,
})

export const sendTransportOrderToEmailCancelled = () => ({
    type: actionTypes.SEND_ORDER_TO_EMAIL_CANCELLED,
})

// Fetch files

export const fetchTransportFiles = id => ({
    type: actionTypes.FETCH_FILES,
    payload: id,
})

export const fetchTransportFilesFulfilled = (files, eventFiles) => ({
    type: actionTypes.FETCH_FILES_FULFILLED,
    payload: {
        files,
        eventFiles,
    },
})

export const fetchTransportFilesRejected = message => ({
    type: actionTypes.FETCH_FILES_REJECTED,
    payload: message,
})

export const fetchTransportFilesCancelled = () => ({
    type: actionTypes.FETCH_FILES_CANCELLED,
})

// Upload files

export const uploadTransportFiles = (id, files) => ({
    type: actionTypes.UPLOAD_FILES,
    payload: {
        id,
        files,
    },
})

export const uploadTransportFilesFulfilled = (files, eventFiles) => ({
    type: actionTypes.UPLOAD_FILES_FULFILLED,
    payload: {
        files,
        eventFiles,
    },
})

export const uploadTransportFilesRejected = message => ({
    type: actionTypes.UPLOAD_FILES_REJECTED,
    payload: message,
})

export const uploadTransportFilesCancelled = () => ({
    type: actionTypes.UPLOAD_FILES_CANCELLED,
})

// Delete file

export const deleteTransportFile = (id, file_id, isEventFile) => ({
    type: actionTypes.DELETE_FILE,
    payload: {
        id,
        file_id,
        isEventFile,
    },
})

export const deleteTransportFileFulfilled = (files, eventFiles) => ({
    type: actionTypes.DELETE_FILE_FULFILLED,
    payload: {
        files,
        eventFiles,
    },
})

export const deleteTransportFileRejected = message => ({
    type: actionTypes.DELETE_FILE_REJECTED,
    payload: message,
})

export const deleteTransportFileCancelled = () => ({
    type: actionTypes.DELETE_FILE_CANCELLED,
})

// Fetch goods

export const fetchTransportGoods = id => ({
    type: actionTypes.FETCH_GOODS,
    payload: id,
})

export const fetchTransportGoodsFulfilled = goods => ({
    type: actionTypes.FETCH_GOODS_FULFILLED,
    payload: goods,
})

export const fetchTransportGoodsRejected = message => ({
    type: actionTypes.FETCH_GOODS_REJECTED,
    payload: message,
})

export const fetchTransportGoodsCancelled = () => ({
    type: actionTypes.FETCH_GOODS_CANCELLED,
})

// Save files

export const saveTransportGoods = (id, data) => ({
    type: actionTypes.SAVE_GOODS,
    payload: {
        id,
        data,
    },
})

export const saveTransportGoodsFulfilled = goods => ({
    type: actionTypes.SAVE_GOODS_FULFILLED,
    payload: goods,
})

export const saveTransportGoodsRejected = message => ({
    type: actionTypes.SAVE_GOODS_REJECTED,
    payload: message,
})

export const saveTransportGoodsCancelled = () => ({
    type: actionTypes.SAVE_GOODS_CANCELLED,
})

// Delete goods

export const deleteTransportGoods = (id, goods_id) => ({
    type: actionTypes.DELETE_GOODS,
    payload: {
        id,
        goods_id,
    },
})

export const deleteTransportGoodsFulfilled = goods => ({
    type: actionTypes.DELETE_GOODS_FULFILLED,
    payload: goods,
})

export const deleteTransportGoodsRejected = message => ({
    type: actionTypes.DELETE_GOODS_REJECTED,
    payload: message,
})

export const deleteTransportGoodsCancelled = () => ({
    type: actionTypes.DELETE_GOODS_CANCELLED,
})

// Fetch parts

export const fetchTransportParts = id => ({
    type: actionTypes.FETCH_PARTS,
    payload: id,
})

export const fetchTransportPartsFulfilled = parts => ({
    type: actionTypes.FETCH_PARTS_FULFILLED,
    payload: parts,
})

export const fetchTransportPartsRejected = message => ({
    type: actionTypes.FETCH_PARTS_REJECTED,
    payload: message,
})

export const fetchTransportPartsCancelled = () => ({
    type: actionTypes.FETCH_PARTS_CANCELLED,
})

// Save part

export const saveTransportPart = (id, data) => ({
    type: actionTypes.SAVE_PART,
    payload: {
        id,
        data,
    },
})

export const saveTransportPartFulfilled = parts => ({
    type: actionTypes.SAVE_PART_FULFILLED,
    payload: parts,
})

export const saveTransportPartRejected = message => ({
    type: actionTypes.SAVE_PART_REJECTED,
    payload: message,
})

export const saveTransportPartCancelled = () => ({
    type: actionTypes.SAVE_PART_CANCELLED,
})

// Save parts order

export const saveTransportPartsOrder = (id, data) => ({
    type: actionTypes.SAVE_PARTS_ORDER,
    payload: {
        id,
        data,
    },
})

export const saveTransportPartsOrderFulfilled = parts => ({
    type: actionTypes.SAVE_PARTS_ORDER_FULFILLED,
    payload: parts,
})

export const saveTransportPartsOrderRejected = message => ({
    type: actionTypes.SAVE_PARTS_ORDER_REJECTED,
    payload: message,
})

export const saveTransportPartsOrderCancelled = () => ({
    type: actionTypes.SAVE_PARTS_ORDER_CANCELLED,
})

// Delete goods

export const deleteTransportPart = (id, part_id) => ({
    type: actionTypes.DELETE_PART,
    payload: {
        id,
        part_id,
    },
})

export const deleteTransportPartFulfilled = goods => ({
    type: actionTypes.DELETE_PART_FULFILLED,
    payload: goods,
})

export const deleteTransportPartRejected = message => ({
    type: actionTypes.DELETE_PART_REJECTED,
    payload: message,
})

export const deleteTransportPartCancelled = () => ({
    type: actionTypes.DELETE_PART_CANCELLED,
})

// Correct event times

export const correctEventTimes = id => ({
    type: actionTypes.CORRECT_EVENT_TIMES,
    payload: id,
})

export const correctEventTimesFulfilled = transport => ({
    type: actionTypes.CORRECT_EVENT_TIMES_FULFILLED,
    payload: transport,
})

export const correctEventTimesRejected = message => ({
    type: actionTypes.CORRECT_EVENT_TIMES_REJECTED,
    payload: message,
})

export const correctEventTimesCancelled = () => ({
    type: actionTypes.CORRECT_EVENT_TIMES_CANCELLED,
})

// Correct point event times

export const correctPointEventTimes = (transport, point) => ({
    type: actionTypes.CORRECT_POINT_EVENT_TIMES,
    payload: {
        transport,
        point
    },
})

export const correctPointEventTimesFulfilled = transport => ({
    type: actionTypes.CORRECT_POINT_EVENT_TIMES_FULFILLED,
    payload: transport,
})

export const correctPointEventTimesRejected = message => ({
    type: actionTypes.CORRECT_POINT_EVENT_TIMES_REJECTED,
    payload: message,
})

export const correctPointEventTimesCancelled = () => ({
    type: actionTypes.CORRECT_POINT_EVENT_TIMES_CANCELLED,
})

// Fetch allowances

export const fetchAllowances = id => ({
    type: actionTypes.FETCH_ALLOWANCES,
    payload: {
        id,
    },
})

export const fetchAllowancesFulfilled = data => ({
    type: actionTypes.FETCH_ALLOWANCES_FULFILLED,
    payload: data,
})

export const fetchAllowancesRejected = message => ({
    type: actionTypes.FETCH_ALLOWANCES_REJECTED,
    payload: message,
})

export const fetchAllowancesCancelled = () => ({
    type: actionTypes.FETCH_ALLOWANCES_CANCELLED,
})

// Add allowances

export const saveAllowances = data => ({
    type: actionTypes.SAVE_ALLOWANCES,
    payload: data,
})

export const saveAllowancesFulfilled = data => ({
    type: actionTypes.SAVE_ALLOWANCES_FULFILLED,
    payload: data,
})

export const saveAllowancesRejected = message => ({
    type: actionTypes.SAVE_ALLOWANCES_REJECTED,
    payload: message,
})

export const saveAllowancesCancelled = () => ({
    type: actionTypes.SAVE_ALLOWANCES_CANCELLED,
})

// Delete allowance

export const deleteAllowance = data => ({
    type: actionTypes.DELETE_ALLOWANCE,
    payload: data,
})

export const deleteAllowanceFulfilled = data => ({
    type: actionTypes.DELETE_ALLOWANCE_FULFILLED,
    payload: data,
})

export const deleteAllowanceRejected = message => ({
    type: actionTypes.DELETE_ALLOWANCE_REJECTED,
    payload: message,
})

export const deleteAllowanceCancelled = () => ({
    type: actionTypes.DELETE_ALLOWANCE_CANCELLED,
})

// Re-Assign allowance

export const reassignAllowances = id => ({
    type: actionTypes.REASSIGN_ALLOWANCES,
    payload: {
        id,
    },
})

export const reassignAllowanceFulfilled = data => ({
    type: actionTypes.REASSIGN_ALLOWANCES_FULFILLED,
    payload: data,
})

export const reassignAllowanceRejected = message => ({
    type: actionTypes.REASSIGN_ALLOWANCES_REJECTED,
    payload: message,
})

export const reassignAllowanceCancelled = () => ({
    type: actionTypes.REASSIGN_ALLOWANCES_CANCELLED,
})

// Fetch scoring

export const fetchScoring = id => ({
    type: actionTypes.FETCH_SCORING,
    payload: id,
})

export const fetchScoringStarted = () => ({
    type: actionTypes.FETCH_SCORING_STARTED,
})

export const fetchScoringFulfilled = data => ({
    type: actionTypes.FETCH_SCORING_FULFILLED,
    payload: data,
})

export const fetchScoringRejected = message => ({
    type: actionTypes.FETCH_SCORING_REJECTED,
    payload: message,
})

export const fetchScoringCancelled = () => ({
    type: actionTypes.FETCH_SCORING_CANCELLED,
})

// Save scoring

export const saveScoring = (id, values) => ({
    type: actionTypes.SAVE_SCORING,
    payload: {
        id,
        values,
    },
})

export const saveScoringStarted = () => ({
    type: actionTypes.SAVE_SCORING_STARTED,
})

export const saveScoringFulfilled = data => ({
    type: actionTypes.SAVE_SCORING_FULFILLED,
    payload: data,
})

export const saveScoringRejected = message => ({
    type: actionTypes.SAVE_SCORING_REJECTED,
    payload: message,
})

export const saveScoringCancelled = () => ({
    type: actionTypes.SAVE_SCORING_CANCELLED,
})

// Fetch suggest for carrier

export const fetchSuggestForCarrier = (carrierId) => ({
    type: actionTypes.FETCH_SUGGEST_FOR_CARRIER,
    payload: {
        carrierId
    },
})

export const fetchSuggestForCarrierFulfilled = data => ({
    type: actionTypes.FETCH_SUGGEST_FOR_CARRIER_FULFILLED,
    payload: data,
})

export const fetchSuggestForCarrierRejected = message => ({
    type: actionTypes.FETCH_SUGGEST_FOR_CARRIER_REJECTED,
    payload: message,
})

export const fetchSuggestForCarrierCancelled = () => ({
    type: actionTypes.FETCH_SUGGEST_FOR_CARRIER_CANCELLED,
})

// Fetch import headers

export const fetchTransportsImportHeaders = file => ({
    type: actionTypes.FETCH_IMPORT_HEADERS,
    payload: file,
})

export const fetchTransportsImportHeadersFulfilled = headers => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_FULFILLED,
    payload: headers,
})

export const fetchTransportsImportHeadersRejected = message => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_REJECTED,
    payload: message,
})

export const fetchTransportsImportHeadersCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_CANCELLED,
})

// Fetch import items

export const fetchTransportsImportItems = (fileId, columnTypes, defaultDate) => ({
    type: actionTypes.FETCH_IMPORT_ITEMS,
    payload: {
        fileId,
        columnTypes,
        defaultDate
    },
})

export const fetchTransportsImportItemsFulfilled = (items, defaultDate) => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_FULFILLED,
    payload: {
        items,
        defaultDate
    },
})

export const fetchTransportsImportItemsRejected = message => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_REJECTED,
    payload: message,
})

export const fetchTransportsImportItemsCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_CANCELLED,
})

// Import transports

export const importTransports = (fileId, columnTypes, defaultDate, keys) => ({
    type: actionTypes.IMPORT_TRANSPORTS,
    payload: {
        fileId,
        columnTypes,
        defaultDate,
        keys
    },
})

export const importTransportsFulfilled = () => ({
    type: actionTypes.IMPORT_TRANSPORTS_FULFILLED,
})

export const importTransportsRejected = message => ({
    type: actionTypes.IMPORT_TRANSPORTS_REJECTED,
    payload: message,
})

export const importTransportsCancelled = () => ({
    type: actionTypes.IMPORT_TRANSPORTS_CANCELLED,
})

// Fetch import prices headers

export const fetchTransportsImportPricesHeaders = file => ({
    type: actionTypes.FETCH_IMPORT_PRICES_HEADERS,
    payload: file,
})

export const fetchTransportsImportPricesHeadersFulfilled = headers => ({
    type: actionTypes.FETCH_IMPORT_PRICES_HEADERS_FULFILLED,
    payload: headers,
})

export const fetchTransportsImportPricesHeadersRejected = message => ({
    type: actionTypes.FETCH_IMPORT_PRICES_HEADERS_REJECTED,
    payload: message,
})

export const fetchTransportsImportPricesHeadersCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_PRICES_HEADERS_CANCELLED,
})

// Fetch import prices items

export const fetchTransportsImportPricesItems = (fileId, columnTypes, defaultDate) => ({
    type: actionTypes.FETCH_IMPORT_PRICES_ITEMS,
    payload: {
        fileId,
        columnTypes,
        defaultDate
    },
})

export const fetchTransportsImportPricesItemsFulfilled = (items, defaultDate) => ({
    type: actionTypes.FETCH_IMPORT_PRICES_ITEMS_FULFILLED,
    payload: {
        items,
        defaultDate
    },
})

export const fetchTransportsImportPricesItemsRejected = message => ({
    type: actionTypes.FETCH_IMPORT_PRICES_ITEMS_REJECTED,
    payload: message,
})

export const fetchTransportsImportPricesItemsCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_PRICES_ITEMS_CANCELLED,
})

// Import transports prices

export const importTransportsPrices = (fileId, columnTypes, defaultDate, keys) => ({
    type: actionTypes.IMPORT_TRANSPORTS_PRICES,
    payload: {
        fileId,
        columnTypes,
        defaultDate,
        keys
    },
})

export const importTransportsPricesFulfilled = () => ({
    type: actionTypes.IMPORT_TRANSPORTS_PRICES_FULFILLED,
})

export const importTransportsPricesRejected = message => ({
    type: actionTypes.IMPORT_TRANSPORTS_PRICES_REJECTED,
    payload: message,
})

export const importTransportsPricesCancelled = () => ({
    type: actionTypes.IMPORT_TRANSPORTS_PRICES_CANCELLED,
})

// Check order number duplicities

export const checkOrderNumbersDuplicities = (transportId, orderNumbers) => ({
    type: actionTypes.CHECK_ORDER_NUMBER_DUPLICITIES,
    payload: {
        transportId,
        orderNumbers,
    },
})

export const checkOrderNumbersDuplicitiesFulfilled = duplicities => ({
    type: actionTypes.CHECK_ORDER_NUMBER_DUPLICITIES_FULFILLED,
    payload: {
        duplicities,
    }
})

export const checkOrderNumbersDuplicitiesRejected = message => ({
    type: actionTypes.CHECK_ORDER_NUMBER_DUPLICITIES_REJECTED,
    payload: message,
})

export const checkOrderNumbersDuplicitiesCancelled = () => ({
    type: actionTypes.CHECK_ORDER_NUMBER_DUPLICITIES_CANCELLED,
})
