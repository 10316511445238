// Fetch list

export const FETCH = 'user_roles.FETCH'
export const FETCH_STARTED = 'user_roles.FETCH_STARTED'
export const FETCH_FULFILLED = 'user_roles.FETCH_FULFILLED'
export const FETCH_REJECTED = 'user_roles.FETCH_REJECTED'
export const FETCH_CANCELLED = 'user_roles.FETCH_CANCELLED'

export const SORTING_CHANGED = 'user_roles.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'user_roles.FETCH_ONE'
export const FETCH_ONE_STARTED = 'user_roles.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'user_roles.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'user_roles.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'user_roles.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'user_roles.SAVE'
export const SAVE_STARTED = 'user_roles.SAVE_STARTED'
export const SAVE_FULFILLED = 'user_roles.SAVE_FULFILLED'
export const SAVE_REJECTED = 'user_roles.SAVE_REJECTED'
export const SAVE_CANCELLED = 'user_roles.SAVE_CANCELLED'

// Delete

export const DELETE = 'user_roles.DELETE'
export const DELETE_STARTED = 'user_roles.DELETE_STARTED'
export const DELETE_FULFILLED = 'user_roles.DELETE_FULFILLED'
export const DELETE_REJECTED = 'user_roles.DELETE_REJECTED'
export const DELETE_CANCELLED = 'user_roles.DELETE_CANCELLED'

// Duplicate

export const DUPLICATE = 'user_roles.DUPLICATE'
export const DUPLICATE_STARTED = 'user_roles.DUPLICATE_STARTED'
export const DUPLICATE_FULFILLED = 'user_roles.DUPLICATE_FULFILLED'
export const DUPLICATE_REJECTED = 'user_roles.DUPLICATE_REJECTED'
export const DUPLICATE_CANCELED = 'user_roles.DUPLICATE_CANCELED'
