import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import * as constants from '../../transports/constants'
import * as config from '../../../common/config'
import {Link} from "react-router-dom";

// eslint-disable-next-line react/prefer-stateless-function
class ReportVehicleChecksRow extends Component {
    render = () => {
        const { data, vehicle, driver, trailer, country } = this.props

        const transportPoint = data.transport_point

        const type = this.props.typesPairs && this.props.typesPairs[data.type_id]
        const typeDataFields =
            constants.TRANSPORT_EVENT_TYPES_DEFINITION[data.type_id] && constants.TRANSPORT_EVENT_TYPES_DEFINITION[data.type_id].dataFields

        const defect = data.getDataItem(constants.TRANSPORT_EVENT_DATA_TYPE_DEFECT)
        const defectText = data.getDataItem(constants.TRANSPORT_EVENT_DATA_TYPE_DEFECT_TEXT)
        const defectExists = typeDataFields && typeDataFields.indexOf(constants.TRANSPORT_EVENT_DATA_TYPE_DEFECT) >= 0

        const vehicleDefect = data.getDataItem(constants.TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT)
        const vehicleDefectText = data.getDataItem(constants.TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT_TEXT)
        const vehicleDefectExists = typeDataFields && typeDataFields.indexOf(constants.TRANSPORT_EVENT_DATA_TYPE_VEHICLE_DEFECT) >= 0

        const trailerDefect = data.getDataItem(constants.TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT)
        const trailerDefectText = data.getDataItem(constants.TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT_TEXT)
        const trailerDefectExists = typeDataFields && typeDataFields.indexOf(constants.TRANSPORT_EVENT_DATA_TYPE_TRAILER_DEFECT) >= 0

        const documentsDefect = data.getDataItem(constants.TRANSPORT_EVENT_DATA_TYPE_DOCUMENTS_DEFECT)
        const documentsDefectText = data.getDataItem(constants.TRANSPORT_EVENT_DATA_TYPE_DOCUMENTS_DEFECT_TEXT)
        const documentsDefectExists = typeDataFields && typeDataFields.indexOf(constants.TRANSPORT_EVENT_DATA_TYPE_DOCUMENTS_DEFECT) >= 0

        const defects = []

        defectExists &&
            defects.push({
                type: this.props.intl.formatMessage({ id: 'fields.defect' }),
                value: defect && defect.value,
                note: defectText && defectText.value,
            })

        vehicleDefectExists &&
            defects.push({
                type: this.props.intl.formatMessage({ id: 'fields.vehicleDefect' }),
                value: vehicleDefect && vehicleDefect.value,
                note: vehicleDefectText && vehicleDefectText.value,
            })

        trailerDefectExists &&
            defects.push({
                type: this.props.intl.formatMessage({ id: 'fields.trailerDefect' }),
                value: trailerDefect && trailerDefect.value,
                note: trailerDefectText && trailerDefectText.value,
            })

        documentsDefectExists &&
            defects.push({
                type: this.props.intl.formatMessage({ id: 'fields.documentsDefect' }),
                value: documentsDefect && documentsDefect.value,
                note: documentsDefectText && documentsDefectText.value,
            })

        return (
            <tr>
                <td className="w-120 text-center">{formatters.datetimeFormatter(data.datetime)}</td>
                <td className="w-80 text-center">
                    <Link to={`/transports/${data.transport_id}`}>
                        {data.transport_id}
                    </Link>
                </td>
                <td className="w-100 text-center">{vehicle && vehicle.name}</td>
                <td className="w-100 text-center">{trailer && trailer.name}</td>
                <td className="w-120">{driver && driver.getShortFullName()}</td>
                <td className="w-220">
                    {country ? `${country.iso_code} ` : null}
                    {transportPoint ? `${transportPoint.zipcode} ${transportPoint.city}` : null}
                </td>
                <td className="w-220">{type && this.props.intl.formatMessage({ id: type.name })}</td>
                <td className="w-80 text-center">
                    {defects.length === 0 && <div>{formatters.boolFormatterDanger(0)}</div>}
                    {defects.map(def => <div key={def.type}>{formatters.boolFormatterDanger(def.value)}</div>)}
                </td>
                <td className="w-160">
                    {defects.length === 0 && <div>{this.props.intl.formatMessage({ id: 'fields.defect' })}</div>}
                    {defects.map(def => <div key={def.type}>{def.type}</div>)}
                </td>
                <td className="w-max wm-200">{defects.map(def => <div key={def.type}>{def.note}</div>)}</td>
                <td className="w-160 text-center">{formatters.gpsFormatter(data.gps_lat, data.gps_lng)}</td>
                <td className="w-100 text-right">
                    {data.transport_point_files &&
                        data.transport_point_files.map(
                            file =>
                                file.filename ? (
                                    <a
                                        key={file.id}
                                        href={`${config.CFG_API_BASE_URL}/files/transport-documents/${file.filename}`}
                                        className="text-gray m-l-xxs"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="far fa-file-image" />
                                    </a>
                                ) : (
                                    <span key={file.id} className="text-default m-l-xxs">
                                        <i className="far fa-file-image" />
                                    </span>
                                )
                        )}
                </td>
            </tr>
        )
    }
}

export default injectIntl(ReportVehicleChecksRow)
