import React from 'react'
import { Route, Switch } from 'react-router'
import CarrierScoring from './CarrierScoring'

const GoodsTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={CarrierScoring} />
    </Switch>
)

export default GoodsTypes
