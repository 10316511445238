import { Record } from 'immutable'

export class CustomerContact extends Record({
    id: undefined,
    name: undefined,
    position: undefined,
    phone: undefined,
    email: undefined,
    note: undefined,
}) {}
