import { Record } from 'immutable'

export class DriverTimes extends Record({
    id: undefined,
    driver_id: undefined,
    vehicle_id: undefined,
    block_is_active: undefined,
    block_start_datetime: undefined,
    block_end_datetime: undefined,
    block_drive_time: undefined,
    block_drive_time_remaining: undefined,
    block_drive_time_is_too_long: undefined,
    day_drive_time: undefined,
    day_drive_time_remaining: undefined,
    day_drive_time_is_too_long: undefined,
    day_drive_time_remaining_cleaned: undefined,
    day_drive_time_is_too_long_cleaned: undefined,
}) {}
