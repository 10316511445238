import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_NUMBERINGS } from '../permissions'

// eslint-disable-next-line react/prefer-stateless-function
class CompaniesNumberingsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick && this.props.onClick(this.props.item)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete && this.props.onDelete(this.props.item)
    }

    render() {
        const numbering = this.props.item

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS_NUMBERINGS.UPDATE, PERMS_NUMBERINGS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS_NUMBERINGS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_NUMBERINGS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-200">{numbering.name}</td>
                <td className="w-160">
                    <FormattedMessage id={`companyNumberingTypes.${numbering.type_id}`} />
                </td>
                <td className="w-400">{numbering.example}</td>
                <td className="w-max wm-200">{numbering.note}</td>
            </tr>
        )
    }
}

export default injectIntl(CompaniesNumberingsListRow)
