import { OrderedMap, Map } from 'immutable'

export const VEHICLE_FUELINGS_IMPORT_CONSTANTS = OrderedMap({
    date_time: Map({ label: 'fields.dateTime' }),
    date: Map({ label: 'fields.date' }),
    time: Map({ label: 'fields.time' }),
    registration: Map({ label: 'fields.registration' }),
    country: Map({ label: 'fields.country' }),
    gps_lat: Map({ label: 'fields.gpsLat' }),
    gps_lng: Map({ label: 'fields.gpsLng' }),
    item: Map({ label: 'fields.item' }),
    value: Map({ label: 'fields.volume' }),
    number: Map({ label: 'fields.documentNumber' }),
})

export const ITEMS_CONSTANTS = OrderedMap({
    fuel_level: Map({ label: 'fields.fuel_level' }),
    adblue_level: Map({ label: 'fields.adblue_level' }),
})

export const PRODUCTS_CONSTANTS = OrderedMap({
    key: Map({ label: 'fields.code' }),
    name: Map({ label: 'fields.name' }),
})

export const DATE_FORMATS = [
    { value: 'd/m/Y', label: 'dd/mm/yyyy' },
    { value: 'd-m-Y', label: 'dd-mm-yyyy' },
    { value: 'd.m.Y', label: 'dd.mm.yyyy' },
    { value: 'd. m. Y', label: 'dd. mm. yyyy' },
    { value: 'm/d/Y', label: 'mm/dd/yyyy' },
    { value: 'm-d-Y', label: 'mm-dd-yyyy' },
    { value: 'm.d.Y', label: 'mm.dd.yyyy' },
    { value: 'm. d. Y', label: 'mm. dd. yyyy' },
]

export const TIME_FORMATS = [{ value: 'H:i', label: 'hh:mm' }, { value: 'G:i', label: 'h:mm' }, { value: 'H:i:s', label: 'hh:mm:ss' }]
