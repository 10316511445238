import React, { Component } from 'react'

import HEREMap from './HEREMap'

class HEREMapRoutes extends Component {
    isComponentMounted = false

    state = {
        mapZoom: 7,
        mapCenter: {
            lat: 49.758924,
            lng: 15.546382,
        },
    }

    componentDidMount() {
        this.isComponentMounted = true
    }

    componentWillUnmount() {
        this.isComponentMounted = false
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className={`col-md-12 ${this.props.className}`}>
                        <HEREMap
                            id={this.props.id ? this.props.id : 'mapContainerRoutes'}
                            appId={this.props.appId}
                            appCode={this.props.appCode}
                            className={this.props.className}
                            markers={this.props.markers}
                            positions={this.props.positions}
                            routes={this.props.routes}
                            center={this.state.mapCenter}
                            zoom={this.state.mapZoom}
                            showPOIs={this.props.showPOIs}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default HEREMapRoutes
