import React from 'react'
import { Route, Switch } from 'react-router'
import VehiclePositionsList from './VehiclePositionsList'

const VehiclePositions = props => (
    <Switch>
        <Route exact path={props.match.url} component={VehiclePositionsList} />
    </Switch>
)

export default VehiclePositions
