import React, { Component } from 'react'
import Immutable from 'immutable'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as config from '../../../common/config'
import * as constants from '../../transports/constants'
import * as formatters from '../../../common/formatters'

import { HEREMapRoutes } from '../../../common/here_maps'

import TransportTemplatesRoutesSummaryDetail from './TransportTemplatesRoutesSummaryDetail'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_COST } from '../permissions'

class TransportTemplatesRoutesSummary extends Component {
    state = {
        routesSet: false,
        routeSpecified: null,
    }

    setRoutes = routes => {
        const newState = {
            routesSet: true,
        }

        routes.valueOf().forEach(route => {
            switch (route.type_id) {
                case constants.TRANSPORT_ROUTE_TYPE_SPECIFIED: {
                    newState.routeSpecified = route
                    break
                }
                default: {
                    break
                }
            }
        })

        this.setState(newState)
    }

    /* eslint-disable max-len */

    getIconSVG = (text, bgColor = '#ffffff', textColor = '#000000', fontSize = '14px') => (
        <svg xmlns="http://www.w3.org/2000/svg" width="28px" height="30px">
            <path
                d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z"
                fill="#fff"
            />
            <path
                d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z"
                fill={bgColor}
            />
            <text x="13" y="19" fontSize={fontSize} fontWeight="bold" textAnchor="middle" fill={textColor} style={{ fontFamily: 'sans-serif' }}>
                {text}
            </text>
        </svg>
    )

    /* eslint-enable max-len */

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.routes &&
            (!this.state.routesSet || !this.props.routes || JSON.stringify(nextProps.routes.toJS()) !== JSON.stringify(this.props.routes.toJS()))
        ) {
            this.setRoutes(nextProps.routes)
        }
    }

    render() {
        const routeSpecified = this.state.routeSpecified
        const transportPoints = this.props.points
        const transportType = this.props.transportType

        const routeSpecifiedClassName =
            transportType && routeSpecified && routeSpecified.getProfitPerc() < transportType.min_profit_percent ? 'text-danger' : 'text-success'

        const hereMapRoutes = []
        if (routeSpecified) {
            hereMapRoutes.push({
                waypoints: routeSpecified.getWaypoints(),
                color: '#22BAA0',
                showRoute: true,
                showWaypoints: false,
                showArrows: false,
            })
        }

        let markers = {}

        transportPoints &&
            transportPoints.forEach((point, key) => {
                if (point.gps_lat && point.gps_lng) {
                    const pointType = point.type_id && this.props.pointTypes[point.type_id]

                    markers[point.id] = {
                        center: {
                            lat: point.gps_lat,
                            lng: point.gps_lng,
                        },
                        iconSvg: this.getIconSVG(key + 1, pointType && pointType.rgb_bg, pointType && pointType.rgb_text),
                        zIndex: 1,
                        size: { w: 40, h: 40 },
                        anchor: { x: 20, y: 40 },
                    }
                }
            })
        markers = Immutable.Map(markers)

        return (
            <div className="transport-routes">
                <div className="row">
                    <div className="col-md-3">
                        <div className="text-center">
                            <span className={!routeSpecified ? 'text-gray' : ''}>
                                <FormattedMessage id="fields.route" />
                            </span>
                            <PermissionsCheck hasAny={Object.values(PERMS_COST)}>
                                <h1 className={routeSpecifiedClassName}>{routeSpecified && formatters.priceFormatter(routeSpecified.getProfit())}</h1>
                                <span className={routeSpecifiedClassName}>
                                    {routeSpecified && formatters.percentFormatter(routeSpecified.getProfitPerc())}
                                </span>
                            </PermissionsCheck>
                        </div>
                        <TransportTemplatesRoutesSummaryDetail route={routeSpecified} transportType={transportType} />
                    </div>

                    <div className="col-md-9">
                        <HEREMapRoutes
                            appId={config.CFG_HERE_MAPS_APP_ID}
                            appCode={config.CFG_HERE_MAPS_APP_CODE}
                            routes={hereMapRoutes}
                            markers={markers}
                            className="h-700"
                            showPOIs
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(TransportTemplatesRoutesSummary)
