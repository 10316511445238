import { Record } from 'immutable'

export class CustomerPlan extends Record({
    id: undefined,
    customer_id: undefined,
    company_cost_center_id: undefined,
    date_from: undefined,
    date_to: undefined,
    count: undefined,
    sales: undefined,
    profit: undefined,
    note: undefined,
}) {}
