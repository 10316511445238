import React from 'react'
import { Route, Switch } from 'react-router'
import ReportCostCenterProfits from './ReportCostCenterProfits'

const Components = props => {
    console.log(props.match.url)
    return (
    <Switch>
        <Route exact path={`${props.match.url}/:year?`} component={ReportCostCenterProfits} />
    </Switch>
)}

export default Components
