import { Record } from 'immutable'

export class BlockedIp extends Record({
    id: undefined,
    name: undefined,
    ip_address: undefined,
    cidr: undefined,
    is_blocked: undefined,
    note: undefined,
}) {}
