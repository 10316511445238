// Fetch list

export const FETCH = 'vehicleDocuments.FETCH'
export const FETCH_FOR_MAP = 'vehicleDocuments.FETCH_FOR_MAP'
export const FETCH_STARTED = 'vehicleDocuments.FETCH_STARTED'
export const FETCH_FULFILLED = 'vehicleDocuments.FETCH_FULFILLED'
export const FETCH_REJECTED = 'vehicleDocuments.FETCH_REJECTED'
export const FETCH_CANCELLED = 'vehicleDocuments.FETCH_CANCELLED'

export const SORTING_CHANGED = 'vehicleDocuments.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'vehicleDocuments.FETCH_ONE'
export const FETCH_ONE_STARTED = 'vehicleDocuments.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'vehicleDocuments.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'vehicleDocuments.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'vehicleDocuments.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'vehicleDocuments.SAVE'
export const SAVE_STARTED = 'vehicleDocuments.SAVE_STARTED'
export const SAVE_FULFILLED = 'vehicleDocuments.SAVE_FULFILLED'
export const SAVE_REJECTED = 'vehicleDocuments.SAVE_REJECTED'
export const SAVE_CANCELLED = 'vehicleDocuments.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'vehicleDocuments.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'vehicleDocuments.DELETE'
export const DELETE_STARTED = 'vehicleDocuments.DELETE_STARTED'
export const DELETE_FULFILLED = 'vehicleDocuments.DELETE_FULFILLED'
export const DELETE_REJECTED = 'vehicleDocuments.DELETE_REJECTED'
export const DELETE_CANCELLED = 'vehicleDocuments.DELETE_CANCELLED'

// Clear

export const CLEAR = 'vehicleDocuments.CLEAR'

// Load files

export const FETCH_FILES = 'vehicleDocuments.FETCH_FILES'
export const FETCH_FILES_STARTED = 'vehicleDocuments.FETCH_FILES_STARTED'
export const FETCH_FILES_FULFILLED = 'vehicleDocuments.FETCH_FILES_FULFILLED'
export const FETCH_FILES_REJECTED = 'vehicleDocuments.FETCH_FILES_REJECTED'
export const FETCH_FILES_CANCELLED = 'vehicleDocuments.FETCH_FILES_CANCELLED'

// Upload files

export const UPLOAD_FILES = 'vehicleDocuments.UPLOAD_FILES'
export const UPLOAD_FILES_STARTED = 'vehicleDocuments.UPLOAD_FILES_STARTED'
export const UPLOAD_FILES_FULFILLED = 'vehicleDocuments.UPLOAD_FILES_FULFILLED'
export const UPLOAD_FILES_REJECTED = 'vehicleDocuments.UPLOAD_FILES_REJECTED'
export const UPLOAD_FILES_CANCELLED = 'vehicleDocuments.UPLOAD_FILES_CANCELLED'

// Delete file

export const DELETE_FILE = 'vehicleDocuments.DELETE_FILE'
export const DELETE_FILE_STARTED = 'vehicleDocuments.DELETE_FILE_STARTED'
export const DELETE_FILE_FULFILLED = 'vehicleDocuments.DELETE_FILE_FULFILLED'
export const DELETE_FILE_REJECTED = 'vehicleDocuments.DELETE_FILE_REJECTED'
export const DELETE_FILE_CANCELLED = 'vehicleDocuments.DELETE_FILE_CANCELLED'


// Export

export const EXPORT = 'vehicleDocuments.EXPORT'
export const EXPORT_FULFILLED = 'vehicleDocuments.EXPORT_FULFILLED'
