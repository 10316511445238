import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import classnames from 'classnames'
import { Modal } from 'react-bootstrap'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as reportDriverHourlyOverviewActionCreators from '../actionCreators'
import { getData, getFetching, getDetailsData, getDetailsFetching } from '../selectors'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import {TableFilterCheckboxField, TableFilterInputField} from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'report_driver_hourly_overview'

class ReportDriverHourlyOverview extends Component {
    state = {
        modalDetails: {
            show: false,
            driverId: null,
            dateFrom: null,
            dateTo: null,
        },
    }

    refresh = () => {
        this.props.actions.fetchReportDriverHourlyOverview(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportDriverHourlyOverview(this.props.tableModel.get('filters').toJS())
    }

    handleFilterChanged = (key, value) => {
        const filters = this.props.tableModel.get('filters')

        let stringFrom = filters.getIn(['date_from', 'value'])
        let stringTo = filters.getIn(['date_to', 'value'])

        if (key === 'period') {
            stringFrom = value.momentFrom.format('DD.MM.YYYY')
            stringTo = value.momentTo.format('DD.MM.YYYY')
        }

        this.props.table.changeFilters([
            {
                column: 'date_from',
                type: TableModelColumnFilteringType.equal,
                value: stringFrom,
            },
            {
                column: 'date_to',
                type: TableModelColumnFilteringType.equal,
                value: stringTo,
            },
        ])
    }

    // Detail
    handleShowDetails = (e, driverId, dateFrom, dateTo) => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: true,
                    driverId,
                    dateFrom,
                    dateTo,
                },
            },
            () => {
                this.props.actions.fetchReportDriverHourlyOverviewDetails(driverId, dateFrom, dateTo)
            }
        )
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: false,
                    driverId: null,
                    dateFrom: null,
                    dateTo: null,
                },
            },
            () => {
                this.props.actions.clearReportDriverHourlyOverviewDetails()
            }
        )
    }

    handleExportDetails = e => {
        e && e.preventDefault()

        const stringFrom = this.state.modalDetails.dateFrom
        const strinTo = this.state.modalDetails.dateTo

        this.props.actions.exportReportDriverHourlyOverviewDetails(
            this.state.modalDetails.driverId,
            stringFrom,
            strinTo,
            this.props.tableModel.get('filters').toJS()
        )
    }

    componentDidMount() {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.setConfiguration(
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ],
            false
        )

        this.props.actions.fetchReportDriverHourlyOverview(this.props.tableModel.get('filters'))
    }

    componentWillUnmount() {
        this.props.actions.clearReportDriverHourlyOverviewDetails()
        this.props.actions.clearReportDriverHourlyOverview()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportDriverHourlyOverview.heading' }))

        const data = this.props.data
        const filters = this.props.tableModel.get('filters')

        const detailsData = this.props.detailsData

        const stringFrom = filters.getIn(['date_from', 'value'])
        const stringTo = filters.getIn(['date_to', 'value'])

        const momentToday = moment()
        const momentFrom = stringFrom ? helpers.convertCSDateToMoment(stringFrom) : moment().startOf('month')
        const momentTo = stringTo ? helpers.convertCSDateToMoment(stringTo) : moment().endOf('month')
        const stringISOFrom = momentFrom.format('YYYY-MM-DD')
        const stringISOTo = momentTo.format('YYYY-MM-DD')

        const momentDays = []
        const totalDays = {}
        for (let i = 0; i <= momentTo.diff(momentFrom, 'days'); i++) {
            const momentDay = moment(momentFrom).add(i, 'days')
            momentDays.push(momentDay)
            totalDays[momentDay.format('YYYY-MM-DD')] = 0
        }

        let total = 0

        const detailsTotal = {
            duration: 0,
            distance: 0,
        }

        let day = null

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportDriverHourlyOverview.heading" />
                                        </span>
                                    </h4>
                                    <div className="pull-left" style={{ marginLeft: '100px' }}>
                                    <span className="pull-left text-gray f-bold" style={{ paddingTop: '10px' }}>
                                        <FormattedMessage id="fields.showIncl" />:
                                    </span>
                                        <div className="pull-left m-l-sm">
                                            <TableFilterCheckboxField
                                                identifier="with_empty"
                                                type={TableModelColumnFilteringType.bool}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                label={this.props.intl.formatMessage({ id: 'fields.showInclEmpty' })}
                                            />
                                        </div>
                                        <div className="pull-left m-l-sm">
                                            <TableFilterCheckboxField
                                                identifier="with_inactive"
                                                type={TableModelColumnFilteringType.bool}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                label={this.props.intl.formatMessage({ id: 'fields.showInclInactive' })}
                                            />
                                        </div>
                                    </div>

                                    <div className="btns-list">
                                        <PeriodPicker
                                            className="m-r-lg"
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_MONTH}
                                            onChange={(momentFrom, momentTo) => this.handleFilterChanged('period', { momentFrom, momentTo })}
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-max wm-200">
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                {momentDays.map(momentDay => (
                                                    <th key={`day-th-${momentDay.format('YYYY-MM-DD')}`} className="w-50 text-center">
                                                        {momentDay.format('DD/MM')}
                                                    </th>
                                                ))}
                                                <th className="wm-100 text-right">
                                                    <FormattedMessage id="fields.total" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-max wm-200">
                                                    <TableFilterInputField
                                                        identifier="driver_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                {momentDays.map(momentDay => (
                                                    <th key={`day-th-filter-${momentDay.format('YYYY-MM-DD')}`} className="w-50" />
                                                ))}
                                                <th className="w-100" />
                                            </tr>
                                        </thead>
                                        <tbody className="relative-td">
                                            {data &&
                                                data.drivers &&
                                                data.drivers.map(driver => {
                                                    if (
                                                        filters.getIn(['driver_name', 'value']) &&
                                                        driver.name.toLowerCase().indexOf(filters.getIn(['driver_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    let totalRow = 0

                                                    return (
                                                        <tr key={`vehicle-${driver.id}`}>
                                                            <td className="w-max wm-200">{driver.name}</td>
                                                            {momentDays.map(momentDay => {
                                                                const date = momentDay.format('YYYY-MM-DD')

                                                                let seconds = 0

                                                                if (driver.days[date]) {
                                                                    seconds = driver.days[date].seconds
                                                                    totalDays[date] += seconds
                                                                    totalRow += seconds
                                                                }
                                                                const haveData = seconds !== 0
                                                                const isWeekend = momentDay.isoWeekday() >= 6
                                                                const isHoliday = data.holidays.includes(date)

                                                                const classes = classnames('w-50 text-center', {
                                                                    'is-weekend': isWeekend || isHoliday,
                                                                    'is-today': momentDay.isSame(momentToday, 'day'),
                                                                    'text-gray': haveData && !driver.days[date].drive && driver.days[date].pause,
                                                                })

                                                                const value = haveData ? formatters.durationFromSecondsFormatter(seconds) : '-'
                                                                const countryChange =
                                                                    haveData && driver.days[date].country_change ? (
                                                                        <span className="dot dot-danger" />
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                const role = haveData ? 'button' : undefined
                                                                const onClick = haveData
                                                                    ? e => {
                                                                        this.handleShowDetails(e, driver.id, date, date)
                                                                    }
                                                                    : undefined

                                                                return (
                                                                    <td key={`day-td-${date}`} className={classes} role={role} onClick={onClick}>
                                                                        {value}
                                                                        {countryChange}
                                                                    </td>
                                                                )
                                                            })}

                                                            <td
                                                                className="w-100 text-right"
                                                                role={totalRow !== 0 ? 'button' : undefined}
                                                                onClick={
                                                                    totalRow !== 0
                                                                        ? e => {
                                                                            this.handleShowDetails(e, driver.id, stringISOFrom, stringISOTo)
                                                                        }
                                                                        : undefined
                                                                }
                                                            >
                                                                <strong>
                                                                    {totalRow === 0 ? '-' : formatters.durationFromSecondsFormatter(totalRow)}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-max wm-200">
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                {momentDays.map(momentDay => {
                                                    const date = momentDay.format('YYYY-MM-DD')

                                                    total += totalDays[date]

                                                    const haveData = totalDays[date] !== 0
                                                    const value = haveData ? formatters.durationFromSecondsFormatter(totalDays[date]) : '-'
                                                    const role = haveData ? 'button' : undefined
                                                    const onClick = haveData
                                                        ? e => {
                                                            this.handleShowDetails(e, null, date, date)
                                                        }
                                                        : undefined

                                                    return (
                                                        <td key={`day-td-total-${date}`} className="w-50 text-center" role={role} onClick={onClick}>
                                                            <strong>{value}</strong>
                                                        </td>
                                                    )
                                                })}

                                                <td
                                                    className="w-100 text-right"
                                                    role={total !== 0 ? 'button' : undefined}
                                                    onClick={
                                                        total !== 0
                                                            ? e => {
                                                                this.handleShowDetails(e, null, stringISOFrom, stringISOTo)
                                                            }
                                                            : undefined
                                                    }
                                                >
                                                    <strong>{total === 0 ? '-' : formatters.durationFromSecondsFormatter(total)}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-md-2">
                        <Modal.Header closeButton>
                            <Modal.Title>{detailsData ? detailsData.title : null}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="clearfix">
                            <LoadingOverlay active={this.props.detailsFetching}>
                                <div className="btns-list">
                                    {detailsData && detailsData.trip_records.length > 0 && (
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExportDetails}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    )}
                                </div>

                                <div className="pull-left wp-100">
                                    <div className="panel-head">
                                        <h2 className="pull-left wp-100">
                                            <div className="pull-left">
                                                <FormattedMessage id="modules.reportDriverHourlyOverview.detail.sumeryHeading" />
                                            </div>
                                            <div className="pull-right text-md" />
                                        </h2>
                                    </div>

                                    <div className="pull-left wp-100 p-v-xs p-h-xs m-b-sm b alert-default">
                                        <div className="table-container">
                                            <table className="table table-striped table-no-border no-m">
                                                <thead>
                                                    <tr>
                                                        <th className="w-max wm-200">
                                                            <FormattedMessage id="fields.country" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.totalTime" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.drive" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.pause" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.km" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {detailsData &&
                                                        detailsData.country_summary.map(summary => {
                                                            return (
                                                                <tr key={`transport-${summary.id}`}>
                                                                    <td>{summary.country_iso}</td>
                                                                    <td className="text-right">
                                                                        {formatters.durationFromSecondsFormatter(summary.duration)}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {formatters.durationFromSecondsFormatter(summary.drive_duration)}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {formatters.durationFromSecondsFormatter(summary.pause_duration)}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {formatters.distanceFormatter(summary.distance, '0,0.00')}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="pull-left wp-100">
                                    <div className="panel-head">
                                        <h2 className="pull-left wp-100">
                                            <div className="pull-left">
                                                <FormattedMessage id="modules.reportDriverHourlyOverview.detail.tripRecordsHeading" />
                                            </div>
                                            <div className="pull-right text-md" />
                                        </h2>
                                    </div>

                                    <div className="pull-left wp-100 p-v-xs p-h-xs m-b-sm b alert-default">
                                        <div className="table-container">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th className="w-120">
                                                            <FormattedMessage id="fields.dateFrom" />
                                                        </th>
                                                        <th className="w-120">
                                                            <FormattedMessage id="fields.dateTo" />
                                                        </th>
                                                        <th className="w-200">
                                                            <FormattedMessage id="fields.driver" />
                                                        </th>
                                                        <th className="w-100">
                                                            <FormattedMessage id="fields.vehicle" />
                                                        </th>
                                                        <th className="w-100 text-center">
                                                            <FormattedMessage id="fields.country" />
                                                        </th>
                                                        <th className="w-max wm-300">
                                                            <FormattedMessage id="fields.eventType" />
                                                        </th>
                                                        <th className="w-80 text-right">
                                                            <FormattedMessage id="fields.duration" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.km" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {detailsData &&
                                                        detailsData.trip_records.map(tripRecord => {
                                                            const country = tripRecord.country.iso_code
                                                                ? tripRecord.country.iso_code
                                                                : tripRecord.country.originCountry &&
                                                                tripRecord.country.targetCountry &&
                                                                `${tripRecord.country.originCountry.iso_code} / ${
                                                                    tripRecord.country.targetCountry.iso_code
                                                                }`

                                                            detailsTotal.duration += tripRecord.duration
                                                            if (tripRecord.event === 1) {
                                                                detailsTotal.distance += tripRecord.distance
                                                            }

                                                            let dayRow = null
                                                            if (day !== tripRecord.day) {
                                                                day = tripRecord.day
                                                                let momentDay = moment(tripRecord.start_datetime)
                                                                let dayString = momentDay.format('dddd ')
                                                                let dayData = detailsData.day_summary.find(day_summary => day_summary.day === day)
                                                                dayString = dayString.charAt(0).toUpperCase() + dayString.slice(1)

                                                                const isWeekend = momentDay.isoWeekday() >= 6
                                                                const isHoliday = data.holidays.includes(day)

                                                                dayRow = (
                                                                    <tr key={`transport-${day}`}>
                                                                        <td colSpan="8" className={isWeekend || isHoliday ? 'is-weekend' : ''}>
                                                                            <strong>
                                                                                {dayString} {formatters.dateFormatter(tripRecord.start_datetime, 'D.M.YYYY')}{' '}
                                                                                {formatters.durationFromSecondsFormatter(dayData.drive_duration)}{' '}
                                                                                {formatters.durationFromSecondsFormatter(dayData.pause_duration)}{' '}
                                                                                {formatters.distanceFormatter(dayData.distance, '0,0.00')}{' '}
                                                                                {formatters.durationFromSecondsFormatter(dayData.duration)}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }

                                                            return (
                                                                <React.Fragment key={`transport-${tripRecord.id}`}>
                                                                    {dayRow}
                                                                    <tr>
                                                                        <td className="w-120">
                                                                            {formatters.datetimeFormatter(tripRecord.start_datetime)}
                                                                        </td>
                                                                        <td className="w-120">
                                                                            {tripRecord.event !== 2 && formatters.datetimeFormatter(tripRecord.end_datetime)}
                                                                        </td>
                                                                        <td className="w-200">{tripRecord.driver.full_name}</td>
                                                                        <td className="w-100">{tripRecord.vehicle.name}</td>
                                                                        <td className="w-100 text-center">{country}</td>
                                                                        <td className="w-max wm-300">
                                                                            {this.props.intl.formatMessage({
                                                                                id: `vehicleTripRecordType.${tripRecord.event}`,
                                                                            })}
                                                                            {tripRecord.borderCrossing && `: ${tripRecord.borderCrossing.name}`}
                                                                        </td>
                                                                        <td className="w-80 text-right">
                                                                            {tripRecord.event !== 2 &&
                                                                                formatters.durationFromSecondsFormatter(tripRecord.duration)}
                                                                        </td>
                                                                        <td className="w-120 text-right">
                                                                            {tripRecord.event === 1 &&
                                                                                formatters.distanceFormatter(tripRecord.distance, '0,0.00')}
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            )
                                                    })}

                                                    <tr className="b-top row-sum">
                                                        <td colSpan="6">
                                                            <strong>
                                                                <FormattedMessage id="fields.total" />:
                                                            </strong>
                                                        </td>
                                                        <td className="text-right">{formatters.durationFromSecondsFormatter(detailsTotal.duration)}</td>
                                                        <td className="text-right">{formatters.distanceFormatter(detailsTotal.distance, '0,0.00')}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default" onClick={this.handleHideDetails}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportDriverHourlyOverviewActionCreators,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ReportDriverHourlyOverview)
)
