import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Constant } from './Constant'

const ConstantList = ({ constants, variables, onDelete }) => {
    return (
        <div className="constant-list">
            {constants.keySeq().map(constantKey => {
                const constant = constants.get(constantKey)
                return (
                    <Constant key={constantKey} constantKey={constantKey} constant={constant}>
                        <FormattedMessage id={constant.get('label')} />
                        {constant.has('variables') &&
                            constant.get('variables').map(variableKey => {
                                if (variables.has(variableKey)) {
                                    const variable = variables.get(variableKey)
                                    return (
                                        <div className="value" key={variableKey}>
                                            {variable.get('label')}
                                            <button className="pull-right" onClick={() => onDelete(constantKey, variableKey)}>
                                                <i className="far fa-times" />
                                            </button>
                                        </div>
                                    )
                                }
                                return null
                            })}
                    </Constant>
                )
            })}
        </div>
    )
}

export default ConstantList
