import React from 'react'
import { Route, Switch } from 'react-router'
import ServiceOrdersList from './ServiceOrdersList'
import ServiceOrdersEdit from './ServiceOrdersEdit'

const ServiceOrders = props => (
    <Switch>
        <Route exact path={props.match.url} component={ServiceOrdersList} />
        <Route exact path={`${props.match.url}/new`} component={ServiceOrdersEdit} />
        <Route exact path={`${props.match.url}/:serviceOrderID`} component={ServiceOrdersEdit} />
    </Switch>
)

export default ServiceOrders
