import React, { Component } from 'react'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import { numberFormatter, priceFormatter } from "../../../common/formatters";

class WarehousingItemsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render = () => (
        <tr onClick={this.handleClick} className="row-selectable">
            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                <td className="w-60 table-buttons">
                    <PermissionsCheck has={[PERMS.UPDATE]}>
                        <button className="far fa-pencil text-gray m-r-xxs" />
                    </PermissionsCheck>
                    <PermissionsCheck has={[PERMS.DELETE]}>
                        <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                    </PermissionsCheck>
                </td>
            </PermissionsCheck>
            <td className="w-200">
                {this.props.data.code}
            </td>
            <td className="w-max wm-300">
                <strong>{this.props.data.name}</strong>
            </td>
            <td className="w-140 text-right"><strong>{numberFormatter(this.props.data.stock_info, '0,0.00', this.props.unit ? this.props.unit.code : '')}</strong></td>
            <td className="w-140 text-right">{numberFormatter(this.props.data.receipt_count, '0,0.00', this.props.unit ? this.props.unit.code : '')}</td>
            <td className="w-140 text-right">{numberFormatter(this.props.data.issued_count, '0,0.00', this.props.unit ? this.props.unit.code : '')}</td>
            <td className="w-140 text-right">{priceFormatter(this.props.data.price, '0,0.00', this.props.currency ? this.props.currency.iso_code : '')}</td>
            <td className="w-140 text-right">{priceFormatter(this.props.data.price * this.props.data.stock_info, '0,0.00', this.props.currency ? this.props.currency.iso_code : '')}</td>
        </tr>
    )
}

export default WarehousingItemsListRow
