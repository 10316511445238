import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { driverTimesheetSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { DriverTimesheet } from './model'
import { Driver } from '../drivers/model'
import * as driversActionCreators from '../drivers/actionCreators'

// Fetch

const fetchDriverTimesheetsEpic = (action$, store) => {
    const tableIdentifier = 'driver_timesheets_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `driver-timesheets/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(driverTimesheetSchema)
                        )

                        const driverTimesheets = normalizedEntitiesToRecordMap(
                            normalizedData.entities.driver_timesheets,
                            DriverTimesheet,
                            normalizedData.result
                        )
                        const drivers = normalizedEntitiesToRecordMap(normalizedData.entities.drivers, Driver)

                        const observables = [
                            Observable.of(driversActionCreators.fetchDriversFulfilled(drivers)),
                            Observable.of(actionCreators.fetchDriverTimesheetsFulfilled(driverTimesheets)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchDriverTimesheetsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchDriverTimesheetEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().driverTimesheets.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `driver-timesheets/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, driverTimesheetSchema)

                        const driverTimesheets = normalizedEntitiesToRecordMap(normalizedData.entities.driver_timesheets, DriverTimesheet)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchDriverTimesheetsFulfilled(driverTimesheets)),
                            Observable.of(actionCreators.fetchDriverTimesheetFulfilled())
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchDriverTimesheetsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveDriverTimesheetEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().driverTimesheets.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload.values)
            if (action.payload.ignoreDuplicity) {
                values.ignore_duplicity = 1
            }

            let path = `driver-timesheets`
            let method = 'POST'

            if (values.id) {
                path = `driver-timesheets/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        if (ajaxResponse.response.duplicity_found) {
                            return Observable.concat(Observable.of(actionCreators.saveDriverTimesheetDuplicityFound(ajaxResponse.response.duplicity)))
                        }

                        const normalizedData = normalize(ajaxResponse.response, driverTimesheetSchema)

                        const driverTimesheets = normalizedEntitiesToRecordMap(normalizedData.entities.driver_timesheets, DriverTimesheet)
                        const driverTimesheet = driverTimesheets.valueSeq().first()

                        return Observable.of(actionCreators.saveDriverTimesheetFulfilled(driverTimesheet))
                    })
                    .catch(error => Observable.of(actionCreators.saveDriverTimesheetRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteDriverTimesheetEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().driverTimesheets.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `driver-timesheets/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteDriverTimesheetFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteDriverTimesheetRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Export

const exportDriverTimesheetsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `driver-timesheets/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

export default [fetchDriverTimesheetsEpic, fetchDriverTimesheetEpic, saveDriverTimesheetEpic, deleteDriverTimesheetEpic, exportDriverTimesheetsEpic]
