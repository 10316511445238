// Fetch list

export const FETCH = 'reportEmptyKilometers.FETCH'
export const FETCH_STARTED = 'reportEmptyKilometers.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportEmptyKilometers.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportEmptyKilometers.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportEmptyKilometers.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportEmptyKilometers.CLEAR'
