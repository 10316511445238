export const PERMS = {
    READ: 'transports.read',
    UPDATE: 'transports.update',
    DELETE: 'transports.delete',
    CREATE: 'transports.create',
    RESTORE: 'transports.restore',
    EXPORT: 'transports.export',
    COPY_TO_CLIPBOARD: 'transports.copy_to_clipboard',
    APPROVAL: 'transports.approval',
}

export const PERMS_COST = {
    READ: 'transports_cost.read',
}

export const PERMS_CUSTOMER = {
    READ: 'transports_customer.read',
    UPDATE: 'transports_customer.update',
}

export const PERMS_CUSTOMER_COST = {
    READ: 'transports_customer_cost.read',
    UPDATE: 'transports_customer_cost.update',
}

export const PERMS_SPEDITION = {
    READ: 'transports_spedition.read',
    UPDATE: 'transports_spedition.update',
}

export const PERMS_SPEDITION_COST = {
    READ: 'transports_spedition_cost.read',
    UPDATE: 'transports_spedition_cost.update',
}

export const PERMS_ROUTES = {
    READ: 'transports_routes.read',
    CREATE: 'transports_routes.create',
    UPDATE: 'transports_routes.update',
    DELETE: 'transports_routes.delete',
}

export const PERMS_GOODS = {
    READ: 'transports_goods.read',
    UPDATE: 'transports_goods.update',
    DELETE: 'transports_goods.delete',
    CREATE: 'transports_goods.create',
}

export const PERMS_PARTS = {
    READ: 'transports_parts.read',
    UPDATE: 'transports_parts.update',
    DELETE: 'transports_parts.delete',
    CREATE: 'transports_parts.create',
}

export const PERMS_FILES = {
    READ: 'transports_files.read',
    UPDATE: 'transports_files.update',
    DELETE: 'transports_files.delete',
    CREATE: 'transports_files.create',
}

export const PERMS_ALLOWANCES = {
    READ: 'transports_allowances.read',
    UPDATE: 'transports_allowances.update',
    DELETE: 'transports_allowances.delete',
    CREATE: 'transports_allowances.create',
}

export const PERMS_SCORING = {
    READ: 'transports_scoring.read',
    UPDATE: 'transports_scoring.update',
    DELETE: 'transports_scoring.delete',
    CREATE: 'transports_scoring.create',
}

export const PERMS_LOG = {
    READ: 'transports_log.read',
    UPDATE: 'transports_log.update',
    DELETE: 'transports_log.delete',
    CREATE: 'transports_log.create',
}

export const PERMS_EVENTS = {
    READ: 'transports_events.read',
    UPDATE: 'transports_events.update',
    DELETE: 'transports_events.delete',
    CREATE: 'transports_events.create',
}

export const PERMS_TO_VEHICLE = {
    CREATE: 'transport_to_vehicle.create',
    DELETE: 'transport_to_vehicle.delete',
}
