import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'

import { url, handleCommonEditActions, validationMessage } from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { Form, InputField, SubmitButton } from '../../../common/form'
import { createGetServiceWorkerByIDSelector, createGetCurrentServiceWorkerStatusSelector } from '../selectors'
import * as serviceWorkersActionCreators from '../actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'service_workers_edit'
const prerequisites = []

class ServiceWorkersEdit extends Component {
    defaultValues = {}

    validationRules = {
        last_name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        first_name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave()
        })
    }

    handleSave = () => {
        this.props.actions.saveServiceWorker(this.state.values)
    }

    componentDidMount() {
        this.props.match.params.serviceWorkerID && this.props.actions.fetchServiceWorker(this.props.match.params.serviceWorkerID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'service-workers'))
        })

        if (JSON.stringify(nextProps.serviceWorker) !== JSON.stringify(this.props.serviceWorker) || (nextProps.serviceWorker && !this.state.values.id)) {
            this.setValues(nextProps.serviceWorker.toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.serviceWorkers.heading' }))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.serviceWorkers.heading" /> -{' '}
                                                {this.props.serviceWorker ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.serviceWorker)}
                                                >
                                                    <div className='row'>
                                                        <div className='col-sm-6'>
                                                            <InputField id="last_name" label={this.props.intl.formatMessage({ id: 'fields.lastName' })} />
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <InputField id="first_name" label={this.props.intl.formatMessage({ id: 'fields.firstName' })} />
                                                        </div>
                                                    </div>
                                                    <InputField id="email" label={this.props.intl.formatMessage({ id: 'fields.email' })} />
                                                    <div className='row'>
                                                        <div className='col-sm-6'>
                                                            <InputField id="costs" label={this.props.intl.formatMessage({ id: 'fields.costs' })} />
                                                        </div>
                                                    </div>

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'service-workers')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        <SubmitButton
                                                            perms={Object.values(PERMS)}
                                                            isEdit={Boolean(this.props.serviceWorker)}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        serviceWorker: createGetServiceWorkerByIDSelector(ownProps.match.params.serviceWorkerID)(state),
        status: createGetCurrentServiceWorkerStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...serviceWorkersActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ServiceWorkersEdit)
)
