import { Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
        duplicity: null,
    }),
    current: Map({
        saving: false,
        error: null,
    }),
    deletion: Map({
        inProgress: false,
        error: null,
    }),
})

function trailerDocuments(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // One
        case actionTypes.FETCH_ONE_STARTED:
            return state.setIn(['current', 'fetching'], true)
        case actionTypes.FETCH_ONE_FULFILLED:
        case actionTypes.FETCH_ONE_REJECTED:
        case actionTypes.FETCH_ONE_CANCELLED:
            return state.setIn(['current', 'fetching'], false)

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], true)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicity'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicity'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], action.payload)
                    .setIn(['all', 'duplicity'], null)
            })
        case actionTypes.SAVE_DUPLICITY_FOUND:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'duplicity'], action.payload)
            })

        // Delete
        case actionTypes.DELETE_STARTED:
            return state.setIn(['deletion', 'inProgress'], true)
        case actionTypes.DELETE_FULFILLED:
            return state.deleteIn(['all', 'objects', action.payload]).withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], null)
            })
        case actionTypes.DELETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], action.payload)
            })
            
        // Fetch files
        case actionTypes.FETCH_FILES_STARTED:
            return state.setIn(['files', 'fetching'], true)
        case actionTypes.FETCH_FILES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['files', 'fetching'], false)
                    .setIn(['files', 'error'], null)
                    .setIn(['files', 'objects'], action.payload)
            })
        case actionTypes.FETCH_FILES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['files', 'fetching'], false).setIn(['files', 'error'], action.payload)
            })
        case actionTypes.FETCH_FILES_CANCELLED:
            return state.setIn(['files', 'fetching'], false)

        // Upload files
        case actionTypes.UPLOAD_FILES_STARTED:
            return state.setIn(['files', 'saving'], true)
        case actionTypes.UPLOAD_FILES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['files', 'saving'], false)
                    .setIn(['files', 'error'], null)
                    .setIn(['files', 'objects'], action.payload)
            })
        case actionTypes.UPLOAD_FILES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['files', 'saving'], false).setIn(['files', 'error'], action.payload)
            })
        case actionTypes.UPLOAD_FILES_CANCELLED:
            return state.setIn(['files', 'saving'], false)

        // Delete file
        case actionTypes.DELETE_FILE_STARTED:
            return state.setIn(['files', 'deleting'], true)
        case actionTypes.DELETE_FILE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['files', 'deleting'], false)
                    .setIn(['files', 'error'], null)
                    .setIn(['files', 'objects'], action.payload)
            })
        case actionTypes.DELETE_FILE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['files', 'deleting'], false).setIn(['files', 'error'], action.payload)
            })
        case actionTypes.DELETE_FILE_CANCELLED:
            return state.setIn(['files', 'deleting'], false)

        // Clear
        case actionTypes.CLEAR:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], OrderedMap({}))
            })

        default:
            return state
    }
}

export default trailerDocuments
