import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import { DispatchersBox } from '../../../common/colored_box'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class TrailersListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render() {
        let currentPosition = this.props.data.current_position
        if (!currentPosition && this.props.trailerCurrentPosition) {
            currentPosition = this.props.trailerCurrentPosition.position_name
        }

        const lastVehicleEvent = this.props.data.last_finished_vehicle_event
        const isLoaded = lastVehicleEvent && lastVehicleEvent.is_trailer_loaded

        const classNames = ['row-selectable']
        if (this.props.data.deleted_at) {
            classNames.push('is-deleted')
        }

        return (
            <tr onClick={this.handleClick} className={classNames.join(' ')}>
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        {!this.props.data.deleted_at && (
                            <PermissionsCheck has={[PERMS.DELETE]}>
                                <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                            </PermissionsCheck>
                        )}
                    </td>
                </PermissionsCheck>
                <td className="w-100">{this.props.data.registration && <DispatchersBox>{this.props.data.registration}</DispatchersBox>}</td>
                <td className="w-160">{this.props.data.vin}</td>
                <td className="w-180">{this.props.trailerType && this.props.trailerType.name}</td>
                <td className="w-140 text-right">
                    {this.props.trailerType &&
                        formatters.dimensionsFormatter(this.props.trailerType.width, this.props.trailerType.height, this.props.trailerType.length)}
                </td>
                <td className="w-80 text-right">{this.props.trailerType && formatters.weightFormatter(this.props.trailerType.weight)}</td>
                <td className="w-max wm-250">{this.props.data.current_position ? <i>{currentPosition}</i> : currentPosition}</td>
                <td className="w-200">
                    {this.props.companyCostCenter && `${this.props.companyCostCenter.code} - ${this.props.companyCostCenter.name}`}
                </td>
                <td className="w-120">
                    {this.props.vehicle && <DispatchersBox dispatcher={this.props.dispatcher}>{this.props.vehicle.registration}</DispatchersBox>}
                </td>
                <td className="w-70 text-center">{formatters.boolFormatter(this.props.data.is_spedition)}</td>
                <td className="w-200">{this.props.carrier && this.props.carrier.name}</td>
                <td className="w-70 text-center">{formatters.boolFormatter(this.props.data.is_active)}</td>
                <td className="w-70 text-center">{formatters.boolFormatter(isLoaded, 'text-danger')}</td>
                <td className="w-70 text-center">{formatters.boolFormatter(this.props.data.is_damage, 'text-danger')}</td>
                <td className="w-70 text-center">
                    {this.props.trailerType ? formatters.boolFormatter(this.props.trailerType.is_secured, 'text-success') : null}
                </td>
                <td className="w-70 text-center">
                    {this.props.trailerType && this.props.trailerType.is_secured
                        ? formatters.boolFormatter(this.props.data.is_security_damage, 'text-danger')
                        : null}
                </td>
                <td className="w-70 text-center">
                    {this.props.trailerType ? formatters.boolFormatter(this.props.trailerType.is_cooled, 'text-success') : null}
                </td>
                <td className="w-70 text-center">
                    {this.props.trailerType && this.props.trailerType.is_cooled
                        ? formatters.boolFormatter(this.props.data.is_cooling_damage, 'text-danger')
                        : null}
                </td>
            </tr>
        )
    }
}

export default TrailersListRow
