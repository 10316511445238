import React from 'react'

import ColoredBox from './ColoredBox'

const DispatchersBox = props => (
    <ColoredBox textRGB={props.dispatcher && props.dispatcher.rgb_text} bgRGB={props.dispatcher && props.dispatcher.rgb_bg}>
        {props.children}
    </ColoredBox>
)

export default DispatchersBox
