import * as actionTypes from './actionTypes'

// Fetch

export const fetchBoard = date => ({
    type: actionTypes.FETCH,
    payload: date,
})

export const fetchBoardFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchBoardRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchBoardCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Vehicle note

export const updateVehicleNote = (vehicleID, text) => ({
    type: actionTypes.VEHICLE_NOTE_UPDATE,
    payload: {
        vehicleID,
        text,
    },
})

export const updateVehicleNoteFulfilled = () => ({
    type: actionTypes.VEHICLE_NOTE_UPDATE_FULFILLED,
})

export const updateVehicleNoteRejected = message => ({
    type: actionTypes.VEHICLE_NOTE_UPDATE_REJECTED,
    payload: message,
})

export const updateVehicleNoteCancelled = () => ({
    type: actionTypes.VEHICLE_NOTE_UPDATE_CANCELLED,
})

// Trailer note

export const updateTrailerNote = (trailerID, text) => ({
    type: actionTypes.TRAILER_NOTE_UPDATE,
    payload: {
        trailerID,
        text,
    },
})

export const updateTrailerNoteFulfilled = () => ({
    type: actionTypes.TRAILER_NOTE_UPDATE_FULFILLED,
})

export const updateTrailerNoteRejected = message => ({
    type: actionTypes.TRAILER_NOTE_UPDATE_REJECTED,
    payload: message,
})

export const updateTrailerNoteCancelled = () => ({
    type: actionTypes.TRAILER_NOTE_UPDATE_CANCELLED,
})

// Trailer info

export const updateTrailerInfo = (trailersId, values) => ({
    type: actionTypes.TRAILER_INFO_UPDATE,
    payload: {
        trailersId,
        values,
    },
})

export const updateTrailerInfoFulfilled = () => ({
    type: actionTypes.TRAILER_INFO_UPDATE_FULFILLED,
})

export const updateTrailerInfoRejected = message => ({
    type: actionTypes.TRAILER_INFO_UPDATE_REJECTED,
    payload: message,
})

export const updateTrailerInfoCancelled = () => ({
    type: actionTypes.TRAILER_INFO_UPDATE_CANCELLED,
})

// Daily note

export const updateDailyNote = (vehicleID, trailerID, date, text) => ({
    type: actionTypes.DAILY_NOTE_UPDATE,
    payload: {
        vehicleID,
        trailerID,
        date,
        text,
    },
})

export const updateDailyNoteFulfilled = () => ({
    type: actionTypes.DAILY_NOTE_UPDATE_FULFILLED,
})

export const updateDailyNoteRejected = message => ({
    type: actionTypes.DAILY_NOTE_UPDATE_REJECTED,
    payload: message,
})

export const updateDailyNoteCancelled = () => ({
    type: actionTypes.DAILY_NOTE_UPDATE_CANCELLED,
})

// Daily info

export const updateDailyInfo = (vehicleID, isSent, isDone) => ({
    type: actionTypes.DAILY_INFO_UPDATE,
    payload: {
        vehicleID,
        isSent,
        isDone,
    },
})

export const updateDailyInfoFulfilled = () => ({
    type: actionTypes.DAILY_INFO_UPDATE_FULFILLED,
})

export const updateDailyInfoRejected = message => ({
    type: actionTypes.DAILY_INFO_UPDATE_REJECTED,
    payload: message,
})

export const updateDailyInfoCancelled = () => ({
    type: actionTypes.DAILY_INFO_UPDATE_CANCELLED,
})

// Daily info

export const updateWeeklyInfo = (vehicleID, year, week, weekendAtHome, weekendAtHomeNote) => ({
    type: actionTypes.WEEKLY_INFO_UPDATE,
    payload: {
        vehicleID,
        year,
        week,
        weekendAtHome,
        weekendAtHomeNote,
    },
})

export const updateWeeklyInfoFulfilled = () => ({
    type: actionTypes.WEEKLY_INFO_UPDATE_FULFILLED,
})

export const updateWeeklyInfoRejected = message => ({
    type: actionTypes.WEEKLY_INFO_UPDATE_REJECTED,
    payload: message,
})

export const updateWeeklyInfoCancelled = () => ({
    type: actionTypes.WEEKLY_INFO_UPDATE_CANCELLED,
})

// Export

export const exportBoard = (date, filters, sorting, preferences) => ({
    type: actionTypes.EXPORT,
    payload: {
        date,
        filters,
        sorting,
        preferences,
    },
})

// Preferences

export const updatePreferences = (preferences, date) => ({
    type: actionTypes.PREFERENCES_UPDATE,
    payload: {
        preferences,
        date,
    },
})

export const updatePreferencesFulfilled = data => ({
    type: actionTypes.PREFERENCES_UPDATE_FULFILLED,
    payload: data,
})

export const updatePreferencesRejected = message => ({
    type: actionTypes.PREFERENCES_UPDATE_REJECTED,
    payload: message,
})

export const updatePreferencesCancelled = () => ({
    type: actionTypes.PREFERENCES_UPDATE_CANCELLED,
})

// Transport point info

export const updateTransportPointNote = (transportPointId, note) => ({
    type: actionTypes.POINT_NOTE_UPDATE,
    payload: {
        transportPointId,
        note,
    },
})

export const updateTransportPointNoteFulfilled = () => ({
    type: actionTypes.POINT_NOTE_UPDATE_FULFILLED,
})

export const updateTransportPointNoteRejected = message => ({
    type: actionTypes.POINT_NOTE_UPDATE_REJECTED,
    payload: message,
})

export const updateTransportPointNoteCancelled = () => ({
    type: actionTypes.POINT_NOTE_UPDATE_CANCELLED,
})
