import * as actionTypes from './actionTypes'

// Fetch

export const fetchOffers = () => ({
    type: actionTypes.FETCH,
})

export const fetchOffersFulfilled = companies => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: companies,
})

export const fetchOffersRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchOffersCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Accept

export const accept = ids => ({
    type: actionTypes.ACCEPT,
    payload: {
        ids,
    },
})

export const acceptFulfilled = ids => ({
    type: actionTypes.ACCEPT_FULFILLED,
    payload: ids,
})

export const acceptRejected = message => ({
    type: actionTypes.ACCEPT_REJECTED,
    payload: message,
})

// Decline

export const decline = (ids, reasonID) => ({
    type: actionTypes.DECLINE,
    payload: {
        ids,
        reasonID,
    },
})

export const declineFulfilled = ids => ({
    type: actionTypes.DECLINE_FULFILLED,
    payload: ids,
})

export const declineRejected = message => ({
    type: actionTypes.DECLINE_REJECTED,
    payload: message,
})

// Export

export const exportOffers = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
