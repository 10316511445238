import React from 'react'
import { Route, Switch } from 'react-router'
import CompaniesList from './CompaniesList'
import CompaniesEdit from './CompaniesEdit'

const Companies = props => (
    <Switch>
        <Route exact path={props.match.url} component={CompaniesList} />
        <Route exact path={`${props.match.url}/new`} component={CompaniesEdit} />
        <Route exact path={`${props.match.url}/:companyID`} component={CompaniesEdit} />
    </Switch>
)

export default Companies
