import * as actionTypes from './actionTypes'

// Fetch

export const fetchDriverTimes = () => ({
    type: actionTypes.FETCH,
})

export const fetchDriverTimesFulfilled = vehicles => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: vehicles,
})

export const fetchDriverTimesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchDriverTimesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})
