import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportVehicleProfits = (year, filters, inclCostCenters, inclDrivers, inclTrailers, inclFeatureCosts, withEmpty, withInactive) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        filters,
        inclCostCenters,
        inclDrivers,
        inclTrailers,
        inclFeatureCosts,
        withEmpty,
        withInactive
    },
})

export const fetchReportVehicleProfitsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportVehicleProfitsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportVehicleProfitsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Export

export const exportReportVehicleProfits = (year, filters, inclCostCenters, inclDrivers, inclTrailers, inclFeatureCosts, withEmpty, withInactive) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
        inclCostCenters,
        inclDrivers,
        inclTrailers,
        inclFeatureCosts,
        withEmpty,
        withInactive
    },
})

// Clear

export const clearReportVehicleProfits = () => ({
    type: actionTypes.CLEAR,
})

// Fetch details

export const fetchReportVehicleProfitsDetails = (id, year, month, inclCostCenters, inclDrivers, inclTrailers, inclFeatureCosts) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        id,
        year,
        month,
        inclCostCenters,
        inclDrivers,
        inclTrailers,
        inclFeatureCosts
    },
})

export const fetchReportVehicleProfitsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportVehicleProfitsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportVehicleProfitsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Export

export const exportReportVehicleProfitsDetails = (id, year, month, inclCostCenters, inclDrivers, inclTrailers, inclFeatureCosts) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        id,
        year,
        month,
        inclCostCenters,
        inclDrivers,
        inclTrailers,
        inclFeatureCosts
    },
})

// Clear

export const clearReportVehicleProfitsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})
