import * as actionTypes from './actionTypes'

// Fetch

export const fetchVehicles = () => ({
    type: actionTypes.FETCH,
})

export const fetchVehiclesFulfilled = vehicles => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: vehicles,
})

export const fetchVehiclesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchVehiclesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchVehicle = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchVehicleFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchVehicleRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchVehicleCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveVehicle = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveVehicleFulfilled = vehicle => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: vehicle,
})

export const saveVehicleRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveVehicleDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteVehicle = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteVehicleFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteVehicleRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Restore

export const restoreVehicle = id => ({
    type: actionTypes.RESTORE,
    payload: id,
})

export const restoreVehicleFulfilled = item => ({
    type: actionTypes.RESTORE_FULFILLED,
    payload: item,
})

export const restoreVehicleRejected = message => ({
    type: actionTypes.RESTORE_REJECTED,
    payload: message,
})

// Transport start

export const fetchTransportStart = values => ({
    type: actionTypes.FETCH_TRANSPORT_START,
    payload: values,
})

export const fetchTransportStartFulfilled = transportStart => ({
    type: actionTypes.FETCH_TRANSPORT_START_FULFILLED,
    payload: transportStart,
})

export const fetchTransportStartRejected = message => ({
    type: actionTypes.FETCH_TRANSPORT_START_REJECTED,
    payload: message,
})

export const fetchTransportStartCancelled = () => ({
    type: actionTypes.FETCH_TRANSPORT_START_CANCELLED,
})

export const clearTransportStart = () => ({
    type: actionTypes.CLEAR_TRANSPORT_START,
})

// Export

export const exportVehicles = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Fetch vehicle history

export const fetchVehicleHistory = id => ({
    type: actionTypes.FETCH_HISTORY,
    payload: id,
})

export const fetchVehicleHistoryFulfilled = data => ({
    type: actionTypes.FETCH_HISTORY_FULFILLED,
    payload: data,
})

export const fetchVehicleHistoryRejected = message => ({
    type: actionTypes.FETCH_HISTORY_REJECTED,
    payload: message,
})

export const fetchVehicleHistoryCancelled = () => ({
    type: actionTypes.FETCH_HISTORY_CANCELLED,
})

// Export vehicle history

export const exportVehicleHistory = id => ({
    type: actionTypes.EXPORT_HISTORY,
    payload: id,
})

export const exportVehicleHistoryFulfilled = data => ({
    type: actionTypes.EXPORT_HISTORY_FULFILLED,
    payload: data,
})

export const exportVehicleHistoryRejected = message => ({
    type: actionTypes.EXPORT_HISTORY_REJECTED,
    payload: message,
})

export const exportVehicleHistoryCancelled = () => ({
    type: actionTypes.EXPORT_HISTORY_CANCELLED,
})

// Fetch vehicle documents

export const fetchVehicleDocuments = (id, page) => ({
    type: actionTypes.FETCH_DOCUMENTS,
    payload: {
        id,
        page,
    },
})

export const fetchVehicleDocumentsFulfilled = data => ({
    type: actionTypes.FETCH_DOCUMENTS_FULFILLED,
    payload: data,
})

export const fetchVehicleDocumentsRejected = message => ({
    type: actionTypes.FETCH_DOCUMENTS_REJECTED,
    payload: message,
})

export const fetchVehicleDocumentsCancelled = () => ({
    type: actionTypes.FETCH_DOCUMENTS_CANCELLED,
})

// Export vehicle documents

export const exportVehicleDocuments = id => ({
    type: actionTypes.EXPORT_DOCUMENTS,
    payload: id,
})

export const exportVehicleDocumentsFulfilled = data => ({
    type: actionTypes.EXPORT_DOCUMENTS_FULFILLED,
    payload: data,
})

export const exportVehicleDocumentsRejected = message => ({
    type: actionTypes.EXPORT_DOCUMENTS_REJECTED,
    payload: message,
})

export const exportVehicleDocumentsCancelled = () => ({
    type: actionTypes.EXPORT_DOCUMENTS_CANCELLED,
})

// Fetch vehicle equipments

export const fetchVehicleEquipments = (id, page) => ({
    type: actionTypes.FETCH_EQUIPMENTS,
    payload: {
        id,
        page,
    },
})

export const fetchVehicleEquipmentsFulfilled = data => ({
    type: actionTypes.FETCH_EQUIPMENTS_FULFILLED,
    payload: data,
})

export const fetchVehicleEquipmentsRejected = message => ({
    type: actionTypes.FETCH_EQUIPMENTS_REJECTED,
    payload: message,
})

export const fetchVehicleEquipmentsCancelled = () => ({
    type: actionTypes.FETCH_EQUIPMENTS_CANCELLED,
})

// Export vehicle equipments

export const exportVehicleEquipments = id => ({
    type: actionTypes.EXPORT_EQUIPMENTS,
    payload: id,
})

export const exportVehicleEquipmentsFulfilled = data => ({
    type: actionTypes.EXPORT_EQUIPMENTS_FULFILLED,
    payload: data,
})

export const exportVehicleEquipmentsRejected = message => ({
    type: actionTypes.EXPORT_EQUIPMENTS_REJECTED,
    payload: message,
})

export const exportVehicleEquipmentsCancelled = () => ({
    type: actionTypes.EXPORT_EQUIPMENTS_CANCELLED,
})

// Fetch vehicle events

export const fetchVehicleEvents = (id, page) => ({
    type: actionTypes.FETCH_EVENTS,
    payload: {
        id,
        page,
    },
})

export const fetchVehicleEventsFulfilled = data => ({
    type: actionTypes.FETCH_EVENTS_FULFILLED,
    payload: data,
})

export const fetchVehicleEventsRejected = message => ({
    type: actionTypes.FETCH_EVENTS_REJECTED,
    payload: message,
})

export const fetchVehicleEventsCancelled = () => ({
    type: actionTypes.FETCH_EVENTS_CANCELLED,
})

// Export vehicle events

export const exportVehicleEvents = (id, page) => ({
    type: actionTypes.EXPORT_EVENTS,
    payload: {
        id,
        page,
    },
})

export const exportVehicleEventsFulfilled = data => ({
    type: actionTypes.EXPORT_EVENTS_FULFILLED,
    payload: data,
})

export const exportVehicleEventsRejected = message => ({
    type: actionTypes.EXPORT_EVENTS_REJECTED,
    payload: message,
})

export const exportVehicleEventsCancelled = () => ({
    type: actionTypes.EXPORT_EVENTS_CANCELLED,
})

// Fetch vehicle fuelings

export const fetchVehicleFuelings = (id, page) => ({
    type: actionTypes.FETCH_FUELINGS,
    payload: {
        id,
        page,
    },
})

export const fetchVehicleFuelingsFulfilled = data => ({
    type: actionTypes.FETCH_FUELINGS_FULFILLED,
    payload: data,
})

export const fetchVehicleFuelingsRejected = message => ({
    type: actionTypes.FETCH_FUELINGS_REJECTED,
    payload: message,
})

export const fetchVehicleFuelingsCancelled = () => ({
    type: actionTypes.FETCH_FUELINGS_CANCELLED,
})

// Export vehicle fuelings

export const exportVehicleFuelings = (id, page) => ({
    type: actionTypes.EXPORT_FUELINGS,
    payload: {
        id,
        page,
    },
})

export const exportVehicleFuelingsFulfilled = data => ({
    type: actionTypes.EXPORT_FUELINGS_FULFILLED,
    payload: data,
})

export const exportVehicleFuelingsRejected = message => ({
    type: actionTypes.EXPORT_FUELINGS_REJECTED,
    payload: message,
})

export const exportVehicleFuelingsCancelled = () => ({
    type: actionTypes.EXPORT_FUELINGS_CANCELLED,
})
