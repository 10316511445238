import { Record } from 'immutable'

export class TrailerEquipment extends Record({
    id: undefined,
    trailer_id: undefined,
    trailer: undefined,
    equipment_id: undefined,
    equipment: undefined,
    note: undefined,
    date_validity_from: undefined,
    date_validity_to: undefined,
}) {}
