import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportDriverTimesheet = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportDriverTimesheetFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportDriverTimesheetRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportDriverTimesheetCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Driver note

export const updateDriverNote = values => ({
    type: actionTypes.DRIVER_NOTE_UPDATE,
    payload: values,
})

export const updateDriverNoteFulfilled = () => ({
    type: actionTypes.DRIVER_NOTE_UPDATE_FULFILLED,
})

export const updateDriverNoteRejected = message => ({
    type: actionTypes.DRIVER_NOTE_UPDATE_REJECTED,
    payload: message,
})

export const updateDriverNoteCancelled = () => ({
    type: actionTypes.DRIVER_NOTE_UPDATE_CANCELLED,
})

// Clear

export const clearReportDriverTimesheet = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportDriverTimesheet = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportDriverTimesheetDetails = (driverId, dateFrom, dateTo) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        driverId,
        dateFrom,
        dateTo,
    },
})

export const fetchReportDriverTimesheetDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportDriverTimesheetDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportDriverTimesheetDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear details

export const clearReportDriverTimesheetDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export details

export const exportReportDriverTimesheetDetails = (driverId, dateFrom, dateTo, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        driverId,
        dateFrom,
        dateTo,
        filters,
    }
})