import { createSelector } from 'reselect'

export const getIsOpen = state => state.vehicleMessages.get('isOpen')

export const getSearchString = state => state.vehicleMessages.get('searchString')
export const getSelectedVehicle = state => state.vehicleMessages.get('selectedVehicle')

export const getMessageVehicles = state => state.vehicleMessages.getIn(['messageVehicles', 'data'])
export const getMessageVehiclesFetching = state => state.vehicleMessages.getIn(['messageVehicles', 'fetching'])
export const getMessageVehiclesError = state => state.vehicleMessages.getIn(['messageVehicles', 'error'])

export const createGetFilteredVehiclesSelector = () =>
    createSelector(
        [getMessageVehicles, getSearchString],
        (vehicles, searchString) =>
            vehicles &&
            vehicles.filter(
                vehicle =>
                    vehicle.registration.toLowerCase().includes(searchString.toLowerCase()) ||
                    (vehicle.driver && vehicle.driver.name.toLowerCase().includes(searchString.toLowerCase()))
            )
    )

export const getFilteredVehicles = state => {
    const vehicles = getMessageVehicles(state)
    return (
        vehicles &&
        vehicles.filter(
            vehicle =>
                vehicle.registration.toLowerCase().includes(getSearchString(state).toLowerCase()) ||
                (vehicle.driver && vehicle.driver.name.toLowerCase().includes(getSearchString(state).toLowerCase()))
        )
    )
}

export const createGetUnreadMessagesSelector = () =>
    createSelector(
        [getMessageVehicles],
        vehicles => vehicles && vehicles.filter(vehicle => vehicle.is_subscribed && vehicle.message && !vehicle.message.read_by_user_at)
    )

export const getMessages = state => state.vehicleMessages.getIn(['messages', 'data'])
export const getMessagesFetching = state => state.vehicleMessages.getIn(['messages', 'fetching'])
export const getMessagesError = state => state.vehicleMessages.getIn(['messages', 'error'])

export const getSendMessageInProgress = state => state.vehicleMessages.getIn(['sendMessage', 'inProgress'])
export const getSendMessageError = state => state.vehicleMessages.getIn(['sendMessage', 'error'])
