import React, { Component } from 'react'
import { TableModelSortDirection } from '../model'

class TableSortButtons extends Component {
    onClick = direction => {
        this.props.onChange(this.props.identifier, direction)
    }

    render() {
        const currentSorting = this.props.sorting

        let direction = null

        if (currentSorting !== undefined) {
            if (currentSorting.getIn(['column']) === this.props.identifier) {
                direction = currentSorting.getIn(['direction'])
            }
        }

        const upClassName = `table-sort fa fa-caret-up ${direction === TableModelSortDirection.ascending ? 'active' : ''}`
        const downClassName = `table-sort fa fa-caret-down ${direction === TableModelSortDirection.descending ? 'active' : ''}`

        return (
            <span>
                <button className={upClassName} onClick={() => this.onClick(TableModelSortDirection.ascending)} />
                <button className={downClassName} onClick={() => this.onClick(TableModelSortDirection.descending)} />
            </span>
        )
    }
}

export default TableSortButtons
