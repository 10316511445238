import { Observable } from 'rxjs'
import { request, parseAPIError } from '../../common/api'
import * as actionCreators from './actionCreators'
import * as actionTypes from './actionTypes'
import * as selectors from './selectors'

const CARRIER_SCORING_BASE_PATH = 'carrier-scoring'

// Fetch

const fetchCarrierScoringEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH)
        .filter(() => !selectors.isCarrierScoringFetching(store.getState()))
        .switchMap(() =>
            Observable.concat(
                Observable.of(actionCreators.fetchCarrierScoringStarted()),
                request({
                    method: 'GET',
                    path: CARRIER_SCORING_BASE_PATH,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.fetchCarrierScoringFulfilled(
                                ajaxResponse.response.data,
                                ajaxResponse.response.rules,
                                ajaxResponse.response.transportScoringRules
                            )
                        )
                    )
                    .catch(error => Observable.of(actionCreators.fetchCarrierScoringRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED))
            )
        )
}

// Save question

const saveCarrierScoringQuestionEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.SAVE_QUESTION)
        .filter(() => !selectors.isCarrierScoringQuestionSaving(store.getState()))
        .switchMap(action => {
            const values = { ...action.payload }
            const method = values.id ? 'PUT' : 'POST'
            const path = values.id ? `${CARRIER_SCORING_BASE_PATH}/${values.id}` : CARRIER_SCORING_BASE_PATH

            return Observable.concat(
                Observable.of(actionCreators.saveCarrierScoringQuestionStarted()),
                request({
                    method,
                    path,
                    body: values,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.saveCarrierScoringQuestionFulfilled(
                                ajaxResponse.response.data,
                                ajaxResponse.response.rules,
                                ajaxResponse.response.transportScoringRules
                            )
                        )
                    )
                    .catch(error => Observable.of(actionCreators.saveCarrierScoringQuestionRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_QUESTION_CANCELLED))
            )
        })
}

// Delete question

const deleteCarrierScoringQuestionEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.DELETE_QUESTION)
        .filter(() => !selectors.isCarrierScoringQuestionDeleting(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.deleteCarrierScoringQuestionStarted()),
                request({
                    method: 'DELETE',
                    path: `${CARRIER_SCORING_BASE_PATH}/${action.payload}`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.deleteCarrierScoringQuestionFulfilled(
                                ajaxResponse.response.data,
                                ajaxResponse.response.rules,
                                ajaxResponse.response.transportScoringRules
                            )
                        )
                    )
                    .catch(error => Observable.of(actionCreators.deleteCarrierScoringQuestionRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_QUESTION_CANCELLED))
            )
        )
}

// Move question UP

const moveCarrierScoringQuestionUpEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.MOVE_QUESTION_UP)
        .filter(() => !selectors.isCarrierScoringQuestionMovingUp(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.moveCarrierScoringQuestionUpStarted()),
                request({
                    method: 'PUT',
                    path: `${CARRIER_SCORING_BASE_PATH}/${action.payload}/move-up`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.moveCarrierScoringQuestionUpFulfilled(
                                ajaxResponse.response.data,
                                ajaxResponse.response.rules,
                                ajaxResponse.response.transportScoringRules
                            )
                        )
                    )
                    .catch(error => Observable.of(actionCreators.moveCarrierScoringQuestionUpRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.MOVE_QUESTION_UP_CANCELLED))
            )
        )
}

// Move question DOWN

const moveCarrierScoringQuestionDownEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.MOVE_QUESTION_DOWN)
        .filter(() => !selectors.isCarrierScoringQuestionMovingDown(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.moveCarrierScoringQuestionDownStarted()),
                request({
                    method: 'PUT',
                    path: `${CARRIER_SCORING_BASE_PATH}/${action.payload}/move-down`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.moveCarrierScoringQuestionDownFulfilled(
                                ajaxResponse.response.data,
                                ajaxResponse.response.rules,
                                ajaxResponse.response.transportScoringRules
                            )
                        )
                    )
                    .catch(error => Observable.of(actionCreators.moveCarrierScoringQuestionDownRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.MOVE_QUESTION_DOWN_CANCELLED))
            )
        )
}

// Save answer

const saveCarrierScoringAnswerEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.SAVE_ANSWER)
        .filter(() => !selectors.isCarrierScoringAnswerSaving(store.getState()))
        .switchMap(action => {
            const values = { ...action.payload }
            const method = values.id ? 'PUT' : 'POST'
            const path = values.id ? `${CARRIER_SCORING_BASE_PATH}/${values.id}/answer` : `${CARRIER_SCORING_BASE_PATH}/${values.question_id}/answer`

            return Observable.concat(
                Observable.of(actionCreators.saveCarrierScoringAnswerStarted()),
                request({
                    method,
                    path,
                    body: values,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.saveCarrierScoringAnswerFulfilled(
                                ajaxResponse.response.data,
                                ajaxResponse.response.rules,
                                ajaxResponse.response.transportScoringRules
                            )
                        )
                    )
                    .catch(error => Observable.of(actionCreators.saveCarrierScoringAnswerRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_ANSWER_CANCELLED))
            )
        })
}

// Delete answer

const deleteCarrierScoringAnswerEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.DELETE_ANSWER)
        .filter(() => !selectors.isCarrierScoringAnswerDeleting(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.deleteCarrierScoringAnswerStarted()),
                request({
                    method: 'DELETE',
                    path: `${CARRIER_SCORING_BASE_PATH}/${action.payload}/answer`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.deleteCarrierScoringAnswerFulfilled(
                                ajaxResponse.response.data,
                                ajaxResponse.response.rules,
                                ajaxResponse.response.transportScoringRules
                            )
                        )
                    )
                    .catch(error => Observable.of(actionCreators.deleteCarrierScoringAnswerRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_ANSWER_CANCELLED))
            )
        )
}

// Move answer UP

const moveCarrierScoringAnswerUpEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.MOVE_ANSWER_UP)
        .filter(() => !selectors.isCarrierScoringAnswerMovingUp(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.moveCarrierScoringAnswerUpStarted()),
                request({
                    method: 'PUT',
                    path: `${CARRIER_SCORING_BASE_PATH}/${action.payload}/move-up/answer`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.moveCarrierScoringAnswerUpFulfilled(
                                ajaxResponse.response.data,
                                ajaxResponse.response.rules,
                                ajaxResponse.response.transportScoringRules
                            )
                        )
                    )
                    .catch(error => Observable.of(actionCreators.moveCarrierScoringAnswerUpRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.MOVE_ANSWER_UP_CANCELLED))
            )
        )
}

// Move answer DOWN

const moveCarrierScoringAnswerDownEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.MOVE_ANSWER_DOWN)
        .filter(() => !selectors.isCarrierScoringAnswerMovingDown(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.moveCarrierScoringAnswerDownStarted()),
                request({
                    method: 'PUT',
                    path: `${CARRIER_SCORING_BASE_PATH}/${action.payload}/move-down/answer`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.moveCarrierScoringAnswerDownFulfilled(
                                ajaxResponse.response.data,
                                ajaxResponse.response.rules,
                                ajaxResponse.response.transportScoringRules
                            )
                        )
                    )
                    .catch(error => Observable.of(actionCreators.moveCarrierScoringAnswerDownRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.MOVE_ANSWER_DOWN_CANCELLED))
            )
        )
}

// Save rule

const saveCarrierScoringRuleEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.SAVE_RULE)
        .filter(() => !selectors.isCarrierScoringRuleSaving(store.getState()))
        .switchMap(action => {
            const values = { ...action.payload }
            const method = values.id ? 'PUT' : 'POST'
            const path = values.id ? `${CARRIER_SCORING_BASE_PATH}/${values.id}/rule` : `${CARRIER_SCORING_BASE_PATH}/rule`

            return Observable.concat(
                Observable.of(actionCreators.saveCarrierScoringRuleStarted()),
                request({
                    method,
                    path,
                    body: values,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.saveCarrierScoringRuleFulfilled(
                                ajaxResponse.response.data,
                                ajaxResponse.response.rules,
                                ajaxResponse.response.transportScoringRules
                            )
                        )
                    )
                    .catch(error => Observable.of(actionCreators.saveCarrierScoringRuleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_RULE_CANCELLED))
            )
        })
}

// Delete rule

const deleteCarrierScoringRuleEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.DELETE_RULE)
        .filter(() => !selectors.isCarrierScoringRuleDeleting(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.deleteCarrierScoringRuleStarted()),
                request({
                    method: 'DELETE',
                    path: `${CARRIER_SCORING_BASE_PATH}/${action.payload}/rule`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.deleteCarrierScoringRuleFulfilled(
                                ajaxResponse.response.data,
                                ajaxResponse.response.rules,
                                ajaxResponse.response.transportScoringRules
                            )
                        )
                    )
                    .catch(error => Observable.of(actionCreators.deleteCarrierScoringRuleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_RULE_CANCELLED))
            )
        )
}

export default [
    fetchCarrierScoringEpic,
    saveCarrierScoringQuestionEpic,
    deleteCarrierScoringQuestionEpic,
    moveCarrierScoringQuestionUpEpic,
    moveCarrierScoringQuestionDownEpic,
    saveCarrierScoringAnswerEpic,
    deleteCarrierScoringAnswerEpic,
    moveCarrierScoringAnswerUpEpic,
    moveCarrierScoringAnswerDownEpic,
    saveCarrierScoringRuleEpic,
    deleteCarrierScoringRuleEpic,
]
