import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import { convertCSDateToMoment } from '../../../helpers'

class DayPicker extends Component {
    onChange = date => {
        date = convertCSDateToMoment(date)

        if (date === null) {
            date = this.props.momentFrom
        }

        this.props.onChange(date, date)
    }

    render() {
        const momentFrom = this.props.momentFrom
        const dateFrom = momentFrom.format('DD.MM.YYYY')

        const minDate = moment(`01-01-${this.props.minYear}`, 'MM-DD-YYYY')
        const maxDate = moment(`12-31-${this.props.maxYear}`, 'MM-DD-YYYY')

        return (
            <div className="pull-left w-120">
                <DatePicker
                    dateFormat="DD.MM.YYYY"
                    autoComplete="off"
                    selected={momentFrom}
                    value={dateFrom}
                    onChange={value => {
                        this.onChange(value)
                    }}
                    onChangeRaw={e => {
                        this.onChange(e.target.value)
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </div>
        )
    }
}

export default DayPicker
