// Login

export const LOGIN = 'auth.LOGIN'
export const LOGIN_STARTED = 'auth.LOGIN_STARTED'
export const LOGIN_FULFILLED = 'auth.LOGIN_FULFILLED'
export const LOGIN_REJECTED = 'auth.LOGIN_REJECTED'
export const LOGIN_CANCELLED = 'auth.LOGIN_CANCELLED'

// Logout

export const LOGOUT = 'auth.LOGOUT'
export const LOGOUT_STARTED = 'auth.LOGOUT_STARTED'
export const LOGOUT_FULFILLED = 'auth.LOGOUT_FULFILLED'
export const LOGOUT_REJECTED = 'auth.LOGOUT_REJECTED'
export const LOGOUT_CANCELLED = 'auth.LOGOUT_CANCELLED'

// Permissions

export const PERMISSIONS_FETCH = 'auth.permissions.FETCH'
export const PERMISSIONS_FETCH_STARTED = 'auth.permissions.FETCH_STARTED'
export const PERMISSIONS_FETCH_FULFILLED = 'auth.permissions.FETCH_FULFILLED'
export const PERMISSIONS_FETCH_REJECTED = 'auth.permissions.FETCH_REJECTED'
export const PERMISSIONS_FETCH_CANCELLED = 'auth.permissions.FETCH_CANCELLED'

// Settings

export const SETTINGS_FETCH_FULFILLED = 'auth.permissions.FETCH_FULFILLED'

// Forgotten password

export const FORGOTTEN_PASSWORD = 'auth.FORGOTTEN_PASSWORD'
export const FORGOTTEN_PASSWORD_STARTED = 'auth.FORGOTTEN_PASSWORD_STARTED'
export const FORGOTTEN_PASSWORD_FULFILLED = 'auth.FORGOTTEN_PASSWORD_FULFILLED'
export const FORGOTTEN_PASSWORD_REJECTED = 'auth.FORGOTTEN_PASSWORD_REJECTED'
export const FORGOTTEN_PASSWORD_CANCELLED = 'auth.FORGOTTEN_PASSWORD_CANCELLED'

// Forgotten password

export const FIND_TOKEN = 'auth.FIND_TOKEN'
export const FIND_TOKEN_STARTED = 'auth.FIND_TOKEN_STARTED'
export const FIND_TOKEN_FULFILLED = 'auth.FIND_TOKEN_FULFILLED'
export const FIND_TOKEN_REJECTED = 'auth.FIND_TOKEN_REJECTED'
export const FIND_TOKEN_CANCELLED = 'auth.FIND_TOKEN_CANCELLED'

// Reset password

export const RESET_PASSWORD = 'auth.RESET_PASSWORD'
export const RESET_PASSWORD_STARTED = 'auth.RESET_PASSWORD_STARTED'
export const RESET_PASSWORD_FULFILLED = 'auth.RESET_PASSWORD_FULFILLED'
export const RESET_PASSWORD_REJECTED = 'auth.RESET_PASSWORD_REJECTED'
export const RESET_PASSWORD_CANCELLED = 'auth.RESET_PASSWORD_CANCELLED'
