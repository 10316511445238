import { Map } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    list: Map({
        settings: null,
        data: null,
        fetching: false,
        error: null,
    }),
})

function reportCustomerWeeklyPlans(state = initialState, action) {
    switch (action.type) {
        // Fetch
        case actionTypes.FETCH:
            return state.setIn(['list', 'settings'], action.payload).setIn(['list', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['list', 'fetching'], false).setIn(['list', 'data'], action.payload)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['list', 'fetching'], false).setIn(['list', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['list', 'fetching'], false)

        // Clear
        case actionTypes.CLEAR:
            return state.setIn(['list', 'data'], null)

        default:
            return state
    }
}

export default reportCustomerWeeklyPlans
