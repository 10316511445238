import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { Modal } from 'react-bootstrap'
import Notifications from 'react-notification-system-redux'
import { TRANSPORT_POINT_TYPES_DEFINITION, TRANSPORT_STATE_PREORDER } from '../../transports/constants'
import { url, convertCSDatetimeToMoment } from '../../../common/helpers'

import { Form, InputField, SelectField, CustomerPicker } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import { getCurrentUserId, getSettings } from '../../auth/selectors'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { saveTransport } from '../../transports/actionCreators'
import { createGetCurrentTransportStatusSelector } from '../../transports/selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS as PERMS_TRANSPORTS } from '../../transports/permissions'
import {CustomDatePicker} from "../../../common/custom_date_picker";

class TracingDetail extends Component {
    validationRules = {}
    transportPointTypes = []

    defaultValues = {
        extra_costs: 0,
    }

    state = {
        showTimePlan: false,
        showCreateTransport: false,
        values: this.defaultValues,
        datetimes: {},
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleCloseModal = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        if (!this.props.transportStatus.get('saving')) {
            this.props.handleHideDetail()
        }
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleDatetimeChange = (key, value) => {
        const newState = {
            datetimes: {
                ...this.state.datetimes,
                [key]: value,
            },
        }

        this.setState(newState)
    }

    handleShowTimePlan = () => {
        this.setState({ showTimePlan: !this.state.showTimePlan })
    }

    handleShowCreateTransport = () => {
        this.setState({ showCreateTransport: !this.state.showCreateTransport })
    }

    handleSubmit = () => {
        const values = {
            is_spedition: false,
            disponent_id: this.props.currentUserId,
            transport_state_id: TRANSPORT_STATE_PREORDER,
            transport_type_id: this.props.values.transport_type_id,
            vehicle_id: this.props.values.vehicle_id,
            is_predeparture_check: this.props.settings && this.props.settings.get('is_predeparture_check') === 'true',
            is_all_required_files_uploaded: false,
            preloading_datetime: '',
            preloading_address: '',
            preloading_note: '',

            customers: [
                {
                    id: null,
                    customer_id: this.props.values.customer_id,
                    price: this.props.values.price,
                    currency_id: this.props.values.currency_id,
                    transport_description: null,
                    invoicing_note: null,
                    surcharges: [{ surcharge_type_id: '0', price: '128' }],
                    invoicing_address_id: this.state.values.customer_invoicing_address_id,
                    delivery_address: this.state.values.customer_delivery_address_id,
                },
            ],

            points: [],
        }

        this.props.tracingPoints.forEach((tracingPoint, id) => {
            const datetime_planned_to = convertCSDatetimeToMoment(this.state.datetimes[`tracing_point_datetime-${id}`]).add(1, 'hours')
            values.points.push({
                type_id: this.state.values[`tracing_point_type_id-${id}`],
                datetime_planned_from: this.state.datetimes[`tracing_point_datetime-${id}`],
                datetime_planned_to: formatters.datetimeFormatter(datetime_planned_to),
                datetime_specified_from: null,
                datetime_specified_to: null,
                datetime_booking_from: null,
                datetime_booking_to: null,
                name: '',
                street: tracingPoint.street,
                city: tracingPoint.city,
                zipcode: tracingPoint.postalCode,
                country_code: tracingPoint.country,
                gps_lat: tracingPoint.lat,
                gps_lng: tracingPoint.lng,
                is_booking_required: 0,
                booking_url: '',
                booking_note: '',
                data: [{ type_id: '10', value: this.state.values[`tracing_point_code-${id}`] }],
            })
        })
        this.props.actions.saveTransport(values)
    }

    componentDidMount = () => {
        Object.keys(TRANSPORT_POINT_TYPES_DEFINITION).forEach(typeId => {
            const type = TRANSPORT_POINT_TYPES_DEFINITION[typeId]

            if (!type.notAvailable) {
                this.transportPointTypes.push({ id: typeId, name: type.name })
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        // Customer
        if (this.state.values.customer_id !== nextProps.values.customer_id) {
            const values = {
                ...this.state.values,
                customer_id: nextProps.values.customer_id,
            }
            this.setValues(values)
        }
        // Transport
        if (this.state.values.transport_type_id !== nextProps.values.transport_type_id) {
            const values = {
                ...this.state.values,
                transport_type_id: nextProps.values.transport_type_id,
            }
            this.setValues(values)
        }
        // Datetimes
        if (JSON.stringify(this.props.datetimes) !== JSON.stringify(nextProps.datetimes)) {
            const lastTracingPointId = this.props.tracingPoints.length - 1
            const startId = 'tracing_point_datetime-0'
            const endId = `tracing_point_datetime-${lastTracingPointId}`

            const datetimes = {}
            const propsDatetimes = nextProps.datetimes

            datetimes[startId] = propsDatetimes.loading
            datetimes[endId] = propsDatetimes.unloading

            this.setState({ datetimes })
        }
        // Save event
        if (this.props.transportStatus.get('saving') && !nextProps.transportStatus.get('saving')) {
            if (nextProps.transportStatus.get('error') === null) {
                const id = nextProps.transportStatus.get('id')
                const link = (
                    <p>
                        {this.props.intl.formatMessage({ id: 'alerts.messages.saveSuccess' })}{' '}
                        <Link to={url(this.props.match, `transports/${id}`)}>{this.props.intl.formatMessage({ id: 'fields.show' })}</Link>
                    </p>
                )
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: link,
                        position: 'tc',
                    },
                    'success'
                )
                this.props.handleHideDetail()
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.transportStatus.get('error'),
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    render() {
        const customerInfo = this.props.customerInfo
        const tracingRoute = this.props.tracingRoute
        const tracingPoints = this.props.tracingPoints
        const customers = this.props.customers
        const values = this.props.values
        const costTotal = this.props.costTotal
        const fixedCostTotal = this.props.fixedCostTotal
        const thisValues = this.state.values
        const isApproachInTime = this.props.isApproachInTime
        const isShippingInTime = this.props.isShippingInTime

        const tracingPointDateimes = this.state.datetimes
        const tracingPointDateimeMoments = {}
        Object.keys(tracingPointDateimes).forEach(key => {
            tracingPointDateimeMoments[key] = convertCSDatetimeToMoment(tracingPointDateimes[key])
        })

        const timeDriving = tracingRoute ? tracingRoute.time_driving : 0
        const timeShiping = tracingRoute ? timeDriving + tracingRoute.time_rest + tracingRoute.time_break : 0

        const extra_costs = parseFloat(thisValues.extra_costs) ? parseFloat(thisValues.extra_costs) : 0
        const costs_total = costTotal + extra_costs

        const profit = values.price ? ((values.price - costs_total) / values.price) * 100 : 0
        const isProfitLow = profit < tracingRoute.min_profit_percent
        const isCustomerSelected = values.customer_id !== null

        const transportPointTypes = []
        this.transportPointTypes.forEach(transportType =>
            transportPointTypes.push({ ...transportType, name: this.props.intl.formatMessage({ id: transportType.name }) })
        )

        const invoicingAddressOptions = customerInfo.invoicing_addresses
            ? customerInfo.invoicing_addresses.map(address => ({
                  id: address.id,
                  name: address.address,
              }))
            : []

        const deliveryAddressOptions = customerInfo.delivery_addresses
            ? customerInfo.delivery_addresses.map(address => ({
                  id: address.id,
                  name: address.address,
              }))
            : []

        /* eslint-disable jsx-a11y/anchor-is-valid */
        return (
            <Modal show={Boolean(this.props.showDetail)} onHide={e => this.handleCloseModal(e)} className="modal-size-lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="modules.tracing.detailHeading" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoadingOverlay active={this.props.transportStatus.get('saving')}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                        >
                            <div className="row text-center b-t p-v-xs m-t-xs">
                                <div className="col-md-2">
                                    <FormattedMessage id="modules.tracing.totalDistance" />
                                    <h1>{formatters.distanceFormatter(tracingRoute.distance, '0,0.00')}</h1>
                                </div>
                                <div className="col-md-2">
                                    <FormattedMessage id="modules.tracing.totalTime" />
                                    <h1 className={`${isApproachInTime && isShippingInTime ? '' : 'text-danger'}`}>
                                        {formatters.hoursFromMinutesFormatter(this.props.timeTotal)}
                                    </h1>
                                </div>
                                <div className="col-md-2">
                                    <FormattedMessage id="modules.tracing.shippingTime" />
                                    <h1>{formatters.hoursFromMinutesFormatter(timeShiping)}</h1>
                                </div>
                                <div className="col-md-2">
                                    <FormattedMessage id="modules.tracing.contractPrice" />
                                    <h1>{formatters.priceFormatter(values.price, '0,0', values.currency_name)}</h1>
                                </div>
                                <div className="col-md-2">
                                    <FormattedMessage id="modules.tracing.totalCost" />
                                    <h1>{formatters.priceFormatter(costs_total, '0,0', values.currency_name)}</h1>
                                </div>
                                <div className="col-md-2">
                                    <FormattedMessage id="modules.tracing.economicResults" />
                                    <h1 className={isProfitLow ? 'text-danger' : ''}>
                                        {formatters.priceFormatter(values.price - costs_total, '0,0', values.currency_name)}
                                    </h1>
                                    <small className={isProfitLow ? 'text-danger' : ''}>{formatters.percentFormatter(profit)}</small>
                                </div>
                            </div>

                            <div className={'text-center'}>
                                <a href="#" onClick={() => this.handleShowTimePlan()}>
                                    {this.props.intl.formatMessage({ id: 'modules.tracing.timePlane' })} <i className="fas fa-long-arrow-alt-down" />
                                </a>
                            </div>

                            {this.state.showTimePlan && (
                                <div className="table-container">
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td className="w-max wm-200 text-left">{this.props.intl.formatMessage({ id: 'fields.drive' })}</td>
                                                <td className="w-100 text-right">
                                                    {formatters.hoursFromMinutesFormatter(tracingRoute.time_driving)}
                                                </td>
                                            </tr>
                                            {tracingRoute.breaks_count > 0 && (
                                                <tr>
                                                    <td className="w-max wm-200 text-left">
                                                        {this.props.intl.formatMessage({ id: 'fields.breaks' })} {tracingRoute.breaks_count}&times;
                                                    </td>
                                                    <td className="w-100 text-right">
                                                        {formatters.hoursFromMinutesFormatter(tracingRoute.time_break)}
                                                    </td>
                                                </tr>
                                            )}
                                            {tracingRoute.rests_count > 0 && (
                                                <tr>
                                                    <td className="w-max wm-200 text-left">
                                                        {this.props.intl.formatMessage({ id: 'fields.rests' })} {tracingRoute.rests_count}&times;
                                                    </td>
                                                    <td className="w-100 text-right">
                                                        {formatters.hoursFromMinutesFormatter(tracingRoute.time_rest)}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            <div className="table-container">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th className="w-160 text-left">
                                                <FormattedMessage id="fields.country" />
                                            </th>
                                            <th className="w-max wm-200">
                                                <FormattedMessage id="fields.distance" />
                                            </th>
                                            <th className="w-100 text-right">
                                                <FormattedMessage id="fields.paidRoutes" />
                                            </th>
                                            <th className="w-100 text-right">
                                                <FormattedMessage id="fields.time" />
                                            </th>
                                            <th className="w-120 text-right">
                                                <FormattedMessage id="fields.fuel" />
                                            </th>
                                            <th className="w-100 text-right">
                                                <FormattedMessage id="fields.adblue" />
                                            </th>
                                            <th className="w-140 text-right">
                                                <FormattedMessage id="fields.toll" />
                                            </th>
                                            <th className="w-140 text-right">
                                                <FormattedMessage id="fields.dailyCost" />
                                            </th>
                                            <th className="w-120 text-right">
                                                <FormattedMessage id="fields.total" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tracingRoute &&
                                            tracingRoute.countries &&
                                            tracingRoute.countries.map(country => {
                                                const total =
                                                    country.costs_fuel + country.costs_adblue + country.costs_toll_per_km + country.costs_toll_per_day
                                                return (
                                                    <tr key={`transport-${country.country_code}`}>
                                                        <td className="w-160 text-left">{country.country_code}</td>
                                                        <td className="w-max wm-200 text-left">{formatters.distanceFormatter(country.distance)}</td>
                                                        <td className="w-100 text-right">
                                                            {formatters.distanceFormatter(country.distance_toll_roads)}
                                                        </td>
                                                        <td className="w-100 text-right">
                                                            {formatters.hoursFromMinutesFormatter(country.time_driving)}
                                                        </td>
                                                        <td className="w-120 text-right">
                                                            {formatters.priceFormatter(
                                                                country.costs_fuel / values.currency_rate,
                                                                '0,0',
                                                                values.currency_name
                                                            )}
                                                        </td>
                                                        <td className="w-100 text-right">
                                                            {formatters.priceFormatter(
                                                                country.costs_adblue / values.currency_rate,
                                                                '0,0',
                                                                values.currency_name
                                                            )}
                                                        </td>
                                                        <td className="w-140 text-right">
                                                            {formatters.priceFormatter(
                                                                country.costs_toll_per_km / values.currency_rate,
                                                                '0,0',
                                                                values.currency_name
                                                            )}
                                                        </td>
                                                        <td className="w-140 text-right">
                                                            {formatters.priceFormatter(
                                                                country.costs_toll_per_day / values.currency_rate,
                                                                '0,0',
                                                                values.currency_name
                                                            )}
                                                        </td>
                                                        <td className="w-120 text-right">
                                                            {formatters.priceFormatter(total / values.currency_rate, '0,0', values.currency_name)}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="table-container">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th className="w-max wm-200 text-left">
                                                <FormattedMessage id="modules.tracing.additionalCosts" />
                                            </th>
                                            <th className="w-120 text-right">
                                                <FormattedMessage id="fields.total" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="w-max wm-200 text-left">
                                                {this.props.intl.formatMessage({ id: 'modules.tracing.fixedRate' })}{' '}
                                                {formatters.priceFormatter(
                                                    tracingRoute.costs_fixed / values.currency_rate,
                                                    '0,0',
                                                    `${values.currency_name}/h`
                                                )}
                                            </td>
                                            <td className="w-100 text-right">
                                                {formatters.priceFormatter(fixedCostTotal, '0,0', values.currency_name)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-max wm-200 text-left">
                                                {this.props.intl.formatMessage({ id: 'modules.tracing.extraCosts' })}
                                            </td>
                                            <td className="w-100 text-right">
                                                <InputField id="extra_costs" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <PermissionsCheck has={[PERMS_TRANSPORTS.CREATE]}>
                                <div className={'text-center'}>
                                    <a href="#" onClick={() => this.handleShowCreateTransport()}>
                                        {this.props.intl.formatMessage({ id: 'buttons.createTransport' })}{' '}
                                        <i className="fas fa-long-arrow-alt-down" />
                                    </a>
                                </div>
                            </PermissionsCheck>

                            {this.state.showCreateTransport && (
                                <div>
                                    {isProfitLow && (
                                        <div className="alert alert-danger m-b-sm">
                                            {this.props.intl.formatMessage(
                                                { id: 'modules.tracing.warningProfit' },
                                                { profit: formatters.percentFormatter(tracingRoute.min_profit_percent) }
                                            )}
                                        </div>
                                    )}
                                    {!(isApproachInTime && isShippingInTime) && (
                                        <div className="alert alert-danger m-b-sm">
                                            {this.props.intl.formatMessage({ id: 'modules.tracing.warningTime' })}
                                        </div>
                                    )}
                                    <CustomerPicker
                                        id="customer_id"
                                        label={this.props.intl.formatMessage({ id: 'fields.customer' })}
                                        values={customers}
                                        onChange={value => this.props.onChange('customer_id', value)}
                                    />
                                    {!isCustomerSelected && (
                                        <div className="alert alert-danger m-b-sm">
                                            <FormattedMessage id="modules.tracing.warningCustomer" />
                                        </div>
                                    )}
                                    {this.props.isCustomersBlocked === 1 && (
                                        <div className="alert alert-danger m-b-sm">
                                            <FormattedMessage id="modules.transports.warningBlockedCustomer" />
                                        </div>
                                    )}
                                    <SelectField
                                        id={`customer_invoicing_address_id`}
                                        label={this.props.intl.formatMessage({ id: 'fields.invoicingAddress' })}
                                        values={invoicingAddressOptions}
                                        prompt="-"
                                        nullable
                                    />

                                    <SelectField
                                        id={`customer_delivery_address_id`}
                                        label={this.props.intl.formatMessage({ id: 'fields.deliveryAddress' })}
                                        values={deliveryAddressOptions}
                                        prompt="-"
                                        nullable
                                    />
                                    <SelectField
                                        id="transport_type_id"
                                        label={this.props.intl.formatMessage({ id: 'fields.transportType' })}
                                        values={this.props.transport_types}
                                        prompt="-"
                                        nullable
                                        onChange={(id, option) => this.props.onChange(id, option.value)}
                                    />
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="w-max wm-200 text-left">
                                                    <FormattedMessage id="modules.tracing.waypoints" />
                                                </th>
                                                <th className="w-200 text-right">
                                                    <FormattedMessage id="fields.type" />
                                                </th>
                                                <th className="w-160 text-right">
                                                    <FormattedMessage id="fields.dateTime" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.code" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tracingPoints &&
                                                tracingPoints.map((tracingPoint, id) => {
                                                    return (
                                                        <tr key={`tracing-point-${id}`}>
                                                            <td className="w-max wm-200 text-left">{tracingPoint.address}</td>
                                                            <td className="w-200">
                                                                <SelectField
                                                                    id={`tracing_point_type_id-${id}`}
                                                                    values={transportPointTypes}
                                                                    prompt="-"
                                                                    nullable
                                                                />
                                                            </td>
                                                            <td className="w-160">
                                                                <CustomDatePicker
                                                                    isTimePicker={true}
                                                                    id={`tracing_point_datetime-${id}`}
                                                                    selected={tracingPointDateimeMoments[`tracing_point_datetime-${id}`]}
                                                                    value={tracingPointDateimes[`tracing_point_datetime-${id}`]}
                                                                    onChange={value => {
                                                                        this.handleDatetimeChange(
                                                                            `tracing_point_datetime-${id}`,
                                                                            value ? formatters.datetimeFormatter(value) : ''
                                                                        )
                                                                    }}
                                                                    onChangeRaw={e => {
                                                                        this.handleDatetimeChange(`tracing_point_datetime-${id}`, e.target.value)
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="w-120 text-right">
                                                                <InputField id={`tracing_point_code-${id}`} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <div className="btns-form">
                                <button className="btn btn-default" onClick={e => this.handleCloseModal(e)}>
                                    <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                                </button>
                                {this.state.showCreateTransport && (
                                    <button
                                        type="submit"
                                        disabled={!(isCustomerSelected && isApproachInTime && isShippingInTime)}
                                        className="btn btn-addon pull-right btn-success"
                                    >
                                        <i className="far fa-check" /> <FormattedMessage id="buttons.createTransport" />
                                    </button>
                                )}
                            </div>
                        </Form>
                    </LoadingOverlay>
                </Modal.Body>
            </Modal>
        )
    }
}
function mapStateToProps(state) {
    return {
        currentUserId: getCurrentUserId(state),
        transportStatus: createGetCurrentTransportStatusSelector()(state),
        settings: getSettings(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    saveTransport,
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TracingDetail)
)
