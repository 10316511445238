import * as actionTypes from './actionTypes'

// Fetch

export const fetchInvoicingOrders = filters => ({
    type: actionTypes.FETCH,
    payload: {
        filters,
    },
})

export const fetchInvoicingOrdersFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: {
        data,
    },
})

export const fetchInvoicingOrdersRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchInvoicingOrdersCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearInvoicingOrders = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportInvoicingOrders = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Set invoicing state

export const setInvoicingState = (transportCustomerId, invoicingStateId, filters) => ({
    type: actionTypes.SET_INVOICING_STATE,
    payload: {
        transportCustomerId,
        invoicingStateId,
        filters,
    },
})

export const setInvoicingStateFulfilled = data => ({
    type: actionTypes.SET_INVOICING_STATE_FULFILLED,
    payload: {
        data,
    },
})

export const setInvoicingStateRejected = message => ({
    type: actionTypes.SET_INVOICING_STATE_REJECTED,
    payload: message,
})

export const setInvoicingStateCancelled = () => ({
    type: actionTypes.SET_INVOICING_STATE_CANCELLED,
})

// Set as invoiced

export const setAsInvoiced = (selectedIds, filters, sorting) => ({
    type: actionTypes.SET_AS_INVOICED,
    payload: {
        selectedIds,
        filters,
        sorting
    },
})

export const setAsInvoicedFulfilled = data => ({
    type: actionTypes.SET_AS_INVOICED_FULFILLED,
    payload: {
        data,
    },
})

export const setAsInvoicedRejected = message => ({
    type: actionTypes.SET_AS_INVOICED_REJECTED,
    payload: message,
})

export const setAsInvoicedCancelled = () => ({
    type: actionTypes.SET_AS_INVOICED_CANCELLED,
})

// Fetch data for invoicing

export const fetchDataForInvoicing = transportCustomersIds => ({
    type: actionTypes.FETCH_DATA_FOR_INVOICING,
    payload: {
        transportCustomersIds,
    },
})

export const fetchDataForInvoicingFulfilled = data => ({
    type: actionTypes.FETCH_DATA_FOR_INVOICING_FULFILLED,
    payload: {
        data,
    },
})

export const fetchDataForInvoicingRejected = message => ({
    type: actionTypes.FETCH_DATA_FOR_INVOICING_REJECTED,
    payload: message,
})

export const fetchDataForInvoicingCancelled = () => ({
    type: actionTypes.FETCH_DATA_FOR_INVOICING_CANCELLED,
})

// Create invoice

export const createInvoice = data => ({
    type: actionTypes.CREATE_INVOICE,
    payload: {
        data,
    },
})

export const createInvoiceFulfilled = (data, createdInvoiceId) => ({
    type: actionTypes.CREATE_INVOICE_FULFILLED,
    payload: {
        data,
        createdInvoiceId,
    },
})

export const createInvoiceRejected = message => ({
    type: actionTypes.CREATE_INVOICE_REJECTED,
    payload: message,
})

export const createInvoiceCancelled = () => ({
    type: actionTypes.CREATE_INVOICE_CANCELLED,
})
