import React from 'react'
import { Route, Switch } from 'react-router'
import BlockedIpsList from './BlockedIpsList'
import BlockedIpsEdit from './BlockedIpsEdit'

const BlockedIps = props => (
    <Switch>
        <Route exact path={props.match.url} component={BlockedIpsList} />
        <Route exact path={`${props.match.url}/new`} component={BlockedIpsEdit} />
        <Route exact path={`${props.match.url}/:blockedIpID`} component={BlockedIpsEdit} />
    </Switch>
)

export default BlockedIps
