import React, { Component } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'

import ColoredBox from '../../../common/colored_box/components/ColoredBox'
import { datetimeFormatter } from '../../../common/formatters'

class SearchRow extends Component {
    handleClick = e => {
        e.preventDefault()

        this.props.selectVehicle(this.props.messageVehicle)
        this.props.startMessageAutoFetching()
    }

    handleMarkAsRead = e => {
        e.preventDefault()

        const messageVehicle = this.props.messageVehicle
        const message = messageVehicle.message

        if (!message.read_at) {
            e.stopPropagation()

            this.props.handleMarkAsRead(messageVehicle.id)
        }
    }

    handleSubscribe = e => {
        e.preventDefault()
        e.stopPropagation()

        this.props.handleSubscribe(this.props.messageVehicle.id)
    }

    handleUnsubscribe = e => {
        e.preventDefault()
        e.stopPropagation()

        this.props.handleUnsubscribe(this.props.messageVehicle.id)
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    render() {
        const messageVehicle = this.props.messageVehicle
        const message = messageVehicle.message
        const dispatcher = messageVehicle.dispatcher
        const driver = messageVehicle.driver

        let stateIconClass = 'far fa-clock text-gray-lighter'
        let stateIconText = this.props.intl.formatMessage({ id: 'messages.state.sending' })
        if (message && message.type_id === 1) {
            if (message.read_at) {
                stateIconClass = 'fas fa-check-circle text-success'
                stateIconText = `${this.props.intl.formatMessage({ id: 'messages.state.read' })} ${datetimeFormatter(message.read_at)}`
            } else if (message.received_at) {
                stateIconClass = 'fas fa-check-circle text-gray-lighter'
                stateIconText = `${this.props.intl.formatMessage({ id: 'messages.state.received' })} ${datetimeFormatter(message.received_at)}`
            } else if (message.sent_at) {
                stateIconClass = 'far fa-check-circle text-gray-lighter'
                stateIconText = `${this.props.intl.formatMessage({ id: 'messages.state.sent' })} ${datetimeFormatter(message.sent_at)}`
            }
        }

        const stateIconTooltip = <Tooltip id="stateIconTooltip">{stateIconText}</Tooltip>
        const markAsReadTooltip = <Tooltip id="markAsReadTooltip">{this.props.intl.formatMessage({ id: 'messages.state.markAsRead' })}</Tooltip>
        const subscribedTooltip = <Tooltip id="subscribeTooltip">{this.props.intl.formatMessage({ id: 'messages.subscribed' })}</Tooltip>
        const unsubscribedTooltip = <Tooltip id="unsubscribeTooltip">{this.props.intl.formatMessage({ id: 'messages.unsubscribed' })}</Tooltip>

        const className = message && !message.read_by_user_at && messageVehicle.is_subscribed && 'search-result-unread'

        return (
            <a href="#" onClick={this.handleClick} className={`pull-left wp-100 search-result ${className}`}>
                <span className="pull-left m-r-xs vehicle">
                    <ColoredBox bgRGB={dispatcher && dispatcher.rgb_bg} textRGB={dispatcher && dispatcher.rgb_text}>
                        {messageVehicle.registration}
                    </ColoredBox>
                    <br />
                    <small>{driver && driver.name}</small>
                </span>

                <span className="pull-left message">
                    {message ? (
                        <React.Fragment>
                            <div className="pull-right text-right icons">
                                <div style={{ marginRight: '-1px' }}>
                                    {messageVehicle.is_subscribed ? (
                                        <OverlayTrigger placement="left" overlay={subscribedTooltip}>
                                            <i className="fas fa-eye text-success m-l-sm" onClick={this.handleUnsubscribe} />
                                        </OverlayTrigger>
                                    ) : (
                                        <OverlayTrigger placement="left" overlay={unsubscribedTooltip}>
                                            <i className="far fa-eye-slash text-gray-lighter m-l-sm" onClick={this.handleSubscribe} />
                                        </OverlayTrigger>
                                    )}
                                </div>
                                <div>
                                    {message.type_id === 1 && (
                                        <OverlayTrigger placement="left" overlay={stateIconTooltip}>
                                            <i className={`m-l-sm ${stateIconClass}`} />
                                        </OverlayTrigger>
                                    )}
                                </div>
                                <div>
                                    {!message.read_by_user_at &&
                                        messageVehicle.is_subscribed && (
                                            <OverlayTrigger placement="left" overlay={markAsReadTooltip}>
                                                <i className="far fa-circle text-gray-lighter m-l-sm" onClick={this.handleMarkAsRead} />
                                            </OverlayTrigger>
                                        )}
                                </div>
                            </div>
                            {message.user && <span className="text-gray m-r-xxs">{message.user.name}:</span>}
                            {message.type_id === 2 && (
                                <span className="text-gray m-r-xxs">
                                    {message.driver ? message.driver.name : this.props.intl.formatMessage({ id: 'messages.fromDriver' })}:
                                </span>
                            )}
                            {message.text}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="pull-right text-right icons">
                                <div style={{ marginRight: '-1px' }}>
                                    {messageVehicle.is_subscribed ? (
                                        <OverlayTrigger placement="left" overlay={subscribedTooltip}>
                                            <i className="fas fa-eye text-gray m-l-sm" onClick={this.handleUnsubscribe} />
                                        </OverlayTrigger>
                                    ) : (
                                        <OverlayTrigger placement="left" overlay={unsubscribedTooltip}>
                                            <i className="far fa-eye-slash text-gray-lighter m-l-sm" onClick={this.handleSubscribe} />
                                        </OverlayTrigger>
                                    )}
                                </div>
                            </div>
                            <i className="text-default">
                                <FormattedMessage id="messages.noMessage" />
                            </i>
                        </React.Fragment>
                    )}
                </span>
            </a>
        )
    }
    /* eslint-enable jsx-a11y/no-static-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */
}

export default injectIntl(SearchRow)
