import { createSelector } from 'reselect'

export const getBorderCrossings = state => state.borderCrossings.getIn(['all', 'objects'])

export const createGetBorderCrossingByIDSelector = id =>
    createSelector(
        [getBorderCrossings],
        borderCrossings => borderCrossings.get(String(id))
    )

const getCurrentBorderCrossingStatus = state => state.borderCrossings.get('current')

export const createGetCurrentBorderCrossingStatusSelector = () =>
    createSelector(
        [getCurrentBorderCrossingStatus],
        status => status
    )

export const getBorderCrossingDeleting = state => state.borderCrossings.getIn(['deletion', 'inProgress'])

export const getBorderCrossingDeleteError = state => state.borderCrossings.getIn(['deletion', 'error'])

export const getFindCountries = state => state.borderCrossings.getIn(['findCountries', 'objects'])

const getFindCountriesStatus = state => state.borderCrossings.get('findCountries')

export const createGetFindCountriesStatusSelector = () =>
    createSelector(
        [getFindCountriesStatus],
        status => status
    )
