import { Record } from 'immutable'

export class Driver extends Record({
    id: undefined,
    fb_id: undefined,
    first_name: undefined,
    last_name: undefined,
    phone: undefined,
    locale: undefined,
    dispatcher_id: undefined,
    company_cost_center_id: undefined,
    is_active: undefined,
    times: undefined,
    fixed_allowances: undefined,
    card_number: undefined,
    code: undefined,
    drivers_type_id: undefined,
    deleted_at: undefined,
    country_id: undefined,
    date_boarding: undefined,
}) {
    getFullName = () => {
        const parts = []
        this.last_name && parts.push(this.last_name)
        this.first_name && parts.push(this.first_name)

        return parts.join(' ')
    }

    getShortFullName = () => {
        const parts = []
        this.last_name && parts.push(this.last_name)
        this.first_name && parts.push(`${this.first_name.substring(0, 1)}.`)

        return parts.join(' ')
    }
}
