import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Form, InputField } from '../../../common/form'
import { getAuthInProgress, getFindTokenInProgress, getAuthError } from '../selectors'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { url, validationMessage } from '../../../common/helpers'
import * as actionCreators from '../actionCreators'

class SetPasswordForm extends Component {
    validationRules = {
        password: (value, isEditing) =>
            (!validator.isEmpty(String(value)) || isEditing) &&
            !validator.isLength(String(value), { min: 6 }) &&
            validationMessage('isLength', { min: 6 }),
        password_confirmation: (value, isEditing) =>
            (!validator.isEmpty(String(value)) || isEditing) &&
            !validator.isLength(String(value), { min: 6 }) &&
            validationMessage('isLength', { min: 6 }),
    }

    handleSubmit = values => {
        values.token = this.props.match.params.token
        this.props.actions.resetPassword(values)
    }

    componentWillReceiveProps(nextProps) {
        // Invalid token
        if (this.props.findTokenInProgress && !nextProps.findTokenInProgress && nextProps.loginError === "Token dosn't exists") {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: this.props.intl.formatMessage({
                        id: 'alerts.messages.invalidToken',
                    }),
                    position: 'tc',
                },
                'error'
            )
            this.props.redirect('/auth/login')
        }
        // Invalid form
        else if (this.props.inProgress && !nextProps.inProgress && nextProps.loginError === 'Validation failed') {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: this.props.intl.formatMessage({
                        id: 'alerts.messages.passwordDontMatch',
                    }),
                    position: 'tc',
                },
                'error'
            )
        }
        // Password change success
        else if (this.props.inProgress && !nextProps.inProgress && nextProps.loginError === null) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                    message: this.props.intl.formatMessage({
                        id: 'alerts.messages.passwordChange',
                    }),
                    position: 'tc',
                },
                'success'
            )
            this.props.redirect('/auth/login')
        }
    }

    componentDidMount() {
        this.props.match.params.token && this.props.actions.findToken(this.props.match.params.token)
    }

    render() {
        return (
            <div>
                <p className="text-center m-t-md">
                    <FormattedMessage id="login.setPassword" />
                </p>
                <Form values={{}} validationRules={this.validationRules} onSubmit={this.handleSubmit}>
                    <LoadingOverlay active={this.props.inProgress || this.props.findTokenInProgress} dark>
                        <InputField
                            type="password"
                            id="password"
                            placeholder={this.props.intl.formatMessage({
                                id: 'fields.password',
                            })}
                        />
                        <InputField
                            type="password"
                            id="password_confirmation"
                            placeholder={this.props.intl.formatMessage({
                                id: 'fields.password',
                            })}
                        />
                        <button type="submit" className="btn btn-success btn-block">
                            <FormattedMessage id="buttons.send" />
                        </button>
                    </LoadingOverlay>
                </Form>
                <p className="text-center">
                    <Link to={url(this.props.match, 'auth/login')}>
                        <FormattedMessage id="login.login" />
                    </Link>
                </p>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        inProgress: getAuthInProgress(state),
        findTokenInProgress: getFindTokenInProgress(state),
        loginError: getAuthError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SetPasswordForm)
)
