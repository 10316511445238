import { createSelector } from 'reselect'

export const getVehicleEquipments = state => state.vehicleEquipments.getIn(['all', 'objects'])

export const createGetVehicleEquipmentByIDSelector = id =>
    createSelector([getVehicleEquipments], vehicleEquipments => vehicleEquipments.get(String(id)))

const getCurrentVehicleEquipmentStatus = state => state.vehicleEquipments.get('current')

export const createGetCurrentVehicleEquipmentStatusSelector = () => createSelector([getCurrentVehicleEquipmentStatus], status => status)

export const getVehicleEquipmentsFetching = state => state.vehicleEquipments.getIn(['all', 'fetching'])

export const getVehicleEquipmentDeleting = state => state.vehicleEquipments.getIn(['deletion', 'inProgress'])

export const getVehicleEquipmentDeleteError = state => state.vehicleEquipments.getIn(['deletion', 'error'])

const getDuplicity = state => state.vehicleEquipments.getIn(['all', 'duplicity'])

export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)
