// Fetch list

export const FETCH = 'companyNumberings.FETCH'
export const FETCH_STARTED = 'companyNumberings.FETCH_STARTED'
export const FETCH_FULFILLED = 'companyNumberings.FETCH_FULFILLED'
export const FETCH_REJECTED = 'companyNumberings.FETCH_REJECTED'
export const FETCH_CANCELLED = 'companyNumberings.FETCH_CANCELLED'

export const SORTING_CHANGED = 'companyNumberings.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'companyNumberings.FETCH_ONE'
export const FETCH_ONE_STARTED = 'companyNumberings.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'companyNumberings.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'companyNumberings.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'companyNumberings.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'companyNumberings.SAVE'
export const SAVE_STARTED = 'companyNumberings.SAVE_STARTED'
export const SAVE_FULFILLED = 'companyNumberings.SAVE_FULFILLED'
export const SAVE_REJECTED = 'companyNumberings.SAVE_REJECTED'
export const SAVE_CANCELLED = 'companyNumberings.SAVE_CANCELLED'

// Delete

export const DELETE = 'companyNumberings.DELETE'
export const DELETE_STARTED = 'companyNumberings.DELETE_STARTED'
export const DELETE_FULFILLED = 'companyNumberings.DELETE_FULFILLED'
export const DELETE_REJECTED = 'companyNumberings.DELETE_REJECTED'
export const DELETE_CANCELLED = 'companyNumberings.DELETE_CANCELLED'

// Export

export const EXPORT = 'companyNumberings.EXPORT'
export const EXPORT_FULFILLED = 'companyNumberings.EXPORT_FULFILLED'
