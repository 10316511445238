import * as actionTypes from './actionTypes'

// Fetch

export const fetchWarehousingItems = () => ({
    type: actionTypes.FETCH,
})

export const fetchWarehousingItemsFulfilled = items => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: items,
})

export const fetchWarehousingItemsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchWarehousingItemsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchWarehousingItem = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchWarehousingItemFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchWarehousingItemRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchWarehousingItemCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveWarehousingItem = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveWarehousingItemFulfilled = item => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: item,
})

export const saveWarehousingItemRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteWarehousingItem = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteWarehousingItemFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteWarehousingItemRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportWarehousingItems = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Fetch receipts

export const fetchWarehousingItemReceipts = (id, page) => ({
    type: actionTypes.FETCH_RECEIPTS,
    payload: {
        id,
        page,
    },
})

export const fetchWarehousingItemReceiptsFulfilled = data => ({
    type: actionTypes.FETCH_RECEIPTS_FULFILLED,
    payload: data,
})

export const fetchWarehousingItemReceiptsRejected = message => ({
    type: actionTypes.FETCH_RECEIPTS_REJECTED,
    payload: message,
})

export const fetchWarehousingItemReceiptsCancelled = () => ({
    type: actionTypes.FETCH_RECEIPTS_CANCELLED,
})

// Export company cost centers

export const exportWarehousingItemReceipts = id => ({
    type: actionTypes.EXPORT_RECEIPTS,
    payload: id,
})

export const exportWarehousingItemReceiptsFulfilled = data => ({
    type: actionTypes.EXPORT_RECEIPTS_FULFILLED,
    payload: data,
})

export const exportWarehousingItemReceiptsRejected = message => ({
    type: actionTypes.EXPORT_RECEIPTS_REJECTED,
    payload: message,
})

export const exportWarehousingItemReceiptsCancelled = () => ({
    type: actionTypes.EXPORT_RECEIPTS_CANCELLED,
})