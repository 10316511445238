import React from 'react'
import { Route, Switch } from 'react-router'
import TrailerDocumentsList from './TrailerDocumentsList'
import TrailerDocumentsEdit from './TrailerDocumentsEdit'

const TrailerDocuments = props => (
    <Switch>
        <Route exact path={props.match.url} component={TrailerDocumentsList} />
        <Route exact path={`${props.match.url}/new`} component={TrailerDocumentsEdit} />
        <Route exact path={`${props.match.url}/:trailerDocumentID`} component={TrailerDocumentsEdit} />
    </Switch>
)

export default TrailerDocuments
