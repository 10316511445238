import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { driverDocumentSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { DriverDocument } from './model'
import { Driver } from '../drivers/model'
import * as driversActionCreators from '../drivers/actionCreators'
import { DocumentType } from '../document_types/model'
import * as documentTypesActionCreators from '../document_types/actionCreators'

// Fetch

const fetchDriverDocumentsEpic = (action$, store) => {
    const tableIdentifier = 'driver_documents_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `driver-documents/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(driverDocumentSchema)
                        )

                        const driverDocuments = normalizedEntitiesToRecordMap(
                            normalizedData.entities.driver_documents,
                            DriverDocument,
                            normalizedData.result
                        )
                        const drivers = normalizedEntitiesToRecordMap(normalizedData.entities.drivers, Driver)
                        const documentTypes = normalizedEntitiesToRecordMap(normalizedData.entities.document_types, DocumentType)

                        const observables = [
                            Observable.of(driversActionCreators.fetchDriversFulfilled(drivers)),
                            Observable.of(documentTypesActionCreators.fetchDocumentTypesFulfilled(documentTypes)),
                            Observable.of(actionCreators.fetchDriverDocumentsFulfilled(driverDocuments)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchDriverDocumentsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchDriverDocumentEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().driverDocuments.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `driver-documents/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, driverDocumentSchema)

                        const driverDocuments = normalizedEntitiesToRecordMap(normalizedData.entities.driver_documents, DriverDocument)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchDriverDocumentsFulfilled(driverDocuments)),
                            Observable.of(actionCreators.fetchDriverDocumentFulfilled())
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchDriverDocumentsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveDriverDocumentEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().driverDocuments.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload.values)
            if (action.payload.ignoreDuplicity) {
                values.ignore_duplicity = 1
            }

            let path = `driver-documents`
            let method = 'POST'

            if (values.id) {
                path = `driver-documents/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        if (ajaxResponse.response.duplicity_found) {
                            return Observable.concat(Observable.of(actionCreators.saveDriverDocumentDuplicityFound(ajaxResponse.response.duplicity)))
                        }

                        const normalizedData = normalize(ajaxResponse.response, driverDocumentSchema)

                        const driverDocuments = normalizedEntitiesToRecordMap(normalizedData.entities.driver_documents, DriverDocument)
                        const driverDocument = driverDocuments.valueSeq().first()

                        return Observable.of(actionCreators.saveDriverDocumentFulfilled(driverDocument))
                    })
                    .catch(error => Observable.of(actionCreators.saveDriverDocumentRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteDriverDocumentEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().driverDocuments.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `driver-documents/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteDriverDocumentFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteDriverDocumentRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )
      
// Fetch files

const fetchDriverDocumentFilesEpic = (action$, store) =>
action$
    .ofType(actionTypes.FETCH_FILES)
    .filter(() => !store.getState().driverDocuments.getIn(['files', 'fetching']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_FILES_STARTED,
            }),
            request({
                path: `driver-documents/${action.payload}/files`,
                method: 'GET',
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchDriverDocumentFilesFulfilled(ajaxResponse.response.files)))
                .catch(error => Observable.of(actionCreators.fetchDriverDocumentFilesRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_FILES_CANCELLED))
        )
    )

// Upload files

const uploadDriverDocumentFilesEpic = (action$, store) =>
action$
    .ofType(actionTypes.UPLOAD_FILES)
    .filter(() => !store.getState().driverDocuments.getIn(['files', 'saving']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.UPLOAD_FILES_STARTED,
            }),
            request({
                path: `driver-documents/${action.payload.id}/files`,
                method: 'POST',
                body: { ...action.payload },
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.uploadDriverDocumentFilesFulfilled(ajaxResponse.response.files)))
                .catch(error => Observable.of(actionCreators.uploadDriverDocumentFilesRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.UPLOAD_FILES_CANCELLED))
        )
    )

// Delete file

const deleteDriverDocumentFileEpic = (action$, store) =>
action$
    .ofType(actionTypes.DELETE_FILE)
    .filter(() => !store.getState().driverDocuments.getIn(['files', 'deleting']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.DELETE_FILE_STARTED,
            }),
            request({
                path: `driver-documents/${action.payload.id}/files/${action.payload.file_id}`,
                method: 'DELETE',
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.deleteDriverDocumentFileFulfilled(ajaxResponse.response.files)))
                .catch(error => Observable.of(actionCreators.deleteDriverDocumentFileRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.DELETE_FILE_CANCELLED))
        )
    )

// Export

const exportDriverDocumentsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `driver-documents/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

export default [fetchDriverDocumentsEpic, fetchDriverDocumentEpic, saveDriverDocumentEpic, deleteDriverDocumentEpic, exportDriverDocumentsEpic, fetchDriverDocumentFilesEpic, uploadDriverDocumentFilesEpic, deleteDriverDocumentFileEpic]
