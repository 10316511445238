import * as actionTypes from './actionTypes'

// Fetch

export const fetchCarrierScoring = () => ({
    type: actionTypes.FETCH,
})

export const fetchCarrierScoringStarted = () => ({
    type: actionTypes.FETCH_STARTED,
})

export const fetchCarrierScoringFulfilled = (items, rules, transportScoringRules) => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: {
        items,
        rules,
        transportScoringRules,
    },
})

export const fetchCarrierScoringRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCarrierScoringCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Save question

export const saveCarrierScoringQuestion = values => ({
    type: actionTypes.SAVE_QUESTION,
    payload: values,
})

export const saveCarrierScoringQuestionStarted = () => ({
    type: actionTypes.SAVE_QUESTION_STARTED,
})

export const saveCarrierScoringQuestionFulfilled = (items, rules, transportScoringRules) => ({
    type: actionTypes.SAVE_QUESTION_FULFILLED,
    payload: {
        items,
        rules,
        transportScoringRules,
    },
})

export const saveCarrierScoringQuestionRejected = message => ({
    type: actionTypes.SAVE_QUESTION_REJECTED,
    payload: message,
})

export const saveCarrierScoringQuestionCancelled = () => ({
    type: actionTypes.SAVE_QUESTION_CANCELLED,
})

// Delete question

export const deleteCarrierScoringQuestion = id => ({
    type: actionTypes.DELETE_QUESTION,
    payload: id,
})

export const deleteCarrierScoringQuestionStarted = () => ({
    type: actionTypes.DELETE_QUESTION_STARTED,
})

export const deleteCarrierScoringQuestionFulfilled = (items, rules, transportScoringRules) => ({
    type: actionTypes.DELETE_QUESTION_FULFILLED,
    payload: {
        items,
        rules,
        transportScoringRules,
    },
})

export const deleteCarrierScoringQuestionRejected = message => ({
    type: actionTypes.DELETE_QUESTION_REJECTED,
    payload: message,
})

export const deleteCarrierScoringQuestionCancelled = () => ({
    type: actionTypes.DELETE_QUESTION_CANCELLED,
})

// Move question UP

export const moveCarrierScoringQuestionUp = id => ({
    type: actionTypes.MOVE_QUESTION_UP,
    payload: id,
})

export const moveCarrierScoringQuestionUpStarted = () => ({
    type: actionTypes.MOVE_QUESTION_UP_STARTED,
})

export const moveCarrierScoringQuestionUpFulfilled = (items, rules, transportScoringRules) => ({
    type: actionTypes.MOVE_QUESTION_UP_FULFILLED,
    payload: {
        items,
        rules,
        transportScoringRules,
    },
})

export const moveCarrierScoringQuestionUpRejected = message => ({
    type: actionTypes.MOVE_QUESTION_UP_REJECTED,
    payload: message,
})

export const moveCarrierScoringQuestionUpCancelled = () => ({
    type: actionTypes.MOVE_QUESTION_UP_CANCELLED,
})

// Move question DOWN

export const moveCarrierScoringQuestionDown = id => ({
    type: actionTypes.MOVE_QUESTION_DOWN,
    payload: id,
})

export const moveCarrierScoringQuestionDownStarted = () => ({
    type: actionTypes.MOVE_QUESTION_DOWN_STARTED,
})

export const moveCarrierScoringQuestionDownFulfilled = (items, rules, transportScoringRules) => ({
    type: actionTypes.MOVE_QUESTION_DOWN_FULFILLED,
    payload: {
        items,
        rules,
        transportScoringRules,
    },
})

export const moveCarrierScoringQuestionDownRejected = message => ({
    type: actionTypes.MOVE_QUESTION_DOWN_REJECTED,
    payload: message,
})

export const moveCarrierScoringQuestionDownCancelled = () => ({
    type: actionTypes.MOVE_QUESTION_DOWN_CANCELLED,
})

// Save answer

export const saveCarrierScoringAnswer = values => ({
    type: actionTypes.SAVE_ANSWER,
    payload: values,
})

export const saveCarrierScoringAnswerStarted = () => ({
    type: actionTypes.SAVE_ANSWER_STARTED,
})

export const saveCarrierScoringAnswerFulfilled = (items, rules, transportScoringRules) => ({
    type: actionTypes.SAVE_ANSWER_FULFILLED,
    payload: {
        items,
        rules,
        transportScoringRules,
    },
})

export const saveCarrierScoringAnswerRejected = message => ({
    type: actionTypes.SAVE_ANSWER_REJECTED,
    payload: message,
})

export const saveCarrierScoringAnswerCancelled = () => ({
    type: actionTypes.SAVE_ANSWER_CANCELLED,
})

// Delete answer

export const deleteCarrierScoringAnswer = id => ({
    type: actionTypes.DELETE_ANSWER,
    payload: id,
})

export const deleteCarrierScoringAnswerStarted = () => ({
    type: actionTypes.DELETE_ANSWER_STARTED,
})

export const deleteCarrierScoringAnswerFulfilled = (items, rules, transportScoringRules) => ({
    type: actionTypes.DELETE_ANSWER_FULFILLED,
    payload: {
        items,
        rules,
        transportScoringRules,
    },
})

export const deleteCarrierScoringAnswerRejected = message => ({
    type: actionTypes.DELETE_ANSWER_REJECTED,
    payload: message,
})

export const deleteCarrierScoringAnswerCancelled = () => ({
    type: actionTypes.DELETE_ANSWER_CANCELLED,
})

// Move answer UP

export const moveCarrierScoringAnswerUp = id => ({
    type: actionTypes.MOVE_ANSWER_UP,
    payload: id,
})

export const moveCarrierScoringAnswerUpStarted = () => ({
    type: actionTypes.MOVE_ANSWER_UP_STARTED,
})

export const moveCarrierScoringAnswerUpFulfilled = (items, rules, transportScoringRules) => ({
    type: actionTypes.MOVE_ANSWER_UP_FULFILLED,
    payload: {
        items,
        rules,
        transportScoringRules,
    },
})

export const moveCarrierScoringAnswerUpRejected = message => ({
    type: actionTypes.MOVE_ANSWER_UP_REJECTED,
    payload: message,
})

export const moveCarrierScoringAnswerUpCancelled = () => ({
    type: actionTypes.MOVE_ANSWER_UP_CANCELLED,
})

// Move answer DOWN

export const moveCarrierScoringAnswerDown = id => ({
    type: actionTypes.MOVE_ANSWER_DOWN,
    payload: id,
})

export const moveCarrierScoringAnswerDownStarted = () => ({
    type: actionTypes.MOVE_ANSWER_DOWN_STARTED,
})

export const moveCarrierScoringAnswerDownFulfilled = (items, rules, transportScoringRules) => ({
    type: actionTypes.MOVE_ANSWER_DOWN_FULFILLED,
    payload: {
        items,
        rules,
        transportScoringRules,
    },
})

export const moveCarrierScoringAnswerDownRejected = message => ({
    type: actionTypes.MOVE_ANSWER_DOWN_REJECTED,
    payload: message,
})

export const moveCarrierScoringAnswerDownCancelled = () => ({
    type: actionTypes.MOVE_ANSWER_DOWN_CANCELLED,
})

// Save rule

export const saveCarrierScoringRule = values => ({
    type: actionTypes.SAVE_RULE,
    payload: values,
})

export const saveCarrierScoringRuleStarted = () => ({
    type: actionTypes.SAVE_RULE_STARTED,
})

export const saveCarrierScoringRuleFulfilled = (items, rules, transportScoringRules) => ({
    type: actionTypes.SAVE_RULE_FULFILLED,
    payload: {
        items,
        rules,
        transportScoringRules,
    },
})

export const saveCarrierScoringRuleRejected = message => ({
    type: actionTypes.SAVE_RULE_REJECTED,
    payload: message,
})

export const saveCarrierScoringRuleCancelled = () => ({
    type: actionTypes.SAVE_RULE_CANCELLED,
})

// Delete rule

export const deleteCarrierScoringRule = id => ({
    type: actionTypes.DELETE_RULE,
    payload: id,
})

export const deleteCarrierScoringRuleStarted = () => ({
    type: actionTypes.DELETE_RULE_STARTED,
})

export const deleteCarrierScoringRuleFulfilled = (items, rules, transportScoringRules) => ({
    type: actionTypes.DELETE_RULE_FULFILLED,
    payload: {
        items,
        rules,
        transportScoringRules,
    },
})

export const deleteCarrierScoringRuleRejected = message => ({
    type: actionTypes.DELETE_RULE_REJECTED,
    payload: message,
})

export const deleteCarrierScoringRuleCancelled = () => ({
    type: actionTypes.DELETE_RULE_CANCELLED,
})
