import * as actionTypes from './actionTypes'

// Fetch

export const fetchTrailerEquipments = () => ({
    type: actionTypes.FETCH,
})

export const fetchTrailerEquipmentsForMap = () => ({
    type: actionTypes.FETCH_FOR_MAP,
})

export const fetchTrailerEquipmentsFulfilled = trailerEquipments => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: trailerEquipments,
})

export const fetchTrailerEquipmentsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTrailerEquipmentsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchTrailerEquipment = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchTrailerEquipmentFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchTrailerEquipmentRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchTrailerEquipmentCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveTrailerEquipment = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveTrailerEquipmentFulfilled = trailerEquipment => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: trailerEquipment,
})

export const saveTrailerEquipmentRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveTrailerEquipmentDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteTrailerEquipment = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteTrailerEquipmentFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteTrailerEquipmentRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Clear

export const clearTrailerEquipments = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportTrailerEquipments = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
