import React from 'react'
import { Route, Switch } from 'react-router'
import EquipmentsList from './EquipmentsList'
import EquipmentsEdit from './EquipmentsEdit'

const Equipments = props => (
    <Switch>
        <Route exact path={props.match.url} component={EquipmentsList} />
        <Route exact path={`${props.match.url}/new`} component={EquipmentsEdit} />
        <Route exact path={`${props.match.url}/:equipmentID`} component={EquipmentsEdit} />
    </Switch>
)

export default Equipments
