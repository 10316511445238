import { Observable } from 'rxjs'

import { request, parseAPIError } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as selectors from './selectors'

// Fetch

const fetchReportCarrierScoringEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request({
                method: 'GET',
                path: `report-carrier-scoring?year=${action.payload}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportCarrierScoringFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportCarrierScoringRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    )

// Fetch scoring

const fetchCarrierScoringEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH_SCORING)
        .filter(() => !selectors.isCarrierScoringFetching(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.fetchScoringStarted()),
                request({
                    method: 'GET',
                    path: `report-carrier-scoring/${action.payload.id}/scoring?year=${action.payload.year}&month=${action.payload.month}`,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchScoringFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchScoringRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_SCORING_CANCELLED))
            )
        )
}

// Save scoring

const saveCarrierScoringEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.SAVE_SCORING)
        .filter(() => !selectors.isCarrierScoringSaving(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.saveScoringStarted()),
                request({
                    method: 'POST',
                    path: `report-carrier-scoring/${action.payload.id}/scoring?year=${action.payload.year}&month=${action.payload.month}`,
                    body: action.payload.values,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.saveScoringFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.saveScoringRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_SCORING_CANCELLED))
            )
        )
}

export default [fetchReportCarrierScoringEpic, fetchCarrierScoringEpic, saveCarrierScoringEpic]
