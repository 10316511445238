import * as actionTypes from './actionTypes'
import { FETCH_SERVICE_ORDER_STATES, FETCH_SERVICE_ORDER_STATES_FULFILLED } from "./actionTypes";

// Fetch

export const fetchServiceOrders = () => ({
    type: actionTypes.FETCH,
})

export const fetchServiceOrdersFulfilled = orders => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: orders,
})

export const fetchServiceOrdersRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchServiceOrdersCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchServiceOrder = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchServiceOrderFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchServiceOrderRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchServiceOrderCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveServiceOrder = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveServiceOrderFulfilled = order => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: order,
})

export const saveServiceOrderRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteServiceOrder = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteServiceOrderFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteServiceOrderRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportServiceOrders = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Job autocomplete

export const fetchJobAutocomplete = data => ({
    type: actionTypes.FETCH_JOB_AUTOCOMPLETE,
    payload: data,
})

export const fetchJobAutocompleteFulfilled = data => ({
    type: actionTypes.FETCH_JOB_AUTOCOMPLETE_FULFILLED,
    payload: data,
})

export const fetchJobAutocompleteRejected = message => ({
    type: actionTypes.FETCH_JOB_AUTOCOMPLETE_REJECTED,
    payload: message,
})

export const fetchJobAutocompleteCancelled = () => ({
    type: actionTypes.FETCH_JOB_AUTOCOMPLETE_CANCELLED,
})

// Material autocomplete

export const fetchMaterialAutocomplete = data => ({
    type: actionTypes.FETCH_MATERIAL_AUTOCOMPLETE,
    payload: data,
})

export const fetchMaterialAutocompleteFulfilled = data => ({
    type: actionTypes.FETCH_MATERIAL_AUTOCOMPLETE_FULFILLED,
    payload: data,
})

export const fetchMaterialAutocompleteRejected = message => ({
    type: actionTypes.FETCH_MATERIAL_AUTOCOMPLETE_REJECTED,
    payload: message,
})

export const fetchMaterialAutocompleteCancelled = () => ({
    type: actionTypes.FETCH_MATERIAL_AUTOCOMPLETE_CANCELLED,
})

// Worker autocomplete

export const fetchWorkerAutocomplete = data => ({
    type: actionTypes.FETCH_WORKER_AUTOCOMPLETE,
    payload: data,
})

export const fetchWorkerAutocompleteFulfilled = data => ({
    type: actionTypes.FETCH_WORKER_AUTOCOMPLETE_FULFILLED,
    payload: data,
})

export const fetchWorkerAutocompleteRejected = message => ({
    type: actionTypes.FETCH_WORKER_AUTOCOMPLETE_REJECTED,
    payload: message,
})

export const fetchWorkerAutocompleteCancelled = () => ({
    type: actionTypes.FETCH_WORKER_AUTOCOMPLETE_CANCELLED,
})

// Fetch states

export const fetchServiceOrderStatesFulfilled = orderStates => ({
    type: actionTypes.FETCH_SERVICE_ORDER_STATES_FULFILLED,
    payload: orderStates,
})

// Create issue

export const createIssue = orderId => ({
    type: actionTypes.CREATE_ISSUE,
    payload: orderId,
})