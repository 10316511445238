import React from 'react'
import { Route, Switch } from 'react-router'
import ChangeLocale from './ChangeLocale'

const Locale = props => (
    <Switch>
        <Route exact path={props.match.url} component={ChangeLocale} />
    </Switch>
)

export default Locale
