import { createSelector } from 'reselect'

export const getUsers = state => state.users.getIn(['all', 'objects'])

export const createGetUserByIDSelector = id => createSelector([getUsers], users => users.get(String(id)))

const getCurrentUserStatus = state => state.users.get('current')

export const createGetCurrentUserStatusSelector = () => createSelector([getCurrentUserStatus], status => status)

const getUserDeleting = state => state.users.getIn(['deletion', 'inProgress'])

export const createGetUserDeletingSelector = () => createSelector([getUserDeleting], inProgress => inProgress)

const getUserDeleteError = state => state.users.getIn(['deletion', 'error'])

export const createGetUserDeleteErrorSelector = () => createSelector([getUserDeleteError], error => error)

export const getLoginLogData = state => state.users.getIn(['loginLog', 'data'])
export const getLoginLogFetching = state => state.users.getIn(['loginLog', 'fetching'])
export const getLoginLogError = state => state.users.getIn(['loginLog', 'error'])