import * as actionTypes from './actionTypes'

// Fetch

export const fetchSales = () => ({
    type: actionTypes.FETCH,
})

export const fetchSalesFulfilled = sales => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: sales,
})

export const fetchSalesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchSalesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchSale = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchSaleFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchSaleRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchSaleCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveSales = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveSalesFulfilled = sales => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: sales,
})

export const saveSalesRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteSales = (id, withFuture) => ({
    type: actionTypes.DELETE,
    payload: { id, withFuture },
})

export const deleteSalesFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteSalesRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Clear

export const clearSales = () => ({
    type: actionTypes.CLEAR,
})

// Fetch import headers

export const fetchSalesImportHeaders = file => ({
    type: actionTypes.FETCH_IMPORT_HEADERS,
    payload: file,
})

export const fetchSalesImportHeadersFulfilled = headers => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_FULFILLED,
    payload: headers,
})

export const fetchSalesImportHeadersRejected = message => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_REJECTED,
    payload: message,
})

export const fetchSalesImportHeadersCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_CANCELLED,
})

// Fetch import items

export const fetchSalesImportItems = (fileId, columnTypes, defaultDate, defaultSalesTypeId) => ({
    type: actionTypes.FETCH_IMPORT_ITEMS,
    payload: {
        fileId,
        columnTypes,
        defaultDate,
        defaultSalesTypeId
    },
})

export const fetchSalesImportItemsFulfilled = (items, defaultDate, defaultSalesTypeId) => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_FULFILLED,
    payload: {
        items,
        defaultDate,
        defaultSalesTypeId
    },
})

export const fetchSalesImportItemsRejected = message => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_REJECTED,
    payload: message,
})

export const fetchSalesImportItemsCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_CANCELLED,
})

// Import sales

export const importSales = (fileId, columnTypes, defaultDate, defaultSalesTypeId, keys) => ({
    type: actionTypes.IMPORT_SALES,
    payload: {
        fileId,
        columnTypes,
        defaultDate,
        defaultSalesTypeId,
        keys
    },
})

export const importSalesFulfilled = () => ({
    type: actionTypes.IMPORT_SALES_FULFILLED,
})

export const importSalesRejected = message => ({
    type: actionTypes.IMPORT_SALES_REJECTED,
    payload: message,
})

export const importSalesCancelled = () => ({
    type: actionTypes.IMPORT_SALES_CANCELLED,
})





// Set invoicing state

export const setInvoicingState = (salesId, invoicingStateId, filters) => ({
    type: actionTypes.SET_INVOICING_STATE,
    payload: {
        salesId,
        invoicingStateId,
        filters,
    },
})

export const setInvoicingStateFulfilled = sales => ({
    type: actionTypes.SET_INVOICING_STATE_FULFILLED,
    payload: sales,
})

export const setInvoicingStateRejected = message => ({
    type: actionTypes.SET_INVOICING_STATE_REJECTED,
    payload: message,
})

export const setInvoicingStateCancelled = () => ({
    type: actionTypes.SET_INVOICING_STATE_CANCELLED,
})

// Fetch data for invoicing

export const fetchDataForInvoicing = salesIds => ({
    type: actionTypes.FETCH_DATA_FOR_INVOICING,
    payload: {
        salesIds,
    },
})

export const fetchDataForInvoicingFulfilled = data => ({
    type: actionTypes.FETCH_DATA_FOR_INVOICING_FULFILLED,
    payload: {
        data,
    },
})

export const fetchDataForInvoicingRejected = message => ({
    type: actionTypes.FETCH_DATA_FOR_INVOICING_REJECTED,
    payload: message,
})

export const fetchDataForInvoicingCancelled = () => ({
    type: actionTypes.FETCH_DATA_FOR_INVOICING_CANCELLED,
})

// Create invoice

export const createInvoice = data => ({
    type: actionTypes.CREATE_INVOICE,
    payload: {
        data,
    },
})

export const createInvoiceFulfilled = (data, createdInvoiceId) => ({
    type: actionTypes.CREATE_INVOICE_FULFILLED,
    payload: {
        data,
        createdInvoiceId,
    },
})

export const createInvoiceRejected = message => ({
    type: actionTypes.CREATE_INVOICE_REJECTED,
    payload: message,
})

export const createInvoiceCancelled = () => ({
    type: actionTypes.CREATE_INVOICE_CANCELLED,
})
