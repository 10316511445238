import React from 'react'
import { Route, Switch } from 'react-router'
import DriverEquipmentsList from './DriverEquipmentsList'
import DriverEquipmentsEdit from './DriverEquipmentsEdit'

const DriverEquipments = props => (
    <Switch>
        <Route exact path={props.match.url} component={DriverEquipmentsList} />
        <Route exact path={`${props.match.url}/new`} component={DriverEquipmentsEdit} />
        <Route exact path={`${props.match.url}/:driverEquipmentID`} component={DriverEquipmentsEdit} />
    </Switch>
)

export default DriverEquipments
