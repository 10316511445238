import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import * as constants from '../constants'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class DriverTimesheetsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render() {
        const driverTimesheet = this.props.data
        const driver = this.props.driver
        const showDatetime = String(driverTimesheet.interval_type_id) === String(constants.INTERVAL_TYPE_INTERVAL)

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-160">{driver ? driver.getShortFullName() : null}</td>
                <td className="w-160">{this.props.intl.formatMessage({ id: `driverTimesheetTypes.${driverTimesheet.type}` })}</td>
                <td className="w-200 text-center">
                    {showDatetime
                        ? formatters.datetimeFormatter(driverTimesheet.start_datetime)
                        : formatters.dateFormatter(driverTimesheet.start_datetime)}
                </td>
                <td className="w-200 text-center">
                    {showDatetime
                        ? formatters.datetimeFormatter(driverTimesheet.end_datetime)
                        : formatters.dateFormatter(driverTimesheet.end_datetime)}
                </td>
                <td className="w-80 text-right">{formatters.durationFromSecondsFormatter(driverTimesheet.time)}</td>
                <td className="w-max wm-200 wrapped">{driverTimesheet.note}</td>
            </tr>
        )
    }
}

export default injectIntl(DriverTimesheetsListRow)
