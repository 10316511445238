import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import Dropzone from 'react-dropzone'

import * as config from '../../../common/config'
import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as transportsActionCreators from '../actionCreators'
import {
    getTransportFiles,
    getTransportEventFiles,
    getTransportFilesFetching,
    getTransportFilesSaving,
    getTransportFilesDeleting,
    getTransportFilesError,
} from '../selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import PermissionsProps from '../../auth/components/PermissionsProps'
import { PERMS_FILES } from '../permissions'

class TransportsFiles extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // actions
    fetchTransportFiles = transportId => {
        this.props.actions.fetchTransportFiles(transportId)
    }

    uploadTransportFiles = files => {
        this.props.actions.uploadTransportFiles(this.props.transport.id, files)
    }

    deleteTransportFile = (transportFileId, isEventFile) => {
        this.props.actions.deleteTransportFile(this.props.transport.id, transportFileId, isEventFile)
    }

    refresh = () => {
        this.fetchTransportFiles(this.props.transport.id)
    }

    // handles
    onDrop = files => {
        const loadedFiles = []

        files.forEach(file => {
            const reader = new FileReader()
            const fileName = file.name

            reader.readAsDataURL(file)
            reader.onload = e => {
                loadedFiles.push({
                    name: fileName,
                    base64: e.target.result,
                })

                if (loadedFiles.length === files.length) {
                    this.uploadTransportFiles(loadedFiles)
                }
            }
        })
    }

    onClick = (e, transportFile) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        window.open(`${config.CFG_API_BASE_URL}/files/transport-files/${transportFile.filename}`)
    }

    onClickEventFile = (e, transportFile) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        window.open(`${config.CFG_API_BASE_URL}/files/transport-documents/${transportFile.filename}`)
    }

    onDelete = (e, transportFile) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.deleteTransportFile(transportFile.id)
                this.hideConfirmDialog()
            },
        })
    }

    onDeleteEventFile = (e, transportFile) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.deleteTransportFile(transportFile.id, 1)
                this.hideConfirmDialog()
            },
        })
    }

    handleSetAllRequiredFilesUploaded = value => {
        this.props.handleSetAllRequiredFilesUploaded && this.props.handleSetAllRequiredFilesUploaded(value)
    }

    componentDidMount() {
        if (this.props.transport) {
            this.fetchTransportFiles(this.props.transport.id)
        }
    }

    componentWillReceiveProps(nextProps) {
        // Fetching after transport change
        if (nextProps.transport && JSON.stringify(nextProps.transport) !== JSON.stringify(this.props.transport)) {
            this.fetchTransportFiles(nextProps.transport.id)
        }

        // Uploading
        if (this.props.transportFilesSaving && !nextProps.transportFilesSaving) {
            if (nextProps.transportFilesError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.filesUploadSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.transportFilesError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Deleting
        if (this.props.transportFilesDeleting && !nextProps.transportFilesDeleting) {
            if (nextProps.transportFilesError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.filesDeleteSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.transportFilesError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const transport = this.props.transport
        const transportFiles = this.props.transportFiles || []
        const transportEventFiles = this.props.transportEventFiles || []

        if (!transport) {
            return null
        }

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_FILES)} noPermissionsTab>
                <LoadingOverlay active={this.props.transportFilesFetching || this.props.transportFilesSaving || this.props.transportFilesDeleting}>
                    <div className="panel-head">
                        <h2 className="pull-left wp-100">
                            <div className="pull-left">
                                <FormattedMessage id="modules.transports.files" />
                            </div>
                            <div className="pull-left m-l-lg">
                                {this.props.allRequiredFilesUploaded ? (
                                    <PermissionsProps
                                        has={[PERMS_FILES.UPDATE]}
                                        props={{
                                            onClick: () => {
                                                this.handleSetAllRequiredFilesUploaded(false)
                                            }
                                        }}
                                    >
                                        <a href="#" className="text-md text-gray no-text-decoration">
                                            <i className="far fa-toggle-on text-success" /> Všechny požadované soubory nahrány
                                        </a>
                                    </PermissionsProps>
                                ) : (
                                    <PermissionsProps
                                        has={[PERMS_FILES.UPDATE]}
                                        props={{
                                            onClick: () => {
                                                this.handleSetAllRequiredFilesUploaded(true)
                                            }
                                        }}
                                    >
                                        <a href="#" className="text-md text-gray no-text-decoration">
                                            <i className="far fa-toggle-off" /> Všechny požadované soubory nahrány
                                        </a>
                                    </PermissionsProps>
                                )}
                            </div>
                        </h2>
                    </div>

                    <PermissionsCheck has={[PERMS_FILES.CREATE]}>
                        <div className="pull-left wp-100 m-b-md">
                            <Dropzone onDrop={this.onDrop} style={{}} className="dropzone" acceptClassName="dropzone-accept">
                                <p className="ready">
                                    <FormattedMessage id="fields.dropzoneReady" />
                                </p>
                                <p className="accept">
                                    <FormattedMessage id="fields.dropzoneAccept" />
                                </p>
                            </Dropzone>
                        </div>
                    </PermissionsCheck>

                    {transportFiles.length > 0 ? (
                        <div className="pull-left wp-100">
                            <div className="table-container">
                                <table className="table table-striped table-no-border no-m">
                                    <thead>
                                        <tr>
                                            <th className="w-60" />
                                            <th className="w-140 text-center">
                                                <FormattedMessage id="fields.time" />
                                            </th>
                                            <th className="w-140">
                                                <FormattedMessage id="fields.user" />
                                            </th>
                                            <th className="w-max wm-300">
                                                <FormattedMessage id="fields.name" />
                                            </th>
                                            <th className="w-100 text-center">
                                                <FormattedMessage id="fields.fileType" />
                                            </th>
                                            <th className="w-100 text-right">
                                                <FormattedMessage id="fields.fileSize" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transportFiles.map(transportFile => (
                                            <tr
                                                key={`transportFile-${transportFile.id}`}
                                                onClick={e => {
                                                    this.onClick(e, transportFile)
                                                }}
                                                className="row-selectable"
                                            >
                                                <td className="w-60 table-buttons">
                                                    <button className="far fa-download text-gray m-r-xxs" />
                                                    <PermissionsCheck has={[PERMS_FILES.DELETE]}>
                                                        <button
                                                            onClick={e => {
                                                                this.onDelete(e, transportFile)
                                                            }}
                                                            className="far fa-trash text-gray"
                                                        />
                                                    </PermissionsCheck>
                                                </td>
                                                <td className="text-center">{formatters.datetimeFormatter(transportFile.datetime)}</td>
                                                <td>{transportFile.user && transportFile.user.name}</td>
                                                <td>{transportFile.name}</td>
                                                <td className="text-center">{transportFile.extension}</td>
                                                <td className="text-right">{transportFile.size}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className="alert alert-warning pull-left wp-100">
                            <p>
                                <FormattedMessage id="fields.noFilesUploaded" />
                            </p>
                        </div>
                    )}

                    <div className="pull-left wp-100 m-t-lg">
                        <div className="table-container">
                            <h4>Dokumenty od řidiče</h4>
                            <table className="table table-striped table-no-border no-m">
                                <thead>
                                    <tr>
                                        <th className="w-60" />
                                        <th className="w-140 text-center">
                                            <FormattedMessage id="fields.time" />
                                        </th>
                                        <th className="w-max wm-300">
                                            <FormattedMessage id="fields.name" />
                                        </th>
                                        <th className="w-100 text-center">
                                            <FormattedMessage id="fields.fileType" />
                                        </th>
                                        <th className="w-100 text-right">
                                            <FormattedMessage id="fields.fileSize" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transportEventFiles.map(transportFile =>
                                        transportFile.filename ? (
                                            <tr
                                                key={`transportEventFile-${transportFile.id}`}
                                                onClick={e => {
                                                    this.onClickEventFile(e, transportFile)
                                                }}
                                                className="row-selectable"
                                            >
                                                <td className="w-60 table-buttons">
                                                    <button className="far fa-download text-gray m-r-xxs" />
                                                    <PermissionsCheck has={[PERMS_FILES.DELETE]}>
                                                        <button
                                                            onClick={e => {
                                                                this.onDeleteEventFile(e, transportFile)
                                                            }}
                                                            className="far fa-trash text-gray"
                                                        />
                                                    </PermissionsCheck>
                                                </td>
                                                <td className="text-center">{formatters.datetimeFormatter(transportFile.datetime)}</td>
                                                <td>{transportFile.filename}</td>
                                                <td className="text-center">{transportFile.extension}</td>
                                                <td className="text-right">{transportFile.size}</td>
                                            </tr>
                                        ) : null
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </LoadingOverlay>

                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        transportFiles: getTransportFiles(state),
        transportEventFiles: getTransportEventFiles(state),
        transportFilesFetching: getTransportFilesFetching(state),
        transportFilesSaving: getTransportFilesSaving(state),
        transportFilesDeleting: getTransportFilesDeleting(state),
        transportFilesError: getTransportFilesError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TransportsFiles)
)
