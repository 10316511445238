export const SALES_GENERATOR_REPEAT_NO = 1
export const SALES_GENERATOR_REPEAT_WEEK = 2
export const SALES_GENERATOR_REPEAT_MONTH = 3
export const SALES_GENERATOR_REPEAT_QUARTER = 4
export const SALES_GENERATOR_REPEAT_HALF = 5
export const SALES_GENERATOR_REPEAT_YEAR = 6

export const SALES_GENERATOR_REPEATS = [
    {
        id: SALES_GENERATOR_REPEAT_NO,
        name: 'period.1',
    },
    {
        id: SALES_GENERATOR_REPEAT_WEEK,
        name: 'period.2',
    },
    {
        id: SALES_GENERATOR_REPEAT_MONTH,
        name: 'period.3',
    },
    {
        id: SALES_GENERATOR_REPEAT_QUARTER,
        name: 'period.4',
    },
    {
        id: SALES_GENERATOR_REPEAT_HALF,
        name: 'period.5',
    },
    {
        id: SALES_GENERATOR_REPEAT_YEAR,
        name: 'period.6',
    },
]
