import * as actionTypes from './actionTypes'

// Fetch

export const fetchCarrierContacts = () => ({
    type: actionTypes.FETCH,
})

export const fetchCarrierContactsFulfilled = carrierContacts => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: carrierContacts,
})

export const fetchCarrierContactsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCarrierContactsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCarrierContact = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCarrierContactFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCarrierContactRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCarrierContactCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCarrierContact = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveCarrierContactFulfilled = carrier => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: carrier,
})

export const saveCarrierContactRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveCarrierContactDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteCarrierContact = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCarrierContactFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCarrierContactRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportCarrierContacts = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
