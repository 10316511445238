// Fetch list

export const FETCH = 'vehicle_positions.FETCH'
export const FETCH_STARTED = 'vehicle_positions.FETCH_STARTED'
export const FETCH_FULFILLED = 'vehicle_positions.FETCH_FULFILLED'
export const FETCH_REJECTED = 'vehicle_positions.FETCH_REJECTED'
export const FETCH_CANCELLED = 'vehicle_positions.FETCH_CANCELLED'

export const SORTING_CHANGED = 'vehicle_positions.SORTING_CHANGED'

// Delete

export const DELETE = 'vehicle_positions.DELETE'
export const DELETE_STARTED = 'vehicle_positions.DELETE_STARTED'
export const DELETE_FULFILLED = 'vehicle_positions.DELETE_FULFILLED'
export const DELETE_REJECTED = 'vehicle_positions.DELETE_REJECTED'
export const DELETE_CANCELLED = 'vehicle_positions.DELETE_CANCELLED'

