import React, { Component } from 'react'
import { is } from 'immutable'
import { injectIntl } from 'react-intl'

import ColoredBox from '../../../common/colored_box/components/ColoredBox'
import DaySpedition from './DaySpedition'
import WeekendAtHomeSpedition from './WeekendAtHomeSpedition'

class VehicleRowSpedition extends Component {
    handlePointNoteChange = transportPoint => {
        this.props.handlePointNoteChange(transportPoint)
    }

    shouldComponentUpdate(nextProps) {
        return !is(this.props.item, nextProps.item) || !is(this.props.days, nextProps.days)
    }

    render() {
        const item = this.props.item

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <tr>
                <td className="b-r w-350 board-vehicle">
                    <div>{item.carrier}</div>
                    <div className="m-t-xxs">{item.vehicle_registration && <ColoredBox>{item.vehicle_registration}</ColoredBox>}</div>
                </td>
                <td className="w-140 board-notes wrapped" />
                {this.props.days.map((date, dayKey) => {
                    const dayDate = date.format('YYYY-MM-DD')

                    return (
                        <React.Fragment key={`spedition-header-${dayDate}`}>
                            {date.weekday() === 5 && <WeekendAtHomeSpedition />}
                            <DaySpedition
                                key={`spedition-${this.props.id}-${dayDate}`}
                                date={date}
                                dayKey={dayKey}
                                vehicleID={item.id}
                                transportPoints={item.transport_points && item.transport_points[dayDate]}
                                handlePointNoteChange={this.handlePointNoteChange}
                                preferences={this.props.preferences}
                                handleTransportClick={this.props.handleTransportClick}
                            />
                        </React.Fragment>
                    )
                })}
            </tr>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

export default injectIntl(VehicleRowSpedition)
