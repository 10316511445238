import React from 'react'
import { Route, Switch } from 'react-router'
import CountriesList from './CountriesList'
import CountriesEdit from './CountriesEdit'

const Countries = props => (
    <Switch>
        <Route exact path={props.match.url} component={CountriesList} />
        <Route exact path={`${props.match.url}/new`} component={CountriesEdit} />
        <Route exact path={`${props.match.url}/:countryID`} component={CountriesEdit} />
    </Switch>
)

export default Countries
