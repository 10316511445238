// Fetch

export const FETCH = 'tracing.FETCH'
export const FETCH_STARTED = 'tracing.FETCH_STARTED'
export const FETCH_FULFILLED = 'tracing.FETCH_FULFILLED'
export const FETCH_REJECTED = 'tracing.FETCH_REJECTED'
export const FETCH_CANCELLED = 'tracing.FETCH_CANCELLED'

// Fetch vehicle

export const FETCH_VEHICLE = 'tracing.FETCH_VEHICLE'
export const FETCH_VEHICLE_STARTED = 'tracing.FETCH_VEHICLE_STARTED'
export const FETCH_VEHICLE_FULFILLED = 'tracing.FETCH_VEHICLE_FULFILLED'
export const FETCH_VEHICLE_REJECTED = 'tracing.FETCH_VEHICLE_REJECTED'
export const FETCH_VEHICLE_CANCELLED = 'tracing.FETCH_VEHICLE_CANCELLED'

// Fetch vehicles

export const FETCH_VEHICLES = 'tracing.FETCH_VEHICLES'
export const FETCH_VEHICLES_STARTED = 'tracing.FETCH_VEHICLES_STARTED'
export const FETCH_VEHICLES_FULFILLED = 'tracing.FETCH_VEHICLES_FULFILLED'
export const FETCH_VEHICLES_REJECTED = 'tracing.FETCH_VEHICLES_REJECTED'
export const FETCH_VEHICLES_CANCELLED = 'tracing.FETCH_VEHICLES_CANCELLED'

// Fetch gps

export const FETCH_GPS = 'tracing.FETCH_GPS'
export const FETCH_GPS_STARTED = 'tracing.FETCH_GPS_STARTED'
export const FETCH_GPS_FULFILLED = 'tracing.FETCH_GPS_FULFILLED'
export const FETCH_GPS_REJECTED = 'tracing.FETCH_GPS_REJECTED'
export const FETCH_GPS_CANCELLED = 'tracing.FETCH_GPS_CANCELLED'

// Fetch address

export const FETCH_ADDRESS = 'tracing.FETCH_ADDRESS'
export const FETCH_ADDRESS_STARTED = 'tracing.FETCH_ADDRESS_STARTED'
export const FETCH_ADDRESS_FULFILLED = 'tracing.FETCH_ADDRESS_FULFILLED'
export const FETCH_ADDRESS_REJECTED = 'tracing.FETCH_ADDRESS_REJECTED'
export const FETCH_ADDRESS_CANCELLED = 'tracing.FETCH_ADDRESS_CANCELLED'

// Clear

export const CLEAR = 'tracing.CLEAR'
export const CLEAR_ROUTE = 'tracing.CLEAR_ROUTE'
