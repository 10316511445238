import * as actionTypes from './actionTypes'

// Fetch

export const fetchCosts = () => ({
    type: actionTypes.FETCH,
})

export const fetchCostsFulfilled = costs => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: costs,
})

export const fetchCostsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCostsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCost = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCostFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCostRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCostCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCost = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveCostFulfilled = cost => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: cost,
})

export const saveCostRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteCost = (id, withFuture) => ({
    type: actionTypes.DELETE,
    payload: { id, withFuture },
})

export const deleteCostFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCostRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Clear

export const clearCosts = () => ({
    type: actionTypes.CLEAR,
})

// Fetch import headers

export const fetchCostsImportHeaders = file => ({
    type: actionTypes.FETCH_IMPORT_HEADERS,
    payload: file,
})

export const fetchCostsImportHeadersFulfilled = headers => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_FULFILLED,
    payload: headers,
})

export const fetchCostsImportHeadersRejected = message => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_REJECTED,
    payload: message,
})

export const fetchCostsImportHeadersCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_CANCELLED,
})

// Fetch import items

export const fetchCostsImportItems = (fileId, columnTypes, defaultDate, defaultCostTypeId) => ({
    type: actionTypes.FETCH_IMPORT_ITEMS,
    payload: {
        fileId,
        columnTypes,
        defaultDate,
        defaultCostTypeId
    },
})

export const fetchCostsImportItemsFulfilled = (items, defaultDate, defaultCostTypeId) => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_FULFILLED,
    payload: {
        items,
        defaultDate,
        defaultCostTypeId
    },
})

export const fetchCostsImportItemsRejected = message => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_REJECTED,
    payload: message,
})

export const fetchCostsImportItemsCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_CANCELLED,
})

// Import costs

export const importCosts = (fileId, columnTypes, defaultDate, defaultCostTypeId, keys) => ({
    type: actionTypes.IMPORT_COSTS,
    payload: {
        fileId,
        columnTypes,
        defaultDate,
        defaultCostTypeId,
        keys
    },
})

export const importCostsFulfilled = () => ({
    type: actionTypes.IMPORT_COSTS_FULFILLED,
})

export const importCostsRejected = message => ({
    type: actionTypes.IMPORT_COSTS_REJECTED,
    payload: message,
})

export const importCostsCancelled = () => ({
    type: actionTypes.IMPORT_COSTS_CANCELLED,
})
