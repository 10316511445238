// Fetch list

export const FETCH = 'sales.FETCH'
export const FETCH_STARTED = 'sales.FETCH_STARTED'
export const FETCH_FULFILLED = 'sales.FETCH_FULFILLED'
export const FETCH_REJECTED = 'sales.FETCH_REJECTED'
export const FETCH_CANCELLED = 'sales.FETCH_CANCELLED'

export const SORTING_CHANGED = 'sales.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'sales.FETCH_ONE'
export const FETCH_ONE_STARTED = 'sales.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'sales.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'sales.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'sales.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'sales.SAVE'
export const SAVE_STARTED = 'sales.SAVE_STARTED'
export const SAVE_FULFILLED = 'sales.SAVE_FULFILLED'
export const SAVE_REJECTED = 'sales.SAVE_REJECTED'
export const SAVE_CANCELLED = 'sales.SAVE_CANCELLED'

// Delete

export const DELETE = 'sales.DELETE'
export const DELETE_STARTED = 'sales.DELETE_STARTED'
export const DELETE_FULFILLED = 'sales.DELETE_FULFILLED'
export const DELETE_REJECTED = 'sales.DELETE_REJECTED'
export const DELETE_CANCELLED = 'sales.DELETE_CANCELLED'

// Clear

export const CLEAR = 'sales.CLEAR'

// Fetch import headers

export const FETCH_IMPORT_HEADERS = 'sales.FETCH_IMPORT_HEADERS'
export const FETCH_IMPORT_HEADERS_STARTED = 'sales.FETCH_IMPORT_HEADERS_STARTED'
export const FETCH_IMPORT_HEADERS_FULFILLED = 'sales.FETCH_IMPORT_HEADERS_FULFILLED'
export const FETCH_IMPORT_HEADERS_REJECTED = 'sales.FETCH_IMPORT_HEADERS_REJECTED'
export const FETCH_IMPORT_HEADERS_CANCELLED = 'sales.FETCH_IMPORT_HEADERS_CANCELLED'

// Fetch import values

export const FETCH_IMPORT_ITEMS = 'sales.FETCH_IMPORT_ITEMS'
export const FETCH_IMPORT_ITEMS_STARTED = 'sales.FETCH_IMPORT_ITEMS_STARTED'
export const FETCH_IMPORT_ITEMS_FULFILLED = 'sales.FETCH_IMPORT_ITEMS_FULFILLED'
export const FETCH_IMPORT_ITEMS_REJECTED = 'sales.FETCH_IMPORT_ITEMS_REJECTED'
export const FETCH_IMPORT_ITEMS_CANCELLED = 'sales.FETCH_IMPORT_ITEMS_CANCELLED'

// Import transports

export const IMPORT_SALES = 'sales.IMPORT_SALES'
export const IMPORT_SALES_STARTED = 'sales.IMPORT_SALES_STARTED'
export const IMPORT_SALES_FULFILLED = 'sales.IMPORT_SALES_FULFILLED'
export const IMPORT_SALES_REJECTED = 'sales.IMPORT_SALES_REJECTED'
export const IMPORT_SALES_CANCELLED = 'sales.IMPORT_SALES_CANCELLED'



// Set invoicing state

export const SET_INVOICING_STATE = 'sales.SET_INVOICING_STATE'
export const SET_INVOICING_STATE_STARTED = 'sales.SET_INVOICING_STATE_STARTED'
export const SET_INVOICING_STATE_FULFILLED = 'sales.SET_INVOICING_STATE_FULFILLED'
export const SET_INVOICING_STATE_REJECTED = 'sales.SET_INVOICING_STATE_REJECTED'
export const SET_INVOICING_STATE_CANCELLED = 'sales.SET_INVOICING_STATE_CANCELLED'

// Set invoicing state

export const FETCH_DATA_FOR_INVOICING = 'sales.FETCH_DATA_FOR_INVOICING'
export const FETCH_DATA_FOR_INVOICING_STARTED = 'sales.FETCH_DATA_FOR_INVOICING_STARTED'
export const FETCH_DATA_FOR_INVOICING_FULFILLED = 'sales.FETCH_DATA_FOR_INVOICING_FULFILLED'
export const FETCH_DATA_FOR_INVOICING_REJECTED = 'sales.FETCH_DATA_FOR_INVOICING_REJECTED'
export const FETCH_DATA_FOR_INVOICING_CANCELLED = 'sales.FETCH_DATA_FOR_INVOICING_CANCELLED'

// Create invoice

export const CREATE_INVOICE = 'sales.CREATE_INVOICE'
export const CREATE_INVOICE_STARTED = 'sales.CREATE_INVOICE_STARTED'
export const CREATE_INVOICE_FULFILLED = 'sales.CREATE_INVOICE_FULFILLED'
export const CREATE_INVOICE_REJECTED = 'sales.CREATE_INVOICE_REJECTED'
export const CREATE_INVOICE_CANCELLED = 'sales.CREATE_INVOICE_CANCELLED'
