import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportTrailerResults = (year, filters) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        filters
    },
})

export const fetchReportTrailerResultsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportTrailerResultsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportTrailerResultsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportTrailerResults = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportTrailerResults = (year, filters) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
    },
})

// Fetch details

export const fetchReportTrailerResultsDetails = (year, trailerId, month, filters) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        year,
        trailerId,
        month,
        filters,
    },
})

export const fetchReportTrailerResultsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportTrailerResultsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportTrailerResultsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportTrailerResultsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportTrailerResultsDetails = (year, trailerId, month, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        year,
        trailerId,
        month,
        filters,
    },
})
