import React from 'react'
import { Route, Switch } from 'react-router'
import CurrenciesList from './CurrenciesList'
import CurrenciesEdit from './CurrenciesEdit'

const Currencies = props => (
    <Switch>
        <Route exact path={props.match.url} component={CurrenciesList} />
        <Route exact path={`${props.match.url}/new`} component={CurrenciesEdit} />
        <Route exact path={`${props.match.url}/:currencyID`} component={CurrenciesEdit} />
    </Switch>
)

export default Currencies
