import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportVehicleKilometersTotal = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportVehicleKilometersTotalFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportVehicleKilometersTotalRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportVehicleKilometersTotalCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportVehicleKilometersTotal = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportVehicleKilometersTotal = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportVehicleKilometersTotalDetails = filters => ({
    type: actionTypes.FETCH_DETAILS,
    payload: filters,
})

export const fetchReportVehicleKilometersTotalDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportVehicleKilometersTotalDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportVehicleKilometersTotalDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear details

export const clearReportVehicleKilometersTotalDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export details

export const exportReportVehicleKilometersTotalDetails = filters => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: filters,
})
