import { Record } from 'immutable'

export class CustomerAddressType extends Record({
    id: undefined,
    name: undefined,
    icon_class: undefined,
    color: undefined,
    bg_color: undefined,
    is_deletable: undefined,
}) {}
