import { OrderedMap, fromJS } from 'immutable'
import store from '../store'
import moment from 'moment'

export const normalizedEntitiesToRecordMap = (normalized, recordClass, order) => {
    if (normalized === undefined) {
        return OrderedMap()
    }

    if (order === undefined) {
        order = Object.keys(normalized)
    }

    return OrderedMap(
        order
            .map(key => [key, fromJS(normalized[key], (tmpKey, value) => (tmpKey === '' ? new recordClass(value) : value))])
            .filter(item => item[1] !== undefined)
    )
}

export const mapNestedRecordsToRecordMap = (recordMap, nestedRecords, recordClass) =>
    recordMap.map(record => {
        Object.keys(nestedRecords).forEach(nestedKey => {
            record = record.update(nestedKey, values => {
                if (!values || !nestedRecords[nestedKey].data) {
                    return values
                }

                const nestedRecordClass = nestedRecords[nestedKey].recordClass
                if (typeof values === 'object') {
                    return values.map(
                        value =>
                            nestedRecordClass
                                ? new nestedRecordClass(nestedRecords[nestedKey].data.get(String(value)))
                                : nestedRecords[nestedKey].data.get(String(value))
                    )
                }

                return nestedRecordClass
                    ? new nestedRecordClass(nestedRecords[nestedKey].data.get(String(values)))
                    : nestedRecords[nestedKey].data.get(String(values))
            })
        })
        return recordClass ? new recordClass(record) : record
    })

export const url = (routerMatch, newPathPart) => `/${newPathPart}`

export const validationMessage = (validationType, options) => {
    const formatCharactersStrings = count => {
        if (count === 1) {
            return 'znak'
        } else if (count === 0 || count >= 5) {
            return 'znaků'
        }

        return 'znaky'
    }

    switch (validationType) {
        case 'isEmpty':
            return 'Údaj je povinný'
        case 'isLength':
            if (options.min === options.max) {
                return `Délka musí být přesně ${options.max} ${formatCharactersStrings(options.max)}`
            } else if (options.min === undefined) {
                return `Délka musí být maximálně ${options.max} ${formatCharactersStrings(options.max)}`
            } else if (options.max === undefined) {
                return `Délka musí být minimálně ${options.min} ${formatCharactersStrings(options.min)}`
            }

            return `Délka musí být od ${options.min} do ${options.max} znaků`

        case 'isFloat':
            return 'Údaj není číslo'
        case 'isInt':
            return 'Údaj není číslo'
        case 'isEmail':
            return 'Údaj není e-mail'
        default:
            return 'Údaj je chybný'
    }
}

export const handleCommonListActions = (
    props,
    nextProps,
    deletionNotificationProperties = {
        title: props.intl ? props.intl.formatMessage({ id: `alerts.titles.success` }) : 'OK.',
        message: props.intl ? props.intl.formatMessage({ id: `alerts.messages.deleteSuccess` }) : 'Item has been successfully deleted.',
        position: 'tc',
    },
    errorNotificationProperties = errorMessage => ({
        title: props.intl ? props.intl.formatMessage({ id: `alerts.titles.error` }) : 'Error',
        message: errorMessage,
        position: 'tc',
    })
) => {
    // Delete successful
    if (props.deleting && !nextProps.deleting && nextProps.deleteError === null) {
        props.notify(deletionNotificationProperties, 'success')
    }

    // Delete error
    if (props.deleting && !nextProps.deleting && nextProps.deleteError !== null) {
        props.notify(errorNotificationProperties(nextProps.deleteError), 'error')
    }
}

export const handleCommonEditActions = (
    props,
    nextProps,
    successCallback,
    duplicityFoundCallback,
    savingNotificationProperties = {
        title: props.intl ? props.intl.formatMessage({ id: `alerts.titles.success` }) : 'OK.',
        message: props.intl ? props.intl.formatMessage({ id: `alerts.messages.saveSuccess` }) : 'Item has been successfully saved.',
        position: 'tc',
    },
    errorNotificationProperties = errorMessage => ({
        title: props.intl ? props.intl.formatMessage({ id: `alerts.titles.error` }) : 'Error',
        message: errorMessage,
        position: 'tc',
    })
) => {
    if (props.status.get('saving') && !nextProps.status.get('saving')) {
        if (nextProps.status.get('error') === null && !(nextProps.duplicity || nextProps.duplicities)) {
            // Save successful
            props.notify(savingNotificationProperties, 'success')

            successCallback && successCallback(props, nextProps)
        } else if (nextProps.status.get('error') === null && (nextProps.duplicity || nextProps.duplicities)) {
            // Duplicity found
            duplicityFoundCallback && duplicityFoundCallback(nextProps.duplicity || nextProps.duplicities)
        } else {
            // Save error
            let error = nextProps.status.get('error')

            if (error !== null && typeof error === 'object') {
                error = Object.entries(error).reduce((accumulator, entry) => {
                    const thisError = entry[1]

                    if (accumulator === null) {
                        return thisError
                    }

                    return `${accumulator} ${thisError}`
                }, null)
            }

            props.notify(errorNotificationProperties(error), 'error')
        }
    }
}

export const getListItemsCountMessage = (clientSideItemsPerPage, clientSideItemsCount, serverSideItemsCount) => {
    let count = clientSideItemsPerPage ? clientSideItemsCount : serverSideItemsCount

    if (count === undefined) {
        count = 0
    }

    if (count === 1) {
        return `${count}`
    } else if (count === 0 || count >= 5) {
        return `${count}`
    }

    return `${count}`
}

export const getNumericalSortedMap = (map, by) =>
    map
        .sort((a, b) => {
            if (a.get(by) < b.get(by)) {
                return -1
            }
            if (a.get(by) > b.get(by)) {
                return 1
            }
            return 0
        })
        .toMap()

export const getAlphabeticalSortedMap = (map, by) => map.sort((a, b) => a.get(by).localeCompare(b.get(by))).toMap()

export const getPrerequisitesArray = (map, key = 'id', val = 'name', intl = null, intlPrefix = '') =>
    map &&
    map
        .valueSeq()
        .map(value => ({
            id: value.get(key),
            name: intl ? intl.formatMessage({ id: `${intlPrefix}${value.get(val)}` }) : value.get(val),
        }))
        .toArray()

export const getPrerequisitesPairs = (map, key = 'id', val = 'name') => {
    const pairs = {}
    map &&
        map.valueSeq().forEach(value => {
            pairs[value.get(key)] = val ? value.get(val) : value
        })

    return pairs
}

export const getPrerequisitesValues = (map, val = 'name', intl = null, intlPrefix = '') =>
    map &&
    map
        .valueSeq()
        .map(value => ({
            value: intl ? intl.formatMessage({ id: `${intlPrefix}${value.get(val)}` }) : value.get(val),
        }))
        .toArray()

export const getFirstExistValue = values => values.find(value => value)

export const getFloatVal = value => (isNaN(parseFloat(String(value).replace(',', '.').replace(/\s+/g, ''))) ? 0 : parseFloat(String(value).replace(',', '.').replace(/\s+/g, '')))

export const convertISODatetimeToMoment = value => moment(value)

export const convertCSDatetimeToMoment = value => {
    const momentDatetime = moment(value, 'DD.MM.YYYY HH:mm')

    return momentDatetime.isValid() ? momentDatetime : null
}

export const convertCSDatetimeToISO = value => {
    const momentDatetime = moment(value, 'DD.MM.YYYY HH:mm')

    return momentDatetime.isValid() ? momentDatetime.format('YYYY-MM-DD HH:mm') : null
}

export const convertISODatetimeToCS = value => {
    const momentDatetime = moment(value)

    return momentDatetime.isValid() ? momentDatetime.format('DD.MM.YYYY HH:mm') : null
}

export const convertCSDateToISO = value => {
    const momentDatetime = moment(value, 'DD.MM.YYYY')

    return momentDatetime.isValid() ? momentDatetime.format('YYYY-MM-DD') : null
}

export const convertISODateToCS = value => {
    const momentDatetime = moment(value)

    return momentDatetime.isValid() ? momentDatetime.format('DD.MM.YYYY') : null
}

export const convertCSDateToMoment = value => {
    const momentDatetime = moment(value, 'DD.MM.YYYY')

    return momentDatetime.isValid() ? momentDatetime : null
}
export const convertISODateToMoment = value => {
    const momentDatetime = moment(value, 'YYYY-MM-DD')

    return momentDatetime.isValid() ? momentDatetime : null
}

export const getNormalizedString = value =>
    String(value)
        .toLocaleLowerCase()
        .split(' ')
        .join('')

export const getTransportPointPlannedDatetimeType = transportPoint => {
    if (!transportPoint) {
        return null
    }

    if (transportPoint.datetime_booking || transportPoint.datetime_booking_from) {
        return 'B'
    } else if (transportPoint.datetime_specified || transportPoint.datetime_specified_from) {
        return 'S'
    } else if (transportPoint.datetime_planned || transportPoint.datetime_planned_from) {
        return 'P'
    }

    return ''
}

export const getTransportPointPlannedDatetimeFrom = (transportPoint, returnAsMoment = false, datetimeFormat = 'YYYY-MM-DD HH:mm:SS') => {
    if (!transportPoint) {
        return null
    }

    const datetime = getFirstExistValue([
        transportPoint.datetime_booking_from,
        transportPoint.datetime_specified_from,
        transportPoint.datetime_planned_from,
    ])

    if (!datetime) {
        return null
    }

    return returnAsMoment ? moment(datetime, datetimeFormat) : datetime
}

export const getTransportPointPlannedDatetimeTo = (transportPoint, returnAsMoment = false, datetimeFormat = 'YYYY-MM-DD HH:mm:SS') => {
    if (!transportPoint) {
        return null
    }

    const datetime = getFirstExistValue([
        transportPoint.datetime_booking_to || transportPoint.datetime_booking_from,
        transportPoint.datetime_specified_to || transportPoint.datetime_specified_from,
        transportPoint.datetime_planned_to || transportPoint.datetime_planned_from,
    ])

    if (!datetime) {
        return null
    }

    return returnAsMoment ? moment(datetime, datetimeFormat) : datetime
}

export const getTransportPointPlannedDatetimes = (transportPoint, returnAsMoment = false, datetimeFormat = 'YYYY-MM-DD HH:mm:SS') => {
    if (!transportPoint) {
        return null
    }

    return {
        from: getTransportPointPlannedDatetimeFrom(transportPoint, returnAsMoment, datetimeFormat),
        to: getTransportPointPlannedDatetimeTo(transportPoint, returnAsMoment, datetimeFormat),
    }
}

export const getShortFullName = (firstName, lastName) => {
    const parts = []
    lastName && parts.push(lastName)
    firstName && parts.push(`${firstName.substring(0, 1)}.`)

    return parts.join(' ')
}

export const hasAnyPermission = permissions => {
    const state = store.getState()
    const userPermissions = state.auth.get('permissions')

    if (!userPermissions || !permissions) {
        return false
    }

    const permissionsIntersect = userPermissions.filter(x => permissions.includes(x))
    return permissionsIntersect.size > 0
}

export const isReadOnlyPermission = permissions => {
    const state = store.getState()
    const userPermissions = state.auth.get('permissions')

    if (!userPermissions || !permissions) {
        return false
    }

    const permissionsIntersect = userPermissions.filter(x => permissions.includes(x))
    return permissionsIntersect.size === 1 && permissionsIntersect.first().split('.')[1] === 'read'
}

export const isDisableField = (isEdit, permissions) => {
    const state = store.getState()
    const userPermissions = state.auth.get('permissions')

    if (!userPermissions || !permissions) {
        return true
    }

    const permissionsIntersect = userPermissions.filter(x => permissions.includes(x)).map(x => x.split('.')[1])

    if (isEdit && permissionsIntersect.includes('update')) {
        return false
    } else if (!isEdit && permissionsIntersect.includes('create')) {
        return false
    }
    return true
}

export const degreesToRadians = degrees => {
    return degrees * (Math.PI / 180)
}

export const getGpsDistance = (lat1, lon1, lat2, lon2) => {
    const dLat = degreesToRadians(lat2 - lat1)
    const dLon = degreesToRadians(lon2 - lon1)

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    const d = 6371 * c

    return d
}
