import * as actionTypes from './actionTypes'

// Fetch

export const fetchPackagingTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchPackagingTypesFulfilled = packagingTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: packagingTypes,
})

export const fetchPackagingTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchPackagingTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchPackagingType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchPackagingTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchPackagingTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchPackagingTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const savePackagingType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const savePackagingTypeFulfilled = packagingType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: packagingType,
})

export const savePackagingTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deletePackagingType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deletePackagingTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deletePackagingTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportPackagingTypes = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
