import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Modal } from 'react-bootstrap'
// eslint-disable-next-line import/default
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import fontawesome from '@fortawesome/fontawesome'
import regular from '@fortawesome/fontawesome-free-regular'
import solid from '@fortawesome/fontawesome-free-solid'

import Field from './Field'

fontawesome.library.add(regular, solid)

// eslint-disable-next-line react/prefer-stateless-function
class FontawesomePicker extends Component {
    state = {
        showModal: false,
        search: '',
    }

    showModal = event => {
        event && event.preventDefault()
        event && event.stopPropagation()
        this.setState({ showModal: true })
    }

    hideModal = event => {
        event && event.preventDefault()
        event && event.stopPropagation()
        this.setState({ showModal: false })
    }

    handleSearch = event => {
        this.setState({ search: event.target.value })
    }

    handleClick = value => {
        this.props.onChange(`${value.prefix} fa-${value.iconName}`)
        this.hideModal()
    }

    render() {
        let icons = Object.values({ ...regular, ...solid })
        if (this.state.search !== '') {
            icons = icons.filter(icon => icon.iconName.includes(this.state.search))
        }
        let value

        if (this.props.hasOwnProperty('getValue')) {
            value = String(this.props.getValue() ? this.props.getValue() : this.props.defaultValue)
        } else {
            value = String(this.props.value ? this.props.value : this.props.defaultValue)
        }

        return (
            <React.Fragment>
                <Field {...this.props}>
                    <div className="input-group m-b-sm">
                        <span className="input-group-btn">
                            <button
                                className="btn btn-default"
                                style={{
                                    opacity: 1,
                                    color: this.props.iconColor ? this.props.iconColor : '#000000',
                                    'background-color': this.props.bgColor ? this.props.bgColor : '#FFFFFF',
                                }}
                                disabled
                            >
                                <i className={value} />
                            </button>
                        </span>
                        <input value={value} className={`form-control`} disabled />
                        <span className="input-group-btn">
                            <button className="btn btn-default" onClick={this.showModal} disabled={this.props.readonly()}>
                                <i className="far fa-search" />
                            </button>
                        </span>
                    </div>
                </Field>

                <Modal show={this.state.showModal} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="fields.icon" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <input placeholder={'hledat'} value={this.state.search} onChange={this.handleSearch} className={'form-control m-b-lg'} />
                            {icons.map((icon, key) => (
                                <button key={key} onClick={() => this.handleClick(icon)}>
                                    <FontAwesomeIcon icon={[icon.prefix, icon.iconName]} size="2x" fixedWidth />
                                </button>
                            ))}
                        </React.Fragment>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

export default injectIntl(FontawesomePicker)
