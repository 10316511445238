import React from 'react'
import TopLine from './TopLine'
import TopMenu from './TopMenu'

import './assets/css/Header.css'

const Menus = props => (
    <div>
        <TopLine />
        <TopMenu />
        {props.children}
    </div>
)

export default Menus
