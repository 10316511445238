import React from 'react'
import { Route, Switch } from 'react-router'
import InvoicingReceivedInvoices from './InvoicingReceivedInvoices'
import InvoicingReceivedInvoicesEdit from './InvoicingReceivedInvoicesEdit'

const Components = props => (
    <Switch>
        <Route exact path={props.match.url} component={InvoicingReceivedInvoices} />
        <Route exact path={`${props.match.url}/new`} component={InvoicingReceivedInvoicesEdit} />
        <Route exact path={`${props.match.url}/:receivedInvoiceID`} component={InvoicingReceivedInvoicesEdit} />
        <Route exact path={`${props.match.url}/:receivedInvoiceID/credit-note`} component={InvoicingReceivedInvoicesEdit} />
    </Switch>
)

export default Components
