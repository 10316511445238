import { createSelector } from 'reselect'

export const getCustomerAddressTypes = state => state.customerAddressTypes.getIn(['all', 'objects'])

export const createGetCustomerAddressTypeByIDSelector = id =>
    createSelector([getCustomerAddressTypes], customerAddressTypes => customerAddressTypes.get(String(id)))

const getCurrentCustomerAddressTypeStatus = state => state.customerAddressTypes.get('current')

export const createGetCurrentCustomerAddressTypeStatusSelector = () => createSelector([getCurrentCustomerAddressTypeStatus], status => status)

export const getCustomerAddressTypeDeleting = state => state.customerAddressTypes.getIn(['deletion', 'inProgress'])

export const getCustomerAddressTypeDeleteError = state => state.customerAddressTypes.getIn(['deletion', 'error'])
