import { createSelector } from 'reselect'

export const getCargoTypes = state => state.cargoTypes.getIn(['all', 'objects'])

export const createGetCargoTypeByIDSelector = id => createSelector([getCargoTypes], cargoTypes => cargoTypes.get(String(id)))

const getCurrentCargoTypeStatus = state => state.cargoTypes.get('current')

export const createGetCurrentCargoTypeStatusSelector = () => createSelector([getCurrentCargoTypeStatus], status => status)

export const getCargoTypeDeleting = state => state.cargoTypes.getIn(['deletion', 'inProgress'])

export const getCargoTypeDeleteError = state => state.cargoTypes.getIn(['deletion', 'error'])
