export const PERMS = {
    READ: 'customers.read',
    UPDATE: 'customers.update',
    DELETE: 'customers.delete',
    RESTORE: 'customers.restore',
    CREATE: 'customers.create',
    EXPORT: 'customers.export',
}

export const PERMS_ADDRESSES = {
    READ: 'customer_addresses.read',
    UPDATE: 'customer_addresses.update',
    DELETE: 'customer_addresses.delete',
    CREATE: 'customer_addresses.create',
    EXPORT: 'customer_addresses.export',
}

export const PERMS_CONTACTS = {
    READ: 'customer_contacts.read',
    UPDATE: 'customer_contacts.update',
    DELETE: 'customer_contacts.delete',
    CREATE: 'customer_contacts.create',
    EXPORT: 'customer_contacts.export',
}

export const PERMS_PLANS = {
    READ: 'customer_plans.read',
    UPDATE: 'customer_plans.update',
    DELETE: 'customer_plans.delete',
    CREATE: 'customer_plans.create',
    EXPORT: 'customer_plans.export',
}
