// Fetch list

export const FETCH = 'vehicleEvents.FETCH'
export const FETCH_FOR_MAP = 'vehicleEvents.FETCH_FOR_MAP'
export const FETCH_STARTED = 'vehicleEvents.FETCH_STARTED'
export const FETCH_FULFILLED = 'vehicleEvents.FETCH_FULFILLED'
export const FETCH_REJECTED = 'vehicleEvents.FETCH_REJECTED'
export const FETCH_CANCELLED = 'vehicleEvents.FETCH_CANCELLED'

export const SORTING_CHANGED = 'vehicleEvents.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'vehicleEvents.FETCH_ONE'
export const FETCH_ONE_FOR_MAP = 'vehicleEvents.FETCH_ONE_FOR_MAP'
export const FETCH_ONE_STARTED = 'vehicleEvents.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'vehicleEvents.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'vehicleEvents.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'vehicleEvents.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'vehicleEvents.SAVE'
export const SAVE_STARTED = 'vehicleEvents.SAVE_STARTED'
export const SAVE_FULFILLED = 'vehicleEvents.SAVE_FULFILLED'
export const SAVE_REJECTED = 'vehicleEvents.SAVE_REJECTED'
export const SAVE_CANCELLED = 'vehicleEvents.SAVE_CANCELLED'

// Delete

export const DELETE = 'vehicleEvents.DELETE'
export const DELETE_STARTED = 'vehicleEvents.DELETE_STARTED'
export const DELETE_FULFILLED = 'vehicleEvents.DELETE_FULFILLED'
export const DELETE_REJECTED = 'vehicleEvents.DELETE_REJECTED'
export const DELETE_CANCELLED = 'vehicleEvents.DELETE_CANCELLED'

// Clear

export const CLEAR = 'vehicleEvents.CLEAR'
