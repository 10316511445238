import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportSpeditionTransports = filters => ({
    type: actionTypes.FETCH,
    payload: {
        filters,
    },
})

export const fetchReportSpeditionTransportsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: {
        data,
    },
})

export const fetchReportSpeditionTransportsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportSpeditionTransportsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportSpeditionTransports = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportSpeditionTransports = filters => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
    },
})

// Export details

export const exportReportSpeditionTransportsDetails = filters => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        filters,
    },
})
