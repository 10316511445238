import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as config from '../../../common/config'
import * as formatters from '../../../common/formatters'

import LoginForm from './LoginForm'
import ForgottenPasswordForm from './ForgottenPasswordForm'
import SetPasswordForm from './SetPasswordForm'

class Login extends Component {
    componentDidMount() {
        document.body.classList.add('page-login')
    }

    componentWillUnmount() {
        document.body.classList.remove('page-login')
    }

    render() {
        document.title = formatters.titleFormatter(config.CFG_APP_NAME)

        return (
            <div
                className="login-container"
                style={{
                    backgroundImage: `url(/images/${config.CFG_APP_BACKGROUND_IMAGE})`,
                }}
            >
                <div className="login-overlay" />
                <div className="row" style={{ marginLeft: '0', marginRight: '0' }}>
                    <div className="col-md-3 center">
                        <div className="login-box">
                            <div className="login-box-overlay" />
                            <div className="login-box-inner">
                                <h1>{config.CFG_APP_NAME}</h1>
                                <h2>
                                    <FormattedMessage id="login.title" /> {config.CFG_VERSION}
                                </h2>

                                <Switch>
                                    <Route exact path={`${this.props.match.url}/forgotten-password-form`} component={ForgottenPasswordForm} />
                                    <Route exact path={`${this.props.match.url}/forgotten-password-form/:token`} component={SetPasswordForm} />
                                    <Route component={LoginForm} />
                                </Switch>

                                <p className="text-center m-t-xs text-sm">
                                    {new Date().getFullYear()} &copy; <FormattedMessage id="login.copyright" />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(Login)
