import { createSelector } from 'reselect'

export const getWarehousingItems = state => state.warehousingItems.getIn(['all', 'objects'])
export const createGetWarehousingItemByIDSelector = id => createSelector([getWarehousingItems], items => items.get(String(id)))

const getCurrentWarehousingItemStatus = state => state.warehousingItems.get('current')
export const createGetCurrentWarehousingItemStatusSelector = () => createSelector([getCurrentWarehousingItemStatus], status => status)

export const getWarehousingItemDeleting = state => state.warehousingItems.getIn(['deletion', 'inProgress'])
export const getWarehousingItemDeleteError = state => state.warehousingItems.getIn(['deletion', 'error'])

export const getWarehousingItemReceiptsData = state => state.warehousingItems.getIn(['receipts', 'data'])
export const getWarehousingItemReceiptsFetching = state => state.warehousingItems.getIn(['receipts', 'fetching'])
export const getWarehousingItemReceiptsError = state => state.warehousingItems.getIn(['receipts', 'error'])