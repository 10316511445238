import React from 'react'
import { Route, Switch } from 'react-router'
import SurchargeTypesList from './SurchargeTypesList'
import SurchargeTypesEdit from './SurchargeTypesEdit'

const SurchargeTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={SurchargeTypesList} />
        <Route exact path={`${props.match.url}/new`} component={SurchargeTypesEdit} />
        <Route exact path={`${props.match.url}/:surchargeTypeID`} component={SurchargeTypesEdit} />
    </Switch>
)

export default SurchargeTypes
