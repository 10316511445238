import React from 'react'
import { Route, Switch } from 'react-router'
import TransportTypesList from './TransportTypesList'
import TransportTypesEdit from './TransportTypesEdit'

const TransportTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={TransportTypesList} />
        <Route exact path={`${props.match.url}/new`} component={TransportTypesEdit} />
        <Route exact path={`${props.match.url}/:transportTypeID`} component={TransportTypesEdit} />
    </Switch>
)

export default TransportTypes
