import React, { Component } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as constants from '../constants'
import * as formatters from '../../../common/formatters'

// eslint-disable-next-line react/prefer-stateless-function
class TransportListRowPointIcon extends Component {
    render = () => {
        const { transport, point, createGetCountryByIDSelector, createGetTransportPointStateByIDSelector } = this.props

        const icons = constants.TRANSPORT_POINT_STATES_DEFINITION

        const country = createGetCountryByIDSelector(point.country_id)
        const type = constants.TRANSPORT_POINT_TYPES_DEFINITION[point.type_id]
        const state = createGetTransportPointStateByIDSelector(point.transport_point_state_id)

        const datetimeFrom = point.getPlannedDatetimeFrom()
        const datetimeTo = point.getPlannedDatetimeTo()
        const arrival = point.datetime_arrival
        const eta =
            (!point.transport_point_state_id || point.transport_point_state_id === constants.TRANSPORT_POINT_STATE_ON_WAY) &&
            point.last_eta_update &&
            point.last_eta_update.datetime_real_eta

        let name = type ? this.props.intl.formatMessage({ id: type.name }) : ''
        let color = type ? type.rgb_bg : '#cccccc'

        if (point.transport_id !== transport.id) {
            name = `${this.props.intl.formatMessage({ id: 'fields.approachFormTransportNumber' })} ${point.transport_id}`
            color = '#999999'
        }

        const tooltip = (
            <Tooltip id="tooltip">
                <strong>{name}</strong>
                <br />
                {point.city && (
                    <span>
                        {country && country.iso_code} {`${point.zipcode} ${point.city}`}
                    </span>
                )}
                <br />
                {formatters.datetimePeriodFormatter(datetimeFrom, datetimeTo)}
                <br />
                {arrival ? (
                    <span>
                        <FormattedMessage id="fields.arrival" />: {formatters.datetimeFormatter(arrival)}
                    </span>
                ) : (
                    <span>
                        <FormattedMessage id="fields.eta" />:{' '}
                        {eta ? (
                            <span>
                                {formatters.datetimeFormatter(eta)}
                                {point.last_eta_update.distance && ` (${formatters.distanceFormatter(point.last_eta_update.distance / 1000)})`}
                            </span>
                        ) : (
                            '-'
                        )}
                    </span>
                )}
                <br />
                {state ? this.props.intl.formatMessage({ id: `transportPointStates.${state.name}` }) : '-'}
            </Tooltip>
        )

        const className =
            point.transport_point_state_id && icons[point.transport_point_state_id]
                ? icons[point.transport_point_state_id].className
                : 'far fa-circle'

        return (
            <OverlayTrigger placement="top" overlay={tooltip}>
                <i className={`${className} transport-point-type-icon`} style={{ color: `${color}` }} />
            </OverlayTrigger>
        )
    }
}

export default injectIntl(TransportListRowPointIcon)
