import React from 'react'
import { Route, Switch } from 'react-router'
import CostTypesList from './CostTypesList'
import CostTypesEdit from './CostTypesEdit'

const CostTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={CostTypesList} />
        <Route exact path={`${props.match.url}/new`} component={CostTypesEdit} />
        <Route exact path={`${props.match.url}/:costTypeID`} component={CostTypesEdit} />
    </Switch>
)

export default CostTypes
