import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError } from '../../common/api'
import { transportEventSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap, mapNestedRecordsToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { TransportEvent, TransportPointFile, TransportEventData, TransportPoint } from '../transports/model'
import { Vehicle } from '../vehicles/model'
import * as vehiclesActionCreators from '../vehicles/actionCreators'
import { Driver } from '../drivers/model'
import * as driversActionCreators from '../drivers/actionCreators'
import { Trailer } from '../trailers/model'
import * as trailersActionCreators from '../trailers/actionCreators'
import { Country } from '../countries/model'
import * as countriesActionCreators from '../countries/actionCreators'

// Fetch

const fetchReportVehicleChecksEpic = (action$, store) => {
    const tableIdentifier = 'report_vehicle_checks_list'

    return action$
        .ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'POST',
                path: `report-vehicle-checks?page=${modelState.getIn(['pagination', 'current']) + 1}`,
                body: {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response.data, new schema.Array(transportEventSchema))

                        let transportEvents = normalizedEntitiesToRecordMap(
                            normalizedData.entities.transport_events,
                            TransportEvent,
                            normalizedData.result
                        )

                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                        const drivers = normalizedEntitiesToRecordMap(normalizedData.entities.drivers, Driver)
                        const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                        const transportPoints = normalizedEntitiesToRecordMap(normalizedData.entities.transport_points, TransportPoint)
                        const transportPointFiles = normalizedEntitiesToRecordMap(normalizedData.entities.transport_point_files, TransportPointFile)
                        const transportEventData = normalizedEntitiesToRecordMap(normalizedData.entities.transport_event_data, TransportEventData)

                        transportEvents = mapNestedRecordsToRecordMap(
                            transportEvents,
                            {
                                transport_point: { data: transportPoints, recordClass: TransportPoint },
                                transport_point_files: { data: transportPointFiles, recordClass: TransportPointFile },
                                transport_event_data: { data: transportEventData, recordClass: TransportEventData },
                            },
                            TransportEvent
                        )

                        const observables = [
                            Observable.of(actionCreators.fetchReportVehicleChecksFulfilled(transportEvents)),
                            Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                            Observable.of(driversActionCreators.fetchDriversFulfilled(drivers)),
                            Observable.of(trailersActionCreators.fetchTrailersFulfilled(trailers)),
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(
                                tableModelActionCreators.updatePagination(
                                    tableIdentifier,
                                    ajaxResponse.response.last_page,
                                    ajaxResponse.response.current_page - 1,
                                    ajaxResponse.response.total
                                )
                            ),
                        ]

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchReportVehicleChecksRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

export default [fetchReportVehicleChecksEpic]
