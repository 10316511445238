import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { Modal } from 'react-bootstrap'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import { getListItemsCountMessage, getPrerequisitesArray, isReadOnlyPermission } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as companiesActionCreators from '../actionCreators'
import { getCompanyBankAccountsData, getCompanyBankAccountsFetching, getDefaultBankAccountsSaving, getDefaultBankAccountsError } from '../selectors'

import { deleteCompanyBankAccount } from '../../company_bank_accounts/actionCreators'
import { getCompanyBankAccountDeleting } from '../../company_bank_accounts/selectors'

import { getPermissions } from '../../auth/selectors'

import CompanyBankAccountsEditForm from '../../company_bank_accounts/components/CompanyBankAccountsEditForm'
import SelectField from '../../../common/form/components/SelectField'
import CompaniesBankAccountsListRow from './CompaniesBankAccountsListRow'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_BANK_ACCOUNTS, PERMS_DEFAULT_BANK_ACCOUNTS } from '../permissions'

const componentIdentifier = 'companies_bank_accounts_list'
const prerequisites = ['currencies']

class CompaniesBankAccountsList extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalBankAccount: {
            show: false,
            item: null,
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // handles
    onClick = item => {
        this.setState({
            modalBankAccount: {
                show: true,
                item,
            },
        })
    }

    onDelete = item => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteCompanyBankAccount(item.id)
                this.hideConfirmDialog()
            },
        })
    }

    onPageChange = page => {
        this.props.actions.fetchCompanyBankAccounts(this.props.company.id, page.selected)
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportCompanyBankAccounts(this.props.company.id)
    }

    handleCreateItem = e => {
        e && e.preventDefault()
        this.setState({
            modalBankAccount: {
                show: true,
                item: null,
            },
        })
    }

    // create modal
    modalBankAccountClose = () => {
        this.setState({
            modalBankAccount: {
                show: false,
                item: null,
            },
        })
    }

    // default accounts
    saveDefaultBankAccount = (isForDomestic, currencyId, isFactoring, value) => {
        this.props.actions.saveDefaultBankAccount(this.props.company.id, {
            is_for_domestic: isForDomestic,
            currency_id: currencyId,
            is_factoring: isFactoring,
            company_bank_account_id: value.value,
        })
    }

    // callbacks
    editSuccessCallback = () => {
        this.modalBankAccountClose()
        this.refresh()
    }

    editBackCallback = () => {
        this.modalBankAccountClose()
    }

    refresh = () => {
        this.props.actions.fetchCompanyBankAccounts(this.props.company.id)
    }

    componentDidMount() {
        if (this.props.company) {
            this.props.actions.fetchCompanyBankAccounts(this.props.company.id)
        }

        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.company && JSON.stringify(nextProps.company) !== JSON.stringify(this.props.company)) {
            this.props.actions.fetchCompanyBankAccounts(nextProps.company.id)
        }

        if (this.props.deleting && !nextProps.deleting) {
            this.refresh()
        }
    }

    render() {
        const data = this.props.data

        const rows = data && data.data
        const page = data && data.page ? data.page : 0
        const total = data && data.total ? data.total : 0
        const defaultBankAccounts = data && data.defaultBankAccounts

        const defaultBankAccountsReadonly = isReadOnlyPermission(Object.values(PERMS_DEFAULT_BANK_ACCOUNTS))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_BANK_ACCOUNTS)} noPermissionsTab>
                <LoadingOverlay active={this.props.fetching || this.props.deleting || this.props.defaultBankAccountsSaving}>
                    <div className="btns-list">
                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                        </button>
                        {rows && rows.length > 0 && (
                            <PermissionsCheck has={[PERMS_BANK_ACCOUNTS.EXPORT]}>
                                <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExport}>
                                    <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                </button>
                            </PermissionsCheck>
                        )}
                        <PermissionsCheck has={[PERMS_BANK_ACCOUNTS.CREATE]}>
                            <button className="btn btn-addon btn-success" onClick={this.handleCreateItem}>
                                <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                            </button>
                        </PermissionsCheck>
                    </div>

                    {rows && rows.length > 0 ? (
                        <div className="clearfix">
                            <div className="table-container table-container-static">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <PermissionsCheck hasAny={[PERMS_BANK_ACCOUNTS.UPDATE, PERMS_BANK_ACCOUNTS.DELETE]}>
                                                <th className="w-60" />
                                            </PermissionsCheck>
                                            <th className="w-180">
                                                <FormattedMessage id="fields.name" />
                                            </th>
                                            <th className="w-180">
                                                <FormattedMessage id="fields.bankAccountNumber" />
                                            </th>
                                            <th className="w-200">
                                                <FormattedMessage id="fields.bankAccountIBAN" />
                                            </th>
                                            <th className="w-100">
                                                <FormattedMessage id="fields.bankAccountSWIFT" />
                                            </th>
                                            <th className="w-40 text-center">
                                                <FormattedMessage id="fields.currency" />
                                            </th>
                                            <th className="w-80 text-center">
                                                <FormattedMessage id="fields.factoring" />
                                            </th>
                                            <th className="w-max wm-200">
                                                <FormattedMessage id="fields.note" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows &&
                                            rows.map((item, index) => (
                                                <CompaniesBankAccountsListRow
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={`row-${index}`}
                                                    item={item}
                                                    onClick={this.onClick}
                                                    onDelete={this.onDelete}
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="pull-left m-l-xxs m-t-md">
                                <FormattedMessage id="pagination.totalRecords" />: {getListItemsCountMessage(0, 0, total)}
                            </div>

                            <ReactPaginate
                                containerClassName="pagination"
                                breakLabel={<span className="disabled">...</span>}
                                activeClassName="active"
                                pageCount={Math.ceil(total / 50)}
                                pageRangeDisplayed={10}
                                marginPagesDisplayed={2}
                                forcePage={page}
                                onPageChange={this.onPageChange}
                                previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                            />
                        </div>
                    ) : (
                        <div className="alert alert-danger pull-left wp-100">
                            <FormattedMessage id="fields.noItemsFound" />
                        </div>
                    )}

                    <PermissionsCheck hasAny={Object.values(PERMS_DEFAULT_BANK_ACCOUNTS)}>
                        <hr />

                        <div>
                            <h4 className="wp-100">
                                <FormattedMessage id="fields.defaultBankAccountsSettings" /> - <FormattedMessage id="fields.domesticCustomers" />
                            </h4>
                            {getPrerequisitesArray(this.props.prerequisites.values.get('currencies'), 'id', 'iso_code').map(currency => {
                                const options = []
                                const optionsFactoring = []

                                rows &&
                                    rows.forEach(account => {
                                        if (account.currency && account.currency.id === currency.id) {
                                            if (account.is_factoring) {
                                                optionsFactoring.push({ id: account.id, name: account.name })
                                            } else {
                                                options.push({ id: account.id, name: account.name })
                                            }
                                        }
                                    })

                                return (
                                    <div key={`currency-${currency.id}`} className="row">
                                        <div className="col-sm-3">
                                            <SelectField
                                                values={options}
                                                label={currency.name}
                                                onChange={value => this.saveDefaultBankAccount(1, currency.id, 0, value)}
                                                value={
                                                    defaultBankAccounts &&
                                                    defaultBankAccounts[1] &&
                                                    defaultBankAccounts[1][currency.id] &&
                                                    defaultBankAccounts[1][currency.id][2]
                                                }
                                                nullable
                                                readonly={() => defaultBankAccountsReadonly}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <SelectField
                                                values={optionsFactoring}
                                                label={`${currency.name} - ${this.props.intl.formatMessage({ id: 'fields.factoring' })}`}
                                                onChange={value => this.saveDefaultBankAccount(1, currency.id, 1, value)}
                                                value={
                                                    defaultBankAccounts &&
                                                    defaultBankAccounts[1] &&
                                                    defaultBankAccounts[1][currency.id] &&
                                                    defaultBankAccounts[1][currency.id][1]
                                                }
                                                nullable
                                                readonly={() => defaultBankAccountsReadonly}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div>
                            <h4 className="wp-100">
                                <FormattedMessage id="fields.defaultBankAccountsSettings" /> -{' '}
                                <FormattedMessage id="fields.foreignCountryCustomers" />
                            </h4>
                            {getPrerequisitesArray(this.props.prerequisites.values.get('currencies'), 'id', 'iso_code').map(currency => {
                                const options = []
                                const optionsFactoring = []

                                rows &&
                                    rows.forEach(account => {
                                        if (account.currency && account.currency.id === currency.id) {
                                            if (account.is_factoring) {
                                                optionsFactoring.push({ id: account.id, name: account.name })
                                            } else {
                                                options.push({ id: account.id, name: account.name })
                                            }
                                        }
                                    })

                                return (
                                    <div key={`currency-${currency.id}`} className="row">
                                        <div className="col-sm-3">
                                            <SelectField
                                                values={options}
                                                label={currency.name}
                                                onChange={value => this.saveDefaultBankAccount(0, currency.id, 0, value)}
                                                value={
                                                    defaultBankAccounts &&
                                                    defaultBankAccounts[2] &&
                                                    defaultBankAccounts[2][currency.id] &&
                                                    defaultBankAccounts[2][currency.id][2]
                                                }
                                                nullable
                                                readonly={() => defaultBankAccountsReadonly}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <SelectField
                                                values={optionsFactoring}
                                                label={`${currency.name} - ${this.props.intl.formatMessage({ id: 'fields.factoring' })}`}
                                                onChange={value => this.saveDefaultBankAccount(0, currency.id, 1, value)}
                                                value={
                                                    defaultBankAccounts &&
                                                    defaultBankAccounts[2] &&
                                                    defaultBankAccounts[2][currency.id] &&
                                                    defaultBankAccounts[2][currency.id][1]
                                                }
                                                nullable
                                                readonly={() => defaultBankAccountsReadonly}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </PermissionsCheck>
                </LoadingOverlay>

                <Modal show={Boolean(this.state.modalBankAccount.show)} onHide={this.modalBankAccountClose} bsSize="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.modalBankAccount.item ? (
                                <FormattedMessage id="fields.itemEdit" />
                            ) : (
                                <FormattedMessage id="fields.itemCreate" />
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CompanyBankAccountsEditForm
                            companyBankAccountID={this.state.modalBankAccount.item && this.state.modalBankAccount.item.id}
                            successCallback={this.editSuccessCallback}
                            backCallback={this.editBackCallback}
                            defaultValues={{
                                company_id: this.props.company.id,
                            }}
                            hiddenFields={['company_id']}
                            PERMS={PERMS_BANK_ACCOUNTS}
                        />
                    </Modal.Body>
                </Modal>

                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        permissions: getPermissions(state),
        data: getCompanyBankAccountsData(state),
        fetching: getCompanyBankAccountsFetching(state),
        deleting: getCompanyBankAccountDeleting(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        defaultBankAccountsSaving: getDefaultBankAccountsSaving(state),
        defaultBankAccountsError: getDefaultBankAccountsError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...companiesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                    deleteCompanyBankAccount,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(CompaniesBankAccountsList)
)
