import { Record } from 'immutable'

export class VehicleDocument extends Record({
    id: undefined,
    vehicle_id: undefined,
    vehicle: undefined,
    document_type_id: undefined,
    document_type: undefined,
    vehicle_document_files_count: undefined,
    number: undefined,
    note: undefined,
    date_validity_from: undefined,
    date_validity_to: undefined,
}) {}
