import React, { Component } from 'react'
import moment from 'moment'
import { injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class DriverDocumentsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render() {
        const document = this.props.data
        const driver = this.props.driver
        const documentType = this.props.documentType
        const notifyDays = documentType && documentType.notify_days
        const validityDaysRemained = document.date_validity_to && moment(document.date_validity_to).diff(moment(), 'days')

        const validityDaysClassName = notifyDays && document.date_validity_to && validityDaysRemained <= notifyDays ? 'text-danger' : ''

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-30 text-center">
                    <span>
                        {document.driver_document_files_count && document.driver_document_files_count > 0 ? (
                            <i className="fas fa-file text-gray-dark" />
                        ) : null}
                    </span>
                </td>
                <td className="w-160">{driver ? driver.getShortFullName() : null}</td>
                <td className="w-200">{documentType ? documentType.name : null}</td>
                <td className="w-140">{document.number}</td>
                <td className="w-200 text-center">{document.date_validity_from ? formatters.dateFormatter(document.date_validity_from) : null}</td>
                <td className="w-200 text-center">{document.date_validity_to ? formatters.dateFormatter(document.date_validity_to) : null}</td>
                <td className={`w-80 text-right ${validityDaysClassName}`}>
                    {document.date_validity_to
                        ? `${formatters.integerFormatter(validityDaysRemained)} ${this.props.intl.formatMessage({ id: 'fields.abbrDays' })}`
                        : null}
                </td>
                <td className="w-max wm-300 wrapped">{document.note}</td>
            </tr>
        )
    }
}

export default injectIntl(DriverDocumentsListRow)
