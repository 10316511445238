// Fetch list

export const FETCH = 'warehousingItems.FETCH'
export const FETCH_STARTED = 'warehousingItems.FETCH_STARTED'
export const FETCH_FULFILLED = 'warehousingItems.FETCH_FULFILLED'
export const FETCH_REJECTED = 'warehousingItems.FETCH_REJECTED'
export const FETCH_CANCELLED = 'warehousingItems.FETCH_CANCELLED'

export const SORTING_CHANGED = 'warehousingItems.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'warehousingItems.FETCH_ONE'
export const FETCH_ONE_STARTED = 'warehousingItems.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'warehousingItems.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'warehousingItems.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'warehousingItems.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'warehousingItems.SAVE'
export const SAVE_STARTED = 'warehousingItems.SAVE_STARTED'
export const SAVE_FULFILLED = 'warehousingItems.SAVE_FULFILLED'
export const SAVE_REJECTED = 'warehousingItems.SAVE_REJECTED'
export const SAVE_CANCELLED = 'warehousingItems.SAVE_CANCELLED'

// Delete

export const DELETE = 'warehousingItems.DELETE'
export const DELETE_STARTED = 'warehousingItems.DELETE_STARTED'
export const DELETE_FULFILLED = 'warehousingItems.DELETE_FULFILLED'
export const DELETE_REJECTED = 'warehousingItems.DELETE_REJECTED'
export const DELETE_CANCELLED = 'warehousingItems.DELETE_CANCELLED'

// Export

export const EXPORT = 'warehousingItems.EXPORT'
export const EXPORT_FULFILLED = 'warehousingItems.EXPORT_FULFILLED'

// Fetch receipts

export const FETCH_RECEIPTS = 'warehousingItems.FETCH_RECEIPTS'
export const FETCH_RECEIPTS_STARTED = 'warehousingItems.FETCH_RECEIPTS_STARTED'
export const FETCH_RECEIPTS_FULFILLED = 'warehousingItems.FETCH_RECEIPTS_FULFILLED'
export const FETCH_RECEIPTS_REJECTED = 'warehousingItems.FETCH_RECEIPTS_REJECTED'
export const FETCH_RECEIPTS_CANCELLED = 'warehousingItems.FETCH_RECEIPTS_CANCELLED'

// Export receipts

export const EXPORT_RECEIPTS = 'warehousingItems.EXPORT_RECEIPTS'
export const EXPORT_RECEIPTS_STARTED = 'warehousingItems.EXPORT_RECEIPTS_STARTED'
export const EXPORT_RECEIPTS_FULFILLED = 'warehousingItems.EXPORT_RECEIPTS_FULFILLED'
export const EXPORT_RECEIPTS_REJECTED = 'warehousingItems.EXPORT_RECEIPTS_REJECTED'
export const EXPORT_RECEIPTS_CANCELLED = 'warehousingItems.EXPORT_RECEIPTS_CANCELLED'