import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import * as constants from '../../driver_timesheets/constants'
import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_TIMESHEETS } from '../permissions'

// eslint-disable-next-line react/prefer-stateless-function
class DriversTimesheetsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick && this.props.onClick(this.props.item)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete && this.props.onDelete(this.props.item)
    }

    render() {
        const timesheet = this.props.item
        const showDatetime = String(timesheet.interval_type_id) === String(constants.INTERVAL_TYPE_INTERVAL)

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS_TIMESHEETS.UPDATE, PERMS_TIMESHEETS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS_TIMESHEETS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_TIMESHEETS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-120">{this.props.intl.formatMessage({ id: `driverTimesheetTypes.${timesheet.type}` })}</td>
                <td className="w-120">
                    {showDatetime ? formatters.datetimeFormatter(timesheet.start_datetime) : formatters.dateFormatter(timesheet.start_datetime)}
                </td>
                <td className="w-120">
                    {showDatetime ? formatters.datetimeFormatter(timesheet.end_datetime) : formatters.dateFormatter(timesheet.end_datetime)}
                </td>
                <td className="w-120 text-right">{formatters.durationFromSecondsFormatter(timesheet.time)}</td>
                <td className="w-max wm-120">{timesheet.note}</td>
            </tr>
        )
    }
}

export default injectIntl(DriversTimesheetsListRow)
