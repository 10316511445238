import { createSelector } from 'reselect'

export const getCurrencies = state => state.currencies.getIn(['all', 'objects'])

export const createGetCurrencyByIDSelector = id => createSelector([getCurrencies], currencies => currencies.get(String(id)))

const getCurrentCurrencyStatus = state => state.currencies.get('current')

export const createGetCurrentCurrencyStatusSelector = () => createSelector([getCurrentCurrencyStatus], status => status)

export const getCurrencyDeleting = state => state.currencies.getIn(['deletion', 'inProgress'])

export const getCurrencyDeleteError = state => state.currencies.getIn(['deletion', 'error'])
