import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import PermissionsProps from '.././../auth/components/PermissionsProps'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS, PERMS_CUSTOMER, PERMS_COST } from '../permissions'
import { PERMS as PERMS_TRANSPORT } from '../../transports/permissions'

class TransportTemplatesListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.transport.id)
    }

    handleCreateTransport = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.handleCreateTransport && this.props.handleCreateTransport(this.props.transport)
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render = () => {
        const transport = this.props.transport
        const transportType = this.props.transportType
        const cargoType = this.props.cargoType
        const goodsType = this.props.goodsType

        const loading = transport.transport_point_loading
        const loadingCountry = loading && this.props.createGetCountryByIDSelector(loading.country_id)

        const unloading = transport.transport_point_unloading
        const unloadingCountry = unloading && this.props.createGetCountryByIDSelector(unloading.country_id)

        return (
            <tr onClick={this.handleClick} className="row-selectable transports-table-row">
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS_TRANSPORT.CREATE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_TRANSPORT.CREATE]}>
                            <a href="#" onClick={this.handleCreateTransport}>
                                <i className="far fa-plus-circle text-gray" />
                            </a>
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <PermissionsProps hasAny={Object.values(PERMS_CUSTOMER)} props={{ className: 'w-500' }}>
                    <td className="w-max wm-500">
                        <strong>{transport.template_name}</strong>
                    </td>
                </PermissionsProps>
                <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER)}>
                    <td className="w-max wm-200">
                        {transport.transport_customers && transport.transport_customers.size
                            ? transport.transport_customers.map(transportCustomer => {
                                const customer = this.props.createGetCustomerByIDSelector(transportCustomer.get('customer_id'))
                                return (
                                    <span key={`customer-${transportCustomer.get('id')}`}>
                                        {customer && customer.name}
                                        <br />
                                    </span>
                                )
                            })
                            : null}
                    </td>
                </PermissionsCheck>
                <td className="w-220">{transportType && transportType.name}</td>
                <td className="w-200">{cargoType && cargoType.name}</td>
                <td className="w-120">{goodsType && goodsType.name}</td>
                <td className="w-240">
                    {loading && (
                        <div>
                            {loading.city && (
                                <strong>
                                    {loadingCountry && loadingCountry.iso_code} {`${loading.zipcode} ${loading.city}`}
                                </strong>
                            )}
                        </div>
                    )}
                </td>
                <td className="w-240">
                    {unloading && (
                        <div>
                            {unloading.city && (
                                <strong>
                                    {unloadingCountry && unloadingCountry.iso_code} {`${unloading.zipcode} ${unloading.city}`}
                                </strong>
                            )}
                        </div>
                    )}
                </td>
                <PermissionsCheck hasAny={Object.values(PERMS_COST)}>
                    <td className="w-100 text-right">
                        {transport.transport_customers && transport.transport_customers.size
                            ? transport.transport_customers.map(transportCustomer => {
                                const currency = this.props.createGetCurrencyByIDSelector(transportCustomer.get('currency_id'))
                                return (
                                    <span key={`currency-${transportCustomer.get('id')}`}>
                                        {formatters.priceFormatter(transportCustomer.get('price'), '0,0', currency ? currency.iso_code : '')}
                                    </span>
                                )
                            })
                            : null}
                    </td>
                </PermissionsCheck>
            </tr>
        )
    }
}

export default TransportTemplatesListRow
