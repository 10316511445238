import * as actionTypes from './actionTypes'

// Fetch

export const fetchCargoTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchCargoTypesFulfilled = cargoTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: cargoTypes,
})

export const fetchCargoTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCargoTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCargoType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCargoTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCargoTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCargoTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCargoType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveCargoTypeFulfilled = cargoType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: cargoType,
})

export const saveCargoTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteCargoType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCargoTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCargoTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportCargoTypes = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
