import React from 'react'
import { Route, Switch } from 'react-router'
import Board from './Board'

const Components = props => (
    <Switch>
        <Route exact path={`${props.match.url}/:date?`} component={Board} />
    </Switch>
)

export default Components
