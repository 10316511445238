import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'

import moment from 'moment'
import { Form, InputField, SelectField, SubmitButton } from '../../../common/form'
import {
    url,
    handleCommonEditActions,
    convertCSDatetimeToISO,
    convertISODateToCS,
    convertCSDateToMoment,
    getPrerequisitesArray,
} from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as customerPlansActionCreators from '../actionCreators'
import { createGetCustomerPlanByIDSelector, createGetCurrentCustomerPlanStatusSelector } from '../selectors'
import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {CustomDatePicker} from "../../../common/custom_date_picker";

const componentIdentifier = 'customer_plans_edit'
const prerequisites = ['company_cost_centers']

class CustomerPlansEditForm extends Component {
    defaultValues = {}

    validationRules = {}

    state = {
        datetimes: {
            date_from: moment().format('DD.MM.YYYY'),
            date_to: moment().format('DD.MM.YYYY'),
        },
        datetimesSet: false,
        values: this.defaultValues,
        plan: {},
        gps: {},
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        values.date_from = convertCSDatetimeToISO(this.state.datetimes.date_from)
        values.date_to = convertCSDatetimeToISO(this.state.datetimes.date_to)

        this.setValues(values, () => {
            this.handleSave(false)
        })
    }

    handleSave = ignoreDuplicity => {
        this.props.actions.saveCustomerPlan(Object.assign(this.state.values, this.state.plan, this.state.gps), ignoreDuplicity)
    }

    handleDatetimeChange = (key, value) => {
        const newState = {
            datetimes: {
                ...this.state.datetimes,
                [key]: value,
            },
        }

        this.setState(newState)
    }

    successCallback = () => {
        this.props.successCallback ? this.props.successCallback() : this.props.redirect(url(this.props.match, 'customer-plans'))
    }

    backCallback = () => {
        this.props.backCallback ? this.props.backCallback() : this.props.redirect(url(this.props.match, 'customer-plans'))
    }

    componentDidMount() {
        this.props.customerPlanID && this.props.actions.fetchCustomerPlan(this.props.customerPlanID)
        this.props.actions.fetchPrerequisites()

        if (this.props.defaultValues) {
            this.setValues(this.props.defaultValues)
        }
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback)

        if (nextProps.customerPlan && (JSON.stringify(nextProps.customerPlan) !== JSON.stringify(this.props.customerPlan) || !this.state.values.id)) {
            this.setValues(nextProps.customerPlan.toJS())
        }

        if (nextProps.customerPlan && !this.state.datetimesSet) {
            this.setState({
                datetimes: {
                    date_from: convertISODateToCS(nextProps.customerPlan.date_from),
                    date_to: convertISODateToCS(nextProps.customerPlan.date_to),
                },
                datetimesSet: true,
            })
        }
    }

    render() {
        const perms = this.props.PERMS ? this.props.PERMS : PERMS

        return (
            <div>
                <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                    <PermissionsCheck hasAny={Object.values(perms)}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                            isEdit={Boolean(this.props.customerPlan)}
                        >
                            <div className="hidden">
                                <InputField id="customer_id" />
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="value_date_from">
                                            <FormattedMessage id="fields.dateFrom" />
                                        </label>
                                        <CustomDatePicker
                                            id="value_date_from"
                                            selected={convertCSDateToMoment(this.state.datetimes.date_from)}
                                            value={this.state.datetimes.date_from}
                                            onChange={value => {
                                                this.handleDatetimeChange('date_from', value ? formatters.dateFormatter(value) : '')
                                            }}
                                            onChangeRaw={e => {
                                                this.handleDatetimeChange('date_from', e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="value_date_to">
                                            <FormattedMessage id="fields.dateTo" />
                                        </label>
                                        <CustomDatePicker
                                            id="value_date_to"
                                            selected={convertCSDateToMoment(this.state.datetimes.date_to)}
                                            value={this.state.datetimes.date_to}
                                            onChange={value => {
                                                this.handleDatetimeChange('date_to', value ? formatters.dateFormatter(value) : '')
                                            }}
                                            onChangeRaw={e => {
                                                this.handleDatetimeChange('date_to', e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <SelectField
                                id="company_cost_center_id"
                                label={this.props.intl.formatMessage({ id: 'fields.costCenter' })}
                                values={getPrerequisitesArray(this.props.prerequisites.values.get('company_cost_centers'))}
                            />

                            <div className="row">
                                <div className="col-md-4">
                                    <InputField id="count" label={this.props.intl.formatMessage({ id: 'fields.count' })} />
                                </div>
                                <div className="col-md-4">
                                    <InputField id="sales" label={this.props.intl.formatMessage({ id: 'fields.sales' })} />
                                </div>
                                <div className="col-md-4">
                                    <InputField id="profit" label={this.props.intl.formatMessage({ id: 'fields.profit' })} />
                                </div>
                            </div>

                            <InputField id="note" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />

                            <div className="btns-form">
                                <button onClick={this.backCallback} className="btn btn-addon btn-default">
                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                </button>
                                <SubmitButton
                                    perms={Object.values(perms)}
                                    isEdit={Boolean(this.props.customerPlan)}
                                    className="btn-addon pull-right"
                                />
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        customerPlan: createGetCustomerPlanByIDSelector(ownProps.customerPlanID)(state),
        status: createGetCurrentCustomerPlanStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...customerPlansActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CustomerPlansEditForm)
)
