import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCostTypeCosts = (year, filters) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        filters
    },
})

export const fetchReportCostTypeCostsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCostTypeCostsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCostTypeCostsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Export

export const exportReportCostTypeCosts = (year, filters) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
    },
})

// Clear

export const clearReportCostTypeCosts = () => ({
    type: actionTypes.CLEAR,
})

// Fetch details

export const fetchReportCostTypeCostsDetails = (id, year, month, inclCostCenters, inclDrivers, inclTrailers) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        id,
        year,
        month,
        inclCostCenters,
        inclDrivers,
        inclTrailers
    },
})

export const fetchReportCostTypeCostsDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportCostTypeCostsDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportCostTypeCostsDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Export

export const exportReportCostTypeCostsDetails = (id, year, month, inclCostCenters, inclDrivers, inclTrailers) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        id,
        year,
        month,
        inclCostCenters,
        inclDrivers,
        inclTrailers
    },
})

// Clear

export const clearReportCostTypeCostsDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})
