import * as actionTypes from './actionTypes'

// Fetch

export const fetchServiceWorkers = () => ({
    type: actionTypes.FETCH,
})

export const fetchServiceWorkersFulfilled = serviceWorkers => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: serviceWorkers,
})

export const fetchServiceWorkersRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchServiceWorkersCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchServiceWorker = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchServiceWorkerFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchServiceWorkerRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchServiceWorkerCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveServiceWorker = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveServiceWorkerFulfilled = serviceWorker => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: serviceWorker,
})

export const saveServiceWorkerRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteServiceWorker = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteServiceWorkerFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteServiceWorkerRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportServiceWorkers = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
