import React from 'react'
import { Route, Switch } from 'react-router'
import InvoiceItemTypesList from './InvoiceItemTypesList'
import InvoiceItemTypesEdit from './InvoiceItemTypesEdit'

const InvoiceItemTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={InvoiceItemTypesList} />
        <Route exact path={`${props.match.url}/new`} component={InvoiceItemTypesEdit} />
        <Route exact path={`${props.match.url}/:invoiceItemTypeID`} component={InvoiceItemTypesEdit} />
    </Switch>
)

export default InvoiceItemTypes
