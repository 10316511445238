import React, { useRef} from 'react'
import { DndProvider, createDndContext } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// https://github.com/react-dnd/react-dnd/issues/186#issuecomment-561631584

const RNDContext = createDndContext(HTML5Backend)

function useDNDProviderElement(props) {
    const manager = useRef(RNDContext)

    if (!props.children) return null

    return <DndProvider manager={manager.current.dragDropManager}>{props.children}</DndProvider>
}

export default function DragAndDrop(props) {
    const DNDElement = useDNDProviderElement(props)
    return <React.Fragment>{DNDElement}</React.Fragment>
}