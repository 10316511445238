import React from 'react'
import { Route, Switch } from 'react-router'
import TransportsList from './TransportsList'
import TransportsEdit from './TransportsEdit'
import TransportsImport from './TransportsImport/TransportsImport'
import TransportsImportPrices from './TransportsImportPrices/TransportsImportPrices'

const Transports = props => (
    <Switch>
        <Route exact path={props.match.url} component={TransportsList} />
        <Route exact path={`${props.match.url}/new`} component={TransportsEdit} />
        <Route exact path={`${props.match.url}/import`} component={TransportsImport} />
        <Route exact path={`${props.match.url}/import-prices`} component={TransportsImportPrices} />
        <Route exact path={`${props.match.url}/:transportID`} component={TransportsEdit} />
    </Switch>
)

export default Transports
