import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { trailerDocumentSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { TrailerDocument } from './model'
import { Trailer } from '../trailers/model'
import * as trailersActionCreators from '../trailers/actionCreators'
import { DocumentType } from '../document_types/model'
import * as documentTypesActionCreators from '../document_types/actionCreators'

// Fetch

const fetchTrailerDocumentsEpic = (action$, store) => {
    const tableIdentifier = 'trailer_documents_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `trailer-documents/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(trailerDocumentSchema)
                        )

                        const trailerDocuments = normalizedEntitiesToRecordMap(
                            normalizedData.entities.trailer_documents,
                            TrailerDocument,
                            normalizedData.result
                        )
                        const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                        const documentTypes = normalizedEntitiesToRecordMap(normalizedData.entities.document_types, DocumentType)

                        const observables = [
                            Observable.of(trailersActionCreators.fetchTrailersFulfilled(trailers)),
                            Observable.of(documentTypesActionCreators.fetchDocumentTypesFulfilled(documentTypes)),
                            Observable.of(actionCreators.fetchTrailerDocumentsFulfilled(trailerDocuments)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchTrailerDocumentsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchTrailerDocumentEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().trailerDocuments.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `trailer-documents/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, trailerDocumentSchema)

                        const trailerDocuments = normalizedEntitiesToRecordMap(normalizedData.entities.trailer_documents, TrailerDocument)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchTrailerDocumentsFulfilled(trailerDocuments)),
                            Observable.of(actionCreators.fetchTrailerDocumentFulfilled())
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchTrailerDocumentsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveTrailerDocumentEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().trailerDocuments.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload.values)
            if (action.payload.ignoreDuplicity) {
                values.ignore_duplicity = 1
            }

            let path = `trailer-documents`
            let method = 'POST'

            if (values.id) {
                path = `trailer-documents/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        if (ajaxResponse.response.duplicity_found) {
                            return Observable.concat(Observable.of(actionCreators.saveTrailerDocumentDuplicityFound(ajaxResponse.response.duplicity)))
                        }

                        const normalizedData = normalize(ajaxResponse.response, trailerDocumentSchema)

                        const trailerDocuments = normalizedEntitiesToRecordMap(normalizedData.entities.trailer_documents, TrailerDocument)
                        const trailerDocument = trailerDocuments.valueSeq().first()

                        return Observable.of(actionCreators.saveTrailerDocumentFulfilled(trailerDocument))
                    })
                    .catch(error => Observable.of(actionCreators.saveTrailerDocumentRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteTrailerDocumentEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().trailerDocuments.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `trailer-documents/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteTrailerDocumentFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteTrailerDocumentRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Fetch files

const fetchTrailerDocumentFilesEpic = (action$, store) =>
action$
    .ofType(actionTypes.FETCH_FILES)
    .filter(() => !store.getState().trailerDocuments.getIn(['files', 'fetching']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_FILES_STARTED,
            }),
            request({
                path: `trailer-documents/${action.payload}/files`,
                method: 'GET',
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchTrailerDocumentFilesFulfilled(ajaxResponse.response.files)))
                .catch(error => Observable.of(actionCreators.fetchTrailerDocumentFilesRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_FILES_CANCELLED))
        )
    )

// Upload files

const uploadTrailerDocumentFilesEpic = (action$, store) =>
action$
    .ofType(actionTypes.UPLOAD_FILES)
    .filter(() => !store.getState().trailerDocuments.getIn(['files', 'saving']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.UPLOAD_FILES_STARTED,
            }),
            request({
                path: `trailer-documents/${action.payload.id}/files`,
                method: 'POST',
                body: { ...action.payload },
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.uploadTrailerDocumentFilesFulfilled(ajaxResponse.response.files)))
                .catch(error => Observable.of(actionCreators.uploadTrailerDocumentFilesRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.UPLOAD_FILES_CANCELLED))
        )
    )

// Delete file

const deleteTrailerDocumentFileEpic = (action$, store) =>
action$
    .ofType(actionTypes.DELETE_FILE)
    .filter(() => !store.getState().trailerDocuments.getIn(['files', 'deleting']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.DELETE_FILE_STARTED,
            }),
            request({
                path: `trailer-documents/${action.payload.id}/files/${action.payload.file_id}`,
                method: 'DELETE',
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.deleteTrailerDocumentFileFulfilled(ajaxResponse.response.files)))
                .catch(error => Observable.of(actionCreators.deleteTrailerDocumentFileRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.DELETE_FILE_CANCELLED))
        )
    )

// Export

const exportTrailerDocumentsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `trailer-documents/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

export default [fetchTrailerDocumentsEpic, fetchTrailerDocumentEpic, saveTrailerDocumentEpic, deleteTrailerDocumentEpic, exportTrailerDocumentsEpic, fetchTrailerDocumentFilesEpic, uploadTrailerDocumentFilesEpic, deleteTrailerDocumentFileEpic]
