// Fetch list

export const FETCH = 'reportVehicleChecks.FETCH'
export const FETCH_STARTED = 'reportVehicleChecks.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportVehicleChecks.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportVehicleChecks.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportVehicleChecks.FETCH_CANCELLED'

export const SORTING_CHANGED = 'reportVehicleChecks.SORTING_CHANGED'

// Clear

export const CLEAR = 'reportVehicleChecks.CLEAR'
