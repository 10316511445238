import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'

import * as formatters from '../../../common/formatters'

import * as reportCustomerPlansActionCreators from '../actionCreators'
import { getData, getSettings, getFetching } from '../selectors'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { convertCSDateToMoment, getPrerequisitesArray } from '../../../common/helpers'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { TableFilterInputField } from '../../../common/table/components'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { createTableModelSelector } from '../../../common/table/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import CheckboxField from "../../../common/form/components/CheckboxField";

const tableIdentifier = 'report_customer_plans'

const componentIdentifier = 'report_customer_plans'
const prerequisites = ['company_cost_centers']

const GROUP_BY_DATE_CREATED = 1
const GROUP_BY_DATE_UNLOADING = 2
const GROUP_BY_DATE_LOADING = 3

class ReportCustomerPlans extends Component {
    // settings
    getDefaultSettings = () => ({
        dateFrom: moment()
            .startOf('year')
            .format('DD.MM.YYYY'),
        dateTo: moment()
            .endOf('year')
            .format('DD.MM.YYYY'),
        companyCostCenterID: null,
        groupBy: GROUP_BY_DATE_CREATED,
        withEmpty: false
    })

    getSettings = () => this.props.settings || this.getDefaultSettings()

    // settings handles

    handleChangeDate = (momentFrom, momentTo) => {
        const settings = {
            ...this.getSettings(),
            dateFrom: momentFrom.format('DD.MM.YYYY'),
            dateTo: momentTo.format('DD.MM.YYYY'),
        }
        this.loadData(settings)
    }

    handleChangeCompanyCostCenterID = companyCostCenterID => {
        const settings = {
            ...this.getSettings(),
            companyCostCenterID,
        }

        this.loadData(settings)
    }

    handleChangeWithEmpty = withEmpty => {
        const settings = {
            ...this.getSettings(),
            withEmpty,
        }

        this.loadData(settings)
    }

    handleChangeGroupBy = groupBy => {
        const settings = {
            ...this.getSettings(),
            groupBy,
        }

        this.loadData(settings)
    }

    refresh = () => {
        this.loadData(this.getSettings())
    }

    // data
    loadData = settings => {
        this.props.actions.fetchReportCustomerPlans(settings || this.getSettings())
    }

    getData = () => this.props.data

    clearData = () => {
        this.props.actions.clearReportCustomerPlans()
    }

    // prerequisites
    loadPrerequisites = () => {
        this.props.actions.fetchPrerequisites()
    }

    // filters
    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    // Export
    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportCustomerPlans(this.getSettings())
    }

    componentDidMount() {
        this.loadData()
        this.loadPrerequisites()
    }

    componentWillUnmount() {
        this.clearData()
        clearTimeout(this.timerRefresh)
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportCustomerPlans.heading' }))

        const data = this.getData()
        const values = data ? data.data : null
        const settings = this.getSettings()
        const filters = this.props.tableModel.get('filters')
        const companyCostCenters = getPrerequisitesArray(this.props.prerequisites.values.get('company_cost_centers'))

        if (!settings) {
            return null
        }

        const momentFrom = convertCSDateToMoment(settings.dateFrom)
        const momentTo = convertCSDateToMoment(settings.dateTo)
        const monthsDiff = Math.ceil(momentTo.diff(momentFrom, 'months', true)) || 1

        let totalPlanCount = 0
        let totalPlanSales = 0
        let totalPlanProfit = 0

        let totalCount = 0
        let totalSales = 0
        let totalProfit = 0

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-md">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportCustomerPlans.heading" />
                                        </span>
                                    </h4>
                                    <div className="pull-left" style={{ marginLeft: '100px' }}>
                                    <span className="pull-left text-gray f-bold" style={{ paddingTop: '10px' }}>
                                        <FormattedMessage id="fields.showIncl" />:
                                    </span>
                                        <div className="pull-left m-l-sm">
                                            <CheckboxField
                                                identifier="with_empty"
                                                onChange={(e) => { this.handleChangeWithEmpty(e.target.checked) }}
                                                label={this.props.intl.formatMessage({ id: 'fields.showInclEmpty' })}
                                                value={settings.withEmpty}
                                            />
                                        </div>
                                    </div>
                                    <div className="btns-list">
                                        <div className="pull-left w-200 m-r-sm">
                                            <select
                                                value={settings.companyCostCenterID}
                                                onChange={e => this.handleChangeCompanyCostCenterID(e.target.value)}
                                                className="form-control pull-left"
                                            >
                                                <option value="0">Všechna střediska</option>
                                                {companyCostCenters.map(item => (
                                                    <option key={`company-cost-center-${item.id}`} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="pull-left w-200 m-r-sm">
                                            <select
                                                value={settings.groupBy}
                                                onChange={e => this.handleChangeGroupBy(e.target.value)}
                                                className="form-control pull-left"
                                            >
                                                <option value={GROUP_BY_DATE_CREATED}>Dle data vytvoření</option>
                                                <option value={GROUP_BY_DATE_LOADING}>Dle data nakládky</option>
                                                <option value={GROUP_BY_DATE_UNLOADING}>Dle data vykládky</option>
                                            </select>
                                        </div>
                                        <PeriodPicker
                                            className={'m-r-lg'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_YEAR}
                                            onChange={(momentFrom, momentTo) => this.handleChangeDate(momentFrom, momentTo)}
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}

                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-max wm-200" />
                                                <th className="w-160" />
                                                <th className="w-160" />
                                                <th className="w-340 th-spanned text-center" colSpan={3}>
                                                    Plán / měsíc
                                                </th>
                                                <th className="w-420 th-spanned text-center" colSpan={3}>
                                                    Výsledek
                                                </th>
                                                <th className="w-340 th-spanned text-center" colSpan={3}>
                                                    Výsledek / měsíc
                                                </th>
                                            </tr>
                                            <tr className="th-bordered">
                                                <th className="w-max wm-200">
                                                    <FormattedMessage id="fields.customer" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.businessManager" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.count" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.sales" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.profit" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.count" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.sales" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.profit" />
                                                </th>
                                                <th className="w-80 text-right">
                                                    <FormattedMessage id="fields.profit" /> %
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.count" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.sales" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.profit" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-max wm-200">
                                                    <TableFilterInputField
                                                        identifier="customer_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-160">
                                                    <TableFilterInputField
                                                        identifier="dispatcher_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-160">
                                                    <TableFilterInputField
                                                        identifier="business_manager_name"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-100" />
                                                <th className="w-120" />
                                                <th className="w-120" />
                                                <th className="w-100" />
                                                <th className="w-120" />
                                                <th className="w-120" />
                                                <th className="w-80" />
                                                <th className="w-100" />
                                                <th className="w-120" />
                                                <th className="w-120" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values &&
                                                values.map(row => {
                                                    // filters
                                                    if (
                                                        filters.getIn(['customer_name', 'value']) &&
                                                        row.customersName
                                                            .toLowerCase()
                                                            .indexOf(filters.getIn(['customer_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['dispatcher_name', 'value']) &&
                                                        row.dispatchersName
                                                            .toLowerCase()
                                                            .indexOf(filters.getIn(['dispatcher_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    if (
                                                        filters.getIn(['business_manager_name', 'value']) &&
                                                        row.businessManagersName
                                                            .toLowerCase()
                                                            .indexOf(filters.getIn(['business_manager_name', 'value']).toLowerCase()) < 0
                                                    ) {
                                                        return null
                                                    }

                                                    // totals
                                                    totalPlanCount += row.planCount
                                                    totalPlanSales += row.planSales
                                                    totalPlanProfit += row.planProfit

                                                    totalCount += row.totalCount
                                                    totalSales += row.totalSales
                                                    totalProfit += row.totalProfit

                                                    return (
                                                        <tr key={`customer-${row.customersId}`}>
                                                            <td className="w-max wm-200">{row.customersName}</td>
                                                            <td className="w-160">{row.dispatchersName}</td>
                                                            <td className="w-160">{row.businessManagersName}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.planCount)}</td>
                                                            <td className="w-120 text-right">{formatters.priceFormatter(row.planSales)}</td>
                                                            <td className="w-120 text-right">{formatters.priceFormatter(row.planProfit)}</td>
                                                            <td className="w-100 text-right">{formatters.numberFormatter(row.totalCount)}</td>
                                                            <td className="w-120 text-right">{formatters.priceFormatter(row.totalSales)}</td>
                                                            <td className="w-120 text-right">{formatters.priceFormatter(row.totalProfit)}</td>
                                                            <td className="w-80 text-right">{formatters.percentFormatter(row.totalProfitPerc)}</td>
                                                            <td className="w-100 text-right">
                                                                {formatters.numberFormatter(row.totalCount / monthsDiff, '0,0.00')}
                                                            </td>
                                                            <td className="w-120 text-right">
                                                                {formatters.priceFormatter(row.totalSales / monthsDiff)}
                                                            </td>
                                                            <td className="w-120 text-right">
                                                                {formatters.priceFormatter(row.totalProfit / monthsDiff)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-max wm-200">
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                <td className="w-160" />
                                                <td className="w-160" />
                                                <td className="w-100 text-right">
                                                    <b>{formatters.numberFormatter(totalPlanCount)}</b>
                                                </td>
                                                <td className="w-120 text-right">
                                                    <b>{formatters.priceFormatter(totalPlanSales)}</b>
                                                </td>
                                                <td className="w-120 text-right">
                                                    <b>{formatters.priceFormatter(totalPlanProfit)}</b>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <b>{formatters.numberFormatter(totalCount)}</b>
                                                </td>
                                                <td className="w-120 text-right">
                                                    <b>{formatters.priceFormatter(totalSales)}</b>
                                                </td>
                                                <td className="w-120 text-right">
                                                    <b>{formatters.priceFormatter(totalProfit)}</b>
                                                </td>
                                                <td className="w-80 text-right">
                                                    <b>{formatters.percentFormatter(totalSales ? (totalProfit / totalSales) * 100 : 0)}</b>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <b>{formatters.numberFormatter(totalCount / monthsDiff, '0,0.00')}</b>
                                                </td>
                                                <td className="w-120 text-right">
                                                    <b>{formatters.priceFormatter(totalSales / monthsDiff)}</b>
                                                </td>
                                                <td className="w-120 text-right">
                                                    <b>{formatters.priceFormatter(totalProfit / monthsDiff)}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        data: getData(state),
        settings: getSettings(state),
        fetching: getFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportCustomerPlansActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ReportCustomerPlans)
)
