import { Record } from 'immutable'

export class Sales extends Record({
    id: undefined,
    sales_type_id: undefined,
    date: undefined,
    repeat_id: undefined,
    date_start: undefined,
    date_end: undefined,
    price: undefined,
    currency_id: undefined,
    currency_rate: undefined,
    company_cost_center_id: undefined,
    vehicle_id: undefined,
    driver_id: undefined,
    trailer_id: undefined,
    invoices: undefined,
    user_id: undefined,
    person_id: undefined,
    sales_generator_id: undefined,
    supplier: undefined,
    bill_number: undefined,
    note: undefined,
    customer_id: undefined,
    invoicing_state_id: undefined,
}) {}
