// Fetch list

export const FETCH = 'reportDriverTimesheet.FETCH'
export const FETCH_STARTED = 'reportDriverTimesheet.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportDriverTimesheet.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportDriverTimesheet.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportDriverTimesheet.FETCH_CANCELLED'

// Driver note

export const DRIVER_NOTE_UPDATE = 'reportDriverTimesheet.DRIVER_NOTE_UPDATE'
export const DRIVER_NOTE_UPDATE_STARTED = 'reportDriverTimesheet.DRIVER_NOTE_UPDATE_STARTED'
export const DRIVER_NOTE_UPDATE_FULFILLED = 'reportDriverTimesheet.DRIVER_NOTE_UPDATE_FULFILLED'
export const DRIVER_NOTE_UPDATE_REJECTED = 'reportDriverTimesheet.DRIVER_NOTE_UPDATE_REJECTED'
export const DRIVER_NOTE_UPDATE_CANCELLED = 'reportDriverTimesheet.DRIVER_NOTE_UPDATE_CANCELLED'

// Clear

export const CLEAR = 'reportDriverTimesheet.CLEAR'

// Export

export const EXPORT = 'reportDriverTimesheet.EXPORT'
export const EXPORT_FULFILLED = 'reportDriverTimesheet.EXPORT_FULFILLED'

// Fetch details

export const FETCH_DETAILS = 'reportDriverTimesheet.FETCH_DETAILS'
export const FETCH_DETAILS_STARTED = 'reportDriverTimesheet.FETCH_DETAILS_STARTED'
export const FETCH_DETAILS_FULFILLED = 'reportDriverTimesheet.FETCH_DETAILS_FULFILLED'
export const FETCH_DETAILS_REJECTED = 'reportDriverTimesheet.FETCH_DETAILS_REJECTED'
export const FETCH_DETAILS_CANCELLED = 'reportDriverTimesheet.FETCH_DETAILS_CANCELLED'

// Clear details

export const CLEAR_DETAILS = 'reportDriverTimesheet.CLEAR_DETAILS'

// Export details

export const EXPORT_DETAILS = 'reportDriverTimesheet.EXPORT_DETAILS'
export const EXPORT_DETAILS_FULFILLED = 'reportDriverTimesheet.EXPORT_DETAILS_FULFILLED'
