import { Map, InvoiceedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: [],
        fetching: false,
        error: null,
    }),
    current: Map({
        object: null,
        supportData: null,
        fetching: false,
        saving: false,
        error: null,
    }),
    deleting: Map({
        inProgress: false,
        error: null,
    }),
    restoring: Map({
        inProgress: false,
        error: null,
    }),
    detail: Map({
        data: {},
        fetching: false,
        error: null,
    }),
    customerInfo: Map({
        data: {},
        fetching: false,
        error: null,
    }),
    createCreditNote: Map({
        data: null,
        fetching: false,
        error: null,
    }),
    exportToAccounting: Map({
        inProgress: false,
        error: null,
    }),
    transportInfo: Map({
        data: {},
        fetching: false,
        error: null,
    }),
    pdfCreating: Map({
        data: null,
        inProgress: false,
        error: null,
    }),
    invoiceSending: Map({
        inProgress: false,
        error: null,
    }),
})

function invoicingInvoices(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload.data)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // Clear
        case actionTypes.CLEAR:
            return state.setIn(['all', 'objects'], InvoiceedMap({}))

        // One
        case actionTypes.FETCH_ONE_STARTED:
            return state.setIn(['current', 'fetching'], true)
        case actionTypes.FETCH_ONE_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'object'], action.payload.invoice)
                    .setIn(['current', 'supportData'], action.payload.supportData)
                    .setIn(['current', 'fetching'], false)
                    .setIn(['current', 'error'], null)
            })
        case actionTypes.FETCH_ONE_REJECTED:
        case actionTypes.FETCH_ONE_CANCELLED:
            return state.setIn(['current', 'fetching'], false)

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], true).setIn(['current', 'error'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'object'], action.payload.invoice)
                    .setIn(['current', 'supportData'], action.payload.supportData)
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false).setIn(['current', 'error'], action.payload)
            })

        // Delete
        case actionTypes.DELETE_STARTED:
            return state.setIn(['deleting', 'inProgress'], true)
        case actionTypes.DELETE_FULFILLED:
            return state.withMutations(map => {
                const objects = state.getIn(['all', 'objects']).filter(item => item.id !== action.payload)
                map
                    .setIn(['all', 'objects'], objects)
                    .setIn(['deleting', 'inProgress'], false)
                    .setIn(['deleting', 'error'], null)
            })
        case actionTypes.DELETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deleting', 'inProgress'], false).setIn(['deleting', 'error'], action.payload)
            })

        // Restore
        case actionTypes.RESTORE_STARTED:
            return state.setIn(['restoring', 'inProgress'], true)
        case actionTypes.RESTORE_FULFILLED:
            return state.withMutations(map => {
                console.log(action.payload)
                map.setIn(['restoring', 'inProgress'], false)
                    .setIn(['restoring', 'error'], null)
                    .setIn(['current', 'object'], action.payload)
            })
        case actionTypes.RESTORE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['restoring', 'inProgress'], false).setIn(['restoring', 'error'], action.payload)
            })


        // Fetch detail
        case actionTypes.FETCH_DETAIL_STARTED:
            return state.setIn(['detail', 'fetching'], true)
        case actionTypes.FETCH_DETAIL_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['detail', 'data'], action.payload.data)
                    .setIn(['detail', 'fetching'], false)
                    .setIn(['detail', 'error'], null)
            })
        case actionTypes.FETCH_DETAIL_REJECTED:
            return state.withMutations(map => {
                map.setIn(['detail', 'fetching'], false).setIn(['detail', 'error'], action.payload)
            })
        case actionTypes.FETCH_DETAIL_CANCELLED:
            return state.setIn(['detail', 'fetching'], false)

        // Load customer info
        case actionTypes.LOAD_CUSTOMER_INFO_STARTED:
            return state.setIn(['customerInfo', 'fetching'], true)
        case actionTypes.LOAD_CUSTOMER_INFO_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['customerInfo', 'data'], action.payload.data)
                    .setIn(['customerInfo', 'fetching'], false)
                    .setIn(['customerInfo', 'error'], null)
            })
        case actionTypes.LOAD_CUSTOMER_INFO_REJECTED:
            return state.withMutations(map => {
                map.setIn(['customerInfo', 'fetching'], false).setIn(['customerInfo', 'error'], action.payload)
            })
        case actionTypes.LOAD_CUSTOMER_INFO_CANCELLED:
            return state.setIn(['customerInfo', 'fetching'], false)
        case actionTypes.CLEAR_CUSTOMER_INFO:
            return state.setIn(['customerInfo', 'data'], {})

        // Create credit note
        case actionTypes.CREATE_CREDIT_NOTE_STARTED:
            return state.setIn(['createCreditNote', 'fetching'], true)
        case actionTypes.CREATE_CREDIT_NOTE_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['createCreditNote', 'data'], action.payload.invoice)
                    .setIn(['createCreditNote', 'fetching'], false)
                    .setIn(['createCreditNote', 'error'], null)
            })
        case actionTypes.CREATE_CREDIT_NOTE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['createCreditNote', 'fetching'], false).setIn(['createCreditNote', 'error'], action.payload)
            })
        case actionTypes.CREATE_CREDIT_NOTE_CANCELLED:
            return state.setIn(['createCreditNote', 'fetching'], false)

        // Export to accounting
        case actionTypes.EXPORT_TO_ACCOUNTING_STARTED:
            return state.setIn(['exportToAccounting', 'inProgress'], true)
        case actionTypes.EXPORT_TO_ACCOUNTING_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['exportToAccounting', 'inProgress'], false).setIn(['exportToAccounting', 'error'], null)
            })
        case actionTypes.EXPORT_TO_ACCOUNTING_REJECTED:
            return state.withMutations(map => {
                map.setIn(['exportToAccounting', 'inProgress'], false).setIn(['exportToAccounting', 'error'], action.payload)
            })

        // Load transport info
        case actionTypes.LOAD_TRANSPORT_INFO_STARTED:
            return state.setIn(['transportInfo', 'fetching'], true)
        case actionTypes.LOAD_TRANSPORT_INFO_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['transportInfo', 'data'], action.payload.data)
                    .setIn(['transportInfo', 'fetching'], false)
                    .setIn(['transportInfo', 'error'], null)
            })
        case actionTypes.LOAD_TRANSPORT_INFO_REJECTED:
            return state.withMutations(map => {
                map.setIn(['transportInfo', 'fetching'], false).setIn(['transportInfo', 'error'], action.payload)
            })
        case actionTypes.LOAD_TRANSPORT_INFO_CANCELLED:
            return state.setIn(['transportInfo', 'fetching'], false)

        // Create pdf
        case actionTypes.CREATE_INVOICE_PDF_STARTED:
            return state.setIn(['pdfCreating', 'inProgress'], true)
        case actionTypes.CREATE_INVOICE_PDF_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['pdfCreating', 'inProgress'], false)
                    .setIn(['pdfCreating', 'error'], null)
                    .setIn(['pdfCreating', 'data'], action.payload)
            })
        case actionTypes.CREATE_INVOICE_PDF_REJECTED:
            return state.withMutations(map => {
                map.setIn(['pdfCreating', 'inProgress'], false).setIn(['pdfCreating', 'error'], action.payload)
            })
        case actionTypes.CREATE_INVOICE_PDF_CANCELLED:
            return state.setIn(['pdfCreating', 'inProgress'], false)

        // Send pdf to email
        case actionTypes.SEND_INVOICE_TO_EMAIL_STARTED:
            return state.setIn(['invoiceSending', 'inProgress'], true)
        case actionTypes.SEND_INVOICE_TO_EMAIL_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['invoiceSending', 'inProgress'], false).setIn(['invoiceSending', 'error'], null)
            })
        case actionTypes.SEND_INVOICE_TO_EMAIL_REJECTED:
            return state.withMutations(map => {
                map.setIn(['invoiceSending', 'inProgress'], false).setIn(['invoiceSending', 'error'], action.payload)
            })
        case actionTypes.SEND_INVOICE_TO_EMAIL_CANCELLED:
            return state.setIn(['invoiceSending', 'inProgress'], false)

        default:
            return state
    }
}

export default invoicingInvoices
