import React from 'react'
import { Route, Switch } from 'react-router'
import ReportEmptyKilometers from './ReportEmptyKilometers'

const Components = props => (
    <Switch>
        <Route exact path={`${props.match.url}/:month?`} component={ReportEmptyKilometers} />
    </Switch>
)

export default Components
