import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { Modal } from 'react-bootstrap'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'

import {
    url,
    handleCommonEditActions,
    getPrerequisitesArray,
    convertCSDateToISO,
    convertISODateToCS,
    convertCSDateToMoment,
} from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { Form, SelectField, InputField, SubmitButton } from '../../../common/form'
import { createGetCostByIDSelector, createGetCurrentCostStatusSelector } from '../selectors'
import * as costsActionCreators from '../actionCreators'
import { createGetVehicleByIDSelector, createGetCurrentVehicleStatusSelector } from '../../vehicles/selectors'
import { createGetUserByIDSelector, createGetCurrentUserStatusSelector } from '../../users/selectors'
import { createGetDriverByIDSelector, createGetCurrentDriverStatusSelector } from '../../drivers/selectors'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import * as constants from '../constants'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import {CustomDatePicker} from "../../../common/custom_date_picker";

const componentIdentifier = 'costs_edit'
const prerequisites = ['cost_types', 'currencies', 'company_cost_centers', 'vehicles', 'users', 'drivers', 'trailers']

class CostsEdit extends Component {
    defaultValues = {
        repeat_id: constants.COST_GENERATOR_REPEAT_NO,
    }

    validationRules = {}

    state = {
        values: this.defaultValues,
        dates: {
            date: moment().format('DD.MM.YYYY'),
            start: moment().format('DD.MM.YYYY'),
            end: null,
        },
        showEditDialog: false,
        datesSet: false,
    }

    handleSubmit = values => {
        if (this.props.cost && this.props.cost.cost_generator_id) {
            this.showEditDialog()
        } else {
            values.date = convertCSDateToISO(this.state.dates.date)
            values.date_start = convertCSDateToISO(this.state.dates.start)
            values.date_end = convertCSDateToISO(this.state.dates.end)
            this.props.actions.saveCost(values)
        }
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleDateChange = (key, value) => {
        const newState = {
            dates: {
                ...this.state.dates,
                [key]: value,
            },
            values: this.state.values,
        }

        newState.values.period = this.calculateRepeat(newState.values.repeat_id, newState.dates.start, newState.dates.end, null)

        this.setState(newState)
    }

    setValues = (values, callback) => {
        const dates = this.state.dates

        if (values.company_cost_center_id && values.company_cost_center_id !== this.state.values.company_cost_center_id) {
            values.vehicle_id = null
            values.trailer_id = null
            values.person_id = null
        }

        if (values.vehicle_id && values.vehicle_id !== this.state.values.vehicle_id) {
            values.company_cost_center_id = null
            values.trailer_id = null
            values.person_id = null
        }

        if (values.trailer_id && values.trailer_id !== this.state.values.trailer_id) {
            values.company_cost_center_id = null
            values.vehicle_id = null
            values.person_id = null
        }

        if (values.person_id && values.person_id !== this.state.values.person_id) {
            values.company_cost_center_id = null
            values.vehicle_id = null
            values.trailer_id = null
        }

        if (values.repeat_id && values.repeat_id !== this.state.values.repeat_id) {
            values.period = this.calculateRepeat(values.repeat_id, dates.start, dates.end, null)
        } else if (values.period && values.period !== this.state.values.period) {
            dates.end = this.calculateRepeat(values.repeat_id, dates.start, null, values.period)
        }

        this.setState(
            {
                values,
                dates,
            },
            () => {
                callback && callback()
            }
        )
    }

    getValues = () => {
        const values = this.state.values
        values.date = convertCSDateToISO(this.state.dates.date)
        values.date_start = convertCSDateToISO(this.state.dates.start)
        values.date_end = convertCSDateToISO(this.state.dates.end)
        return values
    }

    calculateRepeat = (repeat_id, start, end, period) => {
        let multiplier = 1
        let key = null
        switch (parseInt(repeat_id)) {
            case constants.COST_GENERATOR_REPEAT_WEEK:
                key = 'w'
                break
            case constants.COST_GENERATOR_REPEAT_MONTH:
                key = 'M'
                break
            case constants.COST_GENERATOR_REPEAT_QUARTER:
                multiplier = 3
                key = 'M'
                break
            case constants.COST_GENERATOR_REPEAT_HALF:
                multiplier = 6
                key = 'M'
                break
            case constants.COST_GENERATOR_REPEAT_YEAR:
                key = 'y'
                break
            default:
                return null
        }

        const startDate = convertCSDateToMoment(start)
        const endDate = convertCSDateToMoment(end)
        // eslint-disable-next-line prettier/prettier
        const isEndOfMonth = key !== 'w' && startDate.clone().endOf('month').format('YYYY-MM-DD') === startDate.format('YYYY-MM-DD')

        if (!period) {
            if (!end) {
                return 0
            }
            let count = parseInt(endDate.diff(startDate, key, true) / multiplier) + 1
            if (key === 'M' && isEndOfMonth && startDate.date() > endDate.date()) {
                count += 1
            }
            return count
        }
        if (!end) {
            if (period < 1) {
                return null
            }
            const newEndDate = startDate.clone().add((period - 1) * multiplier, key)
            if (isEndOfMonth) {
                newEndDate.endOf('month')
            }
            return newEndDate.format('DD.MM.YYYY')
        }

        return null
    }

    createUsersSelectValues() {
        const users = this.props.prerequisites.values
            .get('users')
            .valueSeq()
            .map(value => ({
                id: `U${value.get('id')}`,
                name: value.get('name'),
            }))
            .toArray()

        const drivers = this.props.prerequisites.values
            .get('drivers')
            .valueSeq()
            .map(value => ({
                id: `D${value.get('id')}`,
                name: value.get('name'),
            }))
            .toArray()

        return users.concat(drivers).sort((a, b) => a.name.localeCompare(b.name))
    }

    // edit dialog

    showEditDialog = () => {
        this.setState({
            showEditDialog: true,
        })
    }

    hideEditDialog = () => {
        this.setState({
            showEditDialog: false,
        })
    }

    saveThisPayment = () => {
        const values = this.getValues()
        values.future_payments = false
        this.props.actions.saveCost(values)
    }

    saveThisAndFuturePayments = () => {
        const values = this.getValues()
        values.future_payments = true
        this.props.actions.saveCost(values)
    }

    componentDidMount() {
        this.props.match.params.costID && this.props.actions.fetchCost(this.props.match.params.costID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'costs'))
        })

        if (JSON.stringify(nextProps.cost) !== JSON.stringify(this.props.cost) || (nextProps.cost && !this.state.values.id)) {
            this.setValues(nextProps.cost.toJS())
        }

        if (nextProps.cost && !this.state.datesSet) {
            const cost = nextProps.cost
            const values = this.state.values
            const dates = this.state.dates

            dates.date = convertISODateToCS(cost.date)
            dates.start = convertISODateToCS(cost.date_start)
            dates.end = convertISODateToCS(cost.date_end)
            this.setState({
                dates,
                values,
                datesSet: true,
            })
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.costs.heading' }))

        const costGeneratorRepeatValues = constants.COST_GENERATOR_REPEATS.map(item => ({
            id: item.id,
            name: this.props.intl.formatMessage({ id: item.name }),
        }))

        const isNoRepeat = parseInt(this.state.values.repeat_id) === constants.COST_GENERATOR_REPEAT_NO

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.costs.heading" /> -{' '}
                                                {this.props.vehicleEvent ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay
                                            active={
                                                this.props.prerequisites.status.get('fetching') ||
                                                this.props.status.get('fetching') ||
                                                this.props.vehicleStatus.get('fetching')
                                            }
                                        >
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.cost)}
                                                >
                                                    <div className="alert alert-default pull-left wp-100">
                                                        <div className="row">
                                                            <div className="col-sm-8">
                                                                <div className="form-group">
                                                                    <SelectField
                                                                        id="cost_type_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.costType' })}
                                                                        values={getPrerequisitesArray(
                                                                            this.props.prerequisites.values.get('cost_types'),
                                                                            'id',
                                                                            'name'
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-8">
                                                                <div className="form-group">
                                                                    <InputField
                                                                        id="supplier"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.supplier' })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <InputField
                                                                        id="bill_number"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.billNumber' })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <InputField
                                                                id="note"
                                                                label={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                            />
                                                        </div>
                                                    </div>

                                                    <h5 className="pull-left wp-100">Nastavení opakování</h5>

                                                    <div className="alert alert-default pull-left wp-100">
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <SelectField
                                                                        id="repeat_id"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.repeat' })}
                                                                        values={costGeneratorRepeatValues}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {!isNoRepeat ? (<div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="value_start">
                                                                        <FormattedMessage id="fields.from" />
                                                                    </label>
                                                                    <CustomDatePicker
                                                                        id="value_start"
                                                                        selected={convertCSDateToMoment(this.state.dates.start)}
                                                                        value={this.state.dates.start}
                                                                        should
                                                                        onChange={value => {
                                                                            this.handleDateChange(
                                                                                'start',
                                                                                value ? formatters.dateFormatter(value) : ''
                                                                            )
                                                                        }}
                                                                        onChangeRaw={e => {
                                                                            this.handleDateChange('start', e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="value_start">
                                                                        <FormattedMessage id="fields.to" />
                                                                    </label>
                                                                    <CustomDatePicker
                                                                        id="value_end"
                                                                        selected={convertCSDateToMoment(this.state.dates.end)}
                                                                        value={this.state.dates.end}
                                                                        onChange={value => {
                                                                            this.handleDateChange('end', value ? formatters.dateFormatter(value) : '')
                                                                        }}
                                                                        onChangeRaw={e => {
                                                                            this.handleDateChange('end', e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <InputField
                                                                        id="period"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.repeatCount' })}
                                                                        type="number"
                                                                        value={4}
                                                                        step={1}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>) : (<div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="value_date">
                                                                        <FormattedMessage id="fields.date" />
                                                                    </label>
                                                                    <CustomDatePicker
                                                                        id="value_date"
                                                                        selected={convertCSDateToMoment(this.state.dates.date)}
                                                                        value={this.state.dates.date}
                                                                        onChange={value => {
                                                                            this.handleDateChange(
                                                                                'date',
                                                                                value ? formatters.dateFormatter(value) : ''
                                                                            )
                                                                        }}
                                                                        onChangeRaw={e => {
                                                                            this.handleDateChange('date', e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>

                                                    <h5 className="pull-left wp-100">Zařazení</h5>

                                                    <div className="alert alert-default pull-left wp-100">
                                                        <div className="row">
                                                            <div className="col-sm-8">
                                                                <SelectField
                                                                    id="company_cost_center_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.costCenter' })}
                                                                    values={getPrerequisitesArray(
                                                                        this.props.prerequisites.values.get('company_cost_centers'),
                                                                        'id',
                                                                        'name'
                                                                    )}
                                                                    nullable={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <SelectField
                                                                    id="vehicle_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.vehicle' })}
                                                                    values={getPrerequisitesArray(
                                                                        this.props.prerequisites.values.get('vehicles'),
                                                                        'id',
                                                                        'name'
                                                                    )}
                                                                    nullable={true}
                                                                />
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <SelectField
                                                                    id="trailer_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.trailer' })}
                                                                    values={getPrerequisitesArray(
                                                                        this.props.prerequisites.values.get('trailers'),
                                                                        'id',
                                                                        'name'
                                                                    )}
                                                                    nullable={true}
                                                                />
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <SelectField
                                                                    id="person_id"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.driver' }) + ' / ' + this.props.intl.formatMessage({ id: 'fields.user' })}
                                                                    values={this.createUsersSelectValues()}
                                                                    nullable={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h5 className="pull-left wp-100">Hodnota nákladu</h5>

                                                    <div className="alert alert-default pull-left wp-100">
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <InputField
                                                                        id="price"
                                                                        label={this.props.intl.formatMessage({ id: 'fields.price' })}
                                                                        type="number"
                                                                        value={4}
                                                                        step={1}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-group">
                                                                    <SelectField
                                                                        id={`currency_id`}
                                                                        label={this.props.intl.formatMessage({ id: 'fields.currency' })}
                                                                        values={getPrerequisitesArray(
                                                                            this.props.prerequisites.values.get('currencies'),
                                                                            'id',
                                                                            'iso_code'
                                                                        )}
                                                                        prompt={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="btns-form pull-left wp-100">
                                                        <Link to={url(this.props.match, 'costs')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        <SubmitButton
                                                            perms={Object.values(PERMS)}
                                                            isEdit={Boolean(this.props.vehicleEvent)}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={Boolean(this.state.showEditDialog)} onHide={this.hideEditDialog} bsSize="sm" className="modal-level-1">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="confirmDialog.updateCostGonerator.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormattedMessage id="confirmDialog.updateCostGonerator.message" />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default m-r-xs" onClick={this.saveThisPayment}>
                            <FormattedMessage id="confirmDialog.updateCostGonerator.onlyThis" />
                        </button>
                        <button className="btn btn-default" onClick={this.saveThisAndFuturePayments}>
                            <FormattedMessage id="confirmDialog.updateCostGonerator.thisAndFuture" />
                        </button>
                    </Modal.Footer>
                </Modal>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        cost: createGetCostByIDSelector(ownProps.match.params.costID)(state),
        status: createGetCurrentCostStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        createGetVehicleByIDSelector: id => createGetVehicleByIDSelector(id)(state),
        vehicleStatus: createGetCurrentVehicleStatusSelector()(state),
        createGetUserByIDSelector: id => createGetUserByIDSelector(id)(state),
        userStatus: createGetCurrentUserStatusSelector()(state),
        createGetDriverByIDSelector: id => createGetDriverByIDSelector(id)(state),
        driverStatus: createGetCurrentDriverStatusSelector()(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...costsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CostsEdit)
)
