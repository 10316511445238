import * as actionTypes from './actionTypes'

// Fetch

export const fetchSurchargeTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchSurchargeTypesFulfilled = surchargeTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: surchargeTypes,
})

export const fetchSurchargeTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchSurchargeTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchSurchargeType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchSurchargeTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchSurchargeTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchSurchargeTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveSurchargeType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveSurchargeTypeFulfilled = surchargeType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: surchargeType,
})

export const saveSurchargeTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteSurchargeType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteSurchargeTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteSurchargeTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportSurchargeTypes = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
