import React, { Component } from 'react'

import { ColoredBox } from '../../../common/colored_box'
import * as formatters from '../../../common/formatters'

// eslint-disable-next-line react/prefer-stateless-function
class VehiclesHistoryListRow extends Component {
    render() {
        const item = this.props.item
        const nextItem = this.props.nextItem

        return (
            <tr>
                <td className="w-120">{formatters.datetimeFormatter(item.datetime_from)}</td>
                <td className="w-120">{formatters.datetimeFormatter(item.datetime_to)}</td>
                <td className={`w-200 ${nextItem && JSON.stringify(nextItem.driver) === JSON.stringify(item.driver) ? 'opacity-50' : ''}`}>
                    {item.driver && item.driver.name}
                </td>
                <td className={`w-max ${nextItem && JSON.stringify(nextItem.trailer) === JSON.stringify(item.trailer) ? 'opacity-25' : ''}`}>
                    {item.trailer && <ColoredBox>{item.trailer.registration}</ColoredBox>}
                </td>
            </tr>
        )
    }
}

export default VehiclesHistoryListRow
