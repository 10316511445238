import * as actionTypes from './actionTypes'

// Fetch

export const fetchGoodsTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchGoodsTypesFulfilled = goodsTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: goodsTypes,
})

export const fetchGoodsTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchGoodsTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchGoodsType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchGoodsTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchGoodsTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchGoodsTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveGoodsType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveGoodsTypeFulfilled = goodsType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: goodsType,
})

export const saveGoodsTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteGoodsType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteGoodsTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteGoodsTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportGoodsTypes = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
