import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchReportTrailerResultsEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request({
                method: 'GET',
                path: `report-trailer-results?year=${action.payload.year}&filters=${JSON.stringify(action.payload.filters)}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportTrailerResultsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportTrailerResultsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    )

// Export

const exportReportTrailerResultsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const year = action.payload.year
        const filters = JSON.stringify(action.payload.filters)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-trailer-results/export?year=${year}&filters=${filters}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Fetch details

const fetchReportTrailerResultsDetailsEpic = action$ =>
    action$.ofType(actionTypes.FETCH_DETAILS).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DETAILS_STARTED,
            }),
            request({
                method: 'GET',
                path: `report-trailer-results/details?filters=${JSON.stringify({ ...action.payload })}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportTrailerResultsDetailsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportTrailerResultsDetailsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_DETAILS_CANCELLED))
        )
    )

// Export details

const exportReportTrailerResultsDetailsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_DETAILS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-trailer-results/details/export?filters=${JSON.stringify({ ...action.payload })}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_DETAILS_FULFILLED,
            })
        )
    })

export default [
    fetchReportTrailerResultsEpic,
    exportReportTrailerResultsEpic,
    fetchReportTrailerResultsDetailsEpic,
    exportReportTrailerResultsDetailsEpic,
]
