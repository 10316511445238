import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class VehicleTypesListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render = () => (
        <tr onClick={this.handleClick} className="row-selectable">
            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                <td className="w-60 table-buttons">
                    <PermissionsCheck has={[PERMS.UPDATE]}>
                        <button className="far fa-pencil text-gray m-r-xxs" />
                    </PermissionsCheck>
                    <PermissionsCheck has={[PERMS.DELETE]}>
                        <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                    </PermissionsCheck>
                </td>
            </PermissionsCheck>
            <td className="w-max wm-200">{this.props.data.name}</td>

            <td className="w-200 text-right">
                {formatters.dimensionsFormatter(this.props.data.width, this.props.data.height, this.props.data.length)}
            </td>
            <td className="w-140 text-right">{formatters.weightFormatter(this.props.data.weight)}</td>
            <td className="w-140 text-right">{formatters.volumeFormatter(this.props.data.tank_volume)}</td>
            <td className="w-140 text-right">{formatters.integerFormatter(this.props.data.axles_count)}</td>
            <td className="w-200 text-right">{formatters.consumptionFormatter(this.props.data.avg_fuel_consumption)}</td>
            <td className="w-200 text-right">{formatters.consumptionFormatter(this.props.data.avg_adblue_consumption, '0,0.000')}</td>
        </tr>
    )
}

export default VehicleTypesListRow
