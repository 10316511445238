import React from 'react'
import { Route, Switch } from 'react-router'
import ReportCostCenterPerformance from './ReportCostCenterPerformance'

const Components = props => (
    <Switch>
        <Route exact path={`${props.match.url}/:year?`} component={ReportCostCenterPerformance} />
    </Switch>
)

export default Components
