import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportVehicleTraffic = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportVehicleTrafficFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportVehicleTrafficRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportVehicleTrafficCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportVehicleTraffic = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportVehicleTraffic = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportVehicleTrafficDetails = filters => ({
    type: actionTypes.FETCH_DETAILS,
    payload: filters,
})

export const fetchReportVehicleTrafficDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportVehicleTrafficDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportVehicleTrafficDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportVehicleTrafficDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportVehicleTrafficDetails = filters => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: filters,
})
