import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'
import { Modal } from 'react-bootstrap'

import * as config from '../../../common/config'
import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { getListItemsCountMessage, handleCommonListActions, url, getPrerequisitesArray } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import {
    TableSortButtons,
    TableFilterInputField,
    TableFilterSelectField,
    TableFilterCheckboxField
} from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'

import * as actionCreators from '../actionCreators'
import {
    getData,
    getCreateCreditNote,
    getCreateCreditNoteFetching,
    getCreateCreditNoteError,
    getExportToAccountingInProgress,
    getExportToAccountingError,
    getPdfCreatingData,
    getPdfCreatingInProgress,
    getPdfCreatingError,
    getInvoiceSending,
    getInvoiceSendingError,
} from '../selectors'

import { getSettings } from '../../auth/selectors'

import InvoicingInvoicesRow from './InvoicingInvoicesRow'
import InvoiceDetail from './InvoiceDetail'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'invoicing_invoices_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'invoicing_invoices_list'
const prerequisites = ['company_cost_centers']

const columns = {
    transport_id: new TableModelColumn({}),
}

class InvoicingInvoices extends Component {
    state = {
        modalInvoiceDetail: {
            show: false,
            invoiceId: null,
        },
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // handlers
    handleExport = e => {
        e && e.preventDefault()

        this.onExportButtonClick()
    }

    handleExportToAccounting = e => {
        e && e.preventDefault()

        this.onExportToAccountingButtonClick()
    }

    // events
    onExportButtonClick = () => {
        this.props.actions.exportInvoicingInvoices(this.props.tableModel.get('filters'), this.props.tableModel.get('sorting').toJS())
    }

    onExportToAccountingButtonClick = () => {
        this.props.actions.exportInvoicingInvoicesToAccounting()
    }

    onRowClick = item => {
        this.setState({
            modalInvoiceDetail: {
                show: true,
                invoiceId: item.id,
            },
        })
    }

    onEditButtonClick = item => {
        this.props.redirect(`/invoicing-invoices/${item.id}`)
    }

    onCreateCreditNoteButtonClick = item => {
        this.props.actions.createCreditNote(item.id)
    }

    onDeleteButtonClick = item => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteInvoice(item.id)
                this.hideConfirmDialog()
            },
        })
    }

    onDownloadPdfButtonClick = item => {
        const language = this.props.intl.locale ? this.props.intl.locale : 'cs'
        const company = item.company_id
        this.props.actions.createInvoicePdf(item.id, { language, company })
    }

    onInvoiceDetailHide = () => {
        this.setState({
            modalInvoiceDetail: {
                show: false,
            },
        })
    }

    refresh = () => {
        this.props.actions.fetchInvoicingInvoices(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    modalInvoiceSendToEmail = (id, email_address, files, documents, locale, company) => {
        this.props.actions.sendInvoiceToEmail(id, { email_address, files, documents, locale, company })
    }

    componentDidMount() {
        this.props.actions.fetchInvoicingInvoices(this.props.tableModel.get('filters'))
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)

        if (this.props.createCreditNoteFetching && !nextProps.createCreditNoteFetching && nextProps.createCreditNote) {
            this.props.redirect(`/invoicing-invoices/${nextProps.createCreditNote.id}`)
        }

        // Invoice download
        if (this.props.pdfCreatingInProgress && !nextProps.pdfCreatingInProgress) {
            if (nextProps.pdfCreatingError !== null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.pdfCreatingError,
                        position: 'tc',
                    },
                    'error'
                )
            } else if (nextProps.pdfCreatingData && nextProps.pdfCreatingData.file_name) {
                window.open(`${config.CFG_API_BASE_URL}/files/invoicing-invoices/${nextProps.pdfCreatingData.file_name}`)
            }
        }

        // Invoice sending
        if (this.props.invoiceSending && !nextProps.invoiceSending) {
            if (nextProps.invoiceSendingError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.invoiceSendingSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.invoiceSendingError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // export to accounting
        if (this.props.exportToAccountingInProgress && !nextProps.exportToAccountingInProgress) {
            if (nextProps.exportToAccountingError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: `alerts.titles.success` }),
                        message: this.props.intl.formatMessage({ id: `alerts.messages.exportSuccess` }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: `alerts.titles.error` }),
                        message: nextProps.invoiceError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.invoicingInvoices.heading' }))

        const sorting = this.props.tableModel.get('sorting')
        const filters = this.props.tableModel.get('filters')
        const settings = this.props.settings && this.props.settings.toJS()

        const paidOptions = [
            { id: 0, name: this.props.intl.formatMessage({ id: 'fields.unpaid' }) },
            { id: 1, name: this.props.intl.formatMessage({ id: 'fields.paid' }) },
            { id: 2, name: this.props.intl.formatMessage({ id: 'fields.overdue' }) },
        ]

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <LoadingOverlay active={this.props.exportToAccountingInProgress}>
                            <div className="panel panel-white">
                                <div className="panel-body">
                                    <h4>
                                        <FormattedMessage id="modules.invoicingInvoices.heading" />
                                    </h4>

                                    <div className="pull-left" style={{ marginLeft: '100px' }}>
                                        <TableFilterCheckboxField
                                            identifier="with_trashed"
                                            type={TableModelColumnFilteringType.equal}
                                            filters={filters}
                                            onChange={this.props.table.changeFilter}
                                            label={this.props.intl.formatMessage({ id: 'fields.showWithTrashed' })}
                                        />
                                    </div>

                                    <div className="pull-left" style={{ marginLeft: '20px' }}>
                                        <TableFilterCheckboxField
                                            identifier="only_trashed"
                                            type={TableModelColumnFilteringType.equal}
                                            filters={filters}
                                            onChange={this.props.table.changeFilter}
                                            label={this.props.intl.formatMessage({ id: 'fields.showOnlyTrashed' })}
                                        />
                                    </div>

                                    <div className="btns-list">
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                        {settings && settings.accounting_sw_name && (
                                            <PermissionsCheck has={[PERMS.EXPORT_TO_ACCOUNTING]}>
                                                <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExportToAccounting}>
                                                    <i className="far fa-arrow-from-bottom" /> <FormattedMessage id="buttons.exportTo" />{' '}
                                                    {settings.accounting_sw_name}
                                                </button>
                                            </PermissionsCheck>
                                        )}
                                        <PermissionsCheck has={[PERMS.CREATE]}>
                                            <Link to={url(this.props.match, 'invoicing-invoices/new')} className="btn btn-addon btn-success">
                                                <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                                            </Link>
                                        </PermissionsCheck>
                                    </div>

                                    <div className="table-container">
                                        <table className="table table-striped table-hover table-fixed-header table-transports">
                                            <thead>
                                                <tr>
                                                    <th className="w-60" />
                                                    <th className="w-120">
                                                        <FormattedMessage id="fields.number" />
                                                        <TableSortButtons
                                                            identifier="number"
                                                            sorting={sorting}
                                                            onChange={this.props.table.changeSorting}
                                                        />
                                                        <br />
                                                        <small>
                                                            <FormattedMessage id="fields.imported" />
                                                            <TableSortButtons
                                                                identifier="imported_at"
                                                                sorting={sorting}
                                                                onChange={this.props.table.changeSorting}
                                                            />
                                                        </small>
                                                    </th>
                                                    <th className="w-90 text-center">
                                                        <FormattedMessage id="fields.dateIssue" />
                                                        <TableSortButtons
                                                            identifier="date_issued"
                                                            sorting={sorting}
                                                            onChange={this.props.table.changeSorting}
                                                        />
                                                    </th>
                                                    <th className="w-90 text-center">
                                                        <FormattedMessage id="fields.dateDue" />
                                                        <TableSortButtons
                                                            identifier="date_due"
                                                            sorting={sorting}
                                                            onChange={this.props.table.changeSorting}
                                                        />
                                                    </th>
                                                    <th className="w-90 text-center">
                                                        <FormattedMessage id="fields.dateTax" />
                                                        <TableSortButtons
                                                            identifier="date_tax"
                                                            sorting={sorting}
                                                            onChange={this.props.table.changeSorting}
                                                        />
                                                    </th>
                                                    <th className="w-300">
                                                        <FormattedMessage id="fields.company" />
                                                    </th>
                                                    <th className="w-160">
                                                        <FormattedMessage id="fields.bankAccount" />
                                                    </th>
                                                    <th className="w-100">
                                                        <FormattedMessage id="fields.costCenter" />
                                                    </th>
                                                    <th className="w-max wm-300">
                                                        <FormattedMessage id="fields.customer" />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.totalExclVat" />
                                                        <TableSortButtons
                                                            identifier="excl_vat"
                                                            sorting={sorting}
                                                            onChange={this.props.table.changeSorting}
                                                        />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.vat" />
                                                        <TableSortButtons
                                                            identifier="vat"
                                                            sorting={sorting}
                                                            onChange={this.props.table.changeSorting}
                                                        />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.totalInclVat" />
                                                        <TableSortButtons
                                                            identifier="incl_vat"
                                                            sorting={sorting}
                                                            onChange={this.props.table.changeSorting}
                                                        />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.paid" />
                                                    </th>
                                                </tr>
                                                <tr className="filters">
                                                    <th className="w-60" />
                                                    <th className="w-120">
                                                        <TableFilterInputField
                                                            identifier="number"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                        />
                                                    </th>
                                                    <th className="w-90" />
                                                    <th className="w-90" />
                                                    <th className="w-90" />
                                                    <th className="w-300">
                                                        <TableFilterInputField
                                                            identifier="supplier"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                        />
                                                    </th>
                                                    <th className="w-160">
                                                        <TableFilterInputField
                                                            identifier="bankAccount"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                        />
                                                    </th>
                                                    <th className="w-100">
                                                        <TableFilterSelectField
                                                            identifier="company_cost_center_id"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={getPrerequisitesArray(
                                                                this.props.prerequisites.values.get('company_cost_centers')
                                                            )}
                                                        />
                                                    </th>
                                                    <th className="w-max wm-300">
                                                        <TableFilterInputField
                                                            identifier="customer"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                        />
                                                    </th>
                                                    <th className="w-120" />
                                                    <th className="w-120" />
                                                    <th className="w-120" />
                                                    <th className="w-120">
                                                        <TableFilterSelectField
                                                            identifier="paid"
                                                            type={TableModelColumnFilteringType.equal}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            values={paidOptions}
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.data &&
                                                    this.props.data.data.map(item => (
                                                        <InvoicingInvoicesRow
                                                            key={`InvoicingInvoicesRow-${item.id}`}
                                                            item={item}
                                                            onClick={this.onRowClick}
                                                            onEditButtonClick={this.onEditButtonClick}
                                                            onCreateCreditNoteButtonClick={this.onCreateCreditNoteButtonClick}
                                                            onDeleteButtonClick={this.onDeleteButtonClick}
                                                            onDownloadPdfButtonClick={this.onDownloadPdfButtonClick}
                                                        />
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="pull-left m-l-xxs m-t-md">
                                        <FormattedMessage id="pagination.totalRecords" />:{' '}
                                        {getListItemsCountMessage(
                                            clientSideItemsPerPage,
                                            this.props.data.count,
                                            this.props.tableModel.getIn(['pagination', 'totalCount'])
                                        )}
                                    </div>

                                    <ReactPaginate
                                        containerClassName="pagination"
                                        breakLabel={<span className="disabled">...</span>}
                                        activeClassName="active"
                                        pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.data.count, clientSideItemsPerPage)}
                                        pageRangeDisplayed={10}
                                        marginPagesDisplayed={2}
                                        forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                        onPageChange={this.props.table.changePage}
                                        previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                        nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                    />
                                </div>
                            </div>
                        </LoadingOverlay>
                    </div>

                    <ConfirmDialog options={this.state.confirmDialog} />

                    <Modal show={Boolean(this.state.modalInvoiceDetail.show)} onHide={this.onInvoiceDetailHide}>
                        <Modal.Header closeButton />
                        <Modal.Body>
                            {Boolean(this.state.modalInvoiceDetail.show) && (
                                <InvoiceDetail
                                    invoiceId={this.state.modalInvoiceDetail.invoiceId}
                                    onCancel={this.onInvoiceDetailHide}
                                    onSendToEmail={this.modalInvoiceSendToEmail}
                                />
                            )}
                        </Modal.Body>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getData,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        createCreditNote: getCreateCreditNote(state),
        createCreditNoteFetching: getCreateCreditNoteFetching(state),
        createCreditNoteError: getCreateCreditNoteError(state),
        settings: getSettings(state),
        exportToAccountingInProgress: getExportToAccountingInProgress(state),
        exportToAccountingError: getExportToAccountingError(state),
        pdfCreatingData: getPdfCreatingData(state),
        pdfCreatingInProgress: getPdfCreatingInProgress(state),
        pdfCreatingError: getPdfCreatingError(state),
        invoiceSending: getInvoiceSending(state),
        invoiceSendingError: getInvoiceSendingError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(InvoicingInvoices)
)
