export const PERMS = {
    READ: 'vehicles.read',
    UPDATE: 'vehicles.update',
    DELETE: 'vehicles.delete',
    CREATE: 'vehicles.create',
    RESTORE: 'vehicles.restore',
    EXPORT: 'vehicles.export',
}

export const PERMS_HISTORY = {
    READ: 'vehicle_history.read',
    UPDATE: 'vehicle_history.update',
    DELETE: 'vehicle_history.delete',
    CREATE: 'vehicle_history.create',
    EXPORT: 'vehicle_history.export',
}

export const PERMS_DOCUMENTS = {
    READ: 'vehicle_documents.read',
    UPDATE: 'vehicle_documents.update',
    DELETE: 'vehicle_documents.delete',
    CREATE: 'vehicle_documents.create',
    EXPORT: 'vehicle_documents.export',
}

export const PERMS_EQUIPMENTS = {
    READ: 'vehicle_equipments.read',
    UPDATE: 'vehicle_equipments.update',
    DELETE: 'vehicle_equipments.delete',
    CREATE: 'vehicle_equipments.create',
    EXPORT: 'vehicle_equipments.export',
}

export const PERMS_EVENTS = {
    READ: 'vehicle_events.read',
    UPDATE: 'vehicle_events.update',
    DELETE: 'vehicle_events.delete',
    CREATE: 'vehicle_events.create',
    EXPORT: 'vehicle_events.export',
}

export const PERMS_FUELINGS = {
    READ: 'vehicle_fuelings.read',
    CREATE: 'vehicle_fuelings.create',
    UPDATE: 'vehicle_fuelings.update',
    DELETE: 'vehicle_fuelings.delete',
    EXPORT: 'vehicle_fuelings.export',
    IMPORT: 'vehicle_fuelings.import',
}
