import { Record } from 'immutable'
import { getTransportPointPlannedDatetimeFrom, getTransportPointPlannedDatetimeTo } from '../../common/helpers'

export class Transport extends Record({
    id: undefined,
    disponent_id: undefined,
    transport_state_id: undefined,
    not_approved: undefined,
    transport_type_id: undefined,
    cargo_type_id: undefined,
    goods_type_id: undefined,
    note: undefined,
    customers: undefined,
    is_cabotage: undefined,
    is_cabotage_auto: undefined,
    is_spedition: undefined,
    is_archived: undefined,
    is_deleted: undefined,
    vehicle_id: undefined,
    vehicle_note: undefined,
    dispatcher_id: undefined,
    carrier_id: undefined,
    carrier_order_number: undefined,
    carrier_price: undefined,
    carrier_currency_id: undefined,
    carrier_currency_rate: undefined,
    carrier_note: undefined,
    carrier_vehicle_registration: undefined,
    carrier_trailer_registration: undefined,
    carrier_driver_name: undefined,
    carrier_driver_phone: undefined,
    carrier_transport_description: undefined,
    created_at: undefined,
    transport_customers: undefined,
    transport_points: undefined,
    transport_point_start: undefined,
    transport_point_loading: undefined,
    transport_point_unloading: undefined,
    transport_point_actual: undefined,
    transport_routes: undefined,
    planned_price: undefined,
    planned_costs: undefined,
    planned_distance: undefined,
    real_price: undefined,
    real_costs: undefined,
    real_distance: undefined,
    specified_distance: undefined,
    invoicing_distance: undefined,
    fb_tmo_id: undefined,
    fb_sent_at: undefined,
    fixed_allowances: undefined,
    is_predeparture_check: undefined,
    is_template: undefined,
    template_name: undefined,
    template_identifier: undefined,
    drivers: undefined,
    predeparture_check: undefined,
    deleted_at: undefined,
    is_all_required_files_uploaded: undefined,
    transport_files: undefined,
    transport_parts: undefined,
    transport_conditions: undefined,
    company_cost_center_id: undefined,
    is_preloaded: undefined,
    preloading_datetime: undefined,
    preloading_address: undefined,
    preloading_note: undefined,
    scoring_answers: undefined,
    is_transport_customer_blocked: undefined,
}) {
    getPlannedProfit = () => this.planned_price - this.planned_costs
    getPlannedProfitPerc = () => this.getPlannedProfit() / this.planned_costs * 100
    getRealProfit = () => this.real_price - this.real_costs
    getRealProfitPerc = () => this.getRealProfit() / this.real_costs * 100
}

export class TransportCustomer extends Record({
    id: undefined,
    transport_id: undefined,
    customer_id: undefined,
    order_number: undefined,
    price: undefined,
    price_base: undefined,
    price_surcharges: undefined,
    currency_id: undefined,
    currency_rate: undefined,
    note: undefined,
    transport_description: undefined,
    invoicing_note: undefined,
    do_not_invoice: undefined,
    surcharges: undefined,
    invoicing_address_id: undefined,
    delivery_address_id: undefined,
    invoicing_distance: undefined,
}) {}

export class TransportFile extends Record({
    id: undefined,
    transport_id: undefined,
    name: undefined,
    filename: undefined,
    user_id: undefined,
}) {}

export class TransportPart extends Record({
    id: undefined,
    transport_id: undefined,
    transport_state_id: undefined,
    order: undefined,
    code: undefined,
    name: undefined,
    note: undefined,
    take_from_transport: undefined,
    carrier_id: undefined,
    carrier_order_number: undefined,
    carrier_price: undefined,
    carrier_currency_id: undefined,
    carrier_currency_rate: undefined,
    carrier_note: undefined,
    carrier_transport_description: undefined,
    loading_datetime_planned_from: undefined,
    loading_datetime_planned_to: undefined,
    loading_datetime_specified_from: undefined,
    loading_datetime_specified_to: undefined,
    loading_datetime_arrival: undefined,
    loading_datetime_finished: undefined,
    loading_name: undefined,
    loading_street: undefined,
    loading_city: undefined,
    loading_zipcode: undefined,
    loading_country_id: undefined,
    loading_note: undefined,
    unloading_datetime_planned_from: undefined,
    unloading_datetime_planned_to: undefined,
    unloading_datetime_specified_from: undefined,
    unloading_datetime_specified_to: undefined,
    unloading_datetime_arrival: undefined,
    unloading_datetime_finished: undefined,
    unloading_name: undefined,
    unloading_street: undefined,
    unloading_city: undefined,
    unloading_zipcode: undefined,
    unloading_country_id: undefined,
    unloading_note: undefined,
    transport_vehicle_type_id: undefined,
    transport_vehicle_number: undefined,
    transport_conditions: undefined,
}) {}

export class TransportCustomerSurcharge extends Record({
    id: undefined,
    transport_customer_id: undefined,
    surcharge_type_id: undefined,
    price: undefined,
}) {}

export class TransportPoint extends Record({
    id: undefined,
    transport_id: undefined,
    type_id: undefined,
    order: undefined,
    transport_point_state_id: undefined,
    datetime_planned_from: undefined,
    datetime_planned_to: undefined,
    datetime_specified_from: undefined,
    datetime_specified_to: undefined,
    datetime_booking_from: undefined,
    datetime_booking_to: undefined,
    datetime_arrival: undefined,
    datetime_finished: undefined,
    last_eta_update: undefined,
    name: undefined,
    street: undefined,
    city: undefined,
    zipcode: undefined,
    country_id: undefined,
    country: undefined,
    gps_lat: undefined,
    gps_lng: undefined,
    note: undefined,
    transport_point_data: undefined,
    transport_point_state: undefined,
    transport_events: undefined,
    transport_navi_events: undefined,
    fb_tmo_id: undefined,
    board_address: undefined,
    km: undefined,
    planned_distance: undefined,
    real_distance: undefined,
    vehicle_id: undefined,
    vehicle: undefined,
    trailer_id: undefined,
    trailer: undefined,
    is_booking_required: undefined,
    booking_url: undefined,
    booking_note: undefined,
}) {
    getPlannedDatetimeFrom = (returnAsMoment = false, datetimeFormat = 'YYYY-MM-DD HH:mm:SS') =>
        getTransportPointPlannedDatetimeFrom(this, returnAsMoment, datetimeFormat)

    getPlannedDatetimeTo = (returnAsMoment = false, datetimeFormat = 'YYYY-MM-DD HH:mm:SS') =>
        getTransportPointPlannedDatetimeTo(this, returnAsMoment, datetimeFormat)

    getDataItem = type_id => this.transport_point_data && this.transport_point_data.find(item => item.type_id === type_id)
}

export class TransportPointData extends Record({
    id: undefined,
    transport_point_id: undefined,
    type_id: undefined,
    value: undefined,
}) {}

export class TransportRoute extends Record({
    id: undefined,
    transport_id: undefined,
    type_id: undefined,
    distance: undefined,
    time_total: undefined,
    time_driving: undefined,
    time_rest: undefined,
    time_break: undefined,
    price: undefined,
    costs_total: undefined,
    costs_fuel: undefined,
    costs_adblue: undefined,
    costs_toll_per_km: undefined,
    costs_toll_per_day: undefined,
    costs_toll_passes: undefined,
    costs_fixed: undefined,
    rests_count: undefined,
    breaks_count: undefined,
    datetime_start: undefined,
    datetime_end: undefined,
    waypoints: undefined,
    positions: undefined,
    polyline: undefined,
}) {
    getProfit = () => this.price - this.costs_total
    getProfitPerc = () => this.getProfit() / this.costs_total * 100
    getWaypoints = () => JSON.parse(this.waypoints)
    getPositions = () => JSON.parse(this.positions || this.waypoints)
}

export class TransportEvent extends Record({
    id: undefined,
    transport_id: undefined,
    transport_point_id: undefined,
    type_id: undefined,
    datetime: undefined,
    vehicle_id: undefined,
    driver_id: undefined,
    driver_2_id: undefined,
    gps_lat: undefined,
    gps_lng: undefined,
    km: undefined,
    fb_tmo_id: undefined,
    transport_point: undefined,
    transport_point_files: undefined,
    transport_event_data: undefined,
    driver: undefined,
    vehicle: undefined,
}) {
    getGPS = () => (this.gps_lat && this.gps_lng ? `${this.gps_lat}, ${this.gps_lng}` : '')
    getDataItem = type_id => this.transport_event_data.find(item => item.type_id === type_id)
}

export class TransportNaviEvent extends Record({
    id: undefined,
    transport_id: undefined,
    transport_point_id: undefined,
    vehicle_id: undefined,
    datetime: undefined,
    type_id: undefined,
    fb_tmo_id: undefined,
    gps_lat: undefined,
    gps_lng: undefined,
}) {}

export class TransportEtaUpdate extends Record({
    id: undefined,
    transport_id: undefined,
    transport_point_id: undefined,
    vehicle_id: undefined,
    datetime: undefined,
    datetime_eta: undefined,
    datetime_real_eta: undefined,
    duration: undefined,
    distance: undefined,
    fb_tmo_id: undefined,
}) {}

export class TransportPointFile extends Record({
    id: undefined,
    transport_id: undefined,
    transport_point_id: undefined,
    transport_event_id: undefined,
    filename: undefined,
    fb_tmo_attachment_id: undefined,
    fb_tmo_attachment_name: undefined,
}) {}

export class TransportEventData extends Record({
    id: undefined,
    transport_event_id: undefined,
    type_id: undefined,
    value: undefined,
}) {}

export class TransportLog extends Record({
    id: undefined,
    transport_id: undefined,
    transport_point_id: undefined,
    transport_event_id: undefined,
    user_id: undefined,
    event: undefined,
    parameters: undefined,
    created_at: undefined,
    transport_log_changes: undefined,
}) {
    getParameters = () => (this.parameters ? JSON.parse(this.parameters) : {})
}

export class TransportLogChange extends Record({
    id: undefined,
    transport_log_id: undefined,
    type: undefined,
    original_value: undefined,
    original_system_value: undefined,
    new_value: undefined,
    new_system_value: undefined,
}) {}
