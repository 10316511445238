import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'

import * as reportCostCenterResultsActionCreators from '../actionCreators'
import { getData, getFetching } from '../selectors'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'report_dispatcher_kpi'

class ReportDispatcherKPI extends Component {
    refresh = () => {
        this.props.actions.fetchReportDispatcherKPI(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        const dateFrom = moment()
            .subtract(1, 'months')
            .startOf('month')
        const dateTo = moment()
            .subtract(1, 'months')
            .endOf('month')

        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportDispatcherKPI(this.props.tableModel.get('filters').toJS())
    }

    handleFilterChanged = (key, value) => {
        const filters = this.props.tableModel.get('filters')

        let stringFrom = filters.getIn(['date_from', 'value'])
        let stringTo = filters.getIn(['date_to', 'value'])

        if (key === 'period') {
            stringFrom = value.momentFrom.format('DD.MM.YYYY')
            stringTo = value.momentTo.format('DD.MM.YYYY')
        }

        this.props.table.changeFilters([
            {
                column: 'date_from',
                type: TableModelColumnFilteringType.equal,
                value: stringFrom,
            },
            {
                column: 'date_to',
                type: TableModelColumnFilteringType.equal,
                value: stringTo,
            },
        ])
    }

    componentDidMount() {
        const dateFrom = moment()
            .subtract(1, 'months')
            .startOf('month')
        const dateTo = moment()
            .subtract(1, 'months')
            .endOf('month')

        this.props.table.setConfiguration(
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ],
            false
        )

        this.props.actions.fetchReportDispatcherKPI(this.props.tableModel.get('filters'))
    }

    componentWillUnmount() {
        this.props.actions.clearReportDispatcherKPI()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportDispatcherKPI.heading' }))

        const data = this.props.data
        const filters = this.props.tableModel.get('filters')

        const stringFrom = filters.getIn(['date_from', 'value'])
        const stringTo = filters.getIn(['date_to', 'value'])

        const momentFrom = stringFrom ? helpers.convertCSDateToMoment(stringFrom) : moment().startOf('month')
        const momentTo = stringTo ? helpers.convertCSDateToMoment(stringTo) : moment().endOf('month')

        let totalLoadingSuccess = 0
        let totalLoadingFailed = 0
        let totalUnloadingSuccess = 0
        let totalUnloadingFailed = 0

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportDispatcherKPI.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className="m-r-lg"
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_MONTH}
                                            onChange={(momentFrom, momentTo) => this.handleFilterChanged('period', { momentFrom, momentTo })}
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-max">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-160 text-right">Nakládky - splněno</th>
                                                <th className="w-160 text-right">Nakládky - nesplněno</th>
                                                <th className="w-100 text-right">%</th>
                                                <th className="w-160 text-right">Vykládky - splněno</th>
                                                <th className="w-160 text-right">Vykládky - nesplněno</th>
                                                <th className="w-100 text-right">%</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.dispatchers &&
                                                data.dispatchers.map(dispatcher => {
                                                    totalLoadingSuccess += dispatcher.loadingSuccess
                                                    totalLoadingFailed += dispatcher.loadingFailed
                                                    totalUnloadingSuccess += dispatcher.unloadingSuccess
                                                    totalUnloadingFailed += dispatcher.unloadingFailed

                                                    return (
                                                        <tr key={`dispatcher-${dispatcher.id}`}>
                                                            <td className="w-max">
                                                                {formatters.shortFullName(dispatcher.first_name, dispatcher.last_name)}
                                                            </td>
                                                            <td className="w-160 text-right">
                                                                {formatters.integerFormatter(dispatcher.loadingSuccess)}
                                                            </td>
                                                            <td className="w-160 text-right">
                                                                {formatters.integerFormatter(dispatcher.loadingFailed)}
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {formatters.percentFormatter(
                                                                    (dispatcher.loadingSuccess / (dispatcher.loadingSuccess + dispatcher.loadingFailed)) *
                                                                        100
                                                                )}
                                                            </td>
                                                            <td className="w-160 text-right">
                                                                {formatters.integerFormatter(dispatcher.unloadingSuccess)}
                                                            </td>
                                                            <td className="w-160 text-right">
                                                                {formatters.integerFormatter(dispatcher.unloadingFailed)}
                                                            </td>
                                                            <td className="w-100 text-right">
                                                                {formatters.percentFormatter(
                                                                    (dispatcher.unloadingSuccess /
                                                                        (dispatcher.unloadingSuccess + dispatcher.unloadingFailed)) *
                                                                        100
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="w-max">
                                                    <strong>
                                                        <FormattedMessage id="fields.total" />:
                                                    </strong>
                                                </td>
                                                <td className="w-160 text-right">
                                                    <strong>{formatters.integerFormatter(totalLoadingSuccess)}</strong>
                                                </td>
                                                <td className="w-160 text-right">
                                                    <strong>{formatters.integerFormatter(totalLoadingFailed)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>
                                                        {formatters.percentFormatter(
                                                            (totalLoadingSuccess / (totalLoadingSuccess + totalLoadingFailed)) * 100
                                                        )}
                                                    </strong>
                                                </td>
                                                <td className="w-160 text-right">
                                                    <strong>{formatters.integerFormatter(totalUnloadingSuccess)}</strong>
                                                </td>
                                                <td className="w-160 text-right">
                                                    <strong>{formatters.integerFormatter(totalUnloadingFailed)}</strong>
                                                </td>
                                                <td className="w-100 text-right">
                                                    <strong>
                                                        {formatters.percentFormatter(
                                                            (totalUnloadingSuccess / (totalUnloadingSuccess + totalUnloadingFailed)) * 100
                                                        )}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        data: getData(state),
        fetching: getFetching(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportCostCenterResultsActionCreators,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ReportDispatcherKPI)
)
