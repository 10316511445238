import React from 'react'
import { Route, Switch } from 'react-router'
import serviceJobsList from './ServiceJobsList'
import serviceJobsEdit from './ServiceJobsEdit'

const serviceJobs = props => (
    <Switch>
        <Route exact path={props.match.url} component={serviceJobsList} />
        <Route exact path={`${props.match.url}/new`} component={serviceJobsEdit} />
        <Route exact path={`${props.match.url}/:serviceJobID`} component={serviceJobsEdit} />
    </Switch>
)

export default serviceJobs
