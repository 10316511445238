// Fetch list

export const FETCH = 'driversTypes.FETCH'
export const FETCH_STARTED = 'driversTypes.FETCH_STARTED'
export const FETCH_FULFILLED = 'driversTypes.FETCH_FULFILLED'
export const FETCH_REJECTED = 'driversTypes.FETCH_REJECTED'
export const FETCH_CANCELLED = 'driversTypes.FETCH_CANCELLED'

export const SORTING_CHANGED = 'driversTypes.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'driversTypes.FETCH_ONE'
export const FETCH_ONE_STARTED = 'driversTypes.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'driversTypes.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'driversTypes.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'driversTypes.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'driversTypes.SAVE'
export const SAVE_STARTED = 'driversTypes.SAVE_STARTED'
export const SAVE_FULFILLED = 'driversTypes.SAVE_FULFILLED'
export const SAVE_REJECTED = 'driversTypes.SAVE_REJECTED'
export const SAVE_CANCELLED = 'driversTypes.SAVE_CANCELLED'

// Delete

export const DELETE = 'driversTypes.DELETE'
export const DELETE_STARTED = 'driversTypes.DELETE_STARTED'
export const DELETE_FULFILLED = 'driversTypes.DELETE_FULFILLED'
export const DELETE_REJECTED = 'driversTypes.DELETE_REJECTED'
export const DELETE_CANCELLED = 'driversTypes.DELETE_CANCELLED'
