import * as actionTypes from './actionTypes'

// Fetch

export const fetchCustomers = () => ({
    type: actionTypes.FETCH,
})

export const fetchCustomersFulfilled = customers => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: customers,
})

export const fetchCustomersRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCustomersCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCustomer = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCustomerFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCustomerRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCustomerCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCustomer = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveCustomerFulfilled = customer => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: customer,
})

export const saveCustomerRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveCustomerDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteCustomer = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCustomerFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCustomerRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})



// Restore

export const restoreCustomer = id => ({
    type: actionTypes.RESTORE,
    payload: id,
})

export const restoreCustomerFulfilled = item => ({
    type: actionTypes.RESTORE_FULFILLED,
    payload: item,
})

export const restoreCustomerRejected = message => ({
    type: actionTypes.RESTORE_REJECTED,
    payload: message,
})

// Load info by VAT number

export const loadInfoByVATNumber = vatNumber => ({
    type: actionTypes.LOAD_INFO,
    payload: vatNumber,
})

export const loadInfoByVATNumberFulfilled = vatNumber => ({
    type: actionTypes.LOAD_INFO_FULFILLED,
    payload: vatNumber,
})

export const loadInfoByVATNumberRejected = message => ({
    type: actionTypes.LOAD_INFO_REJECTED,
    payload: message,
})

export const clearInfoByVATNumber = () => ({
    type: actionTypes.CLEAR_INFO,
})

// Export

export const exportCustomers = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Fetch customer addresses

export const fetchCustomerAddresses = (id, page) => ({
    type: actionTypes.FETCH_ADDRESSES,
    payload: {
        id,
        page,
    },
})

export const fetchCustomerAddressesFulfilled = data => ({
    type: actionTypes.FETCH_ADDRESSES_FULFILLED,
    payload: data,
})

export const fetchCustomerAddressesRejected = message => ({
    type: actionTypes.FETCH_ADDRESSES_REJECTED,
    payload: message,
})

export const fetchCustomerAddressesCancelled = () => ({
    type: actionTypes.FETCH_ADDRESSES_CANCELLED,
})

// Export customer addresses

export const exportCustomerAddresses = id => ({
    type: actionTypes.EXPORT_ADDRESSES,
    payload: id,
})

export const exportCustomerAddressesFulfilled = data => ({
    type: actionTypes.EXPORT_ADDRESSES_FULFILLED,
    payload: data,
})

export const exportCustomerAddressesRejected = message => ({
    type: actionTypes.EXPORT_ADDRESSES_REJECTED,
    payload: message,
})

export const exportCustomerAddressesCancelled = () => ({
    type: actionTypes.EXPORT_ADDRESSES_CANCELLED,
})

// Fetch customer contacts

export const fetchCustomerContacts = (id, page) => ({
    type: actionTypes.FETCH_CONTACTS,
    payload: {
        id,
        page,
    },
})

export const fetchCustomerContactsFulfilled = data => ({
    type: actionTypes.FETCH_CONTACTS_FULFILLED,
    payload: data,
})

export const fetchCustomerContactsRejected = message => ({
    type: actionTypes.FETCH_CONTACTS_REJECTED,
    payload: message,
})

export const fetchCustomerContactsCancelled = () => ({
    type: actionTypes.FETCH_CONTACTS_CANCELLED,
})

// Export customer contacts

export const exportCustomerContacts = id => ({
    type: actionTypes.EXPORT_CONTACTS,
    payload: id,
})

export const exportCustomerContactsFulfilled = data => ({
    type: actionTypes.EXPORT_CONTACTS_FULFILLED,
    payload: data,
})

export const exportCustomerContactsRejected = message => ({
    type: actionTypes.EXPORT_CONTACTS_REJECTED,
    payload: message,
})

export const exportCustomerContactsCancelled = () => ({
    type: actionTypes.EXPORT_CONTACTS_CANCELLED,
})

// Fetch customer plans

export const fetchCustomerPlans = (id, page) => ({
    type: actionTypes.FETCH_PLANS,
    payload: {
        id,
        page,
    },
})

export const fetchCustomerPlansFulfilled = data => ({
    type: actionTypes.FETCH_PLANS_FULFILLED,
    payload: data,
})

export const fetchCustomerPlansRejected = message => ({
    type: actionTypes.FETCH_PLANS_REJECTED,
    payload: message,
})

export const fetchCustomerPlansCancelled = () => ({
    type: actionTypes.FETCH_PLANS_CANCELLED,
})

// Export customer plans

export const exportCustomerPlans = id => ({
    type: actionTypes.EXPORT_PLANS,
    payload: id,
})

export const exportCustomerPlansFulfilled = data => ({
    type: actionTypes.EXPORT_PLANS_FULFILLED,
    payload: data,
})

export const exportCustomerPlansRejected = message => ({
    type: actionTypes.EXPORT_PLANS_REJECTED,
    payload: message,
})

export const exportCustomerPlansCancelled = () => ({
    type: actionTypes.EXPORT_PLANS_CANCELLED,
})

// Check duplicity

export const checkCustomerDuplicity = values => ({
    type: actionTypes.CHECK_DUPLICITY,
    payload: values,
})

export const checkCustomerDuplicityFulfilled = data => ({
    type: actionTypes.CHECK_DUPLICITY_FULFILLED,
    payload: data,
})

export const checkCustomerDuplicityRejected = message => ({
    type: actionTypes.CHECK_DUPLICITY_REJECTED,
    payload: message,
})

export const checkCustomerDuplicityCancelled = () => ({
    type: actionTypes.CHECK_DUPLICITY_CANCELLED,
})


// Fetch import headers

export const fetchCustomersImportHeaders = file => ({
    type: actionTypes.FETCH_IMPORT_HEADERS,
    payload: file,
})

export const fetchCustomersImportHeadersFulfilled = headers => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_FULFILLED,
    payload: headers,
})

export const fetchCustomersImportHeadersRejected = message => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_REJECTED,
    payload: message,
})

export const fetchCustomersImportHeadersCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_HEADERS_CANCELLED,
})

// Fetch import items

export const fetchCustomersImportItems = (fileId, columnTypes) => ({
    type: actionTypes.FETCH_IMPORT_ITEMS,
    payload: {
        fileId,
        columnTypes,
    },
})

export const fetchCustomersImportItemsFulfilled = (items) => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_FULFILLED,
    payload: {
        items,
    },
})

export const fetchCustomersImportItemsRejected = message => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_REJECTED,
    payload: message,
})

export const fetchCustomersImportItemsCancelled = () => ({
    type: actionTypes.FETCH_IMPORT_ITEMS_CANCELLED,
})

// Import

export const importCustomers = (fileId, columnTypes, keys) => ({
    type: actionTypes.IMPORT_CUSTOMERS,
    payload: {
        fileId,
        columnTypes,
        keys
    },
})

export const importCustomersFulfilled = () => ({
    type: actionTypes.IMPORT_CUSTOMERS_FULFILLED,
})

export const importCustomersRejected = message => ({
    type: actionTypes.IMPORT_CUSTOMERS_REJECTED,
    payload: message,
})

export const importCustomersCancelled = () => ({
    type: actionTypes.IMPORT_CUSTOMERS_CANCELLED,
})
