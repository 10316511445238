import { createSelector } from 'reselect'

export const getCustomers = state => state.customers.getIn(['all', 'objects'])

export const createGetCustomerByIDSelector = id => createSelector([getCustomers], customers => customers.get(String(id)))

const getCurrentCustomerStatus = state => state.customers.get('current')
export const createGetCurrentCustomerStatusSelector = () => createSelector([getCurrentCustomerStatus], status => status)

export const getCustomerDeleting = state => state.customers.getIn(['deletion', 'inProgress'])
export const getCustomerDeleteError = state => state.customers.getIn(['deletion', 'error'])

const getInfoByVAT = state => state.customers.get('infoByVAT')
export const createGetInfoByVATSelector = () => createSelector([getInfoByVAT], data => data.get('data'))
export const getInfoByVATLoading = state => state.customers.getIn(['infoByVAT', 'inProgress'])
export const getInfoByVATError = state => state.customers.getIn(['infoByVAT', 'error'])

const getDuplicity = state => state.customers.getIn(['all', 'duplicity'])
export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)

export const getCustomerAddressesData = state => state.customers.getIn(['addresses', 'data'])
export const getCustomerAddressesFetching = state => state.customers.getIn(['addresses', 'fetching'])
export const getCustomerAddressesError = state => state.customers.getIn(['addresses', 'error'])

export const getCustomerContactsData = state => state.customers.getIn(['contacts', 'data'])
export const getCustomerContactsFetching = state => state.customers.getIn(['contacts', 'fetching'])
export const getCustomerContactsError = state => state.customers.getIn(['contacts', 'error'])

export const getCustomerPlansData = state => state.customers.getIn(['plans', 'data'])
export const getCustomerPlansFetching = state => state.customers.getIn(['plans', 'fetching'])
export const getCustomerPlansError = state => state.customers.getIn(['plans', 'error'])

export const getCustomerDuplicityChecking = state => state.customers.getIn(['checkingDuplicity', 'inProgress'])
export const getCustomerDuplicityCheckingError = state => state.customers.getIn(['checkingDuplicity', 'error'])
export const getCustomerDuplicityCheckingData = state => state.customers.getIn(['checkingDuplicity', 'data'])


// Import

export const getImportInProgress = state => state.customers.getIn(['import', 'inProgress'])
export const getImportError = state => state.customers.getIn(['import', 'error'])
export const getImportFileId = state => state.customers.getIn(['import', 'file_id'])
export const getImportHeaders = state => state.customers.getIn(['import', 'headers'])
export const getImportDefaultHeaders = state => state.customers.getIn(['import', 'headersDefault'])
export const getImportItems = state => state.customers.getIn(['import', 'items'])
