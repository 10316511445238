// Fetch list

export const FETCH = 'reportPallets.FETCH'
export const FETCH_STARTED = 'reportPallets.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportPallets.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportPallets.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportPallets.FETCH_CANCELLED'

export const SORTING_CHANGED = 'reportPallets.SORTING_CHANGED'

// Clear

export const CLEAR = 'reportPallets.CLEAR'

// Export

export const EXPORT = 'reportPallets.EXPORT'
export const EXPORT_FULFILLED = 'reportPallets.EXPORT_FULFILLED'
