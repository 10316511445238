import { Record } from 'immutable'

export class TrailerCurrentPosition extends Record({
    id: undefined,
    trailer_id: undefined,
    vehicle_id: undefined,
    driver_id: undefined,
    datetime: undefined,
    gps_lat: undefined,
    gps_lng: undefined,
    position_name: undefined,
    is_loaded: undefined,
    created_at: undefined,
}) {
    getGPS = () => (this.gps_lat && this.gps_lng ? `${this.gps_lat}, ${this.gps_lng}` : '')
}
