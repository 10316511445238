import * as actionTypes from './actionTypes'

// Fetch

export const fetchCustomerAddressTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchCustomerAddressTypesFulfilled = customerAddressTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: customerAddressTypes,
})

export const fetchCustomerAddressTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCustomerAddressTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCustomerAddressType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCustomerAddressTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCustomerAddressTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCustomerAddressTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCustomerAddressType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveCustomerAddressTypeFulfilled = customerAddressType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: customerAddressType,
})

export const saveCustomerAddressTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteCustomerAddressType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCustomerAddressTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCustomerAddressTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportCustomerAddressTypes = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
