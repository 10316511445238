import React, { Component } from 'react'
import { is } from 'immutable'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import * as formatters from '../../../common/formatters'
import ColoredBox from '../../../common/colored_box/components/ColoredBox'
import {
    MapPageVehiclesListRowVehiclePosition,
    MapPageVehiclesListRowTime,
    MapPageVehiclesListRowNavigation,
    MapPageVehiclesListRowTrailerIconState,
    MapPageVehiclesListRowTrailerIconDamaged,
    MapPageVehiclesListRowTrailerIconDamagedSecurity,
    MapPageVehiclesListRowTrailerIconDamagedCooling,
} from '../../map/components/MapPageVehiclesListRow'

import PermissionsProps from '../../auth/components/PermissionsProps'
import { PERMS } from '../permissions'

import Day from './Day'
import WeekendAtHome from './WeekendAtHome'

class VehicleRow extends Component {
    handleVehicleNoteChange = () => {
        this.props.handleVehicleNoteChange(this.props.item)
    }

    handleTrailerNoteChange = () => {
        this.props.handleTrailerNoteChange(this.props.item)
    }

    handleWeekendAtHomeClick = (date, atHome, note) => {
        this.props.handleWeekendAtHomeClick(this.props.vehicleID, date, atHome, note)
    }

    handleWeekendAtHomeChange = (date, atHome) => {
        this.props.handleWeekendAtHomeChange(this.props.vehicleID, date, atHome)
    }

    handleDayNoteChange = (date, currentNote) => {
        this.props.handleDayNoteChange(this.props.item, date, currentNote)
    }

    handleTrailerClick = e => {
        e && e.preventDefault()
        this.props.handleTrailerClick(this.props.item)
    }

    handleIsDoneChange = e => {
        e && e.preventDefault()
        this.props.handleIsDoneChange(this.props.item.id, !(this.props.item.daily_info && this.props.item.daily_info.is_done))
    }

    handlePointNoteChange = transportPoint => {
        this.props.handlePointNoteChange(transportPoint)
    }

    shouldComponentUpdate(nextProps) {
        return !is(this.props.item, nextProps.item) || !is(this.props.days, nextProps.days)
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const item = this.props.item
        const driver = item.driver
        const driverTimes = driver && driver.times
        const position = item.position
        const event = item.event
        const trailer = event && event.trailer
        const isDone = item.daily_info && item.daily_info.is_done
        const documents = item.documents
        const equipments = item.equipments
        const lastEtaUpdate = item.last_eta_update

        const tooltipIsDone = (
            <Tooltip id="tooltipIsDone">
                <strong>{this.props.intl.formatMessage({ id: isDone ? 'modules.board.isDone' : 'modules.board.isNotDone' })}</strong>
            </Tooltip>
        )

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <tr className={isDone ? 'is-done' : ''}>
                <td className="b-r w-350 board-vehicle">
                    <div className="w-90 pull-left">
                        <div>
                            <ColoredBox
                                bgRGB={item.dispatcher && item.dispatcher.rgb_bg}
                                textRGB={item.dispatcher && item.dispatcher.rgb_text}
                                border={item.dispatcher && item.dispatcher.rgb_bg && `1px solid ${item.dispatcher.rgb_bg}`}
                            >
                                {item.name}
                            </ColoredBox>
                        </div>
                        <div className="m-t-xxs">
                            {trailer ? (
                                <PermissionsProps has={[PERMS.UPDATE]} props={{ onClick: this.handleTrailerClick }}>
                                    <a href="#">
                                        <ColoredBox bgRGB="none" border="1px solid #CCCCCC">
                                            {trailer.name}
                                        </ColoredBox>
                                    </a>
                                </PermissionsProps>
                            ) : (
                                <ColoredBox bgRGB="none" border="1px solid #CCCCCC">
                                    -
                                </ColoredBox>
                            )}
                        </div>
                    </div>
                    <div className="w-240 pull-right">
                        <small>
                            {driver ? (
                                <React.Fragment>
                                    <span className="m-r-xs">{driver.name}</span>
                                    {driverTimes && (
                                        <span>
                                            <span className="text-gray-light">
                                                {`${formatters.timeFromSecondsFormatter(driverTimes.day_drive_time)} / `}
                                            </span>
                                            {formatters.timeFromSecondsFormatter(driverTimes.day_drive_time_remaining)}
                                        </span>
                                    )}
                                </React.Fragment>
                            ) : (
                                '-'
                            )}
                            <br />
                            <span className="text-gray-light">{item.dispatcher ? item.dispatcher.name : '-'}</span>
                        </small>
                        <span className="vehicle-state">
                            <MapPageVehiclesListRowVehiclePosition vehiclePosition={position} intl={this.props.intl} className="m-r-xxs" />
                            <MapPageVehiclesListRowTime time={position ? position.datetime : null} intl={this.props.intl} className="m-r-xxs" />
                            <MapPageVehiclesListRowNavigation
                                time={lastEtaUpdate ? lastEtaUpdate.datetime : null}
                                intl={this.props.intl}
                                className="m-r-xxs"
                            />
                            <a href="#" onClick={this.handleIsDoneChange}>
                                <OverlayTrigger placement="top" overlay={tooltipIsDone}>
                                    <i className={`fas fa-check ${isDone ? 'text-success' : 'text-danger'}`} />
                                </OverlayTrigger>
                            </a>
                        </span>
                        <span className="trailer-state">
                            <MapPageVehiclesListRowTrailerIconState vehicleEvent={event} intl={this.props.intl} className="m-r-xxs" />
                            <MapPageVehiclesListRowTrailerIconDamaged vehicleEvent={event} intl={this.props.intl} className="m-r-xxs" />
                            <MapPageVehiclesListRowTrailerIconDamagedSecurity vehicleEvent={event} intl={this.props.intl} className="m-r-xxs" />
                            <MapPageVehiclesListRowTrailerIconDamagedCooling vehicleEvent={event} intl={this.props.intl} />
                        </span>
                    </div>
                </td>
                <PermissionsProps has={[PERMS.UPDATE]} props={{ onClick: this.handleVehicleNoteChange }}>
                    <td className="w-140 board-notes wrapped">
                        {item.board_note}
                        {documents.map(document => {
                            const tooltip = <Tooltip id="tooltip">{document.text}</Tooltip>

                            return (
                                <span key={`document-${document.id}`} className="display-inline wp-100 text-danger">
                                    <OverlayTrigger placement="top" overlay={tooltip}>
                                        <span>
                                            <i className="fas fa-exclamation-triangle" /> {document.name}
                                        </span>
                                    </OverlayTrigger>
                                </span>
                            )
                        })}
                        {equipments.map(equipment => {
                            const tooltip = <Tooltip id="tooltip">{equipment.text}</Tooltip>

                            return (
                                <span key={`equipment-${equipment.id}`} className="display-inline wp-100 text-primary">
                                    <OverlayTrigger placement="top" overlay={tooltip}>
                                        <span>
                                            <i className="fas fa-exclamation-triangle" /> {equipment.name}
                                        </span>
                                    </OverlayTrigger>
                                </span>
                            )
                        })}
                    </td>
                </PermissionsProps>
                {this.props.days.map((date, dayKey) => {
                    const dayDate = date.format('YYYY-MM-DD')

                    return (
                        <React.Fragment key={`table-day-header-${dayDate}`}>
                            {date.weekday() === 5 && (
                                <WeekendAtHome
                                    date={date}
                                    weeklyInfo={item.weekly_info && item.weekly_info[date.format('YYYY-W')]}
                                    handleWeekendAtHomeClick={this.handleWeekendAtHomeClick}
                                    handleWeekendAtHomeChange={this.handleWeekendAtHomeChange}
                                />
                            )}
                            <Day
                                key={`day-${this.props.vehicleID}-${dayDate}`}
                                date={date}
                                dayKey={dayKey}
                                currentUserId={this.props.currentUserId}
                                vehicleID={this.props.vehicleID}
                                notes={item.daily_notes && item.daily_notes[dayDate]}
                                transports={item.transports}
                                transportPoints={item.transport_points && item.transport_points[dayDate]}
                                lastEvent={item.last_event}
                                lastEventDate={item.last_event_datetime && moment(item.last_event_datetime)}
                                trailer={trailer}
                                handleNoteChange={this.handleDayNoteChange}
                                handlePointNoteChange={this.handlePointNoteChange}
                                preferences={this.props.preferences}
                                handleTransportClick={this.props.handleTransportClick}
                            />
                        </React.Fragment>
                    )
                })}
            </tr>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

export default injectIntl(VehicleRow)
