import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class CarriersListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render = () => {

        const classNames = ['row-selectable']
        if (this.props.data.deleted_at) {
            classNames.push('is-deleted')
        }

        return(

            <tr onClick={this.handleClick} className={classNames.join(' ')}>
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        {!this.props.data.deleted_at && (
                            <PermissionsCheck has={[PERMS.DELETE]}>
                                <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                            </PermissionsCheck>
                        )}
                    </td>
                </PermissionsCheck>
                <td className="w-140">
                    <strong>{this.props.data.vat_number}</strong>
                </td>
                <td className="w-100">{this.props.data.reg_number}</td>
                <td className="w-500">
                    <strong>{this.props.data.name}</strong>
                </td>
                <td className="w-80">{this.props.country && this.props.country.iso_code}</td>
                <td className="w-max wm-300">{this.props.data.getAddress()}</td>
                <td className="w-200">{this.props.data.getBankAccount()}</td>
                <td className="w-100 text-center">{formatters.boolFormatter(this.props.data.is_blocked, 'text-danger')}</td>
            </tr>
        )
    }
}

export default CarriersListRow
