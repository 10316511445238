import React, { Component } from 'react'
import { convertISODatetimeToCS } from '../../../common/helpers'

// eslint-disable-next-line react/prefer-stateless-function
class UserLoginLogListRow extends Component {
    render() {
        const item = this.props.item

        return (
            <tr className="row">
                <td className="w-30 text-center">
                    <i className={`fas ${item.login_success === 1 ? 'fa-check text-success' : 'fa-times text-danger'}`} />
                </td>
                <td className="w-120">{convertISODatetimeToCS(item.datetime)}</td>
                <td className="w-140">{item.ip_address}</td>
                <td className="w-max wm-300">{item.user_agent}</td>
            </tr>
        )
    }
}

export default UserLoginLogListRow
