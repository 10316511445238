import { Record } from 'immutable'

export class TransporeonTransport extends Record({
    id: undefined,
    transporeon_id: undefined,
    transporeon_shipments: undefined,
    number: undefined,
    company_name: undefined,
    company_address: undefined,
    company_zip_address: undefined,
    company_city: undefined,
    company_region: undefined,
    country_id: undefined,
    vehicle_id: undefined,
    vehicle_name: undefined,
    start_date: undefined,
    end_date: undefined,
    owner: undefined,
    start_city: undefined,
    start_region: undefined,
    start_zip: undefined,
    start_country_id: undefined,
    end_city: undefined,
    end_region: undefined,
    end_zip: undefined,
    end_country_id: undefined,
    price_currency_id: undefined,
    price_basic: undefined,
    price_additional1: undefined,
    price_additional1_desc: undefined,
    price_additional2: undefined,
    price_additional2_desc: undefined,
    price_additional3: undefined,
    price_additional3_desc: undefined,
    weight: undefined,
    weight_unit: undefined,
    length: undefined,
    length_unit: undefined,
    volume: undefined,
    volume_unit: undefined,
    route: undefined,
    route_unit: undefined,
    comment: undefined,
}) {
    getStartAddress = countrySelector => {
        const country = countrySelector(this.start_country_id)
        const countryCode = country && country.iso_code

        return [countryCode, this.start_zip, this.start_city].filter(n => n).join(' ')
    }

    getEndAddress = countrySelector => {
        const country = countrySelector(this.end_country_id)
        const countryCode = country && country.iso_code

        return [countryCode, this.end_zip, this.end_city].filter(n => n).join(' ')
    }

    getTotalPrice = () =>
        parseFloat(this.price_basic) + parseFloat(this.price_additional1) + parseFloat(this.price_additional2) + parseFloat(this.price_additional3)
}

export class TransporeonShipment extends Record({
    id: undefined,
    transporeon_id: undefined,
    transporeon_stations: undefined,
    number: undefined,
    customer_name: undefined,
    vehicle_id: undefined,
    vehicle_name: undefined,
    weight: undefined,
    weight_unit: undefined,
    length: undefined,
    length_unit: undefined,
    volume: undefined,
    volume_unit: undefined,
    route: undefined,
    route_unit: undefined,
    comment: undefined,
}) {}

export class TransporeonStation extends Record({
    id: undefined,
    transporeon_id: undefined,
    type: undefined,
    avis: undefined,
    description: undefined,
    company_name: undefined,
    address: undefined,
    city: undefined,
    zip: undefined,
    region: undefined,
    country_id: undefined,
    loading_name: undefined,
    loading_ramp: undefined,
    from_date: undefined,
    until_date: undefined,
    transport_unit_name: undefined,
    transport_unit_count: undefined,
    transport_unit_pileable: undefined,
    comment: undefined,
}) {
    getAddress = countrySelector => {
        const country = countrySelector(this.country_id)
        const countryName = country && country.name

        return [this.city, this.region, this.zip, countryName].filter(n => n).join(', ')
    }
}
