import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'

import { url, handleCommonEditActions, validationMessage } from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import { Form, InputField, CheckboxField, SubmitButton } from '../../../common/form'
import { createGetDocumentTypeByIDSelector, createGetCurrentDocumentTypeStatusSelector } from '../selectors'
import * as documentTypesActionCreators from '../actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'document_types_edit'
const prerequisites = []

class DocumentTypesEdit extends Component {
    defaultValues = {}

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
    }

    setValues = values => {
        this.setState({
            values,
        })
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values)
        this.handleSave()
    }

    handleSave = () => {
        this.props.actions.saveDocumentType(this.state.values)
    }

    componentDidMount() {
        this.props.match.params.documentTypeID && this.props.actions.fetchDocumentType(this.props.match.params.documentTypeID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'document-types'))
        })

        if (JSON.stringify(nextProps.documentType) !== JSON.stringify(this.props.documentType) || (nextProps.documentType && !this.state.values.id)) {
            this.setValues(nextProps.documentType.toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.documentTypes.heading' }))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.documentTypes.heading" /> -{' '}
                                                {this.props.documentType ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.documentType)}
                                                >
                                                    <InputField
                                                        id="code"
                                                        label={this.props.intl.formatMessage({ id: 'fields.code' })}
                                                        className="wp-20"
                                                    />
                                                    <InputField
                                                        id="name"
                                                        label={this.props.intl.formatMessage({ id: 'fields.name' })}
                                                        className="wp-50"
                                                    />
                                                    <InputField
                                                        id="default_validity"
                                                        label={this.props.intl.formatMessage({ id: 'fields.defaultValidityInMonths' })}
                                                        className="wp-20"
                                                    />
                                                    <CheckboxField
                                                        id="is_for_drivers"
                                                        label={this.props.intl.formatMessage({ id: 'fields.relevantForDrivers' })}
                                                    />
                                                    <CheckboxField
                                                        id="is_for_vehicles"
                                                        label={this.props.intl.formatMessage({ id: 'fields.relevantForVehicles' })}
                                                    />
                                                    <CheckboxField
                                                        id="is_for_trailers"
                                                        label={this.props.intl.formatMessage({ id: 'fields.relevantForTrailers' })}
                                                    />
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <CheckboxField
                                                                id="notify"
                                                                label={this.props.intl.formatMessage({ id: 'fields.notifyInList' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-8 p-t-10">
                                                            {Boolean(this.state.values.notify) && (
                                                                <InputField
                                                                    id="notify_days"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.daysInAdvance' })}
                                                                    className="wp-20"
                                                                    disabled={!this.state.values.notify}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <CheckboxField
                                                                id="notify_in_board"
                                                                label={this.props.intl.formatMessage({ id: 'fields.notifyInBoard' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-8 p-t-10">
                                                            {Boolean(this.state.values.notify_in_board) && (
                                                                <InputField
                                                                    id="notify_in_board_days"
                                                                    label={this.props.intl.formatMessage({ id: 'fields.daysInAdvance' })}
                                                                    className="wp-20"
                                                                    disabled={!this.state.values.notify}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'document-types')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        <SubmitButton
                                                            perms={Object.values(PERMS)}
                                                            isEdit={Boolean(this.props.documentType)}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        documentType: createGetDocumentTypeByIDSelector(ownProps.match.params.documentTypeID)(state),
        status: createGetCurrentDocumentTypeStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...documentTypesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DocumentTypesEdit)
)
