import { Record } from 'immutable'

export class Carrier extends Record({
    id: undefined,
    name: undefined,
    code: undefined,
    street: undefined,
    city: undefined,
    zipcode: undefined,
    country_id: undefined,
    phone: undefined,
    email: undefined,
    reg_number: undefined,
    vat_number: undefined,
    is_blocked: undefined,
    invoice_deadline_days: undefined,
    email_for_invoicing: undefined,
    limit_unpaid_invoices: undefined,
    limit_overdue_invoices: undefined,
    bank_account: undefined,
    bank_code: undefined,
    bank_account_iban: undefined,
    bank_account_swift: undefined,
    vat_payer: undefined,
    deleted_at: undefined,
}) {
    getAddress = () => {
        const parts = []
        this.street && parts.push(this.street)
        this.city && parts.push(this.city)
        this.zipcode && parts.push(this.zipcode)

        return parts.join(', ')
    }

    getBankAccount = () => {
        const parts = []
        this.bank_account && parts.push(this.bank_account)
        this.bank_account && this.bank_code && parts.push(this.bank_code)

        return parts.join('/')
    }
}
