import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCarrierScoring = year => ({
    type: actionTypes.FETCH,
    payload: year,
})

export const fetchReportCarrierScoringFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCarrierScoringRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCarrierScoringCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCarrierScoring = () => ({
    type: actionTypes.CLEAR,
})

// Fetch scoring

export const fetchScoring = (id, year, month) => ({
    type: actionTypes.FETCH_SCORING,
    payload: {
        id,
        year,
        month,
    },
})

export const fetchScoringStarted = () => ({
    type: actionTypes.FETCH_SCORING_STARTED,
})

export const fetchScoringFulfilled = data => ({
    type: actionTypes.FETCH_SCORING_FULFILLED,
    payload: data,
})

export const fetchScoringRejected = message => ({
    type: actionTypes.FETCH_SCORING_REJECTED,
    payload: message,
})

export const fetchScoringCancelled = () => ({
    type: actionTypes.FETCH_SCORING_CANCELLED,
})

// Save scoring

export const saveScoring = (id, year, month, values) => ({
    type: actionTypes.SAVE_SCORING,
    payload: {
        id,
        year,
        month,
        values,
    },
})

export const saveScoringStarted = () => ({
    type: actionTypes.SAVE_SCORING_STARTED,
})

export const saveScoringFulfilled = data => ({
    type: actionTypes.SAVE_SCORING_FULFILLED,
    payload: data,
})

export const saveScoringRejected = message => ({
    type: actionTypes.SAVE_SCORING_REJECTED,
    payload: message,
})

export const saveScoringCancelled = () => ({
    type: actionTypes.SAVE_SCORING_CANCELLED,
})
