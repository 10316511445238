import * as actionTypes from './actionTypes'

// Fetch

export const fetchVehicleEquipments = () => ({
    type: actionTypes.FETCH,
})

export const fetchVehicleEquipmentsForMap = () => ({
    type: actionTypes.FETCH_FOR_MAP,
})

export const fetchVehicleEquipmentsFulfilled = vehicleEquipments => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: vehicleEquipments,
})

export const fetchVehicleEquipmentsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchVehicleEquipmentsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchVehicleEquipment = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchVehicleEquipmentFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchVehicleEquipmentRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchVehicleEquipmentCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveVehicleEquipment = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveVehicleEquipmentFulfilled = vehicleEquipment => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: vehicleEquipment,
})

export const saveVehicleEquipmentRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveVehicleEquipmentDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteVehicleEquipment = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteVehicleEquipmentFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteVehicleEquipmentRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Clear

export const clearVehicleEquipments = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportVehicleEquipments = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
