import { createSelector } from 'reselect'

export const getDietRates = state => state.dietRates.getIn(['all', 'objects'])

export const createGetDietRateByIDSelector = id => createSelector([getDietRates], dietRates => dietRates.get(String(id)))

const getCurrentDietRateStatus = state => state.dietRates.get('current')

export const createGetCurrentDietRateStatusSelector = () => createSelector([getCurrentDietRateStatus], status => status)

export const getDietRateDeleting = state => state.dietRates.getIn(['deletion', 'inProgress'])

export const getDietRateDeleteError = state => state.dietRates.getIn(['deletion', 'error'])
