// Fetch list

export const FETCH = 'reportDriverResults.FETCH'
export const FETCH_STARTED = 'reportDriverResults.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportDriverResults.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportDriverResults.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportDriverResults.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportDriverResults.CLEAR'

// Export

export const EXPORT = 'reportDriverResults.EXPORT'
export const EXPORT_FULFILLED = 'reportDriverResults.EXPORT_FULFILLED'

// Fetch details

export const FETCH_DETAILS = 'reportDriverResults.FETCH_DETAILS'
export const FETCH_DETAILS_STARTED = 'reportDriverResults.FETCH_DETAILS_STARTED'
export const FETCH_DETAILS_FULFILLED = 'reportDriverResults.FETCH_DETAILS_FULFILLED'
export const FETCH_DETAILS_REJECTED = 'reportDriverResults.FETCH_DETAILS_REJECTED'
export const FETCH_DETAILS_CANCELLED = 'reportDriverResults.FETCH_DETAILS_CANCELLED'

// Clear details

export const CLEAR_DETAILS = 'reportDriverResults.CLEAR_DETAILS'

// Export details

export const EXPORT_DETAILS = 'reportDriverResults.EXPORT_DETAILS'
export const EXPORT_DETAILS_FULFILLED = 'reportDriverResults.EXPORT_DETAILS_FULFILLED'
