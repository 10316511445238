import { createSelector } from 'reselect'

export const getPaymentMethods = state => state.paymentMethods.getIn(['all', 'objects'])

export const createGetPaymentMethodByIDSelector = id => createSelector([getPaymentMethods], paymentMethods => paymentMethods.get(String(id)))

const getCurrentPaymentMethodStatus = state => state.paymentMethods.get('current')
export const createGetCurrentPaymentMethodStatusSelector = () => createSelector([getCurrentPaymentMethodStatus], status => status)

export const getPaymentMethodDeleting = state => state.paymentMethods.getIn(['deletion', 'inProgress'])
export const getPaymentMethodDeleteError = state => state.paymentMethods.getIn(['deletion', 'error'])
