import { Record } from 'immutable'

export class DriverTimesheet extends Record({
    id: undefined,
    type_id: undefined,
    type: undefined,
    driver_id: undefined,
    driver: undefined,
    start_datetime: undefined,
    end_datetime: undefined,
    time: undefined,
    interval_type_id: undefined,
    interval_type: undefined,
    note: undefined,
}) {}
