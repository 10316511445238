import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import BorderCrossingsEditForm from './BorderCrossingsEditForm'

// eslint-disable-next-line react/prefer-stateless-function
class BorderCrossingsEdit extends Component {
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.borderCrossings.heading' }))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.borderCrossings.heading" /> -{' '}
                                                {this.props.match.params.borderCrossingID ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>

                                        <BorderCrossingsEditForm borderCrossingID={this.props.match.params.borderCrossingID} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

export default injectIntl(BorderCrossingsEdit)
