import React from 'react'
import { Route, Switch } from 'react-router'
import UserProfileEdit from './UserProfileEdit'

const UserProfile = props => (
    <Switch>
        <Route exact path={props.match.url} component={UserProfileEdit} />
    </Switch>
)

export default UserProfile
