import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'

import { getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField, TableFilterSelectField, TableFilterBooleanField } from '../../../common/table/components'
import { getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as driversActionCreators from '../actionCreators'
import { getDrivers } from '../selectors'

import DriversHistoryDriversRow from './DriversHistoryDriversRow'

const tableIdentifier = 'drivers_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'drivers_list'
const prerequisites = ['driverLocales']

const columns = {
    fb_id: new TableModelColumn({}),
    first_name: new TableModelColumn({}),
    last_name: new TableModelColumn({}),
    phone: new TableModelColumn({}),
    locale: new TableModelColumn({}),
}

class DriversHistoryDrivers extends Component {
    componentDidMount() {
        this.props.actions.fetchDrivers()
        this.props.actions.fetchPrerequisites()
    }

    render() {
        const filters = this.props.tableModel.get('filters')

        return (
            <React.Fragment>
                <div className="table-container">
                    <table className="table table-striped table-hover table-fixed-header">
                        <thead>
                            <tr>
                                <th className="w-160">
                                    <FormattedMessage id="fields.lastName" />
                                </th>
                                <th className="w-max wm-200">
                                    <FormattedMessage id="fields.firstName" />
                                </th>
                                <th className="w-100 text-center">
                                    <FormattedMessage id="fields.language" />
                                </th>
                                <th className="w-80 text-center">
                                    <FormattedMessage id="fields.active" />
                                </th>
                                <th className="w-40" />
                            </tr>
                            <tr className="filters">
                                <th className="w-160">
                                    <TableFilterInputField
                                        identifier="last_name"
                                        type={TableModelColumnFilteringType.string}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                    />
                                </th>
                                <th className="w-max wm-200">
                                    <TableFilterInputField
                                        identifier="first_name"
                                        type={TableModelColumnFilteringType.string}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                    />
                                </th>
                                <th className="w-100">
                                    <TableFilterSelectField
                                        identifier="locale"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('driverLocales'), 'locale', 'locale')}
                                    />
                                </th>
                                <th className="w-80">
                                    <TableFilterBooleanField
                                        identifier="is_active"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                    />
                                </th>
                                <th className="w-40" />
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.drivers.data.valueSeq().map(driver => (
                                <DriversHistoryDriversRow
                                    key={`row-${driver.id}`}
                                    driver={driver}
                                    selectedDriver={this.props.selectedDriver}
                                    onDriverSelect={this.props.onDriverSelect}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="pull-left m-l-xxs m-t-md">
                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                    {getListItemsCountMessage(
                        clientSideItemsPerPage,
                        this.props.drivers.count,
                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                    )}
                </div>

                <ReactPaginate
                    containerClassName="pagination"
                    breakLabel={<span className="disabled">...</span>}
                    activeClassName="active"
                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.drivers.count, clientSideItemsPerPage)}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                    onPageChange={this.props.table.changePage}
                    ppreviousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        drivers: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getDrivers,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...driversActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(DriversHistoryDrivers)
)
