import { createSelector } from 'reselect'

export const getWarehousingIssues = state => state.warehousingIssues.getIn(['all', 'objects'])
export const createGetWarehousingIssueByIDSelector = id => createSelector([getWarehousingIssues], issues => issues.get(String(id)))

const getCurrentWarehousingIssueStatus = state => state.warehousingIssues.get('current')
export const createGetCurrentWarehousingIssueStatusSelector = () => createSelector([getCurrentWarehousingIssueStatus], status => status)

export const getWarehousingIssueDeleting = state => state.warehousingIssues.getIn(['deletion', 'inProgress'])
export const getWarehousingIssueDeleteError = state => state.warehousingIssues.getIn(['deletion', 'error'])

export const getWarehousingItemData = state => state.warehousingIssues.getIn(['warehousingItem', 'data'])
export const getWarehousingItemFetching = state => state.warehousingIssues.getIn(['warehousingItem', 'fetching'])
export const getWarehousingItemError = state => state.warehousingIssues.getIn(['warehousingItem', 'error'])