// Fetch list

export const FETCH = 'invoicingOrders.FETCH'
export const FETCH_STARTED = 'invoicingOrders.FETCH_STARTED'
export const FETCH_FULFILLED = 'invoicingOrders.FETCH_FULFILLED'
export const FETCH_REJECTED = 'invoicingOrders.FETCH_REJECTED'
export const FETCH_CANCELLED = 'invoicingOrders.FETCH_CANCELLED'

export const SORTING_CHANGED = 'invoicingOrders.SORTING_CHANGED'

// Clear

export const CLEAR = 'invoicingOrders.CLEAR'

// Export

export const EXPORT = 'invoicingOrders.EXPORT'
export const EXPORT_FULFILLED = 'invoicingOrders.EXPORT_FULFILLED'

// Set invoicing state

export const SET_INVOICING_STATE = 'invoicingOrders.SET_INVOICING_STATE'
export const SET_INVOICING_STATE_STARTED = 'invoicingOrders.SET_INVOICING_STATE_STARTED'
export const SET_INVOICING_STATE_FULFILLED = 'invoicingOrders.SET_INVOICING_STATE_FULFILLED'
export const SET_INVOICING_STATE_REJECTED = 'invoicingOrders.SET_INVOICING_STATE_REJECTED'
export const SET_INVOICING_STATE_CANCELLED = 'invoicingOrders.SET_INVOICING_STATE_CANCELLED'

// Set as invoiced

export const SET_AS_INVOICED = 'invoicingOrders.SET_AS_INVOICED'
export const SET_AS_INVOICED_STARTED = 'invoicingOrders.SET_AS_INVOICED_STARTED'
export const SET_AS_INVOICED_FULFILLED = 'invoicingOrders.SET_AS_INVOICED_FULFILLED'
export const SET_AS_INVOICED_REJECTED = 'invoicingOrders.SET_AS_INVOICED_REJECTED'
export const SET_AS_INVOICED_CANCELLED = 'invoicingOrders.SET_AS_INVOICED_CANCELLED'

// Set invoicing state

export const FETCH_DATA_FOR_INVOICING = 'invoicingOrders.FETCH_DATA_FOR_INVOICING'
export const FETCH_DATA_FOR_INVOICING_STARTED = 'invoicingOrders.FETCH_DATA_FOR_INVOICING_STARTED'
export const FETCH_DATA_FOR_INVOICING_FULFILLED = 'invoicingOrders.FETCH_DATA_FOR_INVOICING_FULFILLED'
export const FETCH_DATA_FOR_INVOICING_REJECTED = 'invoicingOrders.FETCH_DATA_FOR_INVOICING_REJECTED'
export const FETCH_DATA_FOR_INVOICING_CANCELLED = 'invoicingOrders.FETCH_DATA_FOR_INVOICING_CANCELLED'

// Create invoice

export const CREATE_INVOICE = 'invoicingOrders.CREATE_INVOICE'
export const CREATE_INVOICE_STARTED = 'invoicingOrders.CREATE_INVOICE_STARTED'
export const CREATE_INVOICE_FULFILLED = 'invoicingOrders.CREATE_INVOICE_FULFILLED'
export const CREATE_INVOICE_REJECTED = 'invoicingOrders.CREATE_INVOICE_REJECTED'
export const CREATE_INVOICE_CANCELLED = 'invoicingOrders.CREATE_INVOICE_CANCELLED'
