import { Record } from 'immutable'

export class DriverEquipment extends Record({
    id: undefined,
    driver_id: undefined,
    driver: undefined,
    equipment_id: undefined,
    equipment: undefined,
    note: undefined,
    date_validity_from: undefined,
    date_validity_to: undefined,
}) {}
