// Fetch list

export const FETCH = 'invoicingInvoices.FETCH'
export const FETCH_STARTED = 'invoicingInvoices.FETCH_STARTED'
export const FETCH_FULFILLED = 'invoicingInvoices.FETCH_FULFILLED'
export const FETCH_REJECTED = 'invoicingInvoices.FETCH_REJECTED'
export const FETCH_CANCELLED = 'invoicingInvoices.FETCH_CANCELLED'

export const SORTING_CHANGED = 'invoicingInvoices.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'invoicingInvoices.FETCH_ONE'
export const FETCH_ONE_STARTED = 'invoicingInvoices.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'invoicingInvoices.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'invoicingInvoices.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'invoicingInvoices.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'invoicingInvoices.SAVE'
export const SAVE_STARTED = 'invoicingInvoices.SAVE_STARTED'
export const SAVE_FULFILLED = 'invoicingInvoices.SAVE_FULFILLED'
export const SAVE_REJECTED = 'invoicingInvoices.SAVE_REJECTED'
export const SAVE_CANCELLED = 'invoicingInvoices.SAVE_CANCELLED'

// Clear

export const CLEAR = 'invoicingInvoices.CLEAR'

// Delete

export const DELETE = 'invoicingInvoices.DELETE'
export const DELETE_STARTED = 'invoicingInvoices.DELETE_STARTED'
export const DELETE_FULFILLED = 'invoicingInvoices.DELETE_FULFILLED'
export const DELETE_REJECTED = 'invoicingInvoices.DELETE_REJECTED'
export const DELETE_CANCELLED = 'invoicingInvoices.DELETE_CANCELLED'

// Restore

export const RESTORE = 'invoicingInvoices.RESTORE'
export const RESTORE_STARTED = 'invoicingInvoices.RESTORE_STARTED'
export const RESTORE_FULFILLED = 'invoicingInvoices.RESTORE_FULFILLED'
export const RESTORE_REJECTED = 'invoicingInvoices.RESTORE_REJECTED'
export const RESTORE_CANCELLED = 'invoicingInvoices.RESTORE_CANCELLED'

// Export

export const EXPORT = 'invoicingInvoices.EXPORT'
export const EXPORT_FULFILLED = 'invoicingInvoices.EXPORT_FULFILLED'

// Fetch detail

export const FETCH_DETAIL = 'invoicingInvoices.FETCH_DETAIL'
export const FETCH_DETAIL_STARTED = 'invoicingInvoices.FETCH_DETAIL_STARTED'
export const FETCH_DETAIL_FULFILLED = 'invoicingInvoices.FETCH_DETAIL_FULFILLED'
export const FETCH_DETAIL_REJECTED = 'invoicingInvoices.FETCH_DETAIL_REJECTED'
export const FETCH_DETAIL_CANCELLED = 'invoicingInvoices.FETCH_DETAIL_CANCELLED'

// Fetch detail

export const LOAD_CUSTOMER_INFO = 'invoicingInvoices.LOAD_CUSTOMER_INFO'
export const LOAD_CUSTOMER_INFO_STARTED = 'invoicingInvoices.LOAD_CUSTOMER_INFO_STARTED'
export const LOAD_CUSTOMER_INFO_FULFILLED = 'invoicingInvoices.LOAD_CUSTOMER_INFO_FULFILLED'
export const LOAD_CUSTOMER_INFO_REJECTED = 'invoicingInvoices.LOAD_CUSTOMER_INFO_REJECTED'
export const LOAD_CUSTOMER_INFO_CANCELLED = 'invoicingInvoices.LOAD_CUSTOMER_INFO_CANCELLED'
export const CLEAR_CUSTOMER_INFO = 'invoicingInvoices.CLEAR_CUSTOMER_INFO'

// Create credit note

export const CREATE_CREDIT_NOTE = 'invoicingInvoices.CREATE_CREDIT_NOTE'
export const CREATE_CREDIT_NOTE_STARTED = 'invoicingInvoices.CREATE_CREDIT_NOTE_STARTED'
export const CREATE_CREDIT_NOTE_FULFILLED = 'invoicingInvoices.CREATE_CREDIT_NOTE_FULFILLED'
export const CREATE_CREDIT_NOTE_REJECTED = 'invoicingInvoices.CREATE_CREDIT_NOTE_REJECTED'
export const CREATE_CREDIT_NOTE_CANCELLED = 'invoicingInvoices.CREATE_CREDIT_NOTE_CANCELLED'

// Export to accounting

export const EXPORT_TO_ACCOUNTING = 'invoicingInvoices.EXPORT_TO_ACCOUNTING'
export const EXPORT_TO_ACCOUNTING_STARTED = 'invoicingInvoices.EXPORT_TO_ACCOUNTING_STARTED'
export const EXPORT_TO_ACCOUNTING_FULFILLED = 'invoicingInvoices.EXPORT_TO_ACCOUNTING_FULFILLED'
export const EXPORT_TO_ACCOUNTING_REJECTED = 'invoicingInvoices.EXPORT_TO_ACCOUNTING_REJECTED'
export const EXPORT_TO_ACCOUNTING_CANCELLED = 'invoicingInvoices.EXPORT_TO_ACCOUNTING_CANCELLED'

// Load transport info

export const LOAD_TRANSPORT_INFO = 'invoicingInvoices.LOAD_TRANSPORT_INFO'
export const LOAD_TRANSPORT_INFO_STARTED = 'invoicingInvoices.LOAD_TRANSPORT_INFO_STARTED'
export const LOAD_TRANSPORT_INFO_FULFILLED = 'invoicingInvoices.LOAD_TRANSPORT_INFO_FULFILLED'
export const LOAD_TRANSPORT_INFO_REJECTED = 'invoicingInvoices.LOAD_TRANSPORT_INFO_REJECTED'
export const LOAD_TRANSPORT_INFO_CANCELLED = 'invoicingInvoices.LOAD_TRANSPORT_INFO_CANCELLED'

// Create invoice PDF

export const CREATE_INVOICE_PDF = 'invoicingInvoices.CREATE_INVOICE_PDF'
export const CREATE_INVOICE_PDF_STARTED = 'invoicingInvoices.CREATE_INVOICE_PDF_STARTED'
export const CREATE_INVOICE_PDF_FULFILLED = 'invoicingInvoices.CREATE_INVOICE_PDF_FULFILLED'
export const CREATE_INVOICE_PDF_REJECTED = 'invoicingInvoices.CREATE_INVOICE_PDF_REJECTED'
export const CREATE_INVOICE_PDF_CANCELLED = 'invoicingInvoices.CREATE_INVOICE_PDF_CANCELLED'

// Send invoice

export const SEND_INVOICE_TO_EMAIL = 'invoicingInvoices.SEND_INVOICE_TO_EMAIL'
export const SEND_INVOICE_TO_EMAIL_STARTED = 'invoicingInvoices.SEND_INVOICE_TO_EMAIL_STARTED'
export const SEND_INVOICE_TO_EMAIL_FULFILLED = 'invoicingInvoices.SEND_INVOICE_TO_EMAIL_FULFILLED'
export const SEND_INVOICE_TO_EMAIL_REJECTED = 'invoicingInvoices.SEND_INVOICE_TO_EMAIL_REJECTED'
export const SEND_INVOICE_TO_EMAIL_CANCELLED = 'invoicingInvoices.SEND_INVOICE_TO_EMAIL_CANCELLED'
