import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchReportCustomerPlansEpic = (action$, store) => {
    return action$.ofType(actionTypes.FETCH).switchMap(() =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request({
                method: 'POST',
                path: `report-customer-plans`,
                body: {
                    settings: store.getState().reportCustomerPlans.getIn(['list', 'settings']),
                },
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportCustomerPlansFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportCustomerPlansRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    )
}

// Export

const exportReportCustomerPlansEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const settings = JSON.stringify(action.payload)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-customer-plans/export?settings=${settings}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

export default [fetchReportCustomerPlansEpic, exportReportCustomerPlansEpic]
