import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import { getPermissions } from '../selectors'

class PermissionsProps extends Component {
    checkPermissions = () => {
        if (this.props.permissions === undefined || this.props.permissions === null) {
            return false
        }

        const requiredPermissions = new List(this.props.has)

        if (requiredPermissions.count() > 0) {
            return requiredPermissions.isSubset(this.props.permissions)
        }

        const anyRequiredPermissions = new List(this.props.hasAny)

        if (anyRequiredPermissions.count() > 0) {
            return this.props.permissions.some(val => anyRequiredPermissions.includes(val))
        }

        return true
    }

    render() {
        if (!this.props.children) {
            return null
        }

        if (this.checkPermissions() && this.props.props) {
            return React.Children.map(this.props.children, child =>
                React.cloneElement(child, {
                    ...{
                        ...this.props.props,
                        permissions: Array.from(this.props.permissions),
                        requiredpermissions: this.props.has || this.props.hasAny,
                    },
                })
            )
        }

        return React.Children.map(this.props.children, child =>
            React.cloneElement(child, {
                ...{ permissions: Array.from(this.props.permissions), requiredpermissions: this.props.has || this.props.hasAny },
            })
        )
    }
}

function mapStateToProps(state) {
    return {
        permissions: getPermissions(state),
    }
}

export default connect(mapStateToProps)(PermissionsProps)
