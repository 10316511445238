import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'

import {
    url,
    handleCommonEditActions,
    validationMessage,
    getPrerequisitesArray,
    convertCSDateToMoment, convertCSDateToISO, convertISODateToCS
} from '../../../common/helpers'
import * as formatters from '../../../common/formatters'
import {Form, InputField, CheckboxField, SubmitButton, SelectField} from '../../../common/form'
import { createGetDietRateByIDSelector, createGetCurrentDietRateStatusSelector } from '../selectors'
import * as dietRatesActionCreators from '../actionCreators'
import { LoadingOverlay } from '../../../common/loading_overlay'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import DatePicker from "react-datepicker";
import moment from "moment";
import * as helpers from "../../../common/helpers";

const componentIdentifier = 'diet_rates_edit'
const prerequisites = []

class DietRatesEdit extends Component {
    defaultValues = {
    }

    validationRules = {
        rate: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        country_id: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        currency_id: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        hours_from: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
        hours_to: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
        dates: {
            date_from: moment().startOf('year'),
            date_to: moment().endOf('year'),
        }
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    getValues = () => {
        const values = this.state.values
        values.date_from = convertCSDateToISO(this.state.dates.date_from)
        values.date_to = convertCSDateToISO(this.state.dates.date_to)
        return values
    }

    handleDateChange = (key, value) => {
        const newState = {
            dates: {
                ...this.state.dates,
                [key]: value,
            },
            values: this.state.values,
        }

        this.setState(newState)
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave()
        })
    }

    handleSave = () => {
        this.props.actions.saveDietRate(this.getValues())
    }

    componentDidMount() {
        this.props.match.params.dietRateID && this.props.actions.fetchDietRate(this.props.match.params.dietRateID)
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, () => {
            this.props.redirect(url(this.props.match, 'diet-rates'))
        })

        if (JSON.stringify(nextProps.dietRate) !== JSON.stringify(this.props.dietRate) || (nextProps.dietRate && !this.state.values.id)) {
            const values = nextProps.dietRate.toJS()
            this.setState({
                dates: {
                    date_from: helpers.convertISODateToCS(values.date_from),
                    date_to: helpers.convertISODateToCS(values.date_to),
                },
                values: values,
            })
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.dietRates.heading' }))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4">
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.dietRates.heading" /> -{' '}
                                                {this.props.dietRate ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.getValues()}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.dietRate)}
                                                >
                                                    <div className="row m-t-lg">
                                                       <div className="col-md-12">
                                                           <h4><FormattedMessage id="fields.validity" /></h4>
                                                       </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="date_from">
                                                                <FormattedMessage id="fields.from" />
                                                            </label>
                                                            <DatePicker
                                                                id="date_from"
                                                                dateFormat="DD.MM.YYYY"
                                                                autoComplete="off"
                                                                selected={convertCSDateToMoment(this.state.dates.date_from)}
                                                                value={this.state.dates.date_from}
                                                                onChange={value => {
                                                                    this.handleDateChange(
                                                                        'date_from',
                                                                        value ? formatters.dateFormatter(value) : ''
                                                                    )
                                                                }}
                                                                onChangeRaw={e => {
                                                                    this.handleDateChange('date_from', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="date_to">
                                                                <FormattedMessage id="fields.to" />
                                                            </label>
                                                            <DatePicker
                                                                id="date_to"
                                                                dateFormat="DD.MM.YYYY"
                                                                autoComplete="off"
                                                                selected={convertCSDateToMoment(this.state.dates.date_to)}
                                                                value={this.state.dates.date_to}
                                                                onChange={value => {
                                                                    this.handleDateChange(
                                                                        'date_to',
                                                                        value ? formatters.dateFormatter(value) : ''
                                                                    )
                                                                }}
                                                                onChangeRaw={e => {
                                                                    this.handleDateChange('date_to', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row m-t-sm">
                                                        <div className="col-md-12">
                                                            <h4><FormattedMessage id="fields.hoursCount" /></h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <InputField id="hours_from" label={this.props.intl.formatMessage({ id: 'fields.from' })} type="number" step="0.1" min="0" max="999" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <InputField id="hours_to" label={this.props.intl.formatMessage({ id: 'fields.to' })} type="number" step="0.1" min="0" max="999" />
                                                        </div>
                                                    </div>
                                                    <div className="row m-t-sm">
                                                        <div className="col-md-12">
                                                            <h4><FormattedMessage id="fields.dietRate" /></h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <SelectField
                                                                id="country_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.country' })}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('countries'), 'id', 'iso_code')}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <CheckboxField id="is_domestic" label={this.props.intl.formatMessage({ id: 'fields.domesticDietRate' })} />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <InputField id="rate" label={this.props.intl.formatMessage({ id: 'fields.value' })} type="number" step="0.1" min="0"/>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <SelectField
                                                                id="currency_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.currency' })}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('currencies'), 'id', 'iso_code')}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'diet-rates')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        <SubmitButton
                                                            perms={Object.values(PERMS)}
                                                            isEdit={Boolean(this.props.dietRate)}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        dietRate: createGetDietRateByIDSelector(ownProps.match.params.dietRateID)(state),
        status: createGetCurrentDietRateStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...dietRatesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DietRatesEdit)
)
