import React from 'react'

const TransportStateBox = props => {
    const state = props.transportState
    const bg = state && state.rgb_bg ? state.rgb_bg : '#dddddd'
    const text = state && state.rgb_text ? state.rgb_text : '#000000'

    return (
        <span className="label" style={{ backgroundColor: bg, color: text }}>
            {props.children}
        </span>
    )
}

export default TransportStateBox
