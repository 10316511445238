const configs = {
    login: {
        apiBaseUrl: 'https://login-back.fleetingo.com',
        appName: 'LOG-IN CZ',
        appBackgroundImage: 'bg.login.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    selectra: {
        apiBaseUrl: 'https://selectra-back.fleetingo.com',
        appName: 'Selectra',
        appBackgroundImage: 'bg.selectra.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
        notificationsUrl: 'https://selectra-back.fleetingo.com:6003',
    },
    'selectra-transport': {
        apiBaseUrl: 'https://selectra-transport-back.fleetingo.com',
        appName: 'Selectra Transport',
        appBackgroundImage: 'bg.selectra.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
        notificationsUrl: 'https://selectra-transport-back.fleetingo.com:6003',
    },
    stl: {
        apiBaseUrl: 'https://stl-back.fleetingo.com',
        appName: 'STL Express',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    jirolsped: {
        apiBaseUrl: 'https://jirolsped-back.fleetingo.com',
        appName: 'JIROL Sped',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    bmt: {
        apiBaseUrl: 'https://bmt-back.fleetingo.com',
        appName: 'BMT CZ',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    vrcspeed: {
        apiBaseUrl: 'https://vrcspeed-back.fleetingo.com',
        appName: 'VRC SPEED',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    demo: {
        apiBaseUrl: 'https://demo-back.fleetingo.com',
        appName: 'DEMO',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
        notificationsUrl: 'https://demo-back.fleetingo.com:6002',
    },
    dev: {
        apiBaseUrl: 'https://dev-back.fleetingo.com',
        appName: 'DEV',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
        notificationsUrl: 'https://dev-back.fleetingo.com:6001',
    },
    azb: {
        apiBaseUrl: 'https://azb-back.fleetingo.com',
        appName: 'A.Z.B.',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    zayml: {
        apiBaseUrl: 'https://zayml-back.fleetingo.com',
        appName: 'ZAYML SK',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    hubert: {
        apiBaseUrl: 'https://hubert-back.fleetingo.com',
        appName: 'HUBERT',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    bb: {
        apiBaseUrl: 'https://bb-back.fleetingo.com',
        appName: 'B&Bartoni',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    mwl: {
        apiBaseUrl: 'https://mwl-back.fleetingo.com',
        appName: 'Maurice Ward Logistics',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    sebalex: {
        apiBaseUrl: 'https://sebalex-back.fleetingo.com',
        appName: 'Sebalex',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    pame: {
        apiBaseUrl: 'https://pame-back.fleetingo.com',
        appName: 'PAME',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    lautaro: {
        apiBaseUrl: 'https://lautaro-back.fleetingo.com',
        appName: 'LAUTARO',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
    default: {
        apiBaseUrl: 'http://fleetingo-backend.test',
        appName: 'Demo',
        appBackgroundImage: 'bg.default.jpg',
        hereMapsAppId: 'Jv00QIv4Q7SWj4tI2ohS',
        hereMapsAppCode: 'KqzFvObpzToFWTN6NHeNDVNciyle3RxHcVF7hIM8ryg',
    },
}


export const locales = {
    cs: {
        id: 'cs',
        name: 'Česky',
        displayInMenu: true
    },
    en:{
        id: 'en',
        name: 'English',
        displayInMenu: true
    },
    pl: {
        id: 'pl',
        name: 'Polski',
        displayInMenu: true
    },
    de: {
        id: 'de',
        name: 'Deutsch',
        displayInMenu: false
    },
}


export const getLocalePairs = function(onlyDisplayinMenu = true){
    const localePairs = []

    Object.entries(locales).map((localeItem, i) => {
        var trueLocaleItem = localeItem[1]
        if(trueLocaleItem.displayInMenu){
            localePairs.push({id: trueLocaleItem.id, name: trueLocaleItem.name});
        }
    })
    return localePairs
}

const subdomain = window && window.location && window.location.host.split('.')[0]
const config = configs[subdomain] || configs.default

export const CFG_SUBDOMAIN = subdomain
export const CFG_API_BASE_URL = config.apiBaseUrl

export const CFG_APP_NAME = config.appName
export const CFG_APP_BACKGROUND_IMAGE = config.appBackgroundImage

export const CFG_HERE_MAPS_APP_ID = config.hereMapsAppId
export const CFG_HERE_MAPS_APP_CODE = config.hereMapsAppCode

export const CFG_GOOGLE_API_KEY = 'AIzaSyBwogBHWqId4gPaF5Mw92ckzFkcvz2k4bs'

export const CFG_VERSION = '1.0'

export const NOTIFICATIONS_URL = config.notificationsUrl
