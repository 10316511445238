import React, { Component } from 'react'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class UnitsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render = () => (
        <tr onClick={this.handleClick} className="row-selectable">
            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                <td className="w-60 table-buttons">
                    <PermissionsCheck has={[PERMS.UPDATE]}>
                        <button className="far fa-pencil text-gray m-r-xxs" />
                    </PermissionsCheck>
                    <PermissionsCheck has={[PERMS.DELETE]}>
                        <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                    </PermissionsCheck>
                </td>
            </PermissionsCheck>
            <td className="w-100">{this.props.data.code}</td>
            <td className="w-max wm-300">{this.props.data.name}</td>
        </tr>
    )
}

export default UnitsListRow
