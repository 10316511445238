import { createSelector } from 'reselect'

export const getCostTypes = state => state.costTypes.getIn(['all', 'objects'])

// eslint-disable-next-line prettier/prettier
export const createGetCostTypeByIDSelector = id => createSelector([getCostTypes], costTypes => costTypes.get(String(id)))

const getCurrentCostTypeStatus = state => state.costTypes.get('current')

// eslint-disable-next-line prettier/prettier
export const createGetCurrentCostTypeStatusSelector = () => createSelector([getCurrentCostTypeStatus], status => status)

export const getCostTypeDeleting = state => state.costTypes.getIn(['deletion', 'inProgress'])

export const getCostTypeDeleteError = state => state.costTypes.getIn(['deletion', 'error'])
