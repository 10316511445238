import React from 'react'
import { Route, Switch } from 'react-router'
import DietRatesList from './DietRatesList'
import DietRatesEdit from './DietRatesEdit'

const DietRates = props => (
    <Switch>
        <Route exact path={props.match.url} component={DietRatesList} />
        <Route exact path={`${props.match.url}/new`} component={DietRatesEdit} />
        <Route exact path={`${props.match.url}/:dietRateID`} component={DietRatesEdit} />
    </Switch>
)

export default DietRates
