import React, { Component } from 'react'
import ArrowField from './ArrowField'

class MonthPicker extends Component {
    onClick = months => {
        const momentFrom = this.props.momentFrom.add(months, 'months')
        const momentTo = this.props.momentTo.add(months, 'months').endOf('month')

        this.props.onClick(momentFrom, momentTo)
    }

    render() {
        const year = this.props.momentFrom.format('YYYY')
        const month = this.props.momentFrom.format('MM')

        const value = `${year} / ${month}`

        const isMin = this.props.minYear === year && month === '01'
        const isMax = this.props.maxYear === year && month === '12'

        return <ArrowField onClick={this.onClick} value={value} isMin={isMin} isMax={isMax} />
    }
}

export default MonthPicker
