import * as actionTypes from './actionTypes'

// Fetch

export const fetchCompanyCostCenters = () => ({
    type: actionTypes.FETCH,
})

export const fetchCompanyCostCentersFulfilled = companyCostCenters => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: companyCostCenters,
})

export const fetchCompanyCostCentersRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCompanyCostCentersCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCompanyCostCenter = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCompanyCostCenterFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCompanyCostCenterRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCompanyCostCenterCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCompanyCostCenter = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveCompanyCostCenterFulfilled = companyCostCenter => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: companyCostCenter,
})

export const saveCompanyCostCenterRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteCompanyCostCenter = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCompanyCostCenterFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCompanyCostCenterRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportCompanyCostCenters = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
