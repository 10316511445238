import React from 'react'
import { Route, Switch } from 'react-router'
import ReportPallets from './ReportPallets'

const Components = props => (
    <Switch>
        <Route exact path={props.match.url} component={ReportPallets} />
    </Switch>
)

export default Components
