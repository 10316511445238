// Fetch list

export const FETCH = 'reportTransportInvoicing.FETCH'
export const FETCH_STARTED = 'reportTransportInvoicing.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportTransportInvoicing.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportTransportInvoicing.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportTransportInvoicing.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportTransportInvoicing.CLEAR'

// Set transport prices

export const SET_TRANSPORT_PRICES = 'reportTransportInvoicing.SET_TRANSPORT_PRICES'
export const SET_TRANSPORT_PRICES_STARTED = 'reportTransportInvoicing.SET_TRANSPORT_PRICES_STARTED'
export const SET_TRANSPORT_PRICES_FULFILLED = 'reportTransportInvoicing.SET_TRANSPORT_PRICES_FULFILLED'
export const SET_TRANSPORT_PRICES_REJECTED = 'reportTransportInvoicing.SET_TRANSPORT_PRICES_REJECTED'
export const SET_TRANSPORT_PRICES_CANCELLED = 'reportTransportInvoicing.SET_TRANSPORT_PRICES_CANCELLED'