import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import { LoadingOverlay } from '../../../common/loading_overlay'

import { getVehicleHistoryData, getVehicleHistoryFetching } from '../selectors'
import * as vehiclesActionCreators from '../actionCreators'

import VehiclesHistoryListRow from './VehiclesHistoryListRow'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_HISTORY } from '../permissions'

class VehiclesHistoryList extends Component {
    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportVehicleHistory(this.props.vehicle.id)
    }

    componentDidMount() {
        if (this.props.vehicle) {
            this.props.actions.fetchVehicleHistory(this.props.vehicle.id)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.vehicle && JSON.stringify(nextProps.vehicle) !== JSON.stringify(this.props.vehicle)) {
            this.props.actions.fetchVehicleHistory(nextProps.vehicle.id)
        }
    }

    render() {
        const vehicle = this.props.vehicle
        const data = this.props.data

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_HISTORY)} noPermissionsTab>
                <LoadingOverlay active={this.props.fetching}>
                    {data && data.length > 0 ? (
                        <React.Fragment>
                            {this.props.showVehicleRegistration && <h4>{vehicle && vehicle.registration}</h4>}

                            <div className="btns-list">
                                <PermissionsCheck has={[PERMS_HISTORY.EXPORT]}>
                                    <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                        <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                    </button>
                                </PermissionsCheck>
                            </div>

                            <div className="table-container table-container-no-pagination">
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                        <tr>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.from" />
                                            </th>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.to" />
                                            </th>
                                            <th className="w-200">
                                                <FormattedMessage id="fields.driver" />
                                            </th>
                                            <th className="w-max">
                                                <FormattedMessage id="fields.trailer" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data &&
                                            data.map((item, index) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <VehiclesHistoryListRow key={`row-${index}`} item={item} nextItem={data[index + 1]} />
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {this.props.showVehicleRegistration && (
                                <div className="pull-left wp-100">
                                    <h4>{vehicle && vehicle.registration}</h4>
                                </div>
                            )}
                            <div className="alert alert-danger pull-left wp-100">
                                <FormattedMessage id="fields.noItemsFound" />
                            </div>
                        </React.Fragment>
                    )}
                </LoadingOverlay>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: getVehicleHistoryData(state),
        fetching: getVehicleHistoryFetching(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...vehiclesActionCreators,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(VehiclesHistoryList)
)
