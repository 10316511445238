import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCustomerMonthlyPlans = (year, filters) => ({
    type: actionTypes.FETCH,
    payload: {
        year,
        filters,
    },
})

export const fetchReportCustomerMonthlyPlansFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCustomerMonthlyPlansRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCustomerMonthlyPlansCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCustomerMonthlyPlans = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportCustomerMonthlyPlans = (year, filters) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
    },
})
