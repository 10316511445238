import { Observable } from 'rxjs'

import { request, parseAPIError } from '../../common/api'
import * as actionCreators from './actionCreators'
import * as actionTypes from './actionTypes'

// Fetch

const fetchEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH)
        .filter(() => !store.getState().tracing.getIn(['route', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request({
                    path: `tracing/calculate-route`,
                    method: 'POST',
                    body: action.payload,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED))
            )
        )

// Fetch vehicles

const fetchVehicleEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_VEHICLE)
        .filter(() => !store.getState().tracing.getIn(['vehicle', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_VEHICLE_STARTED,
                }),
                request({
                    path: `tracing/vehicle`,
                    method: 'POST',
                    body: action.payload,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchVehicleFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchVehicleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_VEHICLE_CANCELLED))
            )
        )

// Fetch vehicles

const fetchVehiclesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_VEHICLES)
        .filter(() => !store.getState().tracing.getIn(['vehicles', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_VEHICLES_STARTED,
                }),
                request({
                    path: `tracing/vehicles`,
                    method: 'POST',
                    body: action.payload,
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchVehiclesFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchVehiclesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_VEHICLES_CANCELLED))
            )
        )

// Fetch gpg

const fetchGpsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_GPS)
        .filter(() => !store.getState().tracing.getIn(['gps', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_GPS_STARTED,
                }),
                request({
                    path: `tracing/find-gps?address=${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchGpsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchGpsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_GPS_CANCELLED))
            )
        )

// Fetch address

const fetchAddressEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ADDRESS)
        .filter(() => !store.getState().tracing.getIn(['address', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ADDRESS_STARTED,
                }),
                request({
                    path: `tracing/find-address?lat=${action.payload.lat}&lng=${action.payload.lng}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchAddressFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchAddressRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ADDRESS_CANCELLED))
            )
        )

export default [fetchEpic, fetchVehicleEpic, fetchVehiclesEpic, fetchGpsEpic, fetchAddressEpic]
