import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'

// eslint-disable-next-line react/prefer-stateless-function
class ConfirmDialog extends Component {
    render() {
        const level = this.props.level || 1

        return (
            <Modal show={Boolean(this.props.options.show)} onHide={this.props.options.onCancel} bsSize="sm" className={`modal-level-${level}`}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.options.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.options.message}</Modal.Body>
                <Modal.Footer>
                    <button className={`btn ${this.props.options.classNameAccept} m-r-xs`} onClick={this.props.options.onAccept}>
                        {this.props.options.labelAccept}
                    </button>
                    <button className={`btn ${this.props.options.classNameCancel}`} onClick={this.props.options.onCancel}>
                        {this.props.options.labelCancel}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ConfirmDialog
