import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import { Modal } from 'react-bootstrap'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import { InputField } from '../../../common/form'
import Form from '../../../common/form/components/Form'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as selectors from '../selectors'
import * as actionCreators from '../actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class TransportScoring extends Component {
    state = {
        scoringItems: null,
        scoringRules: null,
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalQuestion: {
            show: false,
            item: null,
            values: {},
        },
        modalAnswer: {
            show: false,
            item: null,
            question: null,
            values: {},
        },
        modalRule: {
            show: false,
            item: null,
            values: {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    setScoringItems = scoringItems => {
        this.setState({
            scoringItems,
        })
    }

    setScoringRules = scoringRules => {
        this.setState({
            scoringRules,
        })
    }

    getScoringItems = () => this.state.scoringItems || []

    getQuestionAnswers = question => (question && question.answers && Array.isArray(question.answers) ? question.answers : [])

    getScoringRules = () => this.state.scoringRules || []

    // questions modal

    handleCreateQuestion = e => {
        e && e.preventDefault()

        this.setState({
            modalQuestion: {
                show: true,
                item: null,
                values: {},
            },
        })
    }

    handleEditQuestion = question => {
        this.setState({
            modalQuestion: {
                show: true,
                item: question,
                values: {
                    text: question.text,
                },
            },
        })
    }

    modalQuestionChangeValues = values => {
        this.setState({
            modalQuestion: {
                ...this.state.modalQuestion,
                values,
            },
        })
    }

    modalQuestionSubmitForm = () => {
        this.props.actions.saveTransportScoringQuestion({
            text: this.state.modalQuestion.values.text,
            id: this.state.modalQuestion.item && this.state.modalQuestion.item.id,
        })

        this.modalQuestionClose()
    }

    modalQuestionClose = () => {
        this.setState({
            modalQuestion: {
                show: false,
                item: null,
            },
        })
    }

    handleMoveQuestionUp = question => {
        this.props.actions.moveTransportScoringQuestionUp(question.id)
    }

    handleMoveQuestionDown = question => {
        this.props.actions.moveTransportScoringQuestionDown(question.id)
    }

    handleDeleteQuestion = question => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteTransportScoringQuestion(question.id)
                this.hideConfirmDialog()
            },
        })
    }

    // answers modal

    handleCreateAnswer = question => {
        this.setState({
            modalAnswer: {
                show: true,
                item: null,
                question,
                values: {},
            },
        })
    }

    handleEditAnswer = answer => {
        this.setState({
            modalAnswer: {
                show: true,
                item: answer,
                values: {
                    text: answer.text,
                    points: answer.points,
                },
            },
        })
    }

    modalAnswerChangeValues = values => {
        this.setState({
            modalAnswer: {
                ...this.state.modalAnswer,
                values,
            },
        })
    }

    modalAnswerSubmitForm = () => {
        this.props.actions.saveTransportScoringAnswer({
            text: this.state.modalAnswer.values.text,
            points: this.state.modalAnswer.values.points,
            question_id: this.state.modalAnswer.question && this.state.modalAnswer.question.id,
            id: this.state.modalAnswer.item && this.state.modalAnswer.item.id,
        })

        this.modalAnswerClose()
    }

    modalAnswerClose = () => {
        this.setState({
            modalAnswer: {
                show: false,
                item: null,
                question: null,
            },
        })
    }

    handleMoveAnswerUp = answer => {
        this.props.actions.moveTransportScoringAnswerUp(answer.id)
    }

    handleMoveAnswerDown = answer => {
        this.props.actions.moveTransportScoringAnswerDown(answer.id)
    }

    handleDeleteAnswer = answer => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteTransportScoringAnswer(answer.id)
                this.hideConfirmDialog()
            },
        })
    }

    // rules modal

    handleCreateRule = () => {
        this.setState({
            modalRule: {
                show: true,
                item: null,
                values: {},
            },
        })
    }

    handleEditRule = rule => {
        this.setState({
            modalRule: {
                show: true,
                item: rule,
                values: {
                    mark: rule.mark,
                    text: rule.text,
                    points_from: rule.points_from,
                    points_to: rule.points_to,
                },
            },
        })
    }

    modalRuleChangeValues = values => {
        this.setState({
            modalRule: {
                ...this.state.modalRule,
                values,
            },
        })
    }

    modalRuleSubmitForm = () => {
        this.props.actions.saveTransportScoringRule({
            mark: this.state.modalRule.values.mark,
            text: this.state.modalRule.values.text,
            points_from: this.state.modalRule.values.points_from,
            points_to: this.state.modalRule.values.points_to,
            id: this.state.modalRule.item && this.state.modalRule.item.id,
        })

        this.modalRuleClose()
    }

    modalRuleClose = () => {
        this.setState({
            modalRule: {
                show: false,
                item: null,
            },
        })
    }

    handleDeleteRule = rule => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteTransportScoringRule(rule.id)
                this.hideConfirmDialog()
            },
        })
    }

    componentDidMount() {
        this.props.actions.fetchTransportScoring()
    }

    componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(nextProps.scoringItems) !== JSON.stringify(this.props.scoringItems) ||
            (nextProps.scoringItems && !this.state.scoringItems)
        ) {
            this.setScoringItems(nextProps.scoringItems)
        }

        if (
            JSON.stringify(nextProps.scoringRules) !== JSON.stringify(this.props.scoringRules) ||
            (nextProps.scoringRules && !this.state.scoringRules)
        ) {
            this.setScoringRules(nextProps.scoringRules)
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.transportScoring.heading' }))

        const showLoader =
            this.props.isFetching ||
            this.props.isQuestionSaving ||
            this.props.isQuestionDeleting ||
            this.props.isQuestionMovingUp ||
            this.props.isQuestionMovingDown ||
            this.props.isAnswerSaving ||
            this.props.isAnswerDeleting ||
            this.props.isAnswerMovingUp ||
            this.props.isAnswerMovingDown ||
            this.props.isRuleSaving ||
            this.props.isRuleDeleting

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row">
                            <div className="col-md-6" style={{ paddingRight: '0' }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.transportScoring.heading" />
                                            </h4>
                                        </div>
                                        <div className="pull-left wp-100">
                                            <LoadingOverlay active={showLoader}>
                                                {this.getScoringItems().length === 0 && (
                                                    <div className="alert alert-danger pull-left wp-100">
                                                        <FormattedMessage id="fields.scoringNoQuestions" />
                                                    </div>
                                                )}

                                                {this.getScoringItems().map(question => (
                                                    <div key={`question-${question.id}`} className="alert alert-default m-b-xs">
                                                        <div className="clearfix m-t-xs m-b-sm">
                                                            <h4>{question.text}</h4>

                                                            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                                <div className="pull-right">
                                                                    <PermissionsCheck has={[PERMS.UPDATE]}>
                                                                        <button
                                                                            onClick={() => this.handleEditQuestion(question)}
                                                                            className="far fa-pencil text-gray"
                                                                        />
                                                                    </PermissionsCheck>
                                                                    <PermissionsCheck has={[PERMS.UPDATE]}>
                                                                        <button
                                                                            onClick={() => this.handleMoveQuestionUp(question)}
                                                                            className="far fa-arrow-circle-up text-gray"
                                                                        />
                                                                    </PermissionsCheck>
                                                                    <PermissionsCheck has={[PERMS.UPDATE]}>
                                                                        <button
                                                                            onClick={() => this.handleMoveQuestionDown(question)}
                                                                            className="far fa-arrow-circle-down text-gray"
                                                                        />
                                                                    </PermissionsCheck>
                                                                    <PermissionsCheck has={[PERMS.DELETE]}>
                                                                        <button
                                                                            onClick={() => this.handleDeleteQuestion(question)}
                                                                            className="far fa-trash text-gray"
                                                                        />
                                                                    </PermissionsCheck>
                                                                </div>
                                                            </PermissionsCheck>
                                                        </div>

                                                        {this.getQuestionAnswers(question).length === 0 && (
                                                            <div className="alert alert-danger pull-left wp-100 m-b-xs">
                                                                <FormattedMessage id="fields.scoringNoAnswers" />
                                                            </div>
                                                        )}

                                                        <div className="table-container">
                                                            <table className="table table-striped table-hover table-white m-b-xs">
                                                                <tbody>
                                                                    {this.getQuestionAnswers(question).map(answer => (
                                                                        <tr key={`answer-${answer.id}`}>
                                                                            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                                                <td className="w-120 table-buttons">
                                                                                    <PermissionsCheck has={[PERMS.UPDATE]}>
                                                                                        <button
                                                                                            onClick={() => this.handleEditAnswer(answer)}
                                                                                            className="far fa-pencil text-gray m-r-xs"
                                                                                        />
                                                                                    </PermissionsCheck>
                                                                                    <PermissionsCheck has={[PERMS.UPDATE]}>
                                                                                        <button
                                                                                            onClick={() => this.handleMoveAnswerUp(answer)}
                                                                                            className="far fa-arrow-circle-up text-gray m-r-xs"
                                                                                        />
                                                                                    </PermissionsCheck>
                                                                                    <PermissionsCheck has={[PERMS.UPDATE]}>
                                                                                        <button
                                                                                            onClick={() => this.handleMoveAnswerDown(answer)}
                                                                                            className="far fa-arrow-circle-down text-gray m-r-xs"
                                                                                        />
                                                                                    </PermissionsCheck>
                                                                                    <PermissionsCheck has={[PERMS.DELETE]}>
                                                                                        <button
                                                                                            onClick={() => this.handleDeleteAnswer(answer)}
                                                                                            className="far fa-trash text-gray"
                                                                                        />
                                                                                    </PermissionsCheck>
                                                                                </td>
                                                                            </PermissionsCheck>
                                                                            <td>
                                                                                <strong>{answer.text}</strong>
                                                                            </td>
                                                                            <td className="w-160 text-right">
                                                                                {formatters.integerFormatter(answer.points)} b.
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>

                                                            <PermissionsCheck has={[PERMS.CREATE]}>
                                                                <div>
                                                                    <a
                                                                        href="#"
                                                                        onClick={() => this.handleCreateAnswer(question)}
                                                                        className="text-gray"
                                                                    >
                                                                        <i className="far fa-plus-circle" /> <FormattedMessage id="fields.createAnswer" />
                                                                    </a>
                                                                </div>
                                                            </PermissionsCheck>
                                                        </div>
                                                    </div>
                                                ))}

                                                <PermissionsCheck has={[PERMS.CREATE]}>
                                                    <div>
                                                        <button onClick={this.handleCreateQuestion} className="btn btn-default btn-addon">
                                                            <i className="far fa-plus" /> <FormattedMessage id="fields.createQuestion" />
                                                        </button>
                                                    </div>
                                                </PermissionsCheck>
                                            </LoadingOverlay>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6" style={{ paddingLeft: '5px' }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.transportScoring.rules" />
                                            </h4>
                                        </div>
                                        <div className="pull-left wp-100">
                                            <LoadingOverlay active={showLoader}>
                                                {this.getScoringRules().length === 0 && (
                                                    <div className="alert alert-danger pull-left wp-100 m-b-xs">
                                                        <FormattedMessage id="fields.scoringNoRules" />
                                                    </div>
                                                )}

                                                <div className="table-container">
                                                    <table className="table table-striped table-hover table-white m-b-xs">
                                                        <tbody>
                                                            {this.getScoringRules().map(rule => (
                                                                <tr key={`rule-${rule.id}`}>
                                                                    <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                                        <td className="w-80 table-buttons">
                                                                            <PermissionsCheck has={[PERMS.UPDATE]}>
                                                                                <button
                                                                                    onClick={() => this.handleEditRule(rule)}
                                                                                    className="far fa-pencil text-gray m-r-xs"
                                                                                />
                                                                            </PermissionsCheck>
                                                                            <PermissionsCheck has={[PERMS.DELETE]}>
                                                                                <button
                                                                                    onClick={() => this.handleDeleteRule(rule)}
                                                                                    className="far fa-trash text-gray"
                                                                                />
                                                                            </PermissionsCheck>
                                                                        </td>
                                                                    </PermissionsCheck>
                                                                    <td className="w-160 text-center">
                                                                        {formatters.integerFormatter(rule.points_from)} &mdash;{' '}
                                                                        {formatters.integerFormatter(rule.points_to)} b.
                                                                    </td>
                                                                    <td>
                                                                        <strong>{rule.mark}</strong> - {rule.text}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                                        <div>
                                                            <a href="#" onClick={() => this.handleCreateRule()} className="text-gray">
                                                                <i className="far fa-plus-circle" /> <FormattedMessage id="fields.createRule" />
                                                            </a>
                                                        </div>
                                                    </PermissionsCheck>
                                                </div>
                                            </LoadingOverlay>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalQuestion.show)} onHide={this.modalQuestionClose} bsSize="small">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.question" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PermissionsCheck hasAny={[PERMS.CREATE, PERMS.UPDATE]}>
                                <Form
                                    validationRules={{}}
                                    values={this.state.modalQuestion.values}
                                    onChange={this.modalQuestionChangeValues}
                                    onSubmit={this.modalQuestionSubmitForm}
                                >
                                    <div className="form-group">
                                        <label htmlFor="nothing">
                                            <FormattedMessage id={'fields.text'} />:
                                        </label>
                                        <div>
                                            <InputField id="text" />
                                        </div>
                                    </div>

                                    <div className="btns-form clearfix">
                                        <button type="button" className="btn btn-addon btn-default pull-left" onClick={this.modalQuestionClose}>
                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                        </button>
                                        <button type="submit" className="btn btn-addon btn-success pull-right">
                                            <i className="far fa-check" /> <FormattedMessage id="buttons.save" />
                                        </button>
                                    </div>
                                </Form>
                            </PermissionsCheck>
                        </Modal.Body>
                    </Modal>

                    <Modal show={Boolean(this.state.modalAnswer.show)} onHide={this.modalAnswerClose} bsSize="small">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.answer" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PermissionsCheck hasAny={[PERMS.CREATE, PERMS.UPDATE]}>
                                <Form
                                    validationRules={{}}
                                    values={this.state.modalAnswer.values}
                                    onChange={this.modalAnswerChangeValues}
                                    onSubmit={this.modalAnswerSubmitForm}
                                >
                                    <div className="form-group">
                                        <label htmlFor="nothing">
                                            <FormattedMessage id={'fields.text'} />:
                                        </label>
                                        <div>
                                            <InputField id="text" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.pointsCount'} />:
                                                </label>
                                                <div>
                                                    <InputField id="points" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="btns-form clearfix">
                                        <button type="button" className="btn btn-addon btn-default pull-left" onClick={this.modalAnswerClose}>
                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                        </button>
                                        <button type="submit" className="btn btn-addon btn-success pull-right">
                                            <i className="far fa-check" /> <FormattedMessage id="buttons.save" />
                                        </button>
                                    </div>
                                </Form>
                            </PermissionsCheck>
                        </Modal.Body>
                    </Modal>

                    <Modal show={Boolean(this.state.modalRule.show)} onHide={this.modalRuleClose} bsSize="small">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="fields.rule" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PermissionsCheck hasAny={[PERMS.CREATE, PERMS.UPDATE]}>
                                <Form
                                    validationRules={{}}
                                    values={this.state.modalRule.values}
                                    onChange={this.modalRuleChangeValues}
                                    onSubmit={this.modalRuleSubmitForm}
                                >
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.mark'} />:
                                                </label>
                                                <div>
                                                    <InputField id="mark" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.text'} />:
                                                </label>
                                                <div>
                                                    <InputField id="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.pointsCountFrom'} />:
                                                </label>
                                                <div>
                                                    <InputField id="points_from" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.pointsCountTo'} />:
                                                </label>
                                                <div>
                                                    <InputField id="points_to" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="btns-form clearfix">
                                        <button type="button" className="btn btn-addon btn-default pull-left" onClick={this.modalRuleClose}>
                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                        </button>
                                        <button type="submit" className="btn btn-addon btn-success pull-right">
                                            <i className="far fa-check" /> <FormattedMessage id="buttons.save" />
                                        </button>
                                    </div>
                                </Form>
                            </PermissionsCheck>
                        </Modal.Body>
                    </Modal>

                    <ConfirmDialog options={this.state.confirmDialog} />
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        scoringItems: selectors.getItems(state),
        scoringRules: selectors.getRules(state),
        isFetching: selectors.isTransportScoringFetching(state),
        isQuestionSaving: selectors.isTransportScoringQuestionSaving(state),
        isQuestionDeleting: selectors.isTransportScoringQuestionDeleting(state),
        isQuestionMovingUp: selectors.isTransportScoringQuestionMovingUp(state),
        isQuestionMovingDown: selectors.isTransportScoringQuestionMovingDown(state),
        isAnswerSaving: selectors.isTransportScoringAnswerSaving(state),
        isAnswerDeleting: selectors.isTransportScoringAnswerDeleting(state),
        isAnswerMovingUp: selectors.isTransportScoringAnswerMovingUp(state),
        isAnswerMovingDown: selectors.isTransportScoringAnswerMovingDown(state),
        isRuleSaving: selectors.isTransportScoringRuleSaving(state),
        isRuleDeleting: selectors.isTransportScoringRuleDeleting(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TransportScoring)
)
