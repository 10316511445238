import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import ArrowField from './ArrowField'

class DayPicker extends Component {
    onClick = weeks => {
        const momentFrom = this.props.momentFrom.add(weeks, 'weeks')
        const momentTo = this.props.momentTo.add(weeks, 'weeks')

        this.props.onClick(momentFrom, momentTo)
    }

    render() {
        const year = this.props.momentTo.year()
        const week = this.props.momentFrom.week()

        const value = `${year} / ${week}${this.props.intl.formatMessage({ id: 'modules.periodPicke.weekShort' })}`

        const isMin = this.props.minYear === year && this.props.momentTo.week() === 1
        const isMax =
            this.props.maxYear <
            moment(this.props.momentTo)
                .add(1, 'weeks')
                .year()

        return <ArrowField onClick={this.onClick} value={value} isMin={isMin} isMax={isMax} />
    }
}

export default injectIntl(DayPicker)
