import React, { Component } from 'react'
import { Variable } from './Variable'

class ConstantList extends Component {
    render() {
        return (
            <div ref={this.drag} className="variable-list">
                {this.props.variables.keySeq().map(variableKey => {
                    const variable = this.props.variables.get(variableKey)
                    return <Variable key={variableKey} variableKey={variableKey} variable={variable} onDrag={this.props.onDrag} />
                })}
            </div>
        )
    }
}

export default ConstantList
