import { Record } from 'immutable'

export class Trailer extends Record({
    id: undefined,
    name: undefined,
    registration: undefined,
    vin: undefined,
    trailer_type_id: undefined,
    is_active: undefined,
    is_damage: undefined,
    is_security_damage: undefined,
    is_cooling_damage: undefined,
    company_cost_center_id: undefined,
    current_position: undefined,
    trailer_current_position: undefined,
    is_spedition: undefined,
    carrier_id: undefined,
    last_finished_vehicle_event: undefined,
    deleted_at: undefined,
}) {}
