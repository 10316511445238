import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchReportVehicleProfitsEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request({
                method: 'GET',
                path: `report-vehicle-profits?year=${action.payload.year}&filters=${JSON.stringify(action.payload.filters)}&inclCostCenters=${action.payload.inclCostCenters}&inclDrivers=${action.payload.inclDrivers}&inclTrailers=${action.payload.inclTrailers}&inclFeatureCosts=${action.payload.inclFeatureCosts}&withEmpty=${action.payload.withEmpty}&withInactive=${action.payload.withInactive}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportVehicleProfitsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportVehicleProfitsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    )

// Export

const exportReportVehicleProfitsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-vehicle-profits/export?year=${action.payload.year}&filters=${JSON.stringify(action.payload.filters)}&token=${token}&inclCostCenters=${action.payload.inclCostCenters}&inclDrivers=${action.payload.inclDrivers}&inclTrailers=${action.payload.inclTrailers}&inclFeatureCosts=${action.payload.inclFeatureCosts}&withEmpty=${action.payload.withEmpty}&withInactive=${action.payload.withInactive}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })


// Fetch details

const fetchReportVehicleProfitsDetailsEpic = action$ =>
    action$.ofType(actionTypes.FETCH_DETAILS).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DETAILS_STARTED,
            }),
            request({
                method: 'GET',
                // eslint-disable-next-line prettier/prettier
                path: `report-vehicle-profits/details?id=${action.payload.id}&year=${action.payload.year}&month=${action.payload.month}&inclCostCenters=${action.payload.inclCostCenters}&inclDrivers=${action.payload.inclDrivers}&inclTrailers=${action.payload.inclTrailers}&inclFeatureCosts=${action.payload.inclFeatureCosts}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportVehicleProfitsDetailsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportVehicleProfitsDetailsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_DETAILS_CANCELLED))
        )
    )

// Export details

const exportReportVehicleProfitsDetailsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_DETAILS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-vehicle-profits/details/export?id=${action.payload.id}&year=${action.payload.year}&month=${action.payload.month}&token=${token}&inclCostCenters=${action.payload.inclCostCenters}&inclDrivers=${action.payload.inclDrivers}&inclTrailers=${action.payload.inclTrailers}&inclFeatureCosts=${action.payload.inclFeatureCosts}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_DETAILS_FULFILLED,
            })
        )
    })

export default [
    fetchReportVehicleProfitsEpic,
    exportReportVehicleProfitsEpic,
    fetchReportVehicleProfitsDetailsEpic,
    exportReportVehicleProfitsDetailsEpic,
]
