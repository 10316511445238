import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError } from '../../common/api'
import { vehiclePositionSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { VehiclePosition } from './model'

import { Country } from '../countries/model'
import * as countriesActionCreators from '../countries/actionCreators'
import { Driver } from '../drivers/model'
import * as driversActionCreators from '../drivers/actionCreators'
import { Vehicle } from '../vehicles/model'
import * as vehiclesActionCreators from '../vehicles/actionCreators'
import { fromJS } from "immutable";

// Fetch

const fetchVehiclePositionsEpic = (action$, store) => {
    const tableIdentifier = 'vehicle_positions_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `vehicle-positions/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(vehiclePositionSchema)
                        )

                        const vehicle_positions = normalizedEntitiesToRecordMap(normalizedData.entities.vehicle_positions, VehiclePosition, normalizedData.result)
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                        const drivers = normalizedEntitiesToRecordMap(normalizedData.entities.drivers, Driver)
                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)

                        const observables = [
                            Observable.of(actionCreators.fetchVehiclePositionsFulfilled(vehicle_positions)),
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(driversActionCreators.fetchDriversFulfilled(drivers)),
                             Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchVehiclePositionsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}
// Delete

const deleteVehiclePositionEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().vehiclePositions.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `vehicle-positions/${action.payload.id}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteVehiclePositionFulfilled(action.payload.id)))
                    .catch(error => Observable.of(actionCreators.deleteVehiclePositionRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )


export default [fetchVehiclePositionsEpic, deleteVehiclePositionEpic]
