import React from 'react'
import { Route, Switch } from 'react-router'
import ReportInvoicingSummary from './ReportInvoicingSummary'

const Components = props => (
    <Switch>
        <Route exact path={props.match.url} component={ReportInvoicingSummary} />
    </Switch>
)

export default Components
