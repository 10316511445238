import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchBoardEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(action => {
        const date = action.payload.format('Y-MM-DD')

        const requestParams = {
            method: 'GET',
            path: `board?date=${date}`,
        }

        return Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request(requestParams)
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchBoardFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchBoardRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    })

const updateVehicleNoteEpic = (action$, store) =>
    action$
        .ofType(actionTypes.VEHICLE_NOTE_UPDATE)
        .filter(() => !store.getState().board.getIn(['vehicle_note', 'saving']))
        .switchMap(action => {
            const requestParams = {
                method: 'POST',
                path: `board/update-vehicle-note`,
                body: {
                    vehicle_id: action.payload.vehicleID,
                    text: action.payload.text,
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.VEHICLE_NOTE_UPDATE_STARTED,
                }),
                request(requestParams)
                    .switchMap(() => Observable.concat(Observable.of(actionCreators.updateVehicleNoteFulfilled())))
                    .catch(error => Observable.of(actionCreators.updateVehicleNoteRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.VEHICLE_NOTE_UPDATE_CANCELLED))
            )
        })

const updateTrailerNoteEpic = (action$, store) =>
    action$
        .ofType(actionTypes.TRAILER_NOTE_UPDATE)
        .filter(() => !store.getState().board.getIn(['trailer_note', 'saving']))
        .switchMap(action => {
            const requestParams = {
                method: 'POST',
                path: `board/update-trailer-note`,
                body: {
                    trailer_id: action.payload.trailerID,
                    text: action.payload.text,
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.TRAILER_NOTE_UPDATE_STARTED,
                }),
                request(requestParams)
                    .switchMap(() => Observable.concat(Observable.of(actionCreators.updateTrailerNoteFulfilled())))
                    .catch(error => Observable.of(actionCreators.updateTrailerNoteRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.TRAILER_NOTE_UPDATE_CANCELLED))
            )
        })

const updateTrailerInfoEpic = (action$, store) =>
    action$
        .ofType(actionTypes.TRAILER_INFO_UPDATE)
        .filter(() => !store.getState().board.getIn(['trailer_info', 'saving']))
        .switchMap(action => {
            const requestParams = {
                method: 'POST',
                path: `board/update-trailer-info`,
                body: {
                    trailer_id: action.payload.trailersId,
                    is_damaged: action.payload.values.is_damaged,
                    is_security_damaged: action.payload.values.is_security_damaged,
                    is_cooling_damaged: action.payload.values.is_cooling_damaged,
                    current_position: action.payload.values.current_position,
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.TRAILER_INFO_UPDATE_STARTED,
                }),
                request(requestParams)
                    .switchMap(() => Observable.concat(Observable.of(actionCreators.updateTrailerInfoFulfilled())))
                    .catch(error => Observable.of(actionCreators.updateTrailerInfoRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.TRAILER_INFO_UPDATE_CANCELLED))
            )
        })

const updateDailyNoteEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DAILY_NOTE_UPDATE)
        .filter(() => !store.getState().board.getIn(['daily_note', 'saving']))
        .switchMap(action => {
            const requestParams = {
                method: 'POST',
                path: `board/update-daily-note`,
                body: {
                    vehicle_id: action.payload.vehicleID,
                    trailer_id: action.payload.trailerID,
                    date: action.payload.date.format('Y-MM-DD'),
                    text: action.payload.text,
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.DAILY_NOTE_UPDATE_STARTED,
                }),
                request(requestParams)
                    .switchMap(() => Observable.concat(Observable.of(actionCreators.updateDailyNoteFulfilled())))
                    .catch(error => Observable.of(actionCreators.updateDailyNoteRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DAILY_NOTE_UPDATE_CANCELLED))
            )
        })

const updateDailyInfoEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DAILY_INFO_UPDATE)
        .filter(() => !store.getState().board.getIn(['daily_info', 'saving']))
        .switchMap(action => {
            const requestParams = {
                method: 'POST',
                path: `board/update-daily-info`,
                body: {
                    vehicle_id: action.payload.vehicleID,
                    is_done: action.payload.isDone,
                    is_sent: action.payload.isSent,
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.DAILY_INFO_UPDATE_STARTED,
                }),
                request(requestParams)
                    .switchMap(() => Observable.concat(Observable.of(actionCreators.updateDailyInfoFulfilled())))
                    .catch(error => Observable.of(actionCreators.updateDailyInfoRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DAILY_INFO_UPDATE_CANCELLED))
            )
        })

const updateWeeklyInfoEpic = (action$, store) =>
    action$
        .ofType(actionTypes.WEEKLY_INFO_UPDATE)
        .filter(() => !store.getState().board.getIn(['weekly_info', 'saving']))
        .switchMap(action => {
            const requestParams = {
                method: 'POST',
                path: `board/update-weekly-info`,
                body: {
                    vehicle_id: action.payload.vehicleID,
                    year: action.payload.year,
                    week: action.payload.week,
                    weekend_at_home: action.payload.weekendAtHome,
                    weekend_at_home_note: action.payload.weekendAtHomeNote,
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.WEEKLY_INFO_UPDATE_STARTED,
                }),
                request(requestParams)
                    .switchMap(() => Observable.concat(Observable.of(actionCreators.updateWeeklyInfoFulfilled())))
                    .catch(error => Observable.of(actionCreators.updateWeeklyInfoRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.WEEKLY_INFO_UPDATE_CANCELLED))
            )
        })

const exportBoardEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const date = action.payload.date.format('Y-MM-DD')
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const preferences = JSON.stringify(action.payload.preferences)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `board/export?date=${date}&filters=${filters}&sorting=${sorting}&preferences=${preferences}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

const updatePreferencesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.PREFERENCES_UPDATE)
        .filter(() => !store.getState().board.getIn(['preferences', 'saving']))
        .switchMap(action => {
            const requestParams = {
                method: 'POST',
                path: `board/update-preferences`,
                body: {
                    preferences: action.payload.preferences,
                    date: action.payload.date.format('Y-MM-DD'),
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.PREFERENCES_UPDATE_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => Observable.of(actionCreators.updatePreferencesFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.updatePreferencesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.PREFERENCES_UPDATE_CANCELLED))
            )
        })

const updatePointNoteEpic = (action$, store) =>
    action$
        .ofType(actionTypes.POINT_NOTE_UPDATE)
        .filter(() => !store.getState().board.getIn(['point_note', 'saving']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.POINT_NOTE_UPDATE_STARTED,
                }),
                request({
                    method: 'POST',
                    path: `board/update-point-note`,
                    body: {
                        transport_point_id: action.payload.transportPointId,
                        note: action.payload.note,
                    },
                })
                    .switchMap(() => Observable.concat(Observable.of(actionCreators.updateTransportPointNoteFulfilled())))
                    .catch(error => Observable.of(actionCreators.updateTransportPointNoteRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.POINT_NOTE_UPDATE_CANCELLED))
            )
        )

export default [
    fetchBoardEpic,
    updateVehicleNoteEpic,
    updateTrailerNoteEpic,
    updateTrailerInfoEpic,
    updateDailyNoteEpic,
    updateDailyInfoEpic,
    updateWeeklyInfoEpic,
    exportBoardEpic,
    updatePreferencesEpic,
    updatePointNoteEpic,
]
