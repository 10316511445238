import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'

import { Modal } from 'react-bootstrap'
import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as transportsActionCreators from '../actionCreators'
import { getAllowancesData, getAllowancesFetching, getAllowancesSaving, getAllowancesDeleting } from '../selectors'
import { InputField, SelectField, SubmitButton } from '../../../common/form'
import Form from '../../../common/form/components/Form'
import { getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_ALLOWANCES } from '../permissions'

const componentIdentifier = 'transport_allowances'
const prerequisites = ['currencies', 'drivers', 'allowance_types']

class TransportsAllowances extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        addAllowancesDialog: {
            show: false,
        },
        values: {},
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    handleChangeValue = values => {
        this.setState({ values })
    }

    deleteItem = (e, allowance) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteAllowance({ allowanceId: allowance.id, transportId: this.props.transport.id })
                this.hideConfirmDialog()
            },
        })
    }

    editItem = (e, allowance) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.setState({
            values: {
                ...allowance,
                allowance_type: allowance.allowance_type && allowance.allowance_type.id,
                currency_id: allowance.currency && allowance.currency.id,
                driver_id: allowance.driver && allowance.driver.id,
            },
            addAllowancesDialog: {
                show: true,
            },
        })
    }

    submitAddAllowanceForm = () => {
        this.setState({ addAllowancesDialog: { show: false } })
        if (this.props.transport) {
            this.props.actions.saveAllowances([{ transport_id: this.props.transport.id, ...this.state.values }])
            this.setState({ values: {} })
        }
    }

    reAssignAllowances = transportId => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.reAssignAllowances.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.actions.reassignAllowances(transportId)
                this.hideConfirmDialog()
            },
        })
    }

    refresh = () => {
        this.props.actions.fetchAllowances(this.props.transport.id)
    }

    componentDidMount() {
        if (this.props.transport) {
            this.props.actions.fetchAllowances(this.props.transport.id)
            this.props.actions.fetchPrerequisites()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.transport && JSON.stringify(nextProps.transport) !== JSON.stringify(this.props.transport)) {
            this.props.actions.fetchAllowances(nextProps.transport.id)
        }
    }

    render() {
        const transport = this.props.transport

        if (!transport) {
            return null
        }

        const rows = this.props.data
        const total = rows ? Object.keys(rows).length : 0

        // generates array with values for allowance calculation methods
        // here only fixed method will be used (ID: 1)
        const calculationMethods = []
        for (let i = 1; i < 2; i++) {
            calculationMethods.push({
                id: String(i),
                name: this.props.intl.formatMessage({ id: `modules.reportAllowances.calculationMethods.${i}` }),
            })
        }

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_ALLOWANCES)} noPermissionsTab>
                <LoadingOverlay
                    active={this.props.prerequisites.status.get('fetching') || this.props.isFetching || this.props.isSaving || this.props.isDeleting}
                >
                    <div className="panel-head">
                        <h2 className="pull-left wp-100">
                            <div className="pull-left">
                                <FormattedMessage id="modules.transports.allowances" />
                            </div>
                            <div className="pull-right">
                                <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                    <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                </button>
                                <PermissionsCheck has={[PERMS_ALLOWANCES.CREATE, PERMS_ALLOWANCES.DELETE, PERMS_ALLOWANCES.UPDATE]}>
                                    <button
                                        className="btn btn-default btn-addon m-r-xs"
                                        onClick={() => this.reAssignAllowances(this.props.transport.id)}
                                    >
                                        <i className="far fa-undo" /> <FormattedMessage id="modules.transports.reInitAllowances" />
                                    </button>
                                </PermissionsCheck>
                                <PermissionsCheck has={[PERMS_ALLOWANCES.CREATE]}>
                                    <button
                                        className="btn btn-success btn-addon"
                                        onClick={() => this.setState({ addAllowancesDialog: { show: true }, values: {} })}
                                    >
                                        <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                                    </button>
                                </PermissionsCheck>
                            </div>
                        </h2>
                    </div>

                    <div className="pull-left wp-100 m-b-md">
                        {rows && rows.length > 0 ? (
                            <div className="pull-left wp-100">
                                <div className="table-container mh-500">
                                    <table className="table table-striped table-no-border no-m">
                                        <thead>
                                            <tr>
                                                <PermissionsCheck hasAny={[PERMS_ALLOWANCES.UPDATE, PERMS_ALLOWANCES.DELETE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.allowanceType" />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.allowanceCalculationMethod" />
                                                </th>
                                                <th className="w-120 text-right">
                                                    <FormattedMessage id="fields.allowanceAmount" />
                                                </th>
                                                {!transport.is_template && (
                                                    <React.Fragment>
                                                        <th className="w-80 text-right">
                                                            <FormattedMessage id="fields.count" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.total" />
                                                        </th>
                                                    </React.Fragment>
                                                )}
                                                <th className="w-max wm-200">
                                                    <FormattedMessage id="fields.note" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows &&
                                                rows.map((allowance, index) => (
                                                    <tr onClick={e => this.editItem(e, allowance)} className="row-selectable" key={`row-${index}`}>
                                                        <PermissionsCheck hasAny={[PERMS_ALLOWANCES.UPDATE, PERMS_ALLOWANCES.DELETE]}>
                                                            <td className="w-60 table-buttons">
                                                                <PermissionsCheck has={[PERMS_ALLOWANCES.UPDATE]}>
                                                                    <button className="far fa-pencil text-gray m-r-xxs" />
                                                                </PermissionsCheck>
                                                                <PermissionsCheck has={[PERMS_ALLOWANCES.DELETE]}>
                                                                    <button
                                                                        onClick={e => this.deleteItem(e, allowance)}
                                                                        className="far fa-trash text-gray"
                                                                    />
                                                                </PermissionsCheck>
                                                            </td>
                                                        </PermissionsCheck>
                                                        <td className="w-160">{allowance.driver && allowance.driver.name}</td>
                                                        <td className="w-120">{allowance.allowance_type && allowance.allowance_type.name}</td>
                                                        <td className="w-140">
                                                            <FormattedMessage
                                                                id={`modules.reportAllowances.calculationMethods.${allowance.calculation_method}`}
                                                            />
                                                        </td>
                                                        <td className="w-120 text-right">
                                                            {formatters.priceFormatter(
                                                                allowance.allowance,
                                                                '0,0',
                                                                allowance.currency ? allowance.currency.iso_code : ''
                                                            )}
                                                        </td>
                                                        {!transport.is_template && (
                                                            <React.Fragment>
                                                                <td className="w-80 text-right">
                                                                    {formatters.integerFormatter(allowance.units_count)} x
                                                                </td>
                                                                <td className="w-120 text-right">
                                                                    <strong>
                                                                        {formatters.priceFormatter(
                                                                            allowance.allowance * allowance.units_count,
                                                                            '0,0',
                                                                            allowance.currency ? allowance.currency.iso_code : ''
                                                                        )}
                                                                    </strong>
                                                                </td>
                                                            </React.Fragment>
                                                        )}
                                                        <td className="w-max wm-200">{allowance.notice}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />: {getListItemsCountMessage(0, 0, total)}
                                </div>
                            </div>
                        ) : (
                            <div className="alert alert-warning pull-left wp-100">
                                <p>
                                    <FormattedMessage id="fields.noAllowancesFound" />
                                </p>
                            </div>
                        )}
                    </div>

                    <Modal
                        show={Boolean(this.state.addAllowancesDialog.show)}
                        className="modal-size-sm"
                        onHide={() => this.setState({ addAllowancesDialog: { show: false } })}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.values.id ? <FormattedMessage id="fields.itemEdit" /> : <FormattedMessage id="fields.itemCreate" />}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PermissionsCheck hasAny={Object.values(PERMS_ALLOWANCES)}>
                                <Form
                                    validationRules={{}}
                                    values={this.state.values}
                                    onChange={this.handleChangeValue}
                                    onSubmit={this.submitAddAllowanceForm}
                                    isEdit={Boolean(this.state.values.id)}
                                >
                                    <label htmlFor="nothing">
                                        <FormattedMessage id="modules.transports.driver" />:
                                    </label>
                                    <div className="form-group">
                                        <div className="pull-left wp-100">
                                            <SelectField
                                                id="driver_id"
                                                values={getPrerequisitesArray(
                                                    this.props.prerequisites.values
                                                        .get('drivers')
                                                        .filter(driver => transport.drivers.toJS().includes(driver.get('id'))),
                                                    'id',
                                                    'name'
                                                )}
                                                prompt={false}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.allowanceType'} />:
                                                </label>
                                                <div>
                                                    <SelectField
                                                        id="allowance_type"
                                                        values={getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('allowance_types'),
                                                            'id',
                                                            'name'
                                                        )}
                                                        prompt={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.allowanceCalculationMethod'} />:
                                                </label>
                                                <SelectField id="calculation_method" values={calculationMethods} prompt={false} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.allowanceAmount'} />:
                                                </label>
                                                <div>
                                                    <InputField id="allowance" type="numeric" className="text-right" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.currency'} />:
                                                </label>
                                                <div>
                                                    <SelectField
                                                        id="currency_id"
                                                        values={getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('currencies'),
                                                            'id',
                                                            'iso_code'
                                                        )}
                                                        prompt={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="nothing">
                                                    <FormattedMessage id={'fields.count'} />:
                                                </label>
                                                <div>
                                                    <InputField id="units_count" type="numeric" className="text-right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <InputField id="notice" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />
                                    </div>

                                    <div className="btns-form clearfix">
                                        <button
                                            type="button"
                                            className="btn btn-addon btn-default pull-left"
                                            onClick={() => this.setState({ addAllowancesDialog: { show: false } })}
                                        >
                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                        </button>
                                        <SubmitButton
                                            perms={Object.values(PERMS_ALLOWANCES)}
                                            isEdit={Boolean(this.state.values.id)}
                                            className="btn-addon pull-right"
                                        />
                                    </div>
                                </Form>
                            </PermissionsCheck>
                        </Modal.Body>
                    </Modal>

                    <ConfirmDialog options={this.state.confirmDialog} />
                </LoadingOverlay>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: getAllowancesData(state),
        isFetching: getAllowancesFetching(state),
        isSaving: getAllowancesSaving(state),
        isDeleting: getAllowancesDeleting(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...transportsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TransportsAllowances)
)
