import React from 'react'
import { Route, Switch } from 'react-router'
import TaxRatesList from './TaxRatesList'
import TaxRatesEdit from './TaxRatesEdit'

const Countries = props => (
    <Switch>
        <Route exact path={props.match.url} component={TaxRatesList} />
        <Route exact path={`${props.match.url}/new`} component={TaxRatesEdit} />
        <Route exact path={`${props.match.url}/:taxRateID`} component={TaxRatesEdit} />
    </Switch>
)

export default Countries
