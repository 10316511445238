import * as actionTypes from './actionTypes'

// Fetch

export const fetchBorderCrossings = () => ({
    type: actionTypes.FETCH,
})

export const fetchBorderCrossingsFulfilled = borderCrossings => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: borderCrossings,
})

export const fetchBorderCrossingsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchBorderCrossingsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchBorderCrossing = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchBorderCrossingFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchBorderCrossingRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchBorderCrossingCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveBorderCrossing = values => ({
    type: actionTypes.SAVE,
    payload: {
        values,
    },
})

export const saveBorderCrossingFulfilled = customer => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: customer,
})

export const saveBorderCrossingRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteBorderCrossing = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteBorderCrossingFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteBorderCrossingRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Find countries

export const findCountries = values => ({
    type: actionTypes.FIND_COUNTRIES,
    payload: values,
})

export const findCountriesFulfilled = countries => ({
    type: actionTypes.FIND_COUNTRIES_FULFILLED,
    payload: countries,
})

export const findCountriesRejected = message => ({
    type: actionTypes.FIND_COUNTRIES_REJECTED,
    payload: message,
})
