import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'

import * as reportCustomerTurnoversActionCreators from '../actionCreators'
import { getMonth, getData } from '../selectors'

import { TableModelColumnFilteringType } from '../../../common/table/model'
import {TableFilterCheckboxField, TableFilterInputField} from '../../../common/table/components'
import { createTableModelSelector } from '../../../common/table/selectors'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { getActiveFilters } from '../../../common/table/helpers'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'report_customer_turnovers_list'

class ReportCustomerTurnovers extends Component {
    getMonth = () => {
        if (this.props.month) {
            return this.props.month
        }

        return this.props.match.params.month ? this.props.match.params.month : moment().format('YYYY-MM')
    }

    refresh = () => {
        this.props.actions.fetchReportCustomerTurnovers(this.getMonth(), this.props.tableModel.get('filters').toJS())
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleChangeYearMonth = format => {
        const month = `0${format.month.toString()}`.slice(-2)
        const year = format.year.toString()
        const path = `/report-customer-turnovers/${year}-${month}`
        this.props.history.push(path)
    }

    componentDidMount() {
        this.props.actions.fetchReportCustomerTurnovers(this.getMonth(), this.props.tableModel.get('filters').toJS())
    }

    componentWillUnmount() {
        this.props.actions.clearReportCustomerTurnovers()
    }

    componentWillReceiveProps(nextProps) {
        const parsedMonth = nextProps.match.params.month ? nextProps.match.params.month : moment().format('YYYY-MM')
        const filtersChanged = JSON.stringify(nextProps.tableModel.get('filters').toJS()) != JSON.stringify(this.props.tableModel.get('filters').toJS())

        if (parsedMonth !== this.getMonth() || filtersChanged) {
            this.props.actions.clearReportCustomerTurnovers()
            this.props.actions.fetchReportCustomerTurnovers(parsedMonth, nextProps.tableModel.get('filters').toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportCustomerTurnovers.heading' }))

        const momentFrom = moment(`${this.getMonth()}-01`, 'YYYY-MM-DD')
        const momentTo = moment(momentFrom).endOf('month')

        const filters = this.props.tableModel.get('filters')

        const data = this.props.data
        const total = {
            total_czk: 0,
            km_loaded: 0,
            km_start: 0,
            km_service: 0,
            kmEmpty: 0,
            kmTotal: 0,
        }

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportCustomerTurnovers.heading" />
                                        </span>
                                    </h4>
                                    <div className="pull-left" style={{ marginLeft: '100px' }}>
                                    <span className="pull-left text-gray f-bold" style={{ paddingTop: '10px' }}>
                                        <FormattedMessage id="fields.showIncl" />:
                                    </span>
                                        <div className="pull-left m-l-sm">
                                            <TableFilterCheckboxField
                                                identifier="with_empty"
                                                type={TableModelColumnFilteringType.bool}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                label={this.props.intl.formatMessage({ id: 'fields.showInclEmpty' })}
                                            />
                                        </div>
                                    </div>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className={'m-r-sm'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            onChange={(momentFrom, momentTo, format) => this.handleChangeYearMonth(format)}
                                            allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_MONTH]}
                                            forbidPickType
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                    </div>
                                </div>
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                        <tr>
                                            <th className="w-max">
                                                <FormattedMessage id="fields.company" />
                                            </th>
                                            <th className="w-140 text-right">CZK</th>
                                            <th className="w-140 text-right">
                                                <FormattedMessage id="fields.kmFull" />
                                            </th>
                                            <th className="w-140 text-right">
                                                <FormattedMessage id="fields.kmApproach" />
                                            </th>
                                            <th className="w-140 text-right">
                                                <FormattedMessage id="fields.kmService" />
                                            </th>
                                            <th className="w-140 text-right">
                                                &sum; <FormattedMessage id="fields.kmEmpty" />
                                            </th>
                                            <th className="w-140 text-right">&sum; KM</th>
                                            <th className="w-140 text-right">
                                                CZK/<FormattedMessage id="fields.kmFull" />
                                            </th>
                                            <th className="w-140 text-right">CZK/KM</th>
                                        </tr>
                                        <tr className="filters">
                                            <th className="w-max">
                                                <TableFilterInputField
                                                    identifier="customer_name"
                                                    type={TableModelColumnFilteringType.string}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-140 text-right" />
                                            <th className="w-140 text-right" />
                                            <th className="w-140 text-right" />
                                            <th className="w-140 text-right" />
                                            <th className="w-140 text-right" />
                                            <th className="w-140 text-right" />
                                            <th className="w-140 text-right" />
                                            <th className="w-140 text-right" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data &&
                                            data.customers &&
                                            data.customers.map(customer => {
                                                const kmEmpty = customer.km_start + customer.km_service
                                                const kmTotal = customer.km_loaded + customer.km_start + customer.km_service

                                                total.total_czk += customer.total_czk
                                                total.km_loaded += customer.km_loaded
                                                total.km_start += customer.km_start
                                                total.km_service += customer.km_service
                                                total.kmEmpty += kmEmpty
                                                total.kmTotal += kmTotal

                                                return (
                                                    <tr key={`row-${customer.id}`}>
                                                        <td className="w-max">{customer.name}</td>
                                                        <td className="w-140 text-right">{formatters.priceFormatter(customer.total_czk)}</td>
                                                        <td className="w-140 text-right">{formatters.distanceFormatter(customer.km_loaded)}</td>
                                                        <td className="w-140 text-right">{formatters.distanceFormatter(customer.km_start)}</td>
                                                        <td className="w-140 text-right">{formatters.distanceFormatter(customer.km_service)}</td>
                                                        <td className="w-140 text-right">{formatters.distanceFormatter(kmEmpty)}</td>
                                                        <td className="w-140 text-right">{formatters.distanceFormatter(kmTotal)}</td>
                                                        <td className="w-140 text-right">
                                                            {formatters.priceFormatter(
                                                                customer.km_loaded ? customer.total_czk / customer.km_loaded : 0,
                                                                '0,0.00'
                                                            )}
                                                        </td>
                                                        <td className="w-140 text-right">
                                                            {formatters.priceFormatter(kmTotal ? customer.total_czk / kmTotal : 0, '0,0.00')}
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                        <tr className="b-top">
                                            <td className="w-max">
                                                <strong>
                                                    <FormattedMessage id="fields.total" />:
                                                </strong>
                                            </td>
                                            <td className="w-140 text-right">
                                                <strong>{formatters.priceFormatter(total.total_czk)}</strong>
                                            </td>
                                            <td className="w-140 text-right">
                                                <strong>{formatters.distanceFormatter(total.km_loaded)}</strong>
                                            </td>
                                            <td className="w-140 text-right">
                                                <strong>{formatters.distanceFormatter(total.km_start)}</strong>
                                            </td>
                                            <td className="w-140 text-right">
                                                <strong>{formatters.distanceFormatter(total.km_service)}</strong>
                                            </td>
                                            <td className="w-140 text-right">
                                                <strong>{formatters.distanceFormatter(total.kmEmpty)}</strong>
                                            </td>
                                            <td className="w-140 text-right">
                                                <strong>{formatters.distanceFormatter(total.kmTotal)}</strong>
                                            </td>
                                            <td className="w-140 text-right">
                                                <strong>
                                                    {formatters.priceFormatter(total.km_loaded ? total.total_czk / total.km_loaded : 0, '0,0.00')}
                                                </strong>
                                            </td>
                                            <td className="w-140 text-right">
                                                <strong>
                                                    {formatters.priceFormatter(total.kmTotal ? total.total_czk / total.kmTotal : 0, '0,0.00')}
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        month: getMonth(state),
        data: getData(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportCustomerTurnoversActionCreators,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(withRouter(ReportCustomerTurnovers))
)
