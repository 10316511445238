// Fetch list

export const FETCH = 'goodsTypes.FETCH'
export const FETCH_STARTED = 'goodsTypes.FETCH_STARTED'
export const FETCH_FULFILLED = 'goodsTypes.FETCH_FULFILLED'
export const FETCH_REJECTED = 'goodsTypes.FETCH_REJECTED'
export const FETCH_CANCELLED = 'goodsTypes.FETCH_CANCELLED'

export const SORTING_CHANGED = 'goodsTypes.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'goodsTypes.FETCH_ONE'
export const FETCH_ONE_STARTED = 'goodsTypes.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'goodsTypes.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'goodsTypes.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'goodsTypes.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'goodsTypes.SAVE'
export const SAVE_STARTED = 'goodsTypes.SAVE_STARTED'
export const SAVE_FULFILLED = 'goodsTypes.SAVE_FULFILLED'
export const SAVE_REJECTED = 'goodsTypes.SAVE_REJECTED'
export const SAVE_CANCELLED = 'goodsTypes.SAVE_CANCELLED'

// Delete

export const DELETE = 'goodsTypes.DELETE'
export const DELETE_STARTED = 'goodsTypes.DELETE_STARTED'
export const DELETE_FULFILLED = 'goodsTypes.DELETE_FULFILLED'
export const DELETE_REJECTED = 'goodsTypes.DELETE_REJECTED'
export const DELETE_CANCELLED = 'goodsTypes.DELETE_CANCELLED'

// Export

export const EXPORT = 'goodsTypes.EXPORT'
export const EXPORT_FULFILLED = 'goodsTypes.EXPORT_FULFILLED'
