import * as actionTypes from './actionTypes'

// Fetch

export const fetchTransportScoring = () => ({
    type: actionTypes.FETCH,
})

export const fetchTransportScoringStarted = () => ({
    type: actionTypes.FETCH_STARTED,
})

export const fetchTransportScoringFulfilled = (items, rules) => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: {
        items,
        rules,
    },
})

export const fetchTransportScoringRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTransportScoringCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Save question

export const saveTransportScoringQuestion = values => ({
    type: actionTypes.SAVE_QUESTION,
    payload: values,
})

export const saveTransportScoringQuestionStarted = () => ({
    type: actionTypes.SAVE_QUESTION_STARTED,
})

export const saveTransportScoringQuestionFulfilled = (items, rules) => ({
    type: actionTypes.SAVE_QUESTION_FULFILLED,
    payload: {
        items,
        rules,
    },
})

export const saveTransportScoringQuestionRejected = message => ({
    type: actionTypes.SAVE_QUESTION_REJECTED,
    payload: message,
})

export const saveTransportScoringQuestionCancelled = () => ({
    type: actionTypes.SAVE_QUESTION_CANCELLED,
})

// Delete question

export const deleteTransportScoringQuestion = id => ({
    type: actionTypes.DELETE_QUESTION,
    payload: id,
})

export const deleteTransportScoringQuestionStarted = () => ({
    type: actionTypes.DELETE_QUESTION_STARTED,
})

export const deleteTransportScoringQuestionFulfilled = (items, rules) => ({
    type: actionTypes.DELETE_QUESTION_FULFILLED,
    payload: {
        items,
        rules,
    },
})

export const deleteTransportScoringQuestionRejected = message => ({
    type: actionTypes.DELETE_QUESTION_REJECTED,
    payload: message,
})

export const deleteTransportScoringQuestionCancelled = () => ({
    type: actionTypes.DELETE_QUESTION_CANCELLED,
})

// Move question UP

export const moveTransportScoringQuestionUp = id => ({
    type: actionTypes.MOVE_QUESTION_UP,
    payload: id,
})

export const moveTransportScoringQuestionUpStarted = () => ({
    type: actionTypes.MOVE_QUESTION_UP_STARTED,
})

export const moveTransportScoringQuestionUpFulfilled = (items, rules) => ({
    type: actionTypes.MOVE_QUESTION_UP_FULFILLED,
    payload: {
        items,
        rules,
    },
})

export const moveTransportScoringQuestionUpRejected = message => ({
    type: actionTypes.MOVE_QUESTION_UP_REJECTED,
    payload: message,
})

export const moveTransportScoringQuestionUpCancelled = () => ({
    type: actionTypes.MOVE_QUESTION_UP_CANCELLED,
})

// Move question DOWN

export const moveTransportScoringQuestionDown = id => ({
    type: actionTypes.MOVE_QUESTION_DOWN,
    payload: id,
})

export const moveTransportScoringQuestionDownStarted = () => ({
    type: actionTypes.MOVE_QUESTION_DOWN_STARTED,
})

export const moveTransportScoringQuestionDownFulfilled = (items, rules) => ({
    type: actionTypes.MOVE_QUESTION_DOWN_FULFILLED,
    payload: {
        items,
        rules,
    },
})

export const moveTransportScoringQuestionDownRejected = message => ({
    type: actionTypes.MOVE_QUESTION_DOWN_REJECTED,
    payload: message,
})

export const moveTransportScoringQuestionDownCancelled = () => ({
    type: actionTypes.MOVE_QUESTION_DOWN_CANCELLED,
})

// Save answer

export const saveTransportScoringAnswer = values => ({
    type: actionTypes.SAVE_ANSWER,
    payload: values,
})

export const saveTransportScoringAnswerStarted = () => ({
    type: actionTypes.SAVE_ANSWER_STARTED,
})

export const saveTransportScoringAnswerFulfilled = (items, rules) => ({
    type: actionTypes.SAVE_ANSWER_FULFILLED,
    payload: {
        items,
        rules,
    },
})

export const saveTransportScoringAnswerRejected = message => ({
    type: actionTypes.SAVE_ANSWER_REJECTED,
    payload: message,
})

export const saveTransportScoringAnswerCancelled = () => ({
    type: actionTypes.SAVE_ANSWER_CANCELLED,
})

// Delete answer

export const deleteTransportScoringAnswer = id => ({
    type: actionTypes.DELETE_ANSWER,
    payload: id,
})

export const deleteTransportScoringAnswerStarted = () => ({
    type: actionTypes.DELETE_ANSWER_STARTED,
})

export const deleteTransportScoringAnswerFulfilled = (items, rules) => ({
    type: actionTypes.DELETE_ANSWER_FULFILLED,
    payload: {
        items,
        rules,
    },
})

export const deleteTransportScoringAnswerRejected = message => ({
    type: actionTypes.DELETE_ANSWER_REJECTED,
    payload: message,
})

export const deleteTransportScoringAnswerCancelled = () => ({
    type: actionTypes.DELETE_ANSWER_CANCELLED,
})

// Move answer UP

export const moveTransportScoringAnswerUp = id => ({
    type: actionTypes.MOVE_ANSWER_UP,
    payload: id,
})

export const moveTransportScoringAnswerUpStarted = () => ({
    type: actionTypes.MOVE_ANSWER_UP_STARTED,
})

export const moveTransportScoringAnswerUpFulfilled = (items, rules) => ({
    type: actionTypes.MOVE_ANSWER_UP_FULFILLED,
    payload: {
        items,
        rules,
    },
})

export const moveTransportScoringAnswerUpRejected = message => ({
    type: actionTypes.MOVE_ANSWER_UP_REJECTED,
    payload: message,
})

export const moveTransportScoringAnswerUpCancelled = () => ({
    type: actionTypes.MOVE_ANSWER_UP_CANCELLED,
})

// Move answer DOWN

export const moveTransportScoringAnswerDown = id => ({
    type: actionTypes.MOVE_ANSWER_DOWN,
    payload: id,
})

export const moveTransportScoringAnswerDownStarted = () => ({
    type: actionTypes.MOVE_ANSWER_DOWN_STARTED,
})

export const moveTransportScoringAnswerDownFulfilled = (items, rules) => ({
    type: actionTypes.MOVE_ANSWER_DOWN_FULFILLED,
    payload: {
        items,
        rules,
    },
})

export const moveTransportScoringAnswerDownRejected = message => ({
    type: actionTypes.MOVE_ANSWER_DOWN_REJECTED,
    payload: message,
})

export const moveTransportScoringAnswerDownCancelled = () => ({
    type: actionTypes.MOVE_ANSWER_DOWN_CANCELLED,
})

// Save rule

export const saveTransportScoringRule = values => ({
    type: actionTypes.SAVE_RULE,
    payload: values,
})

export const saveTransportScoringRuleStarted = () => ({
    type: actionTypes.SAVE_RULE_STARTED,
})

export const saveTransportScoringRuleFulfilled = (items, rules) => ({
    type: actionTypes.SAVE_RULE_FULFILLED,
    payload: {
        items,
        rules,
    },
})

export const saveTransportScoringRuleRejected = message => ({
    type: actionTypes.SAVE_RULE_REJECTED,
    payload: message,
})

export const saveTransportScoringRuleCancelled = () => ({
    type: actionTypes.SAVE_RULE_CANCELLED,
})

// Delete rule

export const deleteTransportScoringRule = id => ({
    type: actionTypes.DELETE_RULE,
    payload: id,
})

export const deleteTransportScoringRuleStarted = () => ({
    type: actionTypes.DELETE_RULE_STARTED,
})

export const deleteTransportScoringRuleFulfilled = (items, rules) => ({
    type: actionTypes.DELETE_RULE_FULFILLED,
    payload: {
        items,
        rules,
    },
})

export const deleteTransportScoringRuleRejected = message => ({
    type: actionTypes.DELETE_RULE_REJECTED,
    payload: message,
})

export const deleteTransportScoringRuleCancelled = () => ({
    type: actionTypes.DELETE_RULE_CANCELLED,
})
