export const getData = state => state.reportDriverTimesheet.getIn(['list', 'data'])
export const getFetching = state => state.reportDriverTimesheet.getIn(['list', 'fetching'])
export const getError = state => state.reportDriverTimesheet.getIn(['list', 'error'])

export const getDriverNote = state => state.reportDriverTimesheet.get('driver_note')
export const getModalSaving = state => state.reportDriverTimesheet.getIn(['driver_note', 'saving'])

export const getDetailsData = state => state.reportDriverTimesheet.getIn(['details', 'data'])
export const getDetailsFetching = state => state.reportDriverTimesheet.getIn(['details', 'fetching'])
export const getDetailsError = state => state.reportDriverTimesheet.getIn(['details', 'error'])
