import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_FUELINGS } from '../permissions'

// eslint-disable-next-line react/prefer-stateless-function
class VehiclesFuelingsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick && this.props.onClick(this.props.item)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete && this.props.onDelete(this.props.item)
    }

    render() {
        const event = this.props.item

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS_FUELINGS.UPDATE, PERMS_FUELINGS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS_FUELINGS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_FUELINGS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-120">{event.datetime ? formatters.datetimeFormatter(event.datetime) : null}</td>
                <td className="w-140">{event.driver ? event.driver.name : null}</td>
                <td className="w-120">{event.country ? event.country.name : null}</td>
                <td className="w-80">{event.fuel_level}</td>
                <td className="w-80">{event.adblue_level}</td>
                <td className="w-max wm-200">{event.number}</td>
            </tr>
        )
    }
}

export default injectIntl(VehiclesFuelingsListRow)
