import { Record } from 'immutable'

export class CompanyCostCenter extends Record({
    id: undefined,
    name: undefined,
    code: undefined,
    company_id: undefined,
    transport_plan_count: undefined,
    transport_plan_sales: undefined,
    transport_plan_profit: undefined,
    spedition_plan_count: undefined,
    spedition_plan_sales: undefined,
    spedition_plan_profit: undefined,
    transport_minimum_count: undefined,
    transport_minimum_sales: undefined,
    transport_minimum_profit: undefined,
    spedition_minimum_count: undefined,
    spedition_minimum_sales: undefined,
    spedition_minimum_profit: undefined,
}) {}
