import * as actionTypes from './actionTypes'

// Fetch

export const fetchVehicleEvents = () => ({
    type: actionTypes.FETCH,
})

export const fetchVehicleEventsForMap = () => ({
    type: actionTypes.FETCH_FOR_MAP,
})

export const fetchVehicleEventsFulfilled = vehicleEvents => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: vehicleEvents,
})

export const fetchVehicleEventsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchVehicleEventsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchVehicleEvent = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchVehicleEventForMap = id => ({
    type: actionTypes.FETCH_ONE_FOR_MAP,
    payload: id,
})

export const fetchVehicleEventFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchVehicleEventRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchVehicleEventCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveVehicleEvent = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveVehicleEventFulfilled = vehicleEvent => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: vehicleEvent,
})

export const saveVehicleEventRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteVehicleEvent = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteVehicleEventFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteVehicleEventRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Clear

export const clearVehicleEvents = () => ({
    type: actionTypes.CLEAR,
})
