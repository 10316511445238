import * as actionTypes from './actionTypes'

// Fetch

export const fetchTrailerDocuments = () => ({
    type: actionTypes.FETCH,
})

export const fetchTrailerDocumentsForMap = () => ({
    type: actionTypes.FETCH_FOR_MAP,
})

export const fetchTrailerDocumentsFulfilled = trailerDocuments => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: trailerDocuments,
})

export const fetchTrailerDocumentsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTrailerDocumentsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchTrailerDocument = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchTrailerDocumentFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchTrailerDocumentRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchTrailerDocumentCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveTrailerDocument = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveTrailerDocumentFulfilled = trailerDocument => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: trailerDocument,
})

export const saveTrailerDocumentRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveTrailerDocumentDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteTrailerDocument = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteTrailerDocumentFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteTrailerDocumentRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Clear

export const clearTrailerDocuments = () => ({
    type: actionTypes.CLEAR,
})
// Fetch files

export const fetchTrailerDocumentFiles = id => ({
    type: actionTypes.FETCH_FILES,
    payload: id,
})

export const fetchTrailerDocumentFilesFulfilled = files => ({
    type: actionTypes.FETCH_FILES_FULFILLED,
    payload: files,
})

export const fetchTrailerDocumentFilesRejected = message => ({
    type: actionTypes.FETCH_FILES_REJECTED,
    payload: message,
})

export const fetchTrailerDocumentFilesCancelled = () => ({
    type: actionTypes.FETCH_FILES_CANCELLED,
})

// Upload files

export const uploadTrailerDocumentFiles = (id, files) => ({
    type: actionTypes.UPLOAD_FILES,
    payload: {
        id,
        files,
    },
})

export const uploadTrailerDocumentFilesFulfilled = files => ({
    type: actionTypes.UPLOAD_FILES_FULFILLED,
    payload: files,
})

export const uploadTrailerDocumentFilesRejected = message => ({
    type: actionTypes.UPLOAD_FILES_REJECTED,
    payload: message,
})

export const uploadTrailerDocumentFilesCancelled = () => ({
    type: actionTypes.UPLOAD_FILES_CANCELLED,
})

// Delete file

export const deleteTrailerDocumentFile = (id, file_id) => ({
    type: actionTypes.DELETE_FILE,
    payload: {
        id,
        file_id,
    },
})

export const deleteTrailerDocumentFileFulfilled = files => ({
    type: actionTypes.DELETE_FILE_FULFILLED,
    payload: files,
})

export const deleteTrailerDocumentFileRejected = message => ({
    type: actionTypes.DELETE_FILE_REJECTED,
    payload: message,
})

export const deleteTrailerDocumentFileCancelled = () => ({
    type: actionTypes.DELETE_FILE_CANCELLED,
})

// Export

export const exportTrailerDocuments = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
