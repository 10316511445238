// Fetch list

export const FETCH = 'user_logins.FETCH'
export const FETCH_STARTED = 'user_logins.FETCH_STARTED'
export const FETCH_FULFILLED = 'user_logins.FETCH_FULFILLED'
export const FETCH_REJECTED = 'user_logins.FETCH_REJECTED'
export const FETCH_CANCELLED = 'user_logins.FETCH_CANCELLED'

export const SORTING_CHANGED = 'user_logins.SORTING_CHANGED'

// Export

export const EXPORT = 'user_logins.EXPORT'
export const EXPORT_FULFILLED = 'user_logins.EXPORT_FULFILLED'
