import * as actionTypes from './actionTypes'

// Fetch

export const fetchCurrencies = () => ({
    type: actionTypes.FETCH,
})

export const fetchCurrenciesFulfilled = currencies => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: currencies,
})

export const fetchCurrenciesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCurrenciesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCurrency = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCurrencyFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCurrencyRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCurrencyCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCurrency = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveCurrencyFulfilled = currency => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: currency,
})

export const saveCurrencyRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteCurrency = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCurrencyFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCurrencyRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportCurrencies = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
