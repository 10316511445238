import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'
import { fromJS } from 'immutable'

import { request, parseAPIError } from '../../common/api'
import { vehicleFuelingSchema, vehicleFuelingProductSchema, importFileSchema } from '../../common/normalizrSchemas'
import { mapNestedRecordsToRecordMap, normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { VehicleFueling, VehicleFuelingPreview } from './model'
import { VehicleFuelingProduct } from '../vehicle_fueling_products/model'
import { Vehicle } from '../vehicles/model'
import { ImportFile } from '../import_file/model'
import * as vehiclesActionCreators from '../vehicles/actionCreators'
import * as trailersActionCreators from '../trailers/actionCreators'
import { getVehicles } from '../vehicles/selectors'
import { Driver } from '../drivers/model'
import * as driversActionCreators from '../drivers/actionCreators'
import { getDrivers } from '../drivers/selectors'
import { Country } from '../countries/model'
import * as countriesActionCreators from '../countries/actionCreators'
import { getCountries } from '../countries/selectors'
import {Trailer} from "../trailers/model";

// Fetch

const fetchVehicleFuelingsEpic = (action$, store) => {
    const tableIdentifier = 'vehicle_fuelings_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `vehicle-fuelings/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(vehicleFuelingSchema)
                        )

                        let vehicleFuelings = normalizedEntitiesToRecordMap(
                            normalizedData.entities.vehicle_fuelings,
                            VehicleFueling,
                            normalizedData.result
                        )
                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                        const trailers = normalizedEntitiesToRecordMap(normalizedData.entities.trailers, Trailer)
                        const drivers = normalizedEntitiesToRecordMap(normalizedData.entities.drivers, Driver)
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                        const importFiles = normalizedEntitiesToRecordMap(normalizedData.entities.import_files, ImportFile)

                        vehicleFuelings = mapNestedRecordsToRecordMap(vehicleFuelings, VehicleFueling)

                        const observables = [
                            Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                            Observable.of(trailersActionCreators.fetchTrailersFulfilled(trailers)),
                            Observable.of(driversActionCreators.fetchDriversFulfilled(drivers)),
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(actionCreators.fetchVehicleFuelingsFulfilled(vehicleFuelings)),
                            Observable.of(actionCreators.fetchImportFilesFulfilled(importFiles)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchVehicleFuelingsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchVehicleFuelingEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().vehicleFuelings.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `vehicle-fuelings/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, vehicleFuelingSchema)

                        const vehicleFuelings = normalizedEntitiesToRecordMap(normalizedData.entities.vehicle_fuelings, VehicleFueling)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchVehicleFuelingsFulfilled(vehicleFuelings)),
                            Observable.of(actionCreators.fetchVehicleFuelingFulfilled())
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchVehicleFuelingsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveVehicleFuelingEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().vehicleFuelings.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = Object.assign({}, action.payload)

            let path = `vehicle-fuelings`
            let method = 'POST'

            if (values.id) {
                path = `vehicle-fuelings/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, vehicleFuelingSchema)

                        const vehicleFuelings = normalizedEntitiesToRecordMap(normalizedData.entities.vehicle_fuelings, VehicleFueling)
                        const vehicleFueling = vehicleFuelings.valueSeq().first()

                        return Observable.of(actionCreators.saveVehicleFuelingFulfilled(vehicleFueling))
                    })
                    .catch(error => Observable.of(actionCreators.saveVehicleFuelingRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteVehicleFuelingEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().vehicleFuelings.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `vehicle-fuelings/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteVehicleFuelingFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteVehicleFuelingRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Fetch import headers

const fetchImportHeadersEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_IMPORT_HEADERS)
        .filter(() => !store.getState().vehicleFuelings.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORT_HEADERS_STARTED,
                }),
                request({
                    path: `vehicle-fuelings/get-import-headers`,
                    method: 'POST',
                    body: {
                        file: action.payload,
                    },
                })
                    .switchMap(ajaxResponse => {
                        const importHeaders = {
                            file_id: ajaxResponse.response.file_id,
                            headers: fromJS(ajaxResponse.response.headers),
                            headers_default: fromJS(ajaxResponse.response.headers_default).map(value => value.toSet()),
                        }
                        return Observable.of(actionCreators.fetchVehicleFuelingsImportHeadersFulfilled(importHeaders))
                    })
                    .catch(error => Observable.of(actionCreators.fetchVehicleFuelingsImportHeadersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_HEADERS_CANCELLED))
            )
        )

// Fetch values

const fetchImportValuesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_IMPORT_VALUES)
        .filter(() => !store.getState().vehicleFuelings.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORT_VALUES_STARTED,
                }),
                request({
                    path: `vehicle-fuelings/get-import-values`,
                    method: 'POST',
                    body: action.payload,
                })
                    .switchMap(ajaxResponse => {
                        const importValues = {
                            values: fromJS(ajaxResponse.response.values),
                            values_default: fromJS(ajaxResponse.response.values_default).map(value => value.toSet()),
                            show_fueling_companies: ajaxResponse.response.show_fueling_companies,
                            fueling_companies: fromJS(ajaxResponse.response.fueling_companies),
                            selected_fueling_company: ajaxResponse.response.selected_fueling_company,
                            formats: fromJS(ajaxResponse.response.formats),
                            examples: fromJS(ajaxResponse.response.examples),
                        }
                        return Observable.of(actionCreators.fetchVehicleFuelingsImportValuesFulfilled(importValues))
                    })
                    .catch(error => Observable.of(actionCreators.fetchVehicleFuelingsImportValuesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_VALUES_CANCELLED))
            )
        )

// Import preview

const fetchImportPreviewEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_IMPORT_PREVIEW)
        .filter(() => !store.getState().vehicleFuelings.getIn(['preview', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORT_PREVIEW_STARTED,
                }),
                request({
                    path: `vehicle-fuelings/get-import-preview`,
                    method: 'POST',
                    body: {
                        ...action.payload,
                    },
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response.data, new schema.Array(vehicleFuelingSchema))
                        let vehicleFuelingPreview = normalizedEntitiesToRecordMap(
                            normalizedData.entities.vehicle_fuelings,
                            VehicleFuelingPreview,
                            normalizedData.result
                        )
                        let vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                        let drivers = normalizedEntitiesToRecordMap(normalizedData.entities.drivers, Driver)
                        let countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)
                        const state = store.getState()
                        vehicles = getVehicles(state).merge(vehicles)
                        drivers = getDrivers(state).merge(drivers)
                        countries = getCountries(state).merge(countries)
                        vehicleFuelingPreview = mapNestedRecordsToRecordMap(vehicleFuelingPreview, VehicleFuelingPreview)
                        const description = ajaxResponse.response.description

                        const observables = [
                            Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                            Observable.of(driversActionCreators.fetchDriversFulfilled(drivers)),
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(actionCreators.fetchVehicleFuelingsImportPreviewFulfilled(vehicleFuelingPreview, description))
                        ]
                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchVehicleFuelingsImportPreviewRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_PREVIEW_CANCELLED))
            )
        )

// Import

const importEpic = (action$, store) =>
    action$
        .ofType(actionTypes.IMPORT)
        .filter(() => !store.getState().vehicleFuelings.getIn(['import', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.IMPORT_STARTED,
                }),
                request({
                    path: `vehicle-fuelings/import`,
                    method: 'POST',
                    body: {
                        ...action.payload,
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.importVehicleFuelingsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.importVehicleFuelingsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.IMPORT_CANCELLED))
            )
        )

// Featch products headers

const fetchImportProductsHeadersEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_IMPORT_PRODUCTS_HEADERS)
        .filter(() => !store.getState().vehicleFuelings.getIn(['importProducts', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORT_PRODUCTS_HEADERS_STARTED,
                }),
                request({
                    path: `vehicle-fueling-products/get-import-headers`,
                    method: 'POST',
                    body: {
                        file: action.payload,
                    },
                })
                    .switchMap(ajaxResponse => {
                        const productsHeaders = {
                            headers: fromJS(ajaxResponse.response.headers),
                            file_id: ajaxResponse.response.file_id,
                        }
                        return Observable.of(actionCreators.fetchProductsImportHeadersFulfilled(productsHeaders))
                    })
                    .catch(error => Observable.of(actionCreators.fetchProductsImportHeadersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_PRODUCTS_HEADERS_CANCELLED))
            )
        )

// Fetch products preview

const fetchImportProductsPreviewEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_IMPORT_PRODUCTS_PREVIEW)
        .filter(() => !store.getState().vehicleFuelings.getIn(['preview', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORT_PRODUCTS_PREVIEW_STARTED,
                }),
                request({
                    path: `vehicle-fueling-products/get-import-preview`,
                    method: 'POST',
                    body: {
                        ...action.payload,
                    },
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, new schema.Array(vehicleFuelingProductSchema))

                        let vehicleFuelingProducts = normalizedEntitiesToRecordMap(
                            normalizedData.entities.vehicle_fueling_products,
                            VehicleFuelingProduct,
                            normalizedData.result
                        )

                        vehicleFuelingProducts = mapNestedRecordsToRecordMap(vehicleFuelingProducts, VehicleFuelingProduct)

                        return Observable.of(actionCreators.fetchProductsImportPreviewFulfilled(vehicleFuelingProducts))
                    })
                    .catch(error => Observable.of(actionCreators.fetchProductsImportPreviewRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_IMPORT_PRODUCTS_PREVIEW_CANCELLED))
            )
        )

// Import products

const importProductsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.IMPORT_PRODUCTS)
        .filter(() => !store.getState().vehicleFuelings.getIn(['importProducts', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.IMPORT_PRODUCTS_STARTED,
                }),
                request({
                    path: `vehicle-fueling-products/import`,
                    method: 'POST',
                    body: {
                        ...action.payload,
                    },
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response.products, new schema.Array(vehicleFuelingProductSchema))

                        let vehicleFuelingProducts = normalizedEntitiesToRecordMap(
                            normalizedData.entities.vehicle_fueling_products,
                            VehicleFuelingProduct,
                            normalizedData.result
                        )

                        vehicleFuelingProducts = mapNestedRecordsToRecordMap(vehicleFuelingProducts, VehicleFuelingProduct)
                        const company = fromJS(ajaxResponse.response.company)
                        const observables = [
                            Observable.of(actionCreators.importProductsFulfilled(company)),
                            Observable.of(actionCreators.fetchProductFulfilled(vehicleFuelingProducts)),
                        ]

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.importProductsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.IMPORT_PRODUCTS_CANCELLED))
            )
        )

// Fetch product list

const fetchProductsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_PRODUCTS)
        .filter(() => !store.getState().vehicleFuelings.getIn(['productList', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_PRODUCTS_STARTED,
                }),
                request({
                    path: `vehicle-fueling-company/${action.payload.vehicle_fueling_company_id}/products`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, new schema.Array(vehicleFuelingProductSchema))

                        let vehicleFuelingProducts = normalizedEntitiesToRecordMap(
                            normalizedData.entities.vehicle_fueling_products,
                            VehicleFuelingProduct,
                            normalizedData.result
                        )

                        vehicleFuelingProducts = mapNestedRecordsToRecordMap(vehicleFuelingProducts, VehicleFuelingProduct)

                        return Observable.of(actionCreators.fetchProductFulfilled(vehicleFuelingProducts))
                    })
                    .catch(error => Observable.of(actionCreators.fetchProductRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_PRODUCTS_CANCELLED))
            )
        )

// Fetch imports list

const fetchImportsEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_IMPORTS)
        .filter(() => !store.getState().vehicleFuelings.getIn(['productList', 'inProgress']))
        .switchMap(() =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_IMPORTS_STARTED,
                }),
                request({
                    path: `vehicle-fuelings/imports`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, new schema.Array(importFileSchema))
                        const importFiles = normalizedEntitiesToRecordMap(normalizedData.entities.import_files, ImportFile, normalizedData.result)

                        return Observable.of(actionCreators.fetchImportsFulfilled(importFiles))
                    })
                    .catch(error => Observable.of(actionCreators.fetchImportsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_IMPORTS_CANCELLED))
            )
        )

// Delete history

const deleteImportEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE_IMPORT)
        .filter(() => !store.getState().vehicleFuelings.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_IMPORT_STARTED,
                }),
                request({
                    path: `vehicle-fuelings/imports/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteImportFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteImportRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_IMPORT_CANCELLED))
            )
        )

// Fetch country

const fetchCountryEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_COUNTRY)
        .filter(() => !store.getState().vehicleFuelings.getIn(['country', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_COUNTRY_STARTED,
                }),
                request({
                    path: `vehicle-fuelings/get-country`,
                    method: 'POST',
                    body: {
                        ...action.payload,
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchCountryFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchCountryRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_COUNTRY_CANCELLED))
            )
        )

// Fetch driver

const fetchDriverEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_DRIVER)
        .filter(() => !store.getState().vehicleFuelings.getIn(['driver', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_DRIVER_STARTED,
                }),
                request({
                    path: `vehicle-fuelings/get-driver`,
                    method: 'POST',
                    body: {
                        ...action.payload,
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchDriverFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchDriverRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_DRIVER_CANCELLED))
            )
        )

export default [
    fetchVehicleFuelingsEpic,
    fetchVehicleFuelingEpic,
    saveVehicleFuelingEpic,
    deleteVehicleFuelingEpic,
    fetchImportHeadersEpic,
    fetchImportValuesEpic,
    fetchImportPreviewEpic,
    importEpic,
    fetchImportProductsHeadersEpic,
    fetchImportProductsPreviewEpic,
    importProductsEpic,
    fetchProductsEpic,
    fetchImportsEpic,
    deleteImportEpic,
    fetchCountryEpic,
    fetchDriverEpic,
]
