import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { Modal } from 'react-bootstrap'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import { getListItemsCountMessage } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as companiesActionCreators from '../actionCreators'
import { getCompanyCostCentersData, getCompanyCostCentersFetching } from '../selectors'

import { deleteCompanyCostCenter } from '../../company_cost_centers/actionCreators'
import { getCompanyCostCenterDeleting } from '../../company_cost_centers/selectors'

import CompaniesCostCentersListRow from './CompaniesCostCentersListRow'
import CompanyCostCentersEditForm from '../../company_cost_centers/components/CompanyCostCentersEditForm'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_COST_CENTERS } from '../permissions'

class CompaniesCostCentersList extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        modalCostCenter: {
            show: false,
            item: null,
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // handles
    onClick = item => {
        this.setState({
            modalCostCenter: {
                show: true,
                item,
            },
        })
    }

    onDelete = item => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteCompanyCostCenter(item.id)
                this.hideConfirmDialog()
            },
        })
    }

    onPageChange = page => {
        this.props.actions.fetchCompanyCostCenters(this.props.company.id, page.selected)
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportCompanyCostCenters(this.props.company.id)
    }

    handleCreateItem = e => {
        e && e.preventDefault()
        this.setState({
            modalCostCenter: {
                show: true,
                item: null,
            },
        })
    }

    // create modal
    modalCostCenterClose = () => {
        this.setState({
            modalCostCenter: {
                show: false,
                item: null,
            },
        })
    }

    // callbacks
    editSuccessCallback = () => {
        this.modalCostCenterClose()
        this.refresh()
    }

    editBackCallback = () => {
        this.modalCostCenterClose()
    }

    refresh = () => {
        this.props.actions.fetchCompanyCostCenters(this.props.company.id)
    }

    componentDidMount() {
        if (this.props.company) {
            this.props.actions.fetchCompanyCostCenters(this.props.company.id)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.company && JSON.stringify(nextProps.company) !== JSON.stringify(this.props.company)) {
            this.props.actions.fetchCompanyCostCenters(nextProps.company.id)
        }

        if (this.props.deleting && !nextProps.deleting) {
            this.refresh()
        }
    }

    render() {
        const data = this.props.data

        const rows = data && data.data
        const page = data && data.page ? data.page : 0
        const total = data && data.total ? data.total : 0

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_COST_CENTERS)} noPermissionsTab>
                <LoadingOverlay active={this.props.fetching || this.props.deleting}>
                    <div className="btns-list">
                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                        </button>
                        {rows && rows.length > 0 && (
                            <PermissionsCheck has={[PERMS_COST_CENTERS.EXPORT]}>
                                <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExport}>
                                    <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                </button>
                            </PermissionsCheck>
                        )}
                        <PermissionsCheck has={[PERMS_COST_CENTERS.CREATE]}>
                            <button className="btn btn-addon btn-success" onClick={this.handleCreateItem}>
                                <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                            </button>
                        </PermissionsCheck>
                    </div>

                    {rows && rows.length > 0 ? (
                        <React.Fragment>
                            <div className="table-container">
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                        <tr>
                                            <PermissionsCheck hasAny={[PERMS_COST_CENTERS.UPDATE, PERMS_COST_CENTERS.DELETE]}>
                                                <th className="w-60" />
                                            </PermissionsCheck>
                                            <th className="w-100">
                                                <FormattedMessage id="fields.code" />
                                            </th>
                                            <th className="w-max wm-200">
                                                <FormattedMessage id="fields.name" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows &&
                                            rows.map((item, index) => (
                                                <CompaniesCostCentersListRow
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={`row-${index}`}
                                                    item={item}
                                                    onClick={this.onClick}
                                                    onDelete={this.onDelete}
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="pull-left m-l-xxs m-t-md">
                                <FormattedMessage id="pagination.totalRecords" />: {getListItemsCountMessage(0, 0, total)}
                            </div>

                            <ReactPaginate
                                containerClassName="pagination"
                                breakLabel={<span className="disabled">...</span>}
                                activeClassName="active"
                                pageCount={Math.ceil(total / 50)}
                                pageRangeDisplayed={10}
                                marginPagesDisplayed={2}
                                forcePage={page}
                                onPageChange={this.onPageChange}
                                previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                            />
                        </React.Fragment>
                    ) : (
                        <div className="alert alert-danger pull-left wp-100">
                            <FormattedMessage id="fields.noItemsFound" />
                        </div>
                    )}
                </LoadingOverlay>

                <Modal show={Boolean(this.state.modalCostCenter.show)} onHide={this.modalCostCenterClose} className="modal-size-md">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.modalCostCenter.item ? (
                                <FormattedMessage id="fields.itemEdit" />
                            ) : (
                                <FormattedMessage id="fields.itemCreate" />
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CompanyCostCentersEditForm
                            companyCostCenterID={this.state.modalCostCenter.item && this.state.modalCostCenter.item.id}
                            successCallback={this.editSuccessCallback}
                            backCallback={this.editBackCallback}
                            defaultValues={{
                                company_id: this.props.company.id,
                            }}
                            hiddenFields={['company_id']}
                            PERMS={PERMS_COST_CENTERS}
                        />
                    </Modal.Body>
                </Modal>

                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: getCompanyCostCentersData(state),
        fetching: getCompanyCostCentersFetching(state),
        deleting: getCompanyCostCenterDeleting(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...companiesActionCreators,
                    deleteCompanyCostCenter,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(CompaniesCostCentersList)
)
