// Fetch list

export const FETCH = 'reportCarrierUsage.FETCH'
export const FETCH_STARTED = 'reportCarrierUsage.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportCarrierUsage.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportCarrierUsage.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportCarrierUsage.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportCarrierUsage.CLEAR'

// Export

export const EXPORT = 'reportCarrierUsage.EXPORT'
export const EXPORT_FULFILLED = 'reportCarrierUsage.EXPORT_FULFILLED'
