import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

import { resolveModelState } from '../../common/table/helpers'
import * as tableModelActionTypes from '../../common/table/actionTypes'

// Fetch

const fetchReportCustomerSpeditionsEpic = (action$, store) => {
    const tableIdentifier = 'report_customer_speditions'

    return action$
        .ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request({
                    method: 'POST',
                    path: `report-customer-speditions`,
                    body: {
                        filters: modelState.get('filters').toJS(),
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportCustomerSpeditionsFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchReportCustomerSpeditionsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
            )
        })
}

// Export

const exportReportCustomerSpeditionsEpic = (action$, store) => {
    const tableIdentifier = 'report_customer_speditions'

    return action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const modelState = resolveModelState(tableIdentifier, store.getState(), action)

        const filters = JSON.stringify(modelState.get('filters').toJS())
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-customer-speditions/export?filters=${filters}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })
}

// Fetch details

const fetchReportCustomerSpeditionsDetailsEpic = action$ =>
    action$.ofType(actionTypes.FETCH_DETAILS).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DETAILS_STARTED,
            }),
            request({
                method: 'POST',
                path: `report-customer-speditions/details`,
                body: {
                    filters: action.payload,
                },
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportCustomerSpeditionsDetailsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportCustomerSpeditionsDetailsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_DETAILS_CANCELLED))
        )
    )

// Export details

const exportReportCustomerSpeditionsDetailsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_DETAILS).switchMap(action => {
        const filters = JSON.stringify(action.payload)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-customer-speditions/details/export?filters=${filters}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_DETAILS_FULFILLED,
            })
        )
    })

export default [
    fetchReportCustomerSpeditionsEpic,
    exportReportCustomerSpeditionsEpic,
    fetchReportCustomerSpeditionsDetailsEpic,
    exportReportCustomerSpeditionsDetailsEpic,
]
