import { Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    user: Map({
        saving: false,
        fetching: false,
        error: null,
        profile: OrderedMap({}),
    }),
})

function user(state = initialState, action) {
    switch (action.type) {
        // One
        case actionTypes.FETCH:
            return state.setIn(['user', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['user', 'fetching'], false).setIn(['user', 'profile'], action.payload)
            })
        case actionTypes.FETCH_REJECTED:
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['user', 'fetching'], false)

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map.setIn(['user', 'saving'], true).setIn(['user', 'error'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['user', 'saving'], false).setIn(['user', 'error'], null)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['user', 'saving'], false).setIn(['user', 'error'], action.payload)
            })

        default:
            return state
    }
}

export default user
