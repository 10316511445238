import React, { Component } from 'react'

import * as formatters from '../../formatters'

class CustomerPickerListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    render = () => (
        <tr onClick={this.handleClick} className="row-selectable">
            <td className="w-140">
                <strong>{this.props.data.vat_number}</strong>
            </td>
            <td className="w-100">{this.props.data.reg_number}</td>
            <td className="w-400">
                <strong>{this.props.data.name}</strong>
            </td>
            <td className="w-80 text-center">{this.props.country && this.props.country.iso_code}</td>
            <td className="w-max wm-300">{this.props.address && this.props.address.getAddress()}</td>
            <td className="w-200">{this.props.data.getBankAccount()}</td>
            <td className="w-160">{this.props.businessManager && this.props.businessManager.getShortFullName()}</td>
            <td className="w-160">{this.props.dispatcher && this.props.dispatcher.getShortFullName()}</td>
            <td className="w-80 text-center">{formatters.boolFormatter(this.props.data.is_blocked, 'text-danger')}</td>
        </tr>
    )
}

export default CustomerPickerListRow
