import React from 'react'
import { Route, Switch } from 'react-router'
import CompanyCostCentersList from './CompanyCostCentersList'
import CompanyCostCentersEdit from './CompanyCostCentersEdit'

const CompanyCostCenters = props => (
    <Switch>
        <Route exact path={props.match.url} component={CompanyCostCentersList} />
        <Route exact path={`${props.match.url}/new`} component={CompanyCostCentersEdit} />
        <Route exact path={`${props.match.url}/:companyCostCenterID`} component={CompanyCostCentersEdit} />
    </Switch>
)

export default CompanyCostCenters
