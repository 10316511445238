// Fetch list

export const FETCH = 'reportTransports.FETCH'
export const FETCH_STARTED = 'reportTransports.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportTransports.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportTransports.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportTransports.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportTransports.CLEAR'

// Export

export const EXPORT = 'reportTransports.EXPORT'
export const EXPORT_FULFILLED = 'reportTransports.EXPORT_FULFILLED'

// Export details

export const EXPORT_DETAILS = 'reportTransports.EXPORT_DETAILS'
export const EXPORT_DETAILS_FULFILLED = 'reportTransports.EXPORT_DETAILS_FULFILLED'
