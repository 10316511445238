import React from 'react'
import { useDrag } from 'react-dnd'

export const Variable = ({ variableKey, variable, onDrag }) => {
    const [{ isDragging }, drag] = useDrag({
        item: { variableKey, type: 'Variable' },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                onDrag(dropResult.constantKey, item.variableKey)
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0.4 : 1
    return (
        <div ref={drag} className="variable" style={{ opacity }}>
            {variable.get('label')}
        </div>
    )
}
