import React from 'react'
import { Route, Switch } from 'react-router'
import UnitsList from './UnitsList'
import UnitsEdit from './UnitsEdit'

const Units = props => (
    <Switch>
        <Route exact path={props.match.url} component={UnitsList} />
        <Route exact path={`${props.match.url}/new`} component={UnitsEdit} />
        <Route exact path={`${props.match.url}/:unitID`} component={UnitsEdit} />
    </Switch>
)

export default Units
