// allowance calculation methods
export const PER_TIME = 0
export const PER_FLAT = 1
export const PER_DAY = 2
export const PER_DAY_WEEKEND = 2
export const PER_MONTH = 4
export const PER_KILOMETER = 5

// allowance types
export const ALLOWANCE = 1
export const POCKET_MONEY = 2
export const ADDTIONAL = 3
