// Fetch list

export const FETCH = 'documentTypes.FETCH'
export const FETCH_STARTED = 'documentTypes.FETCH_STARTED'
export const FETCH_FULFILLED = 'documentTypes.FETCH_FULFILLED'
export const FETCH_REJECTED = 'documentTypes.FETCH_REJECTED'
export const FETCH_CANCELLED = 'documentTypes.FETCH_CANCELLED'

export const SORTING_CHANGED = 'documentTypes.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'documentTypes.FETCH_ONE'
export const FETCH_ONE_STARTED = 'documentTypes.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'documentTypes.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'documentTypes.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'documentTypes.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'documentTypes.SAVE'
export const SAVE_STARTED = 'documentTypes.SAVE_STARTED'
export const SAVE_FULFILLED = 'documentTypes.SAVE_FULFILLED'
export const SAVE_REJECTED = 'documentTypes.SAVE_REJECTED'
export const SAVE_CANCELLED = 'documentTypes.SAVE_CANCELLED'

// Delete

export const DELETE = 'documentTypes.DELETE'
export const DELETE_STARTED = 'documentTypes.DELETE_STARTED'
export const DELETE_FULFILLED = 'documentTypes.DELETE_FULFILLED'
export const DELETE_REJECTED = 'documentTypes.DELETE_REJECTED'
export const DELETE_CANCELLED = 'documentTypes.DELETE_CANCELLED'

// Export

export const EXPORT = 'documentTypes.EXPORT'
export const EXPORT_FULFILLED = 'documentTypes.EXPORT_FULFILLED'
