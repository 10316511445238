import { Map } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    fetching: false,
    error: null,
    data: null,

    customer_address_types: null,

    preferences: Map({
        saving: false,
        error: null,
        objects: {},
    }),
})

function hereMap(state = initialState, action) {
    switch (action.type) {
        // POIs
        case actionTypes.FETCH_STARTED:
            return state.set('fetching', true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.set('fetching', false)
                    .set('error', null)
                    .set('data', action.payload.here_map_data)
                    .set('customer_address_types', action.payload.customer_address_types)
                    .setIn(['preferences', 'objects'], action.payload.here_map_preferences)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn('fetching', false).setIn('error', action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn('fetching', false)

        // Preferences
        case actionTypes.PREFERENCES_UPDATE_STARTED:
            return state.setIn(['preferences', 'saving'], true)
        case actionTypes.PREFERENCES_UPDATE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['preferences', 'saving'], false)
                    .setIn(['preferences', 'error'], null)
                    .setIn(['preferences', 'objects'], action.payload.here_map_preferences)
                    .set('customer_address_types', action.payload.customer_address_types)
                    .set('data', action.payload.here_map_data)
            })
        case actionTypes.PREFERENCES_UPDATE_REJECTED:
            return state.setIn(['preferences', 'saving'], false).setIn(['preferences', 'error'], action.payload)
        case actionTypes.PREFERENCES_UPDATE_CANCELLED:
            return state.setIn(['preferences', 'saving'], false)

        default:
            return state
    }
}

export default hereMap
