import { Observable } from 'rxjs'
import { request, parseAPIError } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchVehicleMessageVehiclesEpic = action$ =>
    action$.ofType(actionTypes.FETCH_START_AUTO_FETCHING, actionTypes.FETCH, actionTypes.SHOW_SIDEBAR, actionTypes.DESELECT_VEHICLE).switchMap(() => {
        const requestParams = {
            method: 'GET',
            path: `vehicle-messages/list`,
        }

        return Observable.interval(120000)
            .startWith(0)
            .mergeMap(() =>
                Observable.concat(
                    Observable.of({
                        type: actionTypes.FETCH_STARTED,
                    }),
                    request(requestParams)
                        .switchMap(ajaxResponse => Observable.of(actionCreators.fetchMessageVehiclesFulfilled(ajaxResponse.response)))
                        .catch(error => Observable.of(actionCreators.fetchMessageVehiclesRejected(parseAPIError(error))))
                )
            )
            .takeUntil(action$.ofType(actionTypes.FETCH_STOP_AUTO_FETCHING, actionTypes.SELECT_VEHICLE))
    })

// Fetch messaged

const fetchVehicleMessageEpic = (action$, store) =>
    action$.ofType(actionTypes.FETCH_MESSAGES_START_AUTO_FETCHING, actionTypes.FETCH_MESSAGES).switchMap(() => {
        const selectedVehicle = store.getState().vehicleMessages.get('selectedVehicle')
        const requestParams = {
            method: 'GET',
            path: `vehicle-messages/messages/${selectedVehicle.id}`,
        }

        return Observable.interval(60000)
            .startWith(0)
            .mergeMap(() =>
                Observable.concat(
                    Observable.of({
                        type: actionTypes.FETCH_MESSAGES_STARTED,
                    }),
                    request(requestParams)
                        .switchMap(ajaxResponse => {
                            const observables = [
                                Observable.of(actionCreators.fetchMessagesFulfilled(ajaxResponse.response.messages)),
                                Observable.of(actionCreators.fetchMessageVehiclesFulfilled(ajaxResponse.response.vehicleMessages)),
                            ]

                            return Observable.concat(...observables)
                        })
                        .catch(error => Observable.of(actionCreators.fetchMessagesRejected(parseAPIError(error))))
                )
            )
            .takeUntil(action$.ofType(actionTypes.FETCH_MESSAGES_STOP_AUTO_FETCHING, actionTypes.DESELECT_VEHICLE, actionTypes.HIDE_SIDEBAR))
    })

// Send

const sendVehicleMessageEpic = action$ =>
    action$.ofType(actionTypes.SEND_MESSAGE).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.SEND_MESSAGE_STARTED,
            }),
            request({
                path: `vehicle-messages/send`,
                method: 'POST',
                body: {
                    vehicle_id: action.payload.vehiclesId,
                    text: action.payload.text,
                },
            })
                .switchMap(ajaxResponse => {
                    const observables = [
                        Observable.of(actionCreators.fetchMessagesFulfilled(ajaxResponse.response.messages)),
                        Observable.of(actionCreators.fetchMessageVehiclesFulfilled(ajaxResponse.response.vehicleMessages)),
                    ]

                    return Observable.concat(...observables)
                })
                .catch(error => Observable.of(actionCreators.sendMessageRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.SEND_MESSAGE_CANCELLED))
        )
    )

// Mark as read

const markVehicleMessageAsReadEpic = action$ =>
    action$.ofType(actionTypes.MARK_AS_READ).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.MARK_AS_READ_STARTED,
            }),
            request({
                path: `vehicle-messages/mark-as-read`,
                method: 'POST',
                body: {
                    vehicle_id: action.payload,
                },
            })
                .switchMap(ajaxResponse => {
                    const observables = [
                        Observable.of(actionCreators.markMessageAsReadFulfilled()),
                        Observable.of(actionCreators.fetchMessageVehiclesFulfilled(ajaxResponse.response)),
                    ]

                    return Observable.concat(...observables)
                })
                .catch(error => Observable.of(actionCreators.markMessageAsReadRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.MARK_AS_READ_CANCELLED))
        )
    )

// Subscribe

const subscribeVehicleMessageEpic = action$ =>
    action$.ofType(actionTypes.SUBSCRIBE).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.SUBSCRIBE_STARTED,
            }),
            request({
                path: `vehicle-messages/subscribe`,
                method: 'POST',
                body: {
                    vehicle_id: action.payload,
                },
            })
                .switchMap(ajaxResponse => {
                    const observables = [
                        Observable.of(actionCreators.subscribeFulfilled()),
                        Observable.of(actionCreators.fetchMessageVehiclesFulfilled(ajaxResponse.response)),
                    ]

                    return Observable.concat(...observables)
                })
                .catch(error => Observable.of(actionCreators.subscribeRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.SUBSCRIBE_CANCELLED))
        )
    )

// Unsubscribe

const unsubscribeVehicleMessageEpic = action$ =>
    action$.ofType(actionTypes.UNSUBSCRIBE).switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.UNSUBSCRIBE_STARTED,
            }),
            request({
                path: `vehicle-messages/unsubscribe`,
                method: 'POST',
                body: {
                    vehicle_id: action.payload,
                },
            })
                .switchMap(ajaxResponse => {
                    const observables = [
                        Observable.of(actionCreators.unsubscribeFulfilled()),
                        Observable.of(actionCreators.fetchMessageVehiclesFulfilled(ajaxResponse.response)),
                    ]

                    return Observable.concat(...observables)
                })
                .catch(error => Observable.of(actionCreators.unsubscribeRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.UNSUBSCRIBE_CANCELLED))
        )
    )

export default [
    fetchVehicleMessageVehiclesEpic,
    fetchVehicleMessageEpic,
    sendVehicleMessageEpic,
    markVehicleMessageAsReadEpic,
    subscribeVehicleMessageEpic,
    unsubscribeVehicleMessageEpic,
]
