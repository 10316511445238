import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { Modal } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { url, handleCommonListActions, getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { TableFilterInputField, TableFilterSelectField, TableSortButtons, TableFilterCheckboxField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as costsActionCreators from '../actionCreators'
import { getCosts, getCostDeleting, getCostDeleteError } from '../selectors'

import { createGetCostTypeByIDSelector } from '../../cost_types/selectors'
import { createGetCurrencyByIDSelector } from '../../currencies/selectors'
import { createGetCompanyCostCenterByIDSelector } from '../../company_cost_centers/selectors'
import { createGetDriverByIDSelector } from '../../drivers/selectors'
import { createGetUserByIDSelector } from '../../users/selectors'
import { createGetVehicleByIDSelector } from '../../vehicles/selectors'
import { createGetTrailerByIDSelector } from '../../trailers/selectors'

import CostsListRow from './CostsListRow'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'costs_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'costs_list'
const prerequisites = ['costTypes', 'currencies', 'companyCostCenters', 'vehicles', 'users', 'drivers', 'trailers']

const columns = {
    vehicle: new TableModelColumn({}),
    date_start: new TableModelColumn({}),
    date_end: new TableModelColumn({}),
}

class CostsList extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
        removeDialog: {
            show: false,
            onRemoveThisPayment: () => {},
            onRemoveThisAndFuturePayments: () => {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // remove dialog
    showRemoveDialog = options => {
        this.setState({
            removeDialog: {
                show: true,
                onRemoveThisPayment: options.onRemoveThisPayment || this.hideConfirmDialog,
                onRemoveThisAndFuturePayments: options.onRemoveThisAndFuturePayments || this.hideConfirmDialog,
            },
        })
    }

    hideRemoveDialog = () => {
        this.setState({
            removeDialog: {
                show: false,
                onRemoveThisPayment: () => {},
                onRemoveThisAndFuturePayments: () => {},
            },
        })
    }

    // handlers
    onClick = itemId => {
        this.props.redirect(`/costs/${itemId}`)
    }

    onDelete = (itemId, isCostGenerator) => {
        if (isCostGenerator) {
            this.showRemoveDialog({
                onRemoveThisPayment: () => {
                    this.props.actions.deleteCost(itemId, false)
                    this.hideRemoveDialog()
                },
                onRemoveThisAndFuturePayments: () => {
                    this.props.actions.deleteCost(itemId, true)
                    this.hideRemoveDialog()
                },
            })
        } else {
            this.showConfirmDialog({
                title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
                message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
                labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
                labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
                classNameAccept: 'btn-danger',
                onAccept: () => {
                    this.props.actions.deleteCost(itemId, false)
                    this.hideConfirmDialog()
                },
            })
        }
    }

    refresh = () => {
        this.props.actions.fetchCosts()
    }

    resetFilters = () => {
        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'with_future_payments',
                    type: TableModelColumnFilteringType.equal,
                    value: false,
                },
            ]
        )
    }

    handleChangeThreeWaySwitchFilter = (e, identifier) => {
        const filters = this.props.tableModel.get('filters')
        const currentValue = filters.getIn([identifier, 'value']) || 0
        const switchOptions = {
            0: 1,
            1: 2,
            2: 0,
        }

        this.props.table.changeFilter(identifier, TableModelColumnFilteringType.equal, switchOptions[currentValue])
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel, ['with_future_payments']).size > 0

    componentDidMount() {
        this.props.table.changeFilters([
            {
                column: 'with_future_payments',
                type: TableModelColumnFilteringType.equal,
                value: false,
            },
        ])

        this.props.actions.fetchCosts()
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)

        if (this.props.deleting && !nextProps.deleting) {
            this.refresh()
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.costs.heading' }))

        const sorting = this.props.tableModel.get('sorting')
        const filters = this.props.tableModel.get('filters')

        const threeWaySwitchOptions = {
            0: 'fa-repeat text-gray-lighter',
            1: 'fa-repeat-1 text-gray-dark',
            2: 'fa-repeat text-gray-dark',
        }

        const filterIconClassName = threeWaySwitchOptions[filters.getIn(['repeat', 'value']) || 0]

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.costs.heading" />
                                </h4>

                                <div className="pull-left" style={{ marginLeft: '100px' }}>
                                    <TableFilterCheckboxField
                                        identifier="with_future_payments"
                                        type={TableModelColumnFilteringType.equal}
                                        filters={filters}
                                        onChange={this.props.table.changeFilter}
                                        label={this.props.intl.formatMessage({ id: 'fields.showFuturePayments' })}
                                    />
                                </div>

                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                        <Link to={url(this.props.match, 'costs/import')} className="btn btn-addon btn-success m-r-xs">
                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.import" />
                                        </Link>
                                    </PermissionsCheck>
                                    <PermissionsCheck has={[PERMS.CREATE]}>
                                        <Link to={url(this.props.match, 'costs/new')} className="btn btn-addon btn-success">
                                            <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                                        </Link>
                                    </PermissionsCheck>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.date" />
                                                    <TableSortButtons identifier="date" sorting={sorting} onChange={this.props.table.changeSorting} />
                                                </th>
                                                <th className="w-180">
                                                    <FormattedMessage id="fields.cost" />
                                                </th>
                                                <th className="w-200">
                                                    <FormattedMessage id="fields.supplier" />
                                                </th>
                                                <th className="w-180">
                                                    <FormattedMessage id="fields.billNumber" />
                                                </th>
                                                <th className="w-max wm-300">
                                                    <FormattedMessage id="fields.note" />
                                                </th>
                                                <th className="w-300">
                                                    <FormattedMessage id="fields.costCenter" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.vehicle" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.trailer" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.driver" /> / <FormattedMessage id="fields.user" />
                                                </th>
                                                <th className="w-110 text-right">
                                                    <FormattedMessage id="fields.price" />
                                                </th>
                                                <th className="w-110 text-right">
                                                    <FormattedMessage id="fields.priceCZK" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-120">
                                                    <div className="w-90 pull-left">
                                                        <TableFilterInputField
                                                            identifier="date"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.date' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <a
                                                        href="#"
                                                        className="m-t-xxs pull-right"
                                                        onClick={e => {
                                                            this.handleChangeThreeWaySwitchFilter(e, 'repeat')
                                                        }}
                                                    >
                                                        <i className={`far ${filterIconClassName}`} />
                                                    </a>
                                                </th>
                                                <th className="w-180">
                                                    <TableFilterSelectField
                                                        identifier="cost_type_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('costTypes'))}
                                                    />
                                                </th>
                                                <th className="w-200">
                                                    <TableFilterInputField
                                                        identifier="supplier"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.supplier' })}
                                                    />
                                                </th>
                                                <th className="w-180">
                                                    <TableFilterInputField
                                                        identifier="bill_number"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.billNumber' })}
                                                    />
                                                </th>
                                                <th className="w-max wm-300">
                                                    <TableFilterInputField
                                                        identifier="note"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.note' })}
                                                    />
                                                </th>
                                                <th className="w-300">
                                                    <TableFilterSelectField
                                                        identifier="company_cost_center_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('companyCostCenters'))}
                                                    />
                                                </th>
                                                <th className="w-120">
                                                    <TableFilterInputField
                                                        identifier="vehicle"
                                                        type={TableModelColumnFilteringType.custom}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                    />
                                                </th>
                                                <th className="w-120">
                                                    <TableFilterInputField
                                                        identifier="trailer"
                                                        type={TableModelColumnFilteringType.custom}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        placeholder={this.props.intl.formatMessage({ id: 'fields.trailer' })}
                                                    />
                                                </th>
                                                <th className="w-120">
                                                    <TableFilterInputField
                                                        identifier="user"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-110" />
                                                <th className="w-110" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.costs.data.valueSeq().map(row => {
                                                return (
                                                    <CostsListRow
                                                        key={`row-${row.id}`}
                                                        data={row}
                                                        onClick={this.onClick}
                                                        onDelete={this.onDelete}
                                                        costType={this.props.createGetCostTypeByIDSelector(row.cost_type_id)}
                                                        companyCostCenter={this.props.createGetCompanyCostCenterByIDSelector(row.company_cost_center_id)}
                                                        driver={this.props.createGetDriverByIDSelector(row.driver_id)}
                                                        user={this.props.createGetUserByIDSelector(row.user_id)}
                                                        vehicle={this.props.createGetVehicleByIDSelector(row.vehicle_id)}
                                                        trailer={this.props.createGetTrailerByIDSelector(row.trailer_id)}
                                                        currency={this.props.createGetCurrencyByIDSelector(row.currency_id)}
                                                    />
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.costs.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.costs.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>

                    <ConfirmDialog options={this.state.confirmDialog} />

                    <Modal show={Boolean(this.state.removeDialog.show)} onHide={this.hideRemoveDialog} bsSize="sm" className="modal-level-1">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="confirmDialog.deleteCostGonerator.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="confirmDialog.deleteCostGonerator.message" />
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default m-r-xs" onClick={this.state.removeDialog.onRemoveThisPayment}>
                                <FormattedMessage id="confirmDialog.deleteCostGonerator.onlyThis" />
                            </button>
                            <button className="btn btn-default" onClick={this.state.removeDialog.onRemoveThisAndFuturePayments}>
                                <FormattedMessage id="confirmDialog.deleteCostGonerator.thisAndFuture" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        costs: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getCosts,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        deleting: getCostDeleting(state),
        deleteError: getCostDeleteError(state),
        createGetCostTypeByIDSelector: id => createGetCostTypeByIDSelector(id)(state),
        createGetCompanyCostCenterByIDSelector: id => createGetCompanyCostCenterByIDSelector(id)(state),
        createGetCurrencyByIDSelector: id => createGetCurrencyByIDSelector(id)(state),
        createGetDriverByIDSelector: id => createGetDriverByIDSelector(id)(state),
        createGetUserByIDSelector: id => createGetUserByIDSelector(id)(state),
        createGetVehicleByIDSelector: id => createGetVehicleByIDSelector(id)(state),
        createGetTrailerByIDSelector: id => createGetTrailerByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...costsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(CostsList)
)
