import { Record } from 'immutable'

export class VehicleFuelingProduct extends Record({
    id: undefined,
    key: undefined,
    name: undefined,
    product_type: undefined,
    vehicle_fueling_product_type_id: undefined,
    vehicle_fueling_company_id: undefined,
}) {}
