import * as actionTypes from './actionTypes'

// Fetch one

export const fetchUser = id => ({
    type: actionTypes.FETCH,
    payload: id,
})

export const fetchUserFulfilled = user => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: user,
})

export const fetchUserRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchUserCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Save

export const saveUser = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveUserFulfilled = user => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: user,
})

export const saveUserRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})
