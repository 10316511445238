import { createSelector } from 'reselect'

export const getTransports = state => state.transports.getIn(['all', 'objects'])
export const createGetTransportByIDSelector = id =>
    createSelector(
        [getTransports],
        transports => transports.get(String(id))
    )

export const getCurrentTransportStatus = state => state.transports.get('current')
export const createGetCurrentTransportStatusSelector = () =>
    createSelector(
        [getCurrentTransportStatus],
        status => status
    )

export const getTransportDeleting = state => state.transports.getIn(['deletion', 'inProgress'])
export const getTransportDeleteError = state => state.transports.getIn(['deletion', 'error'])

export const getCustomerInfo = state => state.transports.getIn(['customerInfo', 'data'])
export const getCustomerInfoFetching = state => state.transports.getIn(['customerInfo', 'fetching'])
export const getCustomerInfoError = state => state.transports.getIn(['customerInfo', 'error'])

export const getTransportSendingToVehicle = state => state.transports.getIn(['sendingToVehicle', 'inProgress'])
export const getTransportSendToVehicleError = state => state.transports.getIn(['sendingToVehicle', 'error'])

export const getTransportDeletingFromVehicle = state => state.transports.getIn(['deletionFromVehicle', 'inProgress'])
export const getTransportDeleteFromVehicleError = state => state.transports.getIn(['deletionFromVehicle', 'error'])

export const getTransportCreatingFromTemplate = state => state.transports.getIn(['creationFromTemplate', 'inProgress'])
export const getTransportCreateFromTemplateError = state => state.transports.getIn(['creationFromTemplate', 'error'])

export const getTransportCreationTemplate = state => state.transports.getIn(['creationTemplate', 'inProgress'])
export const getTransportCreateTemplateError = state => state.transports.getIn(['creationTemplate', 'error'])
export const getTransportCreatedTemplate = state => state.transports.getIn(['creationTemplate', 'createdTemplate'])

export const getTransportDuplication = state => state.transports.getIn(['duplication', 'inProgress'])
export const getTransportDuplicateError = state => state.transports.getIn(['duplication', 'error'])
export const getTransportDuplicated = state => state.transports.getIn(['duplication', 'duplicatedTransport'])

export const getCurrentTransportEventStatus = state => state.transports.get('event')
export const createGetCurrentTransportEventStatusSelector = () =>
    createSelector(
        [getCurrentTransportEventStatus],
        status => status
    )

export const getTransportEventDeleting = state => state.transports.getIn(['eventDeletion', 'inProgress'])
export const getTransportEventDeleteError = state => state.transports.getIn(['eventDeletion', 'error'])

export const getTransportEventsCreating = state => state.transports.getIn(['eventsCreating', 'inProgress'])
export const getTransportEventsCreatingError = state => state.transports.getIn(['eventsCreating', 'error'])

export const getTransportPointStateChanging = state => state.transports.getIn(['pointStateChanging', 'inProgress'])
export const getTransportPointStateChangeError = state => state.transports.getIn(['pointStateChanging', 'error'])

export const getTransportLogs = state => state.transports.getIn(['log', 'objects'])

export const getTransportRestoring = state => state.transports.getIn(['restoring', 'inProgress'])
export const getTransportRestoreError = state => state.transports.getIn(['restoring', 'error'])

export const getTransportApproving = state => state.transports.getIn(['approving', 'inProgress'])
export const getTransportApproveError = state => state.transports.getIn(['approving', 'error'])

export const getTransportSmsFetching = state => state.transports.getIn(['sms', 'inProgress'])
export const getTransportSmsError = state => state.transports.getIn(['sms', 'error'])
export const getTransportSmsData = state => state.transports.getIn(['sms', 'text'])

export const getDuplicity = state => state.transports.getIn(['all', 'duplicity'])
export const createGetDuplicitySelector = () =>
    createSelector(
        [getDuplicity],
        duplicity => duplicity
    )

export const getPlannedRouteCalculating = state => state.transports.getIn(['plannedRouteCalculating', 'inProgress'])
export const getPlannedRouteCalculatingError = state => state.transports.getIn(['plannedRouteCalculating', 'error'])

export const getSpecifiedRouteCalculating = state => state.transports.getIn(['specifiedRouteCalculating', 'inProgress'])
export const getSpecifiedRouteCalculatingError = state => state.transports.getIn(['specifiedRouteCalculating', 'error'])

export const getRealRouteCalculating = state => state.transports.getIn(['realRouteCalculating', 'inProgress'])
export const getRealRouteCalculatingError = state => state.transports.getIn(['realRouteCalculating', 'error'])

export const getRealVehiclePositions = state => state.transports.getIn(['realVehiclePositions', 'objects'])
export const getVehicleDataSource = state => state.transports.getIn(['vehicleDataSource', 'object'])
export const getVehicleHomeCountry = state => state.transports.getIn(['vehicleHomeCountry', 'object'])

export const getTransportPreview = state => state.transports.getIn(['preview', 'object'])
export const getTransportMapPreview = state => state.transports.getIn(['mapPreview', 'object'])

export const getCustomersCheckingData = state => state.transports.getIn(['checkCustomers', 'data'])

export const getCarriersCheckingData = state => state.transports.getIn(['checkCarriers', 'data'])

export const getTransportOrdersInfo = state => state.transports.getIn(['ordersInfo', 'data'])
export const getTransportOrdersInfoFetching = state => state.transports.getIn(['ordersInfo', 'fetching'])
export const getTransportOrderData = state => state.transports.getIn(['orderCreating', 'data'])
export const getTransportOrderCreating = state => state.transports.getIn(['orderCreating', 'inProgress'])
export const getTransportOrderCreatingError = state => state.transports.getIn(['orderCreating', 'error'])

export const getTransportOrderSending = state => state.transports.getIn(['orderSending', 'inProgress'])
export const getTransportOrderSendingError = state => state.transports.getIn(['orderSending', 'error'])

export const getTransportFiles = state => state.transports.getIn(['files', 'objects'])
export const getTransportEventFiles = state => state.transports.getIn(['files', 'objectsEvent'])
export const getTransportFilesFetching = state => state.transports.getIn(['files', 'fetching'])
export const getTransportFilesSaving = state => state.transports.getIn(['files', 'saving'])
export const getTransportFilesDeleting = state => state.transports.getIn(['files', 'deleting'])
export const getTransportFilesError = state => state.transports.getIn(['files', 'error'])

export const getTransportGoods = state => state.transports.getIn(['goods', 'objects'])
export const getTransportGoodsFetching = state => state.transports.getIn(['goods', 'fetching'])
export const getTransportGoodsSaving = state => state.transports.getIn(['goods', 'saving'])
export const getTransportGoodsDeleting = state => state.transports.getIn(['goods', 'deleting'])
export const getTransportGoodsError = state => state.transports.getIn(['goods', 'error'])

export const getTransportParts = state => state.transports.getIn(['parts', 'objects'])
export const getTransportPartsFetching = state => state.transports.getIn(['parts', 'fetching'])
export const getTransportPartsSaving = state => state.transports.getIn(['parts', 'saving'])
export const getTransportPartsDeleting = state => state.transports.getIn(['parts', 'deleting'])
export const getTransportPartsError = state => state.transports.getIn(['parts', 'error'])

export const getEventTimesCorrection = state => state.transports.getIn(['eventTimesCorrection', 'inProgress'])
export const getEventTimesCorrectionError = state => state.transports.getIn(['eventTimesCorrection', 'error'])

export const getPointEventTimesCorrection = state => state.transports.getIn(['pointEventTimesCorrection', 'inProgress'])
export const getPointEventTimesCorrectionError = state => state.transports.getIn(['pointEventTimesCorrection', 'error'])

export const getAllowancesData = state => state.transports.getIn(['allowances', 'data'])
export const getAllowancesFetching = state => state.transports.getIn(['allowances', 'fetching'])
export const getAllowancesDeleting = state => state.transports.getIn(['allowances', 'deleting'])
export const getAllowancesError = state => state.transports.getIn(['allowances', 'error'])
export const getDrivers = state => state.drivers.getIn(['all', 'objects'])

export const getAllowancesSaving = state => state.transports.getIn(['allowances', 'saving'])

export const getTransportScoringData = state => state.transports.getIn(['scoring', 'data'])
export const isTransportScoringFetching = state => state.transports.getIn(['scoring', 'fetching'])
export const isTransportScoringSaving = state => state.transports.getIn(['scoring', 'saving'])
export const getTransportScoringError = state => state.transports.getIn(['scoring', 'error'])

export const getSuggestsForCarrier = state => state.transports.getIn(['carrierSuggests', 'data'])

// Import

export const getImportInProgress = state => state.transports.getIn(['import', 'inProgress'])
export const getImportError = state => state.transports.getIn(['import', 'error'])
export const getImportFileId = state => state.transports.getIn(['import', 'file_id'])
export const getImportHeaders = state => state.transports.getIn(['import', 'headers'])
export const getImportDefaultHeaders = state => state.transports.getIn(['import', 'headersDefault'])
export const getImportItems = state => state.transports.getIn(['import', 'items'])
export const getDefaultDate = state => state.transports.getIn(['import', 'defaultDate'])

// Import prices

export const getImportPricesInProgress = state => state.transports.getIn(['importPrices', 'inProgress'])
export const getImportPricesError = state => state.transports.getIn(['importPrices', 'error'])
export const getImportPricesFileId = state => state.transports.getIn(['importPrices', 'file_id'])
export const getImportPricesHeaders = state => state.transports.getIn(['importPrices', 'headers'])
export const getImportPricesDefaultHeaders = state => state.transports.getIn(['importPrices', 'headersDefault'])
export const getImportPricesItems = state => state.transports.getIn(['importPrices', 'items'])

// Check order number duplicities

export const getCheckOrderNumberDuplicitiesInProgress = state => state.transports.getIn(['checkOrderNumberDuplicities', 'inProgress'])
export const getCheckOrderNumberDuplicitiesError = state => state.transports.getIn(['checkOrderNumberDuplicities', 'error'])
export const getCheckOrderNumberDuplicities = state => state.transports.getIn(['checkOrderNumberDuplicities', 'duplicities'])
