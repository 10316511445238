import * as actionTypes from './actionTypes'

// Fetch

export const fetchWarehousingIssues = () => ({
    type: actionTypes.FETCH,
})

export const fetchWarehousingIssuesFulfilled = issues => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: issues,
})

export const fetchWarehousingIssuesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchWarehousingIssuesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchWarehousingIssue = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchWarehousingIssueFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchWarehousingIssueRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchWarehousingIssueCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveWarehousingIssue = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveWarehousingIssueFulfilled = issue => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: issue,
})

export const saveWarehousingIssueRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteWarehousingIssue = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteWarehousingIssueFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteWarehousingIssueRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportWarehousingIssues = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Warehouse item autocomplete

export const fetchItemAutocomplete = data => ({
    type: actionTypes.FETCH_ITEM_AUTOCOMPLETE,
    payload: data,
})

export const fetchItemAutocompleteFulfilled = data => ({
    type: actionTypes.FETCH_ITEM_AUTOCOMPLETE_FULFILLED,
    payload: data,
})

export const fetchItemAutocompleteRejected = message => ({
    type: actionTypes.FETCH_ITEM_AUTOCOMPLETE_REJECTED,
    payload: message,
})

export const fetchItemAutocompleteCancelled = () => ({
    type: actionTypes.FETCH_ITEM_AUTOCOMPLETE_CANCELLED,
})