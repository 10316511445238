import { createSelector } from 'reselect'

export const getDocumentTypes = state => state.documentTypes.getIn(['all', 'objects'])

export const createGetDocumentTypeByIDSelector = id => createSelector([getDocumentTypes], documentTypes => documentTypes.get(String(id)))

const getCurrentDocumentTypeStatus = state => state.documentTypes.get('current')

export const createGetCurrentDocumentTypeStatusSelector = () => createSelector([getCurrentDocumentTypeStatus], status => status)

export const getDocumentTypeDeleting = state => state.documentTypes.getIn(['deletion', 'inProgress'])

export const getDocumentTypeDeleteError = state => state.documentTypes.getIn(['deletion', 'error'])
