export const PERMS = {
    READ: 'invoicing_invoices.read',
    UPDATE: 'invoicing_invoices.update',
    DELETE: 'invoicing_invoices.delete',
    RESTORE: 'invoicing_invoices.restore',
    CREATE: 'invoicing_invoices.create',
    EXPORT: 'invoicing_invoices.export',
    EXPORT_TO_ACCOUNTING: 'invoicing_invoices.export_to_accounting',
    DOWNLOAD: 'invoicing_invoices.download',
    SEND: 'invoicing_invoices.send',
}
