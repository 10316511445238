import * as actionTypes from './actionTypes'

// Fetch

export const fetchUnits = () => ({
    type: actionTypes.FETCH,
})

export const fetchUnitsFulfilled = units => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: units,
})

export const fetchUnitsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchUnitsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchUnit = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchUnitFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchUnitRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchUnitCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveUnit = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveUnitFulfilled = unit => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: unit,
})

export const saveUnitRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteUnit = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteUnitFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteUnitRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportUnits = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
