import React, { Component } from 'react'

import * as formatters from '../../../common/formatters'

class DriversHistoryDriversRow extends Component {
    onClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDriverSelect && this.props.onDriverSelect(this.props.driver)
    }

    render() {
        const driver = this.props.driver
        const selectedDriver = this.props.selectedDriver

        return (
            <tr onClick={this.onClick} className={`row-selectable ${selectedDriver && selectedDriver.id === driver.id ? 'row-selected' : ''}`}>
                <td className="w-160">{driver.last_name}</td>
                <td className="w-max wm-200">{driver.first_name}</td>
                <td className="w-100 text-center">{driver.locale}</td>
                <td className="w-80 text-center">{formatters.boolFormatter(driver.is_active)}</td>
                <td className="w-40 table-buttons text-center">
                    <button className="far fa-arrow-circle-right text-gray" />
                </td>
            </tr>
        )
    }
}

export default DriversHistoryDriversRow
