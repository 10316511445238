import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class UserRolesListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleEdit = () => {
        this.props.onEdit(this.props.data.id)
    }

    handleDuplicate = () => {
        this.props.onDuplicate(this.props.data.id)
    }

    handleDelete = () => {
        this.props.onDelete(this.props.data.id)
    }

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */

    render = () => (
        <tr onClick={this.handleClick} className="row-selectable">
            <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                <td className="w-60 text-center">
                    <div onClick={e => e.stopPropagation()}>
                        <DropdownButton id={`menu-${this.props.data.id}`} className={'far fa-bars'} noCaret title="">
                            <PermissionsCheck has={[PERMS.UPDATE]}>
                                <MenuItem eventKey={1} onSelect={this.handleEdit}>
                                    <i className="far fa-pencil" />
                                    <FormattedMessage id="buttons.openEdit" />
                                </MenuItem>
                            </PermissionsCheck>
                            <PermissionsCheck has={[PERMS.CREATE]}>
                                <MenuItem eventKey={2} onSelect={this.handleDuplicate}>
                                    <i className="far fa-copy" />
                                    <FormattedMessage id="buttons.copy" />
                                </MenuItem>
                            </PermissionsCheck>
                            <PermissionsCheck has={[PERMS.DELETE]}>
                                <MenuItem eventKey={3} onSelect={this.handleDelete}>
                                    <i className="far fa-trash" />
                                    <FormattedMessage id="buttons.delete" />
                                </MenuItem>
                            </PermissionsCheck>
                        </DropdownButton>
                    </div>
                </td>
            </PermissionsCheck>
            <td className="w-max wm-300">{this.props.data.name}</td>
            <td className="w-100 text-right">{this.props.data.users_count}</td>
        </tr>
    )
}

export default UserRolesListRow
