import { Map } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    date: null,
    data: null,
    data_speditions: null,
    data_trailers: null,
    fetching: false,
    error: null,
    vehicle_note: Map({
        saving: false,
        error: null,
    }),
    trailer_note: Map({
        saving: false,
        error: null,
    }),
    trailer_info: Map({
        saving: false,
        error: null,
    }),
    daily_note: Map({
        saving: false,
        error: null,
    }),
    snow_chains: Map({
        saving: false,
        error: null,
    }),
    daily_info: Map({
        saving: false,
        error: null,
    }),
    weekly_info: Map({
        saving: false,
        error: null,
    }),
    preferences: Map({
        saving: false,
        error: null,
        objects: {},
    }),
    point_info: Map({
        saving: false,
        error: null,
    }),
})

function board(state = initialState, action) {
    switch (action.type) {
        // Fetch
        case actionTypes.FETCH:
            return state.set('date', action.payload).set('fetching', true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map
                    .set('fetching', false)
                    .set('data', action.payload.board_data)
                    .set('data_speditions', action.payload.board_data_speditions)
                    .set('data_trailers', action.payload.board_data_trailers)
                    .set('error', null)
                    .setIn(['preferences', 'objects'], action.payload.board_preferences)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.set('fetching', false).set('error', action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.set('fetching', false)

        // Vehicle Note
        case actionTypes.VEHICLE_NOTE_UPDATE_STARTED:
            return state.setIn(['vehicle_note', 'saving'], true)
        case actionTypes.VEHICLE_NOTE_UPDATE_FULFILLED:
            return state.setIn(['vehicle_note', 'saving'], false).setIn(['vehicle_note', 'error'], null)
        case actionTypes.VEHICLE_NOTE_UPDATE_REJECTED:
            return state.setIn(['vehicle_note', 'saving'], false).setIn(['vehicle_note', 'error'], action.payload)
        case actionTypes.VEHICLE_NOTE_UPDATE_CANCELLED:
            return state.setIn(['vehicle_note', 'saving'], false)

        // Trailer Note
        case actionTypes.TRAILER_NOTE_UPDATE_STARTED:
            return state.setIn(['trailer_note', 'saving'], true)
        case actionTypes.TRAILER_NOTE_UPDATE_FULFILLED:
            return state.setIn(['trailer_note', 'saving'], false).setIn(['trailer_note', 'error'], null)
        case actionTypes.TRAILER_NOTE_UPDATE_REJECTED:
            return state.setIn(['trailer_note', 'saving'], false).setIn(['trailer_note', 'error'], action.payload)
        case actionTypes.TRAILER_NOTE_UPDATE_CANCELLED:
            return state.setIn(['trailer_note', 'saving'], false)

        // Trailer Info
        case actionTypes.TRAILER_INFO_UPDATE_STARTED:
            return state.setIn(['trailer_info', 'saving'], true)
        case actionTypes.TRAILER_INFO_UPDATE_FULFILLED:
            return state.setIn(['trailer_info', 'saving'], false).setIn(['trailer_info', 'error'], null)
        case actionTypes.TRAILER_INFO_UPDATE_REJECTED:
            return state.setIn(['trailer_info', 'saving'], false).setIn(['trailer_info', 'error'], action.payload)
        case actionTypes.TRAILER_INFO_UPDATE_CANCELLED:
            return state.setIn(['trailer_info', 'saving'], false)

        // Daily Note
        case actionTypes.DAILY_NOTE_UPDATE_STARTED:
            return state.setIn(['daily_note', 'saving'], true)
        case actionTypes.DAILY_NOTE_UPDATE_FULFILLED:
            return state.setIn(['daily_note', 'saving'], false).setIn(['daily_note', 'error'], null)
        case actionTypes.DAILY_NOTE_UPDATE_REJECTED:
            return state.setIn(['daily_note', 'saving'], false).setIn(['daily_note', 'error'], action.payload)
        case actionTypes.DAILY_NOTE_UPDATE_CANCELLED:
            return state.setIn(['daily_note', 'saving'], false)

        // Daily Info
        case actionTypes.DAILY_INFO_UPDATE_STARTED:
            return state.setIn(['daily_info', 'saving'], true)
        case actionTypes.DAILY_INFO_UPDATE_FULFILLED:
            return state.setIn(['daily_info', 'saving'], false).setIn(['daily_info', 'error'], null)
        case actionTypes.DAILY_INFO_UPDATE_REJECTED:
            return state.setIn(['daily_info', 'saving'], false).setIn(['daily_info', 'error'], action.payload)
        case actionTypes.DAILY_INFO_UPDATE_CANCELLED:
            return state.setIn(['daily_info', 'saving'], false)

        // Weekly Info
        case actionTypes.WEEKLY_INFO_UPDATE_STARTED:
            return state.setIn(['weekly_info', 'saving'], true)
        case actionTypes.WEEKLY_INFO_UPDATE_FULFILLED:
            return state.setIn(['weekly_info', 'saving'], false).setIn(['weekly_info', 'error'], null)
        case actionTypes.WEEKLY_INFO_UPDATE_REJECTED:
            return state.setIn(['weekly_info', 'saving'], false).setIn(['weekly_info', 'error'], action.payload)
        case actionTypes.WEEKLY_INFO_UPDATE_CANCELLED:
            return state.setIn(['weekly_info', 'saving'], false)

        // Preferences
        case actionTypes.PREFERENCES_UPDATE_STARTED:
            return state.setIn(['preferences', 'saving'], true)
        case actionTypes.PREFERENCES_UPDATE_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['preferences', 'saving'], false)
                    .setIn(['preferences', 'error'], null)
                    .setIn(['preferences', 'objects'], action.payload.board_preferences)
                    .set('data', action.payload.board_data)
            })
        case actionTypes.PREFERENCES_UPDATE_REJECTED:
            return state.setIn(['preferences', 'saving'], false).setIn(['preferences', 'error'], action.payload)
        case actionTypes.PREFERENCES_UPDATE_CANCELLED:
            return state.setIn(['preferences', 'saving'], false)

        // Transport point note
        case actionTypes.POINT_NOTE_UPDATE_STARTED:
            return state.setIn(['point_note', 'saving'], true)
        case actionTypes.POINT_NOTE_UPDATE_FULFILLED:
            return state.setIn(['point_note', 'saving'], false).setIn(['point_note', 'error'], null)
        case actionTypes.POINT_NOTE_UPDATE_REJECTED:
            return state.setIn(['point_note', 'saving'], false).setIn(['point_note', 'error'], action.payload)
        case actionTypes.POINT_NOTE_UPDATE_CANCELLED:
            return state.setIn(['point_note', 'saving'], false)

        default:
            return state
    }
}

export default board
