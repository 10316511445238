export const DRIVER_TIMESHEET_TYPE_PAUSE = 0
export const DRIVER_TIMESHEET_TYPE_DRIVE = 1
export const DRIVER_TIMESHEET_TYPE_OTHER_JOB = 2
export const DRIVER_TIMESHEET_TYPE_HOLIDAY = 3
export const DRIVER_TIMESHEET_TYPE_VACATION = 4
export const DRIVER_TIMESHEET_TYPE_UNPAID_VACATION = 5
export const DRIVER_TIMESHEET_TYPE_TURNUS_VACATION = 6
export const DRIVER_TIMESHEET_TYPE_DISEASE = 7
export const DRIVER_TIMESHEET_TYPE_DOCTOR = 8
export const DRIVER_TIMESHEET_TYPE_NURSING = 9
export const DRIVER_TIMESHEET_TYPE_ABSENCE = 10
export const DRIVER_TIMESHEET_TYPE_USE_OVERTIME = 11

export const INTERVAL_TYPE_INTERVAL = 0
export const INTERVAL_TYPE_TIME = 1

export const DRIVER_TIMESHEET_GROUP_TYPE_VACATION = 1
export const DRIVER_TIMESHEET_GROUP_TYPE_DOCTOR = 2
export const DRIVER_TIMESHEET_GROUP_TYPE_ABSENCE = 3
export const DRIVER_TIMESHEET_GROUP_TYPE_USE_OVERTIME = 4

export const DRIVER_TIMESHEET_TYPES = {
    [DRIVER_TIMESHEET_TYPE_PAUSE]: {
        name: 'driverTimesheetTypes.pause',
        rgb_bg: '#000000',
    },
    [DRIVER_TIMESHEET_TYPE_DRIVE]: {
        name: 'driverTimesheetTypes.drive',
        rgb_bg: '#000000',
    },
    [DRIVER_TIMESHEET_TYPE_OTHER_JOB]: {
        name: 'driverTimesheetTypes.other_job',
        rgb_bg: '#000000',
    },
    [DRIVER_TIMESHEET_TYPE_HOLIDAY]: {
        name: 'driverTimesheetTypes.holiday',
        rgb_bg: '#12AFCB',
        group: DRIVER_TIMESHEET_GROUP_TYPE_VACATION,
    },
    [DRIVER_TIMESHEET_TYPE_VACATION]: {
        name: 'driverTimesheetTypes.vacation',
        rgb_bg: '#12AFCB',
        group: DRIVER_TIMESHEET_GROUP_TYPE_VACATION,
    },
    [DRIVER_TIMESHEET_TYPE_UNPAID_VACATION]: {
        name: 'driverTimesheetTypes.unpaid_vacation',
        rgb_bg: '#12AFCB',
        group: DRIVER_TIMESHEET_GROUP_TYPE_VACATION,
    },
    [DRIVER_TIMESHEET_TYPE_TURNUS_VACATION]: {
        name: 'driverTimesheetTypes.turnus_vacation',
        rgb_bg: '#12AFCB',
        group: DRIVER_TIMESHEET_GROUP_TYPE_VACATION,
    },
    [DRIVER_TIMESHEET_TYPE_DISEASE]: {
        name: 'driverTimesheetTypes.disease',
        rgb_bg: '#e0c12e',
        group: DRIVER_TIMESHEET_GROUP_TYPE_DOCTOR,
    },
    [DRIVER_TIMESHEET_TYPE_DOCTOR]: {
        name: 'driverTimesheetTypes.doctor',
        rgb_bg: '#e0c12e',
        group: DRIVER_TIMESHEET_GROUP_TYPE_DOCTOR,
    },
    [DRIVER_TIMESHEET_TYPE_NURSING]: {
        name: 'driverTimesheetTypes.nursing',
        rgb_bg: '#e0c12e',
        group: DRIVER_TIMESHEET_GROUP_TYPE_DOCTOR,
    },
    [DRIVER_TIMESHEET_TYPE_ABSENCE]: {
        name: 'driverTimesheetTypes.absence',
        rgb_bg: '#f25656',
        group: DRIVER_TIMESHEET_GROUP_TYPE_ABSENCE,
    },
    [DRIVER_TIMESHEET_TYPE_USE_OVERTIME]: {
        name: 'driverTimesheetTypes.use_overtime',
        rgb_bg: '#22BAA0',
        group: DRIVER_TIMESHEET_GROUP_TYPE_USE_OVERTIME,
    },
}

export const DRIVER_TIMESHEET_INTERVAL_TYPES = {
    [INTERVAL_TYPE_INTERVAL]: {
        name: 'driverTimesheetIntervalTypes.interval',
    },
    [INTERVAL_TYPE_TIME]: {
        name: 'driverTimesheetIntervalTypes.time',
    },
}

export const DRIVER_TIMESHEET_GROUPS = {
    [DRIVER_TIMESHEET_GROUP_TYPE_VACATION]: {
        name: 'driverTimesheetTypes.holiday',
        rgb_bg: '#12AFCB',
    },
    [DRIVER_TIMESHEET_GROUP_TYPE_DOCTOR]: {
        name: 'driverTimesheetTypes.doctor',
        rgb_bg: '#e0c12e',
    },
    [DRIVER_TIMESHEET_GROUP_TYPE_ABSENCE]: {
        name: 'driverTimesheetTypes.absence',
        rgb_bg: '#f25656',
    },
    [DRIVER_TIMESHEET_GROUP_TYPE_USE_OVERTIME]: {
        name: 'driverTimesheetTypes.use_overtime',
        rgb_bg: '#22BAA0',
    },
}
