// Fetch list

export const FETCH = 'board.FETCH'
export const FETCH_STARTED = 'board.FETCH_STARTED'
export const FETCH_FULFILLED = 'board.FETCH_FULFILLED'
export const FETCH_REJECTED = 'board.FETCH_REJECTED'
export const FETCH_CANCELLED = 'board.FETCH_CANCELLED'

// Vehicle note

export const VEHICLE_NOTE_UPDATE = 'board.VEHICLE_NOTE_UPDATE'
export const VEHICLE_NOTE_UPDATE_STARTED = 'board.VEHICLE_NOTE_UPDATE_STARTED'
export const VEHICLE_NOTE_UPDATE_FULFILLED = 'board.VEHICLE_NOTE_UPDATE_FULFILLED'
export const VEHICLE_NOTE_UPDATE_REJECTED = 'board.VEHICLE_NOTE_UPDATE_REJECTED'
export const VEHICLE_NOTE_UPDATE_CANCELLED = 'board.VEHICLE_NOTE_UPDATE_CANCELLED'

// Trailer note

export const TRAILER_NOTE_UPDATE = 'board.TRAILER_NOTE_UPDATE'
export const TRAILER_NOTE_UPDATE_STARTED = 'board.TRAILER_NOTE_UPDATE_STARTED'
export const TRAILER_NOTE_UPDATE_FULFILLED = 'board.TRAILER_NOTE_UPDATE_FULFILLED'
export const TRAILER_NOTE_UPDATE_REJECTED = 'board.TRAILER_NOTE_UPDATE_REJECTED'
export const TRAILER_NOTE_UPDATE_CANCELLED = 'board.TRAILER_NOTE_UPDATE_CANCELLED'

// Trailer info

export const TRAILER_INFO_UPDATE = 'board.TRAILER_INFO_UPDATE'
export const TRAILER_INFO_UPDATE_STARTED = 'board.TRAILER_INFO_UPDATE_STARTED'
export const TRAILER_INFO_UPDATE_FULFILLED = 'board.TRAILER_INFO_UPDATE_FULFILLED'
export const TRAILER_INFO_UPDATE_REJECTED = 'board.TRAILER_INFO_UPDATE_REJECTED'
export const TRAILER_INFO_UPDATE_CANCELLED = 'board.TRAILER_INFO_UPDATE_CANCELLED'

// Daily note

export const DAILY_NOTE_UPDATE = 'board.DAILY_NOTE_UPDATE'
export const DAILY_NOTE_UPDATE_STARTED = 'board.DAILY_NOTE_UPDATE_STARTED'
export const DAILY_NOTE_UPDATE_FULFILLED = 'board.DAILY_NOTE_UPDATE_FULFILLED'
export const DAILY_NOTE_UPDATE_REJECTED = 'board.DAILY_NOTE_UPDATE_REJECTED'
export const DAILY_NOTE_UPDATE_CANCELLED = 'board.DAILY_NOTE_UPDATE_CANCELLED'

// Daily info

export const DAILY_INFO_UPDATE = 'board.DAILY_INFO_UPDATE'
export const DAILY_INFO_UPDATE_STARTED = 'board.DAILY_INFO_UPDATE_STARTED'
export const DAILY_INFO_UPDATE_FULFILLED = 'board.DAILY_INFO_UPDATE_FULFILLED'
export const DAILY_INFO_UPDATE_REJECTED = 'board.DAILY_INFO_UPDATE_REJECTED'
export const DAILY_INFO_UPDATE_CANCELLED = 'board.DAILY_INFO_UPDATE_CANCELLED'

// Weekly info

export const WEEKLY_INFO_UPDATE = 'board.WEEKLY_INFO_UPDATE'
export const WEEKLY_INFO_UPDATE_STARTED = 'board.WEEKLY_INFO_UPDATE_STARTED'
export const WEEKLY_INFO_UPDATE_FULFILLED = 'board.WEEKLY_INFO_UPDATE_FULFILLED'
export const WEEKLY_INFO_UPDATE_REJECTED = 'board.WEEKLY_INFO_UPDATE_REJECTED'
export const WEEKLY_INFO_UPDATE_CANCELLED = 'board.WEEKLY_INFO_UPDATE_CANCELLED'

// Export

export const EXPORT = 'board.EXPORT'
export const EXPORT_FULFILLED = 'board.EXPORT_FULFILLED'

// Preferences

export const PREFERENCES_UPDATE = 'board.PREFERENCES_UPDATE'
export const PREFERENCES_UPDATE_STARTED = 'board.PREFERENCES_UPDATE_STARTED'
export const PREFERENCES_UPDATE_FULFILLED = 'board.PREFERENCES_UPDATE_FULFILLED'
export const PREFERENCES_UPDATE_REJECTED = 'board.PREFERENCES_UPDATE_REJECTED'
export const PREFERENCES_UPDATE_CANCELLED = 'board.PREFERENCES_UPDATE_CANCELLED'

// Transport point note

export const POINT_NOTE_UPDATE = 'board.POINT_NOTE_UPDATE'
export const POINT_NOTE_UPDATE_STARTED = 'board.POINT_NOTE_UPDATE_STARTED'
export const POINT_NOTE_UPDATE_FULFILLED = 'board.POINT_NOTE_UPDATE_FULFILLED'
export const POINT_NOTE_UPDATE_REJECTED = 'board.POINT_NOTE_UPDATE_REJECTED'
export const POINT_NOTE_UPDATE_CANCELLED = 'board.POINT_NOTE_UPDATE_CANCELLED'
