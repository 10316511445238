import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_BANK_ACCOUNTS } from '../permissions'

// eslint-disable-next-line react/prefer-stateless-function
class CompaniesBankAccountsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick && this.props.onClick(this.props.item)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete && this.props.onDelete(this.props.item)
    }

    render() {
        const bankAccount = this.props.item

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck hasAny={[PERMS_BANK_ACCOUNTS.UPDATE, PERMS_BANK_ACCOUNTS.DELETE]}>
                    <td className="w-60 table-buttons">
                        <PermissionsCheck has={[PERMS_BANK_ACCOUNTS.UPDATE]}>
                            <button className="far fa-pencil text-gray m-r-xxs" />
                        </PermissionsCheck>
                        <PermissionsCheck has={[PERMS_BANK_ACCOUNTS.DELETE]}>
                            <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                        </PermissionsCheck>
                    </td>
                </PermissionsCheck>
                <td className="w-180">{bankAccount.name}</td>
                <td className="w-180">
                    <strong>
                        {bankAccount.number}
                        {bankAccount.number && bankAccount.bank_code ? '/' : ''}
                        {bankAccount.bank_code}
                    </strong>
                </td>
                <td className="w-200">{bankAccount.iban}</td>
                <td className="w-100">{bankAccount.swift}</td>
                <td className="w-40 text-center">{bankAccount.currency ? bankAccount.currency.iso_code : ''}</td>
                <td className="w-80 text-center">{formatters.boolFormatter(bankAccount.is_factoring)}</td>
                <td className="w-max wm-200">{bankAccount.note}</td>
            </tr>
        )
    }
}

export default injectIntl(CompaniesBankAccountsListRow)
