export const PERMS = {
    READ: 'drivers.read',
    UPDATE: 'drivers.update',
    DELETE: 'drivers.delete',
    CREATE: 'drivers.create',
    RESTORE: 'drivers.restore',
    EXPORT: 'drivers.export',
}

export const PERMS_HISTORY = {
    READ: 'driver_history.read',
    UPDATE: 'driver_history.update',
    DELETE: 'driver_history.delete',
    CREATE: 'driver_history.create',
    EXPORT: 'driver_history.export',
}

export const PERMS_DOCUMENTS = {
    READ: 'driver_documents.read',
    UPDATE: 'driver_documents.update',
    DELETE: 'driver_documents.delete',
    CREATE: 'driver_documents.create',
    EXPORT: 'driver_documents.export',
}

export const PERMS_EQUIPMENTS = {
    READ: 'driver_equipments.read',
    UPDATE: 'driver_equipments.update',
    DELETE: 'driver_equipments.delete',
    CREATE: 'driver_equipments.create',
    EXPORT: 'driver_equipments.export',
}

export const PERMS_EVENTS = {
    READ: 'driver_events.read',
    EXPORT: 'driver_events.export',
}

export const PERMS_TIMESHEETS = {
    READ: 'driver_timesheets.read',
    UPDATE: 'driver_timesheets.update',
    DELETE: 'driver_timesheets.delete',
    CREATE: 'driver_timesheets.create',
    EXPORT: 'driver_timesheets.export',
}

export const PERMS_ALLOWANCES = {
    READ: 'driver_allowances.read',
    UPDATE: 'driver_allowances.update',
    DELETE: 'driver_allowances.delete',
    CREATE: 'driver_allowances.create',
}
