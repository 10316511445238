import React from 'react'
import { Route, Switch } from 'react-router'
import serviceWorkersList from './ServiceWorkersList'
import serviceWorkersEdit from './ServiceWorkersEdit'

const serviceWorkers = props => (
    <Switch>
        <Route exact path={props.match.url} component={serviceWorkersList} />
        <Route exact path={`${props.match.url}/new`} component={serviceWorkersEdit} />
        <Route exact path={`${props.match.url}/:serviceWorkerID`} component={serviceWorkersEdit} />
    </Switch>
)

export default serviceWorkers
