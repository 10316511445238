import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// eslint-disable-next-line react/prefer-stateless-function
class CustomDatePicker extends Component {
    render() {
        const isTimePicker = this.props.isTimePicker

        const dateFormat = isTimePicker ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY"
        const popperClassName = isTimePicker ? "react-datepicker-with-time" : "react-datepicker"
        const timeFormat = isTimePicker ? "HH:mm" : undefined
        const timeIntervals = isTimePicker ? 15 : undefined

        return (
            <DatePicker
                disabledKeyboardNavigation={true}
                autoComplete="off"
                shouldCloseOnSelect={false}
                popperClassName={popperClassName}
                dateFormat={dateFormat}
                showTimeSelect={isTimePicker}
                timeFormat={timeFormat}
                timeIntervals={timeIntervals}

                {...this.props}
            />
        )
    }
}

export default CustomDatePicker
