// Fetch list

export const FETCH = 'vehicleFuelings.FETCH'
export const FETCH_STARTED = 'vehicleFuelings.FETCH_STARTED'
export const FETCH_FULFILLED = 'vehicleFuelings.FETCH_FULFILLED'
export const FETCH_REJECTED = 'vehicleFuelings.FETCH_REJECTED'
export const FETCH_CANCELLED = 'vehicleFuelings.FETCH_CANCELLED'
export const FETCH_IMPORT_FILES_FULFILLED = 'vehicleFuelings.FETCH_IMPORT_FILES_FULFILLED'

export const SORTING_CHANGED = 'vehicleFuelings.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'vehicleFuelings.FETCH_ONE'
export const FETCH_ONE_STARTED = 'vehicleFuelings.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'vehicleFuelings.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'vehicleFuelings.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'vehicleFuelings.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'vehicleFuelings.SAVE'
export const SAVE_STARTED = 'vehicleFuelings.SAVE_STARTED'
export const SAVE_FULFILLED = 'vehicleFuelings.SAVE_FULFILLED'
export const SAVE_REJECTED = 'vehicleFuelings.SAVE_REJECTED'
export const SAVE_CANCELLED = 'vehicleFuelings.SAVE_CANCELLED'

// Delete

export const DELETE = 'vehicleFuelings.DELETE'
export const DELETE_STARTED = 'vehicleFuelings.DELETE_STARTED'
export const DELETE_FULFILLED = 'vehicleFuelings.DELETE_FULFILLED'
export const DELETE_REJECTED = 'vehicleFuelings.DELETE_REJECTED'
export const DELETE_CANCELLED = 'vehicleFuelings.DELETE_CANCELLED'

// Fetch import headers

export const FETCH_IMPORT_HEADERS = 'vehicleFuelings.FETCH_IMPORT_HEADERS'
export const FETCH_IMPORT_HEADERS_STARTED = 'vehicleFuelings.FETCH_IMPORT_HEADERS_STARTED'
export const FETCH_IMPORT_HEADERS_FULFILLED = 'vehicleFuelings.FETCH_IMPORT_HEADERS_FULFILLED'
export const FETCH_IMPORT_HEADERS_REJECTED = 'vehicleFuelings.FETCH_IMPORT_HEADERS_REJECTED'
export const FETCH_IMPORT_HEADERS_CANCELLED = 'vehicleFuelings.FETCH_IMPORT_HEADERS_CANCELLED'

// Fetch import values

export const FETCH_IMPORT_VALUES = 'vehicleFuelings.FETCH_IMPORT_VALUES'
export const FETCH_IMPORT_VALUES_STARTED = 'vehicleFuelings.FETCH_IMPORT_VALUES_STARTED'
export const FETCH_IMPORT_VALUES_FULFILLED = 'vehicleFuelings.FETCH_IMPORT_VALUES_FULFILLED'
export const FETCH_IMPORT_VALUES_REJECTED = 'vehicleFuelings.FETCH_IMPORT_VALUES_REJECTED'
export const FETCH_IMPORT_VALUES_CANCELLED = 'vehicleFuelings.FETCH_IMPORT_VALUES_CANCELLED'

// Fetch import preview

export const FETCH_IMPORT_PREVIEW = 'vehicleFuelings.FETCH_IMPORT_PREVIEW'
export const FETCH_IMPORT_PREVIEW_STARTED = 'vehicleFuelings.FETCH_IMPORT_PREVIEW_STARTED'
export const FETCH_IMPORT_PREVIEW_FULFILLED = 'vehicleFuelings.FETCH_IMPORT_PREVIEW_FULFILLED'
export const FETCH_IMPORT_PREVIEW_REJECTED = 'vehicleFuelings.FETCH_IMPORT_PREVIEW_REJECTED'
export const FETCH_IMPORT_PREVIEW_CANCELLED = 'vehicleFuelings.FETCH_IMPORT_PREVIEW_CANCELLED'

// Import

export const IMPORT = 'vehicleFuelings.IMPORT'
export const IMPORT_STARTED = 'vehicleFuelings.IMPORT_STARTED'
export const IMPORT_FULFILLED = 'vehicleFuelings.IMPORT_FULFILLED'
export const IMPORT_REJECTED = 'vehicleFuelings.IMPORT_REJECTED'
export const IMPORT_CANCELLED = 'vehicleFuelings.IMPORT_CANCELLED'

// Fetch import products headers

export const FETCH_IMPORT_PRODUCTS_HEADERS = 'vehicleFuelings.FETCH_IMPORT_PRODUCTS_HEADERS'
export const FETCH_IMPORT_PRODUCTS_HEADERS_STARTED = 'vehicleFuelings.FETCH_IMPORT_PRODUCTS_HEADERS_STARTED'
export const FETCH_IMPORT_PRODUCTS_HEADERS_FULFILLED = 'vehicleFuelings.FETCH_IMPORT_PRODUCTS_HEADERS_FULFILLED'
export const FETCH_IMPORT_PRODUCTS_HEADERS_REJECTED = 'vehicleFuelings.FETCH_IMPORT_PRODUCTS_HEADERS_REJECTED'
export const FETCH_IMPORT_PRODUCTS_HEADERS_CANCELLED = 'vehicleFuelings.FETCH_IMPORT_PRODUCTS_HEADERS_CANCELLED'

// Fetch products preview

export const FETCH_IMPORT_PRODUCTS_PREVIEW = 'vehicleFuelings.FETCH_IMPORT_PRODUCTS_PREVIEW'
export const FETCH_IMPORT_PRODUCTS_PREVIEW_STARTED = 'vehicleFuelings.FETCH_IMPORT_PRODUCTS_PREVIEW_STARTED'
export const FETCH_IMPORT_PRODUCTS_PREVIEW_FULFILLED = 'vehicleFuelings.FETCH_IMPORT_PRODUCTS_PREVIEW_FULFILLED'
export const FETCH_IMPORT_PRODUCTS_PREVIEW_REJECTED = 'vehicleFuelings.FETCH_IMPORT_PRODUCTS_PREVIEW_REJECTED'
export const FETCH_IMPORT_PRODUCTS_PREVIEW_CANCELLED = 'vehicleFuelings.FETCH_IMPORT_PRODUCTS_PREVIEW_CANCELLED'

// Import products

export const IMPORT_PRODUCTS = 'vehicleFuelings.IMPORT_PRODUCTS'
export const IMPORT_PRODUCTS_STARTED = 'vehicleFuelings.IMPORT_PRODUCTS_STARTED'
export const IMPORT_PRODUCTS_FULFILLED = 'vehicleFuelings.IMPORT_PRODUCTS_FULFILLED'
export const IMPORT_PRODUCTS_REJECTED = 'vehicleFuelings.IMPORT_PRODUCTS_REJECTED'
export const IMPORT_PRODUCTS_CANCELLED = 'vehicleFuelings.IMPORT_PRODUCTS_CANCELLED'

// Fetch product list

export const FETCH_PRODUCTS = 'vehicleFuelings.FETCH_PRODUCTS'
export const FETCH_PRODUCTS_STARTED = 'vehicleFuelings.FETCH_PRODUCTS_STARTED'
export const FETCH_PRODUCTS_FULFILLED = 'vehicleFuelings.FETCH_PRODUCTS_FULFILLED'
export const FETCH_PRODUCTS_REJECTED = 'vehicleFuelings.FETCH_PRODUCTS_REJECTED'
export const FETCH_PRODUCTS_CANCELLED = 'vehicleFuelings.FETCH_PRODUCTS_CANCELLED'

// Fetch import list

export const FETCH_IMPORTS = 'vehicleFuelings.FETCH_IMPORTS'
export const FETCH_IMPORTS_STARTED = 'vehicleFuelings.FETCH_IMPORTS_STARTED'
export const FETCH_IMPORTS_FULFILLED = 'vehicleFuelings.FETCH_IMPORTS_FULFILLED'
export const FETCH_IMPORTS_REJECTED = 'vehicleFuelings.FETCH_IMPORTS_REJECTED'
export const FETCH_IMPORTS_CANCELLED = 'vehicleFuelings.FETCH_IMPORTS_CANCELLED'

// Delete import

export const DELETE_IMPORT = 'vehicleFuelings.DELETE_IMPORT'
export const DELETE_IMPORT_STARTED = 'vehicleFuelings.DELETE_IMPORT_STARTED'
export const DELETE_IMPORT_FULFILLED = 'vehicleFuelings.DELETE_IMPORT_FULFILLED'
export const DELETE_IMPORT_REJECTED = 'vehicleFuelings.DELETE_IMPORT_REJECTED'
export const DELETE_IMPORT_CANCELLED = 'vehicleFuelings.DELETE_IMPORT_CANCELLED'

// Fetch country

export const FETCH_COUNTRY = 'vehicleFuelings.FETCH_COUNTRY'
export const FETCH_COUNTRY_STARTED = 'vehicleFuelings.FETCH_COUNTRY_STARTED'
export const FETCH_COUNTRY_FULFILLED = 'vehicleFuelings.FETCH_COUNTRY_FULFILLED'
export const FETCH_COUNTRY_REJECTED = 'vehicleFuelings.FETCH_COUNTRY_REJECTED'
export const FETCH_COUNTRY_CANCELLED = 'vehicleFuelings.FETCH_COUNTRY_CANCELLED'

// Fetch driver

export const FETCH_DRIVER = 'vehicleFuelings.FETCH_DRIVER'
export const FETCH_DRIVER_STARTED = 'vehicleFuelings.FETCH_DRIVER_STARTED'
export const FETCH_DRIVER_FULFILLED = 'vehicleFuelings.FETCH_DRIVER_FULFILLED'
export const FETCH_DRIVER_REJECTED = 'vehicleFuelings.FETCH_DRIVER_REJECTED'
export const FETCH_DRIVER_CANCELLED = 'vehicleFuelings.FETCH_DRIVER_CANCELLED'

// Clear

export const CLEAR = 'vehicleFuelings.CLEAR'
export const CLEAR_PRODUCTS = 'vehicleFuelings.CLEAR_PRODUCTS'
export const CLEAR_IMPORT = 'vehicleFuelings.CLEAR_IMPORT'
export const CLEAR_IMPORT_PRODUCTS = 'vehicleFuelings.CLEAR_IMPORT_PRODUCTS'
