import { createSelector } from 'reselect'

export const getInvoiceItemTypes = state => state.invoiceItemTypes.getIn(['all', 'objects'])

export const createGetInvoiceItemTypeByIDSelector = id => createSelector([getInvoiceItemTypes], invoiceItemTypes => invoiceItemTypes.get(String(id)))

const getCurrentInvoiceItemTypeStatus = state => state.invoiceItemTypes.get('current')
export const createGetCurrentInvoiceItemTypeStatusSelector = () => createSelector([getCurrentInvoiceItemTypeStatus], status => status)

export const getInvoiceItemTypeDeleting = state => state.invoiceItemTypes.getIn(['deletion', 'inProgress'])
export const getInvoiceItemTypeDeleteError = state => state.invoiceItemTypes.getIn(['deletion', 'error'])
