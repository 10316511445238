import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'

// Fetch

const fetchReportTransportChangesEpic = (action$, store) => {
    const tableIdentifier = 'report_transport_changes'

    return action$
        .ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'POST',
                path: `report-transport-changes?page=${modelState.getIn(['pagination', 'current']) + 1}`,
                body: {
                    filters: modelState.get('filters').toJS(),
                    datetime: action.payload.datetime,
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const observables = [
                            Observable.of(
                                actionCreators.fetchReportTransportChangesFulfilled(ajaxResponse.response.data, ajaxResponse.response.datetime)
                            ),
                            Observable.of(
                                tableModelActionCreators.updatePagination(
                                    tableIdentifier,
                                    ajaxResponse.response.last_page,
                                    ajaxResponse.response.current_page - 1,
                                    ajaxResponse.response.total
                                )
                            ),
                        ]

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchReportTransportChangesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Export

const exportReportTransportChangesEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const datetime = action.payload.datetime
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-transport-changes/export?filters=${filters}&datetime=${datetime}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

export default [fetchReportTransportChangesEpic, exportReportTransportChangesEpic]
