import { createSelector } from 'reselect'

export const getData = state => state.invoicingInvoices.getIn(['all', 'objects'])
export const createGetDataSelector = () => createSelector([getData], data => data)

export const getCurrentInvoice = state => state.invoicingInvoices.getIn(['current', 'object'])
export const getCurrentSupportData = state => state.invoicingInvoices.getIn(['current', 'supportData'])
export const getCurrentInvoiceFetching = state => state.invoicingInvoices.getIn(['current', 'fetching'])
export const getCurrentInvoiceSaving = state => state.invoicingInvoices.getIn(['current', 'saving'])
export const getCurrentInvoiceError = state => state.invoicingInvoices.getIn(['current', 'error'])

export const getInvoiceDeleting = state => state.invoicingInvoices.getIn(['deleting', 'inProgress'])
export const getInvoiceDeletingError = state => state.invoicingInvoices.getIn(['deleting', 'error'])

export const getInvoiceDetail = state => state.invoicingInvoices.getIn(['detail', 'data'])
export const getInvoiceDetailFetching = state => state.invoicingInvoices.getIn(['detail', 'fetching'])
export const getInvoiceDetailError = state => state.invoicingInvoices.getIn(['detail', 'error'])

export const getCustomerInfo = state => state.invoicingInvoices.getIn(['customerInfo', 'data'])
export const getCustomerInfoFetching = state => state.invoicingInvoices.getIn(['customerInfo', 'fetching'])
export const getCustomerInfoError = state => state.invoicingInvoices.getIn(['customerInfo', 'error'])

export const getCreateCreditNote = state => state.invoicingInvoices.getIn(['createCreditNote', 'data'])
export const getCreateCreditNoteFetching = state => state.invoicingInvoices.getIn(['createCreditNote', 'fetching'])
export const getCreateCreditNoteError = state => state.invoicingInvoices.getIn(['createCreditNote', 'error'])

export const getExportToAccountingInProgress = state => state.invoicingInvoices.getIn(['exportToAccounting', 'inProgress'])
export const getExportToAccountingError = state => state.invoicingInvoices.getIn(['exportToAccounting', 'error'])

export const getTransportInfo = state => state.invoicingInvoices.getIn(['transportInfo', 'data'])
export const getTransportInfoFetching = state => state.invoicingInvoices.getIn(['transportInfo', 'fetching'])
export const getTransportInfoError = state => state.invoicingInvoices.getIn(['transportInfo', 'error'])

export const getPdfCreatingData = state => state.invoicingInvoices.getIn(['pdfCreating', 'data'])
export const getPdfCreatingInProgress = state => state.invoicingInvoices.getIn(['pdfCreating', 'inProgress'])
export const getPdfCreatingError = state => state.invoicingInvoices.getIn(['pdfCreating', 'error'])

export const getInvoiceSending = state => state.invoicingInvoices.getIn(['invoiceSending', 'inProgress'])
export const getInvoiceSendingError = state => state.invoicingInvoices.getIn(['invoiceSending', 'error'])
