// Fetch list

export const FETCH = 'warehousingReceipts.FETCH'
export const FETCH_STARTED = 'warehousingReceipts.FETCH_STARTED'
export const FETCH_FULFILLED = 'warehousingReceipts.FETCH_FULFILLED'
export const FETCH_REJECTED = 'warehousingReceipts.FETCH_REJECTED'
export const FETCH_CANCELLED = 'warehousingReceipts.FETCH_CANCELLED'

export const SORTING_CHANGED = 'warehousingReceipts.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'warehousingReceipts.FETCH_ONE'
export const FETCH_ONE_STARTED = 'warehousingReceipts.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'warehousingReceipts.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'warehousingReceipts.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'warehousingReceipts.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'warehousingReceipts.SAVE'
export const SAVE_STARTED = 'warehousingReceipts.SAVE_STARTED'
export const SAVE_FULFILLED = 'warehousingReceipts.SAVE_FULFILLED'
export const SAVE_REJECTED = 'warehousingReceipts.SAVE_REJECTED'
export const SAVE_CANCELLED = 'warehousingReceipts.SAVE_CANCELLED'

// Delete

export const DELETE = 'warehousingReceipts.DELETE'
export const DELETE_STARTED = 'warehousingReceipts.DELETE_STARTED'
export const DELETE_FULFILLED = 'warehousingReceipts.DELETE_FULFILLED'
export const DELETE_REJECTED = 'warehousingReceipts.DELETE_REJECTED'
export const DELETE_CANCELLED = 'warehousingReceipts.DELETE_CANCELLED'

// Export

export const EXPORT = 'warehousingReceipts.EXPORT'
export const EXPORT_FULFILLED = 'warehousingReceipts.EXPORT_FULFILLED'

// Fetch item autocomplete

export const FETCH_ITEM_AUTOCOMPLETE = 'warehousingReceipts.FETCH_ITEM_AUTOCOMPLETE'
export const FETCH_ITEM_AUTOCOMPLETE_STARTED = 'warehousingReceipts.FETCH_ITEM_AUTOCOMPLETE_STARTED'
export const FETCH_ITEM_AUTOCOMPLETE_FULFILLED = 'warehousingReceipts.FETCH_ITEM_AUTOCOMPLETE_FULFILLED'
export const FETCH_ITEM_AUTOCOMPLETE_REJECTED = 'warehousingReceipts.FETCH_ITEM_AUTOCOMPLETE_REJECTED'
export const FETCH_ITEM_AUTOCOMPLETE_CANCELLED = 'warehousingReceipts.FETCH_ITEM_AUTOCOMPLETE_CANCELLED'