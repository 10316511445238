import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportVehicleChecks = month => ({
    type: actionTypes.FETCH,
    payload: month,
})

export const fetchReportVehicleChecksFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportVehicleChecksRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportVehicleChecksCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportVehicleChecks = () => ({
    type: actionTypes.CLEAR,
})
