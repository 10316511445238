import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportDriverPerformanceAnalysis = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportDriverPerformanceAnalysisFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportDriverPerformanceAnalysisRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportDriverPerformanceAnalysisCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportDriverPerformanceAnalysis = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportDriverPerformanceAnalysis = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportDriverPerformanceAnalysisDetails = (driverId, dateFrom, dateTo) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        driverId,
        dateFrom,
        dateTo,
    },
})

export const fetchReportDriverPerformanceAnalysisDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportDriverPerformanceAnalysisDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportDriverPerformanceAnalysisDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear details

export const clearReportDriverPerformanceAnalysisDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export details

export const exportReportDriverPerformanceAnalysisDetails = (driverId, dateFrom, dateTo, filters) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        driverId,
        dateFrom,
        dateTo,
        filters,
    }
})
