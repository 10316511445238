import { Observable } from 'rxjs'
import { request, parseAPIError } from '../../common/api'
import * as actionCreators from './actionCreators'
import * as actionTypes from './actionTypes'
import * as selectors from './selectors'

const TRANSPORT_SCORING_BASE_PATH = 'transport-scoring'

// Fetch

const fetchTransportScoringEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.FETCH)
        .filter(() => !selectors.isTransportScoringFetching(store.getState()))
        .switchMap(() =>
            Observable.concat(
                Observable.of(actionCreators.fetchTransportScoringStarted()),
                request({
                    method: 'GET',
                    path: TRANSPORT_SCORING_BASE_PATH,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.fetchTransportScoringFulfilled(ajaxResponse.response.data, ajaxResponse.response.rules))
                    )
                    .catch(error => Observable.of(actionCreators.fetchTransportScoringRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED))
            )
        )
}

// Save question

const saveTransportScoringQuestionEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.SAVE_QUESTION)
        .filter(() => !selectors.isTransportScoringQuestionSaving(store.getState()))
        .switchMap(action => {
            const values = { ...action.payload }
            const method = values.id ? 'PUT' : 'POST'
            const path = values.id ? `${TRANSPORT_SCORING_BASE_PATH}/${values.id}` : TRANSPORT_SCORING_BASE_PATH

            return Observable.concat(
                Observable.of(actionCreators.saveTransportScoringQuestionStarted()),
                request({
                    method,
                    path,
                    body: values,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.saveTransportScoringQuestionFulfilled(ajaxResponse.response.data, ajaxResponse.response.rules))
                    )
                    .catch(error => Observable.of(actionCreators.saveTransportScoringQuestionRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_QUESTION_CANCELLED))
            )
        })
}

// Delete question

const deleteTransportScoringQuestionEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.DELETE_QUESTION)
        .filter(() => !selectors.isTransportScoringQuestionDeleting(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.deleteTransportScoringQuestionStarted()),
                request({
                    method: 'DELETE',
                    path: `${TRANSPORT_SCORING_BASE_PATH}/${action.payload}`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.deleteTransportScoringQuestionFulfilled(ajaxResponse.response.data, ajaxResponse.response.rules))
                    )
                    .catch(error => Observable.of(actionCreators.deleteTransportScoringQuestionRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_QUESTION_CANCELLED))
            )
        )
}

// Move question UP

const moveTransportScoringQuestionUpEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.MOVE_QUESTION_UP)
        .filter(() => !selectors.isTransportScoringQuestionMovingUp(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.moveTransportScoringQuestionUpStarted()),
                request({
                    method: 'PUT',
                    path: `${TRANSPORT_SCORING_BASE_PATH}/${action.payload}/move-up`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.moveTransportScoringQuestionUpFulfilled(ajaxResponse.response.data, ajaxResponse.response.rules))
                    )
                    .catch(error => Observable.of(actionCreators.moveTransportScoringQuestionUpRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.MOVE_QUESTION_UP_CANCELLED))
            )
        )
}

// Move question DOWN

const moveTransportScoringQuestionDownEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.MOVE_QUESTION_DOWN)
        .filter(() => !selectors.isTransportScoringQuestionMovingDown(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.moveTransportScoringQuestionDownStarted()),
                request({
                    method: 'PUT',
                    path: `${TRANSPORT_SCORING_BASE_PATH}/${action.payload}/move-down`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(
                            actionCreators.moveTransportScoringQuestionDownFulfilled(ajaxResponse.response.data, ajaxResponse.response.rules)
                        )
                    )
                    .catch(error => Observable.of(actionCreators.moveTransportScoringQuestionDownRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.MOVE_QUESTION_DOWN_CANCELLED))
            )
        )
}

// Save answer

const saveTransportScoringAnswerEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.SAVE_ANSWER)
        .filter(() => !selectors.isTransportScoringAnswerSaving(store.getState()))
        .switchMap(action => {
            const values = { ...action.payload }
            const method = values.id ? 'PUT' : 'POST'
            const path = values.id
                ? `${TRANSPORT_SCORING_BASE_PATH}/${values.id}/answer`
                : `${TRANSPORT_SCORING_BASE_PATH}/${values.question_id}/answer`

            return Observable.concat(
                Observable.of(actionCreators.saveTransportScoringAnswerStarted()),
                request({
                    method,
                    path,
                    body: values,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.saveTransportScoringAnswerFulfilled(ajaxResponse.response.data, ajaxResponse.response.rules))
                    )
                    .catch(error => Observable.of(actionCreators.saveTransportScoringAnswerRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_ANSWER_CANCELLED))
            )
        })
}

// Delete answer

const deleteTransportScoringAnswerEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.DELETE_ANSWER)
        .filter(() => !selectors.isTransportScoringAnswerDeleting(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.deleteTransportScoringAnswerStarted()),
                request({
                    method: 'DELETE',
                    path: `${TRANSPORT_SCORING_BASE_PATH}/${action.payload}/answer`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.deleteTransportScoringAnswerFulfilled(ajaxResponse.response.data, ajaxResponse.response.rules))
                    )
                    .catch(error => Observable.of(actionCreators.deleteTransportScoringAnswerRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_ANSWER_CANCELLED))
            )
        )
}

// Move answer UP

const moveTransportScoringAnswerUpEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.MOVE_ANSWER_UP)
        .filter(() => !selectors.isTransportScoringAnswerMovingUp(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.moveTransportScoringAnswerUpStarted()),
                request({
                    method: 'PUT',
                    path: `${TRANSPORT_SCORING_BASE_PATH}/${action.payload}/move-up/answer`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.moveTransportScoringAnswerUpFulfilled(ajaxResponse.response.data, ajaxResponse.response.rules))
                    )
                    .catch(error => Observable.of(actionCreators.moveTransportScoringAnswerUpRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.MOVE_ANSWER_UP_CANCELLED))
            )
        )
}

// Move answer DOWN

const moveTransportScoringAnswerDownEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.MOVE_ANSWER_DOWN)
        .filter(() => !selectors.isTransportScoringAnswerMovingDown(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.moveTransportScoringAnswerDownStarted()),
                request({
                    method: 'PUT',
                    path: `${TRANSPORT_SCORING_BASE_PATH}/${action.payload}/move-down/answer`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.moveTransportScoringAnswerDownFulfilled(ajaxResponse.response.data, ajaxResponse.response.rules))
                    )
                    .catch(error => Observable.of(actionCreators.moveTransportScoringAnswerDownRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.MOVE_ANSWER_DOWN_CANCELLED))
            )
        )
}

// Save rule

const saveTransportScoringRuleEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.SAVE_RULE)
        .filter(() => !selectors.isTransportScoringRuleSaving(store.getState()))
        .switchMap(action => {
            const values = { ...action.payload }
            const method = values.id ? 'PUT' : 'POST'
            const path = values.id ? `${TRANSPORT_SCORING_BASE_PATH}/${values.id}/rule` : `${TRANSPORT_SCORING_BASE_PATH}/rule`

            return Observable.concat(
                Observable.of(actionCreators.saveTransportScoringRuleStarted()),
                request({
                    method,
                    path,
                    body: values,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.saveTransportScoringRuleFulfilled(ajaxResponse.response.data, ajaxResponse.response.rules))
                    )
                    .catch(error => Observable.of(actionCreators.saveTransportScoringRuleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_RULE_CANCELLED))
            )
        })
}

// Delete rule

const deleteTransportScoringRuleEpic = (action$, store) => {
    return action$
        .ofType(actionTypes.DELETE_RULE)
        .filter(() => !selectors.isTransportScoringRuleDeleting(store.getState()))
        .switchMap(action =>
            Observable.concat(
                Observable.of(actionCreators.deleteTransportScoringRuleStarted()),
                request({
                    method: 'DELETE',
                    path: `${TRANSPORT_SCORING_BASE_PATH}/${action.payload}/rule`,
                })
                    .switchMap(ajaxResponse =>
                        Observable.of(actionCreators.deleteTransportScoringRuleFulfilled(ajaxResponse.response.data, ajaxResponse.response.rules))
                    )
                    .catch(error => Observable.of(actionCreators.deleteTransportScoringRuleRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_RULE_CANCELLED))
            )
        )
}

export default [
    fetchTransportScoringEpic,
    saveTransportScoringQuestionEpic,
    deleteTransportScoringQuestionEpic,
    moveTransportScoringQuestionUpEpic,
    moveTransportScoringQuestionDownEpic,
    saveTransportScoringAnswerEpic,
    deleteTransportScoringAnswerEpic,
    moveTransportScoringAnswerUpEpic,
    moveTransportScoringAnswerDownEpic,
    saveTransportScoringRuleEpic,
    deleteTransportScoringRuleEpic,
]
