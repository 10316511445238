import * as actionTypes from './actionTypes'

// Fetch

export const fetchDietRates = () => ({
    type: actionTypes.FETCH,
})

export const fetchDietRatesFulfilled = dietRates => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: dietRates,
})

export const fetchDietRatesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchDietRatesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchDietRate = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchDietRateFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchDietRateRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchDietRateCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveDietRate = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveDietRateFulfilled = dietRate => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: dietRate,
})

export const saveDietRateRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteDietRate = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteDietRateFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteDietRateRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportDietRates = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
