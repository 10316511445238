import React, { Component } from 'react'
import ArrowField from './ArrowField'

class QuarterPicker extends Component {
    onClick = quarter => {
        const momentFrom = this.props.momentFrom.add(quarter, 'Q')
        const momentTo = this.props.momentTo.add(quarter, 'Q').endOf('month')

        this.props.onClick(momentFrom, momentTo)
    }

    render() {
        const year = this.props.momentFrom.format('YYYY')
        const thisQuarter = this.props.momentFrom.format('Q')

        let quarter = `${year} - `

        switch (thisQuarter) {
            case '1':
                quarter += 'I'
                break
            case '2':
                quarter += 'II'
                break
            case '3':
                quarter += 'III'
                break
            case '4':
                quarter += 'IV'
                break
            default:
                quarter += null
                break
        }

        const isMin = this.props.minYear === year && thisQuarter === '1'
        const isMax = this.props.maxYear === year && thisQuarter === '4'

        return <ArrowField onClick={this.onClick} value={quarter} isMin={isMin} isMax={isMax} />
    }
}

export default QuarterPicker
