import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'

import { getListItemsCountMessage } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { getDriverEventsData, getDriverEventsFetching } from '../selectors'
import * as driversActionCreators from '../actionCreators'

import DriversEventsListRow from './DriversEventsListRow'

import * as constants from '../../vehicle_events/constants'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_EVENTS } from '../permissions'

class DriversEventsList extends Component {
    vehicleEventTypesPairs = {}

    onClick = itemId => {
        window.open(`/vehicle-events/${itemId}`, '_blank')
    }

    onPageChange = page => {
        this.props.actions.fetchDriverEvents(this.props.driver.id, page.selected)
    }

    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportDriverEvents(this.props.driver.id)
    }

    componentDidMount() {
        if (this.props.driver) {
            this.props.actions.fetchDriverEvents(this.props.driver.id)
        }

        constants.VEHICLE_EVENT_TYPES.forEach(item => {
            this.vehicleEventTypesPairs[item.id] = this.props.intl.formatMessage({ id: item.name })
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.driver && JSON.stringify(nextProps.driver) !== JSON.stringify(this.props.driver)) {
            this.props.actions.fetchDriverEvents(nextProps.driver.id)
        }
    }

    render() {
        const data = this.props.data

        const rows = data && data.data
        const page = data && data.page ? data.page : 0
        const total = data && data.total ? data.total : 0

        return (
            <PermissionsCheck hasAny={Object.values(PERMS_EVENTS)} noPermissionsTab>
                <LoadingOverlay active={this.props.fetching}>
                    {rows && rows.length > 0 ? (
                        <React.Fragment>
                            <div className="btns-list">
                                <PermissionsCheck has={[PERMS_EVENTS.EXPORT]}>
                                    <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                        <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                    </button>
                                </PermissionsCheck>
                            </div>

                            <div className="table-container">
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                        <tr>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.from" />
                                            </th>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.to" />
                                            </th>
                                            <th className="w-140">
                                                <FormattedMessage id="fields.vehicle" />
                                            </th>
                                            <th className="w-120">
                                                <FormattedMessage id="fields.trailer" />
                                            </th>
                                            <th className="w-80">
                                                <FormattedMessage id="fields.transport" />
                                            </th>
                                            <th className="w-200">
                                                <FormattedMessage id="fields.eventType" />
                                            </th>
                                            <th className="w-max wm-300">
                                                <FormattedMessage id="fields.address" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows &&
                                            rows.map((item, index) => (
                                                <DriversEventsListRow
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={`row-${index}`}
                                                    item={item}
                                                    onClick={this.onClick}
                                                    eventTypes={this.vehicleEventTypesPairs}
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="pull-left m-l-xxs m-t-md">
                                <FormattedMessage id="pagination.totalRecords" />: {getListItemsCountMessage(0, 0, total)}
                            </div>

                            <ReactPaginate
                                containerClassName="pagination"
                                breakLabel={<span className="disabled">...</span>}
                                activeClassName="active"
                                pageCount={Math.ceil(total / 50)}
                                pageRangeDisplayed={10}
                                marginPagesDisplayed={2}
                                forcePage={page}
                                onPageChange={this.onPageChange}
                                previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                            />
                        </React.Fragment>
                    ) : (
                        <div className="alert alert-danger pull-left wp-100">
                            <FormattedMessage id="fields.noItemsFound" />
                        </div>
                    )}
                </LoadingOverlay>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: getDriverEventsData(state),
        fetching: getDriverEventsFetching(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...driversActionCreators,
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(DriversEventsList)
)
