import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'
import { Tabs, Tab } from 'react-bootstrap'

import * as formatters from '../../../common/formatters'
import { Form, InputField, SelectField, CheckboxField, SubmitButton } from '../../../common/form'
import { url, handleCommonEditActions, validationMessage, getPrerequisitesArray } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as companiesActionCreators from '../actionCreators'
import { createGetCompanyByIDSelector, createGetCurrentCompanyStatusSelector } from '../selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import CompaniesBankAccountsList from './CompaniesBankAccountsList'
import CompaniesCostCentersList from './CompaniesCostCentersList'
import CompaniesNumberingsList from './CompaniesNumberingsList'
import CompaniesSettingsList from './CompaniesSettingsList'

const componentIdentifier = 'companies_edit'
const prerequisites = ['countries']

class CompaniesEdit extends Component {
    defaultValues = {}

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
        companyCostCenters: {
            show: true,
        },
        companyBankAccounts: {
            show: false,
        },
        companyNumberings: {
            show: false,
        },
        companySettings: {
            show: false,
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.save()
        })
    }

    save = () => {
        this.props.actions.saveCompany(this.state.values)
    }

    handleSelectTab = tabKey => {
        if (tabKey === 1) {
            this.loadCompanyCostCenters()
        } else if (tabKey === 2) {
            this.loadCompanyBankAccounts()
        } else if (tabKey === 3) {
            this.loadCompanyNumberings()
        }
        else if (tabKey === 4) {
            this.loadCompanySettings()
        }
    }

    loadCompanyCostCenters = () => {
        this.setState({
            companyCostCenters: {
                show: true,
            },
        })
    }

    loadCompanyBankAccounts = () => {
        this.setState({
            companyBankAccounts: {
                show: true,
            },
        })
    }

    loadCompanyNumberings = () => {
        this.setState({
            companyNumberings: {
                show: true,
            },
        })
    }

    loadCompanySettings = () => {
        this.setState({
            companySettings: {
                show: true,
            },
        })
    }

    successCallback = null

    handleSave = () => {
        this.successCallback = (props, nextProps) => {
            if (nextProps.status.get('id')) {
                props.redirect(url(props.match, `companies/${nextProps.status.get('id')}`))
            } else {
                props.redirect(url(props.match, 'companies'))
            }
        }

        this.save()
    }

    handleSaveAndClose = () => {
        this.successCallback = props => {
            props.redirect(url(props.match, 'companies'))
        }

        this.save()
    }

    componentDidMount() {
        this.props.match.params.companyID && this.props.actions.fetchCompany(this.props.match.params.companyID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback)

        if (nextProps.company && (JSON.stringify(nextProps.company) !== JSON.stringify(this.props.company) || !this.state.values.id)) {
            this.setValues(nextProps.company.toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.companies.heading' }))

        const company = this.props.company

        const mainColClassName = company ? 'col-lg-4' : 'col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4'

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className={mainColClassName} style={{ paddingRight: '0' }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.companies.heading" /> -{' '}
                                                {company ? <FormattedMessage id="fields.itemEdit" /> : <FormattedMessage id="fields.itemCreate" />}
                                            </h4>
                                        </div>
                                        <LoadingOverlay
                                            active={
                                                this.props.prerequisites.status.get('fetching') ||
                                                this.props.status.get('fetching') ||
                                                this.props.status.get('saving')
                                            }
                                        >
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(company)}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="vat_number"
                                                                label={this.props.intl.formatMessage({ id: 'fields.vatNumber' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <InputField
                                                                id="reg_number"
                                                                label={this.props.intl.formatMessage({ id: 'fields.regNumber' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <InputField
                                                        id="name"
                                                        label={this.props.intl.formatMessage({ id: 'fields.name' })}
                                                        formGroupClassName="form-group-bigger"
                                                    />

                                                    <h4 className="m-t-lg wp-100">
                                                        <FormattedMessage id="fields.invoicingAddress" />
                                                    </h4>
                                                    <InputField id="street" label={this.props.intl.formatMessage({ id: 'fields.street' })} />
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <InputField
                                                                id="zipcode"
                                                                label={this.props.intl.formatMessage({ id: 'fields.zipcode' })}
                                                            />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <InputField id="city" label={this.props.intl.formatMessage({ id: 'fields.city' })} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <SelectField
                                                                id="country_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.country' })}
                                                                values={getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('countries'),
                                                                    'id',
                                                                    'iso_code'
                                                                )}
                                                            />
                                                        </div>
                                                    </div>

                                                    <h4 className="m-t-lg wp-100">
                                                        <FormattedMessage id="fields.invoicingSettings" />
                                                    </h4>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <CheckboxField
                                                                id="vat_payer"
                                                                label={this.props.intl.formatMessage({ id: 'fields.vatPayer' })}
                                                            />
                                                        </div>
                                                    </div>

                                                    <h4 className="m-t-lg wp-100">
                                                        <FormattedMessage id="fields.contacts" />
                                                    </h4>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <InputField id="email" label={this.props.intl.formatMessage({ id: 'fields.email' })} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <InputField id="phone" label={this.props.intl.formatMessage({ id: 'fields.phone' })} />
                                                        </div>
                                                    </div>

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'companies')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>

                                                        <SubmitButton
                                                            perms={Object.values(PERMS)}
                                                            isEdit={Boolean(company)}
                                                            className="btn-addon pull-right"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>

                            {company && (
                                <div className="col-lg-8 hp-100" style={{ paddingLeft: '5px' }}>
                                    <div className="panel panel-white hp-100 m-b-no">
                                        <div className="panel-body panel-padding hp-100">
                                            <Tabs
                                                defaultActiveKey={1}
                                                animation={false}
                                                id="detail-tabs"
                                                className="detail-tabs"
                                                onSelect={this.handleSelectTab}
                                            >
                                                <Tab
                                                    eventKey={1}
                                                    title={
                                                        <span>
                                                            <i className="far fa-building m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.costCenters' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.companyCostCenters.show && <CompaniesCostCentersList company={company} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={2}
                                                    title={
                                                        <span>
                                                            <i className="far fa-credit-card m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.bankAccounts' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.companyBankAccounts.show && <CompaniesBankAccountsList company={company} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={3}
                                                    title={
                                                        <span>
                                                            <i className="far fa-sliders-v m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.numberings' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.companyNumberings.show && <CompaniesNumberingsList company={company} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={4}
                                                    title={
                                                        <span>
                                                            <i className="far fa-cogs m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.settings' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.companySettings.show && <CompaniesSettingsList company={company} />}
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        company: createGetCompanyByIDSelector(ownProps.match.params.companyID)(state),
        status: createGetCurrentCompanyStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...companiesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CompaniesEdit)
)
