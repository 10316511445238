import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCarrierUsage = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportCarrierUsageFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCarrierUsageRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCarrierUsageCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCarrierUsage = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportCarrierUsage = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})