import React from 'react'
import classNames from 'classnames'

/* eslint-disable jsx-a11y/label-has-for */
const Field = props => {
    const error = props.hasOwnProperty('getError') ? props.getError() : null

    const formGroupClass = classNames('form-group', props.formGroupClassName, {
        'has-error': error !== null,
    })

    return (
        <div className={formGroupClass}>
            {props.label && (
                <label htmlFor={props.id} className="wp-100 display-inline">
                    {props.label}
                </label>
            )}

            {props.children}

            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    )
}
/* eslint-enable jsx-a11y/label-has-for */

export default Field
