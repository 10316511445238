import { Record } from 'immutable'

export class VehiclePosition extends Record({
    id: undefined,
    country_id: undefined,
    vehicle_id: undefined,
    driver_id: undefined,
    datetime: undefined,
    gps_lat: undefined,
    gps_lng: undefined,
    position_name: undefined,
    course: undefined,
    speed: undefined,
    km: undefined,
    is_ignition_on: undefined,
    is_motor_on: undefined,
    is_moving: undefined,
    fuel_level: undefined,
    consumption: undefined,
    truck_weight: undefined
}) {
    getGPS = () => (this.gps_lat && this.gps_lng ? `${this.gps_lat}, ${this.gps_lng}` : '')
}
