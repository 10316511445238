import * as actionTypes from './actionTypes'
import { FETCH_WAREHOUSE_ITEM, FETCH_WAREHOUSING_ITEM } from "./actionTypes";

// Fetch

export const fetchWarehousingReceipts = () => ({
    type: actionTypes.FETCH,
})

export const fetchWarehousingReceiptsFulfilled = receipts => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: receipts,
})

export const fetchWarehousingReceiptsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchWarehousingReceiptsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchWarehousingReceipt = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchWarehousingReceiptFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchWarehousingReceiptRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchWarehousingReceiptCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveWarehousingReceipt = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveWarehousingReceiptFulfilled = receipt => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: receipt,
})

export const saveWarehousingReceiptRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteWarehousingReceipt = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteWarehousingReceiptFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteWarehousingReceiptRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportWarehousingReceipts = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Warehouse item autocomplete

export const fetchItemAutocomplete = data => ({
    type: actionTypes.FETCH_ITEM_AUTOCOMPLETE,
    payload: data,
})

export const fetchItemAutocompleteFulfilled = data => ({
    type: actionTypes.FETCH_ITEM_AUTOCOMPLETE_FULFILLED,
    payload: data,
})

export const fetchItemAutocompleteRejected = message => ({
    type: actionTypes.FETCH_ITEM_AUTOCOMPLETE_REJECTED,
    payload: message,
})

export const fetchItemAutocompleteCancelled = () => ({
    type: actionTypes.FETCH_ITEM_AUTOCOMPLETE_CANCELLED,
})