import { createSelector } from 'reselect'

export const getCompanies = state => state.companies.getIn(['all', 'objects'])
export const createGetCompanyByIDSelector = id => createSelector([getCompanies], companies => companies.get(String(id)))

const getCurrentCompanyStatus = state => state.companies.get('current')
export const createGetCurrentCompanyStatusSelector = () => createSelector([getCurrentCompanyStatus], status => status)

export const getCompanyDeleting = state => state.companies.getIn(['deletion', 'inProgress'])
export const getCompanyDeleteError = state => state.companies.getIn(['deletion', 'error'])

export const getCompanyCostCentersData = state => state.companies.getIn(['costCenters', 'data'])
export const getCompanyCostCentersFetching = state => state.companies.getIn(['costCenters', 'fetching'])
export const getCompanyCostCentersError = state => state.companies.getIn(['costCenters', 'error'])

export const getCompanyBankAccountsData = state => state.companies.getIn(['bankAccounts', 'data'])
export const getCompanyBankAccountsFetching = state => state.companies.getIn(['bankAccounts', 'fetching'])
export const getCompanyBankAccountsError = state => state.companies.getIn(['bankAccounts', 'error'])

export const getDefaultBankAccountsSaving = state => state.companies.getIn(['defaultBankAccounts', 'saving'])
export const getDefaultBankAccountsError = state => state.companies.getIn(['defaultBankAccounts', 'error'])

export const getSettingsData = state => state.companies.getIn(['settings', 'data'])
export const getSettingsFetching = state => state.companies.getIn(['settings', 'fetching'])
export const getSettingsSaving = state => state.companies.getIn(['settings', 'saving'])
export const getSettingsError = state => state.companies.getIn(['settings', 'error'])

export const getCompanyNumberingsData = state => state.companies.getIn(['numberings', 'data'])
export const getCompanyNumberingsFetching = state => state.companies.getIn(['numberings', 'fetching'])
export const getCompanyNumberingsError = state => state.companies.getIn(['numberings', 'error'])
