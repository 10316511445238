const translations = {
    // Login
    'login.title': 'Fleetingo',
    'login.login': 'Proszę się zalogować',
    'login.forgottenPassword': 'Zapomniane hasło',
    'login.setPassword': 'Ustaw hasło',
    'login.copyright': 'Fleetingo s.r.o.',

    // Auth
    'auth.notLoggedIn': 'Nie masz uprawnień dla dostępu do tej strony.',
    'auth.pleaseWait': 'Wgrywam dane...',

    // Top line
    'topLine.title': 'Fleetingo',
    'topLine.messages': 'Wiadomości',
    'topLine.signOut': 'Wylogować się',
    'topLine.help': 'Wsparcie',

    // Messages
    'messages.headline': 'Wiadomości',
    'messages.find': 'Znajdź to...',
    'messages.noMessage': 'Brak wiadomości',
    'messages.writeMessage': 'Napisz wiadomość...',
    'messages.send': 'Prześlij',
    'messages.fromDriver': 'Od kierowcy',
    'messages.state.sending': 'Jest wysyłana',
    'messages.state.sent': 'Przesłana',
    'messages.state.received': 'Dostarczona',
    'messages.state.read': 'Przeczytana',
    'messages.state.markAsRead': 'Oznacz jako przeczytane',
    'messages.subscribed': 'Powiadomienie włączone',
    'messages.unsubscribed': 'Powiadomienie wyłączone',

    // Menu
    'menuItems.transports': 'Transporty',
    'menuItems.transportTemplates': 'Szablony',
    'menuItems.transporeonOffers': 'Transporeon oferty',
    'menuItems.tracing': 'Rysunek kalkowy',
    'menuItems.dispatcherBoard': 'Panel dyspozytora',
    'menuItems.map': 'Mapa',
    'menuItems.reports': 'Raporty',
    'menuItems.reportTransports': 'Transporty',
    'menuItems.reportTransportDetails': 'Wyniki ekonomiczne',
    'menuItems.reportSpeditionTransportDetails': 'Wyniki ekonomiczne transportu spedycyjnego',
    'menuItems.reportTransportChanges': 'Śledzenie zmian',
    'menuItems.reportTransportInvoicing': 'Fakturowanie',
    'menuItems.reportTransportOrders': 'Zamówienia',
    'menuItems.reportVehicles': 'Pojazdy',
    'menuItems.reportVehicleKilometersTotal': 'Całkowity przebieg',
    'menuItems.reportVehiclePerformance': 'Wskaźniki wydajności',
    'menuItems.reportVehicleTraffic': 'Wskaźniki operacyjne',
    'menuItems.reportVehicleChecks': 'Czeki i wady',
    'menuItems.reportVehicleFuelings': 'Tankowanie',
    'menuItems.reportTrailerFuelings': 'Tankowanie',
    'menuItems.reportCustomers': 'Klienci',
    'menuItems.reportCustomerResults': 'Obroty',
    'menuItems.reportCustomerPlans': 'Plan transportu',
    'menuItems.reportCustomerMonthlyPlans': 'Plan miesięczny',
    'menuItems.reportCustomerWeeklyPlans': 'Plan tygodniowy',
    'menuItems.reportCostCenters': 'Jednostki gospodarcze',
    'menuItems.reportCostCenterResults': 'Obroty',
    'menuItems.reportCostCenterTraffic': 'Wskaźniki operacyjne',
    'menuItems.reportCostCenterPerformance': 'Wskaźniki wydajności',
    'menuItems.reportCostCenterCost': 'Koszty',
    'menuItems.reportCostCenterProfits': 'Wyniki ekonomiczne',
    'menuItems.reportOther': 'Inni',
    'menuItems.reportCustomerTurnovers': 'Klienci',
    'menuItems.reportEmptyKilometers': 'Puste kilometry',
    'menuItems.reportPallets': 'Rejestry palet',
    'menuItems.reportInvoicingSummary': 'Streszczenie',
    'menuItems.reportDrivers': 'Kierowcy',
    'menuItems.reportDriverAllowances': 'Diety i nagrody',
    'menuItems.reportDriverPerformanceAnalysis': 'Analiza wydajności',
    'menuItems.reportDriverHourlyOverview': 'Przegląd godzinowy',
    'menuItems.reportDriverTimesheet': 'Raport pracy',
    'menuItems.reportCarriers': 'Przewoźnicy spedycyjni',
    'menuItems.reportCarrierResults': 'Obroty',
    'menuItems.reportCarrierScoring': 'Ocena',
    'menuItems.reportCarrierUsage': 'Użyj',
    'menuItems.reportDispatchers': 'Dyspozytorzy',
    'menuItems.reportDispatcherKPI': 'KPI',
    'menuItems.reportAppUsage': 'Korzystanie z aplikacji',
    'menuItems.fleet': 'Tabor',
    'menuItems.vehicles': 'Pojazdy',
    'menuItems.trailers': 'Naczepy',
    'menuItems.vehicleEvents': 'Wydarzenia',
    'menuItems.vehicleFuelings': 'Tankowanie',
    'menuItems.vehiclePositions': 'Pozycje',
    'menuItems.drivers': 'Kierowcy',
    'menuItems.addressBook': 'Katalog',
    'menuItems.customers': 'Klienci',
    'menuItems.customerAddresses': 'Adresy',
    'menuItems.carriers': 'Przewoźnicy spedycyjni',
    'menuItems.borderCrossings': 'Przejścia graniczne',
    'menuItems.settings': 'Ustawienia',
    'menuItems.users': 'Użytkownicy',
    'menuItems.userRoles': 'Role użytkowników',
    'menuItems.userLogins': 'Dziennik logowania',
    'menuItems.blockedIps': 'Zablokowane adresy IP',
    'menuItems.countries': 'Kraje',
    'menuItems.currencies': 'Waluty',
    'menuItems.companies': 'Firmy',
    'menuItems.transportTypes': 'Typy transportów',
    'menuItems.vehicleTypes': 'Typy pojazdów',
    'menuItems.trailerTypes': 'Typy naczep',
    'menuItems.cargoTypes': 'Typy ładunków',
    'menuItems.surchargeTypes': 'Typy dopłat',
    'menuItems.customerAddressTypes': 'Typy adresów',
    'menuItems.goodsTypes': 'Typy towarów',
    'menuItems.documentTypes': 'Rodzaje dokumentów',
    'menuItems.driverDocuments': 'Dokumenty',
    'menuItems.vehicleDocuments': 'Dokumenty',
    'menuItems.trailerDocuments': 'Dokumenty',
    'menuItems.driverHistory': 'Historia',
    'menuItems.vehicleHistory': 'Historia',
    'menuItems.trailerHistory': 'Historia',
    'menuItems.equipments': 'Wyposażenie',
    'menuItems.driverEquipments': 'Wyposażenie',
    'menuItems.vehicleEquipments': 'Wyposażenie',
    'menuItems.trailerEquipments': 'Wyposażenie',
    'menuItems.driverTimesheets': 'Arkusze',
    'menuItems.taxRates': 'Stawki podatkowe',
    'menuItems.paymentMethods': 'Metody płatności',
    'menuItems.invoicing': 'Fakturowanie',
    'menuItems.invoicingOrders': 'Zamówienia',
    'menuItems.invoicingInvoices': 'Wystawione faktury',
    'menuItems.invoicingReceivedInvoices': 'Otrzymane faktury',
    'menuItems.invoicingPayments': 'Płatności',
    'menuItems.packagingTypes': 'Rodzaje opakowań',
    'menuItems.transportConditions': 'Warunki transportu',
    'menuItems.invoiceItemTypes': 'Rodzaje ruchów',
    'menuItems.general': 'Obecné',
    'menuItems.transportScoring': 'Ocena transportów',
    'menuItems.carrierScoring': 'Ocena przewoźników',
    'menuItems.generalSettings': 'Ustawienia główne',
    'menuItems.dietRates': 'Ceny posiłków',
    'menuItems.costTypes': 'Rodzaje kosztów',
    'menuItems.cost': 'Koszty',
    'menuItems.costs': 'Koszty',
    'menuItems.costGenerators': 'Koszty cykliczne',
    'menuItems.salesTypes': 'Rodzaje sprzedaży',
    'menuItems.driversTypes': 'Rodzaje sterowników',
    'menuItems.sales': 'Obroty',


    // Fields
    'fields.showWithTrashed': 'Pokaż z usuniętym',
    'fields.showOnlyTrashed': 'Pokaż tylko usunięte',
    'fields.hoursCount': 'Liczą się godziny',
    'fields.ignition': 'Zapłon',
    'fields.motor': 'Silnik',
    'fields.settings': 'Ustawienia',
    'fields.invoice_footer': 'Stopka faktury',
    'fields.transport_footer': 'Stopka oferty/zlecenia transportowego',
    'fields.email': 'E-mail',
    'fields.password': 'Hasło',
    'fields.findByAddress': 'Znajdź według adresu',
    'fields.findByGPS': 'Znajdź przez GPS',
    'fields.yes': 'Tak',
    'fields.no': 'Nie',
    'fields.number': 'Numer',
    'fields.documentNumber': 'Numer identyfikacyjny',
    'fields.createdAt': 'Czas stworzenia',
    'fields.state': 'Stan',
    'fields.disponent': 'Autor',
    'fields.customer': 'Klient',
    'fields.transportType': 'Typ transporty',
    'fields.cargoType': 'Rodzaj ładunku',
    'fields.goodsType': 'Rodzaj towarów',
    'fields.loading': 'Załadunek',
    'fields.loadingDate': 'Data załadunku',
    'fields.loadingTime': 'Czas załadunku',
    'fields.loadingDateTime': 'Data i godzina załadunku',
    'fields.loadingAddress': 'Adres załadunku',
    'fields.loadingCity': 'Miasto załadunku',
    'fields.loadingCountry': 'Ziemia załadunku',
    'fields.unloading': 'Rozładunek',
    'fields.unloadingDate': 'Data rozładunku',
    'fields.unloadingTime': 'Czas rozładunku',
    'fields.unloadingDateTime': 'Data i godzina rozładunku',
    'fields.unloadingAddress': 'Adres rozładunku',
    'fields.unloadingCity': 'Miasto rozładunku',
    'fields.unloadingCountry': 'Ziemia rozładunku',
    'fields.address': 'Adres',
    'fields.ipAddress': 'IP adres',
    'fields.cidr': 'CIDR',
    'fields.userAgent': 'User agent',
    'fields.time': 'Czas',
    'fields.from': 'Od',
    'fields.fromPlace': 'Skąd',
    'fields.to': 'Do',
    'fields.toPlace': 'Gdzie',
    'fields.code': 'Kod',
    'fields.vehicle': 'Pojazd',
    'fields.registration': 'Numer auta',
    'fields.dispatcher': 'Dyspozytor',
    'fields.trailer': 'Naczepa',
    'fields.driver': 'Kierowca',
    'fields.price': 'Cena',
    'fields.pricePerKm': 'Cena / KM',
    'fields.priceCZK': 'Cena CZK',
    'fields.profitKM': 'Przychody / KM',
    'fields.profitKMCZK': 'CZK / KM',
    'fields.costsKM': 'Koszty / KM',
    'fields.sentAt': 'Czas wysłania',
    'fields.approach': 'Dojazdu',
    'fields.approachDateTime': 'Data i czas dojazd',
    'fields.plannedApproach': 'Planowany dojazd',
    'fields.approachFormTransportNumber': 'Dojazd z transportu nr.',
    'fields.difference': 'Różnica',
    'fields.arrival': 'Przyjazd',
    'fields.eta': 'ETA',
    'fields.note': 'Uwaga',
    'fields.orderNumber': 'Numer zlecenia',
    'fields.spedition': 'Przekierowanie',
    'fields.autoCabotage': 'Automatycznie',
    'fields.cabotage': 'Kabotaż',
    'fields.trailerRegistration': 'Numer naczepy',
    'fields.vehicleRegistration': 'Numer pojazdu',
    'fields.driverName': 'Nazwa kierowcy',
    'fields.driverPhone': 'Telefon na kierowcę',
    'fields.unknownTrailer': 'Nieznany naczep',
    'fields.archived': 'Zarchiwizowane',
    'fields.carrier': 'Przewoźnik',
    'fields.basePrice': 'Cena podstawowa',
    'fields.totalPrice': 'Cena całkowita',
    'fields.totalDistance': 'Całkowity dystans',
    'fields.odometerState': 'Przebieg (poc./kon)',
    'fields.odometerDistance': 'Różnica (poc./kon.)',
    'fields.totalConsumption': 'całkowite zużycie',
    'fields.avgConsumption': 'Średnie spożycie',
    'fields.fueling': 'Zatankowany',
    'fields.log': 'Log',
    'fields.date': 'Data',
    'fields.dateTime': 'Data i czas',
    'fields.user': 'Użytkownik',
    'fields.changes': 'Zmiany',
    'fields.pcs': 'Szt',
    'fields.pcsToLoad': 'Kwota do załadowania',
    'fields.pcsToUnload': 'Kwota do rozładowania',
    'fields.weight': 'Waga',
    'fields.temperature': 'Temperatura',
    'fields.temperatureFrom': 'Temperatura od',
    'fields.temperatureTo': 'Temperatura do',
    'fields.palletExchange': 'Wymiana palet',
    'fields.palletExchangeIssued': 'Wydano palety',
    'fields.palletExchangeReceived': 'Otrzymana paleta',
    'fields.count': 'Policz',
    'fields.userCount': 'Liczba użytkowników',
    'fields.plannedCount': 'Planowany numer',
    'fields.fuelingType': 'Kwota',
    'fields.fuelingTypeFull': 'Pełny',
    'fields.fuelingTypeMinimal': 'Minimalny',
    'fields.phone': 'Telefon',
    'fields.reason': 'Powód',
    'fields.defect': 'Wada',
    'fields.defectDescription': 'Opis wady',
    'fields.plannedEndTime': 'Planowany czas zakończenia',
    'fields.vehicleDefect': 'Wada pojazdu',
    'fields.vehicleDefectDescription': 'Opis wady pojazdu',
    'fields.trailerDefect': 'Wada naczepy',
    'fields.trailerDefectDescription': 'Opis wady naczepy',
    'fields.documentsDefect': 'Wada dokumentów',
    'fields.documentsDefectDescription': 'Opis wady dokumentów',
    'fields.withObjection': 'Z zastrzeżeniem',
    'fields.sourceTransport': 'Transport źródłowy',
    'fields.template': 'Szablon',
    'fields.transporeonOffersNumber': 'SCI',
    'fields.pointType': 'Typ',
    'fields.pointName': 'Name',
    'fields.eventType': 'Typ wydarzenia',
    'fields.transportState': 'Stan transportu',
    'fields.customersOrderNumber': 'Numer zlecenia (klient)',
    'fields.customersPrice': 'Cena (klient)',
    'fields.customersCurrency': 'Waluta (klient)',
    'fields.customersNote': 'Uwagi (klient)',
    'fields.vehicleNote': 'Uwagi (vehicle)',
    'fields.carriersOrderNumber': 'Numer zlecenia (przewoźnik)',
    'fields.carriersPrice': 'Cena (przewoźnik)',
    'fields.carriersCurrency': 'Waluta (przewoźnik)',
    'fields.carriersNote': 'Uwagi (przewoźnik)',
    'fields.predepartureCheck': 'Kontrola przed odjazdem',
    'fields.ordering': 'Zamówienie',
    'fields.plannedTime': 'Planowany czas',
    'fields.plannedTimeFrom': 'Planowany czas od',
    'fields.plannedTimeTo': 'Planowany czas do',
    'fields.specifiedTime': 'Określony czas',
    'fields.specifiedTimeFrom': 'Określony czas od',
    'fields.specifiedTimeTo': 'Określony czas do',
    'fields.bookingTime': 'Czas rezerwacji',
    'fields.bookingTimeFrom': 'Czas rezerwacji od',
    'fields.bookingTimeTo': 'Czas rezerwacji do',
    'fields.arrivalTime': 'Czas przyjazdu',
    'fields.finishedTime': 'Czas ukończony',
    'fields.companyName': 'Nazwa firmy',
    'fields.customerName': 'Nazwa firmy',
    'fields.carrierName': 'Nazwa firmy',
    'fields.street': 'Ulica',
    'fields.city': 'Miasto',
    'fields.zipcode': 'Kod pocztowy',
    'fields.country': 'Kraj',
    'fields.gpsLat': 'GPS lat',
    'fields.gpsLng': 'GPS lng',
    'fields.driver2': 'Kierowca 2',
    'fields.mileage': 'Przebieg',
    'fields.gps': 'GPS',
    'fields.attachments': 'Załączniki',
    'fields.transport': 'Transport',
    'fields.transportNo': 'Transport nr.',
    'fields.plannedKilometers': 'Planowane kilometry',
    'fields.realKilometers': 'Prawdziwe kilometry',
    'fields.times': 'Czasy',
    'fields.totalTime': 'Czas całkowity',
    'fields.planned': 'Planowane',
    'fields.specified': 'Określony',
    'fields.booking': 'Rezerwacja',
    'fields.finish': 'Zakończ',
    'fields.details': 'Szczegóły',
    'fields.event': 'Wydarzenie',
    'fields.incorrectTime': 'Nieprawidłowy czas',
    'fields.duration': 'Czas trwania',
    'fields.hours': 'h',
    'fields.hoursVerbally': 'Godziny',
    'fields.daysVerbally': 'Dni',
    'fields.minutes': 'm',
    'fields.minutesVerbally': 'Minuty',
    'fields.eg': 'na przykład',
    'fields.addressType': 'Typ adresu',
    'fields.name': 'Nazwa',
    'fields.plannedRoute': 'Planowana trasa',
    'fields.specifiedRoute': 'Określona trasa',
    'fields.realRoute': 'Prawdziwa trasa',
    'fields.routeDetails': 'Szczegóły trasy',
    'fields.distance': 'Dystans',
    'fields.transportDuration': 'Czas transportu',
    'fields.requiredTime': 'Wymagany czas',
    'fields.drive': 'Jazda',
    'fields.pause': 'Pauza',
    'fields.rests': 'Odpoczywa',
    'fields.breaks': 'Przerwy',
    'fields.priceAndCosts': 'Cena i koszty',
    'fields.totalCosts': 'Koszty całkowite',
    'fields.fuel': 'Paliwo',
    'fields.adblue': 'AdBlue',
    'fields.fuel_level': 'Palivo',
    'fields.adblue_level': 'AdBlue',
    'fields.fuelingCompany': 'Stacja benzynowa dostawcy',
    'fields.fuelingCompanyName': 'Nazwa stacji obsługi dostawcy',
    'fields.toll': 'Myto',
    'fields.tollPasses': 'Płatna przechodzi',
    'fields.fixCosts': 'Napraw koszty',
    'fields.templateName': 'Nazwa szablonu',
    'fields.route': 'Trasa',
    'fields.lenght': 'Długość',
    'fields.volume': 'Tom',
    'fields.trailerNote': 'Uwagi (naczep)',
    'fields.informationUnavailable': 'Informacja jest niedostępna',
    'fields.vehicleIsMoving': 'Pojazd się porusza',
    'fields.vehicleIsMovingAt': 'Pojazd jedzie z prędkością',
    'fields.vehicleIsStanding': 'Pojazd stoi',
    'fields.updatedAt': 'Zaktualizowano na',
    'fields.etaUpdatedAt': 'Zaktualizowano ETA na',
    'fields.trailerLoaded': 'Naczep załadowany',
    'fields.trailerEmpty': 'Naczep pusty',
    'fields.trailerDamage': 'Uszkodzenie naczepu',
    'fields.trailerOk': 'Naczep OK',
    'fields.trailerDamaged': 'Naczep uszkodzony',
    'fields.security': 'Bezpieczeństwo naczepu',
    'fields.securityOk': 'Bezpieczeństwo OK',
    'fields.securityDamaged': 'Bezpieczeństwo uszkodzony',
    'fields.securedTrailers': 'Zabezpieczone naczepy',
    'fields.unsecuredTrailers': 'Niezabezpieczone naczepy',
    'fields.trailerCooling': 'Chłodzenie naczepu',
    'fields.coolingOk': 'Chłodzenie OK',
    'fields.coolingDamaged': 'Chłodzenie uszkodzony',
    'fields.cooledTrailers': 'Chłodzone naczepy',
    'fields.notCooledTrailers': 'Nie chłodzone naczepy',
    'fields.week': 'Tydzień',
    'fields.previousWeek': 'Poprzedni tydzień',
    'fields.nextWeek': 'Dalej tydzień',
    'fields.previousDay': 'Poprzedni dzień',
    'fields.day': 'Dzień',
    'fields.day4': 'dni',
    'fields.nextDay': 'Dalej dzień',
    'fields.year': 'Rok',
    'fields.previousYear': 'Poprzedni rok',
    'fields.nextYear': 'Dalej rok',
    'fields.month': 'Miesiąc',
    'fields.previousMonth': 'Poprzedni miesiąc',
    'fields.nextMonth': 'Dalej miesiąc',
    'fields.company': 'Firma',
    'fields.costCenter': 'Jednostka gospodarcza',
    'fields.costCenters': 'Jednostki gospodarcze',
    'fields.total': 'Całkowity',
    'fields.mean': 'Średni',
    'fields.km': 'KM',
    'fields.kmFull': 'Pełny',
    'fields.kmApproach': 'Dojazd',
    'fields.kmService': 'Serwis',
    'fields.kmEmpty': 'Pusty',
    'fields.approachFrom': 'Dojazd od',
    'fields.approachTo': 'Dojazd do',
    'fields.loaded': 'Załadowany',
    'fields.unloaded': 'Rozładowany',
    'fields.balance': 'Saldo',
    'fields.checkType': 'Rodzaj kontroli',
    'fields.defects': 'Wady',
    'fields.defectType': 'Rodzaj wady',
    'fields.notes': 'Uwagi',
    'fields.fbId': 'FB ID',
    'fields.vin': 'VIN',
    'fields.vehicleType': 'Typ pojazdu',
    'fields.vehicleState': 'Stan pojazdu',
    'fields.trailerState': 'Stan naczepu',
    'fields.fbConfiguration': 'FB konfiguracja',
    'fields.active': 'Aktywny',
    'fields.itemEdit': 'Edytuj rzecz',
    'fields.itemCreate': 'Utwórz nowy element',
    'fields.trailerType': 'Typ naczepu',
    'fields.damaged': 'Uszkodzony',
    'fields.firstName': 'Imię',
    'fields.lastName': 'Nazwisko',
    'fields.fullName': 'Pełne imię i nazwisko',
    'fields.language': 'Język',
    'fields.regNumber': 'REGON',
    'fields.vatNumber': 'NIP',
    'fields.blocked': 'Zablokowany',
    'fields.allowed': 'Dozwolony',
    'fields.icon': 'Ikona',
    'fields.color': 'Kolor',
    'fields.textColor': 'Kolor tekstu',
    'fields.bgColor': 'Kolor tła',
    'fields.isAdmin': 'Administrator',
    'fields.userRole': 'Rola',
    'fields.isoCode': 'ISO kod',
    'fields.isoCode2': 'ISO kod (2)',
    'fields.isoCode3': 'ISO kod (3)',
    'fields.currency': 'Waluta',
    'fields.tollPerKm': 'Myto na KM',
    'fields.tollPerDay': 'Myto dziennie',
    'fields.minimumHourWageInSelectedCurrency': 'Minimalna stawka godzinowa (w wybranej walucie)',
    'fields.minimumHourWage': 'Minimalna stawka godzinowa',
    'fields.default': 'Domyślna',
    'fields.exchangeRate': 'Kurs',
    'fields.minProfit': 'Minimalny zysk',
    'fields.dimensions': 'Wymiary',
    'fields.width': 'Szerokość',
    'fields.height': 'Wysokość',
    'fields.tankVolume': 'Objętość zbiornika',
    'fields.axlesCount': 'Liczba osi',
    'fields.avgFuelConsumption': 'Śr. zużycie paliwa',
    'fields.avgAdBlueConsumption': 'Śr. zużycie AdBlue',
    'fields.fuelLevel': 'Paliwo [l]',
    'fields.adblueLevel': 'AdBlue [l]',
    'fields.secured': 'Zabezpieczony',
    'fields.cooled': 'Chłodzony',
    'fields.transportTypes': 'Typy transportu',
    'fields.vehicleTypes': 'Typy pojazdów',
    'fields.cargoTypes': 'Typy ładunków',
    'fields.goodsTypes': 'Typy towarów',
    'fields.securityRequired': 'Wymagane zabezpieczenia',
    'fields.coolingRequired': 'Wymagane chłodzenie',
    'fields.trailerIsNotSecured': 'Naczep nie jest zabezpieczony',
    'fields.trailerIsNotCooled': 'Naczep is not chłodzony',
    'fields.currentPosition': 'Aktualna lokalizacja',
    'fields.eventCreatedByDriver': 'Utworzone przez kierowcę (w DP)',
    'fields.eventCreatedByDispatcher': 'Utworzony przez dyspozytora (ręcznie)',
    'fields.capacityPallets': 'Liczba pal. miejsc',
    'fields.noVehicle': 'Bez pojazdu',
    'fields.noTrailer': 'Bez naczepy',
    'fields.noDriver': 'Bez kierowcy',
    'fields.noTime': 'Bez czasu',
    'fields.trailerEquipments': 'Wyposażenie naczep',
    'fields.nameInTransporeon': 'Nazwa w Transporeon',
    'fields.navigationStart': 'Włącz nawigację',
    'fields.navigationStop': 'Wyłącz nawigację',
    'fields.speed': 'Prędkość',
    'fields.isSpedition': 'Przekazywanie',
    'fields.legend': 'Legenda',
    'fields.all': 'Wszystko',
    'fields.transportPointState': 'Stan punktu transportu',
    'fields.transportPointType': 'Typ punktu transportu',
    'fields.preferences': 'Ustawienia',
    'fields.relevantForDrivers': 'Kierowcy',
    'fields.relevantForVehicles': 'Pojazdy',
    'fields.relevantForTrailers': 'Naczepy',
    'fields.notify': 'Powiadom',
    'fields.notifyInList': 'Powiadom mnie w przeglądzie',
    'fields.notifyInBoard': 'Powiadom w DP',
    'fields.daysInAdvance': 'Dni wcześniej',
    'fields.documentType': 'Typ dokumentu',
    'fields.validityFrom': 'Ważność od',
    'fields.validityTo': 'Ważność do',
    'fields.validityExpiresIn': 'Wygasa za',
    'fields.abbrDays': 'd',
    'fields.invoicingAddress': 'Adres rozliczeniowy',
    'fields.defaultInvoicingAddress': 'Domyślna adres rozliczeniowy',
    'fields.deliveryAddress': 'Adres pocztowy',
    'fields.contacts': 'Kontakty',
    'fields.invoicingSettings': 'Ustawienia płatności',
    'fields.defaultDeadline': 'Domyślna dojrzałość [dni]',
    'fields.invoicingEmail': 'E-mail rozliczeniowy',
    'fields.invoicingNote': 'Uwaga do fakturowania',
    'fields.limitsAndBlocking': 'Limity i bloki',
    'fields.limitUnpaidInvoices': 'Limit na niezapłacone wierzytelności [CZK]',
    'fields.limitOverdueInvoices': 'Limit na należności przeterminowane [CZK]',
    'fields.bookingRequired': 'Wymagana jest rezerwacja do załadunku / rozładunku',
    'fields.bookingURL': 'Adres URL rezerwacji',
    'fields.bookingNote': 'Uwaga do rezerwacji',
    'fields.history': 'Historia',
    'fields.loginLog': 'Dziennik logowania',
    'fields.documents': 'Dokumenty',
    'fields.events': 'Wydarzenia',
    'fields.timesheets': 'Arkusze',
    'fields.importHistory': 'Historia importu',
    'fields.fuelings': 'Tankowanie',
    'fields.noItemsFound': 'Nie znaleziono żadnych przedmiotów',
    'fields.addresses': 'Adresy',
    'fields.shippingOrder': 'Zamówienie wysyłkowe',
    'fields.client': 'Klient',
    'fields.vehicleRequirements': 'Warunki przewozu / wymagania dotyczące pojazdu',
    'fields.temperatureRecorder': 'Rejestrator temperatury',
    'fields.temperatureControl': 'Naczepa z regulowaną temperaturą',
    'fields.frcCooling': 'Chłodzenie ATP/FRC -20°C',
    'fields.dividingWall': 'Ściana działowa',
    'fields.doubleDeckerTrailer': 'Naczepa dwupiętrowa',
    'fields.curtain': 'Ogon',
    'fields.vehicleAndDriver': 'Pojazd / kierowca',
    'fields.vehiclePhone': 'Telefon w pojeździe',
    'fields.loadingPlaces': 'Miejsca załadunku',
    'fields.customHouse': 'Duchowni',
    'fields.allowedParking': 'Parking dozwolony',
    'fields.unloadingPlaces': 'Miejsca rozładunku',
    'fields.shippingPrice': 'Wysyłka',
    'fields.shippingPriceInclToll': 'Wysyłka w cenie opłata drogowa',
    'fields.boatBooking': 'Rezerwacja łodzi',
    'fields.hotline': 'Hotline',
    'fields.attachedDocuments': 'Załączone dokumenty',
    'fields.otherArrangements': 'Kolejny układ',
    'fields.bankAccounts': 'Konta bankowe',
    'fields.bankAccount': 'Konto bankowe',
    'fields.bankAccountNumber': 'Numer konta',
    'fields.bankAccountIBAN': 'IBAN',
    'fields.bankAccountSWIFT': 'SWIFT',
    'fields.bankCode': 'Kod banku',
    'fields.foundAddressesTitle': 'Znaleziono adresy',
    'fields.foundAddressesText':
        'Znaleźliśmy adresy poniżej w pobliżu. Kliknij, aby wybrać ten, którego chcesz użyć. Jeśli chcesz zapisać nowy adres, kliknij przycisk poniżej.',
    'fields.foundAddressesTextNoSave': 'Znaleźliśmy adresy poniżej w pobliżu. Kliknij, aby wybrać ten, którego chcesz użyć.',
    'fields.dropzoneReady': 'Przeciągnij pliki tutaj lub kliknij, aby wybrać pliki do przesłania',
    'fields.dropzoneAccept': 'Przeciągnij pliki tutaj',
    'fields.fileType': 'Typ pliku',
    'fields.fileName': 'Nazwa pliku',
    'fields.ImportFileName': 'Nazwa pliku importu',
    'fields.ImportDateTime': 'Data i godzina importu',
    'fields.fileSize': 'Rozmiar',
    'fields.noFilesUploaded': 'Nie przesłano jeszcze żadnych plików.',
    'fields.show': 'Wyświetl',
    'fields.monthsAbbr': 'mies.',
    'fields.defaultValidity': 'Domyślna ważność',
    'fields.defaultValidityInMonths': 'Domyślna ważność [mies.]',
    'fields.defaultLifetime': 'Domyślna życie',
    'fields.defaultLifetimeInMonths': 'Domyślna życie [mies.]',
    'fields.equipment': 'Wyposażenie',
    'fields.passedAt': 'Wysłane',
    'fields.lifetimeTo': 'Żywotność do',
    'fields.showChangesFrom': 'Pokaż zmiany z',
    'fields.showOnlyChangedTransports': 'Pokaż tylko transport ze zmianą',
    'fields.cost': 'Koszt',
    'fields.costType': 'Rodzaj koszt',
    'fields.costs': 'Koszty',
    'fields.profit': 'Zysk',
    'fields.profitPerc': 'Zysk %',
    'fields.plannedProfit': 'Planowany zysk',
    'fields.period': 'Okres',
    'fields.numberings': 'Seria numeryczna',
    'fields.format': 'Format',
    'fields.prefix': 'Prefiks',
    'fields.separator': 'Dzielnik',
    'fields.timeFormat': 'Format czasu',
    'fields.dateFormat': 'Format daty',
    'fields.yearFormat': 'Format roku',
    'fields.showMonthNumber': 'Użyj numeru miesiąca',
    'fields.maxRange': 'Maksymalny zasięg',
    'fields.defaultCurrency': 'Domyślna waluta',
    'fields.value': 'Wartość',
    'fields.invoicingState': 'Stan fakturowania',
    'fields.changeTo': 'Zmień na',
    'fields.supplier': 'Dostawca',
    'fields.numbering': 'Numeracja',
    'fields.paymentMethod': 'Metoda płatności',
    'fields.dateIssue': 'Data wydania',
    'fields.imported': 'Importowany',
    'fields.importedAlert': 'Uwaga, ta faktura została już zaimportowana. Wszelkie zmiany nie zostaną przeniesione.',
    'fields.dateDue': 'Termin płatności',
    'fields.dateTax': 'Data podatku',
    'fields.taxTransactionType': 'Typ transakcji podatkowej',
    'fields.taxRateType': 'Typ tawki podatkowe',
    'fields.varSymbol': 'Zmienny symbol',
    'fields.unit': 'JM',
    'fields.item': 'Pozycja',
    'fields.unitPrice': 'Cena JM',
    'fields.totalExclVat': 'Razem bez VAT',
    'fields.totalInclVat': 'Razem z VAT',
    'fields.vat': 'VAT',
    'fields.invoiceNo': 'Faktury nr.',
    'fields.invoicingItems': 'Naliczamy następujące rzeczy',
    'fields.dietRate': 'Oceniać',
    'fields.taxRate': 'Wysokość podatku',
    'fields.noTaxRate': 'Brak wysokość podatku',
    'fields.base': 'Baza',
    'fields.totalToPay': 'Łącznie do zapłaty',
    'fields.rate': 'Oceniać',
    'fields.forDay': 'na dzień',
    'fields.createdBy': 'Stworzone przez',
    'fields.paid': 'Płatne',
    'fields.noGoodsCreated': 'Żaden element ładunku nie został jeszcze utworzony.',
    'fields.totalWeight': 'Całkowita waga',
    'fields.totalVolume': 'Całkowita objętość',
    'fields.packagingType': 'Rodzaj opakowania',
    'fields.transportIsNotDivided': 'Wysyłka nie jest jeszcze podzielona na więcej części',
    'fields.ownTransport': 'Własna przesyłka',
    'fields.transportGoods': 'Towar transportowany',
    'fields.paymentConditions': 'Dojrzałość',
    'fields.transportVehicleType': 'Rodzaj środków transportu',
    'fields.boatNumber': 'Numer statku',
    'fields.trainNumber': 'Numer pociągu',
    'fields.flightNumber': 'Numer lotu',
    'fields.description': 'Opis',
    'fields.type': 'Wpisz',
    'fields.general': 'Ogólne',
    'fields.conditions': 'Warunki',
    'fields.transportConditions': 'Warunki dostawy',
    'fields.invoiceItemType': 'Typ ruchu',
    'fields.suppliersNumber': 'Numer dostawcy',
    'fields.dateReceived': 'Data otrzymania',
    'fields.datePaid': 'Data płatności',
    'fields.approved': 'Zatwierdzony',
    'fields.notApproved': 'Niezatwierdzony',
    'fields.approve': 'Zatwierdź',
    'fields.unpaid': 'Nieopłacone',
    'fields.overdue': 'Zaległy',
    'fields.setAsPaid': 'Oznacz jako płatny',
    'fields.setAsUnpaid': 'Anuluj płatność',
    'fields.creditNote': 'Nota kredytowa',
    'fields.creditNoteNo': 'Numer kredytu',
    'fields.forInvoiceNo': 'Dla faktury nr.',
    'fields.inEU': 'In EU',
    'fields.files': 'Pliki',
    'fields.preloading': 'Wstępne kondycjonowanie',
    'fields.vehicleCount': 'Liczba pojazdów',
    'fields.transportCount': 'Numer przesyłki',
    'fields.businessManager': 'Business manager',
    'fields.costUser': 'Koszt na pracownika',
    'fields.position': 'Pozycje',
    'fields.transportPlan': 'Plan transport',
    'fields.speditionPlan': 'Plan spedycji',
    'fields.margin': 'Marża',
    'fields.offer': 'Oferta',
    'fields.order': 'Zamówienie',
    'fields.orderConfirm': 'Potwierdzenie zamówienia',
    'fields.sendToEmail': 'Wyślij przez e-mail',
    'fields.correctEventTimes': 'Automatyczna korekta czasu',
    'fields.finishQuickly': 'Zakończ szybko',
    'fields.allowances': 'Diety i nagrody',
    'fields.createAllowance': 'Dodaj nagrodę',
    'fields.allowanceAmount': 'Kwota nagrody',
    'fields.noAllowancesFound': 'Nie znaleziono nagród',
    'fields.allowanceType': 'Rodzaj nagrody',
    'fields.allowanceCalculationMethod': 'Metoda obliczeniowa',
    'fields.actions': 'Akcja',
    'fields.differentInMinutes': 'Różnica [min.]',
    'fields.saveThisEvent': 'Utwórz to wydarzenie',
    'fields.transportDescription': 'Opis transportu',
    'fields.noTransportsFound': 'Nie znaleziono przesyłek',
    'fields.vatPayer': 'Płatnik VAT',
    'fields.factoring': 'Factoring',
    'fields.factoringCompanyName': 'Factoring: Nazwa firmy',
    'fields.factoringCompanyRegNumber': 'Factoring: REGON firmy',
    'fields.email_invoicing': 'Fakturowanie e-mailem',
    'fields.doNotInvoice': 'Nie wystawiaj faktur',
    'fields.defaultBankAccountsSettings': 'Ustaw konta domyślne',
    'fields.defaultCompanyBankAccount': 'Domyślne firmowe konto bankowe',
    'fields.defaultInvoicingBankAccounts': 'Domyślne konta bankowe do fakturowania',
    'fields.defaultSupplier': 'Dostawca domyślny',
    'fields.domesticCustomers': 'Klienci krajowi',
    'fields.domesticDietRate': 'Stawka krajowa',
    'fields.foreignCountryCustomers': 'Klienci zagraniczni',
    'fields.paidAt': 'Data płatności',
    'fields.paidAmount': 'Kwota zapłacona',
    'fields.scoringNoQuestions': 'Brak pytań do oceny.',
    'fields.scoringNoAnswers': 'To pytanie nie ma odpowiedzi.',
    'fields.scoringNoRules': 'Brak zasad klasyfikacji.',
    'fields.question': 'Pytanie',
    'fields.answer': 'Odpowiedź',
    'fields.rule': 'Zasada',
    'fields.pointsCount': 'Punkty',
    'fields.pointsCountFrom': 'Punkty od',
    'fields.pointsCountTo': 'Punkty do',
    'fields.mark': 'Ocena',
    'fields.text': 'Text',
    'fields.createQuestion': 'Dodaj pytanie',
    'fields.createAnswer': 'Dodaj odpowiedź',
    'fields.createRule': 'Dodaj regułę',
    'fields.scoringNotSetYet': 'Ocena nie została jeszcze przeprowadzona',
    'fields.scoring': 'Ocena',
    'fields.calculatedFromTransportScoring': 'Obliczone na podstawie oceny transportu',
    'fields.outgoingInvoices': 'Faktury wychodzące',
    'fields.receivedInvoices': 'Otrzymane faktury',
    'fields.invoiced': 'Zafakturowane',
    'fields.uninvoiced': 'Bez faktury',
    'fields.showSpeditions': 'Zobacz w tym przekazywanie',
    'fields.showTrailersOut': 'Pokaż odłączone naczepy',
    'fields.sales': 'Przychody',
    'fields.plannedSales': 'Planowana sprzedaż',
    'fields.minimum': 'Minimum',
    'fields.optimum': 'Optymalny',
    'fields.transport_plans': 'Plany transportowe',
    'fields.transports': 'Transport',
    'fields.speditions': 'Wysyłka',
    'fields.dateFrom': 'Od',
    'fields.dateTo': 'Do',
    'fields.borderCrossing': 'Przejście graniczne',
    'fields.activity': 'Czynność',
    'fields.dailyCost': 'Koszt dzienny',
    'fields.paidRoutes': 'Płatne sekcje',
    'fields.telematicsDataSource': 'Źródło danych telematycznych',
    'fields.telematicsDataId': 'ID danych telematycznych',
    'fields.logisticDataSource': 'Źródło danych logistycznych',
    'fields.logisticDataId': 'ID danych logistycznych ',
    'fields.notificationSettings': 'Ustawienia powiadomień',
    'fields.itemFetching': 'Ładowanie',
    'fields.notifications_use_system': 'Użyj powiadomień systemowych do wyświetlania',
    'fields.notifications_general': 'Powiadomienia ogólne',
    'fields.notifications_transports_load_arrived': 'Transporty - Przyjazd do załadunku (czas, numer transportu, pojazd, kierowca, miasto)',
    'fields.notifications_transports_load_wait': 'Transporty - Oczekiwanie na załadunek (+ powód oczekiwania)',
    'fields.notifications_transports_load_finished': 'Transport - koniec załadunku',
    'fields.notifications_transports_unload_arrival': 'Transport - przyjazd do rozładunku',
    'fields.notifications_transports_unload_wait': 'Transport - Oczekiwanie na rozładunek',
    'fields.notifications_transports_unload_finished': 'Transport - koniec rozładunku',
    'fields.notifications_transports_state_changed_processing': 'Transport - Zmień status na: Przetwarzanie (nr przesyłki, Czas)',
    'fields.notifications_transports_state_changed_delivered': 'Transport - Zmień status na: Zrealizowane',
    'fields.notifications_transports_state_changed_canceled': 'Transport - Zmień status na: Anulowane',
    'fields.notifications_transports_approval': 'Transport - Wniosek o zatwierdzenie',
    'fields.notifications_transporeon_offers': 'Cytaty Transporeon - wstaw nowy cytat',
    'fields.showMarkers': 'Widok trasy - Pokaż inne pojazdy',
    'fields.notifications_vehicle_message': 'Czat',
    'fields.cardNumber': 'Numer karty',
    'fields.invoicingDistance': 'Zafakturowane km',
    'fields.repeat': 'Powtarzać',
    'fields.person': 'Osoba',
    'fields.showFuturePayments': 'Pokaż również przyszłe płatności',
    'fields.yearOfManufacture': 'Rok produkcji',
    'fields.soldAt': 'Data wyprzedaży',

    // Buttons
    'buttons.signIn': 'Zaloguj się',
    'buttons.find': 'Znajdź',
    'buttons.createItem': 'Utwórz przedmiot',
    'buttons.details': 'Szczegóły',
    'buttons.showDetail': 'Pokaż szczegóły',
    'buttons.edit': 'Edytuj',
    'buttons.openEdit': 'Otwórz / edytuj',
    'buttons.copy': 'Kopiuj',
    'buttons.smsCopy': 'Skopiuj do schowka',
    'buttons.createTemplate': 'Utwórz szablon',
    'buttons.delete': 'Usuń',
    'buttons.restore': 'Przywróć',
    'buttons.approve': 'Zatwierdź',
    'buttons.log': 'Pokaż log',
    'buttons.findVehicle': 'Znajdź pojazd',
    'buttons.back': 'Powrót',
    'buttons.next': 'Kolejny',
    'buttons.cancel': 'Anuluj',
    'buttons.save': 'Zapisać',
    'buttons.saveAndClose': 'Zapisz i zamknij',
    'buttons.done': 'Zrobione',
    'buttons.createTransportPoint': 'Utwórz punkt transportowy',
    'buttons.sendToVehicle': 'Wyślij do pojazdu',
    'buttons.deleteFromVehicle': 'Usuń z pojazdu',
    'buttons.createEvent': 'Utwórz wydarzenie',
    'buttons.editEvent': 'Edytuj wydarzenie',
    'buttons.changeState': 'Zmień stan',
    'buttons.showEvents': 'Pokaż wydarzenia',
    'buttons.hideEvents': 'Ukryj wydarzenia',
    'buttons.showNaviEvents': 'Pokaż wydarzenia nawigacyjne',
    'buttons.hideNaviEvents': 'Ukryj wydarzenia nawigacyjne',
    'buttons.findAddress': 'Znajdź adres',
    'buttons.saveAddress': 'Zapisz adres',
    'buttons.addRow': 'Dodaj wiersz',
    'buttons.createTransports': 'Twórz transporty',
    'buttons.createTransport': 'Twórz transport',
    'buttons.accept': 'Akceptuję',
    'buttons.reject': 'Odrzuć',
    'buttons.exportToXLS': 'Eksportuj do XLS',
    'buttons.exportDetailsToXLS': 'Eksportuj szczegóły do XLS',
    'buttons.refresh': 'Przeładować',
    'buttons.filter': 'Filtr',
    'buttons.resetFilters': 'Zresetuj filtry',
    'buttons.acceptSelected': 'Zaakceptuj wybrane',
    'buttons.rejectSelected': 'Odrzuć wybrane',
    'buttons.recountRoute': 'Ponownie oblicz trasę',
    'buttons.addRoute': 'Dodaj trasę',
    'buttons.clearRoute': 'Usuń trasę',
    'buttons.showGpsPositions': 'Zobacz pozycje GPS',
    'buttons.hideGpsPositions': 'Ukryj pozycje GPS',
    'buttons.addCustomer': 'Dodaj klienta',
    'buttons.downloadOrder': 'Pobierz zamówienie',
    'buttons.saveAndDownload': 'Zapisz i pobierz',
    'buttons.saveAsNewAddress': 'Zapisz jako nowy adres',
    'buttons.createInvoice': 'Wystaw fakturę',
    'buttons.setAsInvoiced': 'Oznacz jako zafakturowane',
    'buttons.addItem': 'Dodaj przedmiot',
    'buttons.divideTransportToParts': 'Podziel transport na części',
    'buttons.addTransportPart': 'Dodaj kolejną sekcję',
    'buttons.createCreditNote': 'Utwórz notatkę kredytową',
    'buttons.exportTo': 'Eksportuj do',
    'buttons.sendToEmail': 'Wyślij przez e-mail',
    'buttons.download': 'Pobierz',
    'buttons.send': 'Prześlij',
    'buttons.createNote': 'Utwórz notatkę',
    'buttons.reassignAllowancesFromDriverConfig': 'Odzyskaj nagrody z ustawień sterownika',
    'buttons.import': 'Import',
    'buttons.moreInformation': 'Więcej informacji',
    'buttons.clear': 'Kasować',
    'buttons.focusOnMap': 'Skoncentruj się na mapie',
    'buttons.select': 'Wybierać',
    'buttons.selectAll': 'Zaznacz wszystko',
    'buttons.unselectAll': 'Odznacz wszystko',

    // Units
    'units.pcs': 'ks',
    'units.kg': 'kg',

    // Periods
    'period.1': 'Nie powtarzaj',
    'period.2': 'Tydzień',
    'period.3': 'Miesiąc',
    'period.4': 'Jedna czwarta',
    'period.5': 'Pół roku',
    'period.6': 'Rok',

    // Sorting
    'sorting.title': 'Sortuj według',
    'sorting.option.vehicle': 'Pojazdu',
    'sorting.option.waterfall': 'Wodospad',

    // Validators
    'validator.email.required': 'E-mail jest wymagany',
    'validator.password.required': 'Hasło jest wymagane',

    // Alerts
    'alerts.titles.warning': 'Uwaga!',
    'alerts.titles.error': 'Błąd.',
    'alerts.titles.success': 'OK.',

    'alerts.messages.loginFailed': 'Nie możemy się zalogować, sprawdź swój adres e-mail i hasło.',
    'alerts.messages.logoutFailed': 'Nie możemy Cię wylogować, spróbuj ponownie.',
    'alerts.messages.mailSend': 'E-mail wysłany.',
    'alerts.messages.mailSendFiled': 'Nie można wysłać e-maila.',
    'alerts.messages.invalidToken': 'Nieprawidłowy Token.',
    'alerts.messages.passwordDontMatch': 'Hasła nie pasują do siebie.',
    'alerts.messages.passwordChange': 'Hasło zostało pomyślnie zmienione.',
    'alerts.messages.createSuccess': 'Element został pomyślnie utworzony.',
    'alerts.messages.saveSuccess': 'Element został pomyślnie zapisany.',
    'alerts.messages.copySuccess': 'Element został pomyślnie skopiowane.',
    'alerts.messages.deleteSuccess': 'Element został pomyślnie usunięte.',
    'alerts.messages.restoreSuccess': 'Element został pomyślnie przywrócono.',
    'alerts.messages.approveSuccess': 'Element został pomyślnie zatwierdź.',
    'alerts.messages.transportSendToVehicleSuccess': 'Transport został pomyślnie wysłany do pojazdu.',
    'alerts.messages.transportDeleteFromVehicleSuccess': 'Transport został pomyślnie usunięty z pojazdu.',
    'alerts.messages.createFromTemplateSuccess': 'Transport został pomyślnie utworzony z szablonu.',
    'alerts.messages.transporeonOfferAcceptSuccess': 'Pomyślnie przyjęte.',
    'alerts.messages.transporeonOfferRejectSuccess': 'Pomyślnie odrzucono.',
    'alerts.messages.noteSaveSuccess': 'Notatka została pomyślnie zapisana.',
    'alerts.messages.changesSaveSuccess': 'Zmiany zostały zapisane.',
    'alerts.messages.addressSaveSuccess': 'Adres został pomyślnie zapisany.',
    'alerts.messages.routeCalculateSuccess': 'Trasa została pomyślnie ponownie obliczona.',
    'alerts.messages.filesUploadSuccess': 'Pliki zostały pomyślnie przesłane.',
    'alerts.messages.filesDeleteSuccess': 'Plik został pomyślnie usunięty.',
    'alerts.messages.invoiceCreateSuccess': 'Faktura została pomyślnie utworzona.',
    'alerts.messages.exportSuccess': 'Eksport został pomyślnie zakończony.',
    'alerts.messages.transportOrderSendingSuccess': 'Pomyślnie wysłano na podany adres.',
    'alerts.messages.invoiceSendingSuccess': 'Pomyślnie wysłano na podany adres.',
    'alerts.messages.eventTimesCorrectionSuccess': 'Pomyślnie ponownie obliczone.',
    'alerts.messages.transportEventsCreatingSuccess': 'Wydarzenia zostały pomyślnie utworzone.',
    'alerts.messages.duplicityFound': 'Znaleziono duplikat.',
    'alerts.messages.legendNotFound': 'Nie znaleziono legendy.',
    'alerts.messages.code.9000010': 'Twój adres IP jest zablokowany, skontaktuj się z administratorem...',
    'alerts.messages.code.9000020': 'Twoje konto jest zablokowane, skontaktuj się z administratorem...',
    'alerts.messages.fileImportSuccess': 'Plik został pomyślnie zaimportowany.',
    'alerts.messages.mandatoryItems': 'Wymagane są następujące elementy',
    'alerts.messages.calculateRouteNoRouteFound': 'Nie znaleziono trasy.',
    'alerts.messages.customerBlockedNeedApproval': 'Klient jest zablokowany i wymagana jest zgoda.',
    'alerts.messages.warningDeleted': 'Uwaga! Edytujesz usunięty rekord. Jeśli chcesz go przywrócić, kliknij przycisk przywracania.\n',

    // Loader
    'loader.pleaseWait': 'Proszę czekać...',

    // Confirm dialog
    'confirmDialog.default.title': 'Potwierdzenie',
    'confirmDialog.default.message': 'Czy naprawdę chcesz to zrobić?',
    'confirmDialog.default.accept': 'Potwierdzać',
    'confirmDialog.default.cancel': 'Anuluj',

    'confirmDialog.delete.title': 'Usunąć przedmiot?',
    'confirmDialog.delete.message': 'To jest nieodwracalna czynność, czy na pewno chcesz usunąć przedmiot?',
    'confirmDialog.delete.accept': 'Tak, usuń',
    'confirmDialog.delete.cancel': 'Anuluj',

    'confirmDialog.transportDelete.title': 'Usunąć transport?',
    'confirmDialog.transportDelete.message':
        'Czy na pewno chcesz usunąć transport? Jeśli transport został wysłany do pojazdu, zostanie również usunięty z pojazdu.',
    'confirmDialog.transportDelete.accept': 'Tak, usuń',
    'confirmDialog.transportDelete.cancel': 'Anuluj',

    'confirmDialog.transportRestore.title': 'Przywrócić transport?',
    'confirmDialog.transportRestore.message': 'Czy na pewno chcesz przywrócić transport?',
    'confirmDialog.transportRestore.accept': 'Tak, przywróć',
    'confirmDialog.transportRestore.cancel': 'Anuluj',

    'confirmDialog.itemRestore.title': 'Przywrócić element?',
    'confirmDialog.itemRestore.message': 'Czy na pewno chcesz przywrócić element?',
    'confirmDialog.itemRestore.accept': 'Tak, przywróć',
    'confirmDialog.itemRestore.cancel': 'Anuluj',

    'confirmDialog.transportApproval.title': 'Zatwierdzenie transport?',
    'confirmDialog.transportApproval.message': 'Czy na pewno chcesz zatwierdzenie transport?',
    'confirmDialog.transportApproval.accept': 'Tak, zatwierdzenie',
    'confirmDialog.transportApproval.cancel': 'Anuluj',

    'confirmDialog.transportSendToVehicle.title': 'Wysłać transport do pojazdu?',
    'confirmDialog.transportSendToVehicle.message': 'Czy na pewno chcesz wysłać transport do pojazdu?',
    'confirmDialog.transportSendToVehicle.accept': 'Tak, wyślij',
    'confirmDialog.transportSendToVehicle.cancel': 'Anuluj',

    'confirmDialog.transportDeleteFromVehicle.title': 'Usunąć transport z pojazdu?',
    'confirmDialog.transportDeleteFromVehicle.message': 'Czy na pewno chcesz usunąć transport z pojazdu?',
    'confirmDialog.transportDeleteFromVehicle.accept': 'Tak, usuń',
    'confirmDialog.transportDeleteFromVehicle.cancel': 'Anuluj',

    'confirmDialog.transportTemplateDelete.title': 'Usuń szablon?',
    'confirmDialog.transportTemplateDelete.message': 'Czy na pewno chcesz usunąć szablon??',
    'confirmDialog.transportTemplateDelete.accept': 'Tak, usuń',
    'confirmDialog.transportTemplateDelete.cancel': 'Anuluj',

    'confirmDialog.duplicityFound.title': 'Duplikat',
    'confirmDialog.duplicityFound.message': 'Znaleziono ten sam lub bardzo podobny zapis.',
    'confirmDialog.duplicityFound.accept': 'Nadal oszczędzam',
    'confirmDialog.duplicityFound.cancel': 'Anuluj',

    'confirmDialog.emptyMandatoryFields.title': 'Ostrzeżenie',
    'confirmDialog.emptyMandatoryFields.message': 'Niektóre wymagane dane nie są wypełnione.',
    'confirmDialog.emptyMandatoryFields.accept': 'Nadal oszczędzam',
    'confirmDialog.emptyMandatoryFields.cancel': 'Anuluj',

    'confirmDialog.removeCustomer.title': 'Usunąć klienta?',
    'confirmDialog.removeCustomer.message': 'Czy na pewno chcesz usunąć klienta?',
    'confirmDialog.removeCustomer.accept': 'Tak, usuń',
    'confirmDialog.removeCustomer.cancel': 'Anuluj',

    'confirmDialog.deletePoint.title': 'Usunąć punkt trasy?',
    'confirmDialog.deletePoint.message': 'Czy na pewno chcesz usunąć punkt trasy?',
    'confirmDialog.deletePoint.accept': 'Tak, usuń',
    'confirmDialog.deletePoint.cancel': 'Anuluj',

    'confirmDialog.deleteEvent.title': 'Usunąć wydarzenie?',
    'confirmDialog.deleteEvent.message': 'Czy na pewno chcesz usunąć wydarzenie?',
    'confirmDialog.deleteEvent.accept': 'Tak, usuń',
    'confirmDialog.deleteEvent.cancel': 'Anuluj',

    'confirmDialog.addressNotFoundCreateNew.title': 'Nie znaleziono adresu',
    'confirmDialog.addressNotFoundCreateNew.message': 'Nie znaleźliśmy zapisanego adresu w pobliżu tej lokalizacji. Czy chcesz go stworzyć?',
    'confirmDialog.addressNotFoundCreateNew.accept': 'Tak, utwórz nowy adres',
    'confirmDialog.addressNotFoundCreateNew.cancel': 'Anuluj',

    'confirmDialog.approve.title': 'Zatwierdź przedmiot?',
    'confirmDialog.approve.message': 'Naprawdę chcesz zatwierdzić przedmiot?',
    'confirmDialog.approve.accept': 'Tak, zatwierdź',
    'confirmDialog.approve.cancel': 'Anuluj',

    'confirmDialog.setAsUnpaid.title': 'Anuluj płatność?',
    'confirmDialog.setAsUnpaid.message': 'Czy na pewno chcesz anulować płatność?',
    'confirmDialog.setAsUnpaid.accept': 'Tak, anuluj płatność',
    'confirmDialog.setAsUnpaid.cancel': 'Anuluj',

    'confirmDialog.correctEventTimes.title': 'Automatycznie edytować lub tworzyć wydarzenia?',
    'confirmDialog.correctEventTimes.message':
        'Ta funkcja automatycznie dostosowuje czasy zdarzeń, takie jak przybycie lub odlot z załadunku w zależności od pozycji GPS pojazdu, a następnie ponownie oblicza aktualną trasę. Oryginalne wartości zostaną zastąpione. Czy naprawdę chcesz kontynuować?', // eslint-disable-line
    'confirmDialog.correctEventTimes.accept': 'Tak, kontynuuj',
    'confirmDialog.correctEventTimes.cancel': 'Anuluj',

    'confirmDialog.reAssignAllowances.title': 'Przeładuj z szablonu?',
    'confirmDialog.reAssignAllowances.message': 'Czy na pewno chcesz ponownie załadować nagrody szablonowe? Istniejące rekordy zostaną zastąpione.',
    'confirmDialog.reAssignDietAllowances.message': 'Czy na pewno chcesz ponownie uzyskać limit czasu w ustawieniach? Istniejące zapisy zostaną zastąpione.',
    'confirmDialog.reAssignAllowances.accept': 'Tak, załaduj',
    'confirmDialog.reAssignAllowances.cancel': 'Anuluj',

    'confirmDialog.updateCostGonerator.title': 'Edytować tylko ten koszt?',
    'confirmDialog.updateCostGonerator.message': 'Chcesz dostosować tylko ten koszt lub następujące koszty.',
    'confirmDialog.updateCostGonerator.onlyThis': 'Tylko ten koszt',
    'confirmDialog.updateCostGonerator.thisAndFuture': 'Ten koszt i przyszłość',

    'confirmDialog.deleteCostGonerator.title': 'Usunąć tylko ten koszt?',
    'confirmDialog.deleteCostGonerator.message': 'Chcesz usunąć tylko ten koszt lub nawet następujące koszty.',
    'confirmDialog.deleteCostGonerator.onlyThis': 'Tylko ten koszt',
    'confirmDialog.deleteCostGonerator.thisAndFuture': 'Ten koszt i przyszłość',

    // Pagination
    'pagination.totalRecords': 'Suma zapisów',
    'pagination.previous': 'Poprzedni',
    'pagination.next': 'Dalej',

    // Transport states
    'transportStates.notApproved': 'Niezatwierdzony',
    'transportStates.preOrder': 'Przed sprzedaż',
    'transportStates.order': 'Zamówienie',
    'transportStates.planned': 'Zaplanowany',
    'transportStates.sent': 'Wysłane',
    'transportStates.processing': 'Przetwarzanie',
    'transportStates.finished': 'Skończone',
    'transportStates.canceled': 'Anulowany',

    // Days
    'dayNames.0': 'Niedziela',
    'dayNames.1': 'Poniedziałek',
    'dayNames.2': 'Wtorek',
    'dayNames.3': 'Środa',
    'dayNames.4': 'Czwartek',
    'dayNames.5': 'Piątek',
    'dayNames.6': 'Sobota',

    // Months
    'monthNames.01': 'Styczeń',
    'monthNames.02': 'Luty',
    'monthNames.03': 'Marsz',
    'monthNames.04': 'April',
    'monthNames.05': 'Kwiecień',
    'monthNames.06': 'Czerwiec',
    'monthNames.07': 'Lipiec',
    'monthNames.08': 'Sierpień',
    'monthNames.09': 'Wrzesień',
    'monthNames.10': 'Październik',
    'monthNames.11': 'Listopad',
    'monthNames.12': 'Grudzień',

    // Vehicle data source
    'vehicleDataSource.1': 'FB',

    // Transport point types
    'pointTypes.loading': 'Załadunek',
    'pointTypes.loadingCreate': 'Utwórz załadunek',
    'pointTypes.loadingEdit': 'Edytuj załadunek',
    'pointTypes.unloading': 'Rozładunek',
    'pointTypes.unloadingCreate': 'Utwórz rozładunek',
    'pointTypes.unloadingEdit': 'Edytuj rozładunek',
    'pointTypes.waypoint': 'Punkt tranzytowy',
    'pointTypes.waypointCreate': 'Utwórz punkt tranzytowy',
    'pointTypes.waypointEdit': 'Edytuj punkt tranzytowy',
    'pointTypes.service': 'Serwis',
    'pointTypes.serviceCreate': 'Utwórz serwis',
    'pointTypes.serviceEdit': 'Edytuj serwis',
    'pointTypes.fueling': 'Tankowanie',
    'pointTypes.fuelingCreate': 'Utwórz tankowanie',
    'pointTypes.fuelingEdit': 'Edytuj tankowanie',
    'pointTypes.parking': 'Parkowanie',
    'pointTypes.parkingCreate': 'Utwórz parkowanie',
    'pointTypes.parkingEdit': 'Edytuj parkowanie',
    'pointTypes.parkingWithChange': 'Parkowanie ze zmianą',
    'pointTypes.parkingWithChangeCreate': 'Utwórz parkowanie ze zmianą',
    'pointTypes.parkingWithChangeEdit': 'Edytuj parkowanie ze zmianą',
    'pointTypes.trailerChange': 'Przepinanie',
    'pointTypes.trailerChangeCreate': 'Utwórz przepinanie',
    'pointTypes.trailerChangeEdit': 'Edytuj przepinanie',
    'pointTypes.trailerCatch': 'Podpinanie',
    'pointTypes.trailerCatchCreate': 'Utwórz podpinanie',
    'pointTypes.trailerCatchEdit': 'Edytuj podpinanie',
    'pointTypes.trailerOut': 'Odpinanie',
    'pointTypes.trailerOutCreate': 'Utwórz odpinanie',
    'pointTypes.trailerOutEdit': 'Edytuj odpinanie',
    'pointTypes.move': 'Przejazd',
    'pointTypes.moveCreate': 'Utwórz przejazd',
    'pointTypes.moveEdit': 'Edytuj przejazd',
    'pointTypes.customHouse': 'Urząd celny',
    'pointTypes.customHouseCreate': 'Utwórz urząd celny',
    'pointTypes.customHouseEdit': 'Edytuj urząd celny',
    'pointTypes.vehicleQuit': 'Parkowanie pojazdu',
    'pointTypes.vehicleQuitCreate': 'Utwórz parkowanie pojazdu',
    'pointTypes.vehicleQuitEdit': 'Edytuj parkowanie pojazdu',

    // Transport point states
    'transportPointStates.onWay': 'Na drodze',
    'transportPointStates.arrived': 'Przybył',
    'transportPointStates.waiting': 'Przybył / czekanie',
    'transportPointStates.processing': 'Przybył / przetwarzanie',
    'transportPointStates.done': 'Przybył / zrobione',
    'transportPointStates.finished': 'Skończone',

    // Transport event types
    'transportEvents.predepartureCheck': 'Sprawdź przed odjazdem',
    'transportEvents.loadingArrival': 'Załadunek przyjazd',
    'transportEvents.systemCheck': 'Sprawdź systemów',
    'transportEvents.loadingWaiting': 'Oczekiwanie na załadowanie',
    'transportEvents.loadingStart': 'Rozpoczęcie załadunku',
    'transportEvents.loadingEnd': 'Koniec załadunku',
    'transportEvents.loadingLoaded': 'Ilość sztuk załadowanego towaru / wyjazd',
    'transportEvents.unloadingArrival': 'Rozładunek przyjazd',
    'transportEvents.unloadingWaiting': 'Oczekiwanie na rozładowanie',
    'transportEvents.unloadingStart': 'Rozpoczęcie rozładunku',
    'transportEvents.unloadingEnd': 'Koniec rozładunku',
    'transportEvents.unloadingUnloaded': 'Ilość sztuk rozładowanego towaru / wyjazd',
    'transportEvents.waypointEnd': 'Odjazd',
    'transportEvents.fuelingArrival': 'Przyjazd na tankowanie',
    'transportEvents.fuelingDeparture': 'Odjazd z tankowanie',
    'transportEvents.serviceArrival': 'Przyjazd na serwis',
    'transportEvents.serviceDeparture': 'Odjazd ze serwisu',
    'transportEvents.parkingArrival': 'Przyjazd na parkowanie',
    'transportEvents.parkingCheckBefore': 'Sprawdź przed parkowaniem',
    'transportEvents.parkingTime': 'Wprowadź czas parkowania',
    'transportEvents.parkingCheckAfter': 'Sprawdź po parkowaniu',
    'transportEvents.parkingDeparture': 'Parking departure',
    'transportEvents.trailerChangeArrival': 'Przyjazd na przepinanie',
    'transportEvents.trailerChangeCheck': 'Sprawdź naczepy po przeglądzie',
    'transportEvents.trailerChangeDeparture': 'Odjazd z przepinki',
    'transportEvents.trailerCatchArrival': 'Przyjazd na podpinanie',
    'transportEvents.trailerCatchCheck': 'Sprawdź naczepy po podpinanie',
    'transportEvents.trailerCatchDeparture': 'Odjazd z podpięcia',
    'transportEvents.trailerOutArrival': 'Przyjazd na odpinanie',
    'transportEvents.trailerOutCheck': 'Sprawdź naczepy po odpinanie',
    'transportEvents.trailerOutDeparture': 'Odjazd z odpięcia',
    'transportEvents.parkingWithChangeArrival': 'Przyjazd na parkowanie',
    'transportEvents.parkingWithChangeQuitCheck': 'Sprawdź przed parkowaniem ze zmianą',
    'transportEvents.parkingWithChangeCheck': 'Sprawdź po parkowaniu ze zmianą',
    'transportEvents.moveEnd': 'Odjazd',
    'transportEvents.customHouseArrival': 'Przyjazd na urząd celny',
    'transportEvents.customHouseDeparture': 'Odjazd z urząd celny',
    'transportEvents.vehicleQuitCheck': 'Sprawdź przed opuszczeniem pojazdu',
    'transportEvents.vehicleQuitFinish': 'Zakończenie parkowanie pojazdu',

    // Transport log changes
    'transportLogChanges.transportCreated': 'Transport stworzony',
    'transportLogChanges.transportCreatedByCopying': 'Transport utworzony przez kopiowanie',
    'transportLogChanges.transportCreatedFromTemplate': 'Transport utworzony z szablonu',
    'transportLogChanges.transportCreatedFromTransporeon': 'Transport stworzony z oferty Transporeon',
    'transportLogChanges.transportUpdated': 'Transport zaktualizowany',
    'transportLogChanges.transportDeleted': 'Transport został usunięty',
    'transportLogChanges.transportRestored': 'Przywrócono transport',
    'transportLogChanges.transportSentToVehicle': 'Transport wysłany do pojazdu',
    'transportLogChanges.transportUpdatedInVehicle': 'Transport został zaktualizowany do pojazdu',
    'transportLogChanges.transportDeletedFromVehicle': 'Transport usunięty z pojazdu',
    'transportLogChanges.transportPointCreated': 'Utworzono punkt',
    'transportLogChanges.transportPointUpdated': 'Punkt zaktualizowany',
    'transportLogChanges.transportPointDeleted': 'Punkt usunięty',
    'transportLogChanges.transportPointRestored': 'Punkt przywrócono',
    'transportLogChanges.transportEventCreated': 'Utworzono wydarzenie',
    'transportLogChanges.transportEventUpdated': 'Wydarzenie zostało zaktualizowane',
    'transportLogChanges.transportEventDeleted': 'Wydarzenie zostało usunięte',

    // Vehicle events
    'vehicleEvents.predepartureCheck': 'Kontrola przed odjazdem',
    'vehicleEvents.fueling': 'Tankowanie',
    'vehicleEvents.service': 'Serwis',
    'vehicleEvents.parking': 'Parkowanie',
    'vehicleEvents.parkingWithChange': 'Parkowanie ze zmianą',
    'vehicleEvents.accident': 'Wypadek',
    'vehicleEvents.trailerCatch': 'Podpinanie',
    'vehicleEvents.trailerOut': 'Odpinanie',
    'vehicleEvents.loading': 'Załadunek',
    'vehicleEvents.unloading': 'Rozładunek',
    'vehicleEvents.move': 'Przejazd',
    'vehicleEvents.customHouse': 'Urząd celny',
    'vehicleEvents.vehicleQuit': 'Parkowanie pojazdu',

    // Vehicle trip record type
    'vehicleTripRecordType.dataCompletion': 'Uzupełnienie danych ',
    'vehicleTripRecordType.fueling': 'Tankowanie',
    'vehicleTripRecordType.0': 'Pauza',
    'vehicleTripRecordType.1': 'Napęd',
    'vehicleTripRecordType.2': 'Przejście graniczne',

    // Invoicing states
    'invoicingStates.0': 'Brak faktury',
    'invoicingStates.1': 'Gotowy',
    'invoicingStates.2': 'Zafakturowane',

    // Tax transaction types
    'taxTransactionTypes.1': 'Krajowy',
    'taxTransactionTypes.2': 'EU (reverse charge)',
    'taxTransactionTypes.3': 'Inny',

    // Text before items types
    'textBeforeItemsTypes.1': 'Wystawiamy fakturę za następujące elementy:',
    'textBeforeItemsTypes.2': 'Wystawiamy faktury za następujące transporty:',

    // Tax rate types
    'taxRateTypes.1': 'Podstawowy',
    'taxRateTypes.2': 'Pierwsza obniżona',
    'taxRateTypes.3': 'Drugi zmniejszony',
    'taxRateTypes.4': 'Zero',

    // Transport vehicle types
    'transportVehicleTypes.vehicle': 'Pojazd',
    'transportVehicleTypes.boat': 'Statek',
    'transportVehicleTypes.train': 'Pociąg',
    'transportVehicleTypes.plane': 'Samolot',
    'transportVehicleTypes.other': 'Inne',

    // Company numbering types
    'companyNumberingTypes.1': 'Wystawione faktury',
    'companyNumberingTypes.2': 'Otrzymane faktury',
    'companyNumberingTypes.3': 'Wydane noty kredytowe',
    'companyNumberingTypes.4': 'Otrzymane noty kredytowe',

    // Driver timesheet interval types
    'driverTimesheetIntervalTypes.interval': 'Okres czasu',
    'driverTimesheetIntervalTypes.time': 'Liczba godzin i minut',

    // Driver timesheet types
    'driverTimesheetTypes.pause': 'Stand',
    'driverTimesheetTypes.drive': 'Jedź',
    'driverTimesheetTypes.other_job': 'Inne prace',
    'driverTimesheetTypes.holiday': 'Wakacje',
    'driverTimesheetTypes.vacation': 'Płatny urlop',
    'driverTimesheetTypes.unpaid_vacation': 'Urlop bezpłatny',
    'driverTimesheetTypes.turnus_vacation': 'Wyłącz',
    'driverTimesheetTypes.disease': 'Choroba',
    'driverTimesheetTypes.doctor': 'Doctor',
    'driverTimesheetTypes.nursing': 'Nursing',
    'driverTimesheetTypes.absence': 'Absence',
    'driverTimesheetTypes.use_overtime': 'Nadgodziny',

    // Transports
    'modules.transports.heading': 'Transporty',
    'modules.transports.headingArchive': 'Transporty - archiwum',
    'modules.transports.headingTrashed': 'Transporty - usunięte',
    'modules.transports.headingNew': 'Nowy transport',
    'modules.transports.headingEdit': 'Transport nr.',
    'modules.transports.showOnlyCombined': 'Pokaż tylko połączone',
    'modules.transports.showWithSpeditions': 'Zobacz w tym przekazywanie',
    'modules.transports.showOnlySpeditions': 'Pokaż tylko przesyłki spedycyjne',
    'modules.transports.showOnlyNotApproved': 'Pokaż tylko odrzucone',
    'modules.transports.showOnlyCabotage': 'Pokaż tylko kabotaż',
    'modules.transports.notSentYet': 'Transport nie został jeszcze wysłany',
    'modules.transports.suspectlyLargeApproach': 'Podejrzane duże podejście',
    'modules.transports.warningDeletedTransport': 'Uwaga! Edytujesz usunięty transport. Jeśli chcesz go przywrócić, kliknij przycisk przywracania.',
    'modules.transports.warningNotApprovalTransport':
        'Uwaga! To jest nieautoryzowana przesyłka. Jeśli chcesz to zatwierdzić, kliknij przycisk Zatwierdź.',
    'modules.transports.pricing': 'Ceny',
    'modules.transports.routeDefinition': 'Definicja trasy',
    'modules.transports.requirePredepartureCheck': 'Wymagana jest kontrola przed wyjazdem',
    'modules.transports.showRoutesOnMap': 'Pokaż trasy na mapie',
    'modules.transports.noVehiclePositionsFound': 'W tym transporcie nie znaleziono pozycji GPS',
    'modules.transports.warningBlockedCustomer': 'Uwaga! Ten klient jest zablokowany.',
    'modules.transports.warningBlockedCarrier': 'Uwaga! Ten przewoźnik jest zablokowany.',
    'modules.transports.files': 'Pliki',
    'modules.transports.goods': 'Definicja obciążenia',
    'modules.transports.parts': 'Transport kombinowany (części)',
    'modules.transports.preloaded': 'Przedstawione',
    'modules.transports.allowances': 'Diety i nagrody',
    'modules.transports.reInitAllowances': 'Załaduj ponownie z szablonu',
    'modules.transports.addAllowances': 'Dodaj dietę/kieszonkowe',
    'modules.transports.addAllowancesSelectType': 'Dieta/kieszonkowe, waluta i rodzaj',
    'modules.transports.noDriversFound': 'Transport nie ma przypisanego kierowcy, nie można wprowadzać diet',
    'modules.transports.allowancesSetDefaultValue': 'Ustaw domyślne',
    'modules.transports.driver': 'Kierowca',
    'modules.transports.noDrivers': 'Nie znaleziono sterowników',
    'modules.transports.scoring': 'Ocena',

    // Transport templates
    'modules.transportTemplates.heading': 'Szablony',
    'modules.transportTemplates.headingNew': 'Nowy szablon',
    'modules.transportTemplates.headingEdit': 'Edytuj szablon',
    'modules.transportTemplates.createTransportFromTemplate': 'Utwórz transporty z szablonu',

    // Transporeon offers
    'modules.transporeonOffers.heading': 'Transporeon nabídky',
    'modules.transporeonOffers.reason.transitTimeTooShort': 'Zbyt krótki czas tranzytu',
    'modules.transporeonOffers.reason.noDriverAvailable': 'On nie jest wolnym kierowcą',
    'modules.transporeonOffers.reason.requestedEquipmentNotAvailable': 'Wymagany sprzęt nie jest dostępny',
    'modules.transporeonOffers.reason.other': 'Kolejny powód',
    'modules.transporeonOffers.accept.title': 'Wziąć ofertę?',
    'modules.transporeonOffers.accept.message': 'Czy na pewno chcesz zaakceptować ofertę?',
    'modules.transporeonOffers.reject.title': 'Odrzucić ofertę?',
    'modules.transporeonOffers.reject.message': 'Czy na pewno chcesz odrzucić ofertę?',

    // Board
    'modules.board.heading': 'Panel dyspozytora',
    'modules.board.vehicleNote': 'Nota pojazdu',
    'modules.board.trailerNote': 'Nota naczepu',
    'modules.board.trailerDamage': 'Uszkodzenie naczepu',
    'modules.board.showFree': 'Pokaż dostępne',
    'modules.board.weekendAtHome': 'Weekend w domu',
    'modules.board.weekendAtWork': 'Weekend w pracy',
    'modules.board.isDone': 'Zrobione (na dzisiaj)',
    'modules.board.isNotDone': 'Nie zrobione (na dzisiaj)',
    'modules.board.updatedInLast2Hours': 'Zaktualizowano w ciągu ostatnich 2 godzin',
    'modules.board.updated2HoursAgo': 'Zaktualizowano ponad 2 godziny temu',
    'modules.board.planningState': 'Status planowania',

    // Map
    'modules.map.heading': 'Mapa',

    // Reports
    'modules.reportTransports.heading': 'Raporty - Transporty - Wyniki ekonomiczne',
    'modules.reportSpeditionTransports.heading': 'Raporty - Transporty - Wyniki ekonomiczne transportu spedycyjnego',
    'modules.reportTransportChanges.heading': 'Raporty - Transporty - Śledzenie zmian',
    'modules.reportTransportInvoicing.heading': 'Raporty - Transporty - Fakturowanie',
    'modules.reportTransportOrders.heading': 'Raporty - Transporty - Zamówienia',
    'modules.reportVehicleKilometersTotal.heading': 'Raporty - Pojazdy - Całkowity przebieg',
    'modules.reportVehiclePerformance.heading': 'Raporty - Pojazdy - Wskaźniki wydajności',
    'modules.reportVehicleTraffic.heading': 'Raporty - Pojazdy - Wskaźniki operacyjne',
    'modules.reportVehicleChecks.heading': 'Raporty - Pojazdy - Czeki i wady',
    'modules.reportVehicleFuelings.heading': 'Raporty - Pojazdy - Tankowanie',
    'modules.reportTrailerFuelings.heading': 'Raporty - Naczepy - Tankowanie',
    'modules.reportDriverPerformanceAnalysis.heading': 'Raporty - Kierowcy - Analiza wydajności',
    'modules.reportDriverHourlyOverview.heading': 'Reporty - Kierowcy - Przegląd godzinowy',
    'modules.reportDriverTimesheet.heading': 'Reporty - Kierowcy - Raport pracy',
    'modules.reportCustomerResults.heading': 'Raporty - Klienci - Obroty',
    'modules.reportCustomerPlans.heading': 'Raporty - Klienci - Plan transportu',
    'modules.reportCustomerMonthlyPlans.heading': 'Raporty - Klienci - Plan miesięczny',
    'modules.reportCustomerWeeklyPlans.heading': 'Raporty - Klienci - Plan tygodniowy',
    'modules.reportCostCenterResults.heading': 'Raporty - Jednostki gospodarcze - Obroty',
    'modules.reportCostCenterTraffic.heading': 'Raporty - Jednostki gospodarcze - Wskaźniki operacyjne',
    'modules.reportCostCenterPerformance.heading': 'Raporty - Jednostki gospodarcze - Wskaźniki wydajności',
    'modules.reportCostCenterCost.heading': 'Raporty - Jednostki gospodarcze - Koszty',
    'modules.reportCarrierResults.heading': 'Raporty - Przewoźnicy spedycyjni - Obroty',
    'modules.reportCarrierScoring.heading': 'Raporty - Przewoźnicy spedycyjni - Ocena',
    'modules.reportCarrierUsage.heading': 'Raporty - Przewoźnicy spedycyjni - Użyj',
    'modules.reportDispatcherKPI.heading': 'Raporty - Dyspozytorzy - KPI',
    'modules.reportAppUsage.heading': 'Raporty - Inny - Korzystanie z aplikacji',
    'modules.reportCostCenterProfits.heading': 'Reporty - Jednostki gospodarcze - Wyniki ekonomiczne',

    'modules.reportCustomerTurnovers.heading': 'Raporty - Klienci',
    'modules.reportEmptyKilometers.heading': 'Raporty - Puste kilometry',
    'modules.reportPallets.heading': 'Raporty - Rejestry palet',

    // Vehicles
    'modules.vehicles.heading': 'Pojazdy',
    'modules.vehicleHistory.heading': 'Pojazdy - Historia',
    'modules.vehicleDocuments.heading': 'Pojazdy - Dokumenty',
    'modules.vehicleEquipments.heading': 'Pojazdy - Wyposażenie',
    'modules.vehicleEvents.heading': 'Wydarzenia',

    // Vehicle fueling
    'modules.reportVehicleFuelings.detail.sumeryHeading': 'Przegląd ogólny',
    'modules.reportVehicleFuelings.detail.tripRecordsHeading': 'Szczegółowy przegląd',
    'modules.reportVehicleFuelings.detail.odometerHeading': 'Przegląd licznika kilometrów',

    // Trailer fueling
    'modules.reportTrailerFuelings.detail.sumeryHeading': 'Przegląd ogólny',
    'modules.reportTrailerFuelings.detail.tripRecordsHeading': 'Szczegółowy przegląd',

    // Trailers
    'modules.trailers.heading': 'Naczepy',
    'modules.trailerHistory.heading': 'Naczepy - Historia',
    'modules.trailerDocuments.heading': 'Naczepy - Dokumenty',
    'modules.trailerEquipments.heading': 'Naczepy - Wyposażenie',

    // Drivers
    'modules.drivers.heading': 'Kierowcy',
    'modules.driverHistory.heading': 'Kierowcy - Historia',
    'modules.driverDocuments.heading': 'Kierowcy - Dokumenty',
    'modules.driverEquipments.heading': 'Kierowcy - Wyposażenie',
    'modules.driverTimesheets.heading': 'Kierowcy - Arkusze',

    // Drivers hourly overview
    'modules.reportDriverHourlyOverview.detail.sumeryHeading': 'Przegląd ogólny',
    'modules.reportDriverHourlyOverview.detail.tripRecordsHeading': 'Szczegółowy przegląd',

    // Customers
    'modules.customers.heading': 'Klienci',
    'modules.customerAddresses.heading': 'Adresy',
    'modules.carriers.heading': 'Przewoźnicy spedycyjni',
    'modules.carriers.scoring': 'Ocena',

    // Settings
    'modules.users.heading': 'Użytkownicy',
    'modules.userRoles.heading': 'Role użytkowników',
    'modules.userLogins.heading': 'Ustawienia - Użytkownicy - Dziennik logowania',
    'modules.blockedIps.heading': 'Ustawienia - Użytkownicy - Zablokowane adresy IP',
    'modules.countries.heading': 'Kraje',
    'modules.currencies.heading': 'Waluty',
    'modules.companies.heading': 'Firmy',
    'modules.companyCostCenters.heading': 'Jednostki gospodarcze',
    'modules.transportTypes.heading': 'Typy transportów',
    'modules.vehicleTypes.heading': 'Typy pojazdów',
    'modules.trailerTypes.heading': 'Typy naczep',
    'modules.cargoTypes.heading': 'Typy ładunków',
    'modules.surchargeTypes.heading': 'Typy dopłat',
    'modules.customerAddressTypes.heading': 'Typy adresów',
    'modules.goodsTypes.heading': 'Typy towarów',
    'modules.documentTypes.heading': 'Rodzaje dokumentów',
    'modules.packagingTypes.heading': 'Rodzaje opakowań',
    'modules.equipments.heading': 'Wyposażenie',
    'modules.taxRates.heading': 'Stawki podatkowe',
    'modules.paymentMethods.heading': 'Metody płatności',
    'modules.transportConditions.heading': 'Warunki dostawy',
    'modules.invoiceItemTypes.heading': 'Rodzaje ruchów',
    'modules.costTypes.heading': 'Ustawienia - Rodzaje kosztów',
    'modules.costs.heading': 'Koszty',
    'modules.costGenerators.heading': 'Koszty cykliczne',
    'modules.salesTypes.heading': 'Rodzaje sprzedaży',
    'modules.driversTypes.heading': 'Rodzaje sterowników',
    'modules.sales.heading': 'Obroty',

    'modules.transportScoring.heading': 'Ocena transportu',
    'modules.transportScoring.rules': 'Zasady oceniania',

    'modules.carrierScoring.heading': 'Ocena przewoźnika',
    'modules.carrierScoring.rules': 'Zasady oceniania',

    // Invoicing
    'modules.invoicingOrders.heading': 'Fakturowanie - Zamówienia',
    'modules.invoicingInvoices.heading': 'Fakturowanie - Wystawione faktury',
    'modules.invoicingReceivedInvoices.heading': 'Fakturowanie - Otrzymane faktury',
    'modules.reportInvoicingSummary.heading': 'Fakturowanie - Streszczenie',

    // Diets
    'modules.dietRates.heading': 'Ceny posiłków',

    // General settings
    'modules.generalSettings.heading': 'Ustawienia - Ustawienia ogólne',
    'modules.generalSettings.transports': 'Transports / Traces',
    'modules.generalSettings.email': 'Mailing',
    'modules.generalSettings.invoices': 'Fakturace',
    'modules.generalSettings.security': 'Zabezpieczenia',
    // General settings - transports
    'modules.generalSettings.price_fuel': 'Średnia cena paliwa [CZK / 1 litr]',
    'modules.generalSettings.price_adblue': 'Średnia cena AdBlue [CZK / 1 litr]',
    'modules.generalSettings.rest_minutes': 'Długość odpoczynku [minuty]',
    'modules.generalSettings.break_minutes': 'Długość przerwy bezpieczeństwa [minuty]',
    'modules.generalSettings.rest_interval_minutes': 'Okres odpoczynku [minuty]',
    'modules.generalSettings.break_interval_minutes': 'Przerwa bezpieczeństwa [minuty]',
    'modules.generalSettings.costs_fixed': 'Stałe koszty godzinowe [CZK]',
    'modules.generalSettings.avg_speed': 'Średnia prędkość [km / h]',
    'modules.generalSettings.avg_fuel': 'Średnie zużycie paliwa [l / km]',
    'modules.generalSettings.avg_adblue': 'Średnie zużycie AdBlue [l / km]',
    'modules.generalSettings.drive_at_night_start': 'Nocna jazda zaczyna się za',
    'modules.generalSettings.drive_at_night_end': 'Nocna jazda kończy się za',
    'modules.generalSettings.sms_template': 'Szablon wysyłki',
    'modules.generalSettings.sms_route_point_template': 'Szablon punktu',
    'modules.generalSettings.sms_customer_template': 'Szablon klienta',
    'modules.generalSettings.automatic_event_creation_radius': 'Promień automatycznego tworzenia wydarzeń [°]',
    'modules.generalSettings.automatic_event_creation_time': 'Tolerancja czasu dla automatycznego tworzenia wydarzeń [h]',
    // General settings - email
    'modules.generalSettings.mail_from_address': 'Domyślny nadawca (e-mail)',
    'modules.generalSettings.mail_from_name': 'Domyślny nadawca (nazwa)',
    // General settings - invoices
    'modules.generalSettings.customer_invoice_deadline_days': 'Domyślny termin płatności wystawionych faktur [dni]',
    'modules.generalSettings.supplier_invoice_deadline_days': 'Domyślny termin płatności otrzymanych faktur [dni]',
    // General settings - security
    'modules.generalSettings.reset_password_token_valid_hours': 'Ważność linku do resetowania hasła [godziny]',
    'modules.generalSettings.ip_login_attempts': 'Zablokuj adres IP dla X nieudanych prób logowania',
    'modules.generalSettings.user_login_attempts': 'Zablokuj konto dla X nieudanych prób logowania',

    // Notifications
    'modules.notifications.heading': 'Powiadomienie',

    //Import messages
    'import.step1': 'Poniżej prosimy o przesłanie pliku, z którego mają zostać zaimportowane elementy. Obsługiwane formaty to XLS, XLSX i CSV.',
    'import.step2': 'Proszę ustawić znaczenie poszczególnych kolumn, aby elementy mogły zostać poprawnie zaimportowane.',
    'import.step3': 'Poniżej znajduje się przegląd elementów, które znaleźliśmy w pliku. Proszę wybrać te, które mają zostać przetworzone.',
    'import.noItemsFound': 'Nie znaleziono żadnego elementu.',

    // Costs import
    'modules.costsImport.heading': 'Koszty importu',
    'modules.costsImport.step': 'Krok',
    'modules.costsImport.stepNames.1': 'Udostępnianie pliku',
    'modules.costsImport.stepNames.2': 'Definicje kolumn',
    'modules.costsImport.stepNames.3': 'Streszczenie',

    'modules.costsImport.columnTypes.costCenter': 'Centrum kosztów (kod)',
    'modules.costsImport.columnTypes.vehicle': 'Pojazd (tablica rejestracyjna)',
    'modules.costsImport.columnTypes.driver': 'Kierowca (nazwisko i imię / numer personalny)',
    'modules.costsImport.columnTypes.trailer': 'Naczepa (tablica rejestracyjna)',
    'modules.costsImport.columnTypes.price': 'Kwota',
    'modules.costsImport.columnTypes.currency': 'Waluta',
    'modules.costsImport.columnTypes.date': 'Data',

    // Drivers import
    'modules.driversImport.heading': 'Import sterowników',
    'modules.driversImport.step': 'Krok',
    'modules.driversImport.stepNames.1': 'Udostępnianie pliku',
    'modules.driversImport.stepNames.2': 'Definicje kolumn',
    'modules.driversImport.stepNames.3': 'Streszczenie',

    'modules.driversImport.columnTypes.firstName': 'Nazwa',
    'modules.driversImport.columnTypes.lastName': 'Nazwisko',
    'modules.driversImport.columnTypes.locale': 'Język',
    'modules.driversImport.columnTypes.cardNumber': 'Numer karty',
    'modules.driversImport.columnTypes.code': 'Kod',
    'modules.driversImport.columnTypes.active': 'Aktywny',
    'modules.driversImport.columnTypes.phone': 'Telefon',
    'modules.driversImport.columnTypes.driverType': 'Typ',
    'modules.driversImport.columnTypes.dispatcher': 'Dyspozytor',
    'modules.driversImport.columnTypes.costCenter': 'Centrum kosztów (kod)',

    // Trailers import
    'modules.trailersImport.heading': 'Import naczep',
    'modules.trailersImport.step': 'Krok',
    'modules.trailersImport.stepNames.1': 'Udostępnianie pliku',
    'modules.trailersImport.stepNames.2': 'Definicje kolumn',
    'modules.trailersImport.stepNames.3': 'Streszczenie',

    'modules.trailersImport.columnTypes.vin': 'VIN',
    'modules.trailersImport.columnTypes.registration': 'Numer rejestracyjny',
    'modules.trailersImport.columnTypes.trailerType': 'Typ',
    'modules.trailersImport.columnTypes.active': 'Aktywny',
    'modules.trailersImport.columnTypes.damaged': 'Uszkodzony',
    'modules.trailersImport.columnTypes.securityDamage': 'Uszkodzone zabezpieczenia',
    'modules.trailersImport.columnTypes.coolingDamage': 'Uszkodzone chłodzenie',
    'modules.trailersImport.columnTypes.spedition': 'Spedytor',
    'modules.trailersImport.columnTypes.carrier': 'Przewoźnik',
    'modules.trailersImport.columnTypes.costCenter': 'Centrum kosztów (kod)',

    // Sales import
    'modules.salesImport.heading': 'Sprzedaż importowa',
    'modules.salesImport.step': 'Krok',
    'modules.salesImport.stepNames.1': 'Udostępnianie pliku',
    'modules.salesImport.stepNames.2': 'Definicje kolumn',
    'modules.salesImport.stepNames.3': 'Streszczenie',

    'modules.salesImport.columnTypes.costCenter': 'Centrum kosztów (kod)',
    'modules.salesImport.columnTypes.vehicle': 'Pojazd (tablica rejestracyjna)',
    'modules.salesImport.columnTypes.driver': 'Kierowca (nazwisko i imię / numer personalny)',
    'modules.salesImport.columnTypes.trailer': 'Naczepa (tablica rejestracyjna)',
    'modules.costsImport.columnTypes.registration': 'Tablica rejestracyjna (automatyczne wykrywanie)',
    'modules.costsImport.columnTypes.supplier': 'Dostawca',
    'modules.costsImport.columnTypes.billNumber': 'Numer identyfikacyjny',
    'modules.salesImport.columnTypes.price': 'Kwota',
    'modules.salesImport.columnTypes.currency': 'Waluta',
    'modules.salesImport.columnTypes.date': 'Data',


    // Customers import
    'modules.customersImport.heading': 'Importuj klientów',
    'modules.customersImport.step': 'Krok',
    'modules.customersImport.stepNames.1': 'Udostępnianie pliku',
    'modules.customersImport.stepNames.2': 'Definicje kolumn',
    'modules.customersImport.stepNames.3': 'Streszczenie',

    'modules.customersImport.columnTypes.regNumber': 'numer identyfikacyjny',
    'modules.customersImport.columnTypes.vatNumber': 'CYNA',
    'modules.customersImport.columnTypes.name': 'Nazwa firmy / klienta',
    'modules.customersImport.columnTypes.nameTransporeon': 'Imię w Transporeonie',
    'modules.customersImport.columnTypes.defaultCurrency': 'Domyślna waluta',
    'modules.customersImport.columnTypes.invoiceDeadlineDays': 'Domyślna dojrzałość',
    'modules.customersImport.columnTypes.emailForInvoicing': 'E-mail rozliczeniowy',
    'modules.customersImport.columnTypes.invoicingNote': 'Uwaga dotycząca fakturowania',
    'modules.customersImport.columnTypes.limitUnpaidInvoices': 'Limit niezapłaconych roszczeń',
    'modules.customersImport.columnTypes.limitOverdueInvoices': 'Limit na należności przeterminowane',
    'modules.customersImport.columnTypes.blocked': 'Zablokowany',
    'modules.customersImport.columnTypes.businessManager': 'Menedżer biznesu',
    'modules.customersImport.columnTypes.dispatcher': 'Dyspozytor',
    'modules.customersImport.columnTypes.transportPlanCount': 'Plan transportu (numer)',
    'modules.customersImport.columnTypes.transportPlanValue': 'Plan transportu (wartość)',
    'modules.customersImport.columnTypes.transportPlanMargin': 'Plan transportu (marża)',
    'modules.customersImport.columnTypes.speditionPlanCount': 'Plan wysyłki (numer)',
    'modules.customersImport.columnTypes.speditionPlanValue': 'Plan wysyłki (wartość)',
    'modules.customersImport.columnTypes.speditionPlanMargin': 'Plan spedycyjny (marża)',

    // Customer addresss import
    'modules.customerAddressesImport.heading': 'Importuj klientów',
    'modules.customerAddressesImport.step': 'Krok',
    'modules.customerAddressesImport.stepNames.1': 'Udostępnianie pliku',
    'modules.customerAddressesImport.stepNames.2': 'Definicje kolumn',
    'modules.customerAddressesImport.stepNames.3': 'Streszczenie',

    'modules.customerAddressesImport.columnTypes.name': 'Nazwa',
    'modules.customerAddressesImport.columnTypes.code': 'Kod',
    'modules.customerAddressesImport.columnTypes.customer': 'Klient',
    'modules.customerAddressesImport.columnTypes.addressType': 'Typ adresu',
    'modules.customerAddressesImport.columnTypes.street': 'Ulica',
    'modules.customerAddressesImport.columnTypes.zipcode': 'Kod pocztowy',
    'modules.customerAddressesImport.columnTypes.city': 'Miasto',
    'modules.customerAddressesImport.columnTypes.country': 'Ziemia',
    'modules.customerAddressesImport.columnTypes.gps': 'GPS',
    'modules.customerAddressesImport.columnTypes.email': 'E-mail',
    'modules.customerAddressesImport.columnTypes.phone': 'Telefon',
    'modules.customerAddressesImport.columnTypes.note': 'Notatka',
    'modules.customerAddressesImport.columnTypes.booking': 'Rezerwacja',
    'modules.customerAddressesImport.columnTypes.bookingUrl': 'Adres URL rezerwacji',
    'modules.customerAddressesImport.columnTypes.bookingNote': 'Uwaga do rezerwacji',

    // Permissions
    'permissionGroups.transports': 'Przesyłki',
    'permissionGroups.transports_cost': 'Przesyłki - Cena',
    'permissionGroups.transport_to_vehicle': 'Przesyłki - Wysyłanie do pojazdu',
    'permissionGroups.transports_customer': 'Przesyłki - Klient',
    'permissionGroups.transports_customer_cost': 'Przesyłki - Klient - Cena',
    'permissionGroups.transports_spedition': 'Przesyłki - Nośnik',
    'permissionGroups.transports_spedition_cost': 'Przesyłki - Nośnik - Cena',
    'permissionGroups.transports_events': 'Przesyłki - Wydarzenia',
    'permissionGroups.transports_log': 'Przesyłki - Log',
    'permissionGroups.transports_routes': 'Przesyłki - Definicja trasy',
    'permissionGroups.transports_goods': 'Przesyłki - Definicja ładunku',
    'permissionGroups.transports_parts': 'Przesyłki - Transport kombinowany (części)',
    'permissionGroups.transports_files': 'Przesyłki - Pliki',
    'permissionGroups.transports_allowances': 'Przesyłki - Diety i nagrody',
    'permissionGroups.transports_scoring': 'Przesyłki - Ocena',
    'permissionGroups.transports_template': 'Przesyłki - Szablony',
    'permissionGroups.transports_template_cost': 'Przesyłki - Szablony - Cena',
    'permissionGroups.transports_template_customer': 'Przesyłki - Szablony - Klient',
    'permissionGroups.transports_template_customer_cost': 'Przesyłki - Szablony - Klient - Cena',
    'permissionGroups.transports_template_routes': 'Przesyłki - Szablony - Definicja trasy',
    'permissionGroups.transports_template_allowances': 'Przesyłki - Szablony - Diety i nagrody',
    'permissionGroups.transporeon_offers': 'Oferty transporeon',
    'permissionGroups.tracing': 'Przesyłki - Rysunek kalkowy',
    'permissionGroups.board': 'Dispatch tarpaulin',
    'permissionGroups.here_map': 'Mapa',
    'permissionGroups.report_transports': 'Raporty - Przesyłki - Wyniki ekonomiczne',
    'permissionGroups.report_spedition_transports': 'Raporty - Przesyłki - Wyniki ekonomiczne transportu spedycyjnego',
    'permissionGroups.report_transport_changes': 'Raporty - Przesyłki - Śledzenie zmian',
    'permissionGroups.report_transport_invoicing': 'Raporty - Przesyłki - Fakturowanie',
    'permissionGroups.report_transport_orders': 'Raporty - Przesyłki - Zamówienia',
    'permissionGroups.report_vehicle_kilometers_total': 'Raporty - Pojazdy - Łączna liczba kilometrów',
    'permissionGroups.report_vehicle_performance': 'Raporty - Pojazdy - Wskaźniki operacyjne',
    'permissionGroups.report_vehicle_traffic': 'Raporty - Pojazdy - Wskaźniki wydajności',
    'permissionGroups.report_vehicle_checks': 'Raporty - Pojazdy - Przeglądy i usterki',
    'permissionGroups.report_vehicle_fuelings': 'Raporty - Pojazdy - Tankowanie i zużycie',
    'permissionGroups.report_trailer_fuelings': 'Raporty - Naczepy - Tankowanie',
    'permissionGroups.report_driver_allowances': 'Raporty - Kierowcy - Różnorodność i nagrody',
    'permissionGroups.report_driver_performance_analysis': 'Raporty - Kierowcy - Analiza wydajności',
    'permissionGroups.report_driver_hourly_overview': 'Raporty - Kierowcy - Przegląd godzinowy',
    'permissionGroups.report_driver_timesheets': 'Raporty - Kierowcy - Raport pracy',
    'permissionGroups.report_customer_results': 'Raporty - Klienci - Obroty',
    'permissionGroups.report_customer_plans': 'Raporty - Klienci - Harmonogram transportu',
    'permissionGroups.report_customer_weekly_plans': 'Raporty - Klienci - Tygodniowy harmonogram',
    'permissionGroups.report_customer_monthly_plans': 'Raporty - Klienci - Plan miesięczny',
    'permissionGroups.report_carrier_results': 'Raporty - Spedytorzy - Koszty',
    'permissionGroups.report_carrier_scoring': 'Raporty - Spedytorzy - Oceny',
    'permissionGroups.report_carrier_usage': 'Raporty - Spedytorzy - Wykorzystanie',
    'permissionGroups.report_dispatcher_kpi': 'Raporty - Dyspozytorzy - KPI',
    'permissionGroups.report_cost_center_results': 'Raporty - Centra kosztów - Obroty',
    'permissionGroups.report_cost_center_traffic': 'Raporty - Centra kosztów - Wskaźniki operacyjne',
    'permissionGroups.report_cost_center_performance': 'Raporty - Centra kosztów - Wskaźniki wydajności',
    'permissionGroups.report_cost_center_profits': 'Raporty - Centra kosztów - Wyniki ekonomiczne',
    'permissionGroups.report_customer_turnovers': 'Raporty - Inne - Klienci',
    'permissionGroups.report_empty_kilometers': 'Raporty - Inne - Przebieg',
    'permissionGroups.report_pallets': 'Raporty - Inne - Rekordy palet',
    'permissionGroups.report_invoicing_summary': 'Fakturowanie - Streszczenie',
    'permissionGroups.invoicing_orders': 'Fakturowanie - zamówienia',
    'permissionGroups.invoicing_invoices': 'Fakturowanie - Wystawiono faktury',
    'permissionGroups.invoicing_received_invoices': 'Fakturowanie - Otrzymane faktury',
    'permissionGroups.vehicles': 'Vehicles',
    'permissionGroups.vehicle_history': 'Pojazdy - Historia',
    'permissionGroups.vehicle_documents': 'Pojazdy - Dokumenty',
    'permissionGroups.vehicle_equipments': 'Pojazdy - Wyposażenie',
    'permissionGroups.vehicle_events': 'Pojazdy - Wydarzenia',
    'permissionGroups.vehicle_fuelings': 'Pojazdy - Tankowanie',
    'permissionGroups.vehicle_positions': 'Pojazdy - Pozycje',
    'permissionGroups.vehicle_messages': 'Czat',
    'permissionGroups.trailers': 'Naczepy',
    'permissionGroups.trailer_history': 'Naczepy - Historia',
    'permissionGroups.trailer_documents': 'Naczepy - Dokumenty',
    'permissionGroups.trailer_equipments': 'Naczepy - Wyposażenie',
    'permissionGroups.trailer_events': 'Naczepy - Wydarzenia',
    'permissionGroups.drivers': 'Kierowcy',
    'permissionGroups.driver_history': 'Kierowcy - Historia',
    'permissionGroups.driver_documents': 'Kierowcy - Dokumenty',
    'permissionGroups.driver_equipments': 'Kierowcy - Wyposażenie',
    'permissionGroups.driver_events': 'Kierowcy - Události',
    'permissionGroups.driver_allowances': 'Kierowcy - Diety i nagrody',
    'permissionGroups.driver_timesheets': 'Kierowcy - Arkusze',
    'permissionGroups.customers': 'Katalog - Klienci',
    'permissionGroups.customer_addresses': 'Katalog - Klienci - Adresy',
    'permissionGroups.customer_contacts': 'Katalog - Klienci - Łączność',
    'permissionGroups.customer_plans': 'Katalog - Klienci - Plany transportowe',
    'permissionGroups.addresses': 'Książka adresowa - Adresy',
    'permissionGroups.carriers': 'Książka adresowa - Spedytorzy',
    'permissionGroups.carrier_contacts': 'Książka adresowa - Spedytorzy - Łączność',
    'permissionGroups.border_crossings': 'Książka adresowa - Przejścia graniczne',
    'permissionGroups.transport_conditions': 'Ustawienia - Transport - Warunki transportu',
    'permissionGroups.transport_types': 'Ustawienia - Transport - Typy transportu',
    'permissionGroups.cargo_types': 'Ustawienia - Przesyłki - Rodzaje kosztów',
    'permissionGroups.goods_types': 'Ustawienia - Przesyłki - Typy produktów',
    'permissionGroups.packaging_types': 'Ustawienia - Transport - Rodzaje opakowań',
    'permissionGroups.surcharge_types': 'Ustawienia - Przesyłki - Rodzaje dopłat',
    'permissionGroups.transport_scoring': 'Ustawienia - Przesyłki - Ocena przesyłek',
    'permissionGroups.tax_rates': 'Ustawienia - Rozliczenia - Stawki podatkowe',
    'permissionGroups.payment_methods': 'Ustawienia - Rozliczenia - Metody płatności',
    'permissionGroups.invoice_item_types': 'Ustawienia - Billing - Movement Types',
    'permissionGroups.vehicle_types': 'Ustawienia - Flota - Typy pojazdów',
    'permissionGroups.trailer_types': 'Ustawienia - Flota - Typy naczep',
    'permissionGroups.document_types': 'Ustawienia - Flota - Typy dokumentów',
    'permissionGroups.equipments ': 'Ustawienia - Flota - Wyposażenie',
    'permissionGroups.companies': 'Settings - Directory - Companies',
    'permissionGroups.company_cost_centers': 'Ustawienia - Katalog - Firmy - Centra kosztów',
    'permissionGroups.company_bank_accounts': 'Ustawienia - Katalog - Firmy - Konta bankowe',
    'permissionGroups.company_default_bank_accounts': 'Ustawienia - Katalog - Firmy - Konta bankowe - Domyślne ustawienia konta',
    'permissionGroups.company_numberings': 'Ustawienia - Katalog - Firmy - Seria numerów',
    'permissionGroups.company_settings': 'Ustawienia - Katalog - Firmy - Ustawienia',
    'permissionGroups.countries': 'Ustawienia - Katalog - Kraj',
    'permissionGroups.currencies': 'Ustawienia - Katalog - Waluty',
    'permissionGroups.customer_address_types': 'Ustawienia - Katalog - Typy adresów',
    'permissionGroups.carrier_scoring': 'Ustawienia - Katalog - Ocena przewoźnika',
    'permissionGroups.users': 'Ustawienia - Użytkownicy',
    'permissionGroups.users_login_log': 'Ustawienia - Użytkownicy - Dziennik logowania',
    'permissionGroups.user_roles': 'Ustawienia - Użytkownicy - Role użytkowników',
    'permissionGroups.user_logins': 'Ustawienia - Użytkownicy - Dziennik logowania',
    'permissionGroups.blocked_ips': 'Ustawienia - Użytkownicy - Zablokowane adresy IP',
    'permissionGroups.general_settings_transports': 'Ustawienia - Ustawienia ogólne - Transports / Traces',
    'permissionGroups.general_settings_email': 'Ustawienia - Ustawienia ogólne - Mailing',
    'permissionGroups.general_settings_invoices': 'Ustawienia - Ustawienia ogólne - Fakturace',
    'permissionGroups.general_settings_security': 'Ustawienia - Ustawienia ogólne - Zabezpieczenia',
    'permissionGroups.mails_user_blocked': 'Wyślij e-maila do administratora, aby zablokować użytkownika',
    'permissionGroups.mails_ip_address_blocked': 'Wyślij e-maila do administratora o blokowaniu adresów IP',
    'permissionGroups.notification': 'Notifikace',
    'permissionGroups.diet_rates': 'Ustawienia - Stawki posiłków zagranicznych',
    'permissionGroups.cost_types': 'Ustawienia - Rodzaje kosztów',
    'permissionGroups.costs': 'Koszty',
    'permissionGroups.report_cost_center_cost': 'Raporty - Jednostki gospodarcze - Koszty',
    'permissionGroups.sales_types': 'Ustawienia - Rodzaje sprzedaży',
    'permissionGroups.drivers_types': 'Ustawienia — Typy sterowników',
    'permissionGroups.sales': 'Obroty',


    'permissions.read': 'Read',
    'permissions.create': 'Utwórz',
    'permissions.update': 'Edytuj',
    'permissions.delete': 'Usuń',
    'permissions.write': 'Pisać',
    'permissions.copy_to_clipboard': 'Skopiuj do schowka',
    'permissions.export': 'Eksport',
    'permissions.import': 'Import',
    'permissions.restore': 'Poprawa',
    'permissions.download': 'Pobieranie',
    'permissions.send': 'Wysłać',
    'permissions.receive': 'Odbierz',
    'permissions.export_to_accounting': 'Eksport do księgowości',
    'permissions.approval': 'Zatwierdzenie',

    'permissions.no_permission': 'Nie masz uprawnień do przeglądania tej strony.',

    // Travel allowances
    'modules.reportAllowances.heading': 'Raporty - Diety i nagrody',
    'modules.reportAllowances.drivers': 'Nazwa sterownika',
    'modules.reportAllowances.months': 'Miesiąc',
    'modules.reportAllowances.spendingMoney': 'Pocket',
    'modules.reportAllowances.allowances': 'Diety i nagrody',
    'modules.reportAllowances.additionalAllowances': 'Rewards',
    'modules.reportAllowances.noAdditionalAllowances': 'Brak nagród',
    'modules.reportAllowances.addAdditionalAllowance': 'Dodaj nagrodę',
    'modules.reportAllowances.additionalAllowanceAmount': 'Kwota nagrody',
    'modules.reportAllowances.additionalAllowanceDate': 'Data nagrody',
    'modules.reportAllowances.calculationMethods.0': 'Timeout',
    'modules.reportAllowances.calculationMethods.1': 'Stawka ryczałtowa',
    'modules.reportAllowances.calculationMethods.2': 'Codziennie (dni robocze)',
    'modules.reportAllowances.calculationMethods.3': 'Codziennie (weekendy, święta)',
    'modules.reportAllowances.calculationMethods.4': 'Monthly',
    'modules.reportAllowances.calculationMethods.5': 'Kilometr',

    // Driver performance analysis
    'modules.driverPerformanceAnalysis.period': 'Kropka',
    'modules.driverPerformanceAnalysis.driver': 'Nazwisko kierowcy',
    'modules.driverPerformanceAnalysis.drivingStyle': 'Pieczęć',
    'modules.driverPerformanceAnalysis.degreeOfDifficulty': 'Trudność',
    'modules.driverPerformanceAnalysis.totalDistance': 'Przebieg',
    'modules.driverPerformanceAnalysis.speedOver85DistancePerc': '> 85 km/h',
    'modules.driverPerformanceAnalysis.overallConsumption': 'Całkowite zużycie',
    'modules.driverPerformanceAnalysis.driveConsumption': 'Zużycie podczas jazdy',
    'modules.driverPerformanceAnalysis.serviceBrakeDistance': 'Droga hamowania zasadniczego',
    'modules.driverPerformanceAnalysis.handBrakeUsageCount': 'Używanie hamulca postojowego',

    // Period picker
    'modules.periodPicke.heading': 'Kropka',
    'modules.periodPicke.year': 'Rok',
    'modules.periodPicke.half': 'Pół roku',
    'modules.periodPicke.quarter': 'Kvartál',
    'modules.periodPicke.month': 'Miesiąc',
    'modules.periodPicke.week': 'Tydzień',
    'modules.periodPicke.day': 'Dzień',
    'modules.periodPicke.period': 'Własny okres',
    'modules.periodPicke.weekShort': 'T',

    // Border crossings
    'modules.borderCrossings.heading': 'Przejścia graniczne',

    // Tooltips
    'tooltips.shortAscending': 'Sortuj rosnąco',
    'tooltips.shortDescending': 'Sortuj malejąco',

    // Map
    'modules.map.poisLoading': 'Ładowanie ciekawych miejsc',

    // Vehicle fueling
    'modules.vehicleFuelings.heading': 'Tankowanie',
    'modules.vehicleFuelings.importHistory': 'Historia importu',
    'modules.vehicleFuelings.drive': 'NAP',
    'modules.vehicleFuelings.night': 'NOC',
    'modules.vehicleFuelings.weekend': 'WEK',
    'modules.vehicleFuelings.holiday': 'WAK',
    'modules.vehicleFuelings.otherJob': 'IP',
    'modules.vehicleFuelings.vacation': 'WAK',
    'modules.vehicleFuelings.paidVacation': 'OW',
    'modules.vehicleFuelings.unpaidVacation': 'BW',
    'modules.vehicleFuelings.useOvertime': 'KN',
    'modules.vehicleFuelings.disease': 'CH',
    'modules.vehicleFuelings.absence': 'B',
    'modules.vehicleFuelings.holidayNotWorking': 'ŚND',
    'modules.vehicleFuelings.driveOvertimesHolidayNotWorking': 'NAP+IP +KN+ŚND',
    'modules.vehicleFuelings.overtime': 'ZBC',
    // Vehicle fueling - import
    'modules.vehicleFuelings.firstImportLine': 'Pierwszy rekord importu',
    'modules.vehicleFuelings.numberedValues':
        'Produkty wydają się być oznaczone kodami. Wybierz lub zaimportuj listę produktów stacji benzynowej.',
    // Vehicle fueling - import - steps
    'modules.vehicleFuelings.importStep1': 'Importuj rekordy tankowań - Wybór pliku',
    'modules.vehicleFuelings.importStep2': 'Importuj rekordy tankowań - przypisz pozycje',
    'modules.vehicleFuelings.importStep3': 'Importuj zapisy dotyczące tankowania - przypisanie produktu',
    'modules.vehicleFuelings.importStep4': 'Importuj rekordy tankowań - Podgląd importu',
    'modules.vehicleFuelings.importProductsStep1': 'Importuj produkty do tankowania - Wybór pliku',
    'modules.vehicleFuelings.importProductsStep2': 'Importuj produkty do tankowania - przypisz pozycje',
    'modules.vehicleFuelings.importProductsStep3': 'Import produktów do tankowania - Podgląd importu',
    // Vehicle fueling - import - infolog
    'modules.vehicleFuelings.infolog.notFound': 'Nie znaleziono wartości w importowanym pliku',
    'modules.vehicleFuelings.infolog.notFoundObject': 'Nie znaleziono pasującej pozycji dla rekordu',
    'modules.vehicleFuelings.infolog.fromFile': 'Zaimportowano z pliku',
    'modules.vehicleFuelings.infolog.fromPoint': 'Znalezione według pozycji',
    'modules.vehicleFuelings.infolog.fromLastPoint': 'Znalezione według ostatniej znanej pozycji',
    'modules.vehicleFuelings.infolog.empty': 'Pusty',
    'modules.vehicleFuelings.infolog.format': 'Zły format',
    'modules.vehicleFuelings.infolog.timeNotFound': 'Nie znaleziono czasu',
    'modules.vehicleFuelings.infolog.cantFind': 'Nie może być znalezione',
    // Vehicle positions
    'modules.vehiclePositions.heading': 'Pozycje',
    // Tracing
    'modules.tracing.heading': 'Rysunek kalkowy',
    'modules.tracing.transportDetailHeading': 'Szczegóły dostawy',
    'modules.tracing.routeDefinitionHeading': 'Definicja trasy',
    'modules.tracing.detailHeading': 'Szczegóły trasy',
    'modules.tracing.totalDistance': 'Całkowity dystans',
    'modules.tracing.totalTime': 'Czas wysyłki',
    'modules.tracing.shippingTime': 'Czas jazdy + przerwy',
    'modules.tracing.contractPrice': 'Cena kontraktu',
    'modules.tracing.totalCost': 'Całkowity koszt',
    'modules.tracing.economicResults': 'Wyniki ekonomiczne',
    'modules.tracing.additionalCosts': 'Dodatkowe koszty',
    'modules.tracing.fixedRate': 'Stała stawka',
    'modules.tracing.extraCosts': 'Dodatkowe koszty',
    'modules.tracing.waypoints': 'Waypointy',
    'modules.tracing.timePlane': 'Szczegółowy harmonogram',
    'modules.tracing.map.vehiclesLoading': 'Ładowanie pojazdów',
    'modules.tracing.map.vehicleSearch': 'Wyszukiwanie pojazdów',
    'modules.tracing.warningApproachTime': 'Nalot nie mieści się w przedziale czasowym',
    'modules.tracing.warningShippingTime': 'Czas wysyłki się skończył',
    'modules.tracing.warningTime': 'Nie można utworzyć przesyłki! Ustawiony zakres czasu nie wystarcza na czas całkowity (jazda + przerwy)',
    'modules.tracing.warningCustomer': 'Przed kontynuowaniem wybierz klienta',
    'modules.tracing.warningProfit': 'Zysk musi wynosić co najmniej {profit}.',

    // Notifications
    'notifications.allItems': 'Wszystkie powiadomienia',
    'notifications.unreadItems': 'Masz {count} nieprzeczytanych wiadomości',
    'notifications.noItemsFound': 'Nie znaleziono powiadomień',
    'notifications.markAsRead': 'Oznacz jako przeczytane',

    'permissions.general': 'Ogólnie',
    'permissions.transports_load_arrived': 'Przyjazd do załadunku',
    'permissions.transports_load_wait': 'Czekam na załadowanie',
    'permissions.transports_load_finished': 'Koniec ładowania',
    'permissions.transports_unload_arrival': 'Przyjazd do rozładunku',
    'permissions.transports_unload_wait': 'Czekam na rozładunek',
    'permissions.transports_unload_finished': 'Koniec rozładunku',
    'permissions.transports_state_changed_processing': 'Status wysyłki: przetwarzanie',
    'permissions.transports_state_changed_delivered': 'Status wysyłki: zakończono',
    'permissions.transports_state_changed_canceled': 'Status wysyłki: anulowany',
    'permissions.transporeon_offers': 'Nowa oferta Transporeon',
    'permissions.vehicle_message': 'Czat',
}

export default translations
