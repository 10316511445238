import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

class WeekendAtHomeTrailer extends Component {
    render() {
        return (
            <td className="board-day text-center b-r w-100">
                <div className="note wrapped" />
            </td>
        )
    }
}

export default injectIntl(WeekendAtHomeTrailer)
