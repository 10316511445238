import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportTransports = filters => ({
    type: actionTypes.FETCH,
    payload: {
        filters,
    },
})

export const fetchReportTransportsFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: {
        data,
    },
})

export const fetchReportTransportsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportTransportsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportTransports = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportTransports = filters => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
    },
})

// Export details

export const exportReportTransportsDetails = filters => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        filters,
    },
})
