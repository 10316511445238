import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import Dropzone from 'react-dropzone'

import * as config from '../../../common/config'
import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as trailerDocumentsActionCreators from '../actionCreators'
import {
    getTrailerDocumentFiles,
    getTrailerDocumentFilesFetching,
    getTrailerDocumentFilesSaving,
    getTrailerDocumentFilesDeleting,
    getTrailerDocumentFilesError,
} from '../selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class TrailerDocumentsFiles extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    // actions
    fetchTrailerDocumentFiles = trailerDocumentId => {
        this.props.actions.fetchTrailerDocumentFiles(trailerDocumentId)
    }

    uploadTrailerDocumentFiles = files => {
        this.props.actions.uploadTrailerDocumentFiles(this.props.trailerDocument.id, files)
    }

    deleteTrailerDocumentFile = trailerDocumentFileId => {
        this.props.actions.deleteTrailerDocumentFile(this.props.trailerDocument.id, trailerDocumentFileId)
    }

    refresh = () => {
        this.fetchTrailerDocumentFiles(this.props.trailerDocument.id)
    }

    // handles
    onDrop = files => {
        const loadedFiles = []

        files.forEach(file => {
            const reader = new FileReader()
            const fileName = file.name

            reader.readAsDataURL(file)
            reader.onload = e => {
                loadedFiles.push({
                    name: fileName,
                    base64: e.target.result,
                })

                if (loadedFiles.length === files.length) {
                    this.uploadTrailerDocumentFiles(loadedFiles)
                }
            }
        })
    }

    onClick = (e, trailerDocumentFile) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        window.open(`${config.CFG_API_BASE_URL}/files/trailer-document-files/${trailerDocumentFile.filename}`)
    }

    onDelete = (e, trailerDocumentFile) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.deleteTrailerDocumentFile(trailerDocumentFile.id)
                this.hideConfirmDialog()
            },
        })
    }

    handleSetAllRequiredFilesUploaded = value => {
        this.props.handleSetAllRequiredFilesUploaded && this.props.handleSetAllRequiredFilesUploaded(value)
    }

    componentDidMount() {
        if (this.props.trailerDocument) {
            this.fetchTrailerDocumentFiles(this.props.trailerDocument.id)
        }
    }

    componentWillReceiveProps(nextProps) {
        // Fetching after trailerDocument change
        if (nextProps.trailerDocument && JSON.stringify(nextProps.trailerDocument) !== JSON.stringify(this.props.trailerDocument)) {
            this.fetchTrailerDocumentFiles(nextProps.trailerDocument.id)
        }

        // Uploading
        if (this.props.trailerDocumentFilesSaving && !nextProps.trailerDocumentFilesSaving) {
            if (nextProps.trailerDocumentFilesError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.filesUploadSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.trailerDocumentFilesError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }

        // Deleting
        if (this.props.trailerDocumentFilesDeleting && !nextProps.trailerDocumentFilesDeleting) {
            if (nextProps.trailerDocumentFilesError === null) {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                        message: this.props.intl.formatMessage({ id: 'alerts.messages.filesDeleteSuccess' }),
                        position: 'tc',
                    },
                    'success'
                )
            } else {
                this.props.notify(
                    {
                        title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                        message: nextProps.trailerDocumentFilesError,
                        position: 'tc',
                    },
                    'error'
                )
            }
        }
    }

    render() {
        const trailerDocument = this.props.trailerDocument
        const trailerDocumentFiles = this.props.trailerDocumentFiles || []

        if (!trailerDocument) {
            return null
        }

        return (
            <React.Fragment>
                <LoadingOverlay
                    active={
                        this.props.trailerDocumentFilesFetching || this.props.trailerDocumentFilesSaving || this.props.trailerDocumentFilesDeleting
                    }
                >
                    <PermissionsCheck hasAny={[PERMS.CREATE, PERMS.UPDATE]}>
                        <div className="pull-left wp-100 m-b-md">
                            <Dropzone onDrop={this.onDrop} style={{}} className="dropzone" acceptClassName="dropzone-accept">
                                <p className="ready">
                                    <FormattedMessage id="fields.dropzoneReady" />
                                </p>
                                <p className="accept">
                                    <FormattedMessage id="fields.dropzoneAccept" />
                                </p>
                            </Dropzone>
                        </div>
                    </PermissionsCheck>

                    {trailerDocumentFiles.length > 0 ? (
                        <div className="pull-left wp-100 m-b-md">
                            <div className="table-container">
                                <table className="table table-striped table-no-border no-m">
                                    <thead>
                                        <tr>
                                            <th className="w-60" />
                                            <th className="w-140 text-center">
                                                <FormattedMessage id="fields.time" />
                                            </th>
                                            <th className="w-140">
                                                <FormattedMessage id="fields.user" />
                                            </th>
                                            <th className="w-max wm-300">
                                                <FormattedMessage id="fields.name" />
                                            </th>
                                            <th className="w-100 text-center">
                                                <FormattedMessage id="fields.fileType" />
                                            </th>
                                            <th className="w-100 text-right">
                                                <FormattedMessage id="fields.fileSize" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trailerDocumentFiles.map(trailerDocumentFile => (
                                            <tr
                                                key={`trailerDocumentFile-${trailerDocumentFile.id}`}
                                                onClick={e => {
                                                    this.onClick(e, trailerDocumentFile)
                                                }}
                                                className="row-selectable"
                                            >
                                                <td className="w-60 table-buttons">
                                                    <button className="far fa-download text-gray m-r-xxs" />
                                                    <PermissionsCheck has={[PERMS.DELETE]}>
                                                        <button
                                                            onClick={e => {
                                                                this.onDelete(e, trailerDocumentFile)
                                                            }}
                                                            className="far fa-trash text-gray"
                                                        />
                                                    </PermissionsCheck>
                                                </td>
                                                <td className="text-center">{formatters.datetimeFormatter(trailerDocumentFile.datetime)}</td>
                                                <td>{trailerDocumentFile.user && trailerDocumentFile.user.name}</td>
                                                <td>{trailerDocumentFile.name}</td>
                                                <td className="text-center">{trailerDocumentFile.extension}</td>
                                                <td className="text-right">{trailerDocumentFile.size}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className="alert alert-warning pull-left wp-100">
                            <p>
                                <FormattedMessage id="fields.noFilesUploaded" />
                            </p>
                        </div>
                    )}
                </LoadingOverlay>

                <ConfirmDialog options={this.state.confirmDialog} />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        trailerDocumentFiles: getTrailerDocumentFiles(state),
        trailerDocumentFilesFetching: getTrailerDocumentFilesFetching(state),
        trailerDocumentFilesSaving: getTrailerDocumentFilesSaving(state),
        trailerDocumentFilesDeleting: getTrailerDocumentFilesDeleting(state),
        trailerDocumentFilesError: getTrailerDocumentFilesError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...trailerDocumentsActionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TrailerDocumentsFiles)
)
