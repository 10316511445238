import React, { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
class CheckboxField extends Component {
    render() {
        const value = Boolean(this.props.hasOwnProperty('getValue') ? this.props.getValue() : this.props.value)

        return (
            <div className={`form-group checkbox ${this.props.className}`}>
                <label htmlFor={this.props.id}>
                    <input id={this.props.id} type="checkbox" checked={value} onChange={this.props.onChange} disabled={this.props.readonly ? this.props.readonly() : false} />
                    {this.props.label}
                </label>
            </div>
        )
    }
}

export default CheckboxField
