import { Record } from 'immutable'

export class CompanyNumbering extends Record({
    id: undefined,
    name: undefined,
    format: undefined,
    prefix: undefined,
    year_format: undefined,
    month: undefined,
    digits: undefined,
    separator: undefined,
    note: undefined,
    company_id: undefined,
    type_id: undefined,
}) {}
