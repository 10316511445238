import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'

import * as constants from '../constants'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as actionCreators from '../actionCreators'
import { getReceivedInvoiceDetail, getReceivedInvoiceDetailFetching, getReceivedInvoiceDetailError } from '../selectors'

import * as config from '../../../common/config'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class ReceivedInvoiceDetail extends Component {
    state = {
        data: null,
    }

    fetchReceivedInvoiceDetail = receivedInvoiceId => {
        this.props.actions.fetchReceivedInvoiceDetail(receivedInvoiceId)
    }

    setData = data => {
        this.setState({
            data,
        })
    }

    onFileClick = (e, receivedInvoiceFile) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        window.open(`${config.CFG_API_BASE_URL}/files/received-invoice-files/${receivedInvoiceFile.filename}`)
    }

    componentDidMount() {
        if (this.props.receivedInvoiceId) {
            this.fetchReceivedInvoiceDetail(this.props.receivedInvoiceId)
        }

        if (this.props.data) {
            this.setData(this.props.data)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.receivedInvoiceId) !== JSON.stringify(this.props.receivedInvoiceId)) {
            this.fetchReceivedInvoiceDetail(nextProps.receivedInvoiceId)
        }

        if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
            this.setData(nextProps.data)
        }
    }

    render() {
        const data = this.state.data
        const currencyIsoCode = data && data.currency ? data.currency.iso_code : ''
        const currencyRate = data && data.currency_rate ? data.currency_rate : 1

        const isOverdue = data && data.date_due && moment(data.date_due).isBefore(data.paid_at ? moment(data.paid_at) : moment(), 'days')
        const daysOverdue = data && data.date_due && Math.abs(moment(data.date_due).diff(data.paid_at ? moment(data.paid_at) : moment(), 'days'))

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)}>
                <div>
                    <LoadingOverlay active={this.props.dataFetching}>
                        {data ? (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-12">
                                        {data.type_id === constants.TYPE_CREDIT_NOTE ? (
                                            <h2 className="no-m">
                                                <FormattedMessage id="fields.creditNoteNo" /> {data.number}{' '}
                                                <small className="m-l-sm">
                                                    (<FormattedMessage id="fields.forInvoiceNo" />{' '}
                                                    {data.related_invoice && data.related_invoice.number})
                                                </small>
                                            </h2>
                                        ) : (
                                            <h2 className="no-m">
                                                <FormattedMessage id="fields.invoiceNo" /> {data.number}
                                            </h2>
                                        )}
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        {data.supplier && (
                                            <div>
                                                <h3 className="m-t-xxs">{data.supplier.name}</h3>
                                                <p>
                                                    {data.supplier.street}
                                                    <br />
                                                    {data.supplier.zipcode} {data.supplier.city}
                                                    <br />
                                                    {data.supplier.country ? data.supplier.country.iso_code : null}
                                                </p>
                                                <p>
                                                    <FormattedMessage id="fields.regNumber" />: {data.supplier.reg_number}
                                                    <br />
                                                    <FormattedMessage id="fields.vatNumber" />: {data.supplier.vat_number}
                                                </p>
                                                <p>
                                                    <FormattedMessage id="fields.phone" />: {data.supplier.phone}
                                                    <br />
                                                    <FormattedMessage id="fields.email" />: {data.supplier.email}
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-md-4 b-r b-l">
                                        <div className="row">
                                            <div className="col-md-6 text-gray">
                                                <FormattedMessage id="fields.paymentMethod" />:
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <p>{data.payment_method ? data.payment_method.name : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 text-gray">
                                                <p>
                                                    <FormattedMessage id="fields.dateIssue" />:
                                                    <br />
                                                    <FormattedMessage id="fields.dateReceived" />:
                                                    <br />
                                                    <FormattedMessage id="fields.dateDue" />:
                                                    <br />
                                                    <FormattedMessage id="fields.dateTax" />:
                                                </p>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <p>
                                                    {data.date_issued ? formatters.dateFormatter(data.date_issued) : '-'}
                                                    <br />
                                                    {data.date_received ? formatters.dateFormatter(data.date_received) : '-'}
                                                    <br />
                                                    <strong>{data.date_due ? formatters.dateFormatter(data.date_due) : '-'}</strong>
                                                    <br />
                                                    {data.date_tax ? formatters.dateFormatter(data.date_tax) : '-'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 text-gray">
                                                <p>
                                                    <FormattedMessage id="fields.suppliersNumber" />:
                                                    <br />
                                                    <FormattedMessage id="fields.varSymbol" />:
                                                    <br />
                                                    <FormattedMessage id="fields.taxTransactionType" />:
                                                </p>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <p>
                                                    <strong>{data.suppliers_number || '-'}</strong>
                                                    <br />
                                                    <strong>{data.var_symbol || '-'}</strong>
                                                    <br />
                                                    <FormattedMessage id={`taxTransactionTypes.${data.tax_transaction_type_id}`} />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 text-gray">
                                                <p>
                                                    <FormattedMessage id="fields.approved" />:
                                                    <br />
                                                    <FormattedMessage id="fields.paid" />:
                                                </p>
                                            </div>
                                            <div className="col-md-8 text-right">
                                                <p>
                                                    {data.approved_at ? (
                                                        <span className="text-success">
                                                            {formatters.datetimeFormatter(data.approved_at)}{' '}
                                                            {data.approved_by &&
                                                                ` / ${formatters.shortFullName(
                                                                    data.approved_by.first_name,
                                                                    data.approved_by.last_name
                                                                )}`}
                                                        </span>
                                                    ) : (
                                                        <span className="text-danger">
                                                            <FormattedMessage id="fields.notApproved" />
                                                        </span>
                                                    )}
                                                    <br />
                                                    {data.paid_at ? (
                                                        <span className={isOverdue ? 'text-danger' : 'text-success'}>
                                                            {formatters.dateFormatter(data.paid_at)}
                                                        </span>
                                                    ) : (
                                                        <span className="text-danger">
                                                            {isOverdue ? (
                                                                <span>
                                                                    <FormattedMessage id="fields.overdue" />{' '}
                                                                    {formatters.integerFormatter(daysOverdue)} <FormattedMessage id="fields.day4" />
                                                                </span>
                                                            ) : (
                                                                <FormattedMessage id="fields.unpaid" />
                                                            )}
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        {data.supplier && (
                                            <div>
                                                <h3 className="m-t-xxs">{data.company.name}</h3>
                                                <p>
                                                    {data.company.street}
                                                    <br />
                                                    {data.company.zipcode} {data.company.city}
                                                    <br />
                                                    {data.company.country ? data.company.country.iso_code : null}
                                                </p>
                                                <p>
                                                    <FormattedMessage id="fields.regNumber" />: {data.company.reg_number}
                                                    <br />
                                                    <FormattedMessage id="fields.vatNumber" />: {data.company.vat_number}
                                                </p>
                                                <p>
                                                    <FormattedMessage id="fields.phone" />: {data.company.phone}
                                                    <br />
                                                    <FormattedMessage id="fields.email" />: {data.company.email}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <hr />

                                <div className="row m-t-sm">
                                    <div className="col-md-12">
                                        <p>
                                            <FormattedMessage id="fields.invoicingItems" />:
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="w-max wm-400">
                                                        <FormattedMessage id="fields.name" />
                                                    </th>
                                                    <th className="w-80 text-right">
                                                        <FormattedMessage id="fields.count" />
                                                    </th>
                                                    <th className="w-60 text-center">
                                                        <FormattedMessage id="fields.unit" />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.unitPrice" />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.total" />
                                                    </th>
                                                    <th className="w-80 text-right">
                                                        <FormattedMessage id="fields.vat" /> %
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.items &&
                                                    data.items.map(item => (
                                                        <tr key={`item-${item.id}`}>
                                                            <td>
                                                                {item.name}
                                                                <br />
                                                                {item.text}
                                                            </td>
                                                            <td className="text-right">{formatters.floatFormatter(item.units_count)}</td>
                                                            <td className="text-center">{item.unit}</td>
                                                            <td className="text-right">
                                                                {formatters.priceFormatter(item.unit_price, '0,0.00', currencyIsoCode)}
                                                            </td>
                                                            <td className="text-right">
                                                                <strong>
                                                                    {formatters.priceFormatter(
                                                                        item.unit_price * item.units_count,
                                                                        '0,0.00',
                                                                        currencyIsoCode
                                                                    )}
                                                                </strong>
                                                            </td>
                                                            <td className="text-right">{formatters.percentFormatter(item.tax_rate_percent)}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row m-t-md">
                                    <div className="col-md-6 col-md-offset-6">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="w-max wm-100">
                                                        <FormattedMessage id="fields.taxRate" />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.base" />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.vat" />
                                                    </th>
                                                    <th className="w-120 text-right">
                                                        <FormattedMessage id="fields.total" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.vat_summary &&
                                                    data.vat_summary.map(item => (
                                                        <tr key={`vatSummary-${item.percent}`}>
                                                            <td>{formatters.percentFormatter(item.percent)}</td>
                                                            <td className="text-right">
                                                                {formatters.priceFormatter(item.base, '0,0.00', currencyIsoCode)}
                                                            </td>
                                                            <td className="text-right">
                                                                {formatters.priceFormatter(item.vat, '0,0.00', currencyIsoCode)}
                                                            </td>
                                                            <td className="text-right">
                                                                {formatters.priceFormatter(item.base + item.vat, '0,0.00', currencyIsoCode)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            <FormattedMessage id="fields.total" />
                                                        </strong>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>
                                                            {formatters.priceFormatter(data.total && data.total.excl_vat, '0,0.00', currencyIsoCode)}
                                                        </strong>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>
                                                            {formatters.priceFormatter(data.total && data.total.vat, '0,0.00', currencyIsoCode)}
                                                        </strong>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>
                                                            {formatters.priceFormatter(data.total && data.total.incl_vat, '0,0.00', currencyIsoCode)}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row m-t-md">
                                    <div className="col-md-3 col-md-offset-6">
                                        <p className="text-right m-t-md">
                                            <FormattedMessage id="fields.totalToPay" />:
                                        </p>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <p className="well">
                                            <strong className="text-md">
                                                {formatters.priceFormatter(data.total && data.total.incl_vat, '0,0.00', currencyIsoCode)}
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                                {currencyIsoCode !== 'CZK' ? (
                                    <div className="row m-t-md">
                                        <div className="col-md-6 col-md-offset-6">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="w-max wm-100">
                                                            <FormattedMessage id="fields.taxRate" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.base" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.vat" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.total" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.vat_summary &&
                                                        data.vat_summary.map(item => (
                                                            <tr key={`vatSummary-${item.percent}`}>
                                                                <td>{formatters.percentFormatter(item.percent)}</td>
                                                                <td className="text-right">
                                                                    {formatters.priceFormatter(item.base * currencyRate, '0,0.00')}
                                                                </td>
                                                                <td className="text-right">
                                                                    {formatters.priceFormatter(item.vat * currencyRate, '0,0.00')}
                                                                </td>
                                                                <td className="text-right">
                                                                    {formatters.priceFormatter((item.base + item.vat) * currencyRate, '0,0.00')}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                <FormattedMessage id="fields.total" />
                                                            </strong>
                                                        </td>
                                                        <td className="text-right">
                                                            <strong>
                                                                {formatters.priceFormatter(
                                                                    (data.total && data.total.excl_vat) * currencyRate,
                                                                    '0,0.00'
                                                                )}
                                                            </strong>
                                                        </td>
                                                        <td className="text-right">
                                                            <strong>
                                                                {formatters.priceFormatter((data.total && data.total.vat) * currencyRate, '0,0.00')}
                                                            </strong>
                                                        </td>
                                                        <td className="text-right">
                                                            <strong>
                                                                {formatters.priceFormatter(
                                                                    (data.total && data.total.incl_vat) * currencyRate,
                                                                    '0,0.00'
                                                                )}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p>
                                                <FormattedMessage id="fields.rate" /> {formatters.floatFormatter(currencyRate, 3)} CZK/{currencyIsoCode}{' '}
                                                <FormattedMessage id="fields.forDay" /> {data.date_tax ? formatters.dateFormatter(data.date_tax) : '-'}
                                            </p>
                                        </div>
                                    </div>
                                ) : null}

                                {data.note && (
                                    <div className="row m-t-sm">
                                        <div className="col-md-12">
                                            <p>
                                                <FormattedMessage id="fields.note" />:<br />
                                                {data.note}
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <div className="row m-t-md">
                                    <div className="col-md-12">
                                        <h4>
                                            <FormattedMessage id="fields.files" />
                                        </h4>
                                    </div>
                                    <div className="col-md-12">
                                        {data.files && data.files.length > 0 ? (
                                            <div className="pull-left wp-100 m-b-md">
                                                <div className="table-container">
                                                    <table className="table table-striped table-no-border no-m">
                                                        <thead>
                                                            <tr>
                                                                <th className="w-40" />
                                                                <th className="w-140 text-center">
                                                                    <FormattedMessage id="fields.time" />
                                                                </th>
                                                                <th className="w-140">
                                                                    <FormattedMessage id="fields.user" />
                                                                </th>
                                                                <th className="w-max wm-300">
                                                                    <FormattedMessage id="fields.name" />
                                                                </th>
                                                                <th className="w-100 text-center">
                                                                    <FormattedMessage id="fields.fileType" />
                                                                </th>
                                                                <th className="w-100 text-right">
                                                                    <FormattedMessage id="fields.fileSize" />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.files.map(file => (
                                                                <tr
                                                                    key={`file-${file.id}`}
                                                                    onClick={e => {
                                                                        this.onFileClick(e, file)
                                                                    }}
                                                                    className="row-selectable"
                                                                >
                                                                    <td className="w-60 table-buttons">
                                                                        <button className="far fa-download text-gray m-r-xxs" />
                                                                    </td>
                                                                    <td className="text-center">{formatters.datetimeFormatter(file.datetime)}</td>
                                                                    <td>{file.user && file.user.name}</td>
                                                                    <td>{file.name}</td>
                                                                    <td className="text-center">{file.extension}</td>
                                                                    <td className="text-right">{file.size}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="alert alert-warning pull-left wp-100">
                                                <p>
                                                    <FormattedMessage id="fields.noFilesUploaded" />
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="row m-t-md">
                                    <div className="col-md-12">
                                        <p>
                                            <FormattedMessage id="fields.createdBy" />:{' '}
                                            {data.user ? formatters.fullName(data.user.first_name, data.user.last_name) : '-'}
                                        </p>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="h-200" />
                        )}
                    </LoadingOverlay>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: getReceivedInvoiceDetail(state),
        dataFetching: getReceivedInvoiceDetailFetching(state),
        dataError: getReceivedInvoiceDetailError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ReceivedInvoiceDetail)
)
