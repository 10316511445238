const translations = {
    // Login
    'login.title': 'Fleetingo',
    'login.login': 'Sign in to your account',
    'login.forgottenPassword': 'Forgotten password',
    'login.setPassword': 'Set password',
    'login.copyright': 'Fleetingo s.r.o.',

    // Auth
    'auth.notLoggedIn': 'You are not authenticated. Please log in.',
    'auth.pleaseWait': 'Please wait...',

    // Top line
    'topLine.title': 'Fleetingo',
    'topLine.messages': 'Messages',
    'topLine.signOut': 'Sign out',
    'topLine.help': 'Help',

    // Messages
    'messages.headline': 'Messages',
    'messages.find': 'Find...',
    'messages.noMessage': 'No message',
    'messages.writeMessage': 'Write message...',
    'messages.send': 'Send',
    'messages.fromDriver': 'From driver',
    'messages.state.sending': 'Sending',
    'messages.state.sent': 'Sent',
    'messages.state.received': 'Received',
    'messages.state.read': 'Read',
    'messages.state.markAsRead': 'Mark as read',
    'messages.subscribed': 'Notifications on',
    'messages.unsubscribed': 'Notifications off',

    // Menu
    'menuItems.transports': 'Transports',
    'menuItems.transportTemplates': 'Transport templates',
    'menuItems.transporeonOffers': 'Transporeon offers',
    'menuItems.tracing': 'Tracing',
    'menuItems.dispatcherBoard': 'Dispatcher board',
    'menuItems.map': 'Map',
    'menuItems.reports': 'Reports',
    'menuItems.reportTransports': 'Transports',
    'menuItems.reportTransportDetails': 'Economic results',
    'menuItems.reportSpeditionTransportDetails': 'Economic results of spedition transports',
    'menuItems.reportTransportChanges': 'Changes',
    'menuItems.reportTransportInvoicing': 'Invoicing',
    'menuItems.reportTransportOrders': 'Orders',
    'menuItems.reportVehicles': 'Vehicles',
    'menuItems.reportVehicleKilometersTotal': 'Total kilometers',
    'menuItems.reportVehiclePerformance': 'Performance',
    'menuItems.reportVehicleTraffic': 'Traffic',
    'menuItems.reportVehicleChecks': 'Checks and damages',
    'menuItems.reportVehicleFuelings': 'Refueling',
    'menuItems.reportTrailerFuelings': 'Refueling',
    'menuItems.reportCustomers': 'Customers',
    'menuItems.reportCustomerResults': 'Results',
    'menuItems.reportCustomerPlans': 'Transport plan',
    'menuItems.reportCustomerMonthlyPlans': 'Monthly plan',
    'menuItems.reportCustomerWeeklyPlans': 'Week plan',
    'menuItems.reportCostCenters': 'Cost centers',
    'menuItems.reportCostCenterResults': 'Results',
    'menuItems.reportCostCenterTraffic': 'Traffic',
    'menuItems.reportCostCenterPerformance': 'Performance',
    'menuItems.reportCostCenterProfits': 'Economic results',
    'menuItems.reportCostCenterCost': 'Costs',
    'menuItems.reportOther': 'Other',
    'menuItems.reportCustomerTurnovers': 'Customers',
    'menuItems.reportEmptyKilometers': 'Empty kilometers',
    'menuItems.reportPallets': 'Pallets',
    'menuItems.reportInvoicingSummary': 'Summary',
    'menuItems.reportDrivers': 'Drivers',
    'menuItems.reportDriverAllowances': 'Diets and rewards',
    'menuItems.reportDriverPerformanceAnalysis': 'Performance analysis',
    'menuItems.reportDriverHourlyOverview': 'Hourly overview',
    'menuItems.reportDriverTimesheet': 'Work report',
    'menuItems.reportCarriers': 'Carriers',
    'menuItems.reportCarrierResults': 'Results',
    'menuItems.reportCarrierScoring': 'Rating',
    'menuItems.reportCarrierUsage': 'Usage',
    'menuItems.reportDispatchers': 'Dispatchers',
    'menuItems.reportDispatcherKPI': 'KPI',
    'menuItems.reportAppUsage': 'Application usage',
    'menuItems.fleet': 'Fleet',
    'menuItems.vehicles': 'Vehicles',
    'menuItems.trailers': 'Trailers',
    'menuItems.vehicleEvents': 'Events',
    'menuItems.vehicleFuelings': 'Refueling',
    'menuItems.vehiclePositions': 'Positions',
    'menuItems.drivers': 'Drivers',
    'menuItems.addressBook': 'Address book',
    'menuItems.customers': 'Customers',
    'menuItems.customerAddresses': 'Addresses',
    'menuItems.carriers': 'Carriers',
    'menuItems.borderCrossings': 'Border crossings',
    'menuItems.settings': 'Settings',
    'menuItems.users': 'Users',
    'menuItems.userRoles': 'User roles',
    'menuItems.userLogins': 'Login log',
    'menuItems.blockedIps': 'Blocked IP addresses',
    'menuItems.countries': 'Countries',
    'menuItems.currencies': 'Currencies',
    'menuItems.companies': 'Companies',
    'menuItems.transportTypes': 'Transport types',
    'menuItems.vehicleTypes': 'Vehicle types',
    'menuItems.trailerTypes': 'Trailer types',
    'menuItems.cargoTypes': 'Cargo types',
    'menuItems.surchargeTypes': 'Surcharge types',
    'menuItems.customerAddressTypes': 'Address types',
    'menuItems.goodsTypes': 'Goods types',
    'menuItems.documentTypes': 'Document types',
    'menuItems.driverDocuments': 'Documents',
    'menuItems.vehicleDocuments': 'Documents',
    'menuItems.trailerDocuments': 'Documents',
    'menuItems.driverHistory': 'History',
    'menuItems.vehicleHistory': 'History',
    'menuItems.trailerHistory': 'History',
    'menuItems.equipments': 'Equipment',
    'menuItems.driverEquipments': 'Equipment',
    'menuItems.vehicleEquipments': 'Equipment',
    'menuItems.trailerEquipments': 'Equipment',
    'menuItems.driverTimesheets': 'Worksheets',
    'menuItems.taxRates': 'Tax rates',
    'menuItems.paymentMethods': 'Payment methods',
    'menuItems.invoicing': 'Invoicing',
    'menuItems.invoicingOrders': 'Orders',
    'menuItems.invoicingInvoices': 'Issued invoices',
    'menuItems.invoicingReceivedInvoices': 'Received invoices',
    'menuItems.invoicingPayments': 'Payments',
    'menuItems.packagingTypes': 'Packaging types',
    'menuItems.transportConditions': 'Transport conditions',
    'menuItems.invoiceItemTypes': 'Invoice item types',
    'menuItems.general': 'General',
    'menuItems.transportScoring': 'Transport rating',
    'menuItems.carrierScoring': 'Carrier rating',
    'menuItems.generalSettings': 'General settings',
    'menuItems.dietRates': 'Diet rates',
    'menuItems.costTypes': 'Types of costs',
    'menuItems.cost': 'Costs',
    'menuItems.costs': 'Costs',
    'menuItems.costGenerators': 'Recurring costs',
    'menuItems.salesTypes': 'Types of sales',
    'menuItems.driversTypes': 'Types of drivers',
    'menuItems.sales': 'Sales',

    // Fields
    'fields.showWithTrashed': 'Show with trashed',
    'fields.showOnlyTrashed': 'Show only trashed',
    'fields.hoursCount': 'Hours count',
    'fields.ignition': 'Ignition',
    'fields.motor': 'Engine',
    'fields.settings': 'Settings',
    'fields.invoice_footer': 'Invoice footer',
    'fields.transport_footer': 'Transport order/offer footer',
    'fields.email': 'E-mail',
    'fields.password': 'Password',
    'fields.findByAddress': 'Find by address',
    'fields.findByGPS': 'Find by GPS',
    'fields.yes': 'Yes',
    'fields.no': 'No',
    'fields.number': 'Number',
    'fields.documentNumber': 'Document number',
    'fields.createdAt': 'Created at',
    'fields.state': 'State',
    'fields.disponent': 'Managing clerk',
    'fields.customer': 'Customer',
    'fields.transportType': 'Transport type',
    'fields.cargoType': 'Cargo type',
    'fields.goodsType': 'Goods type',
    'fields.loading': 'Loading',
    'fields.loadingDate': 'Loading date',
    'fields.loadingTime': 'Loading time',
    'fields.loadingDateTime': 'Loading date and time',
    'fields.loadingAddress': 'Loading address',
    'fields.loadingCity': 'Loading city',
    'fields.loadingCountry': 'Loading country',
    'fields.unloading': 'Unloading',
    'fields.unloadingDate': 'Unloading date',
    'fields.unloadingTime': 'Unloading time',
    'fields.unloadingDateTime': 'Date and time of unloading',
    'fields.unloadingAddress': 'Unloading address',
    'fields.unloadingCity': 'Unloading city',
    'fields.unloadingCountry': 'Unloading country',
    'fields.address': 'Address',
    'fields.ipAddress': 'IP address',
    'fields.cidr': 'CIDR',
    'fields.userAgent': 'User agent',
    'fields.time': 'Time',
    'fields.from': 'From',
    'fields.fromPlace': 'From where',
    'fields.to': 'To',
    'fields.toPlace': 'Where',
    'fields.code': 'Code',
    'fields.vehicle': 'Vehicle',
    'fields.registration': 'Registration',
    'fields.dispatcher': 'Dispatcher',
    'fields.trailer': 'Trailer',
    'fields.driver': 'Driver',
    'fields.price': 'Price',
    'fields.pricePerKm': 'Price / KM',
    'fields.priceCZK': 'Price CZK',
    'fields.profitKM': 'Profit / KM',
    'fields.profitKMCZK': 'CZK / KM',
    'fields.costsKM': 'Costs / KM',
    'fields.sentAt': 'Sent at',
    'fields.approach': 'Approach',
    'fields.approachDateTime': 'Date and time of approach',
    'fields.plannedApproach': 'Planned approach',
    'fields.approachFormTransportNumber': 'Approach from transport no.',
    'fields.difference': 'Difference',
    'fields.arrival': 'Arrival',
    'fields.eta': 'ETA',
    'fields.note': 'Note',
    'fields.orderNumber': 'Order number',
    'fields.spedition': 'Forwarding',
    'fields.autoCabotage': 'Auto',
    'fields.cabotage': 'Cabotage',
    'fields.trailerRegistration': 'Trailer reg.',
    'fields.vehicleRegistration': 'Vehicle reg.',
    'fields.driverName': 'Driver name',
    'fields.driverPhone': 'Phone on driver',
    'fields.unknownTrailer': 'Unknown trailer',
    'fields.archived': 'Archived',
    'fields.carrier': 'Carrier',
    'fields.basePrice': 'Base price',
    'fields.totalPrice': 'Total price',
    'fields.totalDistance': 'Total distance',
    'fields.odometerState': 'Mileage (start/end)',
    'fields.odometerDistance': 'Difference (start/end)',
    'fields.totalConsumption': 'Total consumption',
    'fields.avgConsumption': 'Average consumption',
    'fields.fueling': 'Refueled',
    'fields.log': 'Log',
    'fields.date': 'Date',
    'fields.dateTime': 'Date and time',
    'fields.user': 'User',
    'fields.changes': 'Changes',
    'fields.pcs': 'Pcs',
    'fields.pcsToLoad': 'Amount to be loaded',
    'fields.pcsToUnload': 'Amount to be unloaded',
    'fields.weight': 'Weight',
    'fields.temperature': 'Temperature',
    'fields.temperatureFrom': 'Temperature from',
    'fields.temperatureTo': 'Temperature to',
    'fields.palletExchange': 'Pallet exchange',
    'fields.palletExchangeIssued': 'Issued pallets',
    'fields.palletExchangeReceived': 'Received pallets',
    'fields.count': 'Count',
    'fields.userCount': 'Number of users',
    'fields.plannedCount': 'Planned count',
    'fields.fuelingType': 'Amount',
    'fields.fuelingTypeFull': 'Full',
    'fields.fuelingTypeMinimal': 'Minimal',
    'fields.phone': 'Phone',
    'fields.reason': 'Reason',
    'fields.defect': 'Defect',
    'fields.defectDescription': 'Defect description',
    'fields.plannedEndTime': 'Planned end time',
    'fields.vehicleDefect': 'Vehicle defect',
    'fields.vehicleDefectDescription': 'Vehicle defect description',
    'fields.trailerDefect': 'Trailer defect',
    'fields.trailerDefectDescription': 'Trailer defect description',
    'fields.documentsDefect': 'Documents defect',
    'fields.documentsDefectDescription': 'Documents defect description',
    'fields.withObjection': 'With objection',
    'fields.sourceTransport': 'Source transport',
    'fields.template': 'Template',
    'fields.transporeonOffersNumber': 'SCI',
    'fields.pointType': 'Point type',
    'fields.pointName': 'Point name',
    'fields.eventType': 'Event type',
    'fields.transportState': 'Transport state',
    'fields.customersOrderNumber': 'Order number (customer)',
    'fields.customersPrice': 'Price (customer)',
    'fields.customersCurrency': 'Currency (customer)',
    'fields.customersNote': 'Note (customer)',
    'fields.vehicleNote': 'Note (vehicle)',
    'fields.carriersOrderNumber': 'Order number (carrier)',
    'fields.carriersPrice': 'Price (carrier)',
    'fields.carriersCurrency': 'Currency (carrier)',
    'fields.carriersNote': 'Note (carrier)',
    'fields.predepartureCheck': 'Predeparture check',
    'fields.ordering': 'Order',
    'fields.plannedTime': 'Planned time',
    'fields.plannedTimeFrom': 'Planned time from',
    'fields.plannedTimeTo': 'Planned time to',
    'fields.specifiedTime': 'Specified time',
    'fields.specifiedTimeFrom': 'Specified time from',
    'fields.specifiedTimeTo': 'Specified time to',
    'fields.bookingTime': 'Booking time',
    'fields.bookingTimeFrom': 'Booking time from',
    'fields.bookingTimeTo': 'Booking time to',
    'fields.arrivalTime': 'Arrival time',
    'fields.finishedTime': 'Finished time',
    'fields.companyName': 'Company name',
    'fields.customerName': 'Company name / customer name',
    'fields.carrierName': 'Company name / carrier name',
    'fields.street': 'Street',
    'fields.city': 'City',
    'fields.zipcode': 'Zipcode',
    'fields.country': 'Country',
    'fields.gpsLat': 'GPS lat',
    'fields.gpsLng': 'GPS lng',
    'fields.driver2': 'Driver 2',
    'fields.mileage': 'Mileage',
    'fields.gps': 'GPS',
    'fields.attachments': 'Attachments',
    'fields.transport': 'Transport',
    'fields.transportNo': 'Transport no.',
    'fields.plannedKilometers': 'Planned kilometers',
    'fields.realKilometers': 'Real kilometers',
    'fields.times': 'Times',
    'fields.totalTime': 'Total time',
    'fields.planned': 'Planned',
    'fields.specified': 'Specified',
    'fields.booking': 'Booking',
    'fields.finish': 'Finish',
    'fields.details': 'Details',
    'fields.event': 'Event',
    'fields.incorrectTime': 'Incorrect time',
    'fields.duration': 'Duration',
    'fields.hours': 'h',
    'fields.hoursVerbally': 'Hours',
    'fields.daysVerbally': 'Days',
    'fields.minutes': 'm',
    'fields.minutesVerbally': 'Minutes',
    'fields.eg': 'e.g.',
    'fields.addressType': 'Address type',
    'fields.name': 'Name',
    'fields.plannedRoute': 'Planned route',
    'fields.specifiedRoute': 'Specified route',
    'fields.realRoute': 'Real route',
    'fields.routeDetails': 'Route details',
    'fields.distance': 'Distance',
    'fields.transportDuration': 'Transport duration',
    'fields.requiredTime': 'Required time',
    'fields.drive': 'Drive',
    'fields.pause': 'Pause',
    'fields.rests': 'Rests',
    'fields.breaks': 'Breaks',
    'fields.priceAndCosts': 'Price and costs',
    'fields.totalCosts': 'Total costs',
    'fields.fuel': 'Fuel',
    'fields.adblue': 'AdBlue',
    'fields.fuel_level': 'Palivo',
    'fields.adblue_level': 'AdBlue',
    'fields.fuelingCompany': 'Supplier gas station',
    'fields.fuelingCompanyName': "The name of the supplier's service station",
    'fields.toll': 'Toll',
    'fields.tollPasses': 'Toll passes',
    'fields.fixCosts': 'Fix costs',
    'fields.templateName': 'Template name',
    'fields.route': 'Route',
    'fields.lenght': 'Length',
    'fields.volume': 'Volume',
    'fields.trailerNote': 'Note (trailer)',
    'fields.informationUnavailable': 'Information is unavailable',
    'fields.vehicleIsMoving': 'Vehicle is moving',
    'fields.vehicleIsMovingAt': 'Vehicle is moving at',
    'fields.vehicleIsStanding': 'Vehicle is standing',
    'fields.updatedAt': 'Updated at',
    'fields.etaUpdatedAt': 'ETA updated at',
    'fields.trailerLoaded': 'Trailer loaded',
    'fields.trailerEmpty': 'Trailer empty',
    'fields.trailerDamage': 'Trailer damage',
    'fields.trailerOk': 'Trailer OK',
    'fields.trailerDamaged': 'Trailer damaged',
    'fields.security': 'Trailer security',
    'fields.securityOk': 'Security OK',
    'fields.securityDamaged': 'Security damaged',
    'fields.securedTrailers': 'Secured trailers',
    'fields.unsecuredTrailers': 'Unsecured trailers',
    'fields.trailerCooling': 'Trailer cooling',
    'fields.coolingOk': 'Cooling OK',
    'fields.coolingDamaged': 'Cooling damaged',
    'fields.cooledTrailers': 'Cooled trailers',
    'fields.notCooledTrailers': 'Not cooled trailer',
    'fields.week': 'Week',
    'fields.previousWeek': 'Previous week',
    'fields.nextWeek': 'Next week',
    'fields.previousDay': 'Previous day',
    'fields.day': 'Day',
    'fields.day4': 'days',
    'fields.nextDay': 'Next day',
    'fields.year': 'Year',
    'fields.previousYear': 'Previous year',
    'fields.nextYear': 'Next year',
    'fields.month': 'Month',
    'fields.previousMonth': 'Previous month',
    'fields.nextMonth': 'Next month',
    'fields.company': 'Company',
    'fields.costCenter': 'Cost center',
    'fields.costCenters': 'Cost centers',
    'fields.total': 'Total',
    'fields.mean': 'Mean',
    'fields.km': 'KM',
    'fields.kmFull': 'Full',
    'fields.kmApproach': 'Approach',
    'fields.kmService': 'Service',
    'fields.kmEmpty': 'Empty',
    'fields.approachFrom': 'Approach from',
    'fields.approachTo': 'Approach to',
    'fields.loaded': 'Loaded',
    'fields.unloaded': 'Unloaded',
    'fields.balance': 'Balance',
    'fields.checkType': 'Check type',
    'fields.defects': 'Defects',
    'fields.defectType': 'Defect type',
    'fields.notes': 'Notes',
    'fields.fbId': 'FB ID',
    'fields.vin': 'VIN',
    'fields.vehicleType': 'Vehicle type',
    'fields.vehicleState': 'Vehicle state',
    'fields.trailerState': 'Trailer state',
    'fields.fbConfiguration': 'FB configuration',
    'fields.active': 'Active',
    'fields.itemEdit': 'Edit item',
    'fields.itemCreate': 'Create new item',
    'fields.trailerType': 'Trailer type',
    'fields.damaged': 'Damaged',
    'fields.firstName': 'First name',
    'fields.lastName': 'Last name',
    'fields.fullName': 'Full name',
    'fields.language': 'Language',
    'fields.regNumber': 'CRN',
    'fields.vatNumber': 'TAX ID',
    'fields.blocked': 'Blocked',
    'fields.allowed': 'Allowed',
    'fields.icon': 'Icon',
    'fields.color': 'Color',
    'fields.textColor': 'Text color',
    'fields.bgColor': 'Background color',
    'fields.isAdmin': 'Administrator',
    'fields.userRole': 'Role',
    'fields.isoCode': 'ISO code',
    'fields.isoCode2': 'ISO code (2)',
    'fields.isoCode3': 'ISO code (3)',
    'fields.currency': 'Currency',
    'fields.tollPerKm': 'Toll per KM',
    'fields.tollPerDay': 'Toll per day',
    'fields.minimumHourWageInSelectedCurrency': 'Minimum hour wage (in selected currency)',
    'fields.minimumHourWage': 'Minimum hour wage',
    'fields.default': 'Default',
    'fields.exchangeRate': 'Rate',
    'fields.minProfit': 'Minimal profit',
    'fields.dimensions': 'Dimensions',
    'fields.width': 'Width',
    'fields.height': 'Height',
    'fields.tankVolume': 'Tank volume',
    'fields.axlesCount': 'Axles count',
    'fields.avgFuelConsumption': 'Avg. fuel consumption',
    'fields.avgAdBlueConsumption': 'Avg. AdBlue consumption',
    'fields.fuelLevel': 'Fuel [l]',
    'fields.adblueLevel': 'AdBlue [l]',
    'fields.secured': 'Secured',
    'fields.cooled': 'Cooled',
    'fields.transportTypes': 'Transport types',
    'fields.vehicleTypes': 'Vehicle types',
    'fields.cargoTypes': 'Cargo types',
    'fields.goodsTypes': 'Goods types',
    'fields.securityRequired': 'Security required',
    'fields.coolingRequired': 'Cooling required',
    'fields.trailerIsNotSecured': 'Trailer is not secured',
    'fields.trailerIsNotCooled': 'Trailer is not cooled',
    'fields.currentPosition': 'Current position',
    'fields.eventCreatedByDriver': 'Created by driver (in DP)',
    'fields.eventCreatedByDispatcher': 'Created by dispatcher (manually)',
    'fields.capacityPallets': 'Pallets cap.',
    'fields.noVehicle': 'No vehicle',
    'fields.noTrailer': 'No trailer',
    'fields.noDriver': 'No driver',
    'fields.noTime': 'No time',
    'fields.trailerEquipments': 'Trailer equipments',
    'fields.nameInTransporeon': 'Name in Transporeon',
    'fields.navigationStart': 'Turn on navigation',
    'fields.navigationStop': 'Turn off navigation',
    'fields.speed': 'Speed',
    'fields.isSpedition': 'Foreign',
    'fields.legend': 'Legend',
    'fields.all': 'All',
    'fields.transportPointState': 'Transport point state',
    'fields.transportPointType': 'Transport point type',
    'fields.preferences': 'Preferences',
    'fields.relevantForDrivers': 'To drivers',
    'fields.relevantForVehicles': 'To vehicles',
    'fields.relevantForTrailers': 'To trailers',
    'fields.notify': 'Notify',
    'fields.notifyInList': 'Notify in list',
    'fields.notifyInBoard': 'Notify in board',
    'fields.daysInAdvance': 'Days in advance',
    'fields.documentType': 'Document type',
    'fields.validityFrom': 'Validity from',
    'fields.validityTo': 'Validity to',
    'fields.validityExpiresIn': 'Expires in',
    'fields.abbrDays': 'd',
    'fields.invoicingAddress': 'Invoicing address',
    'fields.defaultInvoicingAddress': 'Default invoicing address',
    'fields.deliveryAddress': 'Delivery address',
    'fields.contacts': 'Contacts',
    'fields.invoicingSettings': 'Invoicing settings',
    'fields.defaultDeadline': 'Default maturity [days]',
    'fields.invoicingEmail': 'Invoicing e-mail',
    'fields.invoicingNote': 'Invoicing note',
    'fields.limitsAndBlocking': 'Limits and blocking',
    'fields.limitUnpaidInvoices': 'Limit for unpaid receivables [CZK]',
    'fields.limitOverdueInvoices': 'Limit for overdue receivables [CZK]',
    'fields.bookingRequired': 'Booking for loading/unloading is required',
    'fields.bookingURL': 'URL for booking',
    'fields.bookingNote': 'Note for booking',
    'fields.history': 'History',
    'fields.loginLog': 'Login log',
    'fields.documents': 'Documents',
    'fields.events': 'Events',
    'fields.timesheets': 'Timesheets',
    'fields.importHistory': 'Imports history',
    'fields.fuelings': 'Refueling',
    'fields.noItemsFound': 'No items found',
    'fields.addresses': 'Addresses',
    'fields.shippingOrder': 'Shipping order',
    'fields.client': 'Client',
    'fields.vehicleRequirements': 'Conditions of Carriage / Vehicle Requirements',
    'fields.temperatureRecorder': 'Temperature recorder',
    'fields.temperatureControl': 'Temperature controlled trailer',
    'fields.frcCooling': 'ATP/FRC cooling -20°C',
    'fields.dividingWall': 'Dividing wall',
    'fields.doubleDeckerTrailer': 'Double-decker trailer',
    'fields.curtain': 'Curtain',
    'fields.vehicleAndDriver': 'Vehicle / driver',
    'fields.vehiclePhone': 'Telephone in the vehicle',
    'fields.loadingPlaces': 'Places of loading',
    'fields.customHouse': 'Custom house',
    'fields.allowedParking': 'Allowed parking',
    'fields.unloadingPlaces': 'Places of unloading',
    'fields.shippingPrice': 'Shipping price',
    'fields.shippingPriceInclToll': 'Shipping price incl. toll',
    'fields.boatBooking': 'Boat booking',
    'fields.hotline': 'Hotline',
    'fields.attachedDocuments': 'Attached Documents',
    'fields.otherArrangements': 'Another arrangement',
    'fields.bankAccounts': 'Bank accounts',
    'fields.bankAccount': 'Bank account',
    'fields.bankAccountNumber': 'Account number',
    'fields.bankAccountIBAN': 'IBAN',
    'fields.bankAccountSWIFT': 'SWIFT',
    'fields.bankCode': 'Bank code',
    'fields.foundAddressesTitle': 'Found addresses',
    'fields.foundAddressesText':
        'We found the addresses near this location. Click to select the one you want to use. If you want to save a new address, click the button below.',
    'fields.foundAddressesTextNoSave': 'We found the addresses near this location. Click to select the one you want to use.',
    'fields.dropzoneReady': 'Drop the files here or click to select the files to upload',
    'fields.dropzoneAccept': 'Drop the files here',
    'fields.fileType': 'File type',
    'fields.fileName': 'File Name',
    'fields.ImportFileName': 'Import file name',
    'fields.ImportDateTime': 'Import date and time',
    'fields.fileSize': 'File size',
    'fields.noFilesUploaded': 'No files have been uploaded yet.',
    'fields.show': 'Show',
    'fields.monthsAbbr': 'm.',
    'fields.defaultValidity': 'Default validity',
    'fields.defaultValidityInMonths': 'Default validity [m.]',
    'fields.defaultLifetime': 'Default lifetime',
    'fields.defaultLifetimeInMonths': 'Default lifetime [m.]',
    'fields.equipment': 'Equipment',
    'fields.passedAt': 'Passed',
    'fields.lifetimeTo': 'Lifetime to',
    'fields.showChangesFrom': 'Show changes from',
    'fields.showOnlyChangedTransports': 'Show only changed transports',
    'fields.cost': 'Cost',
    'fields.costType': 'Cost type',
    'fields.costs': 'Costs',
    'fields.profit': 'Profit',
    'fields.profitPerc': 'Profit %',
    'fields.plannedProfit': 'Planned profit',
    'fields.period': 'Period',
    'fields.numberings': 'Numerical series',
    'fields.format': 'Format',
    'fields.prefix': 'Prefix',
    'fields.separator': 'Separator',
    'fields.timeFormat': 'Time format',
    'fields.dateFormat': 'Date format',
    'fields.yearFormat': 'Year format',
    'fields.showMonthNumber': 'Use month number',
    'fields.maxRange': 'Maximal range',
    'fields.defaultCurrency': 'Default currency',
    'fields.value': 'Value',
    'fields.invoicingState': 'Invoicing state',
    'fields.changeTo': 'Change to',
    'fields.supplier': 'Supplier',
    'fields.numbering': 'Numbering',
    'fields.paymentMethod': 'Payment method',
    'fields.dateIssue': 'Date of issue',
    'fields.imported': 'Imported',
    'fields.importedAlert': 'Attention, this invoice has already been imported. Any changes will not be transferred.',
    'fields.dateDue': 'Due date',
    'fields.dateTax': 'Date of tax',
    'fields.taxTransactionType': 'Tax transaction type',
    'fields.taxRateType': 'Type of tax rate',
    'fields.varSymbol': 'Variable symbol',
    'fields.unit': 'Unit',
    'fields.item': 'Item',
    'fields.unitPrice': 'Unit price',
    'fields.totalExclVat': 'Total excl. VAT',
    'fields.totalInclVat': 'Total incl. VAT',
    'fields.vat': 'VAT',
    'fields.invoiceNo': 'Invoice no.',
    'fields.invoicingItems': 'We are billing you the following items',
    'fields.dietRate': 'Rate',
    'fields.taxRate': 'Tax rate',
    'fields.noTaxRate': 'No tax rate',
    'fields.base': 'Base',
    'fields.totalToPay': 'Total to pay',
    'fields.rate': 'Rate',
    'fields.forDay': 'for day',
    'fields.createdBy': 'Created by',
    'fields.paid': 'Paid',
    'fields.noGoodsCreated': 'No items have been created yet.',
    'fields.totalWeight': 'Total weight',
    'fields.totalVolume': 'Total volume',
    'fields.packagingType': 'Packaging type',
    'fields.transportIsNotDivided': 'Transport is not divided yet',
    'fields.ownTransport': 'Own transport',
    'fields.transportGoods': 'Transported goods',
    'fields.paymentConditions': 'Maturity',
    'fields.transportVehicleType': 'Transport vehicle type',
    'fields.boatNumber': 'Boat number',
    'fields.trainNumber': 'Train number',
    'fields.flightNumber': 'Flight number',
    'fields.description': 'Description',
    'fields.type': 'Type',
    'fields.general': 'General',
    'fields.conditions': 'Conditions',
    'fields.transportConditions': 'Transport conditions',
    'fields.invoiceItemType': 'Item type',
    'fields.suppliersNumber': 'Suppliers number',
    'fields.dateReceived': 'Received on',
    'fields.datePaid': 'Payment date',
    'fields.approved': 'Approved',
    'fields.notApproved': 'Not approved',
    'fields.approve': 'Approve',
    'fields.unpaid': 'Unpaid',
    'fields.overdue': 'Overdue',
    'fields.setAsPaid': 'Mark as paid',
    'fields.setAsUnpaid': 'Cancel payment',
    'fields.creditNote': 'Credit note',
    'fields.creditNoteNo': 'Credit note no.',
    'fields.forInvoiceNo': 'For invoice no.',
    'fields.inEU': 'In EU',
    'fields.files': 'Files',
    'fields.preloading': 'Pre-loading',
    'fields.vehicleCount': 'Vehicle count',
    'fields.transportCount': 'Transport count',
    'fields.businessManager': 'Business manager',
    'fields.costUser': 'Cost for worker',
    'fields.position': 'Position',
    'fields.transportPlan': 'Transport plan',
    'fields.speditionPlan': 'Spedition plan',
    'fields.margin': 'Margin',
    'fields.offer': 'Offer',
    'fields.order': 'Order',
    'fields.orderConfirm': 'Order confirmation',
    'fields.sendToEmail': 'Send to email',
    'fields.correctEventTimes': 'Automatic time correction',
    'fields.finishQuickly': 'Finish quickly',
    'fields.allowances': 'Allowances',
    'fields.createAllowance': 'Create allowance',
    'fields.allowanceAmount': 'Allowance amount',
    'fields.noAllowancesFound': 'No allowances found',
    'fields.allowanceType': 'Allowance type',
    'fields.allowanceCalculationMethod': 'Method of calculation',
    'fields.actions': 'Actions',
    'fields.differentInMinutes': 'Different [min.]',
    'fields.saveThisEvent': 'Create this event',
    'fields.transportDescription': 'Transport description',
    'fields.noTransportsFound': 'No transports found',
    'fields.vatPayer': 'VAT payer',
    'fields.factoring': 'Factoring',
    'fields.factoringCompanyName': 'Factoring: Company name',
    'fields.factoringCompanyRegNumber': 'Factoring: Company registration number',
    'fields.email_invoicing': 'E-mail invoicing',
    'fields.doNotInvoice': 'Do not invoice',
    'fields.defaultBankAccountsSettings': 'Default bank accounts settings',
    'fields.defaultCompanyBankAccount': 'Default company bank account',
    'fields.defaultInvoicingBankAccounts': 'Default bank accounts for invoicing',
    'fields.defaultSupplier': 'Default supplier',
    'fields.domesticCustomers': 'Domestic customers',
    'fields.domesticDietRate': 'Domestic diet rate',
    'fields.foreignCountryCustomers': 'Foreign customers',
    'fields.paidAt': 'Paid at',
    'fields.paidAmount': 'Paid amount',
    'fields.scoringNoQuestions': 'No questions to evaluate transportation.',
    'fields.scoringNoAnswers': 'This question has no answers.',
    'fields.scoringNoRules': 'There are no rating rules.',
    'fields.question': 'Question',
    'fields.answer': 'Answer',
    'fields.rule': 'Rule',
    'fields.pointsCount': 'Points',
    'fields.pointsCountFrom': 'From points',
    'fields.pointsCountTo': 'To points',
    'fields.mark': 'Rating',
    'fields.text': 'Text',
    'fields.createQuestion': 'Create qustion',
    'fields.createAnswer': 'Create answer',
    'fields.createRule': 'Create rule',
    'fields.scoringNotSetYet': 'The evaluation has not been carried out yet',
    'fields.scoring': 'Rating',
    'fields.calculatedFromTransportScoring': 'Calculated from transport rating',
    'fields.outgoingInvoices': 'Outgoing invoices',
    'fields.receivedInvoices': 'Received invoices',
    'fields.invoiced': 'Invoiced',
    'fields.uninvoiced': 'Uninvoiced',
    'fields.showSpeditions': 'Show with forwarding transports',
    'fields.showTrailersOut': 'Show with trailers out',
    'fields.sales': 'Revenues',
    'fields.plannedSales': 'Planned revenues',
    'fields.minimum': 'Minimum',
    'fields.optimum': 'Optimum',
    'fields.transport_plans': 'Transport plans',
    'fields.transports': 'Transports',
    'fields.speditions': 'Speditions',
    'fields.dateFrom': 'From',
    'fields.dateTo': 'To',
    'fields.borderCrossing': 'Border crossing',
    'fields.activity': 'Activity',
    'fields.dailyCost': 'Daily cost',
    'fields.paidRoutes': 'Paid sections',
    'fields.telematicsDataSource': 'Telematics data source',
    'fields.telematicsDataId': 'Telematics data ID',
    'fields.logisticDataSource': 'Logistic data source',
    'fields.logisticDataId': 'Logistic data ID',
    'fields.notificationSettings': 'Notification settings',
    'fields.itemFetching': 'loading',
    'fields.notifications_use_system': 'Use system notifications for display',
    'fields.notifications_general': 'General notifications',
    'fields.notifications_transports_load_arrived': 'Transports - Arrival for loading (time, transport number, vehicle, driver, city)',
    'fields.notifications_transports_load_wait': 'Transports - Waiting for loading (+ reason for waiting)',
    'fields.notifications_transports_load_finished': 'Transport - End of loading',
    'fields.notifications_transports_unload_arrival': 'Transport - Arrival for unloading',
    'fields.notifications_transports_unload_wait': 'Transport - Waiting for unloading',
    'fields.notifications_transports_unload_finished': 'Transport - End of unloading',
    'fields.notifications_transports_state_changed_processing': 'Transport - Change status to: Processing (shipment no., Time)',
    'fields.notifications_transports_state_changed_delivered': 'Transport - Change status to: Completed',
    'fields.notifications_transports_state_changed_canceled': 'Transport - Change status to: Canceled',
    'fields.notifications_transports_approval': 'Transport - Application for approval',
    'fields.notifications_transporeon_offers': 'Transporeon Quotes - Insert a new offer',
    'fields.showMarkers': 'Route view - Show other vehicles',
    'fields.notifications_vehicle_message': 'Chat',
    'fields.cardNumber': 'Card number',
    'fields.invoicingDistance': 'Invoiced km',
    'fields.repeat': 'Repeat',
    'fields.person': 'Person',
    'fields.showFuturePayments': 'Show future payments ',
    'fields.yearOfManufacture': 'Year of manufacture',
    'fields.soldAt': 'Date sold',

    // Buttons
    'buttons.signIn': 'Sign in',
    'buttons.find': 'Find',
    'buttons.createItem': 'Create item',
    'buttons.details': 'Details',
    'buttons.showDetail': 'Show detail',
    'buttons.edit': 'Edit',
    'buttons.openEdit': 'Open / edit',
    'buttons.copy': 'Copy',
    'buttons.smsCopy': 'Copy to clipboard',
    'buttons.createTemplate': 'Create template',
    'buttons.delete': 'Delete',
    'buttons.restore': 'Restore',
    'buttons.approve': 'Approve',
    'buttons.log': 'Show log',
    'buttons.findVehicle': 'Find vehicle',
    'buttons.back': 'Back',
    'buttons.next': 'Next',
    'buttons.cancel': 'Cancel',
    'buttons.save': 'Save',
    'buttons.saveAndClose': 'Save and close',
    'buttons.done': 'Done',
    'buttons.createTransportPoint': 'Create transport point',
    'buttons.sendToVehicle': 'Send to vehicle',
    'buttons.deleteFromVehicle': 'Delete from vehicle',
    'buttons.createEvent': 'Create event',
    'buttons.editEvent': 'Edit event',
    'buttons.changeState': 'Change state',
    'buttons.showEvents': 'Show events',
    'buttons.hideEvents': 'Hide events',
    'buttons.showNaviEvents': 'Show navigation events',
    'buttons.hideNaviEvents': 'Hide navigation events',
    'buttons.findAddress': 'Find address',
    'buttons.saveAddress': 'Save address',
    'buttons.addRow': 'Add row',
    'buttons.createTransports': 'Create transport(s)',
    'buttons.createTransport': 'Create transport',
    'buttons.accept': 'Accept',
    'buttons.reject': 'Reject',
    'buttons.exportToXLS': 'Export to XLS',
    'buttons.exportDetailsToXLS': 'Export details to XLS',
    'buttons.refresh': 'Reload',
    'buttons.filter': 'Filter',
    'buttons.resetFilters': 'Reset filters',
    'buttons.acceptSelected': 'Accept selected',
    'buttons.rejectSelected': 'Reject selected',
    'buttons.recountRoute': 'Recalculate route',
    'buttons.addRoute': 'Add a route',
    'buttons.clearRoute': 'Delete route',
    'buttons.showGpsPositions': 'Show GPS positions',
    'buttons.hideGpsPositions': 'Hide GPS positions',
    'buttons.addCustomer': 'Add customer',
    'buttons.downloadOrder': 'Download order',
    'buttons.saveAndDownload': 'Save and download',
    'buttons.saveAsNewAddress': 'Save as new address',
    'buttons.createInvoice': 'Create invoice',
    'buttons.setAsInvoiced': 'Set as invoiced',
    'buttons.addItem': 'Add item',
    'buttons.divideTransportToParts': 'Divide transport to parts',
    'buttons.addTransportPart': 'Add next part',
    'buttons.createCreditNote': 'Create credit note',
    'buttons.exportTo': 'Export to',
    'buttons.sendToEmail': 'Send to email',
    'buttons.download': 'Download',
    'buttons.send': 'Send',
    'buttons.createNote': 'Create note',
    'buttons.reassignAllowancesFromDriverConfig': 'Reassign allowances from driver settings',
    'buttons.import': 'Import',
    'buttons.moreInformation': 'More information',
    'buttons.clear': 'Clear',
    'buttons.focusOnMap': 'Focus on map',
    'buttons.select': 'Select',
    'buttons.selectAll': 'Select all',
    'buttons.unselectAll': 'Unselect all',

    // Units
    'units.pcs': 'pcs',
    'units.kg': 'kg',

    // Periods
    'period.1': "Don't repeat",
    'period.2': 'Week',
    'period.3': 'Month',
    'period.4': 'Quarter',
    'period.5': 'Half a year',
    'period.6': 'Year',

    // Sorting
    'sorting.title': 'Sort by',
    'sorting.option.vehicle': 'Vehicle',
    'sorting.option.waterfall': 'Waterfall',

    // Validators
    'validator.email.required': 'E-mail is required',
    'validator.password.required': 'Password is required',

    // Alerts
    'alerts.titles.warning': 'Warning.',
    'alerts.titles.error': 'Error.',
    'alerts.titles.success': 'OK.',

    'alerts.messages.loginFailed': 'We could not log you in, please check your e-mail and password.',
    'alerts.messages.logoutFailed': 'We could not log you out, please try again.',
    'alerts.messages.mailSend': 'E-mail sent.',
    'alerts.messages.mailSendFiled': 'Email could not be sent.',
    'alerts.messages.invalidToken': 'Invalid token.',
    'alerts.messages.passwordDontMatch': 'Passwords do not match.',
    'alerts.messages.passwordChange': 'Password changed successfully.',
    'alerts.messages.createSuccess': 'Item has been successfully created.',
    'alerts.messages.saveSuccess': 'Item has been successfully saved.',
    'alerts.messages.copySuccess': 'Item has been successfully copied.',
    'alerts.messages.deleteSuccess': 'Item has been successfully deleted.',
    'alerts.messages.restoreSuccess': 'Item has been successfully restored.',
    'alerts.messages.approveSuccess': 'Item has been successfully approve.',
    'alerts.messages.transportSendToVehicleSuccess': 'Transport has been successfully sent to vehicle.',
    'alerts.messages.transportDeleteFromVehicleSuccess': 'Transport has been successfully deleted from vehicle.',
    'alerts.messages.createFromTemplateSuccess': 'Transport(s) has been successfully created from template.',
    'alerts.messages.transporeonOfferAcceptSuccess': 'Successfully accepted.',
    'alerts.messages.transporeonOfferRejectSuccess': 'Successfully rejected.',
    'alerts.messages.noteSaveSuccess': 'Note has been successfully saved.',
    'alerts.messages.changesSaveSuccess': 'Changes has been successfully saved.',
    'alerts.messages.addressSaveSuccess': 'Adress has been successfully saved.',
    'alerts.messages.routeCalculateSuccess': 'The route has been successfully recalculated.',
    'alerts.messages.filesUploadSuccess': 'Files successfully uploaded.',
    'alerts.messages.filesDeleteSuccess': 'File successfully deleted.',
    'alerts.messages.invoiceCreateSuccess': 'Invoice has been successfully created.',
    'alerts.messages.exportSuccess': 'Export has been successfully finished.',
    'alerts.messages.transportOrderSendingSuccess': 'Successfully send to email.',
    'alerts.messages.invoiceSendingSuccess': 'Successfully send to email.',
    'alerts.messages.eventTimesCorrectionSuccess': 'Successfully recalculated.',
    'alerts.messages.transportEventsCreatingSuccess': 'Events have been successfully created.',
    'alerts.messages.duplicityFound': 'Duplicity found.',
    'alerts.messages.legendNotFound': 'Legend not found.',
    'alerts.messages.code.9000010': 'IP address is blocked, contact your administrator...',
    'alerts.messages.code.9000020': 'Account is blocked, contact your administrator...',
    'alerts.messages.fileImportSuccess': 'File successfully imported.',
    'alerts.messages.mandatoryItems': 'The following items are required',
    'alerts.messages.calculateRouteNoRouteFound': 'Route not found.',
    'alerts.messages.customerBlockedNeedApproval': 'The customer is blocked and approval is required.',
    'alerts.messages.warningDeleted': 'Attention! You are editing a deleted record. If you wish to restore it, click the restore button.',

    // Loader
    'loader.pleaseWait': 'Please wait...',

    // Confirm dialog
    'confirmDialog.default.title': 'Confirmation',
    'confirmDialog.default.message': 'Do you really want to do this?',
    'confirmDialog.default.accept': 'Confirm',
    'confirmDialog.default.cancel': 'Cancel',

    'confirmDialog.delete.title': 'Delete item?',
    'confirmDialog.delete.message': 'This is an irreversible action, do you really want to delete the item?',
    'confirmDialog.delete.accept': 'Yes, delete',
    'confirmDialog.delete.cancel': 'Cancel',

    'confirmDialog.transportDelete.title': 'Delete transport?',
    'confirmDialog.transportDelete.message':
        'Are you sure you want to delete transport? If the transport has been sent to the vehicle, it will also be deleted from the vehicle.',
    'confirmDialog.transportDelete.accept': 'Yes, delete',
    'confirmDialog.transportDelete.cancel': 'Cancel',

    'confirmDialog.transportRestore.title': 'Restore transport?',
    'confirmDialog.transportRestore.message': 'Are you sure you want to restore transport?',
    'confirmDialog.transportRestore.accept': 'Yes, restore',
    'confirmDialog.transportRestore.cancel': 'Cancel',

    'confirmDialog.itemRestore.title': 'Restore item?',
    'confirmDialog.itemRestore.message': 'Are you sure you want to restore item?',
    'confirmDialog.itemRestore.accept': 'Yes, restore',
    'confirmDialog.itemRestore.cancel': 'Cancel',

    'confirmDialog.transportApproval.title': 'Approve transport?',
    'confirmDialog.transportApproval.message': 'Are you sure you want to approve transport?',
    'confirmDialog.transportApproval.accept': 'Yes, approve',
    'confirmDialog.transportApproval.cancel': 'Cancel',

    'confirmDialog.transportSendToVehicle.title': 'Send transport to vehicle?',
    'confirmDialog.transportSendToVehicle.message': 'Are you sure you want to send transport to vehicle?',
    'confirmDialog.transportSendToVehicle.accept': 'Yes, send',
    'confirmDialog.transportSendToVehicle.cancel': 'Cancel',

    'confirmDialog.transportDeleteFromVehicle.title': 'Delete transport from vehicle?',
    'confirmDialog.transportDeleteFromVehicle.message': 'Are you sure you want to delete transport from vehicle?',
    'confirmDialog.transportDeleteFromVehicle.accept': 'Yes, delete',
    'confirmDialog.transportDeleteFromVehicle.cancel': 'Cancel',

    'confirmDialog.transportTemplateDelete.title': 'Delete template?',
    'confirmDialog.transportTemplateDelete.message': 'Are you sure you want to delete template?',
    'confirmDialog.transportTemplateDelete.accept': 'Yes, delete',
    'confirmDialog.transportTemplateDelete.cancel': 'Cancel',

    'confirmDialog.duplicityFound.title': 'Duplicate',
    'confirmDialog.duplicityFound.message': 'The same or very similar record was found.',
    'confirmDialog.duplicityFound.accept': 'Still save',
    'confirmDialog.duplicityFound.cancel': 'Cancel',

    'confirmDialog.emptyMandatoryFields.title': 'Warning',
    'confirmDialog.emptyMandatoryFields.message': 'Some required data is not filled in.',
    'confirmDialog.emptyMandatoryFields.accept': 'Still save',
    'confirmDialog.emptyMandatoryFields.cancel': 'Cancel',

    'confirmDialog.removeCustomer.title': 'Remove customer?',
    'confirmDialog.removeCustomer.message': 'Do you really want to remove a customer?',
    'confirmDialog.removeCustomer.accept': 'Yes, remove',
    'confirmDialog.removeCustomer.cancel': 'Cancel',

    'confirmDialog.deletePoint.title': 'Delete waypoint?',
    'confirmDialog.deletePoint.message': 'Are you sure you want to delete the waypoint?',
    'confirmDialog.deletePoint.accept': 'Yes, delete',
    'confirmDialog.deletePoint.cancel': 'Cancel',

    'confirmDialog.deleteEvent.title': 'Delete event?',
    'confirmDialog.deleteEvent.message': 'Are you sure you want to delete the event?',
    'confirmDialog.deleteEvent.accept': 'Yes, delete',
    'confirmDialog.deleteEvent.cancel': 'Cancel',

    'confirmDialog.addressNotFoundCreateNew.title': 'Address not found',
    'confirmDialog.addressNotFoundCreateNew.message': 'We have not found a saved address near this location. Do you want to create it?',
    'confirmDialog.addressNotFoundCreateNew.accept': 'Yes, create a new address',
    'confirmDialog.addressNotFoundCreateNew.cancel': 'Cancel',

    'confirmDialog.approve.title': 'Approve item?',
    'confirmDialog.approve.message': 'Are you sure you want to approve the item?',
    'confirmDialog.approve.accept': 'Yes, approve',
    'confirmDialog.approve.cancel': 'Cancel',

    'confirmDialog.setAsUnpaid.title': 'Cancel payment?',
    'confirmDialog.setAsUnpaid.message': 'Are you sure you want to cancel payment?',
    'confirmDialog.setAsUnpaid.accept': 'Ano, cancel payment',
    'confirmDialog.setAsUnpaid.cancel': 'Cancel',

    'confirmDialog.correctEventTimes.title': 'Automatically edit or create events?',
    'confirmDialog.correctEventTimes.message':
        'This feature automatically adjusts event times such as arrival or departure from loading according to GPS position from the vehicle and then recalculates the actual route. The original values will be replaced. Do you really wish to continue?', // eslint-disable-line
    'confirmDialog.correctEventTimes.accept': 'Yes, continue',
    'confirmDialog.correctEventTimes.cancel': 'Cancel',

    'confirmDialog.reAssignAllowances.title': 'Reload from template?',
    'confirmDialog.reAssignAllowances.message': 'Do you really want to reload the template rewards? Existing records will be replaced.',
    'confirmDialog.reAssignDietAllowances.message': 'Do you really want to reload the time diets? Existing records will be replaced.',
    'confirmDialog.reAssignAllowances.accept': 'Yes, reload',
    'confirmDialog.reAssignAllowances.cancel': 'Zrušit',

    'confirmDialog.updateCostGonerator.title': 'Edit only this cost?',
    'confirmDialog.updateCostGonerator.message': 'You want to adjust only this cost or even the following costs.',
    'confirmDialog.updateCostGonerator.onlyThis': 'Only this cost',
    'confirmDialog.updateCostGonerator.thisAndFuture': 'This cost and the future',

    'confirmDialog.deleteCostGonerator.title': 'Only remove this cost?',
    'confirmDialog.deleteCostGonerator.message': 'You want to remove only this cost or even the following costs.',
    'confirmDialog.deleteCostGonerator.onlyThis': 'Only this cost',
    'confirmDialog.deleteCostGonerator.thisAndFuture': 'This cost and the future',

    // Pagination
    'pagination.totalRecords': 'Total items',
    'pagination.previous': 'Previous',
    'pagination.next': 'Next',

    // Transport states
    'transportStates.notApproved': 'Not approved ',
    'transportStates.preOrder': 'Pre-order',
    'transportStates.order': 'Order',
    'transportStates.planned': 'Planned',
    'transportStates.sent': 'Sent',
    'transportStates.processing': 'Processing',
    'transportStates.finished': 'Finished',
    'transportStates.canceled': 'Canceled',

    // Days
    'dayNames.0': 'Sunday',
    'dayNames.1': 'Monday',
    'dayNames.2': 'Tuesday',
    'dayNames.3': 'Wednesday',
    'dayNames.4': 'Thursday',
    'dayNames.5': 'Friday',
    'dayNames.6': 'Saturday',

    // Months
    'monthNames.01': 'January',
    'monthNames.02': 'February',
    'monthNames.03': 'March',
    'monthNames.04': 'April',
    'monthNames.05': 'May',
    'monthNames.06': 'June',
    'monthNames.07': 'July',
    'monthNames.08': 'August',
    'monthNames.09': 'September',
    'monthNames.10': 'October',
    'monthNames.11': 'November',
    'monthNames.12': 'Decemeber',

    // Vehicle data source
    'vehicleDataSource.1': 'FB',

    // Transport point types
    'pointTypes.loading': 'Loading',
    'pointTypes.loadingCreate': 'Create loading',
    'pointTypes.loadingEdit': 'Edit loading',
    'pointTypes.unloading': 'Unloading',
    'pointTypes.unloadingCreate': 'Create unloading',
    'pointTypes.unloadingEdit': 'Edit unloading',
    'pointTypes.waypoint': 'Waypoint',
    'pointTypes.waypointCreate': 'Create waypoint',
    'pointTypes.waypointEdit': 'Edit waypoint',
    'pointTypes.service': 'Service',
    'pointTypes.serviceCreate': 'Create service',
    'pointTypes.serviceEdit': 'Edit service',
    'pointTypes.fueling': 'Fueling',
    'pointTypes.fuelingCreate': 'Create fueling',
    'pointTypes.fuelingEdit': 'Edit fueling',
    'pointTypes.parking': 'Parking',
    'pointTypes.parkingCreate': 'Create parking',
    'pointTypes.parkingEdit': 'Edit parking',
    'pointTypes.parkingWithChange': 'Parking with change',
    'pointTypes.parkingWithChangeCreate': 'Create parking with change',
    'pointTypes.parkingWithChangeEdit': 'Edit parking with change',
    'pointTypes.trailerChange': 'Trailer change',
    'pointTypes.trailerChangeCreate': 'Create trailer change',
    'pointTypes.trailerChangeEdit': 'Edit trailer change',
    'pointTypes.trailerCatch': 'Trailer catch',
    'pointTypes.trailerCatchCreate': 'Create trailer catch',
    'pointTypes.trailerCatchEdit': 'Edit trailer catch',
    'pointTypes.trailerOut': 'Trailer out',
    'pointTypes.trailerOutCreate': 'Create trailer out',
    'pointTypes.trailerOutEdit': 'Edit trailer out',
    'pointTypes.move': 'Move',
    'pointTypes.moveCreate': 'Create move',
    'pointTypes.moveEdit': 'Edit move',
    'pointTypes.customHouse': 'Custom house',
    'pointTypes.customHouseCreate': 'Create custom house',
    'pointTypes.customHouseEdit': 'Edit custom house',
    'pointTypes.vehicleQuit': 'Vehicle quit',
    'pointTypes.vehicleQuitCreate': 'Create vehicle quit',
    'pointTypes.vehicleQuitEdit': 'Edit vehicle quit',

    // Transport point states
    'transportPointStates.onWay': 'On way',
    'transportPointStates.arrived': 'Arrived',
    'transportPointStates.waiting': 'Arrived / waiting',
    'transportPointStates.processing': 'Arrived / processing',
    'transportPointStates.done': 'Arrived / done',
    'transportPointStates.finished': 'Finished',

    // Transport event types
    'transportEvents.predepartureCheck': 'Predeparture check',
    'transportEvents.loadingArrival': 'Loading arrival',
    'transportEvents.systemCheck': 'System check',
    'transportEvents.loadingWaiting': 'Waiting for loading',
    'transportEvents.loadingStart': 'Loading start',
    'transportEvents.loadingEnd': 'Loading end',
    'transportEvents.loadingLoaded': 'Loading finished / departure',
    'transportEvents.unloadingArrival': 'Unloading arrival',
    'transportEvents.unloadingWaiting': 'Waiting for unloading',
    'transportEvents.unloadingStart': 'Unloading start',
    'transportEvents.unloadingEnd': 'Unloading end',
    'transportEvents.unloadingUnloaded': 'Unloading finished / departure',
    'transportEvents.waypointEnd': 'Waypoint end',
    'transportEvents.fuelingArrival': 'Fueling arrival',
    'transportEvents.fuelingDeparture': 'Fueling departure',
    'transportEvents.serviceArrival': 'Service arrival',
    'transportEvents.serviceDeparture': 'Service departure',
    'transportEvents.parkingArrival': 'Parking arrival',
    'transportEvents.parkingCheckBefore': 'Check before parking',
    'transportEvents.parkingTime': 'Set parking time',
    'transportEvents.parkingCheckAfter': 'Check after parking',
    'transportEvents.parkingDeparture': 'Parking departure',
    'transportEvents.trailerChangeArrival': 'Trailer change arrival',
    'transportEvents.trailerChangeCheck': 'Trailer change check',
    'transportEvents.trailerChangeDeparture': 'Trailer change departure',
    'transportEvents.trailerCatchArrival': 'Trailer catch arrival',
    'transportEvents.trailerCatchCheck': 'Trailer catch check ',
    'transportEvents.trailerCatchDeparture': 'Trailer catch departure',
    'transportEvents.trailerOutArrival': 'Trailer out arrival',
    'transportEvents.trailerOutCheck': 'Trailer out check',
    'transportEvents.trailerOutDeparture': 'Trailer out departure',
    'transportEvents.parkingWithChangeArrival': 'Parking with change arrival',
    'transportEvents.parkingWithChangeQuitCheck': 'Check before parking with change',
    'transportEvents.parkingWithChangeCheck': 'Check after parking with change',
    'transportEvents.moveEnd': 'Move end',
    'transportEvents.customHouseArrival': 'Custom house arrival',
    'transportEvents.customHouseDeparture': 'Custom house departure',
    'transportEvents.vehicleQuitCheck': 'Check before vehicle quit',
    'transportEvents.vehicleQuitFinish': 'Vehicle quit finish',

    // Transport log changes
    'transportLogChanges.transportCreated': 'Transport created',
    'transportLogChanges.transportCreatedByCopying': 'Transport created by copying',
    'transportLogChanges.transportCreatedFromTemplate': 'Transport created from template',
    'transportLogChanges.transportCreatedFromTransporeon': 'Transport created from Transporeon offer',
    'transportLogChanges.transportUpdated': 'Transport updated',
    'transportLogChanges.transportDeleted': 'Transport deleted',
    'transportLogChanges.transportRestored': 'Transport restored',
    'transportLogChanges.transportSentToVehicle': 'Transport sent to vehicle',
    'transportLogChanges.transportUpdatedInVehicle': 'Transport updated to vehicle',
    'transportLogChanges.transportDeletedFromVehicle': 'Transport deleted from vehicle',
    'transportLogChanges.transportPointCreated': 'Point created',
    'transportLogChanges.transportPointUpdated': 'Point updated',
    'transportLogChanges.transportPointDeleted': 'Point deleted',
    'transportLogChanges.transportPointRestored': 'Point restored',
    'transportLogChanges.transportEventCreated': 'Event created',
    'transportLogChanges.transportEventUpdated': 'Event updated',
    'transportLogChanges.transportEventDeleted': 'Event deleted',

    // Vehicle events
    'vehicleEvents.predepartureCheck': 'Predeparture check',
    'vehicleEvents.fueling': 'Fueling',
    'vehicleEvents.service': 'Service',
    'vehicleEvents.parking': 'Parking',
    'vehicleEvents.parkingWithChange': 'Parking with change',
    'vehicleEvents.accident': 'Accident',
    'vehicleEvents.trailerCatch': 'Trailer catch',
    'vehicleEvents.trailerOut': 'Trailer out',
    'vehicleEvents.loading': 'Loading',
    'vehicleEvents.unloading': 'Unloading',
    'vehicleEvents.move': 'Move',
    'vehicleEvents.customHouse': 'Custom house',
    'vehicleEvents.vehicleQuit': 'Vehicle quit',

    // Vehicle trip record type
    'vehicleTripRecordType.dataCompletion': 'Data completion',
    'vehicleTripRecordType.fueling': 'Refueling',
    'vehicleTripRecordType.0': 'Pause',
    'vehicleTripRecordType.1': 'Drive',
    'vehicleTripRecordType.2': 'Border crossing',

    // Invoicing states
    'invoicingStates.0': 'Not invoiced',
    'invoicingStates.1': 'Ready',
    'invoicingStates.2': 'Invoiced',

    // Tax transaction types
    'taxTransactionTypes.1': 'Domestic',
    'taxTransactionTypes.2': 'EU (reverse charge)',
    'taxTransactionTypes.3': 'Other',
    'taxTransactionTypes.4': 'Not subject to VAT',


    // Text before items types
    'textBeforeItemsTypes.1': 'We invoice you for the following items:',
    'textBeforeItemsTypes.2': 'We invoice you for the following transports:',

    // Tax rate types
    'taxRateTypes.1': 'Basic',
    'taxRateTypes.2': 'First reduced',
    'taxRateTypes.3': 'Second reduced',
    'taxRateTypes.4': 'Zero',

    // Transport vehicle types
    'transportVehicleTypes.vehicle': 'Vehicle',
    'transportVehicleTypes.boat': 'Boat',
    'transportVehicleTypes.train': 'Train',
    'transportVehicleTypes.plane': 'Plane',
    'transportVehicleTypes.other': 'Other',

    // Company numbering types
    'companyNumberingTypes.1': 'Issued invoices',
    'companyNumberingTypes.2': 'Received invoices',
    'companyNumberingTypes.3': 'Issued credit notes',
    'companyNumberingTypes.4': 'Received credit notes',

    // Driver timesheet interval types
    'driverTimesheetIntervalTypes.interval': 'Time period',
    'driverTimesheetIntervalTypes.time': 'Number of hours and minutes',

    // Driver timesheet types
    'driverTimesheetTypes.pause': 'Pause',
    'driverTimesheetTypes.drive': 'Drive',
    'driverTimesheetTypes.other_job': 'Other job',
    'driverTimesheetTypes.holiday': 'Holiday',
    'driverTimesheetTypes.vacation': 'Vacation',
    'driverTimesheetTypes.unpaid_vacation': 'Unpaid vacation',
    'driverTimesheetTypes.turnus_vacation': 'Turnus vacation',
    'driverTimesheetTypes.disease': 'Disease',
    'driverTimesheetTypes.doctor': 'Doctor',
    'driverTimesheetTypes.nursing': 'Nursing',
    'driverTimesheetTypes.absence': 'Absence',
    'driverTimesheetTypes.use_overtime': 'Use overtime',

    // Transports
    'modules.transports.heading': 'Transports',
    'modules.transports.headingArchive': 'Transports - archive',
    'modules.transports.headingTrashed': 'Transports - trashed',
    'modules.transports.headingNew': 'New transport',
    'modules.transports.headingEdit': 'Transport no.',
    'modules.transports.showOnlyCombined': 'Show only combined',
    'modules.transports.showWithSpeditions': 'Show with forwarding transports',
    'modules.transports.showOnlySpeditions': 'Show only forwarding transports',
    'modules.transports.showOnlyNotApproved': 'Show only disapproved',
    'modules.transports.showOnlyCabotage': 'Show only cabotages',
    'modules.transports.notSentYet': 'Transport has not yet been sent',
    'modules.transports.suspectlyLargeApproach': 'Suspectly large approach',
    'modules.transports.warningDeletedTransport':
        'Attention! You are editing the deleted transport. If you want to restore it, click the restore button.',
    'modules.transports.warningNotApprovalTransport':
        'Attention! This is an unauthorized shipment. If you wish to approve it, click the approve button. ',
    'modules.transports.pricing': 'Pricing',
    'modules.transports.routeDefinition': 'Route definition',
    'modules.transports.requirePredepartureCheck': 'Require predeparture check',
    'modules.transports.showRoutesOnMap': 'Show routes on map',
    'modules.transports.noVehiclePositionsFound': 'No GPS positions were found within this transport',
    'modules.transports.warningBlockedCustomer': 'Attention! This customer is blocked.',
    'modules.transports.warningBlockedCarrier': 'Attention! This carrier is blocked.',
    'modules.transports.files': 'Files',
    'modules.transports.goods': 'Goods definition',
    'modules.transports.parts': 'Combined transport (parts)',
    'modules.transports.preloaded': 'Pre-loaded',
    'modules.transports.allowances': 'Allowances',
    'modules.transports.reInitAllowances': 'Reload from template',
    'modules.transports.addAllowances': 'Add allowances',
    'modules.transports.addAllowancesSelectType': 'Diet/allowance amount, currency and type',
    'modules.transports.noDriversFound': 'The transport does not have a driver assigned, diets cannot be entered',
    'modules.transports.allowancesSetDefaultValue': 'Set default',
    'modules.transports.driver': 'Driver',
    'modules.transports.noDrivers': 'No drivers found',
    'modules.transports.scoring': 'Rating',

    // Transport templates
    'modules.transportTemplates.heading': 'Templates',
    'modules.transportTemplates.headingNew': 'New template',
    'modules.transportTemplates.headingEdit': 'Edit template',
    'modules.transportTemplates.createTransportFromTemplate': 'Create transport(s) from template',

    // Transporeon offers
    'modules.transporeonOffers.heading': 'Transporeon offers',
    'modules.transporeonOffers.reason.transitTimeTooShort': 'Too short',
    'modules.transporeonOffers.reason.noDriverAvailable': 'No driver available',
    'modules.transporeonOffers.reason.requestedEquipmentNotAvailable': 'Requested equipment not available',
    'modules.transporeonOffers.reason.other': 'Other reason',
    'modules.transporeonOffers.accept.title': 'Accept offer?',
    'modules.transporeonOffers.accept.message': 'Are you sure you want to accept this offer?',
    'modules.transporeonOffers.reject.title': 'Reject offer?',
    'modules.transporeonOffers.reject.message': 'Are you sure you want to reject this offer?',

    // Board
    'modules.board.heading': 'Dispatcher board',
    'modules.board.vehicleNote': 'Note to vehicle',
    'modules.board.trailerNote': 'Note to trailer',
    'modules.board.trailerDamage': 'Trailer damage',
    'modules.board.showFree': 'Show free',
    'modules.board.weekendAtHome': 'Weekend at home',
    'modules.board.weekendAtWork': 'Weekend at work',
    'modules.board.isDone': 'Done (for today)',
    'modules.board.isNotDone': 'Not done (for today)',
    'modules.board.updatedInLast2Hours': 'Updated in the last 2 hours',
    'modules.board.updated2HoursAgo': 'Updated more than 2 hours ago',
    'modules.board.planningState': 'Planning status',

    // Map
    'modules.map.heading': 'Map',

    // Reports
    'modules.reportTransports.heading': 'Reports - Transports - Economic results',
    'modules.reportSpeditionTransports.heading': 'Reports - Transports - Economic results of spedition transports',
    'modules.reportTransportChanges.heading': 'Reports - Transports - Changes',
    'modules.reportTransportInvoicing.heading': 'Reports - Transports - Invoicing',
    'modules.reportTransportOrders.heading': 'Reports - Transports - Orders',
    'modules.reportVehicleKilometersTotal.heading': 'Reports - Vehicles - Total kilometers',
    'modules.reportVehiclePerformance.heading': 'Reports - Vehicles - Performance',
    'modules.reportVehicleTraffic.heading': 'Reports - Vehicles - Traffic',
    'modules.reportVehicleChecks.heading': 'Reports - Vehicles - Checks and damages',
    'modules.reportVehicleFuelings.heading': 'Reports - Vehicles - Refueling',
    'modules.reportTrailerFuelings.heading': 'Reports - Trailers - Refueling',
    'modules.reportDriverPerformanceAnalysis.heading': 'Reports - Drivers - Performance analysis',
    'modules.reportDriverHourlyOverview.heading': 'Reporty - Drivers - Hourly overview',
    'modules.reportDriverTimesheet.heading': 'Reporty - Drivers - Work report',
    'modules.reportCustomerResults.heading': 'Reports - Customers - Results',
    'modules.reportCustomerPlans.heading': 'Reports - Customers - Plans',
    'modules.reportCustomerMonthlyPlans.heading': 'Reports - Customers - Monthly plans',
    'modules.reportCustomerWeeklyPlans.heading': 'Reports - Customers - Weekly plans',
    'modules.reportCostCenterResults.heading': 'Reports - Cost centers - Results',
    'modules.reportCostCenterTraffic.heading': 'Reports - Cost centers - Traffic',
    'modules.reportCostCenterPerformance.heading': 'Reports - Cost centers - Performance',
    'modules.reportCostCenterCost.heading': 'Reports - Cost centers - Costs',
    'modules.reportCarrierResults.heading': 'Reports - Carriers - Results',
    'modules.reportCarrierScoring.heading': 'Reports - Carriers - Rating',
    'modules.reportCarrierUsage.heading': 'Reports - Carriers - Usage',
    'modules.reportDispatcherKPI.heading': 'Reports - Dispatchers - KPI',
    'modules.reportAppUsage.heading': 'Reports - Other - Application usage',
    'modules.reportCostCenterProfits.heading': 'Reporty - Cost centers - Economic results',

    'modules.reportCustomerTurnovers.heading': 'Reports - Customers',
    'modules.reportEmptyKilometers.heading': 'Reports - Empty kilometers',
    'modules.reportPallets.heading': 'Reports - Pallets',

    // Vehicles
    'modules.vehicles.heading': 'Vehicles',
    'modules.vehicleHistory.heading': 'Vehicles - History',
    'modules.vehicleDocuments.heading': 'Vehicles - Documents',
    'modules.vehicleEquipments.heading': 'Vehicles - Equipment',
    'modules.vehicleEvents.heading': 'Events',

    // Vehicle fueling
    'modules.reportVehicleFuelings.detail.sumeryHeading': 'General overview',
    'modules.reportVehicleFuelings.detail.tripRecordsHeading': 'Detailed overview',
    'modules.reportVehicleFuelings.detail.odometerHeading': 'Odometer overview',

    // trailer fueling
    'modules.reportTrailerFuelings.detail.sumeryHeading': 'General overview',
    'modules.reportTrailerFuelings.detail.tripRecordsHeading': 'Detailed overview',

    // Trailers
    'modules.trailers.heading': 'Trailers',
    'modules.trailerHistory.heading': 'Trailers - History',
    'modules.trailerDocuments.heading': 'Trailers - Documents',
    'modules.trailerEquipments.heading': 'Trailers - Equipment',

    // Drivers
    'modules.drivers.heading': 'Drivers',
    'modules.driverHistory.heading': 'Drivers - History',
    'modules.driverDocuments.heading': 'Drivers - Documents',
    'modules.driverEquipments.heading': 'Drivers - Equipment',
    'modules.driverTimesheets.heading': 'Drivers - Worksheets',

    // Drivers hourly overview
    'modules.reportDriverHourlyOverview.detail.sumeryHeading': 'General overview',
    'modules.reportDriverHourlyOverview.detail.tripRecordsHeading': 'Detailed overview',

    // Customers
    'modules.customers.heading': 'Customers',
    'modules.customerAddresses.heading': 'Addresses',
    'modules.carriers.heading': 'Carriers',
    'modules.carriers.scoring': 'Rating',

    // Settings
    'modules.users.heading': 'Users',
    'modules.userRoles.heading': 'User roles',
    'modules.userLogins.heading': 'Settings - Users - Login log',
    'modules.blockedIps.heading': 'Settings - Users - Blokované IP Blocked IP addresses',
    'modules.countries.heading': 'Countries',
    'modules.currencies.heading': 'Currencies',
    'modules.companies.heading': 'Companies',
    'modules.companyCostCenters.heading': 'Cost centers',
    'modules.transportTypes.heading': 'Transport types',
    'modules.vehicleTypes.heading': 'Vehicle types',
    'modules.trailerTypes.heading': 'Trailer types',
    'modules.cargoTypes.heading': 'Cargo types',
    'modules.surchargeTypes.heading': 'Surcharge types',
    'modules.customerAddressTypes.heading': 'Address types',
    'modules.goodsTypes.heading': 'Goods types',
    'modules.documentTypes.heading': 'Document types',
    'modules.packagingTypes.heading': 'Packaging types',
    'modules.equipments.heading': 'Equipment',
    'modules.taxRates.heading': 'Tax rates',
    'modules.paymentMethods.heading': 'Payment methods',
    'modules.transportConditions.heading': 'Transport conditions',
    'modules.invoiceItemTypes.heading': 'Invoice item types',
    'modules.costTypes.heading': 'Settings - Cost types',
    'modules.costs.heading': 'Costs',
    'modules.costGenerators.heading': 'Recurring costs ',
    'modules.salesTypes.heading': 'Types of sales',
    'modules.driversTypes.heading': 'Types of drivers',
    'modules.sales.heading': 'Sales',

    'modules.transportScoring.heading': 'Transport rating',
    'modules.transportScoring.rules': 'Rating rules',

    'modules.carrierScoring.heading': 'Carrier rating',
    'modules.carrierScoring.rules': 'Rating rules',

    // Invoicing
    'modules.invoicingOrders.heading': 'Invoicing - Orders',
    'modules.invoicingInvoices.heading': 'Invoicing - Issued invoices',
    'modules.invoicingReceivedInvoices.heading': 'Invoicing - Received invoices',
    'modules.reportInvoicingSummary.heading': 'Fakturace - Sumář',

    // Diets
    'modules.dietRates.heading': 'Diet rates',

    // General settings
    'modules.generalSettings.heading': 'Settings - General settings',
    'modules.generalSettings.transports': 'Transports / Tracing',
    'modules.generalSettings.email': 'Mailing',
    'modules.generalSettings.invoices': 'Invoices',
    'modules.generalSettings.security': 'Security',
    // General settings - transports
    'modules.generalSettings.price_fuel': 'Average fuel price [CZK / 1 liter]',
    'modules.generalSettings.price_adblue': 'Average price of AdBlue [CZK / 1 liter]',
    'modules.generalSettings.rest_minutes': 'Rest length [minutes]',
    'modules.generalSettings.break_minutes': 'Length of safety break [minutes]',
    'modules.generalSettings.rest_interval_minutes': 'Rest interval [minutes]',
    'modules.generalSettings.break_interval_minutes': 'Safety break interval [minutes]',
    'modules.generalSettings.costs_fixed': 'Fixed hourly costs [CZK]',
    'modules.generalSettings.avg_speed': 'Average speed [km / h]',
    'modules.generalSettings.avg_fuel': 'Average fuel consumption [l / km]',
    'modules.generalSettings.avg_adblue': 'Average AdBlue consumption [l / km]',
    'modules.generalSettings.drive_at_night_start': 'The night ride starts at',
    'modules.generalSettings.drive_at_night_end': 'The night ride ends at',
    'modules.generalSettings.sms_template': 'Transport template',
    'modules.generalSettings.sms_route_point_template': 'Waypoint template',
    'modules.generalSettings.sms_customer_template': 'Customer template',
    'modules.generalSettings.automatic_event_creation_radius': 'Automatic event creation radius [°]',
    'modules.generalSettings.automatic_event_creation_time': 'Time tolerance for automatic event creation [h]',
    // General settings - email
    'modules.generalSettings.mail_from_address': 'Default sender (email)',
    'modules.generalSettings.mail_from_name': 'Default sender (name)',
    // General settings - invoices
    'modules.generalSettings.customer_invoice_deadline_days': 'Default due date for issued invoices [days]',
    'modules.generalSettings.supplier_invoice_deadline_days': 'Default due date for received invoices [days]',
    // General settings - security
    'modules.generalSettings.reset_password_token_valid_hours': 'Password reset link validity [hours]',
    'modules.generalSettings.ip_login_attempts': 'Block IP address for X failed login attempts',
    'modules.generalSettings.user_login_attempts': 'Block account for X failed login attempts',

    //Import messages
    'import.step1': 'Please upload the file from which the items will be imported. Supported formats are XLS, XLSX and CSV.',
    'import.step2': 'Please set the meaning of the individual columns so that the items can be imported correctly.',
    'import.step3': 'Below is an overview of the items we found in the file. Please select the ones to be processed.',
    'import.noItemsFound': 'No item found.',

    // Costs import
    'modules.costsImport.heading': 'Costs import',
    'modules.costsImport.step': 'step',
    'modules.costsImport.stepNames.1': 'File upload',
    'modules.costsImport.stepNames.2': 'Column definitions',
    'modules.costsImport.stepNames.3': 'Summary',

    'modules.costsImport.columnTypes.costCenter': 'Cost center (code)',
    'modules.costsImport.columnTypes.vehicle': 'Vehicle (License plate)',
    'modules.costsImport.columnTypes.driver': 'Driver (Surname and name / personal number)',
    'modules.costsImport.columnTypes.trailer': 'Trailer (License plate)',
    'modules.costsImport.columnTypes.price': 'Price',
    'modules.costsImport.columnTypes.currency': 'Currency',
    'modules.costsImport.columnTypes.date': 'Date',

    // Drivers import
    'modules.driversImport.heading': 'Drivers import',
    'modules.driversImport.step': 'step',
    'modules.driversImport.stepNames.1': 'File upload',
    'modules.driversImport.stepNames.2': 'Column definitions',
    'modules.driversImport.stepNames.3': 'Summary',

    'modules.driversImport.columnTypes.firstName': 'Name',
    'modules.driversImport.columnTypes.lastName': 'Surname',
    'modules.driversImport.columnTypes.locale': 'Language',
    'modules.driversImport.columnTypes.cardNumber': 'Card number',
    'modules.driversImport.columnTypes.code': 'Code',
    'modules.driversImport.columnTypes.active': 'Active',
    'modules.driversImport.columnTypes.phone': 'Phone',
    'modules.driversImport.columnTypes.driverType': 'Type',
    'modules.driversImport.columnTypes.dispatcher': 'Dispatcher',
    'modules.driversImport.columnTypes.costCenter': 'Cost center (code)',

    // Trailers import
    'modules.trailersImport.heading': 'Trailers import',
    'modules.trailersImport.step': 'step',
    'modules.trailersImport.stepNames.1': 'File upload',
    'modules.trailersImport.stepNames.2': 'Column definitions',
    'modules.trailersImport.stepNames.3': 'Summary',

    'modules.trailersImport.columnTypes.vin': 'VIN',
    'modules.trailersImport.columnTypes.registration': 'Registration',
    'modules.trailersImport.columnTypes.trailerType': 'Type',
    'modules.trailersImport.columnTypes.active': 'Active',
    'modules.trailersImport.columnTypes.damaged': 'Damaged',
    'modules.trailersImport.columnTypes.securityDamage': 'Security damaged',
    'modules.trailersImport.columnTypes.coolingDamage': 'Cooling damaged',
    'modules.trailersImport.columnTypes.spedition': 'Spedition',
    'modules.trailersImport.columnTypes.carrier': 'Carrier',
    'modules.trailersImport.columnTypes.costCenter': 'Cost center (code)',

    // Sales import
    'modules.salesImport.heading': 'Sales import',
    'modules.salesImport.step': 'step',
    'modules.salesImport.stepNames.1': 'File upload',
    'modules.salesImport.stepNames.2': 'Column definitions',
    'modules.salesImport.stepNames.3': 'Summary',

    'modules.salesImport.columnTypes.costCenter': 'Cost center (code)',
    'modules.salesImport.columnTypes.vehicle': 'Vehicle (License plate)',
    'modules.salesImport.columnTypes.driver': 'Driver (Surname and name / personal number)',
    'modules.salesImport.columnTypes.trailer': 'Trailer (License plate)',
    'modules.costsImport.columnTypes.registration': 'License plate (auto detection)',
    'modules.costsImport.columnTypes.supplier': 'Supplier',
    'modules.costsImport.columnTypes.billNumber': 'Bill number',
    'modules.salesImport.columnTypes.price': 'Price',
    'modules.salesImport.columnTypes.currency': 'Currency',
    'modules.salesImport.columnTypes.date': 'Date',


    // Customers import
    'modules.customersImport.heading': 'Customers import',
    'modules.customersImport.step': 'step',
    'modules.customersImport.stepNames.1': 'File upload',
    'modules.customersImport.stepNames.2': 'Column definitions',
    'modules.customersImport.stepNames.3': 'Summary',

    'modules.customersImport.columnTypes.regNumber': 'Reg. number',
    'modules.customersImport.columnTypes.vatNumber': 'Vat. number',
    'modules.customersImport.columnTypes.name': 'Company / Customer name',
    'modules.customersImport.columnTypes.nameTransporeon': 'Transporeon name',
    'modules.customersImport.columnTypes.defaultCurrency': 'Default currency',
    'modules.customersImport.columnTypes.invoiceDeadlineDays': 'Invoice deadline days',
    'modules.customersImport.columnTypes.emailForInvoicing': 'E-mail for invoicing',
    'modules.customersImport.columnTypes.invoicingNote': 'Invoicing note',
    'modules.customersImport.columnTypes.limitUnpaidInvoices': 'Limit for unpaid invoices',
    'modules.customersImport.columnTypes.limitOverdueInvoices': 'Limit for overdue invoices',
    'modules.customersImport.columnTypes.blocked': 'Blocked',
    'modules.customersImport.columnTypes.businessManager': 'Business manager',
    'modules.customersImport.columnTypes.dispatcher': 'Dispatcher',
    'modules.customersImport.columnTypes.transportPlanCount': 'Transport plan (count)',
    'modules.customersImport.columnTypes.transportPlanValue': 'Transport plan (value)',
    'modules.customersImport.columnTypes.transportPlanMargin': 'Transport plan (margin)',
    'modules.customersImport.columnTypes.speditionPlanCount': 'Spedition plan (count)',
    'modules.customersImport.columnTypes.speditionPlanValue': 'Spedition plan (value)',
    'modules.customersImport.columnTypes.speditionPlanMargin': 'Spedition plan (margin)',

    // Customer addresss import
    'modules.customerAddressesImport.heading': 'Addresses import',
    'modules.customerAddressesImport.step': 'step',
    'modules.customerAddressesImport.stepNames.1': 'File upload',
    'modules.customerAddressesImport.stepNames.2': 'Column definitions',
    'modules.customerAddressesImport.stepNames.3': 'Summary',

    'modules.customerAddressesImport.columnTypes.name': 'Name',
    'modules.customerAddressesImport.columnTypes.code': 'Code',
    'modules.customerAddressesImport.columnTypes.customer': 'Customer',
    'modules.customerAddressesImport.columnTypes.addressType': 'Address type',
    'modules.customerAddressesImport.columnTypes.street': 'Street',
    'modules.customerAddressesImport.columnTypes.zipcode': 'Zipcode',
    'modules.customerAddressesImport.columnTypes.city': 'City',
    'modules.customerAddressesImport.columnTypes.country': 'Country',
    'modules.customerAddressesImport.columnTypes.gps': 'GPS',
    'modules.customerAddressesImport.columnTypes.email': 'E-mail',
    'modules.customerAddressesImport.columnTypes.phone': 'Phone',
    'modules.customerAddressesImport.columnTypes.note': 'Note',
    'modules.customerAddressesImport.columnTypes.booking': 'Booking',
    'modules.customerAddressesImport.columnTypes.bookingUrl': 'Booking URL',
    'modules.customerAddressesImport.columnTypes.bookingNote': 'Booking note',

    // Notifications
    'modules.notifications.heading': 'Notifications',

    // Permissions
    'permissionGroups.transports': 'Transports',
    'permissionGroups.transports_cost': 'Transports - Cost',
    'permissionGroups.transport_to_vehicle': 'Transports - Send to vehicle',
    'permissionGroups.transports_customer': 'Transports - Customer',
    'permissionGroups.transports_customer_cost': 'Transports - Customer - Cost',
    'permissionGroups.transports_spedition': 'Transports - Spedition',
    'permissionGroups.transports_spedition_cost': 'Transports - Spedition - Cost',
    'permissionGroups.transports_events': 'Transports - Events',
    'permissionGroups.transports_log': 'Transports - Log',
    'permissionGroups.transports_routes': 'Transports - Routes',
    'permissionGroups.transports_goods': 'Transports - Goods',
    'permissionGroups.transports_parts': 'Transports - Parts',
    'permissionGroups.transports_files': 'Transports - Files',
    'permissionGroups.transports_allowances': 'Transports - Allowances',
    'permissionGroups.transports_scoring': 'Transports - Scoring',
    'permissionGroups.transports_template': 'Transports - Templates',
    'permissionGroups.transports_template_cost': 'Transports - Templates - Cost',
    'permissionGroups.transports_template_customer': 'Transports - Templates - Customer',
    'permissionGroups.transports_template_customer_cost': 'Transports - Templates - Customer - Cost',
    'permissionGroups.transports_template_routes': 'Transports - Templates - Routes',
    'permissionGroups.transports_template_allowances': 'Transports - Templates - Allowances',
    'permissionGroups.transporeon_offers': 'Transports - Transporeon Offers',
    'permissionGroups.tracing': 'Transports - Tracing',
    'permissionGroups.board': 'Dispatcher board',
    'permissionGroups.here_map': 'Map',
    'permissionGroups.report_transports': 'Reports - Transports - Economic results',
    'permissionGroups.report_spedition_transports': 'Reports - Transports - Economic results of spedition transports',
    'permissionGroups.report_transport_changes': 'Reports - Transports - Changes',
    'permissionGroups.report_transport_invoicing': 'Reports - Transports - Invoicing',
    'permissionGroups.report_transport_orders': 'Reports - Transports - Orders',
    'permissionGroups.report_vehicle_kilometers_total': 'Reports - Vehicles - Total kilometers',
    'permissionGroups.report_vehicle_performance': 'Reports - Vehicles - Performance',
    'permissionGroups.report_vehicle_traffic': 'Reports - Vehicles - Traffic',
    'permissionGroups.report_vehicle_checks': 'Reports - Vehicles - Checks and defects',
    'permissionGroups.report_vehicle_fuelings': 'Report - Vehicles - Refueling and consumption',
    'permissionGroups.report_trailer_fuelings': 'Report - Trailers - Refueling and consumption',
    'permissionGroups.report_driver_allowances': 'Reports - Drivers - Diets and Rewards',
    'permissionGroups.report_driver_performance_analysis': 'Reports - Drivers - Performance Analysis',
    'permissionGroups.report_driver_hourly_overview': 'Reports - Drivers - Hourly Overview',
    'permissionGroups.report_driver_timesheets': 'Reports - Drivers - Work report',
    'permissionGroups.report_customer_results': 'Reports - Customers - Results',
    'permissionGroups.report_customer_plans': 'Reports - Customers - Plans',
    'permissionGroups.report_customer_weekly_plans': 'Reports - Customers - Weekly plans',
    'permissionGroups.report_customer_monthly_plans': 'Reports - Customers -  Monthly plans',
    'permissionGroups.report_carrier_results': 'Reports - Carrier - Results',
    'permissionGroups.report_carrier_scoring': 'Reports - Carrier - Ratings',
    'permissionGroups.report_carrier_usage': 'Reports - Carrier - Usage',
    'permissionGroups.report_dispatcher_kpi': 'Reports - Dispatchers - KPI',
    'permissionGroups.report_cost_center_results': 'Reports - Cost Centers - Results',
    'permissionGroups.report_cost_center_traffic': 'Reports - Cost Centers - Operational Indicators',
    'permissionGroups.report_cost_center_performance': 'Reports - Cost Centers - Performance',
    'permissionGroups.report_cost_center_profits': 'Reports - Cost Centers - Economic results',
    'permissionGroups.report_customer_turnovers': 'Reports - Other - Customers',
    'permissionGroups.report_empty_kilometers': 'Reports - Other - Empty kilometers',
    'permissionGroups.report_pallets': 'Reports - Other - Pallets',
    'permissionGroups.report_invoicing_summary': 'Invoicing - Summary',
    'permissionGroups.invoicing_orders': 'Invoicing - Orders',
    'permissionGroups.invoicing_invoices': 'Invoicing - Issued invoices',
    'permissionGroups.invoicing_received_invoices': 'Invoicing - Received invoices',
    'permissionGroups.vehicles': 'Vehicles',
    'permissionGroups.vehicle_history': 'Vehicles - History',
    'permissionGroups.vehicle_documents': 'Vehicles - Documents',
    'permissionGroups.vehicle_equipments': 'Vehicles - Equipment',
    'permissionGroups.vehicle_events': 'Vehicles - Events',
    'permissionGroups.vehicle_fuelings': 'Vehicles - Refueling',
    'permissionGroups.vehicle_positions': 'Vehicles - Positions',
    'permissionGroups.vehicle_messages': 'Chat',
    'permissionGroups.trailers': 'Trailers',
    'permissionGroups.trailer_history': 'Trailers - History',
    'permissionGroups.trailer_documents': 'Trailers - Documents',
    'permissionGroups.trailer_equipments': 'Trailers - Equipment',
    'permissionGroups.trailer_events': 'Trailers - Events',
    'permissionGroups.drivers': 'Drivers',
    'permissionGroups.driver_history': 'Drivers - History',
    'permissionGroups.driver_documents': 'Drivers - Documents',
    'permissionGroups.driver_equipments': 'Drivers - Equipment',
    'permissionGroups.driver_events': 'Drivers - Events',
    'permissionGroups.driver_allowances': 'Drivers - Allowances',
    'permissionGroups.driver_timesheets': 'Drivers - Worksheets',
    'permissionGroups.customers': 'Address book - Customers',
    'permissionGroups.customer_addresses': 'Address book - Customers - Addresses',
    'permissionGroups.customer_contacts': 'Address book - Customers - Contacts',
    'permissionGroups.customer_plans': 'Address book - Customers - Plans',
    'permissionGroups.addresses': 'Address book - Address book',
    'permissionGroups.carriers': 'Address book - Carrier',
    'permissionGroups.carrier_contacts': 'Address book - Carrier - Contacts',
    'permissionGroups.border_crossings': 'Address book - Border crossings',
    'permissionGroups.transport_conditions': 'Settings - Transports - Transport Conditions',
    'permissionGroups.transport_types': 'Settings - Transports - Transport Types',
    'permissionGroups.cargo_types': 'Settings - Transports - Cargo Types',
    'permissionGroups.goods_types': 'Settings - Transports - Goods Types',
    'permissionGroups.packaging_types': 'Settings - Transports - Packaging types',
    'permissionGroups.surcharge_types': 'Settings - Transports - Surcharge Types',
    'permissionGroups.transport_scoring': 'Settings - Transports - Scoring',
    'permissionGroups.tax_rates': 'Settings - Billing - Tax rates',
    'permissionGroups.payment_methods': 'Settings - Billing - Payment Methods',
    'permissionGroups.invoice_item_types': 'Settings - Billing - Invoice item types',
    'permissionGroups.vehicle_types': 'Settings - Fleet - Vehicle types',
    'permissionGroups.trailer_types': 'Settings - Fleet - Trailer types',
    'permissionGroups.document_types': 'Settings - Fleet - Document types',
    'permissionGroups.equipments': 'Settings - Fleet - Equipment',
    'permissionGroups.companies': 'Settings - Address book - Companies',
    'permissionGroups.company_cost_centers': 'Settings - Address book - Companies - Cost centers',
    'permissionGroups.company_bank_accounts': 'Settings - Address book - Companies - Bank accounts',
    'permissionGroups.company_default_bank_accounts': 'Settings - Address book - Companies - Default bank account',
    'permissionGroups.company_numberings': 'Settings - Address book - Companies - Number series',
    'permissionGroups.company_settings': 'Settings - Address book - Companies - Settings',
    'permissionGroups.countries': 'Settings - Address book - Country',
    'permissionGroups.currencies': 'Settings - Address book - Currencies',
    'permissionGroups.customer_address_types': 'Settings - Address book - Address types',
    'permissionGroups.carrier_scoring': 'Settings - Address book - Scoring',
    'permissionGroups.users': 'Settings - Users',
    'permissionGroups.users_login_log': 'Settings - Users - Login log',
    'permissionGroups.user_roles': 'Settings - Users - User Roles',
    'permissionGroups.user_logins': 'Settings - Users - Login log',
    'permissionGroups.blocked_ips': 'Settings - Users - Blocked IP addresses',
    'permissionGroups.general_settings_transports': 'Settings - General settings - Transports / Tracing',
    'permissionGroups.general_settings_email': 'Settings - General settings - Mailing',
    'permissionGroups.general_settings_invoices': 'Settings - General settings - Invoices',
    'permissionGroups.general_settings_security': 'Settings - General settings - Security',
    'permissionGroups.mails_user_blocked': 'Blocked user administrator e-mial',
    'permissionGroups.mails_ip_address_blocked': 'Blocking ip addresses administrator e-mail',
    'permissionGroups.notification': 'Notifikace',
    'permissionGroups.diet_rates': 'Settings - Diet rates',
    'permissionGroups.cost_types': 'Settings - Cost types',
    'permissionGroups.costs': 'Costs',
    'permissionGroups.report_cost_center_cost': 'Reports - Cost centers - Costs',
    'permissionGroups.sales_types': 'Settings - Types of sales',
    'permissionGroups.drivers_types': 'Settings - Types of drivers',
    'permissionGroups.sales': 'Sales',


    'permissions.read': 'Read',
    'permissions.create': 'Create',
    'permissions.update': 'Edit',
    'permissions.delete': 'Delete',
    'permissions.write': 'Write',
    'permissions.copy_to_clipboard': 'Copy to clipboard',
    'permissions.export': 'Export',
    'permissions.import': 'Import',
    'permissions.restore': 'Restore',
    'permissions.download': 'Download',
    'permissions.send': 'Send',
    'permissions.receive': 'Receiving',
    'permissions.export_to_accounting': 'Export to accounting',
    'permissions.approval': 'Approval',

    'permissions.no_permission': 'You do not have permission to view this page.',

    // Travel allowances
    'modules.reportAllowances.heading': 'Reports - Allowances',
    'modules.reportAllowances.drivers': 'Driver name',
    'modules.reportAllowances.months': 'Month',
    'modules.reportAllowances.spendingMoney': 'Spending money',
    'modules.reportAllowances.allowances': 'Allowances',
    'modules.reportAllowances.additionalAllowances': 'Additional allowances',
    'modules.reportAllowances.noAdditionalAllowances': 'No additional allowances',
    'modules.reportAllowances.addAdditionalAllowance': 'Add additional allowances',
    'modules.reportAllowances.additionalAllowanceAmount': 'Additional allowances amount',
    'modules.reportAllowances.additionalAllowanceDate': 'Additional allowances date',
    'modules.reportAllowances.calculationMethods.0': 'Temporal',
    'modules.reportAllowances.calculationMethods.1': 'Flat',
    'modules.reportAllowances.calculationMethods.2': 'Daily (working days)',
    'modules.reportAllowances.calculationMethods.3': 'Daily (weekend, holidays)',
    'modules.reportAllowances.calculationMethods.4': 'Monthly',
    'modules.reportAllowances.calculationMethods.5': 'Kilometer',

    // Driver performance analysis
    'modules.driverPerformanceAnalysis.period': 'Period',
    'modules.driverPerformanceAnalysis.driver': 'Driver',
    'modules.driverPerformanceAnalysis.drivingStyle': 'Driving style',
    'modules.driverPerformanceAnalysis.degreeOfDifficulty': 'Difficulty',
    'modules.driverPerformanceAnalysis.totalDistance': 'Total distance',
    'modules.driverPerformanceAnalysis.speedOver85DistancePerc': '> 85 km/h',
    'modules.driverPerformanceAnalysis.overallConsumption': 'Overall consumption',
    'modules.driverPerformanceAnalysis.driveConsumption': 'Driving consumption',
    'modules.driverPerformanceAnalysis.serviceBrakeDistance': 'Service brake',
    'modules.driverPerformanceAnalysis.handBrakeUsageCount': 'Parking brake',

    // Period picker
    'modules.periodPicke.heading': 'Period',
    'modules.periodPicke.year': 'Year',
    'modules.periodPicke.half': 'Half a year',
    'modules.periodPicke.quarter': 'Quarter',
    'modules.periodPicke.month': 'Moon',
    'modules.periodPicke.week': 'Week',
    'modules.periodPicke.day': 'Day',
    'modules.periodPicke.period': 'Own period',
    'modules.periodPicke.weekShort': 'W',

    // Border crossings
    'modules.borderCrossings.heading': 'Border crossings',

    // Tooltips
    'tooltips.shortAscending': 'Sort ascending',
    'tooltips.shortDescending': 'Sort descending',

    // Map
    'modules.map.poisLoading': 'Loading points of interest',

    // Vehicle fueling
    'modules.vehicleFuelings.heading': 'Refueling',
    'modules.vehicleFuelings.importHistory': 'Imports history',
    'modules.vehicleFuelings.drive': 'DRI',
    'modules.vehicleFuelings.night': 'NIG',
    'modules.vehicleFuelings.weekend': 'WEK',
    'modules.vehicleFuelings.holiday': 'HOL',
    'modules.vehicleFuelings.otherJob': 'OJ',
    'modules.vehicleFuelings.vacation': 'V',
    'modules.vehicleFuelings.paidVacation': 'PV',
    'modules.vehicleFuelings.unpaidVacation': 'UV',
    'modules.vehicleFuelings.useOvertime': 'UO',
    'modules.vehicleFuelings.disease': 'D',
    'modules.vehicleFuelings.absence': 'A',
    'modules.vehicleFuelings.holidayNotWorking': 'NWH',
    'modules.vehicleFuelings.driveOvertimesHolidayNotWorking': 'DRI+OJ +UO+NWH',
    'modules.vehicleFuelings.overtime': 'OT',
    // Vehicle fueling - import
    'modules.vehicleFuelings.firstImportLine': 'The first import record',
    'modules.vehicleFuelings.numberedValues':
        'Products appear to be marked with codes. Select or import a list of gas station products.',
    // Vehicle fueling - import - steps
    'modules.vehicleFuelings.importStep1': 'Import refueling records - File selection',
    'modules.vehicleFuelings.importStep2': 'Import refueling records - Assign items',
    'modules.vehicleFuelings.importStep3': 'Import Refueling Records - Product Assignment',
    'modules.vehicleFuelings.importStep4': 'Import refueling records - Import preview',
    'modules.vehicleFuelings.importProductsStep1': 'Import refueling products - File selection',
    'modules.vehicleFuelings.importProductsStep2': 'Import Refueling Products - Assign Items',
    'modules.vehicleFuelings.importProductsStep3': 'Import Refueling Products - Import Preview',
    // Vehicle fueling - import - infolog
    'modules.vehicleFuelings.infolog.notFound': 'Value not found in imported file',
    'modules.vehicleFuelings.infolog.notFoundObject': 'No matching item found',
    'modules.vehicleFuelings.infolog.fromFile': 'Imported from file',
    'modules.vehicleFuelings.infolog.fromPoint': 'Found by position',
    'modules.vehicleFuelings.infolog.fromLastPoint': 'Found according to the last known position',
    'modules.vehicleFuelings.infolog.empty': 'Empty',
    'modules.vehicleFuelings.infolog.format': 'Bad format',
    'modules.vehicleFuelings.infolog.timeNotFound': 'Time not found',
    'modules.vehicleFuelings.infolog.cantFind': 'Cannot be found',
    // Vehicle positions
    'modules.vehiclePositions.heading': 'Positions',
    // Tracing
    'modules.tracing.heading': 'Tracing',
    'modules.tracing.transportDetailHeading': 'Transport detail',
    'modules.tracing.routeDefinitionHeading': 'Definice trasy',
    'modules.tracing.detailHeading': 'Route detail',
    'modules.tracing.totalDistance': 'Total distance',
    'modules.tracing.totalTime': 'Shipping time',
    'modules.tracing.shippingTime': 'Driving time + breaks',
    'modules.tracing.contractPrice': 'Contract price',
    'modules.tracing.totalCost': 'Total cost',
    'modules.tracing.economicResults': 'Economic results',
    'modules.tracing.additionalCosts': 'Additional costs',
    'modules.tracing.fixedRate': 'Fixed rate',
    'modules.tracing.extraCosts': 'Extra costs',
    'modules.tracing.waypoints': 'Waypoints',
    'modules.tracing.timePlane': 'Detailed schedule',
    'modules.tracing.map.vehiclesLoading': 'Loading vehicles',
    'modules.tracing.map.vehicleSearch': 'Vehicle search',
    'modules.tracing.warningApproachTime': 'The approach is not in the time range',
    'modules.tracing.warningShippingTime': 'Shipping is out of time',
    'modules.tracing.warningTime': 'Shipment cannot be created! The set time range is not enough for the total time (driving + breaks)',
    'modules.tracing.warningCustomer': 'Please select a customer before continuing',
    'modules.tracing.warningProfit': 'Profit must be at least {profit}.',

    // Notifications
    'notifications.allItems': 'All notifications',
    'notifications.unreadItems': 'You have {count} unread messages',
    'notifications.noItemsFound': 'No notifications found',
    'notifications.markAsRead': 'Mark as read',

    'permissions.general': 'General',
    'permissions.transports_load_arrived': 'Arrival for loading',
    'permissions.transports_load_wait': 'Waiting for loading',
    'permissions.transports_load_finished': 'End of loading',
    'permissions.transports_unload_arrival': 'Arrival for unloading',
    'permissions.transports_unload_wait': 'Waiting for unloading',
    'permissions.transports_unload_finished': 'End of unloading',
    'permissions.transports_state_changed_processing': 'Shipping Status: Processing',
    'permissions.transports_state_changed_delivered': 'Shipping Status: Completed',
    'permissions.transports_state_changed_canceled': 'Shipping Status: Canceled',
    'permissions.transporeon_offers': 'New Transporeon offer',
    'permissions.vehicle_message': 'Chat',
}

export default translations
