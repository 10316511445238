import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportAppUsage = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportAppUsageFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportAppUsageRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportAppUsageCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportAppUsage = () => ({
    type: actionTypes.CLEAR,
})
