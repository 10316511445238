import { Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: [],
        fetching: false,
        error: null,
    }),
    invoicingStateSetting: Map({
        inProgress: false,
        error: null,
    }),
    dataForInvoicing: Map({
        data: {},
        fetching: false,
        error: null,
    }),
    creatingInvoice: Map({
        createdInvoiceId: null,
        inProgress: false,
        error: null,
    }),
})

function invoicingOrders(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload.data)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // Clear
        case actionTypes.CLEAR:
            return state.setIn(['all', 'objects'], OrderedMap({}))

        // Set invoicing state
        case actionTypes.SET_INVOICING_STATE_STARTED:
            return state.setIn(['invoicingStateSetting', 'inProgress'], true)
        case actionTypes.SET_INVOICING_STATE_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload.data)
                    .setIn(['invoicingStateSetting', 'inProgress'], false)
                    .setIn(['invoicingStateSetting', 'error'], null)
            })
        case actionTypes.SET_INVOICING_STATE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['invoicingStateSetting', 'inProgress'], false).setIn(['invoicingStateSetting', 'error'], action.payload)
            })
        case actionTypes.SET_INVOICING_STATE_CANCELLED:
            return state.setIn(['invoicingStateSetting', 'inProgress'], false)

        // Set as invoiced
        case actionTypes.SET_AS_INVOICED_STARTED:
            return state.setIn(['invoicedSetting', 'inProgress'], true)
        case actionTypes.SET_AS_INVOICED_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload.data)
                    .setIn(['invoicedSetting', 'inProgress'], false)
                    .setIn(['invoicedSetting', 'error'], null)
            })
        case actionTypes.SET_AS_INVOICED_REJECTED:
            return state.withMutations(map => {
                map.setIn(['invoicedSetting', 'inProgress'], false).setIn(['invoicedSetting', 'error'], action.payload)
            })
        case actionTypes.SET_AS_INVOICED_CANCELLED:
            return state.setIn(['invoicedSetting', 'inProgress'], false)

        // Fetch data for invoicing
        case actionTypes.FETCH_DATA_FOR_INVOICING_STARTED:
            return state.setIn(['dataForInvoicing', 'fetching'], true)
        case actionTypes.FETCH_DATA_FOR_INVOICING_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['dataForInvoicing', 'data'], action.payload.data)
                    .setIn(['dataForInvoicing', 'fetching'], false)
                    .setIn(['dataForInvoicing', 'error'], null)
            })
        case actionTypes.FETCH_DATA_FOR_INVOICING_REJECTED:
            return state.withMutations(map => {
                map.setIn(['dataForInvoicing', 'fetching'], false).setIn(['dataForInvoicing', 'error'], action.payload)
            })
        case actionTypes.FETCH_DATA_FOR_INVOICING_CANCELLED:
            return state.setIn(['dataForInvoicing', 'fetching'], false)

        // Create invoice
        case actionTypes.CREATE_INVOICE_STARTED:
            return state.setIn(['creatingInvoice', 'inProgress'], true)
        case actionTypes.CREATE_INVOICE_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload.data)
                    .setIn(['creatingInvoice', 'createdInvoiceId'], action.payload.createdInvoiceId)
                    .setIn(['creatingInvoice', 'inProgress'], false)
                    .setIn(['creatingInvoice', 'error'], null)
            })
        case actionTypes.CREATE_INVOICE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['creatingInvoice', 'inProgress'], false).setIn(['creatingInvoice', 'error'], action.payload)
            })
        case actionTypes.CREATE_INVOICE_CANCELLED:
            return state.setIn(['creatingInvoice', 'inProgress'], false)

        default:
            return state
    }
}

export default invoicingOrders
