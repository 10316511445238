import * as actionTypes from './actionTypes'

// Fetch

export const fetchDriversTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchDriversTypesFulfilled = driversTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: driversTypes,
})

export const fetchDriversTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchDriversTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchDriversType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchDriversTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchDriversTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchDriversTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveDriversType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveDriversTypeFulfilled = driversType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: driversType,
})

export const saveDriversTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteDriversType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteDriversTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteDriversTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})
