import { Map } from 'immutable'
import * as actionTypes from './actionTypes'

const localStorageKey = 'locale'

const initialState = Map({
    current: localStorage.getItem(localStorageKey) ? localStorage.getItem(localStorageKey) : 'cs',
})

function locale(state = initialState, action) {
    switch (action.type) {
        // Change
        case actionTypes.CHANGE:
            localStorage.setItem(localStorageKey, action.payload)

            return state.set('current', action.payload)
        default:
            return state
    }
}

export default locale
