// Fetch list

export const FETCH = 'reportCustomerSpeditions.FETCH'
export const FETCH_STARTED = 'reportCustomerSpeditions.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportCustomerSpeditions.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportCustomerSpeditions.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportCustomerSpeditions.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportCustomerSpeditions.CLEAR'

// Export

export const EXPORT = 'reportCustomerSpeditions.EXPORT'
export const EXPORT_FULFILLED = 'reportCustomerSpeditions.EXPORT_FULFILLED'

// Fetch details

export const FETCH_DETAILS = 'reportCustomerSpeditions.FETCH_DETAILS'
export const FETCH_DETAILS_STARTED = 'reportCustomerSpeditions.FETCH_DETAILS_STARTED'
export const FETCH_DETAILS_FULFILLED = 'reportCustomerSpeditions.FETCH_DETAILS_FULFILLED'
export const FETCH_DETAILS_REJECTED = 'reportCustomerSpeditions.FETCH_DETAILS_REJECTED'
export const FETCH_DETAILS_CANCELLED = 'reportCustomerSpeditions.FETCH_DETAILS_CANCELLED'

// Clear details

export const CLEAR_DETAILS = 'reportCustomerSpeditions.CLEAR_DETAILS'

// Export details

export const EXPORT_DETAILS = 'reportCustomerSpeditions.EXPORT_DETAILS'
export const EXPORT_DETAILS_FULFILLED = 'reportCustomerSpeditions.EXPORT_DETAILS_FULFILLED'
