import { ajax } from 'rxjs/observable/dom/ajax'
import * as config from './config'

export const request = (params, authenticated = true, accessToken) => {
    if (params.url === undefined) {
        params.url = `${config.CFG_API_BASE_URL}/${params.path}`

        delete params.path
    }

    if (params.headers === undefined) {
        params.headers = {}
    }

    params.headers['Content-Type'] = 'application/json'

    if (authenticated) {
        if (accessToken === undefined) {
            accessToken = localStorage.getItem('accessToken')
        }

        params.headers.Authorization = `Bearer ${accessToken}`
    } else {
        params.body.client_secret = 'ORl6sCYpILplZWQcJ4bbiBhx5PoyFUhfXZ2BHStL'
    }

    return ajax(params)
}

export const parseAPIError = ajaxError => {
    if (ajaxError.xhr === undefined || ajaxError.xhr.response === null || ajaxError.xhr.response.error_message === undefined) {
        return 'An unexpected error occurred while communicating with the server.'
    }
    return (
        ajaxError.xhr.response.error_message +
        (ajaxError.xhr.response.error_description === undefined ? '' : `: ${ajaxError.xhr.response.error_description}`)
    )
}

export class DownloadRequest {
    constructor(options) {
        this.url = options.url
    }

    run() {
        window.location.assign(`${config.CFG_API_BASE_URL}/${this.url}`)
    }
}
