import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import ColoredBox from '../../../common/colored_box/components/ColoredBox'

import * as formatters from '../../../common/formatters'

import * as constants from '../constants'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class InvoicingOrdersRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        if (this.props.item && this.props.item.invoicing_state_id !== constants.INVOICING_STATE_INVOICED) {
            this.props.onSelectionToggle && this.props.onSelectionToggle(this.props.item)
        }
    }

    handleChangeInvoicingState = (e, invoicingStateId) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onChangeInvoicingState && this.props.onChangeInvoicingState(this.props.item, invoicingStateId)
    }

    handleInvoiceClick = (e, invoiceId) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onInvoiceClick && this.props.onInvoiceClick(invoiceId)
    }

    handleToggle = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onSelectionToggle && this.props.onSelectionToggle(this.props.item)
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const item = this.props.item

        if (!item) {
            return null
        }

        const isSelected = this.props.isSelected
        const isDisabled = item.invoicing_state_id === constants.INVOICING_STATE_INVOICED

        const tooltipChangeToReady = (
            <Tooltip id="tooltipChangeToReady">
                {`${this.props.intl.formatMessage({ id: 'fields.changeTo' })}: ${this.props.intl.formatMessage({
                    id: `invoicingStates.${constants.INVOICING_STATE_READY}`,
                })}`}
            </Tooltip>
        )

        const tooltipChangeToNotInvoiced = (
            <Tooltip id="tooltipChangeToNotInvoiced">
                {`${this.props.intl.formatMessage({ id: 'fields.changeTo' })}: ${this.props.intl.formatMessage({
                    id: `invoicingStates.${constants.INVOICING_STATE_NOT_INVOICED}`,
                })}`}
            </Tooltip>
        )

        const tooltipChangeToInvoiced = (
            <Tooltip id="tooltipChangeToInvoiced">
                {`${this.props.intl.formatMessage({ id: 'fields.changeTo' })}: ${this.props.intl.formatMessage({
                    id: `invoicingStates.${constants.INVOICING_STATE_INVOICED}`,
                })}`}
            </Tooltip>
        )

        const invoicingStateColors = {
            [constants.INVOICING_STATE_NOT_INVOICED]: {
                name: this.props.intl.formatMessage({ id: `invoicingStates.${constants.INVOICING_STATE_NOT_INVOICED}` }),
                backgroundColor: '#ff0000',
                color: '#ffffff',
            },
            [constants.INVOICING_STATE_READY]: {
                name: this.props.intl.formatMessage({ id: `invoicingStates.${constants.INVOICING_STATE_READY}` }),
                backgroundColor: '#f69833',
                color: '#ffffff',
            },
            [constants.INVOICING_STATE_INVOICED]: {
                name: this.props.intl.formatMessage({ id: `invoicingStates.${constants.INVOICING_STATE_INVOICED}` }),
                backgroundColor: '#22baa0',
                color: '#ffffff',
            },
        }

        return (
            <tr onClick={this.handleClick} className="row-selectable">
                <PermissionsCheck has={[PERMS.CREATE]}>
                    <td className="w-60 text-center">
                        <div>
                            {isSelected && !isDisabled ? (
                                <button onClick={this.handleToggle} className="btn btn-dark far fa-check" disabled={isDisabled} />
                            ) : (
                                <button onClick={this.handleToggle} className="btn btn-default far fa-square" disabled={isDisabled} />
                            )}
                        </div>
                    </td>
                </PermissionsCheck>
                <td className="w-100">
                    <strong>{item.transport_id}</strong>
                    <br />
                    <small className="text-gray">{formatters.datetimeFormatter(item.created_at)}</small>
                </td>
                <td className="w-120">
                    {invoicingStateColors[item.invoicing_state_id] && (
                        <span
                            className="label"
                            style={{
                                backgroundColor: invoicingStateColors[item.invoicing_state_id].backgroundColor,
                                color: invoicingStateColors[item.invoicing_state_id].color,
                            }}
                        >
                            {invoicingStateColors[item.invoicing_state_id].name}
                        </span>
                    )}
                    {item.invoicing_state_id === constants.INVOICING_STATE_NOT_INVOICED && (
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <span className="pull-right action-icon">
                                <OverlayTrigger placement="right" overlay={tooltipChangeToReady}>
                                    <a
                                        href="#"
                                        className="text-danger"
                                        onClick={e => {
                                            this.handleChangeInvoicingState(e, constants.INVOICING_STATE_READY)
                                        }}
                                    >
                                        <i className="far fa-lg fa-toggle-off" />
                                    </a>
                                </OverlayTrigger>
                            </span>
                        </PermissionsCheck>
                    )}
                    {item.invoicing_state_id === constants.INVOICING_STATE_READY && (
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <span className="pull-right action-icon">
                                <OverlayTrigger placement="right" overlay={tooltipChangeToNotInvoiced}>
                                    <a
                                        href="#"
                                        className="text-orange"
                                        onClick={e => {
                                            this.handleChangeInvoicingState(e, constants.INVOICING_STATE_NOT_INVOICED)
                                        }}
                                    >
                                        <i className="fas fa-lg fa-toggle-on" />
                                    </a>
                                </OverlayTrigger>
                                <OverlayTrigger placement="right" overlay={tooltipChangeToInvoiced}>
                                    <a
                                        href="#"
                                        className="text-success m-l-xxs"
                                        onClick={e => {
                                            this.handleChangeInvoicingState(e, constants.INVOICING_STATE_INVOICED)
                                        }}
                                    >
                                        <i className="fas fa-lg fa-check" />
                                    </a>
                                </OverlayTrigger>
                            </span>
                        </PermissionsCheck>
                    )}
                    {item.invoicing_state_id === constants.INVOICING_STATE_INVOICED && item.invoices.length === 0 && (
                        <PermissionsCheck has={[PERMS.UPDATE]}>
                            <span className="pull-right action-icon">
                                <OverlayTrigger placement="right" overlay={tooltipChangeToReady}>
                                    <a
                                        href="#"
                                        className="text-danger m-l-xxs"
                                        onClick={e => {
                                            this.handleChangeInvoicingState(e, constants.INVOICING_STATE_READY)
                                        }}
                                    >
                                        <i className="fas fa-lg fa-times" />
                                    </a>
                                </OverlayTrigger>
                            </span>
                        </PermissionsCheck>
                    )}
                    {item.invoices.map(invoice => (
                        <small key={`invoice-${item.id}-${invoice.id}`} className="display-inline wp-100">
                            <a
                                href="#"
                                className="text-gray"
                                onClick={e => {
                                    this.handleInvoiceClick(e, invoice.id)
                                }}
                            >
                                {invoice.number}
                            </a>
                        </small>
                    ))}
                </td>
                <td className="w-max wm-300">
                    <strong>{item.customer && item.customer.name}</strong>
                    <br />
                    <small className="text-gray">{item.order_number}</small>
                </td>
                <td className="w-120 text-right">
                    <strong>{formatters.priceFormatter(item.price, '0,0', item.currency ? item.currency.iso_code : '')}</strong>
                    <br />
                    <small className="text-gray">{formatters.priceFormatter(item.price * item.currency_rate)}</small>
                </td>
                <td className="w-30 text-center">
                    {item.transport_files && item.transport_files.length > 0 && (
                        <span>
                            {item.is_all_required_files_uploaded ? (
                                <i className="fas fa-file text-success" />
                            ) : (
                                <i className="fas fa-file text-gray-light" />
                            )}
                        </span>
                    )}
                </td>
                <td className="w-400">
                    {item.loading && item.loading.address}
                    {item.loading && item.loading.name && ` - ${item.loading.name}`}
                    <br />
                    <small className="text-gray">
                        {item.loading && item.loading.datetime}
                        {item.loading && item.loading.datetime && item.loading.code && ' | '}
                        {item.loading && item.loading.code}
                    </small>
                </td>
                <td className="w-400">
                    {item.unloading && item.unloading.address}
                    {item.unloading && item.unloading.name && ` - ${item.unloading.name}`}
                    <br />
                    <small className="text-gray">
                        {item.unloading && item.unloading.datetime}
                        {item.unloading && item.unloading.datetime && item.unloading.code && ' | '}
                        {item.unloading && item.unloading.code}
                    </small>
                </td>
                {item.is_spedition ? (
                    <td colSpan={2} className="w-240">
                        <div>
                            <ColoredBox bgRGB="#cccccc" textRGB="#222222" className="wp-100 display-inline text-center">
                                <FormattedMessage id="fields.spedition" />{item.carrier_vehicle_registration && (' - '+item.carrier_vehicle_registration)}
                            </ColoredBox>
                            <br />
                            <small className="text-gray">{item.carrier && item.carrier.name}</small>
                        </div>
                    </td>
                ) : (
                    <React.Fragment>
                        <td className="w-120">
                            {item.vehicles.map(vehicle => (
                                <span key={`vehicle-${item.id}-${vehicle.id}`} className="display-inline wp-100">
                                    <ColoredBox
                                        bgRGB={item.dispatcher && item.dispatcher.rgb_bg}
                                        textRGB={item.dispatcher && item.dispatcher.rgb_text}
                                    >
                                        {vehicle.registration}
                                    </ColoredBox>
                                </span>
                            ))}
                        </td>
                        <td className="w-120">
                            {item.trailers.map(trailer => (
                                <span key={`trailer-${item.id}-${trailer.id}`} className="display-inline wp-100">
                                    <ColoredBox>{trailer.registration}</ColoredBox>
                                </span>
                            ))}
                        </td>
                    </React.Fragment>
                )}
            </tr>
        )
    }
}

export default injectIntl(InvoicingOrdersRow)
