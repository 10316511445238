import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as ReportCostCenterProfitsActionCreators from '../actionCreators'
import { getYear, getData, getFetching, getDetailsData, getDetailsFetching } from '../selectors'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import CheckboxField from "../../../common/form/components/CheckboxField";
import InputField from "../../../common/form/components/InputField";
import * as helpers from "../../../common/helpers";
import { DispatchersBox } from "../../../common/colored_box";
import { SimpleSelectField } from "../../../common/form";

const componentIdentifier = 'report_cost_center_profits'
const prerequisites = ['dispatchers', 'company_cost_centers']

const tableIdentifier = 'report_cost_center_profits'

class ReportCostCenterProfits extends Component {
    state = {
        modalDetails: {
            show: false,
            id: null,
            month: null,
            name: null
        },
        inclDrivers: true,
        inclVehicles: true,
        inclTrailers: true,
        inclFeatureCosts: true,
        withEmpty: false,
    }

    // list
    getYear = () => {
        if (this.props.year) {
            return parseInt(this.props.year)
        }

        return parseInt(this.props.match.params.year ? this.props.match.params.year : moment().format('YYYY'))
    }

    refresh = () => {
        this.props.actions.fetchReportCostCenterProfits(this.getYear(), this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers, this.state.inclFeatureCosts, this.state.withEmpty)
    }


    handleChangeYearMonth = format => {
        const year = format.year.toString()
        const path = `/report-cost-center-profits/${year}`
        this.props.history.push(path)
    }


    changeInclVehicles = value => {
        this.setState({
            ...this.state,
            inclVehicles: value
        }, () => {
            this.state.modalDetails.show ? this.refreshDetails() : this.refresh()
        })
    }

    changeInclDrivers = value => {
        this.setState({
            ...this.state,
            inclDrivers: value
        }, () => {
            this.state.modalDetails.show ? this.refreshDetails() : this.refresh()
        })
    }

    changeInclTrailers = value => {
        this.setState({
            ...this.state,
            inclTrailers: value
        }, () => {
            this.state.modalDetails.show ? this.refreshDetails() : this.refresh()
        })
    }

    changeInclFeatureCosts = value => {
        this.setState({
            ...this.state,
            inclFeatureCosts: value
        }, () => {
            this.state.modalDetails.show ? this.refreshDetails() : this.refresh()
        })
    }
    changeWithEmpty = value => {
        this.setState({
            ...this.state,
            withEmpty: value
        }, () => {
            this.state.modalDetails.show ? this.refreshDetails() : this.refresh()
        })
    }

    // export
    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportCostCenterProfits(this.getYear(), this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers, this.state.inclFeatureCosts, this.state.withEmpty)
    }

    // detail
    handleShowDetails = (e, id, month, name) => {
        e && e.preventDefault()

        this.setState({
            modalDetails: {
                show: true,
                id,
                month,
                name
            },
        }, () => {
            this.props.actions.fetchReportCostCenterProfitsDetails(id, this.getYear(), 0, this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers, this.state.inclFeatureCosts)
        })
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState({
            modalDetails: {
                show: false,
                id: null,
                month: null,
                name: null,
            },
        }, () => {
            this.refresh()
            this.props.actions.clearReportCostCenterProfitsDetails()
        })
    }

    refreshDetails = () => {
        this.props.actions.fetchReportCostCenterProfitsDetails(this.state.modalDetails.id, this.getYear(), 0, this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers, this.state.inclFeatureCosts)
    }

    // export detail
    handleExportDetails = e => {
        e && e.preventDefault()

        this.props.actions.exportReportCostCenterProfitsDetails(this.state.modalDetails.id, this.getYear(), 0, this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers, this.state.inclFeatureCosts)
    }

    componentDidMount() {
        this.props.actions.fetchReportCostCenterProfits(this.getYear(), this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers, this.state.inclFeatureCosts, this.state.withEmpty)
        this.props.actions.fetchPrerequisites()
    }

    componentWillUnmount() {
        this.props.actions.clearReportCostCenterProfits()
    }

    componentWillReceiveProps(nextProps) {
        const parsedYear = parseInt(nextProps.match.params.year ? nextProps.match.params.year : moment().format('YYYY'))

        if (parsedYear !== this.getYear()) {
            this.props.actions.clearReportCostCenterProfits()
            this.props.actions.fetchReportCostCenterProfits(parsedYear, this.state.inclVehicles, this.state.inclDrivers, this.state.inclTrailers, this.state.inclFeatureCosts, this.state.withEmpty)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportCostCenterProfits.heading' }))

        const data = this.props.data && this.props.data.cost_centers
        const total = this.props.data && this.props.data.total

        const detailsData = this.props.detailsData && this.props.detailsData

        const momentFrom = moment(`${this.getYear()}-01-01`, 'YYYY-MM-DD')
        const momentTo = moment(momentFrom).endOf('year')

        const monthKeys = [...Array(12).keys()]

        return (<PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
            <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                <div id="main-wrapper">
                    <div className="panel panel-white">
                        <div className="panel-body">
                            <div className="wp-100 pull-left m-b-xs">
                                <h4 className="pull-left">
                                    <span className="pull-left">
                                        <FormattedMessage id="modules.reportCostCenterProfits.heading" />
                                    </span>
                                </h4>

                                <div className="pull-left" style={{ marginLeft: '60px' }}>
                                    <span className="pull-left text-gray f-bold" style={{ paddingTop: '10px' }}>
                                        <FormattedMessage id="fields.showIncl" />:
                                    </span>
                                    <div className="pull-left m-l-sm">
                                        <CheckboxField
                                            identifier="with_empty"
                                            onChange={(e) => { this.changeWithEmpty(e.target.checked) }}
                                            label={this.props.intl.formatMessage({ id: 'fields.showInclEmpty' })}
                                            value={this.state.withEmpty}
                                        />
                                    </div>
                                </div>

                                <div className="pull-left" style={{ marginLeft: '60px' }}>
                                    <span className="pull-left text-gray f-bold" style={{ paddingTop: '10px' }}>
                                        <FormattedMessage id="fields.show_costs" />:
                                    </span>
                                    <div className="pull-left m-l-sm">
                                        <CheckboxField
                                            id="incl_drivers"
                                            onChange={(e) => { this.changeInclDrivers(e.target.checked) }}
                                            label={this.props.intl.formatMessage({ id: 'fields.incl_drivers' })}
                                            value={this.state.inclDrivers}
                                        />
                                    </div>
                                    <div className="pull-left m-l-sm">
                                        <CheckboxField
                                            id="incl_vehicles"
                                            onChange={(e) => { this.changeInclVehicles(e.target.checked) }}
                                            label={this.props.intl.formatMessage({ id: 'fields.incl_vehicles' })}
                                            value={this.state.inclVehicles}
                                        />
                                    </div>
                                    <div className="pull-left m-l-sm">
                                        <CheckboxField
                                            id="incl_trailers"
                                            onChange={(e) => { this.changeInclTrailers(e.target.checked) }}
                                            label={this.props.intl.formatMessage({ id: 'fields.incl_trailers' })}
                                            value={this.state.inclTrailers}
                                        />
                                    </div>

                                    <div className="pull-left" style={{marginLeft: '60px'}}>
                                        <CheckboxField
                                            id="incl_feature_costs"
                                            onChange={(e) => { this.changeInclFeatureCosts(e.target.checked) }}
                                            label={this.props.intl.formatMessage({ id: 'fields.incl_feature_costs' })}
                                            value={this.state.inclFeatureCosts}r
                                        />
                                    </div>
                                </div>

                                <div className="btns-list">
                                    <PeriodPicker
                                        className={'m-r-sm'}
                                        momentFrom={momentFrom}
                                        momentTo={momentTo}
                                        onChange={(momentFrom, momentTo, format) => this.handleChangeYearMonth(format)}
                                        allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_YEAR]}
                                        forbidPickType
                                    />
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                </div>
                            </div>

                            <div className="table-container">
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                    <tr>
                                        <th className="w-max wm-200" />


                                        {monthKeys.map(key => {
                                            const month = moment().set('month', key)

                                            return (<th key={`th-month-${key}`} className="w-100 text-right">
                                                {this.props.intl.formatMessage({ id: `monthNames.${month.format('MM')}` })}
                                            </th>)
                                        })}

                                        <th className="w-140 text-right">
                                            <FormattedMessage id="fields.total" />
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data && data.map(row => {
                                        const total = row.data.total

                                        if (parseInt(row.is_active) === 0 && total === 0) {
                                            return null
                                        }

                                        return (<tr key={`row-${row.id}`}>
                                            <td className="w-max wm-200 td-clickable" onClick={e => {
                                                this.handleShowDetails(e, row.id, 0, row.name)
                                            }}>
                                                {row.name}
                                                {row.drivers.length > 0 && (
                                                    <span className="text-gray">
                                                       &nbsp;+ {row.drivers.length} {row.drivers.length === 1 ? 'řidič' : (row.drivers.length <= 4 ? 'řidiči' : 'řidičů')}
                                                    </span>
                                                )}
                                                {row.vehicles.length > 0 && (
                                                    <span className="text-gray">
                                                       &nbsp;+ {row.vehicles.length} {row.vehicles.length === 1 ? 'vozidlo' : (row.vehicles.length <= 4 ? 'vozidla' : 'vozidel')}
                                                    </span>
                                                )}
                                                {row.trailers.length > 0 && (
                                                    <span className="text-gray">
                                                       &nbsp;+ {row.trailers.length} {row.trailers.length === 1 ? 'návěs' : (row.trailers.length <= 4 ? 'návěsy' : 'návěsů')}
                                                    </span>
                                                )}
                                            </td>

                                            {monthKeys.map(monthKey => {
                                                const price = row.data.months[monthKey + 1]

                                                return (
                                                    <td key={`row-${row.id}-month-${monthKey}`} className={`w-100 text-right td-clickable ${price < 0 ? 'td-danger' : ''}`} onClick={e => {
                                                        this.handleShowDetails(e, row.id, monthKey + 1, row.name)
                                                    }}>
                                                        {price ? formatters.priceFormatter(price, '0,0') : ''}
                                                    </td>)
                                            })}

                                            <td className={`w-140 text-right td-clickable ${total < 0 ? 'td-danger' : ''}`} onClick={e => {
                                                this.handleShowDetails(e, row.id, 0, row.name)
                                            }}>
                                                <strong>
                                                    {formatters.priceFormatter(total, '0,0')}
                                                </strong>
                                            </td>
                                        </tr>)
                                    })}

                                    {total && (<tr>
                                        <td>
                                            <strong>
                                                <FormattedMessage id="fields.total" />
                                            </strong>
                                        </td>

                                        {monthKeys.map(monthKey => {
                                            const price = total.months[monthKey + 1]

                                            return (<td key={`total-month-${monthKey}`} className={`w-100 text-right ${price < 0 ? 'td-danger' : ''}`}>
                                                <strong>
                                                    {formatters.priceFormatter(price, '0,0')}
                                                </strong>
                                            </td>)
                                        })}

                                        <td className={`w-100 text-right ${total['total'] < 0 ? 'td-danger' : ''}`}>
                                            <strong>
                                                {formatters.priceFormatter(total['total'], '0,0')}
                                            </strong>
                                        </td>
                                    </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-auto">
                    <Modal.Header closeButton>
                        <Modal.Title>{`${this.state.modalDetails.name} / ${momentFrom.format('DD.MM.YYYY')} - ${momentTo.format('DD.MM.YYYY')}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="clearfix">
                        <LoadingOverlay active={this.props.detailsFetching}>
                            <div className="pull-left wp-100 mh-500">
                                <div className="pull-left wp-100 m-b-sm">
                                    <div className="pull-left">
                                        <span className="pull-left text-gray" style={{ paddingTop: '10px' }}>
                                            <FormattedMessage id="fields.show_costs" />:
                                        </span>
                                        <div className="pull-left m-l-sm">
                                            <CheckboxField
                                                id="incl_drivers"
                                                onChange={(e) => { this.changeInclDrivers(e.target.checked) }}
                                                label={this.props.intl.formatMessage({ id: 'fields.incl_drivers' })}
                                                value={this.state.inclDrivers}
                                            />
                                        </div>
                                        <div className="pull-left m-l-sm">
                                            <CheckboxField
                                                id="incl_vehicles"
                                                onChange={(e) => { this.changeInclVehicles(e.target.checked) }}
                                                label={this.props.intl.formatMessage({ id: 'fields.incl_vehicles' })}
                                                value={this.state.inclVehicles}
                                            />
                                        </div>
                                        <div className="pull-left m-l-sm">
                                            <CheckboxField
                                                id="incl_trailers"
                                                onChange={(e) => { this.changeInclTrailers(e.target.checked) }}
                                                label={this.props.intl.formatMessage({ id: 'fields.incl_trailers' })}
                                                value={this.state.inclTrailers}
                                            />
                                        </div>

                                        <div className="pull-left" style={{marginLeft: '100px'}}>
                                            <CheckboxField
                                                id="incl_feature_costs"
                                                onChange={(e) => { this.changeInclFeatureCosts(e.target.checked) }}
                                                label={this.props.intl.formatMessage({ id: 'fields.incl_feature_costs' })}
                                                value={this.state.inclFeatureCosts}
                                            />
                                        </div>
                                    </div>

                                    <div className="btns-list">
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExportDetails}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>

                                    {detailsData && (<div className="pull-left wp-100">
                                        <div className="table-container">
                                            <table className="table table-striped table-hover table-fixed-header">
                                                <thead>
                                                <tr>
                                                    <th className="w-max wm-300" />
                                                    {monthKeys.map(key => {
                                                        const month = moment().set('month', key)

                                                        return (
                                                            <th key={`month-${key}`} className="w-120 text-right">
                                                                {this.props.intl.formatMessage({
                                                                    id: `monthNames.${month.format('MM')}`,
                                                                })}
                                                            </th>
                                                        )
                                                    })}
                                                    <th className="w-140 text-right">
                                                        <FormattedMessage id="fields.total" />
                                                    </th>
                                                </tr>
                                                </thead>

                                                {detailsData && (
                                                    <tbody>
                                                    <tr>
                                                        <td className="w-max wm-300">
                                                            <strong>Zisk / ztráta</strong>
                                                        </td>
                                                        {monthKeys.map(key => {
                                                            const value = detailsData.profits.months[key + 1]

                                                            return (<td key={`profits-month-${key}`} className={`w-120 text-right ${value < 0 ? 'td-danger' : ''}`}>
                                                                    <strong>{formatters.priceFormatter(value, '0,0')}</strong>
                                                                </td>)
                                                        })}
                                                        <td className={`w-140 text-right ${detailsData.profits['total'] < 0 ? 'td-danger' : ''}`}>
                                                            <strong>{formatters.priceFormatter(detailsData.profits['total'], '0,0')}</strong>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="w-max wm-300">
                                                            <strong>Tržby</strong>
                                                        </td>
                                                        {monthKeys.map(key => {
                                                            const value = detailsData.sales.months[key + 1] + detailsData.salesTotal.months[key + 1]

                                                            return (<td key={`sales-month-${key}`} className={`w-120 text-right`}>
                                                                <strong>{formatters.priceFormatter(value, '0,0')}</strong>
                                                            </td>)
                                                        })}
                                                        <td className={`w-140 text-right`}>
                                                            <strong>{formatters.priceFormatter(detailsData.sales.total + detailsData.salesTotal.total, '0,0')}</strong>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="w-max wm-300">
                                                            - Z přeprav
                                                        </td>
                                                        {monthKeys.map(key => {
                                                            const value = detailsData.sales.months[key + 1]

                                                            return (<td key={`sales-month-${key}`} className={`w-120 text-right`}>
                                                                {formatters.priceFormatter(value, '0,0')}
                                                            </td>)
                                                        })}
                                                        <td className={`w-140 text-right`}>
                                                            <strong>{formatters.priceFormatter(detailsData.sales.total, '0,0')}</strong>
                                                        </td>
                                                    </tr>

                                                    {detailsData.salesByType.map(sale => {
                                                        var rows = [];

                                                        rows.push(<tr key={`sales-by-type-${sale.id}`}>
                                                            <td className="w-max wm-300">
                                                                - {sale.name}
                                                            </td>
                                                            {monthKeys.map(key => {
                                                                const value = sale.data.months[key + 1]

                                                                return (<td key={`sales-total-month-${key}`} className={`w-120 text-right`}>
                                                                    {formatters.priceFormatter(value, '0,0')}
                                                                </td>)
                                                            })}
                                                            <td className={`w-140 text-right`}>
                                                                {formatters.priceFormatter(sale.data.total, '0,0')}
                                                            </td>
                                                        </tr>);

                                                        sale.data.relationsTotals.forEach(relation => {
                                                            rows.push(<tr key={`sales-by-type-${sale.id}`}>
                                                                <td className="w-max wm-300 text-gray">
                                                                    - - {relation.name}
                                                                </td>
                                                                {monthKeys.map(key => {
                                                                    let value = sale.data.relations[key + 1] ? sale.data.relations[key + 1][relation.id] : 0

                                                                    return (<td key={`sales-total-month-${key}-relation-${relation.id}`} className={`w-120 text-right text-gray`}>
                                                                        {formatters.priceFormatter(value, '0,0')}
                                                                    </td>)
                                                                })}
                                                                <td className={`w-140 text-right text-gray`}>
                                                                    {formatters.priceFormatter(relation.total, '0,0')}
                                                                </td>
                                                            </tr>);
                                                        })

                                                        return rows
                                                    })}

                                                    <tr>
                                                        <td className="w-max wm-300">
                                                            <strong>Náklady</strong>
                                                        </td>
                                                        {monthKeys.map(key => {
                                                            const value = detailsData.costsTotal.months[key + 1]

                                                            return (<td key={`costs-total-month-${key}`} className={`w-120 text-right`}>
                                                                <strong>{formatters.priceFormatter(value, '0,0')}</strong>
                                                            </td>)
                                                        })}
                                                        <td className={`w-140 text-right`}>
                                                            <strong>{formatters.priceFormatter(detailsData.costsTotal.total, '0,0')}</strong>
                                                        </td>
                                                    </tr>

                                                    {detailsData.costsByType.map(cost => {
                                                        var rows = [];

                                                        rows.push(<tr key={`costs-by-type-${cost.id}`}>
                                                            <td className="w-max wm-300">
                                                                - {cost.name}
                                                            </td>
                                                            {monthKeys.map(key => {
                                                                const value = cost.data.months[key + 1]

                                                                return (<td key={`costs-total-month-${key}`} className={`w-120 text-right`}>
                                                                    {formatters.priceFormatter(value, '0,0')}
                                                                </td>)
                                                            })}
                                                            <td className={`w-140 text-right`}>
                                                                {formatters.priceFormatter(cost.data.total, '0,0')}
                                                            </td>
                                                        </tr>);

                                                        cost.data.relationsTotals.forEach(relation => {
                                                            rows.push(<tr key={`costs-by-type-${cost.id}`}>
                                                                <td className="w-max wm-300 text-gray">
                                                                    - - {relation.name}
                                                                </td>
                                                                {monthKeys.map(key => {
                                                                    let value = cost.data.relations[key + 1] ? cost.data.relations[key + 1][relation.id] : 0

                                                                    return (<td key={`costs-total-month-${key}-relation-${relation.id}`} className={`w-120 text-right text-gray`}>
                                                                        {formatters.priceFormatter(value, '0,0')}
                                                                    </td>)
                                                                })}
                                                                <td className={`w-140 text-right text-gray`}>
                                                                    {formatters.priceFormatter(relation.total, '0,0')}
                                                                </td>
                                                            </tr>);
                                                        })

                                                        return rows
                                                    })}

                                                    </tbody>
                                                )}
                                            </table>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </LoadingOverlay>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default" onClick={this.handleHideDetails}>
                            <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </PermissionsCheck>)
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        year: getYear(state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators({
                ...ReportCostCenterProfitsActionCreators, ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
            }, dispatch),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps, ...dispatchProps, ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(withRouter(ReportCostCenterProfits)))
