import React, { Component } from 'react'
import { DropdownButton, MenuItem, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as constants from '../constants'
import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class InvoicingReceivedInvoicesRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.onClick()
    }

    handleClickApproveButton = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.onApproveButtonClick()
    }

    handleClickSetAsPaidButton = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.onSetAsPaidButtonClick()
    }

    handleClickSetAsUnpaidButton = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.onSetAsUnpaidButtonClick()
    }

    onClick = () => {
        this.props.onClick && this.props.onClick(this.props.item)
    }

    onEditButtonClick = () => {
        this.props.onEditButtonClick && this.props.onEditButtonClick(this.props.item)
    }

    onApproveButtonClick = () => {
        this.props.onApproveButtonClick && this.props.onApproveButtonClick(this.props.item)
    }

    onSetAsPaidButtonClick = () => {
        this.props.onSetAsPaidButtonClick && this.props.onSetAsPaidButtonClick(this.props.item)
    }

    onSetAsUnpaidButtonClick = () => {
        this.props.onSetAsUnpaidButtonClick && this.props.onSetAsUnpaidButtonClick(this.props.item)
    }

    onCreateCreditNoteButtonClick = () => {
        this.props.onCreateCreditNoteButtonClick && this.props.onCreateCreditNoteButtonClick(this.props.item)
    }

    onDeleteButtonClick = () => {
        this.props.onDeleteButtonClick && this.props.onDeleteButtonClick(this.props.item)
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const item = this.props.item
        const visibleTransportsNumberCount = 4

        if (!item) {
            return null
        }

        const company = item.company
        const supplier = item.supplier
        const transports = item.items && [...new Set(item.items.flatMap(item => (item.transport_id ? [item.transport_id] : [])))]
        const isCreditNote = item.type_id === constants.TYPE_CREDIT_NOTE

        // const isOverdue = item.date_due && moment(item.date_due).isBefore(item.paid_at ? moment(item.paid_at) : moment(), 'days')
        // const daysOverdue = item.date_due && Math.abs(moment(item.date_due).diff(item.paid_at ? moment(item.paid_at) : moment(), 'days'))

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-static-element-interactions */

        const classNames = ['row-selectable']
        if (item.deleted_at) {
            classNames.push('is-deleted')
        }

        return (
            <tr onClick={this.handleClick} className={classNames.join(' ')}>
                <td className="w-60 text-center">
                    <div onClick={e => e.stopPropagation()}>
                        <DropdownButton id={`menu-${item.id}`} className={'far fa-bars'} noCaret title="">
                            <MenuItem eventKey={1} onSelect={this.onClick}>
                                <i className="far fa-eye" />
                                <FormattedMessage id="buttons.showDetail" />
                            </MenuItem>
                            <PermissionsCheck has={[PERMS.UPDATE]}>
                                <MenuItem eventKey={2} onSelect={this.onEditButtonClick}>
                                    <i className="far fa-pencil" />
                                    <FormattedMessage id="buttons.edit" />
                                </MenuItem>
                            </PermissionsCheck>
                            <PermissionsCheck hasAny={[PERMS.CREATE, PERMS.UPDATE]}>
                                <MenuItem eventKey={3} onSelect={this.onCreateCreditNoteButtonClick} disabled={isCreditNote}>
                                    <i className="far fa-file-minus" />
                                    <FormattedMessage id="buttons.createCreditNote" />
                                </MenuItem>
                            </PermissionsCheck>
                            {!item.deleted_at && (
                                <PermissionsCheck has={[PERMS.DELETE]}>
                                    <MenuItem eventKey={4} onSelect={this.onDeleteButtonClick}>
                                        <i className="far fa-trash" />
                                        <FormattedMessage id="buttons.delete" />
                                    </MenuItem>
                                </PermissionsCheck>
                            )}
                        </DropdownButton>
                    </div>
                </td>
                <td className="w-120">
                    <strong>{item.number}</strong>
                    {isCreditNote && (
                        <React.Fragment>
                            <br />
                            <small className="text-danger">
                                <FormattedMessage id="fields.creditNote" />
                            </small>
                        </React.Fragment>
                    )}
                </td>
                <td className="w-140">
                    {item.suppliers_number}
                    <br />
                    <small>{item.var_symbol}</small>
                </td>
                <td className="w-100 text-center">
                    {formatters.dateFormatter(item.date_issued)}
                    <br />
                    <small className="text-gray">{formatters.dateFormatter(item.date_received)}</small>
                </td>
                <td className="w-100 text-center">
                    {formatters.dateFormatter(item.date_due)}
                    <br />
                    <small className="text-gray">{formatters.dateFormatter(item.date_tax)}</small>
                </td>
                <td className="w-120 text-center">
                    {item.approved_at ? (
                        <React.Fragment>
                            <span className="text-success">{formatters.datetimeFormatter(item.approved_at)}</span>
                            <br />
                            <small className="text-gray">
                                {item.approved_by ? formatters.shortFullName(item.approved_by.first_name, item.approved_by.last_name) : ''}
                            </small>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <span className="text-danger">
                                <FormattedMessage id="fields.notApproved" />
                            </span>
                            <PermissionsCheck has={[PERMS.UPDATE]}>
                                <br />
                                <a href="#" onClick={this.handleClickApproveButton}>
                                    <small className="text-gray">
                                        <i className="far fa-check-circle" /> <FormattedMessage id="fields.approve" />
                                    </small>
                                </a>
                            </PermissionsCheck>
                        </React.Fragment>
                    )}
                </td>
                <td className="w-max wm-300">
                    <strong>{supplier && supplier.name}</strong>
                    <br />
                    <small className="text-gray">
                        {supplier
                            ? formatters.address(
                                  supplier.street,
                                  supplier.city,
                                  supplier.zipcode,
                                  supplier.country ? supplier.country.iso_code : null
                              )
                            : null}
                    </small>
                </td>
                <td className="w-260">
                    <strong>{company && company.name}</strong>
                    <br />
                    <small className="text-gray">
                        {company
                            ? formatters.address(company.street, company.city, company.zipcode, company.country ? company.country.iso_code : null)
                            : null}
                    </small>
                </td>
                <td className="w-140">
                    {transports ? transports.slice(0, visibleTransportsNumberCount).join(', ') : null}
                    {transports && transports.length > visibleTransportsNumberCount ? (
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{transports.join(', ')}</Tooltip>}>
                            <span>, (+{transports.length - visibleTransportsNumberCount})</span>
                        </OverlayTrigger>
                    ) : null}
                </td>
                <td className="w-140">
                    {item.company_cost_center && item.company_cost_center.code}
                    <br />
                    <small className="text-gray">
                        {item.cost_user ? formatters.shortFullName(item.cost_user.first_name, item.cost_user.last_name) : ''}
                    </small>
                </td>
                <td className="w-120 text-right">
                    <strong>
                        {formatters.priceFormatter(item.total && item.total.excl_vat, '0,0.00', item.currency ? item.currency.iso_code : '')}
                    </strong>
                    <br />
                    <small className="text-gray">{formatters.priceFormatter(item.total && item.total.excl_vat * item.currency_rate, '0,0.00')}</small>
                </td>
                <td className="w-120 text-right">
                    {formatters.priceFormatter(item.total && item.total.vat, '0,0.00', item.currency ? item.currency.iso_code : '')}
                    <br />
                    <small className="text-gray">{formatters.priceFormatter(item.total && item.total.vat * item.currency_rate, '0,0.00')}</small>
                </td>
                <td className="w-120 text-right">
                    {formatters.priceFormatter(item.total && item.total.incl_vat, '0,0.00', item.currency ? item.currency.iso_code : '')}
                    <br />
                    <small className="text-gray">{formatters.priceFormatter(item.total && item.total.incl_vat * item.currency_rate, '0,0.00')}</small>
                </td>
                <td className="w-120 text-right">
                    <span
                        className={
                            item.paid_amount >= (item.total && item.total.incl_vat ? item.total.incl_vat - 1 : 0) ? 'text-success' : 'text-danger'
                        }
                    >
                        {formatters.priceFormatter(item.paid_amount, '0,0.00', item.currency ? item.currency.iso_code : '')}
                    </span>
                    <br />
                    <small>{formatters.dateFormatter(item.paid_at)}</small>
                </td>
            </tr>
        )

        /* eslint-enable jsx-a11y/no-static-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

export default injectIntl(InvoicingReceivedInvoicesRow)
