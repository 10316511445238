import React, { Component } from 'react'
import {Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'

import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'

import * as reportEmptyKilometersActionCreators from '../actionCreators'
import { getMonth, getData } from '../selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { getPrerequisitesArray } from '../../../common/helpers'

import { TableModelColumnFilteringType } from '../../../common/table/model'
import { TableFilterSelectField, TableFilterInputField } from '../../../common/table/components'
import { createTableModelSelector } from '../../../common/table/selectors'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { getActiveFilters } from '../../../common/table/helpers'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'report_empty_kilometers_list'

const componentIdentifier = 'report_empty_kilometers_list'
const prerequisites = ['dispatchers']

class ReportEmptyKilometers extends Component {
    getMonth = () => {
        if (this.props.month) {
            return this.props.month
        }

        return this.props.match.params.month ? this.props.match.params.month : moment().format('YYYY-MM')
    }

    refresh = () => {
        this.props.actions.fetchReportEmptyKilometers(this.getMonth())
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleChangeYearMonth = format => {
        const month = `0${format.month.toString()}`.slice(-2)
        const year = format.year.toString()
        const path = `/report-empty-kilometers/${year}-${month}`
        this.props.history.push(path)
    }

    componentDidMount() {
        this.props.actions.fetchReportEmptyKilometers(this.getMonth())
        this.props.actions.fetchPrerequisites()
    }

    componentWillUnmount() {
        this.props.actions.clearReportEmptyKilometers()
    }

    componentWillReceiveProps(nextProps) {
        const parsedMonth = nextProps.match.params.month ? nextProps.match.params.month : moment().format('YYYY-MM')

        if (parsedMonth !== this.getMonth()) {
            this.props.actions.clearReportEmptyKilometers()
            this.props.actions.fetchReportEmptyKilometers(parsedMonth)
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportEmptyKilometers.heading' }))

        const momentFrom = moment(`${this.getMonth()}-01`, 'YYYY-MM-DD')
        const momentTo = moment(momentFrom).endOf('month')

        const filters = this.props.tableModel.get('filters')

        const data = this.props.data
        const total = {
            total_czk: 0,
            km_start: 0,
            count: 0,
        }

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportEmptyKilometers.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className={'m-r-sm'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            onChange={(momentFrom, momentTo, format) => this.handleChangeYearMonth(format)}
                                            allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_MONTH]}
                                            forbidPickType
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                    </div>
                                </div>
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                        <tr>
                                            <th className="w-100">
                                                <FormattedMessage id="fields.transport" />
                                            </th>
                                            <th className="w-100">
                                                <FormattedMessage id="fields.vehicle" />
                                            </th>
                                            <th className="w-200">
                                                <FormattedMessage id="fields.dispatcher" />
                                            </th>
                                            <th className="w-400">
                                                <FormattedMessage id="fields.customer" />
                                            </th>
                                            <th className="w-300">
                                                <FormattedMessage id="fields.approachFrom" />
                                            </th>
                                            <th className="w-300">
                                                <FormattedMessage id="fields.approachTo" />
                                            </th>
                                            <th className="w-max text-right">
                                                <FormattedMessage id="fields.kmApproach" />
                                            </th>
                                        </tr>
                                        <tr className="filters">
                                            <th className="w-100">
                                                <TableFilterInputField
                                                    identifier="transport"
                                                    type={TableModelColumnFilteringType.string}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-100">
                                                <TableFilterInputField
                                                    identifier="vehicle_name"
                                                    type={TableModelColumnFilteringType.string}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-200">
                                                <TableFilterSelectField
                                                    identifier="dispatcher_id"
                                                    type={TableModelColumnFilteringType.equal}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                />
                                            </th>
                                            <th className="w-400">
                                                <TableFilterInputField
                                                    identifier="customer"
                                                    type={TableModelColumnFilteringType.string}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-300">
                                                <TableFilterInputField
                                                    identifier="start"
                                                    type={TableModelColumnFilteringType.string}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-300">
                                                <TableFilterInputField
                                                    identifier="address"
                                                    type={TableModelColumnFilteringType.string}
                                                    filters={filters}
                                                    onChange={this.props.table.changeFilter}
                                                />
                                            </th>
                                            <th className="w-max text-right" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data &&
                                            data.transports &&
                                            data.transports.map(transport => {
                                                if (
                                                    filters.getIn(['transport', 'value']) &&
                                                    String(transport.id)
                                                        .toLowerCase()
                                                        .indexOf(filters.getIn(['transport', 'value']).toLowerCase()) < 0
                                                ) {
                                                    return null
                                                }

                                                if (
                                                    filters.getIn(['vehicle_name', 'value']) &&
                                                    transport.vehicle.toLowerCase().indexOf(filters.getIn(['vehicle_name', 'value']).toLowerCase()) < 0
                                                ) {
                                                    return null
                                                }

                                                if (
                                                    filters.getIn(['dispatcher_id', 'value']) &&
                                                    (!transport.dispatcher_id ||
                                                        parseInt(transport.dispatcher_id) !== parseInt(filters.getIn(['dispatcher_id', 'value'])))
                                                ) {
                                                    return false
                                                }

                                                if (
                                                    filters.getIn(['customer', 'value']) &&
                                                    transport.customer.toLowerCase().indexOf(filters.getIn(['customer', 'value']).toLowerCase()) < 0
                                                ) {
                                                    return null
                                                }

                                                if (
                                                    filters.getIn(['start', 'value']) &&
                                                    transport.start.toLowerCase().indexOf(filters.getIn(['start', 'value']).toLowerCase()) < 0
                                                ) {
                                                    return null
                                                }

                                                if (
                                                    filters.getIn(['address', 'value']) &&
                                                    transport.address.toLowerCase().indexOf(filters.getIn(['address', 'value']).toLowerCase()) < 0
                                                ) {
                                                    return null
                                                }

                                                total.total_czk += transport.total_czk
                                                total.km_start += transport.km_start
                                                total.count += 1

                                                return (
                                                    <tr key={`row-${transport.id}`}>
                                                        <td className="w-100">
                                                                <Link to={`/transports/${transport.id}`}>
                                                                    {transport.id}
                                                                </Link>
                                                        </td>
                                                        <td className="w-100">{transport.vehicle}</td>
                                                        <td className="w-200">{transport.dispatcher}</td>
                                                        <td className="w-400">{transport.customer}</td>
                                                        <td className="w-300">{transport.start}</td>
                                                        <td className="w-300">{transport.address}</td>
                                                        <td className="w-max text-right">{formatters.distanceFormatter(transport.km_start)}</td>
                                                    </tr>
                                                )
                                            })}

                                        <tr className="b-top">
                                            <td className="w-100">
                                                <strong>
                                                    <FormattedMessage id="fields.total" />:
                                                </strong>
                                            </td>
                                            <td className="w-100" />
                                            <td className="w-200" />
                                            <td className="w-400" />
                                            <td className="w-300" />
                                            <td className="w-300" />
                                            <td className="w-max text-right">
                                                <strong>{formatters.distanceFormatter(total.km_start)}</strong>
                                                <br />
                                                <strong>&oslash; {formatters.distanceFormatter(total.count ? total.km_start / total.count : 0)}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        month: getMonth(state),
        data: getData(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportEmptyKilometersActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(withRouter(ReportEmptyKilometers)))
