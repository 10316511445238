import { createSelector } from 'reselect'

export const getServiceJobs = state => state.serviceJobs.getIn(['all', 'objects'])

export const createGetServiceJobByIDSelector = id => createSelector([getServiceJobs], serviceJobs => serviceJobs.get(String(id)))

const getCurrentServiceJobStatus = state => state.serviceJobs.get('current')

export const createGetCurrentServiceJobStatusSelector = () => createSelector([getCurrentServiceJobStatus], status => status)

export const getServiceJobDeleting = state => state.serviceJobs.getIn(['deletion', 'inProgress'])

export const getServiceJobDeleteError = state => state.serviceJobs.getIn(['deletion', 'error'])
