import { createSelector } from 'reselect'

export const getCompanyCostCenters = state => state.companyCostCenters.getIn(['all', 'objects'])

export const createGetCompanyCostCenterByIDSelector = id =>
    createSelector([getCompanyCostCenters], companyCostCenters => companyCostCenters.get(String(id)))

const getCurrentCompanyCostCenterStatus = state => state.companyCostCenters.get('current')

export const createGetCurrentCompanyCostCenterStatusSelector = () => createSelector([getCurrentCompanyCostCenterStatus], status => status)

export const getCompanyCostCenterDeleting = state => state.companyCostCenters.getIn(['deletion', 'inProgress'])

export const getCompanyCostCenterDeleteError = state => state.companyCostCenters.getIn(['deletion', 'error'])
