import { createSelector } from 'reselect'

export const getCustomerAddresses = state => state.customerAddresses.getIn(['all', 'objects'])

export const createGetCustomerAddressByIDSelector = id =>
    createSelector([getCustomerAddresses], customerAddresses => customerAddresses.get(String(id)))

const getCurrentCustomerAddressStatus = state => state.customerAddresses.get('current')

export const createGetCurrentCustomerAddressStatusSelector = () => createSelector([getCurrentCustomerAddressStatus], status => status)

export const getCustomerAddressDeleting = state => state.customerAddresses.getIn(['deletion', 'inProgress'])

export const getCustomerAddressDeleteError = state => state.customerAddresses.getIn(['deletion', 'error'])

const getDuplicity = state => state.customerAddresses.getIn(['all', 'duplicity'])

export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)

export const getFoundAddresses = state => state.customerAddresses.getIn(['finding', 'objects'])
export const getFoundAddressesFetching = state => state.customerAddresses.getIn(['finding', 'fetching'])
export const getFoundAddressesError = state => state.customerAddresses.getIn(['finding', 'error'])


// Import

export const getImportInProgress = state => state.customerAddresses.getIn(['import', 'inProgress'])
export const getImportError = state => state.customerAddresses.getIn(['import', 'error'])
export const getImportFileId = state => state.customerAddresses.getIn(['import', 'file_id'])
export const getImportHeaders = state => state.customerAddresses.getIn(['import', 'headers'])
export const getImportDefaultHeaders = state => state.customerAddresses.getIn(['import', 'headersDefault'])
export const getImportItems = state => state.customerAddresses.getIn(['import', 'items'])
