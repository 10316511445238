import { createSelector } from 'reselect'

export const getDriverDocuments = state => state.driverDocuments.getIn(['all', 'objects'])

export const createGetDriverDocumentByIDSelector = id => createSelector([getDriverDocuments], driverDocuments => driverDocuments.get(String(id)))

const getCurrentDriverDocumentStatus = state => state.driverDocuments.get('current')

export const createGetCurrentDriverDocumentStatusSelector = () => createSelector([getCurrentDriverDocumentStatus], status => status)

export const getDriverDocumentsFetching = state => state.driverDocuments.getIn(['all', 'fetching'])

export const getDriverDocumentDeleting = state => state.driverDocuments.getIn(['deletion', 'inProgress'])

export const getDriverDocumentDeleteError = state => state.driverDocuments.getIn(['deletion', 'error'])

const getDuplicity = state => state.driverDocuments.getIn(['all', 'duplicity'])

export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)

export const getDriverDocumentFiles = state => state.driverDocuments.getIn(['files', 'objects'])
export const getDriverDocumentFilesFetching = state => state.driverDocuments.getIn(['files', 'fetching'])
export const getDriverDocumentFilesSaving = state => state.driverDocuments.getIn(['files', 'saving'])
export const getDriverDocumentFilesDeleting = state => state.driverDocuments.getIn(['files', 'deleting'])
export const getDriverDocumentFilesError = state => state.driverDocuments.getIn(['files', 'error'])