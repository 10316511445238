import { List, Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
    }),
    current: Map({
        saving: false,
        error: null,
    }),
    deletion: Map({
        inProgress: false,
        error: null,
    }),
    import: Map({
        inProgress: false,
        error: null,
        file_id: null,
        headers: List(),
        headersDefault: Map(),
        items: List(),
        defaultDate: null,
        defaultSalesTypeId: null
    }),
    invoicingStateSetting: Map({
        inProgress: false,
        error: null,
    }),
    dataForInvoicing: Map({
        data: {},
        fetching: false,
        error: null,
    }),
    creatingInvoice: Map({
        createdInvoiceId: null,
        inProgress: false,
        error: null,
    }),
})

function Sales(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // One
        case actionTypes.FETCH_ONE_STARTED:
            return state.setIn(['current', 'fetching'], true)
        case actionTypes.FETCH_ONE_FULFILLED:
        case actionTypes.FETCH_ONE_REJECTED:
        case actionTypes.FETCH_ONE_CANCELLED:
            return state.setIn(['current', 'fetching'], false)

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], true).setIn(['current', 'error'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false).setIn(['current', 'error'], action.payload)
            })

        // Delete
        case actionTypes.DELETE_STARTED:
            return state.setIn(['deletion', 'inProgress'], true)
        case actionTypes.DELETE_FULFILLED:
            return state.deleteIn(['all', 'objects', action.payload]).withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], null)
            })
        case actionTypes.DELETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], action.payload)
            })

        // Clear
        case actionTypes.CLEAR:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], OrderedMap({}))
            })

        // Import headers

        case actionTypes.FETCH_IMPORT_HEADERS_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_HEADERS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
                map.setIn(['import', 'file_id'], action.payload.file_id)
                map.setIn(['import', 'headers'], action.payload.headers)
                map.setIn(['import', 'headersDefault'], action.payload.headers_default)
            })
        case actionTypes.FETCH_IMPORT_HEADERS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_HEADERS_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import values

        case actionTypes.FETCH_IMPORT_ITEMS_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_ITEMS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
                map.setIn(['import', 'items'], action.payload.items)
                map.setIn(['import', 'defaultDate'], action.payload.defaultDate)
                map.setIn(['import', 'defaultSalesTypeId'], action.payload.defaultSalesTypeId)
            })
        case actionTypes.FETCH_IMPORT_ITEMS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_ITEMS_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import sales

        case actionTypes.IMPORT_SALES_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.IMPORT_SALES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
            })
        case actionTypes.IMPORT_SALES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.IMPORT_SALES_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)


        // Set invoicing state
        case actionTypes.SET_INVOICING_STATE_STARTED:
            return state.setIn(['invoicingStateSetting', 'inProgress'], true)
        case actionTypes.SET_INVOICING_STATE_FULFILLED:
            return state.withMutations(map => {
                map
                .setIn(['all', 'objects'], action.payload)
                .setIn(['invoicingStateSetting', 'inProgress'], false)
                .setIn(['invoicingStateSetting', 'error'], null)
            })
        case actionTypes.SET_INVOICING_STATE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['invoicingStateSetting', 'inProgress'], false).setIn(['invoicingStateSetting', 'error'], action.payload)
            })
        case actionTypes.SET_INVOICING_STATE_CANCELLED:
            return state.setIn(['invoicingStateSetting', 'inProgress'], false)

        // Fetch data for invoicing
        case actionTypes.FETCH_DATA_FOR_INVOICING_STARTED:
            return state.setIn(['dataForInvoicing', 'fetching'], true)
        case actionTypes.FETCH_DATA_FOR_INVOICING_FULFILLED:
            return state.withMutations(map => {
                map
                .setIn(['dataForInvoicing', 'data'], action.payload.data)
                .setIn(['dataForInvoicing', 'fetching'], false)
                .setIn(['dataForInvoicing', 'error'], null)
            })
        case actionTypes.FETCH_DATA_FOR_INVOICING_REJECTED:
            return state.withMutations(map => {
                map.setIn(['dataForInvoicing', 'fetching'], false).setIn(['dataForInvoicing', 'error'], action.payload)
            })
        case actionTypes.FETCH_DATA_FOR_INVOICING_CANCELLED:
            return state.setIn(['dataForInvoicing', 'fetching'], false)

        // Create invoice
        case actionTypes.CREATE_INVOICE_STARTED:
            return state.setIn(['creatingInvoice', 'inProgress'], true)
        case actionTypes.CREATE_INVOICE_FULFILLED:
            return state.withMutations(map => {
                map
                .setIn(['all', 'objects'], action.payload.data)
                .setIn(['creatingInvoice', 'createdInvoiceId'], action.payload.createdInvoiceId)
                .setIn(['creatingInvoice', 'inProgress'], false)
                .setIn(['creatingInvoice', 'error'], null)
            })
        case actionTypes.CREATE_INVOICE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['creatingInvoice', 'inProgress'], false).setIn(['creatingInvoice', 'error'], action.payload)
            })
        case actionTypes.CREATE_INVOICE_CANCELLED:
            return state.setIn(['creatingInvoice', 'inProgress'], false)


        default:
            return state
    }
}

export default Sales
