import React from 'react'
import { Route, Switch } from 'react-router'
import CustomerAddressesList from './CustomerAddressesList'
import CustomerAddressesEdit from './CustomerAddressesEdit'
import CustomerAddressesImport from './CustomerAddressesImport'

const CustomerAddresses = props => (
    <Switch>
        <Route exact path={props.match.url} component={CustomerAddressesList} />
        <Route exact path={`${props.match.url}/new`} component={CustomerAddressesEdit} />
        <Route exact path={`${props.match.url}/import`} component={CustomerAddressesImport} />
        <Route exact path={`${props.match.url}/:customerAddressID`} component={CustomerAddressesEdit} />
    </Switch>
)

export default CustomerAddresses
