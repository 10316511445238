import * as actionTypes from './actionTypes'

// Fetch

export const fetchDriverSurchargeTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchDriverSurchargeTypesFulfilled = driverSurchargeTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: driverSurchargeTypes,
})

export const fetchDriverSurchargeTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchDriverSurchargeTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchDriverSurchargeType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchDriverSurchargeTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchDriverSurchargeTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchDriverSurchargeTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveDriverSurchargeType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveDriverSurchargeTypeFulfilled = driverSurchargeType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: driverSurchargeType,
})

export const saveDriverSurchargeTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteDriverSurchargeType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteDriverSurchargeTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteDriverSurchargeTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})
