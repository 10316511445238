import React, { Component } from 'react'
import YearPicker from './pickers/YearPicker'
import HalfPicker from './pickers/HalfPicker'
import QuarterPicker from './pickers/QuarterPicker'
import MonthPicker from './pickers/MonthPicker'
import WeekPicker from './pickers/WeekPicker'
import DayPicker from './pickers/DayPicker'
import DaysPicker from './pickers/DaysPicker'
import ModalPicker from './ModalPicker'

import * as constants from '../constants'

import moment from 'moment'
import { injectIntl } from 'react-intl'

class PeriodPicker extends Component {
    DEFAULT_MIN_YEAR = 2018
    DEFAULT_ADD_MAX_YEAR = 1

    state = {
        isModalShow: false,
        pickerType: this.props.defaultPicker,
    }

    pickers = [
        constants.PERIOD_PICKER_TYPE_YEAR,
        constants.PERIOD_PICKER_TYPE_HALF,
        constants.PERIOD_PICKER_TYPE_QUARTER,
        constants.PERIOD_PICKER_TYPE_MONTH,
        constants.PERIOD_PICKER_TYPE_WEEK,
        constants.PERIOD_PICKER_TYPE_DAY,
        constants.PERIOD_PICKER_TYPE_PERIOD,
    ]

    showModal = isModalShow => {
        this.setState({ isModalShow })
    }

    onChangeModal = (momentFrom, momentTo, pickerType) => {
        this.setState(
            {
                pickerType,
            },
            () => this.onChange(momentFrom, momentTo)
        )
        this.showModal(false)
    }

    onChange = (momentFrom, momentTo) => {
        let formatedOutput = {}

        switch (this.state.pickerType) {
            case 'half':
                formatedOutput.year = momentFrom.year()
                formatedOutput.half = Math.ceil(momentFrom.quarter() / 2)
                break
            case 'quarter':
                formatedOutput.year = momentFrom.year()
                formatedOutput.quarter = momentFrom.quarter()
                break
            case 'month':
                formatedOutput.year = momentFrom.year()
                formatedOutput.month = momentFrom.month() + 1
                break
            case 'week':
                formatedOutput.year = momentFrom.year()
                formatedOutput.week = momentFrom.week()
                break
            case 'year':
                formatedOutput.year = momentFrom.year()
                break
            default:
                formatedOutput = null
                break
        }

        this.props.onChange(momentFrom, momentTo, formatedOutput)
    }

    render() {
        const isModalAllowed = this.props.forbidPickType === undefined || !this.props.forbidPickType

        const momentFrom = moment.isMoment(this.props.momentFrom) ? this.props.momentFrom : moment()
        const momentTo = moment.isMoment(this.props.momentTo) ? this.props.momentTo : moment()

        const minYear = this.props.minYear ? this.props.minYear : this.DEFAULT_MIN_YEAR
        const maxYear = this.props.maxYear
            ? this.props.maxYear
            : moment()
                  .add(this.DEFAULT_ADD_MAX_YEAR, 'year')
                  .year()

        let pickers = this.pickers
        if (this.props.allowedPickers) {
            pickers = pickers.filter(picker => this.props.allowedPickers.includes(picker))
        }
        if (this.props.forbiddenPickers) {
            pickers = pickers.filter(picker => !this.props.forbiddenPickers.includes(picker))
        }

        let pickerType = this.state.pickerType
        if (!pickers.includes(pickerType)) {
            pickerType = pickers[0]
            this.setState({ pickerType })
        }

        return (
            <div className={`datetime-selection pull-left ${this.props.className}`}>
                <div className="btn-group">
                    {(() => {
                        switch (pickerType) {
                            case 'year':
                                return (
                                    <YearPicker
                                        momentFrom={momentFrom}
                                        momentTo={momentTo}
                                        onClick={this.onChange}
                                        minYear={minYear}
                                        maxYear={maxYear}
                                    />
                                )
                            case 'half':
                                return (
                                    <HalfPicker
                                        momentFrom={momentFrom}
                                        momentTo={momentTo}
                                        onClick={this.onChange}
                                        minYear={minYear}
                                        maxYear={maxYear}
                                    />
                                )
                            case 'quarter':
                                return (
                                    <QuarterPicker
                                        momentFrom={momentFrom}
                                        momentTo={momentTo}
                                        onClick={this.onChange}
                                        minYear={minYear}
                                        maxYear={maxYear}
                                    />
                                )
                            case 'month':
                                return (
                                    <MonthPicker
                                        momentFrom={momentFrom}
                                        momentTo={momentTo}
                                        onClick={this.onChange}
                                        minYear={minYear}
                                        maxYear={maxYear}
                                    />
                                )
                            case 'week':
                                return (
                                    <WeekPicker
                                        momentFrom={momentFrom}
                                        momentTo={momentTo}
                                        onClick={this.onChange}
                                        minYear={minYear}
                                        maxYear={maxYear}
                                    />
                                )
                            case 'day':
                                return (
                                    <DayPicker
                                        momentFrom={momentFrom}
                                        momentTo={momentTo}
                                        onChange={this.onChange}
                                        minYear={minYear}
                                        maxYear={maxYear}
                                    />
                                )
                            case 'period':
                                return (
                                    <DaysPicker
                                        momentFrom={momentFrom}
                                        momentTo={momentTo}
                                        onChange={this.onChange}
                                        minYear={minYear}
                                        maxYear={maxYear}
                                    />
                                )
                            default:
                                return null
                        }
                    })()}

                    {isModalAllowed && (
                        <button type="button" className="btn btn-default btn-icon" onClick={() => this.showModal(true)}>
                            <i className="far fa-calendar-alt" />
                        </button>
                    )}
                </div>
                {isModalAllowed && (
                    <ModalPicker
                        show={this.state.isModalShow}
                        pickerType={pickerType}
                        pickers={pickers}
                        momentFrom={momentFrom}
                        momentTo={momentTo}
                        minYear={minYear}
                        maxYear={maxYear}
                        onCancel={() => this.showModal(false)}
                        onAccept={this.onChangeModal}
                    />
                )}
            </div>
        )
    }
}

export default injectIntl(PeriodPicker)
