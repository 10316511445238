import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCostCenterPerformance = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportCostCenterPerformanceFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterPerformanceRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCostCenterPerformanceCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportCostCenterPerformance = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportCostCenterPerformance = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})

// Fetch details

export const fetchReportCostCenterPerformanceDetails = filters => ({
    type: actionTypes.FETCH_DETAILS,
    payload: filters,
})

export const fetchReportCostCenterPerformanceDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterPerformanceDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportCostCenterPerformanceDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Clear

export const clearReportCostCenterPerformanceDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})

// Export

export const exportReportCostCenterPerformanceDetails = filters => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: filters,
})
