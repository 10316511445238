import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import { Col, Row, Tab, Nav, NavItem } from 'react-bootstrap'

import { LoadingOverlay } from '../../../common/loading_overlay'
import { Form, InputField } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import { isReadOnlyPermission, handleCommonEditActions } from '../../../common/helpers'

import * as actionCreators from '../actionCreators'
import { getData, getFetching, createGetCurrentSettingStatusSelector } from '../selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS_TRANSPORTS, PERMS_EMAIL, PERMS_SECURITY } from '../permissions'

class GeneralSettings extends Component {
    defaultValues = {
        transports: {},
        email: {},
        invoices: {},
        security: {},
    }

    validationRules = {}

    state = {
        values: this.defaultValues,
    }

    setValues = (settingGroupKey, values, callback) => {
        this.setState(
            {
                values: {
                    ...this.state.values,
                    [settingGroupKey]: { ...values },
                },
            },
            callback
        )
    }

    handleChangeValues = (settingGroupKey, values) => {
        this.setValues(settingGroupKey, values)
    }

    handleSubmit = (settingGroupKey, values) => {
        this.setValues(settingGroupKey, values, () => {
            this.handleSave(settingGroupKey)
        })
    }

    handleSave = settingGroupKey => {
        this.props.actions.saveGeneralSettings(this.state.values[settingGroupKey])
    }

    componentDidMount() {
        this.props.actions.fetchGeneralSettings()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps)

        if (this.props.fetching && !nextProps.fetching) {
            const values = {}
            Object.entries(nextProps.data).forEach(([groupKey, groupValues]) => {
                values[groupKey] = {}
                Object.entries(groupValues).forEach(([valueKey, value]) => {
                    values[groupKey][valueKey] = value.value
                })
            })
            this.setState({ values })
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.generalSettings.heading' }))

        const data = this.state.values
        const PERMS = [...Object.values(PERMS_TRANSPORTS), ...Object.values(PERMS_EMAIL), ...Object.values(PERMS_SECURITY)]

        return (
            <PermissionsCheck hasAny={PERMS} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.generalSettings.heading" />
                                </h4>

                                <div className="table-container">
                                    <LoadingOverlay active={this.props.fetching}>
                                        <Tab.Container id="general-settings" defaultActiveKey={Object.keys(data)[0]} style={{ margin: '0' }}>
                                            <Row className="clearfix">
                                                <Col sm={4} className="period-picker-menu">
                                                    <Nav bsStyle="tabs" stacked>
                                                        {data &&
                                                            Object.keys(data).map(settingGroup => (
                                                                <NavItem key={settingGroup} eventKey={settingGroup} className="period-picker-item">
                                                                    <FormattedMessage id={`modules.generalSettings.${settingGroup}`} />
                                                                </NavItem>
                                                            ))}
                                                    </Nav>
                                                </Col>
                                                <Col sm={8} className="period-picker-body">
                                                    <Tab.Content animation>
                                                        {data &&
                                                            Object.entries(data).map(([settingGroupKey, settingGroupValues]) => {
                                                                const isReadOnly = isReadOnlyPermission([
                                                                    `general_settings_${settingGroupKey}.read`,
                                                                    `general_settings_${settingGroupKey}.update`,
                                                                ])
                                                                return (
                                                                    <Tab.Pane key={settingGroupKey} eventKey={settingGroupKey}>
                                                                        <PermissionsCheck
                                                                            hasAny={[
                                                                                `general_settings_${settingGroupKey}.read`,
                                                                                `general_settings_${settingGroupKey}.update`,
                                                                            ]}
                                                                        >
                                                                            <Form
                                                                                values={data[settingGroupKey]}
                                                                                validationRules={this.validationRules}
                                                                                onChange={values => this.handleChangeValues(settingGroupKey, values)}
                                                                                onSubmit={values => this.handleSubmit(settingGroupKey, values)}
                                                                                isEdit={!isReadOnly}
                                                                            >
                                                                                {Object.keys(settingGroupValues).map(key => {
                                                                                    const inputProps = this.props.data[settingGroupKey][key].input
                                                                                        ? this.props.data[settingGroupKey][key].input
                                                                                        : {}
                                                                                    return (
                                                                                        <div key={key} className="row">
                                                                                            <div className="col-sm-12">
                                                                                                <InputField
                                                                                                    {...inputProps}
                                                                                                    id={key}
                                                                                                    label={this.props.intl.formatMessage({
                                                                                                        id: `modules.generalSettings.${key}`,
                                                                                                    })}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                                {!isReadOnly && (
                                                                                    <div className="btns-form clearfix">
                                                                                        <button type="submit" className="btn btn-addon btn-success">
                                                                                            <i className="far fa-check" /> <FormattedMessage id="buttons.save" />
                                                                                        </button>
                                                                                    </div>
                                                                                )}
                                                                            </Form>
                                                                        </PermissionsCheck>
                                                                    </Tab.Pane>
                                                                )
                                                            })}
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </LoadingOverlay>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: getData(state),
        fetching: getFetching(state),
        status: createGetCurrentSettingStatusSelector()(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...actionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(GeneralSettings)
)
