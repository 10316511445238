import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import { getPermissions } from '../selectors'
import { NoPermissionsPage, NoPermissionsTab } from './NoPermissions'

class PermissionsCheck extends Component {
    checkPermissions = () => {
        if (this.props.permissions === undefined || this.props.permissions === null) {
            return false
        }

        const requiredPermissions = new List(this.props.has)

        if (requiredPermissions.count() > 0) {
            return requiredPermissions.isSubset(this.props.permissions)
        }

        const anyRequiredPermissions = new List(this.props.hasAny)

        if (anyRequiredPermissions.count() > 0) {
            return this.props.permissions.some(val => anyRequiredPermissions.includes(val))
        }

        return true
    }

    render() {
        if (!this.checkPermissions() || !this.props.children) {
            if (this.props.noPermissionsReturn) {
                return this.props.noPermissionsReturn
            } else if (this.props.noPermissionsPage) {
                return <NoPermissionsPage />
            } else if (this.props.noPermissionsTab) {
                return <NoPermissionsTab />
            }
            return null
        }

        return React.Children.map(this.props.children, child =>
            React.cloneElement(child, {
                ...{ permissions: Array.from(this.props.permissions), requiredpermissions: this.props.has || this.props.hasAny },
            })
        )
    }
}

function mapStateToProps(state) {
    return {
        permissions: getPermissions(state),
    }
}

export default connect(mapStateToProps)(PermissionsCheck)
