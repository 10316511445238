import * as actionTypes from './actionTypes'

// Fetch

export const fetchDriverTimesheets = () => ({
    type: actionTypes.FETCH,
})

export const fetchDriverTimesheetsFulfilled = driverTimesheets => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: driverTimesheets,
})

export const fetchDriverTimesheetsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchDriverTimesheetsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchDriverTimesheet = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchDriverTimesheetFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchDriverTimesheetRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchDriverTimesheetCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveDriverTimesheet = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveDriverTimesheetFulfilled = driverTimesheet => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: driverTimesheet,
})

export const saveDriverTimesheetRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveDriverTimesheetDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteDriverTimesheet = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteDriverTimesheetFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteDriverTimesheetRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Clear

export const clearDriverTimesheets = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportDriverTimesheets = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
