import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError } from '../../common/api'
import { borderCrossingSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import * as countriesActionCreators from '../countries/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { Country } from '../countries/model'
import { BorderCrossings } from './model'
import * as actionCreators from './actionCreators'
import * as actionTypes from './actionTypes'

// Fetch

const fetchBorderCrossingsEpic = (action$, store) => {
    const tableIdentifier = 'border_crossings_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `border-crossings/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(borderCrossingSchema)
                        )

                        const borderCrossings = normalizedEntitiesToRecordMap(
                            normalizedData.entities.border_crossings,
                            BorderCrossings,
                            normalizedData.result
                        )
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)

                        const observables = [
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries)),
                            Observable.of(actionCreators.fetchBorderCrossingsFulfilled(borderCrossings)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchBorderCrossingsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchBorderCrossingEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().borderCrossings.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `border-crossings/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, borderCrossingSchema)

                        const borderCrossings = normalizedEntitiesToRecordMap(normalizedData.entities.border_crossings, BorderCrossings)
                        const countries = normalizedEntitiesToRecordMap(normalizedData.entities.countries, Country)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchBorderCrossingsFulfilled(borderCrossings)),
                            Observable.of(actionCreators.fetchBorderCrossingFulfilled()),
                            Observable.of(countriesActionCreators.fetchCountriesFulfilled(countries))
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchBorderCrossingsRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveBorderCrossingEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().borderCrossings.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = { ...action.payload.values }

            let path = `border-crossings`
            let method = 'POST'

            if (values.id) {
                path = `border-crossings/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, borderCrossingSchema)

                        const borderCrossings = normalizedEntitiesToRecordMap(normalizedData.entities.border_crossings, BorderCrossings)
                        const borderCrossing = borderCrossings.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveBorderCrossingFulfilled(borderCrossing)))
                    })
                    .catch(error => Observable.of(actionCreators.saveBorderCrossingRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteBorderCrossingEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().borderCrossings.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `border-crossings/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteBorderCrossingFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteBorderCrossingRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Find countries

const findCountriesEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FIND_COUNTRIES)
        .filter(() => !store.getState().borderCrossings.getIn(['findCountries', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FIND_COUNTRIES_STARTED,
                }),
                request({
                    path: 'border-crossings/find-countries',
                    method: 'POST',
                    body: { ...action.payload },
                })
                    .switchMap(ajaxResponse =>
                        Observable.concat(Observable.of(actionCreators.findCountriesFulfilled(ajaxResponse.response.countries)))
                    )
                    .catch(error => Observable.of(actionCreators.findCountriesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FIND_COUNTRIES_CANCELLED))
            )
        )

export default [fetchBorderCrossingsEpic, fetchBorderCrossingEpic, saveBorderCrossingEpic, deleteBorderCrossingEpic, findCountriesEpic]
