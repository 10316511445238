// Fetch list

export const FETCH = 'carriers.FETCH'
export const FETCH_STARTED = 'carriers.FETCH_STARTED'
export const FETCH_FULFILLED = 'carriers.FETCH_FULFILLED'
export const FETCH_REJECTED = 'carriers.FETCH_REJECTED'
export const FETCH_CANCELLED = 'carriers.FETCH_CANCELLED'

export const SORTING_CHANGED = 'carriers.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'carriers.FETCH_ONE'
export const FETCH_ONE_STARTED = 'carriers.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'carriers.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'carriers.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'carriers.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'carriers.SAVE'
export const SAVE_STARTED = 'carriers.SAVE_STARTED'
export const SAVE_FULFILLED = 'carriers.SAVE_FULFILLED'
export const SAVE_REJECTED = 'carriers.SAVE_REJECTED'
export const SAVE_CANCELLED = 'carriers.SAVE_CANCELLED'
export const SAVE_DUPLICITY_FOUND = 'carriers.SAVE_DUPLICITY_FOUND'

// Delete

export const DELETE = 'carriers.DELETE'
export const DELETE_STARTED = 'carriers.DELETE_STARTED'
export const DELETE_FULFILLED = 'carriers.DELETE_FULFILLED'
export const DELETE_REJECTED = 'carriers.DELETE_REJECTED'
export const DELETE_CANCELLED = 'carriers.DELETE_CANCELLED'

// Restore

export const RESTORE = 'carriers.RESTORE'
export const RESTORE_STARTED = 'carriers.RESTORE_STARTED'
export const RESTORE_FULFILLED = 'carriers.RESTORE_FULFILLED'
export const RESTORE_REJECTED = 'carriers.RESTORE_REJECTED'
export const RESTORE_CANCELLED = 'carriers.RESTORE_CANCELLED'


// Load info by VAT number

export const LOAD_INFO = 'carriers.LOAD_INFO'
export const LOAD_INFO_STARTED = 'carriers.LOAD_INFO_STARTED'
export const LOAD_INFO_FULFILLED = 'carriers.LOAD_INFO_FULFILLED'
export const LOAD_INFO_REJECTED = 'carriers.LOAD_INFO_REJECTED'
export const LOAD_INFO_CANCELLED = 'carriers.LOAD_INFO_CANCELLED'
export const CLEAR_INFO = 'carriers.CLEAR_INFO'

// Export

export const EXPORT = 'carriers.EXPORT'
export const EXPORT_FULFILLED = 'carriers.EXPORT_FULFILLED'

// Fetch carrier contacts

export const FETCH_CONTACTS = 'carriers.FETCH_CONTACTS'
export const FETCH_CONTACTS_STARTED = 'carriers.FETCH_CONTACTS_STARTED'
export const FETCH_CONTACTS_FULFILLED = 'carriers.FETCH_CONTACTS_FULFILLED'
export const FETCH_CONTACTS_REJECTED = 'carriers.FETCH_CONTACTS_REJECTED'
export const FETCH_CONTACTS_CANCELLED = 'carriers.FETCH_CONTACTS_CANCELLED'

// Export carrier contacts

export const EXPORT_CONTACTS = 'carriers.EXPORT_CONTACTS'
export const EXPORT_CONTACTS_STARTED = 'carriers.EXPORT_CONTACTS_STARTED'
export const EXPORT_CONTACTS_FULFILLED = 'carriers.EXPORT_CONTACTS_FULFILLED'
export const EXPORT_CONTACTS_REJECTED = 'carriers.EXPORT_CONTACTS_REJECTED'
export const EXPORT_CONTACTS_CANCELLED = 'carriers.EXPORT_CONTACTS_CANCELLED'

// Check duplicity

export const CHECK_DUPLICITY = 'carriers.CHECK_DUPLICITY'
export const CHECK_DUPLICITY_STARTED = 'carriers.CHECK_DUPLICITY_STARTED'
export const CHECK_DUPLICITY_FULFILLED = 'carriers.CHECK_DUPLICITY_FULFILLED'
export const CHECK_DUPLICITY_REJECTED = 'carriers.CHECK_DUPLICITY_REJECTED'
export const CHECK_DUPLICITY_CANCELLED = 'carriers.CHECK_DUPLICITY_CANCELLED'
