import React from 'react'
import { Route, Switch } from 'react-router'
import UsersList from './UsersList'
import UsersEdit from './UsersEdit'

const Users = props => (
    <Switch>
        <Route exact path={props.match.url} component={UsersList} />
        <Route exact path={`${props.match.url}/new`} component={UsersEdit} />
        <Route exact path={`${props.match.url}/:userID`} component={UsersEdit} />
    </Switch>
)

export default Users
