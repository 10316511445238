import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import MapPageVehiclesListRow from './MapPageVehiclesListRow'

class MapPageVehiclesList extends Component {
    handleChangeThreeWaySwitchFilter = (e, identifier) => {
        const filters = this.props.vehicleEventsFilterValues
        const currentValue = filters[identifier] || 0
        const switchOptions = {
            0: 1,
            1: 2,
            2: 0,
        }

        this.props.handleVehicleEventsFilterChange(identifier, null, switchOptions[currentValue])
    }

    handleChangeFiveWaySwitchFilter = (e, identifier) => {
        const filters = this.props.vehicleEventsFilterValues
        const currentValue = filters[identifier] || 0
        const switchOptions = {
            0: 1,
            1: 2,
            2: 3,
            3: 4,
            4: 0,
        }

        this.props.handleVehicleEventsFilterChange(identifier, null, switchOptions[currentValue])
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        const props = this.props
        const filters = props.vehicleEventsFilterValues

        const threeWaySwitchOptions = {
            0: 'text-gray-light',
            1: 'text-success',
            2: 'text-danger',
        }

        const fiveWaySwitchOptions = {
            0: 'text-gray-light',
            1: 'text-gray-darkest',
            2: 'text-success',
            3: 'text-danger',
            4: 'text-default',
        }

        const filterIconVehicleStateClassName = threeWaySwitchOptions[filters.vehicle_state || 0]
        const filterIconVehicleUpdateClassName = threeWaySwitchOptions[filters.vehicle_update || 0]
        const filterIconTrailerLoadedClassName = threeWaySwitchOptions[filters.trailer_loaded || 0]
        const filterIconTrailerDamagedClassName = threeWaySwitchOptions[filters.trailer_damaged || 0]
        const filterIconTrailerDamagedSecurityClassName = fiveWaySwitchOptions[filters.trailer_damaged_security || 0]
        const filterIconTrailerDamagedCoolingClassName = fiveWaySwitchOptions[filters.trailer_damaged_cooling || 0]

        return (
            <div className="hp-100">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <input
                                placeholder={`- ${props.intl.formatMessage({ id: 'fields.vehicle' })} -`}
                                defaultValue={props.vehicleEventsFilterValues && props.vehicleEventsFilterValues.vehicle}
                                onChange={event => {
                                    props.handleVehicleEventsFilterChange('vehicle', event)
                                }}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-md-5">
                        <select
                            id={'dispatcher_id'}
                            value={props.vehicleEventsFilterValues && props.vehicleEventsFilterValues.driver_id}
                            onChange={event => {
                                props.handleVehicleEventsFilterChange('driver_id', event)
                            }}
                            className="form-control"
                        >
                            <option value="">{props.intl.formatMessage({ id: 'fields.driver' })}</option>
                            {props.drivers.map(item => (
                                <option key={`driver-${item.id}`} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3 filter-icons text-right">
                        <div className="m-t-sm m-r-xxs">
                            <a
                                href="#"
                                className="m-r-xxs"
                                onClick={e => {
                                    this.handleChangeThreeWaySwitchFilter(e, 'vehicle_state')
                                }}
                            >
                                <i className={`fas fa-circle ${filterIconVehicleStateClassName}`} />
                            </a>
                            <a
                                href="#"
                                onClick={e => {
                                    this.handleChangeThreeWaySwitchFilter(e, 'vehicle_update')
                                }}
                            >
                                <i className={`fas fa-clock ${filterIconVehicleUpdateClassName}`} />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <input
                                placeholder={`- ${props.intl.formatMessage({ id: 'fields.trailer' })} -`}
                                defaultValue={props.vehicleEventsFilterValues && props.vehicleEventsFilterValues.trailer}
                                onChange={event => {
                                    props.handleVehicleEventsFilterChange('trailer', event)
                                }}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-md-5">
                        <select
                            id={'dispatcher_id'}
                            value={props.vehicleEventsFilterValues && props.vehicleEventsFilterValues.dispatcher_id}
                            onChange={event => {
                                props.handleVehicleEventsFilterChange('dispatcher_id', event)
                            }}
                            className="form-control"
                        >
                            <option value="">{props.intl.formatMessage({ id: 'fields.dispatcher' })}</option>
                            {props.dispatchers.map(item => (
                                <option key={`dispatcher-${item.id}`} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3 filter-icons text-right">
                        <div className="m-r-xxs">
                            <a
                                href="#"
                                className="m-r-xxs"
                                onClick={e => {
                                    this.handleChangeThreeWaySwitchFilter(e, 'trailer_loaded')
                                }}
                            >
                                <i className={`fas fa-truck ${filterIconTrailerLoadedClassName}`} />
                            </a>
                            <a
                                href="#"
                                className="m-r-xxs"
                                onClick={e => {
                                    this.handleChangeThreeWaySwitchFilter(e, 'trailer_damaged')
                                }}
                            >
                                <i className={`fas fa-exclamation-circle ${filterIconTrailerDamagedClassName}`} />
                            </a>
                            <a
                                href="#"
                                className="m-r-xxs"
                                onClick={e => {
                                    this.handleChangeFiveWaySwitchFilter(e, 'trailer_damaged_security')
                                }}
                            >
                                <i className={`fas fa-lock ${filterIconTrailerDamagedSecurityClassName}`} />
                            </a>
                            <a
                                href="#"
                                onClick={e => {
                                    this.handleChangeFiveWaySwitchFilter(e, 'trailer_damaged_cooling')
                                }}
                            >
                                <i className={`fas fa-snowflake ${filterIconTrailerDamagedCoolingClassName}`} />
                            </a>
                        </div>
                    </div>
                </div>

                <table className="table table-striped table-hover table-fixed-header table-map-vehicles">
                    <tbody>
                        {props.vehicleEvents.valueSeq().map(vehicleEvent => (
                            <MapPageVehiclesListRow
                                key={`MapPageVehiclesList-${vehicleEvent.id}`}
                                vehicleEvent={vehicleEvent}
                                selected={props.selectedVehicleEvent && props.selectedVehicleEvent.id === vehicleEvent.id}
                                handleVehicleEventsRowClick={props.handleVehicleEventsRowClick}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default injectIntl(MapPageVehiclesList)
