import * as actionTypes from './actionTypes'

// Receive

export const notificationReceived = (notification, userId) => ({
    type: actionTypes.RECEIVED,
    payload: notification,
    userId,
})

export const markNotificationAsRead = timestamp => ({
    type: actionTypes.MARK_AS_READ_FULFILLED,
    payload: timestamp,
})

export const markNotificationAsReadStart = timestamp => ({
    type: actionTypes.MARK_AS_READ,
    payload: timestamp,
})

export const fetchUnreadNotifications = () => ({
    type: actionTypes.UNREAD_NOTIFICATIONS_FETCH_STARTED,
})

export const fetchUnreadNotificationsFinished = notifications => ({
    type: actionTypes.UNREAD_NOTIFICATIONS_FETCH_FULFILLED,
    payload: notifications,
})

export const fetchUnreadNotificationsRejected = message => ({
    type: actionTypes.UNREAD_NOTIFICATIONS_FETCH_REJECTED,
    payload: message,
})

export const fetchNotifications = (filters, page) => ({
    type: actionTypes.NOTIFICATIONS_FETCH_STARTED,
    payload: {
        filters,
        page,
    },
})

export const fetchNotificationsFinished = notifications => ({
    type: actionTypes.NOTIFICATIONS_FETCH_FULFILLED,
    payload: notifications,
})

export const fetchNotificationsSettings = () => ({
    type: actionTypes.NOTIFICATIONS_SETTINGS_FETCH_STARTED,
})

export const fetchNotificationsSettingsFinished = settings => ({
    type: actionTypes.NOTIFICATIONS_SETTINGS_FETCH_FULFILLED,
    payload: settings,
})

export const fetchNotificationsSettingsRejected = message => ({
    type: actionTypes.NOTIFICATIONS_SETTINGS_FETCH_REJECTED,
    payload: message,
})

export const saveNotificationsSettings = settings => ({
    type: actionTypes.NOTIFICATIONS_SETTINGS_SAVE_STARTED,
    payload: settings,
})

export const saveNotificationsSettingsFinished = () => ({
    type: actionTypes.NOTIFICATIONS_SETTINGS_SAVE_FULFILLED,
})
