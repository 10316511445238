export const getIsAuthenticated = state => state.auth.get('accessToken') !== null && state.auth.get('refreshToken') !== null

export const getAuthInProgress = state => state.auth.get('inProgress')

export const getFindTokenInProgress = state => state.auth.get('findTokenInProgress')

export const getAuthError = state => state.auth.get('error')

export const getPermissions = state => state.auth.get('permissions')

export const getPermissionsLoaded = state => state.auth.get('permissions') !== null

export const getCurrentUserId = state => state.auth.get('userID')

export const getSettings = state => state.auth.get('settings')

export const getLegends = state => state.auth.get('legends')
