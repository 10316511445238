export const PERMS = {
    READ: 'transports_template.read',
    UPDATE: 'transports_template.update',
    DELETE: 'transports_template.delete',
    CREATE: 'transports_template.create',
    EXPORT: 'transports_template.export',
}

export const PERMS_COST = {
    READ: 'transports_template_cost.read',
}

export const PERMS_CUSTOMER = {
    READ: 'transports_template_customer.read',
    UPDATE: 'transports_template_customer.update',
}

export const PERMS_CUSTOMER_COST = {
    READ: 'transports_template_customer_cost.read',
    UPDATE: 'transports_template_customer_cost.update',
}

export const PERMS_ROUTES = {
    READ: 'transports_template_routes.read',
    CREATE: 'transports_template_routes.create',
    UPDATE: 'transports_template_routes.update',
    DELETE: 'transports_template_routes.delete',
}

export const PERMS_ALLOWANCES = {
    READ: 'transports_template_allowances.read',
    UPDATE: 'transports_template_allowances.update',
    DELETE: 'transports_template_allowances.delete',
    CREATE: 'transports_template_allowances.create',
}
