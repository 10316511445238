import React from 'react'

const ArrowField = props => {
    const isMin = props.isMin ? 'disabled' : ''
    const isMax = props.isMax ? 'disabled' : ''

    return (
        <div className="pull-left">
            <button onClick={() => props.onClick(-1)} type="button" className={`btn btn-default btn-icon ${isMin}`}>
                <i className="far fa-chevron-left" />
            </button>
            <button type="button" className="btn btn-default">
                {props.value}
            </button>
            <button onClick={() => props.onClick(1)} type="button" className={`btn btn-default btn-icon ${isMax}`}>
                <i className="far fa-chevron-right" />
            </button>
        </div>
    )
}

export default ArrowField
