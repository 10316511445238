export const getItems = state => state.carrierScoring.getIn(['data', 'items'])
export const getRules = state => state.carrierScoring.getIn(['data', 'rules'])
export const getTransportScoringRules = state => state.carrierScoring.getIn(['data', 'transportScoringRules'])

export const isCarrierScoringFetching = state => state.carrierScoring.getIn(['data', 'fetching'])
export const isCarrierScoringError = state => state.carrierScoring.getIn(['data', 'error'])

export const isCarrierScoringQuestionSaving = state => state.carrierScoring.getIn(['question', 'saving'])
export const isCarrierScoringQuestionDeleting = state => state.carrierScoring.getIn(['question', 'deleting'])
export const isCarrierScoringQuestionMovingUp = state => state.carrierScoring.getIn(['question', 'movingUp'])
export const isCarrierScoringQuestionMovingDown = state => state.carrierScoring.getIn(['question', 'movingDown'])
export const getCarrierScoringQuestionError = state => state.carrierScoring.getIn(['question', 'error'])

export const isCarrierScoringAnswerSaving = state => state.carrierScoring.getIn(['answer', 'saving'])
export const isCarrierScoringAnswerDeleting = state => state.carrierScoring.getIn(['answer', 'deleting'])
export const isCarrierScoringAnswerMovingUp = state => state.carrierScoring.getIn(['answer', 'movingUp'])
export const isCarrierScoringAnswerMovingDown = state => state.carrierScoring.getIn(['answer', 'movingDown'])
export const getCarrierScoringAnswerError = state => state.carrierScoring.getIn(['answer', 'error'])

export const isCarrierScoringRuleSaving = state => state.carrierScoring.getIn(['rule', 'saving'])
export const isCarrierScoringRuleDeleting = state => state.carrierScoring.getIn(['rule', 'deleting'])
export const getCarrierScoringRuleError = state => state.carrierScoring.getIn(['rule', 'error'])
