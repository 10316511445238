// Fetch list

export const FETCH = 'serviceOrders.FETCH'
export const FETCH_STARTED = 'serviceOrders.FETCH_STARTED'
export const FETCH_FULFILLED = 'serviceOrders.FETCH_FULFILLED'
export const FETCH_REJECTED = 'serviceOrders.FETCH_REJECTED'
export const FETCH_CANCELLED = 'serviceOrders.FETCH_CANCELLED'

export const SORTING_CHANGED = 'serviceOrders.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'serviceOrders.FETCH_ONE'
export const FETCH_ONE_STARTED = 'serviceOrders.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'serviceOrders.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'serviceOrders.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'serviceOrders.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'serviceOrders.SAVE'
export const SAVE_STARTED = 'serviceOrders.SAVE_STARTED'
export const SAVE_FULFILLED = 'serviceOrders.SAVE_FULFILLED'
export const SAVE_REJECTED = 'serviceOrders.SAVE_REJECTED'
export const SAVE_CANCELLED = 'serviceOrders.SAVE_CANCELLED'

// Delete

export const DELETE = 'serviceOrders.DELETE'
export const DELETE_STARTED = 'serviceOrders.DELETE_STARTED'
export const DELETE_FULFILLED = 'serviceOrders.DELETE_FULFILLED'
export const DELETE_REJECTED = 'serviceOrders.DELETE_REJECTED'
export const DELETE_CANCELLED = 'serviceOrders.DELETE_CANCELLED'

// Export

export const EXPORT = 'serviceOrders.EXPORT'
export const EXPORT_FULFILLED = 'serviceOrders.EXPORT_FULFILLED'

// Fetch job autocomplete

export const FETCH_JOB_AUTOCOMPLETE = 'serviceOrders.FETCH_JOB_AUTOCOMPLETE'
export const FETCH_JOB_AUTOCOMPLETE_STARTED = 'serviceOrders.FETCH_JOB_AUTOCOMPLETE_STARTED'
export const FETCH_JOB_AUTOCOMPLETE_FULFILLED = 'serviceOrders.FETCH_JOB_AUTOCOMPLETE_FULFILLED'
export const FETCH_JOB_AUTOCOMPLETE_REJECTED = 'serviceOrders.FETCH_JOB_AUTOCOMPLETE_REJECTED'
export const FETCH_JOB_AUTOCOMPLETE_CANCELLED = 'serviceOrders.FETCH_JOB_AUTOCOMPLETE_CANCELLED'

// Fetch material autocomplete

export const FETCH_MATERIAL_AUTOCOMPLETE = 'serviceOrders.FETCH_MATERIAL_AUTOCOMPLETE'
export const FETCH_MATERIAL_AUTOCOMPLETE_STARTED = 'serviceOrders.FETCH_MATERIAL_AUTOCOMPLETE_STARTED'
export const FETCH_MATERIAL_AUTOCOMPLETE_FULFILLED = 'serviceOrders.FETCH_MATERIAL_AUTOCOMPLETE_FULFILLED'
export const FETCH_MATERIAL_AUTOCOMPLETE_REJECTED = 'serviceOrders.FETCH_MATERIAL_AUTOCOMPLETE_REJECTED'
export const FETCH_MATERIAL_AUTOCOMPLETE_CANCELLED = 'serviceOrders.FETCH_MATERIAL_AUTOCOMPLETE_CANCELLED'

// Fetch worker autocomplete

export const FETCH_WORKER_AUTOCOMPLETE = 'serviceOrders.FETCH_WORKER_AUTOCOMPLETE'
export const FETCH_WORKER_AUTOCOMPLETE_STARTED = 'serviceOrders.FETCH_WORKER_AUTOCOMPLETE_STARTED'
export const FETCH_WORKER_AUTOCOMPLETE_FULFILLED = 'serviceOrders.FETCH_WORKER_AUTOCOMPLETE_FULFILLED'
export const FETCH_WORKER_AUTOCOMPLETE_REJECTED = 'serviceOrders.FETCH_WORKER_AUTOCOMPLETE_REJECTED'
export const FETCH_WORKER_AUTOCOMPLETE_CANCELLED = 'serviceOrders.FETCH_WORKER_AUTOCOMPLETE_CANCELLED'

// Fetch states

export const FETCH_SERVICE_ORDER_STATES_FULFILLED = 'serviceOrders.FETCH_SERVICE_ORDER_STATES_FULFILLED'

// Create issue

export const CREATE_ISSUE = 'serviceOrders.CREATE_ISSUE'