// Fetch

export const FETCH = 'generalSettings.FETCH'
export const FETCH_STARTED = 'generalSettings.FETCH_STARTED'
export const FETCH_FULFILLED = 'generalSettings.FETCH_FULFILLED'
export const FETCH_REJECTED = 'generalSettings.FETCH_REJECTED'
export const FETCH_CANCELLED = 'generalSettings.FETCH_CANCELLED'

// Save

export const SAVE = 'generalSettings.SAVE'
export const SAVE_STARTED = 'generalSettings.SAVE_STARTED'
export const SAVE_FULFILLED = 'generalSettings.SAVE_FULFILLED'
export const SAVE_REJECTED = 'generalSettings.SAVE_REJECTED'
export const SAVE_CANCELLED = 'generalSettings.SAVE_CANCELLED'
