import * as actionTypes from './actionTypes'

// Fetch

export const fetchCompanyBankAccounts = () => ({
    type: actionTypes.FETCH,
})

export const fetchCompanyBankAccountsFulfilled = companyBankAccounts => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: companyBankAccounts,
})

export const fetchCompanyBankAccountsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchCompanyBankAccountsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchCompanyBankAccount = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchCompanyBankAccountFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchCompanyBankAccountRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchCompanyBankAccountCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveCompanyBankAccount = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveCompanyBankAccountFulfilled = companyBankAccount => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: companyBankAccount,
})

export const saveCompanyBankAccountRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteCompanyBankAccount = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteCompanyBankAccountFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteCompanyBankAccountRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportCompanyBankAccounts = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
