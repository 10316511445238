import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

import { Form, SelectField, InputField, SubmitButton } from '../../../common/form'
import * as formatters from '../../../common/formatters'
import {
    url,
    handleCommonEditActions,
    getPrerequisitesArray,
    convertCSDateToISO,
    convertISODateToCS,
    convertCSDateToMoment,
} from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as vehicleDocumentsActionCreators from '../actionCreators'
import { createGetVehicleDocumentByIDSelector, createGetCurrentVehicleDocumentStatusSelector, createGetDuplicitySelector } from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import VehicleDocumentsFiles from './VehicleDocumentsFiles'
import {CustomDatePicker} from "../../../common/custom_date_picker";

const componentIdentifier = 'vehicle_documents_edit'
const prerequisites = ['vehicles', 'document_types_for_vehicles', 'document_types_validity']

class VehicleDocumentsEditForm extends Component {
    defaultValues = {}

    validationRules = {}

    defaultValidity = {}

    state = {
        values: this.defaultValues,
        datetimes: {
            validity_from: moment().format('DD.MM.YYYY'),
            validity_to: moment().format('DD.MM.YYYY'),
        },
        datetimesSet: false,
        modalDuplicity: {
            show: false,
            duplicity: null,
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleDatetimeChange = (key, value) => {
        const newState = {
            datetimes: {
                ...this.state.datetimes,
                [key]: value,
            },
        }

        this.setState(newState, () => {
            if (key === 'validity_from') {
                this.updateValidity()
            }
        })
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave(false)
        })
    }

    handleSave = ignoreDuplicity => {
        this.props.actions.saveVehicleDocument(
            {
                ...this.state.values,
                date_validity_from: convertCSDateToISO(this.state.datetimes.validity_from),
                date_validity_to: convertCSDateToISO(this.state.datetimes.validity_to),
            },
            ignoreDuplicity
        )
    }

    duplicityFoundCallback = duplicity => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()
        this.handleSave(true)
    }

    successCallback = () => {
        this.props.successCallback ? this.props.successCallback() : this.props.redirect(url(this.props.match, 'vehicle-documents'))
    }

    backCallback = () => {
        this.props.backCallback ? this.props.backCallback() : this.props.redirect(url(this.props.match, 'vehicle-documents'))
    }

    handleDocumentTypeChange = (fieldId, option) => {
        this.setValues(
            {
                ...this.state.values,
                [fieldId]: option.value,
            },
            this.updateValidity
        )
    }

    updateValidity = () => {
        if (this.state.datetimes.validity_from && this.state.values.document_type_id) {
            const documentTypeId = parseInt(this.state.values.document_type_id)
            const validity = this.defaultValidity[documentTypeId] ? parseInt(this.defaultValidity[documentTypeId]) : 0
            const validityToMoment = convertCSDateToMoment(this.state.datetimes.validity_from)
            validityToMoment.add(validity, 'months')

            this.setState({
                datetimes: {
                    ...this.state.datetimes,
                    validity_to: validityToMoment.format('DD.MM.YYYY'),
                },
            })
        }
    }

    componentDidMount() {
        this.props.vehicleDocumentID && this.props.actions.fetchVehicleDocument(this.props.vehicleDocumentID)
        this.props.actions.fetchPrerequisites()

        if (this.props.defaultValues) {
            this.setValues(this.props.defaultValues)
        }
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback, this.duplicityFoundCallback)

        if (nextProps.vehicleDocument && !this.state.datetimesSet) {
            this.setState({
                values: nextProps.vehicleDocument.toJS(),
                datetimes: {
                    validity_from: convertISODateToCS(nextProps.vehicleDocument.date_validity_from),
                    validity_to: convertISODateToCS(nextProps.vehicleDocument.date_validity_to),
                },
                datetimesSet: true,
            })
        }

        if (this.props.prerequisites.status.get('fetching') && !nextProps.prerequisites.status.get('fetching')) {
            this.defaultValidity = {}
            nextProps.prerequisites.values
                .get('document_types_validity')
                .valueSeq()
                .forEach(item => {
                    this.defaultValidity[item.get('id')] = item.get('default_validity')
                })
        }
    }

    render() {
        const duplicity = this.props.duplicity
        const perms = this.props.PERMS ? this.props.PERMS : PERMS

        return (
            <div>
                <LoadingOverlay active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching')}>
                    <PermissionsCheck hasAny={Object.values(perms)}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                            isEdit={Boolean(this.props.vehicleDocument)}
                        >
                            <div className={this.props.hiddenFields && this.props.hiddenFields.indexOf('vehicle_id') !== -1 ? 'hidden' : ''}>
                                <SelectField
                                    id="vehicle_id"
                                    label={this.props.intl.formatMessage({ id: 'fields.vehicle' })}
                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('vehicles'))}
                                    className="wp-50"
                                />
                            </div>
                            <SelectField
                                id="document_type_id"
                                label={this.props.intl.formatMessage({ id: 'fields.documentType' })}
                                values={getPrerequisitesArray(this.props.prerequisites.values.get('document_types_for_vehicles'))}
                                className="wp-50"
                                onChange={this.handleDocumentTypeChange}
                            />
                            <InputField id="number" label={this.props.intl.formatMessage({ id: 'fields.number' })} className="wp-50" />
                            <div>
                                <div className="form-group w-200">
                                    <label htmlFor="value_date_validity_from">
                                        <FormattedMessage id="fields.validityFrom" />
                                    </label>
                                    <CustomDatePicker
                                        id="value_date_validity_from"
                                        selected={convertCSDateToMoment(this.state.datetimes.validity_from)}
                                        value={this.state.datetimes.validity_from}
                                        onChange={value => {
                                            this.handleDatetimeChange('validity_from', value ? formatters.dateFormatter(value) : '')
                                        }}
                                        onChangeRaw={e => {
                                            this.handleDatetimeChange('validity_from', e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="form-group w-200">
                                    <label htmlFor="value_date_validity_to">
                                        <FormattedMessage id="fields.validityTo" />
                                    </label>
                                    <CustomDatePicker
                                        id="value_date_validity_to"
                                        selected={convertCSDateToMoment(this.state.datetimes.validity_to)}
                                        value={this.state.datetimes.validity_to}
                                        onChange={value => {
                                            this.handleDatetimeChange('validity_to', value ? formatters.dateFormatter(value) : '')
                                        }}
                                        onChangeRaw={e => {
                                            this.handleDatetimeChange('validity_to', e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <InputField id="note" label={this.props.intl.formatMessage({ id: 'fields.note' })} autosize />

                            {this.props.vehicleDocument && (
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>
                                            <FormattedMessage id="fields.files" />
                                        </h4>
                                    </div>
                                    <div className="col-md-12">
                                        <VehicleDocumentsFiles vehicleDocument={this.props.vehicleDocument} />
                                    </div>
                                </div>
                            )}

                            <div className="btns-form">
                                <button onClick={this.backCallback} className="btn btn-addon btn-default">
                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                </button>
                                <SubmitButton
                                    perms={Object.values(perms)}
                                    isEdit={Boolean(this.props.vehicleDocument)}
                                    className="btn-addon pull-right"
                                />
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>

                <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} bsSize="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="confirmDialog.duplicityFound.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormattedMessage id="confirmDialog.duplicityFound.message" />
                        {duplicity && (
                            <div>
                                <div className="row m-t-lg">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.vehicle" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.vehicle}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.documentType" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.type}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.number" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.number}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.validityFrom" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.date_validity_from}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.validityTo" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.date_validity_to}</strong>
                                    </div>
                                </div>
                                <hr className="tiny" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormattedMessage id="fields.note" />:
                                    </div>
                                    <div className="col-sm-8 text-right">
                                        <strong>{duplicity.note}</strong>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.CREATE]}>
                            <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                                <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                            </button>
                        </PermissionsCheck>
                        <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                            <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        vehicleDocument: createGetVehicleDocumentByIDSelector(ownProps.vehicleDocumentID)(state),
        status: createGetCurrentVehicleDocumentStatusSelector()(state),
        duplicity: createGetDuplicitySelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...vehicleDocumentsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(VehicleDocumentsEditForm)
)
