import React, { Component } from 'react'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class CompanyCostCentersListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.data.id)
    }

    handleDelete = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onDelete(this.props.data.id)
    }

    render = () => (
        <PermissionsCheck hasAny={[PERMS.DELETE, PERMS.UPDATE]}>
            <tr onClick={this.handleClick} className="row-selectable">
                <td className="w-60 table-buttons">
                    <PermissionsCheck has={[PERMS.UPDATE]}>
                        <button className="far fa-pencil text-gray m-r-xxs" />
                    </PermissionsCheck>
                    <PermissionsCheck has={[PERMS.DELETE]}>
                        <button onClick={this.handleDelete} className="far fa-trash text-gray" />
                    </PermissionsCheck>
                </td>
                <td className="w-300">{this.props.company && this.props.company.name}</td>
                <td className="w-140">{this.props.data.code}</td>
                <td className="w-max wm-200">
                    <strong>{this.props.data.name}</strong>
                </td>
            </tr>
        </PermissionsCheck>
    )
}

export default CompanyCostCentersListRow
