import React, { Component } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import * as constants from '../constants'

// eslint-disable-next-line react/prefer-stateless-function
class TransportListRowStateIcon extends Component {
    render = () => {
        const { state, title } = this.props

        const icons = {}
        icons[constants.TRANSPORT_POINT_STATE_ON_WAY] = 'far fa-arrow-circle-right'
        icons[constants.TRANSPORT_POINT_STATE_ARRIVED] = 'far fa-check-circle'
        icons[constants.TRANSPORT_POINT_STATE_WAITING] = 'far fa-clock'
        icons[constants.TRANSPORT_POINT_STATE_IN_PROCESS] = 'fas fa-cog fa-spin'
        icons[constants.TRANSPORT_POINT_STATE_DONE] = 'fas fa-check-circle'
        icons[constants.TRANSPORT_POINT_STATE_FINISHED] = 'fas fa-check-circle'

        const colors = {}
        colors[constants.TRANSPORT_POINT_STATE_ON_WAY] = 'text-gray'
        colors[constants.TRANSPORT_POINT_STATE_ARRIVED] = 'text-gray'
        colors[constants.TRANSPORT_POINT_STATE_WAITING] = 'text-gray'
        colors[constants.TRANSPORT_POINT_STATE_IN_PROCESS] = 'text-gray'
        colors[constants.TRANSPORT_POINT_STATE_DONE] = 'text-success'
        colors[constants.TRANSPORT_POINT_STATE_FINISHED] = 'text-success'

        const tooltip = (
            <Tooltip id="tooltip">
                <strong>{state.name}</strong>
                <br />
                {title}
            </Tooltip>
        )

        return (
            <span>
                {title ? (
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        <span className={`${icons[state.id]} ${colors[state.id]}`} />
                    </OverlayTrigger>
                ) : (
                    <span className={`${icons[state.id]} ${colors[state.id]}`} />
                )}
            </span>
        )
    }
}

export default TransportListRowStateIcon
