// Fetch list

export const FETCH = 'serviceJobs.FETCH'
export const FETCH_STARTED = 'serviceJobs.FETCH_STARTED'
export const FETCH_FULFILLED = 'serviceJobs.FETCH_FULFILLED'
export const FETCH_REJECTED = 'serviceJobs.FETCH_REJECTED'
export const FETCH_CANCELLED = 'serviceJobs.FETCH_CANCELLED'

export const SORTING_CHANGED = 'serviceJobs.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'serviceJobs.FETCH_ONE'
export const FETCH_ONE_STARTED = 'serviceJobs.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'serviceJobs.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'serviceJobs.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'serviceJobs.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'serviceJobs.SAVE'
export const SAVE_STARTED = 'serviceJobs.SAVE_STARTED'
export const SAVE_FULFILLED = 'serviceJobs.SAVE_FULFILLED'
export const SAVE_REJECTED = 'serviceJobs.SAVE_REJECTED'
export const SAVE_CANCELLED = 'serviceJobs.SAVE_CANCELLED'

// Delete

export const DELETE = 'serviceJobs.DELETE'
export const DELETE_STARTED = 'serviceJobs.DELETE_STARTED'
export const DELETE_FULFILLED = 'serviceJobs.DELETE_FULFILLED'
export const DELETE_REJECTED = 'serviceJobs.DELETE_REJECTED'
export const DELETE_CANCELLED = 'serviceJobs.DELETE_CANCELLED'

// Export

export const EXPORT = 'serviceJobs.EXPORT'
export const EXPORT_FULFILLED = 'serviceJobs.EXPORT_FULFILLED'
