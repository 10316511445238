import React from 'react'
import { Route, Switch } from 'react-router'
import WarehousingReceiptsList from './WarehousingReceiptsList'
import WarehousingReceiptsEdit from './WarehousingReceiptsEdit'

const WarehousingReceipts = props => (
    <Switch>
        <Route exact path={props.match.url} component={WarehousingReceiptsList} />
        <Route exact path={`${props.match.url}/new`} component={WarehousingReceiptsEdit} />
        <Route exact path={`${props.match.url}/:warehousingReceiptID`} component={WarehousingReceiptsEdit} />
    </Switch>
)

export default WarehousingReceipts
