import * as actionTypes from './actionTypes'

// Fetch

export const fetchTrailerTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchTrailerTypesFulfilled = trailerTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: trailerTypes,
})

export const fetchTrailerTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTrailerTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchTrailerType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchTrailerTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchTrailerTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchTrailerTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveTrailerType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveTrailerTypeFulfilled = trailerType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: trailerType,
})

export const saveTrailerTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteTrailerType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteTrailerTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteTrailerTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportTrailerTypes = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
