import * as actionTypes from './actionTypes'

// Login

export const login = values => ({
    type: actionTypes.LOGIN,
    payload: values,
})

export const loginFulfilled = (accessToken, refreshToken, userID, permissions, settings, legends) => ({
    type: actionTypes.LOGIN_FULFILLED,
    payload: {
        accessToken,
        refreshToken,
        userID,
        permissions,
        settings,
        legends,
    },
})

export const loginRejected = message => ({
    type: actionTypes.LOGIN_REJECTED,
    payload: message,
})

// Logout

export const logout = () => ({
    type: actionTypes.LOGOUT,
})

export const logoutFulfilled = () => ({
    type: actionTypes.LOGOUT_FULFILLED,
})

export const logoutRejected = message => ({
    type: actionTypes.LOGOUT_REJECTED,
    payload: message,
})

// Permissions

export const fetchPermissions = () => ({
    type: actionTypes.PERMISSIONS_FETCH,
})

export const permissionsFetchFulfilled = (permissions, settings, legends) => ({
    type: actionTypes.PERMISSIONS_FETCH_FULFILLED,
    payload: {
        permissions,
        settings,
        legends,
    },
})

export const permissionsFetchRejected = message => ({
    type: actionTypes.PERMISSIONS_FETCH_REJECTED,
    payload: message,
})

// Forgotten password

export const forgottenPassword = values => ({
    type: actionTypes.FORGOTTEN_PASSWORD,
    payload: values,
})

export const forgottenPasswordFulfilled = () => ({
    type: actionTypes.FORGOTTEN_PASSWORD_FULFILLED,
})

export const forgottenPasswordRejected = message => ({
    type: actionTypes.LOGIN_REJECTED,
    payload: message,
})

// Find token

export const findToken = values => ({
    type: actionTypes.FIND_TOKEN,
    payload: values,
})

export const findTokenFulfilled = () => ({
    type: actionTypes.FIND_TOKEN_FULFILLED,
})

export const findTokenRejected = message => ({
    type: actionTypes.FIND_TOKEN_REJECTED,
    payload: message,
})

// Reset password

export const resetPassword = values => ({
    type: actionTypes.RESET_PASSWORD,
    payload: values,
})

export const resetPasswordFulfilled = () => ({
    type: actionTypes.RESET_PASSWORD_FULFILLED,
})

export const resetPasswordRejected = message => ({
    type: actionTypes.RESET_PASSWORD_REJECTED,
    payload: message,
})
