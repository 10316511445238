import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Modal } from 'react-bootstrap'
import { SwatchesPicker } from 'react-color'

import Field from './Field'

// eslint-disable-next-line react/prefer-stateless-function
class ColorPicker extends Component {
    state = {
        showModal: false,
    }

    showModal = event => {
        event && event.preventDefault()
        event && event.stopPropagation()
        this.setState({ showModal: true })
    }

    hideModal = event => {
        event && event.preventDefault()
        event && event.stopPropagation()
        this.setState({ showModal: false })
    }

    handleClick = value => {
        this.props.onChange(value.hex)
        this.hideModal()
    }

    render() {
        let value

        if (this.props.hasOwnProperty('getValue')) {
            value = String(this.props.getValue() ? this.props.getValue() : this.props.defaultValue)
        } else {
            value = String(this.props.value ? this.props.value : this.props.defaultValue)
        }

        return (
            <React.Fragment>
                <Field {...this.props}>
                    <div className="input-group m-b-sm">
                        <span className="input-group-btn">
                            <button className="btn btn-default" style={{ opacity: 1, backgroundColor: value }} disabled>
                                {' '}
                            </button>
                        </span>
                        <input value={value} className={`form-control`} disabled />
                        <span className="input-group-btn">
                            <button className="btn btn-default" onClick={this.showModal} disabled={this.props.readonly()}>
                                <i className="far fa-search" />
                            </button>
                        </span>
                    </div>
                </Field>

                <Modal show={this.state.showModal} onHide={this.hideModal} bsSize="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="fields.color" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SwatchesPicker className="color-picker" onChange={this.handleClick} colors={this.props.colors} width="100%" height="auto" />
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

export default injectIntl(ColorPicker)
