import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { url, handleCommonListActions, getListItemsCountMessage } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableSortButtons, TableFilterInputField, TableFilterSelectField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import * as driverSurchargeTypesActionCreators from '../actionCreators'
import { getDriverSurchargeTypes, getDriverSurchargeTypeDeleting, getDriverSurchargeTypeDeleteError } from '../selectors'

import DriverSurchargeTypesListRow from './DriverSurchargeTypesListRow'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import * as constants from '../constants'

const tableIdentifier = 'driver_surcharge_types_list'
const clientSideItemsPerPage = undefined

const columns = {
    name: new TableModelColumn({}),
}

class DriverSurchargeTypesList extends Component {
    state = {
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {
            },
            onAccept: () => {
            },
        },
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {
                },
                onAccept: () => {
                },
            },
        })
    }

    // handlers
    onClick = itemId => {
        this.props.redirect(`/driver-surcharge-types/${itemId}`)
    }

    onDelete = itemId => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteDriverSurchargeType(itemId)
                this.hideConfirmDialog()
            },
        })
    }

    refresh = () => {
        this.props.actions.fetchDriverSurchargeTypes()
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        this.props.actions.fetchDriverSurchargeTypes()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.driverSurchargeTypes.heading' }))

        const sorting = this.props.tableModel.get('sorting')
        const filters = this.props.tableModel.get('filters')

        const typesOptions = [{
            id: constants.DRIVER_SURCHARGE_TYPE_DAY,
            name: this.props.intl.formatMessage({ id: `driverSurchargeTypes.${constants.DRIVER_SURCHARGE_TYPE_DAY}` }),
        }, {
            id: constants.DRIVER_SURCHARGE_TYPE_MONTH,
            name: this.props.intl.formatMessage({ id: `driverSurchargeTypes.${constants.DRIVER_SURCHARGE_TYPE_MONTH}` }),
        },]

        return (<PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
            <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                <div id="main-wrapper">
                    <div className="panel panel-white">
                        <div className="panel-body">
                            <h4>
                                <FormattedMessage id="modules.driverSurchargeTypes.heading" />
                            </h4>

                            <div className="btns-list">
                                {this.isFilterActive() > 0 && (<button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                    <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                </button>)}
                                <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                    <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                </button>
                                <PermissionsCheck has={[PERMS.CREATE]}>
                                    <Link to={url(this.props.match, 'driver-surcharge-types/new')} className="btn btn-addon btn-success">
                                        <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                                    </Link>
                                </PermissionsCheck>
                            </div>

                            <div className="table-container">
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                    <tr>
                                        <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                            <th className="w-60" />
                                        </PermissionsCheck>
                                        <th className="w-max wm-300">
                                            <FormattedMessage id="fields.name" />
                                            <TableSortButtons identifier="name" sorting={sorting} onChange={this.props.table.changeSorting} />
                                        </th>
                                        <th className="w-100">
                                            <FormattedMessage id="fields.code" />
                                        </th>
                                        <th className="w-160">
                                            <FormattedMessage id="fields.driverSurchargeType" />
                                        </th>
                                    </tr>
                                    <tr className="filters">
                                        <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                            <th className="w-60" />
                                        </PermissionsCheck>
                                        <th className="w-max wm-300">
                                            <TableFilterInputField
                                                identifier="name"
                                                type={TableModelColumnFilteringType.string}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                            />
                                        </th>
                                        <th className="w-100">
                                            <TableFilterInputField
                                                identifier="code"
                                                type={TableModelColumnFilteringType.string}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                            />
                                        </th>
                                        <th className="w-160">
                                            <TableFilterSelectField
                                                identifier="type_id"
                                                type={TableModelColumnFilteringType.equal}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                values={typesOptions}
                                            />
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.driverSurchargeTypes.data.valueSeq().map(row => (
                                        <DriverSurchargeTypesListRow key={`row-${row.id}`} data={row} onClick={this.onClick} onDelete={this.onDelete} type={row.type && this.props.intl.formatMessage({ id: `driverSurchargeTypes.${row.type}` })} />))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="pull-left m-l-xxs m-t-md">
                                <FormattedMessage id="pagination.totalRecords" />:{' '}
                                {getListItemsCountMessage(clientSideItemsPerPage, this.props.driverSurchargeTypes.count, this.props.tableModel.getIn(['pagination', 'totalCount']))}
                            </div>

                            <ReactPaginate
                                containerClassName="pagination"
                                breakLabel={<span className="disabled">...</span>}
                                activeClassName="active"
                                pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.driverSurchargeTypes.count, clientSideItemsPerPage)}
                                pageRangeDisplayed={10}
                                marginPagesDisplayed={2}
                                forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                onPageChange={this.props.table.changePage}
                                previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                            />
                        </div>
                    </div>
                </div>

                <ConfirmDialog options={this.state.confirmDialog} />
            </div>
        </PermissionsCheck>)
    }
}

function mapStateToProps(state) {
    return {
        driverSurchargeTypes: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getDriverSurchargeTypes,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        deleting: getDriverSurchargeTypeDeleting(state),
        deleteError: getDriverSurchargeTypeDeleteError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(driverSurchargeTypesActionCreators, dispatch),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps, ...dispatchProps, ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(DriverSurchargeTypesList))
