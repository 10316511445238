import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'

import * as constants from '../../transports/constants'
import * as formatters from '../../../common/formatters'

import { getListItemsCountMessage } from '../../../common/helpers'
import * as reportPalletsActionCreators from '../actionCreators'
import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { getData } from '../selectors'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { TableFilterInputField, TableFilterBooleanField, TableFilterSelectField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { createGetVehicleByIDSelector } from '../../vehicles/selectors'
import { createGetDriverByIDSelector } from '../../drivers/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import { PeriodPicker } from "../../../common/period_picker";
import * as periodPickerConstants from "../../../common/period_picker/constants";
import moment from "moment";
import * as helpers from "../../../common/helpers";
import {Link} from "react-router-dom";

const tableIdentifier = 'report_pallets_list'
const clientSideItemsPerPage = undefined

const columns = {
    datetime: new TableModelColumn({}),
    transport_id: new TableModelColumn({}),
}

class ReportPallets extends Component {
    refresh = () => {
        this.props.actions.fetchReportPallets(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportPallets(this.props.tableModel.get('filters'))
    }

    handleFilterChanged = (momentFrom, momentTo) => {
        this.props.table.changeFilters([
            {
                column: 'date_from',
                type: TableModelColumnFilteringType.equal,
                value: momentFrom.format('DD.MM.YYYY'),
            },
            {
                column: 'date_to',
                type: TableModelColumnFilteringType.equal,
                value: momentTo.format('DD.MM.YYYY'),
            },
        ])
    }

    componentDidMount() {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.setConfiguration(
            [
                {
                    column: 'date_from',
                    type: TableModelColumnFilteringType.equal,
                    value: dateFrom.format('DD.MM.YYYY'),
                },
                {
                    column: 'date_to',
                    type: TableModelColumnFilteringType.equal,
                    value: dateTo.format('DD.MM.YYYY'),
                },
            ],
            false
        )

        this.props.actions.fetchReportPallets(this.props.tableModel.get('filters'))
    }

    componentWillUnmount() {
        this.props.actions.clearReportPallets()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportPallets.heading' }))

        const filters = this.props.tableModel.get('filters')

        const stringFrom = filters.getIn(['date_from', 'value'])
        const stringTo = filters.getIn(['date_to', 'value'])

        const momentFrom = stringFrom ? helpers.convertCSDateToMoment(stringFrom) : moment().startOf('month')
        const momentTo = stringTo ? helpers.convertCSDateToMoment(stringTo) : moment().endOf('month')

        const typesValues = [
            { id: constants.TRANSPORT_POINT_TYPE_LOADING, name: this.props.intl.formatMessage({ id: 'fields.loading' }) },
            { id: constants.TRANSPORT_POINT_TYPE_UNLOADING, name: this.props.intl.formatMessage({ id: 'fields.unloading' }) },
        ]

        const typesPairs = {
            [constants.TRANSPORT_POINT_TYPE_LOADING]: this.props.intl.formatMessage({ id: 'fields.loading' }),
            [constants.TRANSPORT_POINT_TYPE_UNLOADING]: this.props.intl.formatMessage({ id: 'fields.unloading' }),
        }

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportPallets.heading" />
                                        </span>
                                    </h4>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className="m-r-lg"
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_MONTH}
                                            onChange={(momentFrom, momentTo) => this.handleFilterChanged(momentFrom, momentTo)}
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-80">
                                                    <FormattedMessage id="fields.transport" />
                                                </th>
                                                <th className="w-max">
                                                    <FormattedMessage id="fields.customer" />
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.pointType" />
                                                </th>
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.dateTime" />
                                                </th>
                                                <th className="w-300">
                                                    <FormattedMessage id="fields.address" />
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.vehicle" />
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.trailer" />
                                                </th>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                <th className="w-100 text-center">
                                                    <FormattedMessage id="fields.palletExchange" />
                                                </th>
                                                <th className="w-80 text-right">
                                                    <FormattedMessage id="fields.loaded" />
                                                </th>
                                                <th className="w-80 text-right">
                                                    <FormattedMessage id="fields.unloaded" />
                                                </th>
                                                <th className="w-80 text-right">
                                                    <FormattedMessage id="fields.damaged" />
                                                </th>
                                                <th className="w-80 text-right">
                                                    <FormattedMessage id="fields.balance" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-80">
                                                    <TableFilterInputField
                                                        identifier="transport_id"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-max">
                                                    <TableFilterInputField
                                                        identifier="customer"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterSelectField
                                                        identifier="type_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={typesValues}
                                                    />
                                                </th>
                                                <th className="w-120" />
                                                <th className="w-300">
                                                    <TableFilterInputField
                                                        identifier="address"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterInputField
                                                        identifier="vehicle"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterInputField
                                                        identifier="trailer"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-160">
                                                    <TableFilterInputField
                                                        identifier="driver"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterBooleanField
                                                        identifier="pallet_exchange"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-80" />
                                                <th className="w-80" />
                                                <th className="w-80" />
                                                <th className="w-80" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.pallets &&
                                                this.props.pallets.data.map(item => (
                                                    <tr key={item.id}>
                                                        <td className="w-80">
                                                            <Link to={`/transports/${item.transport}`}>
                                                                {item.transport}
                                                            </Link>
                                                        </td>
                                                        <td className="w-max">{item.customer}</td>
                                                        <td className="w-100">{typesPairs[item.type_id]}</td>
                                                        <td className="w-120">{formatters.datetimeFormatter(item.datetime)}</td>
                                                        <td className="w-300">{item.address}</td>
                                                        <td className="w-100">{item.vehicle}</td>
                                                        <td className="w-100">{item.trailer}</td>
                                                        <td className="w-160">{item.driver}</td>
                                                        <td className="w-100 text-center">{formatters.boolFormatter(item.pallet_exchange)}</td>
                                                        <td className="w-80 text-right">{formatters.numberFormatter(item.loaded, '0,0', 'ks')}</td>
                                                        <td className="w-80 text-right">{formatters.numberFormatter(item.unloaded, '0,0', 'ks')}</td>
                                                        <td className="w-80 text-right">{formatters.numberFormatter(item.damaged, '0,0', 'ks')}</td>
                                                        <td className="w-80 text-right">
                                                            {formatters.numberFormatter(item.loaded - item.unloaded - item.damaged, '0,0', 'ks')}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.pallets.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.pallets.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        pallets: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getData,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        createGetVehicleByIDSelector: id => createGetVehicleByIDSelector(id)(state),
        createGetDriverByIDSelector: id => createGetDriverByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportPalletsActionCreators,
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReportPallets))
