import { Record } from 'immutable'

export class ServiceOrder extends Record({
    id: undefined,
    date_planned_from: undefined,
    date_planned_to: undefined,
    text: undefined,
    customer_id: undefined,
    vehicle_id: undefined,
    state_id: undefined,
    jobs: undefined,
    workers: undefined,
    material: undefined,
    issues: undefined
}) {}

export class ServiceOrderState extends Record({
    id: undefined,
    pos_order: undefined,
    name: undefined,
    rgb_background: undefined,
    rgb_text: undefined
}) {}

export class ServiceOrderJob extends Record({
    id: undefined,
    service_order_id: undefined,
    service_job_id: undefined,
    code: undefined,
    name: undefined,
    units_count: undefined,
    unit_price: undefined,
    unit_duration: undefined
}) {}

export class ServiceOrderMaterial extends Record({
    id: undefined,
    service_order_id: undefined,
    warehousing_item_id: undefined,
    code: undefined,
    name: undefined,
    units_count: undefined,
    unit_price: undefined,
    unit: undefined
}) {}

export class ServiceOrderWorker extends Record({
    id: undefined,
    service_order_id: undefined,
    worker_id: undefined,
    first_name: undefined,
    last_name: undefined,
    duration: undefined,
    costs: undefined
}) {}