import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import Select from 'react-select'
import moment from 'moment'

class WeekSelector extends Component {
    onChange = week => {
        const year = this.props.momentTo.format('YYYY')

        let day = moment(`01-01-${year}`, 'DD-MM-YYYY')
        day.add(week.value - 1, 'weeks')

        const momentFrom = moment(day).startOf('week')
        const momentTo = moment(day).endOf('week')

        this.props.onChange(momentFrom, momentTo)
    }

    render() {
        const year = this.props.momentTo.year()
        const thisWeek = this.props.momentTo.week()

        const day = moment(`01-01-${year}`, 'DD-MM-YYYY')
        const weekNumber = day.weeksInYear()
        const options = []

        for (let index = 1; index <= weekNumber; index++) {
            const weekStart = moment(day).startOf('week')
            const weekEnd = moment(day).endOf('week')
            if (weekEnd.year() === year) {
                options.push({
                    value: index,
                    label:
                        this.props.intl.formatMessage({ id: 'modules.periodPicke.week' }) +
                        ' ' +
                        weekStart.format('w: D.M') +
                        ' - ' +
                        weekEnd.format('D.M'),
                })
            }
            day.add(1, 'weeks')
        }

        return (
            <div className={`datetime-selection ${this.props.className}`}>
                <label htmlFor={this.props.id} className="wp-100 display-inline">
                    <FormattedMessage id="modules.periodPicke.week" />
                </label>
                <Select value={thisWeek} options={options} onChange={this.onChange} clearable={false} />
            </div>
        )
    }
}

export default injectIntl(WeekSelector)
