// Fetch list

export const FETCH = 'reportTrailerResults.FETCH'
export const FETCH_STARTED = 'reportTrailerResults.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportTrailerResults.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportTrailerResults.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportTrailerResults.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportTrailerResults.CLEAR'

// Export

export const EXPORT = 'reportTrailerResults.EXPORT'
export const EXPORT_FULFILLED = 'reportTrailerResults.EXPORT_FULFILLED'

// Fetch details

export const FETCH_DETAILS = 'reportTrailerResults.FETCH_DETAILS'
export const FETCH_DETAILS_STARTED = 'reportTrailerResults.FETCH_DETAILS_STARTED'
export const FETCH_DETAILS_FULFILLED = 'reportTrailerResults.FETCH_DETAILS_FULFILLED'
export const FETCH_DETAILS_REJECTED = 'reportTrailerResults.FETCH_DETAILS_REJECTED'
export const FETCH_DETAILS_CANCELLED = 'reportTrailerResults.FETCH_DETAILS_CANCELLED'

// Clear details

export const CLEAR_DETAILS = 'reportTrailerResults.CLEAR_DETAILS'

// Export details

export const EXPORT_DETAILS = 'reportTrailerResults.EXPORT_DETAILS'
export const EXPORT_DETAILS_FULFILLED = 'reportTrailerResults.EXPORT_DETAILS_FULFILLED'
