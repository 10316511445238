import * as actionTypes from './actionTypes'

// Fetch

export const fetchInvoicingReceivedInvoices = filters => ({
    type: actionTypes.FETCH,
    payload: {
        filters,
    },
})

export const fetchInvoicingReceivedInvoicesFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: {
        data,
    },
})

export const fetchInvoicingReceivedInvoicesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchInvoicingReceivedInvoicesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearInvoicingReceivedInvoices = () => ({
    type: actionTypes.CLEAR,
})

export const clearInvoicingReceivedInvoice = () => ({
    type: actionTypes.CLEAR_CURRENT,
})

// Fetch one

export const fetchInvoicingReceivedInvoice = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchInvoicingReceivedInvoiceFulfilled = (receivedInvoice, supportData) => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
    payload: {
        receivedInvoice,
        supportData,
    },
})

export const fetchInvoicingReceivedInvoiceRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchInvoicingReceivedInvoiceCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveInvoicingReceivedInvoice = (values, ignoreDuplicity) => ({
    type: actionTypes.SAVE,
    payload: {
        values,
        ignoreDuplicity,
    },
})

export const saveInvoicingReceivedInvoiceFulfilled = (receivedInvoice, supportData) => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: {
        receivedInvoice,
        supportData,
    },
})

export const saveInvoicingReceivedInvoiceRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

export const saveInvoicingReceivedInvoiceDuplicityFound = duplicity => ({
    type: actionTypes.SAVE_DUPLICITY_FOUND,
    payload: duplicity,
})

// Delete

export const deleteReceivedInvoice = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteReceivedInvoiceFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteReceivedInvoiceRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Restore

export const restoreReceivedInvoice = id => ({
    type: actionTypes.RESTORE,
    payload: id,
})

export const restoreReceivedInvoiceFulfilled = item => ({
    type: actionTypes.RESTORE_FULFILLED,
    payload: item,
})

export const restoreReceivedInvoiceRejected = message => ({
    type: actionTypes.RESTORE_REJECTED,
    payload: message,
})

// Export

export const exportInvoicingReceivedInvoices = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})

// Fetch detail

export const fetchReceivedInvoiceDetail = receivedInvoiceId => ({
    type: actionTypes.FETCH_DETAIL,
    payload: {
        receivedInvoiceId,
    },
})

export const fetchReceivedInvoiceDetailFulfilled = data => ({
    type: actionTypes.FETCH_DETAIL_FULFILLED,
    payload: {
        data,
    },
})

export const fetchReceivedInvoiceDetailRejected = message => ({
    type: actionTypes.FETCH_DETAIL_REJECTED,
    payload: message,
})

export const fetchReceivedInvoiceDetailCancelled = () => ({
    type: actionTypes.FETCH_DETAIL_CANCELLED,
})

// Load supplier info

export const loadSupplierInfo = supplierId => ({
    type: actionTypes.LOAD_SUPPLIER_INFO,
    payload: {
        supplierId,
    },
})

export const loadSupplierInfoFulfilled = data => ({
    type: actionTypes.LOAD_SUPPLIER_INFO_FULFILLED,
    payload: {
        data,
    },
})

export const loadSupplierInfoRejected = message => ({
    type: actionTypes.LOAD_SUPPLIER_INFO_REJECTED,
    payload: message,
})

export const loadSupplierInfoCancelled = () => ({
    type: actionTypes.LOAD_SUPPLIER_INFO_CANCELLED,
})

export const clearSupplierInfo = () => ({
    type: actionTypes.CLEAR_SUPPLIER_INFO,
})

// Approve

export const approveReceivedInvoice = id => ({
    type: actionTypes.APPROVE,
    payload: id,
})

export const approveReceivedInvoiceFulfilled = data => ({
    type: actionTypes.APPROVE_FULFILLED,
    payload: {
        data,
    },
})

export const approveReceivedInvoiceRejected = message => ({
    type: actionTypes.APPROVE_REJECTED,
    payload: message,
})

// Set as paid

export const setReceivedInvoiceAsPaid = (id, date) => ({
    type: actionTypes.SET_AS_PAID,
    payload: {
        id,
        date,
    },
})

export const setReceivedInvoiceAsPaidFulfilled = data => ({
    type: actionTypes.SET_AS_PAID_FULFILLED,
    payload: {
        data,
    },
})

export const setReceivedInvoiceAsPaidRejected = message => ({
    type: actionTypes.SET_AS_PAID_REJECTED,
    payload: message,
})

// Set as unpaid

export const setReceivedInvoiceAsUnpaid = id => ({
    type: actionTypes.SET_AS_UNPAID,
    payload: {
        id,
    },
})

export const setReceivedInvoiceAsUnpaidFulfilled = data => ({
    type: actionTypes.SET_AS_UNPAID_FULFILLED,
    payload: {
        data,
    },
})

export const setReceivedInvoiceAsUnpaidRejected = message => ({
    type: actionTypes.SET_AS_UNPAID_REJECTED,
    payload: message,
})

// Load transport info

export const loadTransportInfo = transportId => ({
    type: actionTypes.LOAD_TRANSPORT_INFO,
    payload: {
        transportId,
    },
})

export const loadTransportInfoFulfilled = data => ({
    type: actionTypes.LOAD_TRANSPORT_INFO_FULFILLED,
    payload: {
        data,
    },
})

export const loadTransportInfoRejected = message => ({
    type: actionTypes.LOAD_TRANSPORT_INFO_REJECTED,
    payload: message,
})

export const loadTransportInfoCancelled = () => ({
    type: actionTypes.LOAD_TRANSPORT_INFO_CANCELLED,
})

export const clearTransportInfo = () => ({
    type: actionTypes.CLEAR_TRANSPORT_INFO,
})

// Create credit note

export const createCreditNote = invoiceId => ({
    type: actionTypes.CREATE_CREDIT_NOTE,
    payload: {
        invoiceId,
    },
})

export const createCreditNoteFulfilled = invoice => ({
    type: actionTypes.CREATE_CREDIT_NOTE_FULFILLED,
    payload: {
        invoice,
    },
})

export const createCreditNoteRejected = message => ({
    type: actionTypes.CREATE_CREDIT_NOTE_REJECTED,
    payload: message,
})

export const createCreditNoteCancelled = () => ({
    type: actionTypes.CREATE_CREDIT_NOTE_CANCELLED,
})

// Fetch files

export const fetchReceivedInvoiceFiles = id => ({
    type: actionTypes.FETCH_FILES,
    payload: id,
})

export const fetchReceivedInvoiceFilesFulfilled = files => ({
    type: actionTypes.FETCH_FILES_FULFILLED,
    payload: files,
})

export const fetchReceivedInvoiceFilesRejected = message => ({
    type: actionTypes.FETCH_FILES_REJECTED,
    payload: message,
})

export const fetchReceivedInvoiceFilesCancelled = () => ({
    type: actionTypes.FETCH_FILES_CANCELLED,
})

// Upload files

export const uploadReceivedInvoiceFiles = (id, files) => ({
    type: actionTypes.UPLOAD_FILES,
    payload: {
        id,
        files,
    },
})

export const uploadReceivedInvoiceFilesFulfilled = files => ({
    type: actionTypes.UPLOAD_FILES_FULFILLED,
    payload: files,
})

export const uploadReceivedInvoiceFilesRejected = message => ({
    type: actionTypes.UPLOAD_FILES_REJECTED,
    payload: message,
})

export const uploadReceivedInvoiceFilesCancelled = () => ({
    type: actionTypes.UPLOAD_FILES_CANCELLED,
})

// Delete file

export const deleteReceivedInvoiceFile = (id, file_id) => ({
    type: actionTypes.DELETE_FILE,
    payload: {
        id,
        file_id,
    },
})

export const deleteReceivedInvoiceFileFulfilled = files => ({
    type: actionTypes.DELETE_FILE_FULFILLED,
    payload: files,
})

export const deleteReceivedInvoiceFileRejected = message => ({
    type: actionTypes.DELETE_FILE_REJECTED,
    payload: message,
})

export const deleteReceivedInvoiceFileCancelled = () => ({
    type: actionTypes.DELETE_FILE_CANCELLED,
})

// Export to accounting

export const exportInvoicingReceivedInvoicesToAccounting = () => ({
    type: actionTypes.EXPORT_TO_ACCOUNTING,
})

export const exportInvoicingReceivedInvoicesToAccountingFulfilled = invoice => ({
    type: actionTypes.EXPORT_TO_ACCOUNTING_FULFILLED,
    payload: {
        invoice,
    },
})

export const exportInvoicingReceivedInvoicesToAccountingRejected = message => ({
    type: actionTypes.EXPORT_TO_ACCOUNTING_REJECTED,
    payload: message,
})

export const exportInvoicingReceivedInvoicesToAccountingCancelled = () => ({
    type: actionTypes.EXPORT_TO_ACCOUNTING_CANCELLED,
})

// Check duplicity

export const checkReceivedInvoiceDuplicity = values => ({
    type: actionTypes.CHECK_DUPLICITY,
    payload: values,
})

export const checkReceivedInvoiceDuplicityFulfilled = data => ({
    type: actionTypes.CHECK_DUPLICITY_FULFILLED,
    payload: data,
})

export const checkReceivedInvoiceDuplicityRejected = message => ({
    type: actionTypes.CHECK_DUPLICITY_REJECTED,
    payload: message,
})

export const checkReceivedInvoiceDuplicityCancelled = () => ({
    type: actionTypes.CHECK_DUPLICITY_CANCELLED,
})
