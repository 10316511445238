import { createSelector } from 'reselect'

export const getData = state => state.reportTransportInvoicing.getIn(['all', 'objects'])
export const createGetDataSelector = () =>
    createSelector(
        [getData],
        data => data
    )

export const isSetTransportPricesInProgress = state => state.transports.getIn(['setTransportPrices', 'inProgress'])
export const getSetTransportPricesError = state => state.transports.getIn(['setTransportPrices', 'error'])