import { createSelector } from 'reselect'

export const getEquipments = state => state.equipments.getIn(['all', 'objects'])

export const createGetEquipmentByIDSelector = id => createSelector([getEquipments], equipments => equipments.get(String(id)))

const getCurrentEquipmentStatus = state => state.equipments.get('current')

export const createGetCurrentEquipmentStatusSelector = () => createSelector([getCurrentEquipmentStatus], status => status)

export const getEquipmentDeleting = state => state.equipments.getIn(['deletion', 'inProgress'])

export const getEquipmentDeleteError = state => state.equipments.getIn(['deletion', 'error'])
