import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import Select from 'react-select'
import moment from 'moment'

class MonthSelector extends Component {
    onChange = month => {
        const year = this.props.momentFrom.format('YYYY')
        const momentFrom = moment(`${month.value}-01-${year}`, 'MM-DD-YYYY')
        const momentTo = moment(momentFrom).endOf('month')

        this.props.onChange(momentFrom, momentTo)
    }

    render() {
        const month = this.props.momentFrom.format('MM')
        const months = [...Array(12).keys()].map(i => `0${i + 1}`.slice(-2))

        const options = months.map(value => ({
            value,
            label: this.props.intl.formatMessage({ id: `monthNames.${value}` }),
        }))

        return (
            <div className={`datetime-selection ${this.props.className}`}>
                <label htmlFor={this.props.id} className="wp-100 display-inline">
                    <FormattedMessage id="modules.periodPicke.month" />
                </label>
                <Select value={month} options={options} onChange={this.onChange} clearable={false} />
            </div>
        )
    }
}

export default injectIntl(MonthSelector)
