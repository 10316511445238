import React from 'react'
import { Route, Switch } from 'react-router'
import TransportScoring from './TransportScoring'

const GoodsTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={TransportScoring} />
    </Switch>
)

export default GoodsTypes
