import { Record } from 'immutable'

export class DriverSurcharge extends Record({
    id: undefined,
    driver_id: undefined,
    driver_surcharge_type_id: undefined,
    currency_id: undefined,
    driver: undefined,
    driver_surcharge_type: undefined,
    currency: undefined,
    date: undefined,
    value: undefined,
    note: undefined,
}) {}
