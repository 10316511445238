import { Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: [],
        fetching: false,
        error: null,
    }),
    setTransportPrices: Map({
        inProgress: false,
        error: null,
    }),
})

function reportTransportInvoicing(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload.data)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)

        // Clear
        case actionTypes.CLEAR:
            return state.setIn(['all', 'objects'], OrderedMap({}))

        default:
            return state

        // Set transport prices
        case actionTypes.SET_TRANSPORT_PRICES_STARTED:
            return state.setIn(['setTransportPrices', 'inProgress'], true)
        case actionTypes.SET_TRANSPORT_PRICES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['setTransportPrices', 'inProgress'], false)
                .setIn(['setTransportPrices', 'error'], null)
                .setIn(['all', 'objects'], action.payload.data)
            })
        case actionTypes.SET_TRANSPORT_PRICES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['setTransportPrices', 'inProgress'], false).setIn(['setTransportPrices', 'error'], action.payload)
            })
    }
}

export default reportTransportInvoicing
