import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError, DownloadRequest } from '../../common/api'
import { serviceOrderSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import * as customersActionCreators from '../customers/actionCreators'
import * as vehiclesActionCreators from '../vehicles/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import * as actionCreators from './actionCreators'
import * as actionTypes from './actionTypes'
import { ServiceOrder, ServiceOrderState } from './model'
import { Customer } from "../customers/model";
import { Vehicle } from "../vehicles/model";

// Fetch

const fetchServiceOrdersEpic = (action$, store) => {
    const tableIdentifier = 'service_orders_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: `service-orders/list`,
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(serviceOrderSchema)
                        )

                        const orders = normalizedEntitiesToRecordMap(normalizedData.entities.service_orders, ServiceOrder, normalizedData.result)
                        const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)
                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)
                        const states = normalizedEntitiesToRecordMap(normalizedData.entities.service_order_states, ServiceOrderState)

                        const observables = [
                            Observable.of(actionCreators.fetchServiceOrdersFulfilled(orders)),
                            Observable.of(customersActionCreators.fetchCustomersFulfilled(customers)),
                            Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                            Observable.of(actionCreators.fetchServiceOrderStatesFulfilled(states)),
                        ]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchServiceOrdersRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchServiceOrderEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().serviceOrders.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `service-orders/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, serviceOrderSchema)

                        const orders = normalizedEntitiesToRecordMap(normalizedData.entities.service_orders, ServiceOrder)
                        const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)
                        const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchServiceOrdersFulfilled(orders)),
                            Observable.of(actionCreators.fetchServiceOrderFulfilled()),
                            Observable.of(customersActionCreators.fetchCustomersFulfilled(customers)),
                            Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchServiceOrderRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveServiceOrderEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().serviceOrders.getIn(['current', 'saving']))
        .switchMap(action => {
            const values = { ...action.payload }

            let path = `service-orders`
            let method = 'POST'

            if (values.id) {
                path = `service-orders/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, serviceOrderSchema)

                        const serviceOrders = normalizedEntitiesToRecordMap(normalizedData.entities.service_orders, ServiceOrder)
                        const serviceOrder = serviceOrders.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveServiceOrderFulfilled(serviceOrder)))
                    })
                    .catch(error => Observable.of(actionCreators.saveServiceOrderRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteServiceOrderEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().serviceOrders.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `service-orders/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteServiceOrderFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteServiceOrderRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

// Export

const exportServiceOrdersEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        const sorting = JSON.stringify(action.payload.sorting)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `service-orders/export?filters=${filters}&sorting=${sorting}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Fetch job autocomplete

const fetchJobAutocompleteEpic = (action$, store) =>
    action$
    .ofType(actionTypes.FETCH_JOB_AUTOCOMPLETE)
    .filter(() => !store.getState().serviceOrders.getIn(['serviceJob', 'fetching']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_JOB_AUTOCOMPLETE_STARTED,
            }),
            request({
                path: `service-orders/job-autocomplete`,
                method: 'POST',
                body: action.payload
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchJobAutocompleteFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchJobAutocompleteRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_JOB_AUTOCOMPLETE_CANCELLED))
        )
    )

// Fetch material autocomplete

const fetchMaterialAutocompleteEpic = (action$, store) =>
    action$
    .ofType(actionTypes.FETCH_MATERIAL_AUTOCOMPLETE)
    .filter(() => !store.getState().serviceOrders.getIn(['serviceMaterial', 'fetching']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_MATERIAL_AUTOCOMPLETE_STARTED,
            }),
            request({
                path: `service-orders/material-autocomplete`,
                method: 'POST',
                body: action.payload
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchMaterialAutocompleteFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchMaterialAutocompleteRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_MATERIAL_AUTOCOMPLETE_CANCELLED))
        )
    )

// Fetch worker autocomplete

const fetchWorkerAutocompleteEpic = (action$, store) =>
    action$
    .ofType(actionTypes.FETCH_WORKER_AUTOCOMPLETE)
    .filter(() => !store.getState().serviceOrders.getIn(['serviceWorker', 'fetching']))
    .switchMap(action =>
        Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_WORKER_AUTOCOMPLETE_STARTED,
            }),
            request({
                path: `service-orders/worker-autocomplete`,
                method: 'POST',
                body: action.payload
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchWorkerAutocompleteFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchWorkerAutocompleteRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_WORKER_AUTOCOMPLETE_CANCELLED))
        )
    )

// Create issue

const createIssueEpic = (action$, store) =>
    action$
    .ofType(actionTypes.CREATE_ISSUE)
    .switchMap(action =>
        Observable.concat(
            request({
                path: `service-orders/${action.payload}/create-issue`,
                method: 'POST'
            })
            .switchMap(ajaxResponse => {
                const normalizedData = normalize(ajaxResponse.response, serviceOrderSchema)

                const orders = normalizedEntitiesToRecordMap(normalizedData.entities.service_orders, ServiceOrder)
                const customers = normalizedEntitiesToRecordMap(normalizedData.entities.customers, Customer)
                const vehicles = normalizedEntitiesToRecordMap(normalizedData.entities.vehicles, Vehicle)

                return Observable.concat(
                    Observable.of(actionCreators.fetchServiceOrdersFulfilled(orders)),
                    Observable.of(actionCreators.fetchServiceOrderFulfilled()),
                    Observable.of(customersActionCreators.fetchCustomersFulfilled(customers)),
                    Observable.of(vehiclesActionCreators.fetchVehiclesFulfilled(vehicles)),
                )
            })
            .catch(error => Observable.of(actionCreators.fetchWorkerAutocompleteRejected(parseAPIError(error))))
            .takeUntil(action$.ofType(actionTypes.FETCH_WORKER_AUTOCOMPLETE_CANCELLED))
        )
    )

export default [
    fetchServiceOrdersEpic,
    fetchServiceOrderEpic,
    saveServiceOrderEpic,
    deleteServiceOrderEpic,
    exportServiceOrdersEpic,
    fetchJobAutocompleteEpic,
    fetchMaterialAutocompleteEpic,
    fetchWorkerAutocompleteEpic,
    createIssueEpic
]
