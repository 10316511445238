import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'

import { Form, InputField, SelectField, CheckboxField, SubmitButton } from '../../../common/form'
import { url, handleCommonEditActions, validationMessage, getPrerequisitesArray } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as companyBankAccountsActionCreators from '../actionCreators'
import { createGetCompanyBankAccountByIDSelector, createGetCurrentCompanyBankAccountStatusSelector } from '../selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'company_bank_accounts_edit'
const prerequisites = ['companies', 'currencies']

class CompanyBankAccountsEditForm extends Component {
    defaultValues = {}

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave()
        })
    }

    handleSave = () => {
        this.props.actions.saveCompanyBankAccount(this.state.values)
    }

    successCallback = () => {
        this.props.successCallback ? this.props.successCallback() : this.props.redirect(url(this.props.match, 'company-bank-accounts'))
    }

    backCallback = () => {
        this.props.backCallback ? this.props.backCallback() : this.props.redirect(url(this.props.match, 'company-bank-accounts'))
    }

    componentDidMount() {
        this.props.companyBankAccountID && this.props.actions.fetchCompanyBankAccount(this.props.companyBankAccountID)
        this.props.actions.fetchPrerequisites()

        if (this.props.defaultValues) {
            this.setValues(this.props.defaultValues)
        }
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback)

        if (
            nextProps.companyBankAccount &&
            (JSON.stringify(nextProps.companyBankAccount) !== JSON.stringify(this.props.companyBankAccount) || !this.state.values.id)
        ) {
            this.setValues(nextProps.companyBankAccount.toJS())
        }
    }

    render() {
        const perms = this.props.PERMS ? this.props.PERMS : PERMS
        return (
            <div>
                <LoadingOverlay
                    active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching') || this.props.status.get('saving')}
                >
                    <PermissionsCheck hasAny={Object.values(perms)}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                            isEdit={Boolean(this.props.companyBankAccount)}
                        >
                            <div className={this.props.hiddenFields && this.props.hiddenFields.indexOf('company_id') !== -1 ? 'hidden' : ''}>
                                <SelectField
                                    id="company_id"
                                    label={this.props.intl.formatMessage({ id: 'fields.company' })}
                                    values={getPrerequisitesArray(this.props.prerequisites.values.get('companies'))}
                                />
                            </div>
                            <InputField id="name" label={this.props.intl.formatMessage({ id: 'fields.name' })} />
                            <div className="row">
                                <div className="col-md-6">
                                    <InputField
                                        id="number"
                                        label={this.props.intl.formatMessage({ id: 'fields.bankAccountNumber' })}
                                        formGroupClassName="form-group-bigger"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        id="bank_code"
                                        label={this.props.intl.formatMessage({ id: 'fields.bankCode' })}
                                        formGroupClassName="form-group-bigger"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <InputField id="iban" label={this.props.intl.formatMessage({ id: 'fields.bankAccountIBAN' })} />
                                </div>
                                <div className="col-md-6">
                                    <InputField id="swift" label={this.props.intl.formatMessage({ id: 'fields.bankAccountSWIFT' })} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <SelectField
                                        id="currency_id"
                                        label={this.props.intl.formatMessage({ id: 'fields.currency' })}
                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('currencies'), 'id', 'iso_code')}
                                    />
                                </div>
                            </div>

                            <CheckboxField
                                id="is_factoring"
                                label={this.props.intl.formatMessage({ id: 'fields.factoring' })}
                                className="p-t-5"
                            />
                            {Boolean(this.state.values.is_factoring) && (
                                <div className="row">
                                    <div className="col-md-6">
                                        <InputField
                                            id="factoring_company_name"
                                            label={this.props.intl.formatMessage({ id: 'fields.factoringCompanyName' })} />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            id="factoring_company_reg_number"
                                            label={this.props.intl.formatMessage({ id: 'fields.factoringCompanyRegNumber' })} />
                                    </div>
                                </div>

                            )}

                            <InputField id="note" label={this.props.intl.formatMessage({ id: 'fields.note' })} />

                            <div className="btns-form">
                                <button onClick={this.backCallback} className="btn btn-addon btn-default">
                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                </button>
                                <SubmitButton
                                    perms={Object.values(perms)}
                                    isEdit={Boolean(this.props.companyBankAccount)}
                                    className="btn-addon pull-right"
                                />
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        companyBankAccount: createGetCompanyBankAccountByIDSelector(ownProps.companyBankAccountID)(state),
        status: createGetCurrentCompanyBankAccountStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...companyBankAccountsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CompanyBankAccountsEditForm)
)
