import * as actionTypes from './actionTypes'

// Fetch

export const fetchInvoiceItemTypes = () => ({
    type: actionTypes.FETCH,
})

export const fetchInvoiceItemTypesFulfilled = invoiceItemTypes => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: invoiceItemTypes,
})

export const fetchInvoiceItemTypesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchInvoiceItemTypesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchInvoiceItemType = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchInvoiceItemTypeFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchInvoiceItemTypeRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchInvoiceItemTypeCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveInvoiceItemType = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveInvoiceItemTypeFulfilled = invoiceItemType => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: invoiceItemType,
})

export const saveInvoiceItemTypeRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteInvoiceItemType = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteInvoiceItemTypeFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteInvoiceItemTypeRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})

// Export

export const exportInvoiceItemTypes = (filters, sorting) => ({
    type: actionTypes.EXPORT,
    payload: {
        filters,
        sorting,
    },
})
