// Fetch list

export const FETCH = 'reportSpeditionTransports.FETCH'
export const FETCH_STARTED = 'reportSpeditionTransports.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportSpeditionTransports.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportSpeditionTransports.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportSpeditionTransports.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportSpeditionTransports.CLEAR'

// Export

export const EXPORT = 'reportSpeditionTransports.EXPORT'
export const EXPORT_FULFILLED = 'reportSpeditionTransports.EXPORT_FULFILLED'

// Export details

export const EXPORT_DETAILS = 'reportSpeditionTransports.EXPORT_DETAILS'
export const EXPORT_DETAILS_FULFILLED = 'reportSpeditionTransports.EXPORT_DETAILS_FULFILLED'
