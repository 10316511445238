export const PERMS_TRANSPORTS = {
    READ: 'general_settings_transports.read',
    UPDATE: 'general_settings_transports.update',
}

export const PERMS_EMAIL = {
    READ: 'general_settings_email.read',
    UPDATE: 'general_settings_email.update',
}

export const PERMS_SECURITY = {
    READ: 'general_settings_security.read',
    UPDATE: 'general_settings_security.update',
}
