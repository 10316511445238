import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import TextareaAutosize from 'react-autosize-textarea'

import ChatMessage from './ChatMessage'
import * as vehicleMessagesActionCreators from '../actionCreators'
import { getSelectedVehicle, getSendMessageInProgress, getSendMessageError, getMessages } from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class Chat extends Component {
    state = {
        newMessage: '',
    }

    chatMessagesContainer = null

    handleReturnToSearch = e => {
        e.preventDefault()

        this.props.actions.deselectVehicle()
    }

    handleSendMessage = e => {
        e.preventDefault()

        this.props.actions.sendMessage(this.props.vehicle.id, this.state.newMessage)
        this.setState({ newMessage: '' })
    }

    handleNewMessageChange = e => {
        this.setState({ newMessage: e.target.value })
    }

    scrollToBottom = () => {
        this.chatMessagesContainer.scrollTop = this.chatMessagesContainer.scrollHeight
    }

    componentDidMount() {
        this.scrollToBottom()
    }

    componentWillReceiveProps(nextProps) {
        // Send error
        if (this.props.sendInProgress && !nextProps.sendInProgress && nextProps.sendError !== null) {
            this.props.notify(
                {
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: nextProps.sendError,
                    position: 'tc',
                },
                'error'
            )
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.messages) !== JSON.stringify(this.props.messages)) {
            this.scrollToBottom()
        }
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        return (
            <React.Fragment>
                <div className="chat-header">
                    <h3>
                        <span className="pull-left">
                            <span>
                                <FormattedMessage id="messages.headline" />:
                            </span>{' '}
                            <b>{this.props.vehicle.registration}</b>
                        </span>
                        <a href="#" onClick={this.handleReturnToSearch} className="pull-right">
                            <i className="far fa-times" />
                        </a>
                    </h3>
                </div>
                <div className="scrollable wp-100 chat" ref={ref => (this.chatMessagesContainer = ref)}>
                    <div className="scrollable-in">
                        {this.props.messages && this.props.messages.map(message => <ChatMessage key={`message-${message.id}`} message={message} />)}
                    </div>
                </div>
                <PermissionsCheck hasAny={[PERMS.WRITE, PERMS.UPDATE]}>
                    <div className="chat-write wp-100">
                        <form className="form-horizontal" onSubmit={this.handleSendMessage}>
                            <TextareaAutosize
                                className="form-control"
                                placeholder={this.props.intl.formatMessage({ id: 'messages.writeMessage' })}
                                value={this.state.newMessage}
                                onChange={this.handleNewMessageChange}
                                maxRows={5}
                            />
                            <button type="submit" className="btn btn-success">
                                <i className="far fa-paper-plane" />
                            </button>
                        </form>
                    </div>
                </PermissionsCheck>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        vehicle: getSelectedVehicle(state),
        messages: getMessages(state),
        sendInProgress: getSendMessageInProgress(state),
        sendError: getSendMessageError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(vehicleMessagesActionCreators, dispatch),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Chat))
