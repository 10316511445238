import { createSelector } from 'reselect'

export const getSurchargeTypes = state => state.surchargeTypes.getIn(['all', 'objects'])

export const createGetSurchargeTypeByIDSelector = id => createSelector([getSurchargeTypes], surchargeTypes => surchargeTypes.get(String(id)))

const getCurrentSurchargeTypeStatus = state => state.surchargeTypes.get('current')

export const createGetCurrentSurchargeTypeStatusSelector = () => createSelector([getCurrentSurchargeTypeStatus], status => status)

export const getSurchargeTypeDeleting = state => state.surchargeTypes.getIn(['deletion', 'inProgress'])

export const getSurchargeTypeDeleteError = state => state.surchargeTypes.getIn(['deletion', 'error'])
