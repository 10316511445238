import { createSelector } from 'reselect'
import {getUserRoles} from "../user_roles/selectors";

export const getVehiclePositions = state => state.vehiclePositions.getIn(['all', 'objects'])


export const createGetVehiclePositionByIDSelector = id => createSelector([getVehiclePositions], vehiclePositions => vehiclePositions.get(String(id)))

export const getVehiclePositionsFetching = state => state.vehiclePositions.getIn(['all', 'fetching'])
export const getVehiclePositionDeleting = state => state.vehiclePositions.getIn(['deletion', 'inProgress'])

export const getVehiclePositionDeleteError = state => state.vehiclePositions.getIn(['deletion', 'error'])
