import { createSelector } from 'reselect'

export const getTrailerEquipments = state => state.trailerEquipments.getIn(['all', 'objects'])

export const createGetTrailerEquipmentByIDSelector = id =>
    createSelector([getTrailerEquipments], trailerEquipments => trailerEquipments.get(String(id)))

const getCurrentTrailerEquipmentStatus = state => state.trailerEquipments.get('current')

export const createGetCurrentTrailerEquipmentStatusSelector = () => createSelector([getCurrentTrailerEquipmentStatus], status => status)

export const getTrailerEquipmentsFetching = state => state.trailerEquipments.getIn(['all', 'fetching'])

export const getTrailerEquipmentDeleting = state => state.trailerEquipments.getIn(['deletion', 'inProgress'])

export const getTrailerEquipmentDeleteError = state => state.trailerEquipments.getIn(['deletion', 'error'])

const getDuplicity = state => state.trailerEquipments.getIn(['all', 'duplicity'])

export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)
