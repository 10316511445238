import { Map, List, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
    }),
    importFiles: OrderedMap({}),
    current: Map({
        saving: false,
        error: null,
    }),
    deletion: Map({
        inProgress: false,
        error: null,
    }),
    import: Map({
        inProgress: false,
        error: null,
        file_id: null,
        headers: List(),
        headersDefault: Map(),
        values: List(),
        valuesDefault: Map(),
        fuelingCompanies: List(),
        showFuelingCompanies: false,
        selectedFuelingCompany: null,
        formats: Map(),
        examples: Map(),
        preview: OrderedMap(),
        description: null,
    }),
    importProducts: Map({
        inProgress: false,
        error: null,
        file_id: null,
        headers: List(),
        preview: OrderedMap(),
    }),
    products: Map({
        fetching: false,
        error: null,
        objects: OrderedMap(),
    }),
    imports: Map({
        inProgress: false,
        error: null,
        objects: OrderedMap(),
    }),
    deletionImport: Map({
        inProgress: false,
        error: null,
    }),
    country: Map({
        inProgress: false,
        error: null,
        value: null,
    }),
    driver: Map({
        inProgress: false,
        error: null,
        value: null,
    }),
})

function vehicleFuelings(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], action.payload)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.FETCH_IMPORT_FILES_FULFILLED:
            return state.set('importFiles', action.payload)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        // One
        case actionTypes.FETCH_ONE_STARTED:
            return state.setIn(['current', 'fetching'], true)
        case actionTypes.FETCH_ONE_FULFILLED:
        case actionTypes.FETCH_ONE_REJECTED:
        case actionTypes.FETCH_ONE_CANCELLED:
            return state.setIn(['current', 'fetching'], false)

        // New/edit
        case actionTypes.SAVE_STARTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], true).setIn(['current', 'error'], null)
            })
        case actionTypes.SAVE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false)
                    .setIn(['current', 'error'], null)
                    .setIn(['all', 'objects', String(action.payload.get('id'))], action.payload)
            })
        case actionTypes.SAVE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['current', 'saving'], false).setIn(['current', 'error'], action.payload)
            })

        // Delete
        case actionTypes.DELETE_STARTED:
            return state.setIn(['deletion', 'inProgress'], true)
        case actionTypes.DELETE_FULFILLED:
            return state.deleteIn(['all', 'objects', action.payload]).withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], null)
            })
        case actionTypes.DELETE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deletion', 'inProgress'], false).setIn(['deletion', 'error'], action.payload)
            })

        // Import headers
        case actionTypes.FETCH_IMPORT_HEADERS_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_HEADERS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
                map.setIn(['import', 'file_id'], action.payload.file_id)
                map.setIn(['import', 'headers'], action.payload.headers)
                map.setIn(['import', 'headersDefault'], action.payload.headers_default)
            })
        case actionTypes.FETCH_IMPORT_HEADERS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_HEADERS_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import values
        case actionTypes.FETCH_IMPORT_VALUES_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_VALUES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
                map.setIn(['import', 'values'], action.payload.values)
                map.setIn(['import', 'valuesDefault'], action.payload.values_default)
                map.setIn(['import', 'showFuelingCompanies'], action.payload.show_fueling_companies)
                map.setIn(['import', 'fuelingCompanies'], action.payload.fueling_companies)
                map.setIn(['import', 'selectedFuelingCompany'], action.payload.selected_fueling_company)
                map.setIn(['import', 'formats'], action.payload.formats)
                map.setIn(['import', 'examples'], action.payload.examples)
            })
        case actionTypes.FETCH_IMPORT_VALUES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_VALUES_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import preview
        case actionTypes.FETCH_IMPORT_PREVIEW_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_PREVIEW_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
                map.setIn(['import', 'preview'], action.payload.data)
                map.setIn(['import', 'description'], action.payload.description)
            })
        case actionTypes.FETCH_IMPORT_PREVIEW_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_PREVIEW_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import
        case actionTypes.IMPORT_STARTED:
            return state.setIn(['import', 'inProgress'], true)
        case actionTypes.IMPORT_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false)
                map.setIn(['import', 'error'], null)
            })
        case actionTypes.IMPORT_REJECTED:
            return state.withMutations(map => {
                map.setIn(['import', 'inProgress'], false).setIn(['import', 'error'], action.payload)
            })
        case actionTypes.IMPORT_CANCELLED:
            return state.setIn(['import', 'inProgress'], false)

        // Import products headers
        case actionTypes.FETCH_IMPORT_PRODUCTS_HEADERS_STARTED:
            return state.setIn(['importProducts', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_PRODUCTS_HEADERS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['importProducts', 'inProgress'], false)
                map.setIn(['importProducts', 'error'], null)
                map.setIn(['importProducts', 'file_id'], action.payload.file_id)
                map.setIn(['importProducts', 'headers'], action.payload.headers)
            })
        case actionTypes.FETCH_IMPORT_PRODUCTS_HEADERS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['importProducts', 'inProgress'], false).setIn(['importProducts', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_PRODUCTS_HEADERS_CANCELLED:
            return state.setIn(['importProducts', 'inProgress'], false)

        // Import products preview
        case actionTypes.FETCH_IMPORT_PRODUCTS_PREVIEW_STARTED:
            return state.setIn(['importProducts', 'inProgress'], true)
        case actionTypes.FETCH_IMPORT_PRODUCTS_PREVIEW_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['importProducts', 'inProgress'], false)
                map.setIn(['importProducts', 'error'], null)
                map.setIn(['importProducts', 'preview'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_PRODUCTS_PREVIEW_REJECTED:
            return state.withMutations(map => {
                map.setIn(['importProducts', 'inProgress'], false).setIn(['importProducts', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORT_PRODUCTS_PREVIEW_CANCELLED:
            return state.setIn(['importProducts', 'inProgress'], false)

        // Import products
        case actionTypes.IMPORT_PRODUCTS_STARTED:
            return state.setIn(['importProducts', 'inProgress'], true)
        case actionTypes.IMPORT_PRODUCTS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['importProducts', 'inProgress'], false)
                map.setIn(['importProducts', 'error'], null)
                map.setIn(['import', 'fuelingCompanies'], map.getIn(['import', 'fuelingCompanies']).push(action.payload))
            })
        case actionTypes.IMPORT_PRODUCTS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['importProducts', 'inProgress'], false).setIn(['importProducts', 'error'], action.payload)
            })
        case actionTypes.IMPORT_PRODUCTS_CANCELLED:
            return state.setIn(['importProducts', 'inProgress'], false)

        // Product list
        case actionTypes.FETCH_PRODUCTS_STARTED:
            return state.setIn(['products', 'inProgress'], true)
        case actionTypes.FETCH_PRODUCTS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['products', 'inProgress'], false)
                map.setIn(['products', 'error'], null)
                map.setIn(['products', 'objects'], action.payload)
            })
        case actionTypes.FETCH_PRODUCTS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['products', 'inProgress'], false).setIn(['products', 'error'], action.payload)
            })
        case actionTypes.FETCH_PRODUCTS_CANCELLED:
            return state.setIn(['products', 'inProgress'], false)

        // Import list
        case actionTypes.FETCH_IMPORTS_STARTED:
            return state.setIn(['imports', 'fetching'], true)
        case actionTypes.FETCH_IMPORTS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['imports', 'objects'], action.payload)
                    .setIn(['imports', 'fetching'], false)
                    .setIn(['imports', 'error'], null)
            })
        case actionTypes.FETCH_IMPORTS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['imports', 'fetching'], false).setIn(['imports', 'error'], action.payload)
            })
        case actionTypes.FETCH_IMPORTS_CANCELLED:
            return state.setIn(['imports', 'fetching'], false)

        // Delete import
        case actionTypes.DELETE_IMPORT_STARTED:
            return state.setIn(['deletionImport', 'inProgress'], true)
        case actionTypes.DELETE_IMPORT_FULFILLED:
            return state.deleteIn(['imports', 'objects', action.payload]).withMutations(map => {
                map.setIn(['deletionImport', 'inProgress'], false).setIn(['deletionImport', 'error'], null)
            })
        case actionTypes.DELETE_IMPORT_REJECTED:
            return state.withMutations(map => {
                map.setIn(['deletionImport', 'inProgress'], false).setIn(['deletionImport', 'error'], action.payload)
            })

        // Fetch country
        case actionTypes.FETCH_COUNTRY_STARTED:
            return state.setIn(['country', 'inProgress'], true)
        case actionTypes.FETCH_COUNTRY_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['country', 'inProgress'], false)
                map.setIn(['country', 'error'], null)
                map.setIn(['country', 'value'], action.payload)
            })
        case actionTypes.FETCH_COUNTRY_REJECTED:
            return state.withMutations(map => {
                map.setIn(['country', 'inProgress'], false).setIn(['country', 'error'], action.payload)
            })
        case actionTypes.FETCH_COUNTRY_CANCELLED:
            return state.setIn(['country', 'inProgress'], false)

        // Fetch driver
        case actionTypes.FETCH_DRIVER_STARTED:
            return state.setIn(['driver', 'inProgress'], true)
        case actionTypes.FETCH_DRIVER_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['driver', 'inProgress'], false)
                map.setIn(['driver', 'error'], null)
                map.setIn(['driver', 'value'], action.payload)
            })
        case actionTypes.FETCH_DRIVER_REJECTED:
            return state.withMutations(map => {
                map.setIn(['driver', 'inProgress'], false).setIn(['driver', 'error'], action.payload)
            })
        case actionTypes.FETCH_DRIVER_CANCELLED:
            return state.setIn(['driver', 'inProgress'], false)

        // Clear
        case actionTypes.CLEAR:
            return state.withMutations(map => {
                map.setIn(['all', 'objects'], OrderedMap({}))
            })
        case actionTypes.CLEAR_PRODUCTS:
            return state.withMutations(map => {
                map.setIn(['products', 'objects'], OrderedMap({}))
            })
        case actionTypes.CLEAR_IMPORT:
            return state.withMutations(map => {
                map.setIn(['import', 'error'], null)
                map.setIn(['import', 'file_id'], null)
                map.setIn(['import', 'headers'], List())
                map.setIn(['import', 'headersDefault'], Map())
                map.setIn(['import', 'values'], List())
                map.setIn(['import', 'valuesDefault'], Map())
                map.setIn(['import', 'fuelingCompanies'], List())
                map.setIn(['import', 'showFuelingCompanies'], false)
                map.setIn(['import', 'selectedFuelingCompany'], null)
                map.setIn(['import', 'formats'], Map())
                map.setIn(['import', 'examples'], Map())
                map.setIn(['import', 'preview'], OrderedMap())
                map.setIn(['import', 'description'], null)
            })
        case actionTypes.CLEAR_IMPORT_PRODUCTS:
            return state.withMutations(map => {
                map.setIn(['importProducts', 'error'], null)
                map.setIn(['importProducts', 'file_id'], null)
                map.setIn(['importProducts', 'headers'], List())
                map.setIn(['importProducts', 'preview'], OrderedMap())
            })

        default:
            return state
    }
}

export default vehicleFuelings
