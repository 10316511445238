import { createSelector } from 'reselect'

export const getTransportStates = state => state.transportStates.getIn(['all', 'objects'])

export const createGetTransportStateByIDSelector = id => createSelector([getTransportStates], transportStates => transportStates.get(String(id)))

const getCurrentTransportStateStatus = state => state.transportStates.get('current')

export const createGetCurrentTransportStateStatusSelector = () => createSelector([getCurrentTransportStateStatus], status => status)

export const getTransportStateDeleting = state => state.transportStates.getIn(['deletion', 'inProgress'])

export const getTransportStateDeleteError = state => state.transportStates.getIn(['deletion', 'error'])
