import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'

import * as config from '../../../common/config'

import { Form, InputField, SelectField, SubmitButton } from '../../../common/form'
import { url, handleCommonEditActions, validationMessage, getPrerequisitesArray } from '../../../common/helpers'
import { HEREMapAddressPicker } from '../../../common/here_maps'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as borderCrossingsActionCreators from '../actionCreators'
import {
    createGetBorderCrossingByIDSelector,
    createGetCurrentBorderCrossingStatusSelector,
    getFindCountries,
    createGetFindCountriesStatusSelector,
} from '../selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'border_crossings_edit'
const prerequisites = ['countries']

class BorderCrossingsEditForm extends Component {
    defaultValues = {}

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
        gps: {},
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.props.actions.saveBorderCrossing(Object.assign(this.state.values, this.state.gps))
        })
    }

    setGPS = coord => {
        this.setState({
            gps: coord,
        })
    }

    handleMarkerPositionChanged = coord => {
        this.setGPS({
            gps_lat: coord.lat,
            gps_lng: coord.lng,
        })

        this.props.actions.findCountries({ lat: coord.lat, lng: coord.lng })
    }

    successCallback = () => {
        this.props.successCallback ? this.props.successCallback() : this.props.redirect(url(this.props.match, 'border-crossings'))
    }

    componentDidMount() {
        this.props.borderCrossingID && this.props.actions.fetchBorderCrossing(this.props.borderCrossingID)
        this.props.actions.fetchPrerequisites()

        if (this.props.defaultValues) {
            this.setValues(this.props.defaultValues)
        }
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback, this.duplicityFoundCallback)

        if (
            nextProps.borderCrossing &&
            (JSON.stringify(nextProps.borderCrossing) !== JSON.stringify(this.props.borderCrossing) || !this.state.values.id)
        ) {
            this.setValues({
                ...nextProps.borderCrossing.toJS(),
            })

            if (nextProps.borderCrossing.gps_lat && nextProps.borderCrossing.gps_lng) {
                this.setGPS({
                    gps_lat: nextProps.borderCrossing.gps_lat,
                    gps_lng: nextProps.borderCrossing.gps_lng,
                })
            }
        } else if (nextProps.foundCountries && JSON.stringify(nextProps.foundCountries) !== JSON.stringify(this.props.foundCountries)) {
            const values = this.state.values

            if (nextProps.foundCountries[0]) {
                values.country_id_1 = nextProps.foundCountries[0]
            }
            if (nextProps.foundCountries[1]) {
                values.country_id_2 = nextProps.foundCountries[1]
            }

            this.setValues({
                ...values,
            })
        }
    }

    render() {
        const gps = this.state.gps &&
            this.state.gps.gps_lat &&
            this.state.gps.gps_lat && {
                lat: this.state.gps.gps_lat,
                lng: this.state.gps.gps_lng,
            }

        const countries = getPrerequisitesArray(this.props.prerequisites.values.get('countries'), 'id', 'iso_code')

        return (
            <div>
                <LoadingOverlay active={this.props.status.get('fetching')}>
                    <PermissionsCheck hasAny={Object.values(PERMS)}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                            isEdit={Boolean(this.props.borderCrossing)}
                        >
                            <InputField
                                id="name"
                                label={this.props.intl.formatMessage({ id: 'fields.name' })}
                                formGroupClassName="form-group-bigger"
                            />

                            <LoadingOverlay active={this.props.foundCountriesInProgress.get('inProgress')}>
                                <SelectField id="country_id_1" label={this.props.intl.formatMessage({ id: 'fields.country' })} values={countries} />
                                <SelectField id="country_id_2" values={countries} />
                            </LoadingOverlay>

                            <div className="p-v-sm b-t">
                                <HEREMapAddressPicker
                                    appId={config.CFG_HERE_MAPS_APP_ID}
                                    appCode={config.CFG_HERE_MAPS_APP_CODE}
                                    markerCenter={gps}
                                    handleMarkerPositionChanged={this.handleMarkerPositionChanged}
                                    className="h-400"
                                />
                            </div>

                            <div className="btns-form">
                                <Link to={url(this.props.match, 'border-crossings')} className="btn btn-addon btn-default">
                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                </Link>
                                <SubmitButton
                                    perms={Object.values(PERMS)}
                                    isEdit={Boolean(this.props.borderCrossing)}
                                    className="btn-addon pull-right"
                                />
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        borderCrossing: createGetBorderCrossingByIDSelector(ownProps.borderCrossingID)(state),
        status: createGetCurrentBorderCrossingStatusSelector()(state),
        createGetBorderCrossingByIDSelector: id => createGetBorderCrossingByIDSelector(id)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        foundCountries: getFindCountries(state),
        foundCountriesInProgress: createGetFindCountriesStatusSelector()(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...borderCrossingsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(BorderCrossingsEditForm)
)
