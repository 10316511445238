import { Observable } from 'rxjs'
import { normalize, schema } from 'normalizr'

import { request, parseAPIError } from '../../common/api'
import { costTypeSchema } from '../../common/normalizrSchemas'
import { normalizedEntitiesToRecordMap } from '../../common/helpers'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import * as tableModelActionCreators from '../../common/table/actionCreators'
import { resolveModelState } from '../../common/table/helpers'
import { CostType } from './model'

// Fetch

const fetchCostTypesEpic = (action$, store) => {
    const tableIdentifier = 'cost_types_list'
    const serverSide = true

    const triggeringAction = serverSide
        ? action$.ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        : action$.ofType(actionTypes.FETCH)

    return triggeringAction
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'GET',
                path: 'cost-types/list',
            }

            if (serverSide) {
                requestParams.method = 'POST'
                requestParams.path += `?page=${modelState.getIn(['pagination', 'current']) + 1}`
                requestParams.body = {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                }
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(
                            serverSide ? ajaxResponse.response.data : ajaxResponse.response,
                            new schema.Array(costTypeSchema)
                        )

                        const costTypes = normalizedEntitiesToRecordMap(normalizedData.entities.cost_types, CostType, normalizedData.result)

                        const observables = [Observable.of(actionCreators.fetchCostTypesFulfilled(costTypes))]

                        serverSide &&
                            observables.push(
                                Observable.of(
                                    tableModelActionCreators.updatePagination(
                                        tableIdentifier,
                                        ajaxResponse.response.last_page,
                                        ajaxResponse.response.current_page - 1,
                                        ajaxResponse.response.total
                                    )
                                )
                            )

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchCostTypesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Fetch one

const fetchCostTypeEpic = (action$, store) =>
    action$
        .ofType(actionTypes.FETCH_ONE)
        .filter(() => !store.getState().costTypes.getIn(['current', 'fetching']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_ONE_STARTED,
                }),
                request({
                    path: `cost-types/${action.payload}`,
                    method: 'GET',
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, costTypeSchema)

                        const costTypes = normalizedEntitiesToRecordMap(normalizedData.entities.cost_types, CostType)

                        return Observable.concat(
                            Observable.of(actionCreators.fetchCostTypesFulfilled(costTypes)),
                            Observable.of(actionCreators.fetchCostTypeFulfilled())
                        )
                    })
                    .catch(error => Observable.of(actionCreators.fetchCostTypesRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_ONE_CANCELLED))
            )
        )

// Save

const saveCostTypeEpic = (action$, store) =>
    action$
        .ofType(actionTypes.SAVE)
        .filter(() => !store.getState().costTypes.getIn(['current', 'saving']))
        .switchMap(action => {
            // eslint-disable-next-line prefer-object-spread
            const values = Object.assign({}, action.payload)

            let path = 'cost-types'
            let method = 'POST'

            if (values.id) {
                path = `cost-types/${values.id}`
                method = 'PUT'

                delete values.id
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.SAVE_STARTED,
                }),
                request({
                    path,
                    method,
                    body: values,
                })
                    .switchMap(ajaxResponse => {
                        const normalizedData = normalize(ajaxResponse.response, costTypeSchema)

                        const costTypes = normalizedEntitiesToRecordMap(normalizedData.entities.cost_types, CostType)
                        const costType = costTypes.valueSeq().first()

                        return Observable.concat(Observable.of(actionCreators.saveCostTypeFulfilled(costType)))
                    })
                    .catch(error => Observable.of(actionCreators.saveCostTypeRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.SAVE_CANCELLED))
            )
        })

// Delete

const deleteCostTypeEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DELETE)
        .filter(() => !store.getState().costTypes.getIn(['deletion', 'inProgress']))
        .switchMap(action =>
            Observable.concat(
                Observable.of({
                    type: actionTypes.DELETE_STARTED,
                }),
                request({
                    path: `cost-types/${action.payload}`,
                    method: 'DELETE',
                })
                    .switchMap(() => Observable.of(actionCreators.deleteCostTypeFulfilled(action.payload)))
                    .catch(error => Observable.of(actionCreators.deleteCostTypeRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DELETE_CANCELLED))
            )
        )

export default [fetchCostTypesEpic, fetchCostTypeEpic, saveCostTypeEpic, deleteCostTypeEpic]
