import { createSelector } from 'reselect'

export const getDrivers = state => state.drivers.getIn(['all', 'objects'])

export const createGetDriverByIDSelector = id => createSelector([getDrivers], drivers => drivers.get(String(id)))

const getCurrentDriverStatus = state => state.drivers.get('current')
export const createGetCurrentDriverStatusSelector = () => createSelector([getCurrentDriverStatus], status => status)

export const getDriverDeleting = state => state.drivers.getIn(['deletion', 'inProgress'])
export const getDriverDeleteError = state => state.drivers.getIn(['deletion', 'error'])

const getDuplicities = state => state.drivers.getIn(['all', 'duplicities'])
export const createGetDuplicitiesSelector = () => createSelector([getDuplicities], duplicities => duplicities)

export const getDriverHistoryData = state => state.drivers.getIn(['history', 'data'])
export const getDriverHistoryFetching = state => state.drivers.getIn(['history', 'fetching'])
export const getDriverHistoryError = state => state.drivers.getIn(['history', 'error'])

export const getDriverDocumentsData = state => state.drivers.getIn(['documents', 'data'])
export const getDriverDocumentsFetching = state => state.drivers.getIn(['documents', 'fetching'])
export const getDriverDocumentsError = state => state.drivers.getIn(['documents', 'error'])

export const getDriverEquipmentsData = state => state.drivers.getIn(['equipments', 'data'])
export const getDriverEquipmentsFetching = state => state.drivers.getIn(['equipments', 'fetching'])
export const getDriverEquipmentsError = state => state.drivers.getIn(['equipments', 'error'])

export const getDriverEventsData = state => state.drivers.getIn(['events', 'data'])
export const getDriverEventsFetching = state => state.drivers.getIn(['events', 'fetching'])
export const getDriverEventsError = state => state.drivers.getIn(['events', 'error'])

export const getDriverTimesheetsData = state => state.drivers.getIn(['timesheets', 'data'])
export const getDriverTimesheetsFetching = state => state.drivers.getIn(['timesheets', 'fetching'])
export const getDriverTimesheetsError = state => state.drivers.getIn(['timesheets', 'error'])

export const getDriverAllowancesData = state => state.drivers.getIn(['allowances', 'data'])
export const getDriverAllowancesFetching = state => state.drivers.getIn(['allowances', 'fetching'])
export const getDriverAllowancesSaving = state => state.drivers.getIn(['allowances', 'saving'])
export const getDriverAllowancesDeleting = state => state.drivers.getIn(['allowances', 'deleting'])
export const getDriverAllowancesError = state => state.drivers.getIn(['allowances', 'error'])

// Import

export const getImportInProgress = state => state.drivers.getIn(['import', 'inProgress'])
export const getImportError = state => state.drivers.getIn(['import', 'error'])
export const getImportFileId = state => state.drivers.getIn(['import', 'file_id'])
export const getImportHeaders = state => state.drivers.getIn(['import', 'headers'])
export const getImportDefaultHeaders = state => state.drivers.getIn(['import', 'headersDefault'])
export const getImportItems = state => state.drivers.getIn(['import', 'items'])
