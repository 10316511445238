import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportDispatcherKPI = filters => ({
    type: actionTypes.FETCH,
    payload: filters,
})

export const fetchReportDispatcherKPIFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportDispatcherKPIRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportDispatcherKPICancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Clear

export const clearReportDispatcherKPI = () => ({
    type: actionTypes.CLEAR,
})

// Export

export const exportReportDispatcherKPI = filters => ({
    type: actionTypes.EXPORT,
    payload: filters,
})