import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'
import moment from 'moment'

class QuarterSelector extends Component {
    onChange = quarter => {
        const year = this.props.momentFrom.format('YYYY')

        const startQuarter = quarter.value * 3 + 1
        const endQuarter = (quarter.value + 1) * 3

        const momentFrom = moment(`${startQuarter}-01-${year}`, 'MM-DD-YYYY')
        const momentTo = moment(`${endQuarter}-01-${year}`, 'MM-DD-YYYY').endOf('month')

        this.props.onChange(momentFrom, momentTo)
    }

    render() {
        const options = [
            {
                value: 0,
                label: 'I',
            },
            {
                value: 1,
                label: 'II',
            },
            {
                value: 2,
                label: 'III',
            },
            {
                value: 3,
                label: 'IV',
            },
        ]

        const quarter = this.props.momentFrom.format('Q') - 1

        return (
            <div className={`datetime-selection ${this.props.className}`}>
                <label htmlFor={this.props.id} className="wp-100 display-inline">
                    <FormattedMessage id="modules.periodPicke.quarter" />
                </label>
                <Select value={quarter} options={options} onChange={this.onChange} clearable={false} />
            </div>
        )
    }
}

export default QuarterSelector
