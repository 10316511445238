import React from 'react'
import { Route, Switch } from 'react-router'
import DriverTimesheetsList from './DriverTimesheetsList'
import DriverTimesheetsEdit from './DriverTimesheetsEdit'

const DriverTimesheets = props => (
    <Switch>
        <Route exact path={props.match.url} component={DriverTimesheetsList} />
        <Route exact path={`${props.match.url}/new`} component={DriverTimesheetsEdit} />
        <Route exact path={`${props.match.url}/:driverTimesheetID`} component={DriverTimesheetsEdit} />
    </Switch>
)

export default DriverTimesheets
