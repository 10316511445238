import React, { Component } from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import { injectIntl } from 'react-intl'
import scriptLoader from 'react-async-script-loader'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import * as config from '../../../common/config'

const DragHandle = SortableHandle(({ id, onClick, isActive }) => (
    <span className="input-group-btn">
        <button className={`btn ${isActive ? 'btn-danger' : 'btn-dark'}`} onClick={e => onClick(e)}>
            <span>{id}</span>
        </button>
    </span>
))

class TracingPoint extends Component {
    handleOnChange = value => {
        this.props.onChange(value)
    }

    handleOnEnterKeyDown = value => {
        this.props.onSubmit(value)
    }

    handleOnSelect = value => {
        geocodeByAddress(value)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.props.onSelect(latLng, value)
            })
    }

    handleOnClick = e => {
        e && e.preventDefault()
        this.props.onSelectMarker()
    }

    handleOnClickTrash = e => {
        e && e.preventDefault()
        this.props.onDelete()
    }

    handleOnClickAddressPicker = e => {
        e && e.preventDefault()
        this.props.openAddressesPicker()
    }

    render() {
        const placesAutocompleteStyles = {
            autocompleteItemActive: {
                backgroundColor: '#f6f6f6',
            },
            googleLogoImage: {
                width: 100,
            },
        }

        return (
            <div className="form-group">
                {this.props.isScriptLoaded && this.props.isScriptLoadSucceed && (
                    <div className="input-group">
                        <DragHandle id={this.props.id} onClick={this.handleOnClick} isActive={this.props.isActive} />
                        <PlacesAutocomplete
                            inputProps={{
                                value: this.props.tracingPoint.address,
                                onChange: this.handleOnChange,
                            }}
                            onSelect={this.handleOnSelect}
                            styles={placesAutocompleteStyles}
                            onEnterKeyDown={this.handleOnEnterKeyDown}
                            id="places_autocomplete"
                        />
                        <span className="input-group-btn">
                            <button type="button" className="btn btn-default" onClick={this.handleOnClickAddressPicker}>
                                <i className="far fa-search" />
                            </button>
                            <button type="button" className="btn btn-default" onClick={this.handleOnClickTrash}>
                                <i className="far fa-trash text-danger mr-0" />
                            </button>
                        </span>
                    </div>
                )}
            </div>
        )
    }
}

export default injectIntl(scriptLoader(`https://maps.googleapis.com/maps/api/js?key=${config.CFG_GOOGLE_API_KEY}&libraries=places`)(TracingPoint))
