import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'

import * as formatters from '../../../common/formatters'
import { handleCommonListActions, getListItemsCountMessage, getPrerequisitesArray } from '../../../common/helpers'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableSortButtons, TableFilterInputField, TableFilterSelectField } from '../../../common/table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../../common/table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableDataSelector, createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as usersLoginsActionCreators from '../actionCreators'
import { getUserLogins } from '../selectors'
import { createGetCurrencyByIDSelector } from '../../currencies/selectors'
import { createGetUserByIDSelector } from '../../users/selectors'

import UserLoginsListRow from './UserLoginsListRow'
import PermissionsCheck from '../../auth/components/PermissionsCheck'

import { PERMS } from '../permissions'

const tableIdentifier = 'user_logins_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'user_logins_list'
const prerequisites = ['users']

const columns = {
    first_name: new TableModelColumn({}),
}

class UserLoginsList extends Component {
    handleExport = e => {
        e && e.preventDefault()
        this.props.actions.exportUserLogins(this.props.tableModel.get('filters').toJS(), this.props.tableModel.get('sorting').toJS())
    }

    handleChangeThreeWaySwitchFilter = (e, identifier) => {
        const filters = this.props.tableModel.get('filters')
        const currentValue = filters.getIn([identifier, 'value']) || 0
        const switchOptions = {
            0: 1,
            1: 2,
            2: 0,
        }

        this.props.table.changeFilter(identifier, TableModelColumnFilteringType.equal, switchOptions[currentValue])
    }

    refresh = () => {
        this.props.actions.fetchUserLogins()
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        this.props.actions.fetchUserLogins()
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)
    }

    /* eslint-disable jsx-a11y/anchor-is-valid */
    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.userLogins.heading' }))

        const sorting = this.props.tableModel.get('sorting')
        const filters = this.props.tableModel.get('filters')

        const threeWaySwitchOptions = {
            0: 'fa-filter text-gray-lighter',
            1: 'fa-check text-success',
            2: 'fa-times text-danger',
        }

        const filterIconFilesClassName = threeWaySwitchOptions[filters.getIn(['login_success', 'value']) || 0]

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <h4>
                                    <FormattedMessage id="modules.userLogins.heading" />
                                </h4>

                                <div className="btns-list">
                                    {this.isFilterActive() > 0 && (
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                            <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                        </button>
                                    )}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="w-30" />
                                                <th className="w-120">
                                                    <FormattedMessage id="fields.dateTime" />
                                                    <TableSortButtons
                                                        identifier="datetime"
                                                        sorting={sorting}
                                                        onChange={this.props.table.changeSorting}
                                                    />
                                                </th>
                                                <th className="w-240">
                                                    <FormattedMessage id="fields.email" />
                                                    <TableSortButtons
                                                        identifier="login"
                                                        sorting={sorting}
                                                        onChange={this.props.table.changeSorting}
                                                    />
                                                </th>
                                                <th className="w-240">
                                                    <FormattedMessage id="fields.user" />
                                                    <TableSortButtons
                                                        identifier="user_id"
                                                        sorting={sorting}
                                                        onChange={this.props.table.changeSorting}
                                                    />
                                                </th>
                                                <th className="w-140">
                                                    <FormattedMessage id="fields.ipAddress" />
                                                    <TableSortButtons
                                                        identifier="ip_address"
                                                        sorting={sorting}
                                                        onChange={this.props.table.changeSorting}
                                                    />
                                                </th>
                                                <th className="w-max wm-200">
                                                    <FormattedMessage id="fields.userAgent" />
                                                    <TableSortButtons
                                                        identifier="user_agent"
                                                        sorting={sorting}
                                                        onChange={this.props.table.changeSorting}
                                                    />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="w-30 text-center">
                                                    <a
                                                        href="#"
                                                        className="m-t-xxs display-inline p-h-xxs"
                                                        onClick={e => {
                                                            this.handleChangeThreeWaySwitchFilter(e, 'login_success')
                                                        }}
                                                    >
                                                        <i className={`fas ${filterIconFilesClassName}`} />
                                                    </a>
                                                </th>
                                                <th className="w-120">
                                                    <div className="w-110">
                                                        <TableFilterInputField
                                                            identifier="datetime_from"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.from' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                    <div className="m-t-xxs w-110">
                                                        <TableFilterInputField
                                                            identifier="datetime_to"
                                                            type={TableModelColumnFilteringType.string}
                                                            filters={filters}
                                                            onChange={this.props.table.changeFilter}
                                                            placeholder={this.props.intl.formatMessage({ id: 'fields.to' })}
                                                            datepicker
                                                        />
                                                    </div>
                                                </th>
                                                <th className="w-240">
                                                    <TableFilterInputField
                                                        identifier="login"
                                                        type={TableModelColumnFilteringType.stringFromStart}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-240">
                                                    <TableFilterSelectField
                                                        identifier="user_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('users'))}
                                                        prompt="-"
                                                    />
                                                </th>
                                                <th className="w-140">
                                                    <TableFilterInputField
                                                        identifier="ip_address"
                                                        type={TableModelColumnFilteringType.stringFromStart}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-max wm-200">
                                                    <TableFilterInputField
                                                        identifier="user_agent"
                                                        type={TableModelColumnFilteringType.stringFromStart}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.userLogins.data.valueSeq().map(row => (
                                                <UserLoginsListRow
                                                    key={`row-${row.id}`}
                                                    data={row}
                                                    user={this.props.createGetUserByIDSelector(row.get('user_id'))}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pull-left m-l-xxs m-t-md">
                                    <FormattedMessage id="pagination.totalRecords" />:{' '}
                                    {getListItemsCountMessage(
                                        clientSideItemsPerPage,
                                        this.props.userLogins.count,
                                        this.props.tableModel.getIn(['pagination', 'totalCount'])
                                    )}
                                </div>

                                <ReactPaginate
                                    containerClassName="pagination"
                                    breakLabel={<span className="disabled">...</span>}
                                    activeClassName="active"
                                    pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.userLogins.count, clientSideItemsPerPage)}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={2}
                                    forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                                    onPageChange={this.props.table.changePage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state) {
    return {
        userLogins: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getUserLogins,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        createGetUserByIDSelector: id => createGetUserByIDSelector(id)(state),
        createGetCurrencyByIDSelector: id => createGetCurrencyByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...usersLoginsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(UserLoginsList)
)
