import { createSelector } from 'reselect'

export const getData = state => state.invoicingReceivedInvoices.getIn(['all', 'objects'])
export const createGetDataSelector = () =>
    createSelector(
        [getData],
        data => data
    )

const getDuplicities = state => state.invoicingReceivedInvoices.getIn(['all', 'duplicities'])
export const createGetDuplicitiesSelector = () =>
    createSelector(
        [getDuplicities],
        duplicities => duplicities
    )

export const getCurrentReceivedInvoice = state => state.invoicingReceivedInvoices.getIn(['current', 'object'])
export const getCurrentSupportData = state => state.invoicingReceivedInvoices.getIn(['current', 'supportData'])
export const getCurrentReceivedInvoiceFetching = state => state.invoicingReceivedInvoices.getIn(['current', 'fetching'])
export const getCurrentReceivedInvoiceSaving = state => state.invoicingReceivedInvoices.getIn(['current', 'saving'])
export const getCurrentReceivedInvoiceError = state => state.invoicingReceivedInvoices.getIn(['current', 'error'])

export const getReceivedInvoiceDeleting = state => state.invoicingReceivedInvoices.getIn(['deleting', 'inProgress'])
export const getReceivedInvoiceDeletingError = state => state.invoicingReceivedInvoices.getIn(['deleting', 'error'])

export const getReceivedInvoiceDetail = state => state.invoicingReceivedInvoices.getIn(['detail', 'data'])
export const getReceivedInvoiceDetailFetching = state => state.invoicingReceivedInvoices.getIn(['detail', 'fetching'])
export const getReceivedInvoiceDetailError = state => state.invoicingReceivedInvoices.getIn(['detail', 'error'])

export const getSupplierInfo = state => state.invoicingReceivedInvoices.getIn(['supplierInfo', 'data'])
export const getSupplierInfoFetching = state => state.invoicingReceivedInvoices.getIn(['supplierInfo', 'fetching'])
export const getSupplierInfoError = state => state.invoicingReceivedInvoices.getIn(['supplierInfo', 'error'])

export const getReceivedInvoiceApproveInProgress = state => state.invoicingReceivedInvoices.getIn(['approve', 'inProgress'])
export const getReceivedInvoiceApproveError = state => state.invoicingReceivedInvoices.getIn(['approve', 'error'])

export const getReceivedInvoiceSetAsPaidInProgress = state => state.invoicingReceivedInvoices.getIn(['setAsPaid', 'inProgress'])
export const getReceivedInvoiceSetAsPaidError = state => state.invoicingReceivedInvoices.getIn(['setAsPaid', 'error'])

export const getReceivedInvoiceSetAsUnpaidInProgress = state => state.invoicingReceivedInvoices.getIn(['setAsUnpaid', 'inProgress'])
export const getReceivedInvoiceSetAsUnpaidError = state => state.invoicingReceivedInvoices.getIn(['setAsUnpaid', 'error'])

export const getTransportInfo = state => state.invoicingReceivedInvoices.getIn(['transportInfo', 'data'])
export const getTransportInfoFetching = state => state.invoicingReceivedInvoices.getIn(['transportInfo', 'fetching'])
export const getTransportInfoError = state => state.invoicingReceivedInvoices.getIn(['transportInfo', 'error'])

export const getCreateCreditNote = state => state.invoicingReceivedInvoices.getIn(['createCreditNote', 'data'])
export const getCreateCreditNoteFetching = state => state.invoicingReceivedInvoices.getIn(['createCreditNote', 'fetching'])
export const getCreateCreditNoteError = state => state.invoicingReceivedInvoices.getIn(['createCreditNote', 'error'])

export const getReceivedInvoiceFiles = state => state.invoicingReceivedInvoices.getIn(['files', 'objects'])
export const getReceivedInvoiceFilesFetching = state => state.invoicingReceivedInvoices.getIn(['files', 'fetching'])
export const getReceivedInvoiceFilesSaving = state => state.invoicingReceivedInvoices.getIn(['files', 'saving'])
export const getReceivedInvoiceFilesDeleting = state => state.invoicingReceivedInvoices.getIn(['files', 'deleting'])
export const getReceivedInvoiceFilesError = state => state.invoicingReceivedInvoices.getIn(['files', 'error'])

export const getExportToAccountingInProgress = state => state.invoicingReceivedInvoices.getIn(['exportToAccounting', 'inProgress'])
export const getExportToAccountingError = state => state.invoicingReceivedInvoices.getIn(['exportToAccounting', 'error'])

export const getReceivedInvoiceDuplicityChecking = state => state.invoicingReceivedInvoices.getIn(['checkingDuplicity', 'inProgress'])
export const getReceivedInvoiceDuplicityCheckingError = state => state.invoicingReceivedInvoices.getIn(['checkingDuplicity', 'error'])
export const getReceivedInvoiceDuplicityCheckingData = state => state.invoicingReceivedInvoices.getIn(['checkingDuplicity', 'data'])
