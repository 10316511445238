import * as actionTypes from './actionTypes'

// Fetch

export const fetchVehiclePositions = () => ({
    type: actionTypes.FETCH,
})

export const fetchVehiclePositionsFulfilled = vehiclePositions => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: vehiclePositions,
})

export const fetchVehiclePositionsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchVehiclePositionsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Delete

export const deleteVehiclePosition = (id) => ({
    type: actionTypes.DELETE,
    payload: { id },
})

export const deleteVehiclePositionFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteVehiclePositionRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})
