import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import { Modal, Tabs, Tab } from 'react-bootstrap'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import * as formatters from '../../../common/formatters'
import { Form, InputField, SelectField, CheckboxField, SubmitButton } from '../../../common/form'
import {
    url,
    handleCommonEditActions,
    getPrerequisitesArray,
} from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as vehiclesActionCreators from '../actionCreators'
import { createGetVehicleByIDSelector, createGetCurrentVehicleStatusSelector, createGetDuplicitySelector } from '../selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

import * as constants from '../constants'

import VehiclesDocumentsList from './VehiclesDocumentsList'
import VehiclesEquipmentsList from './VehiclesEquipmentsList'
import VehiclesEventsList from './VehiclesEventsList'
import VehiclesHistoryList from './VehiclesHistoryList'
import VehiclesFuelingsList from './VehiclesFuelingsList'
import moment from "moment";
import * as helpers from "../../../common/helpers";
import {CustomDatePicker} from "../../../common/custom_date_picker";

const componentIdentifier = 'vehicles_edit'
const prerequisites = ['vehicleTypes', 'vehicleFleetboardConfigurations', 'users', 'companyCostCenters', 'carriers']

class VehiclesEdit extends Component {
    defaultValues = {
        is_spedition: false,
    }

    validationRules = {}

    state = {
        values: this.defaultValues,
        dates: {
            sold_at: null,
        },
        modalDuplicity: {
            show: false,
            duplicity: null,
        },
        vehicleHistory: {
            show: true,
        },
        vehicleDocuments: {
            show: false,
        },
        vehicleEquipments: {
            show: false,
        },
        vehicleEvents: {
            show: false,
        },
        vehicleFuelings: {
            show: false,
        },
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {},
            onAccept: () => {},
        },
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
                dates: {
                    sold_at: values.sold_at ? moment(values.sold_at, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''
                }
            },
            callback
        )
    }

    handleDateChange = (key, value) => {
        const dates = {
            ...this.state.dates,
            [key]: value,
        }

        this.setState({
            dates,
        })
    }

    handleChangeValues = values => {
        if (
            this.state.values.logistic_data_source_id !== values.logistic_data_source_id &&
            this.state.values.telematics_data_source_id === values.telematics_data_source_id &&
            !values.logistic_data_id
        ) {
            values.logistic_data_id = values.telematics_data_id
        }
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.save(false)
        })
    }

    save = ignoreDuplicity => {
        this.props.actions.saveVehicle({
            ...this.state.values,
            ...this.state.dates
        }, ignoreDuplicity)
    }

    duplicityFoundCallback = duplicity => {
        this.setState({
            modalDuplicity: {
                show: true,
                duplicity,
            },
        })
    }

    handleDuplicityCancel = () => {
        this.setState({
            modalDuplicity: {
                show: false,
                duplicity: null,
            },
        })
    }

    handleDuplicityConfirm = () => {
        this.handleDuplicityCancel()
        this.save(true)
    }

    handleSelectTab = tabKey => {
        if (tabKey === 1) {
            this.loadVehicleHistory()
        } else if (tabKey === 2) {
            this.loadVehicleDocuments()
        } else if (tabKey === 3) {
            this.loadVehicleEquipments()
        } else if (tabKey === 4) {
            this.loadVehicleEvents()
        } else if (tabKey === 5) {
            this.loadVehicleFuelings()
        }
    }

    loadVehicleHistory = () => {
        this.setState({
            vehicleHistory: {
                show: true,
            },
        })
    }

    loadVehicleDocuments = () => {
        this.setState({
            vehicleDocuments: {
                show: true,
            },
        })
    }

    loadVehicleEquipments = () => {
        this.setState({
            vehicleEquipments: {
                show: true,
            },
        })
    }

    loadVehicleEvents = () => {
        this.setState({
            vehicleEvents: {
                show: true,
            },
        })
    }

    loadVehicleFuelings = () => {
        this.setState({
            vehicleFuelings: {
                show: true,
            },
        })
    }

    successCallback = null

    handleSave = () => {
        this.successCallback = (props, nextProps) => {
            if (nextProps.status.get('id')) {
                props.redirect(url(props.match, `vehicles/${nextProps.status.get('id')}`))
            } else {
                props.redirect(url(props.match, 'vehicles'))
            }
        }

        this.save()
    }

    handleSaveAndClose = () => {
        this.successCallback = props => {
            props.redirect(url(props.match, 'vehicles'))
        }

        this.save()
    }

    // restore
    handleRestore = () => {
        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.itemRestore.accept' }),
            classNameAccept: 'btn-success',
            onAccept: () => {
                this.props.actions.restoreVehicle(this.props.vehicle.id)
                this.hideConfirmDialog()
            },
        })
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {},
                onAccept: () => {},
            },
        })
    }

    componentDidMount() {
        this.props.match.params.vehicleID && this.props.actions.fetchVehicle(this.props.match.params.vehicleID)
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback, this.duplicityFoundCallback)

        if (JSON.stringify(nextProps.vehicle) !== JSON.stringify(this.props.vehicle) || (nextProps.vehicle && !this.state.values.id)) {
            this.setValues(nextProps.vehicle.toJS())
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.vehicles.heading' }))

        const vehicleDataSourcesOptions = [
            {
                id: constants.VEHICLE_DATA_SOURCE_FB,
                name: this.props.intl.formatMessage({ id: `vehicleDataSource.${constants.VEHICLE_DATA_SOURCE_FB}` }),
            },
        ]

        const vehicle = this.props.vehicle
        const duplicity = this.props.duplicity

        const mainColClassName = vehicle ? 'col-lg-4' : 'col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4'

        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="row hp-100">
                            <div className={mainColClassName} style={{ paddingRight: '0' }}>
                                <div className="panel panel-white">
                                    <div className="panel-body panel-padding">
                                        <div className="panel-head">
                                            <h4>
                                                <FormattedMessage id="modules.vehicles.heading" /> -{' '}
                                                {this.props.vehicle ? (
                                                    <FormattedMessage id="fields.itemEdit" />
                                                ) : (
                                                    <FormattedMessage id="fields.itemCreate" />
                                                )}
                                            </h4>
                                        </div>
                                        <LoadingOverlay
                                            active={
                                                this.props.prerequisites.status.get('fetching') ||
                                                this.props.status.get('fetching') ||
                                                this.props.status.get('saving')
                                            }
                                        >
                                            <PermissionsCheck hasAny={Object.values(PERMS)}>
                                                <Form
                                                    values={this.state.values}
                                                    validationRules={this.validationRules}
                                                    onChange={this.handleChangeValues}
                                                    onSubmit={this.handleSubmit}
                                                    isEdit={Boolean(this.props.vehicle)}
                                                >
                                                    {vehicle && vehicle.deleted_at && (
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-danger m-b-lg">
                                                                    <div className="row">
                                                                        <div className="col-sm-8">
                                                                            <p className="no-m">
                                                                                <FormattedMessage id="alerts.messages.warningDeleted" />
                                                                            </p>
                                                                        </div>
                                                                        <PermissionsCheck has={[PERMS.RESTORE]}>
                                                                            <div className="col-sm-4 text-right">
                                                                                <button onClick={this.handleRestore} type="button" className="btn btn-danger">
                                                                                    <FormattedMessage id="buttons.restore" />
                                                                                </button>
                                                                            </div>
                                                                        </PermissionsCheck>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <InputField
                                                                id="registration"
                                                                label={this.props.intl.formatMessage({ id: 'fields.registration' })}
                                                                formGroupClassName="form-group-bigger"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <InputField id="year_of_manufacture" label={this.props.intl.formatMessage({ id: 'fields.yearOfManufacture' })} />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <label htmlFor="value_sold_at">
                                                                <FormattedMessage id="fields.soldAt" />
                                                            </label>
                                                            <CustomDatePicker
                                                                id="value_sold_at"
                                                                selected={helpers.convertCSDateToMoment(this.state.dates.sold_at)}
                                                                value={this.state.dates.sold_at}
                                                                onChange={value => {
                                                                    this.handleDateChange(
                                                                        'sold_at',
                                                                        value ? formatters.dateFormatter(value) : ''
                                                                    )
                                                                }}
                                                                onChangeRaw={e => {
                                                                    this.handleDateChange('sold_at', e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <InputField id="vin" label={this.props.intl.formatMessage({ id: 'fields.vin' })} />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <InputField id="phone" label={this.props.intl.formatMessage({ id: 'fields.phone' })} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <SelectField
                                                                id="vehicle_type_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.vehicleType' })}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('vehicleTypes'))}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <SelectField
                                                                id="fleetboard_configuration_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.fbConfiguration' })}
                                                                values={getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('vehicleFleetboardConfigurations')
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <SelectField
                                                                id="telematics_data_source_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.telematicsDataSource' })}
                                                                values={vehicleDataSourcesOptions}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <InputField
                                                                id="telematics_data_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.telematicsDataId' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <SelectField
                                                                id="logistic_data_source_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.logisticDataSource' })}
                                                                values={vehicleDataSourcesOptions}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <InputField
                                                                id="logistic_data_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.logisticDataId' })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <SelectField
                                                                id="dispatcher_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.dispatcher' })}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('users'))}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6 col-lg-12 col-xl-6">
                                                            <SelectField
                                                                id="company_cost_center_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.costCenter' })}
                                                                values={getPrerequisitesArray(
                                                                    this.props.prerequisites.values.get('companyCostCenters')
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <CheckboxField id="is_active" label={this.props.intl.formatMessage({ id: 'fields.active' })} />

                                                    <div className="form-part">
                                                        <CheckboxField
                                                            id="is_spedition"
                                                            label={this.props.intl.formatMessage({ id: 'fields.isSpedition' })}
                                                        />
                                                        {Boolean(this.state.values.is_spedition) && (
                                                            <SelectField
                                                                id="carrier_id"
                                                                label={this.props.intl.formatMessage({ id: 'fields.carrier' })}
                                                                values={getPrerequisitesArray(this.props.prerequisites.values.get('carriers'))}
                                                            />
                                                        )}
                                                    </div>

                                                    <div className="btns-form">
                                                        <Link to={url(this.props.match, 'vehicles')} className="btn btn-addon btn-default">
                                                            <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                                        </Link>
                                                        {vehicle && (
                                                            <SubmitButton
                                                                saveAndClose
                                                                onClick={this.handleSaveAndClose}
                                                                isEdit={Boolean(this.props.vehicle)}
                                                                perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                                className="btn-addon pull-right"
                                                            />
                                                        )}
                                                        <SubmitButton
                                                            type="button"
                                                            onClick={this.handleSave}
                                                            isEdit={Boolean(this.props.vehicle)}
                                                            perms={[PERMS.CREATE, PERMS.UPDATE]}
                                                            className="btn-addon pull-right m-r-xs"
                                                        />
                                                    </div>
                                                </Form>
                                            </PermissionsCheck>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                            </div>

                            {vehicle && (
                                <div className="col-lg-8 hp-100" style={{ paddingLeft: '5px' }}>
                                    <div className="panel panel-white hp-100 m-b-no">
                                        <div className="panel-body panel-padding hp-100">
                                            <Tabs
                                                defaultActiveKey={1}
                                                animation={false}
                                                id="detail-tabs"
                                                className="detail-tabs"
                                                onSelect={this.handleSelectTab}
                                            >
                                                <Tab
                                                    eventKey={1}
                                                    title={
                                                        <span>
                                                            <i className="far fa-history m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.history' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.vehicleHistory.show && <VehiclesHistoryList vehicle={vehicle} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={2}
                                                    title={
                                                        <span>
                                                            <i className="far fa-file-alt m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.documents' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.vehicleDocuments.show && <VehiclesDocumentsList vehicle={vehicle} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={3}
                                                    title={
                                                        <span>
                                                            <i className="far fa-wrench m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.equipment' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.vehicleEquipments.show && <VehiclesEquipmentsList vehicle={vehicle} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={4}
                                                    title={
                                                        <span>
                                                            <i className="far fa-calendar-alt m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.events' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.vehicleEvents.show && <VehiclesEventsList vehicle={vehicle} />}
                                                </Tab>
                                                <Tab
                                                    eventKey={5}
                                                    title={
                                                        <span>
                                                            <i className="far fa-calendar-alt m-r-xxs" />
                                                            {this.props.intl.formatMessage({ id: 'fields.fuelings' })}
                                                        </span>
                                                    }
                                                >
                                                    {this.state.vehicleFuelings.show && <VehiclesFuelingsList vehicle={vehicle} />}
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDuplicity.show)} onHide={this.handleDuplicityCancel} bsSize="sm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FormattedMessage id="confirmDialog.duplicityFound.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormattedMessage id="confirmDialog.duplicityFound.message" />
                            {duplicity && (
                                <div>
                                    <div className="row m-t-lg">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.registration" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.registration}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.vin" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.vin}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.phone" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.phone}</strong>
                                        </div>
                                    </div>
                                    <hr className="tiny" />
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <FormattedMessage id="fields.vehicleType" />:
                                        </div>
                                        <div className="col-sm-8 text-right">
                                            <strong>{duplicity.type}</strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <PermissionsCheck hasAny={[PERMS.CREATE, PERMS.UPDATE]}>
                                <button className="btn btn-success m-r-xs" onClick={this.handleDuplicityConfirm}>
                                    <FormattedMessage id="confirmDialog.duplicityFound.accept" />
                                </button>
                            </PermissionsCheck>
                            <button className="btn btn-default" onClick={this.handleDuplicityCancel}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <ConfirmDialog options={this.state.confirmDialog} />
            </PermissionsCheck>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        vehicle: createGetVehicleByIDSelector(ownProps.match.params.vehicleID)(state),
        status: createGetCurrentVehicleStatusSelector()(state),
        duplicity: createGetDuplicitySelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...vehiclesActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(VehiclesEdit)
)
