import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

import * as tableModelActionTypes from '../../common/table/actionTypes'
import { resolveModelState } from '../../common/table/helpers'

// Fetch

const fetchReportDriverSurchargeEpic = (action$, store) => {
    const tableIdentifier = 'report_driver_surcharges'

    return action$
        .ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request({
                    method: 'POST',
                    path: `report-driver-surcharges`,
                    body: {
                        filters: modelState.get('filters').toJS(),
                    },
                })
                    .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportDriverSurchargeFulfilled(ajaxResponse.response)))
                    .catch(error => Observable.of(actionCreators.fetchReportDriverSurchargeRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
            )
        })
}

// Driver note
const updateDriverNoteEpic = (action$, store) =>
    action$
        .ofType(actionTypes.DRIVER_NOTE_UPDATE)
        .filter(() => !store.getState().reportDriverSurcharge.getIn(['driver_note', 'saving']))
        .switchMap(action => {
            const requestParams = {
                method: 'POST',
                path: `report-driver-surcharges/update-driver-note`,
                body: action.payload,
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.DRIVER_NOTE_UPDATE_STARTED,
                }),
                request(requestParams)
                    .switchMap(() => Observable.concat(Observable.of(actionCreators.updateDriverNoteFulfilled())))
                    .catch(error => Observable.of(actionCreators.updateDriverNoteRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.DRIVER_NOTE_UPDATE_CANCELLED))
            )
        })

// Export

const exportReportDriverSurchargeEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-driver-surcharges/export?filters=${filters}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

// Fetch details

const fetchReportDriverSurchargeDetailsEpic = (action$, store) =>
    action$.ofType(actionTypes.FETCH_DETAILS).switchMap(action => {
        const tableIdentifier = 'report_driver_surcharges'
        const modelState = resolveModelState(tableIdentifier, store.getState(), action)

        return Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_DETAILS_STARTED,
            }),
            request({
                method: 'POST',
                path: `report-driver-surcharges/details`,
                body: {
                    driver_id: action.payload.driverId,
                    date_from: action.payload.dateFrom,
                    date_to: action.payload.dateTo,
                    driver_surcharge_type_id: action.payload.driverSurchargeTypeId,
                    filters: modelState.get('filters').toJS(),
                },
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportDriverSurchargeDetailsFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportDriverSurchargeDetailsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_DETAILS_CANCELLED))
        )
    })

// Export details

const exportReportDriverSurchargeDetailsEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT_DETAILS).switchMap(action => {
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-driver-surcharges/details/export?driver_id=${action.payload.driverId}&date_from=${action.payload.dateFrom}&date_to=${action.payload.dateTo}&driver_surcharge_type_id=${action.payload.driverSurchargeTypeId}&filters=${JSON.stringify({ ...action.payload.filters })}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_DETAILS_FULFILLED,
            })
        )
    })

export default [
    fetchReportDriverSurchargeEpic,
    updateDriverNoteEpic,
    exportReportDriverSurchargeEpic,
    fetchReportDriverSurchargeDetailsEpic,
    exportReportDriverSurchargeDetailsEpic,
]
