import React, { Component } from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as constants from '../constants'
import * as formatters from '../../../common/formatters'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

class InvoicingOrdersRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.onClick()
    }

    onClick = () => {
        this.props.onClick && this.props.onClick(this.props.item)
    }

    onEditButtonClick = () => {
        this.props.onEditButtonClick && this.props.onEditButtonClick(this.props.item)
    }

    onCreateCreditNoteButtonClick = () => {
        this.props.onCreateCreditNoteButtonClick && this.props.onCreateCreditNoteButtonClick(this.props.item)
    }

    onDeleteButtonClick = () => {
        this.props.onDeleteButtonClick && this.props.onDeleteButtonClick(this.props.item)
    }

    onDownloadPdfButtonClick = () => {
        this.props.onDownloadPdfButtonClick && this.props.onDownloadPdfButtonClick(this.props.item)
    }

    render() {
        const item = this.props.item

        if (!item) {
            return null
        }

        const company = item.company
        const customer = item.customer
        const customerAddress = item.customer_address
        const isCreditNote = item.type_id === constants.TYPE_CREDIT_NOTE

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-static-element-interactions */


        const classNames = ['row-selectable']
        if (item.deleted_at) {
            classNames.push('is-deleted')
        }

        return (
            <tr onClick={this.handleClick} className={classNames.join(' ')}>
                <td className="w-60 text-center">
                    <div onClick={e => e.stopPropagation()}>
                        <DropdownButton id={`menu-${item.id}`} className={'far fa-bars'} noCaret title="">
                            <MenuItem eventKey={1} onSelect={this.onClick}>
                                <i className="far fa-eye" />
                                <FormattedMessage id="buttons.showDetail" />
                            </MenuItem>
                            <PermissionsCheck has={[PERMS.UPDATE]}>
                                <MenuItem eventKey={2} onSelect={this.onEditButtonClick}>
                                    <i className="far fa-pencil" />
                                    <FormattedMessage id="buttons.edit" />
                                </MenuItem>
                            </PermissionsCheck>
                            <PermissionsCheck hasAny={[PERMS.CREATE, PERMS.UPDATE]}>
                                <MenuItem eventKey={3} onSelect={this.onCreateCreditNoteButtonClick} disabled={isCreditNote}>
                                    <i className="far fa-file-minus" />
                                    <FormattedMessage id="buttons.createCreditNote" />
                                </MenuItem>
                            </PermissionsCheck>
                            {!item.deleted_at && (
                                <PermissionsCheck has={[PERMS.DELETE]}>
                                    <MenuItem eventKey={4} onSelect={this.onDeleteButtonClick}>
                                        <i className="far fa-trash" />
                                        <FormattedMessage id="buttons.delete" />
                                    </MenuItem>
                                </PermissionsCheck>
                            )}
                            <PermissionsCheck has={[PERMS.DOWNLOAD]}>
                                <MenuItem eventKey={5} onSelect={this.onDownloadPdfButtonClick}>
                                    <i className="far fa-file-pdf" />
                                    <FormattedMessage id="buttons.download" />
                                </MenuItem>
                            </PermissionsCheck>
                        </DropdownButton>
                    </div>
                </td>
                <td className="w-120">
                    <strong>{item.number}</strong>
                    {item.imported_at && (
                        <React.Fragment>
                            <br />
                            <small className="text-success">
                                {formatters.dateFormatter(item.imported_at)}
                            </small>
                        </React.Fragment>
                    )}
                    {isCreditNote && (
                        <React.Fragment>
                            <br />
                            <small className="text-danger">
                                <FormattedMessage id="fields.creditNote" />
                            </small>
                        </React.Fragment>
                    )}
                </td>
                <td className="w-90 text-center">{formatters.dateFormatter(item.date_issued)}</td>
                <td className="w-90 text-center">
                    <strong>{formatters.dateFormatter(item.date_due)}</strong>
                </td>
                <td className="w-90 text-center">{formatters.dateFormatter(item.date_tax)}</td>
                <td className="w-300">
                    <strong>{company && company.name}</strong>
                    <br />
                    <small className="text-gray">
                        {company
                            ? formatters.address(company.street, company.city, company.zipcode, company.country ? company.country.iso_code : null)
                            : null}
                    </small>
                </td>
                <td className="w-160">{item.company_bank_account && (item.company_bank_account.number+'/'+item.company_bank_account.bank_code)}</td>
                <td className="w-100">{item.company_cost_center && item.company_cost_center.code}</td>
                <td className="w-max wm-300">
                    <strong>{customer && customer.name}</strong>
                    <br />
                    <small className="text-gray">
                        {customerAddress
                            ? formatters.address(
                                  customerAddress.street,
                                  customerAddress.city,
                                  customerAddress.zipcode,
                                  customerAddress.country ? customerAddress.country.iso_code : null
                              )
                            : null}
                    </small>
                </td>
                <td className="w-120 text-right">
                    <strong>
                        {formatters.priceFormatter(item.total && item.total.excl_vat, '0,0.00', item.currency ? item.currency.iso_code : '')}
                    </strong>
                    <br />
                    <small className="text-gray">{formatters.priceFormatter(item.total && item.total.excl_vat * item.currency_rate, '0,0.00')}</small>
                </td>
                <td className="w-120 text-right">
                    {formatters.priceFormatter(item.total && item.total.vat, '0,0.00', item.currency ? item.currency.iso_code : '')}
                    <br />
                    <small className="text-gray">{formatters.priceFormatter(item.total && item.total.vat * item.currency_rate, '0,0.00')}</small>
                </td>
                <td className="w-120 text-right">
                    {formatters.priceFormatter(item.total && item.total.incl_vat, '0,0.00', item.currency ? item.currency.iso_code : '')}
                    <br />
                    <small className="text-gray">{formatters.priceFormatter(item.total && item.total.incl_vat * item.currency_rate, '0,0.00')}</small>
                </td>
                <td className="w-120 text-right">
                    <span
                        className={
                            item.paid_amount >= (item.total && item.total.incl_vat ? item.total.incl_vat - 1 : 0) ? 'text-success' : 'text-danger'
                        }
                    >
                        {formatters.priceFormatter(item.paid_amount, '0,0.00', item.currency ? item.currency.iso_code : '')}
                    </span>
                    <br />
                    <small>{formatters.dateFormatter(item.paid_at)}</small>
                </td>
            </tr>
        )

        /* eslint-enable jsx-a11y/no-static-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

export default injectIntl(InvoicingOrdersRow)
