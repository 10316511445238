import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import classnames from 'classnames'
import { Modal } from 'react-bootstrap'

import { DispatchersBox } from '../../../common/colored_box'
import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'

import * as reportVehicleFuelingsActionCreators from '../actionCreators'
import { getData, getFetching, getDetailsData, getDetailsFetching } from '../selectors'

import {TableFilterCheckboxField, TableFilterInputField, TableFilterSelectField} from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'

import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const tableIdentifier = 'report_vehicle_fuelings'

const componentIdentifier = 'report_vehicle_fuelings'
const prerequisites = ['dispatchers', 'company_cost_centers']

class reportVehicleFuelings extends Component {
    state = {
        modalDetails: {
            show: false,
            vehicleId: null,
            dateFrom: null,
            dateTo: null,
        },
    }

    refresh = () => {
        this.props.actions.fetchReportVehicleFuelings(this.props.tableModel.get('filters'))
    }

    resetFilters = () => {
        const date = moment()

        this.props.table.resetFilters(
            [],
            [
                {
                    column: 'year',
                    type: TableModelColumnFilteringType.equal,
                    value: date.format('YYYY'),
                },
                {
                    column: 'month',
                    type: TableModelColumnFilteringType.equal,
                    value: date.format('MM'),
                },
            ]
        )
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportVehicleFuelings(this.props.tableModel.get('filters').toJS())
    }

    handleFilterChanged = (key, value) => {
        const filters = this.props.tableModel.get('filters')

        let year = filters.getIn(['year', 'value'])
        let month = filters.getIn(['month', 'value'])

        if (key === 'period') {
            year = value.year
            month = value.month ? value.month : ''
        }

        this.props.table.changeFilters([
            {
                column: 'year',
                type: TableModelColumnFilteringType.equal,
                value: year,
            },
            {
                column: 'month',
                type: TableModelColumnFilteringType.equal,
                value: month,
            },
        ])
    }

    // Detail
    handleShowDetails = (e, vehicleId, dateFrom, dateTo) => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: true,
                    vehicleId,
                    dateFrom,
                    dateTo,
                },
            },
            () => {
                this.props.actions.fetchReportVehicleFuelingDetails(vehicleId, dateFrom, dateTo)
            }
        )
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState(
            {
                modalDetails: {
                    show: false,
                    vehicleId: null,
                    dateFrom: null,
                    dateTo: null,
                },
            },
            () => {
                this.props.actions.clearReportVehicleFuelingDetails()
            }
        )
    }

    handleExportDetails = e => {
        e && e.preventDefault()

        this.props.actions.exportReportVehicleFuelingDetails(
            this.state.modalDetails.vehicleId,
            this.state.modalDetails.dateFrom,
            this.state.modalDetails.dateTo,
            this.props.tableModel.get('filters').toJS()
        )
    }

    componentDidMount() {
        const date = moment()

        this.props.table.setConfiguration(
            [
                {
                    column: 'year',
                    type: TableModelColumnFilteringType.equal,
                    value: date.format('YYYY'),
                },
                {
                    column: 'month',
                    type: TableModelColumnFilteringType.equal,
                    value: date.format('MM'),
                },
            ],
            false
        )

        this.props.actions.fetchReportVehicleFuelings(this.props.tableModel.get('filters'))
    }

    componentWillUnmount() {
        this.props.actions.clearReportVehicleFuelingDetails()
        this.props.actions.clearReportVehicleFuelings()
        this.props.actions.fetchPrerequisites()
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportVehicleFuelings.heading' }))

        const data = this.props.data
        const filters = this.props.tableModel.get('filters')

        const detailsData = this.props.detailsData

        const headers = []
        const totals = {
            haveData: false,
            fueling: 0,
            consumption: 0,
            km: 0,
        }

        const year = filters.getIn(['year', 'value'])
        const month = filters.getIn(['month', 'value'])
        const momentToday = moment()
        let momentFrom
        let momentTo
        let columnWidthClassName = 'w-50'

        if (month === '') {
            columnWidthClassName = 'w-80'

            momentFrom = moment(`${year}-01-01`, 'YYYY-MM-DD')
            momentTo = moment(momentFrom).endOf('year')

            const monthKeys = [...Array(12).keys()]
            monthKeys.forEach(monthKey => {
                const month = moment().set('month', monthKey)
                const id = month.format('MM')
                const name = this.props.intl.formatMessage({ id: `monthNames.${id}` })
                const from = `${year}-${id}-01`
                const to = moment(from, 'YYYY-MM-DD')
                    .endOf('month')
                    .format('YYYY-MM-DD')
                totals[id] = {
                    fueling: 0,
                    consumption: 0,
                    km: 0,
                }
                headers.push({
                    id,
                    name,
                    from,
                    to,
                })
            })
        } else {
            momentFrom = moment(`${year}-${month}-01`, 'YYYY-MM-DD')
            momentTo = moment(momentFrom).endOf('month')

            for (let i = 0; i <= momentTo.diff(momentFrom, 'days'); i++) {
                const momentDay = moment(momentFrom).add(i, 'days')
                const id = momentDay.format('DD/MM')
                const name = momentDay.format('DD/MM')
                const day = momentDay.format('YYYY-MM-DD')
                totals[id] = {
                    fueling: 0,
                    consumption: 0,
                    km: 0,
                }
                headers.push({
                    id,
                    name,
                    from: day,
                    to: day,
                    momentDay,
                })
            }
        }

        const stringISOFrom = momentFrom.format('YYYY-MM-DD')
        const stringISOTo = momentTo.format('YYYY-MM-DD')

        const detailsTotal = {
            duration: 0,
            distance: 0,
            consumption: 0,
            fueling: 0,
        }

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (
            <PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
                <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                    <div id="main-wrapper">
                        <div className="panel panel-white">
                            <div className="panel-body">
                                <div className="wp-100 pull-left m-b-xs">
                                    <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportVehicleFuelings.heading" />
                                        </span>
                                    </h4>
                                    <div className="pull-left" style={{ marginLeft: '100px' }}>
                                    <span className="pull-left text-gray f-bold" style={{ paddingTop: '10px' }}>
                                        <FormattedMessage id="fields.showIncl" />:
                                    </span>
                                        <div className="pull-left m-l-sm">
                                            <TableFilterCheckboxField
                                                identifier="with_empty"
                                                type={TableModelColumnFilteringType.bool}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                label={this.props.intl.formatMessage({ id: 'fields.showInclEmpty' })}
                                            />
                                        </div>
                                        <div className="pull-left m-l-sm">
                                            <TableFilterCheckboxField
                                                identifier="with_inactive"
                                                type={TableModelColumnFilteringType.bool}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                label={this.props.intl.formatMessage({ id: 'fields.showInclInactive' })}
                                            />
                                        </div>
                                    </div>
                                    <div className="btns-list">
                                        <PeriodPicker
                                            className={'m-r-sm'}
                                            momentFrom={momentFrom}
                                            momentTo={momentTo}
                                            onChange={(momentFrom, momentTo, format) => this.handleFilterChanged('period', format)}
                                            defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_MONTH}
                                            allowedPickers={[
                                                periodPickerConstants.PERIOD_PICKER_TYPE_MONTH,
                                                periodPickerConstants.PERIOD_PICKER_TYPE_YEAR,
                                            ]}
                                        />
                                        {this.isFilterActive() > 0 && (
                                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                            </button>
                                        )}
                                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                        </button>
                                        <PermissionsCheck has={[PERMS.EXPORT]}>
                                            <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        </PermissionsCheck>
                                    </div>
                                </div>

                                <div className="table-container">
                                    <table className="table table-striped table-hover table-fixed-header">
                                        <thead>
                                            <tr>
                                                <th className="wm-120 w-max">
                                                    <FormattedMessage id="fields.vehicle" />
                                                </th>
                                                <th className="w-100">
                                                    <FormattedMessage id="fields.dispatcher" />
                                                </th>
                                                <th className="w-80">
                                                    <FormattedMessage id="fields.costCenter" />
                                                </th>
                                                {headers.map(header => (
                                                    <th key={`day-th-${header.id}`} className={`${columnWidthClassName} text-center`}>
                                                        {header.name}
                                                    </th>
                                                ))}
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.totalDistance" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.odometerState" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.odometerDistance" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.totalConsumption" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.avgConsumption" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.fueling" />
                                                </th>
                                                <th className="w-100 text-right">
                                                    <FormattedMessage id="fields.difference" />
                                                </th>
                                            </tr>
                                            <tr className="filters">
                                                <th className="wm-120 w-max">
                                                    <TableFilterInputField
                                                        identifier="registration"
                                                        type={TableModelColumnFilteringType.string}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                    />
                                                </th>
                                                <th className="w-100">
                                                    <TableFilterSelectField
                                                        identifier="dispatcher_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('dispatchers'))}
                                                    />
                                                </th>
                                                <th className="w-80">
                                                    <TableFilterSelectField
                                                        identifier="company_cost_center_id"
                                                        type={TableModelColumnFilteringType.equal}
                                                        filters={filters}
                                                        onChange={this.props.table.changeFilter}
                                                        values={helpers.getPrerequisitesArray(
                                                            this.props.prerequisites.values.get('company_cost_centers')
                                                        )}
                                                    />
                                                </th>
                                                {headers.map(header => (
                                                    <th key={`day-th-filter-${header.name}`} className={`${columnWidthClassName} text-center`} />
                                                ))}
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                                <th className="w-100" />
                                            </tr>
                                        </thead>
                                        <tbody className="relative-td">
                                            {data &&
                                                data.vehicles &&
                                                data.vehicles.map(vehicle => {
                                                    const totalRow = {
                                                        haveData: false,
                                                        fueling: 0,
                                                        consumption: 0,
                                                        km: 0,
                                                    }

                                                    return (
                                                        <tr key={`vehicle-${vehicle.id}`}>
                                                            <td className="wm-120 w-max">
                                                                <DispatchersBox dispatcher={vehicle.dispatcher}>
                                                                    {vehicle.registration}
                                                                </DispatchersBox>
                                                            </td>
                                                            <td className="w-100">
                                                                {vehicle.dispatcher &&
                                                                    formatters.shortFullName(
                                                                        vehicle.dispatcher.first_name,
                                                                        vehicle.dispatcher.last_name
                                                                    )}
                                                            </td>
                                                            <td className="w-80">
                                                                {vehicle.company_cost_center && vehicle.company_cost_center.code}
                                                            </td>
                                                            {headers.map(header => {
                                                                const date = header.momentDay
                                                                const haveData = vehicle.data[header.id] !== undefined

                                                                const isWeekend = date ? date.isoWeekday() >= 6 : false
                                                                const isToday = date ? date.isSame(momentToday, 'day') : false
                                                                const isConsumption = haveData && vehicle.data[header.id].consumption > 0

                                                                const classes = classnames('text-center no-h-p', {
                                                                    'is-weekend': isWeekend,
                                                                    'is-today': isToday,
                                                                    columnWidthClassName,
                                                                })

                                                                if (haveData) {
                                                                    totalRow.haveData = true
                                                                    if (vehicle.data[header.id].fueling) {
                                                                        totalRow.fueling += vehicle.data[header.id].fueling
                                                                        totals[header.id].fueling += vehicle.data[header.id].fueling
                                                                    }
                                                                    if (vehicle.data[header.id].consumption) {
                                                                        totalRow.consumption += vehicle.data[header.id].consumption
                                                                        totals[header.id].consumption += vehicle.data[header.id].consumption
                                                                    }
                                                                    if (vehicle.data[header.id].km) {
                                                                        totalRow.km += vehicle.data[header.id].km
                                                                        totals[header.id].km += vehicle.data[header.id].km
                                                                    }
                                                                }

                                                                const role = haveData ? 'button' : undefined
                                                                const onClick = haveData
                                                                    ? e => {
                                                                          this.handleShowDetails(e, vehicle.id, header.from, header.to)
                                                                      }
                                                                    : undefined

                                                                return (
                                                                    <td
                                                                        key={`day-td-${header.name}`}
                                                                        className={classes}
                                                                        role={role}
                                                                        onClick={onClick}
                                                                    >
                                                                        {haveData && vehicle.data[header.id].fueling
                                                                            ? `+${formatters.numberFormatter(vehicle.data[header.id].fueling, '0,0')}`
                                                                            : '-'}
                                                                        <br />
                                                                        <small className={isConsumption ? 'text-danger' : 'text-default'}>
                                                                            {haveData && vehicle.data[header.id].consumption
                                                                                ? formatters.numberFormatter(
                                                                                      -vehicle.data[header.id].consumption,
                                                                                      '0,0'
                                                                                  )
                                                                                : '-'}
                                                                        </small>
                                                                    </td>
                                                                )
                                                            })}

                                                            <td
                                                                className="w-100 text-right"
                                                                role={totalRow.haveData ? 'button' : undefined}
                                                                onClick={
                                                                    totalRow.haveData
                                                                        ? e => {
                                                                              this.handleShowDetails(e, vehicle.id, stringISOFrom, stringISOTo)
                                                                          }
                                                                        : undefined
                                                                }
                                                            >
                                                                <strong>{totalRow.km === 0 ? '-' : formatters.distanceFormatter(totalRow.km)}</strong>
                                                            </td>
                                                            <td
                                                                className="w-100 text-right"
                                                                role={totalRow.haveData ? 'button' : undefined}
                                                                onClick={
                                                                    totalRow.haveData
                                                                        ? e => {
                                                                              this.handleShowDetails(e, vehicle.id, stringISOFrom, stringISOTo)
                                                                          }
                                                                        : undefined
                                                                }
                                                            >
                                                                <strong>
                                                                    {vehicle.odometer === undefined || vehicle.odometer === 0
                                                                        ? '-'
                                                                        : `${formatters.distanceFormatter(
                                                                              vehicle.odometer_start
                                                                          )} / ${formatters.distanceFormatter(vehicle.odometer_end)}`}
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className="w-100 text-right"
                                                                role={totalRow.haveData ? 'button' : undefined}
                                                                onClick={
                                                                    totalRow.haveData
                                                                        ? e => {
                                                                              this.handleShowDetails(e, vehicle.id, stringISOFrom, stringISOTo)
                                                                          }
                                                                        : undefined
                                                                }
                                                            >
                                                                <strong>
                                                                    {vehicle.odometer === undefined || vehicle.odometer === 0
                                                                        ? '-'
                                                                        : formatters.distanceFormatter(vehicle.odometer)}
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className="w-100 text-right"
                                                                role={totalRow.haveData ? 'button' : undefined}
                                                                onClick={
                                                                    totalRow.haveData
                                                                        ? e => {
                                                                              this.handleShowDetails(e, vehicle.id, stringISOFrom, stringISOTo)
                                                                          }
                                                                        : undefined
                                                                }
                                                            >
                                                                <strong>
                                                                    {totalRow.consumption === 0
                                                                        ? '-'
                                                                        : formatters.volumeFormatter(totalRow.consumption)}
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className="w-100 text-right"
                                                                role={totalRow.haveData ? 'button' : undefined}
                                                                onClick={
                                                                    totalRow.haveData
                                                                        ? e => {
                                                                              this.handleShowDetails(e, vehicle.id, stringISOFrom, stringISOTo)
                                                                          }
                                                                        : undefined
                                                                }
                                                            >
                                                                <strong>
                                                                    {totalRow.km === 0
                                                                        ? '-'
                                                                        : formatters.floatFormatter((totalRow.consumption / totalRow.km) * 100, 2)}
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className="w-100 text-right"
                                                                role={totalRow.haveData ? 'button' : undefined}
                                                                onClick={
                                                                    totalRow.haveData
                                                                        ? e => {
                                                                              this.handleShowDetails(e, vehicle.id, stringISOFrom, stringISOTo)
                                                                          }
                                                                        : undefined
                                                                }
                                                            >
                                                                <strong>
                                                                    {totalRow.fueling === 0 ? '-' : formatters.volumeFormatter(totalRow.fueling)}
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className="w-100 text-right"
                                                                role={totalRow.haveData ? 'button' : undefined}
                                                                onClick={
                                                                    totalRow.haveData
                                                                        ? e => {
                                                                              this.handleShowDetails(e, vehicle.id, stringISOFrom, stringISOTo)
                                                                          }
                                                                        : undefined
                                                                }
                                                            >
                                                                <strong>
                                                                    {totalRow.fueling - totalRow.consumption === 0
                                                                        ? '-'
                                                                        : formatters.volumeFormatter(totalRow.fueling - totalRow.consumption)}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            <tr className="b-top row-sum">
                                                <td className="wm-120 w-max" colSpan={3}>
                                                    Celkem
                                                </td>
                                                {headers.map(header => {
                                                    totals.fueling += totals[header.id].fueling
                                                    totals.consumption += totals[header.id].consumption
                                                    totals.km += totals[header.id].km

                                                    const haveData =
                                                        totals[header.id].fueling !== 0 ||
                                                        totals[header.id].consumption !== 0 ||
                                                        totals[header.id].km !== 0
                                                    if (haveData) {
                                                        totals.haveData = true
                                                    }

                                                    const role = haveData ? 'button' : undefined
                                                    const onClick = haveData
                                                        ? e => {
                                                              this.handleShowDetails(e, null, header.from, header.to)
                                                          }
                                                        : undefined
                                                    return (
                                                        <td
                                                            key={`day-td-total-${header.id}`}
                                                            className={`${columnWidthClassName} text-center no-h-p text-center`}
                                                            role={role}
                                                            onClick={onClick}
                                                        >
                                                            <strong>
                                                                {totals[header.id].fueling
                                                                    ? formatters.numberFormatter(totals[header.id].fueling, '0,0')
                                                                    : '-'}
                                                                <br />
                                                                <small className="text-gray">
                                                                    {totals[header.id].consumption
                                                                        ? formatters.numberFormatter(totals[header.id].consumption, '0,0')
                                                                        : '-'}
                                                                </small>
                                                            </strong>
                                                        </td>
                                                    )
                                                })}

                                                <td
                                                    className="w-100 text-right"
                                                    role={totals.haveData ? 'button' : undefined}
                                                    onClick={
                                                        totals.haveData
                                                            ? e => {
                                                                  this.handleShowDetails(e, null, stringISOFrom, stringISOTo)
                                                              }
                                                            : undefined
                                                    }
                                                >
                                                    <strong>{totals.km === 0 ? '-' : formatters.distanceFormatter(totals.km)}</strong>
                                                </td>
                                                <td
                                                    className="w-100 text-right"
                                                    role={totals.haveData ? 'button' : undefined}
                                                    onClick={
                                                        totals.haveData
                                                            ? e => {
                                                                  this.handleShowDetails(e, null, stringISOFrom, stringISOTo)
                                                              }
                                                            : undefined
                                                    }
                                                >
                                                    <strong>-</strong>
                                                </td>
                                                <td
                                                    className="w-100 text-right"
                                                    role={totals.haveData ? 'button' : undefined}
                                                    onClick={
                                                        totals.haveData
                                                            ? e => {
                                                                  this.handleShowDetails(e, null, stringISOFrom, stringISOTo)
                                                              }
                                                            : undefined
                                                    }
                                                >
                                                    <strong>-</strong>
                                                </td>
                                                <td
                                                    className="w-100 text-right"
                                                    role={totals.haveData ? 'button' : undefined}
                                                    onClick={
                                                        totals.haveData
                                                            ? e => {
                                                                  this.handleShowDetails(e, null, stringISOFrom, stringISOTo)
                                                              }
                                                            : undefined
                                                    }
                                                >
                                                    <strong>{totals.consumption === 0 ? '-' : formatters.volumeFormatter(totals.consumption)}</strong>
                                                </td>
                                                <td
                                                    className="w-100 text-right"
                                                    role={totals.haveData ? 'button' : undefined}
                                                    onClick={
                                                        totals.haveData
                                                            ? e => {
                                                                  this.handleShowDetails(e, null, stringISOFrom, stringISOTo)
                                                              }
                                                            : undefined
                                                    }
                                                >
                                                    <strong>
                                                        {totals.km === 0 ? '-' : formatters.floatFormatter((totals.consumption / totals.km) * 100, 2)}
                                                    </strong>
                                                </td>
                                                <td
                                                    className="w-100 text-right"
                                                    role={totals.haveData ? 'button' : undefined}
                                                    onClick={
                                                        totals.haveData
                                                            ? e => {
                                                                  this.handleShowDetails(e, null, stringISOFrom, stringISOTo)
                                                              }
                                                            : undefined
                                                    }
                                                >
                                                    <strong>{totals.fueling === 0 ? '-' : formatters.volumeFormatter(totals.fueling)}</strong>
                                                </td>
                                                <td
                                                    className="w-100 text-right"
                                                    role={totals.haveData ? 'button' : undefined}
                                                    onClick={
                                                        totals.haveData
                                                            ? e => {
                                                                  this.handleShowDetails(e, null, stringISOFrom, stringISOTo)
                                                              }
                                                            : undefined
                                                    }
                                                >
                                                    <strong>
                                                        {totals.fueling - totals.consumption === 0
                                                            ? '-'
                                                            : formatters.volumeFormatter(totals.fueling - totals.consumption)}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-md-2">
                        <Modal.Header closeButton>
                            <Modal.Title>{detailsData ? detailsData.title : null}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="clearfix">
                            <LoadingOverlay active={this.props.detailsFetching}>
                                <div className="btns-list">
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        {detailsData && detailsData.records.length > 0 && (
                                            <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExportDetails}>
                                                <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                            </button>
                                        )}
                                    </PermissionsCheck>
                                </div>

                                <div className="pull-left wp-100">
                                    <div className="panel-head">
                                        <h2 className="pull-left wp-100">
                                            <div className="pull-left">
                                                <FormattedMessage id="modules.reportVehicleFuelings.detail.sumeryHeading" />
                                            </div>
                                            <div className="pull-right text-md" />
                                        </h2>
                                    </div>

                                    <div className="pull-left wp-100 p-v-xs p-h-xs m-b-sm b alert-default">
                                        <div className="table-container">
                                            <table className="table table-striped table-no-border no-m">
                                                <thead>
                                                    <tr>
                                                        <th className="w-max wm-200">
                                                            <FormattedMessage id="fields.eventType" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.totalTime" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.km" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.totalConsumption" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.avgConsumption" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.fueling" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {detailsData &&
                                                        detailsData.summary.map(summary => {
                                                            return (
                                                                <tr key={`transport-${summary.type_id}`}>
                                                                    <td>
                                                                        {summary.type_id !== null
                                                                            ? this.props.intl.formatMessage({
                                                                                  id: `vehicleTripRecordType.${summary.type_id}`,
                                                                              })
                                                                            : this.props.intl.formatMessage({
                                                                                  id: `vehicleTripRecordType.fueling`,
                                                                              })}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {summary.type_id !== null &&
                                                                            formatters.durationFromSecondsFormatter(summary.duration)}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {summary.type_id !== null &&
                                                                            formatters.distanceFormatter(summary.distance, '0,0.00')}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {summary.type_id !== null &&
                                                                            formatters.volumeFormatter(summary.consumption, '0,0.00')}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {summary.type_id !== null &&
                                                                            summary.distance > 0 &&
                                                                            formatters.volumeFormatter(
                                                                                (summary.consumption / summary.distance) * 100,
                                                                                '0,0.00'
                                                                            )}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {summary.type_id === null &&
                                                                            formatters.volumeFormatter(summary.fueling, '0,0.00')}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="pull-left wp-100">
                                    <div className="panel-head">
                                        <h2 className="pull-left wp-100">
                                            <div className="pull-left">
                                                <FormattedMessage id="modules.reportVehicleFuelings.detail.odometerHeading" />
                                            </div>
                                            <div className="pull-right text-md" />
                                        </h2>
                                    </div>

                                    <div className="pull-left wp-100 p-v-xs p-h-xs m-b-sm b alert-default">
                                        <div className="table-container">
                                            <table className="table table-striped table-no-border no-m">
                                                <thead>
                                                    <tr>
                                                        <th className="w-max wm-200">
                                                            <FormattedMessage id="fields.vehicle" />
                                                        </th>
                                                        <th className="w-240 text-right">
                                                            <FormattedMessage id="fields.odometerState" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.odometerDistance" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.km" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.difference" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {detailsData &&
                                                        detailsData.odometer.map(odometer => {
                                                            return (
                                                                <tr key={`transport-${odometer.vehicle.id}`}>
                                                                    <td>{odometer.vehicle ? odometer.vehicle.name : ''}</td>
                                                                    <td className="text-right">
                                                                        {formatters.distanceFormatter(odometer.odometer_start, '0,0.00')} /{' '}
                                                                        {formatters.distanceFormatter(odometer.odometer_end, '0,0.00')}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {formatters.distanceFormatter(odometer.odometer, '0,0.00')}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {formatters.distanceFormatter(odometer.km, '0,0.00')}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        {formatters.distanceFormatter(odometer.odometer - odometer.km, '0,0.00')}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="pull-left wp-100">
                                    <div className="panel-head">
                                        <h2 className="pull-left wp-100">
                                            <div className="pull-left">
                                                <FormattedMessage id="modules.reportVehicleFuelings.detail.tripRecordsHeading" />
                                            </div>
                                            <div className="pull-right text-md" />
                                        </h2>
                                    </div>

                                    <div className="pull-left wp-100 p-v-xs p-h-xs m-b-sm b alert-default">
                                        <div className="table-container">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th className="w-120">
                                                            <FormattedMessage id="fields.dateFrom" />
                                                        </th>
                                                        <th className="w-120">
                                                            <FormattedMessage id="fields.dateTo" />
                                                        </th>
                                                        <th className="w-200">
                                                            <FormattedMessage id="fields.driver" />
                                                        </th>
                                                        <th className="w-100">
                                                            <FormattedMessage id="fields.vehicle" />
                                                        </th>
                                                        <th className="w-max wm-200">
                                                            <FormattedMessage id="fields.eventType" />
                                                        </th>
                                                        <th className="w-80 text-right">
                                                            <FormattedMessage id="fields.duration" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.km" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.totalConsumption" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.avgConsumption" />
                                                        </th>
                                                        <th className="w-120 text-right">
                                                            <FormattedMessage id="fields.fueling" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {detailsData &&
                                                        detailsData.records.map(record => {
                                                            detailsTotal.duration += record.duration
                                                            detailsTotal.distance += record.distance
                                                            detailsTotal.consumption += record.consumption
                                                            detailsTotal.fueling += record.fueling
                                                            return (
                                                                <tr key={`transport-${record.id}`}>
                                                                    {record.start_datetime === undefined && record.end_datetime === undefined ? (
                                                                        <td colSpan="2" className="w-240">
                                                                            <FormattedMessage id="vehicleTripRecordType.dataCompletion" />
                                                                        </td>
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            <td className="w-120">
                                                                                {formatters.datetimeFormatter(record.start_datetime)}
                                                                            </td>
                                                                            <td className="w-120">
                                                                                {record.event !== null &&
                                                                                    formatters.datetimeFormatter(record.end_datetime)}
                                                                            </td>
                                                                        </React.Fragment>
                                                                    )}
                                                                    <td className="w-200">{record.driver ? record.driver.full_name : ''}</td>
                                                                    <td className="w-100">{record.vehicle ? record.vehicle.name : ''}</td>
                                                                    <td className="w-max wm-200">
                                                                        {record.type_id !== null
                                                                            ? this.props.intl.formatMessage({
                                                                                  id: `vehicleTripRecordType.${record.type_id}`,
                                                                              })
                                                                            : this.props.intl.formatMessage({
                                                                                  id: `vehicleTripRecordType.fueling`,
                                                                              })}
                                                                    </td>
                                                                    <td className="w-80 text-right">
                                                                        {record.type_id !== null &&
                                                                            formatters.durationFromSecondsFormatter(record.duration)}
                                                                    </td>
                                                                    <td className="w-120 text-right">
                                                                        {record.type_id !== null &&
                                                                            formatters.distanceFormatter(record.distance, '0,0.00')}
                                                                    </td>
                                                                    <td className="w-120 text-right">
                                                                        {record.type_id !== null &&
                                                                            formatters.volumeFormatter(record.consumption, '0,0.00')}
                                                                    </td>
                                                                    <td className="w-120 text-right">
                                                                        {record.type_id !== null &&
                                                                            record.distance > 0 &&
                                                                            formatters.volumeFormatter(
                                                                                (record.consumption / record.distance) * 100,
                                                                                '0,0.00'
                                                                            )}
                                                                    </td>
                                                                    <td className="w-120 text-right">
                                                                        {record.type_id === null &&
                                                                            formatters.volumeFormatter(record.fueling, '0,0.00')}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}

                                                    <tr className="b-top row-sum">
                                                        <td colSpan="5">
                                                            <strong>
                                                                <FormattedMessage id="fields.total" />:
                                                            </strong>
                                                        </td>
                                                        <td className="w-80 text-right">
                                                            {detailsTotal.duration !== 0 &&
                                                                formatters.durationFromSecondsFormatter(detailsTotal.duration)}
                                                        </td>
                                                        <td className="w-120 text-right">
                                                            {detailsTotal.distance !== 0 &&
                                                                formatters.distanceFormatter(detailsTotal.distance, '0,0.00')}
                                                        </td>
                                                        <td className="w-120 text-right">
                                                            {detailsTotal.consumption !== 0 &&
                                                                formatters.volumeFormatter(detailsTotal.consumption, '0,0.00')}
                                                        </td>
                                                        <td className="w-120 text-right">
                                                            {detailsTotal.distance > 0 &&
                                                                formatters.volumeFormatter(
                                                                    (detailsTotal.consumption / detailsTotal.distance) * 100,
                                                                    '0,0.00'
                                                                )}
                                                        </td>
                                                        <td className="w-120 text-right">
                                                            {detailsTotal.fueling !== 0 && formatters.volumeFormatter(detailsTotal.fueling, '0,0.00')}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-default" onClick={this.handleHideDetails}>
                                <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </PermissionsCheck>
        )
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...reportVehicleFuelingsActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(reportVehicleFuelings)
)
