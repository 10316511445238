import { Observable } from 'rxjs'

import { request, parseAPIError } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch

const fetchReportEmptyKilometersEpic = action$ =>
    action$.ofType(actionTypes.FETCH).switchMap(action => {
        const requestParams = {
            method: 'GET',
            path: `report-empty-kilometers?month=${action.payload}`,
        }

        return Observable.concat(
            Observable.of({
                type: actionTypes.FETCH_STARTED,
            }),
            request(requestParams)
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchReportEmptyKilometersFulfilled(ajaxResponse.response)))
                .catch(error => Observable.of(actionCreators.fetchReportEmptyKilometersRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH))
        )
    })

export default [fetchReportEmptyKilometersEpic]
