import * as actionTypes from './actionTypes'

// Fetch

export const fetchVehicleFleetboardConfigurations = () => ({
    type: actionTypes.FETCH,
})

export const fetchVehicleFleetboardConfigurationsFulfilled = vehicleFleetboardConfigurations => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: vehicleFleetboardConfigurations,
})

export const fetchVehicleFleetboardConfigurationsRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchVehicleFleetboardConfigurationsCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Fetch one

export const fetchVehicleFleetboardConfiguration = id => ({
    type: actionTypes.FETCH_ONE,
    payload: id,
})

export const fetchVehicleFleetboardConfigurationFulfilled = () => ({
    type: actionTypes.FETCH_ONE_FULFILLED,
})

export const fetchVehicleFleetboardConfigurationRejected = message => ({
    type: actionTypes.FETCH_ONE_REJECTED,
    payload: message,
})

export const fetchVehicleFleetboardConfigurationCancelled = () => ({
    type: actionTypes.FETCH_ONE_CANCELLED,
})

// Save

export const saveVehicleFleetboardConfiguration = values => ({
    type: actionTypes.SAVE,
    payload: values,
})

export const saveVehicleFleetboardConfigurationFulfilled = vehicleFleetboardConfiguration => ({
    type: actionTypes.SAVE_FULFILLED,
    payload: vehicleFleetboardConfiguration,
})

export const saveVehicleFleetboardConfigurationRejected = message => ({
    type: actionTypes.SAVE_REJECTED,
    payload: message,
})

// Delete

export const deleteVehicleFleetboardConfiguration = id => ({
    type: actionTypes.DELETE,
    payload: id,
})

export const deleteVehicleFleetboardConfigurationFulfilled = id => ({
    type: actionTypes.DELETE_FULFILLED,
    payload: id,
})

export const deleteVehicleFleetboardConfigurationRejected = message => ({
    type: actionTypes.DELETE_REJECTED,
    payload: message,
})
