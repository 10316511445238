import React from 'react'
import { Route, Switch } from 'react-router'
import CargoTypesList from './CargoTypesList'
import CargoTypesEdit from './CargoTypesEdit'

const CargoTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={CargoTypesList} />
        <Route exact path={`${props.match.url}/new`} component={CargoTypesEdit} />
        <Route exact path={`${props.match.url}/:cargoTypeID`} component={CargoTypesEdit} />
    </Switch>
)

export default CargoTypes
