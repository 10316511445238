import * as actionTypes from './actionTypes'

// Fetch

export const fetchReportCostCenterCost = year => ({
    type: actionTypes.FETCH,
    payload: year,
})

export const fetchReportCostCenterCostFulfilled = data => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterCostRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchReportCostCenterCostCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})

// Export

export const exportReportCostCenterCost = (year, filters) => ({
    type: actionTypes.EXPORT,
    payload: {
        year,
        filters,
    },
})

// Clear

export const clearReportCostCenterCost = () => ({
    type: actionTypes.CLEAR,
})

// Fetch details

export const fetchReportCostCenterCostDetails = (year, subjectName, subjectId) => ({
    type: actionTypes.FETCH_DETAILS,
    payload: {
        year,
        subjectName,
        subjectId,
    },
})

export const fetchReportCostCenterCostDetailsFulfilled = data => ({
    type: actionTypes.FETCH_DETAILS_FULFILLED,
    payload: data,
})

export const fetchReportCostCenterCostDetailsRejected = message => ({
    type: actionTypes.FETCH_DETAILS_REJECTED,
    payload: message,
})

export const fetchReportCostCenterCostDetailsCancelled = () => ({
    type: actionTypes.FETCH_DETAILS_CANCELLED,
})

// Export

export const exportReportCostCenterCostDetails = (year, subjectName, subjectId) => ({
    type: actionTypes.EXPORT_DETAILS,
    payload: {
        year,
        subjectName,
        subjectId,
    },
})

// Clear

export const clearReportCostCenterCostDetails = () => ({
    type: actionTypes.CLEAR_DETAILS,
})
