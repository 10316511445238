import React from 'react'

const ColoredBox = props => {
    const bg = props.bgRGB ? props.bgRGB : '#dddddd'
    const text = props.textRGB ? props.textRGB : '#000000'
    const border = props.border ? props.border : '1px solid #dddddd'

    return (
        <span className={`btn-rounded ${props.className}`} style={{ backgroundColor: bg, color: text, border: border }}>
            {props.children}
        </span>
    )
}

export default ColoredBox
