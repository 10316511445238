import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as notifications } from 'react-notification-system-redux'

import tableModel from './common/table/reducers'
import prerequisites from './common/prerequisites/reducers'
import locale from './pages/locale/reducers'
import auth from './pages/auth/reducers'
import countries from './pages/countries/reducers'
import currencies from './pages/currencies/reducers'
import carriers from './pages/carriers/reducers'
import carrierContacts from './pages/carrier_contacts/reducers'
import transportTypes from './pages/transport_types/reducers'
import cargoTypes from './pages/cargo_types/reducers'
import vehicleTypes from './pages/vehicle_types/reducers'
import trailerTypes from './pages/trailer_types/reducers'
import companies from './pages/companies/reducers'
import companyCostCenters from './pages/company_cost_centers/reducers'
import companyBankAccounts from './pages/company_bank_accounts/reducers'
import companyNumberings from './pages/company_numberings/reducers'
import customers from './pages/customers/reducers'
import drivers from './pages/drivers/reducers'
import driversTypes from './pages/drivers_types/reducers'
import trailers from './pages/trailers/reducers'
import users from './pages/users/reducers'
import userRoles from './pages/user_roles/reducers'
import userLogins from './pages/user_logins/reducers'
import blockedIps from './pages/blocked_ips/reducers'
import generalSettings from './pages/general_settings/reducers'
import vehicleFleetboardConfigurations from './pages/vehicle_fleetboard_configurations/reducers'
import vehicles from './pages/vehicles/reducers'
import customerAddresses from './pages/customer_address/reducers'
import customerContacts from './pages/customer_contacts/reducers'
import customerPlans from './pages/customer_plans/reducers'
import vehiclePositions from './pages/vehicle_positions/reducers'
import driverTimes from './pages/driver_times/reducers'
import driverTimesheets from './pages/driver_timesheets/reducers'
import transports from './pages/transports/reducers'
import transportStates from './pages/transport_states/reducers'
import transportPointStates from './pages/transport_point_states/reducers'
import board from './pages/board/reducers'
import surchargeTypes from './pages/surcharge_types/reducers'
import customerAddressTypes from './pages/customer_address_types/reducers'
import vehicleEvents from './pages/vehicle_events/reducers'
import vehicleFuelings from './pages/vehicle_fuelings/reducers'
import transporeonOffers from './pages/transporeon_offers/reducers'
import vehicleMessages from './pages/vehicle_messages/reducers'
import reportVehicleChecks from './pages/report_vehicle_checks/reducers'
import reportCustomerTurnovers from './pages/report_customer_turnovers/reducers'
import reportEmptyKilometers from './pages/report_empty_kilometers/reducers'
import reportPallets from './pages/report_pallets/reducers'
import reportInvoicingSummary from './pages/report_invoicing_summary/reducers'
import goodsTypes from './pages/goods_types/reducers'
import trailerCurrentPositions from './pages/trailer_current_positions/reducers'
import documentTypes from './pages/document_types/reducers'
import driverDocuments from './pages/driver_documents/reducers'
import vehicleDocuments from './pages/vehicle_documents/reducers'
import trailerDocuments from './pages/trailer_documents/reducers'
import equipments from './pages/equipments/reducers'
import driverEquipments from './pages/driver_equipments/reducers'
import vehicleEquipments from './pages/vehicle_equipments/reducers'
import trailerEquipments from './pages/trailer_equipments/reducers'
import reportCustomerResults from './pages/report_customer_results/reducers'
import reportCostCenterResults from './pages/report_cost_center_results/reducers'
import reportTransportInvoicing from './pages/report_transport_invoicing/reducers'
import reportTransportChanges from './pages/report_transport_changes/reducers'
import reportTransports from './pages/report_transports/reducers'
import reportSpeditionTransports from './pages/report_spedition_transports/reducers'
import reportVehicleKilometersTotal from './pages/report_vehicle_kilometers_total/reducers'
import reportCostCenterTraffic from './pages/report_cost_center_traffic/reducers'
import reportCostCenterPerformance from './pages/report_cost_center_performance/reducers'
import reportCostCenterProfits from './pages/report_cost_center_profits/reducers'
import reportVehicleTraffic from './pages/report_vehicle_traffic/reducers'
import reportVehiclePerformance from './pages/report_vehicle_performance/reducers'
import reportVehicleFuelings from './pages/report_vehicle_fuelings/reducers'
import reportVehicleResults from './pages/report_vehicle_results/reducers'
import reportDriverAllowances from './pages/report_driver_allowances/reducers'
import reportDriverPerformanceAnalysis from './pages/report_driver_performance_analysis/reducers'
import reportDriverHourlyOverview from './pages/report_driver_hourly_overview/reducers'
import reportDriverTimesheet from './pages/report_driver_timesheets/reducers'
import reportDriverResults from './pages/report_driver_results/reducers'
import reportDriverCosts from './pages/report_driver_costs/reducers'
import reportTrailerResults from './pages/report_trailer_results/reducers'
import reportTrailerCosts from './pages/report_trailer_costs/reducers'
import reportTrailerFuelings from './pages/report_trailer_fuelings/reducers'
import reportVehicleCosts from './pages/report_vehicle_costs/reducers'
import reportCostCenterCosts from './pages/report_cost_center_costs/reducers'
import reportVehicleProfits from './pages/report_vehicle_profits/reducers'
import taxRates from './pages/tax_rates/reducers'
import paymentMethods from './pages/payment_methods/reducers'
import invoicingOrders from './pages/invoicing_orders/reducers'
import invoicingInvoices from './pages/invoicing_invoices/reducers'
import packagingTypes from './pages/packaging_types/reducers'
import transportConditions from './pages/transport_conditions/reducers'
import invoiceItemTypes from './pages/invoice_item_types/reducers'
import invoicingReceivedInvoices from './pages/invoicing_received_invoices/reducers'
import transportScoring from './pages/transport_scoring/reducers'
import carrierScoring from './pages/carrier_scoring/reducers'
import reportCarrierScoring from './pages/report_carrier_scoring/reducers'
import reportDispatcherKPI from './pages/report_dispatcher_kpi/reducers'
import reportAppUsage from './pages/report_app_usage/reducers'
import reportCarrierUsage from './pages/report_carrier_usage/reducers'
import reportTransportOrders from './pages/report_transport_orders/reducers'
import reportCustomerPlans from './pages/report_customer_plans/reducers'
import reportCustomerMonthlyPlans from './pages/report_customer_monthly_plans/reducers'
import reportCustomerWeeklyPlans from './pages/report_customer_weekly_plans/reducers'
import reportCarrierResults from './pages/report_carrier_results/reducers'
import reportCostCenterCost from './pages/report_cost_center_cost/reducers'
import borderCrossings from './pages/border_crossings/reducers'
import legend from './pages/legend/reducers'
import hereMap from './common/here_maps/reducers'
import tracing from './pages/tracing/reducers'
import userProfileSettings from './pages/user_profile/reducers'
import wsnotifications from './common/notifications/reducers'
import dietRates from './pages/diet_rates/reducers'
import costTypes from './pages/cost_types/reducers'
import costs from './pages/costs/reducers'
import reportCostTypeCosts from './pages/report_cost_types_costs/reducers'
import salesTypes from './pages/sales_types/reducers'
import sales from './pages/sales/reducers'
import driverSurchargeTypes from './pages/driver_surcharge_types/reducers'
import reportDriverSurcharges from './pages/report_driver_surcharges/reducers'
import driverSurcharges from './pages/driver_surcharges/reducers'
import reportCustomerSpeditions from './pages/report_customer_speditions/reducers'
import units from './pages/units/reducers'
import warehousingWarehouses from './pages/warehousing_warehouses/reducers'
import warehousingItems from './pages/warehousing_items/reducers'
import warehousingReceipts from './pages/warehousing_receipts/reducers'
import warehousingIssues from './pages/warehousing_issues/reducers'
import serviceJobs from './pages/service_jobs/reducers'
import serviceWorkers from './pages/service_workers/reducers'
import serviceOrders from './pages/service_orders/reducers'

const reducers = combineReducers({
    routing: routerReducer,
    notifications,
    locale,
    tableModel,
    prerequisites,
    auth,
    countries,
    currencies,
    carriers,
    carrierContacts,
    transportTypes,
    cargoTypes,
    vehicleTypes,
    trailerTypes,
    companies,
    companyCostCenters,
    companyBankAccounts,
    companyNumberings,
    customers,
    drivers,
    driversTypes,
    trailers,
    users,
    userRoles,
    userLogins,
    blockedIps,
    generalSettings,
    vehicleFleetboardConfigurations,
    vehicles,
    customerAddresses,
    customerContacts,
    customerPlans,
    vehiclePositions,
    driverTimes,
    driverTimesheets,
    transports,
    transportStates,
    transportPointStates,
    board,
    surchargeTypes,
    customerAddressTypes,
    vehicleEvents,
    vehicleFuelings,
    transporeonOffers,
    vehicleMessages,
    reportVehicleChecks,
    reportCustomerTurnovers,
    reportEmptyKilometers,
    reportPallets,
    reportInvoicingSummary,
    goodsTypes,
    trailerCurrentPositions,
    documentTypes,
    driverDocuments,
    vehicleDocuments,
    trailerDocuments,
    equipments,
    driverEquipments,
    vehicleEquipments,
    trailerEquipments,
    reportCustomerResults,
    reportCostCenterResults,
    reportCostCenterProfits,
    reportTransportChanges,
    reportTransports,
    reportSpeditionTransports,
    reportVehicleKilometersTotal,
    reportCostCenterTraffic,
    reportCostCenterPerformance,
    reportVehicleTraffic,
    reportVehiclePerformance,
    reportVehicleFuelings,
    reportVehicleResults,
    reportDriverAllowances,
    reportDriverPerformanceAnalysis,
    reportDriverHourlyOverview,
    reportDriverTimesheet,
    reportDriverResults,
    reportDriverCosts,
    reportTrailerResults,
    reportTrailerCosts,
    reportTrailerFuelings,
    reportVehicleCosts,
    reportCostCenterCosts,
    reportVehicleProfits,
    taxRates,
    paymentMethods,
    invoicingOrders,
    invoicingInvoices,
    packagingTypes,
    transportConditions,
    invoiceItemTypes,
    invoicingReceivedInvoices,
    transportScoring,
    carrierScoring,
    reportCarrierScoring,
    reportDispatcherKPI,
    reportAppUsage,
    reportCarrierUsage,
    reportTransportInvoicing,
    reportTransportOrders,
    reportCustomerPlans,
    reportCustomerMonthlyPlans,
    reportCustomerWeeklyPlans,
    reportCarrierResults,
    reportCostCenterCost,
    borderCrossings,
    legend,
    hereMap,
    tracing,
    userProfileSettings,
    wsnotifications,
    dietRates,
    costTypes,
    costs,
    reportCostTypeCosts,
    salesTypes,
    sales,
    driverSurchargeTypes,
    reportDriverSurcharges,
    driverSurcharges,
    reportCustomerSpeditions,
    units,
    warehousingWarehouses,
    warehousingItems,
    warehousingReceipts,
    warehousingIssues,
    serviceJobs,
    serviceWorkers,
    serviceOrders,
})

export default reducers
