import { Map, OrderedMap } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    all: Map({
        objects: OrderedMap({}),
        fetching: false,
        error: null,
    }),
})

function reportPallets(state = initialState, action) {
    switch (action.type) {
        // List
        case actionTypes.FETCH_STARTED:
            return state.setIn(['all', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map
                    .setIn(['all', 'objects'], action.payload)
                    .setIn(['all', 'fetching'], false)
                    .setIn(['all', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['all', 'fetching'], false).setIn(['all', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['all', 'fetching'], false)
        case actionTypes.SORTING_CHANGED:
            return state.setIn(['all', 'sorting'], action.payload)

        default:
            return state
    }
}

export default reportPallets
