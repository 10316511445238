// Fetch list

export const FETCH = 'warehousingIssues.FETCH'
export const FETCH_STARTED = 'warehousingIssues.FETCH_STARTED'
export const FETCH_FULFILLED = 'warehousingIssues.FETCH_FULFILLED'
export const FETCH_REJECTED = 'warehousingIssues.FETCH_REJECTED'
export const FETCH_CANCELLED = 'warehousingIssues.FETCH_CANCELLED'

export const SORTING_CHANGED = 'warehousingIssues.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'warehousingIssues.FETCH_ONE'
export const FETCH_ONE_STARTED = 'warehousingIssues.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'warehousingIssues.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'warehousingIssues.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'warehousingIssues.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'warehousingIssues.SAVE'
export const SAVE_STARTED = 'warehousingIssues.SAVE_STARTED'
export const SAVE_FULFILLED = 'warehousingIssues.SAVE_FULFILLED'
export const SAVE_REJECTED = 'warehousingIssues.SAVE_REJECTED'
export const SAVE_CANCELLED = 'warehousingIssues.SAVE_CANCELLED'

// Delete

export const DELETE = 'warehousingIssues.DELETE'
export const DELETE_STARTED = 'warehousingIssues.DELETE_STARTED'
export const DELETE_FULFILLED = 'warehousingIssues.DELETE_FULFILLED'
export const DELETE_REJECTED = 'warehousingIssues.DELETE_REJECTED'
export const DELETE_CANCELLED = 'warehousingIssues.DELETE_CANCELLED'

// Export

export const EXPORT = 'warehousingIssues.EXPORT'
export const EXPORT_FULFILLED = 'warehousingIssues.EXPORT_FULFILLED'

// Fetch item autocomplete

export const FETCH_ITEM_AUTOCOMPLETE = 'warehousingIssues.FETCH_ITEM_AUTOCOMPLETE'
export const FETCH_ITEM_AUTOCOMPLETE_STARTED = 'warehousingIssues.FETCH_ITEM_AUTOCOMPLETE_STARTED'
export const FETCH_ITEM_AUTOCOMPLETE_FULFILLED = 'warehousingIssues.FETCH_ITEM_AUTOCOMPLETE_FULFILLED'
export const FETCH_ITEM_AUTOCOMPLETE_REJECTED = 'warehousingIssues.FETCH_ITEM_AUTOCOMPLETE_REJECTED'
export const FETCH_ITEM_AUTOCOMPLETE_CANCELLED = 'warehousingIssues.FETCH_ITEM_AUTOCOMPLETE_CANCELLED'