import { Observable } from 'rxjs'

import { request, parseAPIError, DownloadRequest } from '../../common/api'

import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'
import * as tableModelActionTypes from '../../common/table/actionTypes'
import { resolveModelState } from '../../common/table/helpers'

// Fetch

const fetchReportInvoicingSummaryEpic = (action$, store) => {
    const tableIdentifier = 'report_invoicing_summary_list'

    return action$
        .ofType(actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED)
        .filter(action => action.type !== tableModelActionTypes.CONFIGURATION_CHANGED || action.payload.tableIdentifier === tableIdentifier)
        .switchMap(action => {
            const modelState = resolveModelState(tableIdentifier, store.getState(), action)

            const requestParams = {
                method: 'POST',
                path: `report-invoicing-summary`,
                body: {
                    sorting: modelState.get('sorting').toJS(),
                    filters: modelState.get('filters').toJS(),
                },
            }

            return Observable.concat(
                Observable.of({
                    type: actionTypes.FETCH_STARTED,
                }),
                request(requestParams)
                    .switchMap(ajaxResponse => {
                        const observables = [
                            Observable.of(actionCreators.fetchReportInvoicingSummaryFulfilled(ajaxResponse.response.data)),
                        ]

                        return Observable.concat(...observables)
                    })
                    .catch(error => Observable.of(actionCreators.fetchReportInvoicingSummaryRejected(parseAPIError(error))))
                    .takeUntil(action$.ofType(actionTypes.FETCH_CANCELLED, actionTypes.FETCH, tableModelActionTypes.CONFIGURATION_CHANGED))
            )
        })
}

// Export

const exportReportInvoicingSummaryEpic = (action$, store) =>
    action$.ofType(actionTypes.EXPORT).switchMap(action => {
        const filters = JSON.stringify(action.payload)
        const token = store.getState().auth.get('accessToken')

        new DownloadRequest({
            url: `report-invoicing-summary/export?filters=${filters}&token=${token}`,
        }).run()

        return Observable.concat(
            Observable.of({
                type: actionTypes.EXPORT_FULFILLED,
            })
        )
    })

export default [fetchReportInvoicingSummaryEpic, exportReportInvoicingSummaryEpic]
