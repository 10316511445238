// Fetch list

export const FETCH = 'reportCustomerMonthlyPlans.FETCH'
export const FETCH_STARTED = 'reportCustomerMonthlyPlans.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportCustomerMonthlyPlans.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportCustomerMonthlyPlans.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportCustomerMonthlyPlans.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportCustomerMonthlyPlans.CLEAR'

// Export

export const EXPORT = 'reportCustomerMonthlyPlans.EXPORT'
export const EXPORT_FULFILLED = 'reportCustomerMonthlyPlans.EXPORT_FULFILLED'
