import * as actionTypes from './actionTypes'

// Fetch

export const fetchTransportPointStates = () => ({
    type: actionTypes.FETCH,
})

export const fetchTransportPointStatesFulfilled = transportPointStates => ({
    type: actionTypes.FETCH_FULFILLED,
    payload: transportPointStates,
})

export const fetchTransportPointStatesRejected = message => ({
    type: actionTypes.FETCH_REJECTED,
    payload: message,
})

export const fetchTransportPointStatesCancelled = () => ({
    type: actionTypes.FETCH_CANCELLED,
})
