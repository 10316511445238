import { Observable } from 'rxjs'

import { request, parseAPIError } from '../api'

import * as tableModelActionTypes from '../table/actionTypes'
import * as actionTypes from './actionTypes'
import * as actionCreators from './actionCreators'

// Fetch unread

const getUnreadNotifications = action$ =>
    action$.ofType(actionTypes.UNREAD_NOTIFICATIONS_FETCH_STARTED).switchMap(() => {
        return Observable.concat(
            request({
                method: 'GET',
                path: 'notifications/unreaded',
            })
                .switchMap(ajaxResponse => {
                    return Observable.of(actionCreators.fetchUnreadNotificationsFinished(ajaxResponse.response))
                })
                .catch(error => Observable.of(actionCreators.fetchUnreadNotificationsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.UNREAD_NOTIFICATIONS_FETCH_CANCELLED))
        )
    })

// Mark as read

const markNotificationAsReadEpic = action$ =>
    action$.ofType(actionTypes.MARK_AS_READ).switchMap(action => {
        return Observable.concat(
            request({
                method: 'PUT',
                path: `notifications/${action.payload}`,
            })
                .switchMap(ajaxResponse => {
                    return Observable.concat(Observable.of(actionCreators.markNotificationAsRead(ajaxResponse.response)))
                })
                .takeUntil(action$.ofType(actionTypes.MARK_AS_READ_CANCELLED))
        )
    })

// Featch

const getNotifications = action$ =>
    action$.ofType(actionTypes.NOTIFICATIONS_FETCH_STARTED).switchMap(action => {
        const filters = JSON.stringify(action.payload.filters)
        return Observable.concat(
            request({
                method: 'GET',
                path: `notifications/list?filters=${filters}${action.payload.page ? `&page=${action.payload.page + 1}` : ''}`,
            })
                .switchMap(ajaxResponse => Observable.of(actionCreators.fetchNotificationsFinished(ajaxResponse.response)))
                .takeUntil(action$.ofType(actionTypes.NOTIFICATIONS_FETCH_CANCELLED, tableModelActionTypes.CONFIGURATION_CHANGED))
        )
    })

const getNotificationsSettings = action$ =>
    action$.ofType(actionTypes.NOTIFICATIONS_SETTINGS_FETCH_STARTED).switchMap(() => {
        return Observable.concat(
            request({
                method: 'GET',
                path: 'notifications/settings',
            })
                .switchMap(ajaxResponse => {
                    return Observable.of(actionCreators.fetchNotificationsSettingsFinished(ajaxResponse.response))
                })
                .catch(error => Observable.of(actionCreators.fetchNotificationsSettingsRejected(parseAPIError(error))))
                .takeUntil(action$.ofType(actionTypes.NOTIFICATIONS_SETTINGS_FETCH_CANCELLED))
        )
    })

const saveNotificationsSettings = action$ =>
    action$.ofType(actionTypes.NOTIFICATIONS_SETTINGS_SAVE_STARTED).switchMap(action => {
        return Observable.concat(
            request({
                method: 'PUT',
                path: 'notifications/settings',
                body: action.payload,
            })
                .switchMap(ajaxResponse => {
                    return Observable.of(actionCreators.saveNotificationsSettingsFinished(ajaxResponse.response))
                })
                .takeUntil(action$.ofType(actionTypes.NOTIFICATIONS_SETTINGS_SAVE_CANCELLED))
        )
    })

export default [getUnreadNotifications, markNotificationAsReadEpic, getNotifications, getNotificationsSettings, saveNotificationsSettings]
