import { Map } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    list: Map({
        day: null,
        data: null,
        fetching: false,
        error: null,
    }),
    driver_note: Map({
        saving: false,
        error: null,
    }),
    details: Map({
        data: null,
        fetching: false,
        error: null,
    }),
})

function reportDriverTimesheet(state = initialState, action) {
    switch (action.type) {
        // Fetch
        case actionTypes.FETCH:
            return state.setIn(['list', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['list', 'fetching'], false).setIn(['list', 'data'], action.payload)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['list', 'fetching'], false).setIn(['list', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['list', 'fetching'], false)

        // Driver Note
        case actionTypes.DRIVER_NOTE_UPDATE_STARTED:
            return state.setIn(['driver_note', 'saving'], true)
        case actionTypes.DRIVER_NOTE_UPDATE_FULFILLED:
            return state.setIn(['driver_note', 'saving'], false).setIn(['driver_note', 'error'], null)
        case actionTypes.DRIVER_NOTE_UPDATE_REJECTED:
            return state.setIn(['driver_note', 'saving'], false).setIn(['vehicle_driver_notenote', 'error'], action.payload)
        case actionTypes.DRIVER_NOTE_UPDATE_CANCELLED:
            return state.setIn(['driver_note', 'saving'], false)

        // Clear
        case actionTypes.CLEAR:
            return state.setIn(['list', 'data'], null)

        // Fetch details
        case actionTypes.FETCH_DETAILS:
            return state.setIn(['details', 'fetching'], true)
        case actionTypes.FETCH_DETAILS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['details', 'fetching'], false).setIn(['details', 'data'], action.payload)
            })
        case actionTypes.FETCH_DETAILS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['details', 'fetching'], false).setIn(['details', 'error'], action.payload)
            })
        case actionTypes.FETCH_DETAILS_CANCELLED:
            return state.setIn(['details', 'fetching'], false)

        // Clear details
        case actionTypes.CLEAR_DETAILS:
            return state.setIn(['details', 'data'], null)

        default:
            return state
    }
}

export default reportDriverTimesheet
