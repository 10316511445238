import React, { Component } from 'react'

import TableFilterField from './TableFilterField'

class TableFilterSelectField extends Component {
    handleChange = event => {
        this.props.onChange(this.props.identifier, this.props.type, event.target.value)
    }

    render() {
        let value = this.props.filters.getIn([this.props.identifier, 'value'])

        if (value === undefined) {
            value = ''
        }

        const values = this.props.values

        return (
            <TableFilterField className={this.props.className}>
                <select id={`filter_${this.props.identifier}`} value={value} onChange={this.handleChange} className="form-control">
                    <option value="">{this.props.prompt || '-'}</option>
                    {values &&
                        values.map(item => (
                            <option key={`field-${this.props.identifier}-${item.id}`} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                </select>
            </TableFilterField>
        )
    }
}

export default TableFilterSelectField
