// Fetch list

export const FETCH = 'countries.FETCH'
export const FETCH_STARTED = 'countries.FETCH_STARTED'
export const FETCH_FULFILLED = 'countries.FETCH_FULFILLED'
export const FETCH_REJECTED = 'countries.FETCH_REJECTED'
export const FETCH_CANCELLED = 'countries.FETCH_CANCELLED'

export const SORTING_CHANGED = 'countries.SORTING_CHANGED'

// Fetch one

export const FETCH_ONE = 'countries.FETCH_ONE'
export const FETCH_ONE_STARTED = 'countries.FETCH_ONE_STARTED'
export const FETCH_ONE_FULFILLED = 'countries.FETCH_ONE_FULFILLED'
export const FETCH_ONE_REJECTED = 'countries.FETCH_ONE_REJECTED'
export const FETCH_ONE_CANCELLED = 'countries.FETCH_ONE_CANCELLED'

// Save

export const SAVE = 'countries.SAVE'
export const SAVE_STARTED = 'countries.SAVE_STARTED'
export const SAVE_FULFILLED = 'countries.SAVE_FULFILLED'
export const SAVE_REJECTED = 'countries.SAVE_REJECTED'
export const SAVE_CANCELLED = 'countries.SAVE_CANCELLED'

// Delete

export const DELETE = 'countries.DELETE'
export const DELETE_STARTED = 'countries.DELETE_STARTED'
export const DELETE_FULFILLED = 'countries.DELETE_FULFILLED'
export const DELETE_REJECTED = 'countries.DELETE_REJECTED'
export const DELETE_CANCELLED = 'countries.DELETE_CANCELLED'

// Export

export const EXPORT = 'countries.EXPORT'
export const EXPORT_FULFILLED = 'countries.EXPORT_FULFILLED'
