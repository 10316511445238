export const getRoute = state => state.tracing.getIn(['route', 'object'])
export const getRouteFetching = state => state.tracing.getIn(['route', 'fetching'])
export const getRouteError = state => state.tracing.getIn(['route', 'error'])

export const getVehicle = state => state.tracing.getIn(['vehicle', 'object'])
export const getVehicleFetching = state => state.tracing.getIn(['vehicle', 'fetching'])
export const getVehicleError = state => state.tracing.getIn(['vehicle', 'error'])

export const getVehicles = state => state.tracing.getIn(['vehicles', 'objects'])
export const getVehiclesFetching = state => state.tracing.getIn(['vehicles', 'fetching'])
export const getVehiclesError = state => state.tracing.getIn(['vehicles', 'error'])

export const getGps = state => state.tracing.getIn(['gps', 'object'])
export const getGpsFetching = state => state.tracing.getIn(['gps', 'fetching'])
export const getGpsError = state => state.tracing.getIn(['gps', 'error'])

export const getAddress = state => state.tracing.getIn(['address', 'object'])
export const getAddressFetching = state => state.tracing.getIn(['address', 'fetching'])
export const getAddressError = state => state.tracing.getIn(['address', 'error'])
