import React from 'react'
import { Route, Switch } from 'react-router'
import TrailerTypesList from './TrailerTypesList'
import TrailerTypesEdit from './TrailerTypesEdit'

const TrailerTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={TrailerTypesList} />
        <Route exact path={`${props.match.url}/new`} component={TrailerTypesEdit} />
        <Route exact path={`${props.match.url}/:trailerTypeID`} component={TrailerTypesEdit} />
    </Switch>
)

export default TrailerTypes
