import React from 'react'
import { Route, Switch } from 'react-router'
import VehicleEquipmentsList from './VehicleEquipmentsList'
import VehicleEquipmentsEdit from './VehicleEquipmentsEdit'

const VehicleEquipments = props => (
    <Switch>
        <Route exact path={props.match.url} component={VehicleEquipmentsList} />
        <Route exact path={`${props.match.url}/new`} component={VehicleEquipmentsEdit} />
        <Route exact path={`${props.match.url}/:vehicleEquipmentID`} component={VehicleEquipmentsEdit} />
    </Switch>
)

export default VehicleEquipments
