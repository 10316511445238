import { createSelector } from 'reselect'

export const getVehicleEvents = state => state.vehicleEvents.getIn(['all', 'objects'])

export const createGetVehicleEventByIDSelector = id => createSelector([getVehicleEvents], vehicleEvents => vehicleEvents.get(String(id)))

const getCurrentVehicleEventStatus = state => state.vehicleEvents.get('current')

export const createGetCurrentVehicleEventStatusSelector = () => createSelector([getCurrentVehicleEventStatus], status => status)

export const getVehicleEventsFetching = state => state.vehicleEvents.getIn(['all', 'fetching'])

export const getVehicleEventFetching = state => state.vehicleEvents.getIn(['current', 'fetching'])

export const getVehicleEventDeleting = state => state.vehicleEvents.getIn(['deletion', 'inProgress'])

export const getVehicleEventDeleteError = state => state.vehicleEvents.getIn(['deletion', 'error'])
