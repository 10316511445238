export const getDate = state => state.board.get('date')
export const getBoardData = state => state.board.get('data')
export const getBoardDataSpeditions = state => state.board.get('data_speditions')
export const getBoardDataTrailers = state => state.board.get('data_trailers')

export const getVehicleNote = state => state.board.get('vehicle_note')
export const getTrailerNote = state => state.board.get('trailer_note')
export const getTrailerInfo = state => state.board.get('trailer_info')
export const getDailyNote = state => state.board.get('daily_note')
export const getDailyInfo = state => state.board.get('daily_info')
export const getWeeklyInfo = state => state.board.get('weekly_info')

export const getModalSaving = state =>
    state.board.getIn(['vehicle_note', 'saving']) ||
    state.board.getIn(['trailer_note', 'saving']) ||
    state.board.getIn(['snow_chains', 'saving']) ||
    state.board.getIn(['daily_note', 'saving']) ||
    state.board.getIn(['daily_info', 'saving']) ||
    state.board.getIn(['weekly_info', 'saving'])
export const getFetching = state => state.board.get('fetching') || getModalSaving(state)

export const getPreferences = state => state.board.getIn(['preferences', 'objects'])

export const getPointNoteSaving = state => state.board.getIn(['point_note', 'saving'])
export const getPointNoteError = state => state.board.getIn(['point_note', 'error'])
