import { Record } from 'immutable'

export class WarehousingIssue extends Record({
    id: undefined,
    date: undefined,
    note: undefined,
    customer_id: undefined,
    currency_id: undefined,
    currency_rate: undefined,
    warehouse_id: undefined,
    items_count: undefined,
    total_price: undefined,
    items: undefined
}) {}


export class WarehousingIssueItem extends Record({
    id: undefined,
    issue_id: undefined,
    item_id: undefined,
    code: undefined,
    name: undefined,
    units_count: undefined,
    unit_price: undefined
}) {}