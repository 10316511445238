import { Map } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    route: Map({
        object: null,
        fetching: false,
        error: null,
    }),
    address: Map({
        object: '',
        fetching: false,
        error: null,
    }),
    gps: Map({
        object: '',
        fetching: false,
        error: null,
    }),
    vehicle: Map({
        object: {},
        fetching: false,
        error: null,
    }),
    vehicles: Map({
        objects: [],
        fetching: false,
        error: null,
    }),
})

function tracing(state = initialState, action) {
    switch (action.type) {
        // Fetch
        case actionTypes.FETCH_STARTED:
            return state.setIn(['route', 'fetching'], true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['route', 'fetching'], false)
                    .setIn(['route', 'object'], action.payload)
                    .setIn(['route', 'error'], null)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.setIn(['route', 'fetching'], false).setIn(['route', 'error'], action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.setIn(['route', 'fetching'], false)

        // Fetch vehicle
        case actionTypes.FETCH_VEHICLE_STARTED:
            return state.setIn(['vehicle', 'fetching'], true)
        case actionTypes.FETCH_VEHICLE_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['vehicle', 'fetching'], false)
                    .setIn(['vehicle', 'object'], action.payload)
                    .setIn(['vehicle', 'error'], null)
            })
        case actionTypes.FETCH_VEHICLE_REJECTED:
            return state.withMutations(map => {
                map.setIn(['vehicle', 'fetching'], false).setIn(['vehicle', 'error'], action.payload)
            })
        case actionTypes.FETCH_VEHICLE_CANCELLED:
            return state.setIn(['vehicle', 'fetching'], false)

        // Fetch vehicles
        case actionTypes.FETCH_VEHICLES_STARTED:
            return state.setIn(['vehicles', 'fetching'], true)
        case actionTypes.FETCH_VEHICLES_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['vehicles', 'fetching'], false)
                    .setIn(['vehicles', 'objects'], action.payload)
                    .setIn(['vehicles', 'error'], null)
            })
        case actionTypes.FETCH_VEHICLES_REJECTED:
            return state.withMutations(map => {
                map.setIn(['vehicles', 'fetching'], false).setIn(['vehicles', 'error'], action.payload)
            })
        case actionTypes.FETCH_VEHICLES_CANCELLED:
            return state.setIn(['vehicles', 'fetching'], false)

        // Fetch gps
        case actionTypes.FETCH_GPS_STARTED:
            return state.setIn(['gps', 'fetching'], true)
        case actionTypes.FETCH_GPS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['gps', 'fetching'], false)
                    .setIn(['gps', 'object'], action.payload)
                    .setIn(['gps', 'error'], null)
            })
        case actionTypes.FETCH_GPS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['gps', 'fetching'], false).setIn(['gps', 'error'], action.payload)
            })
        case actionTypes.FETCH_GPS_CANCELLED:
            return state.setIn(['gps', 'fetching'], false)

        // Fetch address
        case actionTypes.FETCH_ADDRESS_STARTED:
            return state.setIn(['address', 'fetching'], true)
        case actionTypes.FETCH_ADDRESS_FULFILLED:
            return state.withMutations(map => {
                map.setIn(['address', 'fetching'], false)
                    .setIn(['address', 'object'], action.payload)
                    .setIn(['address', 'error'], null)
            })
        case actionTypes.FETCH_ADDRESS_REJECTED:
            return state.withMutations(map => {
                map.setIn(['address', 'fetching'], false).setIn(['address', 'error'], action.payload)
            })
        case actionTypes.FETCH_ADDRESS_CANCELLED:
            return state.setIn(['address', 'fetching'], false)

        // Clear
        case actionTypes.CLEAR:
            return state.withMutations(map => {
                map.setIn(['route', 'object'], null)
                map.setIn(['vehicle', 'object'], null)
                map.setIn(['vehicles', 'objects'], null)
            })
        case actionTypes.CLEAR_ROUTE:
            return state.withMutations(map => {
                map.setIn(['route', 'object'], null)
            })

        default:
            return state
    }
}

export default tracing
