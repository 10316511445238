import React from 'react'
import { Route, Switch } from 'react-router'
import CostsList from './CostsList'
import CostsEdit from './CostsEdit'
import CostsImport from './CostsImport'

const Costs = props => (
    <Switch>
        <Route exact path={props.match.url} component={CostsList} />
        <Route exact path={`${props.match.url}/new`} component={CostsEdit} />
        <Route exact path={`${props.match.url}/import`} component={CostsImport} />
        <Route exact path={`${props.match.url}/:costID`} component={CostsEdit} />
    </Switch>
)

export default Costs
