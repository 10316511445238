import { createSelector } from 'reselect'

export const getUserRoles = state => state.userRoles.getIn(['all', 'objects'])

export const createGetUserRoleByIDSelector = id => createSelector([getUserRoles], userRoles => userRoles.get(String(id)))

const getCurrentUserRoleStatus = state => state.userRoles.get('current')

export const createGetCurrentUserRoleStatusSelector = () => createSelector([getCurrentUserRoleStatus], status => status)

const getUserRoleDeleting = state => state.userRoles.getIn(['deletion', 'inProgress'])

export const createGetUserRoleDeletingSelector = () => createSelector([getUserRoleDeleting], inProgress => inProgress)

const getUserRoleDeleteError = state => state.userRoles.getIn(['deletion', 'error'])

export const createGetUserRoleDeleteErrorSelector = () => createSelector([getUserRoleDeleteError], error => error)

export const getUserRoleDuplication = state => state.userRoles.getIn(['duplication', 'inProgress'])
export const getUserRoleDuplicateError = state => state.userRoles.getIn(['duplication', 'error'])
export const getUserRoleDuplicated = state => state.userRoles.getIn(['duplication', 'duplicatedUserRole'])
