import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Notifications from 'react-notification-system-redux'
import validator from 'validator'

import { Form, InputField, SelectField, SubmitButton } from '../../../common/form'
import { url, handleCommonEditActions, validationMessage, getPrerequisitesArray } from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'

import * as companyCostCentersActionCreators from '../actionCreators'
import { createGetCompanyCostCenterByIDSelector, createGetCurrentCompanyCostCenterStatusSelector } from '../selectors'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'
import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'

const componentIdentifier = 'company_cost_centers_edit'
const prerequisites = ['companies']

class CompanyCostCentersEditForm extends Component {
    defaultValues = {}

    validationRules = {
        name: value => validator.isEmpty(String(value)) && validationMessage('isEmpty'),
    }

    state = {
        values: this.defaultValues,
    }

    setValues = (values, callback) => {
        this.setState(
            {
                values,
            },
            callback
        )
    }

    handleChangeValues = values => {
        this.setValues(values)
    }

    handleSubmit = values => {
        this.setValues(values, () => {
            this.handleSave()
        })
    }

    handleSave = () => {
        this.props.actions.saveCompanyCostCenter(this.state.values)
    }

    successCallback = () => {
        this.props.successCallback ? this.props.successCallback() : this.props.redirect(url(this.props.match, 'company-cost-centers'))
    }

    backCallback = () => {
        this.props.backCallback ? this.props.backCallback() : this.props.redirect(url(this.props.match, 'company-cost-centers'))
    }

    componentDidMount() {
        this.props.companyCostCenterID && this.props.actions.fetchCompanyCostCenter(this.props.companyCostCenterID)
        this.props.actions.fetchPrerequisites()

        if (this.props.defaultValues) {
            this.setValues(this.props.defaultValues)
        }
    }

    componentWillReceiveProps(nextProps) {
        handleCommonEditActions(this.props, nextProps, this.successCallback)

        if (
            nextProps.companyCostCenter &&
            (JSON.stringify(nextProps.companyCostCenter) !== JSON.stringify(this.props.companyCostCenter) || !this.state.values.id)
        ) {
            this.setValues(nextProps.companyCostCenter.toJS())
        }
    }

    render() {
        const perms = this.props.PERMS ? this.props.PERMS : PERMS
        return (
            <div>
                <LoadingOverlay
                    active={this.props.prerequisites.status.get('fetching') || this.props.status.get('fetching') || this.props.status.get('saving')}
                >
                    <PermissionsCheck hasAny={Object.values(perms)}>
                        <Form
                            values={this.state.values}
                            validationRules={this.validationRules}
                            onChange={this.handleChangeValues}
                            onSubmit={this.handleSubmit}
                            isEdit={Boolean(this.props.companyCostCenter)}
                        >
                            <div className="row">
                                <div
                                    className={`col-md-8 ${
                                        this.props.hiddenFields && this.props.hiddenFields.indexOf('company_id') !== -1 ? 'hidden' : ''
                                    }`}
                                >
                                    <SelectField
                                        id="company_id"
                                        label={this.props.intl.formatMessage({ id: 'fields.company' })}
                                        values={getPrerequisitesArray(this.props.prerequisites.values.get('companies'))}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField id="code" label={this.props.intl.formatMessage({ id: 'fields.code' })} />
                                </div>
                            </div>
                            <InputField
                                id="name"
                                label={this.props.intl.formatMessage({ id: 'fields.name' })}
                                formGroupClassName="form-group-bigger"
                            />

                            <h4 className="m-t-lg wp-100">
                                <FormattedMessage id="fields.transportPlan" />
                            </h4>
                            <p>
                                <FormattedMessage id="fields.minimum" />
                            </p>
                            <div className="row">
                                <div className="col-md-4">
                                    <InputField id="transport_minimum_count" label={this.props.intl.formatMessage({ id: 'fields.count' })} />
                                </div>
                                <div className="col-md-4">
                                    <InputField id="transport_minimum_sales" label={this.props.intl.formatMessage({ id: 'fields.sales' })} />
                                </div>
                                <div className="col-md-4">
                                    <InputField id="transport_minimum_profit" label={this.props.intl.formatMessage({ id: 'fields.profit' })} />
                                </div>
                            </div>
                            <p>
                                <FormattedMessage id="fields.optimum" />
                            </p>
                            <div className="row">
                                <div className="col-md-4">
                                    <InputField id="transport_plan_count" label={this.props.intl.formatMessage({ id: 'fields.count' })} />
                                </div>
                                <div className="col-md-4">
                                    <InputField id="transport_plan_sales" label={this.props.intl.formatMessage({ id: 'fields.sales' })} />
                                </div>
                                <div className="col-md-4">
                                    <InputField id="transport_plan_profit" label={this.props.intl.formatMessage({ id: 'fields.profit' })} />
                                </div>
                            </div>

                            <div className="btns-form">
                                <button onClick={this.backCallback} className="btn btn-addon btn-default">
                                    <i className="far fa-chevron-left" /> <FormattedMessage id="buttons.back" />
                                </button>
                                <SubmitButton
                                    perms={Object.values(perms)}
                                    isEdit={Boolean(this.props.companyCostCenter)}
                                    className="btn-addon pull-right"
                                />
                            </div>
                        </Form>
                    </PermissionsCheck>
                </LoadingOverlay>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        companyCostCenter: createGetCompanyCostCenterByIDSelector(ownProps.companyCostCenterID)(state),
        status: createGetCurrentCompanyCostCenterStatusSelector()(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...companyCostCentersActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        redirect: toURL => dispatch(push(toURL)),
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CompanyCostCentersEditForm)
)
