import { Map } from 'immutable'
import * as actionTypes from './actionTypes'

const initialState = Map({
    month: null,
    data: null,
    fetching: false,
    error: null,
})

function reportEmptyKilometers(state = initialState, action) {
    switch (action.type) {
        // Fetch
        case actionTypes.FETCH:
            return state.set('month', action.payload).set('fetching', true)
        case actionTypes.FETCH_FULFILLED:
            return state.withMutations(map => {
                map.set('fetching', false).set('data', action.payload)
            })
        case actionTypes.FETCH_REJECTED:
            return state.withMutations(map => {
                map.set('fetching', false).set('error', action.payload)
            })
        case actionTypes.FETCH_CANCELLED:
            return state.set('fetching', false)

        // Clear
        case actionTypes.CLEAR:
            return state.set('data', null)

        default:
            return state
    }
}

export default reportEmptyKilometers
