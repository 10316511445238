export const VEHICLE_EVENT_TYPE_PREDEPARTURE_CHECK = 1010
export const VEHICLE_EVENT_TYPE_FUELING = 1020
export const VEHICLE_EVENT_TYPE_SERVICE = 1030
export const VEHICLE_EVENT_TYPE_PARKING = 1040
export const VEHICLE_EVENT_TYPE_PARKING_AND_CHANGE = 1050
export const VEHICLE_EVENT_TYPE_ACCIDENT = 1060
export const VEHICLE_EVENT_TYPE_MOVE = 1070
export const VEHICLE_EVENT_TYPE_CUSTOM_HOUSE = 1080
export const VEHICLE_EVENT_TYPE_VEHICLE_QUIT = 1090

export const VEHICLE_EVENT_TYPE_TRAILER_CATCH = 2010
export const VEHICLE_EVENT_TYPE_TRAILER_LOADING = 2020
export const VEHICLE_EVENT_TYPE_TRAILER_UNLOADING = 2030
export const VEHICLE_EVENT_TYPE_TRAILER_OUT = 2040

export const VEHICLE_EVENT_TYPES = [
    {
        id: VEHICLE_EVENT_TYPE_PREDEPARTURE_CHECK,
        name: 'vehicleEvents.predepartureCheck',
    },
    {
        id: VEHICLE_EVENT_TYPE_FUELING,
        name: 'vehicleEvents.fueling',
    },
    {
        id: VEHICLE_EVENT_TYPE_SERVICE,
        name: 'vehicleEvents.service',
    },
    {
        id: VEHICLE_EVENT_TYPE_PARKING,
        name: 'vehicleEvents.parking',
    },
    {
        id: VEHICLE_EVENT_TYPE_PARKING_AND_CHANGE,
        name: 'vehicleEvents.parkingWithChange',
    },
    {
        id: VEHICLE_EVENT_TYPE_ACCIDENT,
        name: 'vehicleEvents.accident',
    },
    {
        id: VEHICLE_EVENT_TYPE_TRAILER_CATCH,
        name: 'vehicleEvents.trailerCatch',
    },
    {
        id: VEHICLE_EVENT_TYPE_TRAILER_LOADING,
        name: 'vehicleEvents.loading',
    },
    {
        id: VEHICLE_EVENT_TYPE_TRAILER_UNLOADING,
        name: 'vehicleEvents.unloading',
    },
    {
        id: VEHICLE_EVENT_TYPE_TRAILER_OUT,
        name: 'vehicleEvents.trailerOut',
    },
    {
        id: VEHICLE_EVENT_TYPE_MOVE,
        name: 'vehicleEvents.move',
    },
    {
        id: VEHICLE_EVENT_TYPE_CUSTOM_HOUSE,
        name: 'vehicleEvents.customHouse',
    },
    {
        id: VEHICLE_EVENT_TYPE_VEHICLE_QUIT,
        name: 'vehicleEvents.vehicleQuit',
    },
]
