// Fetch list

export const FETCH = 'reportTransportOrders.FETCH'
export const FETCH_STARTED = 'reportTransportOrders.FETCH_STARTED'
export const FETCH_FULFILLED = 'reportTransportOrders.FETCH_FULFILLED'
export const FETCH_REJECTED = 'reportTransportOrders.FETCH_REJECTED'
export const FETCH_CANCELLED = 'reportTransportOrders.FETCH_CANCELLED'

// Clear

export const CLEAR = 'reportTransportOrders.CLEAR'
