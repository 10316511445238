import React, { Component } from 'react'
import { DropdownButton, MenuItem, Tooltip, OverlayTrigger } from 'react-bootstrap'
import moment from 'moment'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import * as constants from '../constants'
import { DispatchersBox, TransportStateBox, ColoredBox } from '../../../common/colored_box'

import TransportsListRowPointIcon from './TransportsListRowPointIcon'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import PermissionsProps from '../../auth/components/PermissionsProps'

import { PERMS as PERMS_TEMPLATES } from '../../transport_templates/permissions'
import { PERMS, PERMS_CUSTOMER, PERMS_SPEDITION, PERMS_COST } from '../permissions'

class TransportsListRow extends Component {
    handleClick = e => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.props.onClick(this.props.transport.id)
    }

    handleEdit = () => {
        this.props.onClick(this.props.transport.id)
    }

    handleDelete = () => {
        this.props.onDelete(this.props.transport.id)
    }

    handleDuplicate = () => {
        this.props.onDuplicate(this.props.transport.id)
    }

    handleCreateTemplate = () => {
        this.props.onCreateTemplate(this.props.transport.id)
    }

    handleCopySms = () => {
        this.props.onCopySms(this.props.transport.id)
    }

    getScoringRules = () => (this.props.transportScoringSettings && this.props.transportScoringSettings.toJS().rules ? this.props.transportScoringSettings.toJS().rules : [])

    getScoringAnswers = () => (this.props.transport.scoring_answers ? this.props.transport.scoring_answers.toJS() : [])

    existsScoringAnswer = () => this.getScoringAnswers().length > 0

    getTotalPoints = () => {
        let points = 0
        this.getScoringAnswers().forEach(answer => {
            points += parseInt(answer.answer ? answer.answer.points : 0)
        })

        return points
    }

    getScoringResultRule = () => {
        const totalPoints = this.getTotalPoints()
        let resultRule = null
        this.getScoringRules().forEach(rule => {
            if (rule.points_from <= totalPoints && rule.points_to >= totalPoints) {
                resultRule = rule
            }
        })

        return resultRule
    }

    render = () => {
        const transport = this.props.transport
        const disponent = this.props.disponent
        const transportState = this.props.transportState
        const transportType = this.props.transportType
        const cargoType = this.props.cargoType
        const goodsType = this.props.goodsType
        const vehicle = this.props.vehicle
        const dispatcher = this.props.dispatcher
        const carrier = this.props.carrier
        const settings = this.props.settings

        const loading = transport.transport_point_loading
        const loadingDatetimeType = loading && helpers.getTransportPointPlannedDatetimeType(loading)
        const loadingDatetimeFrom = loading && loading.getPlannedDatetimeFrom()
        const loadingDatetimeTo = loading && loading.getPlannedDatetimeTo()
        const loadingArrival = loading && loading.datetime_arrival
        const loadingCountry = loading && this.props.createGetCountryByIDSelector(loading.country_id)
        const loadingETA =
            loading &&
            (!loading.transport_point_state_id || loading.transport_point_state_id === constants.TRANSPORT_POINT_STATE_ON_WAY) &&
            loading.last_eta_update &&
            loading.last_eta_update.datetime_real_eta

        let loadingCode = loading && loading.getDataItem(constants.TRANSPORT_POINT_DATA_TYPE_CODE)

        let start = null
        if (loading && loading.transport_id !== transport.id) {
            start = loading
            loadingCode = ''
        }

        const unloading = transport.transport_point_unloading
        const unloadingDatetimeType = unloading && helpers.getTransportPointPlannedDatetimeType(unloading)
        const unloadingDatetimeFrom = unloading && unloading.getPlannedDatetimeFrom()
        const unloadingDatetimeTo = unloading && unloading.getPlannedDatetimeTo()
        const unloadingArrival = unloading && unloading.datetime_arrival
        const unloadingCountry = unloading && this.props.createGetCountryByIDSelector(unloading.country_id)
        const unloadingETA =
            unloading &&
            (!unloading.transport_point_state_id || unloading.transport_point_state_id === constants.TRANSPORT_POINT_STATE_ON_WAY) &&
            unloading.last_eta_update &&
            unloading.last_eta_update.datetime_real_eta

        const points = {
            before: [],
            between: [],
            after: [],
        }

        const vehicles = []
        const vehicleIds = {}

        const trailers = []
        const trailerIds = {}

        if (transport.transport_point_start && (!loading || transport.transport_point_start.id !== loading.id)) {
            points.before.push(transport.transport_point_start)
        }

        transport.transport_points &&
            transport.transport_points.forEach(point => {
                if (point.type_id === constants.TRANSPORT_POINT_TYPE_WAYPOINT) {
                    return null
                }

                if (!start && loading && point.order < loading.order) {
                    points.before.push(point)
                } else if (unloading && point.order > unloading.order) {
                    points.after.push(point)
                } else if ((!loading || loading.id !== point.id) && (!unloading || unloading.id !== point.id)) {
                    points.between.push(point)
                }

                if (point.vehicle_id && !vehicleIds[point.vehicle_id]) {
                    const v = this.props.createGetVehicleByIDSelector(point.vehicle_id)
                    if (v) {
                        vehicleIds[point.vehicle_id] = 1
                        vehicles.push(this.props.createGetVehicleByIDSelector(point.vehicle_id))
                    }
                }

                if (point.trailer_id && !trailerIds[point.trailer_id]) {
                    const t = this.props.createGetTrailerByIDSelector(point.trailer_id)
                    if (t) {
                        trailerIds[point.trailer_id] = 1
                        trailers.push(this.props.createGetTrailerByIDSelector(point.trailer_id))
                    }
                }

                return null
            })

        const transportParts =
            transport.transport_parts && transport.transport_parts.size ? transport.transport_parts : [{ id: 0, take_from_transport: true }]

        const fbSentTooltip = (
            <Tooltip id="fbSentTooltip">
                {transport.fb_sent_at
                    ? `${this.props.intl.formatMessage({ id: 'fields.sentAt' })} ${formatters.datetimeFormatter(transport.fb_sent_at)}`
                    : this.props.intl.formatMessage({ id: 'modules.transports.notSentYet' })}
            </Tooltip>
        )

        const approachDiffTolerance = settings && settings.get('approach_diff_tolerance')? settings.get('approach_diff_tolerance') : 0

        const loadingDistanceWarning = Boolean(
            loading &&
                loading.real_distance &&
                loading.real_distance > 20 &&
                loading.planned_distance &&
                loading.real_distance > loading.planned_distance * (1 + approachDiffTolerance / 100)
        )

        const loadingDistanceWarningTooltip = loadingDistanceWarning && (
            <Tooltip id="startDistanceWarningTooltip">
                {`${this.props.intl.formatMessage({ id: 'modules.transports.suspectlyLargeApproach' })}: ${formatters.distanceFormatter(
                    loading.real_distance
                )}`}
                <br />
                {`${this.props.intl.formatMessage({ id: 'fields.plannedApproach' })}: ${formatters.distanceFormatter(loading.planned_distance)}`}
                <br />
                {`${this.props.intl.formatMessage({ id: 'fields.difference' })}: ${formatters.percentFormatter(
                    ((loading.real_distance - loading.planned_distance) / loading.planned_distance) * 100
                )}`}
            </Tooltip>
        )

        const classNames = ['transports-table-row', 'row-selectable']
        if (transport.deleted_at) {
            classNames.push('is-deleted')
        }

        let totalPrice = 0
        let totalPriceCZK = 0
        let totalCurrency = null
        transport.transport_customers &&
            transport.transport_customers.size > 0 &&
            transport.transport_customers.forEach(transportCustomer => {
                totalPrice += transportCustomer.get('price')
                totalPriceCZK += transportCustomer.get('price') * transportCustomer.get('currency_rate')
                totalCurrency = this.props.createGetCurrencyByIDSelector(transportCustomer.get('currency_id'))
            })

        const plannedDistance = parseInt(transport.invoicing_distance > 0 ? transport.invoicing_distance : (transport.specified_distance > 0 ? transport.specified_distance : transport.planned_distance))
        const distance = transport.real_distance > 0 ? transport.real_distance : plannedDistance

        const realRouteDiffTolerance = settings && settings.get('real_route_diff_tolerance') ? settings.get('real_route_diff_tolerance') : 0

        const distanceWarning = Boolean(
            transport.real_distance &&
            transport.real_distance > 20 &&
            plannedDistance &&
            (
                transport.real_distance > plannedDistance * (1 + realRouteDiffTolerance / 100) ||
                (!transport.real_distance && !plannedDistance)
            )
        )

        const distanceWarningTooltip = distanceWarning && (
            <Tooltip id="distanceWarningTooltip">
                {`${this.props.intl.formatMessage({ id: 'modules.transports.realDistanceWarning' })}`}
            </Tooltip>
        )

        const scoringResultRule = this.getScoringResultRule()

        const minPricePerKm = settings && settings.get('min_price_per_km') ? settings.get('min_price_per_km') : 0

        const minPricePerKmWarning = Boolean(
            (!distance || !totalPriceCZK) ||
            (
                distance &&
                totalPriceCZK &&
                minPricePerKm &&
                (totalPriceCZK / distance < minPricePerKm || totalPriceCZK / distance <= 0)
            )
        )

        const minPricePerKmWarningTooltip = minPricePerKmWarning && (
            <Tooltip id="minPricePerKmWarningTooltip">
                {`${this.props.intl.formatMessage({ id: 'modules.transports.minPricePerKmWarningTooltip' })}`}
            </Tooltip>
        )

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-static-element-interactions */

        return (
            <tr onClick={this.handleClick} className={classNames.join(' ')}>
                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.CREATE, PERMS_TEMPLATES.CREATE, PERMS.DELETE, PERMS.COPY_TO_CLIPBOARD]}>
                    <td className="w-40 text-center td-menu">
                        <div onClick={e => e.stopPropagation()}>
                            <DropdownButton id={`menu-${transport.id}`} className={'far fa-bars'} noCaret title="">
                                <PermissionsCheck has={[PERMS.UPDATE]}>
                                    <MenuItem eventKey={1} onSelect={this.handleEdit}>
                                        <i className="far fa-pencil" />
                                        <FormattedMessage id="buttons.openEdit" />
                                    </MenuItem>
                                </PermissionsCheck>
                                <PermissionsCheck has={[PERMS.CREATE]}>
                                    <MenuItem eventKey={2} onSelect={this.handleDuplicate} disabled={transport.deleted_at}>
                                        <i className="far fa-copy" />
                                        <FormattedMessage id="buttons.copy" />
                                    </MenuItem>
                                </PermissionsCheck>
                                <PermissionsCheck has={[PERMS_TEMPLATES.CREATE]}>
                                    <MenuItem eventKey={3} onSelect={this.handleCreateTemplate} disabled={transport.deleted_at}>
                                        <i className="far fa-clipboard" />
                                        <FormattedMessage id="buttons.createTemplate" />
                                    </MenuItem>
                                </PermissionsCheck>
                                <PermissionsCheck has={[PERMS.DELETE]}>
                                    <MenuItem
                                        eventKey={4}
                                        onSelect={this.handleDelete}
                                        disabled={Boolean(transport.deleted_at || transport.fb_sent_at)}
                                    >
                                        <i className="far fa-trash" />
                                        <FormattedMessage id="buttons.delete" />
                                    </MenuItem>
                                </PermissionsCheck>
                                {this.props.transpportCanSendSms && (
                                    <PermissionsCheck has={[PERMS.COPY_TO_CLIPBOARD]}>
                                        <MenuItem eventKey={5} onSelect={this.handleCopySms}>
                                            <i className="far fa-clipboard" />
                                            <FormattedMessage id="buttons.smsCopy" />
                                        </MenuItem>
                                    </PermissionsCheck>
                                )}
                            </DropdownButton>
                        </div>
                    </td>
                </PermissionsCheck>
                <td className="w-100">
                    <strong>{transport.id}</strong>
                    <small className="pull-right">
                        <OverlayTrigger placement="top" overlay={fbSentTooltip}>
                            <i className={`fas fa-check m-l-xxs ${transport.fb_sent_at ? 'text-success' : 'text-default'}`} />
                        </OverlayTrigger>
                    </small>
                    <br />
                    <small className="text-gray">{formatters.datetimeFormatter(transport.created_at)}</small>
                </td>
                <td className="w-100">
                    {transportState && (
                        <TransportStateBox transportState={transportState}>
                            {this.props.intl.formatMessage({ id: `transportStates.${transportState.name}` })}
                        </TransportStateBox>
                    )}
                    <br />
                    <small className="text-gray display-inline wp-100">{disponent && disponent.getShortFullName()}</small>
                </td>
                <td className="w-30 text-center">
                    {transport.transport_files && transport.transport_files.size > 0 && (
                        <span>
                            {transport.is_all_required_files_uploaded ? (
                                <i className="fas fa-file text-success" />
                            ) : (
                                <i className="fas fa-file text-gray-light" />
                            )}
                        </span>
                    )}
                </td>
                <PermissionsCheck hasAny={Object.values(PERMS_CUSTOMER)}>
                    <td className="w-max wm-360 relative">
                        {transport.transport_customers && transport.transport_customers.size ? (
                            transport.transport_customers.map(transportCustomer => {
                                const customer = this.props.createGetCustomerByIDSelector(transportCustomer.get('customer_id'))
                                return (
                                    <span key={`customer-${transportCustomer.get('id')}`}>
                                        <strong>{customer && customer.name}</strong>
                                        {transportCustomer.order_number && ` [${transportCustomer.order_number}]`}
                                        <br />
                                    </span>
                                )
                            })
                        ) : (
                            <span>
                                <br />
                            </span>
                        )}
                        <small className="text-gray">
                            {transportType && transportType.name} {transport.is_cabotage ? ('('+this.props.intl.formatMessage({ id: 'fields.cabotage' })+')') : ''} | {cargoType && cargoType.name} | {goodsType && goodsType.name}
                        </small>
                    </td>
                </PermissionsCheck>
                <PermissionsProps hasAny={Object.values(PERMS_CUSTOMER)} props={{ className: 'w-720' }}>
                    <td colSpan={2} className="w-max wm-720">
                        {transportParts.map(transportPart => {
                            if (transportPart.take_from_transport) {
                                return (
                                    <div key={`transportPart-${transportPart.id}`} className="relative pull-left wp-100">
                                        <div className="transport-point-loading">
                                            {loading && (
                                                <div>
                                                    <TransportsListRowPointIcon
                                                        key={loading.id}
                                                        transport={transport}
                                                        point={loading}
                                                        createGetCountryByIDSelector={this.props.createGetCountryByIDSelector}
                                                        createGetTransportPointStateByIDSelector={this.props.createGetTransportPointStateByIDSelector}
                                                    />
                                                    {loading.city && (
                                                        <strong>
                                                            {loadingCountry && loadingCountry.iso_code} {`${loading.zipcode} ${loading.city}`}
                                                        </strong>
                                                    )}
                                                    {loadingDistanceWarning && (
                                                        <OverlayTrigger placement="top" overlay={loadingDistanceWarningTooltip}>
                                                            <i className="far fa-exclamation-triangle text-danger m-l-xxs" />
                                                        </OverlayTrigger>
                                                    )}
                                                    <br />
                                                    <small className="text-gray">
                                                        {loadingDatetimeType && `${loadingDatetimeType}: `}
                                                        {formatters.datetimePeriodFormatter(loadingDatetimeFrom, loadingDatetimeTo)}
                                                        {' | '}
                                                        {loadingArrival ? (
                                                            <span
                                                                className={
                                                                    loadingDatetimeTo && moment(loadingArrival).isBefore(moment(loadingDatetimeTo))
                                                                        ? 'text-success'
                                                                        : 'text-danger'
                                                                }
                                                            >
                                                                A: {formatters.datetimeFormatter(loadingArrival)}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                ETA:{' '}
                                                                {loadingETA ? (
                                                                    <span
                                                                        className={
                                                                            loadingDatetimeTo && moment(loadingETA).isBefore(moment(loadingDatetimeTo))
                                                                                ? 'text-success'
                                                                                : 'text-danger'
                                                                        }
                                                                    >
                                                                        {formatters.datetimeFormatter(loadingETA)}
                                                                        {loading.last_eta_update.distance &&
                                                                            ` (${formatters.distanceFormatter(loading.last_eta_update.distance / 1000)})`}
                                                                    </span>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </span>
                                                        )}
                                                        {loadingCode && loadingCode.value && ` | ${loadingCode.value}`}
                                                    </small>
                                                </div>
                                            )}
                                        </div>
                                        <div className="transport-point-unloading">
                                            {unloading && (
                                                <div>
                                                    <TransportsListRowPointIcon
                                                        key={unloading.id}
                                                        transport={transport}
                                                        point={unloading}
                                                        createGetCountryByIDSelector={this.props.createGetCountryByIDSelector}
                                                        createGetTransportPointStateByIDSelector={this.props.createGetTransportPointStateByIDSelector}
                                                    />
                                                    {unloading.city && (
                                                        <strong>
                                                            {unloadingCountry && unloadingCountry.iso_code} {`${unloading.zipcode} ${unloading.city}`}
                                                        </strong>
                                                    )}
                                                    <br />
                                                    <small className="text-gray">
                                                        {unloadingDatetimeType && `${unloadingDatetimeType}: `}
                                                        {formatters.datetimePeriodFormatter(unloadingDatetimeFrom, unloadingDatetimeTo)}
                                                        {' | '}
                                                        {unloadingArrival ? (
                                                            <span
                                                                className={
                                                                    loadingDatetimeTo && moment(unloadingArrival).isBefore(moment(unloadingDatetimeTo))
                                                                        ? 'text-success'
                                                                        : 'text-danger'
                                                                }
                                                            >
                                                                A: {formatters.datetimeFormatter(unloadingArrival)}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                ETA:{' '}
                                                                {unloadingETA ? (
                                                                    <span
                                                                        className={
                                                                            unloadingDatetimeTo &&
                                                                            moment(unloadingETA).isBefore(moment(unloadingDatetimeTo))
                                                                                ? 'text-success'
                                                                                : 'text-danger'
                                                                        }
                                                                    >
                                                                        {formatters.datetimeFormatter(unloadingETA)}
                                                                        {unloading.last_eta_update.distance &&
                                                                            ` (${formatters.distanceFormatter(
                                                                                unloading.last_eta_update.distance / 1000
                                                                            )})`}
                                                                    </span>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </span>
                                                        )}
                                                    </small>
                                                </div>
                                            )}
                                        </div>
                                        <div className="transport-point-icons transport-point-icons-before">
                                            <div>
                                                {points.before.map(point => (
                                                    <TransportsListRowPointIcon
                                                        key={point.id}
                                                        transport={transport}
                                                        point={point}
                                                        createGetCountryByIDSelector={this.props.createGetCountryByIDSelector}
                                                        createGetTransportPointStateByIDSelector={this.props.createGetTransportPointStateByIDSelector}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        <span className="transport-point-icons transport-point-icons-between">
                                            <div>
                                                {points.between.map(point => (
                                                    <TransportsListRowPointIcon
                                                        key={point.id}
                                                        transport={transport}
                                                        point={point}
                                                        createGetCountryByIDSelector={this.props.createGetCountryByIDSelector}
                                                        createGetTransportPointStateByIDSelector={this.props.createGetTransportPointStateByIDSelector}
                                                    />
                                                ))}
                                            </div>
                                        </span>
                                        <span className="transport-point-icons transport-point-icons-after">
                                            <div>
                                                {points.after.map(point => (
                                                    <TransportsListRowPointIcon
                                                        key={point.id}
                                                        transport={transport}
                                                        point={point}
                                                        createGetCountryByIDSelector={this.props.createGetCountryByIDSelector}
                                                        createGetTransportPointStateByIDSelector={this.props.createGetTransportPointStateByIDSelector}
                                                    />
                                                ))}
                                            </div>
                                        </span>
                                    </div>
                                )
                            }
                            return (
                                <div key={`transportPart-${transportPart.id}`} className="transport_part">
                                    <span>{transportPart.name}</span>
                                </div>
                            )
                        })}
                    </td>
                </PermissionsProps>
                {transport.is_spedition ? (
                    <td colSpan={2} className="w-240">
                        <div>
                            <ColoredBox bgRGB="#cccccc" textRGB="#222222" className="wp-100 display-inline text-center">
                                <FormattedMessage id="fields.spedition" />{transport.carrier_vehicle_registration && (' - '+transport.carrier_vehicle_registration)}
                            </ColoredBox>
                            <PermissionsCheck hasAny={Object.values(PERMS_SPEDITION)}>
                                <br />
                                <small className="text-gray">{carrier && carrier.name}</small>
                            </PermissionsCheck>
                        </div>
                    </td>
                ) : (
                    <td className="w-120">
                        <div>
                            {vehicle || vehicles.length > 0 ? (
                                <div>
                                    {vehicles.length ? (
                                        vehicles.map((v, k) => (
                                            <span key={`vehicles-${v.id}`} style={{ marginTop: `${k ? 3 : 0}px` }} className="display-inline">
                                                <DispatchersBox dispatcher={dispatcher}>{v.registration}</DispatchersBox>
                                                <br />
                                            </span>
                                        ))
                                    ) : (
                                        <span>
                                            <DispatchersBox dispatcher={dispatcher}>{vehicle.registration}</DispatchersBox>
                                            <br />
                                        </span>
                                    )}

                                    <div>
                                        <small className="text-gray">{dispatcher && dispatcher.getShortFullName()}</small>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </td>
                )}
                {!transport.is_spedition && (
                    <td className="w-120">
                        <div>
                            {trailers.length > 0 &&
                                trailers.map((t, k) => (
                                    <span key={`trailers-${t.id}`} style={{ marginTop: `${k ? 3 : 0}px` }} className="display-inline">
                                        <DispatchersBox dispatcher={null}>{t.registration}</DispatchersBox>
                                        <br />
                                    </span>
                                ))}
                        </div>
                        <small className="text-gray">
                            {transport.drivers &&
                                transport.drivers.map(driverId => {
                                    const driver = this.props.createGetDriverByIDSelector(driverId)

                                    return (
                                        driver && (
                                            <span key={driverId}>
                                                {driver.getShortFullName()}
                                                <br />
                                            </span>
                                        )
                                    )
                                })}
                        </small>
                    </td>
                )}
                <td className={distanceWarning ? 'bg-danger w-90 text-right' : 'w-90 text-right'}>
                    {distanceWarning && (
                        <OverlayTrigger placement="top" overlay={distanceWarningTooltip}>
                            <i className="far fa-exclamation-triangle m-r-xxs" />
                        </OverlayTrigger>
                    )}
                    {transport.real_distance ? formatters.distanceFormatter(transport.real_distance) : '-'}
                    <span className="display-inline wp-100">
                        <small className={!distanceWarning ? 'text-gray' : ''}>
                            {plannedDistance > 0 ? formatters.distanceFormatter(plannedDistance) : '-'}
                        </small>
                    </span>
                </td>
                <PermissionsCheck hasAny={Object.values(PERMS_COST)}>
                    <td className="w-90 text-right">
                        {transport.transport_customers && transport.transport_customers.size ? (
                            transport.transport_customers.map(transportCustomer => {
                                const currency = this.props.createGetCurrencyByIDSelector(transportCustomer.get('currency_id'))
                                return (
                                    <span className="display-inline wp-100" key={`currency-${transportCustomer.get('id')}`}>
                                        {formatters.priceFormatter(transportCustomer.get('price'), '0,0', currency ? currency.iso_code : '')}
                                    </span>
                                )
                            })
                        ) : (
                            <span />
                        )}
                        <span className="display-inline wp-100">
                            <small className="text-gray">{formatters.priceFormatter(totalPriceCZK)}</small>
                        </span>
                    </td>
                    <td className={minPricePerKmWarning ? 'bg-danger w-100 text-right' : 'w-100 text-right'}>
                        {minPricePerKmWarning && (
                            <OverlayTrigger placement="top" overlay={minPricePerKmWarningTooltip}>
                                <i className="far fa-exclamation-triangle m-r-xxs" />
                            </OverlayTrigger>
                        )}
                        {distance && formatters.priceFormatter(totalPrice / distance, '0,0.00', totalCurrency ? totalCurrency.iso_code : '')}
                        <span className="display-inline wp-100">
                            <small className={!minPricePerKmWarning ? 'text-gray' : ''}>{distance && formatters.priceFormatter(totalPriceCZK / distance, '0,0.00')}</small>
                        </span>
                    </td>
                </PermissionsCheck>
                <td className="w-50 text-center">
                    {this.existsScoringAnswer() && (
                        <div>
                            <strong>{scoringResultRule && scoringResultRule.mark}</strong>
                            <br />
                            <small className="text-gray">{this.getTotalPoints()}</small>
                        </div>
                    )}
                </td>
            </tr>
        )

        /* eslint-enable jsx-a11y/no-static-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

export default injectIntl(TransportsListRow)
