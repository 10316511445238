import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import ReactPaginate from 'react-paginate'
import Notifications from 'react-notification-system-redux'
import { handleCommonListActions, getListItemsCountMessage, getPrerequisitesArray } from '../../helpers'

import { prepareTableActionCreators } from '../../table/actionCreators'
import { TableSortButtons, TableFilterInputField, TableFilterSelectField, TableFilterBooleanField } from '../../table/components'
import { getActiveFilters, getTotalNumberOfPages } from '../../table/helpers'
import { TableModelColumn, TableModelColumnFilteringType } from '../../table/model'
import { createTableDataSelector, createTableModelSelector } from '../../table/selectors'

import { preparePrerequisitesActionCreators } from '../../prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../prerequisites/selectors'

import * as customersActionCreators from '../../../pages/customers/actionCreators'
import { getCustomers } from '../../../pages/customers/selectors'
import { createGetCountryByIDSelector } from '../../../pages/countries/selectors'
import { createGetCustomerAddressByIDSelector } from '../../../pages/customer_address/selectors'
import { createGetUserByIDSelector } from '../../../pages/users/selectors'

import CustomerPickerListRow from './CustomerPickerListRow'

const tableIdentifier = 'customers_list'
const clientSideItemsPerPage = undefined

const componentIdentifier = 'customers_list'
const prerequisites = ['countries', 'users']

const columns = {
    name: new TableModelColumn({}),
    code: new TableModelColumn({}),
    street: new TableModelColumn({}),
    city: new TableModelColumn({}),
    zipcode: new TableModelColumn({}),
    country: new TableModelColumn({}),
    reg_number: new TableModelColumn({}),
    vat_number: new TableModelColumn({}),
}

class CustomersList extends Component {
    refresh = () => {
        this.props.actions.fetchCustomers()
    }

    resetFilters = () => {
        this.props.table.resetFilters()
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    componentDidMount() {
        this.props.actions.fetchCustomers()
        this.props.actions.fetchPrerequisites()
    }

    componentWillReceiveProps(nextProps) {
        handleCommonListActions(this.props, nextProps)
    }

    render() {
        const sorting = this.props.tableModel.get('sorting')
        const filters = this.props.tableModel.get('filters')

        return (
            <div className={'page-inner bg-n '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                <div id="main-wrapper">
                    <h4>
                        <FormattedMessage id="modules.customers.heading" />
                    </h4>

                    <div className="btns-list">
                        {this.isFilterActive() > 0 && (
                            <button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                            </button>
                        )}
                        <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                            <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                        </button>
                    </div>

                    <div className="table-container">
                        <table className="table table-striped table-hover table-fixed-header full-height">
                            <thead>
                                <tr>
                                    <th className="w-140">
                                        <FormattedMessage id="fields.vatNumber" />
                                    </th>
                                    <th className="w-100">
                                        <FormattedMessage id="fields.regNumber" />
                                    </th>
                                    <th className="w-400">
                                        <FormattedMessage id="fields.name" />
                                        <TableSortButtons identifier="name" sorting={sorting} onChange={this.props.table.changeSorting} />
                                    </th>
                                    <th className="w-80 text-center">
                                        <FormattedMessage id="fields.country" />
                                    </th>
                                    <th className="w-max wm-300">
                                        <FormattedMessage id="fields.address" />
                                    </th>
                                    <th className="w-200">
                                        <FormattedMessage id="fields.bankAccount" />
                                    </th>
                                    <th className="w-160">
                                        <FormattedMessage id="fields.businessManager" />
                                    </th>
                                    <th className="w-160">
                                        <FormattedMessage id="fields.dispatcher" />
                                    </th>
                                    <th className="w-80 text-center">
                                        <FormattedMessage id="fields.blocked" />
                                    </th>
                                </tr>
                                <tr className="filters">
                                    <th className="w-140">
                                        <TableFilterInputField
                                            identifier="vat_number"
                                            type={TableModelColumnFilteringType.stringFromStart}
                                            filters={filters}
                                            onChange={this.props.table.changeFilter}
                                        />
                                    </th>
                                    <th className="w-100">
                                        <TableFilterInputField
                                            identifier="reg_number"
                                            type={TableModelColumnFilteringType.stringFromStart}
                                            filters={filters}
                                            onChange={this.props.table.changeFilter}
                                        />
                                    </th>
                                    <th className="w-400">
                                        <TableFilterInputField
                                            identifier="name"
                                            type={TableModelColumnFilteringType.string}
                                            filters={filters}
                                            onChange={this.props.table.changeFilter}
                                        />
                                    </th>
                                    <th className="w-80">
                                        <TableFilterSelectField
                                            identifier="country_id"
                                            type={TableModelColumnFilteringType.equal}
                                            filters={filters}
                                            onChange={this.props.table.changeFilter}
                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('countries'), 'id', 'iso_code')}
                                        />
                                    </th>
                                    <th className="w-max wm-300">
                                        <TableFilterInputField
                                            identifier="address_full"
                                            type={TableModelColumnFilteringType.string}
                                            filters={filters}
                                            onChange={this.props.table.changeFilter}
                                        />
                                    </th>
                                    <th className="w-200">
                                        <TableFilterInputField
                                            identifier="bank_account_full"
                                            type={TableModelColumnFilteringType.string}
                                            filters={filters}
                                            onChange={this.props.table.changeFilter}
                                        />
                                    </th>
                                    <th className="w-160">
                                        <TableFilterSelectField
                                            identifier="business_manager_id"
                                            type={TableModelColumnFilteringType.equal}
                                            filters={filters}
                                            onChange={this.props.table.changeFilter}
                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('users'))}
                                        />
                                    </th>
                                    <th className="w-160">
                                        <TableFilterSelectField
                                            identifier="dispatcher_id"
                                            type={TableModelColumnFilteringType.equal}
                                            filters={filters}
                                            onChange={this.props.table.changeFilter}
                                            values={getPrerequisitesArray(this.props.prerequisites.values.get('users'))}
                                        />
                                    </th>
                                    <th className="w-80">
                                        <TableFilterBooleanField
                                            identifier="is_blocked"
                                            type={TableModelColumnFilteringType.equal}
                                            filters={filters}
                                            onChange={this.props.table.changeFilter}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.customers.data.valueSeq().map(row => {
                                    const address = this.props.createGetCustomerAddressByIDSelector(row.invoicing_address)
                                    const country = address && this.props.createGetCountryByIDSelector(address.country_id)
                                    const businessManager = this.props.createGetUserByIDSelector(row.business_manager_id)
                                    const dispatcher = this.props.createGetUserByIDSelector(row.dispatcher_id)

                                    return (
                                        <CustomerPickerListRow
                                            key={`row-${row.id}`}
                                            data={row}
                                            onClick={this.props.onClick}
                                            country={country}
                                            address={address}
                                            businessManager={businessManager}
                                            dispatcher={dispatcher}
                                        />
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="pull-left m-l-xxs m-t-md">
                        <FormattedMessage id="pagination.totalRecords" />:{' '}
                        {getListItemsCountMessage(
                            clientSideItemsPerPage,
                            this.props.customers.count,
                            this.props.tableModel.getIn(['pagination', 'totalCount'])
                        )}
                    </div>

                    <ReactPaginate
                        containerClassName="pagination"
                        breakLabel={<span className="disabled">...</span>}
                        activeClassName="active"
                        pageCount={getTotalNumberOfPages(this.props.tableModel, this.props.customers.count, clientSideItemsPerPage)}
                        pageRangeDisplayed={10}
                        marginPagesDisplayed={2}
                        forcePage={this.props.tableModel.getIn(['pagination', 'current'])}
                        onPageChange={this.props.table.changePage}
                        previousLabel={this.props.intl.formatMessage({ id: 'pagination.previous' })}
                        nextLabel={this.props.intl.formatMessage({ id: 'pagination.next' })}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        customers: createTableDataSelector({
            tableIdentifier,
            columns,
            dataSelector: getCustomers,
            clientSideItemsPerPage,
        })(state),
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        createGetCountryByIDSelector: id => createGetCountryByIDSelector(id)(state),
        createGetCustomerAddressByIDSelector: id => createGetCustomerAddressByIDSelector(id)(state),
        createGetUserByIDSelector: id => createGetUserByIDSelector(id)(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    ...customersActionCreators,
                    ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                },
                dispatch
            ),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        redirect: toURL => dispatch(push(toURL)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(CustomersList)
)
