import { createSelector } from 'reselect'

export const getVehicles = state => state.vehicles.getIn(['all', 'objects'])
export const getTrailers = state => state.trailers.getIn(['all', 'objects'])

export const createGetVehicleByIDSelector = id => createSelector([getVehicles], vehicles => vehicles.get(String(id)))
export const createGetTrailerByIDSelector = id => createSelector([getTrailers], trailers => trailers.get(String(id)))

const getCurrentVehicleStatus = state => state.vehicles.get('current')
export const createGetCurrentVehicleStatusSelector = () => createSelector([getCurrentVehicleStatus], status => status)

export const getVehicleDeleting = state => state.vehicles.getIn(['deletion', 'inProgress'])
export const getVehicleDeleteError = state => state.vehicles.getIn(['deletion', 'error'])

const getTransportStart = state => state.vehicles.getIn(['transportStart', 'object'])
export const createGetTransportStartSelector = () => createSelector([getTransportStart], transportStart => transportStart)

const getDuplicity = state => state.vehicles.getIn(['all', 'duplicity'])
export const createGetDuplicitySelector = () => createSelector([getDuplicity], duplicity => duplicity)

export const getVehicleHistoryData = state => state.vehicles.getIn(['history', 'data'])
export const getVehicleHistoryFetching = state => state.vehicles.getIn(['history', 'fetching'])
export const getVehicleHistoryError = state => state.vehicles.getIn(['history', 'error'])

export const getVehicleDocumentsData = state => state.vehicles.getIn(['documents', 'data'])
export const getVehicleDocumentsFetching = state => state.vehicles.getIn(['documents', 'fetching'])
export const getVehicleDocumentsError = state => state.vehicles.getIn(['documents', 'error'])

export const getVehicleEquipmentsData = state => state.vehicles.getIn(['equipments', 'data'])
export const getVehicleEquipmentsFetching = state => state.vehicles.getIn(['equipments', 'fetching'])
export const getVehicleEquipmentsError = state => state.vehicles.getIn(['equipments', 'error'])

export const getVehicleEventsData = state => state.vehicles.getIn(['events', 'data'])
export const getVehicleEventsFetching = state => state.vehicles.getIn(['events', 'fetching'])
export const getVehicleEventsError = state => state.vehicles.getIn(['events', 'error'])

export const getVehicleFuelingsData = state => state.vehicles.getIn(['fuelings', 'data'])
export const getVehicleFuelingsFetching = state => state.vehicles.getIn(['fuelings', 'fetching'])
export const getVehicleFuelingsError = state => state.vehicles.getIn(['fuelings', 'error'])
