export const getYear = state => state.reportDriverAllowances.getIn(['list', 'year'])
export const getData = state => state.reportDriverAllowances.getIn(['list', 'data'])
export const getFetching = state => state.reportDriverAllowances.getIn(['list', 'fetching'])
export const getError = state => state.reportDriverAllowances.getIn(['list', 'error'])

export const getDetailsData = state => state.reportDriverAllowances.getIn(['details', 'data'])
export const getDetailsFetching = state => state.reportDriverAllowances.getIn(['details', 'fetching'])
export const getDetailsSaving = state => state.reportDriverAllowances.getIn(['details', 'saving'])
export const getDetailsDeleting = state => state.reportDriverAllowances.getIn(['details', 'deleting'])
export const getDetailsError = state => state.reportDriverAllowances.getIn(['details', 'error'])
