import React from 'react'
import { Route, Switch } from 'react-router'
import WarehousingItemsList from './WarehousingItemsList'
import WarehousingItemsEdit from './WarehousingItemsEdit'

const WarehousingItems = props => (
    <Switch>
        <Route exact path={props.match.url} component={WarehousingItemsList} />
        <Route exact path={`${props.match.url}/new`} component={WarehousingItemsEdit} />
        <Route exact path={`${props.match.url}/:warehousingItemID`} component={WarehousingItemsEdit} />
    </Switch>
)

export default WarehousingItems
