import React from 'react'
import { Route, Switch } from 'react-router'
import DocumentTypesList from './DocumentTypesList'
import DocumentTypesEdit from './DocumentTypesEdit'

const DocumentTypes = props => (
    <Switch>
        <Route exact path={props.match.url} component={DocumentTypesList} />
        <Route exact path={`${props.match.url}/new`} component={DocumentTypesEdit} />
        <Route exact path={`${props.match.url}/:documentTypeID`} component={DocumentTypesEdit} />
    </Switch>
)

export default DocumentTypes
