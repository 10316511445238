import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import classnames from 'classnames'
import { Modal } from 'react-bootstrap'
import Notifications from 'react-notification-system-redux'

import { ConfirmDialog } from '../../../common/confirm_dialog'
import { PeriodPicker } from '../../../common/period_picker'
import * as periodPickerConstants from '../../../common/period_picker/constants'
import * as formatters from '../../../common/formatters'
import * as helpers from '../../../common/helpers'
import { LoadingOverlay } from '../../../common/loading_overlay'
import DriverSurchargesEditForm from '../../driver_surcharges/components/DriverSurchargesEditForm'

import { preparePrerequisitesActionCreators } from '../../../common/prerequisites/actionCreators'
import { preparePrerequisitesFetchStatusSelectors } from '../../../common/prerequisites/selectors'

import * as reportDriverSurchargeActionCreators from '../actionCreators'
import { getData, getFetching, getDetailsData, getDetailsFetching, getDriverNote, getModalSaving } from '../selectors'

import * as driver_surcharges_constants from '../../driver_timesheets/constants'
import { deleteDriverSurcharge } from '../../driver_surcharges/actionCreators'
import { getDriverSurchargeDeleting, getDriverSurchargeDeleteError } from '../../driver_surcharges/selectors'

import { prepareTableActionCreators } from '../../../common/table/actionCreators'
import { TableFilterInputField, TableFilterSelectField } from '../../../common/table/components'
import { getActiveFilters } from '../../../common/table/helpers'
import { TableModelColumnFilteringType } from '../../../common/table/model'
import { createTableModelSelector } from '../../../common/table/selectors'

import PermissionsCheck from '../../auth/components/PermissionsCheck'
import { PERMS } from '../permissions'
import { fullName, priceFormatter } from "../../../common/formatters";

const tableIdentifier = 'report_driver_surcharges'

const componentIdentifier = 'report_driver_surcharges'
const prerequisites = ['dispatchers', 'driversTypes', 'driverSurchargeTypesMonthCodes']

class ReportDriverSurcharges extends Component {
    state = {
        modal: {
            identifier: null,
        },
        modalCreateSurcharge: {
            show: false,
            driverSurchargeID: null,
        },
        modalDetails: {
            show: false,
            driverId: null,
            dateFrom: null,
            dateTo: null,
            driverSurchargeTypeId: null,
        },
        confirmDialog: {
            show: false,
            title: '',
            message: '',
            labelCancel: '',
            labelAccept: '',
            classNameAccept: 'text-success',
            classNameCancel: 'text-default',
            onCancel: () => {
            },
            onAccept: () => {
            },
        },
    }

    refresh = () => {
        this.props.actions.fetchReportDriverSurcharge(this.props.tableModel.get('filters'))
    }

    refreshDetail = () => {
        if (this.state.modalDetails.show) {
            this.props.actions.fetchReportDriverSurchargeDetails(this.state.modalDetails.driverId, this.state.modalDetails.dateFrom, this.state.modalDetails.dateTo, this.state.modalDetails.driverSurchargeTypeId)
        }
    }

    resetFilters = () => {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.resetFilters([], [{
            column: 'date_from',
            type: TableModelColumnFilteringType.equal,
            value: dateFrom.format('DD.MM.YYYY'),
        }, {
            column: 'date_to',
            type: TableModelColumnFilteringType.equal,
            value: dateTo.format('DD.MM.YYYY'),
        },])
    }

    isFilterActive = () => getActiveFilters(this.props.tableModel).size > 0

    handleExport = e => {
        e && e.preventDefault()

        this.props.actions.exportReportDriverSurcharge(this.props.tableModel.get('filters').toJS())
    }

    handleFilterChanged = (key, value) => {
        const filters = this.props.tableModel.get('filters')

        let stringFrom = filters.getIn(['date_from', 'value'])
        let stringTo = filters.getIn(['date_to', 'value'])

        if (key === 'period') {
            stringFrom = value.momentFrom.format('DD.MM.YYYY')
            stringTo = value.momentTo.format('DD.MM.YYYY')
        }

        this.props.table.changeFilters([{
            column: 'date_from',
            type: TableModelColumnFilteringType.equal,
            value: stringFrom,
        }, {
            column: 'date_to',
            type: TableModelColumnFilteringType.equal,
            value: stringTo,
        },])
    }

    // confirm dialog
    showConfirmDialog = options => {
        this.setState({
            confirmDialog: {
                show: true,
                title: options.title || this.props.intl.formatMessage({ id: 'confirmDialog.default.title' }),
                message: options.message || this.props.intl.formatMessage({ id: 'confirmDialog.default.message' }),
                labelCancel: options.labelCancel || this.props.intl.formatMessage({ id: 'confirmDialog.default.cancel' }),
                labelAccept: options.labelAccept || this.props.intl.formatMessage({ id: 'confirmDialog.default.accept' }),
                classNameAccept: options.classNameAccept || 'btn-success',
                classNameCancel: options.classNameCancel || 'btn-default',
                onCancel: options.onCancel || this.hideConfirmDialog,
                onAccept: options.onAccept || this.hideConfirmDialog,
            },
        })
    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                show: false,
                onCancel: () => {
                },
                onAccept: () => {
                },
            },
        })
    }

    // Detail
    handleShowDetails = (e, driverId, dateFrom, dateTo, driverSurchargeTypeId) => {
        e && e.preventDefault()

        this.setState({
            modalDetails: {
                show: true,
                driverId,
                dateFrom,
                dateTo,
                driverSurchargeTypeId,
            },
        }, () => {
            this.props.actions.fetchReportDriverSurchargeDetails(driverId, dateFrom, dateTo, driverSurchargeTypeId)
        })
    }

    handleHideDetails = e => {
        e && e.preventDefault()

        this.setState({
            modalDetails: {
                show: false,
                driverId: null,
                dateFrom: null,
                dateTo: null,
            },
        }, () => {
            this.props.actions.clearReportDriverSurchargeDetails()
        })
    }

    handleExportDetails = e => {
        e && e.preventDefault()

        const stringFrom = this.state.modalDetails.dateFrom
        const strinTo = this.state.modalDetails.dateTo

        this.props.actions.exportReportDriverSurchargeDetails(this.state.modalDetails.driverId, stringFrom, strinTo, this.state.modalDetails.driverSurchargeTypeId || null, this.props.tableModel.get('filters').toJS())
    }

    // Create surcharge
    handleDeleteSurcharge = (e, driverSurchargeID) => {
        e && e.preventDefault()
        e && e.stopPropagation()

        this.showConfirmDialog({
            title: this.props.intl.formatMessage({ id: 'confirmDialog.delete.title' }),
            message: this.props.intl.formatMessage({ id: 'confirmDialog.delete.message' }),
            labelCancel: this.props.intl.formatMessage({ id: 'confirmDialog.delete.cancel' }),
            labelAccept: this.props.intl.formatMessage({ id: 'confirmDialog.delete.accept' }),
            classNameAccept: 'btn-danger',
            onAccept: () => {
                this.props.actions.deleteDriverSurcharge(driverSurchargeID)
                this.hideConfirmDialog()
            },
        })
    }

    handleModalCreateSurchargeShow = (e, driverSurchargeID) => {
        e && e.preventDefault()

        this.setState({
            modalCreateSurcharge: {
                show: true,
                driverSurchargeID,
            },
        })
    }

    handleModalCreateSurchargeClose = e => {
        e && e.preventDefault()

        this.setState({
            modalCreateSurcharge: {
                show: false,
            },
        })
    }

    editCreateSurchargeSuccessCallback = () => {
        this.handleModalCreateSurchargeClose()
        this.refreshDetail()
        this.refresh()
    }

    editCreateSurchargeBackCallback = () => {
        this.handleModalCreateSurchargeClose()
    }

    handleModalDriverNoteSubmit = e => {
        e.preventDefault()
        this.props.actions.updateDriverNote(this.state.modal.values)
    }

    handleSaveResult = (error, successMessage) => {
        if (error) {
            this.props.notify({
                title: this.props.intl.formatMessage({ id: `alerts.titles.error` }),
                message: error,
                position: 'tc',
            }, 'error')
        }
        else {
            this.setState({ modal: { identifier: null } })

            this.props.notify({
                title: this.props.intl.formatMessage({ id: `alerts.titles.success` }),
                message: successMessage,
                position: 'tc',
            }, 'success')

            this.refresh()
            this.refreshDetail()
        }
    }

    componentDidMount() {
        const dateFrom = moment().startOf('month')
        const dateTo = moment().endOf('month')

        this.props.table.setConfiguration([{
            column: 'date_from',
            type: TableModelColumnFilteringType.equal,
            value: dateFrom.format('DD.MM.YYYY'),
        }, {
            column: 'date_to',
            type: TableModelColumnFilteringType.equal,
            value: dateTo.format('DD.MM.YYYY'),
        }, {
            column: 'type_id',
            type: TableModelColumnFilteringType.equal,
            value: Object.keys(driver_surcharges_constants.DRIVER_TIMESHEET_TYPES),
        },], false)

        this.props.actions.fetchPrerequisites()
        this.props.actions.fetchReportDriverSurcharge(this.props.tableModel.get('filters'))
    }

    componentWillUnmount() {
        this.props.actions.clearReportDriverSurchargeDetails()
        this.props.actions.clearReportDriverSurcharge()
    }

    componentWillReceiveProps(nextProps) {
        // Driver note
        if (this.props.driverNote.get('saving') && !nextProps.driverNote.get('saving')) {
            this.handleSaveResult(nextProps.driverNote.get('error'), this.props.intl.formatMessage({ id: 'alerts.messages.noteSaveSuccess' }))
        }

        // Surcharge delete
        if (this.props.driverSurchargeDeleting && !nextProps.driverSurchargeDeleting) {
            if (nextProps.driverSurchargeDeleteError === null) {
                this.props.notify({
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.success' }),
                    message: this.props.intl.formatMessage({ id: 'alerts.messages.deleteSuccess' }),
                    position: 'tc',
                }, 'success')
                this.refreshDetail()
                this.refresh()
            }
            else {
                this.props.notify({
                    title: this.props.intl.formatMessage({ id: 'alerts.titles.error' }),
                    message: nextProps.createFromTemplateError,
                    position: 'tc',
                }, 'error')
            }
        }
    }

    render() {
        document.title = formatters.titleFormatter(this.props.intl.formatMessage({ id: 'modules.reportDriverSurcharge.heading' }))

        const data = this.props.data
        const filters = this.props.tableModel.get('filters')

        const detailsData = this.props.detailsData

        const stringFrom = filters.getIn(['date_from', 'value'])
        const stringTo = filters.getIn(['date_to', 'value'])

        const momentToday = moment()
        const momentFrom = stringFrom ? helpers.convertCSDateToMoment(stringFrom) : moment().startOf('month')
        const momentTo = stringTo ? helpers.convertCSDateToMoment(stringTo) : moment().endOf('month')
        const stringISOFrom = momentFrom.format('YYYY-MM-DD')
        const stringISOTo = momentTo.format('YYYY-MM-DD')

        const detailMomentDay = this.state.modalDetails.dateFrom === this.state.modalDetails.dateTo ? helpers.convertISODatetimeToMoment(this.state.modalDetails.dateTo) : null
        const stringDetailMomentDay = detailMomentDay ? detailMomentDay.format('D.M.Y') : null
        const stringISODetailMomentNextDay = detailMomentDay ? detailMomentDay.add(1, 'd').format('YYYY-MM-DD') : null
        const stringISODetailMomentPrevDay = detailMomentDay ? detailMomentDay.add(-2, 'd').format('YYYY-MM-DD') : null

        const momentDays = []
        const totalDays = {}
        for (let i = 0; i <= momentTo.diff(momentFrom, 'days'); i++) {
            const momentDay = moment(momentFrom).add(i, 'days')
            momentDays.push(momentDay)
            totalDays[momentDay.format('YYYY-MM-DD')] = {
                total: 0,
            }
        }

        let total = {
            sum: 0,
            total: 0,
        }

        const surchargeTypes = this.props.prerequisites.values.get('driverSurchargeTypesMonthCodes').toArray()

        const monthSurchargeTotals = {}
        surchargeTypes.map(surchargeType => {
            monthSurchargeTotals[surchargeType.get('id')] = 0
        })

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        return (<PermissionsCheck hasAny={Object.values(PERMS)} noPermissionsPage>
            <div className={'page-inner '+(document.body.classList.contains('mobile-menu-open') ? 'sidebar-visible' : '')}>
                <div id="main-wrapper">
                    <div className="panel panel-white">
                        <div className="panel-body">
                            <div className="wp-100 pull-left m-b-xs">
                                <h4 className="pull-left">
                                        <span className="pull-left">
                                            <FormattedMessage id="modules.reportDriverSurcharge.heading" />
                                        </span>
                                </h4>
                                <div className="btns-list">
                                    <PeriodPicker
                                        className="m-r-lg"
                                        momentFrom={momentFrom}
                                        momentTo={momentTo}
                                        defaultPicker={periodPickerConstants.PERIOD_PICKER_TYPE_MONTH}
                                        onChange={(momentFrom, momentTo) => this.handleFilterChanged('period', {
                                            momentFrom,
                                            momentTo
                                        })}
                                        allowedPickers={[periodPickerConstants.PERIOD_PICKER_TYPE_MONTH]}
                                        forbidPickType
                                    />
                                    {this.isFilterActive() > 0 && (<button className="btn btn-default btn-addon m-r-xs" onClick={this.resetFilters}>
                                        <i className="far fa-times" /> <FormattedMessage id="buttons.resetFilters" />
                                    </button>)}
                                    <button className="btn btn-default btn-addon m-r-xs" onClick={this.refresh}>
                                        <i className="far fa-sync-alt" /> <FormattedMessage id="buttons.refresh" />
                                    </button>
                                    <PermissionsCheck has={[PERMS.EXPORT]}>
                                        <button className="btn btn-primary btn-addon" onClick={this.handleExport}>
                                            <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                        </button>
                                    </PermissionsCheck>
                                </div>
                            </div>

                            <div className="table-container">
                                <table className="table table-striped table-hover table-fixed-header">
                                    <thead>
                                    <tr>
                                        <th className="w-max wm-200">
                                            <FormattedMessage id="fields.driver" />
                                        </th>
                                        <th className="w-100">
                                            <FormattedMessage id="fields.type" />
                                        </th>
                                        {momentDays.map(momentDay => (
                                            <th key={`day-th-${momentDay.format('YYYY-MM-DD')}`} className="w-50 text-center">
                                                {momentDay.format('DD/MM')}
                                            </th>))}
                                        <th className="w-100 text-right">
                                            <FormattedMessage id="fields.sum" />
                                        </th>
                                        {surchargeTypes.map(surchargeType => (
                                            <th key={`day-th-${surchargeType.get('id')}`} className="w-70 text-right">
                                                {surchargeType.get('code')}
                                            </th>))}
                                        <th className="w-100 text-right">
                                            <FormattedMessage id="fields.total" />
                                        </th>
                                    </tr>
                                    <tr className="filters">
                                        <th className="w-max wm-200">
                                            <TableFilterInputField
                                                identifier="driver_name"
                                                type={TableModelColumnFilteringType.string}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                            />
                                        </th>
                                        <th className="w-100">
                                            <TableFilterSelectField
                                                identifier="drivers_type_id"
                                                type={TableModelColumnFilteringType.equal}
                                                filters={filters}
                                                onChange={this.props.table.changeFilter}
                                                values={helpers.getPrerequisitesArray(this.props.prerequisites.values.get('driversTypes'))}
                                            />
                                        </th>
                                        {momentDays.map(momentDay => (
                                            <th key={`day-th-filter-${momentDay.format('YYYY-MM-DD')}`} className="w-50" />))}
                                        <th className="w-100" />
                                        {surchargeTypes.map(surchargeType => (<th key={`day-th-${surchargeType.get('id')}`} className="w-70"></th>))}
                                        <th className="w-100" />
                                    </tr>
                                    </thead>
                                    <tbody className="relative-td">
                                    {data && data.drivers && data.drivers.map(driverData => {
                                        const driver = driverData.driver
                                        const days = driverData.days
                                        const monthSurcharges = driverData.month_surcharges

                                        if (!driver) {
                                            return null;
                                        }

                                        if (filters.getIn(['driver_name', 'value']) && driver.full_name.toLowerCase().indexOf(filters.getIn(['driver_name', 'value']).toLowerCase()) < 0) {
                                            return null
                                        }

                                        const totalRow = {
                                            sum: 0,
                                            total: 0,
                                        }

                                        return (<tr key={`vehicle-${driver.id}`}>
                                            <td
                                                className="w-max wm-200"
                                                role="button"
                                                onClick={e => {
                                                    this.handleShowDetails(e, driver.id, stringISOFrom, stringISOTo)
                                                }}
                                            >
                                                {fullName(driver.first_name, driver.last_name)}
                                            </td>
                                            <td className="w-100">
                                                {driver.drivers_type ? driver.drivers_type.name : null}
                                            </td>
                                            {momentDays.map(momentDay => {
                                                const date = momentDay.format('YYYY-MM-DD')
                                                const value = days[date] ? days[date].total : 0

                                                const isWeekend = momentDay.isoWeekday() >= 6
                                                const isHoliday = data.holidays.includes(date)

                                                const classes = classnames('w-50 text-center', {
                                                    'is-weekend': isWeekend || isHoliday,
                                                    'is-today': momentDay.isSame(momentToday, 'day'),
                                                })

                                                if (value) {
                                                    totalDays[date].sum += value
                                                    totalRow.sum += value

                                                    totalDays[date].total += value
                                                    totalRow.total += value
                                                }

                                                return (<td
                                                    key={`day-td-${date}`}
                                                    className={classes}
                                                    role="button"
                                                    onClick={e => {
                                                        this.handleShowDetails(e, driver.id, date, date)
                                                    }}
                                                >
                                                    {value ? priceFormatter(value, '0,0', '') : '-'}
                                                </td>)
                                            })}

                                            <td
                                                className="w-100 text-right"
                                                role="button"
                                                onClick={e => this.handleShowDetails(e, driver.id, stringISOFrom, stringISOTo)}
                                            >
                                                <strong>
                                                    {totalRow.sum ? priceFormatter(totalRow.sum, '0,0', '') : '-'}
                                                </strong>
                                            </td>

                                            {surchargeTypes.map(surchargeType => {
                                                const typeId = surchargeType.get('id')
                                                const value = monthSurcharges[typeId] ? monthSurcharges[typeId].total : 0

                                                if (value) {
                                                    monthSurchargeTotals[typeId] += value

                                                    totalRow.total += value
                                                }

                                                return (<td
                                                    className="w-70 text-right"
                                                    role="button"
                                                    onClick={e => this.handleShowDetails(e, driver.id, stringISOFrom, stringISOTo, typeId)}
                                                >
                                                    {value ? priceFormatter(value, '0,0', '') : '-'}
                                                </td>)
                                            })}

                                            <td
                                                className="w-100 text-right"
                                                role="button"
                                                onClick={e => this.handleShowDetails(e, driver.id, stringISOFrom, stringISOTo)}
                                            >
                                                <strong>
                                                    {totalRow.total ? priceFormatter(totalRow.total, '0,0', '') : '-'}
                                                </strong>
                                            </td>
                                        </tr>)
                                    })}

                                    <tr className="b-top row-sum">
                                        <td className="w-max wm-200">
                                            <strong>
                                                <FormattedMessage id="fields.total" />:
                                            </strong>
                                        </td>
                                        <td className="w-100" />
                                        {momentDays.map(momentDay => {
                                            const date = momentDay.format('YYYY-MM-DD')
                                            const value = totalDays[date].total

                                            total.sum += value
                                            total.total += value

                                            return (<td
                                                key={`day-td-total-${date}`}
                                                className="w-50 text-center"
                                                role="button"
                                                onClick={e => this.handleShowDetails(e, null, date, date)}
                                            >
                                                <strong>
                                                    {value ? priceFormatter(value, '0,0', '') : '-'}
                                                </strong>
                                            </td>)
                                        })}

                                        <td
                                            className="w-100 text-right"
                                            role="button"
                                            onClick={e => this.handleShowDetails(e, null, stringISOFrom, stringISOTo)}
                                        >
                                            <strong>
                                                {total.sum ? priceFormatter(total.sum, '0,0', '') : '-'}
                                            </strong>
                                        </td>

                                        {surchargeTypes.map(surchargeType => {
                                            const typeId = surchargeType.get('id')
                                            const value = monthSurchargeTotals[typeId]

                                            total.total += value

                                            return (<td
                                                className="w-70 text-right"
                                                role="button"
                                                onClick={e => this.handleShowDetails(e, null, stringISOFrom, stringISOTo, typeId)}
                                            >
                                                {value ? priceFormatter(value, '0,0', '') : '-'}
                                            </td>)
                                        })}

                                        <td
                                            className="w-100 text-right"
                                            role="button"
                                            onClick={e => this.handleShowDetails(e, null, stringISOFrom, stringISOTo)}
                                        >
                                            <strong>
                                                {total.total ? priceFormatter(total.total, '0,0', '') : '-'}
                                            </strong>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={Boolean(this.state.modalDetails.show)} onHide={this.handleHideDetails} className="modal-size-md-2">
                    <Modal.Header closeButton>
                        <Modal.Title>{detailsData ? detailsData.title : null}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="clearfix">
                        <LoadingOverlay active={this.props.detailsFetching}>
                            <div className="btns-list">
                                {stringDetailMomentDay && (<span className="pull-left text-thin period-selection">
                                        <button
                                            className="btn btn-default btn-rounded"
                                            onClick={e => this.handleShowDetails(e, this.state.modalDetails.driverId, stringISODetailMomentPrevDay, stringISODetailMomentPrevDay)}
                                        >
                                            <i className="far fa-angle-left" />
                                        </button>
                                        <span>{stringDetailMomentDay}</span>
                                        <button
                                            className="btn btn-default btn-rounded"
                                            onClick={e => this.handleShowDetails(e, this.state.modalDetails.driverId, stringISODetailMomentNextDay, stringISODetailMomentNextDay)}
                                        >
                                            <i className="far fa-angle-right" />
                                        </button>
                                    </span>)}
                                    {detailsData && detailsData.data.length > 0 && (<PermissionsCheck has={[PERMS.EXPORT]}>
                                    <button className="btn btn-primary btn-addon m-r-xs" onClick={this.handleExportDetails}>
                                        <i className="far fa-file-excel" /> <FormattedMessage id="buttons.exportToXLS" />
                                    </button>
                                </PermissionsCheck>)}

                                <PermissionsCheck has={[PERMS.CREATE]}>
                                    <button className="btn btn-addon btn-success m-r-xs" onClick={this.handleModalCreateSurchargeShow}>
                                        <i className="far fa-plus" /> <FormattedMessage id="buttons.createItem" />
                                    </button>
                                </PermissionsCheck>
                            </div>

                            <div className="pull-left wp-100">
                                <div className="panel-head">
                                    <h2 className="pull-left wp-100">
                                        <div className="pull-left">
                                            <FormattedMessage id="modules.reportDriverSurcharges.detail.title" />
                                        </div>
                                        <div className="pull-right text-md" />
                                    </h2>
                                </div>

                                <div className="pull-left wp-100 p-v-xs p-h-xs m-b-sm b alert-default">
                                    <div className="table-container">
                                        <table className="table table-striped table-no-border no-m">
                                            <thead>
                                            <tr>
                                                <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                    <th className="w-60" />
                                                </PermissionsCheck>
                                                <th className="w-160">
                                                    <FormattedMessage id="fields.driver" />
                                                </th>
                                                <th className="w-240">
                                                    <FormattedMessage id="fields.type" />
                                                </th>
                                                <th className="w-120 text-center">
                                                    <FormattedMessage id="fields.date" />
                                                </th>
                                                <th className="w-160 text-right">
                                                    <FormattedMessage id="fields.value" />
                                                </th>
                                                <th className="w-max wm-200">
                                                    <FormattedMessage id="fields.note" />
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {detailsData && detailsData.data.map(item => {
                                                const onClickRow = e => this.handleModalCreateSurchargeShow(e, item.id)

                                                return (<tr key={`driver-surcharge-${item.id}`} onClick={onClickRow}>
                                                    <PermissionsCheck hasAny={[PERMS.UPDATE, PERMS.DELETE]}>
                                                        <td className="table-buttons">
                                                            <PermissionsCheck has={[PERMS.UPDATE]}>
                                                                <button className="far fa-pencil text-gray m-r-xxs" />
                                                            </PermissionsCheck>
                                                            <PermissionsCheck has={[PERMS.DELETE]}>
                                                                <button
                                                                    onClick={e => this.handleDeleteSurcharge(e, item.id)}
                                                                    className="far fa-trash text-gray"
                                                                />
                                                            </PermissionsCheck>
                                                        </td>
                                                    </PermissionsCheck>
                                                    <td>
                                                        {item.driver_name}
                                                    </td>
                                                    <td>
                                                        {item.driver_surcharge_type_name}
                                                    </td>
                                                    <td>
                                                        {item.date ? formatters.dateFormatter(item.date) : null}
                                                    </td>
                                                    <td className="text-right">
                                                        {formatters.priceFormatter(item.value, '0.00', item.currency_code)}
                                                    </td>
                                                    <td>
                                                        {item.note}
                                                    </td>
                                                </tr>)
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </LoadingOverlay>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default" onClick={this.handleHideDetails}>
                            <FormattedMessage id="confirmDialog.duplicityFound.cancel" />
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={Boolean(this.state.modalCreateSurcharge.show)} onHide={this.handleModalCreateSurchargeClose} bsSize="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.modalCreateSurcharge.driverSurchargeID ? (<FormattedMessage id="fields.itemEdit" />) : (
                                <FormattedMessage id="fields.itemCreate" />)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="clearfix">
                        <LoadingOverlay active={false}>
                            <div className="pull-left wp-100">
                                <DriverSurchargesEditForm
                                    successCallback={this.editCreateSurchargeSuccessCallback}
                                    backCallback={this.editCreateSurchargeBackCallback}
                                    defaultValues={{
                                        driver_id: this.state.modalDetails.driverId,
                                        date: moment(this.state.modalDetails.dateFrom).format('DD.MM.YYYY'),
                                        driver_surcharge_type_id: this.state.modalDetails.driverSurchargeTypeId
                                    }}
                                    driverSurchargeID={this.state.modalCreateSurcharge.driverSurchargeID}
                                    hiddenFields={this.state.modalDetails.driverId ? ['driver_id'] : []}
                                    PERMS={PERMS}
                                />
                            </div>
                        </LoadingOverlay>
                    </Modal.Body>
                </Modal>
            </div>

            <ConfirmDialog options={this.state.confirmDialog} />
        </PermissionsCheck>)
        /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}

function mapStateToProps(state) {
    return {
        tableModel: createTableModelSelector(tableIdentifier)(state),
        prerequisites: preparePrerequisitesFetchStatusSelectors(componentIdentifier, prerequisites, state),
        data: getData(state),
        fetching: getFetching(state),
        detailsData: getDetailsData(state),
        detailsFetching: getDetailsFetching(state),
        driverNote: getDriverNote(state),
        modalSaving: getModalSaving(state),
        driverSurchargeDeleting: getDriverSurchargeDeleting(state),
        driverSurchargeDeleteError: getDriverSurchargeDeleteError(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators({
                ...reportDriverSurchargeActionCreators, ...preparePrerequisitesActionCreators(componentIdentifier, prerequisites),
                deleteDriverSurcharge,
            }, dispatch),
        },
        notify: (notification, type) => dispatch(Notifications.show(notification, type)),
        dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps, ...dispatchProps, ...ownProps,
        table: bindActionCreators(prepareTableActionCreators(tableIdentifier, stateProps.tableModel), dispatchProps.dispatch),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReportDriverSurcharges))
